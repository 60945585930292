import React, { ReactNode } from "react";

import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

interface AddCommentFieldButtonProps {
  onCommentClick: () => void;
  disabled: boolean;
  buttonText?: ReactNode;
}

function AddCommentFieldButton({
  onCommentClick,
  disabled,
  buttonText,
}: AddCommentFieldButtonProps) {
  return (
    <Button
      variant="blue"
      size="medium"
      onClick={onCommentClick}
      className="add-comment-field__comment-btn"
      disabled={disabled}
    >
      {buttonText ?? (
        <IDHFormattedMessage id="ws_comment" defaultMessage="Comment" />
      )}
    </Button>
  );
}

export default AddCommentFieldButton;
