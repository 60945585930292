export const mockedCoupons = [
  {
    uuid: "1",
    code: "1",
    wsTask: null,
    wsProject: null,
    isAssigned: false,
    hasAccess: null,
  },
  {
    uuid: "2",
    code: "2",
    wsTask: null,
    wsProject: null,
    isAssigned: false,
    hasAccess: null,
  },
  {
    uuid: "3",
    code: "3",
    wsTask: null,
    wsProject: null,
    isAssigned: false,
    hasAccess: null,
  },
  {
    uuid: "4",
    code: "4",
    wsTask: null,
    wsProject: null,
    isAssigned: false,
    hasAccess: null,
  },
  {
    uuid: "5",
    code: "5",
    wsTask: null,
    wsProject: null,
    isAssigned: false,
    hasAccess: null,
  },
];

export const mockedGroups = [
  {
    uuid: "1",
    name: "1",
    campaignCounter: 0,
    promoCodesCounter: 0,
  },
  {
    uuid: "2",
    name: "2",
    campaignCounter: 0,
    promoCodesCounter: 0,
  },
  {
    uuid: "3",
    name: "3",
    campaignCounter: 0,
    promoCodesCounter: 0,
  },
  {
    uuid: "4",
    name: "4",
    campaignCounter: 0,
    promoCodesCounter: 0,
  },
  {
    uuid: "5",
    name: "5",
    campaignCounter: 0,
    promoCodesCounter: 0,
  },
];
