import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import FilterOptions from "./FilterOptions";

function FiltersWrap({ handleCheckboxChange, selectedFilters, feed }) {
  const {
    selectDataSetReducer: { selectDataSetList },
  } = useSelector((state) => state);

  const getSelectTypeOptions = (options) => {
    if (options?.wsSelectDataSetUuid) {
      return selectDataSetList[`dataSetType_${options.wsSelectDataSetUuid}`];
    }
    if (options?.singleSelectOptions) {
      return options?.singleSelectOptions;
    }
    return [];
  };

  const getSelectTypeKey = (options) => {
    if (options?.wsSelectDataSetUuid) return "wsSelectDataSetUuid";
    return "singleSelectOptions";
  };

  const sortedFeed = useMemo(
    () =>
      feed.map((action) => ({
        ...action,
        metaFieldOptions: {
          ...action.metaFieldOptions,
          [getSelectTypeKey(action.metaFieldOptions)]:
            getSelectTypeOptions(action.metaFieldOptions).sort((a, b) => {
              if (a.outOfOrder && b.outOfOrder)
                return a.name?.localeCompare(b.name);
              if (a.outOfOrder) return -1;
              if (b.outOfOrder) {
                return 1;
              }

              const containsA = selectedFilters?.selections?.includes(a.value);
              const containsB = selectedFilters?.selections?.includes(b.value);
              if (containsA && containsB) return a.name?.localeCompare(b.name);
              if (containsA) return -1;
              if (containsB) {
                return 1;
              }
              return a.name?.localeCompare(b.name);
            }) || [],
        },
      })) || [],
    [selectedFilters, feed, selectDataSetList],
  );

  return (
    <>
      {sortedFeed.map((action, index) => (
        <div className="filter" key={`${action.name}-${index}`}>
          <div className="filter__title">{action.name}</div>
          <FilterOptions
            options={action.metaFieldOptions?.singleSelectOptions}
            selectedFilters={selectedFilters}
            handleCheckboxChange={handleCheckboxChange}
          />
        </div>
      ))}
    </>
  );
}

export default FiltersWrap;
