import React, { useState } from "react";
import "./BookDemoModal.scss";

import { injectIntl } from "react-intl";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { translateMessage } from "src/app/methods/translateMessage";
import Modal, { ModalTitle } from "../../components/Modal/Modal";
import BookDemoCalendarImg from "../../../images/book-demo-calendar.png";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { ReactComponent as WavingHand } from "../../../images/waving-hand.svg";
import Benelux from "../../../images/markets/benelux.png";
import Cee from "../../../images/markets/cee.png";
import Dach from "../../../images/markets/dach.png";
import Me from "../../../images/markets/me.png";
import Nordic from "../../../images/markets/nordic.png";
import Uk from "../../../images/markets/uk.png";
import Japac from "../../../images/markets/japac.png";
import Af from "../../../images/markets/af.png";
import Mena from "../../../images/markets/mena.png";
import Na from "../../../images/markets/na.png";
import Latam from "../../../images/markets/latam.png";
import OtherImg from "../../../images/markets/other.png";

function BookDemoModal(props) {
  const { onClose, intl } = props;

  const [selectedMarket, setSelectedMarket] = useState({});

  const options = [
    {
      value: "BENELUX",
      label: translateMessage({
        intl,
        id: "ws_book_demo_options_benelux",
        defaultMessage: "(BENELUX) Belgium, Netherlands, Luxemburg",
      }),
    },
    {
      value: "CEE",
      label: translateMessage({
        intl,
        id: "ws_book_demo_options_cee",
        defaultMessage: "(CEE) Central and Eastern Europe",
      }),
    },
    {
      value: "DACH",
      label: translateMessage({
        intl,
        id: "ws_book_demo_options_dach",
        defaultMessage: "(DACH) Germany, Austria, Switzerland",
      }),
    },
    {
      value: "ME",
      label: translateMessage({
        intl,
        id: "ws_book_demo_options_me",
        defaultMessage: "(ME) Mediterranean Europe",
      }),
    },
    {
      value: "NORDIC",
      label: translateMessage({
        intl,
        id: "ws_book_demo_options_nordic",
        defaultMessage: "(NORDIC) Denmark, Finland, Iceland, Norway, Sweden",
      }),
    },
    {
      value: "UK",
      label: translateMessage({
        intl,
        id: "ws_book_demo_options_uk",
        defaultMessage: "(UK) United Kingdom",
      }),
    },
    {
      value: "JAPAC",
      label: translateMessage({
        intl,
        id: "ws_book_demo_options_japac",
        defaultMessage: "(JAPAC) Japan and Asia Pacific",
      }),
    },
    {
      value: "AF",
      label: translateMessage({
        intl,
        id: "ws_book_demo_options_af",
        defaultMessage: "(AF) Africa",
      }),
    },
    {
      value: "MENA",
      label: translateMessage({
        intl,
        id: "ws_book_demo_options_mena",
        defaultMessage: "(MENA) Middle East and North Africa",
      }),
    },
    {
      value: "NA",
      label: translateMessage({
        intl,
        id: "ws_book_demo_options_na",
        defaultMessage: "(NA) North America",
      }),
    },
    {
      value: "LATAM",
      label: translateMessage({
        intl,
        id: "ws_book_demo_options_latam",
        defaultMessage: "(LATAM) Latin America",
      }),
    },
    {
      value: "OTHER",
      label: translateMessage({
        intl,
        id: "ws_book_demo_options_other_markets",
        defaultMessage: "Other markets",
      }),
    },
  ];

  const dataRelatedWithMarket = [
    {
      market: "BENELUX",
      avatar: Benelux,
      name: "James",
      link: "https://bit.ly/3LK8DLNf",
    },
    {
      market: "CEE",
      avatar: Cee,
      name: "James",
      link: "https://bit.ly/3LK8DLN",
    },
    {
      market: "DACH",
      avatar: Dach,
      name: "Patricia",
      link: "https://bit.ly/3LGPIBn",
    },
    {
      market: "ME",
      avatar: Me,
      name: "James",
      link: "https://bit.ly/3LK8DLN",
    },
    {
      market: "NORDIC",
      avatar: Nordic,
      name: "James",
      link: "https://bit.ly/3LK8DLN",
    },
    {
      market: "UK",
      avatar: Uk,
      name: "James",
      link: "https://bit.ly/3LK8DLN",
    },
    {
      market: "JAPAC",
      avatar: Japac,
      name: "Ashwin",
      link: "https://bit.ly/3Syo9fP",
    },
    {
      market: "AF",
      avatar: Af,
      name: "James",
      link: "https://bit.ly/3LK8DLN",
    },
    {
      market: "MENA",
      avatar: Mena,
      name: "James",
      link: "https://bit.ly/3LK8DLN",
    },
    {
      market: "NA",
      avatar: Na,
      name: "James",
      link: "https://bit.ly/3LK8DLN",
    },
    {
      market: "LATAM",
      avatar: Latam,
      name: "James",
      link: "https://bit.ly/3LK8DLN",
    },
  ];

  const findElementForMarket = (selectedMarket) => {
    return dataRelatedWithMarket.find((el) => el.market === selectedMarket);
  };

  const isAnyMarketSelected = Object.keys(selectedMarket).length > 0;
  const otherMarkets = selectedMarket.value === "OTHER";

  return (
    <Modal
      className="book-demo-modal"
      onClose={onClose}
      closeIcon
      confirmButtonText={
        <IDHFormattedMessage id="ws_next_step" defaultMessage="Next step" />
      }
      buttonCentered
      buttonsHidden={!isAnyMarketSelected || otherMarkets}
      onConfirmClick={() => {
        window.open(
          findElementForMarket(selectedMarket.value).link,
          "_blank",
          "noopener, noreferrer",
        );
      }}
    >
      <ModalTitle>
        <IDHFormattedMessage id="ws_book_a_demo" defaultMessage="Book a demo" />
      </ModalTitle>
      <CustomSelect
        value={selectedMarket}
        onChange={(newValue) => {
          setSelectedMarket(newValue);
        }}
        options={options}
        placeholder={translateMessage({
          intl,
          id: "ws_select_your_market",
          defaultMessage: "Select your market",
        })}
      />

      {isAnyMarketSelected ? (
        <>
          {otherMarkets ? (
            <div className="book-demo-modal__other-markets">
              <img
                src={OtherImg}
                alt="mail-illustration"
                className="book-demo-modal__other-markets-img"
              />
              <div className="book-demo-modal__other-markets-contact">
                <IDHFormattedMessage
                  id="ws_please_contact_sales_team"
                  defaultMessage="Please contact our Sales Team"
                />
                :
              </div>
              <div className="book-demo-modal__other-markets-mail">
                <a href="mailto:sales@indahash.com">sales@indahash.com</a>
              </div>
            </div>
          ) : (
            <>
              <img
                src={findElementForMarket(selectedMarket.value).avatar}
                alt="avatar"
                className="book-demo-modal__avatar"
              />
              <div className="book-demo-modal__contact-person">
                <IDHFormattedMessage id="ws_meet" defaultMessage="Meet" />
                &nbsp;
                <span>{findElementForMarket(selectedMarket.value).name}</span>
                &nbsp;
                <WavingHand />
              </div>
              <div className="book-demo-modal__text">
                <IDHFormattedMessage
                  id="ws_experts_happy_tell_product"
                  defaultMessage=" Our expert will be happy to tell you about our product and answer all \n your questions. Go to the next step to set a specific call date."
                />
              </div>
            </>
          )}
        </>
      ) : (
        <img
          src={BookDemoCalendarImg}
          alt="calendar"
          className="book-demo-modal__calendar-img"
        />
      )}
    </Modal>
  );
}

export default injectIntl(BookDemoModal);
