import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SectionHeader } from "../../../../components/Pane/SectionHeader/SectionHeader";
import { SectionRowWrapper } from "../../../../components/Pane/SectionRowWrapper/SectionRowWrapper";
import { StatisticsSync } from "../components/StaticticsSync/StatisticsSync";
import { StatisticList } from "../components/StatisticList/StatisticList";

function Statistics(props) {
  const {
    platform,
    platformDisplayName,
    statistics,
    synchronizable,
    synchronizationDate,
    taskDetailsLoading,
  } = props;

  return (
    <SectionRowWrapper>
      <div className="task-section-header-wrapper">
        <SectionHeader
          name={
            <IDHFormattedMessage
              id="ws_statistics"
              defaultMessage="Statistics"
            />
          }
        />
        {synchronizable && (
          <StatisticsSync
            synchronizationDate={synchronizationDate}
            taskDetailsLoading={taskDetailsLoading}
          />
        )}
      </div>
      <StatisticList
        statistics={statistics}
        platform={platform}
        platformDisplayName={platformDisplayName}
      />
    </SectionRowWrapper>
  );
}

export { Statistics };
