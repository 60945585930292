import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as FollowersIcon } from "../../../../../images/profile-data/followers.svg";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import ProfileBox, {
  PBContent,
  PBHeader,
  PBParam,
} from "../components/ProfileBox/ProfileBox";

export function Followers(props) {
  const { block, followers, notableUsers, paidPostPerformance, height } = props;

  const showContent = !height || height > 1;

  return (
    <ProfileBox block={block}>
      <PBHeader
        icon={<FollowersIcon />}
        count={followers}
        title={
          <IDHFormattedMessage id="ws_followers" defaultMessage="Followers" />
        }
        onShowcase={height}
      />
      {showContent && (
        <PBContent column>
          {notableUsers && (
            <PBParam
              name={
                <IDHFormattedMessage
                  id="ws_notable_users"
                  defaultMessage="Notable users"
                />
              }
              tooltip={
                <Tooltip
                  center
                  content={
                    <IDHFormattedMessage
                      id="ws_tooltip_notable_users"
                      defaultMessage="Notable users are those followers who have an account with at least 1000 followers"
                    />
                  }
                />
              }
              firstValue={`${notableUsers}%`}
              numberOfParams={1}
            />
          )}
          {paidPostPerformance && (
            <PBParam
              name={
                <IDHFormattedMessage
                  id="ws_performance_of_paid_posts"
                  defaultMessage="Performance of paid posts"
                />
              }
              tooltip={
                <Tooltip
                  center
                  content={
                    <IDHFormattedMessage
                      id="ws_tooltip_performance_of_paid_posts"
                      defaultMessage="Engagement rate of posts with #ad or 30+ similar tags vs engagement rate on normal posts"
                    />
                  }
                />
              }
              firstValue={`${paidPostPerformance}%`}
              numberOfParams={1}
            />
          )}
        </PBContent>
      )}
    </ProfileBox>
  );
}
