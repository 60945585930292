import React, { useState } from "react";
import "./SelectableLabels.scss";
import classNames from "classnames";
import { SingleSelectableLabel } from "./components/SingleSelectableLabel/SingleSelectableLabel";

export function SelectableLabels({
  feed,
  setValue,
  translative,
  selectDensity,
  disabled,
}) {
  const [lockScroll, setLockScroll] = useState(false);

  return (
    <div
      className={classNames("select-tags__selectable-labels", {
        "select-tags__selectable-labels--lock-scroll": lockScroll,
      })}
    >
      {feed?.map((f) => (
        <SingleSelectableLabel
          value={f}
          onClick={setValue}
          translative={translative}
          selectDensity={selectDensity}
          setLockScroll={setLockScroll}
          disabled={disabled}
        />
      ))}
    </div>
  );
}
