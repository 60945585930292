import React, { createRef, memo } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import axios from "axios";
import classNames from "classnames";

import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import _ from "lodash";
import { isObjectEmpty } from "src/app/pages/CreatorDiscoveryTool/utils/isObjectEmpty";
import Tabs from "src/app/components/Tabs/Tabs";
import Tab from "src/app/components/Tabs/Tab";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import { showToast } from "src/app/methods/showToast";
import { toast } from "react-toastify";
import { PageNotFound } from "src/app/components/PageNotFound/PageNotFound";
import { ReactComponent as ChevronUp } from "src/images/chevron-up-grey.svg";
import { ReactComponent as ChevronDown } from "src/images/chevron-down.svg";
import { ReactComponent as EditIcon } from "src/images/edit-blue.svg";
import {
  Breadcrumbs,
  BreadcrumbsItem,
} from "src/app/components/Breadcrumbs/Breadcrumbs";
import { downloadUrl } from "src/app/pages/CreatorDiscoveryTool/utils/downloadUrl";
import { getProfileFromLocation } from "src/app/pages/CreatorDiscoveryTool/utils/getProfileFromLocation";
import { stringifyFilters } from "src/app/pages/CreatorDiscoveryTool/utils/stringifyFilters";
import { GlobalTaskDetails } from "src/app/components/GlobalTaskDetails/GlobalTaskDetails";
import { isStringUuid } from "src/utils/methods";
import { getProjectsList, openTrialExceededModal } from "src/redux";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import {
  CreatorShowcaseContext,
  openCreatorShowcase,
} from "src/app/CreatorShowcase/utils";
import responseCodes from "src/utils/responseCodes";
import { ProfileDataPane } from "../../profile/ProfileDataPane/ProfileDataPane";
import { AudienceOverlapPane } from "../AudienceOverlapPane/AudienceOverlapPane";
import CreatorDiscoveryToolActionBar from "./CreatorDiscoveryToolActionBar";
import CrisisDetectionModal from "../CrisisDetectionModal/CrisisDetectionModal";
import AddCreatorsInfoModal from "../AddCreatorsInfoModal/AddCreatorsInfoModal";
import AddCreatorsModal from "../AddCreatorsModal/AddCreatorsModal";
import SearchLabels from "../SearchLabels/SearchLabels";
import AfterExportModal from "../../profile/AfterExportModal/AfterExportModal";
import urlQuery from "../../helpers/urlQuery";
import PreloaderContainer from "../../base/preloader/PreloaderContainer.jsx";
import METHODS from "../../helpers/methods";
import CONSTS from "../../helpers/consts";
import EditNameModal from "../EditNameModal/EditNameModal";
import CreateAudienceModal from "../CreateAudienceModal/CreateAudienceModal";
import AudiencesSearch from "../AudiencesSearch/AudiencesSearch";
import AudiencesResults from "../AudiencesResults/AudiencesResults";
import {
  GET_INFLUENCERS_LIST_DATA_FAIL,
  GET_INFLUENCERS_LIST_DATA_SUCCESS,
  addToAudiences,
  changeSearchAudienceFilters,
  clearAllSelectedUsers,
  clearAllSelectedUsersBothTypes,
  clearDisabledCountries,
  clearInfluencersListInfo,
  clearResults,
  clearSelectedUsers,
  createInfluencersList,
  deleteInfluencersFromList,
  editName,
  exportList,
  getInfluencersListData,
  refreshAudience,
  updateSearchFilters,
  loadMoreSearchResults,
  loadMoreSelectedInfluencers,
  POST_LOAD_MORE_SEARCH_RESULTS_SUCCESS,
  POST_LOAD_MORE_SELECTED_INFLUENCERS_SUCCESS,
  setAudienceLoading,
  setAudienceData,
} from "../audiencesManagerActions";

/*

  ################################################################
  #####################     READ THIS     ########################
  ################################################################


  IN THIS COMPONENT PAGE AND ACTIVE TAB VARIABLES SHOULD BE MANAGED VIA URL QUERY
  URL QUERY SHOULD BE ONE SOURCE OF TRUTH (BUT NOT EVERYWHERE I COULD ACHIEVE THAT)
  ALSO SORT IT PARTIALLY MANAGED BY URL, BUT IN THIS CASE I HAD TO HAVE DATA STRUCTURE
  FOR REACT-SELECT, BUT YOU SHOULD RALLY ON URL IN THIS CASE.

*/

export const applyFiltersBtnRef = createRef(null);
export const resultsHeaderRightRef = createRef(null);

class AudiencesResultsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.SORT_OPTIONS_INITIAL = [
      {
        value: "velocityIndex",
        label: this.props.intl.formatMessage({
          id: "front_sort_by_relevance",
          defaultMessage: "Relevance",
        }),
      },
      {
        value: "followers",
        label: this.props.intl.formatMessage({
          id: "front_sort_by_followers",
          defaultMessage: "Followers",
        }),
      },
      {
        value: "avgEr",
        label: this.props.intl.formatMessage({
          id: "front_sort_by_avg_er",
          defaultMessage: "Avg. ER",
        }),
      },
    ];

    this.state = {
      cancelToken: null,
      page: 1,
      listLoading: false,
      listInit: false,
      loader: false,
      notFound: false,
      showFilters: false,
      activeTab: this.getQueryParam("activeTab") || "results",
      searchFilters: null,
      showCreateAudienceModal: false,
      showEditNameModal: false,
      showDeleteUserModal: false,
      listId: null,
      selectedInfluencers: [],
      selectedInfluencersUrls: [],
      sortBy: null,
      showAfterExportModal: false,
      showToast: true,
      confirmButtonLoading: false,
      showAddCreatorsModal: false,
      showAddCreatorsInfoModal: false,
      showBrandSafety: false,
      showCrisisDetectionModal: false,
      showAudienceOverlapPane: false,
      applyFiltersClicked: false,
      SORT_OPTIONS: this.SORT_OPTIONS_INITIAL,
      SORT_OPTIONS_SELECTED: [
        ...this.SORT_OPTIONS_INITIAL,
        {
          value: "recentlyAdded",
          label: this.props.intl.formatMessage({
            id: "front_sort_by_recently_added",
            defaultMessage: "Recently added",
          }),
        },
      ],
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { listId },
      },
      influencersInListInfo,
      clearResults,
    } = this.props;

    const sortByFromUrl = this.getQueryParam("sortBy");
    const activeTabFromUrl = this.getQueryParam("activeTab");

    this.setQueryParams([
      {
        key: "sortBy",
        value: sortByFromUrl || this.state.SORT_OPTIONS[0].value,
      },
      {
        key: "activeTab",
        value: activeTabFromUrl || "results",
      },
    ]);

    this.syncFilters();
    this.setState({
      loader: true,
      // showFilters: false,
    });
    this.props.getProjectsList(this.props.activeWorkspaceUuid);

    if (listId) {
      this.setState({
        listId,
      });

      // new list => get data from backend
      if (influencersInListInfo?.listId !== listId) {
        this.props.clearAllSelectedUsersBothTypes();
        this.getListData(() => {
          this.setState({
            loader: false,
          });
        });
      } else {
        // last visited list => get data from redux store
        this.setState({
          selectedInfluencers:
            activeTabFromUrl === "results"
              ? this.props.selectedInfluencersOnResults
              : this.props.selectedInfluencersOnList,
          selectedInfluencersUrls:
            activeTabFromUrl === "results"
              ? this.props.selectedInfluencersOnResultsUrls
              : this.props.selectedInfluencersOnListUrls,
          loader: false,
        });
      }
    } else {
      // results of search without saved list
      if (!Object.keys(this.props.searchAudienceFilters).length) {
        // if Filters are empty then redirect to search page, where they are initialized
        this.props.history.replace({
          pathname: `/workspace/${this.props.activeWorkspaceUuid}/creator-discovery-tool/search${getProfileFromLocation()}`,
          search: window.location.search.toString(),
        });
      } else {
        this.setState({
          selectedInfluencers:
            activeTabFromUrl === "results"
              ? this.props.selectedInfluencersOnResults
              : this.props.selectedInfluencersOnList,
          selectedInfluencersUrls:
            activeTabFromUrl === "results"
              ? this.props.selectedInfluencersOnResultsUrls
              : this.props.selectedInfluencersOnListUrls,
          loader: false,
        });

        // clearResults();
        this.clearAllSelected();
        this.props.clearInfluencersListInfo();
        this.showToast();
        this.searchAudience().then(() => {
          this.setState({
            loader: false,
          });
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.listSocialProvider !== this.props.listSocialProvider) {
      this.setState({
        SORT_OPTIONS: this.filterSortOptions(this.props.listSocialProvider),
      });
    }
    const isProfile =
      this.props.location.pathname.includes("/profile/") ||
      prevProps.location.pathname.includes("/profile/");
    if (
      prevProps.selectedInfluencersOnResults !==
        this.props.selectedInfluencersOnResults ||
      prevProps.selectedInfluencersOnList !==
        this.props.selectedInfluencersOnList ||
      prevState.activeTab !== this.state.activeTab
    ) {
      // properly set selectedInfluencers variable depends on activeTab
      this.setState({
        selectedInfluencers:
          this.state.activeTab === "results"
            ? this.props.selectedInfluencersOnResults
            : this.props.selectedInfluencersOnList,
        selectedInfluencersUrls:
          this.state.activeTab === "results"
            ? this.props.selectedInfluencersOnResultsUrls
            : this.props.selectedInfluencersOnListUrls,
      });
    }

    const prevParams = new URLSearchParams(prevProps.location.search);
    const newParams = new URLSearchParams(this.props.location.search);
    prevParams.delete("view");
    newParams.delete("view");
    const prevLocation = prevProps.location.pathname + prevParams.toString();
    const newLocation = this.props.location.pathname + newParams.toString();

    if (prevLocation !== newLocation) {
      const sortByFromUrl = this.getQueryParam("sortBy");
      const pageFromUrl = this.getQueryParam("page");
      const pageSelectedFromUrl = this.getQueryParam("pageSelected");
      const activeTabFromUrl = this.getQueryParam("activeTab");

      this.setState({
        sortBy: sortByFromUrl
          ? this.state.SORT_OPTIONS_SELECTED.filter(
              (item) => item.value === sortByFromUrl,
            )[0]
          : this.state.SORT_OPTIONS[0],
        page: pageFromUrl || this.state.page,
        pageSelected: pageSelectedFromUrl || this.state.pageSelected,
        activeTab: activeTabFromUrl || this.state.activeTab,
      });

      if (activeTabFromUrl !== "selected" && !isProfile) {
        this.setState({ page: 1 });
        this.searchAudience();
      }
    }

    if (
      Object.keys(prevProps.searchAudienceFilters).length === 1 &&
      Object.keys(this.props.searchAudienceFilters).length === 20
    ) {
      this.syncFilters();
    }
  }

  // componentWillUnmount() {
  //   this.props.clearInfluencersListInfo();
  // }

  getQueryParam = (key) => {
    const { location } = this.props;
    return urlQuery.getParam(location.search, key);
  };

  setQueryParam = (key, value) => {
    const { location } = this.props;
    const queryString = urlQuery.setParam(location.search, key, value);

    this.changeQueryString(`?${queryString}`);
  };

  setQueryParams = (array) => {
    const { location } = this.props;
    let queryString = location.search;
    array.forEach((item) => {
      queryString = `?${urlQuery.setParam(queryString, item.key, item.value)}`;
    });
    this.changeQueryString(queryString);
  };

  changeQueryString = (queryString) => {
    const { history } = this.props;

    history.replace(window.location.pathname + queryString);
  };

  getListData = async (callbackFunction) => {
    const {
      match: {
        params: { listId },
      },
      getInfluencersListData,
    } = this.props;

    getInfluencersListData(
      listId,
      this.getQueryParam("pageSelected"),
      this.getQueryParam("sortBy"),
    ).then(async (res) => {
      if (res.type === GET_INFLUENCERS_LIST_DATA_SUCCESS) {
        this.syncFilters();

        await this.searchAudience();

        if (callbackFunction) {
          callbackFunction();
        }
      } else if (res.type === GET_INFLUENCERS_LIST_DATA_FAIL) {
        this.setState({ loader: false, notFound: true });
      }
    });
  };

  refreshListDataByPagination = async (
    callbackFunction,
    page,
    sortBy,
    refreshSearchResults = true,
  ) => {
    const {
      match: {
        params: { listId },
      },
      getInfluencersListData,
    } = this.props;

    getInfluencersListData(
      listId,
      page,
      sortBy ?? this.getQueryParam("sortBy"),
    ).then(async (res) => {
      if (res.type === GET_INFLUENCERS_LIST_DATA_SUCCESS) {
        if (refreshSearchResults) {
          this.syncFilters();

          await this.searchAudience();
        }

        if (callbackFunction) {
          callbackFunction();
        }
      } else if (res.type === GET_INFLUENCERS_LIST_DATA_FAIL) {
        this.setState({ loader: false, notFound: true });
      }
    });
  };

  refreshListData = async () => {
    await this.refreshAudience();
  };

  getMoreResults = async (endOfList) => {
    if (this.state.listLoading || endOfList) return;
    this.setState({ listLoading: true });

    const sortByValue = this.getQueryParam("sortBy");

    if (this.state.activeTab === "results") {
      // I moved this construct because we need pagination to compare requests
      const queryData = {
        searchFilters: METHODS.parseAudienceSearchToRequest({
          ...this.props.searchAudienceFilters,
          currentListId: this.props.searchAudienceFilters
            ?.excludeCreatorsFromList
            ? this.state.listId
            : null,
        }),
        pagination: {
          page: parseInt(this.state.page) + 1,
          onPage: 20,
          orderBy: sortByValue || "velocityIndex",
          order: "desc",
        },
      };
      this.props.loadMoreSearchResults(queryData).then((r) => {
        r.type === POST_LOAD_MORE_SEARCH_RESULTS_SUCCESS
          ? this.setState({
              page: parseInt(this.state.page) + 1,
              listLoading: false,
            })
          : this.setState({
              listLoading: false,
            });
      });
    } else {
      const {
        match: {
          params: { listId },
        },
        loadMoreSelectedInfluencers,
      } = this.props;

      loadMoreSelectedInfluencers(
        listId,
        parseInt(this.state.page) + 1,
        this.getQueryParam("sortBy"),
      ).then(async (res) => {
        res.type === POST_LOAD_MORE_SELECTED_INFLUENCERS_SUCCESS
          ? this.setState({
              page: parseInt(this.state.page) + 1,
              listLoading: false,
            })
          : this.setState({
              listLoading: false,
            });
        // if (res.type === GET_INFLUENCERS_LIST_DATA_SUCCESS) {
        //   this.syncFilters();

        //   await this.searchAudience();
        // } else if (res.type === GET_INFLUENCERS_LIST_DATA_FAIL) {
        //   this.setState({ loader: false, notFound: true });
        // }
      });
    }

    this.setState({
      applyFiltersClicked: false,
    });
  };

  searchAudience = async (
    page = this.getQueryParam("page"),
    sortByValue = this.getQueryParam("sortBy"),
    forceRequest = false,
  ) => {
    this.setState({ listLoading: true });
    // I moved this construct because we need pagination to compare requests
    const queryData = {
      searchFilters: METHODS.parseAudienceSearchToRequest({
        ...this.props.searchAudienceFilters,
        currentListId: this.state?.listId ?? null,
      }),
      pagination: {
        page: parseInt(1),
        onPage: 20,
        orderBy: sortByValue || "velocityIndex",
        order: "desc",
      },
    };
    // before sending request lets check if we already have answer for this data
    if (
      forceRequest ||
      (!isObjectEmpty(this.props.searchAudienceFilters) &&
        JSON.stringify(queryData) !== this.props.recentSearchAudienceQuery)
    ) {
      const { setAudienceData, setAudienceLoading } = this.props;

      const source = axios.CancelToken.source();

      this.setState({
        cancelToken: source,
      });

      setAudienceLoading(true);

      axios
        .post(CONSTS.searchAudience, queryData, {
          headers: {
            "Workspace-Uuid": METHODS.getWorkspaceUuidFromCurrentUrl(),
          },
          cancelToken: source.token,
        })
        .then((res) => {
          if (res.data.status_code === responseCodes["200_OK"]) {
            setAudienceData(res.data.content);
          }
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            const { response } = error;
            if (response.status === responseCodes["402_PAYMENT_REQUIRED"]) {
              this.props.openTrialExceededModal();
            }
          }
          console.error(error);
        })
        .finally(() => {
          setAudienceLoading(false);
          this.setState({
            cancelToken: null,
          });
        });

      this.setState({
        applyFiltersClicked: false,
        listLoading: false,
      });
    }
  };

  refreshAudience = async (page, sortByValue) => {
    const pageFromUrl = this.getQueryParam("page");
    const sortByFromUrl = this.getQueryParam("sortBy");

    const pageToSearch = page || pageFromUrl;
    const sortByToSearch = sortByValue || sortByFromUrl;

    this.props.refreshAudience(
      {
        ...this.props.searchAudienceFilters,
        currentListId: this.props.searchAudienceFilters?.excludeCreatorsFromList
          ? this.state.listId
          : null,
      },
      pageToSearch,
      sortByToSearch,
    );
  };

  syncFilters = () => {
    const { searchAudienceFilters } = this.props;
    this.setState({
      searchFilters: { ...searchAudienceFilters },
    });
  };

  tabChange = (tab) => {
    if (tab === "results") {
      this.setQueryParams([
        {
          key: "sortBy",
          value: "velocityIndex",
        },
        {
          key: "activeTab",
          value: tab,
        },
      ]);
    } else {
      this.setQueryParam("activeTab", tab);
    }
    this.setState({ page: 1 });
  };

  toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    });
  };

  closeFilters = () => {
    this.setState({ showFilters: false });
  };

  applyFilters = async (tagPropsName) => {
    // If there is ongoing request - cancel it
    const { cancelToken } = this.state;
    if (cancelToken) {
      cancelToken.cancel();
    }

    const {
      searchAudienceFilters,
      updateSearchFilters,
      getInfluencersListData,
      clearAllSelectedUsers,
    } = this.props;
    const { listId } = this.state;

    this.setState({
      applyFiltersClicked: true,
    });

    const isSocialUsernameInputEmpty =
      !searchAudienceFilters.influencerSocialUsername;

    if (
      listId &&
      isSocialUsernameInputEmpty &&
      tagPropsName !== "influencerSocialUsername"
    ) {
      await updateSearchFilters(listId, searchAudienceFilters);
      await getInfluencersListData(listId);
    }

    this.setQueryParam("filters", stringifyFilters(searchAudienceFilters));
    this.syncFilters();
    this.toggleFilters();

    clearAllSelectedUsers("results");
  };

  clearFilters = async () => {
    this.setState({
      showFilters: false,
    });
    await this.props.changeSearchAudienceFilters({
      influencerSocialProvider: {
        value: this.state.searchFilters.influencerSocialProvider.value,
        label: this.state.searchFilters.influencerSocialProvider.label,
      },
    });
    await this.syncFilters();
    await this.props.clearDisabledCountries();
    this.setState({
      showFilters: true,
    });
  };

  emptyFilters = () => {
    const { searchFilters } = this.state;
    const { searchAudienceFilters } = this.props;

    if (!searchFilters || !searchAudienceFilters) return true;

    let isEmpty = true;
    const searchFiltersKeys = Object.keys(searchFilters);

    for (let i = 0; i < searchFiltersKeys.length; i++) {
      const searchField = searchFilters[searchFiltersKeys[i]];
      if (Array.isArray(searchField)) {
        if (searchField.length > 0) {
          isEmpty = false;
          break;
        }
      } else {
        if (searchField !== null) {
          isEmpty = false;
          break;
        }
      }
    }

    const searchAudienceFiltersKeys = Object.keys(searchAudienceFilters);
    if (isEmpty) {
      for (let i = 0; i < searchAudienceFiltersKeys.length; i++) {
        const searchField = searchAudienceFilters[searchAudienceFiltersKeys[i]];

        if (Array.isArray(searchField)) {
          if (searchField.length > 0) {
            isEmpty = false;
            break;
          }
        } else {
          if (searchField !== null) {
            isEmpty = false;
            break;
          }
        }
      }
    }
    return isEmpty;
  };

  createAudience = () => {
    this.setState({
      showCreateAudienceModal: true,
    });
  };

  closeCreateAudienceModal = () => {
    this.setState({
      showCreateAudienceModal: false,
    });
  };

  showEditNameModal = () => {
    this.setState({
      showEditNameModal: true,
    });
  };

  filterSortOptions = (listSocialProvider) => {
    if (listSocialProvider === CONSTS.socialProvider.snapchat) {
      return this.SORT_OPTIONS_INITIAL.filter(
        (option) => option.value !== "avgEr",
      );
    }
    return this.SORT_OPTIONS_INITIAL;
  };

  confirmCreateAudience = (name) => {
    if (name.length > CONSTS.audienceListNameMaxLength) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="front_audience_name_too_long"
          defaultMessage="Please use up to 300 characters"
        />,
      );
    } else {
      this.setState({
        confirmButtonLoading: true,
      });

      this.props
        .createAudiencesList(
          this.state.searchFilters,
          this.state.selectedInfluencers,
          name,
        )
        .then((data) => {
          if (data.error) {
            METHODS.responseErrorHandler(data);
          } else {
            const {
              status_code: statusCode,
              content: { listId },
            } = data?.payload?.data;
            if (statusCode === 200) {
              this.clearAllSelected();
              this.setState({
                showCreateAudienceModal: false,
                listId,
              });
              this.props.history.push(
                `/workspace/${this.props.activeWorkspaceUuid}/creator-discovery-tool/list/${listId}`,
              );

              this.getListData(() => {
                this.tabChange("selected");
              });
            }
          }

          this.setState({
            confirmButtonLoading: false,
          });
        });
    }
  };

  closeEditNameModal = () => {
    this.setState({
      showEditNameModal: false,
    });
  };

  confirmEditName = async (name) => {
    this.setState({
      confirmButtonLoading: true,
    });

    if (name.length > CONSTS.audienceListNameMaxLength) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="front_audience_name_too_long"
          defaultMessage="Please use up to 300 characters"
        />,
      );
      this.setState({
        confirmButtonLoading: false,
      });
    } else {
      const { listId } = this.state;

      await this.props.editName(name, listId).then((data) => {
        if (data.error) {
          METHODS.responseErrorHandler(data);
        } else {
          if (data?.payload?.data.status_code === 200) {
            this.setState({
              showEditNameModal: false,
            });
          }
        }
      });

      this.setState({
        confirmButtonLoading: false,
      });
    }
  };

  closeDeleteUserModal = () => {
    this.setState({
      showDeleteUserModal: false,
    });
  };

  closeAddCreatorsModal = () => {
    this.setState({
      showAddCreatorsModal: false,
    });
  };

  closeAddCreatorsInfoModal = () => {
    this.setState({
      showAddCreatorsInfoModal: false,
    });
  };

  showAddCreatorsInfoModal = () => {
    this.setState({
      showAddCreatorsInfoModal: true,
    });
  };

  toggleBrandSafetyVisibility = () => {
    this.setState((prev) => ({ showBrandSafety: !prev.showBrandSafety }));
  };

  toggleCrisisDetectionModalVisibility = () => {
    this.setState((prev) => ({
      showCrisisDetectionModal: !prev.showCrisisDetectionModal,
    }));
  };

  deleteAudience = () => {
    this.setState({
      showDeleteUserModal: true,
    });
  };

  confirmDeleteUser = () => {
    const { deleteInfluencersFromList, getInfluencersListData } = this.props;
    const { listId, selectedInfluencers, searchFilters } = this.state;

    this.setState({
      confirmButtonLoading: true,
    });

    deleteInfluencersFromList(selectedInfluencers, listId).then(() => {
      getInfluencersListData(listId);
      this.closeDeleteUserModal();

      // refresh search result after add audience if checkbox exclude creators is enable
      if (searchFilters && searchFilters.excludeCreatorsFromList) {
        this.searchAudience(
          this.getQueryParam("page"),
          this.getQueryParam("sortBy"),
          true,
        ).then(() => {
          this.setState({
            loader: false,
          });
        });
      }

      this.setState({
        confirmButtonLoading: false,
      });
    });
  };

  confirmAddCreators = () => {};

  addToAudience = async () => {
    const { addToAudience, getInfluencersListData } = this.props;
    const { listId, selectedInfluencers, searchFilters } = this.state;

    await addToAudience(selectedInfluencers, listId);

    // refresh search result after add audience if checkbox exclude creators is enable
    if (searchFilters && searchFilters.excludeCreatorsFromList) {
      this.searchAudience(
        this.getQueryParam("page"),
        this.getQueryParam("sortBy"),
        true,
      ).then(() => {
        this.setState({
          loader: false,
        });
      });
    }

    this.clearAllSelected();

    await getInfluencersListData(listId);
  };

  hasFiltersChanged = () => {
    const { searchFilters: stateFilters } = this.state;
    const { searchAudienceFilters: propsFilters } = this.props;

    let isEqual = true;
    const keys = Object.keys(propsFilters);

    for (let i = 0; i < keys.length; i++) {
      const keyName = keys[i];

      if (stateFilters && Array.isArray(stateFilters[keyName])) {
        if (!_.isEqual(stateFilters[keyName], propsFilters[keyName] || [])) {
          isEqual = false;
          break;
        }
      } else if (
        stateFilters &&
        typeof propsFilters[keyName] === "string" &&
        stateFilters[keyName] != propsFilters[keyName]
      ) {
        // socialUsername input
        isEqual = false;
        break;
      } else if (
        stateFilters &&
        typeof propsFilters[keyName] === "boolean" &&
        stateFilters[keyName] !== propsFilters[keyName]
      ) {
        isEqual = false;
        break;
      } else {
        if (
          stateFilters &&
          stateFilters[keyName]?.value != propsFilters[keyName]?.value
        ) {
          isEqual = false;
          break;
        }
      }
    }

    return !isEqual;
  };

  hasSocialProviderChanged = () => {
    const { searchFilters: stateFilters } = this.state;
    const { searchAudienceFilters: propsFilters } = this.props;

    return (
      stateFilters.influencerSocialProvider.value !==
      propsFilters.influencerSocialProvider.value
    );
  };

  exportList = async (type) => {
    const { activeTab, listId, selectedInfluencers } = this.state;
    const {
      influencersInList,
      influencersInListInfo,
      influencersInListUuids,
      searchAudienceResult,
      searchAudienceFilters,
      similarProfiles,
      exportList,
    } = this.props;

    if (type === "xls") {
      const response = await axios.post(
        CONSTS.exportListXls,
        {
          userIds: selectedInfluencers,
          listId,
          totalUsers: influencersInList.length,
          tabName:
            activeTab === "results"
              ? "search results"
              : influencersInListInfo?.listName,
        },
        {
          responseType: "blob",
        },
      );

      const contentDisposition = response.headers["content-disposition"];
      const filenameMatch =
        contentDisposition &&
        contentDisposition.match(/filename\s*=\s*"(.+)"/i);
      const filename = filenameMatch ? filenameMatch[1] : "creators.xlsx";

      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (type === "pdf") {
      const search = window.location.search.substring(1);

      if (activeTab === "selected") {
        const ids = selectedInfluencers.map((id) => influencersInListUuids[id]);

        if (ids.length) {
          openCreatorShowcase({
            history: this.props.history,
            context: CreatorShowcaseContext.CreatorDiscoveryList,
            creatorId: ids[0],
            ids,
            workspaceId: this.props.activeWorkspaceUuid,
            search,
          });
        }
      } else {
        const ids = selectedInfluencers.map(
          (id) =>
            [...searchAudienceResult, ...similarProfiles].find(
              (item) => item.userId === id,
            ).profileUrl,
        );
        if (ids.length) {
          openCreatorShowcase({
            history: this.props.history,
            context: CreatorShowcaseContext.CreatorDiscoverySearch,
            creatorId: ids[0],
            ids,
            workspaceId: this.props.activeWorkspaceUuid,
            search,
          });
        }
      }
    } else {
      exportList(type, selectedInfluencers, searchAudienceFilters).then(
        (data) => {
          if (data?.payload?.status === 200) {
            this.setState({
              showAfterExportModal: true,
            });
          }
        },
      );
    }
  };

  hideAfterExportModal = () => {
    this.setState({
      showAfterExportModal: false,
    });
  };

  clearAllSelected = () => {
    this.props.clearAllSelectedUsers(this.state.activeTab);
  };

  sortByChanged = (newValue) => {
    this.setState({
      sortBy: newValue,
    });

    this.setQueryParams([
      {
        key: "sortBy",
        value: newValue?.value,
      },
      // {
      //   key: "page",
      //   value: 1,
      // },
    ]);
  };

  afterRemoveCallback = async (tagPropsName) => {
    await this.applyFilters(tagPropsName);
    this.setState({ showFilters: false });
  };

  showToast = () => {
    const wasSeen = JSON.parse(
      window.localStorage.getItem("influencerDiscoveryFirstTimeSearched"),
    );

    if (!wasSeen) {
      toast.info(
        <div onClick={() => this.setState({ showFilters: true })}>
          <IDHFormattedMessage
            id="front_toast_too_many_results"
            defaultMessage="Too many search results?"
          />
          <br />
          <br />
          <IDHFormattedMessage
            id="front_toast_use_filters"
            defaultMessage="Use filters to refine your search results."
          />
        </div>,
      );

      window.localStorage.setItem(
        "influencerDiscoveryFirstTimeSearched",
        JSON.stringify(true),
      );
    }
  };

  showAudienceOverlapPane = () => {
    this.setState({
      showAudienceOverlapPane: true,
    });
  };

  closeAudienceOverlapPane = () => {
    this.setState({
      showAudienceOverlapPane: false,
    });
  };

  setSelectedInfluencers = (ids) => {
    this.setState({
      selectedInfluencers: ids,
    });
  };

  render() {
    const {
      activeTab,
      showFilters,
      searchFilters,
      showCreateAudienceModal,
      showEditNameModal,
      showDeleteUserModal,
      listId,
      selectedInfluencers,
      selectedInfluencersUrls,
      loader,
      notFound,
      sortBy,
      confirmButtonLoading,
      showAfterExportModal,
      showAddCreatorsModal,
      showAddCreatorsInfoModal,
      showBrandSafety,
      showCrisisDetectionModal,
      showAudienceOverlapPane,
    } = this.state;
    const {
      activeWorkspaceUuid,
      searchAudienceInfo,
      recentSearchAudienceQuery,
      influencersInList,
      influencersInListInfo,
      influencersInListUuids,
      searchAudienceResult,
      similarProfiles,
      listSocialProvider,
      awaitingUsers,
    } = this.props;

    const isIndaList = influencersInListInfo?.listName?.length;
    const query = new URLSearchParams(window.location.search);
    const showGlobalTask = isStringUuid(query.get("displayGlobalTask") || "");

    return (
      <div
        className={classNames(
          "audience-results-page",
          `audience-results-page--${activeTab}`,
        )}
      >
        {loader ? (
          <PreloaderContainer />
        ) : notFound ? (
          <PageNotFound />
        ) : (
          <>
            <div
              className={classNames("audience-results-page__header", {
                "audience-results-page__header--sticky":
                  showFilters && activeTab === "results",
              })}
            >
              <div className="audience-results-page__header__topbar">
                <div className="audiences-results-page__header__topbar--left">
                  {isIndaList ? (
                    <Breadcrumbs>
                      <BreadcrumbsItem
                        text={
                          <IDHFormattedMessage
                            id="front_breadcrumbs_creator_lists"
                            defaultMessage="Creator lists"
                          />
                        }
                        to={`/workspace/${activeWorkspaceUuid}/creator-discovery-tool/lists`}
                      />
                    </Breadcrumbs>
                  ) : (
                    <div className="audience-results-page__header__breadcrumbs--empty" />
                  )}

                  {influencersInListInfo?.listName?.length ? (
                    <div className="audience-name">
                      <div
                        className="audience-name--left"
                        onClick={this.showEditNameModal}
                      >
                        {METHODS.limitListName(influencersInListInfo?.listName)}
                        <EditIcon className="audience-name--left-icon" />
                      </div>
                      <div className="audience-name--right" />
                    </div>
                  ) : (
                    <div className="audience-name--empty">
                      <div>
                        <h2 className="audience-name--left-heading">
                          <IDHFormattedMessage
                            id="ws_creator_search"
                            defaultMessage="Creator Search"
                          />
                        </h2>
                        <h6 className="audience-name--left-description">
                          <IDHFormattedMessage
                            id="ws_creator_search_description"
                            defaultMessage="Here you can search for creators and analyze their social metrics."
                          />
                        </h6>
                      </div>
                    </div>
                  )}
                </div>

                <div className="audience-results-page__header__topbar--right">
                  <div
                    className="audience-results-page__header__topbar--right__top"
                    ref={resultsHeaderRightRef}
                  />
                  <div className="audience-results-page__buttons">
                    {activeTab === "results" && (
                      <>
                        <Button
                          size="medium"
                          variant={showFilters ? "light-blue" : "blue"}
                          onClick={this.toggleFilters}
                          dataAttr="cdt-show-filters"
                          className="audience-results-page__buttons__filters"
                        >
                          {!showFilters ? (
                            <>
                              <IDHFormattedMessage
                                id="front_open_filters"
                                defaultMessage="Open filters"
                              />
                              <ChevronDown className="toggle-filters-icon toggle-filters-icon--down" />
                            </>
                          ) : (
                            <>
                              <IDHFormattedMessage
                                id="front_close_filters"
                                defaultMessage="Close filters"
                              />
                              <ChevronUp className="toggle-filters-icon toggle-filters-icon--up" />
                            </>
                          )}
                        </Button>
                        {showFilters && this.hasFiltersChanged() && (
                          <Button
                            size="medium"
                            variant="green"
                            onClick={this.applyFilters}
                            id="apply-filters-btn"
                            buttonRef={applyFiltersBtnRef}
                          >
                            <IDHFormattedMessage
                              id="front_apply_filters"
                              defaultMessage="Apply filters"
                            />
                          </Button>
                        )}

                        {!listId && !this.emptyFilters() && (
                          <Button
                            size="medium"
                            variant="transparent"
                            onClick={this.clearFilters}
                          >
                            <IDHFormattedMessage
                              id="front_clear_filters"
                              defaultMessage="Clear filters"
                            />
                          </Button>
                        )}
                      </>
                    )}
                    <div
                      className={classNames("sort-wrapper", {
                        "sort-wrapper--in-tabs":
                          !showFilters || activeTab === "selected",
                      })}
                    >
                      <div className="idh-label">
                        <IDHFormattedMessage
                          id="front_sort_by"
                          defaultMessage="Sort by"
                        />
                        :
                      </div>
                      <CustomSelect
                        placeholder={this.props.intl.formatMessage({
                          id: "front_select",
                          defaultMessage: "Select",
                        })}
                        value={this.state.sortBy}
                        options={
                          activeTab === "results"
                            ? this.state.SORT_OPTIONS
                            : this.state.SORT_OPTIONS_SELECTED
                        }
                        onChange={(newValue) => this.sortByChanged(newValue)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="audience-results-page__tabs-wrapper">
                <div className="audience-results-page__tabs">
                  <Tabs>
                    <Tab
                      tabText={
                        <IDHFormattedMessage
                          id="front_search_results"
                          defaultMessage="Search results"
                        />
                      }
                      count={
                        searchAudienceInfo?.totalResults >= 10000
                          ? "9999+"
                          : searchAudienceInfo?.totalResults
                      }
                      active={activeTab === "results"}
                      onClick={() => this.tabChange("results")}
                      big
                      noClickable={!listId}
                    />
                    {listId && (
                      <Tab
                        tabText={
                          <IDHFormattedMessage
                            id="front_selected_influencers"
                            defaultMessage="Selected influencers"
                          />
                        }
                        count={influencersInListInfo?.totalResults ?? 0}
                        active={activeTab === "selected"}
                        onClick={() => this.tabChange("selected")}
                        big
                      />
                    )}
                  </Tabs>
                </div>
              </div>

              <CreatorDiscoveryToolActionBar
                activeTab={activeTab}
                selectedInfluencers={selectedInfluencers}
                listId={listId}
                influencersInList={influencersInList}
                influencersInListInfo={influencersInListInfo}
                searchAudienceResult={searchAudienceResult}
                listSocialProvider={listSocialProvider}
                showBrandSafety={showBrandSafety}
                toggleBrandSafetyVisibility={this.toggleBrandSafetyVisibility}
                exportList={this.exportList}
                clearAllSelected={this.clearAllSelected}
                addToAudience={this.addToAudience}
                createAudience={this.createAudience}
                deleteAudience={this.deleteAudience}
                toggleCrisisDetectionModalVisibility={
                  this.toggleCrisisDetectionModalVisibility
                }
                showAudienceOverlapPane={this.showAudienceOverlapPane}
                hasAccessToInfluencerInsightsAudienceOverlap={
                  this.props.hasAccessToInfluencerInsightsAudienceOverlap
                }
                hasAccessToInfluencerInsightsBrandSafety={
                  this.props.hasAccessToInfluencerInsightsBrandSafety
                }
                selectedInfluencersUrls={selectedInfluencersUrls}
                setShowAddCreatorsModal={(v) =>
                  this.setState({
                    showAddCreatorsModal: v,
                  })
                }
                refreshAudience={this.refreshAudience}
              />
            </div>

            {showFilters && activeTab === "results" && (
              <AudiencesSearch preSelect={searchFilters} listId={listId} />
            )}
            <div className="sort-and-labels-wrapper">
              <div
                className={classNames("sort-wrapper", {
                  "sort-wrapper--filters-opened":
                    showFilters && activeTab === "results",
                })}
              >
                <div className="idh-label">
                  <IDHFormattedMessage
                    id="front_sort_by"
                    defaultMessage="Sort by"
                  />
                  :
                </div>
                <CustomSelect
                  placeholder={this.props.intl.formatMessage({
                    id: "front_select",
                    defaultMessage: "Select",
                  })}
                  value={this.state.sortBy}
                  options={
                    activeTab === "results"
                      ? this.state.SORT_OPTIONS
                      : this.state.SORT_OPTIONS_SELECTED
                  }
                  onChange={(newValue) => this.sortByChanged(newValue)}
                />
              </div>
              {searchFilters && activeTab === "results" && (
                <SearchLabels
                  afterRemoveCallback={this.afterRemoveCallback}
                  searchFilters={searchFilters}
                  closeFilters={this.closeFilters}
                />
              )}
            </div>
            <AudiencesResults
              selectedInterest={searchFilters?.chosenTopics || []}
              activeTab={activeTab}
              page={this.state.page}
              userList={
                activeTab === "selected"
                  ? influencersInList
                  : searchAudienceResult
              }
              infoData={
                activeTab === "selected"
                  ? influencersInListInfo
                  : searchAudienceInfo
              }
              similarProfiles={similarProfiles}
              searchData={searchFilters}
              getMoreResults={this.getMoreResults}
              sortBy={sortBy}
              getListData={
                activeTab === "selected"
                  ? this.refreshListDataByPagination
                  : this.refreshListData
              }
              tabChange={this.tabChange}
              setQueryParam={this.setQueryParam}
              listId={listId}
              listLoading={this.state.listLoading}
              listInit={this.state.listInit}
            />

            {/* {similarProfiles.length && activeTab === "results" ? (
              <>
                <div className="audience-results-page__tabs-wrapper">
                  <div className="audience-results-page__tabs">
                    <Tabs>
                      <Tab
                        tabText={
                          <IDHFormattedMessage
                            id="front_similar_profiles"
                            defaultMessage="Similar profiles"
                          />
                        }
                        count={similarProfiles.length}
                        active
                        big
                        noClickable
                      />
                    </Tabs>
                  </div>
                </div>

                <AudiencesResults
                  selectedInterest={searchFilters?.chosenTopics || []}
                  activeTab={activeTab}
                  userList={similarProfiles}
                  infoData={{
                    totalResults: similarProfiles.length,
                    ...searchAudienceInfo,
                  }}
                  searchData={searchFilters}
                  sortBy={sortBy}
                  tabChange={this.tabChange}
                  setQueryParam={this.setQueryParam}
                  listId={listId}
                  listLoading={this.state.listLoading}
                  listInit={this.state.listInit}
                />
              </>
            ) : null} */}

            {showCreateAudienceModal && (
              <CreateAudienceModal
                onClose={this.closeCreateAudienceModal}
                onConfirmClick={this.confirmCreateAudience}
                confirmButtonLoading={confirmButtonLoading}
              />
            )}
            {showEditNameModal && (
              <EditNameModal
                onClose={this.closeEditNameModal}
                onConfirmClick={this.confirmEditName}
                name={influencersInListInfo?.listName}
                confirmButtonLoading={confirmButtonLoading}
              />
            )}
            {showDeleteUserModal && (
              <RemoveModal
                onClose={this.closeDeleteUserModal}
                objectNames={
                  this.props.influencersInList
                    ?.filter((item) =>
                      this.state.selectedInfluencers.includes(item.userId),
                    )
                    ?.map((item) => {
                      return item.socialUsername;
                    }) ?? []
                }
                removeFunction={this.confirmDeleteUser}
                loading={confirmButtonLoading}
              />
            )}
          </>
        )}

        {showAddCreatorsModal && (
          <AddCreatorsModal
            onClose={this.closeAddCreatorsModal}
            onConfirmClick={this.confirmAddCreators}
            confirmButtonLoading={confirmButtonLoading}
            showAddCreatorsInfoModal={this.showAddCreatorsInfoModal}
            listId={listId}
            awaitingUsers={awaitingUsers}
            influencersInList={influencersInList}
            sortByChanged={this.sortByChanged}
            listSocialProvider={listSocialProvider}
          />
        )}

        {showAddCreatorsInfoModal && (
          <AddCreatorsInfoModal
            onConfirmClick={this.closeAddCreatorsInfoModal}
            onClose={this.closeAddCreatorsInfoModal}
          />
        )}

        {showAfterExportModal && (
          <AfterExportModal onClose={this.hideAfterExportModal} />
        )}

        {showCrisisDetectionModal && (
          <CrisisDetectionModal
            onConfirmClick={this.toggleCrisisDetectionModalVisibility}
            onClose={this.toggleCrisisDetectionModalVisibility}
          />
        )}

        {showAudienceOverlapPane && (
          <AudienceOverlapPane
            onClose={this.closeAudienceOverlapPane}
            influencersInList={influencersInList}
            listId={listId}
            listName={METHODS.limitListName(influencersInListInfo?.listName)}
          />
        )}

        <Switch>
          <Route
            path={[
              `/workspace/${activeWorkspaceUuid}/creator-discovery-tool/list/:listId/profile/:socialProvider/:socialUsername`,
              `/workspace/${activeWorkspaceUuid}/creator-discovery-tool/search-results/profile/:socialProvider/:socialUsername`,
            ]}
            render={(props) => (
              <ProfileDataPane
                {...props}
                activeTab={activeTab}
                listId={listId}
                refreshAudience={this.refreshAudience}
                addToAudience={this.addToAudience}
                setSelectedInfluencers={this.setSelectedInfluencers}
                influencersInList={influencersInList}
                influencersInListUuids={influencersInListUuids}
              />
            )}
          />
        </Switch>

        {showGlobalTask && <GlobalTaskDetails />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeWorkspaceUuid: state.mainReducer.activeWorkspaceUuid,
  searchAudienceInfo: state.audiencesManagerReducer.searchAudienceInfo,
  searchAudienceFilters: state.audiencesManagerReducer.searchAudienceFilters,
  recentSearchAudienceQuery:
    state.audiencesManagerReducer.recentSearchAudienceQuery,
  selectedInfluencersOnResults:
    state.audiencesManagerReducer.selectedInfluencersOnResults,
  selectedInfluencersOnList:
    state.audiencesManagerReducer.selectedInfluencersOnList,
  selectedInfluencersOnResultsUrls:
    state.audiencesManagerReducer.selectedInfluencersOnResultsUrls,
  selectedInfluencersOnListUrls:
    state.audiencesManagerReducer.selectedInfluencersOnListUrls,
  searchAudienceResult: state.audiencesManagerReducer.searchAudienceResult,
  similarProfiles: state.audiencesManagerReducer.similarProfiles,
  influencersInList: state.audiencesManagerReducer.influencersInList,
  influencersInListInfo: state.audiencesManagerReducer.influencersInListInfo,
  influencersInListUuids: state.audiencesManagerReducer.influencersInListUuids,
  hasAccessToInfluencerInsightsToolVelocityIndex:
    state.audiencesManagerReducer
      .hasAccessToInfluencerInsightsToolVelocityIndex,
  hasAccessToInfluencerInsightsAudienceOverlap:
    state.audiencesManagerReducer.hasAccessToInfluencerInsightsAudienceOverlap,
  hasAccessToInfluencerInsightsBrandSafety:
    state.audiencesManagerReducer.hasAccessToInfluencerInsightsBrandSafety,
  interests: state.audiencesManagerReducer.interests,
  listSocialProvider: state.audiencesManagerReducer.listSocialProvider,
  awaitingUsers: state.audiencesManagerReducer.awaitingUsers,
  influencerInterests: state.audiencesManagerReducer.influencerInterests,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAudienceLoading: (isLoading) => dispatch(setAudienceLoading(isLoading)),
    setAudienceData: (data) => dispatch(setAudienceData(data)),
    loadMoreSearchResults: (data) => dispatch(loadMoreSearchResults(data)),
    loadMoreSelectedInfluencers: (listId, page, sortBy) =>
      dispatch(loadMoreSelectedInfluencers(listId, page, sortBy)),
    refreshAudience: (searchData, page, sortBy) =>
      dispatch(refreshAudience(searchData, page, sortBy)),
    getInfluencersListData: (listId, page, sortBy) =>
      dispatch(getInfluencersListData(listId, page, sortBy)),
    createAudiencesList: (searchData, influencersList, name) =>
      dispatch(createInfluencersList(searchData, influencersList, name)),
    clearResults: () => dispatch(clearResults()),
    editName: (newName, listId) => dispatch(editName(newName, listId)),
    clearSelectedUsers: () => dispatch(clearSelectedUsers()),
    clearAllSelectedUsers: (type) => dispatch(clearAllSelectedUsers(type)),
    clearAllSelectedUsersBothTypes: () =>
      dispatch(clearAllSelectedUsersBothTypes()),
    deleteInfluencersFromList: (userList, listId) =>
      dispatch(deleteInfluencersFromList(userList, listId)),
    addToAudience: (userList, listId) =>
      dispatch(addToAudiences(userList, listId)),
    updateSearchFilters: (listId, searchData) =>
      dispatch(updateSearchFilters(listId, searchData)),
    exportList: (type, userIds, searchData) =>
      dispatch(exportList(type, userIds, searchData)),
    changeSearchAudienceFilters: (newFilters) =>
      dispatch(changeSearchAudienceFilters(newFilters)),
    clearDisabledCountries: () => dispatch(clearDisabledCountries()),
    clearInfluencersListInfo: () => dispatch(clearInfluencersListInfo()),
    getProjectsList: (activeWorkspaceUuid) =>
      dispatch(getProjectsList(activeWorkspaceUuid)),
    openTrialExceededModal: () => dispatch(openTrialExceededModal()),
  };
};

const MemoizedAudiencesResultsContainer = memo(AudiencesResultsContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(MemoizedAudiencesResultsContainer)));
