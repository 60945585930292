import classNames from "classnames";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Portal } from "react-overlays";
import { usePopper } from "react-popper";
import { overlaysRef } from "src/App";
import useOnClickOutside from "src/app/methods/useOnClickOutside";
import zIndex from "src/utils/zIndex";

export const DropdownPopper = forwardRef(
  ({ overlay, placement = "bottom-start", children }, ref = null) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 4],
          },
        },
      ],
    });

    const popperContainer = useRef(null);

    const [isOpen, setIsOpen] = useState(false);

    useOnClickOutside(popperContainer, () => {
      isOpen === true && setIsOpen(false);
    });

    useImperativeHandle(ref, () => ({
      forceVisible(state) {
        setIsOpen(state);
      },
    }));

    return (
      <>
        <div
          ref={setReferenceElement}
          onClick={() => setIsOpen(!isOpen)}
          className={classNames([
            "dropdown-popper",
            isOpen && "dropdown-popper--open",
          ])}
        >
          {children}
        </div>

        <Portal container={overlaysRef}>
          {isOpen && (
            <div ref={popperContainer}>
              <div
                ref={setPopperElement}
                style={{ ...styles.popper, zIndex: zIndex.dropdown }}
                {...attributes.popper}
              >
                {overlay}
              </div>
            </div>
          )}
        </Portal>
      </>
    );
  },
);
