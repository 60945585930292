import React, { useRef } from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { TaskType } from "src/types";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { SectionHeader } from "../../../components/Pane/SectionHeader/SectionHeader";
import { SectionRowWrapper } from "../../../components/Pane/SectionRowWrapper/SectionRowWrapper";
import { Actions } from "./section/Actions";
import { Contents } from "./section/Contents";
import { Creators } from "./section/Creators";
import { Payments } from "./section/Payments";
import { Publications } from "./section/Publications";

const METADATA_WHITELIST = [
  MetaFieldType.Percent,
  MetaFieldType.Number,
  MetaFieldType.Currency,
  MetaFieldType.Date,
  MetaFieldType.ProgressBar,
  MetaFieldType.SingleSelect,
  MetaFieldType.MultiSelect,
  MetaFieldType.AggregatedSelect,
  MetaFieldType.Member,
  MetaFieldType.File,
  MetaFieldType.Text,
];

function ProjectSummaryTab(props) {
  const { metadata } = props;

  const metadataFiltered = metadata
    ? metadata.filter((m) => METADATA_WHITELIST.includes(m.type))
    : [];

  return (
    <div className="project-summary-tab">
      <SectionRowWrapper>
        <SectionHeader
          name={
            <IDHFormattedMessage id="ws_creators" defaultMessage="Creators" />
          }
        />
        <Creators
          foundFields={metadataFiltered.filter(
            (el) => el.taskType === TaskType.Creator,
          )}
        />
      </SectionRowWrapper>
      <SectionRowWrapper>
        <SectionHeader
          name={
            <IDHFormattedMessage id="ws_content" defaultMessage="Content" />
          }
        />
        <Contents
          foundFields={metadataFiltered.filter(
            (el) => el.taskType === TaskType.Content,
          )}
        />
      </SectionRowWrapper>
      <SectionRowWrapper>
        <SectionHeader
          name={
            <IDHFormattedMessage id="ws_actions" defaultMessage="Actions" />
          }
        />
        <Actions
          foundFields={metadataFiltered.filter(
            (el) => el.taskType === TaskType.Action,
          )}
        />
      </SectionRowWrapper>
      <SectionRowWrapper>
        <SectionHeader
          name={
            <IDHFormattedMessage
              id="ws_publications"
              defaultMessage="Publications"
            />
          }
        />
        <Publications
          foundFields={metadataFiltered.filter(
            (el) => el.taskType === TaskType.Publication,
          )}
        />
      </SectionRowWrapper>
      <SectionRowWrapper>
        <SectionHeader
          name={
            <IDHFormattedMessage id="ws_payments" defaultMessage="Payments" />
          }
        />
        <Payments
          foundFields={metadataFiltered.filter(
            (el) => el.taskType === TaskType.Payment,
          )}
        />
      </SectionRowWrapper>
    </div>
  );
}

export { ProjectSummaryTab };
