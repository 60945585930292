import React from "react";
import Pictogram, {
  PictogramVariants,
} from "../../../components/Pictogram/Pictogram";
import "./DetectedPublicationsSection.scss";
import placeholderIcon from "../../../../images/project-placeholder.svg";
import publicationPlaceholder from "../../../../images/publication-placeholder.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

interface Props {
  data: {
    project: any;
    publications: any[];
  };
}

export const DetectedPublicationsSection: React.FC<Props> = (props) => {
  const {
    data: { project, publications },
  } = props;

  return (
    <div className="detected-publications-section">
      <div className="detected-publications-section__project">
        <img
          className="detected-publications-section__project-cover"
          src={
            project.cover?.thumbnails?.tiny ??
            project.cover?.publicUrl ??
            placeholderIcon
          }
          alt="avatar"
        />
        <div className="detected-publications-section__project-data">
          <span className="detected-publications-section__project-data-text">
            <IDHFormattedMessage id="ws_project" defaultMessage="Project" />
          </span>
          <span className="detected-publications-section__project-data-title">
            {project.title ?? project.projectTitle}
          </span>
        </div>
      </div>

      <div className="detected-publications-section__publications">
        <span className="detected-publications-section__publications-header">
          <IDHFormattedMessage
            id="ws_publication"
            defaultMessage="Publication"
          />
        </span>

        {publications.map((item) => (
          <div className="detected-publications-section__publications-item">
            <img
              className="detected-publications-section__publications-item-cover"
              src={
                item.cover?.thumbnails?.tiny ??
                item.cover?.publicUrl ??
                publicationPlaceholder
              }
            />
            <div className="detected-publications-section__publications-item-data">
              <span className="detected-publications-section__publications-item-title">
                {item.title}
              </span>
              <span className="detected-publications-section__publications-item-provider">
                <Pictogram
                  identifier="SocialProviders/instagram"
                  variant={PictogramVariants.Original}
                />
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
