import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { isEmpty } from "../../../../../utils/methods";
import { SectionHeader } from "../components/SectionHeader";
import { Hashtags } from "../single/Hashtags";
import { Keywords } from "../single/Keywords";

export function HashtagsAndKeywords(props) {
  const {
    profileData: { hashtags, keywords },
  } = props;

  if (isEmpty(hashtags) && isEmpty(keywords)) {
    return null;
  }

  return (
    <div className="profile-data-tab__section-wrapper">
      <SectionHeader
        text={
          <IDHFormattedMessage
            id="ws_hashtags_keywords"
            defaultMessage="Hashtags and keywords"
          />
        }
      />
      <div className="profile-data-tab__section-two-boxes">
        {!isEmpty(hashtags) && (
          <Hashtags
            hashtags={Object.keys(hashtags).map((key) => ({
              value: key,
              count: hashtags[key],
            }))}
          />
        )}
        {!isEmpty(keywords) && (
          <Keywords
            keywords={Object.keys(keywords).map((key) => ({
              value: key,
              count: keywords[key],
            }))}
          />
        )}
      </div>
    </div>
  );
}
