import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { TaskType } from "src/types";
import { RootState } from "src/redux/reducers";
import Check from "../../../components/Check/Check";

import placeholderIcon from "../../../../images/placeholder-image.svg";
import SocialProfileIcons from "../../../components/IconsWithStatus/SocialProfileIcons/SocialProfileIcons";

import { ReactComponent as MenuIcon } from "../../../../images/dots.svg";
import CustomImage from "../../../components/CustomImage/CustomImage";
import TaskMenuDropdown from "../../../dropdowns/TaskMenuDropdown/TaskMenuDropdown";
import { copyProjectOrTaskLink } from "../../../methods/copyProjectOrTaskLink";
import { handleTaskDelete } from "../../../methods/handleTaskDelete";
import { Metadata } from "../../../Task/Subtask/Subtask";
import { showToast } from "../../../methods/showToast";
import RenameModal from "../../../modals/RenameModal/RenameModal";
import { tableDataType } from "../../../components/Table/Table";
import { changeTaskMetaFieldValue } from "../../../../redux";
import { getImageSource } from "../../../../utils/methods";

function TaskBoxName(props: any) {
  const { data, boardColumns, showTaskDetails } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ projectId: string; taskId: string }>();

  const [showRenameModal, setShowRenameModal] = useState(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const taskId = data.projectId;

  useEffect(() => {
    const completed = data?.metadata?.find(
      (field: Metadata) => field.key === "completed",
    );

    if (completed) {
      setIsComplete(completed.value);
    }
  }, [data]);

  const setTaskCompleted = async () => {
    try {
      const completed = data.metadata.find(
        (field: Metadata) => field.key === "completed",
      );

      await dispatch(
        changeTaskMetaFieldValue(taskId, completed.uuid, !isComplete),
      );
    } catch (error) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_error_while_completing_an_action"
          defaultMessage="Error while completing an action!"
        />,
      );
    }
  };

  return (
    <div className="task-box__main-info">
      <div className="task-box__main-info-content">
        {data.taskType === TaskType.Action ||
        data.taskType === TaskType.Payment ? (
          <Check
            isCheck={isComplete}
            setIsCheck={(value) => {
              setIsComplete(value);
              setTaskCompleted();
            }}
          />
        ) : data.taskType === TaskType.Creator ? (
          <CustomImage
            className="task-box__main-info-cover"
            src={getImageSource(data?.cover, "tiny", placeholderIcon)}
            alt="task-cover"
            loading="lazy"
          />
        ) : null}

        <div>
          <h4 className="task-box__main-title" onClick={showTaskDetails}>
            {data.projectName}
          </h4>
          {data.socialProfiles?.length > 0 && (
            <SocialProfileIcons data={data.socialProfiles} />
          )}
        </div>
      </div>

      <TaskMenuDropdown
        data={data}
        menuButton={<MenuIcon className="task-box__main-menu-button" />}
        taskId={taskId}
        editTask={() => setShowRenameModal(true)}
        enableMove
        copyLink={() =>
          copyProjectOrTaskLink(activeWorkspaceUuid, params.projectId, taskId)
        }
        boardColumns={boardColumns}
        showDeleteButton
      />

      {showRenameModal && (
        <RenameModal
          onClose={() => setShowRenameModal(false)}
          objectId={taskId}
          taskName={data.projectName}
          titleFieldId={data.titleMetaFieldId}
          projectId={params.projectId}
          dataType={tableDataType.Task}
        />
      )}
    </div>
  );
}

export default TaskBoxName;
