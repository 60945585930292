import React from "react";

import { useParams } from "react-router";

import { CustomTable } from "src/app/components/CustomTable/CustomTable";
import goToImage from "src/images/share-alt.svg";
import { Img } from "src/app/components/Img/Img";
import { EmptyActivityTab } from "src/app/Task/tabs/ActivityTab/EmptyActivityTab";
import { AvatarAndName } from "src/app/components/GlobalTaskDetails/tabs/GlobalActivityTab/GlobalActivityTabCampaigns";
import { TaskType } from "src/types";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import Select from "src/app/components/Select/Select";
import { handleRowClick } from "src/app/Task/utils/methods";
import { publicationsColumnSettings } from "src/app/Task/tabs/PublicationsTab/PublicationsTab";
import placeholder from "src/images/publication-placeholder-grey.svg";
import { GlobalTask } from "../GlobalActivityTab";

interface GlobalPublicationsTabProps {
  tasks: GlobalTask[];
}

export function GlobalPublicationsTab({ tasks }: GlobalPublicationsTabProps) {
  const params = useParams<{ workspaceUuid: string }>();

  const generateRows = (tasks: GlobalTask[]) => {
    return tasks.map((task) => {
      const keys = {};

      publicationsColumnSettings.forEach((colSettings) => {
        const foundMetDataItem = task.metadata.find(
          (metaDataItem) =>
            metaDataItem.key === colSettings.accessor &&
            colSettings.accessor !== "title",
        );

        if (foundMetDataItem) {
          if (foundMetDataItem.type === MetaFieldType.SingleSelect) {
            Object.assign(keys, {
              [foundMetDataItem.key]: (
                <Select
                  mode="single"
                  optionsData={foundMetDataItem?.data?.singleSelectOptions}
                  uuid={foundMetDataItem?.uuid}
                  fieldValue={foundMetDataItem.value}
                  fieldName={foundMetDataItem.name}
                  emptyFieldPlaceholder={undefined}
                  readOnly
                />
              ),
            });
          } else {
            Object.assign(keys, {
              [foundMetDataItem.key]: foundMetDataItem.value,
            });
          }
        }
      });

      return {
        name: (
          <AvatarAndName
            name={task.title}
            avatar={task.cover}
            placeholder={placeholder}
          />
        ),
        ...keys,
        action: <Img src={goToImage} fallbackImageSrc={goToImage} />,
        onRowClick: () =>
          handleRowClick(
            task.id,
            TaskType.Publication,
            params.workspaceUuid,
            task.wsProjectUuid,
          ),
      };
    });
  };

  return (
    <div className="activity-tab__table-wrapper">
      <CustomTable
        columns={publicationsColumnSettings}
        data={generateRows(tasks)}
        headerClassName="activity-tab__table-header"
        noRowsPlaceholder={<EmptyActivityTab />}
      />
    </div>
  );
}
