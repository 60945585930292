import React, { useState } from "react";
import { showToast } from "src/app/methods/showToast";
import { MAX_SIZE_PROJECT_COVER } from "src/utils/uploadFunctionsUtils";
import IDHFormattedMessage from "../../IDHFormattedMessage/IDHFormattedMessage";

export const useSetCoverImageFile = () => {
  const [coverImageFile, setCoverImageFile] = useState<File | null>(null);

  const setNewImage = (file: File) => {
    if (!file) {
      return;
    }

    const size = file.size / Number(1000 * 1000);
    if (size > MAX_SIZE_PROJECT_COVER) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_file_is_too_large"
          defaultMessage="File is too large."
        />,
      );
    } else {
      setCoverImageFile(file);
    }
  };

  return { coverImageFile, setNewImage, setCoverImageFile };
};
