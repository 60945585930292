import React from "react";

import classNames from "classnames";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import thumbsUpIcon from "src/images/thumbs-up.png";
import thumbsDownIcon from "src/images/thumbs-down.png";
import { ContentProposalElementStatus } from "src/redux/comment/types";

interface AcceptRejectButtonsProps {
  handleClickAccept: () => void;
  handleClickReject: () => void;
  status: ContentProposalElementStatus;
  hasUserAccepted: boolean;
  hasUserRejected: boolean;
}

export default function AcceptRejectButtons({
  handleClickAccept,
  handleClickReject,
  status,
  hasUserAccepted,
  hasUserRejected,
}: AcceptRejectButtonsProps) {
  return (
    <div className="content-proposal-comment__accept-reject-buttons">
      <button
        onClick={handleClickAccept}
        className={classNames("content-proposal-comment__btn", {
          "content-proposal-comment__btn--accepted":
            status === ContentProposalElementStatus.Accepted,
          "content-proposal-comment__btn--selected": hasUserAccepted,
        })}
      >
        <img src={thumbsUpIcon} alt="" />
        <IDHFormattedMessage id="ws_accept" defaultMessage="Accept" />
      </button>
      <button
        onClick={handleClickReject}
        className={classNames("content-proposal-comment__btn", {
          "content-proposal-comment__btn content-proposal-comment__btn--rejected":
            status === ContentProposalElementStatus.Rejected,
          "content-proposal-comment__btn--selected": hasUserRejected,
        })}
      >
        <img src={thumbsDownIcon} alt="" />
        <IDHFormattedMessage id="ws_reject" defaultMessage="Reject" />
      </button>
    </div>
  );
}
