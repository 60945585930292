import { API_URLS } from "../../utils/API_URLS";
import {
  GET_AUTOMATION_LIST,
  REFRESH_AUTOMATION_LIST,
  SET_EDITED_AUTOMATION_DATA,
  SET_SHOW_PUBLICATIONS_DETECTED_MODAL,
  GET_DETECTED_PUBLICATIONS,
} from "./automationTypes";

export function refreshAutomationList(projectId: string) {
  const url = API_URLS.getProjectAutomations.replace(
    ":projectUuid:",
    projectId,
  );

  return {
    type: REFRESH_AUTOMATION_LIST,
    payload: {
      request: { url },
    },
  };
}

export function getAutomationList(projectId: string) {
  const url = API_URLS.getProjectAutomations.replace(
    ":projectUuid:",
    projectId,
  );

  return {
    type: GET_AUTOMATION_LIST,
    payload: {
      request: { url },
    },
  };
}

export function setShowPublicationsDetectedModal(
  showPublicationsDetectedModal: boolean,
) {
  return {
    type: SET_SHOW_PUBLICATIONS_DETECTED_MODAL,
    payload: { showPublicationsDetectedModal },
  };
}

export function getDetectedPublications(detectedTasks: any) {
  return {
    type: GET_DETECTED_PUBLICATIONS,
    payload: {
      request: {
        method: "post",
        url: API_URLS.resultAutomation,
        data: {
          wsTaskUuids: detectedTasks,
        },
      },
    },
  };
}

export function setEditedAutomationData(editedAutomationData: any) {
  return {
    type: SET_EDITED_AUTOMATION_DATA,
    payload: { editedAutomationData },
  };
}
