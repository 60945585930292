import React, { useState } from "react";

import axios from "axios";
import { useParams } from "react-router";

import { API_URLS } from "src/utils/API_URLS";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { showToast } from "src/app/methods/showToast";
import { AccessLinkButtonsComponent } from "./AccessLinkButtonsComponent";
import { clearValues, validate } from "./utils/utils";

export function RecruitmentFormButtons({
  values,
  setValues,
  setPageHandler,
  setErrors,
  submitDisabled,
}) {
  const { extensionUuid, token } = useParams();
  const [loading, setLoading] = useState(false);

  const submitValues = async () => {
    if (validate(values, setErrors)) {
      setLoading(true);
      try {
        await axios.put(
          API_URLS.updateRecruitmentFormLinkData
            .replace(":wsExtensionUuid:", extensionUuid)
            .replace(":token:", token),
          {
            metafields: values.filter((v) => v.accessMode === "write"),
          },
        );
        setPageHandler("success");
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          error.response.data.errors.map((result) => {
            showToast(
              "error",
              <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
              result.message,
            );
          });
        } else {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id="ws_something_went_wrong_during_adding_link"
              defaultMessage="Something went wrong during adding link"
            />,
          );
        }
      } finally {
        setLoading(false);
      }
    } else {
      showToast(
        "info",
        <IDHFormattedMessage
          id="ws_some_fields_are_required"
          defaultMessage="Some fields are required."
        />,
        <IDHFormattedMessage
          id="ws_please_fill_required_fields"
          defaultMessage="Please fill required fields."
        />,
      );
    }
  };

  return (
    <AccessLinkButtonsComponent
      clearValues={() => clearValues(values, setValues, setErrors)}
      submitValues={submitValues}
      submitDisabled={submitDisabled || loading}
    />
  );
}
