import React from "react";
import { CreatorInterests } from "../../../../Task/tabs/ProfileDataTab/single/CreatorInterests";

export const renderCreatorInterests = (
  label,
  data,
  layout,
  GridBoxComponent,
) => {
  let boxData = null;

  if (data?.user_audience_data?.influencersInterests) {
    boxData = data?.user_audience_data?.influencersInterests;
  }

  if (data?.creatorsInterests) {
    boxData = data?.creatorsInterests;
  }

  const key = `${label}-creator-interests`;
  const height = layout?.find((item) => item.i === key)?.h;

  return (
    <GridBoxComponent key={key} id={key}>
      <CreatorInterests data={boxData} height={height} />
    </GridBoxComponent>
  );
};
