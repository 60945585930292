import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import NumberFormat, { NumberFormatValues } from "react-number-format";

import { translateMessage } from "src/app/methods/translateMessage";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import { currencySearchFunction } from "src/app/components/CustomSelect/functions/currencySearchFunction";
import { ReactComponent as InfoIcon } from "src/images/info-circle.svg";
import { wsAxiosGet } from "src/helpers/wsAxios";
import { API_URLS } from "src/utils/API_URLS";
import { ReactComponent as RestoreIcon } from "src/images/restore.svg";
import Tooltip from "src/app/components/Tooltip/Tooltip";
import { CurrencyOption, MFState, MFStateEntries } from "../../types";

import "./EarnedMediaValue.scss";

interface DefaultEmvParams {
  key: string;
  value: number;
}
interface EarnedMediaValueProps {
  state: MFState;
  setState: (type: MFStateEntries, value: any) => void;
  isEdited: boolean;
}

export default function EarnedMediaValue({
  state: { currenciesData, selectedCurrency, emvValues },
  setState,
  isEdited,
}: EarnedMediaValueProps) {
  const intl = useIntl();
  const [defaultEmvParams, setDefaultEmvParams] = useState<
    Array<DefaultEmvParams>
  >([]);

  const setUserEmvValue = (value: number | undefined, key: string) => {
    const newValue = {
      ...emvValues,
      [key]: value,
    };

    setState("emvValues", newValue);
  };

  const restoreToDefault = (key: string) => {
    const foundDefault = defaultEmvParams.find(
      ({ key: defaultKey }) => defaultKey === key,
    );
    if (foundDefault) {
      setUserEmvValue(foundDefault.value, key);
    }
  };

  const isAllowed = (values: NumberFormatValues) => {
    const { floatValue } = values;
    if (!floatValue) return true;
    return floatValue >= 0 && floatValue <= Number.MAX_SAFE_INTEGER;
  };

  const shouldDisplayRestoreButton = (key: string) => {
    const foundDefaultValue = defaultEmvParams.find(
      (param) => param.key === key,
    )?.value;
    if (
      typeof foundDefaultValue === "number" &&
      typeof emvValues[key] === "number"
    ) {
      return foundDefaultValue !== emvValues[key];
    }
    return false;
  };

  const currencyOptions = Object.keys(currenciesData).map((code) => ({
    key: code,
    label: (
      <span className="currency-option">
        <span className="currency-code">{code}</span>
        {currenciesData[code]}
      </span>
    ),
    value: code,
  }));

  useEffect(() => {
    async function getDefaultEmvParams() {
      const response = await wsAxiosGet<{ content: Record<string, number> }>({
        url: API_URLS.getDefaultEmvParams,
      });
      if (response) {
        const dataArray: Array<DefaultEmvParams> = Object.entries(
          response.content,
        ).map(([key, value]) => ({
          key,
          value,
        }));

        setDefaultEmvParams(dataArray);
        if (!isEdited) {
          setState("emvValues", response.content);
        }
      }
    }
    getDefaultEmvParams();
  }, [isEdited]);

  return (
    <div className="earned-media-value">
      <div className="currency-wrapper__row">
        <div className="currency-select">
          <CustomInputLabel htmlFor="currency">
            <IDHFormattedMessage
              id="ws_emv_currency"
              defaultMessage="EMV currency"
            />
          </CustomInputLabel>
          <CustomSelect
            id="currency"
            value={selectedCurrency}
            disabled={isEdited}
            options={currencyOptions}
            placeholder={`${translateMessage({
              intl,
              id: "ws_select_currency",
              defaultMessage: "Select currency",
            })}...`}
            isSearchable
            onChange={(newValue: CurrencyOption) => {
              setState("selectedCurrency", newValue);
            }}
            filterOption={currencySearchFunction}
          />
        </div>
      </div>
      <div className="earned-media-value__info">
        <InfoIcon />
        <IDHFormattedMessage
          id="ws_emv_info"
          defaultMessage="EMV will be calculated Instagram, TikTok, YouTube, Facebook and Pinterest publications when all necessary metrics are filled."
        />
      </div>
      <div className="earned-media-value__monetary-values">
        <div className="earned-media-value__title">
          <IDHFormattedMessage
            id="ws_monetary_values_of_metrics"
            defaultMessage="Monetary values of metrics"
          />
        </div>
        <div className="earned-media-value__description">
          <IDHFormattedMessage
            id="ws_monetary_values_of_metrics_info"
            defaultMessage="Adjust the monetary values for individual metrics based on which the EMV will be calculated."
          />
        </div>
      </div>
      <div className="earned-media-value__list">
        {defaultEmvParams.map(({ key, value }) => (
          <div className="earned-media-value__list-item" key={key}>
            <div className="earned-media-value__label-wrapper">
              <CustomInputLabel
                htmlFor={key}
                fontSize="medium"
                fontColor="dark-grey"
                fontWeight="thin"
              >
                <IDHFormattedMessage id={`ws_${key}`} />
              </CustomInputLabel>

              {shouldDisplayRestoreButton(key) && (
                <Tooltip
                  contentClassName="earned-media-value__tooltip"
                  content={
                    <IDHFormattedMessage
                      id="ws_restore_the_default_metric_value"
                      defaultMessage="Restore the default metric value"
                    />
                  }
                >
                  <button
                    onClick={() => restoreToDefault(key)}
                    aria-label="Restore to default"
                    type="button"
                    className="earned-media-value__restore-button"
                  >
                    <RestoreIcon />
                  </button>
                </Tooltip>
              )}
            </div>

            <NumberFormat
              id={key}
              className="ws-input"
              isAllowed={isAllowed}
              prefix={
                selectedCurrency.value
                  ? `${selectedCurrency.value} `
                  : undefined
              }
              defaultValue={value}
              value={emvValues[key]}
              onValueChange={(values: NumberFormatValues) =>
                setUserEmvValue(values.floatValue, key)
              }
              decimalScale={4}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
