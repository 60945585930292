import React, { useEffect, useState } from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as HashtagsIcon } from "../../../../../images/profile-data/hashtags.svg";
import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";
import { CustomTagCloud } from "../components/CustomTagCloud/CustomTagCloud";

export function Hashtags(props) {
  const { hashtags, height } = props;
  const onShowcase = height;

  return (
    <ProfileBox className="profile-box--tags">
      <PBHeader
        icon={<HashtagsIcon />}
        title={
          <IDHFormattedMessage id="ws_hashtags" defaultMessage="Hashtags" />
        }
        onShowcase={onShowcase}
      />
      <CustomTagCloud data={hashtags} blue />
    </ProfileBox>
  );
}
