import React from "react";
import "./ProgressBar.scss";
import classNames from "classnames";

function ProgressBar({
  percent,
  barColor,
  backgroundColor,
  progressSeparator,
}) {
  return (
    <div
      className={classNames("progress-bar", {
        "progress-bar--with-separator": progressSeparator,
      })}
      style={{
        backgroundColor: backgroundColor || null,
      }}
    >
      <div
        className="progress-bar__color"
        style={{
          width: `${percent}%`,
          backgroundColor: barColor || null,
        }}
      />
    </div>
  );
}

export default ProgressBar;
