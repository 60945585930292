import React from "react";

import { useParams } from "react-router";

import { CustomTable } from "src/app/components/CustomTable/CustomTable";
import goToImage from "src/images/share-alt.svg";
import { Img } from "src/app/components/Img/Img";
import { EmptyActivityTab } from "src/app/Task/tabs/ActivityTab/EmptyActivityTab";
import { AvatarAndName } from "src/app/components/GlobalTaskDetails/tabs/GlobalActivityTab/GlobalActivityTabCampaigns";
import { TaskType } from "src/types";
import { handleRowClick } from "src/app/Task/utils/methods";
import circleTick from "src/images/circle-tick-1.svg";
import { actionsColumnSettings } from "src/app/Task/tabs/ActionsTab/ActionsTab";
import { GlobalTask } from "../GlobalActivityTab";

interface GlobalActionsTabProps {
  tasks: GlobalTask[];
}

export function GlobalActionsTab({ tasks }: GlobalActionsTabProps) {
  const params = useParams<{ workspaceUuid: string }>();

  const generateRows = (tasks: GlobalTask[]) => {
    return tasks.map((task) => {
      return {
        name: (
          <AvatarAndName
            name={task.title}
            placeholder={circleTick}
            avatarWidth={24}
            avatarHeight={24}
          />
        ),
        action: <Img src={goToImage} fallbackImageSrc={goToImage} />,
        onRowClick: () =>
          handleRowClick(
            task.id,
            TaskType.Action,
            params.workspaceUuid,
            task.wsProjectUuid,
          ),
      };
    });
  };

  return (
    <div className="activity-tab__table-wrapper">
      <CustomTable
        columns={actionsColumnSettings}
        data={generateRows(tasks)}
        headerClassName="activity-tab__table-header"
        noRowsPlaceholder={<EmptyActivityTab />}
      />
    </div>
  );
}
