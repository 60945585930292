import React from "react";

import { LinkExpiredBlock } from "../../../LinkExpiredBlock/LinkExpiredBlock";
import { SuccessBlock } from "../../../SuccessBlock/SuccessBlock";

export function FormHandler({ pageHandler }) {
  switch (pageHandler) {
    case "expired":
      return <LinkExpiredBlock />;
    case "success":
      return <SuccessBlock />;
    default:
      return null;
  }
}
