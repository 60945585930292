import React from "react";
import "./SimpleSelect.scss";

import classNames from "classnames";
import { DropdownMenu, DropdownMenuItem } from "../Dropdown/Dropdown";
import { DropdownPortal } from "../DropdownPortal/DropdownPortal";
import { ReactComponent as ChevronDownIcon } from "../../../images/chevron-down-alt.svg";

import { ReactComponent as TickBlueIcon } from "../../../images/tick-blue.svg";

export function SimpleSelect({
  options,
  selectedOption,
  setSelectedOption,
  disabled,
}) {
  const menu = (
    <DropdownMenu isOpen>
      {options.map((option, i) => {
        return (
          <DropdownMenuItem key={i} onClick={() => setSelectedOption(option)}>
            {option.name}
            {selectedOption?.value === option.value && (
              <TickBlueIcon className="simple-select__tick-blue" />
            )}
          </DropdownMenuItem>
        );
      })}
    </DropdownMenu>
  );

  return (
    <DropdownPortal overlay={menu} disabled={disabled}>
      <div
        className={classNames("simple-select", {
          "simple-select--disabled": disabled,
        })}
      >
        {selectedOption?.name}
        <ChevronDownIcon className="simple-select__chevron-down" />
      </div>
    </DropdownPortal>
  );
}
