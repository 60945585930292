import React, { useState } from "react";
import "./FlexibleSearchCriteriaModal.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal from "src/app/components/Modal/Modal";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import { Button } from "src/app/components/Button/Button";
import video from "./utils/fsc-modal-video-720.mp4";

export function FlexibleSearchCriteriaModal(props) {
  const { onClose } = props;

  const [stopShowing, setStopShowing] = useState(false);

  const handleClose = () => {
    if (stopShowing) {
      localStorage.setItem("stop-showing-fsc-modal", true);
    }
    onClose();
  };

  return (
    <Modal
      className="fsc-modal"
      overlayClassName="fsc-modal__overlay"
      onClose={handleClose}
    >
      <div className="fsc-modal__info">
        <span className="fsc-modal__info-new">
          <IDHFormattedMessage id="ws_new" defaultMessage="New" />
          🔥
        </span>

        <span className="fsc-modal__info-title">
          <IDHFormattedMessage
            id="ws_flexible_search_criteria"
            defaultMessage="Flexible Search Criteria"
          />
        </span>

        <span className="fsc-modal__info-description">
          <IDHFormattedMessage
            id="ws_flexible_search_criteria_description"
            defaultMessage="Adjust specific filter sections to get more precise search results that are perfectly tailored to your needs."
          />
        </span>

        <CustomCheckbox
          id="stop-showing"
          label={
            <IDHFormattedMessage
              id="dont_show_me_this_again"
              defaultMessage="Don’t show me this again"
            />
          }
          checked={stopShowing}
          onChange={(e) => setStopShowing(e.target.checked)}
        />

        <Button
          onClick={handleClose}
          variant="white-with-grey-border"
          size="medium"
        >
          <IDHFormattedMessage id="ws_close" defaultMessage="Close" />
        </Button>
      </div>
      <div className="fsc-modal__video">
        <video src={video} autoPlay loop />
      </div>
    </Modal>
  );
}
