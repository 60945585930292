import React from "react";
import { InfluencerGrowth } from "../../../../Task/tabs/ProfileDataTab/single/InfluencerGrowth";

export const renderCreatorGrowth = (label, data, layout, GridBoxComponent) => {
  const {
    followersStatisticsData,
    user_data: { followers },
  } = data;

  const key = `${label}-creator-growth`;
  const height = layout?.find((item) => item.i === key)?.h;

  return (
    <GridBoxComponent key={key} id={key}>
      <InfluencerGrowth
        followersStatisticsData={followersStatisticsData}
        followers={followers}
        height={height}
      />
    </GridBoxComponent>
  );
};
