import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.scss";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "src/redux/reducers";
import { Button } from "../components/Button/Button";
import emptyDashboardImage from "../../images/empty-dashboard-image.png";
import PageHeader from "../components/PageHeader/PageHeader";
import { ReactComponent as RocketIcon } from "../../images/rocket.svg";

const Dashboard: React.FC = () => {
  const [showNewAllowListModal, setShowAllowListModal] =
    useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    containerRef.current?.classList.remove("dashboard--hidden");
  }, []);

  const {
    mainReducer: { identity, activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  if (
    !identity.permissions?.workspace.includes("view_dashboard") &&
    activeWorkspaceUuid
  ) {
    return <Redirect to={`/workspace/${activeWorkspaceUuid}/projects`} />;
  }

  // Temporarily always redirect to projects instead of dashboard (dashboard is not really functional yet):
  if (activeWorkspaceUuid) {
    return <Redirect to={`/workspace/${activeWorkspaceUuid}/projects`} />;
  }

  return <Redirect to="/workspace/" />;

  return (
    <div ref={containerRef} className="dashboard dashboard--hidden">
      <PageHeader title="Dashboard" />
      <div className="dashboard__content">
        <div className="dashboard__content-left">
          <p className="dashboard__content-left-text">
            <div className="dashboard__content-left-text-item">
              3... 2... 1...
            </div>
            <div className="dashboard__content-left-text-item">
              Create your first project and go on a journey!
              <RocketIcon className="dashboard__content-left-text-item-icon" />
            </div>
            <div className="dashboard__content-left-text-item">
              Let the story begin!
            </div>
          </p>
          <Button
            variant="blue"
            size="large"
            onClick={() => setShowAllowListModal(true)}
          >
            Create project
          </Button>
        </div>
        <div className="dashboard__content-right">
          <img src={emptyDashboardImage} alt="empty-dashboard" />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
