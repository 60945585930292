import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { uuidv7 as uuid } from "uuidv7";
import { useDispatch, useSelector } from "react-redux";

import Modal, { ModalTitle } from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { CustomTokenInput } from "src/app/components/CustomTokenInput/CustomTokenInput";
import { translateMessage } from "src/app/methods/translateMessage";
import {
  createDictionaryElements,
  setShouldCloseAddDictionaryElementModal,
} from "src/redux";
import { RootState } from "src/redux/reducers";

interface AddNewDictionaryElementModalProps {
  onClose: () => void;
  workspaceUuid: string;
  wsDictionaryName: string;
  dictionaryUuid: string;
  setElementToScrollUuid?: Dispatch<SetStateAction<string | null>>;
}

export function AddNewDictionaryElementModal({
  onClose,
  workspaceUuid,
  wsDictionaryName,
  dictionaryUuid,
  setElementToScrollUuid,
}: AddNewDictionaryElementModalProps) {
  const intl = useIntl();

  const [names, setNames] = useState<string[]>([]);

  const dispatch = useDispatch();
  const {
    dictionaryReducer: { shouldCloseAddDictionaryElementModal },
  } = useSelector((state: RootState) => state);

  const handleConfirm = () => {
    const elementsData = names.map((name, index) => {
      const elementUuid = uuid();

      if (index === 0 && setElementToScrollUuid) {
        setElementToScrollUuid(elementUuid);
      }

      return {
        name,
        uuid: elementUuid,
      };
    });

    dispatch(
      createDictionaryElements(workspaceUuid, dictionaryUuid, elementsData),
    );
  };

  useEffect(() => {
    if (shouldCloseAddDictionaryElementModal) {
      dispatch(setShouldCloseAddDictionaryElementModal(false));
      onClose();
    }
  }, [shouldCloseAddDictionaryElementModal]);

  useEffect(() => {
    const textarea = document.querySelector(
      ".react-autosuggest__container .ws-textarea",
    ) as HTMLElement;

    if (textarea) {
      const id = setTimeout(() => {
        textarea.click();
      }, 150);
      return () => {
        clearTimeout(id);
      };
    }
  }, []);

  return (
    <Modal
      className="add-new-dictionary-element-modal"
      onClose={onClose}
      onConfirmClick={handleConfirm}
      confirmButtonDisabled={!names.length}
      closeButtonText={translateMessage({
        intl,
        id: "ws_cancel",
        defaultMessage: "Cancel",
      })}
      confirmButtonText={translateMessage({
        intl,
        id: "ws_confirm",
        defaultMessage: "Confirm",
      })}
      displayCancelButton
    >
      <ModalTitle>
        <div>
          <IDHFormattedMessage id="ws_add" defaultMessage="Add" />{" "}
          {wsDictionaryName}
        </div>
      </ModalTitle>
      <CustomTokenInput
        value={names}
        onChange={(newValue: string[]) => setNames(newValue)}
        placeholder={
          !names.length
            ? `${translateMessage({
                intl,
                id: "ws_enter",
                defaultMessage: "Enter",
              })} ${wsDictionaryName} ${translateMessage({
                intl,
                id: "ws_name",
                defaultMessage: "name",
              })}`
            : ""
        }
        separators={[",", "\n"]}
      />
    </Modal>
  );
}
