import React from "react";
import Pictogram, {
  PictogramVariants,
} from "../../../../../components/Pictogram/Pictogram";
import { renderGeneratorMetaValue } from "../../../../../generator-data/components/ContentBox/ContentBox";
import "./PostsDataBox.scss";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import RatingSelector from "src/app/components/RatingSelector/RatingSelector";

export function PostsDataBox(props) {
  const { data } = props;

  const iconName = data?.data?.icon?.name;
  const folder = iconName?.includes("/") ? iconName.split("/")[0] : "Social";
  const name = iconName?.includes("/") ? iconName.split("/")[1] : iconName;
  const variant =
    folder === "SocialProviders"
      ? PictogramVariants.Original
      : PictogramVariants[data?.data?.icon?.color];

  const showIcon = name && folder;

  const renderContent = () => {
    if (data.type === MetaFieldType.Rating) {
      return <RatingSelector disabled value={parseInt(data?.value || "0")} />;
    }

    return renderGeneratorMetaValue(data);
  };

  return (
    <div className="posts-data-box">
      <span className="posts-data-box__name">{data.name}</span>

      <span className="posts-data-box__value">
        {showIcon && (
          <span className="posts-data-box__value-icon">
            <Pictogram identifier={`${folder}/${name}`} variant={variant} />
          </span>
        )}
        {renderContent()}
      </span>
    </div>
  );
}
