import React from "react";
import "./SelectTags.scss";

import { SelectedLabels } from "./partials/SelectedLabels/SelectedLabels";
import { SelectableLabels } from "./partials/SelectableLabels/SelectableLabels";

export function SelectTags({
  feed = [],
  values = [],
  setValue,
  unsetValue,
  clearValues,
  translative,
  getFullObject,
  selectDensity = false,
  disabled = false,
}) {
  const getValue = (fv, v) => {
    if (translative) {
      return fv.value === v;
    }

    if (selectDensity) {
      return fv.value === v.value;
    }

    return fv === v;
  };

  const filteredFeed =
    feed?.length > 0
      ? feed?.filter((fv) => !values.find((v) => getValue(fv, v)))
      : [];

  if (feed?.length === 0 && values?.length === 0) return <></>;

  const constructValues = () => {
    if (selectDensity) {
      return values.map((v) => `${v.label} ${v.percent}%`);
    }

    return values;
  };

  return (
    <div className="select-tags">
      {values.length > 0 && (
        <SelectedLabels
          values={constructValues()}
          unsetValue={unsetValue}
          clearValues={clearValues}
          translative={translative}
          getFullObject={getFullObject}
          disabled={disabled}
        />
      )}
      <SelectableLabels
        feed={filteredFeed}
        setValue={setValue}
        translative={translative}
        selectDensity={selectDensity}
        disabled={disabled}
      />
    </div>
  );
}
