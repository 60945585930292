import React from "react";
import "./UseInCreatorDatabase.scss";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MFState, MFStateEntries } from "../../types";

interface Props {
  state: MFState;
  setState: (type: MFStateEntries, value: any) => void;
}

export const UseInCreatorDatabase: React.FC<Props> = (props) => {
  const {
    state: { showInCreatorDatabase },
    setState,
  } = props;

  return (
    <div className="use-in-creator-database">
      <CustomSwitch
        checked={showInCreatorDatabase}
        onChange={() =>
          setState("showInCreatorDatabase", !showInCreatorDatabase)
        }
      />
      <span className="use-in-creator-database__title">
        <IDHFormattedMessage
          id="ws_use_in_creator_database"
          defaultMessage="Use in Creator Database"
        />
      </span>
    </div>
  );
};
