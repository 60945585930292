import React, { ReactNode } from "react";
import "./Breadcrumbs.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";

type Props = {
  children: React.ReactNode;
};

export const Breadcrumbs: React.FC<Props> = (props) => {
  return <div className="breadcrumbs">{props.children}</div>;
};

type ItemProps = {
  text: string | ReactNode;
  to: string;
  disabled?: boolean;
  onClick?: () => void;
};

export const BreadcrumbsItem: React.FC<ItemProps> = ({
  text,
  to,
  disabled,
  onClick,
}) => {
  if (to || typeof onClick === "function") {
    return (
      <div>
        <Link
          className={classNames("breadcrumbs__item", {
            "breadcrumbs__item--disabled": disabled,
          })}
          to={to}
          onClick={onClick}
        >
          {text}
        </Link>
      </div>
    );
  }
  return (
    <div>
      <div className="breadcrumbs__item" onClick={onClick}>
        {text}
      </div>
    </div>
  );
};
