export const generateColumns = (columns = [], hiddenColumns = []) => {
  const filteredColumns = columns.filter(
    (c) => !hiddenColumns.includes(c.uuid)
  );
  return [
    {
      Header: "NAME",
      accessor: "projectName",
      Footer: "Name",
      sticky: "left",
      width: 375,
    },
    ...filteredColumns.map((c) => ({
      accessor: c.uuid,
      Header: c.name,
      origin: c.origin,
      width: "auto",
    })),

    ...(filteredColumns.length === 0
      ? [
          {
            Header: " ",
            accessor: "7512ab25-6c6d-4339-b831-0260e5d8a149",
            Footer: " 7512ab25-6c6d-4339-b831-0260e5d8a149",
            width: "auto",
          },
          {
            Header: " action",
            accessor: "projectAction",
            Footer: " action",
            sticky: "right",
            width: 88,
          },
        ]
      : [
          {
            Header: " action",
            accessor: "projectAction",
            Footer: " action",
            sticky: "right",
            width: 88,
          },
        ]),
  ];
};
