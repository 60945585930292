import React from "react";

import classNames from "classnames";

import { DropdownPortalMenu } from "src/app/components/DropdownPortal";

export function MenuOverlay({ children }: React.PropsWithChildren<{}>) {
  return (
    <DropdownPortalMenu className={classNames("label-selector__menu")}>
      {children}
    </DropdownPortalMenu>
  );
}
