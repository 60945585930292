import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { clearUserAcceptedContent, clearUserRejectedContent } from "src/redux";
import { RootState } from "src/redux/reducers";
import { ContentProposalElementStatus } from "src/redux/comment/types";
import { ReactComponent as PlusWhiteIcon } from "src/images/plus-white.svg";
import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import ModerateContentProposalField from "./ModerateContentProposalField";
import AddContentProposalField from "./AddContentProposalField";
import AddCommentWrapper from "../AddCommentField/AddCommentWrapper";

import "./ContentProposalField.scss";

interface ContentProposalFieldProps {
  activeWorkspaceUuid: string;
  taskId: string;
  userAvatar: JSX.Element;
  showAddContentProposalField: boolean;
  displayFixedAddContentProposalField: boolean;
  setShowAddContentProposalField: (show: boolean) => void;
  setContentProposalFieldNotSubmitted: (result: boolean) => void;
}

export default function ContentProposalField({
  activeWorkspaceUuid,
  taskId,
  userAvatar,
  showAddContentProposalField,
  setShowAddContentProposalField,
  displayFixedAddContentProposalField,
  setContentProposalFieldNotSubmitted,
}: ContentProposalFieldProps) {
  const userAcceptedContent = useSelector(
    (state: RootState) => state.commentReducer.userAcceptedContent,
  );
  const userRejectedContent = useSelector(
    (state: RootState) => state.commentReducer.userRejectedContent,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearUserAcceptedContent());
      dispatch(clearUserRejectedContent());
    };
  }, []);

  if (
    userAcceptedContent.wsTaskCommentUuid ||
    userRejectedContent.wsTaskCommentUuid
  ) {
    return (
      <AddCommentWrapper>
        {userAvatar}
        <ModerateContentProposalField
          status={
            userAcceptedContent.wsTaskCommentUuid
              ? ContentProposalElementStatus.Accepted
              : ContentProposalElementStatus.Rejected
          }
          moderatedProposalElements={
            userAcceptedContent.wsTaskCommentUuid
              ? userAcceptedContent
              : userRejectedContent
          }
          taskId={taskId}
          hideContentProposalField={() => {
            dispatch(clearUserAcceptedContent());
            dispatch(clearUserRejectedContent());
          }}
        />
      </AddCommentWrapper>
    );
  }
  if (showAddContentProposalField) {
    return (
      <AddCommentWrapper>
        {userAvatar}
        <AddContentProposalField
          activeWorkspaceUuid={activeWorkspaceUuid}
          hideContentProposalField={() => {
            setShowAddContentProposalField(false);
            setContentProposalFieldNotSubmitted(false);
          }}
          taskId={taskId}
          setContentProposalFieldNotSubmitted={
            setContentProposalFieldNotSubmitted
          }
        />
      </AddCommentWrapper>
    );
  }

  if (!displayFixedAddContentProposalField) {
    return null;
  }

  return (
    <Button
      size="medium"
      variant="blue"
      onClick={() => setShowAddContentProposalField(true)}
      fontSize="big"
      data-qa="add-content-proposal"
      className="button--add-content-proposal"
    >
      <PlusWhiteIcon />
      <IDHFormattedMessage
        id="ws_add_content_proposal"
        defaultMessage="Add Content Proposal"
      />
    </Button>
  );
}
