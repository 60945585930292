import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { translateMessage } from "src/app/methods/translateMessage";

import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import SimpleExpander from "src/app/components/SimpleExpander/SimpleExpander";

interface Props {
  name: string;
  data: any;
  filters: any;
  updateFilter: (key: string, value: any) => void;
}

export const MultiCheckboxFilter: React.FC<Props> = (props) => {
  const { name, data, filters, updateFilter } = props;

  const intl = useIntl();

  const feed = useMemo(
    () =>
      data?.sort((a: { value: string }, b: { value: string }) => {
        const containsA = filters[name]?.includes(a.value);
        const containsB = filters[name]?.includes(b.value);
        if (containsA && containsB) return a.value.localeCompare(b.value);
        if (containsA) return -1;
        if (containsB) {
          return 1;
        }
        return a.value?.localeCompare(b?.value);
      }) || [],
    [filters, data],
  );

  const feed1 = feed.slice(0, 5);
  const feed2 = feed.slice(5, feed.length);

  const onChange = (e: any, option: any) => {
    if (e.target.checked) {
      updateFilter(name, [...(filters[name] || []), option.value]);
    } else {
      updateFilter(name, [
        ...filters[name].filter((f: any) => f !== option.value),
      ]);
    }
  };

  return (
    <div className="single-select-filter-options">
      {feed1.map((option: any) => (
        <CustomCheckbox
          id={option.value}
          name={option.label}
          label={option.label}
          onChange={(e) => onChange(e, option)}
          checked={!!filters[name]?.includes(option.value)}
        />
      ))}
      {feed2.length > 0 && (
        <SimpleExpander
          contentClassName="single-select-filter-options__expander__content"
          expandText={`${translateMessage({
            intl,
            id: "ws_more",
            defaultMessage: "More",
          })} (${feed2.length})`}
        >
          {feed2.map((option: any) => (
            <CustomCheckbox
              id={option.value}
              name={option.label}
              label={option.label}
              onChange={(e) => onChange(e, option)}
              checked={!!filters[name]?.includes(option.value)}
            />
          ))}
        </SimpleExpander>
      )}
    </div>
  );
};
