import React from "react";
import "./CreatorInDatabaseLink.scss";
import { useHistory } from "react-router";
import { openGlobalTaskDetails } from "src/app/components/GlobalTaskDetails/methods";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import { ReactComponent as CreatorIcon } from "src/images/creator-discovery.svg";

export function CreatorInDatabaseLink(props) {
  const { globalTaskUuid } = props;

  const history = useHistory();

  if (!globalTaskUuid) return null;

  return (
    <MaterialTooltip
      center
      content={
        <IDHFormattedMessage
          id="ws_creator_in_database_tooltip"
          defaultMessage="Creator in Database"
        />
      }
    >
      <CreatorIcon
        className="creator-in-database-link"
        onClick={() => openGlobalTaskDetails(history, globalTaskUuid)}
      />
    </MaterialTooltip>
  );
}
