import { SelectOption } from "src/app/components/Select/types";
import { TaskType } from "src/types";
import { GlobalTask } from "src/app/components/GlobalTaskDetails/tabs/GlobalActivityTab/GlobalActivityTab";
import { Column, MetaDataValue, Row } from "../dictionary/types";
import { removeRows, updateColumns, updateList } from "../dictionary/utils";
import {
  CLEAR_GLOBAL_TASK_CREATOR_LISTS,
  CLEAR_GLOBAL_TASK_DETAILS,
  CLEAR_GLOBAL_TASK_PROJECTS,
  CLEAR_GLOBAL_TASK_RELATED_TASKS,
  GET_GLOBAL_TASKS_FAIL,
  GET_GLOBAL_TASKS_SUCCESS,
  GET_GLOBAL_TASK_CREATOR_LISTS,
  GET_GLOBAL_TASK_CREATOR_LISTS_SUCCESS,
  GET_GLOBAL_TASK_DETAILS,
  GET_GLOBAL_TASK_DETAILS_SUCCESS,
  GET_GLOBAL_TASK_DETAILS_SUCCESS_FAIL,
  GET_GLOBAL_TASK_PROJECTS,
  GET_GLOBAL_TASK_PROJECTS_SUCCESS,
  GET_GLOBAL_TASK_RELATED_TASKS,
  GET_GLOBAL_TASK_RELATED_TASKS_SUCCESS,
  GET_SINGLE_GLOBAL_TASK_FROM_LIST_SUCCESS,
  REMOVE_GLOBAL_TASKS_SUCCESS,
  SET_GLOBAL_TASK_META_VALUE_SUCCESS,
  UPDATE_CREATOR_DATABASE_SELECT_FIELD_SUCCESS,
} from "./creatorDatabaseTypes";
import {
  GlobalTaskCreatorListItem,
  GlobalTaskDetails,
  GlobalTaskProjects,
} from "./types";

export interface CreatorDatabaseReducerState {
  globalTasks: Row[];
  globalTaskColumns: Column[];
  globalTaskDetails: GlobalTaskDetails;
  globalTasksTotalCount: number;
  loading: boolean;
  detailsLoading: boolean;
  globalTaskProjects: GlobalTaskProjects;
  isGlobalTaskProjectsLoading: boolean;
  globalTaskCreatorLists: GlobalTaskCreatorListItem[];
  isGlobalTaskCreatorListsLoading: boolean;
  globalTaskRelatedTasks: {
    relatedContentTasks: GlobalTask[];
    relatedActionTasks: GlobalTask[];
    relatedPublicationTasks: GlobalTask[];
    relatedPaymentTasks: GlobalTask[];
  };
  isGlobalTaskRelatedTasksLoading: boolean;
}

const initialState: CreatorDatabaseReducerState = {
  globalTasks: [],
  globalTaskColumns: [],
  globalTaskDetails: {} as GlobalTaskDetails,
  globalTasksTotalCount: 0,
  loading: true,
  detailsLoading: false,
  globalTaskProjects: {
    columns: [],
    projects: [],
  },
  isGlobalTaskProjectsLoading: false,
  globalTaskCreatorLists: [],
  isGlobalTaskCreatorListsLoading: false,
  globalTaskRelatedTasks: {
    relatedContentTasks: [],
    relatedActionTasks: [],
    relatedPublicationTasks: [],
    relatedPaymentTasks: [],
  },
  isGlobalTaskRelatedTasksLoading: false,
};

function creatorDatabaseReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_GLOBAL_TASKS_SUCCESS: {
      return {
        ...state,
        globalTasks: action.payload.data.content.list,
        globalTaskColumns: action.payload.data.content.columns,
        globalTasksTotalCount: action.payload.data.content.totalCount,
        loading: false,
      };
    }

    case GET_GLOBAL_TASKS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_SINGLE_GLOBAL_TASK_FROM_LIST_SUCCESS: {
      const newData = action.payload.data.content[0];
      return {
        ...state,
        globalTasks: state.globalTasks.map((task) => {
          if (task.uuid === newData.uuid) {
            return newData;
          }

          return task;
        }),
      };
    }

    case SET_GLOBAL_TASK_META_VALUE_SUCCESS:
      const {
        uuids: updatedUuids,
        metaFieldUuid,
        metaValue,
      }: {
        uuids: string[];
        metaFieldUuid: string;
        metaValue: MetaDataValue | null;
      } = action.payload.config.reduxSourceAction.payload;

      const updatedList = updateList(
        state.globalTasks,
        updatedUuids,
        metaFieldUuid,
        metaValue,
      );

      return {
        ...state,
        globalTasks: updatedList,
      };

    case GET_GLOBAL_TASK_DETAILS: {
      if (action.payload.skipLoading) return state;

      return {
        ...state,
        detailsLoading: true,
      };
    }

    case GET_GLOBAL_TASK_DETAILS_SUCCESS: {
      return {
        ...state,
        globalTaskDetails: action.payload.data.content,
        detailsLoading: false,
      };
    }

    case GET_GLOBAL_TASK_DETAILS_SUCCESS_FAIL: {
      return {
        ...state,
        detailsLoading: false,
      };
    }

    case CLEAR_GLOBAL_TASK_DETAILS:
      return {
        ...state,
        globalTaskDetails: {},
      };

    case REMOVE_GLOBAL_TASKS_SUCCESS:
      const {
        request: {
          data: { uuids },
        },
      } = action.payload.config.reduxSourceAction.payload;

      const removedRows = removeRows(state.globalTasks, uuids);

      return {
        ...state,
        globalTasks: removedRows,
      };

    case UPDATE_CREATOR_DATABASE_SELECT_FIELD_SUCCESS: {
      const {
        metaFieldUuid,
        singleSelectOptions,
      }: { metaFieldUuid: string; singleSelectOptions: SelectOption[] } =
        action.payload.config.reduxSourceAction.payload;

      const updatedColumns = updateColumns(
        state.globalTaskColumns,
        metaFieldUuid,
        singleSelectOptions,
      );

      return {
        ...state,
        globalTaskColumns: updatedColumns,
      };
    }

    case GET_GLOBAL_TASK_PROJECTS:
      return {
        ...state,
        isGlobalTaskProjectsLoading: true,
      };

    case GET_GLOBAL_TASK_PROJECTS_SUCCESS:
      return {
        ...state,
        globalTaskProjects: action.payload.data.content,
        isGlobalTaskProjectsLoading: false,
      };

    case CLEAR_GLOBAL_TASK_PROJECTS:
      return {
        ...state,
        globalTaskProjects: { columns: [], projects: [] },
      };

    case GET_GLOBAL_TASK_CREATOR_LISTS:
      return {
        ...state,
        isGlobalTaskCreatorListsLoading: true,
      };

    case GET_GLOBAL_TASK_CREATOR_LISTS_SUCCESS:
      return {
        ...state,
        globalTaskCreatorLists: action.payload.data.content,
        isGlobalTaskCreatorListsLoading: false,
      };

    case CLEAR_GLOBAL_TASK_CREATOR_LISTS:
      return {
        ...state,
        globalTaskCreatorLists: [],
      };

    case GET_GLOBAL_TASK_RELATED_TASKS:
      return {
        ...state,
        isGlobalTaskRelatedTasksLoading: true,
      };

    case GET_GLOBAL_TASK_RELATED_TASKS_SUCCESS:
      const { taskType }: { taskType: TaskType } =
        action.payload.config.reduxSourceAction.payload;

      const objectPropertyName = `related${
        taskType[0].toUpperCase() + taskType.slice(1)
      }Tasks`;

      const relatedTasks = action.payload.data.content;

      return {
        ...state,
        globalTaskRelatedTasks: {
          ...state.globalTaskRelatedTasks,
          [objectPropertyName]: relatedTasks,
        },
        isGlobalTaskRelatedTasksLoading: false,
      };

    case CLEAR_GLOBAL_TASK_RELATED_TASKS:
      return {
        ...state,
        globalTaskRelatedTasks: {
          relatedContentTasks: [],
          relatedActionTasks: [],
          relatedPublicationTasks: [],
          relatedPaymentTasks: [],
        },
      };

    default:
      return state;
  }
}

export default creatorDatabaseReducer;
