import React, { useState } from "react";
import "./AccessLinkPageForm.scss";

import { AccessLinkPageFormButtons } from "./components/AccessLinkPageFormButtons/AccessLinkPageFormButtons";
import { AccessLinkPageInputField } from "./components/AccessLinkPageInputField/AccessLinkPageInputField";
import { RecruitmentFormButtons } from "./components/AccessLinkPageFormButtons/RecruitmentFormButtons";

export function AccessLinkPageForm({
  metafields = [],
  wsWorkspaceUuid,
  setPageHandler,
  type,
  extensionUuid,
}) {
  const [values, setValues] = useState(
    metafields.map((field) => ({
      accessMode: field.accessMode,
      wsMetaFieldUuid: field.uuid,
      wsMetaFieldType: field.metaFieldOrigin,
      required: field.metaFieldIsRequired,
      value: field.value,
    })),
  );

  const isFormEditable =
    metafields.filter((m) => m.accessMode === "write").length > 0;

  const [errors, setErrors] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const dispatchValue = (
    accessMode,
    wsMetaFieldUuid,
    wsMetaFieldType,
    required,
    value,
  ) => {
    // check if wsMetaFieldUuid exist
    // if not append if to the end of vals
    const tempVals = values.filter(
      (v) => v.wsMetaFieldUuid !== wsMetaFieldUuid,
    );
    setValues([
      ...tempVals,
      { accessMode, wsMetaFieldUuid, wsMetaFieldType, required, value },
    ]);
  };

  return (
    <div className="access-link-page__form">
      {metafields.map((metafield, i) => (
        <AccessLinkPageInputField
          key={i}
          metafield={metafield}
          globalValue={
            values.find((gv) => gv.wsMetaFieldUuid === metafield.uuid)?.value
          }
          dispatchValue={dispatchValue}
          wsWorkspaceUuid={wsWorkspaceUuid}
          errors={errors}
          setSubmitDisabled={setSubmitDisabled}
          formType={type}
          extensionUuid={extensionUuid}
        />
      ))}
      {isFormEditable &&
        (type === "recruitmentForm" ? (
          <RecruitmentFormButtons
            values={values}
            setValues={setValues}
            setPageHandler={setPageHandler}
            setErrors={setErrors}
            submitDisabled={submitDisabled}
          />
        ) : (
          <AccessLinkPageFormButtons
            values={values}
            setValues={setValues}
            setPageHandler={setPageHandler}
            setErrors={setErrors}
            submitDisabled={submitDisabled}
          />
        ))}
    </div>
  );
}
