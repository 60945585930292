import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { API_URLS } from "src/utils/API_URLS";
import { capitalizeFirstLetter } from "src/utils/methods";
import "./ProfileDataPane.scss";

import { Pane } from "src/app/components/Pane/Pane";
import {
  PaneTopbar,
  PaneTopbarCol,
  PaneTopbarToolbar,
} from "src/app/components/Pane/PaneTopbar/PaneTopbar";

import { ProfileDataTab } from "src/app/Task/tabs/ProfileDataTab/ProfileDataTab";
import SocialProviderSelect from "src/app/Task/tabs/ProfileDataTab/components/SocialProviderSelect";
import Loader from "src/app/components/Loader/Loader";
import { PaneContainer } from "src/app/components/Pane/PaneContainer/PaneContainer";

import { Button } from "src/app/components/Button/Button";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { showToast } from "src/app/methods/showToast";
import AddCreatorsCDTWorkspaceProjectModal from "src/app/modals/AddCreatorsCDTWorkspaceProjectModal/AddCreatorsCDTWorkspaceProjectModal";
import { setCreateNewProjectData } from "src/redux/main/mainActions";
import { ReactComponent as CreatorIcon } from "src/images/creator-discovery.svg";
import { ReactComponent as ListIcon } from "src/images/list.svg";
import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";
import { ReactComponent as RocketIcon } from "src/images/rocket-grey.svg";
import { ReactComponent as TickIcon } from "src/images/tick-blue.svg";
import {
  createGlobalTasks,
  getGlobalTaskDetails,
} from "src/redux/creator-database/creatorDatabaseActions";
import { uuidv7 as uuid } from "uuidv7";
import { navigateToCreateNewProject } from "src/app/SettingsModal/methods";
import { PreviewSettingsDropdown } from "src/app/components/GlobalTaskDetails/PreviewSettingsDropdown/PreviewSettingsDropdown";
import { CreatorShowcaseContext } from "src/app/CreatorShowcase/utils";
import Tabs from "src/app/components/Tabs/Tabs";
import Tab from "src/app/components/Tabs/Tab";
import {
  Performance,
  PerformanceContext,
} from "src/app/components/GlobalTaskDetails/tabs/Performance/Performance";
import { GlobalActivityTab } from "src/app/components/GlobalTaskDetails/tabs/GlobalActivityTab/GlobalActivityTab";
import { convertToLocalTimezone } from "src/app/methods/convertToLocalTimezone";
import { GlobalData } from "src/app/components/GlobalTaskDetails/tabs/GlobalData/GlobalData";
import { GlobalTaskSocialData } from "src/app/components/GlobalTaskDetails/tabs/GlobalTaskSocialData/GlobalTaskSocialData";
import {
  InitialPerformanceFilters,
  PerformanceFilters,
} from "src/app/components/GlobalTaskDetails/tabs/Performance/components/PerformanceFilters/PerformanceFilters";
import { PerformanceFiltersButton } from "src/app/components/GlobalTaskDetails/tabs/Performance/components/PerformanceFilters/components/PerformanceFiltersButton/PerformanceFiltersButton";
import { UserNotFound } from "./partials/UserNotFound/UserNotFound";
import { UserInfo } from "./partials/UserInfo/UserInfo";
import useInterval from "../../../utils/useInterval";
import CONSTS from "../../helpers/consts";
import { ExportDropdown } from "./partials/ExportDropdown/ExportDropdown";
import {
  deleteInfluencersFromList,
  getInfluencersListData,
} from "../../audiences-manager/audiencesManagerActions";
import {
  generateSelectedInfluencersWithSocialProfiles,
  selectedCreatorsPayload,
} from "../../audiences-manager/views/CreatorDiscoveryToolActionBar";
import { ActiveProfileDataTab } from "./utils";

function ProfileDataPane({
  activeTab,
  listId,
  refreshAudience,
  addToAudience,
  setSelectedInfluencers,
  influencersInList,
  influencersInListUuids,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const activeWorkspaceUuid = useSelector(
    (state) => state.mainReducer.activeWorkspaceUuid,
  );
  const globalTaskDetails = useSelector(
    (state) => state.creatorDatabaseReducer.globalTaskDetails,
  );
  const projectsList = useSelector(
    (state) => state.projectReducer.projectsList,
  );
  const membersList = useSelector((state) => state.projectReducer.membersList);

  const { socialProvider, socialUsername } = useParams();
  const searchParams = new URLSearchParams(location.search);

  const [selectedSocialProvider, setSelectedSocialProvider] = useState({
    value: socialProvider,
    label: (
      <IDHFormattedMessage
        id={`front_${socialProvider}`}
        defaultMessage={capitalizeFirstLetter(socialProvider)}
      />
    ),
  });
  const [profileData, setProfileData] = useState(null);
  const [originProfile, setOriginProfile] = useState(null);
  const [paneState, setPaneState] = useState("loading");
  const [activeProfileTab, setActiveProfileTab] = useState(
    ActiveProfileDataTab.SocialData,
  );
  const [performanceData, setPerformanceData] = useState([]);
  const [performanceFilters, setPerformanceFilters] = useState(
    InitialPerformanceFilters,
  );
  const [showFilters, setShowFilters] = useState(false);
  const [dateCreated, setDateCreated] = useState(null);
  const [
    showModalAddCreatorsToWorkspaceProject,
    setShowModalAddCreatorsToWorkspaceProject,
  ] = useState(false);

  const selectedInfluencersUrls = [profileData?.user_data?.socialProfileUrl];
  const userData = [profileData?.user_data];
  const disableAddToAudience = Boolean(
    influencersInList?.find((item) => item.userId === profileData?.hashUserId),
  );

  const attempts = useRef(10);
  const [shouldSync, setShouldSync] = useState();

  const creatorId = useMemo(() => {
    if (activeTab === "results")
      return profileData?.user_data?.socialProfileUrl || "";
    if (!influencersInListUuids || !profileData?.hashUserId) return "";
    return influencersInListUuids[profileData?.hashUserId] || "";
  }, [profileData, influencersInListUuids]);

  useEffect(() => {
    if (profileData?.wsGlobalTaskUuid) {
      dispatch(getGlobalTaskDetails(profileData.wsGlobalTaskUuid));
    }
  }, [profileData?.wsGlobalTaskUuid]);

  useEffect(() => {
    if (globalTaskDetails?.created) {
      const dateValue = globalTaskDetails.created.date;
      if (dateValue !== null) {
        setDateCreated(convertToLocalTimezone(dateValue));
      }
    }
  }, [globalTaskDetails?.created]);

  const taskOwner = useMemo(() => {
    return membersList?.find(
      (member) => member.id === globalTaskDetails?.ownerWsMemberUuid,
    );
  }, [globalTaskDetails?.ownerWsMemberUuid, membersList]);

  const checkProfileData = async () => {
    if (attempts.current > 0) {
      try {
        const {
          data: {
            content: { hasFreshData },
          },
        } = await axios.post(CONSTS.checkProfileData, {
          hashUserId: profileData.hashUserId,
          requestTime: profileData.lastUserSync.requestTime.date,
        });
        if (hasFreshData) setShouldSync(false);
      } catch (e) {
        console.error(e);
      }
      attempts.current--;
    }
  };

  useInterval(
    () => {
      checkProfileData();
    },
    shouldSync ? 5000 : null,
  );

  const closePane = () => {
    const pathNameWithoutProfile =
      window.location.pathname.split("/profile/")[0];

    searchParams.delete("origin");

    history.push({
      pathname: pathNameWithoutProfile,
      search: `?${searchParams.toString()}`,
    });
  };

  const getSocialUsernameByProvider = (provider) =>
    originProfile
      ? originProfile.user_data.socialProviders[provider].socialUsername
      : socialUsername;

  const openProfile = () => {
    const socialUsername = getSocialUsernameByProvider(
      selectedSocialProvider.value,
    );
    const pathNameWithoutProfile =
      window.location.pathname.split("/profile/")[0];
    const pathNameWithProfile =
      `${pathNameWithoutProfile}/profile/` +
      `${selectedSocialProvider.value}/${socialUsername}`;

    const originParams = searchParams.get("origin");
    const originProvider = originParams?.split("/")[0];

    if (!originParams)
      searchParams.set(
        "origin",
        `${socialProvider}/${getSocialUsernameByProvider(socialProvider)}`,
      );

    if (originProvider === selectedSocialProvider.value)
      searchParams.delete("origin");

    history.push({
      pathname: pathNameWithProfile,
      search: `?${searchParams.toString()}`,
    });
  };

  const getOriginData = async () => {
    const originParams = searchParams.get("origin");
    const originProvider = originParams.split("/")[0];
    const originUsername = originParams.split("/")[1];

    const originUrl = API_URLS.getProfileData
      .replace(":workspaceUuid:", activeWorkspaceUuid)
      .replace(":socialProvider:", originProvider.replace("_", ""))
      .replace(":socialUsername:", originUsername);

    if (!originProfile) {
      axios.get(originUrl).then((r) => setOriginProfile(r.data.content));
      // .finally(() => setPaneState("notFoundWithOrigin"))
      // .catch((err2) => setPaneState("notFound"));
    }
  };

  const getProfileData = async (socialProvider) => {
    const url = API_URLS.getProfileData
      .replace(":workspaceUuid:", activeWorkspaceUuid)
      .replace(":socialProvider:", socialProvider.replace("_", ""))
      .replace(":socialUsername:", socialUsername);

    setPaneState("loading");
    axios
      .get(url)
      .then((r) => {
        const originData = searchParams.get("origin");
        setProfileData(r.data.content);

        if (!originProfile && !originData) setOriginProfile(r.data.content);
        setPaneState("origin");

        const { shouldProfileBeRefreshed } = r.data.content.lastUserSync;
        shouldProfileBeRefreshed && setShouldSync(true);
      })
      .catch((err) => {
        // tu trzeba zrobic ifa, ktory sprawdzi czy mamy origin czy po prostu not found
        const originParams = searchParams.get("origin");

        if (originParams) {
          setPaneState("notFoundWithOrigin");
        } else setPaneState("notFound");
      });
  };

  // This useEffect is used to get profile data at the beginning and after social provider change
  useEffect(() => {
    if (selectedSocialProvider.value !== socialProvider) openProfile();
  }, [selectedSocialProvider]);

  useEffect(() => {
    if (shouldSync === false) {
      showToast(
        "info",
        <IDHFormattedMessage id="ws_info" defaultMessage="Info" />,
        <IDHFormattedMessage
          id="front_updating_user_profile"
          defaultMessage="Updating user profile..."
        />,
      );
      getProfileData(socialProvider);
      attempts.current = 10;
    }
  }, [shouldSync]); // trigger refresh

  useEffect(() => {
    setProfileData(null);
    setShouldSync(null);
    setSelectedSocialProvider({
      value: socialProvider,
      label: (
        <IDHFormattedMessage
          id={`front_${socialProvider}`}
          defaultMessage={capitalizeFirstLetter(socialProvider)}
        />
      ),
    });
    const originParams = searchParams.get("origin");
    if (originParams) {
      getOriginData();
    }

    getProfileData(socialProvider);
  }, [socialProvider, socialUsername]);

  const dispatchDataAndNavigateToCreateNewProject = () => {
    dispatch(
      setCreateNewProjectData({
        selectedCreatorsPayload: selectedCreatorsPayload(
          selectedInfluencersUrls,
          userData,
        ),
      }),
    );
    navigateToCreateNewProject(history, activeWorkspaceUuid);
  };

  const addCreatorsToDatabase = () => {
    const { user_data } = profileData;
    const creatorData = {
      uuid: uuid(),
      name: user_data.username,
      socialProfiles: Object.keys(user_data.socialProviders).map((key) => {
        const { socialProvider, socialUsername, socialProfileUrl } =
          user_data.socialProviders[key];
        return {
          socialProvider,
          socialUsername,
          socialProfileUrl,
        };
      }),
    };
    showToast(
      "success",
      <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
      <IDHFormattedMessage
        id="ws_creators_added_to_database"
        defaultMessage="Creators successfully added to Database"
      />,
    );
    dispatch(createGlobalTasks(activeWorkspaceUuid, "creator", [creatorData]));
    refreshAudience();
  };

  const handleAddToAudience = async () => {
    await setSelectedInfluencers([profileData.hashUserId]);
    await addToAudience();
    showToast(
      "success",
      <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
      <IDHFormattedMessage
        id="ws_creator_added_to_list"
        defaultMessage="Creator successfully added to list"
      />,
    );
  };

  const deleteInfluencerFromList = () => {
    dispatch(deleteInfluencersFromList([profileData?.hashUserId], listId));
    dispatch(getInfluencersListData(listId));
    closePane();
  };

  const socialProfiles = originProfile?.user_data
    ? Object.keys(originProfile.user_data.socialProviders).map((e) => ({
        ...originProfile.user_data.socialProviders[e],
      }))
    : [];

  const renderTab = () => {
    if (!socialProfiles?.length > 0) {
      return <Loader />;
    }

    switch (activeProfileTab) {
      case ActiveProfileDataTab.General:
        return (
          <GlobalData
            taskId={globalTaskDetails.uuid}
            metadata={globalTaskDetails.metadata}
            taskOwner={taskOwner}
            dateCreated={dateCreated}
          />
        );

      case ActiveProfileDataTab.SocialData:
        if (profileData.wsGlobalTaskUuid) {
          return (
            <GlobalTaskSocialData
              data={{
                uuid: globalTaskDetails.uuid,
                title: globalTaskDetails.title,
              }}
              socialProfiles={globalTaskDetails?.socialProfiles}
            />
          );
        }

        return (
          <ProfileDataTab
            socialProfiles={socialProfiles}
            profileNotFound={false}
            profileData={profileData}
            selectedSocialProvider={selectedSocialProvider}
            hasAnySocialProfile
          />
        );

      case ActiveProfileDataTab.Activity:
        return <GlobalActivityTab taskId={globalTaskDetails.uuid} />;

      case ActiveProfileDataTab.Performance:
        return (
          <Performance
            context={PerformanceContext.GlobalTaskDetails}
            taskId={globalTaskDetails.uuid}
            data={performanceData}
            setData={setPerformanceData}
            filters={performanceFilters}
          />
        );
    }
  };

  const renderContent = () => {
    switch (paneState) {
      case "origin":
        return profileData ? (
          <>
            <PaneTopbar>
              <PaneTopbarCol>
                <UserInfo userData={profileData.user_data} />
              </PaneTopbarCol>
              <PaneTopbarCol className="pane-top-bar__col--right">
                <PaneTopbarToolbar hidePane={closePane}>
                  <Dropdown toRight>
                    {(isOpen, setIsOpen) => (
                      <>
                        <Button
                          size="small"
                          variant="blue"
                          onClick={() => setIsOpen(true)}
                        >
                          <PlusIcon className="icon-left" />
                          <IDHFormattedMessage
                            id="front_add_to"
                            defaultMessage="Add to..."
                          />
                        </Button>
                        <DropdownMenu isOpen={isOpen}>
                          <DropdownMenuItem
                            onClick={() => {
                              addCreatorsToDatabase();
                              setIsOpen(false);
                            }}
                            disabled={profileData.wsGlobalTaskUuid}
                          >
                            <CreatorIcon />
                            <IDHFormattedMessage
                              id="front_creator_database"
                              defaultMessage="Creator Database"
                            />
                            {profileData.wsGlobalTaskUuid && <TickIcon />}
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => {
                              setShowModalAddCreatorsToWorkspaceProject(true);
                              setIsOpen(false);
                            }}
                          >
                            <RocketIcon />
                            <IDHFormattedMessage
                              id="front_campaign"
                              defaultMessage="Campaign"
                            />
                          </DropdownMenuItem>
                          {activeTab === "results" && listId && (
                            <DropdownMenuItem
                              onClick={handleAddToAudience}
                              disabled={disableAddToAudience}
                            >
                              <ListIcon />
                              <IDHFormattedMessage
                                id="front_creator_list"
                                defaultMessage="Creator list"
                              />
                            </DropdownMenuItem>
                          )}
                        </DropdownMenu>
                      </>
                    )}
                  </Dropdown>

                  <PreviewSettingsDropdown
                    showcaseData={{
                      history,
                      context:
                        activeTab === "selected"
                          ? CreatorShowcaseContext.CreatorDiscoveryList
                          : CreatorShowcaseContext.CreatorDiscoverySearch,
                      creatorId,
                      ids: [creatorId],
                      workspaceId: activeWorkspaceUuid,
                    }}
                    linkToCopy={window.location.href}
                    removeData={
                      activeTab === "selected"
                        ? {
                            objectNames: [socialUsername],
                            removeFunction: deleteInfluencerFromList,
                          }
                        : undefined
                    }
                  />
                </PaneTopbarToolbar>
                {!profileData.wsGlobalTaskUuid && (
                  <SocialProviderSelect
                    socialProfiles={socialProfiles}
                    setSelectedSocialProvider={setSelectedSocialProvider}
                    selectedSocialProvider={selectedSocialProvider}
                  />
                )}
              </PaneTopbarCol>
            </PaneTopbar>

            <PaneContainer noBorder={Boolean(profileData.wsGlobalTaskUuid)}>
              {profileData.wsGlobalTaskUuid && (
                <Tabs>
                  <Tab
                    tabText={
                      <IDHFormattedMessage
                        id="ws_general"
                        defaultMessage="General"
                      />
                    }
                    active={activeProfileTab === ActiveProfileDataTab.General}
                    onClick={() =>
                      setActiveProfileTab(ActiveProfileDataTab.General)
                    }
                  />
                  <Tab
                    tabText={
                      <IDHFormattedMessage
                        id="ws_social_data"
                        defaultMessage="Social data"
                      />
                    }
                    active={
                      activeProfileTab === ActiveProfileDataTab.SocialData
                    }
                    onClick={() =>
                      setActiveProfileTab(ActiveProfileDataTab.SocialData)
                    }
                  />
                  <Tab
                    tabText={
                      <IDHFormattedMessage
                        id="ws_activity"
                        defaultMessage="Activity"
                      />
                    }
                    active={activeProfileTab === ActiveProfileDataTab.Activity}
                    onClick={() =>
                      setActiveProfileTab(ActiveProfileDataTab.Activity)
                    }
                  />
                  <Tab
                    tabText={
                      <IDHFormattedMessage
                        id="ws_performance"
                        defaultMessage="Performance"
                      />
                    }
                    active={
                      activeProfileTab === ActiveProfileDataTab.Performance
                    }
                    onClick={() =>
                      setActiveProfileTab(ActiveProfileDataTab.Performance)
                    }
                  />

                  {activeProfileTab === ActiveProfileDataTab.Performance &&
                  performanceData.length ? (
                    <PerformanceFiltersButton
                      openFilters={() => setShowFilters(true)}
                      filters={performanceFilters}
                    />
                  ) : null}
                </Tabs>
              )}

              {renderTab()}
            </PaneContainer>

            {showModalAddCreatorsToWorkspaceProject && (
              <AddCreatorsCDTWorkspaceProjectModal
                onClose={() => setShowModalAddCreatorsToWorkspaceProject(false)}
                projectsList={projectsList}
                activeWorkspaceUuid={activeWorkspaceUuid}
                selectedCreators={generateSelectedInfluencersWithSocialProfiles(
                  selectedInfluencersUrls,
                  userData,
                )}
                openCreateNewProjectModal={
                  dispatchDataAndNavigateToCreateNewProject
                }
              />
            )}

            <PerformanceFilters
              setFiltersVisible={setShowFilters}
              filtersVisible={showFilters}
              performanceData={performanceData}
              filters={performanceFilters}
              setFilters={setPerformanceFilters}
            />
          </>
        ) : (
          <Loader />
        );
      case "notFoundWithOrigin":
        return originProfile ? (
          <>
            <PaneTopbar>
              <PaneTopbarCol>
                <UserInfo userData={originProfile.user_data} />
              </PaneTopbarCol>
              <PaneTopbarCol className="pane-top-bar__col--right">
                <PaneTopbarToolbar hidePane={closePane}>
                  {socialProvider == "snapchat" && (
                    <ExportDropdown hashUserId={originProfile.hashUserId} />
                  )}
                </PaneTopbarToolbar>
                <SocialProviderSelect
                  socialProfiles={socialProfiles}
                  setSelectedSocialProvider={setSelectedSocialProvider}
                  selectedSocialProvider={selectedSocialProvider}
                />
              </PaneTopbarCol>
            </PaneTopbar>

            <UserNotFound
              goBack={() => {
                setProfileData(null);
                history.goBack();
                openProfile();
              }}
            />
          </>
        ) : (
          <Loader />
        );
      case "notFound":
        return (
          <UserNotFound
            closePane={closePane}
            goBack={() => {
              setProfileData(null);
              history.goBack();
              openProfile();
            }}
          />
        );
      case "loading":
      default:
        return <Loader />;
    }
  };

  return (
    <Pane
      showPane
      hidePane={closePane}
      width={798}
      className="profile-data-pane"
    >
      {renderContent()}
    </Pane>
  );
}

export { ProfileDataPane };
