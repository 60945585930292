import React, { useContext, useEffect, useState } from "react";
import "./SingleFilter.scss";
import { useIntl } from "react-intl";
import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import { translateMessage } from "src/app/methods/translateMessage";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { SingleSelectFilterOptions } from "../SingleSelectFilterOptions/SingleSelectFilterOptions";
import { SingleDatePickerRange } from "../SingleDatePickerRange/SingleDatePickerRange";
import { MemberSelect } from "../MemberSelect/MemberSelect";
import { SingleCheckboxFilter } from "../SingleCheckboxFilter/SingleCheckboxFilter";
import { SingleRangeBox } from "../SingleRangeBox/SingleRangeBox";
import { SinglePercentFilter } from "../SinglePercentFilter/SinglePercentFilter";
import { DictionaryElementFilterSelect } from "../DictionaryElementFilterSelect/DictionaryElementFilterSelect";
import { DictionaryFiltersContext } from "../../DictionaryFilters";
import SingleRatingFilter from "../SingleRatingFilter/SingleRatingFilter";

function SingleFilter({ col, sendFilter, defaultValue }) {
  const intl = useIntl();

  const [waitEvent, setWaitEvent] = useState(null);

  const [value, setValue] = useState(defaultValue?.from || defaultValue);

  const {
    dictionaryFilters: { filters },
  } = useContext(DictionaryFiltersContext);

  useEffect(() => {
    let timer = 0;
    if (waitEvent && sendFilter) {
      timer = setTimeout(() => {
        sendFilter(col.uuid, waitEvent.target.value);
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [waitEvent]);

  const onInputChange = (event) => {
    setValue(event.target.value);
    event.persist();
    setWaitEvent(event);
  };

  const renderInput = () => {
    switch (col.type) {
      case "text":
        return (
          <CustomInput
            value={value}
            onChange={onInputChange}
            type="text"
            placeholder={translateMessage({
              intl,
              id: "ws_enter_value",
              defaultMessage: "Enter value",
            })}
          />
        );
      case "boolVal":
        return (
          <SingleCheckboxFilter
            col={col}
            sendFilter={sendFilter}
            setWaitEvent={setWaitEvent}
          />
        );
      case "percent":
        return <SinglePercentFilter col={col} setWaitEvent={setWaitEvent} />;
      case "number":
      case "currency":
        return (
          <SingleRangeBox
            col={col}
            sendFilter={sendFilter}
            setWaitEvent={setWaitEvent}
          />
        );
      case "date":
        return <SingleDatePickerRange col={col} sendFilter={sendFilter} />;
      case "multiSelect":
      case "singleSelect":
        return (
          <SingleSelectFilterOptions
            col={col}
            sendFilter={sendFilter}
            displayNotSetOption={col.type === MetaFieldType.SingleSelect}
          />
        );

      case "member":
        return <MemberSelect col={col} sendFilter={sendFilter} />;

      case "dictionaryElement":
        return (
          <DictionaryElementFilterSelect
            col={col}
            sendFilter={sendFilter}
            filters={filters}
          />
        );
      case "rating":
        return <SingleRatingFilter col={col} sendFilter={sendFilter} />;

      default:
        return <div>{col.type}</div>;
    }
  };

  const disabledTypes = ["accessLink", "file"];
  if (disabledTypes.includes(col.type)) return <></>;

  return (
    <div className="single-filter">
      <CustomInputLabel variant="bold">{col.name}</CustomInputLabel>
      {renderInput()}
    </div>
  );
}

export default SingleFilter;
