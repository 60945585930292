import axios from "axios";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Dispatch as ReduxDispatch } from "redux";

import { refreshData } from "src/utils/uploadFunctionsUtils";
import { getProject } from "../../../redux";
import { API_URLS } from "../../../utils/API_URLS";
import { uploadMultipleFiles } from "../../../utils/uploadFunctions";
import { showToast } from "../../methods/showToast";
import { MetaFileData } from "../MultipleFileField/MultipleFileField";
import { addNewFilesFromFileList } from "./utils";

export const uploadProjectFile = async (
  e: ChangeEvent<HTMLInputElement>,
  dispatch: ReduxDispatch,
  fieldId: string,
  projectId: string,
  activeWorkspaceUuid: string,
  setFilesData: Dispatch<SetStateAction<MetaFileData[]>>,
) => {
  const target = e.target as HTMLInputElement;

  const newFiles = addNewFilesFromFileList(target.files as FileList);

  setFilesData((files: MetaFileData[]) => [...files, ...newFiles]);

  await uploadMultipleFiles({
    e,
    wsMetaFieldUuid: fieldId,
    wsProjectUuid: projectId,
    wsWorkspaceUuid: activeWorkspaceUuid,
    catchCallback: () => {
      setFilesData?.((prev) =>
        prev.filter((prevFile) =>
          Boolean(
            !newFiles.find((newFile) => newFile.assetId === prevFile.assetId),
          ),
        ),
      );
    },
    finallyCallback: () => {
      setTimeout(() => {
        refreshData({ dispatch, wsProjectUuid: projectId });
      }, 1000);
    },
  });
};

export const deleteProjectFile = async (
  assetId: string,
  fieldId: string,
  dispatch: ReduxDispatch,
  projectId: string,
  setFilesData: Dispatch<SetStateAction<MetaFileData[]>>,
  files: MetaFileData[],
) => {
  const url = API_URLS.deleteProjectMetaFieldFileAsset
    .replace(":fieldId:", fieldId)
    .replace(":assetId:", String(assetId));

  try {
    await axios.delete(url);
    setFilesData((list) => list.filter((item) => item.assetId !== assetId));

    showToast("success", "Success", "File has been deleted.");

    dispatch(getProject(projectId));
  } catch (error) {
    showToast("error", "Error", "Could not delete file.");
    setFilesData(files);
  }
};
