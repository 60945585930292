import { IntlShape } from "react-intl";
import { AppConfig } from "src/config/AppConfig";

interface Props {
  intl: IntlShape;
  id: string;
  defaultMessage?: string;
}

export function translateMessage({ intl, id, defaultMessage }: Props) {
  if (!id) return "";
  if (!AppConfig.isRunningLocalDevelopmentMode()) {
    if (AppConfig.getEnvironmentName() !== "live" && !(id in intl.messages)) {
      return id;
    }
  }

  return intl.formatMessage({ id, defaultMessage: defaultMessage || id });
}
