import React from "react";
import classNames from "classnames";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { AttachmentPlacement } from "src/types";
import { getImageSource, isThumbnailsExist } from "../../../../utils/methods";
import AttachmentSettingsDropdown from "../../../dropdowns/AttachmentSettingsDropdown/AttachmentSettingsDropdown";
import { ReactComponent as PlayIcon } from "../../../../images/play.svg";
import { ReactComponent as VideoIcon } from "../../../../images/video-attachment.svg";
import { DefaultAttachment } from "./DefaultAttachment";
import getAttachmentIcon from "../utils/getAttachmentIcon";
import { ReactComponent as DragAndDropIcon } from "../../../../images/dnd.svg";
import { ReactComponent as RemoveIcon } from "../../../../images/remove.svg";
import { AttachmentDetails } from "./ImageAttachment";

export function VideoAttachment({
  place,
  data,
  onGalleryAttachmentClick,
  setIsHovered,
  isHovered,
  taskType,
  fileExtension,
  handleRemoveAttachment,
}) {
  const downloadUrl =
    data.publicDownloadUrl || data.downloadUrl || data.publicPreviewUrl;

  if (place === AttachmentPlacement.Comment) {
    return (
      <>
        {isThumbnailsExist(data, "small") === true ? (
          <div className="attachment-wrapper">
            <div
              className="attachment-video__div"
              onClick={onGalleryAttachmentClick}
            >
              <button className="attachment-video__play-icon-btn">
                <PlayIcon className="attachment-video__play-icon" />
              </button>
              <img
                src={getImageSource(data, "small")}
                className="comment-video__image"
                loading="lazy"
              />
            </div>
            <AttachmentDetails name={data.name} downloadUrl={downloadUrl} />
          </div>
        ) : (
          <DefaultAttachment
            data={data}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            place={place}
            onAttachmentClick={onGalleryAttachmentClick}
            getIcon={() => getAttachmentIcon(fileExtension)}
            fileExtension={fileExtension}
            taskType={taskType}
          />
        )}
      </>
    );
  }
  if (place === AttachmentPlacement.Dropdown) {
    return (
      <div
        className="attachment-wrapper"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onGalleryAttachmentClick}
      >
        {isThumbnailsExist(data, "small") === true ? (
          <div
            className={classNames(
              "attachment-video",
              "attachment-video__small",
              {
                "attachment-video--hovered": isHovered,
              },
            )}
            style={{
              backgroundImage: `url(${getImageSource(data, "small")})`,
            }}
            onClick={onGalleryAttachmentClick}
          >
            <PlayIcon className="attachment-video__play-icon" />
          </div>
        ) : (
          <VideoIcon
            className="extension-icon"
            onClick={onGalleryAttachmentClick}
          />
        )}
        {data.parentUuid && isHovered && (
          <AttachmentSettingsDropdown
            downloadUrl={data.downloadUrl}
            commentId={data.parentUuid}
            assetId={data.id}
            taskType={taskType}
            isImage
          />
        )}
      </div>
    );
  }
  if (place === AttachmentPlacement.InlineWithLabel) {
    return (
      <div
        className={classNames(
          "attachment-wrapper",
          "attachment-wrapper__inline",
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onGalleryAttachmentClick}
      >
        {isThumbnailsExist(data, "small") === true ? (
          <div
            className={classNames("attachment-video", {
              "attachment-video--hovered": isHovered,
            })}
            style={{
              backgroundImage: `url(${getImageSource(data, "small")})`,
            }}
            onClick={onGalleryAttachmentClick}
          >
            <PlayIcon className="attachment-video__play-icon__small" />
          </div>
        ) : (
          <VideoIcon
            className="extension-icon"
            onClick={onGalleryAttachmentClick}
          />
        )}
        <div className="attachment-file__details">
          <div
            className={classNames("attachment-file__name", {
              "attachment-file__name--in-comment":
                place === AttachmentPlacement.Comment,
            })}
          >
            {data.name}
          </div>
          <div className="attachment-file__download-wrapper">
            <div className="attachment-file__type">
              {fileExtension.toUpperCase()}
            </div>
            {(data.downloadUrl || data.publicPreviewUrl || data.publicUrl) && (
              <a
                className="attachment-file__download"
                href={downloadUrl}
                download
                onClick={(e) => {
                  e.stopPropagation();
                  !downloadUrl && e.preventDefault();
                }}
              >
                <IDHFormattedMessage
                  id="ws_download"
                  defaultMessage="Download"
                />
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (place === AttachmentPlacement.AttachmentPreview) {
    return (
      <div
        className={classNames(
          "attachment-wrapper",
          "attachment-wrapper__preview-video",
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onGalleryAttachmentClick}
      >
        <video
          width="64"
          height="64"
          controls="false"
          className="attachment-preview-video"
        >
          <source src={data.publicUrl} type={data.type} />
        </video>
        <div
          className={classNames("overlay", {
            "overlay--visible": isHovered,
          })}
        />
        <DragAndDropIcon
          className={classNames("dnd-icon dnd-icon--white", {
            "dnd-icon--visible": isHovered,
          })}
        />
        <RemoveIcon onClick={handleRemoveAttachment} className="remove-icon" />
      </div>
    );
  }

  if (place === AttachmentPlacement.ContentProposal) {
    return (
      <>
        {isThumbnailsExist(data, "small") === true ? (
          <div className="attachment-wrapper">
            <div
              className="attachment-video__div"
              onClick={onGalleryAttachmentClick}
            >
              <button className="attachment-video__play-icon-btn">
                <PlayIcon className="attachment-video__play-icon" />
              </button>
              <img
                src={getImageSource(data, "small")}
                className="comment-video__image"
                loading="lazy"
              />
            </div>
            <AttachmentDetails name={data.name} downloadUrl={downloadUrl} />
          </div>
        ) : (
          <DefaultAttachment
            data={data}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            place={place}
            onAttachmentClick={onGalleryAttachmentClick}
            getIcon={() => getAttachmentIcon(fileExtension)}
            fileExtension={fileExtension}
            taskType={taskType}
          />
        )}
      </>
    );
  }

  return isThumbnailsExist(data, "small") === true ? (
    <div
      className="attachment-wrapper"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onGalleryAttachmentClick}
    >
      <div
        className={classNames("attachment-video", {
          "attachment-video--hovered": isHovered,
        })}
        style={{
          backgroundImage: `url(${getImageSource(data, "small")})`,
        }}
        onClick={onGalleryAttachmentClick}
      >
        <PlayIcon className="attachment-video__play-icon__small" />
      </div>

      {data.parentUuid && isHovered && (
        <AttachmentSettingsDropdown
          downloadUrl={data.downloadUrl}
          commentId={data.parentUuid}
          assetId={data.id}
          taskType={taskType}
          isImage
        />
      )}
    </div>
  ) : place === AttachmentPlacement.Attachments ? (
    <DefaultAttachment
      data={data}
      isHovered={isHovered}
      setIsHovered={setIsHovered}
      place={place}
      onAttachmentClick={onGalleryAttachmentClick}
      getIcon={() => getAttachmentIcon(fileExtension)}
      fileExtension={fileExtension}
      taskType={taskType}
    />
  ) : (
    <div
      className={classNames("attachment-video__placeholder", {
        "attachment-video__placeholder-border":
          place === AttachmentPlacement.Attachments,
      })}
      onClick={onGalleryAttachmentClick}
    >
      <VideoIcon className="extension-icon" />
    </div>
  );
}
