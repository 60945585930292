import React, { useState } from "react";
import "./TableSettingsDropdown.scss";

import classNames from "classnames";
import { useSelector } from "react-redux";

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { tableDataType } from "src/app/components/Table/Table";
import { ImageOption } from "src/app/components/CustomSelect/CustomSelect";
import { RootState } from "src/redux/reducers";
import { ReactComponent as SettingsIcon } from "../../../images/settings.svg";
import { ReactComponent as SettingsShapeIcon } from "../../../images/settings-dropdown/settings-shape.svg";
import { ReactComponent as LayoutIcon } from "../../../images/settings-dropdown/layout.svg";
import { ReactComponent as TableIcon } from "../../../images/settings-dropdown/table.svg";
import { ReactComponent as BoardIcon } from "../../../images/settings-dropdown/board.svg";
import { ReactComponent as ChevronDownIcon } from "../../../images/chevron-down.svg";

import CustomizeFieldsDropdown from "../CustomizeFieldsDropdown/CustomizeFieldsDropdown";

import { ReactComponent as SelectedIcon } from "../../../images/selected.svg";

const viewOptions = [
  {
    value: "list",
    label: (
      <ImageOption icon={TableIcon}>
        <IDHFormattedMessage id="ws_table" defaultMessage="Table" />
      </ImageOption>
    ),
  },
  {
    value: "board",
    label: (
      <ImageOption icon={BoardIcon}>
        <IDHFormattedMessage id="ws_board" defaultMessage="Board" />
      </ImageOption>
    ),
  },
];

export function TableSettingsDropdown(props: any) {
  const {
    setShowAddFieldModal,
    setShowFieldSetupModal,
    fieldData,
    setFieldData,
    fields,
    currentTab,
    setCurrentTab,
    permissions,
  } = props;

  const {
    projectReducer: { taskType },
  } = useSelector((state: RootState) => state);

  const [showLayoutOptions, setShowLayoutOptions] = useState(false);

  const renderTabTitle = () => {
    switch (currentTab.value) {
      case "list":
        return "Table";

      case "board":
        return "Board";

      default:
        return currentTab.value;
    }
  };

  return (
    <Dropdown className="table-settings-dropdown">
      {(isOpen, setIsOpen) => (
        <>
          <Button
            variant="light-grey"
            size="square"
            onClick={() => setIsOpen(true)}
          >
            <SettingsIcon />
          </Button>

          <DropdownMenu
            isOpen={isOpen}
            onClose={() => setShowLayoutOptions(false)}
          >
            <DropdownMenuItem
              className={classNames("table-settings-dropdown__layout", {
                "table-settings-dropdown__layout--active": showLayoutOptions,
              })}
              onClick={() => setShowLayoutOptions((v) => !v)}
            >
              <span className="table-settings-dropdown__layout-wrapper">
                <LayoutIcon />
                <IDHFormattedMessage id="ws_layout" defaultMessage="Layout" />
              </span>
              <span
                className={classNames("table-settings-dropdown__tab-wrapper", {
                  "table-settings-dropdown__tab-wrapper--rotated":
                    showLayoutOptions,
                })}
              >
                {renderTabTitle()}
                <ChevronDownIcon />
              </span>
            </DropdownMenuItem>

            <div
              className={classNames("table-settings-dropdown__layout-options", {
                "table-settings-dropdown__layout-options--visible":
                  showLayoutOptions,
              })}
            >
              {viewOptions.map((option: any, index: number) => (
                <DropdownMenuItem
                  key={option.value + index}
                  className="table-settings-dropdown__layout-options-item"
                  dark={option.value === currentTab.value}
                  onClick={() => {
                    setCurrentTab(option);
                    setIsOpen(false);
                  }}
                >
                  {option.label}
                  {option.value === currentTab.value && (
                    <SelectedIcon style={{ marginLeft: 8, marginRight: 0 }} />
                  )}
                </DropdownMenuItem>
              ))}
            </div>

            {permissions?.project.includes("task_meta_fields_management") && (
              <CustomizeFieldsDropdown
                setShowAddFieldModal={setShowAddFieldModal}
                setShowFieldSetupModal={setShowFieldSetupModal}
                fieldData={fieldData}
                setFieldData={setFieldData}
                fields={fields}
                dataType={tableDataType.Task}
                taskType={taskType}
                dropdownButton={
                  <DropdownMenuItem className="table-settings-dropdown__settings">
                    <SettingsShapeIcon />
                    <IDHFormattedMessage
                      id="ws_settings"
                      defaultMessage="Settings"
                    />
                  </DropdownMenuItem>
                }
                subheader={
                  <IDHFormattedMessage
                    id="ws_columns"
                    defaultMessage="Columns"
                  />
                }
                addBtnText={
                  <IDHFormattedMessage
                    id="ws_add_column"
                    defaultMessage="Add column"
                  />
                }
              />
            )}
          </DropdownMenu>
        </>
      )}
    </Dropdown>
  );
}
