import React from "react";
import "./PaneTopbar.scss";

import { ReactComponent as CloseIcon } from "src/images/cross-alt.svg";
import classNames from "classnames";

function PaneTopbar(props) {
  const { children } = props;

  return <div className="pane-top-bar">{children}</div>;
}

function PaneTopbarLeft(props) {
  const { children } = props;
  return <div className="pane-top-bar__left">{children}</div>;
}

function PaneTopbarCol({ className, children }) {
  return (
    <div className={classNames(["pane-top-bar__col", className])}>
      {children}
    </div>
  );
}

function PaneTopbarToolbar(props) {
  const { children, hidePane } = props;
  return (
    <div className="pane-top-bar__toolbar">
      {children}
      <CloseIcon className="pane-top-bar__toolbar-icon" onClick={hidePane} />
    </div>
  );
}

export { PaneTopbar, PaneTopbarLeft, PaneTopbarCol, PaneTopbarToolbar };
