import React from "react";
import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

interface EditCommentFieldButtonProps {
  onSaveChangesClick: () => void;
  onCancelClick: () => void;
  disabled: boolean;
}

function EditCommentFieldButton({
  onCancelClick,
  onSaveChangesClick,
  disabled,
}: EditCommentFieldButtonProps) {
  return (
    <div className="edit-comment-field__toolbar">
      <div className="edit-comment-field__buttons">
        <Button
          variant="light"
          size="medium"
          onClick={onCancelClick}
          className="edit-comment-field__cancel"
        >
          <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          variant="blue"
          size="medium"
          onClick={onSaveChangesClick}
          disabled={disabled}
        >
          <IDHFormattedMessage
            id="ws_save_changes"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </div>
  );
}

export default EditCommentFieldButton;
