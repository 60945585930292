import React from "react";

import { ReactComponent as PlusIcon } from "src/images/plus-dark-grey.svg";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import { Dropdown, DropdownMenu } from "../Dropdown/Dropdown";
import { Button } from "../Button/Button";

interface AddToDropdownProps {
  children: React.ReactNode;
}

export function AddToDropdown({ children }: AddToDropdownProps) {
  return (
    <Dropdown toBottom>
      {(isOpen, setIsOpen) => (
        <>
          <Button size="large" variant="white" onClick={() => setIsOpen(true)}>
            <PlusIcon />
            <IDHFormattedMessage id="ws_add_to" defaultMessage="Add to" />
            ...
          </Button>
          <DropdownMenu isOpen={isOpen}>{children}</DropdownMenu>
        </>
      )}
    </Dropdown>
  );
}
