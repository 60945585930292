import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as BioIcon } from "../../../../../images/profile-data/bio-new.svg";
import ProfileBox, {
  PBContent,
  PBHeader,
} from "../components/ProfileBox/ProfileBox";
import XssFilter from "../../../../components/XssFilter/XssFilter";

export function Bio(props) {
  const { block, description, height } = props;

  const showContent = !height || height > 1;

  return (
    <ProfileBox block={block}>
      <PBHeader
        icon={<BioIcon />}
        title={<IDHFormattedMessage id="ws_bio" defaultMessage="Bio" />}
        onShowcase={height}
      />
      {showContent && (
        <PBContent column>
          <div
            className="profile-box__bio-info"
            dangerouslySetInnerHTML={{
              __html: XssFilter(description),
            }}
          />
        </PBContent>
      )}
    </ProfileBox>
  );
}
