import React, { useState, useRef, ChangeEvent, useEffect } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import { TextBox } from "src/app/components/TextBox/TextBox";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { ReactComponent as UploadFileIcon } from "src/images/upload-file.svg";
import { ReactComponent as AddLinkIcon } from "src/images/attachment-transparent-blue.svg";
import AttachmentsField from "src/app/components/TextBox/components/AttachmentsField/AttachmentsField";
import { API_URLS } from "src/utils/API_URLS";
import { wsAxiosPost } from "src/helpers/wsAxios";
import {
  clearContentProposalFilterStatus,
  getTaskDetails,
  getWsContentProposalElementsSummary,
} from "src/redux";
import { Button } from "src/app/components/Button/Button";
import { translateMessage } from "src/app/methods/translateMessage";
import { TextBoxWrapper } from "src/app/components/TextBoxWrapper/TextBoxWrapper";
import { AttachmentMetaDataAndKey } from "../AddCommentField/AddCommentField";
import AddCommentFieldButton from "../AddCommentField/AddCommentFieldButton";
import { handleAddAttachments } from "../../utils/handleAddAttachments";
import { getAcceptedFileExtensions } from "../../Attachments/utils/getAcceptedFileExtensions";
import { ALLOWED_ATTACHMENT_EXTENSIONS } from "../../utils/constants";
import { CommentDisplayType } from "../../Task";
import scrollElementToBottom from "../../../../helpers/scrollElementToBottom";
import AddLinksModal from "./AddLinksModal";

export const getAttachmentsWithFile = (
  attachments: AttachmentMetaDataAndKey[],
) => {
  return attachments
    .map((attachmentItem) => {
      if (attachmentItem.attachment.externalUrl) {
        return {
          externalUrl: attachmentItem.attachment.externalUrl,
        };
      }
      if (!attachmentItem.file) return null;
      return {
        key: attachmentItem.key,
        name: attachmentItem.file.name,
        mimeType: attachmentItem.file.type,
        size: attachmentItem.file.size,
      };
    })
    .filter(Boolean);
};

interface AddContentProposalProps {
  activeWorkspaceUuid: string;
  hideContentProposalField: () => void;
  taskId: string;
  setContentProposalFieldNotSubmitted: (result: boolean) => void;
}

export default function AddContentProposalField({
  activeWorkspaceUuid,
  hideContentProposalField,
  taskId,
  setContentProposalFieldNotSubmitted,
}: AddContentProposalProps) {
  const [commentOnContent, setCommentOnContent] = useState("");
  const [caption, setCaption] = useState("");
  const [isCaptionOn, setIsCaptionOn] = useState(true);
  const [attachments, setAttachments] = useState<AttachmentMetaDataAndKey[]>(
    [],
  );
  const [isAddLinksModalOpen, setIsAddLinksModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const intl = useIntl();
  const dispatch = useDispatch();

  const toggleCaption = () => {
    setIsCaptionOn((prev) => !prev);
  };

  const clearInputValue = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleAddContentProposal = async () => {
    try {
      await wsAxiosPost(
        API_URLS.createContentProposal,
        {
          caption: isCaptionOn ? caption : null,
          comment: commentOnContent.length > 0 ? commentOnContent : null,
          wsTaskUuid: taskId,
          attachments: getAttachmentsWithFile(attachments),
        },
        undefined,
        () => {
          setIsLoading(true);
        },
        () => {
          hideContentProposalField();
          dispatch(getWsContentProposalElementsSummary(taskId));
          dispatch(clearContentProposalFilterStatus());
          setContentProposalFieldNotSubmitted(false);
        },
        () => {
          setIsLoading(false);
        },
      );
      await dispatch(getTaskDetails(taskId));
      setTimeout(() => {
        scrollElementToBottom("task__container");
      }, 1500);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddAttachmentFromInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = Array.from(e.target.files);

    if (!files.length) return;

    handleAddAttachments({
      files,
      commentId: "commentId",
      activeWorkspaceUuid,
      clearInputValue,
      setAttachments,
      commentType: CommentDisplayType.ContentProposal,
    });
  };

  useEffect(() => {
    if (!isCaptionOn) {
      setCaption("");
    }
  }, [isCaptionOn]);

  useEffect(() => {
    if ((isCaptionOn && caption.length > 0) || attachments.length > 0) {
      setContentProposalFieldNotSubmitted(true);
    } else {
      setContentProposalFieldNotSubmitted(false);
    }
  }, [caption, attachments]);

  return (
    <>
      <div className="add-comment-field add-content-proposal add-comment-field--focus">
        <TextBox
          className="add-comment-field__textarea add-comment-field__textarea--focus"
          setEncodedValue={setCommentOnContent}
          emojisToBottom
          enableEmoji
          enableMentions
          shouldDisableParentElementFocus
          placeholder={translateMessage({
            intl,
            id: "ws_write_a_comment_on_the_content",
            defaultMessage: "Write a comment on the content",
          })}
          editorHeaderChildren={
            <div className="add-content-proposal__caption">
              <div
                className={classNames(
                  "add-content-proposal__caption-switch",
                  !isCaptionOn && "add-content-proposal__caption-switch--off",
                )}
              >
                <CustomSwitch checked={isCaptionOn} onChange={toggleCaption} />
                <IDHFormattedMessage id="ws_caption" defaultMessage="Caption" />
              </div>
              {isCaptionOn && (
                <TextBoxWrapper
                  className="ws-input add-content-proposal__caption-textarea"
                  value={caption}
                  onChange={setCaption}
                  placeholder={translateMessage({
                    intl,
                    id: "ws_enter_a_caption",
                    defaultMessage: "Enter a caption",
                  })}
                  enableEmoji
                />
              )}
              <div className="add-content-proposal__add-content">
                <div className="add-content-proposal__buttons">
                  <label
                    htmlFor="add-content"
                    className="attachment-square add-attachment-square"
                  >
                    <UploadFileIcon className="attachment-square__add-icon" />
                    <IDHFormattedMessage
                      id="ws_upload_file"
                      defaultMessage="Upload file"
                    />
                    <input
                      ref={inputRef}
                      multiple
                      type="file"
                      id="add-content"
                      accept={getAcceptedFileExtensions(
                        ALLOWED_ATTACHMENT_EXTENSIONS,
                      )}
                      onChange={handleAddAttachmentFromInput}
                    />
                  </label>
                  <div
                    className="attachment-square add-attachment-square"
                    onClick={() => setIsAddLinksModalOpen(true)}
                  >
                    <AddLinkIcon className="attachment-square__add-icon" />
                    <IDHFormattedMessage
                      id="ws_paste_link"
                      defaultMessage="Paste link"
                    />
                  </div>
                </div>
                <AttachmentsField
                  attachments={attachments}
                  setAttachments={setAttachments}
                />
              </div>
            </div>
          }
          bottomRightButton={
            <>
              <Button
                variant="white-with-black-border"
                size="medium"
                className="cancel-button"
                onClick={hideContentProposalField}
              >
                <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
              </Button>
              <AddCommentFieldButton
                disabled={
                  (!caption.length && isCaptionOn) ||
                  attachments.some((attachment) => attachment.isLoading) ||
                  isLoading ||
                  (!isCaptionOn && attachments.length === 0)
                }
                onCommentClick={handleAddContentProposal}
                buttonText={
                  <IDHFormattedMessage id="ws_send" defaultMessage="Send" />
                }
              />
            </>
          }
          showButtons
        />
      </div>
      {isAddLinksModalOpen && (
        <AddLinksModal
          onClose={() => setIsAddLinksModalOpen(false)}
          setAttachments={setAttachments}
        />
      )}
    </>
  );
}
