import React from "react";
import "./DropdownPortalMenu.scss";
import classNames from "classnames";

interface DropdownPortalMenuProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  className?: string;
}

export function DropdownPortalMenu({
  className,
  children,
  ...rest
}: React.PropsWithChildren<DropdownPortalMenuProps>) {
  return (
    <div className={classNames(["dropdown-portal__menu", className])} {...rest}>
      {children}
    </div>
  );
}
