import React from "react";
import "../SearchSection.scss";

import { useHistory } from "react-router";
import classNames from "classnames";

import ProjectPlaceholder from "src/images/project-placeholder.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { getImageSource } from "../../../../../utils/methods";

export function ProjectsResults(props) {
  const { data, clearResults, showSeparator } = props;

  const history = useHistory();

  const openProject = (workspaceUuid, projectUuid) => {
    clearResults();
    history.push(`/workspace/${workspaceUuid}/projects/${projectUuid}`);
  };

  if (!data?.length) return null;

  return (
    <div
      className={classNames("search-section", {
        "search-section--with-separator": showSeparator,
      })}
    >
      <h4 className="search-section__header">
        <IDHFormattedMessage id="ws_campaigns" defaultMessage="Campaigns" />
      </h4>

      {data.map((item) => (
        <div
          key={item.wsProjectUuid}
          className="search-section__item"
          onClick={() => openProject(item.workspaceUuid, item.wsProjectUuid)}
        >
          <img
            className="search-section__item-cover search-section__item-cover--square"
            src={getImageSource(item?.projectCover, "tiny", ProjectPlaceholder)}
            alt="project-icon"
          />
          <span className="search-section__item-name">
            {item.wsProjectName}
          </span>
        </div>
      ))}
    </div>
  );
}
