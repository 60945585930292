import React, { SetStateAction, Dispatch } from "react";
import classNames from "classnames";

import { AttachmentPlacement } from "src/types";
import { ReactComponent as DragAndDropIcon } from "src/images/dnd.svg";
import { ReactComponent as RemoveIcon } from "src/images/remove.svg";
import { getFileFieldIcon } from "src/app/metafields/FileField/utils";
import { TooltipPopper } from "src/app/components/TooltipPopper/TooltipPopper";
import { AttachmentMetaData } from "../Attachment";

interface UrlAttachmentProps {
  data: AttachmentMetaData;
  place: AttachmentPlacement;
  handleRemoveAttachment: ((e: React.MouseEvent) => void) | undefined;
  isHovered: boolean;
  setIsHovered: Dispatch<SetStateAction<boolean>>;
  fileExtension: string;
  onGalleryAttachmentClick: ((e: React.MouseEvent) => void) | undefined;
}

export default function UrlAttachment({
  data,
  place,
  handleRemoveAttachment,
  isHovered,
  setIsHovered,
  fileExtension,
  onGalleryAttachmentClick,
}: UrlAttachmentProps) {
  const { externalUrl } = data;

  if (place === AttachmentPlacement.Dropdown) {
    return (
      <a
        href={externalUrl}
        title={externalUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="attachment-wrapper attachment-wrapper--dropdown"
      >
        {getFileFieldIcon(fileExtension)}
      </a>
    );
  }

  if (place === AttachmentPlacement.ContentProposal) {
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="attachment-wrapper"
      >
        <a href={externalUrl} target="_blank" rel="noopener noreferrer">
          <div className="attachment-file">
            <div className="attachment-file__url-icon">
              {getFileFieldIcon(fileExtension)}
            </div>
          </div>
        </a>
        <div className="attachment-file__details">
          <div className="attachment-file__download-wrapper">
            <a
              className="attachment-file__download"
              href={externalUrl}
              title={externalUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {externalUrl}
            </a>
          </div>
        </div>
      </div>
    );
  }

  if (place === AttachmentPlacement.ContentProposalSummary) {
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="attachment-wrapper"
        onClick={onGalleryAttachmentClick}
      >
        <div className="attachment-file">
          <div>{getFileFieldIcon(fileExtension)}</div>
          <div className="attachment-file__details">
            <div className="attachment-file__name">{externalUrl}</div>
          </div>
        </div>
      </div>
    );
  }

  if (place === AttachmentPlacement.AttachmentPreview) {
    return (
      <TooltipPopper content={externalUrl} className="TooltipedEllipsis">
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="attachment-wrapper attachment-wrapper__preview-url"
        >
          {!isHovered && getFileFieldIcon(fileExtension)}
          <DragAndDropIcon
            className={classNames("dnd-icon dnd-icon--gray", {
              "dnd-icon--visible": isHovered,
            })}
          />
          <RemoveIcon
            onClick={handleRemoveAttachment}
            className="remove-icon"
          />
        </div>
      </TooltipPopper>
    );
  }
  return null;
}
