export const actionPredefinedFilters = [
  {
    name: "Actions",
    metaFieldOptions: {
      singleSelectOptions: [
        {
          value: "completed",
          name: "Completed",
          label: "Completed",
        },
        {
          value: "incompleted",
          name: "Incompleted",
          label: "Incompleted",
        },
        {
          value: "my_actions",
          name: "My Actions",
          label: "My Actions",
        },
      ],
    },
  },
];
