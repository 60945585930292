import { MemberWithNotificationType, MetafieldAccessMode } from "./meta-field";
import { XtrmPaymentsDetails } from "./payments";

export enum ExtensionTypeEnum {
  CreatorDatabaseSubmissionForm = "creatorDatabaseForm",
  CreatorDatabaseMetaFieldList = "creatorDatabaseMetaFieldList",
  CreatorDatabaseMetaFieldMembers = "creatorDatabaseMetaFieldMembers",
  RecruitmentForm = "recruitmentForm",
  WsProjectLock = "wsProjectLock",
  ApiConnection = "apiConnection",
  XtrmPayments = "xtrmPayments",
}

type XtrmPaymentsSettings = {
  BeneficiaryID: string;
};

type CreatorDatabaseSubmissionFormSettings = {
  token: string;
  wsGlobalMetaFieldUuid: string;
};

type RecruitmentFormDetailsSettings = {
  token: string;
  wsTaskMetaFieldUuid: string;
};

type WsExtensionBase = {
  enabled: boolean;
  uuid: string;
  relatedUuid: string | null;
  wsWorkspaceUuid: string | null;
  wsProjectUuid: string | null;
};

export type WsExtensionCreatorDatabaseMetaFieldList = {
  type: ExtensionTypeEnum.CreatorDatabaseMetaFieldList;
} & WsExtensionBase;

export type WsExtensionXtrmPayments = {
  type: ExtensionTypeEnum.XtrmPayments;
  settings: XtrmPaymentsSettings;
} & WsExtensionBase;

export type WsExtensionRecruitmentForm = {
  type: ExtensionTypeEnum.RecruitmentForm;
  settings: RecruitmentFormDetailsSettings;
} & WsExtensionBase;

export type WsExtensionCreatorDatabaseSubmissionForm = {
  type: ExtensionTypeEnum.CreatorDatabaseSubmissionForm;
  settings: CreatorDatabaseSubmissionFormSettings;
} & WsExtensionBase;

export type WsExtensionWsProjectLock = {
  type: ExtensionTypeEnum.WsProjectLock;
  settings: [];
} & WsExtensionBase;

export type WsApiConnection = {
  type: ExtensionTypeEnum.ApiConnection;
  settings: {
    brandName: string;
    encodedData: string;
    token: string;
  };
} & WsExtensionBase;

export type WsExtension =
  | WsExtensionXtrmPayments
  | WsExtensionRecruitmentForm
  | WsExtensionWsProjectLock
  | WsExtensionCreatorDatabaseSubmissionForm
  | WsExtensionCreatorDatabaseMetaFieldList
  | any;

export type GlobalMetaFieldListItemData = {
  uuid: string;
  name: string;
  members: [];
};

export type GlobalMetaFieldDetails = {
  uuid: string;
  wsGlobalTaskMetaFieldUuid: string | null;
  members: string[];
  name: string;
};

export type RecruitmentFormDetailsCover = {
  parentUuid: string; // extension uuid
  name: string;
  mimeType: string;
  realName: string;
  size: number;
};

export type RecruitmentFormUserDataMembersWithNotification = {
  wsMemberUuid: string | null;
  wsTeamUuid: string | null;
  type: MemberWithNotificationType;
};

export type RecruitmentFormDetails = {
  uuid: string;
  wsProjectUuid: string | null;
  wsWorkspaceUuid: string | null;
  type: string;
  settings: RecruitmentFormDetailsSettings;
  enabled: boolean;
  recruitmentFormUuid: string;
  recruitmentFormMetaFields: RecruitmentFormMetaField[];
  recruitmentFormMetaFieldsAvailable: RecruitmentFormMetaFieldAvailable[];
  recruitmentFormCaption: string;
  recruitmentFormDescription: string;
  recruitmentIframeHtml: string;
  recruitmentFormUrl: string;
  wsAccessLinkCover: RecruitmentFormDetailsCover | string | null;
  recruitmentFormMembersWithNotification: RecruitmentFormUserDataMembersWithNotification[];
};

export type ExtensionDetails = RecruitmentFormDetails | XtrmPaymentsDetails;

export type RecruitmentFormMetaFieldAvailable = {
  uuid: string;
  type: string;
  key: string;
  name: string;
  isVisible: boolean;
  isRequired: boolean;
  wsMetaFieldDescription: string | null;
};

export type RecruitmentFormMetaField = {
  wsMetaFieldName: string;
  wsMetaFieldType: string;
  wsMetaFieldUuid: string;
  wsMetaFieldAccessMode: MetafieldAccessMode;
  wsMetaFieldIsRequired: boolean;
  wsMetaFieldIsNotDeletable: boolean;
  wsMetaFieldDescription: string | null;
};
