import React from "react";

import { ReactComponent as CloseIcon } from "src/images/close.svg";
import { NotificationType } from "src/redux/main/mainReducer";
import IDHFormattedMessage from "../../IDHFormattedMessage/IDHFormattedMessage";

import "./SingleNotification.scss";

interface SingleNotificationProps {
  type: NotificationType;
  message: string | undefined;
  onClose?: () => void;
}

export default function SingleNotification({
  type,
  message,
  onClose,
}: SingleNotificationProps) {
  switch (type) {
    case "reload":
      return (
        <div className="single-notification single-notification--green">
          <span className="single-notification__content">
            <span className="single-notification__text">
              <IDHFormattedMessage
                id="ws_to_maintain_the_best_experience_finish_task_and_reload_the_page"
                defaultMessage="To maintain the best experience please finish your current task
              and reload the page."
              />
            </span>
            <span
              className="single-notification__button single-notification__button--green"
              onClick={() => window.location.reload()}
            >
              <IDHFormattedMessage id="ws_reload" defaultMessage="Reload" />
            </span>
          </span>
          {onClose && (
            <CloseIcon
              className="single-notification__close"
              onClick={onClose}
            />
          )}
        </div>
      );
    case "offline":
      return (
        <div className="single-notification single-notification--red">
          <span className="single-notification__content">
            <span className="single-notification__text">
              <IDHFormattedMessage
                id="ws_offline_notification"
                defaultMessage="You are offline"
              />
            </span>
          </span>
          {onClose && (
            <CloseIcon
              className="single-notification__close"
              onClick={onClose}
            />
          )}
        </div>
      );
    case "online":
      return (
        <div className="single-notification single-notification--green">
          <span className="single-notification__content">
            <span className="single-notification__text">
              <IDHFormattedMessage
                id="ws_online_notification"
                defaultMessage="You are back online. Reload the page to see the latest changes."
              />
            </span>
            <span
              className="single-notification__button single-notification__button--green"
              onClick={() => window.location.reload()}
            >
              <IDHFormattedMessage id="ws_reload" defaultMessage="Reload" />
            </span>
          </span>
          {onClose && (
            <CloseIcon
              className="single-notification__close"
              onClick={onClose}
            />
          )}
        </div>
      );
    default:
      return (
        <div className="single-notification">
          {message}
          <CloseIcon className="single-notification__close" />
        </div>
      );
  }
}
