import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "src/app/components/Button/Button";
import {
  Breadcrumbs,
  BreadcrumbsItem,
} from "src/app/components/Breadcrumbs/Breadcrumbs";
import { getProfileFromLocation } from "src/app/pages/CreatorDiscoveryTool/utils/getProfileFromLocation";
import AudiencesSearch from "../AudiencesSearch/AudiencesSearch";

import { searchAudience } from "../audiencesManagerActions";
import Emoji from "../../components/Emoji/Emoji";
import PreloaderContainer from "../../base/preloader/PreloaderContainer";

class AudiencesSearchContainer extends React.Component {
  // TODO:: this view id to remove, but we need it for now to set up filters
  componentDidMount() {
    setTimeout(() => {
      this.searchHandler();
    }, 100);
  }

  close = () => {
    const { activeWorkspaceUuid } = this.props;
    this.props.history.replace({
      pathname: `/workspace/${activeWorkspaceUuid}/creator-discovery-tool/lists`,
      search: window.location.search.toString(),
    });
  };

  searchHandler = () => {
    const { activeWorkspaceUuid } = this.props;
    this.props.history.replace({
      pathname: `/workspace/${activeWorkspaceUuid}/creator-discovery-tool/search-results${getProfileFromLocation()}`,
      search: window.location.search.toString(),
    });
  };

  render() {
    return (
      <div className="audience-search-page">
        <PreloaderContainer />
        <Breadcrumbs>
          <BreadcrumbsItem
            text={
              <IDHFormattedMessage
                id="front_breadcrumbs_audiences_manager"
                defaultMessage="Audiences manager"
              />
            }
            to={`/workspace/${this.props.activeWorkspaceUuid}/creator-discovery-tool/lists`}
          />
          <BreadcrumbsItem
            text={
              <IDHFormattedMessage
                id="front_breadcrumbs_audiences_search"
                defaultMessage="Search for audience"
              />
            }
          />
        </Breadcrumbs>

        <div className="audience-search-wrapper">
          <div className="audience-search-actions">
            <Button transparent size="xlarge" onClick={this.close}>
              <IDHFormattedMessage id="front_close" defaultMessage="Close" />
            </Button>
            <Button size="xlarge" onClick={this.searchHandler}>
              <IDHFormattedMessage
                id="front_search_for_audience"
                defaultMessage="Search for audience"
              />
              &nbsp; &nbsp;
              <Emoji icon="🔍" />
            </Button>
          </div>
        </div>

        <AudiencesSearch />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeWorkspaceUuid: state.mainReducer.activeWorkspaceUuid,
  searchAudienceFilters: state.audiencesManagerReducer.searchAudienceFilters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    searchAudience: (searchData) => dispatch(searchAudience(searchData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AudiencesSearchContainer));
