import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SeeAsClientModeSwitch.scss";

import { injectIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { getCouponsList } from "src/redux/coupon/couponActions";
import { API_URLS } from "../../../utils/API_URLS";
import { wsAxiosPatch } from "../../../helpers/wsAxios";
import {
  getProject,
  getTasksAutocomplete,
  getTasksCounters,
  getTasksList,
} from "../../../redux";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import { CustomSwitch } from "../CustomSwitch/CustomSwitch";

function SeeAsClientModeSwitch(props) {
  const { intl } = props;

  const {
    mainReducer: { activeWorkspaceUuid },
    projectReducer: {
      taskType,
      projectBasicData: { projectId, seeAsClientMode, permissions },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [isSeeAsClientModeOn, setIsSeeAsClientModeOn] =
    useState(seeAsClientMode);

  useEffect(() => {
    setIsSeeAsClientModeOn(seeAsClientMode);
  }, [seeAsClientMode]);

  const toggleSwitch = async () => {
    await wsAxiosPatch(
      API_URLS.toggleSeeAsClientMode.replace(":wsProjectUuid:", projectId),
      {
        enabled: !isSeeAsClientModeOn,
      },
      translateMessage({
        intl,
        id: "ws_error_switching_client_mode",
        defaultMessage: "Error occurred during switching see as client mode",
      }),
    );
    setIsSeeAsClientModeOn((prev) => !prev);
    dispatch(getProject(projectId));
    dispatch(getTasksList(projectId, taskType));
    dispatch(getTasksCounters(projectId));
    dispatch(getTasksAutocomplete(projectId, "creator"));
    dispatch(getTasksAutocomplete(projectId, "content"));
    dispatch(getTasksAutocomplete(projectId, "publication"));
    dispatch(getCouponsList(activeWorkspaceUuid));
  };

  return (
    <div className="see-as-client-mode-switch">
      <CustomSwitch checked={isSeeAsClientModeOn} onChange={toggleSwitch} />
      <IDHFormattedMessage
        id="ws_see_it_as_a_client"
        defaultMessage="See it as a Client"
      />
    </div>
  );
}

export default injectIntl(SeeAsClientModeSwitch);
