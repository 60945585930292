import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios, { AxiosResponse } from "axios";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import {
  MemberWithNotificationType,
  RecruitmentFormUserDataMembersWithNotification,
} from "src/types";
import { API_URLS } from "src/utils/API_URLS";
import { showToast } from "src/app/methods/showToast";
import {
  WorkspacePermissionsEnum,
  ProjectPermissionsEnum,
} from "src/utils/PermissionsEnums";
import classNames from "classnames";
import { RootState } from "src/redux/reducers";
import AnimatedDiv from "../../../../../../../components/AnimatedDiv/AnimatedDiv";
import FormNotificationMemberTeam from "./FormNotificationMemberTeam";
import "./RecruitmentFormNotificationMembers.scss";
import RecruitmentFormNotificationMemberList from "./RecruitmentFormNotificationMemberList";
import { RemoveModal } from "../../../../../../../modals/RemoveModal/RemoveModal";
import { RecruitmentFormUserData } from "../../types";
import { showErrorToast } from "src/utils/methods";

interface RecruitmentFormNotificationsProps {
  setUserData: Dispatch<SetStateAction<RecruitmentFormUserData>>;
  userData: RecruitmentFormUserData;
  extensionUuid: string;
  permissions: { project: string[]; workspace: string[] };
}

export interface Field {
  label: string;
  metafieldType: string;
  value: string;
  isNotDeletable?: boolean;
}

export interface WsTeam {
  uuid: string;
  wsWorkspaceUuid: string;
  wsMembers: WsMember[];
}

export interface WsMember {
  uuid: string;
  name: string;
  email: string;
  avatarUrl: string;
}

export interface MemberData {
  label: string;
  name: string;
  email: string;
  value: string;
  avatarUrl: string | null;
  members: WsMember[];
  type: MemberWithNotificationType;
}

export function RecruitmentFormNotifications({
  setUserData,
  userData,
  extensionUuid,
  permissions,
}: RecruitmentFormNotificationsProps) {
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState<WsTeam[] | null>([]);
  const [deleteMemberData, setDeleteMemberData] = useState<MemberData | null>(
    null,
  );
  const [showConfirmDeleteMemberModal, setShowConfirmDeleteMemberModal] =
    useState<boolean>(false);

  const membersList = useSelector(
    (state: RootState) => state.projectReducer.membersList,
  );
  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );

  const handleUpdateMembersWithNotification = async (
    membersWithNotification: RecruitmentFormUserDataMembersWithNotification[],
  ) => {
    if (!extensionUuid) return;

    try {
      await axios.put(
        API_URLS.updateExtensionDetails.replace(
          ":wsExtensionUuid:",
          extensionUuid,
        ),
        {
          ...userData,
          recruitmentFormMetaFields: {
            ...userData.recruitmentFormMetaFields,
            membersWithNotification,
          },
          wsAccessLinkCover: null,
        },
      );
    } catch (err) {
      console.error(err);
      showErrorToast();
    }
  };

  const assignMultipleMember = (
    membersObjectList: RecruitmentFormUserDataMembersWithNotification[],
  ) => {
    setUserData((prevState) => {
      const tmp = [
        ...prevState.recruitmentFormMetaFields.membersWithNotification,
      ];

      membersObjectList?.map((memberObject) => {
        const objectExist =
          prevState.recruitmentFormMetaFields.membersWithNotification.find(
            (item) =>
              (memberObject.type === MemberWithNotificationType.team &&
                item.wsTeamUuid === memberObject.wsTeamUuid) ||
              (memberObject.type === MemberWithNotificationType.member &&
                item.wsMemberUuid === memberObject.wsMemberUuid),
          );

        if (!objectExist) {
          tmp.push(memberObject);
        }
      });

      if (
        permissions?.project?.includes(
          ProjectPermissionsEnum.MANAGE_PROJECT_CONFIGURATION,
        ) ||
        permissions?.workspace?.includes(
          WorkspacePermissionsEnum.CREATOR_DATABASE_CONFIGURATION,
        )
      ) {
        handleUpdateMembersWithNotification(tmp);
      }

      return {
        ...prevState,
        recruitmentFormMetaFields: {
          ...prevState.recruitmentFormMetaFields,
          membersWithNotification: tmp,
        },
      };
    });
  };

  const removeMemberFromNotification = (
    objectUuid: string,
    objectType: MemberWithNotificationType,
  ) => {
    setUserData((prevState) => {
      const filteredList =
        prevState.recruitmentFormMetaFields.membersWithNotification.filter(
          (item) =>
            !(
              (objectType === MemberWithNotificationType.team &&
                item.wsTeamUuid === objectUuid) ||
              (objectType === MemberWithNotificationType.member &&
                item.wsMemberUuid === objectUuid)
            ),
        );

      if (
        permissions?.project?.includes(
          ProjectPermissionsEnum.MANAGE_PROJECT_CONFIGURATION,
        ) ||
        permissions?.workspace?.includes(
          WorkspacePermissionsEnum.CREATOR_DATABASE_CONFIGURATION,
        )
      ) {
        handleUpdateMembersWithNotification(filteredList);
      }

      return {
        ...prevState,
        recruitmentFormMetaFields: {
          ...prevState.recruitmentFormMetaFields,
          membersWithNotification: filteredList,
        },
      };
    });
  };

  const getTeams = () => {
    setLoading(true);

    const url = API_URLS.teamsForWorkspace.replace(
      ":wsWorkspaceUuid:",
      activeWorkspaceUuid,
    );

    axios
      .get(url)
      .then((response: AxiosResponse) => {
        const { content } = response.data;
        setTeams(content);
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_something_went_wrong"
            defaultMessage="Something went wrong"
          />,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTeams();
  }, []);

  return (
    <>
      <div className="recruitment-form__description">
        <IDHFormattedMessage
          id="ws_recruitment_form_notifications_description"
          defaultMessage="Select members or team with access to notification after creator registration."
        />
      </div>

      <AnimatedDiv className={classNames("notification-members")}>
        <FormNotificationMemberTeam
          members={membersList}
          teams={teams}
          membersWithNotification={
            userData?.recruitmentFormMetaFields?.membersWithNotification
          }
          assignMultipleMember={assignMultipleMember}
          handleUpdateMembersWithNotification={
            handleUpdateMembersWithNotification
          }
        />
        <RecruitmentFormNotificationMemberList
          membersWithNotification={
            userData?.recruitmentFormMetaFields?.membersWithNotification
          }
          setShowConfirmDeleteMemberModal={setShowConfirmDeleteMemberModal}
          setDeleteMemberData={setDeleteMemberData}
          isLoading={loading}
          members={membersList}
          teams={teams}
          removeMemberFromNotification={removeMemberFromNotification}
        />
      </AnimatedDiv>

      {showConfirmDeleteMemberModal && (
        <RemoveModal
          objectNames={[deleteMemberData?.name ?? ""]}
          onClose={() => setShowConfirmDeleteMemberModal(false)}
          removeFunction={() => {
            if (deleteMemberData) {
              removeMemberFromNotification(
                deleteMemberData.value,
                deleteMemberData.type,
              );
            }
          }}
        />
      )}
    </>
  );
}
