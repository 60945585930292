import React from "react";
import classNames from "classnames";

import { uuidv7 as uuid } from "uuidv7";
import axios from "axios";
import { useDispatch } from "react-redux";
import { refreshAutomationList } from "src/redux/automation/automationActions";
import Tooltip from "src/app/components/Tooltip/Tooltip";
import { injectIntl } from "react-intl";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { getProject } from "src/redux";
import { showErrorToast } from "src/utils/methods";
import { CustomSwitch } from "../../../components/CustomSwitch/CustomSwitch";
import { API_URLS } from "../../../../utils/API_URLS";
import { ActivationButton } from "./ActivationButton";
import AutomationsEnum from "../../../SettingsModal/tabs/Automations/AutomationsEnum";

function CreatorDetectionList(props) {
  const { creatorDetectionList, projectBasicData, automationListLoading } =
    props;

  const dispatch = useDispatch();
  const { projectId, permissions } = projectBasicData;

  const reloadData = () => {
    dispatch(refreshAutomationList(projectId));
    dispatch(getProject(projectId));
  };

  const createAutomation = async () => {
    const url = API_URLS.createAutomation;
    const automationUuid = uuid();

    try {
      await axios.post(url, {
        uuid: automationUuid,
        wsProjectUuid: projectId,
        wsAutomationName: AutomationsEnum.CreatorDetectionRunnableAutomation,
      });
      reloadData();
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const toggleAutomation = async (automationData, enabled) => {
    const url = API_URLS.toggleAutomation;

    try {
      await axios.post(url, {
        uuid: automationData.uuid,
        enabled,
      });

      reloadData();
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const tooltipContent = (
    <IDHFormattedMessage
      id="ws_tooltip_add_creators_based_on_publications"
      defaultMessage="Automatically add new creators based on publications in your campaign."
    />
  );
  return !automationListLoading && creatorDetectionList.length > 0 ? (
    creatorDetectionList.map((automation) => (
      <div className="automations__list-element" key={automation.uuid}>
        <span className="automations__list-element-text">
          <span
            className={classNames("automations__list-element-status", {
              "automations__list-element-status--active": automation.enabled,
            })}
          />
          <IDHFormattedMessage
            id="ws_creators_detection"
            defaultMessage="Creators detection"
          />
          &nbsp;
          <Tooltip content={tooltipContent} />
        </span>

        <CustomSwitch
          checked={automation.enabled}
          onChange={(e) => toggleAutomation(automation, e.target.checked)}
          disabled={
            !permissions?.project?.includes("manage_project_configuration")
          }
        />
      </div>
    ))
  ) : (
    <div className="automations__list-element">
      <span className="automations__list-element-text">
        <span className={classNames("automations__list-element-status")} />
        <IDHFormattedMessage
          id="ws_creators_detection"
          defaultMessage="Creators detection"
        />
        &nbsp;
        <Tooltip content={tooltipContent} />
      </span>

      <CustomSwitch
        onChange={createAutomation}
        disabled={
          !permissions?.project?.includes("manage_project_configuration")
        }
      />
    </div>
  );
}

export default injectIntl(CreatorDetectionList);
