import { RouterChildContext } from "react-router";

export const openGlobalTaskDetails = (
  history: RouterChildContext["router"]["history"],
  taskId: string,
) => {
  const url = new URL(window.location.href);
  url.searchParams.set("displayGlobalTask", taskId);
  history.push(url.pathname + url.search);
};

export const closeGlobalTaskDetails = (
  history: RouterChildContext["router"]["history"],
) => {
  const url = new URL(window.location.href);
  url.searchParams.delete("displayGlobalTask");
  history.push(url.pathname + url.search);
};
