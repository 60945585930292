import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as FemaleIcon } from "../../../../../images/profile-data/female.svg";
import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { ProgressBarList } from "../components/ProgressBarList/ProgressBarList";

export function FemalePerAge(props) {
  const { audienceWomen, female, height, onProjectDetails } = props;

  return (
    <ProfileBox onProjectDetails={onProjectDetails}>
      <PBHeader
        icon={<FemaleIcon />}
        count={`${audienceWomen}%`}
        title={<IDHFormattedMessage id="ws_female" defaultMessage="Female" />}
        onShowcase={height}
        onProjectDetails={onProjectDetails}
      />
      {!height && (
        <div className="profile-box__heading">
          <IDHFormattedMessage
            id="ws_age_group_female"
            defaultMessage="Age group - Female"
          />

          <Tooltip
            center
            content={
              <IDHFormattedMessage
                id="ws_tooltip_age_group_female"
                defaultMessage="Audience demographics broken down by age groups and gender"
              />
            }
          />
        </div>
      )}
      <ProgressBarList
        data={female}
        barColor="#FD397A"
        backgroundColor="rgba(253, 57, 122, 0.1)"
        height={height}
      />
    </ProfileBox>
  );
}

export default FemalePerAge;
