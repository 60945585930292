import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { IntlShape, WrappedComponentProps, injectIntl } from "react-intl";

import { translateMessage } from "src/app/methods/translateMessage";
import { removeProjectByProjectUuid } from "src/redux";
import { showToast } from "../../methods/showToast";
import { API_URLS } from "../../../utils/API_URLS";
import { RemoveModal } from "../RemoveModal/RemoveModal";

interface Props extends WrappedComponentProps<"intl"> {
  onClose: () => void;
  wsProjectUuid: string;
  wsProjectName: string;
  wsWorkspaceUuid: string;
  singleProject?: boolean;
  intl: IntlShape;
}

const ConfirmationOfDeleteProjectModal: React.FC<Props> = (props) => {
  const {
    onClose,
    wsProjectUuid,
    wsProjectName,
    wsWorkspaceUuid,
    singleProject,
    intl,
  } = props;

  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const confirmDeleting = async () => {
    setLoading(true);

    try {
      await axios.delete(
        API_URLS.deleteProject.replace(":wsProjectUuid:", wsProjectUuid),
      );

      if (singleProject) {
        history.push(`/workspace/${wsWorkspaceUuid}/projects`);
      } else {
        dispatch(removeProjectByProjectUuid(wsProjectUuid));
      }
      showToast(
        "success",
        translateMessage({
          intl,
          id: "ws_success",
          defaultMessage: "Success",
        }),
        translateMessage({
          intl,
          id: "ws_campaign_queued_to_delete",
          defaultMessage: "Campaign has been queued to delete.",
        }),
      );
      onClose();
    } catch (err) {
      showToast(
        "error",
        translateMessage({
          intl,
          id: "ws_error",
          defaultMessage: "Error",
        }),
        translateMessage({
          intl,
          id: "ws_error_on_deleting_campaign",
          defaultMessage: "Error on deleting campaign.",
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <RemoveModal
      onClose={onClose}
      objectNames={[wsProjectName]}
      removeFunction={confirmDeleting}
      loading={loading}
    />
  );
};

export default injectIntl(ConfirmationOfDeleteProjectModal);
