import React, { useEffect, useState } from "react";
import { CustomDateRange } from "src/app/components/CustomDateRange/CustomDateRange";

export function DatePickerFilter({ name, filters, updateFilter }) {
  const [waitEvent, setWaitEvent] = useState(null);

  const [value, setValue] = useState(filters[name]?.from);
  const [value2, setValue2] = useState(filters[name]?.to);

  useEffect(() => {
    let timer = 0;
    const delay = value === value2 ? 2500 : 500;

    if (waitEvent && updateFilter) {
      timer = setTimeout(() => {
        updateFilter(name, waitEvent.target.value);
      }, delay);
    }
    return () => clearTimeout(timer);
  }, [waitEvent]);

  useEffect(() => {
    value !== undefined &&
      value2 !== undefined &&
      (value !== filters[name]?.from || value2 !== filters[name]?.to) &&
      setWaitEvent({
        target: {
          value: value === null ? undefined : { from: value, to: value2 },
        },
      });
  }, [value, value2]);

  return (
    <CustomDateRange
      dateFrom={value}
      setDateFrom={setValue}
      dateTo={value2}
      setDateTo={setValue2}
      toRight
    />
  );
}
