import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import moment from "moment/moment";

import { DropdownPortalMenu } from "src/app/components/DropdownPortal";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import { RootState } from "src/redux/reducers";
import { getDictionaryIcon } from "src/app/methods/getDictionaryTypes";
import { getSelectDataSetOptions } from "src/redux/select-data-set/selectDataSetActions";
import { SelectDictionaryOption } from "./DictionaryElementSelector";
import { skeletonOptions } from "./utils";
import { MetaFieldType } from "../../methods/getMetaFieldTypeOptions";
import { getDateLabel } from "../../methods/getDateLabel";
import {
  getFullDate,
  splitNumbers,
  splitPercents,
} from "../../../utils/methods";
import DictionaryElementTableLabel from "./DictionaryElementTableLabel";
import DictionaryElementLabel from "./DictionaryElementLabel";

interface DictionaryElementSelectorMenuProps {
  handleChange: (newValue: any) => void;
  onMenuClose: () => void;
  isVisible: boolean;
  forceOpen: boolean;
  selectedOption: Partial<SelectDictionaryOption>;
  wsDictionaryUuid: string | undefined;
  wsDictionarySubFieldUuid: string | null | undefined;
}

export default function DictionaryElementSelectorMenu({
  handleChange,
  onMenuClose,
  isVisible,
  forceOpen,
  selectedOption,
  wsDictionaryUuid,
  wsDictionarySubFieldUuid,
}: DictionaryElementSelectorMenuProps) {
  const {
    selectDataSetReducer: { selectDataSetList, isSelectDataSetRequestLoading },
    dictionaryReducer: {
      dictionaryAutoCompletes,
      dictionaryAutoCompletesColumns,
      dictionaryAutocompleteLoading,
      isDictionaryAutocompleteRequestLoading,
    },
    mainReducer: { identity },
    projectReducer: { membersList },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const intl = useIntl();

  const isLoading =
    dictionaryAutocompleteLoading ||
    isDictionaryAutocompleteRequestLoading ||
    isSelectDataSetRequestLoading;

  const getSubMetaFieldValue = (metaFieldItem: any): string | null => {
    const value = metaFieldItem.metadata?.find(
      (item: any) => item.uuid === wsDictionarySubFieldUuid,
    )?.value
      ? metaFieldItem.metadata?.find(
          (item: any) => item.uuid === wsDictionarySubFieldUuid,
        )?.value
      : null;

    const metaField =
      dictionaryAutoCompletesColumns[
        `dictionaryType_${wsDictionaryUuid}`
      ]?.find((item) => item.uuid === wsDictionarySubFieldUuid) ?? null;

    if (metaField?.type === MetaFieldType.DictionaryElement) {
      const subMetaFieldDictionaryUuid = metaField?.data?.wsDictionaryUuid;

      if (typeof subMetaFieldDictionaryUuid !== "string") return value;

      const dictionaryAutoCompletesDictionary =
        dictionaryAutoCompletes[
          `dictionaryType_${subMetaFieldDictionaryUuid}`
        ] ?? [];

      if (dictionaryAutoCompletesDictionary.length > 0) {
        const supportingFieldDictionaryItem =
          dictionaryAutoCompletesDictionary.find(
            (dictionaryItem) => dictionaryItem.uuid === value,
          );

        if (!supportingFieldDictionaryItem) return value;

        return supportingFieldDictionaryItem.title;
      }

      return value;
    }

    if (metaField?.type === MetaFieldType.SingleSelect) {
      if (metaField.data?.wsSelectDataSetUuid) {
        const foundDataset =
          selectDataSetList[
            `dataSetType_${metaField.data.wsSelectDataSetUuid}`
          ];

        if (!foundDataset) return value;

        return (
          foundDataset.find((datasetItem) => datasetItem.value === value)
            ?.name ?? null
        );
      }
      return (
        metaField?.data?.singleSelectOptions?.find(
          (item: any) => item.value === value,
        )?.name ?? null
      );
    }
    if (metaField?.type === MetaFieldType.MultiSelect) {
      const valuesArr = metaField?.data?.singleSelectOptions?.filter(
        (item: any) => value?.includes(item.value),
      );

      if (valuesArr?.length) {
        return valuesArr.map((item: any) => item.name).join(", ");
      }

      return null;
    }
    if (metaField?.type === MetaFieldType.Member) {
      const foundMember = membersList?.find((member) => member.id === value);
      return foundMember?.name ?? null;
    }
    if (metaField?.type === MetaFieldType.Date) {
      if (value && value?.date) {
        const formattedValue = getDateLabel(
          "en-EN",
          moment(value.date),
          false,
          intl,
        );

        return typeof formattedValue?.date === "string"
          ? formattedValue?.date
          : getFullDate(formattedValue?.date.toDate());
      }

      return null;
    }
    if (metaField?.type === MetaFieldType.Percent) {
      return value ? `${splitPercents(value)}%` : null;
    }
    if (metaField?.type === MetaFieldType.Number) {
      return value ? `${splitNumbers(value)}` : null;
    }
    if (metaField?.type === MetaFieldType.Currency) {
      return value && metaField?.data?.currencyCode
        ? `${metaField?.data?.currencyCode} ${splitNumbers(value)}`
        : null;
    }

    return value;
  };

  const filterOption = (candidate: any, input: any) => {
    const searchWords = input?.trim()
      ? input
          .trim()
          .split(" ")
          ?.map((substring: string) => substring.toUpperCase())
      : [];

    if (searchWords && searchWords.length > 0) {
      const concatString = `${candidate?.data?.name} ${candidate?.data?.subField ?? ""}`;
      const filteredName = searchWords
        ?.filter((word: string) => word !== "")
        .every((word: string) =>
          concatString.trim().toUpperCase().includes(word),
        );

      return candidate.data.__isNew__ || filteredName;
    }

    return true;
  };

  const getMissingDataSet = (dataSetTypeUuid: string) => {
    if (!selectDataSetList[`dataSetType_${dataSetTypeUuid}`]) {
      dispatch(getSelectDataSetOptions(dataSetTypeUuid));
    }
  };

  const options = useMemo(() => {
    if (isLoading) return null;

    const list =
      dictionaryAutoCompletes[`dictionaryType_${wsDictionaryUuid}`] || [];

    if (!list.length) return null;

    const metaField =
      dictionaryAutoCompletesColumns[
        `dictionaryType_${wsDictionaryUuid}`
      ]?.find((item) => item.uuid === wsDictionarySubFieldUuid) ?? null;

    if (metaField && typeof metaField.data.wsSelectDataSetUuid === "string") {
      getMissingDataSet(metaField.data.wsSelectDataSetUuid);
    }

    return list.map((listItem) => {
      const icon = getDictionaryIcon(identity, wsDictionaryUuid);
      return {
        value: listItem.uuid,
        label: (
          <DictionaryElementLabel
            listItem={listItem}
            wsDictionarySubFieldUuid={wsDictionarySubFieldUuid}
            subMetaFieldValue={getSubMetaFieldValue(listItem)}
            icon={icon}
          />
        ),
        tableLabel: (
          <DictionaryElementTableLabel title={listItem.title} icon={icon} />
        ),
        name: listItem.title,
        subField: getSubMetaFieldValue(listItem) ?? "",
      };
    });
  }, [
    wsDictionaryUuid,
    wsDictionarySubFieldUuid,
    identity,
    dictionaryAutoCompletes,
    selectDataSetList,
    isLoading,
  ]);

  return (
    <DropdownPortalMenu
      className="dictionary-element-selector-menu"
      onMouseLeave={(e: any) => e.stopPropagation()}
    >
      <CustomSelect
        options={isLoading ? skeletonOptions : options}
        value={selectedOption}
        onChange={handleChange}
        isSearchable
        menuIsOpen
        forceOpen={forceOpen}
        forceFocus={isVisible}
        filterOption={filterOption}
        onMenuClose={onMenuClose}
        openOnFocus={false}
      />
    </DropdownPortalMenu>
  );
}
