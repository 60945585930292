import axios from "axios";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Dispatch as ReduxDispatch } from "redux";

import { wsAxiosDelete } from "src/helpers/wsAxios";
import { refreshData } from "src/utils/uploadFunctionsUtils";
import { tableDataType } from "src/app/components/Table/Table";
import { API_URLS } from "../../../utils/API_URLS";
import { uploadMultipleFiles } from "../../../utils/uploadFunctions";
import { showToast } from "../../methods/showToast";
import { MetaFileData } from "../MultipleFileField/MultipleFileField";
import { addNewFilesFromFileList } from "./utils";

export const uploadTaskFile = async (
  e: ChangeEvent<HTMLInputElement>,
  dispatch: ReduxDispatch,
  fieldId: string,
  activeWorkspaceUuid: string,
  setFilesData: Dispatch<SetStateAction<MetaFileData[]>>,
  projectId: string,
  taskId: string,
) => {
  const target = e.target as HTMLInputElement;

  const newFiles = addNewFilesFromFileList(target.files as FileList);

  setFilesData((files: MetaFileData[]) => [...files, ...newFiles]);

  await uploadMultipleFiles({
    e,
    wsMetaFieldUuid: fieldId,
    wsProjectUuid: projectId,
    wsWorkspaceUuid: activeWorkspaceUuid,
    wsTaskUuid: taskId,
    catchCallback: () => {
      setFilesData((prev) =>
        prev.filter((prevFile) =>
          Boolean(
            !newFiles.find((newFile) => newFile.assetId === prevFile.assetId),
          ),
        ),
      );
    },
    finallyCallback: () => {
      setTimeout(() => {
        refreshData({ dispatch, wsTaskUuid: taskId });
      }, 1000);
    },
  });
};

export const deleteTaskFile = async (
  assetId: string,
  fieldId: string,
  dispatch: ReduxDispatch,
  projectId: string,
  setFilesData: Dispatch<SetStateAction<MetaFileData[]>>,
  files: MetaFileData[],
  taskId: string,
  taskType: string,
) => {
  const url = API_URLS.deleteTaskMetaFieldFileAsset
    .replace(":fieldId:", fieldId)
    .replace(":taskId:", taskId)
    .replace(":assetId:", String(assetId));

  try {
    await axios.delete(url);
    setFilesData((list) => list.filter((item) => item.assetId !== assetId));

    showToast("success", "Success", "File has been deleted.");
  } catch (error) {
    showToast("error", "Error", "Could not delete file.");
    setFilesData(files);
  }
};

interface DeleteGlobalTaskFileProps {
  context: tableDataType;
  assetId: string;
  fieldId: string;
  taskId: string;
  thenCallback?: () => void;
  errorCallback?: () => void;
}

export const deleteFileBasingOnContext = async ({
  context,
  assetId,
  fieldId,
  taskId,
  thenCallback,
  errorCallback,
}: DeleteGlobalTaskFileProps) => {
  let url;

  switch (context) {
    case tableDataType.GlobalTask:
      url = API_URLS.deleteGlobalTaskMetaField;
      break;
    case tableDataType.Dictionary:
      url = API_URLS.deleteDictionaryMetaField;
      break;
    default:
      throw new Error("Context not supported");
  }

  url = url
    .replace(":fieldId:", fieldId)
    .replace(":taskId:", taskId)
    .replace(":assetId:", assetId);

  wsAxiosDelete({
    url,
    thenCallback: () => {
      showToast("success", "Success", "File has been deleted.");
      if (thenCallback) {
        thenCallback();
      }
    },
    errorCallback: () => {
      showToast("error", "Error", "Could not delete file.");
      if (errorCallback) {
        errorCallback();
      }
    },
  });
};

interface UploadFileBasingOnContextProps {
  e: ChangeEvent<HTMLInputElement>;
  wsMetaFieldUuid: string;
  wsWorkspaceUuid: string;
  setFilesData?: Dispatch<SetStateAction<MetaFileData[]>>;
  wsTaskUuid: string;
  thenCallback?: () => void;
  finallyCallback?: () => void;
  context?: tableDataType;
}

export const uploadFileBasingOnContext = async ({
  e,
  wsMetaFieldUuid,
  wsWorkspaceUuid,
  wsTaskUuid,
  setFilesData,
  thenCallback,
  finallyCallback,
  context,
}: UploadFileBasingOnContextProps) => {
  const target = e.target as HTMLInputElement;

  if (!target.files) return;

  const newFiles = addNewFilesFromFileList(target.files);

  setFilesData?.((files: MetaFileData[]) => [...files, ...newFiles]);

  uploadMultipleFiles({
    e,
    wsMetaFieldUuid,
    wsWorkspaceUuid,
    wsTaskUuid,
    context,
    thenCallback,
    catchCallback: () => {
      setFilesData?.((prev) =>
        prev.filter((prevFile) =>
          Boolean(
            !newFiles.find((newFile) => newFile.assetId === prevFile.assetId),
          ),
        ),
      );
    },
    finallyCallback,
  });
};
