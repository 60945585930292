import React from "react";

import classNames from "classnames";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { useWindowSize } from "src/hooks/useWindowSize";
import { Button } from "../Button/Button";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";

import "react-tooltip/dist/react-tooltip.css";
import "./SelectedTasksBar.scss";

interface SelectedTasksBarProps {
  selectedTasksLength: number;
  children?: React.ReactNode;
  isTooltipOpen?: boolean;
  hideTooltip?: () => void;
}

export function SelectedTasksBar({
  children,
  selectedTasksLength,
  isTooltipOpen,
  hideTooltip,
}: SelectedTasksBarProps) {
  const { width } = useWindowSize();

  const isScreenSmall = width <= 1500;

  return (
    <div
      className={classNames("selected-tasks-bar", {
        "selected-tasks-bar--hidden": !selectedTasksLength,
        "selected-tasks-bar--with-tooltip":
          isTooltipOpen && selectedTasksLength >= 2 && !isScreenSmall,
      })}
      data-tooltip-id="selected-tasks-bar__tooltip"
    >
      <ReactTooltip
        id="selected-tasks-bar__tooltip"
        isOpen={Boolean(isTooltipOpen && selectedTasksLength >= 2)}
        place={isScreenSmall ? "top" : "left"}
        clickable
      >
        <IDHFormattedMessage
          id="ws_selected_tasks_bar_tooltip"
          defaultMessage="Changing the value of a field in one object will be applied to all other fields in the same column of selected objects."
        />
        <Button variant="white-with-border" size="medium" onClick={hideTooltip}>
          Ok
        </Button>
      </ReactTooltip>
      <div className="selected-tasks-bar__counter">
        <IDHFormattedMessage id="ws_selected" defaultMessage="Selected" />
        <div className="selected-tasks-bar__counter-number">
          {selectedTasksLength}
        </div>
      </div>
      <div className="selected-tasks-bar__separator" />
      {children}
    </div>
  );
}
