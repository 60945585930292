import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./PerformanceFilters.scss";

import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Pane } from "src/app/components/Pane/Pane";
import {
  PaneTopbar,
  PaneTopbarLeft,
  PaneTopbarToolbar,
} from "src/app/components/Pane/PaneTopbar/PaneTopbar";
import { ReactComponent as FilterIcon } from "src/images/filter.svg";
import { ReactComponent as LockIcon } from "src/images/lock.svg";
import { PerformanceElement } from "../../Performance";
import { SingleFilter } from "./components/SingleFilter/SingleFilter";

export const InitialPerformanceFilters = {
  time: {
    from: null,
    to: null,
  },
  campaigns: [],
};

export interface IPerformanceFilters {
  time: {
    from: string | null;
    to: string | null;
  };
  campaigns: string[];
}

interface Props {
  filtersVisible: boolean;
  setFiltersVisible: Dispatch<SetStateAction<boolean>>;
  performanceData: PerformanceElement[];
  filters: IPerformanceFilters;
  setFilters: Dispatch<SetStateAction<IPerformanceFilters>>;
}

export const PerformanceFilters: React.FC<Props> = (props) => {
  const {
    filtersVisible,
    setFiltersVisible,
    performanceData,
    filters,
    setFilters,
  } = props;

  const [campaigns, setCampaigns] = useState<
    { label: string | React.ReactNode; value: string }[]
  >([]);

  useEffect(() => {
    const newCampaigns: {
      label: string | React.ReactNode;
      value: string;
    }[] = [...campaigns];

    performanceData.forEach((item) => {
      if (!item.breakdownValues?.campaign) return;

      Object.entries(item.breakdownValues.campaign).forEach((campaign) => {
        const [, values] = campaign;
        const foundCampaign = newCampaigns.find(
          (item) => item.value === values.projectUuid,
        );

        if (!foundCampaign) {
          newCampaigns.push({
            label: values.projectTitle || <LockIcon />,
            value: values.projectUuid,
          });
        }
      });
    });

    setCampaigns(newCampaigns);
  }, [performanceData]);

  const updateFilter = (key: string, value: any) => {
    setFilters((filters) => ({
      ...filters,
      [key]: value,
    }));
  };

  const clearFilters = () => {
    setFilters(InitialPerformanceFilters);
  };

  return (
    <Pane
      className="performance-filters"
      backdropClassName="performance-filters__backdrop"
      showPane={filtersVisible}
      hidePane={() => setFiltersVisible(false)}
      width={309}
    >
      <PaneTopbar>
        <PaneTopbarLeft>
          <FilterIcon className="filters-header__icon" />
          <span className="filters-header__title">
            <IDHFormattedMessage id="ws_filter" defaultMessage="Filter" />
          </span>
        </PaneTopbarLeft>
        <PaneTopbarToolbar hidePane={() => setFiltersVisible(false)} />
      </PaneTopbar>

      <div className="pane__container filters">
        {/* Time */}
        <SingleFilter
          name="time"
          type="date"
          data={{}}
          filters={filters}
          updateFilter={updateFilter}
        />

        {/* Campaigns */}
        <SingleFilter
          name="campaigns"
          type="checkbox"
          data={campaigns}
          filters={filters}
          updateFilter={updateFilter}
        />
      </div>

      <Button
        variant="light"
        size="medium"
        className="project-filters-button"
        onClick={clearFilters}
      >
        <IDHFormattedMessage
          id="ws_clear_filters"
          defaultMessage="Clear filters"
        />
      </Button>
    </Pane>
  );
};
