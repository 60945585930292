import React, { useState } from "react";
import "./MyAccount.scss";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URLS } from "src/utils/API_URLS";
import { identify } from "src/redux/main/mainActions";
import { showToast } from "src/app/methods/showToast";
import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import { uploadAvatarBlob } from "src/utils/uploadFunctions";
import { RootState } from "src/redux/reducers";
import { showErrorToast } from "src/utils/methods";
import { ModalTitle } from "../Modal/Modal";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import CustomInput, { CustomInputLabel } from "../CustomInput/CustomInput";
import CustomImage from "../CustomImage/CustomImage";
import ImageCropModal from "../CroppedImageUploader/ImageCropModal/ImageCropModal";
import { Button } from "../Button/Button";

interface Props {
  onClose: () => void;
}

const MyAccount: React.FC<Props> = (props) => {
  const { onClose } = props;

  const {
    mainReducer: { identity },
  } = useSelector((state: RootState) => state);

  const [name, setName] = useState(identity.name);
  const [avatarUrl, setAvatarUrl] = useState(identity.avatarUrl);
  const [avatarToUpdate, setAvatarToUpdate] = useState(null);
  const [showImageCropModal, setShowImageCropModal] = useState(false);

  const usernameChanged = identity.name && name && identity.name !== name;

  const dispatch = useDispatch();

  const onChangeAvatar = async (file: any) => {
    if (identity?.id) {
      setAvatarToUpdate(file);
      setAvatarUrl(file.localSrc);
    }
  };

  const updateAvatar = () => {
    if (identity?.id) {
      return uploadAvatarBlob(
        avatarToUpdate,
        dispatch,
        identity?.id,
        identity.lastWorkspaceUuid,
      );
    }
    return false;
  };

  const updateUsername = async () => {
    try {
      await axios.post(API_URLS.createProfile, {
        wsMemberName: name,
      });
      dispatch(identify(identity.lastWorkspaceUuid));
      return true;
    } catch (err) {
      console.error(err);
      showErrorToast({
        id: "ws_could_not_update_username",
        defaultMessage: "ws_could_not_update_username",
      });
      return false;
    }
  };

  const handleConfirm = async () => {
    let avatarUpdated = false;
    let userNameUpdated = false;

    if (avatarToUpdate) {
      avatarUpdated = await updateAvatar();
    }

    if (usernameChanged) {
      userNameUpdated = await updateUsername();
    }

    if (avatarUpdated || userNameUpdated) {
      showToast(
        "success",
        <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
        <IDHFormattedMessage
          id="ws_settings_have_been_saved"
          defaultMessage="Settings have been saved!"
        />,
      );
    }
  };

  return (
    <>
      <div className="my-account">
        <ModalTitle>
          <IDHFormattedMessage id="ws_my_account" defaultMessage="My Account" />
        </ModalTitle>
        <h6 className="my-account__subheader">
          <IDHFormattedMessage id="ws_photo" defaultMessage="Photo" />
        </h6>

        <div className="my-account__avatar">
          <CustomImage src={avatarUrl} alt="avatar" />

          <span
            className="my-account__avatar-button"
            onClick={() => setShowImageCropModal(true)}
          >
            <IDHFormattedMessage
              id="ws_change_image"
              defaultMessage="Change image"
            />
          </span>
        </div>

        <h6 className="my-account__subheader">
          <IDHFormattedMessage
            id="ws_personal_info"
            defaultMessage="Personal info"
          />
        </h6>

        <CustomInputLabel htmlFor="email">
          <IDHFormattedMessage id="ws_email" defaultMessage="Email" />
        </CustomInputLabel>
        <CustomInput id="email" value={identity.email} disabled />

        <CustomInputLabel htmlFor="name">
          <IDHFormattedMessage id="ws_full_name" defaultMessage="Full name" />
        </CustomInputLabel>
        <CustomInput
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        {showImageCropModal && (
          <ImageCropModal
            uploadFunction={onChangeAvatar}
            onClose={() => setShowImageCropModal(false)}
            circle
          />
        )}
      </div>

      <SettingsButtons onClose={onClose}>
        <Button
          size="large"
          variant="blue"
          onClick={handleConfirm}
          disabled={!name?.length || (!avatarToUpdate && !usernameChanged)}
        >
          <IDHFormattedMessage id="ws_update" defaultMessage="Update" />
        </Button>
      </SettingsButtons>
    </>
  );
};

export default MyAccount;
