import {
  ContentProposalElement,
  ContentProposalElementStatus,
} from "src/redux/comment/types";
import { API_URLS } from "../../utils/API_URLS";
import {
  CLEAR_USER_ACCEPTED_CONTENT,
  CLEAR_USER_REJECTED_CONTENT,
  CREATE_TASK_COMMENT,
  DELETE_TASK_COMMENT,
  EDIT_TASK_COMMENT,
  SET_COMMENT_AS_EDITABLE,
  SET_USER_ACCEPTED_CONTENT,
  SET_USER_REJECTED_CONTENT,
  GET_CONTENT_PROPOSAL_SUMMARY,
  CLEAR_CONTENT_PROPOSAL_FILTER_STATUS,
  UPDATE_CONTENT_PROPOSAL_FILTER_STATUS,
  SET_REPLY_TO_COMMENT_DATA,
  CLEAR_REPLY_TO_COMMENT_DATA,
} from "./commentTypes";

export function createTaskComment(
  wsTaskCommentUuid: string,
  wsTaskUuid: string,
  comment: string,
) {
  const data = {
    wsTaskCommentUuid,
    wsTaskUuid,
    comment,
  };

  return {
    type: CREATE_TASK_COMMENT,
    payload: {
      request: {
        method: "post",
        url: API_URLS.createTaskComment,
        data,
      },
    },
  };
}

export function editTaskComment(commentId: string, comment: string) {
  const data = {
    commentId,
    comment,
  };

  return {
    type: EDIT_TASK_COMMENT,
    payload: {
      request: {
        method: "put",
        url: API_URLS.editTaskComment,
        data,
      },
    },
  };
}

export function deleteTaskComment(commentId: string) {
  return {
    type: DELETE_TASK_COMMENT,
    payload: {
      request: {
        method: "delete",
        url: `${API_URLS.deleteTaskComment}/${commentId}`,
      },
    },
  };
}

export function setCommentAsEditable(commentId: string | null) {
  return {
    type: SET_COMMENT_AS_EDITABLE,
    payload: {
      commentId,
    },
  };
}

export interface UserContentProps {
  wsTaskCommentUuid: string;
  contentProposalElement: ContentProposalElement;
}

export function setUserAcceptedContent(acceptedContent: UserContentProps) {
  return {
    type: SET_USER_ACCEPTED_CONTENT,
    payload: {
      acceptedContent,
    },
  };
}

export function setUserRejectedContent(rejectedContent: UserContentProps) {
  return {
    type: SET_USER_REJECTED_CONTENT,
    payload: {
      rejectedContent,
    },
  };
}

export function clearUserAcceptedContent() {
  return {
    type: CLEAR_USER_ACCEPTED_CONTENT,
  };
}

export function clearUserRejectedContent() {
  return {
    type: CLEAR_USER_REJECTED_CONTENT,
  };
}

export function getWsContentProposalElementsSummary(
  wsTaskUuid: string,
  status: ContentProposalElementStatus[] = [],
) {
  return {
    type: GET_CONTENT_PROPOSAL_SUMMARY,
    payload: {
      request: {
        method: "post",
        url: API_URLS.wsContentProposalElementsSummary.replace(
          ":wsTaskUuid:",
          wsTaskUuid,
        ),
        data: {
          status,
        },
      },
    },
  };
}

export function updateContentProposalFilterStatus(
  status: ContentProposalElementStatus[] = [],
) {
  return {
    type: UPDATE_CONTENT_PROPOSAL_FILTER_STATUS,
    payload: {
      status,
    },
  };
}

export function clearContentProposalFilterStatus() {
  return {
    type: CLEAR_CONTENT_PROPOSAL_FILTER_STATUS,
  };
}

interface SetReplyToCommentDataProps {
  wsTaskUuid: string;
  parentWsTaskCommentUuid: string | null;
  replyToCommentText: string;
  replyToCommentContent?: ContentProposalElement[];
}

export function setReplyToCommentData({
  wsTaskUuid,
  parentWsTaskCommentUuid,
  replyToCommentText,
  replyToCommentContent,
}: SetReplyToCommentDataProps) {
  return {
    type: SET_REPLY_TO_COMMENT_DATA,
    payload: {
      wsTaskUuid,
      parentWsTaskCommentUuid,
      replyToCommentText,
      replyToCommentContent,
    },
  };
}

export function clearReplyToCommentData() {
  return {
    type: CLEAR_REPLY_TO_COMMENT_DATA,
  };
}
