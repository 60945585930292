import moment from "moment";

export const convertTimezone = (date: string) => {
  // hack for support Chrome Windows/MacOS and Safari
  return new Date(moment(date).toDate());
  // old version
  // return new Date(
  //   (typeof date === "string" ? new Date(date) : date).toLocaleString(
  //     undefined,
  //     {
  //       timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //     }
  //   )
  // );
};
