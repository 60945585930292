import React from "react";

import { AudienceAgeAndGender } from "../../../../Task/tabs/ProfileDataTab/single/AudienceAgeAndGender";

export const renderAudienceAge = (label, data, layout, GridBoxComponent) => {
  const {
    user_audience_data: { audienceAgeGroups },
  } = data;

  const key = `${label}-audience-age`;
  const height = layout?.find((item) => item.i === key)?.h;

  return (
    <GridBoxComponent key={key} id={key}>
      <AudienceAgeAndGender
        audienceAgeGroups={audienceAgeGroups}
        height={height}
      />
    </GridBoxComponent>
  );
};
