import axios, { AxiosResponse } from "axios";
import { API_URLS } from "src/utils/API_URLS";
import { RequestInputs } from "src/types";
import { AssetData } from "src/utils/uploadFunctions";
import { ParentType } from "src/utils/uploadFunctionsUtils";

enum MetaFieldTypeShortName {
  MetaFieldTypeTask = "t",
  MetaFieldTypeProject = "p",
}

enum MetaFieldTypeLongName {
  MetaFieldTypeTask = "wsTaskMetaField",
  MetaFieldTypeProject = "wsProjectMetaField",
}

const metaFieldTypeOptions = {
  [MetaFieldTypeShortName.MetaFieldTypeTask]: {
    presignedRequestType: MetaFieldTypeLongName.MetaFieldTypeTask,
    parentType: ParentType.wsTaskMetaField,
  },
  [MetaFieldTypeShortName.MetaFieldTypeProject]: {
    presignedRequestType: MetaFieldTypeLongName.MetaFieldTypeProject,
    parentType: ParentType.wsProjectMetaField,
  },
};

export class UploadMultiFilesExt {
  private _hasCorrectType = false;

  private _hasCorrectSize = false;

  constructor(
    private readonly _files: FileList,
    private readonly _allowedTypes: string[],
    private readonly _maxSize: number,
    private readonly fieldId: string,
    private readonly wsMetaFieldUuid: string,
    private readonly wsTargetFileMetaFieldType:
      | MetaFieldTypeLongName.MetaFieldTypeTask
      | MetaFieldTypeLongName.MetaFieldTypeProject,
    private readonly parentType: string,
    private readonly metaFieldType:
      | MetaFieldTypeShortName.MetaFieldTypeTask
      | MetaFieldTypeShortName.MetaFieldTypeProject,
    private readonly wsTaskUuid?: string,
    private readonly wsWorkspaceUuid?: string,
    private readonly token?: string,
    private readonly newFiles?: { assetId: string; name: string }[],
    private readonly extensionUuid?: string,
  ) {}

  validate() {
    Array.from(this._files).forEach((file) => {
      const fileNameAr = file.name.split(".");
      const fileExtension = fileNameAr[fileNameAr.length - 1];

      const sizeInMB = file.size / parseInt((1000 * 1000).toFixed(2));

      if (!this._allowedTypes.includes(fileExtension.toLowerCase())) {
        this._hasCorrectType = false;
        throw new Error("Invalid file type.");
      } else if (sizeInMB > this._maxSize) {
        this._hasCorrectType = true;
        this._hasCorrectSize = false;
        throw new Error("File is too large.");
      } else {
        this._hasCorrectType = true;
        this._hasCorrectSize = true;
      }
    });
  }

  get hasCorrectType() {
    return this._hasCorrectType;
  }

  get hasCorrectSize() {
    return this._hasCorrectSize;
  }

  get maxSize() {
    return this._maxSize;
  }

  async sendAllRequestsForRecruitmentFormField(): Promise<AssetData[]> {
    const assets: AssetData[] = [];

    const response = await this.getPresignedRequestForRecruitmentFormField();

    let i = 0;

    for (const item of response.data.content) {
      const { action } = item.token.requestAttributes;
      const { requestInputs } = item.token;

      const path = requestInputs.key.split("/");
      const realName = path[path.length - 1];

      await this.sendRequestToS3(this._files[i], action, requestInputs);

      const dataToCreateAsset = {
        uuid: this?.newFiles?.[i].assetId,
        wsWorkspaceUuid: this.wsWorkspaceUuid!,
        parentUuid: this.fieldId,
        parentType:
          this.wsTargetFileMetaFieldType ===
          MetaFieldTypeLongName.MetaFieldTypeProject
            ? ParentType.wsProjectMetaField
            : ParentType.wsTaskMetaField,
        name: this._files[i].name,
        realName,
        mimeType: this._files[i].type,
        size: this._files[i].size,
        ...(this.wsTaskUuid && { wsTaskUuid: this.wsTaskUuid }),
      };

      assets.push(dataToCreateAsset);
      i++;
    }
    return assets;
  }

  async sendAllRequestsForExternalTaskField(): Promise<void> {
    const assets: AssetData[] = [];

    const response = await this.getPresignedRequestForExternalTaskField();
    // S3

    let i = 0;

    for (const item of response.data.content) {
      const { action } = item.token.requestAttributes;
      const { requestInputs } = item.token;

      const path = requestInputs.key.split("/");
      const realName = path[path.length - 1];

      await this.sendRequestToS3(this._files[i], action, requestInputs);

      const dataToCreateAsset = {
        uuid: this?.newFiles?.[i].assetId,
        wsWorkspaceUuid: this.wsWorkspaceUuid!,
        parentUuid: this.fieldId,
        parentType:
          this.wsTargetFileMetaFieldType ===
          MetaFieldTypeLongName.MetaFieldTypeProject
            ? ParentType.wsProjectMetaField
            : ParentType.wsTaskMetaField,
        name: this._files[i].name,
        realName,
        mimeType: this._files[i].type,
        size: this._files[i].size,
        ...(this.wsTaskUuid && { wsTaskUuid: this.wsTaskUuid }),
      };

      assets.push(dataToCreateAsset);
      i++;
    }

    this.createAssets(assets);
  }

  private async getPresignedRequestForRecruitmentFormField() {
    return axios.post(API_URLS.presignedRequestForRecruitmentFormMetaField, {
      wsExtensionUuid: this.extensionUuid,
      token: this.token,
      numberOfPresignedRequests: this._files.length,
      wsTaskMetaFieldUuid: this.fieldId,
    });
  }

  private async getPresignedRequestForExternalTaskField() {
    return axios.post(
      API_URLS.presignedRequestForExternalTaskField,
      {
        fileNames: this?.newFiles?.map((newFileDatum) => newFileDatum.name),
        presignedRequestType:
          metaFieldTypeOptions[this.metaFieldType].presignedRequestType,
        wsWorkspaceUuid: this.wsWorkspaceUuid,
      },
      {
        params: {
          wsMetaFieldUuid: this.wsMetaFieldUuid,
          wsTargetFileMetaFieldUuid: this.fieldId,
          wsTargetFileMetaFieldType: this.wsTargetFileMetaFieldType,
          token: this.token,
          metaFieldType: this.metaFieldType,
          wsTaskUuid: this.wsTaskUuid,
        },
      },
    );
  }

  private getFormDataToUploadS3(file: File, requestInputs: RequestInputs) {
    const formData = new FormData();

    formData.set("key", requestInputs.key);
    formData.set("acl", requestInputs.acl);
    formData.set("X-Amz-Credential", requestInputs["X-Amz-Credential"]);
    formData.set("X-Amz-Algorithm", requestInputs["X-Amz-Algorithm"]);
    formData.set("X-Amz-Date", requestInputs["X-Amz-Date"]);
    formData.set("Policy", requestInputs.Policy);
    formData.set("X-Amz-Signature", requestInputs["X-Amz-Signature"]);
    formData.set("success_action_status", requestInputs.success_action_status);
    formData.set("file", file);

    return formData;
  }

  private async sendRequestToS3(
    file: File,
    action: string,
    requestInputs: RequestInputs,
  ): Promise<AxiosResponse<any>> {
    return axios.post(action, this.getFormDataToUploadS3(file, requestInputs));
  }

  private async createAssets(assets: AssetData[]): Promise<AxiosResponse<any>> {
    return axios.post(API_URLS.createMultipleAssets, {
      assets,
    });
  }
}
