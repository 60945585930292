import "./CredibilityToolExploringModal.scss";
import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "src/images/close.svg";
import IDHFormattedMessage from "../../components/IDHFormattedMessage/IDHFormattedMessage";

import FaceIcon from "../../../images/credibility-tool/credibility-exploring-modal-img.png";

function CredibilityToolExploringModal() {
  const [showModal, setShowModal] = useState(true);

  return showModal ? (
    <div className="crediblity-tool-exploring crediblity-tool-exploring--reload">
      <span className="crediblity-tool-exploring__content">
        <img src={FaceIcon} className="crediblity-tool-exploring__face-icon" />
        <span className="crediblity-tool-exploring__text">
          <IDHFormattedMessage
            id="ws_want_to_discover_more_visit_our_credibility_check_tool_page_for_deeper_insights"
            defaultMessage="Want to discover more? Visit our Credibility Check Tool page for deeper insights."
          />
        </span>
        <span
          className="crediblity-tool-exploring__button"
          onClick={() => window.location.reload()}
        >
          <IDHFormattedMessage
            id="ws_start_exploring"
            defaultMessage="Start exploring!"
          />
        </span>
      </span>
      <CloseIcon
        className="crediblity-tool-exploring__close"
        onClick={() => setShowModal(false)}
      />
    </div>
  ) : (
    <></>
  );
}

export default CredibilityToolExploringModal;
