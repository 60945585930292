import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { getTaskDetails } from "../../../../../redux";
import { API_URLS } from "../../../../../utils/API_URLS";
import CustomDatePicker, {
  DatePickerWrapper,
} from "../../../../components/CustomDatePicker/CustomDatePicker";
import { showToast } from "../../../../methods/showToast";
import { DashedBox } from "../../../../components/DashedBox/DashedBox";
import { SectionHeader } from "../../../../components/Pane/SectionHeader/SectionHeader";

function Published(props) {
  const { publicationDate } = props;

  const [value, setValue] = useState(
    publicationDate ? publicationDate.date : null,
  );

  const { taskId } = useParams();
  const dispatch = useDispatch();

  const onDateChange = async (newDate) => {
    // Kacper TODO - check why it goes 2 times
    try {
      setValue(newDate);
      await axios.patch(API_URLS.patchPublicationStatistic, {
        taskUuid: taskId,
        statistic: "publicationDate",
        value: newDate,
      });
      dispatch(getTaskDetails(taskId));
    } catch (err) {
      console.error(err);
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_cannot_change_publication_date"
          defaultMessage="Cannot change publication date"
        />,
      );
    }
  };

  return (
    <div>
      <SectionHeader
        name={
          <IDHFormattedMessage id="ws_published" defaultMessage="Published" />
        }
      />
      <DashedBox className="published-dashed-box">
        <DatePickerWrapper value={value}>
          <CustomDatePicker
            initialDate={value}
            dateChangeHandler={onDateChange}
            disableRedLabel
          />
        </DatePickerWrapper>
      </DashedBox>
    </div>
  );
}

export { Published };
