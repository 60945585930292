import React, { CSSProperties } from "react";

import classNames from "classnames";
import { TooltipedEllipsis } from "src/app/components/TooltipedEllipsis/TooltipedEllipsis";
import { valueExists } from "src/app/methods/valueExists";
import Pictogram, {
  PictogramVariants,
} from "src/app/components/Pictogram/Pictogram";
import { CrossIcon } from "./CrossIcon";

interface SelectLabelProps {
  index?: number;
  value?: any;
  onClickHandler?: () => void;
  emptyFieldPlaceholder: React.ReactNode;
  rejectMulti?: any;
  style?: CSSProperties;
  disableTooltip?: boolean;
  readOnly?: boolean;
  showCounter?: boolean;
}

function SelectLabel({
  index,
  value,
  onClickHandler,
  emptyFieldPlaceholder,
  rejectMulti,
  readOnly,
  showCounter,
}: SelectLabelProps) {
  const isMulti = !!rejectMulti;

  return (
    <span
      onClick={!readOnly && onClickHandler ? onClickHandler : undefined}
      className={classNames([
        "label-selector__label",
        "label-selector__label--cell",
        "label-selector__label--selected",
        !value?.name && "label-selector__label--placeholder",
        !onClickHandler || (readOnly && "select__label--readOnly"),
      ])}
    >
      {value?.name ? (
        <span
          className={classNames("label-selector__label-text-wrapper", {
            "label-selector__label-text-wrapper--multi": isMulti,
          })}
        >
          {value.icon ? (
            <Pictogram
              identifier={value.icon.name}
              variant={
                value.icon.varianet
                  ? value.icon.variant
                  : PictogramVariants.Original
              }
            />
          ) : null}
          {showCounter ? <>x&nbsp;{value.count}</> : value?.name}
        </span>
      ) : (
        emptyFieldPlaceholder
      )}
      {isMulti && (
        <CrossIcon fill="#8F919D" onClick={() => rejectMulti(index)} />
      )}
    </span>
  );
}

export default SelectLabel;
