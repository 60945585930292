import React, { useEffect, useState } from "react";
import classNames from "classnames";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RootState } from "src/redux/reducers";
import CustomImage from "../../../components/CustomImage/CustomImage";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "../../../components/Dropdown/Dropdown";
import { ReactComponent as PlusIcon } from "../../../../images/plus-transparent.svg";
import { ReactComponent as SettingsIcon } from "../../../../images/settings-v.svg";
import projectSquarePlaceholder from "../../../../images/project-square-placeholder.svg";
import { API_URLS } from "../../../../utils/API_URLS";
import { uploadProjectCover } from "../../../../utils/uploadFunctions";
import Skeleton from "../../../components/Skeleton/Skeleton";
import { getImageSource } from "../../../../utils/methods";
import ImageCropModal from "../../../components/CroppedImageUploader/ImageCropModal/ImageCropModal";

function ProjectCover() {
  const {
    mainReducer: { activeWorkspaceUuid },
    projectReducer: { projectBasicData },
  } = useSelector((state: RootState) => state);

  const [coverImageId, setCoverImageId] = useState<string | null>(
    projectBasicData?.cover?.id ?? null,
  );
  const [coverImageUrl, setCoverImageUrl] = useState<any>(
    getImageSource(projectBasicData?.cover, "tiny"),
  );
  const [showUploadLogoModal, setShowUploadLogoModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const src = getImageSource(projectBasicData?.cover, "tiny");

    if (projectBasicData?.cover === null) {
      setCoverImageUrl(null);
    } else if (src && src !== coverImageUrl) {
      setCoverImageUrl(src);
      setCoverImageId(projectBasicData?.cover?.id ?? null);
    }
  }, [projectBasicData?.cover]);

  useEffect(() => {
    return () => {
      setCoverImageUrl("");
      setCoverImageId(null);
    };
  }, []);

  const dispatch = useDispatch();

  const removeCoverPhoto = () => {
    axios
      .delete(`${API_URLS.deleteAsset}/${activeWorkspaceUuid}/${coverImageId}`)
      .then(function () {
        setCoverImageUrl(null);
      })
      .catch(function (error) {});
  };

  const getPlaceholderImage = () => {
    if (coverImageUrl) {
      return coverImageUrl;
    }

    return projectSquarePlaceholder;
  };

  const setNewImage = (file: any) => {
    if (!projectBasicData) return;
    setCoverImageUrl(null);
    setLoading(true);
    uploadProjectCover(
      file,
      dispatch,
      projectBasicData.projectId,
      activeWorkspaceUuid,
      setLoading,
    );
  };

  return (
    <div className="project-cover">
      {loading ? (
        <Skeleton width={48} height={48} borderRadius={8} />
      ) : (
        <>
          <CustomImage
            className="project-cover__image"
            src={getPlaceholderImage()}
          />

          {!coverImageUrl ? (
            <div
              className="project-cover__file"
              onClick={() => setShowUploadLogoModal(true)}
            >
              <div className="project-cover__icon">
                <PlusIcon />
              </div>
            </div>
          ) : (
            <Dropdown>
              {(isOpen, setIsOpen) => (
                <>
                  <div
                    className="project-cover__icon"
                    onClick={() => setIsOpen(true)}
                  >
                    <SettingsIcon />
                  </div>

                  <DropdownMenu isOpen={isOpen}>
                    <DropdownMenuItem onClick={removeCoverPhoto}>
                      <IDHFormattedMessage
                        id="ws_remove_cover_photo"
                        defaultMessage="Remove cover photo"
                      />
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="image-input"
                      onClick={() => setShowUploadLogoModal(true)}
                    >
                      <IDHFormattedMessage
                        id="ws_change_cover_photo"
                        defaultMessage="Change cover photo"
                      />
                    </DropdownMenuItem>
                  </DropdownMenu>
                </>
              )}
            </Dropdown>
          )}
        </>
      )}
      {showUploadLogoModal && (
        <ImageCropModal
          uploadFunction={setNewImage}
          onClose={() => setShowUploadLogoModal(false)}
        />
      )}
    </div>
  );
}

export { ProjectCover };
