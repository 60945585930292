import React from "react";

import classNames from "classnames";

import CustomImage from "src/app/components/CustomImage/CustomImage";
import SocialProfileIcons from "src/app/components/IconsWithStatus/SocialProfileIcons/SocialProfileIcons";
import { ShareTaskList, getPlaceholderImage } from "./ShareTaskList";

function ShareMultipleColumnsTaskDetails({ tasks, taskAvatarShape }) {
  if (tasks.length === 1) {
    const task = tasks[0];
    return (
      <div className="share-multiple-columns__task-details">
        <div
          className={classNames("ws-table__image", {
            "ws-table__image--square": task.taskType !== "creator",
          })}
        >
          <CustomImage
            className={classNames("ws-table__image-cover", {
              "ws-table__image-cover--circle":
                taskAvatarShape === taskAvatarShape.Circle,
              "ws-table__image-cover--square":
                taskAvatarShape === taskAvatarShape.Square,
            })}
            src={getPlaceholderImage(task)}
            alt=""
          />
        </div>
        <div
          className={classNames("share-multiple-columns__task-name-wrapper", {
            "share-multiple-columns__task-name-wrapper--with-socials":
              task.socialProfiles?.length > 0,
          })}
        >
          <div className="share-multiple-columns__task-name">{task.title}</div>
          {task.socialProfiles?.length > 0 && (
            <SocialProfileIcons data={task.socialProfiles} />
          )}
        </div>
      </div>
    );
  }

  return <ShareTaskList tasks={tasks} taskAvatarShape={taskAvatarShape} />;
}

export default ShareMultipleColumnsTaskDetails;
