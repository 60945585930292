import React, { useEffect, useState } from "react";
import "./SettingsModal.scss";

import axios from "axios";
import { API_URLS } from "src/utils/API_URLS";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "src/redux/reducers";
import { showErrorToast } from "src/utils/methods";
import Modal from "../components/Modal/Modal";
import SettingsSidebar from "./components/SettingsSidebar/SettingsSidebar";
import { SettingsTabContent } from "./components/SettingsTabContent/SettingsTabContent";
import { showToast } from "../methods/showToast";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import {
  MemberSettingsTab,
  ProjectSettingsTab,
  SettingsContext,
  SettingsTab,
  TaskSettingsTab,
  WorkspaceSettingsTab,
} from "./enums";
import { AutomationsSection } from "./tabs/Automations/Automations";
import { changeSettingsSection, changeSettingsTab } from "./methods";
import { ExtensionsSection } from "./tabs/Extensions/Extensions";
import { CreatorDatabaseConfigurationSection } from "./tabs/CreatorDatabaseConfiguration/CreatorDatabaseConfiguration";
import { TeamsSection } from "./tabs/Teams/Teams";
import { IntegrationsSection } from "./tabs/Integrations/Integrations";

export type UniversalSettingsTab =
  | SettingsTab
  | WorkspaceSettingsTab
  | ProjectSettingsTab
  | TaskSettingsTab
  | MemberSettingsTab
  | string;

interface Props {
  onClose: () => void;
  members?: any;
  automations?: any;
  contextTargetUuid: string | undefined;
  disableTransition?: boolean;
}

export const SettingsModal: React.FC<Props> = (props) => {
  const {
    onClose,
    members,
    automations,
    contextTargetUuid,
    disableTransition,
  } = props;

  const [settingsData, setSettingsData] = useState<any>({});
  const [creatorDiscovery, setCreatorDiscovery] = useState(null);
  const [data, setData] = useState<any>({});
  const [dataGathered, setDataGathered] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    mainReducer: {
      activeWorkspaceUuid,
      identity,
      workspacesList,
      settingsModalData,
    },
    projectReducer: { membersList },
  } = useSelector((state: RootState) => state);

  const { context, tab } = settingsModalData;

  const workspaceData = workspacesList?.find(
    (workspace) => workspace.uuid === activeWorkspaceUuid,
  );

  const history = useHistory();

  useEffect(() => {
    if (tab === SettingsTab.CreatorDiscovery && !creatorDiscovery) {
      getCreatorDiscoveryData();
    }
  }, [tab]);

  useEffect(() => {
    if (context === SettingsContext.Workspace && membersList && dataGathered) {
      getData();
    }
  }, [membersList]);

  const setTab = (tab: UniversalSettingsTab) => {
    changeSettingsTab(history, tab);

    switch (tab) {
      case SettingsTab.Automations:
        changeSettingsSection(history, AutomationsSection.AutomationsMain);
        break;
      case SettingsTab.Extensions:
        changeSettingsSection(history, ExtensionsSection.ExtensionsList);
        break;
      case SettingsTab.CreatorDatabaseConfiguration:
        changeSettingsSection(
          history,
          CreatorDatabaseConfigurationSection.ExtensionsList,
        );
        break;
      case SettingsTab.Teams:
        changeSettingsSection(history, TeamsSection.TeamList);
        break;
      case SettingsTab.Integrations:
        changeSettingsSection(history, IntegrationsSection.ExtensionsList);
        break;
    }
  };

  const getSettingsUrl = () => {
    if (!contextTargetUuid) return null;

    switch (context) {
      case SettingsContext.Project:
        return API_URLS.getProjectSettings.replace(
          ":projectUuid:",
          contextTargetUuid,
        );

      case SettingsContext.Task:
        return API_URLS.getTaskSettings.replace(
          ":taskUuid:",
          contextTargetUuid,
        );

      case SettingsContext.Workspace:
        return API_URLS.getWorkspaceSettings.replace(
          ":workspaceUuid:",
          contextTargetUuid,
        );

      case SettingsContext.Member:
        return API_URLS.getMemberSettings;

      default:
        return null;
    }
  };

  const getSettingsData = () => {
    const url = getSettingsUrl();
    if (!url) return;

    axios
      .get(url)
      .then((response) => {
        const {
          data: { content },
        } = response;

        setSettingsData(content);
        setLoading(false);
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_something_went_wrong"
            defaultMessage="Something went wrong!"
          />,
        );
        onClose();
      });
  };

  const getContextData = () => {
    switch (context) {
      case SettingsContext.Project: {
        getProjectData();
        break;
      }

      case SettingsContext.Task: {
        getTaskData();
        break;
      }

      case SettingsContext.Workspace: {
        if (membersList === undefined) {
          setDataGathered(true);
          return;
        }
        getWorkspaceData();
        break;
      }

      case SettingsContext.Member: {
        setDataGathered(true);
        break;
      }
    }
  };

  const getProjectData = () => {
    if (!contextTargetUuid) return null;
    axios
      .get(API_URLS.getProject.replace(":projectUuid:", contextTargetUuid))
      .then((response) => {
        const {
          data: { content },
        } = response;
        setData({ ...content, projectName: content.title });
        setDataGathered(true);
      });
  };

  const getTaskData = () => {
    if (!contextTargetUuid) return null;
    axios
      .get(API_URLS.getTaskDetails.replace(":taskId:", contextTargetUuid))
      .then((response) => {
        const {
          data: { content },
        } = response;

        setData({
          ...content,
          projectName: content.title,
          permissions: identity.permissions,
        });
        setDataGathered(true);
      });
  };

  const getWorkspaceData = () => {
    setData({
      projectName: workspaceData?.name,
      permissions: identity?.permissions,
      members: membersList,
    });
    setDataGathered(true);
  };

  const getCreatorDiscoveryData = async () => {
    const url = API_URLS.getCreatorDiscoverySettings.replace(
      ":workspaceUuid:",
      activeWorkspaceUuid,
    );

    try {
      const {
        data: { content },
      } = await axios.get(url);
      setCreatorDiscovery(content);
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const getData = () => {
    getContextData();
    getSettingsData();
  };

  useEffect(() => {
    getData();
  }, [context]);

  return (
    <Modal
      onClose={onClose}
      className="settings-modal"
      key="settings-modal"
      disableTransition={disableTransition}
    >
      <SettingsSidebar
        data={settingsData}
        tab={tab}
        setTab={setTab}
        loading={loading || !dataGathered}
      />
      <SettingsTabContent
        onClose={onClose}
        tab={tab}
        getData={getData}
        getCreatorDiscoveryData={getCreatorDiscoveryData}
        contextData={{
          uuid: contextTargetUuid,
          workspaceUuid: activeWorkspaceUuid,
          ...settingsData?.context,
          permissions: data?.permissions,
        }}
        members={{ ...data, ...members }}
        automations={{ ...data, ...automations }}
        creatorDiscovery={creatorDiscovery}
        loading={loading || !dataGathered}
      />
    </Modal>
  );
};
