import React from "react";
import "./StoreConfiguration.scss";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { ModalText, ModalTitle } from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { copyLink } from "src/utils/methods";
import { ReactComponent as URLIcon } from "src/images/link-alt.svg";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import { SettingsButtons } from "../../components/SettingsTabContent/SettingsTabContent";

interface Props {
  onClose: () => void;
}

export const StoreConfiguration: React.FC<Props> = (props) => {
  const { onClose } = props;

  const {
    mainReducer: { identity },
  } = useSelector((state: RootState) => state);

  const { wsCouponWebhookUrl } = identity;

  return (
    <>
      <AnimatedDiv className="store-configuration">
        <ModalTitle>
          <IDHFormattedMessage
            id="ws_store_configuration"
            defaultMessage="Store configuration"
          />
        </ModalTitle>

        <ModalText>
          <IDHFormattedMessage
            id="ws_to_track_performance_of_promo_codes"
            defaultMessage="To track the performance of your promo codes, it is necessary to configure your ecommerce platform correctly. You may need to create a custom plugin for your ecommerce site that will send real-time notifications each time a promo code is redeemed."
          />
        </ModalText>

        <ModalText>
          <IDHFormattedMessage
            id="ws_if_the_integration_instruction"
            defaultMessage="If the integration instructions described below are unclear, please consult your software development team."
          />
        </ModalText>

        <div className="store-configuration__instructions">
          <div className="settings-row-element__left-title">
            <IDHFormattedMessage
              id="ws_webhook_url"
              defaultMessage="Webhook URL"
            />
          </div>
          <div className="store-configuration__instructions-webhook-url">
            <input type="text" value={wsCouponWebhookUrl} readOnly />
            <button onClick={() => copyLink(wsCouponWebhookUrl)}>
              <URLIcon />
              <IDHFormattedMessage id="ws_copy_url" defaultMessage="Copy URL" />
            </button>
          </div>

          <div className="settings-row-element__left-title">
            <IDHFormattedMessage
              id="ws_webhook_specification"
              defaultMessage="Webhook specification"
            />
          </div>

          <div className="store-configuration__instructions-webhook-specification">
            <ModalText>
              <IDHFormattedMessage
                id="ws_when_a_client_redeems"
                defaultMessage="When a client redeems a promo code, your ecommerce platform must
              send a"
              />{" "}
              <code>POST</code>{" "}
              <IDHFormattedMessage
                id="ws_request_to_the"
                defaultMessage="request to the"
              />{" "}
              <code>Webhook URL</code>{" "}
              <IDHFormattedMessage
                id="ws_provided_above_the"
                defaultMessage="provided above. The"
              />{" "}
              <code>Content-Type</code>{" "}
              <IDHFormattedMessage
                id="ws_header_of_the_request_must_be"
                defaultMessage="header of the request must be"
              />{" "}
              <code>application/json</code>.{" "}
              <IDHFormattedMessage
                id="ws_the_request_body_should_contain"
                defaultMessage="The request body should contain a
                valid JSON object structure that includes at least these four
                properties"
              />{" "}
              :
              <ul>
                <li>
                  <code>order_id</code>:{" "}
                  <IDHFormattedMessage
                    id="ws_a_unique_order_identifier_in_your_store"
                    defaultMessage="a unique order identifier in your store"
                  />{" "}
                  (<code>string</code>)<br />
                </li>
                <li>
                  <code>amount</code>:{" "}
                  <IDHFormattedMessage
                    id="ws_the_total_order_value"
                    defaultMessage="the total order value"
                  />{" "}
                  (<code>float</code>
                  )<br />
                </li>
                <li>
                  <code>currency</code>:{" "}
                  <IDHFormattedMessage
                    id="ws_the_3_digit_iso_code"
                    defaultMessage="the 3-digit ISO code of the order
                    currency"
                  />{" "}
                  (<code>string</code>)<br />
                </li>
                <li>
                  <code>coupon</code>:{" "}
                  <IDHFormattedMessage
                    id="ws_the_promo_code_used_by_the_client"
                    defaultMessage="the promo code used by the client when
                    placing the order"
                  />{" "}
                  (<code>string</code>)
                </li>
              </ul>
            </ModalText>
          </div>

          <div className="store-configuration__instructions-section">
            <ModalText>
              <IDHFormattedMessage
                id="ws_please_see_the_sample_request_body_below"
                defaultMessage="Please see the sample request body below, which includes
                    additional optional fields"
              />
              :
              <pre>
                &#123;{"\n"}
                &emsp;"order_id": "38718",{"\n"}
                &emsp;"amount": 59.99, {"\n"}
                &emsp;"currency": "EUR", {"\n"}
                &emsp;"coupon": "WINTERSALE", {"\n"}
                &emsp;"discount": 10, {"\n"}
                &emsp;"margin": 25, {"\n"}
                &emsp;"country": "DEU" {"\n"}
                &#125;
              </pre>
            </ModalText>
          </div>

          <div className="store-configuration__instructions-section">
            <ModalText>
              <IDHFormattedMessage
                id="ws_after_sending_the_webhook"
                defaultMessage="After sending the webhook, expect to receive a"
              />{" "}
              <code>200 OK</code>{" "}
              <IDHFormattedMessage
                id="ws_response_code_with_plain_text_body_content"
                defaultMessage="response code with plain-text body content"
              />{" "}
              <code>ok</code>.{" "}
              <IDHFormattedMessage
                id="ws_until_you_receive_this_exact_response"
                defaultMessage="Until you receive this exact response, it is
                necessary to keep retrying the webhook notification"
              />
              .
            </ModalText>
          </div>
        </div>
      </AnimatedDiv>

      <SettingsButtons onClose={onClose} />
    </>
  );
};
