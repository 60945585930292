import React from "react";
import "./DropdownPortal.scss";

import Dropdown from "rc-dropdown";
import "rc-dropdown/assets/index.css";
import { overlaysRef } from "src/App";
import zIndex from "src/utils/zIndex";

export function DropdownPortal({
  trigger = ["click"],
  placement = "bottomLeft",
  disabled = false,
  ...rest
}) {
  return (
    <Dropdown
      {...rest}
      trigger={disabled ? ["none"] : trigger}
      overlayStyle={{ zIndex: zIndex.dropdown, paddingTop: "10px" }}
      placement={placement}
      getPopupContainer={() => overlaysRef.current}
      ref={rest?.ddRef}
    />
  );
}
