import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  forwardRef,
} from "react";
import "./CustomInput.scss";
import classNames from "classnames";

interface Props
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  id?: string;
  value?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: "text" | "number" | "password" | "email";
  placeholder?: string;
  fullWidth?: boolean;
  onBlur?: () => void;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  required?: boolean;
  wide?: boolean;
  error?: boolean;
  disabled?: boolean;
  pattern?: string;
  size?: number;
  className?: string;
}

const CustomInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    type,
    value,
    name,
    onChange,
    placeholder,
    fullWidth,
    onBlur,
    onKeyPress,
    required,
    wide,
    error,
    disabled,
    pattern,
    size,
    id,
    className,
    ...restProps
  } = props;

  return (
    <input
      ref={ref}
      type={type}
      className={classNames(
        "ws-input",
        {
          "ws-input--fullWidth": fullWidth,
          "ws-input--wide": wide,
          "ws-input--error": error,
          "ws-input--disabled": disabled,
        },
        className,
      )}
      value={value}
      id={id}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      required={required}
      disabled={disabled}
      pattern={pattern}
      size={size}
      {...restProps}
    />
  );
});

export default CustomInput;

type CustomLabelFontSize = "small" | "medium" | "big";
type CustomLabelFontWeight = "thin" | "medium" | "bold";
type CustomLabelColor = "light-grey" | "dark-grey";

interface CustomInputLabelProps
  extends React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  children: React.ReactNode;
  error?: boolean;
  aboveInput?: boolean;
  required?: boolean;
  className?: string;
  fontSize?: CustomLabelFontSize;
  fontWeight?: CustomLabelFontWeight;
  fontColor?: CustomLabelColor;
}

export function CustomInputLabel(props: CustomInputLabelProps) {
  const {
    children,
    error,
    aboveInput,
    required,
    className,
    fontSize = "small",
    fontWeight = "thin",
    fontColor = "light-grey",
    ...rest
  } = props;
  return (
    <label
      className={classNames(
        "ws-input-label",
        className,
        `ws-input-label--font-${fontSize}`,
        `ws-input-label--${fontWeight}`,
        `ws-input-label--${fontColor}`,
        {
          "ws-input-label--error": error,
          "ws-input-label--above-input": aboveInput,
        },
      )}
      {...rest}
    >
      {children}
      {required && <span className="ws-input-label--required">*</span>}
    </label>
  );
}
