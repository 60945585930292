import {
  GlobalMetaFieldDetails,
  GlobalMetaFieldListItemData,
  RecruitmentFormMetaFieldAvailable,
  WsExtension,
} from "src/types";
import * as actionTypes from "./extensionTypes";

export interface ExtensionReducerState {
  extensionList: WsExtension[];
  workspaceExtensionList: WsExtension[];
  isExtensionListLoading: boolean;
  isWorkspaceExtensionListLoading: boolean;
  isHowItWorksModalVisible: boolean;
  isMetaFieldMembersListLoading: boolean;
  globalMetaFieldList: GlobalMetaFieldListItemData[];
  isGlobalMetaFieldListLoading: boolean;
  globalMetaFieldDetails: GlobalMetaFieldDetails;
  availableProjectMetaFields: RecruitmentFormMetaFieldAvailable[];
  availableWorkspaceGlobalTaskMetaFields: RecruitmentFormMetaFieldAvailable[];
}

export const globalMetaFieldDetailsInitialState = {
  uuid: "",
  wsGlobalTaskMetaFieldUuid: null,
  members: [],
  name: "",
};

const initialState: ExtensionReducerState = {
  extensionList: [],
  workspaceExtensionList: [],
  isExtensionListLoading: false,
  isWorkspaceExtensionListLoading: false,
  isHowItWorksModalVisible: false,
  isMetaFieldMembersListLoading: false,
  globalMetaFieldList: [],
  isGlobalMetaFieldListLoading: false,
  globalMetaFieldDetails: globalMetaFieldDetailsInitialState,
  availableProjectMetaFields: [],
  availableWorkspaceGlobalTaskMetaFields: [],
};

function extensionReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_EXTENSION_LIST:
      return {
        ...state,
        isExtensionListLoading: true,
      };
    case actionTypes.GET_WORKSPACE_EXTENSION_LIST:
      return {
        ...state,
        isWorkspaceExtensionListLoading: true,
      };
    case actionTypes.GET_WORKSPACE_EXTENSION_LIST_SUCCESS:
      return {
        ...state,
        workspaceExtensionList: action.payload.data.content,
        isWorkspaceExtensionListLoading: false,
      };
    case actionTypes.GET_WORKSPACE_EXTENSION_LIST_FAIL:
      return {
        ...state,
        isExtensionListLoading: false,
      };
    case actionTypes.GET_EXTENSION_LIST_SUCCESS:
      return {
        ...state,
        extensionList: action.payload.data.content,
        isExtensionListLoading: false,
      };
    case actionTypes.GET_EXTENSION_LIST_FAIL:
      return {
        ...state,
        isExtensionListLoading: false,
      };
    case actionTypes.SET_EXTENSION_LIST:
      return {
        ...state,
        extensionList: action.payload.extensionList,
      };
    case actionTypes.SET_WORKSPACE_EXTENSIONS_LIST:
      return {
        ...state,
        workspaceExtensionList: action.payload.extensionList,
      };
    case actionTypes.CLEAR_EXTENSION_LIST:
      return {
        ...state,
        extensionList: [],
      };
    case actionTypes.SET_GLOBAL_TASK_META_VALUE_LIST:
      return {
        ...state,
        globalMetaFieldList: action.payload.globalMetaFieldList,
        isGlobalMetaFieldListLoading: false,
      };
    case actionTypes.SET_IS_GLOBAL_TASK_META_VALUE_LIST_LOADING:
      return {
        ...state,
        isGlobalMetaFieldListLoading:
          action.payload.isGlobalMetaFieldListLoading,
      };
    case actionTypes.GET_AVAILABLE_PROJECT_META_FIELDS_SUCCESS:
      return {
        ...state,
        availableProjectMetaFields: action.payload.data.content,
      };
    case actionTypes.GET_AVAILABLE_WORKSPACE_GLOBAL_TASK_META_FIELDS_SUCCESS:
      return {
        ...state,
        availableWorkspaceGlobalTaskMetaFields: action.payload.data.content,
      };
    case actionTypes.CLEAR_AVAILABLE_PROJECT_META_FIELDS:
      return {
        ...state,
        availableProjectMetaFields: [],
      };
    case actionTypes.CLEAR_AVAILABLE_WORKSPACE_GLOBAL_TASK_META_FIELDS:
      return {
        ...state,
        availableWorkspaceGlobalTaskMetaFields: [],
      };
    default:
      return state;
  }
}

export default extensionReducer;
