import React, { ReactNode, PropsWithChildren } from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalTitle } from "src/app/components/Modal/Modal";
import { ExtensionsSection } from "./Extensions";
import { ChangeSettingsSectionParam } from "../../methods";

interface ExtensionViewProps {
  setExtensionsSection: (section: ChangeSettingsSectionParam) => void;
  title: ReactNode;
}

export default function ExtensionView({
  setExtensionsSection,
  title,
  children,
}: PropsWithChildren<ExtensionViewProps>) {
  return (
    <>
      <div className="extensions__header">
        <button
          onClick={() => setExtensionsSection(ExtensionsSection.ExtensionsList)}
          className="extensions__back-btn"
        >
          <IDHFormattedMessage id="ws_extensions" defaultMessage="Extensions" />
        </button>
        <ModalTitle>{title}</ModalTitle>
      </div>
      {children}
    </>
  );
}
