import React from "react";
import "./SettingsComponents.scss";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export function SettingsSeparator() {
  return <div className="settings-separator" />;
}

interface RowElementProps {
  titleId: string;
  titleDefaultMessage: string;
  descId: string;
  descDefaultMessage: string;
  children?: React.ReactNode;
}

export const SettingsRowElement: React.FC<RowElementProps> = (props) => {
  const { titleId, titleDefaultMessage, descId, descDefaultMessage, children } =
    props;

  return (
    <div className="settings-row-element">
      <div className="settings-row-element__left">
        <span className="settings-row-element__left-title">
          <IDHFormattedMessage
            id={titleId}
            defaultMessage={titleDefaultMessage}
          />
        </span>

        <span className="settings-row-element__left-description">
          <IDHFormattedMessage
            id={descId}
            defaultMessage={descDefaultMessage}
          />
        </span>
      </div>

      <div>{children}</div>
    </div>
  );
};
