import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import DropdownPortal from "src/app/components/DropdownPortal";
import { useScrollLock } from "src/app/methods/useScrollLock";
import { MetaDataValue } from "src/redux/dictionary/types";
import { openDictionaryDetails } from "src/app/components/DictionaryDetails/methods";
import { tableDataType } from "src/app/components/Table/Table";
import { RootState } from "src/redux/reducers";
import { getDictionaryIcon } from "src/app/methods/getDictionaryTypes";
import { getDictionaryAutoComplete } from "src/redux";
import { ReactComponent as PlusIcon } from "../../../images/plus-transparent.svg";
import { ReactComponent as NavigationIcon } from "../../../images/navigation.svg";
import DictionaryElementSelectorMenu from "./DictionaryElementSelectorMenu";
import DictionaryElementTableLabel from "./DictionaryElementTableLabel";

import "./DictionaryElementSelector.scss";

interface Props {
  value: MetaDataValue | null;
  wsDictionaryUuid: string | undefined;
  wsDictionarySubFieldUuid: string | null | undefined;
  onValueChange: (newValue: any) => void;
  emptyFieldClick?: () => void;
  toRight?: boolean;
  onClose?: () => void;
  displayOnlyMenu?: boolean;
  disabled?: boolean;
  dataType?: tableDataType;
}

export interface SelectDictionaryOption {
  value: any;
  label: React.JSX.Element;
  tableLabel: React.JSX.Element;
  name: any;
  subField: string;
}

export const DictionaryElementSelector: React.FC<Props> = ({
  onValueChange,
  value,
  wsDictionaryUuid,
  wsDictionarySubFieldUuid,
  emptyFieldClick,
  toRight,
  onClose,
  displayOnlyMenu,
  disabled,
  dataType,
}: Props) => {
  const { unlockScroll, lockScroll } = useScrollLock();

  const [selectedOption, setSelectedOption] = useState<
    Partial<SelectDictionaryOption>
  >({});
  const [forceOpen, setForceOpen] = useState<boolean>(false);
  const [isVisible, setVisible] = useState<boolean>(false);

  const ddRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    dictionaryReducer: {
      dictionaryAutoCompletes,
      isDictionaryAutocompleteRequestLoading,
    },
    mainReducer: { identity },
  } = useSelector((state: RootState) => state);

  const Menu = (
    <DictionaryElementSelectorMenu
      handleChange={(newValue: any) => {
        setSelectedOption(newValue);
        onValueChange(newValue.value);
        setForceOpen(false);
      }}
      onMenuClose={() => {
        unlockScroll();
        if (onClose) {
          onClose();
        }
      }}
      isVisible={isVisible}
      forceOpen={forceOpen}
      selectedOption={selectedOption}
      wsDictionaryUuid={wsDictionaryUuid}
      wsDictionarySubFieldUuid={wsDictionarySubFieldUuid}
    />
  );

  useEffect(() => {
    if (!wsDictionaryUuid) return;

    const list =
      dictionaryAutoCompletes[`dictionaryType_${wsDictionaryUuid}`] || [];

    if (!list.length) return;

    const foundOption = list.find((listItem) => {
      return listItem.uuid === value;
    });
    if (foundOption && foundOption.uuid !== selectedOption.value) {
      setSelectedOption({
        value: foundOption.uuid,
        tableLabel: (
          <DictionaryElementTableLabel
            title={foundOption.title}
            icon={getDictionaryIcon(identity, wsDictionaryUuid)}
          />
        ),
      });
    }
    if (!foundOption) {
      setSelectedOption({});
    }
  }, [value, wsDictionaryUuid, dictionaryAutoCompletes, identity]);

  useEffect(() => {
    if (
      !wsDictionaryUuid ||
      dictionaryAutoCompletes[`dictionaryType_${wsDictionaryUuid}`] ||
      isDictionaryAutocompleteRequestLoading
    )
      return;

    if (
      dataType === tableDataType.GlobalTask ||
      dataType === tableDataType.GlobalMetaFieldProjectWizardFields
    ) {
      dispatch(getDictionaryAutoComplete(wsDictionaryUuid));
    }
  }, [
    dataType,
    wsDictionaryUuid,
    dictionaryAutoCompletes,
    isDictionaryAutocompleteRequestLoading,
  ]);

  if (displayOnlyMenu) {
    return Menu;
  }

  if (!selectedOption.value && disabled) return null;

  return (
    <DropdownPortal
      className="select-dropdown"
      toRight={toRight}
      overlay={Menu}
      onVisibleChange={(v: boolean) => {
        setVisible(v);
        if (v) {
          lockScroll();
        }
      }}
      ddRef={ddRef}
      disabled={disabled}
    >
      {selectedOption.value ? (
        <span className="dictionary-element-selector__value">
          {selectedOption.tableLabel}
          <NavigationIcon
            className="dictionary-element-selector__value-link"
            onClick={(e) => {
              e.stopPropagation();
              openDictionaryDetails(history, selectedOption.value);
            }}
          />
        </span>
      ) : (
        <div className="tasks-table__empty-field" onClick={emptyFieldClick}>
          <PlusIcon />
        </div>
      )}
    </DropdownPortal>
  );
};
