import React, { useEffect, useRef, useState } from "react";
import "./ExternalFileField.scss";

import { ReactComponent as PlusIcon } from "src/images/plus-transparent.svg";

import { AttachmentPlacement, TaskType } from "src/types";
import { showToast } from "src/app/methods/showToast";

import Attachment from "src/app/Task/Attachments/Attachment";
import { GalleryLightboxLocal } from "src/app/components/GalleryLightboxLocal/GalleryLightboxLocal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import AttachmentLoader from "src/app/Task/Attachments/AttachmentLoader";
import { ExternalFileButton } from "../ExternalFileButton/ExternalFileButton";
import { uploadExternalFile } from "../../../methods/uploadExternalFile";

export function ExternalFileField({
  files,
  setFiles,
  uuid,
  objectId,
  dataType,
  wsWorkspaceUuid,
  metafieldUuid,
  metaFieldType,
  token,
  readOnly = false,
  setSubmitDisabled,
  extensionUuid,
  formType,
}) {
  const [showGallery, setShowGallery] = useState(false);
  const [gallerySources, setGallerySources] = useState([]);
  const inputRef = useRef(null);

  const loading = files?.filter((f) => f.loading === true).length > 0;

  useEffect(() => {
    setSubmitDisabled && setSubmitDisabled(loading);
  }, [loading]);

  const uploadFile = async (e) => {
    try {
      const response = await uploadExternalFile(
        e,
        uuid,
        metafieldUuid,
        metaFieldType,
        dataType,
        objectId,
        wsWorkspaceUuid,
        token,
        setFiles,
        extensionUuid,
        formType,
      );

      if (formType === "recruitmentForm") {
        setFiles((prevFiles) => {
          return prevFiles.map((file) => {
            const assetData = response.find(
              (asset) => asset.uuid === file.assetId,
            );
            if (!assetData) return file;
            return { ...file, realName: assetData.realName, loading: false };
          });
        });
      }
    } catch (e) {
      console.error(e);
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_something_went_wrong_check_console"
          defaultMessage="Something went wrong. Check console."
        />,
      );
    }
  };

  const showLightBox = (images) => {
    if (images[0].url) {
      setGallerySources(images);
      setShowGallery(true);
    }
  };

  const hideLightBox = () => {
    setShowGallery(false);
  };

  if (!files?.length) {
    if (readOnly === false)
      return <ExternalFileButton uploadFile={uploadFile} />;
    return (
      <div className="access-link-page__input-field__preview">
        <IDHFormattedMessage
          id="ws_no_files_to_display"
          defaultMessage="No files to display."
        />
      </div>
    );
  }

  return (
    <div className="external-file-field">
      {files.map((file, index) => {
        return (
          <div className="external-file-field__box" key={index}>
            {file.loading ? (
              <AttachmentLoader fileName={file.name} />
            ) : (
              <Attachment
                data={file}
                place={AttachmentPlacement.InlineWithLabel}
                taskType={TaskType.Creator}
                customGalleryClickHandler={showLightBox}
              />
            )}
          </div>
        );
      })}
      {loading !== true && readOnly === false && (
        <span className="external-file-field__add-button">
          <input ref={inputRef} type="file" multiple onChange={uploadFile} />
          <PlusIcon />
        </span>
      )}
      {showGallery && (
        <GalleryLightboxLocal
          show={showGallery}
          sources={gallerySources}
          hide={hideLightBox}
        />
      )}
    </div>
  );
}
