export const CREATE_TASK_COMMENT = "CREATE_TASK_COMMENT";
export const DELETE_TASK_COMMENT = "DELETE_TASK_COMMENT";
export const EDIT_TASK_COMMENT = "EDIT_TASK_COMMENT";
export const SET_COMMENT_AS_EDITABLE = "SET_COMMENT_AS_EDITABLE";
export const SET_USER_ACCEPTED_CONTENT = "SET_USER_ACCEPTED_CONTENT";
export const SET_USER_REJECTED_CONTENT = "SET_USER_REJECTED_CONTENT";
export const CLEAR_USER_ACCEPTED_CONTENT = "CLEAR_USER_ACCEPTED_CONTENT";
export const CLEAR_USER_REJECTED_CONTENT = "CLEAR_USER_REJECTED_CONTENT";
export const GET_CONTENT_PROPOSAL_SUMMARY = "GET_CONTENT_PROPOSAL_SUMMARY";
export const GET_CONTENT_PROPOSAL_SUMMARY_FAIL =
  "GET_CONTENT_PROPOSAL_SUMMARY_FAIL";
export const GET_CONTENT_PROPOSAL_SUMMARY_SUCCESS =
  "GET_CONTENT_PROPOSAL_SUMMARY_SUCCESS";
export const UPDATE_CONTENT_PROPOSAL_FILTER_STATUS =
  "UPDATE_CONTENT_PROPOSAL_FILTER_STATUS";
export const CLEAR_CONTENT_PROPOSAL_FILTER_STATUS =
  "CLEAR_CONTENT_PROPOSAL_FILTER_STATUS";
export const SET_REPLY_TO_COMMENT_DATA = "SET_REPLY_TO_COMMENT_DATA";
export const CLEAR_REPLY_TO_COMMENT_DATA = "CLEAR_REPLY_TO_COMMENT_DATA";
