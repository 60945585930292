import React, { useState } from "react";
import "./PerformanceBox.scss";

import classNames from "classnames";
import { ReactComponent as ChartIcon } from "src/images/chart.svg";
import { ReactComponent as CloseIcon } from "src/images/close-arrow.svg";
import { PerformanceElement } from "../../Performance";
import { SimpleContent } from "./components/SimpleContent/SimpleContent";
import { ChartContent } from "./components/ChartContent/ChartContent";

export const SUPPORTED_PERFORMANCE_VIEW_TYPES = ["campaign", "month"];

interface Props {
  data: PerformanceElement;
}

export const PerformanceBox: React.FC<Props> = (props) => {
  const { data } = props;

  const [advancedMode, setAdvancedMode] = useState(
    SUPPORTED_PERFORMANCE_VIEW_TYPES.includes(
      data.defaultVisualizationBreakdown,
    ),
  );

  const renderContent = () => {
    if (advancedMode) return <ChartContent data={data} />;
    return <SimpleContent data={data} />;
  };

  return (
    <div
      className={classNames("performance-box", {
        "performance-box--chart-mode": advancedMode,
      })}
    >
      {renderContent()}

      {Boolean(data.breakdownValues) && (
        <span
          className="performance-box__mode-button"
          onClick={() => setAdvancedMode((v) => !v)}
        >
          {advancedMode ? <CloseIcon /> : <ChartIcon />}
        </span>
      )}
    </div>
  );
};
