import React, { ReactNode } from "react";
import { formatDistance } from "date-fns";

import { convertToLocalTimezone } from "src/app/methods/convertToLocalTimezone";
import CommentMenuDropdown from "src/app/dropdowns/CommentMenuDropdown";
import { IMember } from "src/app/project/ProjectTypes";
import { Comment } from "src/redux/comment/types";

interface CommentHeaderProps {
  comment: Comment;
  commentAuthor: IMember | undefined;
  commentId: string;
  commentAuthorCustomElement: ReactNode;
  hasAccessToRemoveWsTaskComment: boolean;
}

export default function CommentHeader({
  comment,
  commentId,
  commentAuthor,
  commentAuthorCustomElement,
  hasAccessToRemoveWsTaskComment,
}: CommentHeaderProps) {
  return (
    <div className="comment__header">
      <div className="comment__header-left">
        <div className="comment__author">{commentAuthor?.name}</div>
        {commentAuthorCustomElement}
        <div
          className="comment__creation-date"
          title={
            comment.edited &&
            convertToLocalTimezone(comment.edited.date).format(
              "DD.MM.YYYY HH:mm",
            )
          }
        >
          {comment.edited
            ? formatDistance(
                new Date(
                  convertToLocalTimezone(comment.edited.date).toString(),
                ),
                new Date(),
                {
                  addSuffix: true,
                },
              )
            : "-"}
        </div>
      </div>
      <div className="comment__header-right">
        <CommentMenuDropdown
          commentId={commentId}
          commentAuthor={comment.author}
          commentType={comment.type}
          hasAccessToRemoveWsTaskComment={hasAccessToRemoveWsTaskComment}
        />
      </div>
    </div>
  );
}
