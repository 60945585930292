import React, { useState } from "react";

import classNames from "classnames";
import { useDispatch } from "react-redux";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";

import { updateSubtaskRank } from "../../../redux/task/taskActions";
import { generateRankString } from "../../../utils/rankStrings";
import AnimatedDiv from "../../components/AnimatedDiv/AnimatedDiv";
import Subtask from "../Subtask/Subtask";

interface Props {
  subtasksList: any[];
  taskDetails: { taskId: string };
  projectId: string;
}

const SubtasksList: React.FC<Props> = (props) => {
  const { subtasksList, taskDetails, projectId } = props;

  const dispatch = useDispatch();
  const [placeholderProps, setPlaceholderProps] = useState<any>({});

  const handleDragUpdate = (update: any) => {
    if (!update.destination) {
      return;
    }

    const queryAttr = "data-rbd-drag-handle-draggable-id";

    const { draggableId } = update;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery) as {
      clientHeight: number;
      clientWidth: number;
      parentNode: any;
    };

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, destinationIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft,
      ),
    });
  };

  const handleDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }
    const { destination, source } = result;

    const sourceField: any = subtasksList[source.index];
    const targetField: any = subtasksList[destination.index];
    const fieldIndex = subtasksList.findIndex(
      (field) => field.id === targetField.id,
    );

    let newRank = "";
    if (destination.index < source.index) {
      newRank = generateRankString(
        subtasksList[fieldIndex - 1]?.rank || "",
        subtasksList[fieldIndex].rank,
      );
      // move bottom
    } else {
      newRank = generateRankString(
        subtasksList[fieldIndex].rank,
        subtasksList[fieldIndex + 1]?.rank || "",
      );
    }

    dispatch(updateSubtaskRank(sourceField.id, newRank));
    setPlaceholderProps({});
  };

  return (
    <DragDropContext
      onDragUpdate={handleDragUpdate}
      onDragEnd={(result) => handleDragEnd(result)}
    >
      <Droppable droppableId="droppable" direction="vertical">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={classNames("subtask-list__content-row", {
              "subtask-list__content-row--dragging-over":
                snapshot.isDraggingOver,
            })}
            {...provided.droppableProps}
          >
            {subtasksList
              ?.sort((a: any, b: any) => {
                return a.rank.localeCompare(b.rank);
              })
              .map((subtask: any, index: number): React.ReactElement => {
                return (
                  <Subtask
                    key={subtask.id}
                    name={subtask.title}
                    data={subtask}
                    taskDetails={taskDetails}
                    subtaskId={subtask.id}
                    subtaskRank={subtask.rank}
                    index={index}
                    projectId={projectId}
                    isNew={subtask.isNew}
                  />
                );
              })}
            {provided.placeholder}
            {placeholderProps && snapshot.isDraggingOver && (
              <AnimatedDiv
                className="subtask__placeholder"
                style={{
                  top: placeholderProps?.clientY,
                  left: placeholderProps?.clientX,
                  height: placeholderProps?.clientHeight,
                  width: placeholderProps?.clientWidth,
                }}
              />
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SubtasksList;
