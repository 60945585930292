import { FieldType } from "src/types";
import {
  getFilterObjectIndex,
  initiateNewBoolValFilter,
  initiateNewDateFilter,
  initiateNewRatingFilter,
  initiateNewSelectFilter,
  injectNullSelectOption,
  pushOptionToExistingSelectFilter,
} from "./tableSortingUtils";

export const generateFilters = (data, dictionaryAutoCompletes) => {
  const activeFilters = [];
  data.forEach((row) => {
    row.metadata
      .sort((a, b) => a.rank?.localeCompare(b.rank))
      .forEach((field) => {
        switch (field.type) {
          case FieldType.SingleSelect:
          case FieldType.MultiSelect:
          case FieldType.AggregatedSelect:
            const foundOptions = field.data.singleSelectOptions;
            foundOptions?.forEach((foundOption) => {
              const foundSelectFilterIndex = activeFilters.findIndex(
                (filter) => filter.uuid === field.uuid,
              );

              if (foundSelectFilterIndex !== -1) {
                const doesOptionExistInFilters = activeFilters[
                  foundSelectFilterIndex
                ].metaFieldOptions.singleSelectOptions.find(
                  (option) => option.value === foundOption.value,
                );

                if (doesOptionExistInFilters) return;

                pushOptionToExistingSelectFilter(
                  activeFilters,
                  foundOption,
                  foundSelectFilterIndex,
                );
              } else {
                initiateNewSelectFilter(
                  field.type,
                  activeFilters,
                  field.name,
                  field.uuid,
                  foundOption,
                );
              }
            });
            break;

          case FieldType.BoolVal:
            const objIndex2 = getFilterObjectIndex(field.name, activeFilters);
            if (objIndex2 === -1)
              initiateNewBoolValFilter(activeFilters, field.name, field.uuid);

            break;
          case FieldType.Date:
            const objIndex = getFilterObjectIndex(field.name, activeFilters);
            if (objIndex === -1)
              initiateNewDateFilter(activeFilters, field.name, field.uuid);
            break;

          case FieldType.DictionaryElement: {
            const objIndex = getFilterObjectIndex(field.name, activeFilters);
            if (objIndex !== -1) return;
            if (!dictionaryAutoCompletes) return;
            const { wsDictionaryUuid } = field.data;
            const list =
              dictionaryAutoCompletes[`dictionaryType_${wsDictionaryUuid}`] ||
              [];

            const options = list.map((item) => ({
              value: item.uuid,
              name: item.title,
            }));

            activeFilters.push({
              type: FieldType.DictionaryElement,
              name: field.name,
              uuid: field.uuid,
              metaFieldOptions: {
                singleSelectOptions: [
                  injectNullSelectOption(field.fieldId),
                  ...options,
                ],
              },
            });
            break;
          }
          case FieldType.Rating: {
            const objIndex = getFilterObjectIndex(field.name, activeFilters);
            if (objIndex === -1)
              initiateNewRatingFilter(activeFilters, field.name, field.fieldId);
            break;
          }
        }
      });
  });
  return activeFilters;
};
