import React from "react";
import "./DottedBox.scss";
import classNames from "classnames";

function DottedBox({ children, className, ...rest }) {
  return (
    <div className={classNames("dotted-box", className)} {...rest}>
      {children}
    </div>
  );
}

export default DottedBox;
