import moment from "moment";
import React from "react";
import { SummaryBoxList } from "../../../components/SummaryBoxList/SummaryBoxList";

function Creators(props) {
  const { foundFields } = props;

  return <SummaryBoxList foundFields={foundFields} />;
}

export { Creators };
