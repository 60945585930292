import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPortal } from "react-dom";
import FsLightbox from "fslightbox-react";

import { setGallerySources } from "../../../redux";
import "./GalleryLightbox.scss";

export function GalleryLightbox() {
  const [isVisible, setIsVisible] = useState(false);

  const {
    taskReducer: { gallerySources },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const lightboxRef = useRef(null);

  useEffect(() => {
    if (gallerySources.length > 0) {
      setIsVisible(true);
    }
  }, [gallerySources]);

  const handleClose = () => {
    setIsVisible(false);
    dispatch(setGallerySources([]));
  };

  return createPortal(
    <FsLightbox
      toggler={isVisible}
      types={gallerySources.map((gallerySource) => gallerySource.type)}
      sources={gallerySources.map((gallerySource) => gallerySource.url)}
      key={gallerySources[0]?.url}
      // key is remountComponent toggler so in future i suggest using gallerySuccess.length
      ref={lightboxRef}
      onClose={handleClose}
    />,
    document.querySelector(".Overlays"),
  );
}
