import React, { useEffect, useState } from "react";

export function ElementsPageNumber(props) {
  const [number, setNumber] = useState(null);

  const { section, pages } = props;

  useEffect(() => {
    if (!pages.length) return;

    const newNumber = pages.findIndex((item) => {
      const element = item.elements?.find((el) => {
        return el.i === section;
      });

      return element;
    });

    setNumber(newNumber + 1);
  }, [pages]);

  return <span className="elements-page-number">{number}</span>;
}
