import React, { useState } from "react";

import moment from "moment";

import {
  moment24HourTimeFormat,
  momentDateFormat,
} from "src/app/components/CustomDatePicker/utils";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import placeholderIcon from "src/images/placeholder-image.svg";

interface AddedByProps {
  name?: string;
  avatarUrl?: string;
  dateCreated: moment.Moment | string | null;
}

export function AddedBy({ name, avatarUrl, dateCreated }: AddedByProps) {
  const [src, setSrc] = useState(avatarUrl || placeholderIcon);

  const handleError = () => {
    setSrc(placeholderIcon);
  };

  return (
    <div className="task-story-feed">
      <div className="task-story-feed__activity">
        <span>
          <IDHFormattedMessage id="ws_added_by" defaultMessage="added by" />
        </span>
        <img
          src={src}
          className="task-story-feed__avatar"
          onError={handleError}
          alt="added-by-pic-example"
        />
        <span className="task-story-feed__name">{name}</span>
        <span>
          <IDHFormattedMessage id="ws_on" defaultMessage="on" />
        </span>
        <span className="task-story-feed__date">
          {moment(dateCreated).format(momentDateFormat)}
        </span>
        <span className="task-story-feed__time">
          {moment(dateCreated).format(moment24HourTimeFormat)}
        </span>
      </div>
    </div>
  );
}
