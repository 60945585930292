import {
  RecruitmentFormDetailsCover,
  RecruitmentFormUserDataMembersWithNotification,
  MetafieldAccessMode,
} from "src/types";

export enum ActiveTabs {
  Share = "share",
  General = "general",
  Fields = "fields",
  Notifications = "notifications",
}

export type RecruitmentFormUserDataMetafields = {
  wsMetaFieldType: string;
  wsMetaFieldUuid: string;
  wsMetaFieldAccessMode: MetafieldAccessMode;
  wsMetaFieldIsRequired: boolean;
  wsMetaFieldDescription: string | null;
};

export type RecruitmentFormUserData = {
  recruitmentFormMetaFields: {
    metaFields: RecruitmentFormUserDataMetafields[];
    membersWithNotification: RecruitmentFormUserDataMembersWithNotification[];
    caption: string;
    description: string;
  };
  wsAccessLinkCover: RecruitmentFormDetailsCover | string | null;
  recruitmentFormUuid: string | null;
  wsExtensionType: string | null;
};
