import React from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import { calculateStrokeWidth, renderCustomizedLabel } from "./PieChartUtils";
import "./Doughnut.scss";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import METHODS from "../../helpers/methods";

export function Doughnut({ data, dataKey, width, height, size, colors }) {
  function CustomTooltip({ active, payload }) {
    if (!active) return null;
    for (const bar of payload)
      return (
        <div className="recharts-custom-tooltip">
          <IDHFormattedMessage id={bar.name} defaultMessage={bar.name} />
          {" : "}
          <span className="recharts-custom-tooltip__amount">
            {METHODS.splitNumbers(bar.value)}
          </span>
        </div>
      );
    return null;
  }
  return (
    <PieChart width={width} height={height}>
      <Pie
        data={data}
        dataKey={dataKey}
        labelLine={false}
        outerRadius={width / 2}
        innerRadius={width / 2 - size}
        strokeWidth={calculateStrokeWidth(data, dataKey)}
        label={renderCustomizedLabel}
        startAngle={-270}
      >
        {data.map((entry, index) => (
          <Cell fill={colors[index % colors.length]} />
        ))}
      </Pie>
      <Tooltip content={<CustomTooltip />} />
    </PieChart>
  );
}
