import React, { useEffect, useState } from "react";
import { uuidv7 as uuid } from "uuidv7";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { WrappedComponentProps, injectIntl, useIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { TaskType } from "src/types";
import { CustomInputLabel } from "../../../../components/CustomInput/CustomInput";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import { ModalColumn } from "../../../../components/Modal/Modal";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { ConditionsModal } from "../../modals/ConditionsModal/ConditionsModal";
import TargetValueModal from "../../modals/TargetValueModal/TargetValueModal";
import { FieldIcons } from "../FieldIcons/FieldIcons";
import { ReactComponent as TrashCanIcon } from "../../../../../images/trash-can.svg";
import { MFState, MFStateEntries } from "../../types";
import {
  formulaMathematicalOptions,
  isMetricsDataArrayType,
} from "../../utils";
import "./Summary.scss";

interface Props extends WrappedComponentProps<"intl"> {
  getCountConditions: (selectedTaskType: TaskType | null) => void;
  getAvailableTargets: (selectedTaskType: TaskType | null) => void;
  state: MFState;
  setState: (type: MFStateEntries, value: any) => void;
  globalFieldMode: boolean | undefined;
}

const Summary: React.FC<Props> = (props) => {
  const {
    getCountConditions,
    getAvailableTargets,
    state: {
      metricsData,
      selectedSummarySources,
      targetValue,
      formulaValue,
      conditionColumns,
      conditionValues,
      availableTargets,
      availableTargetsValue,
      targetValueOption,
      valueSourceOptions,
      selectedIcon,
      selectedColor,
      addIcon,
      selectedTaskType,
    },
    globalFieldMode,
    setState,
  } = props;

  const [showTargetValueModal, setShowTargetValueModal] = useState(false);
  const [showConditionsModal, setShowConditionsModal] = useState(false);

  const intl = useIntl();

  const clearTargetValue = () => {
    setState("targetValue", "");
    setState("availableTargetsValue", {});
  };

  const clearConditions = () => {
    setState("conditionValues", [
      {
        uuid: uuid(),
        column: {
          label: "",
          value: null,
        },
        value: {
          label: "",
          value: null,
        },
      },
    ]);
  };

  useEffect(() => {
    if (isMetricsDataArrayType(metricsData)) {
      setState(
        "valueSourceOptions",
        metricsData.map(
          (item: { sourceName: string; taskMetaFieldUuid: string }) => ({
            label: item.sourceName,
            value: item.taskMetaFieldUuid,
          }),
        ),
      );
    }
  }, [metricsData]);

  useEffect(() => {
    getCountConditions(selectedTaskType.value);
    getAvailableTargets(selectedTaskType.value);
  }, [selectedTaskType.value]);

  useEffect(() => {
    if (globalFieldMode) {
      setState("conditionColumns", []);
      setState("availableTargets", []);
      setState("selectedSummarySources", []);
      setState("valueSourceOptions", []);
      clearTargetValue();
      clearConditions();
    }
  }, [selectedTaskType.value, globalFieldMode]);

  return (
    <div className="summary">
      <div className="summary__row summary__row--to-top">
        <ModalColumn>
          <CustomInputLabel htmlFor="source">
            <IDHFormattedMessage
              id="ws_data_source"
              defaultMessage="Data source"
            />
          </CustomInputLabel>
          <CustomSelect
            id="source"
            value={selectedSummarySources}
            options={valueSourceOptions}
            isMulti
            placeholder={`${translateMessage({
              intl,
              id: "ws_select",
              defaultMessage: "Select",
            })}...`}
            onChange={(newValue: any) =>
              setState("selectedSummarySources", newValue)
            }
            closeMenuOnSelect={false}
          />
        </ModalColumn>
        <ModalColumn>
          <CustomInputLabel htmlFor="formula">
            <IDHFormattedMessage
              id="ws_logical_function"
              defaultMessage="Logical function"
            />
          </CustomInputLabel>
          <CustomSelect
            id="formula"
            value={formulaValue}
            options={formulaMathematicalOptions}
            placeholder={`${translateMessage({
              intl,
              id: "ws_select",
              defaultMessage: "Select",
            })}...`}
            //   isSearchable
            onChange={(newValue: any) => setState("formulaValue", newValue)}
          />
        </ModalColumn>
      </div>

      {/* {formulaValue.value && selectedSummarySources.length ? ( */}
      <div className="summary__row summary__row--with-separator">
        <span
          className="summary__row-button"
          onClick={() => setShowTargetValueModal(true)}
        >
          <IDHFormattedMessage
            id="ws_set_target_value"
            defaultMessage="Set target value"
          />
          <Tooltip
            center
            content={
              <IDHFormattedMessage
                id="ws_tooltip_summary_specify_target_value"
                defaultMessage="Specify the target value by manually entering the value or selecting the source (field) that defines it."
              />
            }
          />
        </span>
        <span className="summary__row-value">
          {targetValueOption === "custom" && targetValue ? (
            <>
              {targetValue}
              <TrashCanIcon onClick={clearTargetValue} />
            </>
          ) : targetValueOption === "from-source" &&
            availableTargetsValue.value ? (
            <>
              {availableTargetsValue.value}
              <TrashCanIcon onClick={clearTargetValue} />
            </>
          ) : null}
        </span>
      </div>

      <div className="summary__row summary__row--with-separator">
        <span
          className="summary__row-button"
          onClick={() => setShowConditionsModal(true)}
        >
          <IDHFormattedMessage
            id="ws_set_conditions"
            defaultMessage="Set conditions"
          />
          <Tooltip
            center
            content={
              <IDHFormattedMessage
                id="ws_tooltip_summary_specify_conditions"
                defaultMessage="Specify the conditions (values of specific fields) that must be met in order for a given field to be counted."
              />
            }
          />
        </span>
        {conditionValues.length > 1 && (
          <span className="summary__row-value">
            {conditionValues.length - 1}&nbsp;{" "}
            <IDHFormattedMessage id="ws_condition" defaultMessage="Condition" />
            {conditionValues.length > 2 && "s"}
            <TrashCanIcon onClick={clearConditions} />
          </span>
        )}

        {/* {showMatchSelect && (
            <CustomSelect
              value={matchValue}
              onChange={(newValue: ISelectOption) => setMatchValue(newValue)}
              options={matchOptions}
              placeholder="Select..."
            />
          )} */}
      </div>
      {/* ) : null} */}

      {/* {addFieldCountCondition && (
        <FieldCountConditions
          conditionColumns={conditionColumns}
          conditionValues={conditionValues}
          setConditionValues={setConditionValues}
        />
      )} */}

      {showTargetValueModal && (
        <TargetValueModal
          onClose={() => {
            setShowTargetValueModal(false);
          }}
          targetValueOption={targetValueOption}
          availableTargetsValue={availableTargetsValue}
          availableTargets={availableTargets}
          targetValue={targetValue}
          setState={setState}
        />
      )}

      {showConditionsModal && (
        <ConditionsModal
          onClose={() => setShowConditionsModal(false)}
          conditionColumns={conditionColumns}
          conditionValues={conditionValues}
          setConditionValues={(newValue: any) =>
            setState("conditionValues", newValue)
          }
        />
      )}

      <FieldIcons
        selectedIcon={selectedIcon}
        setSelectedIcon={(newValue: any) => setState("selectedIcon", newValue)}
        selectedColor={selectedColor}
        setSelectedColor={(newValue: any) =>
          setState("selectedColor", newValue)
        }
        addIcon={addIcon}
        setAddIcon={(newValue: any) => setState("addIcon", newValue)}
      />
    </div>
  );
};

export default injectIntl(Summary);
