import React from "react";
import "./BrandSafetyEmailNotificationCellTooltip.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export function BrandSafetyEmailNotificationCellTooltip() {
  return (
    <div className="brand-safety-email-notification-tooltip">
      <div className="brand-safety-email-notification-tooltip__text">
        <IDHFormattedMessage
          id="ws_brand_safety_email_notification_tooltip"
          defaultMessage="You do not receive email notifications. Only the list owner receives them."
        />
      </div>
    </div>
  );
}
