import React, { useEffect, useState } from "react";
import "./PublicationBox.scss";

import classNames from "classnames";

import { useSelector } from "react-redux";
import { uuidv7 as uuid } from "uuidv7";
import { renderGeneratorMetaValue } from "src/app/generator-data/components/ContentBox/ContentBox";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import CustomImage from "../../../../../components/CustomImage/CustomImage";
import CreatorPlaceholder from "../../../../../../images/creator-placeholder.svg";
import PublicationPlaceholder from "../../../../../../images/publication-placeholder.svg";
import { useGetMetaField } from "../../../../../methods/useGetMetaField";
import { getImageSource } from "../../../../../../utils/methods";
import Pictogram, {
  PictogramVariants,
} from "../../../../../components/Pictogram/Pictogram";
import XssFilter from "../../../../../components/XssFilter/XssFilter";
import StatisticsItem from "./StatisticsItem";

const CAPTIONS_BLACKLIST = ["instagram publication", "tiktok publication"];

export function PublicationBox(props) {
  const { data, creatorsList, board, onClick } = props;

  const [parentKey, setParentKey] = useState(uuid());
  const [wrapperKey, setWrapperKey] = useState(uuid());
  const [metadataValues, setMetadataValues] = useState([]);
  const [visibleMetadata, setVisibleMetadata] = useState([]);
  const [oldVisibleMetadata, setOldVisibleMetadata] = useState([]);
  const [oldStatistics, setOldStatistics] = useState([]);
  const [enableSaving, setEnableSaving] = useState(false);

  const {
    reportReducer: { publicationsMetadata, showLoader, loaderText, loading },
  } = useSelector((state) => state);

  const synchronizing = showLoader && loaderText.toLowerCase().includes("sync");
  const oneLineDescription = data?.publication?.summaryStatistics?.length > 6;
  const parentId = `publication-box-${data.taskId}`;

  useEffect(() => {
    if (synchronizing) return;
    const wrapper = document.getElementById(`wrapper-${data.taskId}`);
    if (!wrapper) {
      setParentKey(uuid());
    }
    setWrapperKey(uuid());

    const items = document.querySelectorAll(
      `#${parentId} .publication-box__statistics-item`,
    );

    const newMetadataValues = Array.from(items)
      .filter((item) => {
        if (item.id.includes("pub-meta-")) {
          const uuid = item.id.replace("pub-meta-", "");

          const newMetaField = data.metadata.find(
            (field) => field.uuid === uuid,
          );
          const oldMetaField = visibleMetadata.find(
            (field) => field.uuid === uuid,
          );

          if (
            newMetaField &&
            oldMetaField &&
            newMetaField.value !== oldMetaField.value
          ) {
            return false;
          }
        }

        if (item.id.includes("pub-stats-")) {
          const fieldName = item.id.replace("pub-stats-", "");

          const newMetaField = data?.publication?.summaryStatistics.find(
            (field) => field.name === fieldName,
          );
          const oldMetaField = oldStatistics.find(
            (field) => field.name === fieldName,
          );

          if (
            newMetaField &&
            oldMetaField &&
            newMetaField.value !== oldMetaField.value
          ) {
            return false;
          }
        }

        return true;
      })
      .map((item) => {
        return {
          id: item.id,
          html: item.innerHTML,
        };
      });

    setMetadataValues(newMetadataValues);

    const newVisibleMetadata =
      publicationsMetadata
        ?.filter((item) => item?.reportVisible)
        .map((item) => {
          const metaField = data.metadata.find(
            (field) => field.uuid === item.uuid,
          );

          return metaField;
        }) || [];

    setOldStatistics(data?.publication?.summaryStatistics);
    setOldVisibleMetadata(visibleMetadata);
    setVisibleMetadata(newVisibleMetadata);
  }, [
    publicationsMetadata,
    synchronizing,
    data?.publication?.summaryStatistics,
  ]);

  useEffect(() => {
    if (!loading && !enableSaving) {
      setTimeout(() => {
        setEnableSaving(true);
      }, 1000);
    }
  }, [loading]);

  useEffect(() => {
    if (synchronizing) return;
    if (
      enableSaving &&
      (visibleMetadata.length || data?.publication?.summaryStatistics)
    ) {
      applySavedValues();
    }
    if (visibleMetadata.length > oldVisibleMetadata.length) {
      const items = document.querySelectorAll(
        `#${parentId} .publication-box__statistics-item`,
      );

      const lastItem = items[items.length - 1];
      if (
        lastItem &&
        !metadataValues.find((item) => item.id === lastItem?.id)
      ) {
        setMetadataValues((values) => [
          ...values,
          { id: lastItem.id, html: lastItem.innerHtml },
        ]);
      }
    }
  }, [visibleMetadata]);

  const applySavedValues = () => {
    const items = document.querySelectorAll(
      `#${parentId} .publication-box__statistics-item`,
    );

    Array.from(items).forEach((item) => {
      const field = metadataValues.find((el) => el.id === item.id);

      if (field) {
        item.innerHTML = field.html;
      }
    });
  };

  const renderStatistics = (statsData, maxElements, isSentiment) => {
    return statsData
      ?.filter((item) => item.value !== null)
      .slice(0, maxElements)
      .map((item) => {
        const iconName = item.icon.name;
        const id = `pub-stats-${item.name}`;
        const folder = iconName.includes("/")
          ? iconName.split("/")[0]
          : "Social";
        const name = iconName.includes("/") ? iconName.split("/")[1] : iconName;
        const variant =
          folder === "SocialProviders"
            ? PictogramVariants.Original
            : PictogramVariants[item.icon.color];

        const renderValue = () => {
          if (isSentiment) return `${Math.round(item.value * 100)}%`;

          if (!item.value) return 0;
          return renderGeneratorMetaValue(item);
        };

        return (
          <StatisticsItem
            id={id}
            key={`${item.icon.name}-${item.icon.color}`}
            name={name}
            variant={variant}
            folder={folder}
          >
            {renderValue()}
          </StatisticsItem>
        );
      });
  };

  const creatorId = useGetMetaField("creator", data?.metadata)?.value;
  const publicationType = useGetMetaField("type", data?.metadata);
  const creator = creatorsList?.find((item) => item?.taskId === creatorId);
  const caption = data.title || data.projectName;

  return (
    <div
      key={parentKey}
      id={parentId}
      className={classNames("publication-box", {
        "publication-box--board": board,
      })}
      onClick={onClick}
    >
      <div className="publication-box__info">
        <div className="publication-box__info-creator">
          <CustomImage
            src={getImageSource(creator?.cover, "tiny", CreatorPlaceholder)}
          />
          <span className="publication-box__info-title">{creator?.title}</span>
        </div>
        {publicationType?.value && (
          <div className="publication-box__info-provider">
            <Pictogram
              identifier={`SocialProviders/${publicationType?.value}`}
              variant={PictogramVariants.Original}
            />
          </div>
        )}
      </div>
      <a
        href={data?.publication?.url}
        rel="noopener noreferrer nofollow"
        onClick={(e) => {
          if (board) {
            e.preventDefault();
          }
        }}
      >
        <CustomImage
          className="publication-box__image"
          src={getImageSource(data?.cover, "small", PublicationPlaceholder)}
        />
      </a>
      <div
        key={wrapperKey}
        id={`wrapper-${data.taskId}`}
        className="publication-box__statistics"
      >
        {renderStatistics(data?.publication?.summaryStatistics, 9)}
        {visibleMetadata
          .filter((item) => item.value)
          .map((item) => {
            const id = `pub-meta-${item.uuid}`;
            const value = item.value ? renderGeneratorMetaValue(item) : 0;
            if (item.fieldValueSource === MetaFieldType.EMV) {
              return (
                <StatisticsItem
                  key={id}
                  id={id}
                  folder="Sentiment"
                  name={MetaFieldType.EMV}
                  variant={PictogramVariants.Original}
                >
                  {value}
                </StatisticsItem>
              );
            }
            return (
              <div
                key={id}
                id={id}
                className="publication-box__statistics-item"
              >
                <div className="publication-box__statistics-item-wrapper">
                  <div className="publication-box__statistics-item-wrapper-name">
                    {item.name}
                  </div>
                  {/* &nbsp; */}
                  <div className="publication-box__statistics-item-wrapper-value">
                    {value}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div
        className={classNames("publication-box__description", {
          "publication-box__description--one-line": oneLineDescription,
        })}
      >
        {!CAPTIONS_BLACKLIST.includes(caption.toLowerCase()) && (
          <span dangerouslySetInnerHTML={{ __html: XssFilter(caption) }} />
        )}
      </div>
      <div className="publication-box__sentiments">
        {renderStatistics(data?.publication?.summarySentimentData, 4, true)}
      </div>
    </div>
  );
}
