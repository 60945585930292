import React, {
  useEffect,
  useState,
  ChangeEvent,
  SetStateAction,
  Dispatch,
  useReducer,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { FilesDropdownMenu } from "src/app/metafields/FileField/FilesDropdown";
import {
  deleteFileBasingOnContext,
  uploadFileBasingOnContext,
} from "src/app/metafields/FileField/taskFunctions";
import { getGlobalTasks } from "src/redux/creator-database/creatorDatabaseActions";
import { MetaFileData } from "src/app/metafields/MultipleFileField/MultipleFileField";
import { Rectangle } from "@glideapps/glide-data-grid";
import { RootState } from "src/redux/reducers";
import { MetaDataFileValue } from "src/redux/dictionary/types";
import { tableDataType } from "src/app/components/Table/Table";
import { useParams } from "react-router";
import { getCellPosition, initialStaticCellSettings } from "../../utils";
import { GlideDataGridContext, StaticCellSettings } from "../../types";
import creatorDatabaseFiltersReducer, {
  creatorDatabaseFiltersReducerInitialState,
} from "../../../../CreatorDatabase/redux/creatorDatabaseFiltersReducer";
import { getDictionaryListElement } from "../../../../../redux";

interface FilesDropdownWrapperProps {
  filesData: MetaDataFileValue[];
  uuid: string;
  rowUuid: string;
  readOnly: boolean;
  closeDropdown: () => void;
  setStaticCellSettings: Dispatch<SetStateAction<StaticCellSettings>>;
  bounds: Rectangle;
  context: GlideDataGridContext;
}

function FilesDropdownWrapper({
  setStaticCellSettings,
  filesData,
  uuid,
  rowUuid,
  readOnly,
  closeDropdown,
  bounds,
  context,
}: FilesDropdownWrapperProps) {
  const [localFilesData, setLocalFilesData] = useState<MetaFileData[]>([]);
  const params = useParams<{ dictionaryUuid: string }>();

  const dispatch = useDispatch();
  const {
    creatorDatabaseReducer: { globalTaskColumns },
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const [creatorDatabaseFilters] = useReducer(
    creatorDatabaseFiltersReducer,
    creatorDatabaseFiltersReducerInitialState(globalTaskColumns),
  );
  const { filters, sortBy, sort } = creatorDatabaseFilters;

  const isGlobalTask = context === tableDataType.CreatorDatabase;

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    closeDropdown();
    setStaticCellSettings({
      numberOfFiles: e.target.files?.length ?? 0,
      style: getCellPosition(bounds),
    });
    uploadFileBasingOnContext({
      e,
      wsMetaFieldUuid: uuid,
      wsWorkspaceUuid: activeWorkspaceUuid,
      setFilesData: setLocalFilesData,
      wsTaskUuid: rowUuid,
      thenCallback: () => {
        if (isGlobalTask) {
          dispatch(
            getGlobalTasks(activeWorkspaceUuid, { filters, sortBy, sort }),
          );
        }

        if (context === tableDataType.Dictionary) {
          dispatch(getDictionaryListElement(params.dictionaryUuid, rowUuid));
        }
      },
      finallyCallback: () => {
        setStaticCellSettings(initialStaticCellSettings);
      },
      context: isGlobalTask ? tableDataType.GlobalTask : context,
    });
  };

  const deleteFile = (assetId: string) => {
    deleteFileBasingOnContext({
      assetId,
      taskId: rowUuid,
      fieldId: uuid,
      thenCallback: () => {
        setLocalFilesData((prev) => {
          const newFilesList = prev.filter((file) => file.assetId !== assetId);
          if (!newFilesList.length) {
            closeDropdown();
          }
          return newFilesList;
        });
        if (isGlobalTask) {
          dispatch(
            getGlobalTasks(activeWorkspaceUuid, { filters, sortBy, sort }),
          );
        }

        if (context === tableDataType.Dictionary) {
          dispatch(getDictionaryListElement(params.dictionaryUuid, rowUuid));
        }
      },
      context: isGlobalTask ? tableDataType.GlobalTask : context,
    });
  };

  useEffect(() => {
    setLocalFilesData(filesData);
  }, [filesData]);

  return (
    <FilesDropdownMenu
      filesData={localFilesData}
      deleteFile={deleteFile}
      uploadFile={uploadFile}
      readOnly={readOnly}
    />
  );
}

export default FilesDropdownWrapper;
