export const addMetaFieldsToGridElements = (
  filteredMetadata,
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
) => {
  filteredMetadata.forEach((field) => {
    const label = `metadata-${field.key
      .replaceAll(" ", "-")
      ?.replaceAll(".", "")
      .toLowerCase()}-${field.uuid}`;

    const element = {
      ...field,
      label,
      gridBoxType: "metadata",
      visible: field.isVisible,
      section: "campaignDetails",
    };

    // applying saved template
    if (savedTemplate?.length && !templateApplied) {
      if (savedTemplate.find((item) => item.i === element.label)) {
        newGridElements.push(element);
      } else {
        newHiddenGridElements.push(element);
      }
    }
    // applying default template
    else if (field.isVisible && field.value) {
      newGridElements.push(element);
    } else {
      newHiddenGridElements.push(element);
    }
  });
};
