import React from "react";
import SingleMetaField from "src/app/Task/components/TaskMetaFields/SingleMetaField";
import { tableDataType } from "src/app/components/Table/Table";

export const renderCell = (cellData, projectId, columnValueSource) => {
  return (
    <SingleMetaField
      metaData={cellData}
      dataType={tableDataType.Project}
      exteralProjectId={projectId}
      wsGlobalProjectMetaFieldUuid={cellData.wsGlobalProjectMetaFieldUuid}
      columnValueSource={columnValueSource}
    />
  );
};
