export const addCustomGridElement = (
  title,
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied
) => {
  const element = {
    label: title,
    gridBoxType: title,
    section: title,
  };

  // applying saved template
  if (savedTemplate?.length && !templateApplied) {
    if (savedTemplate.find((item) => item.i === element.label)) {
      newGridElements.push(element);
    } else {
      newHiddenGridElements.push(element);
    }
  }
  // applying default template
  else {
    newGridElements.push(element);
  }
};
