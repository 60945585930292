import React, { createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";

import "./CreatorDiscoveryTool.scss";
import { injectIntl } from "react-intl";
import { TrialExceededModal } from "src/app/modals/TrialExceededModal/TrialExceededModal";
import { DefaultLayout } from "../layouts/DefaultLayout";

import AudiencesResultsContainer from "./legacy/app/audiences-manager/views/AudiencesResultsContainer";
import AudiencesSearchContainer from "./legacy/app/audiences-manager/views/AudiencesSearchContainer";
import InfluencersListHandler from "./handlers/InfluencersListHandler";
import {
  closeTrialExceededCDTModal,
  getSettings,
} from "./legacy/app/audiences-manager/audiencesManagerActions";
import WebsocketListener from "../../../WebsocketListener";

export const cdtScrollRef = createRef(null);

function CreatorDiscoveryTool(props) {
  const {
    mainReducer: { activeWorkspaceUuid, identity },
    audiencesManagerReducer: {
      trialExceededCDTModalVisible,
      hasAccessToInfluencerInsightsTool,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  if (
    !identity.permissions?.workspace.includes("creator_discovery") &&
    activeWorkspaceUuid
  ) {
    return <Redirect to={`/workspace/${activeWorkspaceUuid}/projects`} />;
  }

  return (
    <DefaultLayout name="creator-discovery-tool" scrollRef={cdtScrollRef}>
      <Switch>
        <Route
          path={`/workspace/${activeWorkspaceUuid}/creator-discovery-tool/lists`}
          exact
          component={InfluencersListHandler}
        />
        <Route
          path={`/workspace/${activeWorkspaceUuid}/creator-discovery-tool/list/:listId`}
          component={
            hasAccessToInfluencerInsightsTool ? AudiencesResultsContainer : null
          }
        />
        <Route
          path={`/workspace/${activeWorkspaceUuid}/creator-discovery-tool/search`}
          component={AudiencesSearchContainer}
        />
        <Route
          path={`/workspace/${activeWorkspaceUuid}/creator-discovery-tool/search-results`}
          component={AudiencesResultsContainer}
        />
      </Switch>
      {trialExceededCDTModalVisible && (
        <TrialExceededModal
          onClose={() => dispatch(closeTrialExceededCDTModal())}
        />
      )}
      <WebsocketListener />
    </DefaultLayout>
  );
}

export default injectIntl(CreatorDiscoveryTool);
