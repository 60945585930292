import React, { useRef } from "react";
import { ReactComponent as UploadIcon } from "src/images/upload.svg";

import "./ExternalFileButton.scss";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export function ExternalFileButton({ uploadFile }) {
  const inputRef = useRef(null);
  return (
    <div className="external-file-button">
      <UploadIcon
        width={24}
        height={24}
        className="external-file-button__icon"
      />
      <div className="external-file-button__title">
        <IDHFormattedMessage id="ws_upload_file" defaultMessage="Upload file" />
      </div>
      <div className="external-file-button__text">
        <IDHFormattedMessage id="ws_formats" defaultMessage="Formats" />: jpg,
        jpeg, png, mov, mp4, pptx, docx, xlsx, pdf, txt, zip <br />
        <IDHFormattedMessage
          id="ws_max_file_size"
          defaultMessage="Max file size"
        />
        : 50MB
      </div>
      <input
        className="external-file-button__input"
        ref={inputRef}
        type="file"
        multiple
        onChange={uploadFile}
      />
    </div>
  );
}
