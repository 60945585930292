export const GET_EXTENSION_LIST = "GET_EXTENSION_LIST";
export const GET_EXTENSION_LIST_SUCCESS = "GET_EXTENSION_LIST_SUCCESS";
export const GET_EXTENSION_LIST_FAIL = "GET_EXTENSION_LIST_FAIL";
export const GET_WORKSPACE_EXTENSION_LIST = "GET_WORKSPACE_EXTENSION_LIST";
export const GET_WORKSPACE_EXTENSION_LIST_SUCCESS =
  "GET_WORKSPACE_EXTENSION_LIST_SUCCESS";
export const GET_WORKSPACE_EXTENSION_LIST_FAIL =
  "GET_WORKSPACE_EXTENSION_LIST_FAIL";
export const GET_EXTENSION_DETAILS = "GET_EXTENSION_DETAILS";
export const GET_EXTENSION_DETAILS_SUCCESS = "GET_EXTENSION_DETAILS_SUCCESS";
export const GET_EXTENSION_DETAILS_FAIL = "GET_EXTENSION_DETAILS_FAIL";
export const SET_EXTENSION_DETAILS = "SET_EXTENSION_DETAILS";
export const SET_EXTENSION_DETAILS_SUCCESS = "SET_EXTENSION_DETAILS_SUCCESS";
export const SET_EXTENSION_LIST = "SET_EXTENSION_LIST";
export const SET_WORKSPACE_EXTENSIONS_LIST = "SET_WORKSPACE_EXTENSIONS_LIST";
export const SET_EXTENSION_LIST_SUCCESS = "SET_EXTENSION_LIST_SUCCESS";
export const CLEAR_EXTENSION_DETAILS = "CLEAR_EXTENSION_DETAILS";
export const CLEAR_EXTENSION_LIST = "CLEAR_EXTENSION_LIST";
export const SET_GLOBAL_TASK_META_VALUE_LIST =
  "SET_GLOBAL_TASK_META_VALUE_LIST";
export const SET_IS_GLOBAL_TASK_META_VALUE_LIST_LOADING =
  "SET_IS_GLOBAL_TASK_META_VALUE_LIST_LOADING";
export const GET_AVAILABLE_PROJECT_META_FIELDS =
  "GET_AVAILABLE_PROJECT_META_FIELDS";
export const GET_AVAILABLE_PROJECT_META_FIELDS_SUCCESS =
  "GET_AVAILABLE_PROJECT_META_FIELDS_SUCCESS";
export const GET_AVAILABLE_WORKSPACE_GLOBAL_TASK_META_FIELDS =
  "GET_AVAILABLE_WORKSPACE_GLOBAL_TASK_META_FIELDS";
export const GET_AVAILABLE_WORKSPACE_GLOBAL_TASK_META_FIELDS_SUCCESS =
  "GET_AVAILABLE_WORKSPACE_GLOBAL_TASK_META_FIELDS_SUCCESS";
export const CLEAR_AVAILABLE_PROJECT_META_FIELDS =
  "CLEAR_AVAILABLE_PROJECT_META_FIELDS";
export const CLEAR_AVAILABLE_WORKSPACE_GLOBAL_TASK_META_FIELDS =
  "CLEAR_AVAILABLE_WORKSPACE_GLOBAL_TASK_META_FIELDS";
