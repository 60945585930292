import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import "./GlideDataGridHeader.scss";

import { ReactComponent as ArrowIcon } from "src/images/arrow-down.svg";
import classNames from "classnames";
import { TooltipSettings } from "../../types";
import { initialTooltipSettings } from "../../utils";

interface Props {
  title: string;
  bounds: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  arrowDirection?: string | null;
  setTooltipSettings: Dispatch<SetStateAction<TooltipSettings>>;
}

export const GlideDataGridHeader: React.FC<Props> = (props) => {
  const { title, bounds, setTooltipSettings, arrowDirection } = props;

  const width = bounds.width - 21;

  const ref = useRef<HTMLDivElement>(null);
  const isOverflow = (ref?.current?.scrollWidth || 0) >= width;

  useEffect(() => {
    if (isOverflow) {
      setTooltipSettings({
        value: title,
        type: "header",
        bounds,
      });
    } else {
      setTooltipSettings(initialTooltipSettings);
    }
  }, [isOverflow]);

  return (
    <div className="glide-data-grid-header">
      <div
        className="glide-data-grid-header__title"
        style={{ maxWidth: width }}
        ref={ref}
      >
        {arrowDirection && (
          <ArrowIcon
            className={classNames("glide-data-grid-header__arrow", {
              "glide-data-grid-header__arrow-desc":
                arrowDirection.toLocaleUpperCase() === "DESC",
            })}
          />
        )}
        {title}
      </div>
    </div>
  );
};
