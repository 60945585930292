import React from "react";

import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { capitalizeFirstLetter } from "src/utils/methods";

function SocialProviderSelect(props) {
  const { socialProfiles, setSelectedSocialProvider, selectedSocialProvider } =
    props;

  const mapSocialProfilesToOptions = () => {
    return socialProfiles.map((el, index) => ({
      value: el.socialProvider,
      label: (
        <IDHFormattedMessage
          id={`front_${el.socialProvider}`}
          defaultMessage={capitalizeFirstLetter(el.socialProvider)}
        />
      ),
      index,
    }));
  };

  const onSocialProviderSelectChange = (newValue) => {
    setSelectedSocialProvider(newValue);
  };

  return (
    <CustomSelect
      value={selectedSocialProvider}
      onChange={onSocialProviderSelectChange}
      options={mapSocialProfilesToOptions()}
    />
  );
}

export default SocialProviderSelect;
