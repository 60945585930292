import React, { useState } from "react";
import "./ExportDropdown.scss";

import { ReactComponent as DownloadIcon } from "src/images/download-rounded.svg";

import { ReactComponent as PdfIcon } from "src/images/pdf.svg";
import { ReactComponent as PptxIcon } from "src/images/pptx.svg";

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import { useDispatch } from "react-redux";
import { exportList } from "../../../../audiences-manager/audiencesManagerActions";
import AfterExportModal from "../../../AfterExportModal/AfterExportModal";
import METHODS from "../../../../helpers/methods";

export function ExportDropdown({ hashUserId }) {
  const dispatch = useDispatch();
  const [showAfterExportModal, setShowAfterExportModal] = useState(false);

  const triggerExport = (type) => {
    dispatch(exportList(type, [hashUserId])).then((data) => {
      if (data?.payload?.status === 200) {
        setShowAfterExportModal(true);
      } else if (data.error) {
        METHODS.responseErrorHandler(data);
      }
    });
  };

  return (
    <>
      <Dropdown>
        {(isOpen, setIsOpen) => (
          <>
            <div onClick={() => setIsOpen(true)}>
              <DownloadIcon className="profile-data-pane__export-dropdown__toggle" />
            </div>

            <DropdownMenu
              isOpen={isOpen}
              className="profile-data-pane__export-dropdown__menu"
            >
              <DropdownMenuItem onClick={() => triggerExport("pdf")}>
                <PdfIcon />
                .PDF
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => triggerExport("pptx")}>
                <PptxIcon />
                .PPTX
              </DropdownMenuItem>
            </DropdownMenu>
          </>
        )}
      </Dropdown>
      {showAfterExportModal && (
        <AfterExportModal onClose={() => setShowAfterExportModal(false)} />
      )}
    </>
  );
}
