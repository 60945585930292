import { actionStrings, getFieldIdFromDueFilter } from "./tableSortingUtils";

export const synchronizeLocalFilters = (
  data,
  projectId,
  taskType,
  setSelectedFilters,
  setLocalSyncToggle,
  dispatch,
) => {
  const settingsStr = localStorage.getItem("ws-project-settings");
  const foundSettings = JSON.parse(settingsStr || "[]");
  if (foundSettings && foundSettings.length > 0) {
    const foundProject = foundSettings.find(
      (project) => project?.projectId === projectId,
    );

    if (!foundProject) return;
    const selectedFilters = foundProject[taskType]?.selectedFilters || [];
    const newSelectedFilters = [];

    selectedFilters.forEach((selectedFilter) => {
      const matchedSingleSelects = data
        .map((project) =>
          project.metadata.find((metaField) => {
            if (
              typeof metaField.value === "string" ||
              Array.isArray(metaField.value)
            ) {
              return metaField.value?.includes(selectedFilter);
            }
            return false;
          }),
        )
        .filter((project) => project !== undefined);

      if (matchedSingleSelects[0]) {
        newSelectedFilters.push(selectedFilter);
      } else if (selectedFilter.substring(0, 3) === "due") {
        const fieldId = getFieldIdFromDueFilter(selectedFilter);
        const matchedDateFields = data
          .map((project) =>
            project.metadata.find((metaField) => metaField.uuid === fieldId),
          )
          .filter((project) => project !== undefined);
        if (matchedDateFields[0]) {
          newSelectedFilters.push(selectedFilter);
        }
      } else if (actionStrings.includes(selectedFilter))
        newSelectedFilters.push(selectedFilter);
    });
    dispatch(setSelectedFilters({ selections: newSelectedFilters }));
    dispatch(setLocalSyncToggle(false));
  }
};
