import React, { useMemo, useState } from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "src/app/components/Button/Button";

import { ReactComponent as AudienceOverlapIcon } from "src/images/audience-overlap.svg";
import { ReactComponent as ThunderIcon } from "src/images/thunder.svg";
import { ReactComponent as CloseIcon } from "src/images/close.svg";
import { ReactComponent as ShieldIcon } from "src/images/shield.svg";
import {
  ReactComponent as PlusBlueIcon,
  ReactComponent as PlusIcon,
} from "src/images/plus-alt.svg";
import { ReactComponent as TrashPinkIcon } from "src/images/trash-can.svg";
import { ReactComponent as InfoRedIcon } from "src/images/info-red.svg";
import { ReactComponent as RocketIcon } from "src/images/rocket-grey.svg";
import { ReactComponent as CreatorIcon } from "src/images/creator-discovery.svg";
import { ReactComponent as ListIcon } from "src/images/list.svg";
import { uuidv7 as uuid } from "uuidv7";
import AddCreatorsCDTWorkspaceProjectModal from "src/app/modals/AddCreatorsCDTWorkspaceProjectModal/AddCreatorsCDTWorkspaceProjectModal";
import { useDispatch, useSelector } from "react-redux";
import { generateRankString } from "src/utils/rankStrings";
import { Portal } from "react-overlays";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import { createGlobalTasks } from "src/redux/creator-database/creatorDatabaseActions";
import { showToast } from "src/app/methods/showToast";
import { navigateToCreateNewProject } from "src/app/SettingsModal/methods";
import { setCreateNewProjectData } from "src/redux/main/mainActions";
import { useHistory } from "react-router";
import { resultsHeaderRightRef } from "./AudiencesResultsContainer";
import { ButtonCounter } from "../../components/ButtonCounter/ButtonCounter";
import CONSTS from "../../helpers/consts";
import CrisisDetectionModal from "../CrisisDetectionModal/CrisisDetectionModal";
import ExportTypes from "../../components/ExportTypes/ExportTypes";
import BrandSafety from "../BrandSafety/BrandSafety";

const generateSocialProfiles = (socialProviders) =>
  Object.keys(socialProviders).map((key) => {
    const { socialProvider, socialUsername, socialProfileUrl } =
      socialProviders[key];
    return {
      socialProvider,
      socialUsername,
      socialProfileUrl,
    };
  });

export const generateSelectedInfluencersWithSocialProfiles = (
  selectedInfluencersUrls,
  searchAudienceResult,
) =>
  selectedInfluencersUrls.map((url) => {
    const foundInfluencerData = searchAudienceResult.find(
      (result) => result.socialProfileUrl || result.profileUrl === url,
    );
    if (foundInfluencerData) {
      return {
        name:
          foundInfluencerData.socialUsername || foundInfluencerData.username,
        socialProfiles: generateSocialProfiles(
          foundInfluencerData.socialProviders,
        ),
      };
    }
    return url;
  });

export const selectedCreatorsPayload = (
  selectedInfluencersUrls,
  searchAudienceResult,
) => {
  let previousRank = "a";

  const selectedInfluencersWithSocialProfiles =
    generateSelectedInfluencersWithSocialProfiles(
      selectedInfluencersUrls,
      searchAudienceResult,
    );

  return selectedInfluencersWithSocialProfiles.map((influencer) => {
    const newRank = generateRankString(previousRank, "");
    previousRank = newRank;

    if (typeof influencer === "string") {
      return {
        uuid: uuid(),
        name: influencer,
        rank: newRank,
      };
    }
    return {
      uuid: uuid(),
      ...influencer,
      rank: newRank,
    };
  });
};

function CreatorDiscoveryToolActionBar(props) {
  const {
    activeTab,
    selectedInfluencers,
    listId,
    influencersInList,
    influencersInListInfo,
    searchAudienceResult,
    listSocialProvider,
    exportList,
    clearAllSelected,
    showBrandSafety,
    addToAudience,
    createAudience,
    deleteAudience,
    toggleBrandSafetyVisibility,
    toggleCrisisDetectionModalVisibility,
    showCrisisDetectionModal,
    showAudienceOverlapPane,
    hasAccessToInfluencerInsightsAudienceOverlap,
    hasAccessToInfluencerInsightsBrandSafety,
    selectedInfluencersUrls,
    setShowAddCreatorsModal,
    refreshAudience,
  } = props;

  const {
    mainReducer: { activeWorkspaceUuid },
    projectReducer: { projectsList },
  } = useSelector((state) => state);
  const history = useHistory();

  const isSelectedAtLeastOneInfluencer = selectedInfluencers?.length > 0;
  const areInListAtLeastTwoInfluencers = influencersInList?.length > 1;
  const addToWorkspaceProjectDisabled =
    selectedInfluencersUrls?.length < 1 ||
    selectedInfluencersUrls?.length > 100;
  const addToAudienceDisabled =
    !selectedInfluencers.length ||
    influencersInList?.length + selectedInfluencers.length >
      CONSTS.audienceListUsersLimit;
  const audienceOverlapDisabled = !(
    areInListAtLeastTwoInfluencers && activeTab === "selected"
  );
  const selectedNonGlobalInfluencers = useMemo(() => {
    if (!selectedInfluencers) return [];

    const list =
      activeTab === "selected" ? influencersInList : searchAudienceResult;

    const influList = [];
    selectedInfluencers.forEach((influ) => {
      const influData = list?.find((item) => item.userId === influ);
      if (influData && !influData.wsGlobalTaskUuid) {
        const { socialProviders } = influData;
        const globalTaskData = {
          uuid: uuid(),
          name: influData.socialUsername,
          socialProfiles: generateSocialProfiles(socialProviders),
        };
        influList.push(globalTaskData);
      }
    });
    return influList;
  }, [activeTab, selectedInfluencers, influencersInList, searchAudienceResult]);

  const [
    showModalAddCreatorsToWorkspaceProject,
    setShowModalAddCreatorsToWorkspaceProject,
  ] = useState(false);

  const dispatch = useDispatch();

  const getAudienceOverlapTooltipMsg = () => {
    if (activeTab !== "selected")
      return {
        id: "front_available_only_on_the_selected_creators_list_tooltip",
        defaultMessage: "Available only on the selected Creators list.",
      };
    return {
      id: "front_select_at_least_two_creators_from_the_list_tooltip",
      defaultMessage: "Select at least two creators from the list.",
    };
  };

  const dispatchDataAndNavigateToCreateNewProject = () => {
    const records =
      activeTab === "selected" ? influencersInList : searchAudienceResult;

    dispatch(
      setCreateNewProjectData({
        selectedCreatorsPayload: selectedCreatorsPayload(
          selectedInfluencersUrls,
          records,
        ),
      }),
    );
    navigateToCreateNewProject(history, activeWorkspaceUuid);
  };

  const checkIfAudienceOverPageLimit = () => {
    if (
      activeTab === "selected" &&
      influencersInListInfo?.totalResults > CONSTS.audienceListPageSize
    ) {
      return true;
    }

    return false;
  };

  const checkIfSelectedAudienceOverPageLimit = () => {
    if (
      activeTab === "selected" &&
      selectedInfluencers?.length > CONSTS.audienceListPageSize
    ) {
      return true;
    }

    return false;
  };

  const addCreatorsToDatabase = () => {
    dispatch(
      createGlobalTasks(
        activeWorkspaceUuid,
        "creator",
        selectedNonGlobalInfluencers,
      ),
    );
    showToast(
      "success",
      <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
      <IDHFormattedMessage
        id="ws_creators_added_to_database"
        defaultMessage="Creators successfully added to Database"
      />,
    );
    refreshAudience();
  };

  const handleAddToAudience = () => {
    addToAudience();
    showToast(
      "success",
      <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
      <IDHFormattedMessage
        id="ws_creators_added_to_list"
        defaultMessage="Creators successfully added to list"
      />,
    );
  };

  return (
    <>
      <div className="audience-results-page__actions-wrap">
        <div className="audience-results-page__actions">
          <Portal container={resultsHeaderRightRef}>
            <div className="audience-results-page__actions-button-wrapper">
              {listSocialProvider === CONSTS.socialProvider.instagram &&
                hasAccessToInfluencerInsightsAudienceOverlap && (
                  <MaterialTooltip
                    center
                    content={
                      checkIfAudienceOverPageLimit() ? (
                        <IDHFormattedMessage
                          id="front_available_disable_audience_overlap_over_limit_tooltip"
                          defaultMessage="Available only under 100 selected Creators list."
                        />
                      ) : (
                        <IDHFormattedMessage
                          id={getAudienceOverlapTooltipMsg().id}
                          defaultMessage={
                            getAudienceOverlapTooltipMsg().defaultMessage
                          }
                        />
                      )
                    }
                    contentHidden={
                      !(
                        audienceOverlapDisabled ||
                        checkIfAudienceOverPageLimit()
                      )
                    }
                  >
                    <Button
                      size="thin"
                      variant="white-to-light"
                      onClick={showAudienceOverlapPane}
                      className="audience-results-page__overlap-btn"
                      disabled={
                        audienceOverlapDisabled ||
                        checkIfAudienceOverPageLimit()
                      }
                    >
                      <AudienceOverlapIcon className="icon-left" />
                      <IDHFormattedMessage
                        id="front_audience_overlap"
                        defaultMessage="Audience Overlap"
                      />
                    </Button>
                  </MaterialTooltip>
                )}

              {showBrandSafety && (
                <BrandSafety
                  listId={listId}
                  toggleBrandSafetyVisibility={toggleBrandSafetyVisibility}
                  toggleCrisisDetectionModalVisibility={
                    toggleCrisisDetectionModalVisibility
                  }
                />
              )}

              {listSocialProvider !== CONSTS.socialProvider.youtube &&
                hasAccessToInfluencerInsightsBrandSafety &&
                listSocialProvider !== CONSTS.socialProvider.snapchat && (
                  <MaterialTooltip
                    center
                    content={
                      checkIfAudienceOverPageLimit() ? (
                        <IDHFormattedMessage
                          id="front_available_disable_brand_safety_over_limit_tooltip"
                          defaultMessage="Available only under 100 selected Creators list."
                        />
                      ) : (
                        <IDHFormattedMessage
                          id="front_available_only_on_the_selected_creators_list_tooltip"
                          defaultMessage="Available only on the selected Creators list."
                        />
                      )
                    }
                    contentHidden={
                      activeTab !== "selected" ||
                      !checkIfAudienceOverPageLimit()
                    }
                  >
                    <Button
                      onClick={toggleBrandSafetyVisibility}
                      size="thin"
                      variant="white-to-light"
                      className="brand-safety__button"
                      disabled={
                        activeTab !== "selected" ||
                        checkIfAudienceOverPageLimit()
                      }
                    >
                      <ShieldIcon className="icon-left" />
                      <IDHFormattedMessage
                        id="front_brand_safety_btn"
                        defaultMessage="Brand safety"
                      />
                    </Button>
                  </MaterialTooltip>
                )}

              {isSelectedAtLeastOneInfluencer && (
                <Button
                  size="thin"
                  variant="white-to-light"
                  className="audience-results-page__actions-clear"
                  onClick={clearAllSelected}
                >
                  <CloseIcon className="icon-left" />
                  <IDHFormattedMessage
                    id="front_clear_selected"
                    defaultMessage="Clear selected"
                  />
                </Button>
              )}

              {activeTab === "results" ? (
                <>
                  {listId ? (
                    <div className="audience-results-page__actions-button">
                      {influencersInList?.length + selectedInfluencers?.length >
                        CONSTS.audienceListUsersLimit && (
                        <div className="warning-wrapper">
                          <MaterialTooltip
                            center
                            content={
                              <IDHFormattedMessage
                                id="front_max_100_infu_on_list_warning"
                                defaultMessage="Audience can contain up to 100 Influencers. Please reduce your current selection."
                              />
                            }
                          >
                            <InfoRedIcon className="influ-count-warning" />
                          </MaterialTooltip>
                        </div>
                      )}
                    </div>
                  ) : (
                    <MaterialTooltip
                      center
                      content={
                        <IDHFormattedMessage
                          id="front_select_at_least_one_creator_from_the_list_tooltip"
                          defaultMessage="Select at least one Creator from the list"
                        />
                      }
                      contentHidden={isSelectedAtLeastOneInfluencer}
                    >
                      <Button
                        size="thin"
                        variant="blue"
                        onClick={createAudience}
                        disabled={!isSelectedAtLeastOneInfluencer}
                      >
                        {!selectedInfluencers.length ? (
                          <ThunderIcon className="icon-left" />
                        ) : (
                          <ThunderIcon className="icon-left" />
                        )}
                        <IDHFormattedMessage
                          id="front_create_audience"
                          defaultMessage="Create Audience"
                        />
                        {isSelectedAtLeastOneInfluencer && (
                          <ButtonCounter count={selectedInfluencers.length} />
                        )}
                      </Button>
                    </MaterialTooltip>
                  )}
                </>
              ) : (
                activeTab === "selected" &&
                isSelectedAtLeastOneInfluencer && (
                  <Button
                    size="thin"
                    variant="white-to-red"
                    onClick={deleteAudience}
                    disabled={!selectedInfluencers.length}
                  >
                    <TrashPinkIcon className="icon-left" />
                    <IDHFormattedMessage
                      id="front_delete_from_the_list"
                      defaultMessage="Delete from the list"
                    />
                    {isSelectedAtLeastOneInfluencer && (
                      <ButtonCounter
                        count={selectedInfluencers.length}
                        backgroundColor="#FFD3D3"
                        color="#fd397a"
                      />
                    )}
                  </Button>
                )
              )}

              <Dropdown toRight>
                {(isOpen, setIsOpen) => (
                  <>
                    <Button
                      size="medium"
                      variant="blue"
                      onClick={() => setIsOpen(true)}
                    >
                      <PlusBlueIcon className="icon-left" />

                      <IDHFormattedMessage
                        id="front_add_to"
                        defaultMessage="Add to..."
                      />
                    </Button>
                    <DropdownMenu isOpen={isOpen}>
                      <MaterialTooltip
                        center
                        content={
                          <IDHFormattedMessage
                            id="front_select_non_global_creator_to_add_to_database"
                            defaultMessage="Select at least one local Creator to add to the Creator Database"
                          />
                        }
                        contentHidden={selectedNonGlobalInfluencers.length}
                      >
                        <DropdownMenuItem
                          onClick={addCreatorsToDatabase}
                          disabled={!selectedNonGlobalInfluencers.length}
                        >
                          <CreatorIcon />
                          <IDHFormattedMessage
                            id="front_creator_database"
                            defaultMessage="Creator Database"
                          />
                        </DropdownMenuItem>
                      </MaterialTooltip>

                      <MaterialTooltip
                        center
                        content={
                          selectedInfluencersUrls?.length > 100 ? (
                            <IDHFormattedMessage
                              id="front_you_can_add_only_100_influencers"
                              defaultMessage="You can add only 100 influencers to campaign at once"
                            />
                          ) : (
                            <IDHFormattedMessage
                              id="front_select_creator_to_add_to_workspace_campaign"
                              defaultMessage="Select at least one Creator to add to the Campaign"
                            />
                          )
                        }
                        contentHidden={!addToWorkspaceProjectDisabled}
                      >
                        <DropdownMenuItem
                          onClick={() =>
                            setShowModalAddCreatorsToWorkspaceProject(true)
                          }
                          disabled={addToWorkspaceProjectDisabled}
                        >
                          <RocketIcon />
                          <IDHFormattedMessage
                            id="front_campaign"
                            defaultMessage="Campaign"
                          />
                        </DropdownMenuItem>
                      </MaterialTooltip>

                      {activeTab === "results" && listId && (
                        <MaterialTooltip
                          center
                          content={
                            <IDHFormattedMessage
                              id="front_select_at_least_one_creator_from_the_list_tooltip"
                              defaultMessage="Select at least one Creator from the list"
                            />
                          }
                          contentHidden={isSelectedAtLeastOneInfluencer}
                        >
                          <DropdownMenuItem
                            onClick={handleAddToAudience}
                            disabled={addToAudienceDisabled}
                          >
                            <ListIcon />
                            <IDHFormattedMessage
                              id="front_creator_list"
                              defaultMessage="Creator list"
                            />
                          </DropdownMenuItem>
                        </MaterialTooltip>
                      )}
                    </DropdownMenu>
                  </>
                )}
              </Dropdown>
            </div>
            <div className="audience-results-page__action-buttons">
              {activeTab === "selected" && (
                <Button
                  size="thin"
                  variant="white-to-light"
                  onClick={() => setShowAddCreatorsModal(true)}
                >
                  <PlusIcon className="icon-left" />
                  <IDHFormattedMessage
                    id="front_add_creator"
                    defaultMessage="Add creator"
                  />
                </Button>
              )}

              {listSocialProvider !== "snapchat" && (
                <ExportTypes
                  paginationLimit={checkIfSelectedAudienceOverPageLimit()}
                  disabled={!selectedInfluencers.length}
                  selectedInfluencers={selectedInfluencers}
                  onExportClick={exportList}
                  showXls={activeTab === "selected"}
                />
              )}
            </div>
          </Portal>
        </div>

        {showCrisisDetectionModal && (
          <CrisisDetectionModal
            onConfirmClick={toggleCrisisDetectionModalVisibility}
            onClose={toggleCrisisDetectionModalVisibility}
          />
        )}
      </div>

      {showModalAddCreatorsToWorkspaceProject && (
        <AddCreatorsCDTWorkspaceProjectModal
          onClose={() => setShowModalAddCreatorsToWorkspaceProject(false)}
          projectsList={projectsList}
          activeWorkspaceUuid={activeWorkspaceUuid}
          selectedCreators={generateSelectedInfluencersWithSocialProfiles(
            selectedInfluencersUrls,
            searchAudienceResult,
          )}
          openCreateNewProjectModal={dispatchDataAndNavigateToCreateNewProject}
        />
      )}
    </>
  );
}

export default CreatorDiscoveryToolActionBar;
