import React from "react";
import "./WorkspaceRolesInfoModal.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalTitle } from "../../../components/Modal/Modal";

function WorkspaceRolesInfoModal({ onClose }) {
  const closeModal = () => {
    onClose();
  };

  return (
    <Modal
      className="workspace-workspace-roles-info-modal"
      onClose={closeModal}
      displayCancelButton
    >
      <ModalTitle backBtnFunc={closeModal}>
        <IDHFormattedMessage id="ws_roles" defaultMessage="Roles" />
      </ModalTitle>
      <div className="workspace-roles-info-modal__permissions">
        <div className="workspace-roles-info-modal__column">
          <header className="workspace-roles-info-modal__role workspace-roles-info-modal__role--admin">
            <IDHFormattedMessage
              id="ws_supervisor"
              defaultMessage="Supervisor"
            />
          </header>

          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_supervisor_permission_1"
              defaultMessage="Can invite members from any email domain to join the organization."
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_supervisor_permission_2"
              defaultMessage="Can assign roles to workspace members"
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_supervisor_permission_3"
              defaultMessage="Can create and modify Teams"
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_supervisor_permission_4"
              defaultMessage="Can manage user permissions (e.g. Viewer, Editor)"
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_supervisor_permission_5"
              defaultMessage="Can see all activity logs"
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_supervisor_permission_6"
              defaultMessage="Can use Creator Discovery tool"
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_supervisor_permission_7"
              defaultMessage="Can use Creator Database"
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_supervisor_permission_8"
              defaultMessage="Can use Dictionaries"
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_supervisor_permission_9"
              defaultMessage="Can see, create and manage all campaigns in organisation"
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_supervisor_permission_10"
              defaultMessage="Can see coupons store configuration details (to pass to clients)"
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_supervisor_permission_11"
              defaultMessage="Can see and manage all Creator Lists in organisation"
            />
          </div>
        </div>
        <div className="workspace-roles-info-modal__column">
          <div className="workspace-roles-info-modal__role workspace-roles-info-modal__role--member">
            <IDHFormattedMessage id="ws_member" defaultMessage="Member" />
          </div>

          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_member_permission_1"
              defaultMessage="Can invite new members to organisation within the primary email domain only"
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_member_permission_2"
              defaultMessage="Can see activity logs for accessible system elements/objects."
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_member_permission_3"
              defaultMessage="Can use Creator Discovery tool"
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_member_permission_4"
              defaultMessage="Can use Creator Database"
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_member_permission_5"
              defaultMessage="Can use Dictionaries"
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_member_permission_6"
              defaultMessage="Can create and manage campaigns"
            />
          </div>
          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_member_permission_7"
              defaultMessage="Can see coupons store configuration details (to pass to clients)"
            />
          </div>
        </div>
        <div className="workspace-roles-info-modal__column">
          <div className="workspace-roles-info-modal__role workspace-roles-info-modal__role--client">
            <IDHFormattedMessage
              id="ws_guest_client"
              defaultMessage="Guest (Client)"
            />
          </div>

          <div className="workspace-roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_workspace_roles_modal_guest_client_permission_1"
              defaultMessage="Can see only shared campaign elements"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export { WorkspaceRolesInfoModal };
