import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as GrowthIcon } from "../../../../../images/profile-data/growth.svg";
import Chart from "../../../../components/Chart/Chart";
import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";

export function InfluencerGrowth(props) {
  const { followers, followersStatisticsData, height } = props;

  const onShowcase = height;

  return (
    <ProfileBox block>
      <PBHeader
        icon={<GrowthIcon />}
        count={followers}
        title={
          <IDHFormattedMessage
            id="ws_creator_growth"
            defaultMessage="Creator growth"
          />
        }
        onShowcase={onShowcase}
      />
      <Chart
        title=""
        color="#FFB822"
        icon=""
        data={followersStatisticsData}
        height={150}
      />
    </ProfileBox>
  );
}
