import React from "react";
import Skeleton, { SkeletonText } from "src/app/components/Skeleton/Skeleton";

export const skeletonOptions = [
  {
    value: "skeleton-1",
    label: (
      <span className="image-select-option">
        <Skeleton height={20} width={20} borderRadius={4} marginRight={6} />
        <SkeletonText height={20} width={160} />
      </span>
    ),
  },
  {
    value: "skeleton-2",
    label: (
      <span className="image-select-option">
        <Skeleton height={20} width={20} borderRadius={4} marginRight={6} />
        <SkeletonText height={20} width={160} />
      </span>
    ),
  },
  {
    value: "skeleton-3",
    label: (
      <span className="image-select-option">
        <Skeleton height={20} width={20} borderRadius={4} marginRight={6} />
        <SkeletonText height={20} width={160} />
      </span>
    ),
  },
  {
    value: "skeleton-4",
    label: (
      <span className="image-select-option">
        <Skeleton height={20} width={20} borderRadius={4} marginRight={6} />
        <SkeletonText height={20} width={160} />
      </span>
    ),
  },
  {
    value: "skeleton-5",
    label: (
      <span className="image-select-option">
        <Skeleton height={20} width={20} borderRadius={4} marginRight={6} />
        <SkeletonText height={20} width={160} />
      </span>
    ),
  },
];
