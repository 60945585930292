import React from "react";
import "./StackedLabels.scss";

interface Props {
  values: string[];
}

export const StackedLabels: React.FC<Props> = (props) => {
  const { values } = props;

  return (
    <div className="stacked-labels">
      {values.map((value) => (
        <div className="stacked-labels__item">{value}</div>
      ))}
    </div>
  );
};
