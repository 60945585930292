// z-index values are also stored in variables.scss
const zIndex = {
  under: -1,
  base: 0,
  above: 10,
  navigation: 1010,
  popover: 1020,
  dropdown: 1030,
  tooltip: 1040,
  max: 2147483647,
};

export default zIndex;
