import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import classNames from "classnames";
import moment from "moment";

import { momentDateFormat } from "src/app/components/CustomDatePicker/utils";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useDispatch } from "react-redux";
import { ReactComponent as RefreshIcon } from "../../../../../../images/refresh.svg";
import { showToast } from "../../../../../methods/showToast";
import { API_URLS } from "../../../../../../utils/API_URLS";
import "./StatisticsSync.scss";
import { updatePublicationSynchronizationStatusIcon } from "../../../../../../redux";

function StatisticsSync(props) {
  const { synchronizationDate, taskDetailsLoading } = props;
  const { taskId } = useParams();
  const dispatch = useDispatch();

  const refreshPublicationStatistics = async () => {
    dispatch(updatePublicationSynchronizationStatusIcon(true));
    try {
      await axios.post(API_URLS.postRefreshPublicationStatistics, {
        taskUuid: taskId,
      });
    } catch (err) {
      console.error(err);
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_cannot_refresh_publication_statistics"
          defaultMessage="Cannot refresh publication statistics"
        />,
      );
    }
  };

  return (
    <div className="task-statistics-synchronization">
      {synchronizationDate && (
        <>
          <div className="task-statistics-synchronization__sync-date-text">
            <IDHFormattedMessage
              id="ws_synchronization_date"
              defaultMessage="synchronization date"
            />
          </div>
          <div className="task-statistics-synchronization__date">
            {moment(synchronizationDate.date, momentDateFormat).format(
              "DD.MM.YYYY",
            )}
          </div>
        </>
      )}
      <div
        className={classNames(
          "task-statistics-synchronization__refresh-wrapper",
          {
            "task-statistics-synchronization__refresh-wrapper--disabled":
              taskDetailsLoading,
          },
        )}
        onClick={refreshPublicationStatistics}
      >
        <RefreshIcon
          className={classNames(
            "task-statistics-synchronization__refresh-icon",
            {
              "task-statistics-synchronization__refresh-icon--rotating":
                taskDetailsLoading,
            },
          )}
        />
        <IDHFormattedMessage id="ws_refresh" defaultMessage="Refresh" />
      </div>
    </div>
  );
}

export { StatisticsSync };
