import moment from "moment";
import { FieldKey, FieldType } from "../../../../../../types";
import { isNextWeek, isThisWeek, isToday } from "./tableSortingUtils";

export const filterTableData = (rows, selectedFilters, userId) => {
  return rows.filter((row) =>
    ifRowMatchesFilters(row.original, selectedFilters, userId),
  );
};

export const ifRowMatchesFilters = (row, selectedFilters, userId) => {
  if (
    !selectedFilters.selections ||
    selectedFilters.selections.length === 0 ||
    row.id === "empty"
  )
    return true;

  for (const cell of row.metadata) {
    if (
      cell.type === FieldType.SingleSelect &&
      selectedFilters?.selections?.includes(cell.value)
    )
      return true;

    switch (cell.type) {
      case FieldType.SingleSelect:
      case FieldType.DictionaryElement:
        if (
          selectedFilters?.selections?.includes(cell.value) ||
          (selectedFilters?.selections.includes(`${cell.uuid}_null`) &&
            cell.value === null)
        )
          return true;
        break;
      case FieldType.MultiSelect:
      case FieldType.AggregatedSelect:
        if (
          selectedFilters?.selections?.some((value) =>
            cell.value?.includes(value),
          ) ||
          (selectedFilters?.selections.includes(`${cell.uuid}_null`) &&
            cell.value === null)
        )
          return true;
        break;
      case FieldType.Date:
        if (cell.value?.date) {
          const date = moment(cell.value?.date);
          if (isToday(date, selectedFilters, cell.uuid)) return true;
          if (isThisWeek(date, selectedFilters, cell.uuid)) return true;
          if (isNextWeek(date, selectedFilters, cell.uuid)) return true;
        }
      case FieldType.BoolVal:
        if (selectedFilters?.selections?.includes(String(cell.value)))
          return true;
        break;

      case FieldType.Rating:
        if (selectedFilters?.selections?.includes(String(cell.value)))
          return true;
        break;
    }
    switch (cell.key) {
      case FieldKey.Completed:
        if (
          cell.value === true &&
          selectedFilters?.selections?.includes("completed")
        )
          return true;
        if (
          cell.value === false &&
          selectedFilters?.selections?.includes("incompleted")
        )
          return true;
        break;
      case FieldKey.Assignee:
        if (
          cell.value === userId &&
          selectedFilters?.selections?.includes("my_actions")
        )
          return true;
        break;
    }
  }
  return false;
};
