import React, { useEffect, useState } from "react";
import "../Preview.scss";

import { useDispatch } from "react-redux";
import { getImageSource } from "src/utils/methods";
import AddSocialProfilesModal, {
  SocialProfile,
} from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import { CoverImage } from "src/redux/project/types";
import {
  getGlobalTaskDetails,
  setGlobalTaskMetaValue,
} from "src/redux/creator-database/creatorDatabaseActions";
import { getDictionaryDetails, setDictionaryElementMetaValue } from "src/redux";
import { useParams } from "react-router";
import { PreviewContext } from "../utils";
import SocialProfileIcons from "../../IconsWithStatus/SocialProfileIcons/SocialProfileIcons";
import { ReactComponent as EditIcon } from "../../../../images/edit.svg";
import { Img } from "../../Img/Img";
import { ScalingInput } from "../../ScalingInput/ScalingInput";

interface Props {
  context: PreviewContext;
  previewObjectUuid: string;
  title: string;
  titleMetaFieldUuid?: string;
  cover?: {
    data: CoverImage;
    placeholder: string;
  };
  socialProfiles?: SocialProfile[];
}

export default function PreviewInfo(props: Props) {
  const {
    context,
    previewObjectUuid,
    title,
    titleMetaFieldUuid,
    cover,
    socialProfiles,
  } = props;

  const [localTitle, setLocalTitle] = useState(title);
  const [showSocialProfilesModal, setShowSocialProfilesModal] = useState(false);

  const { dictionaryUuid } = useParams<{ dictionaryUuid: string }>();

  const dispatch = useDispatch();

  useEffect(() => {
    setLocalTitle(title);
  }, [title]);

  const handleBlur = () => {
    if (title === localTitle || !titleMetaFieldUuid) return;

    switch (context) {
      case PreviewContext.CreatorDatabase:
        dispatch(
          setGlobalTaskMetaValue(
            previewObjectUuid,
            titleMetaFieldUuid,
            localTitle,
          ),
        );
        dispatch(getGlobalTaskDetails(previewObjectUuid));
        break;
      case PreviewContext.Dictionary:
        dispatch(
          setDictionaryElementMetaValue(
            [previewObjectUuid],
            titleMetaFieldUuid,
            localTitle,
          ),
        );
        dispatch(getDictionaryDetails(dictionaryUuid, previewObjectUuid));
        break;
      default:
        break;
    }
  };

  const renderCover = () => {
    if (context === PreviewContext.Dictionary) return null;

    if (cover?.data) {
      return (
        <span className="preview__info-cover-wrapper">
          <Img
            className="preview__info-cover"
            src={getImageSource(cover.data, "small")}
            fallbackImageSrc={cover.placeholder}
          />
        </span>
      );
    }

    return null;
  };

  const renderTitle = () => {
    if (!titleMetaFieldUuid)
      return <span className="preview__info-title">{title}</span>;

    return (
      <ScalingInput
        className="preview__info-title"
        value={localTitle}
        onChange={(e) => setLocalTitle(e.target.value)}
        onBlur={handleBlur}
      />
    );
  };

  return (
    <div className="preview__info">
      {renderCover()}
      <div className="preview__info-data">
        {renderTitle()}
        {socialProfiles && (
          <div className="preview__info-social-icons">
            <SocialProfileIcons data={socialProfiles} size="big" />
            <EditIcon
              className="person-info__edit-icon"
              onClick={() => setShowSocialProfilesModal(true)}
            />
          </div>
        )}
      </div>

      {showSocialProfilesModal && (
        <AddSocialProfilesModal
          objectType="global-task"
          onClose={() => setShowSocialProfilesModal(false)}
          name={title}
          taskId={previewObjectUuid}
          socialProfiles={socialProfiles}
        />
      )}
    </div>
  );
}
