import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { TaskType } from "src/types";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { IMember } from "src/app/project/ProjectTypes";
import { convertToLocalTimezone } from "src/app/methods/convertToLocalTimezone";
import { SettingsContext } from "src/app/SettingsModal/enums";
import { tableDataType } from "src/app/components/Table/Table";

import "./Creators.scss";
import {
  Performance,
  PerformanceContext,
  PerformanceElement,
} from "src/app/components/GlobalTaskDetails/tabs/Performance/Performance";
import {
  IPerformanceFilters,
  PerformanceFilters,
} from "src/app/components/GlobalTaskDetails/tabs/Performance/components/PerformanceFilters/PerformanceFilters";
import { PerformanceFiltersButton } from "src/app/components/GlobalTaskDetails/tabs/Performance/components/PerformanceFilters/components/PerformanceFiltersButton/PerformanceFiltersButton";
import { RootState } from "src/redux/reducers";
import { ActivityTab } from "../tabs/ActivityTab/ActivityTab";
import { GeneralTab } from "../tabs/GeneralTab/GeneralTab";
import { TaskSocialData } from "../tabs/TaskSocialData/TaskSocialData";
import { ParticipationDetails } from "../tabs/ParticipationDetails/ParticipationDetails";
import Tabs from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Tab";

enum CreatorsTabs {
  PartcicipationDetails = "participation-details",
  General = "general",
  SocialData = "social-data",
  Activity = "activity",
  Performance = "performance",
}

interface CreatorsProps {
  taskDetails: any;
  taskType: TaskType;
  taskOwner: IMember | undefined;
  hasAccessToCreatorDatabase: boolean;
}

function Creators({
  taskDetails: {
    socialProfiles,
    relatedContentTasks,
    relatedActionTasks,
    relatedPublicationTasks,
    relatedPaymentTasks,
    permissions,
    title,
    taskId,
    metadata,
    created,
    wsGlobalTaskUuid,
    parentProjectId,
  },
  taskType,
  taskOwner,
  hasAccessToCreatorDatabase,
}: CreatorsProps) {
  const {
    mainReducer: { identity },
  } = useSelector((state: RootState) => state);
  const [activeTab, setActiveTab] = useState(
    CreatorsTabs.PartcicipationDetails,
  );
  const [dateCreated, setDateCreated] = useState<moment.Moment | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [performanceData, setPerformanceData] = useState<PerformanceElement[]>(
    [],
  );
  const [performanceFilters, setPerformanceFilters] =
    useState<IPerformanceFilters>({
      time: {
        from: null,
        to: null,
      },
      campaigns: [parentProjectId],
    });

  useEffect(() => {
    if (created) {
      const dateValue = created.date;
      if (dateValue !== null) {
        setDateCreated(convertToLocalTimezone(dateValue));
      }
    }
  }, [created]);

  return (
    <div className="creators">
      <Tabs>
        <Tab
          tabText={
            <IDHFormattedMessage
              id="ws_participation_details"
              defaultMessage="Participation Details"
            />
          }
          active={activeTab === CreatorsTabs.PartcicipationDetails}
          onClick={() => setActiveTab(CreatorsTabs.PartcicipationDetails)}
          medium
        />
        {hasAccessToCreatorDatabase && wsGlobalTaskUuid && (
          <Tab
            tabText={
              <IDHFormattedMessage id="ws_general" defaultMessage="General" />
            }
            active={activeTab === CreatorsTabs.General}
            onClick={() => setActiveTab(CreatorsTabs.General)}
            medium
          />
        )}
        <Tab
          tabText={
            <IDHFormattedMessage
              id="ws_social_data"
              defaultMessage="Social Data"
            />
          }
          active={activeTab === CreatorsTabs.SocialData}
          onClick={() => setActiveTab(CreatorsTabs.SocialData)}
          medium
        />
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_activity" defaultMessage="Activity" />
          }
          active={activeTab === CreatorsTabs.Activity}
          onClick={() => setActiveTab(CreatorsTabs.Activity)}
          medium
        />

        {wsGlobalTaskUuid &&
          identity?.permissions?.workspace?.includes("creator_database") && (
            <Tab
              tabText={
                <IDHFormattedMessage
                  id="ws_performance"
                  defaultMessage="Performance"
                />
              }
              active={activeTab === CreatorsTabs.Performance}
              onClick={() => setActiveTab(CreatorsTabs.Performance)}
              medium
            />
          )}

        {activeTab === CreatorsTabs.Performance && performanceData.length ? (
          <PerformanceFiltersButton
            openFilters={() => setShowFilters(true)}
            filters={performanceFilters}
          />
        ) : null}
      </Tabs>

      {activeTab === CreatorsTabs.PartcicipationDetails && (
        <ParticipationDetails
          settingsContextData={{
            context: SettingsContext.Task,
            contextTargetUuid: taskId,
          }}
          dataType={tableDataType.Task}
          metadata={metadata}
          taskOwner={taskOwner}
          dateCreated={dateCreated}
        />
      )}

      {activeTab === CreatorsTabs.General && (
        <GeneralTab taskId={wsGlobalTaskUuid} />
      )}

      {activeTab === CreatorsTabs.SocialData && (
        <TaskSocialData
          hasAnySocialProfile={socialProfiles.length > 0}
          socialProfiles={socialProfiles}
          data={{ uuid: taskId, title }}
        />
      )}

      {activeTab === CreatorsTabs.Activity && (
        <ActivityTab
          tasks={{
            relatedContentTasks,
            relatedActionTasks,
            relatedPublicationTasks,
            relatedPaymentTasks,
          }}
          taskType={taskType}
          permissions={permissions}
        />
      )}

      {activeTab === CreatorsTabs.Performance && (
        <Performance
          context={PerformanceContext.Task}
          taskId={wsGlobalTaskUuid}
          data={performanceData}
          setData={setPerformanceData}
          filters={performanceFilters}
          hasInitialFilters
        />
      )}

      <PerformanceFilters
        setFiltersVisible={setShowFilters}
        filtersVisible={showFilters}
        performanceData={performanceData}
        filters={performanceFilters}
        setFilters={setPerformanceFilters}
      />
    </div>
  );
}

export default Creators;
