import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { valueExists } from "src/app/methods/valueExists";

export const sortTableData = (
  rowA,
  rowB,
  id,
  membersList,
  creatorAutocomplete,
  contentAutocomplete,
  publicationAutocomplete,
  dictionaryAutoCompletes,
) => {
  const dir = rowA.index < rowB.index ? -1 : 1;
  const metaA = rowA.original.metadata.find((field) => field.uuid === id);
  const metaB = rowB.original.metadata.find((field) => field.uuid === id);
  if (!valueExists(metaA?.value)) {
    return dir;
  }
  if (!valueExists(metaB?.value)) {
    return -dir;
  }

  if (metaA?.value && metaB.value) {
    switch (metaA?.type) {
      case MetaFieldType.Text:
        return metaB?.value.localeCompare(metaA?.value);

      case MetaFieldType.Date:
        const dateA = new Date(metaA?.value?.date).getTime();
        const dateB = new Date(metaB?.value?.date).getTime();
        return dateB - dateA;
      case MetaFieldType.Member:
        const memberA = membersList.find((member) => member.id === metaA.value);
        const memberB = membersList.find((member) => member.id === metaB.value);
        if (memberA && memberB) {
          return memberA.name.localeCompare(memberB.name);
        }
      case MetaFieldType.BoolVal:
        return metaA.value * metaB.value;
      case MetaFieldType.SingleSelect: {
        const nameA = metaA.data.singleSelectOptions?.find(
          (select) => select.value === metaA.value,
        ).name;
        const nameB = metaB.data.singleSelectOptions?.find(
          (select) => select.value === metaB.value,
        ).name;
        return nameB.localeCompare(nameA);
      }
      case MetaFieldType.MultiSelect:
      case MetaFieldType.AggregatedSelect: {
        const nameA =
          metaA.data.singleSelectOptions?.find(
            (select) => select.value === metaA.value[0],
          )?.name || "";
        const nameB =
          metaB.data.singleSelectOptions?.find(
            (select) => select.value === metaB.value[0],
          )?.name || "";
        return nameB.localeCompare(nameA);
      }
      case MetaFieldType.DictionaryElement: {
        const { wsDictionaryUuid } = metaA?.data || {};
        const list =
          dictionaryAutoCompletes[`dictionaryType_${wsDictionaryUuid}`] || [];

        const nameA =
          list.find((item) => item.uuid === metaA.value)?.title || "";
        const nameB =
          list.find((item) => item.uuid === metaB.value)?.title || "";

        return nameB.localeCompare(nameA);
      }
      case MetaFieldType.Creator:
        let creatorA;
        let creatorB;
        if (creatorAutocomplete) {
          creatorA = creatorAutocomplete.find(
            (creator) => creator.taskId === metaA.value,
          );
          creatorB = creatorAutocomplete.find(
            (creator) => creator.taskId === metaB.value,
          );
        }
        if (creatorA && creatorB) {
          return creatorB.title.localeCompare(creatorA?.title);
        }
      case MetaFieldType.Content:
        let contentA;
        let contentB;
        if (contentAutocomplete) {
          contentA = contentAutocomplete.find(
            (content) => content.taskId === metaA.value,
          );
          contentB = contentAutocomplete.find(
            (content) => content.taskId === metaB.value,
          );
        }
        if (contentA && contentB) {
          return contentB?.title.localeCompare(contentA?.title);
        }
      case MetaFieldType.PublicationRelated:
        let publicationA;
        let publicationB;
        if (Array.isArray(publicationAutocomplete)) {
          publicationA = publicationAutocomplete.find(
            (publication) => publication.taskId === metaA.value,
          );
          publicationB = publicationAutocomplete.find(
            (publication) => publication.taskId === metaB.value,
          );
        }
        if (publicationA && publicationB) {
          return publicationB?.title.localeCompare(publicationA?.title);
        }
      default:
        return metaB?.value - metaA?.value;
    }
  }
  if (metaA && metaA?.value && metaB && !metaB.value) {
    return -1;
  }
  if (metaA && !metaA?.value && metaB && metaB.value) {
    return 1;
  }
  return 0;
};
