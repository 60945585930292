import React, { useRef, useState, Dispatch, SetStateAction } from "react";

import { DropdownMenuItem } from "../../components/Dropdown/Dropdown";
import IDHFormattedMessage from "../../components/IDHFormattedMessage/IDHFormattedMessage";
import DropdownPortal, {
  DropdownPortalMenu,
} from "../../components/DropdownPortal";
import { ContentProposalElementStatus } from "../../../redux/comment/types";
import { ReactComponent as TickBlueIcon } from "../../../images/tick-blue.svg";
import useOnClickOutside from "../../methods/useOnClickOutside";

interface Props {
  children: React.ReactNode;
  onFilterChanged: (status: ContentProposalElementStatus[]) => void;
  contentProposalSummaryFilterStatus: ContentProposalElementStatus[];
  setContentProposalSummaryFilterStatus: Dispatch<
    SetStateAction<ContentProposalElementStatus[]>
  >;
}

function ContentProposalElementsSummaryFilterDropdown({
  children,
  onFilterChanged,
  contentProposalSummaryFilterStatus,
  setContentProposalSummaryFilterStatus,
}: Props) {
  const [visible, setVisible] = useState(false);

  const ref = useRef(null);

  const toggleStatus = (newStatus: ContentProposalElementStatus) => {
    let newStatuses = contentProposalSummaryFilterStatus;

    if (contentProposalSummaryFilterStatus.includes(newStatus)) {
      newStatuses = newStatuses.filter(
        (v: ContentProposalElementStatus) => v !== newStatus,
      );
    } else {
      newStatuses.push(newStatus);
    }

    setContentProposalSummaryFilterStatus(newStatuses);
    onFilterChanged(newStatuses);
  };
  const clearStatus = () => {
    onFilterChanged([]);
    setContentProposalSummaryFilterStatus([]);
  };

  const onChangeVisibility = () => {
    setVisible(!visible);
  };

  useOnClickOutside(ref, () => setVisible(false));

  const menu = (
    <DropdownPortalMenu>
      <DropdownMenuItem
        onClick={() => {
          clearStatus();
        }}
      >
        <IDHFormattedMessage
          id="ws_show_all_proposal_status"
          defaultMessage="Show all"
        />
        {contentProposalSummaryFilterStatus.length === 0 && (
          <TickBlueIcon className="simple-select__tick-blue" />
        )}
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => {
          toggleStatus(ContentProposalElementStatus.Fresh);
        }}
      >
        <IDHFormattedMessage
          id="ws_moderation_needed_proposal_status"
          defaultMessage="Moderation needed"
        />
        {contentProposalSummaryFilterStatus.includes(
          ContentProposalElementStatus.Fresh,
        ) && <TickBlueIcon className="simple-select__tick-blue" />}
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => {
          toggleStatus(ContentProposalElementStatus.Accepted);
        }}
      >
        <IDHFormattedMessage
          id="ws_accepted_proposal_status"
          defaultMessage="Accepted"
        />
        {contentProposalSummaryFilterStatus.includes(
          ContentProposalElementStatus.Accepted,
        ) && <TickBlueIcon className="simple-select__tick-blue" />}
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => {
          toggleStatus(ContentProposalElementStatus.Rejected);
        }}
      >
        <IDHFormattedMessage
          id="ws_rejected_proposal_status"
          defaultMessage="Rejected"
        />
        {contentProposalSummaryFilterStatus.includes(
          ContentProposalElementStatus.Rejected,
        ) && <TickBlueIcon className="simple-select__tick-blue" />}
      </DropdownMenuItem>
    </DropdownPortalMenu>
  );

  return (
    <div ref={ref}>
      <DropdownPortal
        overlayClassName="comment-menu-options"
        overlay={menu}
        onClick={onChangeVisibility}
        visible={visible}
      >
        <span>{children}</span>
      </DropdownPortal>
    </div>
  );
}

export default ContentProposalElementsSummaryFilterDropdown;
