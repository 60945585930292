import React from "react";

import { ReactComponent as OtherAttachmentIcon } from "../../../images/other-attachment.svg";
import { ReactComponent as ProgressBar } from "../../../images/progress-bar.svg";

interface Props {
  fileName: string | null;
}

function AttachmentLoader({ fileName }: Props) {
  return (
    <div className="attachment-wrapper attachment-loader">
      <div className="attachment-file">
        <OtherAttachmentIcon className="attachment-loader__icon" />
        <div className="attachment-file__details">
          {fileName && <div className="attachment-file__name">{fileName}</div>}
          <ProgressBar className="attachment-loader__progressbar" />
        </div>
      </div>
    </div>
  );
}

export default AttachmentLoader;
