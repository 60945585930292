import React, { useEffect, useState } from "react";
import "./MyTasks.scss";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router";
import { injectIntl, useIntl } from "react-intl";
import { isStringUuid } from "src/utils/methods";
import { RootState } from "src/redux/reducers";
import AnimatedDiv from "../components/AnimatedDiv/AnimatedDiv";
import PageHeader from "../components/PageHeader/PageHeader";
import RecentlyAssigned from "./RecentlyAssigned/RecentlyAssigned";
import { clearMyTasksList, getMyTasks } from "../../redux/task/taskActions";
import { setShowTask } from "../../redux";
import Task from "../Task/Task";
import { showToast } from "../methods/showToast";
import WebsocketListener from "../../WebsocketListener";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { GlobalTaskDetails } from "../components/GlobalTaskDetails/GlobalTaskDetails";

function MyTasks() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const params = useParams<{ taskId: string }>();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const showGlobalTask = isStringUuid(query.get("displayGlobalTask") || "");

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );
  const { taskDetailsError } = useSelector(
    (state: RootState) => state.taskReducer,
  );

  const { myTasks } = useSelector((state: RootState) => state.taskReducer);
  const { showTask } = useSelector((state: RootState) => state.projectReducer);

  useEffect(() => {
    if (activeWorkspaceUuid) {
      dispatch(getMyTasks(activeWorkspaceUuid));
    }
  }, [activeWorkspaceUuid]);

  useEffect(() => {
    if (taskDetailsError && params.taskId) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_you_dont_have_access_to_this_resource"
          defaultMessage="You don't have access to this resource"
        />,
      );
      history.push(`/workspace/${activeWorkspaceUuid}/my-actions`);
    }
  }, [taskDetailsError]);

  useEffect(() => {
    if (params.taskId) {
      dispatch(setShowTask(true));
    } else {
      dispatch(setShowTask(false));
    }
  }, [location]);

  useEffect(() => {
    return () => {
      dispatch(clearMyTasksList());
    };
  }, []);

  return (
    <AnimatedDiv className="my-tasks">
      <PageHeader
        title={
          <IDHFormattedMessage id="ws_my_actions" defaultMessage="My actions" />
        }
        description={
          <IDHFormattedMessage
            id="ws_my_actions_description"
            defaultMessage="Here you will find all tasks assigned to you."
          />
        }
      />
      <RecentlyAssigned myTasks={myTasks} />

      {showTask && (
        <Task onCloseHandler={() => dispatch(setShowTask(false))} onMyTasks />
      )}

      {showGlobalTask && <GlobalTaskDetails />}

      <WebsocketListener />
    </AnimatedDiv>
  );
}

export default MyTasks;
