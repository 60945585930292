import React from "react";
import "./CommentsAnalysisTab.scss";
import { SentimentData } from "./sections/SentimentData";
import { SentimentAnalysis } from "./sections/SentimentAnalysis/SentimentAnalysis";

function CommentsAnalysisTab(props) {
  const {
    platform,
    platformDisplayName,
    sentimentData,
    sentimentCommentsData,
  } = props;

  // use in task: https://app.asana.com/0/807220246535156/1207096865853875/f
  // const publicationNotDetectedReason = "account_private";
  //
  // if (publicationNotDetectedReason) {
  //   return (
  //     <PublicationNotDetected
  //       reason={publicationNotDetectedReason}
  //       setShowUpdatePublicationLinkModal={setShowUpdatePublicationLinkModal}
  //     />
  //   );
  // }

  return (
    <div className="comments-analysis-tab">
      <SentimentData
        platform={platform}
        platformDisplayName={platformDisplayName}
        sentimentData={sentimentData}
      />

      <SentimentAnalysis
        sentimentData={sentimentData}
        sentimentCommentsData={sentimentCommentsData}
      />
    </div>
  );
}

export { CommentsAnalysisTab };
