import React from "react";

import { LocationByCity } from "../../../../Task/tabs/ProfileDataTab/single/LocationByCity";

export const renderLocationByCity = (label, data, layout, GridBoxComponent) => {
  const {
    user_audience_data: { audienceCityReach },
  } = data;

  const key = `${label}-location-by-city`;
  const height = layout?.find((item) => item.i === key)?.h;

  return (
    <GridBoxComponent key={key} id={key}>
      <LocationByCity audienceCityReach={audienceCityReach} height={height} />
    </GridBoxComponent>
  );
};
