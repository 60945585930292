import React, { useMemo, useState } from "react";
import "./SentimentAnalysis.scss";

import { SectionRowWrapper } from "src/app/components/Pane/SectionRowWrapper/SectionRowWrapper";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tabs from "src/app/components/Tabs/Tabs";
import Tab from "src/app/components/Tabs/Tab";
import Pictogram, {
  PictogramVariants,
} from "src/app/components/Pictogram/Pictogram";
import { SectionHeader } from "../../../../../components/Pane/SectionHeader/SectionHeader";
import { CommentList } from "../../components/CommentList/CommentList";

export function SentimentAnalysis(props) {
  const { sentimentData, sentimentCommentsData } = props;

  const [activeTab, setActiveTab] = useState("positive");

  const positiveValue = useMemo(
    () =>
      sentimentData?.find((item) => item.name === "sentimentPositiveShare")
        ?.value * 100,
    [],
  );
  const neutralValue = useMemo(
    () =>
      sentimentData?.find((item) => item.name === "sentimentNeutralShare")
        ?.value * 100,
    [],
  );
  const negativeValue = useMemo(
    () =>
      sentimentData?.find((item) => item.name === "sentimentNegativeShare")
        ?.value * 100,
    [],
  );

  return (
    <SectionRowWrapper className="sentiment-analysis">
      <div className="task-section-header-wrapper">
        <SectionHeader
          name={
            <IDHFormattedMessage
              id="ws_sentiment_analysis"
              defaultMessage="Sentiment analysis"
            />
          }
        />
      </div>

      <Tabs>
        <Tab
          tabText={
            <div className="sentiment-analysis__tab-content">
              <Pictogram
                identifier="Sentiment/positive"
                variant={PictogramVariants.ForestCrayola}
              />
              {parseInt(positiveValue || 0)}%
            </div>
          }
          active={activeTab === "positive"}
          onClick={() => setActiveTab("positive")}
          big
        />

        <Tab
          tabText={
            <div className="sentiment-analysis__tab-content">
              <Pictogram
                identifier="Sentiment/neutral"
                variant={PictogramVariants.PhilippineSilver}
              />
              {parseInt(neutralValue || 0)}%
            </div>
          }
          active={activeTab === "neutral"}
          onClick={() => setActiveTab("neutral")}
          big
        />

        <Tab
          tabText={
            <div className="sentiment-analysis__tab-content">
              <Pictogram
                identifier="Sentiment/negative"
                variant={PictogramVariants.Vermilion}
              />
              {parseInt(negativeValue || 0)}%
            </div>
          }
          active={activeTab === "negative"}
          onClick={() => setActiveTab("negative")}
          big
        />
      </Tabs>

      <CommentList
        sentimentCommentsData={sentimentCommentsData}
        activeTab={activeTab}
      />
    </SectionRowWrapper>
  );
}
