import React from "react";

import { IntlShape } from "react-intl";

import { translateMessage } from "src/app/methods/translateMessage";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import { ProjectTemplate, ProjectTemplateType } from "src/redux/project/types";
import { ProjectSetupOption, ProjectSetupType, SelectOption } from "./types";

export const getProjectSetupOptions = (
  intl: IntlShape,
): ProjectSetupOption[] => {
  return [
    {
      value: ProjectSetupType.Template,
      label: translateMessage({
        intl,
        id: "ws_template",
        defaultMessage: "Template",
      }),
      customElement: (
        <div className="ws-react-select__option-description">
          <IDHFormattedMessage
            id="ws_campaign_from_template"
            defaultMessage="Create a new campaign based on pre-defined template"
          />
        </div>
      ),
    },
    {
      value: ProjectSetupType.BasedOnExistingProject,
      label: translateMessage({
        intl,
        id: "ws_use_structure_from_existing_campaign",
        defaultMessage: "Use structure from existing campaign",
      }),
      customElement: (
        <div className="ws-react-select__option-description">
          <IDHFormattedMessage
            id="ws_campaign_based_on_existing_campaign"
            defaultMessage="Create a new campaign based on the structure of an existing campaign"
          />
        </div>
      ),
    },
    {
      value: ProjectSetupType.Blank,
      label: translateMessage({
        intl,
        id: "ws_blank",
        defaultMessage: "Blank",
      }),
      customElement: (
        <div className="ws-react-select__option-description">
          <IDHFormattedMessage
            id="ws_campaign_without_structure"
            defaultMessage="Create a new campaign without structure"
          />
        </div>
      ),
    },
  ];
};

export const getProjectTemplateOptions = (
  projectTemplates: ProjectTemplate[],
  type: ProjectTemplateType,
) => {
  return projectTemplates
    .filter((projectTemplate) => projectTemplate.type === type)
    .map((projectTemplate) => {
      const baseOptionProperties = {
        value: projectTemplate.uuid,
        label: projectTemplate.name,
      };

      if (projectTemplate.description) {
        return {
          ...baseOptionProperties,
          customElement: (
            <div className="ws-react-select__option-description">
              {projectTemplate.description}
            </div>
          ),
        };
      }

      return baseOptionProperties;
    });
};

const removeDuplicatedFields = (arr: Metadata[]) => {
  const sanitizedArray = arr.reduce(
    (uniqueObjects: Metadata[], currentObject) => {
      const existingObject = uniqueObjects.find(
        (obj: Metadata) => obj.uuid === currentObject.uuid,
      );
      if (!existingObject) {
        uniqueObjects.push(currentObject);
      }

      return uniqueObjects;
    },
    [],
  );

  return sanitizedArray;
};

export const getProjectTemplatesRequiredFields = (
  selectedBaseTemplateOption: SelectOption | null,
  selectedSocialPlatformTemplateOptions: SelectOption[],
  projectTemplates: ProjectTemplate[],
) => {
  let fields: Metadata[] = [];

  if (selectedBaseTemplateOption) {
    const foundProjectTemplate = projectTemplates.find(
      (template) => template.uuid === selectedBaseTemplateOption.value,
    );
    if (foundProjectTemplate) {
      fields = [...foundProjectTemplate.valueRequiredWsGlobalProjectMetaFields];
    }
  }

  if (selectedSocialPlatformTemplateOptions.length) {
    selectedSocialPlatformTemplateOptions.forEach((template) => {
      const foundProjectTemplate = projectTemplates.find(
        (projectTemplate) => projectTemplate.uuid === template.value,
      );
      if (foundProjectTemplate) {
        fields = [
          ...fields,
          ...foundProjectTemplate.valueRequiredWsGlobalProjectMetaFields,
        ];
      }
    });
  }

  return removeDuplicatedFields(fields);
};

export const isSubmissionFormEnabled = (
  selectedBaseTemplateOption: SelectOption | null,
  selectedSocialPlatformTemplateOptions: SelectOption[],
  projectTemplates: ProjectTemplate[],
) => {
  const result = [];

  if (selectedBaseTemplateOption) {
    const foundBaseTemplate = projectTemplates.find(
      (template) => template.uuid === selectedBaseTemplateOption.value,
    );
    if (foundBaseTemplate) {
      result.push(foundBaseTemplate.isSubmissionFormEnabled);
    }
  }

  if (selectedSocialPlatformTemplateOptions.length) {
    selectedSocialPlatformTemplateOptions.forEach((socialTemplate) => {
      const foundTemplate = projectTemplates.find(
        (projectTemplate) => projectTemplate.uuid === socialTemplate.value,
      );
      if (foundTemplate) {
        result.push(foundTemplate.isSubmissionFormEnabled);
      }
    });
  }

  return result.some(Boolean);
};
