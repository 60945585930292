import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import classNames from "classnames";
import Flag from "src/app/components/Flag/Flag";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import METHODS from "../../../helpers/methods";
import { OverlapInfluencerInfo } from "./OverlapInfluencerInfo";
import CONSTS from "../../../helpers/consts";
import {
  CDTable,
  CDTableColumn,
  CDTableRow,
} from "../../../components/CDTable/CDTable";
import WarningInfo from "../../AudiencesResults/AudienceResultLoading/WarningInfo";

const columns = [
  {
    id: "front_username",
    defaultMessage: "Username",
    toLeft: true,
  },
  {
    id: "front_followers",
    defaultMessage: "Followers",
  },
  {
    id: "front_overlapping",
    defaultMessage: "Overlapping",
    tooltip: {
      id: "front_overlapping_followers_tooltip",
      defaultMessage:
        "Percentage of followers by this Creator that overlap with one or more ofthe Creator on the list.",
    },
  },
  {
    id: "front_unique",
    defaultMessage: "Unique",
    tooltip: {
      alignRight: true,
      id: "front_unique_followers_tooltip",
      defaultMessage:
        "Percentage of followers by this Creator that are not featured by any other Creator on this list.",
    },
  },
];

export function OverlappedUsersTable({ influencersInList, overlapData }) {
  const [influencersOverlapData, setInfluencersOverlapData] = useState([]);
  const [errorsWithCalculatingOverlap, setErrorsWithCalculatingData] = useState(
    [],
  );

  const history = useHistory();

  const openProfile = (socialProvider, username) => {
    const pathNameWithProfile = `${window.location.pathname}/profile/${socialProvider}/${username}`;
    const { search } = window.location;

    history.push({
      pathname: pathNameWithProfile,
      search,
    });
  };

  useEffect(() => {
    const filteredOverlapData = [];

    overlapData.data?.forEach((influencer) => {
      const extendedInfo = influencersInList.find(
        (influencerInList) =>
          influencerInList.socialUsername === influencer.username,
      );
      if (extendedInfo) {
        filteredOverlapData.push({
          overlapInfo: influencer,
          extendedInfo,
        });
      }
    });

    setInfluencersOverlapData(filteredOverlapData);

    if (
      Array.isArray(overlapData.errorsWithCalculatingOverlap) ||
      !overlapData.errorsWithCalculatingOverlap
    )
      return;

    const errorsArray = Object.entries(
      overlapData.errorsWithCalculatingOverlap,
    );
    const transformedErrorsArray = [];

    errorsArray.forEach((errorArr) => {
      const influencerErrorData = {
        username: errorArr[0],
        errorMessage: errorArr[1],
      };

      const extendedInfo = influencersInList.find(
        (influencerInList) =>
          influencerInList.socialUsername === influencerErrorData.username,
      );
      if (extendedInfo) {
        transformedErrorsArray.push({
          extendedInfo,
          errorMessage: errorArr[1],
        });
      }
    });

    setErrorsWithCalculatingData(transformedErrorsArray);
  }, [influencersInList, overlapData]);

  return (
    <div className="audience-overlap-results">
      <CDTable columns={columns} autoLayout>
        {errorsWithCalculatingOverlap.map((influencerWithError) => (
          <CDTableRow
            key={influencerWithError.extendedInfo.userId}
            data-qa={influencerWithError.extendedInfo.socialUsername}
          >
            <CDTableColumn className="audience-overlap-table__username">
              <div className="influencer-photo">
                <div
                  className={classNames(
                    "influencer-photo__img",
                    "cdt-table__image-content",
                    "cdt-table__image",
                  )}
                  style={{
                    backgroundImage: `url(${influencerWithError.extendedInfo.profilePictureUrl})`,
                  }}
                />

                <Flag countryCode={influencerWithError.extendedInfo.country} />
              </div>
              <OverlapInfluencerInfo
                socialProvider={CONSTS.socialProvider.instagram}
                username={influencerWithError.extendedInfo.socialUsername}
                openProfile={openProfile}
              />
            </CDTableColumn>
            <CDTableColumn colSpan="3">
              <WarningInfo
                error={
                  <IDHFormattedMessage
                    id={`front_missing_audience_data_${influencerWithError.errorMessage}`}
                    defaultMessage={influencerWithError.errorMessage}
                  />
                }
              />
            </CDTableColumn>
          </CDTableRow>
        ))}
        {influencersOverlapData.map((influencer) => (
          <CDTableRow
            key={influencer.userId}
            data-qa={influencer.overlapInfo.username}
          >
            <CDTableColumn className="audience-overlap-table__username">
              <div className="influencer-photo">
                <div
                  className={classNames(
                    "influencer-photo__img",
                    "cdt-table__image-content",
                    "cdt-table__image",
                  )}
                  style={{
                    backgroundImage: `url(${influencer.extendedInfo.profilePictureUrl})`,
                  }}
                  onClick={() =>
                    openProfile(
                      CONSTS.socialProvider.instagram,
                      influencer.overlapInfo.username,
                    )
                  }
                />

                <Flag countryCode={influencer.extendedInfo.country} />
              </div>
              <OverlapInfluencerInfo
                socialProvider={CONSTS.socialProvider.instagram}
                username={influencer.overlapInfo.username}
                openProfile={openProfile}
              />
            </CDTableColumn>
            <CDTableColumn>
              <span className="audience-overlap-table__amount audience-overlap-table__amount--followers">
                {METHODS.splitNumbers(influencer.overlapInfo.followers)}
              </span>
            </CDTableColumn>
            <CDTableColumn>
              <span className="audience-overlap-table__amount audience-overlap-table__amount--overlapping">
                {METHODS.splitPercents(
                  1 - influencer.overlapInfo.uniquePercentage,
                  " ",
                  1,
                )}
              </span>
            </CDTableColumn>
            <CDTableColumn>
              <span className="audience-overlap-table__amount audience-overlap-table__amount--unique">
                {METHODS.splitPercents(
                  influencer.overlapInfo.uniquePercentage,
                  " ",
                  1,
                )}
              </span>
            </CDTableColumn>
          </CDTableRow>
        ))}
      </CDTable>
    </div>
  );
}
