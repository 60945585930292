export const sortTitleData = (rowA, rowB, id, asc) => {
  const dir = asc ? -1 : 1;

  const metaA = rowA.original.metadata.find((field) => field.key === "title");
  const metaB = rowB.original.metadata.find((field) => field.key === "title");

  if (!metaA?.value) {
    return dir;
  }
  if (!metaB?.value) {
    return -dir;
  }

  if (metaA?.value && metaB.value) {
    return metaB?.value.localeCompare(metaA?.value);
  }
  if (metaA && metaA?.value && metaB && !metaB.value) {
    return -1;
  }
  if (metaA && !metaA?.value && metaB && metaB.value) {
    return 1;
  }
  return 0;
};
