import React, { useEffect, useMemo, useState } from "react";
import "./ManagePagesModal.scss";

import classNames from "classnames";
import { arrayMoveImmutable } from "array-move";
import { useDispatch, useSelector } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import html2canvas from "html2canvas";

import Modal, { ModalTitle } from "src/app/components/Modal/Modal";
import {
  setNewlyAddedPages,
  setReportGridElements,
  setReportHiddenGridElements,
  setReportLayout,
  setReportSeparators,
} from "src/redux";
import Loader from "src/app/components/Loader/Loader";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import zIndex from "src/utils/zIndex";
import { ReactComponent as DeleteIcon } from "../../../../images/trash-can-sm.svg";
import { ReactComponent as PlusIcon } from "../../../../images/plus-transparent.svg";
import { ReactComponent as CrossIcon } from "../../../../images/cross.svg";

export function ManagePagesModal({ onClose, format }) {
  const [pages, setPages] = useState([]);
  const [generatingPages, setGeneratingPages] = useState(true);
  const [pageWidth, setPageWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);

  const dispatch = useDispatch();

  const {
    reportReducer: {
      layout,
      gridElements,
      hiddenGridElements,
      newlyAddedPages,
      separators,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    setTimeout(() => {
      getMiniature();
    }, 200);
  }, []);

  const getMiniature = () => {
    const reportTemplate = document.querySelector(".report-template");

    html2canvas(reportTemplate, {
      onclone: (clone) => {
        clone.querySelectorAll("img").forEach((img) => img.remove());
      },
    }).then((templateCanvas) => {
      if (!templateCanvas) return;

      const url = templateCanvas.toDataURL();
      const image = new Image();
      image.onload = () => getMiniaturePages(image, templateCanvas);
      image.src = url;
    });
  };

  const getMiniaturePages = (image, templateCanvas) => {
    setPageWidth(templateCanvas.width);
    setPageHeight(templateCanvas.width * 1.41330166271);
    const widthOfOnePiece = templateCanvas.width;
    const heightOfOnePiece = templateCanvas.width * 1.41330166271;

    const numRowsToCut = Math.floor(templateCanvas.height / heightOfOnePiece);

    const newPages = [];
    for (let i = 0; i < numRowsToCut; ++i) {
      const canvas = document.createElement("canvas");
      canvas.width = widthOfOnePiece;
      canvas.height = heightOfOnePiece;
      const context = canvas.getContext("2d");
      context.drawImage(
        image,
        0,
        i * heightOfOnePiece,
        widthOfOnePiece,
        heightOfOnePiece,
        0,
        0,
        canvas.width,
        canvas.height,
      );
      const page = {
        src: canvas.toDataURL(),
      };
      newPages.push(page);
    }
    setPages(newPages);
    setGeneratingPages(false);
  };

  const updatePagePosition = ({ oldIndex, newIndex }) => {
    if (newIndex === 0 || newIndex === pages.length - 1) return;

    let newLayout = [];

    newLayout = getNewLayout(oldIndex, newIndex);

    if (newLayout.length) {
      dispatch(
        setReportLayout(
          newLayout.filter((item) => !item.i.includes("separator")),
        ),
      );
    }

    setPages((pages) => arrayMoveImmutable(pages, oldIndex, newIndex));
  };

  const addEmptyPage = (index) => {
    const { rows, cols } = format;

    const canvas = document.createElement("canvas");
    canvas.width = pageWidth;
    canvas.height = pageHeight;
    const context = canvas.getContext("2d");
    context.beginPath();
    context.fillStyle = "white";
    context.fillRect(0, 0, pageWidth, pageHeight);

    const newPageRange = {
      start: rows * (index + 1),
      end: rows * (index + 2) - 1,
    };

    const newNewlyAddedPages = newlyAddedPages.map((item) => {
      if (item.start === newPageRange.start) {
        return {
          start: item.start + rows,
          end: item.end + rows,
        };
      }

      return item;
    });

    const newLayout = layout.map((item) => {
      if (item.y >= newPageRange.start) {
        return {
          ...item,
          y: item.y + rows,
        };
      }

      return item;
    });

    if (index === pages.length - 2) {
      const separatorId = `separator-${newPageRange.end}`;

      const newSeparators = [
        ...separators.map((item, index) => {
          if (index === separators.length - 1) {
            return {
              ...item,
              provider: "default",
            };
          }

          return item;
        }),
        { key: separatorId, provider: "last" },
      ];

      const newSeparator = {
        i: separatorId,
        w: cols,
        h: 1,
        y: newPageRange.end,
        x: 0,
      };

      dispatch(setReportSeparators(newSeparators));
      newLayout.push(newSeparator);
    }

    dispatch(setNewlyAddedPages([...newNewlyAddedPages, newPageRange]));
    setPages((pages) => [
      ...pages.slice(0, index + 1),
      {
        src: canvas.toDataURL(),
      },
      ...pages.slice(index + 1),
    ]);
    dispatch(setReportLayout(newLayout));
  };

  const deletePage = (number) => {
    if (number === 0 || number === pages.length - 1) return;

    const { rows } = format;
    const start = number * rows;
    const end = (number + 1) * rows - 1;

    const elementsToRemove = layout.filter((item) => {
      if (item.y >= start && item.y <= end) {
        return true;
      }
    });

    const newGridElements = gridElements?.filter(
      (item) => !elementsToRemove.find((el) => el.i === item.label),
    );
    const newHiddenGridElements = gridElements?.filter((item) =>
      elementsToRemove.find((el) => el.i === item.label),
    );

    dispatch(setReportGridElements(newGridElements));
    dispatch(
      setReportHiddenGridElements([
        ...hiddenGridElements,
        ...newHiddenGridElements,
      ]),
    );

    setPages((pages) => [
      ...pages.slice(0, number),
      ...pages.slice(number + 1),
    ]);

    if (
      newlyAddedPages.find((page) => page.start === start && page.end === end)
    ) {
      dispatch(
        setNewlyAddedPages(
          newlyAddedPages.filter(
            (page) => page.start === start && page.end === end,
          ),
        ),
      );
    }
  };

  const getNewLayout = (oldIndex, newIndex) => {
    const isPositive = Math.sign(oldIndex - newIndex) >= 0;
    const difference = Math.abs(newIndex - oldIndex);
    const { rows } = format;

    if (isPositive) {
      const rowBottom = newIndex * rows;
      const rowTop = oldIndex * rows;

      return layout.map((item) => {
        if (item.y >= rowTop && item.y < rowTop + rows) {
          return {
            ...item,
            y: item.y - rows * difference,
          };
        }

        if (item.y >= rowBottom && item.y < rowTop) {
          return {
            ...item,
            y: item.y + rows,
          };
        }

        return item;
      });
    }
    const rowBottom = oldIndex * rows;
    const rowTop = (newIndex + 1) * rows;

    return layout.map((item) => {
      if (item.y >= rowBottom && item.y < rowBottom + rows) {
        return {
          ...item,
          y: item.y + rows * difference,
        };
      }

      if (item.y >= rowBottom && item.y < rowTop) {
        return {
          ...item,
          y: item.y - rows,
        };
      }

      return item;
    });
  };

  const Page = SortableElement(({ page, number }) => (
    <li
      className="manage-pages-modal__page-wrapper"
      style={{ zIndex: zIndex.max }}
    >
      <div
        className={classNames("manage-pages-modal__page", {
          "manage-pages-modal__page--disabled":
            number === 0 || number === pages.length - 1,
        })}
      >
        <img src={page.src} alt="page" crossOrigin="anonymous" />
        <span className="manage-pages-modal__page-number">{number + 1}</span>

        {number !== 0 && number !== pages.length - 1 && (
          <span className="manage-pages-modal__page-button manage-pages-modal__page-delete">
            <DeleteIcon onClick={() => deletePage(number)} />
          </span>
        )}
      </div>

      {number !== pages.length - 1 && (
        <span className="manage-pages-modal__main-pages-plus">
          <PlusIcon onClick={() => addEmptyPage(number)} />
        </span>
      )}
    </li>
  ));

  const PageList = SortableContainer(() => {
    return (
      <ul className="manage-pages-modal__main-pages">
        {pages.map((value, index) => (
          <Page
            key={`item-${value}`}
            index={index}
            number={index}
            page={value}
            disabled={index === 0 || index === pages.length - 1}
          />
        ))}
      </ul>
    );
  });

  const PagesLoader = useMemo(() => {
    return (
      <div className="manage-pages-modal__main-content-loader">
        <Loader />
      </div>
    );
  }, [generatingPages]);

  return (
    <Modal className="manage-pages-modal" onClose={onClose}>
      <div className="manage-pages-modal__main">
        <div className="manage-pages-modal__main-top">
          <ModalTitle>
            <IDHFormattedMessage
              id="ws_report_pages_management"
              defaultMessage="Report pages management"
            />
          </ModalTitle>

          <button
            className="manage-pages-modal__main-top-close"
            onClick={onClose}
          >
            <CrossIcon />
          </button>
        </div>

        <div className="manage-pages-modal__main-content">
          {generatingPages ? (
            PagesLoader
          ) : (
            <PageList
              pages={pages}
              onSortEnd={updatePagePosition}
              axis="xy"
              distance={1}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}
