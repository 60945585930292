import React, { PropsWithChildren } from "react";
import classNames from "classnames";

import { SocialProfileStatus } from "src/types";

interface SocialProfileLinkProps {
  status: SocialProfileStatus;
  url: string;
}

export default function SocialProfileLink({
  status,
  url,
  children,
}: PropsWithChildren<SocialProfileLinkProps>) {
  return (
    <a
      className={classNames("social-profile-icons__icon", {
        "social-profile-icons__icon--awaiting":
          status === SocialProfileStatus.Fresh,
        "social-profile-icons__icon--verified":
          status === SocialProfileStatus.Verified,
        "social-profile-icons__icon--not-found":
          status === SocialProfileStatus.NotFound,
        "social-profile-icons__icon--error":
          status === SocialProfileStatus.Error,
        "social-profile-icons__icon--restricted":
          status === SocialProfileStatus.Restricted,
        "social-profile-icons__icon--private":
          status === SocialProfileStatus.Private,
      })}
      href={url}
      rel="noopener noreferrer nofollow"
      target="_blank"
      onClick={(e) => {
        if (status === SocialProfileStatus.Verified) {
          e.stopPropagation();
        }
      }}
    >
      {children}
    </a>
  );
}
