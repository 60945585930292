import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Flag from "src/app/components/Flag/Flag";

const countryData = [
  {
    name: "Afghanistan",
    code: "AF",
    dialling_code: "+93",
  },
  {
    name: "Albania",
    code: "AL",
    dialling_code: "+355",
  },
  {
    name: "Algeria",
    code: "DZ",
    dialling_code: "+213",
  },
  {
    name: "American Samoa",
    code: "AS",
    dialling_code: "+1",
  },
  {
    name: "Andorra",
    code: "AD",
    dialling_code: "+376",
  },
  {
    name: "Angola",
    code: "AO",
    dialling_code: "+244",
  },
  {
    name: "Anguilla",
    code: "AI",
    dialling_code: "+43",
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    dialling_code: "+1",
  },
  {
    name: "Argentina",
    code: "AR",
    dialling_code: "+54",
  },
  {
    name: "Armenia",
    code: "AM",
    dialling_code: "+374",
  },
  {
    name: "Aruba",
    code: "AW",
    dialling_code: "+297",
  },
  {
    name: "Australia",
    code: "AU",
    dialling_code: "+61",
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    dialling_code: "+994",
  },
  {
    name: "Bahamas",
    code: "BS",
    dialling_code: "+1",
  },
  {
    name: "Bahrain",
    code: "BH",
    dialling_code: "+973",
  },
  {
    name: "Bangladesh",
    code: "BD",
    dialling_code: "+880",
  },
  {
    name: "Barbados",
    code: "BB",
    dialling_code: "+1",
  },
  {
    name: "Belarus",
    code: "BY",
    dialling_code: "+375",
  },
  {
    name: "Belgium",
    code: "BE",
    dialling_code: "+32",
  },
  {
    name: "Belize",
    code: "BZ",
    dialling_code: "+501",
  },
  {
    name: "Benin",
    code: "BJ",
    dialling_code: "+229",
  },
  {
    name: "Bermuda",
    code: "BM",
    dialling_code: "+1",
  },
  {
    name: "Bhutan",
    code: "BT",
    dialling_code: "+975",
  },
  {
    name: "Plurinational State of Bolivia",
    code: "BO",
    dialling_code: "+591",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    dialling_code: "+387",
  },
  {
    name: "Botswana",
    code: "BW",
    dialling_code: "+267",
  },
  {
    name: "Brazil",
    code: "BR",
    dialling_code: "+55",
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    dialling_code: "+246",
  },
  {
    name: "Virgin Islands (British)",
    code: "VG",
    dialling_code: "+1",
  },
  {
    name: "Virgin Islands (U.S.)",
    code: "VI",
    dialling_code: "+1",
  },
  {
    name: "Brunei Darussalam",
    code: "BN",
    dialling_code: "+673",
  },
  {
    name: "Bulgaria",
    code: "BG",
    dialling_code: "+359",
  },
  {
    name: "Burkina Faso",
    code: "BF",
    dialling_code: "+226",
  },
  {
    name: "Burundi",
    code: "BI",
    dialling_code: "+257",
  },
  {
    name: "Cambodia",
    code: "KH",
    dialling_code: "+855",
  },
  {
    name: "Cameroon",
    code: "CM",
    dialling_code: "+237",
  },
  {
    name: "Canada",
    code: "CA",
    dialling_code: "+1",
  },
  {
    name: "Cabo Verde",
    code: "CV",
    dialling_code: "+238",
  },
  {
    name: "Cayman Islands",
    code: "KY",
    dialling_code: "+1",
  },
  {
    name: "Central African Republic",
    code: "CF",
    dialling_code: "+236",
  },
  {
    name: "Chile",
    code: "CL",
    dialling_code: "+56",
  },
  {
    name: "China",
    code: "CN",
    dialling_code: "+86",
  },
  {
    name: "Colombia",
    code: "CO",
    dialling_code: "+57",
  },
  {
    name: "Comoros",
    code: "KM",
    dialling_code: "+269",
  },
  {
    name: "Congo",
    code: "CG",
    dialling_code: "+242",
  },
  {
    name: "Democratic Republic of the Congo",
    code: "CD",
    dialling_code: "+243",
  },
  {
    name: "Cook Islands",
    code: "CK",
    dialling_code: "+682",
  },
  {
    name: "Costa Rica",
    code: "CR",
    dialling_code: "+506",
  },
  {
    name: "Croatia",
    code: "HR",
    dialling_code: "+385",
  },
  {
    name: "Cuba",
    code: "CU",
    dialling_code: "+53",
  },
  {
    name: "Cuba",
    code: "CU",
    dialling_code: "+53",
  },
  {
    name: "Cyprus",
    code: "CY",
    dialling_code: "+357",
  },
  {
    name: "Czech Republic",
    code: "CZ",
    dialling_code: "+420",
  },
  {
    name: "Denmark",
    code: "DK",
    dialling_code: "+45",
  },
  {
    name: "Djibouti",
    code: "DJ",
    dialling_code: "+253",
  },
  {
    name: "Dominica",
    code: "DM",
    dialling_code: "+1",
  },
  {
    name: "Dominican Republic",
    code: "DO",
    dialling_code: "+1",
  },
  {
    name: "Ecuador",
    code: "EC",
    dialling_code: "+593",
  },
  {
    name: "Egypt",
    code: "EG",
    dialling_code: "+20",
  },
  {
    name: "El Salvador",
    code: "SV",
    dialling_code: "+503",
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    dialling_code: "+240",
  },
  {
    name: "Eritrea",
    code: "ER",
    dialling_code: "+291",
  },
  {
    name: "Estonia",
    code: "EE",
    dialling_code: "+372",
  },
  {
    name: "Ethiopia",
    code: "ET",
    dialling_code: "+251",
  },
  {
    name: "Falkland Islands (Malvinas)",
    code: "FK",
    dialling_code: "+500",
  },
  {
    name: "Faroe Islands",
    code: "FO",
    dialling_code: "+298",
  },
  {
    name: "Fiji",
    code: "FJ",
    dialling_code: "+679",
  },
  {
    name: "Finland",
    code: "FI",
    dialling_code: "+358",
  },
  {
    name: "France",
    code: "FR",
    dialling_code: "+33",
  },
  {
    name: "French Guiana",
    code: "GF",
    dialling_code: "+594",
  },
  {
    name: "French Polynesia",
    code: "PF",
    dialling_code: "+689",
  },
  {
    name: "Gabon",
    code: "GA",
    dialling_code: "+241",
  },
  {
    name: "Gambia",
    code: "GM",
    dialling_code: "+220",
  },
  {
    name: "Georgia",
    code: "GE",
    dialling_code: "+995",
  },
  {
    name: "Germany",
    code: "DE",
    dialling_code: "+49",
  },
  {
    name: "Ghana",
    code: "GH",
    dialling_code: "+233",
  },
  {
    name: "Gibraltar",
    code: "GI",
    dialling_code: "+350",
  },
  {
    name: "Greece",
    code: "GR",
    dialling_code: "+30",
  },
  {
    name: "Greenland",
    code: "GL",
    dialling_code: "+299",
  },
  {
    name: "Grenada",
    code: "GD",
    dialling_code: "+1",
  },
  {
    name: "Guadeloupe",
    code: "GP",
    dialling_code: "+590",
  },
  {
    name: "Guam",
    code: "GU",
    dialling_code: "+1",
  },
  {
    name: "Guatemala",
    code: "GT",
    dialling_code: "+502",
  },
  {
    name: "Guinea",
    code: "GN",
    dialling_code: "+224",
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    dialling_code: "+245",
  },
  {
    name: "Guyana",
    code: "GY",
    dialling_code: "+592",
  },
  {
    name: "Haiti",
    code: "HT",
    dialling_code: "+509",
  },
  {
    name: "Holy See",
    code: "VA",
    dialling_code: "+39",
  },
  {
    name: "Honduras",
    code: "HN",
    dialling_code: "+504",
  },
  {
    name: "Hong Kong",
    code: "HK",
    dialling_code: "+852",
  },
  {
    name: "Hungary",
    code: "HU",
    dialling_code: "+36",
  },
  {
    name: "Iceland",
    code: "IS",
    dialling_code: "+354",
  },
  {
    name: "India",
    code: "IN",
    dialling_code: "+91",
  },
  {
    name: "Indonesia",
    code: "ID",
    dialling_code: "+62",
  },
  {
    name: "Côte d'Ivoire",
    code: "CI",
    dialling_code: "+225",
  },
  {
    name: "Islamic Republic of Iran",
    code: "IR",
    dialling_code: "+98",
  },
  {
    name: "Iraq",
    code: "IQ",
    dialling_code: "+964",
  },
  {
    name: "Ireland",
    code: "IE",
    dialling_code: "+353",
  },
  {
    name: "Israel",
    code: "IL",
    dialling_code: "+972",
  },
  {
    name: "Italy",
    code: "IT",
    dialling_code: "+39",
  },
  {
    name: "Jamaica",
    code: "JM",
    dialling_code: "+1",
  },
  {
    name: "Japan",
    code: "JP",
    dialling_code: "+81",
  },
  {
    name: "Jordan",
    code: "JO",
    dialling_code: "+962",
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    dialling_code: "+7",
  },
  {
    name: "Kenya",
    code: "KE",
    dialling_code: "+254",
  },
  {
    name: "Kiribati",
    code: "KI",
    dialling_code: "+686",
  },
  {
    name: "Kuwait",
    code: "KW",
    dialling_code: "+965",
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    dialling_code: "+996",
  },
  {
    name: "Lao People's Democratic Republic",
    code: "LA",
    dialling_code: "+856",
  },
  {
    name: "Latvia",
    code: "LV",
    dialling_code: "+371",
  },
  {
    name: "Lebanon",
    code: "LB",
    dialling_code: "+961",
  },
  {
    name: "Lesotho",
    code: "LS",
    dialling_code: "+266",
  },
  {
    name: "Liberia",
    code: "LR",
    dialling_code: "+231",
  },
  {
    name: "Libya",
    code: "LY",
    dialling_code: "+218",
  },
  {
    name: "Liechtenstein",
    code: "LI",
    dialling_code: "+423",
  },
  {
    name: "Lithuania",
    code: "LT",
    dialling_code: "+370",
  },
  {
    name: "Luxembourg",
    code: "LU",
    dialling_code: "+352",
  },
  {
    name: "Macao",
    code: "MO",
    dialling_code: "+853",
  },
  {
    name: "Macedonia",
    code: "MK",
    dialling_code: "+389",
  },
  {
    name: "Madagascar",
    code: "MG",
    dialling_code: "+261",
  },
  {
    name: "Malawi",
    code: "MW",
    dialling_code: "+265",
  },
  {
    name: "Malaysia",
    code: "MY",
    dialling_code: "+60",
  },
  {
    name: "Maldives",
    code: "MV",
    dialling_code: "+960",
  },
  {
    name: "Mali",
    code: "ML",
    dialling_code: "+223",
  },
  {
    name: "Malta",
    code: "MT",
    dialling_code: "+356",
  },
  {
    name: "Marshall Islands",
    code: "MH",
    dialling_code: "+692",
  },
  {
    name: "Martinique",
    code: "MQ",
    dialling_code: "+596",
  },
  {
    name: "Mauritania",
    code: "MR",
    dialling_code: "+222",
  },
  {
    name: "Mauritius",
    code: "MU",
    dialling_code: "+230",
  },
  {
    name: "Mayotte",
    code: "YT",
    dialling_code: "+262",
  },
  {
    name: "Mexico",
    code: "MX",
    dialling_code: "+52",
  },
  {
    name: "Federated States of Micronesia",
    code: "FM",
    dialling_code: "+691",
  },
  {
    name: "Republic of Moldova",
    code: "MD",
    dialling_code: "+373",
  },
  {
    name: "Monaco",
    code: "MC",
    dialling_code: "+377",
  },
  {
    name: "Mongolia",
    code: "MN",
    dialling_code: "+976",
  },
  {
    name: "Montenegro",
    code: "ME",
    dialling_code: "+382",
  },
  {
    name: "Montserrat",
    code: "MS",
    dialling_code: "+1",
  },
  {
    name: "Morocco",
    code: "MA",
    dialling_code: "+212",
  },
  {
    name: "Mozambique",
    code: "MZ",
    dialling_code: "+258",
  },
  {
    name: "Myanmar",
    code: "MM",
    dialling_code: "+95",
  },
  {
    name: "Namibia",
    code: "NA",
    dialling_code: "+264",
  },
  {
    name: "Nauru",
    code: "NR",
    dialling_code: "+674",
  },
  {
    name: "Nepal",
    code: "NP",
    dialling_code: "+977",
  },
  {
    name: "The Netherlands",
    code: "NL",
    dialling_code: "+31",
  },
  {
    name: "New Caledonia",
    code: "NC",
    dialling_code: "+687",
  },
  {
    name: "New Zealand",
    code: "NZ",
    dialling_code: "+64",
  },
  {
    name: "Nicaragua",
    code: "NI",
    dialling_code: "+505",
  },
  {
    name: "Niger",
    code: "NE",
    dialling_code: "+227",
  },
  {
    name: "Nigeria",
    code: "NG",
    dialling_code: "+234",
  },
  {
    name: "Niue",
    code: "NU",
    dialling_code: "+683",
  },
  {
    name: "Norfolk Island",
    code: "NF",
    dialling_code: "+672",
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    dialling_code: "+1",
  },
  {
    name: "Norway",
    code: "NO",
    dialling_code: "+47",
  },
  {
    name: "Oman",
    code: "OM",
    dialling_code: "+968",
  },
  {
    name: "Pakistan",
    code: "PK",
    dialling_code: "+92",
  },
  {
    name: "Palau",
    code: "PW",
    dialling_code: "+680",
  },
  {
    name: "State of Palestine",
    code: "PS",
    dialling_code: "+970",
  },
  {
    name: "Panama",
    code: "PA",
    dialling_code: "+507",
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    dialling_code: "+675",
  },
  {
    name: "Paraguay",
    code: "PY",
    dialling_code: "+595",
  },
  {
    name: "Peru",
    code: "PE",
    dialling_code: "+51",
  },
  {
    name: "Philippines",
    code: "PH",
    dialling_code: "+63",
  },
  {
    name: "Poland",
    code: "PL",
    dialling_code: "+48",
  },
  {
    name: "Portugal",
    code: "PT",
    dialling_code: "+351",
  },
  {
    name: "Puerto Rico",
    code: "PR",
    dialling_code: "+1",
  },
  {
    name: "Qatar",
    code: "QA",
    dialling_code: "+974",
  },
  {
    name: "Republic of Kosovo",
    code: "XK",
    dialling_code: "+381",
  },
  {
    name: "Réunion",
    code: "RE",
    dialling_code: "+262",
  },
  {
    name: "Romania",
    code: "RO",
    dialling_code: "+40",
  },
  {
    name: "Russian Federation",
    code: "RU",
    dialling_code: "+7",
  },
  {
    name: "Rwanda",
    code: "RW",
    dialling_code: "+250",
  },
  {
    name: "Saint Barthélemy",
    code: "BL",
    dialling_code: "+590",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SH",
    dialling_code: "+290",
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    dialling_code: "+1",
  },
  {
    name: "Saint Lucia",
    code: "LC",
    dialling_code: "+1",
  },
  {
    name: "Saint Martin (French part)",
    code: "MF",
    dialling_code: "+590",
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    dialling_code: "+508",
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    dialling_code: "+1",
  },
  {
    name: "Samoa",
    code: "WS",
    dialling_code: "+685",
  },
  {
    name: "San Marino",
    code: "SM",
    dialling_code: "+378",
  },
  {
    name: "Sao Tome and Principe",
    code: "ST",
    dialling_code: "+239",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    dialling_code: "+966",
  },
  {
    name: "Senegal",
    code: "SN",
    dialling_code: "+221",
  },
  {
    name: "Serbia",
    code: "RS",
    dialling_code: "+381",
  },
  {
    name: "Seychelles",
    code: "SC",
    dialling_code: "+248",
  },
  {
    name: "Sierra Leone",
    code: "SL",
    dialling_code: "+232",
  },
  {
    name: "Singapore",
    code: "SG",
    dialling_code: "+65",
  },
  {
    name: "Slovakia",
    code: "SK",
    dialling_code: "+421",
  },
  {
    name: "Slovenia",
    code: "SI",
    dialling_code: "+386",
  },
  {
    name: "Solomon Islands",
    code: "SB",
    dialling_code: "+677",
  },
  {
    name: "Somalia",
    code: "SO",
    dialling_code: "+252",
  },
  {
    name: "South Africa",
    code: "ZA",
    dialling_code: "+27",
  },
  {
    name: "Republic of Korea",
    code: "KR",
    dialling_code: "+82",
  },
  {
    name: "Spain",
    code: "ES",
    dialling_code: "+34",
  },
  {
    name: "Sri Lanka",
    code: "LK",
    dialling_code: "+94",
  },
  {
    name: "Sudan",
    code: "SD",
    dialling_code: "+249",
  },
  {
    name: "Suriname",
    code: "SR",
    dialling_code: "+597",
  },
  {
    name: "Swaziland",
    code: "SZ",
    dialling_code: "+268",
  },
  {
    name: "Sweden",
    code: "SE",
    dialling_code: "+46",
  },
  {
    name: "Switzerland",
    code: "CH",
    dialling_code: "+41",
  },
  {
    name: "Syrian Arab Republic",
    code: "SY",
    dialling_code: "+963",
  },
  {
    name: "Taiwan",
    code: "TW",
    dialling_code: "+886",
  },
  {
    name: "Tajikistan",
    code: "TJ",
    dialling_code: "+992",
  },
  {
    name: "United Republic of Tanzania",
    code: "TZ",
    dialling_code: "+255",
  },
  {
    name: "Thailand",
    code: "TH",
    dialling_code: "+66",
  },
  {
    name: "Timor-Leste",
    code: "TL",
    dialling_code: "+670",
  },
  {
    name: "Togo",
    code: "TG",
    dialling_code: "+228",
  },
  {
    name: "Tokelau",
    code: "TK",
    dialling_code: "+690",
  },
  {
    name: "Tonga",
    code: "TO",
    dialling_code: "+676",
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    dialling_code: "+1",
  },
  {
    name: "Tunisia",
    code: "TN",
    dialling_code: "+216",
  },
  {
    name: "Turkey",
    code: "TR",
    dialling_code: "+90",
  },
  {
    name: "Turkmenistan",
    code: "TM",
    dialling_code: "+993",
  },
  {
    name: "Turks and Caicos Islands",
    code: "TC",
    dialling_code: "+1",
  },
  {
    name: "Tuvalu",
    code: "TV",
    dialling_code: "+688",
  },
  {
    name: "Uganda",
    code: "UG",
    dialling_code: "+256",
  },
  {
    name: "Ukraine",
    code: "UA",
    dialling_code: "+380",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    dialling_code: "+971",
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    code: "GB",
    dialling_code: "+44",
  },
  {
    name: "United States of America",
    code: "US",
    dialling_code: "+1",
  },
  {
    name: "Uruguay",
    code: "UY",
    dialling_code: "+598",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    dialling_code: "+998",
  },
  {
    name: "Vanuatu",
    code: "VU",
    dialling_code: "+678",
  },
  {
    name: "Bolivarian Republic of Venezuela",
    code: "VE",
    dialling_code: "+58",
  },
  {
    name: "Vietnam",
    code: "VN",
    dialling_code: "+84",
  },
  {
    name: "Wallis and Futuna",
    code: "WF",
    dialling_code: "+681",
  },
  {
    name: "Yemen",
    code: "YE",
    dialling_code: "+967",
  },
  {
    name: "Zambia",
    code: "ZM",
    dialling_code: "+260",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    dialling_code: "+263",
  },
];

export const getCountryOptions = () => {
  return countryData.map((option) => ({
    label: (
      <div className="trial-page-right__country-option">
        <Flag countryCode={option.code} />
        <span className="trial-page-right__country-option-name">
          {option.name}
        </span>
      </div>
    ),
    value: option.name,
    code: option.code,
  }));
};

export const getPhoneNumberPrefixBaseOnCountryCode = (code) =>
  countryData.find((data) => data.code === code).dialling_code;

export const useSoftwareAsOptions = [
  {
    label: <IDHFormattedMessage id="ws_agency" defaultMessage="Agency" />,
    value: "ws_agency",
  },
  {
    label: (
      <IDHFormattedMessage id="ws_local_brand" defaultMessage="Local Brand" />
    ),
    value: "ws_local_brand",
  },
  {
    label: (
      <IDHFormattedMessage id="ws_global_brand" defaultMessage="Global Brand" />
    ),
    value: "ws_global_brand",
  },
  {
    label: (
      <IDHFormattedMessage id="ws_media_owner" defaultMessage="Media Owner" />
    ),
    value: "ws_media_owner",
  },
  {
    label: <IDHFormattedMessage id="ws_ecommerce" defaultMessage="eCommerce" />,
    value: "ws_ecommerce",
  },
  {
    label: <IDHFormattedMessage id="ws_startup" defaultMessage="Startup" />,
    value: "ws_startup",
  },
  {
    label: (
      <IDHFormattedMessage id="ws_university" defaultMessage="University" />
    ),
    value: "ws_university",
  },
];

export const marketOptions = [
  {
    label: (
      <IDHFormattedMessage
        id="ws_select_market_poland"
        defaultMessage="(PL) Poland"
      />
    ),
    value: "PL",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_select_market_europe"
        defaultMessage="(EU) Europe"
      />
    ),
    value: "EU",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_select_market_united_kingdom"
        defaultMessage="(UK) United Kingdom"
      />
    ),
    value: "UK",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_select_market_japan_and_asia_pacific"
        defaultMessage="(JAPAC) Japan and Asia"
      />
    ),
    value: "JAPAC",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_select_market_africa"
        defaultMessage="(AF) Africa"
      />
    ),
    value: "AF",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_select_market_middle_east_and_north_africa"
        defaultMessage="(MENA) Middle East and North Africa"
      />
    ),
    value: "MENA",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_select_market_north_america"
        defaultMessage="(NA) North America"
      />
    ),
    value: "NA",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_select_market_latin_america"
        defaultMessage="(LATAM) Latin America"
      />
    ),
    value: "LATAM",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_select_market_india"
        defaultMessage="(IN) India"
      />
    ),
    value: "IN",
  },
  {
    label: (
      <IDHFormattedMessage id="ws_select_market_other" defaultMessage="Other" />
    ),
    value: "other",
  },
];

export const needAssistanceInOptions = [
  {
    label: (
      <IDHFormattedMessage
        id="ws_finding_creators"
        defaultMessage="Finding Creators"
      />
    ),
    value: "ws_finding_creators",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_evaluating_creators"
        defaultMessage="Evaluating Creators"
      />
    ),
    value: "ws_evaluating_creators",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_managing_influencer_campaigns"
        defaultMessage="Managing Influencer Campaigns, Teams & Tasks"
      />
    ),
    value: "ws_managing_influencer_campaigns",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_generating_reports"
        defaultMessage="Generating Reports on Creators and Campaigns"
      />
    ),
    value: "ws_generating_reports",
  },
  {
    label: <IDHFormattedMessage id="ws_other" defaultMessage="Other" />,
    value: "other",
  },
];

export const isBusinessEmailMatchingWebsiteDomain = (email, website) => {
  const emailDomain = email.match(/@(.+)/);

  const websiteDomain = website.match(
    /^(?:https?:\/\/)?(?:www\.)?(.+?)(?:\/|$)/i,
  );

  return (
    emailDomain &&
    websiteDomain &&
    emailDomain[1].toLowerCase() === websiteDomain[1].toLowerCase()
  );
};

export const formatPhoneNumber = (phoneNumber) => {
  const strippedNumber = phoneNumber.replace(/[^\d ]/g, "");

  const formattedNumber = strippedNumber.replace(/(\d{3})(?=\d)/g, "$1 ");

  return formattedNumber;
};
