import { UserContentProps } from "./commentActions";
import {
  CLEAR_USER_ACCEPTED_CONTENT,
  CLEAR_USER_REJECTED_CONTENT,
  SET_COMMENT_AS_EDITABLE,
  SET_USER_ACCEPTED_CONTENT,
  SET_USER_REJECTED_CONTENT,
  GET_CONTENT_PROPOSAL_SUMMARY_SUCCESS,
  UPDATE_CONTENT_PROPOSAL_FILTER_STATUS,
  CLEAR_CONTENT_PROPOSAL_FILTER_STATUS,
  SET_REPLY_TO_COMMENT_DATA,
  CLEAR_REPLY_TO_COMMENT_DATA,
} from "./commentTypes";
import {
  ContentProposalElement,
  ContentProposalElementCaption,
  ContentProposalElementMedia,
  ContentProposalElementStatus,
} from "./types";
import { removeOrAddContentElementToArray } from "./utils";
import { ErrorMessage } from "../../utils/methods";

export interface UserContent {
  contentProposalElements: ContentProposalElement[];
  wsTaskCommentUuid: string;
}

interface ReplyToCommentData {
  wsTaskUuid: string;
  parentWsTaskCommentUuid: string | null;
  replyToCommentText: string;
  replyToCommentContent: ContentProposalElement[];
}

const contentInitialState = {
  contentProposalElements: [],
  wsTaskCommentUuid: "",
};

const replyToCommentDataInitialData = {
  wsTaskUuid: "",
  parentWsTaskCommentUuid: "",
  replyToCommentText: "",
  replyToCommentContent: [],
};

export interface CommentReducerState {
  currentEditedComment: string | null;
  userAcceptedContent: UserContent;
  userRejectedContent: UserContent;
  wsContentProposalCaptionElements: ContentProposalElementCaption[];
  wsContentProposalMediaElements: ContentProposalElementMedia[];
  wsContentProposalSummaryFilterStatus: ContentProposalElementStatus[];
  replyToCommentData: ReplyToCommentData;
  errorMessages: ErrorMessage[];
}

const initialState: CommentReducerState = {
  currentEditedComment: null,
  userAcceptedContent: contentInitialState,
  userRejectedContent: contentInitialState,
  wsContentProposalCaptionElements: [],
  wsContentProposalMediaElements: [],
  wsContentProposalSummaryFilterStatus: [],
  replyToCommentData: replyToCommentDataInitialData,
  errorMessages: [],
};

function commentReducer(
  state = initialState,
  action: any,
): CommentReducerState {
  switch (action.type) {
    case SET_COMMENT_AS_EDITABLE:
      const { commentId } = action.payload;

      return {
        ...state,
        currentEditedComment: commentId,
      };

    case SET_USER_ACCEPTED_CONTENT:
      const { acceptedContent }: { acceptedContent: UserContentProps } =
        action.payload;

      if (
        state.userAcceptedContent.wsTaskCommentUuid !==
        acceptedContent.wsTaskCommentUuid
      ) {
        return {
          ...state,
          userAcceptedContent: {
            wsTaskCommentUuid: acceptedContent.wsTaskCommentUuid,
            contentProposalElements: [acceptedContent.contentProposalElement],
          },
        };
      }

      const removedOrAddedAcceptedContentElement =
        removeOrAddContentElementToArray(
          state.userAcceptedContent.contentProposalElements,
          acceptedContent.contentProposalElement,
        );

      if (!removedOrAddedAcceptedContentElement.length) {
        return {
          ...state,
          userAcceptedContent: contentInitialState,
        };
      }

      return {
        ...state,
        userAcceptedContent: {
          ...state.userAcceptedContent,
          contentProposalElements: removedOrAddedAcceptedContentElement,
        },
      };

    case SET_USER_REJECTED_CONTENT:
      const { rejectedContent }: { rejectedContent: UserContentProps } =
        action.payload;

      if (
        state.userRejectedContent.wsTaskCommentUuid !==
        rejectedContent.wsTaskCommentUuid
      ) {
        return {
          ...state,
          userRejectedContent: {
            wsTaskCommentUuid: rejectedContent.wsTaskCommentUuid,
            contentProposalElements: [rejectedContent.contentProposalElement],
          },
        };
      }

      const removedOrAddedRejectedContentElement =
        removeOrAddContentElementToArray(
          state.userRejectedContent.contentProposalElements,
          rejectedContent.contentProposalElement,
        );

      if (!removedOrAddedRejectedContentElement.length) {
        return {
          ...state,
          userRejectedContent: contentInitialState,
        };
      }

      return {
        ...state,
        userRejectedContent: {
          ...state.userRejectedContent,
          contentProposalElements: removedOrAddedRejectedContentElement,
        },
      };

    case CLEAR_USER_ACCEPTED_CONTENT:
      return {
        ...state,
        userAcceptedContent: contentInitialState,
      };

    case CLEAR_USER_REJECTED_CONTENT:
      return {
        ...state,
        userRejectedContent: contentInitialState,
      };

    case GET_CONTENT_PROPOSAL_SUMMARY_SUCCESS:
      return {
        ...state,
        wsContentProposalCaptionElements:
          action?.payload?.data?.content?.caption ?? [],
        wsContentProposalMediaElements:
          action?.payload?.data?.content?.media ?? [],
      };

    case UPDATE_CONTENT_PROPOSAL_FILTER_STATUS:
      return {
        ...state,
        wsContentProposalSummaryFilterStatus: action.payload.status,
      };

    case CLEAR_CONTENT_PROPOSAL_FILTER_STATUS:
      return {
        ...state,
        wsContentProposalSummaryFilterStatus: [],
      };

    case SET_REPLY_TO_COMMENT_DATA:
      return {
        ...state,
        replyToCommentData: action.payload,
      };

    case CLEAR_REPLY_TO_COMMENT_DATA:
      return {
        ...state,
        replyToCommentData: replyToCommentDataInitialData,
      };

    default:
      return state;
  }
}

export default commentReducer;
