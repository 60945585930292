import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { toast } from "react-toastify";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as InfoIcon } from "src/images/info-grey.svg";
import { API_URLS } from "../../../../utils/API_URLS";
import Loader from "../../../components/Loader/Loader";
import { AudienceAgeAndGender } from "../../../Task/tabs/ProfileDataTab/single/AudienceAgeAndGender";
import { AudienceBrandAffinity } from "../../../Task/tabs/ProfileDataTab/single/AudienceBrandAffinity";
import { AudienceInterests } from "../../../Task/tabs/ProfileDataTab/single/AudienceInterests";
import { Credibility } from "../../../Task/tabs/ProfileDataTab/single/Credibility";
import { FemalePerAge } from "../../../Task/tabs/ProfileDataTab/single/FemalePerAge";
import { GeographicalReach } from "../../../Task/tabs/ProfileDataTab/single/GeographicalReach";
import { Languages } from "../../../Task/tabs/ProfileDataTab/single/Languages";
import { LocationByCity } from "../../../Task/tabs/ProfileDataTab/single/LocationByCity";
import { MalePerAge } from "../../../Task/tabs/ProfileDataTab/single/MalePerAge";
import { EmptyPublishersTab } from "../EmptyPublishersTab";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { EmptyAllCreatorsTab } from "../EmptyAllCreatorsTab";

const allCreators = {
  value: "all-creators",
  label: (
    <IDHFormattedMessage id="ws_all_creators" defaultMessage="All creators" />
  ),
};
const publishers = {
  value: "publishers",
  label: (
    <IDHFormattedMessage
      id="ws_only_publishers"
      defaultMessage="Only publishers"
    />
  ),
};
const defaultCreatorsType = allCreators;

function AudienceDataTab() {
  const [tabData, setTabData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [creatorsType, setCreatorsType] = useState(defaultCreatorsType);

  const { projectId } = useParams();

  const getTabData = async (type) => {
    setLoading(true);
    try {
      const url = `${API_URLS.getProjectAudienceData.replace(
        ":projectUuid:",
        projectId,
      )}?creatorsType=${type}`;

      const data = await axios.get(url);

      setTabData(data.data.content);
    } catch (err) {
      toast.error("Error on loading audience data.");
    }

    setLoading(false);
  };

  useEffect(() => {
    getTabData(creatorsType.value);
  }, [creatorsType]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="audience-data-tab">
      <div className="tab-filter">
        <div className="tab-filter__header">
          <IDHFormattedMessage
            id="ws_audience_data_tab_text"
            defaultMessage="Here you will find data about audience. Below you can select whether the data should apply to all the creators you have in your project or only those who published the content."
          />
        </div>
        <div className="tab-filter__info-bar">
          <InfoIcon />
          <span className="tab-filter__info-bar-text">
            <IDHFormattedMessage
              id="ws_audience_data_tab_info_bar"
              defaultMessage={`The given values have been calculated using a weighted average. They will slightly differ from the corresponding calculations shown in the "Overview" tab where a simple (arithmetic) mean is used for the calculations.`}
            />
          </span>
        </div>

        <div className="tab-filter__select">
          <CustomSelect
            value={creatorsType}
            onChange={(newValue) => {
              setCreatorsType(newValue);
            }}
            options={[allCreators, publishers]}
          />
        </div>
      </div>

      {tabData && (
        <div>
          {tabData.credibility && (
            <Credibility credibility={tabData.credibility} />
          )}
          <div className="audience-data-tab__grid">
            {tabData.audienceAgeGroups && (
              <AudienceAgeAndGender
                audienceAgeGroups={tabData.audienceAgeGroups}
                onProjectDetails
              />
            )}
            {tabData.audienceWomen && tabData.gendersPerAge?.female && (
              <FemalePerAge
                audienceWomen={tabData.audienceWomen}
                female={tabData.gendersPerAge?.female}
                onProjectDetails
              />
            )}
            {tabData.audienceMen && tabData.gendersPerAge?.male && (
              <MalePerAge
                audienceMen={tabData.audienceMen}
                male={tabData.gendersPerAge?.male}
                onProjectDetails
              />
            )}
            {tabData.audienceLanguages.length > 0 && (
              <Languages
                audienceLanguages={tabData.audienceLanguages}
                onProjectDetails
              />
            )}
            {tabData.audienceGeographicalReach && (
              <GeographicalReach
                audienceGeographicalReach={tabData.audienceGeographicalReach}
                onProjectDetails
              />
            )}
            {tabData.audienceCityReach.length > 0 && (
              <LocationByCity
                audienceCityReach={tabData.audienceCityReach}
                onProjectDetails
              />
            )}
          </div>

          <div className="audience-data-tab__second-grid">
            {tabData.audienceInterests.length > 0 && (
              <AudienceInterests
                audienceInterests={tabData.audienceInterests}
                onProjectDetails
              />
            )}
            {tabData.brandAffinity.length > 0 && (
              <AudienceBrandAffinity
                brandAffinity={tabData.brandAffinity}
                onProjectDetails
              />
            )}
          </div>
        </div>
      )}

      {!tabData && creatorsType.value === allCreators.value && (
        <EmptyAllCreatorsTab />
      )}
      {!tabData && creatorsType.value === publishers.value && (
        <EmptyPublishersTab />
      )}
    </div>
  );
}

export { AudienceDataTab };
