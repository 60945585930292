import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import "./LanguageSelector.scss";

import { SelectOption } from "src/app/modals/ManageFieldModal/SocialMetrics";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import CustomSelect from "../CustomSelect/CustomSelect";

import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";

import { ReactComponent as EnglishIcon } from "../../../images/languages/english.svg";
import { ReactComponent as PolishIcon } from "../../../images/languages/polish.svg";

const options = [
  {
    label: (
      <div className="language-selector__item">
        <EnglishIcon />
        <span className="language-selector__item-original">English</span>
        <span className="language-selector__item-translation">
          <IDHFormattedMessage id="ws_english" defaultMessage="English" />
        </span>
      </div>
    ),
    value: "en_GB",
  },
  {
    label: (
      <div className="language-selector__item">
        <PolishIcon />
        <span className="language-selector__item-original">Polski</span>
        <span className="language-selector__item-translation">
          <IDHFormattedMessage id="ws_polish" defaultMessage="Polski" />
        </span>
      </div>
    ),
    value: "pl_PL",
  },
];

interface Props {
  selectedLanguage: SelectOption;
  setSelectedLanguage: Dispatch<SetStateAction<SelectOption>>;
}

export const LanguageSelector: React.FC<Props> = (props) => {
  const { selectedLanguage, setSelectedLanguage } = props;

  const {
    mainReducer: { locale, supportedLanguages },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (!locale) return;

    const language = options.find((item) => item.value === locale);

    if (language) {
      setSelectedLanguage(language);
    }
  }, [locale]);

  return (
    <CustomSelect
      value={selectedLanguage}
      options={options.filter((item) =>
        supportedLanguages.find((lang: string) => lang === item.value),
      )}
      onChange={(newValue: SelectOption) => setSelectedLanguage(newValue)}
    />
  );
};
