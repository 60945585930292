import React from "react";
import "./ExtensionsDropdown.scss";

import { useDispatch, useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "src/app/components/Button/Button";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import { AutomationsSection } from "src/app/SettingsModal/tabs/Automations/Automations";

import { useHistory } from "react-router";
import { SettingsContext, SettingsTab } from "src/app/SettingsModal/enums";
import { openSettingsModal } from "src/app/SettingsModal/methods";
import { ExtensionsSection } from "src/app/SettingsModal/tabs/Extensions/Extensions";
import { setEditedAutomationData } from "src/redux/automation/automationActions";
import { AppLocation } from "src/redux/main/mainReducer";
import { RootState } from "src/redux/reducers";
import { WsExtension, ExtensionTypeEnum } from "src/types";
import { ReactComponent as LightningIcon } from "../../../images/lightning.svg";
import AutomationsEnum from "../../SettingsModal/tabs/Automations/AutomationsEnum";
import { ProjectPermissionsEnum } from "../../../utils/PermissionsEnums";

interface Props {
  location: AppLocation;
}

export const ExtensionsDropdown: React.FC<Props> = (props) => {
  const { location } = props;

  const {
    projectReducer: { projectBasicData },
  } = useSelector((state: RootState) => state);

  const history = useHistory();
  const dispatch = useDispatch();

  const enabledAutomations = projectBasicData?.wsAutomations.filter(
    (a) => a.enabled,
  );
  const enabledExtensions = projectBasicData?.wsExtensions.filter(
    (e) => e?.enabled,
  );

  if (!enabledAutomations?.length && !enabledExtensions?.length) return null;

  const creatorDetectionList = enabledAutomations?.filter(
    (a) =>
      a.wsAutomationName === AutomationsEnum.CreatorDetectionRunnableAutomation,
  );
  const publicationDetectionList = enabledAutomations?.filter(
    (a) =>
      a.wsAutomationName ===
      AutomationsEnum.PublicationDetectionRunnableAutomation,
  );
  const publicationSynchronizationList = enabledAutomations?.filter(
    (a) =>
      a.wsAutomationName ===
      AutomationsEnum.PublicationSynchronizationRunnableAutomation,
  );

  const openAutomations = (
    section: AutomationsSection,
    automationData?: any,
  ) => {
    const manageAutomations = projectBasicData?.permissions?.project?.includes(
      ProjectPermissionsEnum.MANAGE_AUTOMATIONS,
    );

    const automationsSection = manageAutomations
      ? section
      : AutomationsSection.AutomationsMain;

    if (automationData) {
      dispatch(setEditedAutomationData(automationData));
    }

    openSettingsModal(
      history,
      location,
      SettingsContext.Project,
      SettingsTab.Automations,
      automationsSection,
    );
  };

  const openExtensionRecruitmentFormDetails = () => {
    openSettingsModal(
      history,
      location,
      SettingsContext.Project,
      SettingsTab.Extensions,
      ExtensionsSection.RecruitmentForm,
    );
  };

  const openExtensionsList = () => {
    openSettingsModal(
      history,
      location,
      SettingsContext.Project,
      SettingsTab.Extensions,
      ExtensionsSection.ExtensionsList,
    );
  };

  const renderExtensionsListOptions = (
    extensionList: WsExtension[] | undefined,
  ) => {
    if (!extensionList?.length) return;

    return (
      <>
        <div className="extensions-dropdown__subheader">
          <IDHFormattedMessage id="ws_extensions" defaultMessage="Extensions" />
        </div>
        {extensionList.map((extensionData: WsExtension, i) => {
          switch (extensionData.type) {
            case ExtensionTypeEnum.RecruitmentForm:
              return (
                <DropdownMenuItem
                  onClick={openExtensionRecruitmentFormDetails}
                  key={i}
                >
                  <IDHFormattedMessage
                    id="ws_recruitment_form"
                    defaultMessage="Recruitment form"
                  />
                </DropdownMenuItem>
              );
            case ExtensionTypeEnum.WsProjectLock:
              return (
                <DropdownMenuItem onClick={openExtensionsList} key={i}>
                  <IDHFormattedMessage
                    id="ws_lock_project_editing_extension"
                    defaultMessage="Lock project editing"
                  />
                </DropdownMenuItem>
              );
            default:
              return null;
          }
        })}
      </>
    );
  };

  const renderAutomationsOptions = () => {
    if (!enabledAutomations?.length) return;

    return (
      <>
        <div className="extensions-dropdown__subheader">
          <IDHFormattedMessage
            id="ws_automations"
            defaultMessage="Automations"
          />
        </div>

        {(creatorDetectionList?.length ?? 0) > 0 && (
          <DropdownMenuItem
            onClick={() => openAutomations(AutomationsSection.AutomationsMain)}
          >
            <IDHFormattedMessage
              id="ws_creators_detection"
              defaultMessage="Creators detection"
            />
          </DropdownMenuItem>
        )}

        {(publicationDetectionList?.length ?? 0) > 0 && (
          <DropdownMenuItem
            onClick={() =>
              openAutomations(AutomationsSection.PublicationsDetection)
            }
          >
            <IDHFormattedMessage
              id="ws_publication_detection"
              defaultMessage="Publication detection"
            />
          </DropdownMenuItem>
        )}

        {(publicationSynchronizationList?.length ?? 0) > 0 && (
          <DropdownMenuItem
            onClick={() =>
              openAutomations(AutomationsSection.PublicationsSynchronization)
            }
          >
            <IDHFormattedMessage
              id="ws_publication_synchronization"
              defaultMessage="Publications synchronization"
            />
          </DropdownMenuItem>
        )}
      </>
    );
  };

  if (
    (!enabledAutomations?.length && !enabledExtensions?.length) ||
    !projectBasicData?.permissions?.project?.includes(
      "display_project_configuration",
    )
  )
    return null;

  return (
    <Dropdown className="extensions-dropdown" toRight>
      {(isOpen, setIsOpen) => (
        <>
          <Button
            variant="white"
            size="small"
            textWithIcon
            onClick={() => setIsOpen(true)}
          >
            <LightningIcon />
            {enabledAutomations?.length ?? 0 + (enabledExtensions?.length ?? 0)}
          </Button>

          <DropdownMenu isOpen={isOpen}>
            {renderAutomationsOptions()}
            {renderExtensionsListOptions(enabledExtensions)}
          </DropdownMenu>
        </>
      )}
    </Dropdown>
  );
};
