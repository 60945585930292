function charAt(string: string, index: number) {
  const first = string.charCodeAt(index);
  let second;
  if (first >= 0xd800 && first <= 0xdbff && string.length > index + 1) {
    second = string.charCodeAt(index + 1);
    if (second >= 0xdc00 && second <= 0xdfff) {
      return string.substring(index, index + 2);
    }
  }
  return string[index];
}

function slice(string: string, start: number, end: number) {
  let accumulator = "";
  let character;
  let stringIndex = 0;
  let unicodeIndex = 0;
  const { length } = string;

  while (stringIndex < length) {
    character = charAt(string, stringIndex);
    if (unicodeIndex >= start && unicodeIndex < end) {
      accumulator += character;
    }
    stringIndex += character.length;
    unicodeIndex += 1;
  }
  return accumulator;
}

function toNumber(value: number | undefined, fallback: number) {
  if (value === undefined) {
    return fallback;
  }
  return Number(value);
}

export default function multiByteSlice(
  string: string,
  start: number,
  end: number,
) {
  const realStart = toNumber(start, 0);
  const realEnd = toNumber(end, string.length);
  if (realEnd === realStart) {
    return "";
  }
  if (realEnd > realStart) {
    return slice(string, realStart, realEnd);
  }
  return slice(string, realEnd, realStart);
}
