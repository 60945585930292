import React from "react";
import "./LocationByCityList.scss";

import Flag from "../../../../../components/Flag/Flag";

function LocationByCityList(props) {
  const { data, height } = props;

  const getData = () => {
    switch (height) {
      case 3:
        return data?.slice(0, 4);

      default:
        return data;
    }
  };

  return (
    <div className="location-list">
      {getData()?.map((item, index) => (
        <div className="location-list__item" key={index}>
          <div className="location-list__label">
            <Flag countryCode={item.countryCode} />
            {item.name}
          </div>
          <div className="location-list__value">{item.percent}%</div>
        </div>
      ))}
    </div>
  );
}

export { LocationByCityList };
