import { addSpaceIfNeeded } from "./addSpaceIfNeeded";

export const addMetaFieldToLayout = (
  field,
  newLayout,
  newSeparators,
  format,
) => {
  const lastEl = addSpaceIfNeeded(field.type, newLayout, format);

  let x = 0;
  let y = 0;
  let w = 1;
  let h = 1;

  // full width
  if (field.type === "text") {
    x = 0;
    y = lastEl.y + lastEl.h;
    w = format.cols;
    h = 2;
  }
  // first in row and half the width
  else if (
    (lastEl.x === format.cols / 2 && lastEl.w === format.cols / 2) ||
    lastEl.w === format.cols
  ) {
    x = 0;
    y = lastEl.y + lastEl.h;
    w = format.cols / 2;
  }
  // second in row and half the width
  else if (lastEl.x === 0 && lastEl.w === format.cols / 2) {
    x = format.cols / 2;
    y = lastEl.y;
    w = format.cols / 2;
  }

  const key = field.label;
  newLayout.push({ i: key, x, y, w, h });
};
