import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import classNames from "classnames";
import { TaskType } from "src/types";
import { RootState } from "src/redux/reducers";
import { ReactComponent as PlusIcon } from "../../images/plus-transparent.svg";
import { ReactComponent as PlusAltIcon } from "../../images/plus-alt.svg";
import { ReactComponent as SettingsIcon } from "../../images/settings-v.svg";
import placeholderIcon from "../../images/placeholder-image.svg";
import publicationPlaceholder from "../../images/publication-placeholder.svg";
import projectPlaceholder from "../../images/project-placeholder.svg";
import { ReactComponent as OpenNewCardIcon } from "../../images/share-alt.svg";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "../components/Dropdown/Dropdown";
import Skeleton, { SkeletonCircle } from "../components/Skeleton/Skeleton";
import { changeTaskMetaFieldValue, setShowTask } from "../../redux";
import { API_URLS } from "../../utils/API_URLS";
import Check from "../components/Check/Check";
import { Metadata } from "../Task/Subtask/Subtask";
import { Button } from "../components/Button/Button";
import AddSocialProfilesModal from "../modals/AddSocialProfilesModal/AddSocialProfilesModal";
import SocialProfileIcons from "../components/IconsWithStatus/SocialProfileIcons/SocialProfileIcons";
import CustomImage from "../components/CustomImage/CustomImage";
import { tableDataType, taskAvatarShape } from "../components/Table/Table";
import UpdatePublicationLinkModal from "../modals/UpdatePublicationLinkModal/UpdatePublicationLinkModal";
import OuterLink from "../components/OuterLink/OuterLink";
import { TASK_TYPES } from "../../utils/variables";
import { getImageSource } from "../../utils/methods";
import ImageCropModal from "../components/CroppedImageUploader/ImageCropModal/ImageCropModal";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { useSetCoverImageFile } from "../components/CroppedImageUploader/utils/useUploadCoverImage";
import { DropdownPopper } from "../components/DropdownPopper/DropdownPopper";

interface Props {
  item: any;
  disableLink?: boolean;
  parentLoading?: boolean;
  updateData?: () => void;
  taskAvatarShape: taskAvatarShape;
  showPane?: () => void;
  dataType: tableDataType;
  singleRow?: boolean;
}

export const ProjectOrTaskName: React.FC<Props> = (props) => {
  const { item, disableLink, parentLoading, updateData, dataType, singleRow } =
    props;

  const params = useParams<{ projectId: string }>();
  const dispatch = useDispatch();

  const [coverImageUrl, setCoverImageUrl] = useState<any>(
    getImageSource(item?.cover, "tiny"),
  );
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [coverImageId, setCoverImageId] = useState<number>(item?.cover?.id);
  const [showAddSocialProfilesModal, setShowAddSocialProfilesModal] =
    useState(false);
  const [showUpdatePublicationLinkModal, setShowUpdatePublicationLinkModal] =
    useState<boolean>(false);
  const [showUploadLogoModal, setShowUploadLogoModal] =
    useState<boolean>(false);
  const { coverImageFile, setNewImage } = useSetCoverImageFile();

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const dropdownRef = useRef(null);

  useEffect(() => {
    if (coverImageFile) {
      uploadMedia(coverImageFile);
    }
  }, [coverImageFile]);

  useEffect(() => {
    const src = getImageSource(item?.cover, "tiny");

    if (item.cover === null) {
      setCoverImageUrl(null);
    } else if (src && src !== coverImageUrl) {
      setCoverImageUrl(src);
      setCoverImageId(item.cover.id);
    }
  }, [item.cover]);

  useEffect(() => {
    const completed = item.metadata.find(
      (field: Metadata) => field.key === "completed",
    );

    if (completed) {
      setIsCompleted(completed.value);
    }
  }, [item]);

  const setTaskCompleted = () => {
    const completed = item.metadata.find(
      (field: Metadata) => field.key === "completed",
    );

    dispatch(
      changeTaskMetaFieldValue(
        item.projectId || item.taskId,
        completed.uuid,
        !isCompleted,
      ),
    );
  };

  const isProject = () => {
    return !isTask();
  };

  const isTask = () => {
    return TASK_TYPES.includes(item?.taskType);
  };

  const getPresignedRequestToUploadAsset = (wsObjectUuid: string) => {
    const presignedRequestUrl = new URL(
      isProject()
        ? API_URLS.presignedRequestForProjectCover
        : API_URLS.presignedRequestForTaskCover,
    );

    if (isProject()) {
      presignedRequestUrl.searchParams.append("wsProjectUuid", wsObjectUuid);
    }

    if (isTask()) {
      presignedRequestUrl.searchParams.append("wsTaskUuid", wsObjectUuid);
      presignedRequestUrl.searchParams.append("name", "cover");
    }

    presignedRequestUrl.searchParams.append(
      "wsWorkspaceUuid",
      activeWorkspaceUuid,
    );

    return presignedRequestUrl.toString();
  };

  const uploadMedia = (coverImageFile: File) => {
    const wsTaskUuid = item.projectId || item.taskId;

    setCoverImageUrl(null);
    setLoading(true);

    axios
      .get(getPresignedRequestToUploadAsset(wsTaskUuid))
      .then(function (response) {
        const {
          requestInputs,
          requestAttributes: { action },
        } = response.data.content;

        axios
          .post(action, getFormDataToUploadS3(coverImageFile, requestInputs))
          .then(function (response) {
            if (response.status === 201) {
              const parentUuid = item.projectId || item.taskId;
              const parentType =
                item.parentType === "ws_task" ? "ws_task" : "ws_project";

              const requestInputsKeyArray = requestInputs.key.split("/");
              const realName =
                requestInputsKeyArray[requestInputsKeyArray.length - 1];

              axios
                .post(API_URLS.createAsset, {
                  wsWorkspaceUuid: activeWorkspaceUuid,
                  parentUuid,
                  parentType,
                  name: coverImageFile.name,
                  realName,
                  mimeType: coverImageFile.type,
                  size: coverImageFile.size,
                  isUpdatingCoverTask: true,
                })
                .then(function (response) {
                  if (updateData) {
                    updateData();
                  }
                  setLoading(false);
                  setCoverImageUrl(response.data.content.url);
                  setCoverImageId(response.data.content.id);
                });
            }
          })
          .catch(function (error) {
            console.error(error.response?.data?.content?.errors);
            setLoading(false);
          });
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const getFormDataToUploadS3 = (file: File, requestInputs: any) => {
    const formData = new FormData();

    formData.set("key", requestInputs.key);
    formData.set("acl", requestInputs.acl);
    formData.set("X-Amz-Credential", requestInputs["X-Amz-Credential"]);
    formData.set("X-Amz-Algorithm", requestInputs["X-Amz-Algorithm"]);
    formData.set("X-Amz-Date", requestInputs["X-Amz-Date"]);
    formData.set("Policy", requestInputs.Policy);
    formData.set("X-Amz-Signature", requestInputs["X-Amz-Signature"]);
    formData.set("success_action_status", requestInputs.success_action_status);
    formData.set("file", file);

    return formData;
  };

  const removeCoverPhoto = () => {
    axios
      .delete(`${API_URLS.deleteAsset}/${activeWorkspaceUuid}/${coverImageId}`)
      .then(function () {
        setCoverImageUrl(null);
      })
      .catch(function (erorr) {});
  };

  const openSocialMediaModal = (e: any) => {
    e.stopPropagation();
    setShowAddSocialProfilesModal(true);
  };

  const openUpdatePublicationLinkModal = (e: any) => {
    // TODO: check why here is stopPropagation
    e.stopPropagation();
    setShowUpdatePublicationLinkModal(true);
  };

  const getPlaceholderImage = () => {
    if (coverImageUrl) {
      return coverImageUrl;
    }

    if (item.taskType === TaskType.Publication) {
      return publicationPlaceholder;
    }

    if (isProject()) {
      return projectPlaceholder;
    }

    if (isTask()) {
      return placeholderIcon;
    }

    return placeholderIcon;
  };

  return (
    <div className="ws-table__content">
      {showUploadLogoModal && (
        <ImageCropModal
          circle={props.taskAvatarShape === taskAvatarShape.Circle}
          uploadFunction={setNewImage}
          onClose={() => setShowUploadLogoModal(false)}
        />
      )}
      <div className="ws-table__row-info">
        {parentLoading || loading ? (
          <>
            {item.taskType === TaskType.Content ? (
              <Skeleton width={48} height={48} borderRadius={8} />
            ) : (
              <SkeletonCircle size={48} />
            )}
          </>
        ) : item.taskType !== "action" && item.taskType !== "payment" ? (
          <div
            className={classNames("ws-table__image", {
              "ws-table__image--square": item.taskType === TaskType.Content,
            })}
          >
            <img
              className={classNames("ws-table__image-cover", {
                "ws-table__image-cover--circle":
                  props.taskAvatarShape === taskAvatarShape.Circle,
                "ws-table__image-cover--square":
                  props.taskAvatarShape === taskAvatarShape.Square,
              })}
              loading="lazy"
              src={getPlaceholderImage()}
              alt=""
            />

            {!coverImageUrl ? (
              <div
                className="ws-table__image-file"
                onClick={() => setShowUploadLogoModal(true)}
              >
                <div
                  className={classNames("ws-table__image-icon", {
                    "ws-table__image-icon--circle":
                      props.taskAvatarShape === taskAvatarShape.Circle,
                    "ws-table__image-icon--square":
                      props.taskAvatarShape === taskAvatarShape.Square,
                  })}
                >
                  <PlusIcon />
                </div>
              </div>
            ) : (
              // @ts-ignore
              <DropdownPopper
                overlay={
                  <DropdownMenu isOpen>
                    <DropdownMenuItem onClick={removeCoverPhoto}>
                      <IDHFormattedMessage
                        id="ws_remove_cover_photo"
                        defaultMessage="Remove cover photo"
                      />
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="image-input"
                      onClick={() => setShowUploadLogoModal(true)}
                    >
                      <IDHFormattedMessage
                        id="ws_change_cover_photo"
                        defaultMessage="Change cover photo"
                      />
                    </DropdownMenuItem>
                  </DropdownMenu>
                }
                ref={dropdownRef}
              >
                <div
                  className={classNames("ws-table__image-icon", {
                    "ws-table__image-icon--circle":
                      props.taskAvatarShape === taskAvatarShape.Circle,
                    "ws-table__image-icon--square":
                      props.taskAvatarShape === taskAvatarShape.Square,
                  })}
                >
                  <SettingsIcon />
                </div>
              </DropdownPopper>
            )}
          </div>
        ) : (
          <Check
            isCheck={isCompleted}
            setIsCheck={(value) => {
              setIsCompleted(value);
              setTaskCompleted();
            }}
          />
        )}
        {parentLoading ? (
          <Skeleton width={80} height={15} variant="text" marginLeft={12} />
        ) : !disableLink ? (
          <Link
            to={{
              pathname: `/workspace/${activeWorkspaceUuid}/projects/${
                item.projectId
              }${singleRow ? "/details" : ""}`,
              search: "taskType=creator",
            }}
            data-project-name={item.projectName}
          >
            <div
              key={item.projectName}
              className="ws-table__name ws-table__name--clickable"
            >
              {item.projectName}
            </div>
          </Link>
        ) : (
          <Link
            to={`/workspace/${activeWorkspaceUuid}/projects/${params.projectId}/${item.projectId}`}
            className={classNames("ws-table__name", {
              "ws-table__name--with-tick":
                item.taskType === TaskType.Action ||
                item.taskType === TaskType.Payment,
            })}
            onClick={() => dispatch(setShowTask(true))}
          >
            <div className="ws-table__task-name"> {item.projectName}</div>
            {item.taskType === TaskType.Creator && (
              <div>
                {item.socialProfiles.length ? (
                  <SocialProfileIcons data={item.socialProfiles} />
                ) : (
                  <Button
                    variant="bare"
                    size="small"
                    onClick={openSocialMediaModal}
                  >
                    <PlusAltIcon />
                    <IDHFormattedMessage
                      id="ws_add_social_profiles"
                      defaultMessage="Add social profiles"
                    />
                  </Button>
                )}
              </div>
            )}

            {item.taskType === TaskType.Publication &&
              (!item.publication || item.publication.url === null) && (
                <Button
                  variant="bare"
                  size="small"
                  onClick={openUpdatePublicationLinkModal}
                >
                  <PlusAltIcon />
                  <IDHFormattedMessage
                    id="ws_add_link"
                    defaultMessage="Add link"
                  />
                </Button>
              )}
          </Link>
        )}
        {item.publication && item.publication.url !== null && (
          <OuterLink
            href={item.publication.url}
            className="ws-table__open-new-card"
          >
            <OpenNewCardIcon />
          </OuterLink>
        )}
      </div>

      {showAddSocialProfilesModal && (
        <AddSocialProfilesModal
          objectType="task"
          onClose={() => setShowAddSocialProfilesModal(false)}
          name={item.projectName}
          taskId={item.projectId}
          socialProfiles={item.socialProfiles}
        />
      )}

      {showUpdatePublicationLinkModal && (
        <UpdatePublicationLinkModal
          onClose={() => setShowUpdatePublicationLinkModal(false)}
          taskId={item.projectId}
        />
      )}
    </div>
  );
};
