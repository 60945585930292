import { SocialProvider } from "../../../../types";

export const addMetricsToLayout = (metric, newLayout, format) => {
  const lastRow = Math.max(...newLayout.map((o) => o.y));
  const lastEl = newLayout.find((item) => item.y === lastRow);
  const currentRow = lastEl.y + lastEl.h;
  const rowDifference = currentRow % format.rows;
  const newRow = rowDifference
    ? currentRow + format.rows - rowDifference
    : currentRow;

  switch (format.rows) {
    case 7:
      addFieldsEvery7Rows(metric, newRow, newLayout);
      break;
    case 18:
      addFieldsEvery12Rows(metric, newRow, newLayout);
      break;
  }
};

const addFieldsEvery7Rows = (metric, newRow, newLayout) => {
  // first row
  newLayout.push({ i: `${metric.label}-bio`, x: 0, y: newRow, w: 6, h: 2 });
  newLayout.push({
    i: `${metric.label}-followers`,
    x: 0,
    y: newRow + 2,
    w: 2,
    h: 1,
  });
  newLayout.push({
    i: `${metric.label}-er`,
    x: 0,
    y: newRow + 3,
    w: 2,
    h: 1,
  });
  if (metric.label === SocialProvider.Instagram) {
    newLayout.push({
      i: `${metric.label}-credibility`,
      x: 0,
      y: newRow + 4,
      w: 2,
      h: 1,
    });
  }
  newLayout.push({
    i: `${metric.label}-recently-published`,
    x: 2,
    y: newRow + 2,
    w: 4,
    h: 4,
  });

  // second-row
  newLayout.push({
    i: `${metric.label}-audience-age`,
    x: 0,
    y: newRow + 7,
    w: 2,
    h: 3,
  });
  newLayout.push({
    i: `${metric.label}-female-per-age`,
    x: 2,
    y: newRow + 7,
    w: 2,
    h: 3,
  });
  newLayout.push({
    i: `${metric.label}-male-per-age`,
    x: 4,
    y: newRow + 7,
    w: 2,
    h: 3,
  });
  newLayout.push({
    i: `${metric.label}-languages`,
    x: 0,
    y: newRow + 10,
    w: 2,
    h: 3,
  });
  newLayout.push({
    i: `${metric.label}-geographical-reach`,
    x: 2,
    y: newRow + 10,
    w: 2,
    h: 3,
  });
  if (metric.label === SocialProvider.Instagram) {
    newLayout.push({
      i: `${metric.label}-location-by-city`,
      x: 4,
      y: newRow + 10,
      w: 2,
      h: 3,
    });
  }
  // newLayout.push({
  //   i: `${metric.label}-creator-interests`,
  //   x: 0,
  //   y: newRow + 14,
  //   w: 2,
  //   h: 3,
  // });
  // newLayout.push({
  //   i: `${metric.label}-creators-brand-affinity`,
  //   x: 2,
  //   y: newRow + 14,
  //   w: 2,
  //   h: 3,
  // });
  // newLayout.push({
  //   i: `${metric.label}-audience-interests`,
  //   x: 4,
  //   y: newRow + 14,
  //   w: 2,
  //   h: 3,
  // });
  // newLayout.push({
  //   i: `${metric.label}-audience-brand-affinity`,
  //   x: 0,
  //   y: newRow + 17,
  //   w: 2,
  //   h: 3,
  // });
  // newLayout.push({
  //   i: `${metric.label}-hashtags`,
  //   x: 2,
  //   y: newRow + 17,
  //   w: 2,
  //   h: 3,
  // });
  // newLayout.push({
  //   i: `${metric.label}-keywords`,
  //   x: 4,
  //   y: newRow + 17,
  //   w: 2,
  //   h: 3,
  // });
};

const addFieldsEvery12Rows = (metric, newRow, newLayout) => {
  newLayout.push({ i: `${metric.label}-bio`, x: 0, y: newRow, w: 6, h: 2 });
  newLayout.push({
    i: `${metric.label}-followers`,
    x: 0,
    y: newRow + 2,
    w: 2,
    h: 1,
  });
  newLayout.push({
    i: `${metric.label}-er`,
    x: 0,
    y: newRow + 3,
    w: 2,
    h: 1,
  });
  if (metric.label === SocialProvider.Instagram) {
    newLayout.push({
      i: `${metric.label}-credibility`,
      x: 0,
      y: newRow + 4,
      w: 2,
      h: 1,
    });
  }
  newLayout.push({
    i: `${metric.label}-recently-published`,
    x: 2,
    y: newRow + 2,
    w: 4,
    h: 3,
  });

  newLayout.push({
    i: `${metric.label}-audience-age`,
    x: 0,
    y: newRow + 5,
    w: 2,
    h: 3,
  });
  newLayout.push({
    i: `${metric.label}-female-per-age`,
    x: 2,
    y: newRow + 5,
    w: 2,
    h: 3,
  });
  newLayout.push({
    i: `${metric.label}-male-per-age`,
    x: 4,
    y: newRow + 5,
    w: 2,
    h: 3,
  });
  newLayout.push({
    i: `${metric.label}-languages`,
    x: 0,
    y: newRow + 8,
    w: 2,
    h: 3,
  });
  newLayout.push({
    i: `${metric.label}-geographical-reach`,
    x: 2,
    y: newRow + 8,
    w: 2,
    h: 3,
  });
  if (metric.label === SocialProvider.Instagram) {
    newLayout.push({
      i: `${metric.label}-location-by-city`,
      x: 4,
      y: newRow + 8,
      w: 2,
      h: 3,
    });
  }
};
