import React from "react";
import "./ProjectsDataTableRow.scss";
import classNames from "classnames";

export function ProjectsDataTableRow(props) {
  return (
    <tr
      className={classNames([
        "projects-data-table__row",
        props.isHeader && "projects-data-table__row--header",
      ])}
      {...props}
      data-project-name={
        props.children[0].props.cell?.row.original.projectName.props.project
          .projectName
      }
      key={
        props.children[0].props.cell?.row.original.projectName.props.project
          .projectName || "headerRow"
      }
    />
  );
}
