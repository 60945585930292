import React from "react";
import "./PostInfo.scss";
import { Link } from "react-router-dom";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import OuterLink from "../../components/OuterLink/OuterLink";
import METHODS from "../../helpers/methods";
import CONSTS from "../../helpers/consts";
import { postType } from "../../helpers/postColumnsConst";

import SocialProviderIcon from "../../components/SocialProviderIcon/SocialProviderIcon";

function PostInfo({
  post,
  campaignNameVisibility,
  type,
  hideFollowers,
  profileDataVisibilityEnabled,
  campaignGroupId,
}) {
  return (
    <div className="post-info">
      <OuterLink href={post.socialProfileUrl} className="post-info__username">
        <SocialProviderIcon
          universal={post.cpconExternalContent}
          cpcon={post.cpcon}
          provider={post.socialProvider}
          user
          big
        />
        <span>{post.socialUserName}</span>
      </OuterLink>

      <div className="post-info__middle">
        {!hideFollowers && (
          <div className="post-info__followers">
            {post.socialProvider === CONSTS.socialProvider.tiktok &&
            type === postType.published
              ? METHODS.splitNumbers(post.followersAfterPublish)
              : METHODS.splitNumbers(post.followersCount)}
          </div>
        )}

        {profileDataVisibilityEnabled && (
          <Link
            to={`/brand-dashboard/profile/campaign-group/${campaignGroupId}/${post.userHashId}`}
            className="influencer-info__link-to-profile"
          >
            <IDHFormattedMessage
              id="front_profile_data"
              defaultMessage="Profile data"
            />
          </Link>
        )}
      </div>

      {campaignNameVisibility && (
        <div className="post-info__campaign">{post.campaignName}</div>
      )}
    </div>
  );
}

export default PostInfo;
