import { API_URLS } from "src/utils/API_URLS";
import { GlobalMetaFields } from "src/app/SettingsModal/tabs/Extensions/components/MetaFieldMembers/GlobalMetaFieldList";
import { WsExtension } from "src/types";
import * as actionTypes from "./extensionTypes";

export function getExtensionList(projectId: string) {
  const url = API_URLS.getProjectExtensionsList.replace(
    ":projectUuid:",
    projectId,
  );

  return {
    type: actionTypes.GET_EXTENSION_LIST,
    payload: {
      request: { url },
    },
  };
}

export function getWorkspaceExtensionList(wsWorkspaceUuid: string) {
  const url = API_URLS.getWorkspaceExtensionsList.replace(
    ":wsWorkspaceUuid:",
    wsWorkspaceUuid,
  );

  return {
    type: actionTypes.GET_WORKSPACE_EXTENSION_LIST,
    payload: {
      request: { url },
    },
  };
}

export function setExtensionList(extensionList: WsExtension[]) {
  return { type: actionTypes.SET_EXTENSION_LIST, payload: { extensionList } };
}

export function setWorkspaceExtensionList(extensionList: WsExtension[]) {
  return {
    type: actionTypes.SET_WORKSPACE_EXTENSIONS_LIST,
    payload: { extensionList },
  };
}

export function clearExtensionList() {
  return {
    type: actionTypes.CLEAR_EXTENSION_LIST,
  };
}

export function setGlobalMetaFieldList(
  globalMetaFieldList: GlobalMetaFields[],
) {
  return {
    type: actionTypes.SET_GLOBAL_TASK_META_VALUE_LIST,
    payload: { globalMetaFieldList },
  };
}

export function setIsGlobalMetaFieldListLoading(loading: boolean) {
  return {
    type: actionTypes.SET_IS_GLOBAL_TASK_META_VALUE_LIST_LOADING,
    payload: { loading },
  };
}

export function getAvailableProjectMetaFields(wsProjectUuid: string) {
  const url = API_URLS.getAvailableFields.replace(
    ":projectUuid:",
    wsProjectUuid,
  );
  return {
    type: actionTypes.GET_AVAILABLE_PROJECT_META_FIELDS,
    payload: {
      request: { url },
    },
  };
}

export function getAvailableWorkspaceGlobalTaskMetaFields(
  wsWorkspaceUuid: string,
) {
  const url = API_URLS.getWorkspaceAvailableFields.replace(
    ":wsWorkspaceUuid:",
    wsWorkspaceUuid,
  );
  return {
    type: actionTypes.GET_AVAILABLE_WORKSPACE_GLOBAL_TASK_META_FIELDS,
    payload: {
      request: { url },
    },
  };
}

export function clearAvailableProjectMetaFields() {
  return {
    type: actionTypes.CLEAR_AVAILABLE_PROJECT_META_FIELDS,
  };
}

export function clearAvailableWorkspaceGlobalTaskMetaFields() {
  return {
    type: actionTypes.CLEAR_AVAILABLE_WORKSPACE_GLOBAL_TASK_META_FIELDS,
  };
}
