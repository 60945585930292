import { DateObject } from "src/app/project/Project";

interface CommentBase {
  author: string;
  comment: string;
  edited: DateObject;
}

export interface IRegularComment extends CommentBase {
  commentId: string;
  taskId: string;
  type: CommentType.Comment;
  replyWsTaskCommentUuid: string | null;
  replyWsTaskComment: Comment | null;
  attachments: any;
}

export enum ContentProposalElementStatus {
  Fresh = "fresh",
  Accepted = "accepted",
  Rejected = "rejected",
}

export enum ContentProposalElementType {
  Media = "media",
  Caption = "caption",
  ExternalUrl = "externalUrl",
}

export enum CommentType {
  ContentProposal = "contentProposal",
  Comment = "comment",
  ContentProposalElementModeration = "contentProposalModeration",
}

interface ContentProposalElementBase {
  uuid: string;
  wsTaskUuid: string;
  wsTaskCommentUuid: string;
  status: ContentProposalElementStatus;
  created: DateObject;
}

export interface ContentProposalElementCaption
  extends ContentProposalElementBase {
  caption: string;
  type: ContentProposalElementType.Caption;
}

export type ContentProposalElementMedia =
  | ContentProposalElementMediaType
  | ContentProposalElementExternalUrlType;

interface ContentProposalElementExternalUrlType
  extends ContentProposalElementBase {
  caption: null;
  externalUrl: string;
  mimeType: null;
  name: null;
  type: ContentProposalElementType.ExternalUrl;
}

export interface ContentProposalElementMediaType
  extends ContentProposalElementBase {
  name: string;
  publicUrl: string;
  mimeType: string;
  downloadUrl: string;
  type: ContentProposalElementType.Media;
  thumbnails: { big: string | null; small: string | null; tiny: string | null };
}

export type ContentProposalElement =
  | ContentProposalElementCaption
  | ContentProposalElementMedia;

export interface IContentProposalElementComment extends CommentBase {
  uuid: string;
  wsTaskUuid: string;
  type: CommentType.ContentProposal;
  contentProposalElements: ContentProposalElement[];
}

export interface IContentProposalElementModerationComment extends CommentBase {
  uuid: string;
  wsTaskUuid: string;
  type: CommentType.ContentProposalElementModeration;
  contentProposalElements: ContentProposalElement[];
  replyWsTaskCommentUuid: string | null;
}

export type Comment =
  | IContentProposalElementComment
  | IContentProposalElementModerationComment
  | IRegularComment;
