import { useCallback } from "react";

const setOverflow = (el) => {
  const marginRight = el.offsetWidth - el.clientWidth;
  const paddingBottom = el.offsetHeight - el.clientHeight;

  el.style.overflow = "hidden";
  if (marginRight > 0) el.style.marginRight = `${marginRight}px`;
  if (paddingBottom > 0) el.style.paddingBottom = `${paddingBottom}px`;
};

const resetOverflow = (el) => {
  el.style.overflow = null;
  el.style.marginRight = null;
  el.style.paddingBottom = null;
};

export const useScrollLock = () => {
  const { body } = document;

  const lockScroll = useCallback(() => {
    let depth = parseInt(body.getAttribute("depth")) || 0;
    depth += 1;

    if (depth === 1) {
      document
        .querySelectorAll(".scroll-fixed")
        .forEach((fixedElement) => setOverflow(fixedElement));
    }
    body.setAttribute("depth", depth);
  });

  const unlockScroll = useCallback(() => {
    let depth = body.getAttribute("depth");
    if (depth) {
      if (depth === "1") {
        document
          .querySelectorAll(".scroll-fixed")
          .forEach((fixedElement) => resetOverflow(fixedElement));
        body.removeAttribute("depth");
      } else {
        depth -= 1;
        body.setAttribute("depth", depth);
      }
    }
  });

  return {
    lockScroll,
    unlockScroll,
  };
};
