import { addSpaceIfNeeded } from "../../generator-data/functions/addSpaceIfNeeded";

export const addCreatorInsightsToLayout = (data, newLayout, format) => {
  const lastEl = addSpaceIfNeeded("creator-insights", newLayout, format);

  let x = 0;
  let y = 0;
  const w = 4;
  const h = 3;

  if (lastEl.w === 12 || lastEl.x === 8) {
    x = 0;
    y = lastEl.y + lastEl.h;
  }
  // second in row and half the width
  else if (lastEl.x === 0 || lastEl.x === 4) {
    x = lastEl.x + 4;
    y = lastEl.y;
  }

  newLayout.push({ i: data.label, x, y, w, h });
};
