import React from "react";

import DOMPurify from "dompurify";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";
import { ReactComponent as BioIcon } from "../../../../../images/profile-data/bio.svg";

export function SocialBio(props) {
  const { bio, height } = props;
  return (
    <ProfileBox block>
      {height && (
        <PBHeader
          icon={<BioIcon />}
          title={<IDHFormattedMessage id="ws_bio" defaultMessage="Bio" />}
          onShowcase={height}
        />
      )}
      <div
        className="profile-box__bio"
        //  eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(bio),
        }}
      />
    </ProfileBox>
  );
}
