import React, { useState, useRef } from "react";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import { connect } from "react-redux";
import "./BrandSafety.scss";
import axios from "axios";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as TickGreen } from "src/images/tick-green.svg";
import { ReactComponent as CrossBlueIcon } from "src/images/close.svg";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import { Button } from "src/app/components/Button/Button";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { objectsDiffer } from "src/app/methods/objectsDiffer";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import { showErrorToast } from "src/utils/methods";
import CONSTS from "../../helpers/consts";
import { getInfluencersListData } from "../audiencesManagerActions";
import { useOnClickOutside } from "../../base/utils/useOnOutsideClick";

function BrandSafety(props) {
  const {
    intl,
    listId,
    getInfluencersListData,
    toggleBrandSafetyVisibility,
    brandSafetySettings,
    toggleCrisisDetectionModalVisibility,
  } = props;
  const [value, setValue] = useState("");
  const [selectedMonth, setSelectedMonth] = useState({
    value: brandSafetySettings.months || 1,
    label: `${brandSafetySettings.months || 1} ${
      brandSafetySettings.months > 1 ? "months" : "month"
    }`,
  });
  const [keywords, setKeywords] = useState(brandSafetySettings.keywords);
  const ref = useRef();

  const [crisisDetectionEnabled, setCrisisDetectionEnabled] = useState(
    brandSafetySettings.crisisDetectionEnabled,
  );

  useOnClickOutside(ref, () => {
    toggleBrandSafetyVisibility();
  });

  const deleteLabel = (keyword) => {
    setKeywords(keywords.filter((label) => label !== keyword));
  };

  const getMonths = () => {
    const options = [];
    for (let i = 1; i < 13; i++) {
      options.push({
        value: i,
        label: `${i} ${i > 1 ? "months" : "month"}`,
      });
    }
    return options;
  };

  const onSaveButtonClick = async () => {
    toggleBrandSafetyVisibility();
    try {
      await axios.post(CONSTS.postUpdateBrandSafetySettings, {
        listId,
        crisisDetectionEnabled,
        keywords,
        months: selectedMonth.value,
      });
      if (
        crisisDetectionEnabled &&
        !brandSafetySettings.crisisDetectionEnabled
      ) {
        toggleCrisisDetectionModalVisibility();
      }
      getInfluencersListData(listId);
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const addKeyword = () => {
    const newKeywords = value
      .replace(/[ ,]/g, "\n")
      .split("\n")
      .filter((el) => el !== "" && !keywords.includes(el));
    setKeywords([...keywords, ...newKeywords]);
    setValue("");
  };

  return (
    <div className="brand-safety" ref={ref}>
      <div className="brand-safety__title">
        <IDHFormattedMessage
          id="front_brand_safety"
          defaultMessage="Brand safety"
        />
      </div>
      <div className="brand-safety__scanner">
        <div className="brand-safety__scanner__label">
          <IDHFormattedMessage
            id="front_crisis_detection_scanner"
            defaultMessage="Crisis Detection Scanner"
          />
          <MaterialTooltip
            center
            content={
              <IDHFormattedMessage
                id="front_crisis_scanner_detection_tooltip"
                defaultMessage="Feature of scanning the selected Creators on your list for crises that may negatively affect the image of the Brand cooperating with this account. After enabling it, you will be informed about activities on selected accounts that may indicate an image crisis. You will receive email notifications."
              />
            }
          />
        </div>
        <CustomSwitch
          checked={crisisDetectionEnabled}
          onChange={() => setCrisisDetectionEnabled((c) => !c)}
        />
      </div>
      <div className="brand-safety__post-check">
        <div className="brand-safety__post-check__label">
          <IDHFormattedMessage
            id="front_competitors_post_check"
            defaultMessage="Competitor's Post Check"
          />
          <MaterialTooltip
            center
            content={
              <div>
                <IDHFormattedMessage
                  id="front_feature_that_allows_you_to_scan"
                  defaultMessage='Feature that allows you to scan to check if there are posts with specific tags / hashtags on the accounts belonging to the Creators included in the". Selected Creators" list.'
                />
                <div className="brand-safety__tooltip-list">
                  <div className="brand-safety__tooltip-list-title">
                    <IDHFormattedMessage
                      id="front_by_enabling_this_feature"
                      defaultMessage="By enabling this feature:"
                    />
                  </div>
                  <div className="brand-safety__tooltip-list-item">
                    <TickGreen />
                    <IDHFormattedMessage
                      id="front_you_will_scan_the_posts_of_selected"
                      defaultMessage="You will scan the posts of selected Creators back according to the selected period"
                    />
                  </div>
                  <div className="brand-safety__tooltip-list-item">
                    <TickGreen />
                    <IDHFormattedMessage
                      id="front_you_will_be_notified_on_an_ongoing_basis"
                      defaultMessage='You will be notified on an ongoing basis on the selected creators list in the "Brand Safety" column and via e-mail'
                    />
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <MaterialTooltip
          center
          alignRight
          content={
            <IDHFormattedMessage
              id="front_keywords_enter_at_least_one_keyword_to_enable_post_check"
              defaultMessage="Enter at least one keyword to enable Competitor's Post Check."
            />
          }
          contentHidden={keywords.length > 0}
        >
          <CustomSwitch
            checked={keywords.length > 0}
            onChange={(e) => setKeywords([])}
            disabled={keywords.length === 0}
          />
        </MaterialTooltip>
      </div>
      <div className="audience-search__input">
        <div className="idh-label idh-label--with-tooltip">
          <IDHFormattedMessage
            id="front_keywords_brand_safety"
            defaultMessage="Keywords"
          />
          <MaterialTooltip
            center
            content={
              <IDHFormattedMessage
                id="front_keywords_brand_safety_tooltip"
                defaultMessage="Enter keywords here, i.e. tags or hashtags of brands or campaigns that you want to check on selected accounts."
              />
            }
          />
        </div>
        <input
          placeholder={intl.formatMessage({
            id: "front_enter_keywords_to_detect",
            defaultMessage: "Enter keywords to detect",
          })}
          value={value}
          onKeyUp={(e) => e.key === "Enter" && addKeyword()}
          onBlur={addKeyword}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <div className="audience-search__labels">
        {keywords.map((keyword, index) => (
          <div
            className={classNames("audience-search__label", {
              "audience-search__label--disabled": false,
            })}
            key={`keyword-${index}`}
          >
            {keyword}
            <CrossBlueIcon
              className="audience-search__label__close"
              onClick={() => deleteLabel(keyword)}
              height={16}
              width={16}
            />
          </div>
        ))}
      </div>
      <div className="audience-search__field">
        <div className="idh-label idh-label--with-tooltip">
          <IDHFormattedMessage
            id="front_search_published_posts_back_to"
            defaultMessage="Search published posts, back to:"
          />
        </div>
        <CustomSelect
          placeholder={intl.formatMessage({
            id: "front_select_minimum_er",
            defaultMessage: "Select",
          })}
          value={selectedMonth}
          options={getMonths()}
          onChange={(newValue) => setSelectedMonth(newValue)}
        />
      </div>
      <Button
        size="medium"
        variant="blue"
        onClick={onSaveButtonClick}
        disabled={
          !objectsDiffer(brandSafetySettings, {
            keywords,
            months: selectedMonth.value,
            crisisDetectionEnabled,
          })
        }
      >
        <IDHFormattedMessage id="front_save_btn" defaultMessage="Save" />
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  brandSafetySettings: state.audiencesManagerReducer.brandSafetySettings,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getInfluencersListData: (listId, pageSelected, sortBy) =>
      dispatch(getInfluencersListData(listId, pageSelected, sortBy)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(BrandSafety));
