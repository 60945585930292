import React, { useState } from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tab from "src/app/components/Tabs/Tab";
import Tabs from "src/app/components/Tabs/Tabs";
import { TaskType } from "src/types";
import { CoverImage } from "src/redux/project/types";
import { Metadata } from "../../Subtask/Subtask";
import { ContentTab } from "../ContentTab/ContentTab";
import { PublicationsTab } from "../PublicationsTab/PublicationsTab";
import { ActionsTab } from "../ActionsTab/ActionsTab";
import { PaymentsTab } from "../PaymentsTab/PaymentsTab";

enum ActivityTabs {
  Content = "Content",
  Publications = "Publications",
  Payments = "Payments",
  Actions = "Actions",
}

export interface Task {
  id: string;
  rank: string;
  title: string;
  metadata: Metadata[];
  cover: CoverImage | null;
}

interface ActivityTabProps {
  tasks: {
    relatedContentTasks: Task[];
    relatedActionTasks: Task[];
    relatedPublicationTasks: Task[];
    relatedPaymentTasks: Task[];
  };
  taskType: TaskType;
  permissions: { project: string[] };
}

export function ActivityTab({
  tasks,
  taskType,
  permissions,
}: ActivityTabProps) {
  const [activeTab, setActiveTab] = useState<ActivityTabs>(
    ActivityTabs.Content,
  );

  const renderTabContent = () => {
    switch (activeTab) {
      case ActivityTabs.Content:
        return (
          <ContentTab
            tasks={tasks.relatedContentTasks}
            taskType={taskType}
            permissions={permissions}
          />
        );
      case ActivityTabs.Publications:
        return (
          <PublicationsTab
            tasks={tasks.relatedPublicationTasks}
            permissions={permissions}
            taskType={taskType}
          />
        );
      case ActivityTabs.Payments:
        return (
          <PaymentsTab
            tasks={tasks.relatedPaymentTasks}
            permissions={permissions}
            taskType={taskType}
          />
        );
      case ActivityTabs.Actions:
        return (
          <ActionsTab
            tasks={tasks.relatedActionTasks}
            permissions={permissions}
            taskType={taskType}
          />
        );
    }
  };

  return (
    <div className="activity-tab">
      <Tabs>
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_content" defaultMessage="Content" />
          }
          active={activeTab === ActivityTabs.Content}
          onClick={() => setActiveTab(ActivityTabs.Content)}
        />
        <Tab
          tabText={
            <IDHFormattedMessage
              id="ws_publications"
              defaultMessage="Publications"
            />
          }
          active={activeTab === ActivityTabs.Publications}
          onClick={() => setActiveTab(ActivityTabs.Publications)}
        />
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_payments" defaultMessage="Payments" />
          }
          active={activeTab === ActivityTabs.Payments}
          onClick={() => setActiveTab(ActivityTabs.Payments)}
        />
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_actions" defaultMessage="Actions" />
          }
          active={activeTab === ActivityTabs.Actions}
          onClick={() => setActiveTab(ActivityTabs.Actions)}
        />
      </Tabs>
      {renderTabContent()}
    </div>
  );
}
