import React from "react";
import "react-customize-token-input/dist/react-customize-token-input.css";
import "./CustomTokenInput.scss";

import TokenInput from "react-customize-token-input";

interface Props {
  value: string[];
  onChange: (newValue: string[]) => void;
  placeholder?: string;
  separators?: string[];
}

export const CustomTokenInput: React.FC<Props> = (props) => {
  const { value, onChange, placeholder, separators } = props;

  return (
    <TokenInput
      tokenValues={value}
      onTokenValuesChange={onChange}
      placeholder={placeholder}
      separators={separators}
    />
  );
};
