import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { toast } from "react-toastify";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { API_URLS } from "../../../../utils/API_URLS";
import Loader from "../../../components/Loader/Loader";
import { AudienceAgeAndGender } from "../../../Task/tabs/ProfileDataTab/single/AudienceAgeAndGender";
import { FemalePerAge } from "../../../Task/tabs/ProfileDataTab/single/FemalePerAge";
import { FollowersRange } from "../../../Task/tabs/ProfileDataTab/single/FollowersRange";
import { Location } from "../../../Task/tabs/ProfileDataTab/single/Location";
import { MalePerAge } from "../../../Task/tabs/ProfileDataTab/single/MalePerAge";
import { EmptyPublishersTab } from "../EmptyPublishersTab";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { CreatorInterests } from "../../../Task/tabs/ProfileDataTab/single/CreatorInterests";
import { CreatorsBrandAffinity } from "../../../Task/tabs/ProfileDataTab/single/CreatorsBrandAffinity";
import { EmptyAllCreatorsTab } from "../EmptyAllCreatorsTab";

const allCreators = {
  value: "all-creators",
  label: (
    <IDHFormattedMessage id="ws_all_creators" defaultMessage="All creators" />
  ),
};
const publishers = {
  value: "publishers",
  label: (
    <IDHFormattedMessage
      id="ws_only_publishers"
      defaultMessage="Only publishers"
    />
  ),
};
const defaultCreatorsType = allCreators;

function CreatorsInsightsTab() {
  const [tabData, setTabData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [creatorsType, setCreatorsType] = useState(defaultCreatorsType);

  const { projectId } = useParams();

  const getTabData = async (type) => {
    setLoading(true);
    try {
      const url = `${API_URLS.getProjectCreatorsInsights.replace(
        ":projectUuid:",
        projectId,
      )}?creatorsType=${type}`;

      const data = await axios.get(url);

      setTabData(data.data.content);
    } catch (err) {
      toast.error("Error on loading audience data.");
    }

    setLoading(false);
  };

  useEffect(() => {
    getTabData(creatorsType.value);
  }, [creatorsType]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="creators-insights-tab">
      <div className="tab-filter">
        <div className="tab-filter__header">
          <IDHFormattedMessage
            id="ws_creator_insights_tab_text"
            defaultMessage="Here you will find data about creator insights. Below you can select whether the data should apply to all the creators you have in your campaign or only those who published the content."
          />
        </div>
        <div className="tab-filter__select">
          <CustomSelect
            value={creatorsType}
            onChange={(newValue) => {
              setCreatorsType(newValue);
            }}
            options={[allCreators, publishers]}
          />
        </div>
      </div>

      {tabData && (
        <div className="tab-content">
          {tabData.audienceAgeGroups && (
            <AudienceAgeAndGender
              audienceAgeGroups={tabData.audienceAgeGroups}
              onProjectDetails
            />
          )}
          {tabData.audienceWomen && tabData.gendersPerAge?.female && (
            <FemalePerAge
              audienceWomen={tabData.audienceWomen}
              female={tabData.gendersPerAge?.female}
              onProjectDetails
            />
          )}
          {tabData.audienceMen && tabData.gendersPerAge?.male && (
            <MalePerAge
              audienceMen={tabData.audienceMen}
              male={tabData.gendersPerAge?.male}
              onProjectDetails
            />
          )}
          {tabData.countryLocations.length > 0 && (
            <Location
              countryLocations={tabData.countryLocations.slice(0, 8)}
              onProjectDetails
            />
          )}

          {tabData.followersRange && (
            <FollowersRange
              followersRange={tabData.followersRange}
              onProjectDetails
            />
          )}

          {tabData.creatorsInterests.length > 0 && (
            <CreatorInterests
              data={tabData.creatorsInterests}
              onProjectDetails
            />
          )}

          {tabData.creatorsBrandAffinity.length > 0 && (
            <CreatorsBrandAffinity
              data={tabData.creatorsBrandAffinity}
              onProjectDetails
            />
          )}
        </div>
      )}

      {!tabData && creatorsType.value === allCreators.value && (
        <EmptyAllCreatorsTab />
      )}
      {!tabData && creatorsType.value === publishers.value && (
        <EmptyPublishersTab />
      )}
    </div>
  );
}

export { CreatorsInsightsTab };
