import Modal from "../../components/Modal/Modal";
import "./UpgradeAccountModal.scss";
import IDHFormattedMessage from "../../components/IDHFormattedMessage/IDHFormattedMessage";

import { ReactComponent as UpgradeAccountIcon } from "../../../images/credibility-tool/upgrade-account-img.svg";

import React from "react";
import { Button } from "../../components/Button/Button";
import { useParams } from "react-router";

function UpgradeAccountModal(props) {
  const { userUuid } = useParams();
  const { onClose } = props;

  const handleClick = () => {
    window.location.href = userUuid
      ? `/workspace/trial?fccId=${userUuid}`
      : "/workspace/trial";
  };

  return (
    <Modal
      className="upgrade-account-modal"
      onClose={onClose}
      displayCancelButton={false}
    >
      <div className="upgrade-account-modal__row">
        <div className="upgrade-account-modal__column">
          <div className="upgrade-account-modal__header">
            <IDHFormattedMessage
              id="ws_credibility_tool_upgrade_account_header"
              defaultMessage="Oops..."
            />
          </div>
          <div className="upgrade-account-modal__description">
            <IDHFormattedMessage
              id="ws_credibility_tool_upgrade_account_description"
              defaultMessage="You have reached your limit of 20 accounts. Upgrade your account."
            />
          </div>

          <div className="upgrade-account-modal__buttons">
            <Button
              variant="white-with-grey-border"
              className="upgrade-account-modal__cancel-btn"
              onClick={onClose}
            >
              <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
            </Button>

            <Button
              className="upgrade-account-modal__upgrade-account-btn"
              onClick={handleClick}
            >
              <IDHFormattedMessage
                id="ws_upgrade_account"
                defaultMessage="Upgrade account"
              />
            </Button>
          </div>
        </div>
        <div className="upgrade-account-modal__column">
          <UpgradeAccountIcon />
        </div>
      </div>
    </Modal>
  );
}

export default UpgradeAccountModal;
