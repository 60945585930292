import React, { useEffect, useState } from "react";
import "./CodesTable.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useSelector } from "react-redux";
import { Coupon, CouponGroup } from "src/redux/coupon/couponReducer";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { RootState } from "src/redux/reducers";
import { GroupTableItem } from "./components/GroupTableItem";
import { CouponTableItem } from "./components/CouponTableItem";
import { mockedCoupons, mockedGroups } from "./mockedData";

export type CouponViewType = "group-list" | "coupon-list";

interface Props {
  viewType: CouponViewType;
}

export const CodesTable: React.FC<Props> = (props) => {
  const { viewType } = props;

  const {
    couponReducer: { couponsList, couponGroups, listLoading, detailsLoading },
  } = useSelector((state: RootState) => state);

  const couponsToRender = detailsLoading ? mockedCoupons : couponsList;
  const groupsToRender = listLoading ? mockedGroups : couponGroups;

  const CouponList = couponsToRender.map((coupon: Coupon) => (
    <CouponTableItem key={coupon.uuid} data={coupon} />
  ));

  const CouponGroupList = groupsToRender.map((couponGroup: CouponGroup) => (
    <GroupTableItem key={couponGroup.uuid} data={couponGroup} />
  ));

  const renderList = () => {
    if (viewType === "coupon-list") return CouponList;
    return CouponGroupList;
  };

  const CouponGroupHeaders = (
    <>
      <span className="codes-table__head-offer">
        <IDHFormattedMessage id="ws_offer" defaultMessage="Offer" />
      </span>
      <span className="codes-table__head-campaigns">
        <IDHFormattedMessage id="ws_campaigns" defaultMessage="Campaigns" />
      </span>
      <span className="codes-table__head-coupons">
        <IDHFormattedMessage id="ws_coupons" defaultMessage="Coupons" />
      </span>
    </>
  );

  const CouponListHeaders = (
    <>
      <span className="codes-table__head-code">
        <IDHFormattedMessage id="ws_promo_code" defaultMessage="Promo code" />
      </span>
      <span className="codes-table__head-campaign">
        <IDHFormattedMessage id="ws_campaign" defaultMessage="Campaign" />
      </span>
      <span className="codes-table__head-connected-with">
        <IDHFormattedMessage
          id="ws_connected_with"
          defaultMessage="Connected with"
        />
      </span>
    </>
  );

  const renderHeaders = () => {
    if (viewType === "coupon-list") return CouponListHeaders;
    return CouponGroupHeaders;
  };

  return (
    <AnimatedDiv className="codes-table">
      <div className="codes-table__head">
        {renderHeaders()}
        <span className="codes-table__head-action">
          <IDHFormattedMessage id="ws_action" defaultMessage="Action" />
        </span>
      </div>

      <div className="codes-table__body">{renderList()}</div>
    </AnimatedDiv>
  );
};
