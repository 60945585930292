export const REFRESH_AUTOMATION_LIST = "REFRESH_AUTOMATION_LIST";
export const REFRESH_AUTOMATION_LIST_SUCCESS =
  "REFRESH_AUTOMATION_LIST_SUCCESS";
export const GET_AUTOMATION_LIST = "GET_AUTOMATION_LIST";
export const GET_AUTOMATION_LIST_SUCCESS = "GET_AUTOMATION_LIST_SUCCESS";
export const SET_SHOW_PUBLICATIONS_DETECTED_MODAL =
  "SET_SHOW_PUBLICATIONS_DETECTED_MODAL";
export const GET_DETECTED_PUBLICATIONS = "GET_DETECTED_PUBLICATIONS";
export const GET_DETECTED_PUBLICATIONS_SUCCESS =
  "GET_DETECTED_PUBLICATIONS_SUCCESS";
export const SET_EDITED_AUTOMATION_DATA = "SET_EDITED_AUTOMATION_DATA";
