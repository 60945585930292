import React, { useContext, useState } from "react";
// import "./ShareModal.scss";

import { ReactComponent as EyesIcon } from "src/images/eyes.svg";

import { matchRoleName } from "src/utils/matchRoleName";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MembersContext } from "src/app/SettingsModal/tabs/Members/Members";
import { useSelector } from "react-redux";
import { SettingsContext } from "src/app/SettingsModal/enums";
import { UserListFilter } from "./UserListFilter";
import { UserListItem } from "./UserListItem";

function UserList(props) {
  const { getData } = props;
  const { members } = useContext(MembersContext);

  const [filteredGroup, setFilteredGroup] = useState("all");

  const {
    mainReducer: { settingsModalData },
  } = useSelector((state) => state);

  const { context } = settingsModalData;

  const getUserList = () => {
    if (context === SettingsContext.Workspace) {
      return members.filter((user) => user.status !== "removed");
    }

    return members.filter((user) =>
      filteredGroup !== "all"
        ? matchRoleName(user.memberRole) === filteredGroup
        : true,
    );
  };

  return (
    <div className="members__user-list-wrap">
      <div className="members__user-list__header">
        <IDHFormattedMessage
          id="ws_people_with_access"
          defaultMessage="People with access"
        />
        {context === SettingsContext.Project && (
          <UserListFilter
            filteredGroup={filteredGroup}
            setFilteredGroup={setFilteredGroup}
          />
        )}
      </div>
      <div className="members__user-list">
        {getUserList().length > 0 ? (
          getUserList().map((user, index) => (
            <UserListItem
              key={`project-user-${index}`}
              user={user}
              getData={getData}
            />
          ))
        ) : (
          <div className="members__user-list-empty">
            <EyesIcon className="members__eyes-icon" />
            &nbsp;
            <IDHFormattedMessage
              id="ws_you_have_no_clients_in_this_campaign"
              defaultMessage="You have no Clients in this campaign."
            />
          </div>
        )}
      </div>
      <div className="members__user-list__footer" />
    </div>
  );
}

export { UserList };
