import React, { useEffect, useState } from "react";
import "./CreatorBox.scss";

import { useSelector } from "react-redux";
import { uuidv7 as uuid } from "uuidv7";
import RatingSelector from "src/app/components/RatingSelector/RatingSelector";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import CustomImage from "../../../../../components/CustomImage/CustomImage";
import CreatorPlaceholder from "../../../../../../images/creator-placeholder.svg";
import SocialProfileIcons from "../../../../../components/IconsWithStatus/SocialProfileIcons/SocialProfileIcons";
import { TASK_FIELDS_BLACKLIST } from "../../../../../Task/components/TaskMetaFields/TaskMetaFields";

import { renderGeneratorMetaValue } from "../../../../../generator-data/components/ContentBox/ContentBox";
import { getImageSource } from "../../../../../../utils/methods";

export const REPORT_TASK_FIELDS_WHITELIST = ["currency", "percent", "number"];

export const isMetaFieldMetric = (item) =>
  item.fieldValueSource &&
  !TASK_FIELDS_BLACKLIST.includes(item.key) &&
  REPORT_TASK_FIELDS_WHITELIST.includes(item.type);

export function CreatorBox(props) {
  const { data } = props;

  const {
    reportReducer: { creatorsMetadata, showLoader, loaderText, loading },
  } = useSelector((state) => state);

  const [parentKey, setParentKey] = useState(uuid());
  const [wrapperKey, setWrapperKey] = useState(uuid());
  const [metadataValues, setMetadataValues] = useState([]);
  const [visibleMetadata, setVisibleMetadata] = useState([]);
  const [oldVisibleMetadata, setOldVisibleMetadata] = useState([]);
  const [enableSaving, setEnableSaving] = useState(false);

  const synchronizing = showLoader && loaderText.toLowerCase().includes("sync");
  const parentId = `creator-box-${data.taskId}`;

  useEffect(() => {
    if (synchronizing) return;

    const wrapper = document.getElementById(`wrapper-${data.taskId}`);
    if (!wrapper) {
      setParentKey(uuid());
    }
    setWrapperKey(uuid());

    const items = document.querySelectorAll(
      `#${parentId} .creator-box__metrics-box`,
    );

    const newMetadataValues = Array.from(items)
      ?.filter((item) => {
        if (item.id.includes("creator-meta-")) {
          const uuid = item.id.replace("creator-meta-", "");

          const newMetaField = data.metadata.find(
            (field) => field.uuid === uuid,
          );
          const oldMetaField = visibleMetadata.find(
            (field) => field.uuid === uuid,
          );

          if (
            newMetaField &&
            oldMetaField &&
            newMetaField.value !== oldMetaField.value
          ) {
            return false;
          }
        }

        if (item.id.includes("creator-metrics-")) {
          const uuid = item.id.replace("creator-metrics-", "");

          const newMetaField = data.metadata.find(
            (field) => field.uuid === uuid,
          );

          if (newMetaField) {
            return false;
          }
        }

        return true;
      })
      .map((item) => {
        return {
          id: item.id,
          html: item.innerHTML,
        };
      });

    setMetadataValues(newMetadataValues);

    const newVisibleMetadata =
      creatorsMetadata
        ?.filter((item) => item.reportVisible)
        ?.map((item) => {
          const metaField = data.metadata.find(
            (field) => field.uuid === item.uuid,
          );

          return metaField;
        }) || [];

    setOldVisibleMetadata(visibleMetadata);
    setVisibleMetadata(newVisibleMetadata);
  }, [creatorsMetadata, synchronizing]);

  useEffect(() => {
    if (!loading && !enableSaving) {
      setTimeout(() => {
        setEnableSaving(true);
      }, 1000);
    }
  }, [loading]);

  useEffect(() => {
    if (synchronizing) return;
    if (enableSaving && visibleMetadata.length) {
      applySavedValues();
    }
    if (visibleMetadata.length > oldVisibleMetadata.length) {
      const items = document.querySelectorAll(
        `#${parentId} .creator-box__metrics-box`,
      );

      const lastItem = items[items.length - 1];
      if (
        lastItem &&
        !metadataValues.find((item) => item.id === lastItem?.id)
      ) {
        setMetadataValues((values) => [
          ...values,
          { id: lastItem.id, html: lastItem.innerHtml },
        ]);
      }
    }
  }, [visibleMetadata]);

  const applySavedValues = () => {
    const items = document.querySelectorAll(
      `#${parentId} .creator-box__metrics-box`,
    );

    Array.from(items).forEach((item) => {
      const field = metadataValues.find((el) => el.id === item.id);

      if (field) {
        item.innerHTML = field.html;
      }
    });
  };

  const renderContent = (data) => {
    if (data.type === MetaFieldType.Rating) {
      return (
        <RatingSelector disabled value={parseInt(data?.value || "0")} small />
      );
    }

    return renderGeneratorMetaValue(data);
  };

  return (
    <div id={parentId} key={parentKey} className="creator-box">
      <div className="creator-box__data">
        <div className="creator-box__data-top">
          <CustomImage
            className="creator-box__data-top-avatar"
            src={getImageSource(data?.cover, "small", CreatorPlaceholder)}
            alt="avatar"
          />
          <div className="creator-box__data-top-info">
            <span>{data.title}</span>
            <SocialProfileIcons data={data.socialProfiles} />
          </div>
        </div>
      </div>
      <div
        key={wrapperKey}
        id={`wrapper-${data.taskId}`}
        className="creator-box__metrics"
      >
        {visibleMetadata.length ? (
          <>
            {visibleMetadata
              ?.filter((item) => item.value)
              ?.map((metaField) => {
                const id = `creator-meta-${metaField.uuid}`;
                return (
                  <div id={id} key={id} className="creator-box__metrics-box">
                    <span className="creator-box__metrics-box-name">
                      {metaField.name}
                    </span>
                    <span className="creator-box__metrics-box-value">
                      {renderContent(metaField)}
                    </span>
                  </div>
                );
              })}
          </>
        ) : (
          <div className="creator-box__metrics-box" key="empty">
            <span className="creator-box__metrics-box-name" />
            <span className="creator-box__metrics-box-value" />
          </div>
        )}
      </div>
    </div>
  );
}
