import React, { useState } from "react";
import axios from "axios";
import classNames from "classnames";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useIntl } from "react-intl";
import { ReactComponent as IncomingIcon } from "../../../images/incoming.svg";
import { ReactComponent as WarningIcon } from "../../../images/warning-circle.svg";
import { Button } from "../../components/Button/Button";
import CustomInput, {
  CustomInputLabel,
} from "../../components/CustomInput/CustomInput";
import { API_URLS } from "../../../utils/API_URLS";
import BookDemoModal from "../../modals/BookDemoModal/BookDemoModal";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import { translateMessage } from "../../methods/translateMessage";

function RightSide() {
  const intl = useIntl();
  const [email, setEmail] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const [showBookDemoModal, setShowBookDemoModal] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInvalidEmailError(false);

    try {
      await axios.post(API_URLS.requestMagicLink, {
        wsMemberEmail: email,
        rememberMe,
      });
      setShowForm(false);
    } catch (err) {
      setShowForm(true);
      if (err.response?.data.errors[0] === "Invalid email") {
        setInvalidEmailError(true);
      }
    }
  };

  // podzielić na mniejsze pliki

  return (
    <div className="login-page-right">
      <div className="login-page-right__book-demo">
        <IDHFormattedMessage
          id="ws_not_a_member"
          defaultMessage="Not a member?"
        />
        &nbsp;
        <span onClick={() => (window.location = "/workspace/trial")}>
          <IDHFormattedMessage
            id="ws_request_demo"
            defaultMessage="Request Demo"
          />
        </span>
      </div>
      <div
        className={classNames("login-page-right__container", {
          "login-page-right__container--confirm": !showForm,
        })}
      >
        {showForm ? (
          <>
            <div className="login-page-right__header">
              <IDHFormattedMessage
                id="ws_sign_in_to_indahash_self_serve"
                defaultMessage="Sign in to indaHash Self-Serve"
              />
            </div>
            <form onSubmit={handleSubmit}>
              <CustomInputLabel
                htmlFor="email"
                error={invalidEmailError}
                aboveInput
              >
                <IDHFormattedMessage id="ws_email" defaultMessage="Email" />
              </CustomInputLabel>
              <CustomInput
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                type="email"
                error={invalidEmailError}
                autoComplete="on"
              />

              {invalidEmailError && (
                <>
                  <div className="login-page-right__invalid-email-error">
                    <WarningIcon className="login-page-right__invalid-email-error-icon" />
                    <IDHFormattedMessage
                      id="ws_entered_address_does_not_exist_in_db"
                      defaultMessage="Entered address does not exist in our database."
                    />
                  </div>
                  <div className="login-page-right__contact-us">
                    <IDHFormattedMessage
                      id="ws_contact_us_at"
                      defaultMessage="Contact us at"
                    />
                    &nbsp;
                    <a href={`mailto:${translateMessage({intl, id: "ws_support_email", defaultMessage: "support@indahash.com"})}`}>
                      <IDHFormattedMessage id="ws_support_email" defaultMessage="support@indahash.com" />
                    </a>
                    &nbsp;
                    <IDHFormattedMessage id="ws_or" defaultMessage="or" />
                    &nbsp;
                    <span
                      className="login-page-right__book-demo-2"
                      onClick={() => (window.location = "/workspace/trial")}
                    >
                      <IDHFormattedMessage
                        id="ws_request_demo"
                        defaultMessage="Request Demo"
                      />
                    </span>
                  </div>
                </>
              )}

              <CustomCheckbox
                id="remember-me-checkbox"
                name="remember-me-checkbox"
                label="Keep me logged in"
                checked={rememberMe}
                onChange={() => setRememberMe((prev) => !prev)}
              />

              <Button
                size="large"
                variant="blue"
                className="login-page-right__btn"
              >
                <IDHFormattedMessage id="ws_sign_in" defaultMessage="Sign in" />
              </Button>
            </form>
          </>
        ) : (
          <>
            <IncomingIcon className="login-page-right__incoming-icon" />
            <div className="login-page-right__header">
              <IDHFormattedMessage
                id="ws_confirm_your_email"
                defaultMessage="Confirm your email"
              />
            </div>
            <div className="login-page-right__text">
              <IDHFormattedMessage
                id="ws_we_emailed_a_magic_link_to"
                defaultMessage="We emailed a magic link to"
              />
            </div>
            <div className="login-page-right__email-address">{email}</div>
            <div className="login-page-right__text">
              <IDHFormattedMessage
                id="ws_click_the_link_to_sign_in"
                defaultMessage="Click the link to sign in"
              />
              .
            </div>
            <div className="login-page-right__try-again">
              <IDHFormattedMessage
                id="ws_if_you_havent_received_an_email_after_a_minute_1"
                defaultMessage="If you haven't received an email after a minute"
              />
              <br />
              <IDHFormattedMessage
                id="ws_if_you_havent_received_an_email_after_a_minute_2"
                defaultMessage=",please check your spam folder or click here to try again"
              />
              <div onClick={() => setShowForm(true)}>
                <IDHFormattedMessage
                  id="ws_click_here_to_try_again"
                  defaultMessage="click here to try again"
                />
              </div>
            </div>
          </>
        )}
      </div>

      {showBookDemoModal && (
        <BookDemoModal onClose={() => setShowBookDemoModal(false)} />
      )}
    </div>
  );
}

export { RightSide };
