const UNHANDLED_VALUES = [0, null, undefined];

export const shouldDisplaySentiments = (sentimentValues) => {
  if (!sentimentValues) return false;
  let result = false;
  Object.entries(sentimentValues).map((item) => {
    const [, value] = item;
    if (!UNHANDLED_VALUES.includes(value)) result = true;
  });
  return result;
};
