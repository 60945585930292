import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./StopSharingWithClientModal.scss";

import classNames from "classnames";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalText, ModalTitle } from "../../components/Modal/Modal";
import { ReactComponent as ClientsGreyIcon } from "../../../images/client-grey.svg";
import { API_URLS } from "../../../utils/API_URLS";
import { wsAxiosPatch } from "../../../helpers/wsAxios";
import {
  getSingleTaskForList,
  getTasksList,
  localSetColumnsSharing,
  localSetTasksSharing,
} from "../../../redux";
import { StackedAvatars } from "../../components/StackedAvatars/StackedAvatars";
import SocialProfileIcons from "../../components/IconsWithStatus/SocialProfileIcons/SocialProfileIcons";
import CustomImage from "../../components/CustomImage/CustomImage";

function StopSharingWithClientModal(props) {
  const {
    onClose,
    metaFieldId,
    taskType,
    taskAvatarShape,
    getPlaceholderImage,
    task,
  } = props;

  const params = useParams();
  const dispatch = useDispatch();

  const {
    projectReducer: { projectBasicData },
  } = useSelector((state) => state);

  const unshareColumn = async () => {
    await wsAxiosPatch(
      API_URLS.unshareColumn.replace(":metaFieldId:", metaFieldId),
      {},
      `Error occurred during unsharing column`,
      () => {},
      () => {
        dispatch(localSetColumnsSharing([metaFieldId], false));
      },
    );
    onClose();
  };

  const unshareTask = async () => {
    await wsAxiosPatch(
      API_URLS.unshareTask.replace(":taskId:", task.taskId),
      {},
      `Error occurred during unsharing task`,
      () => {},
      () => {
        dispatch(localSetTasksSharing([task.taskId], false));
        dispatch(getSingleTaskForList(task.taskId));
      },
    );
    onClose();
  };

  return (
    <Modal
      className="stop-sharing-with-client-modal"
      onClose={onClose}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      closeButtonVariant="grey"
      onConfirmClick={task ? unshareTask : unshareColumn}
      confirmButtonText={
        <IDHFormattedMessage
          id="ws_stop_sharing"
          defaultMessage="Stop sharing"
        />
      }
      confirmButtonVariant="red"
    >
      <ModalTitle>
        <ClientsGreyIcon className="stop-sharing-with-client-modal__icon" />
        <IDHFormattedMessage
          id="ws_stop_sharing"
          defaultMessage="Stop sharing"
        />
        <StackedAvatars
          avatars={projectBasicData.members.filter((user) => user.isClient)}
          maxAvatars={2}
          stackedUsers
        />
      </ModalTitle>
      <ModalText>
        {task ? (
          <IDHFormattedMessage
            id="stop_sharing_width_client_modal_text_a"
            defaultMessage="Are you sure you want to stop sharing these items with Clients?"
          />
        ) : (
          <IDHFormattedMessage
            id="stop_sharing_width_client_modal_text_b"
            defaultMessage="Are you sure you want to stop sharing this column with Clients?"
          />
        )}
      </ModalText>

      {task && (
        <div className="stop-sharing-with-client-modal__task-details">
          <div
            className={classNames("ws-table__image", {
              "ws-table__image--square": task.taskType !== "creator",
            })}
          >
            <CustomImage
              className={classNames("ws-table__image-cover", {
                "ws-table__image-cover--circle":
                  taskAvatarShape === taskAvatarShape.Circle,
                "ws-table__image-cover--square":
                  taskAvatarShape === taskAvatarShape.Square,
              })}
              src={getPlaceholderImage()}
              alt=""
            />
          </div>
          <div
            className={classNames(
              "stop-sharing-with-client-modal__task-name-wrapper",
              {
                "stop-sharing-with-client-modal__task-name-wrapper--with-socials":
                  task.socialProfiles?.length > 0,
              },
            )}
          >
            <div className="stop-sharing-with-client-modal__task-name">
              {task.title}
            </div>
            {task.socialProfiles?.length > 0 && (
              <SocialProfileIcons data={task.socialProfiles} />
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}

export default StopSharingWithClientModal;
