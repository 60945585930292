import React from "react";
import "./TableItem.scss";

import { useSelector } from "react-redux";
import CampaignPlaceholder from "src/images/project-placeholder.svg";
import CreatorPlaceholder from "src/images/creator-placeholder.svg";
import { ReactComponent as LockIcon } from "src/images/lock.svg";
import { ReactComponent as LinkIcon } from "src/images/link-alt.svg";

import { Coupon } from "src/redux/coupon/couponReducer";
import Skeleton from "src/app/components/Skeleton/Skeleton";
import { copyToClipboard } from "src/app/methods/copyToClipboard";
import { showToast } from "src/app/methods/showToast";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import { RootState } from "src/redux/reducers";
import { CodeSettingsDropdown } from "../../../dropdowns/CodeSettingsDropdown/CodeSettingsDropdown";

interface Props {
  data: Coupon;
}

export const CouponTableItem: React.FC<Props> = (props) => {
  const { data } = props;

  const { wsProject, wsTask, hasAccess, isAssigned } = data;

  const {
    couponReducer: { detailsLoading },
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const copyCodeToClipBoard = () => {
    copyToClipboard(data.code);
    showToast(
      "success",
      <IDHFormattedMessage id="ws_success" defaultMessage="Success!" />,
      <IDHFormattedMessage
        id="ws_code_has_been_copied"
        defaultMessage="Code has been copied to clipboard!"
      />,
    );
  };

  const renderProject = () => {
    if (isAssigned && !hasAccess) {
      return (
        <MaterialTooltip
          id="no-access-to-campaign-tooltip"
          center
          content={
            <IDHFormattedMessage
              id="ws_coupons_no_access_to_campaign_tooltip"
              defaultMessage="No access to campaign toooltip"
            />
          }
          contentHidden={false}
          clickable={false}
          className="coupon-tooltip"
          contentClassName=""
        >
          <LockIcon />
        </MaterialTooltip>
      );
    }
    if (!wsProject) return "-";
    return (
      <a
        className="table-item__campaign-value"
        href={`/workspace/${activeWorkspaceUuid}/projects/${wsProject.projectId}`}
        rel="noopener noreferrer"
      >
        <img
          src={wsProject.cover?.thumbnails?.tiny || CampaignPlaceholder}
          alt="campaign-cover"
        />
        <span className="table-item__value-name">{wsProject.title}</span>
      </a>
    );
  };

  const renderTask = () => {
    if (isAssigned && !hasAccess) {
      return (
        <MaterialTooltip
          id="no-access-to-task-tooltip"
          center
          content={
            <IDHFormattedMessage
              id="ws_coupons_no_access_to_task_tooltip"
              defaultMessage="No access to task toooltip"
            />
          }
          contentHidden={false}
          clickable={false}
          className="coupon-tooltip"
          contentClassName=""
        >
          <LockIcon />
        </MaterialTooltip>
      );
    }
    if (!wsTask) return "-";
    return (
      <a
        className="table-item__connected-with-value"
        href={`/workspace/${activeWorkspaceUuid}/projects/${wsProject.projectId}/${wsTask.taskId}`}
        rel="noopener noreferrer"
      >
        <img
          src={wsTask.cover?.thumbnails?.tiny || CreatorPlaceholder}
          alt="campaign-cover"
        />
        <span className="table-item__value-name">{wsTask.title}</span>
      </a>
    );
  };

  const shouldDisplaySkeleton = (content: any) => {
    if (detailsLoading)
      return <Skeleton width={80} height={20} borderRadius={6} />;
    return content;
  };

  return (
    <div className="table-item" data-coupon-code={data.code}>
      <span className="table-item__code">
        <span
          key={data?.code}
          onClick={copyCodeToClipBoard}
          className="table-item__code-name animate-on-update"
        >
          {shouldDisplaySkeleton(data.code)}
          <LinkIcon className="link-icon" />
        </span>
      </span>
      <span
        key={wsProject?.title}
        className="table-item__campaign animate-on-update"
      >
        {shouldDisplaySkeleton(renderProject())}
      </span>
      <span
        key={wsTask?.title}
        className="table-item__connected-with animate-on-update"
      >
        {shouldDisplaySkeleton(renderTask())}
      </span>
      <span className="table-item__action">
        <CodeSettingsDropdown data={data} />
      </span>
    </div>
  );
};
