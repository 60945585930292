import { Dispatch as ReduxDispatch } from "redux";
import { tableDataType } from "src/app/components/Table/Table";
import { getProject, getProjectDetails, getTaskDetails } from "../redux";
import { API_URLS } from "./API_URLS";

interface RefreshDataProps {
  dispatch: ReduxDispatch;
  wsProjectUuid?: string;
  wsTaskUuid?: string;
}

export const refreshData = async ({
  dispatch,
  wsProjectUuid,
  wsTaskUuid,
}: RefreshDataProps) => {
  if (wsTaskUuid) {
    await dispatch(getTaskDetails(wsTaskUuid));
  }

  if (wsProjectUuid) {
    await dispatch(getProject(wsProjectUuid));
    await dispatch(getProjectDetails(wsProjectUuid));
  }
};

export enum ParentType {
  wsTaskMetaField = "ws_task_meta_field",
  wsProjectMetaField = "ws_project_meta_field",
  wsGlobalTaskMetaField = "ws_global_task_meta_field",
  wsDictionaryMetaField = "ws_dictionary_meta_field",
}

export const getMetaFileParams = (
  isTask: string | undefined,
  context: tableDataType | undefined,
) => {
  let url;
  let parentType;

  switch (context) {
    case tableDataType.GlobalTask:
      url = API_URLS.presignedRequestForGlobalTaskMetaField;
      parentType = ParentType.wsGlobalTaskMetaField;
      break;
    case tableDataType.Dictionary:
      url = API_URLS.presignedRequestForDictionary;
      parentType = ParentType.wsDictionaryMetaField;
      break;
    default:
      url = isTask
        ? API_URLS.presignedRequestForTaskMetaField
        : API_URLS.presignedRequestForProjectMetaField;
      parentType = isTask
        ? ParentType.wsTaskMetaField
        : ParentType.wsProjectMetaField;
      break;
  }
  return { url, parentType };
};

//  File sizes in MB
export const MAX_SIZE_SINGLE_ATTACHMENT = 300;
export const MAX_SIZE_PROJECT_COVER = 2;
export const MAX_SIZE_AVATAR = 2;

export const allowedTypesForAttachmentUpload = [
  "png",
  "jpg",
  "jpeg",
  "pdf",
  "xls",
  "xlsx",
  "doc",
  "docx",
  "pptx",
  "ppt",
  "txt",
  "zip",
  "mp3",
  "mp4",
  "avi",
  "webm",
  "mov",
  "csv",
  "odt",
  "ods",
];
