import React, { useEffect } from "react";

import ExtensionsList from "./components/ExtensionsList/ExtensionsList";
import "./Extensions.scss";
import { useDispatch, useSelector } from "react-redux";
import { getExtensionList, setExtensionList } from "src/redux";
import Loader from "src/app/components/Loader/Loader";
import { useHistory } from "react-router";
import { RootState } from "src/redux/reducers";
import { WsExtension, ExtensionTypeEnum } from "src/types";
import {
  GLOBAL_TASK_METAFIELD_TYPE,
  TASK_METAFIELD_TYPE,
} from "./components/RecruitmentForm/components/RecruitmentFormFields/RecruitmentFormFields";
import {
  ChangeSettingsSectionParam,
  changeSettingsSection,
} from "../../methods";
import RecruitmentForm from "./components/RecruitmentForm/RecruitmentForm";
import { ContextType, SettingsTab } from "../../enums";

export enum ExtensionsSection {
  ExtensionsList = "ExtensionsList",
  RecruitmentForm = "RecruitmentForm",
}

interface ExtensionsProps {
  onClose: () => void;
  contextData: any;
}

const recruitmentFormInitialData = {
  enabled: false,
  type: ExtensionTypeEnum.RecruitmentForm,
  uuid: "",
} as WsExtension;

export const getMetaFieldTypeByExtensionType = (
  extensionType: string,
): string => {
  if (extensionType === ExtensionTypeEnum.RecruitmentForm) {
    return TASK_METAFIELD_TYPE;
  }

  return GLOBAL_TASK_METAFIELD_TYPE;
};

export function Extensions({ onClose, contextData }: ExtensionsProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    mainReducer: { settingsModalData },
    extensionReducer: { extensionList, isExtensionListLoading },
  } = useSelector((state: RootState) => state);

  const { section: extensionsSection } = settingsModalData;

  const setExtensionsSection = (section: ChangeSettingsSectionParam) => {
    changeSettingsSection(history, section);
  };

  const hasRecruitmentFormExtension = extensionList.some(
    (extension) => extension.type === ExtensionTypeEnum.RecruitmentForm,
  );

  if (!hasRecruitmentFormExtension) {
    const newExtensionList = [recruitmentFormInitialData, ...extensionList];

    dispatch(setExtensionList(newExtensionList));
  }

  const renderComponent = () => {
    switch (extensionsSection) {
      case ExtensionsSection.ExtensionsList:
        return (
          <ExtensionsList
            currentSettingsTab={SettingsTab.Extensions}
            onClose={onClose}
            setExtensionsSection={setExtensionsSection}
            extensionList={extensionList}
            permissions={contextData?.permissions}
            wsProjectUuid={contextData?.uuid ?? null}
            isProjectExtensions={
              contextData ? contextData.type === ContextType.wsProject : false
            }
          />
        );
      case ExtensionsSection.RecruitmentForm:
        const recruitmentListItem = extensionList.find(
          (extension) => extension.type === ExtensionTypeEnum.RecruitmentForm,
        );
        if (!recruitmentListItem) return null;
        return (
          <RecruitmentForm
            onClose={onClose}
            setExtensionsSection={setExtensionsSection}
            contextData={contextData}
            recruitmentListItem={recruitmentListItem}
            extensionType={ExtensionTypeEnum.RecruitmentForm}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (typeof contextData?.uuid !== "string") return;
    dispatch(getExtensionList(contextData.uuid));
  }, [contextData?.uuid]);

  return (
    <div className="extensions">
      {isExtensionListLoading || extensionList.length === 0 ? (
        <Loader />
      ) : (
        renderComponent()
      )}
    </div>
  );
}
