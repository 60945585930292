import React from "react";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";

export const getTaskTypeOptions = () => [
  {
    label: <IDHFormattedMessage id="ws_all" defaultMessage="All" />,
    value: null,
  },
  {
    label: <IDHFormattedMessage id="ws_creator" defaultMessage="Creator" />,
    value: "creator",
  },
  {
    label: <IDHFormattedMessage id="ws_content" defaultMessage="Content" />,
    value: "content",
  },
  {
    label: (
      <IDHFormattedMessage id="ws_publication" defaultMessage="Publication" />
    ),
    value: "publication",
  },
  {
    label: <IDHFormattedMessage id="ws_payment" defaultMessage="Payment" />,
    value: "payment",
  },
  {
    label: <IDHFormattedMessage id="ws_action" defaultMessage="Action" />,
    value: "action",
  },
];
