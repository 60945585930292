import React, { useEffect, useState } from "react";
import "./TrialLimitationsModal.scss";
import { useSelector } from "react-redux";

import { API_URLS } from "src/utils/API_URLS";
import axios from "axios";
import classNames from "classnames";
import { RootState } from "src/redux/reducers";
import Modal from "../../components/Modal/Modal";
import { TrialLanguage } from "./steps/TrialLanguage";
import { Limitations } from "./steps/Limitations";

export type TrialLimitationsStep = "language" | "limitations";

interface Props {
  onClose: () => void;
}

const TrialLimitationsModal: React.FC<Props> = (props) => {
  const { onClose } = props;

  const {
    mainReducer: {
      activeWorkspaceUuid,
      identity: { showTrialLanguage },
      supportedLanguages,
    },
  } = useSelector((state: RootState) => state);

  const [trialLimits, setTrialLimits] = useState(null);
  const [currentStep] = useState<TrialLimitationsStep>(
    showTrialLanguage && supportedLanguages.length > 1
      ? "language"
      : "limitations",
  );

  const getTrialLimits = async () => {
    try {
      const data = await axios.get(
        API_URLS.getTrialLimits.replace(
          ":wsWorkspaceUuid:",
          activeWorkspaceUuid,
        ),
      );
      setTrialLimits(data.data.content);
    } catch {
      console.error("Cannot get trial limits");
    }
  };

  useEffect(() => {
    getTrialLimits();
  }, []);

  const renderStep = () => {
    switch (currentStep) {
      case "language":
        return <TrialLanguage />;

      case "limitations":
        return <Limitations trialLimits={trialLimits} onClose={onClose} />;

      default:
        return null;
    }
  };

  if (trialLimits === null) {
    return null;
  }

  return (
    <Modal
      className={classNames("trial-limitations-modal", {
        "trial-limitations-modal--thin": currentStep === "language",
      })}
      onClose={onClose}
      disableTransition
    >
      {renderStep()}
    </Modal>
  );
};

export { TrialLimitationsModal };
