import { addSpaceIfNeeded } from "../../generator-data/functions/addSpaceIfNeeded";

export const addCampaignAudienceDataToLayout = (data, newLayout, format) => {
  const lastEl = addSpaceIfNeeded(getAudienceDataType(data), newLayout, format);

  let x = 0;
  let y = 0;
  const { w } = data;
  const { h } = data;

  switch (w) {
    case 4:
      if (lastEl.w === 12 || lastEl.x === 8) {
        x = 0;
        y = lastEl.y + lastEl.h;
      } else {
        x = lastEl.x + 4;
        y = lastEl.y;
      }
      break;

    case 6:
      if (lastEl.w === 6 && lastEl.x === 0) {
        x = 6;
        y = lastEl.y;
      } else {
        x = 0;
        y = lastEl.y + lastEl.h;
      }
      break;

    case 12:
      x = 0;
      y = lastEl.y + lastEl.h;
      break;
  }

  newLayout.push({ i: data.label, x, y, w, h });
};

const getAudienceDataType = (data) => {
  switch (data.w) {
    case 12:
      return "header";
    case 6:
      return "audience-data";
    default:
      return "metric";
  }
};
