import React from "react";

import { useDispatch, useSelector } from "react-redux";

import Skeleton from "src/app/components/Skeleton/Skeleton";
import { useHistory } from "react-router";
import { RootState } from "src/redux/reducers";
import { GlobalMetaFieldListItemData } from "src/types";
import { ReactComponent as EditIcon } from "../../../../../../images/edit.svg";
import { StackedAvatars } from "../../../../../components/StackedAvatars/StackedAvatars";
import "./GlobalMetaFieldListItem.scss";
import IDHFormattedMessage from "../../../../../components/IDHFormattedMessage/IDHFormattedMessage";
import { ExtensionsSection } from "../../Extensions";
import { CreatorDatabaseConfigurationSection } from "../../../CreatorDatabaseConfiguration/CreatorDatabaseConfiguration";
import { changeSettingsObjectUuid } from "../../../../methods";

interface Props {
  data: GlobalMetaFieldListItemData;
  setExtensionsSection: (
    section:
      | ExtensionsSection
      | CreatorDatabaseConfigurationSection.SubmissionForm
      | CreatorDatabaseConfigurationSection.MetaFieldList
      | CreatorDatabaseConfigurationSection.MetaFieldMembers,
  ) => void;
}

export const GlobalMetaFieldListItem: React.FC<Props> = (props) => {
  const { data, setExtensionsSection } = props;
  const history = useHistory();

  const { name, members, uuid } = data;

  const {
    mainReducer: { activeWorkspaceUuid },
    extensionReducer: { isGlobalMetaFieldListLoading },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const shouldDisplaySkeleton = (content: any) => {
    if (isGlobalMetaFieldListLoading)
      return <Skeleton width={80} height={20} borderRadius={6} />;
    return content;
  };

  const getMembersAvatars = (membersList: any) => {
    if (membersList?.length > 0) {
      return (
        <StackedAvatars
          avatars={membersList?.map((member: any) => ({
            url: member?.avatarUrl,
          }))}
          maxAvatars={5}
        />
      );
    }
    return (
      <div className="global-meta-field-table-item__members-all">
        <IDHFormattedMessage
          id="ws_member_everyone"
          defaultMessage="Everyone"
        />
      </div>
    );
  };

  return (
    <div className="global-meta-field-table-item">
      <span className="global-meta-field-table-item__meta-field">
        {shouldDisplaySkeleton(name)}
      </span>
      <span className="global-meta-field-table-item__members">
        {shouldDisplaySkeleton(<>{getMembersAvatars(members)}</>)}
      </span>
      <span className="global-meta-field-table-item__action">
        {shouldDisplaySkeleton(
          <div
            className="global-meta-field-table-item__action-row"
            onClick={() => {
              changeSettingsObjectUuid(history, uuid);
              setExtensionsSection(
                CreatorDatabaseConfigurationSection.MetaFieldMembers,
              );
            }}
          >
            <EditIcon />
            <IDHFormattedMessage id="ws_edit" defaultMessage="Edit" />
          </div>,
        )}
      </span>
    </div>
  );
};
