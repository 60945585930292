import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

import AvatarPlaceholder from "src/images/avatar-purple.svg";

export const createUserOptions = (membersList, members) => {
  return membersList
    .filter(
      (identityMember) =>
        !members.find((user) => user.id === identityMember.id) &&
        identityMember &&
        identityMember?.status !== "removed",
    )
    .map((user) => ({
      label: (
        <div className="members__user-select-option">
          <img
            className="members__user-select-option__photo"
            src={user.avatarUrl || AvatarPlaceholder}
            alt="avatar"
          />
          <div className="members__user-select-option__details">
            <div className="members__user-select-option__details-name">
              {user.name}
            </div>
            {user.isClient && (
              <div className="members__user-select-option__details-client">
                <IDHFormattedMessage id="ws_client" defaultMessage="Client" />
              </div>
            )}
          </div>
        </div>
      ),
      value: { email: user.email, name: user.name, id: user.id },
      color: user.isClient ? "#F7911A" : "#6A6D7C",
      backgroundColor: user.isClient ? "#FFF5E9" : "#EDEDEF",
    }));
};
