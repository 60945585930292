import React, { useState } from "react";
import "./AudienceResultLoading.scss";
import { connect } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as RemoveIcon } from "src/images/close.svg";
import { ReactComponent as PlaceholderAvatar } from "src/images/members-grey.svg";
import { wsAxiosPost } from "src/helpers/wsAxios";
import Loader from "../../../components/Loader/Loader";
import CONSTS from "../../../helpers/consts";
import {
  getInfluencersListData,
  deleteAwaitingUsers,
} from "../../audiencesManagerActions";
import { CDTableColumn, CDTableRow } from "../../../components/CDTable/CDTable";
import WarningInfo from "./WarningInfo";

function AudienceResultLoading(props) {
  const {
    username,
    showAsNonExistent,
    status,
    id,
    listId,
    listSocialProvider,
    competitorsCheckEnabled,
    deleteAwaitingUsers: handleDeleteAwaitingUsers,
  } = props;
  const [deleteProcess, setDeleteProcess] = useState(false);

  const deleteNotExistentUser = async () => {
    setDeleteProcess(true);
    wsAxiosPost(
      CONSTS.deleteAwaitingInfluencersFromList,
      {
        listId,
        id,
      },
      () => {},
      () => {},
      () => {
        handleDeleteAwaitingUsers(id);
      },
      () => {
        setDeleteProcess(false);
      },
    );
  };

  const getReasonTranslationData = () => {
    switch (status) {
      case "FailedNotFound":
        return {
          id: "ws_audience_status_failed_not_found",
          defaultMessage: "The account has not been found.",
        };
      case "FailedRestricted":
        return {
          id: "ws_audience_status_failed_restricted",
          defaultMessage: "The account is restricted.",
        };
      case "FailedPrivate":
        return {
          id: "ws_audience_status_failed_private",
          defaultMessage: "The account is private.",
        };
      case "FailedTooSmall":
        return {
          id: "ws_audience_status_failed_too_small",
          defaultMessage: "The account has fewer than 1000 followers.",
        };
      case "FailedDataError":
        return {
          id: "ws_audience_status_failed_data_error",
          defaultMessage: "There is an issue with the account data.",
        };
      default:
        return {
          id: "ws_audience_status_failed_not_found",
          defaultMessage: "The account has not been found.",
        };
    }
  };

  const renderWarningInfoOrLoader = () => {
    if (showAsNonExistent) {
      const translationData = getReasonTranslationData();
      return (
        <WarningInfo
          error={
            <IDHFormattedMessage
              id="front_account_hasnt_been_added_to_the_list"
              defaultMessage="Account hasn't been added to the list."
            />
          }
          errorMessage={
            <IDHFormattedMessage
              id={translationData.id}
              defaultMessage={translationData.defaultMessage}
            />
          }
        />
      );
    }
    return <Loader grey />;
  };

  return (
    <CDTableRow className="audience-result-loading">
      <CDTableColumn className="cdt-table__checkbox" />
      <CDTableColumn>
        <PlaceholderAvatar width={80} height={80} />
      </CDTableColumn>
      <CDTableColumn>
        <div className="audience-result-loading__username">{username}</div>
      </CDTableColumn>
      {listSocialProvider === "instagram" && (
        <CDTableColumn>{!showAsNonExistent && <Loader grey />}</CDTableColumn>
      )}
      <CDTableColumn>{renderWarningInfoOrLoader()}</CDTableColumn>
      {competitorsCheckEnabled && (
        <CDTableColumn>{!showAsNonExistent && <Loader grey />}</CDTableColumn>
      )}
      <CDTableColumn>{!showAsNonExistent && <Loader grey />} </CDTableColumn>
      <CDTableColumn>
        {showAsNonExistent && !deleteProcess ? (
          <RemoveIcon
            className="audience-result-loading__remove-icon"
            onClick={deleteNotExistentUser}
          />
        ) : (
          <Loader grey />
        )}
      </CDTableColumn>
    </CDTableRow>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getInfluencersListData: (listId, pageSelected, sortBy) =>
      dispatch(getInfluencersListData(listId, pageSelected, sortBy)),
    deleteAwaitingUsers: (awaitingUserRow) =>
      dispatch(deleteAwaitingUsers(awaitingUserRow)),
  };
};

export default connect(null, mapDispatchToProps)(AudienceResultLoading);
