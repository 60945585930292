import React from "react";

import { PublicationStatus } from "src/types/publication";
import GatheringProfileData from "src/app/Task/tabs/ProfileDataTab/components/EmptyTabs/GatheringProfileData";
import { Statistics } from "./section/Statistics";
import PublicationNotDetected, {
  publicationNotDetectedStatuses,
} from "../ProfileDataTab/components/EmptyTabs/PublicationNotDetected";

import "./PublicationSummaryTab.scss";

function PublicationSummaryTab(props) {
  const {
    platform,
    platformDisplayName,
    statistics,
    synchronizable,
    synchronizationDate,
    status,
    setShowUpdatePublicationLinkModal,
    publicationUrl,
    taskDetailsLoading,
  } = props;

  if (publicationNotDetectedStatuses.includes(status)) {
    return (
      <PublicationNotDetected
        status={status}
        setShowUpdatePublicationLinkModal={setShowUpdatePublicationLinkModal}
      />
    );
  }

  if (status === PublicationStatus.Fresh && publicationUrl) {
    return <GatheringProfileData />;
  }

  return (
    <div className="publication-summary-tab">
      <Statistics
        platform={platform}
        platformDisplayName={platformDisplayName}
        statistics={statistics}
        synchronizable={synchronizable}
        synchronizationDate={synchronizationDate}
        taskDetailsLoading={taskDetailsLoading}
      />
    </div>
  );
}

export { PublicationSummaryTab };
