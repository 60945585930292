import React, { useState } from "react";

import { GetFreeSubscriptionModal } from "src/app/modals/GetFreeSubscriptionModal/GetFreeSubscriptionModal";
import FireIcon from "src/images/fire.png";
import SnackBar from "../components/SnackBar/SnackBar";
import { useSessionStorage } from "../methods/useSessionStorage";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";

export function FreeTrialSnackBarModal() {
  const [value, setValue] = useSessionStorage(
    "showGetFreeSubscriptionModal",
    true,
  );
  const [showGetFreeSubscriptionModal, setShowGetFreeSubscriptionModal] =
    useState(false);

  return (
    <>
      {value && (
        <SnackBar
          text={
            <>
              <IDHFormattedMessage
                id="ws_get_1_extra_moth_of_subscription"
                defaultMessage="Get 1 extra month of subscription for free!"
              />
              <img src={FireIcon} className="snack-bar__icon" />
            </>
          }
          buttonText={
            <IDHFormattedMessage id="ws_read_more" defaultMessage="Read more" />
          }
          buttonOnClick={() => setShowGetFreeSubscriptionModal(true)}
          hideSnackBar={() => setValue(false)}
        />
      )}
      {showGetFreeSubscriptionModal && (
        <GetFreeSubscriptionModal
          onClose={() => setShowGetFreeSubscriptionModal(false)}
        />
      )}
    </>
  );
}
