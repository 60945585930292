import React from "react";
import { GlobalTaskSearchResult } from "../../../TopSearch";

interface Props {
  data: GlobalTaskSearchResult;
}
export function GlobalTaskItemName(props: Props) {
  const { data } = props;
  return <span className="search-section__item-name">{data.title}</span>;
}
