import React, { memo, useMemo } from "react";
import { taskAvatarShape } from "src/app/components/OverviewFieldsTable/OverviewFieldsTable";
import { flexRender } from "@tanstack/react-table";
import classNames from "classnames";
import { RootStateOrAny, useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import { SortableHandle } from "react-sortable-hoc";
import { ReactComponent as DragIcon } from "src/images/grid-grey.svg";
import ActionDropdown from "../../../dropdowns/ActionDropdown/ActionDropdown";
import TasksTableField from "../../TableField/TasksTableField";
import TaskInfo from "./TaskInfo";
import { tableDataType } from "../../../components/Table/Table";
import { SkeletonText } from "../../../components/Skeleton/Skeleton";

const COLUMN_ID_BLACKLIST = [
  "select",
  "drag-handle",
  "creator",
  "content",
  "publication",
  "action",
  "selection",
  " action",
  "payment",
  "rightPadding",
  "leftPadding",
];

const COLUMN_ID_ALLOW_LIST = [
  "creator",
  "content",
  "publication",
  "action",
  "payment",
];

interface CellInterface {
  cell: any;
  rowData: any;
  rowId: string;
  rowIndex: number;
}

const Cell = memo(({ cell, rowData, rowId, rowIndex }: CellInterface) => {
  const activeWorkspaceUuid = useSelector(
    (state: RootStateOrAny) => state.mainReducer.activeWorkspaceUuid,
  );

  const clientMode = useSelector(
    (state: RootStateOrAny) => state.projectReducer.projectBasicData.clientMode,
  );

  const couponsList = useSelector(
    (state: RootStateOrAny) => state.couponReducer.couponsList,
  );

  const dictionaryAutocompleteLoading = useSelector(
    (state: RootStateOrAny) =>
      state.dictionaryReducer.dictionaryAutocompleteLoading,
  );

  const identity = useSelector(
    (state: RootStateOrAny) => state.mainReducer.identity,
  );

  const membersList = useSelector(
    (state: RootState) => state.projectReducer.activeMembersList,
  );

  const permissions = useSelector(
    (state: RootStateOrAny) =>
      state.projectReducer.projectBasicData.permissions,
  );

  const seeAsClientMode = useSelector(
    (state: RootStateOrAny) =>
      state.projectReducer.projectBasicData.seeAsClientMode,
  );

  const selectedTasks = useSelector(
    (state: RootStateOrAny) => state.taskReducer.selectedTasks,
  );

  const showShareMultipleColumnsModal = useSelector(
    (state: RootStateOrAny) => state.taskReducer.showShareMultipleColumnsModal,
  );

  const showStopSharingWithClientModal = useSelector(
    (state: RootStateOrAny) => state.taskReducer.showStopSharingWithClientModal,
  );

  const tasksLoading = useSelector(
    (state: RootStateOrAny) => state.taskReducer.tasksLoading,
  );

  const metaField = rowData.metadata.find(
    (el: any) => el.uuid === cell.column.id,
  );

  const MetaField = useMemo(() => {
    const fieldData = metaField?.overrideValue || metaField;

    const fieldValue = fieldData?.value?.date
      ? fieldData?.value?.date
      : fieldData?.value; // refactor

    if (tasksLoading) {
      return <SkeletonText width={60} height={15} />;
    }

    if (fieldData) {
      return (
        <TasksTableField
          activeWorkspaceUuid={activeWorkspaceUuid}
          columnKey={cell.column.columnDef.columnFromApi.metaFieldKey}
          columnName={cell.column.columnDef.columnFromApi.metaFieldName}
          couponsList={couponsList}
          dataType={tableDataType.Task}
          fieldData={fieldData}
          fieldValue={fieldValue === "" ? null : fieldValue}
          membersList={membersList}
          objectId={rowId}
          rowId={rowId}
          selectedTasks={selectedTasks}
          title={rowData.title}
          overrideValue={Boolean(metaField?.overrideValue)}
          blockManualEdit={Boolean(metaField?.blockManualEdit)}
        />
      );
    }

    return null;
  }, [
    metaField,
    cell.column.columnFromApi,
    tasksLoading,
    dictionaryAutocompleteLoading,
    selectedTasks,
    couponsList,
  ]);

  const DragHandle = SortableHandle(() => {
    if (tasksLoading) return <> </>;

    return <DragIcon style={{ cursor: "pointer" }} />;
  });

  const renderCheckbox = () => {
    if (tasksLoading) return null;

    return (
      <span
        className={classNames("tasks-table__td-checkbox", {
          "tasks-table__td-checkbox--checked": cell.row.getIsSelected(),
        })}
      >
        <span className="tasks-table__td-checkbox-index">{rowIndex + 1}</span>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </span>
    );
  };

  return (
    <>
      {cell.column.id === "drag-handle" && <DragHandle />}

      {cell.column.id === "select" && renderCheckbox()}

      {cell.column.id === " action" && (
        <ActionDropdown
          activeWorkspaceUuid={activeWorkspaceUuid}
          clientMode={clientMode}
          globalTaskUuid={rowData?.wsGlobalTaskUuid}
          identity={identity}
          permissions={permissions}
          seeAsClientMode={seeAsClientMode}
          taskId={rowId}
          taskName={rowData.title}
          taskShared={rowData.shared}
          taskType={rowData.taskType}
          titleFieldId={rowData.titleMetaFieldId}
        />
      )}

      {COLUMN_ID_ALLOW_LIST.includes(cell.column.id) && (
        <TaskInfo
          activeWorkspaceUuid={activeWorkspaceUuid}
          clientMode={clientMode}
          dataType={tableDataType.Task}
          disableLink
          item={rowData}
          parentLoading={false}
          permissions={permissions}
          showShareMultipleColumnsModal={showShareMultipleColumnsModal}
          showStopSharingWithClientModal={showStopSharingWithClientModal}
          taskAvatarShape={taskAvatarShape.Circle}
          taskType={rowData.taskType}
          tasksLoading={tasksLoading}
        />
      )}
      {!COLUMN_ID_BLACKLIST.includes(cell.column.id) && MetaField}
    </>
  );
});

export default Cell;
