import React from "react";
import "./ProjectsList.scss";
import { useSelector } from "react-redux";

import ProjectsTable from "./partials/ProjectsTable/ProjectsTable";
import { generateRows } from "./utils/generateRows";
import { generateColumns } from "./utils/generateColumns";

export default function ProjectsList({
  projects,
  columns,
  setShowCreateProjectModal,
  listLoading,
  filtersRefreshing,
  filters,
}) {
  const hiddenColumns = useSelector(
    (state) => state.projectFiltersReducer.hiddenColumns,
  );

  return (
    <div className="projects-list-data">
      <ProjectsTable
        data={generateRows(projects, columns)}
        columns={generateColumns(columns, hiddenColumns)}
        setShowCreateProjectModal={setShowCreateProjectModal}
        listLoading={listLoading}
        filtersRefreshing={filtersRefreshing}
        filters={filters}
      />
    </div>
  );
}
