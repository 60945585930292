import React, { useState } from "react";
import { isEmpty } from "lodash";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { TASK_FIELDS_BLACKLIST } from "src/app/Task/components/TaskMetaFields/TaskMetaFields";
import { DictionaryMetaField } from "../DictionaryMetaField/DictionaryMetaField";

import "./DictionaryMetadata.scss";

interface Props {
  elementUuid: string;
  metadata: any;
}

export default function DictionaryMetadata(props: Props) {
  const { elementUuid, metadata = [] } = props;

  const [showMore, setShowMore] = useState(false);

  const moreFields = metadata?.filter(
    (field: any) => !TASK_FIELDS_BLACKLIST.includes(field.key),
  );
  const lessFields = metadata.filter((field: any) => !isEmpty(field.value));

  return (
    <section className="dictionary-metadata">
      {(showMore ? metadata : lessFields)
        .sort((a: { rank: string }, b: { rank: string }) =>
          a.rank.localeCompare(b.rank),
        )
        .map((field: any) => (
          <DictionaryMetaField
            field={field}
            elementUuid={elementUuid}
            key={field.uuid}
          />
        ))}
      {moreFields.length > lessFields.length && (
        <div
          className="dictionary-metadata__show-more"
          onClick={() => setShowMore((prev) => !prev)}
        >
          {showMore ? (
            <IDHFormattedMessage id="ws_show_less" defaultMessage="Show less" />
          ) : (
            <IDHFormattedMessage id="ws_show_more" defaultMessage="Show more" />
          )}
        </div>
      )}
    </section>
  );
}
