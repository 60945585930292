import React from "react";
import "../SearchSection.scss";

import classNames from "classnames";
import { useHistory } from "react-router";

import { TaskItemCover } from "./components/TaskItemCover";
import { TaskItemName } from "./components/TaskItemName";

export function TasksResults(props) {
  const { data, clearResults } = props;

  if (!data) {
    return null;
  }

  const filteredTasks = Object.entries(data)
    .map((e) => ({
      name: e[0],
      data: e[1],
    }))
    .filter((item) => item.data.length);
  const history = useHistory();

  const openTask = (
    workspaceUuid,
    projectUuid,
    taskUuid,
    hasAccessToProject,
  ) => {
    clearResults();
    if (hasAccessToProject === false) {
      history.push(
        `/workspace/${workspaceUuid}/my-actions/${projectUuid}/${taskUuid}`,
      );
    } else {
      history.push(
        `/workspace/${workspaceUuid}/projects/${projectUuid}/${taskUuid}`,
      );
    }
  };

  return (
    <div>
      {filteredTasks.map((item, index) => (
        <div
          key={item.name}
          className={classNames("search-section", {
            "search-section--with-separator": data.length - 1 !== index,
          })}
        >
          <h4 className="search-section__header">
            {item.name === "creators" ? "creators in campaigns" : item.name}
          </h4>

          {item.data.map((task) => (
            <div
              key={task.wsTaskUuid}
              className="search-section__item"
              onClick={() =>
                openTask(
                  task.workspaceUuid,
                  task.projectUuid,
                  task.wsTaskUuid,
                  task.hasAccessToProject,
                )
              }
            >
              <TaskItemCover data={task} />
              <TaskItemName data={task} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
