import React from "react";

import { TaskType } from "src/types";
import { IMember } from "src/app/project/ProjectTypes";
import {
  IContentProposalElementComment,
  ContentProposalElementType,
} from "src/redux/comment/types";
import { ReactComponent as UploadIcon } from "src/images/upload.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import CommentWrapper from "./CommentWrapper";
import CommentText, { decodeComment } from "./CommentText";
import ReplyToCommentButton from "./ReplyToCommentButton";
import ContentProposalItem from "./ContentProposalItem";

import "./ContentProposalElementComment.scss";

interface ContentProposalElementCommentProps {
  comment: IContentProposalElementComment;
  commentAuthor: IMember | undefined;
  taskType: TaskType;
  hasAccessToRemoveWsTaskComment: boolean;
  membersList: IMember[];
}

export default function ContentProposalElementComment({
  comment,
  commentAuthor,
  taskType,
  hasAccessToRemoveWsTaskComment,
  membersList,
}: ContentProposalElementCommentProps) {
  const hasCaption = comment.contentProposalElements.some(
    (element) => element.type === ContentProposalElementType.Caption,
  );
  const hasMedia = comment.contentProposalElements.some(
    (element) =>
      element.type === ContentProposalElementType.Media ||
      element.type === ContentProposalElementType.ExternalUrl,
  );

  const firstMediaElementIndex = comment.contentProposalElements.findIndex(
    (item) =>
      item.type === ContentProposalElementType.ExternalUrl ||
      item.type === ContentProposalElementType.Media,
  );

  return (
    <CommentWrapper
      comment={comment}
      commentId={comment.uuid}
      commentAuthor={commentAuthor}
      commentAuthorCustomElement={
        <div className="comment-header-element__action-info">
          <UploadIcon />
          {(hasMedia || hasCaption) && (
            <div>
              <IDHFormattedMessage id="ws_added" defaultMessage="added" />{" "}
              {hasMedia && (
                <IDHFormattedMessage id="ws_content" defaultMessage="Content" />
              )}
              {hasMedia && hasCaption && (
                <>
                  {" "}
                  <IDHFormattedMessage id="ws_and" defaultMessage="and" />{" "}
                </>
              )}
              {hasCaption && (
                <IDHFormattedMessage id="ws_caption" defaultMessage="Caption" />
              )}
            </div>
          )}
        </div>
      }
      hasAccessToRemoveWsTaskComment={hasAccessToRemoveWsTaskComment}
    >
      <>
        {comment.comment.length > 0 && (
          <CommentText>
            <span
              className="mentionText"
              dangerouslySetInnerHTML={{
                __html: decodeComment(comment.comment, membersList),
              }}
            />
            <ReplyToCommentButton
              wsTaskUuid={comment.wsTaskUuid}
              parentWsTaskCommentUuid={comment.uuid}
              replyToCommentText={comment.comment}
            />
          </CommentText>
        )}

        {comment.contentProposalElements.map(
          (contentProposalElement, index) => (
            <ContentProposalItem
              key={contentProposalElement.uuid}
              data={contentProposalElement}
              taskType={taskType}
              comment={comment}
              currentElementIndex={index}
              firstMediaElementIndex={firstMediaElementIndex}
            />
          ),
        )}
      </>
    </CommentWrapper>
  );
}
