import React from "react";

import { getImageSource } from "src/utils/methods";
import AvatarPlaceholder from "src/images/avatar-purple.svg";
import { PaymentDefaultValues } from "src/types";
import { ReactComponent as PaymentIcon } from "src/images/payment-file.svg";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

interface PaymentRecipientProps {
  paymentDefaultValues: PaymentDefaultValues;
  paymentsHistoryUrl: string;
}

export default function PaymentRecipient({
  paymentDefaultValues,
  paymentsHistoryUrl,
}: PaymentRecipientProps) {
  return (
    <div className="send-payment-modal__recipient-name send-payment-modal__text send-payment-modal__text--small">
      <div className="send-payment-modal__recipient-name">
        <span className="action-field action-field--circle">
          <img
            src={getImageSource(
              paymentDefaultValues.creator.cover,
              "tiny",
              AvatarPlaceholder,
            )}
            alt="avatar"
          />
        </span>
        <span>{paymentDefaultValues.creator.title}</span>
      </div>
      <span className="send-payment-modal__square-separator" />
      <TooltipPortal
        content={
          <div>
            <strong>
              <IDHFormattedMessage
                id="ws_payments_history"
                defaultMessage="Payments history"
              />
            </strong>
            <br />
            <IDHFormattedMessage
              id="ws_payments_history_description"
              defaultMessage="Check payments history for this creator"
            />
          </div>
        }
      >
        <a href={paymentsHistoryUrl} target="_blank" rel="noopener noreferrer">
          <PaymentIcon />
        </a>
      </TooltipPortal>
    </div>
  );
}
