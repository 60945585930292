import React from "react";

import { RecentlyPublished } from "../../../../Task/tabs/ProfileDataTab/single/RecentlyPublished";

export const renderRecentlyPublished = (
  label,
  data,
  socialUrl,
  layout,
  GridBoxComponent,
  recentlyPublishedReady,
  setRecentlyPublishedReady,
) => {
  const { lastPostsData } = data;

  const key = `${label}-recently-published`;
  const height = layout?.find((item) => item.i === key)?.h;

  return (
    <GridBoxComponent key={key} id={key}>
      <RecentlyPublished
        lastPostsData={lastPostsData}
        socialUrl={socialUrl}
        height={height}
        provider={label}
        recentlyPublishedReady={recentlyPublishedReady}
        setRecentlyPublishedReady={setRecentlyPublishedReady}
      />
    </GridBoxComponent>
  );
};
