import React from "react";
import "./TeamList.scss";

import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalTitle } from "src/app/components/Modal/Modal";

import { ReactComponent as PlusIcon } from "src/images/plus-transparent.svg";
import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import { TeamsSection } from "../../Teams";
import { EmptyTeams } from "../EmptyTeams/EmptyTeams";
import { Team } from "../../utils";
import { TeamOnList } from "./components/TeamOnList";

interface Props {
  teams: Team[];
  loading: boolean;
  getTeams: () => void;
  setSection: (newSection: TeamsSection) => void;
  setObjectUuid: (objectUuid: string) => void;
  onClose: () => void;
}

export const TeamList: React.FC<Props> = (props) => {
  const { teams, loading, getTeams, setSection, setObjectUuid, onClose } =
    props;

  const createTeamClick = () => {
    setSection(TeamsSection.AddTeam);
  };

  const teamNameClick = (teamUuid: string) => {
    setSection(TeamsSection.TeamDetails);
    setObjectUuid(teamUuid);
  };

  return (
    <>
      <AnimatedDiv className="team-list">
        <ModalTitle>
          <IDHFormattedMessage id="ws_teams" defaultMessage="Teams" />

          {teams.length > 0 ? (
            <Button variant="blue" size="medium" onClick={createTeamClick}>
              <PlusIcon />
              <IDHFormattedMessage
                id="ws_create_team"
                defaultMessage="Create team"
              />
            </Button>
          ) : null}
        </ModalTitle>

        {!teams.length && !loading ? (
          <EmptyTeams createTeamClick={createTeamClick} />
        ) : (
          <div className="team-list__table">
            <div className="team-list__table-head">
              <span className="team-list__table-head-el">
                <IDHFormattedMessage id="ws_name" defaultMessage="Name" />
              </span>
              <span className="team-list__table-head-el">
                <IDHFormattedMessage id="ws_members" defaultMessage="Members" />
              </span>
              <span className="team-list__table-head-el" />
            </div>

            <div className="team-list__table-body">
              {teams.map((team) => (
                <TeamOnList
                  key={team.uuid}
                  team={team}
                  teamNameClick={teamNameClick}
                  getTeams={getTeams}
                />
              ))}
            </div>
          </div>
        )}
      </AnimatedDiv>

      <SettingsButtons onClose={onClose} />
    </>
  );
};
