import React, { useEffect, useState } from "react";
import "./CouponGroupView.scss";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "src/app/components/Skeleton/Skeleton";
import { Link } from "react-router-dom";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "src/app/components/Button/Button";
import {
  getCouponGroupDetails,
  setCouponsLoading,
} from "src/redux/coupon/couponActions";

import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";
import { RootState } from "src/redux/reducers";
import { CodesTable } from "../CodesTable/CodesTable";
import { AddCouponsModal } from "../../modals";
import { CouponGroupSettingsDropdown } from "../../dropdowns/CouponGroupSettingsDropdown/CouponGroupSettingsDropdown";

export function CouponGroupView() {
  const [showAddCouponsModal, setShowAddCouponsModal] = useState(false);

  const {
    couponReducer: { couponGroupDetails, detailsLoading },
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const { couponGroupId } = useParams<{ couponGroupId: string }>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (couponGroupId) {
      dispatch(getCouponGroupDetails(couponGroupId));
    }
  }, [couponGroupId]);

  const GoBackButton = (
    <>
      {detailsLoading ? (
        <Skeleton width={110} height={16} borderRadius={8} />
      ) : (
        <Link
          className="coupon-group-view__go-back-button"
          to={`/workspace/${activeWorkspaceUuid}/coupon-groups`}
          onClick={() => dispatch(setCouponsLoading(true))}
        >
          <IDHFormattedMessage
            id="ws_coupon_groups"
            defaultMessage="Coupon groups"
          />
        </Link>
      )}
    </>
  );

  return (
    <div className="coupon-group-view">
      {GoBackButton}
      <div className="coupon-group-view__topbar">
        {detailsLoading ? (
          <Skeleton width={160} height={32} borderRadius={8} />
        ) : (
          <div>
            <span className="coupon-group-view__group-name">
              {couponGroupDetails.name}
            </span>

            <CouponGroupSettingsDropdown
              couponGroupUuid={couponGroupDetails.uuid}
              groupData={couponGroupDetails}
              buttonSize="small"
            />
          </div>
        )}

        {!detailsLoading && (
          <div>
            <Button
              variant="blue"
              size="large"
              onClick={() => setShowAddCouponsModal(true)}
            >
              <PlusIcon />
              <IDHFormattedMessage
                id="ws_add_coupons"
                defaultMessage="Add Coupons"
              />
            </Button>
          </div>
        )}
      </div>

      <CodesTable viewType="coupon-list" />

      {showAddCouponsModal && (
        <AddCouponsModal onClose={() => setShowAddCouponsModal(false)} />
      )}
    </div>
  );
}
