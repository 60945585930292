import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router";
import moment from "moment";
import AddButton, {
  AddButtonVariant,
} from "../../../../../components/AddButton/AddButton";
import { ReactComponent as EditIcon } from "../../../../../../images/edit.svg";
import { API_URLS } from "../../../../../../utils/API_URLS";
import { getTaskDetails } from "../../../../../../redux";
import { splitNumbers, splitPercents } from "../../../../../../utils/methods";
import { wsAxiosPatch } from "../../../../../../helpers/wsAxios";
import { convertMinutesToHours } from "../../../../../methods/convertMinutesToHours";

function StatisticValue(props) {
  const {
    editable,
    value,
    showInput,
    setShowInput,
    statistic,
    platform,
    isCompound,
  } = props;

  useEffect(() => {
    setInputValue(value !== null ? String(value) : "");
  }, [value]);

  const [inputValue, setInputValue] = useState("");

  const { taskId } = useParams();
  const inputEl = useRef(null);
  const span = useRef(null);
  const dispatch = useDispatch();

  const onButtonClick = () => {
    setShowInput(true);
    setTimeout(() => {
      inputEl.current.focus();
    }, 200);

    // Kacper TODO create useFocus hook
  };

  const hideInput = () => {
    setShowInput(false);
    if (Number(inputValue) >= 0) {
      setInputValue(Number(inputValue).toFixed());
    } else if (
      Number(inputValue) < 0 ||
      (value === null && inputValue === "")
    ) {
      setInputValue(String(value));
      return;
    }

    if (value === null || value.toString() !== Number(inputValue).toFixed()) {
      changeStatisticValue();
    }
  };

  const changeStatisticValue = async () => {
    wsAxiosPatch(
      API_URLS.patchPublicationStatistic,
      {
        taskUuid: taskId,
        statistic,
        value: inputValue === "" ? null : Number(inputValue).toFixed(),
      },
      `Cannot change ${platform} ${statistic} value`,
    );
    dispatch(getTaskDetails(taskId));
  };

  const renderValue = (value) => {
    if (statistic.includes("sentiment")) {
      return `${splitPercents(value || 0)}%`;
    }

    if (statistic === "avgViewPercentage") {
      return `${value}%`;
    }
    if (statistic === "minutesWatched") {
      return convertMinutesToHours(value);
    }
    if (value % 1 !== 0) {
      return `${splitPercents(value)}%`;
    }

    return splitNumbers(value);
  };

  // const handleInputChange = (e) => {
  //   if(e.target.value === "") {
  //     setInputValue(e.target.value === "" ? e.target.value : Number(e.target.value).toFixed())
  //   } else
  // }

  // Kacper TODO add div task-statistic and statistic icon

  return (
    <>
      {showInput ? (
        <input
          type="number"
          onBlur={hideInput}
          className="task-statistic__input"
          value={inputValue}
          onKeyPress={(e) => {
            e.key === "Enter" && hideInput();
          }}
          onInput={(e) => setInputValue(e.target.value)}
          ref={inputEl}
        />
      ) : editable && value === null && !showInput ? (
        <AddButton variant={AddButtonVariant.Rect} onClick={onButtonClick} />
      ) : (
        <div className="task-statistic__value">{renderValue(inputValue)}</div>
      )}

      {editable && !showInput && (
        <EditIcon className="task-statistic__edit" onClick={onButtonClick} />
      )}
    </>
  );
}

export { StatisticValue };
