import React from "react";
import "./ActionCell.scss";

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";

import { ReactComponent as DotsIcon } from "src/images/settings-horizontal.svg";
import { ReactComponent as ManageIcon } from "src/images/edit.svg";
import { ReactComponent as PublishIcon } from "src/images/publish.svg";
import { ReactComponent as UnpublishIcon } from "src/images/unpublish.svg";
import { ReactComponent as DuplicateIcon } from "src/images/duplicate.svg";
import { ReactComponent as DeleteIcon } from "src/images/trash-can.svg";
import { ReactComponent as XlsIcon } from "src/images/xls.svg";
import { ReactComponent as PptxIcon } from "src/images/pptx.svg";
import { ReactComponent as PdfIcon } from "src/images/pdf.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "src/app/components/Button/Button";
import axios from "axios";
import { showToast } from "src/app/methods/showToast";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import { useSelector } from "react-redux";
import CONSTS from "../../../../helpers/consts";

export function ActionCell({
  listItem,
  goToDetails,
  onDuplicateClick,
  onDeleteListClick,
  exportList,
  published,
  setPublished,
}) {
  const {
    audiencesManagerReducer: { hasAccessToFullExports },
  } = useSelector((state) => state);

  const changeListVisibility = () => {
    const newPublished = !published;
    setPublished(newPublished);
    axios
      .post(CONSTS.changeListVisibility, {
        listId: listItem.listId,
        isPublic: newPublished,
      })
      .catch((error) => {
        error?.response?.data?.content?.errors[0] &&
          showToast("error", "Error", error.response.data.content.errors[0]);
        setPublished(!newPublished);
      })
      .then((res) => {
        res &&
          showToast(
            "success",
            "Success",
            <IDHFormattedMessage id={res.data.content} />,
          );
      });
  };

  const exportXls = () => {
    showToast(
      "info",
      <IDHFormattedMessage id="ws_info" defaultMessage="Info" />,
      <IDHFormattedMessage
        id="ws_downloading_in_progress"
        defaultMessage="Downloading in progress..."
      />,
    );
    exportList("xls", listItem);
  };

  return (
    <Dropdown toRight>
      {(isOpen, setIsOpen) => (
        <>
          <Button
            variant="light-grey"
            size="large"
            onClick={() => setIsOpen(true)}
            className="influencers-list__actions-button"
            data-list-id={listItem.listId}
            data-list-name={listItem.listName}
          >
            <DotsIcon />
          </Button>
          {isOpen && (
            <DropdownMenu className="influencers-list__actions-dropdown__menu">
              <DropdownMenuItem>
                <div
                  className="influencers-list__action"
                  onClick={() => goToDetails(listItem.listId)}
                >
                  <div className="influencers-list__action-icon">
                    <ManageIcon />
                  </div>
                  <IDHFormattedMessage id="ws_edit" defaultMessage="Edit" />
                </div>
              </DropdownMenuItem>
              {listItem.canManageList && (
                <DropdownMenuItem>
                  <div
                    className="influencers-list__action"
                    onClick={() => changeListVisibility()}
                  >
                    {published ? (
                      <>
                        <div className="influencers-list__action-icon">
                          <UnpublishIcon />
                        </div>
                        <IDHFormattedMessage
                          id="ws_unpublish"
                          defaultMessage="Unpublish"
                        />
                      </>
                    ) : (
                      <>
                        <div className="influencers-list__action-icon">
                          <PublishIcon />
                        </div>
                        <IDHFormattedMessage
                          id="ws_publish"
                          defaultMessage="Publish"
                        />
                      </>
                    )}
                  </div>
                </DropdownMenuItem>
              )}
              {listItem.socialProvider !== "snapchat" && (
                <>
                  <MaterialTooltip
                    center
                    className="influencers-list__action-tooltip"
                    content={
                      hasAccessToFullExports && listItem.usersCount >= 500 ? (
                        <IDHFormattedMessage
                          id="front_creator_list_limit_tooltip_xlsx"
                          defaultMessage="Number of creator is limited to 500 for XLSX"
                        />
                      ) : (
                        <IDHFormattedMessage
                          id="front_creator_list_limit_tooltip"
                          defaultMessage="Number of creator is limit - export file will contain creators on first list page"
                        />
                      )
                    }
                    contentHidden={
                      listItem?.usersCount <= CONSTS.audienceListPageSize ||
                      (hasAccessToFullExports && listItem.usersCount < 500)
                    }
                  >
                    <DropdownMenuItem disabled={listItem.usersCount === 0}>
                      <div
                        className="influencers-list__action"
                        onClick={exportXls}
                      >
                        <div className="influencers-list__action-icon">
                          <XlsIcon />
                        </div>
                        <IDHFormattedMessage
                          id="ws_download"
                          defaultMessage="Download"
                        />
                        <span className="influencers-list__action-ext">
                          .XLSX
                        </span>
                      </div>
                    </DropdownMenuItem>
                  </MaterialTooltip>

                  <MaterialTooltip
                    center
                    className="influencers-list__action-tooltip"
                    content={
                      <IDHFormattedMessage
                        id="front_creator_list_limit_tooltip"
                        defaultMessage="Number of creator is limit - export file will contain creators on first list page"
                      />
                    }
                    contentHidden={
                      listItem?.usersCount <= CONSTS.audienceListPageSize
                    }
                  >
                    <DropdownMenuItem disabled={listItem.usersCount === 0}>
                      <div
                        className="influencers-list__action"
                        onClick={() => exportList("pptx", listItem)}
                      >
                        <div className="influencers-list__action-icon">
                          <PptxIcon />
                        </div>
                        <IDHFormattedMessage
                          id="ws_download"
                          defaultMessage="Download"
                        />
                        <span className="influencers-list__action-ext">
                          .PPTX
                        </span>
                      </div>
                    </DropdownMenuItem>

                    <DropdownMenuItem disabled={listItem.usersCount === 0}>
                      <div
                        className="influencers-list__action"
                        onClick={() => exportList("pdf", listItem)}
                      >
                        <div className="influencers-list__action-icon">
                          <PdfIcon />
                        </div>
                        <IDHFormattedMessage
                          id="ws_download"
                          defaultMessage="Download"
                        />
                        <span className="influencers-list__action-ext">
                          {" .PDF"}
                        </span>
                      </div>
                    </DropdownMenuItem>
                  </MaterialTooltip>
                </>
              )}
              <DropdownMenuItem>
                <div
                  className="influencers-list__action"
                  onClick={() => {
                    onDuplicateClick(listItem.listName, listItem.listId);
                  }}
                >
                  <div className="influencers-list__action-icon">
                    <DuplicateIcon />
                  </div>
                  <IDHFormattedMessage
                    id="front_duplicate"
                    defaultMessage="Duplicate"
                  />
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <div
                  className="influencers-list__action influencers-list__action--danger"
                  onClick={() => {
                    onDeleteListClick(listItem.listId);
                  }}
                >
                  <div className="influencers-list__action-icon">
                    <DeleteIcon />
                  </div>
                  <IDHFormattedMessage id="ws_remove" defaultMessage="Remove" />
                </div>
              </DropdownMenuItem>
            </DropdownMenu>
          )}
        </>
      )}
    </Dropdown>
  );
}
