// Task
export const CREATE_SUBTASK = "CREATE_SUBTASK";
export const CREATE_EMPTY_SUBTASK = "CREATE_EMPTY_SUBTASK";
export const DELETE_EMPTY_SUBTASK = "DELETE_EMPTY_SUBTASK";
export const GET_TASK_DETAILS = "GET_TASK_DETAILS";
export const GET_TASK_DETAILS_FAIL = "GET_TASK_DETAILS_FAIL";
export const GET_TASK_DETAILS_SUCCESS = "GET_TASK_DETAILS_SUCCESS";
export const PUBLICATION_SYNCHRONIZATION_IN_PROGRESS =
  "PUBLICATION_SYNCHRONIZATION_IN_PROGRESS";
export const PUBLICATION_SYNCHRONIZATION_DONE =
  "PUBLICATION_SYNCHRONIZATION_DONE";
export const GET_SINGLE_TASK_FOR_LIST = "GET_SINGLE_TASK_FOR_LIST";
export const GET_SINGLE_TASK_FOR_LIST_SUCCESS =
  "GET_SINGLE_TASK_FOR_LIST_SUCCESS";
export const SET_TASKS_LIST = "SET_TASKS_LIST";
export const SET_SELECTED_TASKS = "SET_SELECTED_TASKS";
export const CHANGE_SUBTASK_NAME = "CHANGE_SUBTASK_NAME";
export const UPDATE_SUBTASK_RANK = "UPDATE_SUBTASK_RANK";
export const GET_MY_TASKS = "GET_MY_TASKS";
export const GET_MY_TASKS_SUCCESS = "GET_MY_TASKS_SUCCESS";
export const GET_SINGLE_TASK_FOR_MY_TASKS_LIST =
  "GET_SINGLE_TASK_FOR_MY_TASKS_LIST";
export const GET_SINGLE_TASK_FOR_MY_TASKS_LIST_SUCCESS =
  "GET_SINGLE_TASK_FOR_MY_TASKS_LIST_SUCCESS";
export const CLEAR_TASK_DETAILS = "CLEAR_TASK_DETAILS";
export const DELETE_NEW_SUBTASK_WITHOUT_TITLE =
  "DELETE_NEW_SUBTASK_WITHOUT_TITLE";
export const OPEN_ADD_TASK_MODAL = "OPEN_ADD_TASK_MODAL";
export const CLOSE_ADD_TASK_MODAL = "CLOSE_ADD_TASK_MODAL";
export const OPEN_ADD_CREATORS_MODAL = "OPEN_ADD_CREATORS_MODAL";
export const CLOSE_ADD_CREATORS_MODAL = "CLOSE_ADD_CREATORS_MODAL";
export const OPEN_ADD_PUBLICATION_MODAL = "OPEN_ADD_PUBLICATION_MODAL";
export const CLOSE_ADD_PUBLICATION_MODAL = "CLOSE_ADD_PUBLICATION_MODAL";
export const OPEN_STOP_SHARING_WITH_CLIENT_MODAL =
  "OPEN_STOP_SHARING_WITH_CLIENT_MODAL";
export const OPEN_STOP_SHARING_GROUP_WITH_CLIENT_MODAL =
  "OPEN_STOP_SHARING_GROUP_WITH_CLIENT_MODAL";
export const HIDE_STOP_SHARING_WITH_CLIENT_MODAL =
  "HIDE_STOP_SHARING_WITH_CLIENT_MODAL";
export const OPEN_SHARE_MULTIPLE_COLUMNS_MODAL =
  "OPEN_SHARE_MULTIPLE_COLUMNS_MODAL";
export const OPEN_SHARE_GROUP_MULTIPLE_COLUMNS_MODAL =
  "OPEN_SHARE_GROUP_MULTIPLE_COLUMNS_MODAL";
export const HIDE_SHARE_MULTIPLE_COLUMNS_MODAL =
  "HIDE_SHARE_MULTIPLE_COLUMNS_MODAL";

// new
export const UPDATE_TASK_META_FIELD = "UPDATE_TASK_META_FIELD";
export const ADD_TASK_META_FIELD = "ADD_TASK_META_FIELD";
export const ADD_TASK_META_FIELD_SUCCESS = "ADD_TASK_META_FIELD_SUCCESS";
export const ADD_TASK_META_FIELD_FAIL = "ADD_TASK_META_FIELD_FAIL";
export const SET_TASK_FIELD_ADDED_OR_UPDATED =
  "SET_TASK_FIELD_ADDED_OR_UPDATED";
export const CHANGE_TASK_FIELD_VALUE = "CHANGE_TASK_FIELD_VALUE";
export const CHANGE_TASK_FIELD_VALUE_FAIL = "CHANGE_TASK_FIELD_VALUE_FAIL";
export const CHANGE_TASK_GROUP_FIELD_VALUE = "CHANGE_TASK_GROUP_FIELD_VALUE";
export const UPDATE_TASK_META_FIELD_SUCCESS = "UPDATE_TASK_META_FIELD_SUCCESS";
export const UPDATE_TASK_META_FIELD_RANK = "UPDATE_TASK_META_FIELD_RANK";
export const UPDATE_TASK_META_FIELD_VISIBILITY =
  "UPDATE_TASK_META_FIELD_VISIBILITY";
export const UPDATE_TASK_SINGLE_SELECT_FIELD =
  "UPDATE_TASK_SINGLE_SELECT_FIELD";
export const SET_TASKS_LOADING = "SET_TASKS_LOADING";
export const SET_GALLERY_SOURCES = "SET_GALLERY_SOURCES";
export const CLEAR_MY_TASKS_LIST = "CLEAR_MY_TASKS_LIST";
export const CLEAR_TASK_LIST = "CLEAR_TASK_LIST";
export const CREATE_GLOBAL_TASK_META_FIELD = "CREATE_GLOBAL_TASK_META_FIELD";
export const CREATE_GLOBAL_TASK_META_FIELD_SUCCESS =
  "CREATE_GLOBAL_TASK_META_FIELD_SUCCESS";

export const LOCAL_SET_COLUMNS_SHARING = "LOCAL_SET_COLUMNS_SHARING";
export const LOCAL_SET_COLUMNS_GROUP_SHARING =
  "LOCAL_SET_COLUMNS_GROUP_SHARING";
export const LOCAL_SET_TASKS_SHARING = "LOCAL_SET_TASKS_SHARING";
export const CLEAR_TASK_ERROR_MESSAGES = "CLEAR_TASK_ERROR_MESSAGES";
export const GET_MY_TASKS_ERROR = "GET_MY_TASKS_ERROR";
export const SET_TABLE_INPUT_FOCUSED = "SET_TABLE_INPUT_FOCUSED";
export const SET_LAST_SELECTED_ROW_ID = "SET_LAST_SELECTED_ROW_ID";

export const DELETE_SELECTED_TASKS = "DELETE_SELECTED_TASKS";
export const DELETE_SELECTED_TASKS_SUCCESS = "DELETE_SELECTED_TASKS_SUCCESS";
export const DELETE_SELECTED_TASKS_FAIL = "DELETE_SELECTED_TASKS_FAIL";
