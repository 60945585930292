import classNames from "classnames";
import React from "react";
import SingleMetaField from "src/app/Task/components/TaskMetaFields/SingleMetaField";
import { TaskMetaFieldName } from "src/app/Task/components/TaskMetaFields/TaskMetaFieldName";
import { HIGHER_FIELDS } from "src/app/Task/components/TaskMetaFields/TaskMetaFields";
import { tableDataType } from "src/app/components/Table/Table";

interface Props {
  field: any;
  elementUuid: string;
}

export const DictionaryMetaField: React.FC<Props> = (props) => {
  const { field, elementUuid } = props;

  return (
    <div
      className={classNames("dictionary-metadata__row", {
        "dictionary-metadata__row--high": HIGHER_FIELDS.includes(field.type),
      })}
      key={field.uuid}
      data-column-name={field.name}
      data-column-key={field.uuid}
    >
      <TaskMetaFieldName field={field} />
      <SingleMetaField
        metaData={field}
        dataType={tableDataType.Dictionary}
        externalTaskId={elementUuid}
      />
    </div>
  );
};
