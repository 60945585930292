import React, { useEffect, useState } from "react";
import "./PersonProfilePicture.scss";
import axios from "axios";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { TaskType } from "src/types";
import { RootState } from "src/redux/reducers";
import { ReactComponent as PlusIcon } from "../../../images/plus-transparent.svg";
import { ReactComponent as SettingsIcon } from "../../../images/settings-v.svg";
import PlaceholderImage from "../../../images/placeholder-image.svg";
import publicationPlaceholder from "../../../images/publication-placeholder-grey.svg";
import { API_URLS } from "../../../utils/API_URLS";
import { Dropdown, DropdownMenu, DropdownMenuItem } from "../Dropdown/Dropdown";
import { showToast } from "../../methods/showToast";
import Skeleton, { SkeletonCircle } from "../Skeleton/Skeleton";
import CustomImage from "../CustomImage/CustomImage";
import { getImageSource } from "../../../utils/methods";
import ImageCropModal from "../CroppedImageUploader/ImageCropModal/ImageCropModal";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";

type Props = {
  item: any;
  src?: string | null;
  something?: string;
  updateData?: () => void;
  parentLoading?: boolean;
};

function PersonProfilePicture(props: Props) {
  const { src, item, updateData, parentLoading } = props;

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const [coverImageUrl, setCoverImageUrl] = useState<null | string>(
    src || null,
  );
  const [coverImageFile, setCoverImageFile] = useState<any>(null);
  const [showImageCropModal, setShowImageCropModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setCoverImageUrl(src || null);
  }, [src]);

  const setNewImage = (file: any) => {
    if (!file) {
      return;
    }

    let fileExtension = file.name.split(".");
    fileExtension = fileExtension[fileExtension.length - 1];
    const allowedTypes = ["png", "jpg", "jpeg"];

    if (!file) {
      return;
    }

    if (!allowedTypes.includes(fileExtension.toLowerCase())) {
      showToast("error", "Error", "Invalid file type.");
      return;
    }

    const size = file.size / parseInt((1000 * 1000).toFixed(2));
    if (size > 10) {
      showToast("error", "Error", "File is too large.");
    } else {
      setCoverImageFile(file);
      setLoading(true);
    }
  };

  useEffect(() => {
    if (coverImageFile) {
      uploadMedia();
    }
  }, [coverImageFile]);

  // useEffect(() => {
  //   setCoverImageUrl(src);
  // }, [src]);

  useEffect(() => {
    const src = getImageSource(item?.cover, "tiny");

    if (src && src !== coverImageUrl) {
      setCoverImageUrl(src);
    }
  }, [item?.cover?.thumbnails]);
  const uploadMedia = () => {
    const wsTaskUuid = item.projectId || item.taskId;

    setLoading(true);

    setCoverImageUrl(null);

    axios
      .get(
        `${API_URLS.presignedRequestForTaskCover}?wsTaskUuid=${wsTaskUuid}&wsWorkspaceUuid=${activeWorkspaceUuid}&name=cover`,
      )
      .then(function (response) {
        const {
          requestInputs,
          requestAttributes: { action },
        } = response.data.content;

        axios
          .post(action, getFormDataToUploadS3(coverImageFile, requestInputs))
          .then(function (response) {
            if (response.status === 201) {
              const parentUuid = item.projectId || item.taskId;
              const requestInputsKeyArray = requestInputs.key.split("/");
              const realName =
                requestInputsKeyArray[requestInputsKeyArray.length - 1];

              axios
                .post(API_URLS.createAsset, {
                  wsWorkspaceUuid: activeWorkspaceUuid,
                  parentUuid,
                  parentType: "ws_task",
                  name: coverImageFile.name,
                  realName,
                  mimeType: coverImageFile.type,
                  size: coverImageFile.size,
                  isUpdatingCoverTask: true,
                })
                .then(function (response) {
                  if (updateData) {
                    updateData();
                  }
                  setLoading(false);
                  setCoverImageUrl(response.data.content.url);
                });
            }
          })
          .catch(function (error) {
            console.error(error.response?.data?.content?.errors);
            setLoading(false);
          });
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const getFormDataToUploadS3 = (file: File, requestInputs: any) => {
    const formData = new FormData();

    formData.set("key", requestInputs.key);
    formData.set("acl", requestInputs.acl);
    formData.set("X-Amz-Credential", requestInputs["X-Amz-Credential"]);
    formData.set("X-Amz-Algorithm", requestInputs["X-Amz-Algorithm"]);
    formData.set("X-Amz-Date", requestInputs["X-Amz-Date"]);
    formData.set("Policy", requestInputs.Policy);
    formData.set("X-Amz-Signature", requestInputs["X-Amz-Signature"]);
    formData.set("success_action_status", requestInputs.success_action_status);
    formData.set("file", file);

    return formData;
  };

  const removeCoverPhoto = () => {
    axios
      .delete(`${API_URLS.deleteAsset}/${activeWorkspaceUuid}/${item.cover.id}`)
      .then(function () {
        setCoverImageUrl(null);
        if (updateData) {
          updateData();
        }
      });
  };

  return (
    <div
      className={classNames("person-profile-picture", {
        "person-profile-picture--square":
          item.taskType === TaskType.Content ||
          item.taskType === TaskType.Publication,
      })}
    >
      {loading || parentLoading ? (
        <Skeleton
          width={80}
          height={80}
          borderRadius={item.taskType === TaskType.Content ? "4px" : "50%"}
        />
      ) : (
        <CustomImage
          className="person-profile-picture__photo"
          src={
            coverImageUrl ||
            (item.taskType === TaskType.Publication
              ? publicationPlaceholder
              : PlaceholderImage)
          }
          loading="lazy"
          alt="profile-pic-example"
        />
      )}

      {!coverImageUrl && !loading && !parentLoading ? (
        <div
          className="person-profile-picture__button"
          onClick={() => setShowImageCropModal(true)}
        >
          <PlusIcon />
        </div>
      ) : !loading && !parentLoading ? (
        <Dropdown>
          {(isOpen, setIsOpen) => (
            <>
              <div
                className="person-profile-picture__button"
                onClick={() => setIsOpen(true)}
              >
                <SettingsIcon />
              </div>

              <DropdownMenu isOpen={isOpen}>
                {item?.cover?.isRemovable && (
                  <DropdownMenuItem onClick={removeCoverPhoto}>
                    <IDHFormattedMessage
                      id="ws_remove_cover_photo"
                      defaultMessage="Remove cover photo"
                    />
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  className="image-input"
                  onClick={() => setShowImageCropModal(true)}
                >
                  <IDHFormattedMessage
                    id="ws_change_cover_photo"
                    defaultMessage="Change cover photo"
                  />
                </DropdownMenuItem>
              </DropdownMenu>
            </>
          )}
        </Dropdown>
      ) : null}
      {showImageCropModal && (
        <ImageCropModal
          uploadFunction={setNewImage}
          onClose={() => setShowImageCropModal(false)}
          circle={
            !(
              item.taskType === TaskType.Content ||
              item.taskType === TaskType.Publication
            )
          }
        />
      )}
    </div>
  );
}

export default PersonProfilePicture;
