import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalText, ModalTitle } from "src/app/components/Modal/Modal";
import CreatorDetectionList from "src/app/modals/AutomationsModal/components/CreatorDetectionList";
import PublicationDetectionList from "src/app/modals/AutomationsModal/components/PublicationDetectionList";
import PublicationSynchronizationList from "src/app/modals/AutomationsModal/components/PublicationSynchronizationList";
import { setEditedAutomationData } from "src/redux/automation/automationActions";
import { WsAutomation } from "src/redux/automation/types";
import { RootState } from "src/redux/reducers";
import { AutomationsSection } from "../Automations";
import AutomationsEnum from "../AutomationsEnum";

interface Props {
  onClose: () => void;
  setSection: (section: AutomationsSection) => void;
  basicData: any;
}

export const AutomationsMain: React.FC<Props> = (props) => {
  const { onClose, setSection, basicData } = props;

  const {
    automationReducer: { automationList, automationListLoading },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const handlePublication = (automationData?: WsAutomation) => {
    setSection(AutomationsSection.PublicationsDetection);
    if (automationData) {
      dispatch(setEditedAutomationData(automationData));
    } else {
      dispatch(setEditedAutomationData({}));
    }
  };

  const handlePublicationSynchronization = (automationData?: WsAutomation) => {
    setSection(AutomationsSection.PublicationsSynchronization);
    if (automationData) {
      dispatch(setEditedAutomationData(automationData));
    } else {
      dispatch(setEditedAutomationData({}));
    }
  };

  const publicationDetectionList = automationList.filter(
    (a) =>
      a.wsAutomationName ===
      AutomationsEnum.PublicationDetectionRunnableAutomation,
  );
  const publicationSynchronizationList = automationList.filter(
    (a) =>
      a.wsAutomationName ===
      AutomationsEnum.PublicationSynchronizationRunnableAutomation,
  );
  const creatorDetectionList = automationList.filter(
    (a) =>
      a.wsAutomationName === AutomationsEnum.CreatorDetectionRunnableAutomation,
  );

  return (
    <>
      <AnimatedDiv className="automations">
        <ModalTitle>
          <IDHFormattedMessage
            id="ws_automations"
            defaultMessage="Automations"
          />
        </ModalTitle>
        <ModalText>
          <IDHFormattedMessage
            id="ws_activate_automations_that_will_improve_workflow"
            defaultMessage="Activate automations that will improve your workflow."
          />
        </ModalText>

        <div className="automations__list">
          <CreatorDetectionList
            creatorDetectionList={creatorDetectionList}
            projectBasicData={basicData}
            automationListLoading={automationListLoading}
          />
          <PublicationDetectionList
            publicationDetectionList={publicationDetectionList}
            projectBasicData={basicData}
            handlePublication={handlePublication}
            automationListLoading={automationListLoading}
          />
          <PublicationSynchronizationList
            publicationSynchronizationList={publicationSynchronizationList}
            projectBasicData={basicData}
            handlePublicationSynchronization={handlePublicationSynchronization}
            automationListLoading={automationListLoading}
          />
        </div>
      </AnimatedDiv>

      <SettingsButtons onClose={onClose} />
    </>
  );
};
