import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import useOnClickOutside from "src/app/methods/useOnClickOutside";

interface Props {
  children: React.ReactNode;
  scrollableContainerIdentifier: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  hideOnScroll?: boolean;
}

export const DropdownMenuPortal: React.FC<Props> = (props: any) => {
  const { children, scrollableContainerIdentifier, setIsOpen, hideOnScroll } =
    props;

  const [initialScrollTop, setInitialScrollTop] = useState<number | null>(null);

  const menuRef = useRef<any>(null);

  const container = document.querySelector(
    scrollableContainerIdentifier,
  ) as HTMLElement;

  useEffect(() => {
    if (container && !initialScrollTop) {
      setInitialScrollTop(container.scrollTop);
    }
  }, [container?.scrollTop]);

  useEffect(() => {
    if (!container || initialScrollTop === null) return;

    container.onscroll = () => {
      if (hideOnScroll) {
        setIsOpen(false);
      } else {
        menuRef.current.style.setProperty(
          "transform",
          `translateY(${initialScrollTop - container.scrollTop}px)`,
          "important",
        );
      }
    };

    return () => {
      container.onscroll = null;
    };
  }, [initialScrollTop, container?.scrollTop, menuRef]);

  useOnClickOutside(menuRef, () => {
    setIsOpen(false);
  });

  return <div ref={menuRef}>{children}</div>;
};
