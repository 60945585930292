import React, { useRef, useState, Dispatch, SetStateAction } from "react";

import axios from "axios";
import { useSelector } from "react-redux";
import { DropdownMenuItem } from "../../components/Dropdown/Dropdown";
import IDHFormattedMessage from "../../components/IDHFormattedMessage/IDHFormattedMessage";
import DropdownPortal, {
  DropdownPortalMenu,
} from "../../components/DropdownPortal";
import { ContentProposalElementStatus } from "../../../redux/comment/types";
import useOnClickOutside from "../../methods/useOnClickOutside";

import { API_URLS } from "../../../utils/API_URLS";
import { RootState } from "../../../redux/reducers";
import { showToast } from "../../methods/showToast";

interface Props {
  children: React.ReactNode;
  setShowContentDownloadModal: Dispatch<SetStateAction<boolean>>;
  wsTaskUuids?: string[];
  wsProjectUuid: string | null;
}

function ContentProposalElementsSummaryDownloadDropdown({
  children,
  setShowContentDownloadModal,
  wsTaskUuids,
  wsProjectUuid,
}: Props) {
  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((reduxState: RootState) => reduxState);

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const ref = useRef(null);

  const onChangeVisibility = () => {
    setVisible(!visible);
  };

  const downloadContentProposal = (status: null | string) => {
    if (loading || !wsTaskUuids) {
      return;
    }

    setLoading(true);
    axios
      .post(API_URLS.downloadContentProposal, {
        wsWorkspaceUuid: activeWorkspaceUuid,
        wsTaskUuids,
        wsProjectUuid,
        status,
      })
      .then((response) => {
        if (response.data?.statusCode === 200) {
          setShowContentDownloadModal(true);
          onChangeVisibility();
        } else {
          showToast(
            "warning",
            <IDHFormattedMessage id="ws_warning" defaultMessage="Warning" />,
            <IDHFormattedMessage
              id="ws_content_proposal_empty_content_list"
              defaultMessage="No elements avaliable for selected status"
            />,
          );
        }
      })
      .catch((err) => {
        showToast(
          "warning",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_content_proposal_download_error"
            defaultMessage="No elements avaliable for selected status"
          />,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useOnClickOutside(ref, () => setVisible(false));

  const menu = (
    <DropdownPortalMenu>
      <DropdownMenuItem
        onClick={() => {
          downloadContentProposal(null);
        }}
      >
        <IDHFormattedMessage
          id="ws_download_all_content"
          defaultMessage="All content"
        />
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => {
          downloadContentProposal(ContentProposalElementStatus.Fresh);
        }}
      >
        <IDHFormattedMessage
          id="ws_moderation_needed_proposal_status"
          defaultMessage="Moderation needed"
        />
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => {
          downloadContentProposal(ContentProposalElementStatus.Accepted);
        }}
      >
        <IDHFormattedMessage
          id="ws_accepted_proposal_status"
          defaultMessage="Accepted"
        />
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => {
          downloadContentProposal(ContentProposalElementStatus.Rejected);
        }}
      >
        <IDHFormattedMessage
          id="ws_rejected_proposal_status"
          defaultMessage="Rejected"
        />
      </DropdownMenuItem>
    </DropdownPortalMenu>
  );

  return (
    <div ref={ref}>
      <DropdownPortal
        overlayClassName="comment-menu-options"
        overlay={menu}
        onClick={onChangeVisibility}
        visible={visible}
      >
        <span>{children}</span>
      </DropdownPortal>
    </div>
  );
}

export default ContentProposalElementsSummaryDownloadDropdown;
