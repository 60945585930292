import React from "react";

import { ReactComponent as PlusWhiteIcon } from "src/images/plus-white.svg";
import NotFoundImg from "src/images/empty-content-proposal.svg";
import NotFoundImgForUsersWithoutAccessToManage from "src/images/empty-content-proposal-client.svg";
import { EmptyTabContent } from "../../tabs/ProfileDataTab/components/EmptyTabs/EmptyTabContent/EmptyTabContent";
import IDHFormattedMessage from "../../../components/IDHFormattedMessage/IDHFormattedMessage";
import { EmptyTabContainer } from "../../tabs/ProfileDataTab/components/EmptyTabs/EmptyTabContainer/EmptyTabContainer";

import "./EmptyContentProposalList.scss";

interface EmptyContentProposalListInterface {
  onButtonClick: (show: boolean) => void;
  hasAccessToCreateContentProposal: boolean;
}

export default function EmptyContentProposalList({
  onButtonClick,
  hasAccessToCreateContentProposal,
}: EmptyContentProposalListInterface) {
  const renderTextForUserWithAccessToManage = () => {
    return (
      <div className="empty-content-proposal-list">
        <div className="empty-content-proposal-list__header">
          <IDHFormattedMessage
            id="ws_no_content_proposal_header"
            defaultMessage="There are no content proposals"
          />
        </div>
        <div className="empty-content-proposal-list__description">
          <IDHFormattedMessage
            id="ws_add_your_first_content_proposal"
            defaultMessage="Add your first content proposal and start collaboration."
          />
        </div>
      </div>
    );
  };
  const renderTextForUserWithoutAccessToManage = () => {
    return (
      <div className="empty-content-proposal-list">
        <div className="empty-content-proposal-list__header">
          <IDHFormattedMessage
            id="ws_no_content_proposal_header"
            defaultMessage="There are no content proposals"
          />
        </div>
        <div className="empty-content-proposal-list__description">
          <IDHFormattedMessage
            id="ws_patiently_wait_for_content_proposals"
            defaultMessage="Patiently wait for content proposals for moderation to appear."
          />
        </div>
      </div>
    );
  };

  return (
    <EmptyTabContainer className="empty-actions-tab">
      {hasAccessToCreateContentProposal && (
        <EmptyTabContent
          text={renderTextForUserWithAccessToManage()}
          buttonText={
            <IDHFormattedMessage
              id="ws_add_content_proposal"
              defaultMessage="Add Content Proposal"
            />
          }
          onButtonClick={() => onButtonClick(true)}
          buttonIcon={<PlusWhiteIcon />}
          imgUrl={NotFoundImg}
        />
      )}
      {!hasAccessToCreateContentProposal && (
        <EmptyTabContent
          text={renderTextForUserWithoutAccessToManage()}
          imgUrl={NotFoundImgForUsersWithoutAccessToManage}
        />
      )}
    </EmptyTabContainer>
  );
}
