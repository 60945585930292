import React, { useEffect } from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { WrappedComponentProps, injectIntl, useIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { CustomInputLabel } from "../../../../components/CustomInput/CustomInput";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import { ModalColumn } from "../../../../components/Modal/Modal";
import { MFState, MFStateEntries } from "../../types";

interface Props extends WrappedComponentProps<"intl"> {
  state: MFState;
  setState: (type: MFStateEntries, value: any) => void;
  isEdited: boolean;
}

export interface SelectOption {
  value: any;
  label: JSX.Element;
}

function SalesMetrics(props: Props) {
  const {
    state: { salesMetrics, salesMetricOptions, selectedMetric },
    setState,
    isEdited,
  } = props;

  const intl = useIntl();

  useEffect(() => {
    if (Object.keys(salesMetrics).length) {
      setState("selectedMetric", {});
      setState(
        "salesMetricOptions",
        Object.entries(salesMetrics).map((e: any) => {
          return {
            label: e[1].key,
            value: e[0],
            type: e[1].type,
            icon: e[1].icon,
          };
        }),
      );
    }
  }, [salesMetrics]);

  useEffect(() => {
    if (salesMetricOptions?.length) {
      setState("dataApplied", true);
    }
  }, [salesMetricOptions]);

  return (
    <div className="metric-wrapper metric-wrapper--no-margin">
      <ModalColumn>
        <CustomInputLabel htmlFor="metric">
          <IDHFormattedMessage id="ws_metric" defaultMessage="Metric" />
        </CustomInputLabel>
        <CustomSelect
          id="metric"
          value={selectedMetric}
          options={salesMetricOptions}
          placeholder={translateMessage({
            intl,
            id: "ws_select_metric",
            defaultMessage: "Select metric",
          })}
          isSearchable
          onChange={(newValue: any) => setState("selectedMetric", newValue)}
          disabled={isEdited}
        />
      </ModalColumn>
    </div>
  );
}

export default injectIntl(SalesMetrics);
