import React from "react";
import "./ProgressBarList.scss";

import { ProgressBarListItem } from "./ProgressBarListItem";

export function ProgressBarList(props) {
  const {
    data,
    barColor,
    backgroundColor,
    icon,
    flag,
    language,
    city,
    height,
  } = props;

  const renderBars = () => {
    switch (height) {
      case 3:
        return data
          ?.slice(0, 4)
          .map((item, index) => (
            <ProgressBarListItem
              item={item}
              key={index}
              barColor={barColor}
              backgroundColor={backgroundColor}
              icon={icon}
              flag={flag}
              language={language}
              city={city}
            />
          ));

      default:
        return data?.map((item, index) => (
          <ProgressBarListItem
            item={item}
            key={index}
            barColor={barColor}
            backgroundColor={backgroundColor}
            icon={icon}
            flag={flag}
            language={language}
            city={city}
          />
        ));
    }
  };

  return <div className="progress-bar-data">{renderBars()}</div>;
}
