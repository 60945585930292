import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import "./MoveToPane.scss";

import { useDispatch } from "react-redux";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "../../components/Dropdown/Dropdown";
import useOnClickOutside from "../../methods/useOnClickOutside";
import { changeTaskMetaFieldValue } from "../../../redux";
import { Metadata } from "../../Task/Subtask/Subtask";
import { DropdownMenuSearch } from "../../components/Dropdown/components/DropdownMenuSearch";
import { BoardColumnLabel } from "../../board/BoardColumn/BoardColumnLabel";

interface Props {
  isOpen: boolean;
  setShowMoveToPane: Dispatch<SetStateAction<boolean>>;
  taskId: string;
  boardColumns: Metadata[];
}

export function MoveToPane(props: Props) {
  const { isOpen, setShowMoveToPane, taskId, boardColumns } = props;

  const [searchValue, setSearchValue] = useState("");

  const menuRef = useRef(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  useOnClickOutside(menuRef, () => setShowMoveToPane(false));

  const moveTo = (uuid: string, newValue: string) => {
    dispatch(changeTaskMetaFieldValue(taskId, uuid, newValue));
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        searchRef.current?.focus();
      }, 50);
    }
  }, [isOpen]);

  return (
    <div ref={menuRef}>
      <DropdownMenu className="move-to-pane" isOpen={isOpen}>
        <DropdownMenuSearch
          ref={searchRef}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {boardColumns
          .filter(
            (column: Metadata) =>
              column.name.toUpperCase().includes(searchValue.toUpperCase()) ||
              column.formattedValue
                ?.toUpperCase()
                .includes(searchValue.toUpperCase()),
          )
          .map((column: Metadata) => (
            <DropdownMenuItem
              key={column.value}
              onClick={() => moveTo(column.uuid, column.value)}
            >
              {column.label}
            </DropdownMenuItem>
          ))}
      </DropdownMenu>
    </div>
  );
}
