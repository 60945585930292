import React, { useState, useRef, useEffect, ChangeEvent } from "react";
import classNames from "classnames";
import { uuidv7 } from "uuidv7";
import { useDispatch } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import AttachmentsField from "src/app/components/TextBox/components/AttachmentsField/AttachmentsField";
import { getTaskDetails } from "src/redux";
import {
  createAssetRelationInDb,
  addTaskComment,
} from "src/utils/uploadFunctions";
import { TextBox } from "src/app/components/TextBox/TextBox";
import { showToast } from "src/app/methods/showToast";
import scrollElementToBottom from "src/helpers/scrollElementToBottom";
import AddCommentFieldButton from "./AddCommentFieldButton";
import { AttachmentMetaData } from "../../Attachments/Attachment";
import AddAttachmentInput from "../AddAttachmentInput/AddAttachmentInput";
import { ALLOWED_ATTACHMENT_EXTENSIONS } from "../../utils/constants";
import { getAcceptedFileExtensions } from "../../Attachments/utils/getAcceptedFileExtensions";
import { handleAddAttachments } from "../../utils/handleAddAttachments";
import { CommentDisplayType } from "../../Task";

import "./AddCommentField.scss";

interface AddCommentFieldProps {
  taskId: string;
  activeWorkspaceUuid: string;
  setContentProposalFieldNotSubmitted?: (result: boolean) => void;
}

export interface AttachmentMetaDataAndKey {
  attachment: AttachmentMetaData;
  file?: File;
  key?: string;
  isLoading?: boolean;
  localUuid: string;
}

function AddCommentField({
  taskId,
  activeWorkspaceUuid,
  setContentProposalFieldNotSubmitted,
}: AddCommentFieldProps) {
  const [value, setValue] = useState("");
  const [showMentionList, setShowMentionList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [encodedValue, setEncodedValue] = useState("");
  const [isTextareaEnlarged, setIsTextareaEnlarged] = useState(false);
  const [attachments, setAttachments] = useState<AttachmentMetaDataAndKey[]>(
    [],
  );
  const [commentId, setCommentId] = useState("");

  const dispatch = useDispatch();

  const inputRef = useRef<HTMLInputElement>(null);
  const addCommentFieldRef = useRef<HTMLDivElement>(null);

  const onCommentClick = async () => {
    setIsLoading(true);

    try {
      await addTaskComment(commentId, taskId, encodedValue);
      if (attachments.length > 0) {
        await createAssetRelationInDb(attachments);
      }
      setValue(" ");
      setIsTextareaEnlarged(false);
      setValue("");
      setAttachments([]);
      setCommentId(uuidv7());

      await dispatch(getTaskDetails(taskId));
      setTimeout(() => {
        scrollElementToBottom("task__container");
      }, 1500);
    } catch (error) {
      showToast(
        "error",
        <IDHFormattedMessage
          id="ws_something_went_wrong"
          defaultMessage="Something went wrong."
        />,
        <IDHFormattedMessage
          id="ws_could_not_create_comment"
          defaultMessage="Could not create comment."
        />,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const clearInputValue = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleAddAttachmentFromInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = Array.from(e.target.files);

    if (!files.length) return;

    handleAddAttachments({
      files,
      commentId,
      activeWorkspaceUuid,
      clearInputValue,
      setAttachments,
      commentType: CommentDisplayType.Comment,
    });
  };

  const handleAddAttachmentsFromClipboard = async (
    e: globalThis.ClipboardEvent,
    commentId: string,
  ) => {
    if (!e.clipboardData) return;

    const files = Array.from(e.clipboardData.files);

    if (!files.length) return;

    setIsTextareaEnlarged(true);

    await handleAddAttachments({
      files,
      commentId,
      activeWorkspaceUuid,
      clearInputValue,
      setAttachments,
      commentType: CommentDisplayType.Comment,
    });
  };

  useEffect(() => {
    setCommentId(uuidv7());
  }, []);

  useEffect(() => {
    setAttachments([]);
  }, [taskId]);

  useEffect(() => {
    const pasteHandler = async (e: Event) => {
      const clipboardEvent = e as globalThis.ClipboardEvent;
      if (isLoading) return;
      await handleAddAttachmentsFromClipboard(clipboardEvent, commentId);
    };

    addCommentFieldRef.current?.addEventListener("paste", pasteHandler);

    return () =>
      addCommentFieldRef.current?.removeEventListener("paste", pasteHandler);
  }, [isLoading, commentId]);

  useEffect(() => {
    if (!setContentProposalFieldNotSubmitted) {
      return;
    }

    if ((encodedValue && encodedValue.length > 0) || attachments.length > 0) {
      setContentProposalFieldNotSubmitted(true);
    } else {
      setContentProposalFieldNotSubmitted(false);
    }
  }, [encodedValue, attachments]);

  return (
    <div
      ref={addCommentFieldRef}
      className={classNames("add-comment-field", {
        "add-comment-field--focus": isTextareaEnlarged,
      })}
    >
      <TextBox
        className={classNames("add-comment-field__textarea", {
          "add-comment-field__textarea--focus": isTextareaEnlarged,
        })}
        value={value || ""}
        placeholder="Write a comment or post an update..."
        onFocus={() => setIsTextareaEnlarged(true)}
        setEncodedValue={setEncodedValue}
        showMentionList={showMentionList}
        setShowMentionList={setShowMentionList}
        editorBottomChildren={
          <AttachmentsField
            attachments={attachments}
            setAttachments={setAttachments}
          />
        }
        bottomLeftButton={
          <AddAttachmentInput
            handleAddAttachments={handleAddAttachmentFromInput}
            inputRef={inputRef}
            acceptedFileExtensions={getAcceptedFileExtensions(
              ALLOWED_ATTACHMENT_EXTENSIONS,
            )}
            className="add-comment-field__add-attachment"
          />
        }
        emojisToBottom
        enableEmoji
        showButtons
        enableMentions
        bottomRightButton={
          isTextareaEnlarged && (
            <AddCommentFieldButton
              disabled={
                attachments.some((attachment) => attachment.isLoading) ||
                isLoading ||
                (!encodedValue.length && !attachments.length)
              }
              onCommentClick={onCommentClick}
            />
          )
        }
      />
    </div>
  );
}

export default AddCommentField;
