import React from "react";

import { AppConfig } from "src/config/AppConfig";
import { showToast } from "src/app/methods/showToast";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SelectedCreator } from "src/app/modals/AddCreatorsCDTWorkspaceProjectModal/AddCreatorsCDTWorkspaceProjectModal";
import { UniversalSettingsTab } from "src/app/SettingsModal/SettingsModal";
import { ErrorMessage, parseErrorMessages } from "src/utils/methods";
import * as actionTypes from "./mainTypes";

export type HowItWorksModalDataProps = {
  location: AppLocation;
};

export type CreateNewProjectData = {
  selectedCreatorsPayload?: SelectedCreator[];
};

export enum AppLocation {
  CreateNewProject = "create-new-project",
  PromoCodes = "promo-codes",
  Navbar = "navbar",
  TaskMetaFields = "task-meta-fields",
  Task = "task",
  ProjectList = "project-list",
  SingleProject = "single-project",
  TopbarRight = "topbar-right",
  ProjectOverviewTable = "project-overview-table",
  SettingsModal = "settings-modal",
  ProjectDetails = "project-details",
  Empty = "",
}

export type SettingsModalDataProps = {
  location: AppLocation;
  context: string;
  tab: UniversalSettingsTab;
  section?: string;
  objectUuid?: string;
};

export interface Workspace {
  uuid: string;
  name: string;
  logo: null | string;
  smallLogo: null | string;
}

export type NotificationType = "reload" | "online" | "offline";

interface Notification {
  id: string;
  type: NotificationType;
  message: string | undefined;
}

type WsProjectReportShowcasePdfReadyModalDataProps = {
  reportUrl: string;
};

export interface MainReducerState {
  translations: any;
  locale: string;
  supportedLanguages: string[];
  identity: any;
  identifyLoading: boolean;
  notifications: Notification[];
  wsProjectReportShowcasePdfReadyModalData: WsProjectReportShowcasePdfReadyModalDataProps | null;
  activeWorkspaceUuid: string;
  workspacesList: Workspace[];
  creatorDiscoveryToolUrl: string | null;
  showAppMessageModal: boolean;
  intercomUnreadMessages: number;
  settingsModalData: SettingsModalDataProps;
  howItWorksModalData: HowItWorksModalDataProps;
  createNewProjectData: CreateNewProjectData;
  websocket: WebSocket | null;
  identifyError: string | null;
  errorMessages: ErrorMessage[];
}

export const initialSettingsModalData = {
  location: AppLocation.Empty,
  tab: "",
  context: "",
  section: "",
};

export const initialHowItWorksModalData = { location: AppLocation.Empty };

const initialState: MainReducerState = {
  translations: {},
  locale: "en_GB",
  supportedLanguages: ["en_GB"],
  identity: {},
  identifyLoading: true,
  activeWorkspaceUuid: "",
  workspacesList: [],
  creatorDiscoveryToolUrl: null,
  notifications: [],
  wsProjectReportShowcasePdfReadyModalData: null,
  showAppMessageModal: false,
  intercomUnreadMessages: 0,
  settingsModalData: initialSettingsModalData,
  howItWorksModalData: initialHowItWorksModalData,
  createNewProjectData: {},
  websocket: null,
  identifyError: null,
  errorMessages: [],
};

function mainReducer(state = initialState, action: any): MainReducerState {
  switch (action.type) {
    case actionTypes.GET_TRANSLATIONS_SUCCESS:
      const { languages } = action.payload.data.content;

      return {
        ...state,
        translations: action.payload.data.content?.trans,
        locale: action.payload.data.content?.locale,
        supportedLanguages: languages?.length
          ? languages
          : state.supportedLanguages,
      };

    case actionTypes.GET_IDENTIFY_SUCCESS:
    case actionTypes.GET_IS_LOGGED_IN_SUCCESS:
      // Checking if endpoint included workspaceUuid parameter, if so, correct permissions should have been downloaded
      const permissionsLoaded =
        action.payload.config.url.includes("workspaceUuid");

      return {
        ...state,
        identity: action.payload.data?.content,
        identifyLoading: !permissionsLoaded,
        identifyError: null,
      };

    case actionTypes.GET_IDENTIFY_FAIL:
      if (
        !action?.error?.config?.reduxSourceAction?.payload?.request?.url?.includes(
          "=trial",
        )
      ) {
        showToast(
          "info",
          <IDHFormattedMessage id="ws_identity_title" defaultMessage="Info" />,
          <IDHFormattedMessage
            id="ws_identity_please_log_in"
            defaultMessage="Please log in"
          />,
        );
      }

      return {
        ...state,
        identifyError: actionTypes.GET_IDENTIFY_FAIL,
      };

    case actionTypes.GET_IS_LOGGED_IN_FAIL:
      return {
        ...state,
        identifyError: actionTypes.GET_IDENTIFY_FAIL,
      };

    case actionTypes.SET_ACTIVE_WORKSPACE_UUID:
      return {
        ...state,
        activeWorkspaceUuid: action.payload.workspaceUuid,
      };

    case actionTypes.SET_INTERCOM_UNREAD_MESSAGES:
      return {
        ...state,
        intercomUnreadMessages: action.payload.counter,
      };

    case actionTypes.SET_WEBSOCKET:
      return {
        ...state,
        websocket: new WebSocket(
          `${AppConfig.getWebsocketAddress()}/workspace/connect?workspaceId=${action.payload.workspaceUuid}&userId=${action.payload.userId}&challenge=${action.payload.challenge}`,
        ),
      };

    case actionTypes.CLEAR_WEBSOCKET:
      return {
        ...state,
        websocket: null,
      };

    case actionTypes.GET_WORKSPACES_LIST_SUCCESS:
      return {
        ...state,
        workspacesList: action.payload.data.content,
      };

    case actionTypes.GET_CREATOR_DISCOVERY_TOOL_URL_SUCCESS:
      return {
        ...state,
        creatorDiscoveryToolUrl:
          action.payload.data.content.creatorDiscoveryToolUrl,
      };

    case actionTypes.TASK_META_FIELD_CREATED:
      return state;

    case actionTypes.PDF_READY:
      return {
        ...state,
        wsProjectReportShowcasePdfReadyModalData: action.payload,
      };

    case actionTypes.PDF_CLOSE_READY:
      return {
        ...state,
        wsProjectReportShowcasePdfReadyModalData: null,
      };

    case actionTypes.SEND_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications.filter((n) => n.type !== action.payload.type),
          action.payload,
        ],
      };

    case actionTypes.DROP_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (n) => n.id !== action.payload.id,
        ),
      };

    case actionTypes.SET_SETTINGS_MODAL_DATA:
      return {
        ...state,
        settingsModalData: action.payload.settingsModalData,
      };

    case actionTypes.SET_HOW_IT_WORKS_MODAL_DATA:
      return {
        ...state,
        howItWorksModalData: action.payload.howItWorksModalData,
      };

    case actionTypes.SET_CREATE_NEW_PROJECT_DATA:
      return {
        ...state,
        createNewProjectData: action.payload.createNewProjectData,
      };

    case actionTypes.CLEAR_CREATE_NEW_PROJECT_DATA:
      return {
        ...state,
        createNewProjectData: {},
      };

    default: {
      if (action?.error?.response?.data && action.type.includes("_FAIL")) {
        if (
          action.error.response.data?.errors &&
          action.error.response.data?.errors.length > 0 &&
          action.error.response.data?.errors[0]?.field ===
            "User is not member specified project"
        ) {
          // TODO: tmp hack, https://app.asana.com/0/0/1203142742951466/f
          return state;
        }

        return {
          ...state,
          errorMessages: parseErrorMessages(
            action?.error?.response?.data?.errors,
          ),
        };
      }

      if (
        action?.payload?.data?.status === "fail" ||
        action?.error?.response?.status >= 500
      ) {
        return {
          ...state,
          errorMessages: [{ message: "An unexpected error occurred." }],
        };
      }
      return state;
    }
  }
}

export default mainReducer;
