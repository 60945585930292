import React, { useState } from "react";

import { ReactComponent as PlusWhiteIcon } from "src/images/plus-white.svg";
import AddSocialProfilesModal from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import NotFoundImg from "src/images/clickbait.svg";
import { EmptyTabContainer } from "./EmptyTabContainer/EmptyTabContainer";
import { EmptyTabContent } from "./EmptyTabContent/EmptyTabContent";

function EmptyProfileDataTab(props) {
  const { socialProfiles, taskTitle, taskId } = props;

  const [showAddSocialProfilesModal, setShowAddSocialProfilesModal] =
    useState(false);

  const openSocialMediaModal = (e) => {
    e.stopPropagation();
    setShowAddSocialProfilesModal(true);
  };

  return (
    <EmptyTabContainer className="empty-social-profiles-tab">
      <EmptyTabContent
        text={
          <IDHFormattedMessage
            id="ws_to_see_the_creator_stats_add_links"
            defaultMessage="Too see the Creator stats here, add links to social profiles"
          />
        }
        buttonText={
          <IDHFormattedMessage
            id="add_social_profiles"
            defaultMessage="Add social profiles"
          />
        }
        onButtonClick={openSocialMediaModal}
        buttonIcon={<PlusWhiteIcon />}
        imgUrl={NotFoundImg}
      />

      {showAddSocialProfilesModal && (
        <AddSocialProfilesModal
          objectType="task"
          onClose={() => setShowAddSocialProfilesModal(false)}
          name={taskTitle}
          taskId={taskId}
          socialProfiles={socialProfiles}
        />
      )}
    </EmptyTabContainer>
  );
}

export { EmptyProfileDataTab };
