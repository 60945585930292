import React, { useState } from "react";

import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import {
  ProjectPermissionsEnum,
  WorkspacePermissionsEnum,
} from "src/utils/PermissionsEnums";
import { WsExtension, ExtensionTypeEnum } from "src/types";
import { ChangeSettingsSectionParam } from "src/app/SettingsModal/methods";
import { CreatorDatabaseConfigurationSection } from "../../../CreatorDatabaseConfiguration/CreatorDatabaseConfiguration";
import { ExtensionsSection } from "../../Extensions";
import ExtensionsListItemContent from "./ExtensionsListItemContent";
import ExtensionListItemEditButton from "./ExtensionListItemEditButton";

interface ExtensionsListItemProps {
  setExtensionsSection: (section: ChangeSettingsSectionParam) => void;
  data: WsExtension;
  permissions: { project: string[]; workspace: string[] };
}

export default function ExtensionsListItem({
  setExtensionsSection,
  data,
  permissions,
}: ExtensionsListItemProps) {
  const [toggled, setToggled] = useState(data.enabled);

  switch (data.type) {
    case ExtensionTypeEnum.RecruitmentForm:
      return (
        <ExtensionsListItemContent
          name={
            <IDHFormattedMessage
              id="ws_recruitment_form"
              defaultMessage="Recruitment form"
            />
          }
          description={
            <IDHFormattedMessage
              id="ws_recruitment_form_description"
              defaultMessage="Enable the recruitment of Creators through a dedicated form."
            />
          }
          controls={
            data.enabled ? (
              <ExtensionListItemEditButton
                onClick={() =>
                  setExtensionsSection(ExtensionsSection.RecruitmentForm)
                }
              />
            ) : (
              <CustomSwitch
                checked={toggled}
                onChange={(e) => {
                  setToggled(e.target.checked);
                  setTimeout(() => {
                    setExtensionsSection(ExtensionsSection.RecruitmentForm);
                  }, 200);
                }}
                disabled={
                  !permissions?.project?.includes(
                    ProjectPermissionsEnum.MANAGE_PROJECT_CONFIGURATION,
                  ) &&
                  !permissions?.workspace?.includes(
                    WorkspacePermissionsEnum.CREATOR_DATABASE_CONFIGURATION,
                  )
                }
              />
            )
          }
        />
      );
    case ExtensionTypeEnum.CreatorDatabaseSubmissionForm:
      return (
        <ExtensionsListItemContent
          name={
            <IDHFormattedMessage
              id="ws_creator_registration_form"
              defaultMessage="Creator Registration Form"
            />
          }
          description={
            <IDHFormattedMessage
              id="ws_recruitment_form_description"
              defaultMessage="Enable the recruitment of Creators through a dedicated form."
            />
          }
          controls={
            data.enabled ? (
              <ExtensionListItemEditButton
                onClick={() =>
                  setExtensionsSection(
                    CreatorDatabaseConfigurationSection.SubmissionForm,
                  )
                }
              />
            ) : (
              <CustomSwitch
                checked={toggled}
                onChange={(e) => {
                  setToggled(e.target.checked);
                  setTimeout(() => {
                    setExtensionsSection(
                      CreatorDatabaseConfigurationSection.SubmissionForm,
                    );
                  }, 200);
                }}
                disabled={
                  !permissions?.workspace?.includes(
                    WorkspacePermissionsEnum.CREATOR_DATABASE_CONFIGURATION,
                  )
                }
              />
            )
          }
        />
      );
    case ExtensionTypeEnum.CreatorDatabaseMetaFieldList:
      return (
        <ExtensionsListItemContent
          name={
            <IDHFormattedMessage
              id="ws_workspace_extension_visibility_and_permissions"
              defaultMessage="Visibility and permissions"
            />
          }
          description={
            <IDHFormattedMessage
              id="ws_workspace_extension_visibility_and_permissions_description"
              defaultMessage="Manage visibility and editing for members and entire teams."
            />
          }
          controls={
            <ExtensionListItemEditButton
              onClick={() =>
                setExtensionsSection(
                  CreatorDatabaseConfigurationSection.MetaFieldList,
                )
              }
              disabled={
                !permissions?.workspace?.includes(
                  WorkspacePermissionsEnum.CREATOR_DATABASE_META_FIELD_ACCESS_MANAGEMENT,
                )
              }
            />
          }
        />
      );
    case ExtensionTypeEnum.ApiConnection:
      return (
        <ExtensionsListItemContent
          name={
            <IDHFormattedMessage
              id="ws_instagram_account_verification"
              defaultMessage="Instagram Account Verification"
            />
          }
          description={
            <IDHFormattedMessage
              id="ws_instagram_account_verification_description"
              defaultMessage="Allows creators to authorize their accounts and share insights from campaigns."
            />
          }
          controls={
            <ExtensionListItemEditButton
              onClick={() =>
                setExtensionsSection(
                  CreatorDatabaseConfigurationSection.InstagramAccountVerification,
                )
              }
            />
          }
        />
      );
    default:
      return null;
  }
}
