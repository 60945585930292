import React from "react";
import classNames from "classnames";

import { ContentBox } from "../components/ContentBox/ContentBox";

export const renderMetaField = (field, data, GridBox) => {
  const key = field.label;

  const isReport = Boolean(field?.section);

  return (
    <GridBox
      className={classNames({ "grid-box--small": field.type !== "text" })}
      key={key}
      id={key}
    >
      <ContentBox
        key={key}
        fieldName={field.name}
        data={data}
        block
        clearValue={!isReport}
      />
    </GridBox>
  );
};
