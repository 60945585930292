import React, { useState } from "react";
import axios from "axios";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalColumn } from "src/app/components/Modal/Modal";
import { RecruitmentFormDetails } from "src/types";
import { Button } from "src/app/components/Button/Button";
import { API_URLS } from "src/utils/API_URLS";
import { showToast } from "src/app/methods/showToast";
import {
  ProjectPermissionsEnum,
  WorkspacePermissionsEnum,
} from "src/utils/PermissionsEnums";
import { ReactComponent as RegenerateLinkIcon } from "src/images/regenerate-orange.svg";
import { ReactComponent as WarningIcon } from "src/images/warning-gray.svg";
import { ReactComponent as CodeIcon } from "src/images/code-blue.svg";
import { useCopyToClipboard } from "src/app/methods/useCopyToClipboard";
import { showErrorToast } from "src/utils/methods";
import { EmbedFormModal } from "./components/EmbedFormModal/EmbedFormModal";
import ExtensionsListItemContent from "../../../ExtensionsList/ExtensionsListItemContent";
import { getExtensionDetailsByUuid } from "../../../../utils";
import CopyValueInput from "../../../CopyValueInput/CopyValueInput";

const getRecruitmentFormDetails = async (uuid: string) => {
  const data = await getExtensionDetailsByUuid<{
    content: RecruitmentFormDetails;
  }>({
    uuid,
  });
  if (data) {
    return data.content;
  }
};

interface RecruitmentFormShareProps {
  recruitmentFormDetails: RecruitmentFormDetails;
  setRecruitmentFormDetails: (
    recruitmentFormDetails: RecruitmentFormDetails,
  ) => void;
  permissions: { project: string[]; workspace: string[] };
}

export default function RecruitmentFormShare({
  recruitmentFormDetails,
  permissions,
  setRecruitmentFormDetails,
}: RecruitmentFormShareProps) {
  const [isEmbedFormModalOpen, setIsEmbedFormModalOpen] = useState(false);
  const [isLinkRegenerating, setIsLinkRegenerating] = useState(false);
  const { isCopied, copy } = useCopyToClipboard();

  const regenerateToken = async (extensionUuid: string) => {
    try {
      setIsLinkRegenerating(true);
      await axios.put(
        API_URLS.updateRegenerateToken.replace(
          ":wsExtensionUuid:",
          extensionUuid,
        ),
      );
      showToast(
        "success",
        <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
        <IDHFormattedMessage
          id="ws_link_regenerated"
          defaultMessage="Recruitment form link has been regenerated."
        />,
      );
      const updatedRecruitmentFormDetails =
        await getRecruitmentFormDetails(extensionUuid);
      if (updatedRecruitmentFormDetails) {
        setRecruitmentFormDetails(updatedRecruitmentFormDetails);
      }
    } catch (err) {
      console.error(err);
      showErrorToast();
    } finally {
      setIsLinkRegenerating(false);
    }
  };

  return (
    <>
      <ExtensionsListItemContent
        name={
          <div className="recruitment-form__description">
            <IDHFormattedMessage
              id="ws_recruitment_form_share_description"
              defaultMessage="The form has been launched and ready for use. To fully leverage its capabilities, take at least one of the following steps:"
            />
            <div className="recruitment-form__item-wrapper">
              <span className="order-number">1</span>
              <IDHFormattedMessage
                id="ws_share_the_link_on_social_media"
                defaultMessage="Share the link to the form on your social media channels."
              />
            </div>
            <div className="recruitment-form__item-wrapper">
              <span className="order-number">2</span>
              <IDHFormattedMessage
                id="ws_embed_form_on_website"
                defaultMessage="Embed the form on your brand website."
              />
            </div>
          </div>
        }
      />
      <div className="recruitment-form__share-wrapper">
        <ModalColumn>
          <ModalColumn>
            <div className="extensions__name">
              <IDHFormattedMessage id="ws_link" defaultMessage="Link" />
            </div>
            <CopyValueInput
              displayedValue={recruitmentFormDetails.recruitmentFormUrl}
              copiedValue={recruitmentFormDetails.recruitmentFormUrl}
            />
          </ModalColumn>
          <ExtensionsListItemContent
            name={
              <IDHFormattedMessage
                id="ws_embed_form"
                defaultMessage="Embed form"
              />
            }
            description={
              <IDHFormattedMessage
                id="ws_embed_form_description"
                defaultMessage="Allows to place a Recruitment form on a website or in another application using embedded code."
              />
            }
            controls={
              <button
                className="extensions__action-btn"
                onClick={() => setIsEmbedFormModalOpen(true)}
              >
                <CodeIcon />
                <IDHFormattedMessage id="ws_embed" defaultMessage="Embed" />
              </button>
            }
          />
        </ModalColumn>
        <ExtensionsListItemContent
          name={
            <IDHFormattedMessage
              id="ws_regenerate_link"
              defaultMessage="Regenerate link"
            />
          }
          description={
            <div className="recruitment-form__regenerate-info">
              <WarningIcon />
              <IDHFormattedMessage
                id="ws_regenerate_link_description"
                defaultMessage="Please note that the old link will no longer be valid, and it cannot be restored."
              />
            </div>
          }
          controls={
            <Button
              size="large"
              variant="orange"
              onClick={() => regenerateToken(recruitmentFormDetails.uuid)}
              disabled={
                isLinkRegenerating ||
                (!permissions?.project?.includes(
                  ProjectPermissionsEnum.MANAGE_PROJECT_CONFIGURATION,
                ) &&
                  !permissions?.workspace?.includes(
                    WorkspacePermissionsEnum.CREATOR_DATABASE_CONFIGURATION,
                  ))
              }
            >
              <RegenerateLinkIcon />
              <IDHFormattedMessage
                id="ws_regenerate"
                defaultMessage="Regenerate"
              />
            </Button>
          }
        />
      </div>
      {isEmbedFormModalOpen && (
        <EmbedFormModal
          onClose={() => setIsEmbedFormModalOpen(false)}
          recruitmentFormDetails={recruitmentFormDetails}
        />
      )}
    </>
  );
}
