import React from "react";
import { SummaryBoxList } from "../../../components/SummaryBoxList/SummaryBoxList";

function Payments(props) {
  const { foundFields } = props;

  return <SummaryBoxList foundFields={foundFields} />;
}

export { Payments };
