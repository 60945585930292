// eslint-disable-next-line import/prefer-default-export
export abstract class AppConfig {
  public static getAppPath(): string {
    if (typeof process.env.REACT_APP_PUBLIC_URL !== "string") {
      throw new Error("REACT_APP_PUBLIC_URL is not a string");
    }

    return process.env.REACT_APP_PUBLIC_URL;
  }

  public static getAppHost(): string {
    // eslint-disable-next-line no-undef
    const windowOrigin = new URL(window.location.origin);
    const host = windowOrigin.toString();

    if (host.endsWith("/")) {
      return host.slice(0, -1);
    }

    return host;
  }

  public static getAppHostname(): string {
    // eslint-disable-next-line no-undef
    const windowOrigin = new URL(window.location.origin);

    return windowOrigin.hostname;
  }

  public static getAssetForHostname(assetPath: string): string {
    return `${this.getAppPath()}/assets_by_hostname/${this.getAppHostname()}/${assetPath}`;
  }

  // Pretty much the same as getAppHost(), but without port
  public static getApiHost(): string {
    const windowOrigin = new URL(this.getAppHost());
    windowOrigin.port = "";
    const host = windowOrigin.toString();

    if (host.endsWith("/")) {
      return host.slice(0, -1);
    }

    return host;
  }

  public static getAppRoute(): string {
    return `${this.getAppHost()}${this.getAppPath()}`;
  }

  public static getApiRoute(): string {
    return `${this.getApiHost()}/workspace-api`;
  }

  public static getEnvironmentName(): string {
    if (typeof process.env.REACT_APP_ENV_NAME !== "string") {
      throw new Error("REACT_APP_ENV_NAME is not a string");
    }

    return process.env.REACT_APP_ENV_NAME;
  }

  public static getWebsocketAddress(): string {
    const envName = AppConfig.getEnvironmentName();

    // eslint-disable-next-line default-case
    switch (envName) {
      case "local":
      case "localdev":
        return "wss://wss.tagme.test:8787/wss";
      case "dev":
        return "wss://ws-dev.websockets.work/wss";
      case "beta":
        return "wss://ws-beta.websockets.work/wss";
      case "stage1":
        return "wss://ws-stage1.websockets.work/wss";
      case "live":
        return "wss://ws-live.websockets.work/wss";
    }

    throw new Error(`Unable to determine websocketAddress for: ${envName}`);
  }

  public static isRunningLocalDevelopmentMode(): boolean {
    // eslint-disable-next-line default-case
    switch (process.env.NODE_ENV) {
      case "production":
        return false;
      case "development":
        return true;
    }

    throw new Error(`Invalid NODE_ENV value: ${process.env.NODE_ENV}`);
  }
}
