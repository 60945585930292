import React, { useState } from "react";
import "./RenameCouponModal.scss";

import { useIntl } from "react-intl";
import Modal, { ModalTitle } from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";

import { useDispatch, useSelector } from "react-redux";
import { Coupon } from "src/redux/coupon/couponReducer";
import { renameCoupon } from "src/redux/coupon/couponActions";
import { translateMessage } from "src/app/methods/translateMessage";
import { RootState } from "src/redux/reducers";

interface Props {
  onClose: () => void;
  data: Coupon;
}

export const RenameCouponsModal: React.FC<Props> = (props) => {
  const { onClose, data } = props;

  const [code, setCode] = useState(data.code);

  const {
    couponReducer: { couponGroupDetails },
  } = useSelector((state: RootState) => state);

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleConfirm = () => {
    if (!couponGroupDetails.uuid) return;
    dispatch(renameCoupon(code, data.uuid, couponGroupDetails.uuid));
    onClose();
  };

  return (
    <Modal
      className="rename-coupon-modal"
      onClose={onClose}
      onConfirmClick={handleConfirm}
      confirmButtonText={
        <IDHFormattedMessage id="ws_rename" defaultMessage="Rename" />
      }
      confirmButtonDisabled={
        !code.length || data.code === code || code.includes(" ")
      }
      displayCancelButton
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_rename_promo_code"
          defaultMessage="Rename Promo Code"
        />
      </ModalTitle>
      <div className="rename-coupon-modal__input-wrapper">
        <CustomInputLabel htmlFor="code">
          <IDHFormattedMessage
            id="ws_promo_codes"
            defaultMessage="Promo Code"
          />
        </CustomInputLabel>
        <CustomInput
          id="code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder={translateMessage({
            intl,
            id: "ws_promo_code",
            defaultMessage: "Promo code",
          })}
        />
      </div>
    </Modal>
  );
};
