import { SocialProvider } from "src/types";
import { translateMessage } from "../../methods/translateMessage";

export const getDefaultCreatorDatabaseFilters = (intl) => [
  {
    accessLevel: ["editor"],
    data: [],
    name: "Creator",
    type: "text",
    valueSource: null,
    uuid: "taskTitleAndSocialUsername",
    rank: "aaaaaa",
  },
  {
    accessLevel: ["editor"],
    data: {
      singleSelectOptions: Object.keys(SocialProvider)
        .filter((key) => SocialProvider[key] !== SocialProvider.Custom)
        .map((item, idx) => ({
          name: translateMessage({
            intl,
            id: item.toLowerCase(),
            defaultMessage: item,
          }),
          value: SocialProvider[item],
          color: "fff",
          backgroundColor: "fff",
          rank: String.fromCharCode(97 + idx),
        })),
    },
    name: "Social Platform",
    type: "singleSelect",
    valueSource: null,
    uuid: "socialProviders",
    rank: "aaaaab",
  },
];

export const getDefaultDictionaryFilters = (dictionaryElementName) => [
  {
    accessLevel: ["editor"],
    data: [],
    name: dictionaryElementName,
    type: "text",
    valueSource: null,
    uuid: "title",
    rank: "aaaaaa",
  },
];
