import React from "react";
import "./PreloaderContainer.scss";
import Loader from "../../components/Loader/Loader.jsx";

class PreloaderContainer extends React.Component {
  render() {
    return (
      <div className="page-preloader">
        <Loader />
      </div>
    );
  }
}

export default PreloaderContainer;
