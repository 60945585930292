import React, { useMemo } from "react";
import Pictogram, {
  PictogramVariants,
} from "../../../components/Pictogram/Pictogram";

export function GlossaryItem(props) {
  const { data, iconFolder } = props;

  const Icon = useMemo(() => {
    return (
      <Pictogram
        identifier={`${iconFolder}/${data.icon}`}
        variant={PictogramVariants.Graphite}
      />
    );
  }, [data]);

  return (
    <div className="report-glossary__item">
      <div className="report-glossary__item-heading">
        {Icon}
        <div className="report-glossary__item-heading-title">{data.title}</div>
      </div>
      <p className="report-glossary__item-text">{data.text}</p>
    </div>
  );
}
