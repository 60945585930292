import React from "react";
import "./VelocityValue.scss";
import classNames from "classnames";
import { ReactComponent as WarningIcon } from "src/images/warning.svg";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import VelocityIcon from "../VelocityIcon/VelocityIcon";
import VelocityValueTooltipContent from "./VelocityValueTooltipContent";

function VelocityValue(props) {
  const {
    value,
    hideNullTooltip,
    filterMatchSummary,
    isVelocitySummaryModalOpen,
    ...restProps
  } = props;

  function VelocityContent() {
    return (
      <div
        className={classNames("velocity-value", {
          "velocity-value--high": (value || 0) > 50,
          "velocity-value--low": (value || 0) === 0,
          "velocity-value--no-click": typeof restProps.onClick !== "function",
        })}
        {...restProps}
      >
        {filterMatchSummary?.doesResultMatchAllFilters === false && (
          <WarningIcon
            className="velocity-value__warning"
            height={20}
            width={20}
          />
        )}
        <VelocityIcon percent={value || 0} />
        {value || 0}
      </div>
    );
  }

  return (
    <div className="velocity-value-wrapper">
      {filterMatchSummary &&
      filterMatchSummary.matches.length > 0 &&
      !isVelocitySummaryModalOpen ? (
        <MaterialTooltip
          className="velocity-value-tooltip"
          clickable
          content={
            <VelocityValueTooltipContent
              value={value}
              filterMatchSummary={filterMatchSummary}
            />
          }
          contentClassName="velocity-value-tooltip__content"
        >
          <VelocityContent />
        </MaterialTooltip>
      ) : (
        <VelocityContent />
      )}
    </div>
  );
}

export default VelocityValue;
