export const getPercentOptions = (maxValueInPercentage, step = 10) => {
  const options = [];
  for (let i = 1; i < maxValueInPercentage / step + 1; i++) {
    options.push({
      value: `${i * step}`,
      label: `${i * step} %`,
    });
  }
  return options;
};
