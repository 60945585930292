import React from "react";

import { uuidv7 as uuid } from "uuidv7";

import { ReactComponent as XlsIcon } from "src/images/xls-attachment.svg";
import { ReactComponent as DocIcon } from "src/images/doc-attachment.svg";
import { ReactComponent as PptxIcon } from "src/images/pptx-attachment.svg";
import { ReactComponent as ZipIcon } from "src/images/zip-attachment.svg";
import { ReactComponent as TxtIcon } from "src/images/txt-attachment.svg";
import { ReactComponent as ImageAttachment } from "src/images/image-attachment.svg";
import { ReactComponent as AudioAttachment } from "src/images/audio-attachment.svg";
import { ReactComponent as VideoAttachment } from "src/images/video-attachment.svg";
import { ReactComponent as PdfIcon } from "src/images/pdf-attachment.svg";
import { ReactComponent as FileGreyIcon } from "src/images/files/file-grey.svg";
import { ReactComponent as URLIcon } from "src/images/link-light-gray.svg";

export const getFileFieldIcon = (fileExtension: string | undefined | null) => {
  switch (fileExtension) {
    case "pdf":
      return <PdfIcon className="extension-icon" />;
    case "xls":
    case "xlsx":
    case "csv":
      return <XlsIcon className="extension-icon" />;
    case "doc":
    case "odc":
      return <DocIcon className="extension-icon" />;
    case "docx":
      return <DocIcon className="extension-icon" />;
    case "ppt":
    case "pptx":
    case "ods":
      return <PptxIcon className="extension-icon" />;
    case "txt":
      return <TxtIcon className="extension-icon" />;
    case "zip":
      return <ZipIcon className="extension-icon" />;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return <ImageAttachment className="extension-icon" />;

    case "mp3":
    case "mpeg":
      return <AudioAttachment className="extension-icon" />;
    case "mp4":
    case "avi":
    case "webm":
      return <VideoAttachment className="extension-icon" />;
    case "url":
      return <URLIcon />;
    default:
      return <FileGreyIcon className="extension-icon" />;
  }
};

export const addNewFilesFromFileList = (fileList: FileList) => {
  return Array.from(fileList).map((item: File) => ({
    assetId: uuid(),
    mimeType: item.type,
    publicPreviewUrl: "",
    publicDownloadUrl: "",
    name: item.name,
    loading: true,
  }));
};
