import React, { useState } from "react";
import "./ChartContent.scss";

import Pictogram, {
  PictogramVariants,
} from "src/app/components/Pictogram/Pictogram";
import SimpleChart from "src/app/components/SimpleChart/SimpleChart";
import { getHexFromPictogramVariant } from "src/app/components/Pictogram/getHexFromPictogramVariant";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import classNames from "classnames";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { PerformanceElement } from "../../../../Performance";
import { CampaignList } from "./components/CampaignList/CampaignList";
import { SUPPORTED_PERFORMANCE_VIEW_TYPES } from "../../PerformanceBox";

interface Props {
  data: PerformanceElement;
}

export const ChartContent: React.FC<Props> = (props) => {
  const { data } = props;

  const hasMonths = Boolean(data.breakdownValues?.month);
  const hasCampaigns = Boolean(data.breakdownValues?.campaign);

  const getDefaultView = () => {
    if (
      SUPPORTED_PERFORMANCE_VIEW_TYPES.includes(
        data.defaultVisualizationBreakdown,
      )
    ) {
      return data.defaultVisualizationBreakdown;
    }

    if (data.breakdownValues?.month) {
      return "month";
    }

    if (data.breakdownValues?.campaign) {
      return "campaign";
    }

    return "month";
  };

  const [view, setView] = useState(getDefaultView());

  const renderContent = () => {
    switch (view) {
      case "month":
        if (!hasMonths) return null;
        return (
          <SimpleChart
            color={getHexFromPictogramVariant(
              PictogramVariants[data.icon.color],
            )}
            data={data.breakdownValues?.month}
            detailedData={data.breakdownValuesDetails?.month}
            height={200}
          />
        );

      case "campaign":
        if (!hasCampaigns) return null;
        return <CampaignList data={data.breakdownValues.campaign} />;

      default:
        return null;
    }
  };

  return (
    <AnimatedDiv className="chart-content">
      <div className="chart-content__top">
        <div className="chart-content__top-info">
          <Pictogram
            identifier={data.icon.name}
            variant={PictogramVariants[data.icon.color]}
          />
          <span className="chart-content__text">
            <span className="chart-content__value">{data.value}</span>
            <span className="chart-content__title">{data.title}</span>
          </span>
        </div>

        {hasMonths && hasCampaigns && (
          <div className="chart-content__views">
            {hasMonths && (
              <span
                className={classNames("chart-content__view", {
                  "chart-content__view--active": view === "month",
                  "chart-content__view--symmetrical": !hasCampaigns,
                })}
                onClick={() => setView("month")}
              >
                <IDHFormattedMessage id="ws_months" defaultMessage="Months" />
              </span>
            )}

            {hasCampaigns && (
              <span
                className={classNames("chart-content__view", {
                  "chart-content__view--active": view === "campaign",
                  "chart-content__view--symmetrical": !hasMonths,
                })}
                onClick={() => setView("campaign")}
              >
                <IDHFormattedMessage
                  id="ws_campaigns"
                  defaultMessage="Campaigns"
                />
              </span>
            )}
          </div>
        )}
      </div>

      {renderContent()}
    </AnimatedDiv>
  );
};
