import React, { useRef, useState } from "react";
import "./TemplateSelector.scss";

import { useHistory, useParams } from "react-router";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ChevronDown } from "src/images/chevron-down.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import useOnClickOutside from "../../../methods/useOnClickOutside";

import { TemplateSelectorMenu } from "./TemplateSelectorMenu";
import {
  setMultipleLayouts,
  setNewSavedTemplate,
  setSelectedTemplate,
} from "../../../../redux";

export function TemplateSelector(props) {
  const {
    templateOptions,
    selectedTemplate,
    setTemplateToRemoveUuid,
    setShowRemoveTemplateModal,
    clickTemplate,
    disabled,
  } = props;

  const [showMenu, setShowMenu] = useState(false);

  const selectorRef = useRef();

  useOnClickOutside(selectorRef, () => setShowMenu(false));

  const handleTemplateClick = (template) => {
    clickTemplate(template);
    setShowMenu(false);
  };

  const handleDeleteClick = (e, templateUuid) => {
    e.stopPropagation();

    setTemplateToRemoveUuid(templateUuid);
    setShowRemoveTemplateModal(true);
  };

  return (
    <div
      ref={selectorRef}
      className={classNames("template-selector", {
        "template-selector--disabled": disabled,
      })}
    >
      <div
        className={classNames("template-selector__value", {
          "template-selector__value--menu-open": showMenu,
        })}
        onClick={() => setShowMenu(true)}
      >
        <span className="template-selector__value-placeholder">
          <IDHFormattedMessage
            id="ws_select_template"
            defaultMessage="Select template"
          />
          ...
        </span>
        <span className="template-selector__value-wrapper">
          {selectedTemplate.label}
        </span>
        <ChevronDown className="template-selector__value-chevron" />
      </div>

      <TemplateSelectorMenu
        visible={showMenu}
        templateOptions={templateOptions}
        selectedTemplate={selectedTemplate}
        handleTemplateClick={handleTemplateClick}
        handleDeleteClick={handleDeleteClick}
      />
    </div>
  );
}
