import { setNewlyAddedPages } from "src/redux";
import { pagesAreEqual, separatorsAreEqual } from "../../../utils/methods";

const ELEMENTS_WHITELIST = ["basic-info", "cover-box", "title-box"];

export const getPages = (props) => {
  const {
    dispatch,
    format,
    gridElements,
    newlyAddedPages,
    oldLayout,
    oldSeparators,
    pages,
    separators,
    //
    setPages,
    setLayout,
    setSeparators,
  } = props;

  const newPages = [];
  const newLayout = [];
  let newSeparators = [...oldSeparators];

  const lastRow = Math.max(...oldLayout.map((item) => item.y));
  const spaceLeft = format.rows - (lastRow % format.rows);
  const fullSpace = lastRow + spaceLeft;

  let previousBorder = 0;
  let heightDiff = 0;
  for (let i = format.rows - 1; i <= fullSpace; i += format.rows) {
    const start = previousBorder;
    const end = i;

    const elements = oldLayout.filter(
      (item) => item.y >= start && item.y <= end,
    );

    if (
      !elements.filter(
        (item) =>
          gridElements.find((box) => item.i === box.label) ||
          ELEMENTS_WHITELIST.some((el) => item.i.includes(el)),
      ).length &&
      !newlyAddedPages?.find((page) => page.start === start && page.end === end)
    ) {
      heightDiff += format.rows;
    } else {
      const page = {
        elements,
      };

      newPages.push(page);
      previousBorder = i + 1;
      newLayout.push(
        ...elements.map((item) => ({ ...item, y: item.y - heightDiff })),
      );

      if (
        elements.filter((item) => !item.i.includes("separator")).length &&
        newlyAddedPages?.find(
          (page) => page.start === start && page.end === end,
        )
      ) {
        dispatch(
          setNewlyAddedPages(
            newlyAddedPages.filter(
              (page) => page.start !== start && page.end !== end,
            ),
          ),
        );
      }
    }
  }

  if (
    separatorsAreEqual(separators, newSeparators) &&
    pagesAreEqual(pages, newPages)
  ) {
    return;
  }

  newPages.forEach((page, index) => {
    const separator = page.elements?.find((item) =>
      item.i.includes("separator"),
    ).i;

    if (index === newPages.length - 1) {
      return;
    }

    const elList = newPages[index + 1].elements.filter(
      (item) => !item.i.includes("separator"),
    );

    const provider = elList[0]?.i.split("-")[0] || "default";

    if (elList.every((item) => item.i.includes(provider))) {
      newSeparators = newSeparators.map((item) => {
        if (item?.key === separator) {
          return {
            ...item,
            provider,
          };
        }

        return item;
      });
    } else {
      newSeparators = newSeparators.map((item) => {
        if (item?.key === separator) {
          return {
            ...item,
            provider: "default",
          };
        }

        return item;
      });
    }
  });

  newSeparators = newSeparators.map((item, index) => {
    if (index === newSeparators.length - 1) {
      return {
        ...item,
        provider: "last",
      };
    }

    return item;
  });

  setPages(newPages);
  setLayout(newLayout);
  setSeparators(newSeparators);
};
