import React from "react";

import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as MaleIcon } from "../../../../../images/profile-data/male.svg";
import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";
import { ProgressBarList } from "../components/ProgressBarList/ProgressBarList";

export function MalePerAge(props) {
  const { audienceMen, male, height, onProjectDetails } = props;

  return (
    <ProfileBox onProjectDetails={onProjectDetails}>
      <PBHeader
        icon={<MaleIcon />}
        count={`${audienceMen}%`}
        title={<IDHFormattedMessage id="ws_male" defaultMessage="Male" />}
        onShowcase={height}
        onProjectDetails={onProjectDetails}
      />
      {!height && (
        <div className="profile-box__heading">
          <IDHFormattedMessage
            id="ws_age_group_male"
            defaultMessage="Age group - Male"
          />

          <MaterialTooltip
            center
            content={
              <IDHFormattedMessage
                id="ws_tooltip_age_group_male"
                defaultMessage="Audience demographics broken down by age groups and gender"
              />
            }
          />
        </div>
      )}
      <ProgressBarList
        data={male}
        barColor="#536CEB"
        backgroundColor="rgba(83, 108, 235, 0.1)"
        height={height}
      />
    </ProfileBox>
  );
}
