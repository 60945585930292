import React, { useState, useEffect, useRef } from "react";
import "./Activity.scss";

import { useDispatch, useSelector } from "react-redux";

import { Redirect, useHistory } from "react-router";
import InfiniteScroll from "react-infinite-scroller";
import axios from "axios";
import { API_URLS } from "src/utils/API_URLS";
import {
  initialActivityFilters,
  initialActivityList,
} from "src/redux/activity/activityReducer";
import noFilteredResultsImg from "src/images/empty-folder-group.svg";
import { customEqual, showErrorToast } from "src/utils/methods";
import { navigateToCreateNewProject } from "src/app/SettingsModal/methods";
import { RootState } from "src/redux/reducers";
import { ReactComponent as FilterIcon } from "../../../images/filter.svg";

import { ActivityItem } from "./components/ActivityItem/ActivityItem";
import NoResultsImg from "../../../images/slacking.svg";
import { ReactComponent as PlusSignIcon } from "../../../images/plus-white.svg";
import { ReactComponent as MagnifyingGlassIcon } from "../../../images/magnifier-grey.svg";

import {
  clearActivityFilters,
  setActivityFilters,
  setActivityList,
  setActivityLoading,
  setActivityOffset,
  setOldestActivityDate,
} from "../../../redux/activity/activityActions";
import WebsocketListener from "../../../WebsocketListener";
import { Button } from "../Button/Button";
import { Pane } from "../Pane/Pane";
import { ActivityFilters } from "./components/ActivityFIlters/ActivityFilters";
import { SkeletonCircle, SkeletonText } from "../Skeleton/Skeleton";

import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import NoResultsScreen from "../NoResultsScreen/NoResultsScreen";
import { cancelRequestAxios } from "./utils/CancelRequest";

const cancelRequestAxiosObject = new cancelRequestAxios();

function ActivityElementLoader() {
  return (
    <div className="activity__table-element">
      <div className="activity__table-element-activity">
        <SkeletonCircle size={24} marginRight={8} />
        <SkeletonText width={500} height={18} />
      </div>
      <div className="activity__table-element-time">
        <SkeletonText width={120} height={18} />
      </div>
      <div className="activity__table-element-project">
        <SkeletonText width="100%" height={18} />
      </div>
    </div>
  );
}

export function Activity() {
  const activityList = useSelector(
    (state: RootState) => state.activityReducer.activityList,
  );
  const oldestActivityDate = useSelector(
    (state: RootState) => state.activityReducer.oldestActivityDate,
  );
  const limit = useSelector((state: RootState) => state.activityReducer.limit);
  const offset = useSelector(
    (state: RootState) => state.activityReducer.offset,
  );
  const filters = useSelector(
    (state: RootState) => state.activityReducer.filters,
    customEqual,
  );
  const identity = useSelector(
    (state: RootState) => state.mainReducer.identity,
  );
  const identifyLoading = useSelector(
    (state: RootState) => state.mainReducer.identifyLoading,
  );
  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );
  const projectsList = useSelector(
    (state: RootState) => state.projectReducer.projectsList,
  );
  const membersList = useSelector(
    (state: RootState) => state.projectReducer.activeMembersList,
  );

  const [filtersVisible, setFiltersVisible] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [filtersCounter, setFiltersCounter] = useState(0);
  const [endOfList, setEndOfList] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [errorOcurred, setErrorOcurred] = useState(false);
  const [blockInfinityScroll, setBlockInfinityScroll] = useState(true);

  const [computedFilters, setComputedFilters] = useState(
    initialActivityFilters,
  );

  const dispatch = useDispatch();

  const scrollRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  useEffect(() => {
    dispatch(setActivityOffset(0));
    dispatch(setActivityList(initialActivityList));
    dispatch(setActivityLoading(true));
  }, []);

  useEffect(() => {
    if (activeWorkspaceUuid) {
      checkFilters();
    }
  }, [activeWorkspaceUuid]);

  const checkFilters = () => {
    const activityFiltersFromLocalStorage =
      localStorage.getItem("activityFilters");
    if (!activityFiltersFromLocalStorage) {
      setFiltersApplied(true);
    } else {
      const parsedFilters = JSON.parse(activityFiltersFromLocalStorage);
      const activeWorkspaceFilters = parsedFilters[activeWorkspaceUuid];
      if (activeWorkspaceFilters) {
        setComputedFilters(activeWorkspaceFilters);
        dispatch(setActivityFilters(activeWorkspaceFilters));
      }
      setFiltersApplied(true);
    }
  };

  useEffect(() => {
    setFiltersCounter(
      (filters?.campaigns?.length ?? 0) +
        (filters?.members?.length ?? 0) +
        Number(
          filters?.dateRange?.dateFrom !== null ||
            filters?.dateRange?.dateTo !== null,
        ),
    );
    setComputedFilters(filters);
  }, [filters]);

  useEffect(() => {
    dispatch(setActivityOffset(0));
  }, [activeWorkspaceUuid, filters, limit]);

  useEffect(() => {
    dispatch(setActivityOffset(offset));
  }, [offset]);

  const computeOldestActivityDate = (
    elements: { createdDate: { date: string } }[],
  ) => {
    const oldestActivity = elements.sort(
      (a, b) =>
        new Date(b.createdDate.date).getTime() -
        new Date(a.createdDate.date).getTime(),
    )[0];
    if (oldestActivity) {
      dispatch(setOldestActivityDate(oldestActivity.createdDate.date));
    }
  };

  const getActivities = async () => {
    setListLoading(true);
    if (offset === 0) {
      dispatch(setActivityList([]));
    }
    cancelRequestAxiosObject.cancelAndCreateToken();
    try {
      const url = `${API_URLS.getActivityList}?wsWorkspaceUuid=${activeWorkspaceUuid}`;
      const {
        data: { content: results },
      } = await axios.get(url, {
        params: {
          filters: {
            campaigns: filters?.campaigns,
            members: filters?.members,
            dateRange: {
              dateFrom: filters?.dateRange.dateFrom || oldestActivityDate,
              dateTo: filters?.dateRange.dateTo,
            },
          },
          limit,
          offset,
        },
        cancelToken: cancelRequestAxiosObject.cancel_resquest?.token,
      });
      const elements = results.activityLogs ?? [];
      computeOldestActivityDate(elements);
      dispatch(
        setActivityList(
          offset === 0 ? elements : [...activityList, ...elements],
        ),
      );
      setEndOfList(results?.lastItem ?? false);
      cancelRequestAxiosObject.resetCancelToken();
      setListLoading(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        showErrorToast();
        setErrorOcurred(true);
        setListLoading(false);
        setBlockInfinityScroll(true);
      }
    } finally {
      setBlockInfinityScroll(false);
    }
  };

  useEffect(() => {
    if (
      filtersApplied &&
      activeWorkspaceUuid &&
      activeWorkspaceUuid !== "" &&
      filters &&
      limit != null &&
      offset != null
    ) {
      getActivities();
    }
  }, [filtersApplied, activeWorkspaceUuid, filters, limit, offset]);

  const handleOpenCDTSearch = () => {
    history.push(
      `/workspace/${activeWorkspaceUuid}/creator-discovery-tool/search`,
    );
  };

  if (
    !identifyLoading &&
    !identity.permissions?.workspace.includes("view_dashboard") &&
    activeWorkspaceUuid
  ) {
    return <Redirect to={`/workspace/${activeWorkspaceUuid}/projects`} />;
  }

  const renderTableHead = () => (
    <div className="activity__table-head">
      <span className="activity__table-head-activity">
        <IDHFormattedMessage id="ws_activity" defaultMessage="Activity" />
      </span>
      <span className="activity__table-head-time">
        <IDHFormattedMessage id="ws_time" defaultMessage="Time" />
      </span>
      <span className="activity__table-head-project">
        <IDHFormattedMessage id="ws_campaign" defaultMessage="Campaign" />
      </span>
    </div>
  );

  const renderSceletons = () => (
    <div className="activity__table">
      {renderTableHead()}
      <div className="activity__table-body">
        <div key={0}>
          <ActivityElementLoader />
          <ActivityElementLoader />
          <ActivityElementLoader />
        </div>
      </div>
    </div>
  );

  const renderActivityTable = () => (
    <div className="activity__table">
      {renderTableHead()}
      <div className="activity__table-body">
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if (!blockInfinityScroll) {
              setBlockInfinityScroll(true);
              dispatch(setActivityOffset(offset + limit));
            }
          }}
          hasMore={!endOfList && !errorOcurred}
          loader={
            <div key={0}>
              <ActivityElementLoader />
              <ActivityElementLoader />
              <ActivityElementLoader />
            </div>
          }
          useWindow={false}
          getScrollParent={() => scrollRef.current}
        >
          {activityList.map((item: any) => (
            <ActivityItem
              key={typeof item === "string" ? item : item?.createdDate?.date}
              data={item}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );

  const renderNoResultsInfo = () => (
    <div className="activity__no-results">
      <NoResultsScreen
        title={
          <IDHFormattedMessage
            id="ws_there_are_no_activities"
            defaultMessage="There are no activities"
          />
        }
        subtitle={
          identity.permissions?.workspace.includes("creator_discovery") &&
          identity.permissions?.workspace.includes("create_projects") && (
            <IDHFormattedMessage
              id="ws_create_your_first_campaign"
              defaultMessage="Create your first campaign or start looking for creators."
            />
          )
        }
        imgUrl={NoResultsImg}
        bottomContent={
          <>
            {identity.permissions?.workspace.includes("creator_discovery") && (
              <Button
                size="large"
                variant="white-with-border"
                onClick={handleOpenCDTSearch}
              >
                <MagnifyingGlassIcon />
                <IDHFormattedMessage
                  id="ws_go_to_creator_search"
                  defaultMessage="Go to Creator Search"
                />
              </Button>
            )}
            {identity.permissions?.workspace.includes("create_projects") && (
              <Button
                size="large"
                variant="blue"
                onClick={() =>
                  navigateToCreateNewProject(history, activeWorkspaceUuid)
                }
              >
                <PlusSignIcon />
                <IDHFormattedMessage
                  id="ws_create_campaign"
                  defaultMessage="Create campaign"
                />
              </Button>
            )}
          </>
        }
      />
    </div>
  );

  const renderNoFilteredResultsInfo = () => (
    <div className="activity__no-results">
      <NoResultsScreen
        title={
          <IDHFormattedMessage
            id="ws_no_results_found"
            defaultMessage="No results found"
          />
        }
        subtitle={
          <IDHFormattedMessage
            id="ws_remove_filters"
            defaultMessage="No results match the filter criteria. Remove filter or clear all filters to show results."
          />
        }
        imgUrl={noFilteredResultsImg}
        bottomContent={
          <Button
            size="large"
            variant="blue"
            onClick={() => dispatch(clearActivityFilters())}
          >
            <IDHFormattedMessage
              id="ws_clear_filters"
              defaultMessage="Clear filters"
            />
          </Button>
        }
      />
    </div>
  );

  return (
    <div id="scroll-target" className="activity" ref={scrollRef}>
      <div className="activity__topbar-wrapper">
        <div className="activity__topbar">
          <div>
            <h2 className="activity__topbar-heading">
              <IDHFormattedMessage id="ws_activity" defaultMessage="Activity" />
            </h2>
            <h6 className="activity__topbar-description">
              <IDHFormattedMessage
                id="ws_activity_description"
                defaultMessage="Here you can track members activities in your workspace."
              />
            </h6>
          </div>
          {(activityList?.length !== 0 || filtersCounter > 0) && (
            <Button
              variant="white"
              size="small"
              textWithIcon
              onClick={() => setFiltersVisible((prev) => !prev)}
            >
              <FilterIcon />
              <IDHFormattedMessage id="ws_filter" defaultMessage="Filter" />
              {filtersCounter > 0 && `: ${filtersCounter}`}
            </Button>
          )}
        </div>
      </div>
      {activityList?.length > 0 && renderActivityTable()}
      {activityList?.length === 0 && listLoading && renderSceletons()}
      {activityList?.length === 0 &&
        !listLoading &&
        filtersCounter > 0 &&
        renderNoFilteredResultsInfo()}
      {activityList?.length === 0 &&
        !listLoading &&
        filtersCounter === 0 &&
        renderNoResultsInfo()}
      <Pane
        showPane={filtersVisible}
        hidePane={() => setFiltersVisible(false)}
        width={309}
        className="activity-filters-pane"
      >
        <ActivityFilters
          setFiltersVisible={setFiltersVisible}
          membersList={membersList}
          projectsList={projectsList}
          activeWorkspaceUuid={activeWorkspaceUuid}
          filtersApplied={filtersApplied}
          filters={computedFilters}
          initialActivityFilters={initialActivityFilters}
        />
      </Pane>
      <WebsocketListener />
    </div>
  );
}
