import { ReactNode } from "react";

export enum ProjectSetupType {
  Template = "template",
  BasedOnExistingProject = "existingProjectStructure",
  Blank = "blank",
}

type SelectOption = { label: string; value: string };

type CurrenciesData = {
  [key: string]: string;
};

type ProjectSetupOption = {
  value:
    | ProjectSetupType.Template
    | ProjectSetupType.BasedOnExistingProject
    | ProjectSetupType.Blank;
  label: string;
  customElement: ReactNode;
};

export type { SelectOption, CurrenciesData, ProjectSetupOption };
