import {
  GET_AUTOMATION_LIST,
  GET_AUTOMATION_LIST_SUCCESS,
  REFRESH_AUTOMATION_LIST_SUCCESS,
  SET_EDITED_AUTOMATION_DATA,
  SET_SHOW_PUBLICATIONS_DETECTED_MODAL,
  GET_DETECTED_PUBLICATIONS,
  GET_DETECTED_PUBLICATIONS_SUCCESS,
} from "./automationTypes";
import { WsAutomation } from "./types";

export interface AutomationReducerState {
  automationList: WsAutomation[];
  detectedTasks: any;
  projectsList: any;
  automationListLoading: boolean;
  showPublicationsDetectedModal: boolean;
  editedAutomationData: any;
}

const initialState: AutomationReducerState = {
  automationList: [],
  detectedTasks: [],
  projectsList: [],
  automationListLoading: true,
  showPublicationsDetectedModal: false,
  editedAutomationData: {},
};

function automationReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_AUTOMATION_LIST:
      return { ...state, automationListLoading: true };

    case GET_AUTOMATION_LIST_SUCCESS:
      return {
        ...state,
        automationList: action.payload.data.content,
        automationListLoading: false,
      };

    case REFRESH_AUTOMATION_LIST_SUCCESS:
      return {
        ...state,
        automationList: action.payload.data.content,
      };

    case SET_SHOW_PUBLICATIONS_DETECTED_MODAL:
      return {
        ...state,
        showPublicationsDetectedModal:
          action.payload.showPublicationsDetectedModal,
      };

    case GET_DETECTED_PUBLICATIONS:
      return {
        ...state,
        detectedTasks: action.payload.detectedTasks,
      };

    case GET_DETECTED_PUBLICATIONS_SUCCESS:
      const response = action?.payload?.data?.content;

      if (response && response?.length > 0) {
        return {
          ...state,
          showPublicationsDetectedModal: true,
          projectsList: response,
        };
      }

      return state;

    case SET_EDITED_AUTOMATION_DATA:
      return {
        ...state,
        editedAutomationData: action.payload.editedAutomationData,
      };

    default:
      return state;
  }
}

export default automationReducer;
