import * as qs from "qs";

const urlQuery = {
  setParam: (queryString, key, value) => {
    let queryStringObj = {};

    if (queryString.length > 0) {
      queryStringObj = qs.parse(queryString.substring(1));
    }

    const queries = {
      ...queryStringObj,
      [key]: value,
    };

    return qs.stringify(queries);
  },

  checkParam: (queryString, key, value) => {
    let queryStringObj = {};
    if (queryString.length > 0) {
      queryStringObj = qs.parse(queryString.substring(1));
    }

    return queryStringObj[key] === value;
  },

  getParam: (queryString, key) => {
    let queryStringObj = {};
    if (queryString.length > 0) {
      queryStringObj = qs.parse(queryString.substring(1));
    }

    return queryStringObj[key];
  },
};

export default urlQuery;
