import React, { forwardRef, useRef } from "react";
import "./GridBox.scss";

import { ReactComponent as CloseIcon } from "src/images/close.svg";
import { ReactComponent as DragIcon } from "src/images/drag.svg";
import classNames from "classnames";

export const GridBox = forwardRef((props, ref) => {
  const {
    key,
    id,
    children,
    className,
    saveBoxContent,
    removeFromLayout,
    smallPadding,
    disableEditing,
    ...rest
  } = props;

  const contentRef = useRef();

  const Content = children[0];
  const ResizeButton = children[1];

  return (
    <div
      key={key}
      ref={ref}
      onBlur={saveBoxContent}
      className={classNames(
        { "react-grid-item--small": smallPadding },
        className,
      )}
      {...rest}
    >
      <div
        ref={contentRef}
        contentEditable={!disableEditing}
        suppressContentEditableWarning
        className="react-grid-item__content"
      >
        {Content}
      </div>
      <span className="react-drag-handle">
        <DragIcon />
      </span>
      <span className="react-remove-handle" onClick={removeFromLayout}>
        <CloseIcon />
      </span>
      {ResizeButton}
    </div>
  );
});
