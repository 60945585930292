import React from "react";

import moment from "moment";

import { tableDataType } from "src/app/components/Table/Table";
import { SettingsContext } from "src/app/SettingsModal/enums";
import { IMember } from "src/app/project/ProjectTypes";
import { Metadata } from "../../Subtask/Subtask";
import TaskMetaFields from "../../components/TaskMetaFields/TaskMetaFields";
import { AddedBy } from "../../components/AddedBy/AddedBy";

export interface SettingsContextDataProps {
  context: SettingsContext;
  contextTargetUuid: string;
}

interface ParticipationDetailsProps {
  metadata: Metadata[];
  taskOwner: IMember | undefined;
  dateCreated: moment.Moment | null;
  dataType: tableDataType;
  settingsContextData: SettingsContextDataProps;
}

export function ParticipationDetails({
  metadata,
  taskOwner,
  dateCreated,
  dataType,
  settingsContextData,
}: ParticipationDetailsProps) {
  return (
    <>
      <TaskMetaFields
        data={metadata}
        dataType={dataType}
        settingsContextData={settingsContextData}
      />
      <AddedBy
        name={taskOwner?.name}
        avatarUrl={taskOwner?.avatarUrl}
        dateCreated={dateCreated}
      />
    </>
  );
}
