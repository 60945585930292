// @ts-nocheck
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import "./CustomDateRange.scss";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRangePicker } from "react-date-range";

import moment from "moment";
import classNames from "classnames";
import { addDays } from "date-fns";
import { usePopper } from "react-popper";
import { Portal } from "react-overlays";
import { overlaysRef } from "src/App";
import zIndex from "src/utils/zIndex";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import useOnClickOutside from "../../methods/useOnClickOutside";
import { ReactComponent as CrossIcon } from "../../../images/cross.svg";
import { ReactComponent as CalendarIcon } from "../../../images/calendar.svg";
import { momentDateFormat } from "../CustomDatePicker/utils";

interface Props {
  dateFrom: string | null;
  setDateFrom: Dispatch<SetStateAction<string | null>>;
  dateTo: string | null;
  setDateTo: Dispatch<SetStateAction<string | null>>;
  startingRangeDays?: number;
  showError?: boolean;
  errorMessage?: string | ReactNode;
  toRight?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export const CustomDateRange: React.FC<Props> = (props) => {
  const {
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    startingRangeDays,
    showError,
    errorMessage,
    minDate,
    maxDate,
    toRight,
  } = props;

  const [ranges, setRanges] = useState<any>([
    {
      startDate: dateFrom ? new Date(dateFrom) : null,
      endDate: dateTo ? new Date(dateTo) : null,
      key: "selection",
    },
  ]);

  const [showPicker, setShowPicker] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const popperContainer = useRef(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const handleChange = (item: any) => {
    const newRanges = [item.selection];

    setRanges(newRanges);
    const newStartDate = moment(newRanges[0]?.startDate)?.format(
      momentDateFormat,
    );
    const newEndDate = moment(newRanges[0]?.endDate)?.format(momentDateFormat);

    setDateFrom(newStartDate);
    setDateTo(newEndDate);
    if (newStartDate !== newEndDate) setShowPicker(false);
  };

  const handleClear = (e: any) => {
    e.stopPropagation();

    setDateFrom(null);
    setDateTo(null);
    setShowPicker(false);
  };

  useEffect(() => {
    setRanges([
      {
        startDate: dateFrom ? new Date(dateFrom) : new Date(),
        endDate: dateTo
          ? new Date(dateTo)
          : addDays(new Date(), startingRangeDays || 0),
        key: "selection",
      },
    ]);
  }, [dateFrom, dateTo]);

  //

  useOnClickOutside(popperContainer, () => setShowPicker(false));

  //

  return (
    <div
      ref={setReferenceElement}
      onClick={() => setShowPicker(true)}
      className="custom-date-range"
    >
      <div
        className={classNames("custom-date-range__input", {
          "custom-date-range__input--focused": showPicker,
          "custom-date-range__input--error": showError,
        })}
      >
        {!showError ? (
          <>
            <CalendarIcon />
            {dateFrom || (
              <span className="custom-date-range__input-placeholder">
                <IDHFormattedMessage
                  id="ws_start_date"
                  defaultMessage="Start date"
                />
              </span>
            )}
            <span className="custom-date-range__input-separator" />
            {dateTo || (
              <span className="custom-date-range__input-placeholder">
                <IDHFormattedMessage
                  id="ws_end_date"
                  defaultMessage="End date"
                />
              </span>
            )}
            {dateFrom && dateTo ? (
              <CrossIcon
                className="custom-date-range__clear-btn"
                onClick={(e) => handleClear(e)}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <span className="custom-date-range__input-error">{errorMessage}</span>
        )}
      </div>
      <Portal container={overlaysRef}>
        {showPicker && (
          <div ref={popperContainer}>
            <div
              ref={setPopperElement}
              style={{ ...styles.popper, zIndex: zIndex.dropdown }}
              {...attributes.popper}
            >
              <DateRangePicker
                className={classNames({
                  "rdrDateRangePickerWrapper--to-right": toRight,
                })}
                ranges={ranges}
                onChange={handleChange}
                months={2}
                minDate={minDate}
                maxDate={maxDate}
                direction="horizontal"
                showDateDisplay={false}
                color="5D78FF"
              />
            </div>
          </div>
        )}
      </Portal>
    </div>
  );
};
