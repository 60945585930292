import React from "react";
import "./InterestsList.scss";

import { InterestsListItem } from "./InterestsListItem";

export function InterestsList(props) {
  const { data, icon, fullWidthItem } = props;

  return (
    <div className="interests-list">
      {data?.map((item, index) => (
        <InterestsListItem
          item={item}
          key={index}
          icon={icon}
          fullWidthItem={fullWidthItem}
        />
      ))}
    </div>
  );
}
