import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalRow } from "src/app/components/Modal/Modal";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { getSettingsGlobalFields } from "src/redux";
import { RootState } from "src/redux/reducers";
import { TaskType, XtrmPaymentsDetails } from "src/types";
import Loader from "src/app/components/Loader/Loader";
import { wsAxiosPut } from "src/helpers/wsAxios";
import { API_URLS } from "src/utils/API_URLS";

const disabledOption = {
  label: "Disabled",
  value: null,
};

type UpdateXtrmPaymentDetailsFieldName =
  | "amountWsTaskGlobalFieldUuid"
  | "emailWsTaskGlobalFieldUuid";

interface MetaFieldOption {
  label: string;
  value: string | null;
}

interface XTRMPaymentsSettingsProps {
  xtrmPaymentsDetails: XtrmPaymentsDetails;
}

export default function XTRMPaymentsSettings({
  xtrmPaymentsDetails,
}: XTRMPaymentsSettingsProps) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [amountOption, setAmountOption] = useState<MetaFieldOption | null>(
    null,
  );
  const [emailOption, setEmailOption] = useState<MetaFieldOption | null>(null);

  const dispatch = useDispatch();
  const {
    mainReducer: { activeWorkspaceUuid },
    settingsReducer: { globalTaskMetaFields, areGlobalMetaFieldsLoading },
  } = useSelector((state: RootState) => state);

  const updateXtrmPaymentDetails = async (
    fieldName: UpdateXtrmPaymentDetailsFieldName,
    value: string | null,
  ) => {
    let updatePayload = {};

    if (fieldName === "amountWsTaskGlobalFieldUuid") {
      updatePayload = {
        amountWsTaskGlobalFieldUuid: value,
        emailWsTaskGlobalFieldUuid: emailOption?.value ?? null,
      };
    } else {
      updatePayload = {
        amountWsTaskGlobalFieldUuid: amountOption?.value ?? null,
        emailWsTaskGlobalFieldUuid: value,
      };
    }

    const url = API_URLS.updateXtrmPaymentDetails.replace(
      ":wsExtensionUuid:",
      xtrmPaymentsDetails.uuid,
    );
    wsAxiosPut(
      url,
      updatePayload,
      "ws_something_went_wrong",
      () => {
        setIsUpdating(true);
      },
      () => {},
      () => setIsUpdating(false),
    );
  };

  const amountMetaFieldOptions = globalTaskMetaFields
    .filter(
      (item) =>
        item.taskType === TaskType.Payment &&
        item.type === MetaFieldType.Currency,
    )
    .map((item) => ({
      label: `Payments → ${item.name}`,
      value: item.uuid,
    }));

  const emailMetaFieldOptions = globalTaskMetaFields
    .filter(
      (item) =>
        item.taskType === TaskType.Creator &&
        item.type === MetaFieldType.Text &&
        item.showInCreatorDatabase,
    )
    .map((item) => ({
      label: `Creator → ${item.name}`,
      value: item.uuid,
    }));

  const getSelectedMetaFieldOption = (
    fieldName: UpdateXtrmPaymentDetailsFieldName,
    paymentDetails: XtrmPaymentsDetails,
  ) => {
    const prefix =
      fieldName === "amountWsTaskGlobalFieldUuid" ? "Payments →" : "Creator →";

    return {
      label: paymentDetails[fieldName]
        ? `${prefix} ${globalTaskMetaFields.find((item) => item.uuid === paymentDetails[fieldName])?.name}`
        : "Disabled",
      value: paymentDetails[fieldName],
    };
  };

  useEffect(() => {
    dispatch(getSettingsGlobalFields(activeWorkspaceUuid));
  }, [activeWorkspaceUuid]);

  useEffect(() => {
    const setSelectedMetaFieldOptions = () => {
      setAmountOption(
        getSelectedMetaFieldOption(
          "amountWsTaskGlobalFieldUuid",
          xtrmPaymentsDetails,
        ),
      );
      setEmailOption(
        getSelectedMetaFieldOption(
          "emailWsTaskGlobalFieldUuid",
          xtrmPaymentsDetails,
        ),
      );
    };

    setSelectedMetaFieldOptions();
  }, [xtrmPaymentsDetails, globalTaskMetaFields]);

  return (
    <div className="xtrmPayments__settings-wrapper">
      {areGlobalMetaFieldsLoading ? (
        <Loader />
      ) : (
        <>
          <ModalRow>
            <div className="xtrmPayments__settings-label">
              <IDHFormattedMessage
                id="ws_use_default_amount_value"
                defaultMessage="Use default amount value from the field"
              />
            </div>
            <CustomSelect
              value={amountOption}
              options={[disabledOption, ...amountMetaFieldOptions]}
              disabled={isUpdating}
              onChange={(newValue: MetaFieldOption) => {
                updateXtrmPaymentDetails(
                  "amountWsTaskGlobalFieldUuid",
                  newValue.value,
                );
                setAmountOption(newValue);
              }}
            />
          </ModalRow>
          <ModalRow>
            <div className="xtrmPayments__settings-label">
              <IDHFormattedMessage
                id="ws_use_default_email_value"
                defaultMessage="Use default email value from the field"
              />
            </div>

            <CustomSelect
              value={emailOption}
              options={[disabledOption, ...emailMetaFieldOptions]}
              disabled={isUpdating}
              onChange={(newValue: MetaFieldOption) => {
                updateXtrmPaymentDetails(
                  "emailWsTaskGlobalFieldUuid",
                  newValue.value,
                );
                setEmailOption(newValue);
              }}
            />
          </ModalRow>
        </>
      )}
    </div>
  );
}
