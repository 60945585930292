import React, { useEffect, useState } from "react";
import { TagCloud } from "react-tagcloud";
import classNames from "classnames";
import "./CustomTagCloud.scss";

export function CustomTagCloud(props) {
  const { data, blue, green } = props;

  const [minWordValue, setMinWordValue] = useState(1);
  const [maxWordValue, setMaxWordValue] = useState(20);

  const minFontSize = 12;
  const maxFontSize = 20;

  useEffect(() => {
    let minVal = 0;
    let maxVal = 0;

    for (let i = 0; i < data.length; i++) {
      if (!minVal || data[i].value < minVal) {
        minVal = data[i].count;
      }
      if (data[i].value > maxVal) {
        maxVal = data[i].count;
      }
    }

    setMinWordValue(minVal);
    setMaxWordValue(maxVal);
  }, [data]);

  const wordSize = (wordValue) => {
    const proportional =
      (wordValue - minWordValue) / (maxWordValue - minWordValue);
    return parseInt(minFontSize + proportional * (maxFontSize - minFontSize));
  };

  const tagOpacity = (index) => {
    return 1 - (index % 5) / 10;
  };

  const customRenderer = (tag) => {
    return (
      <span
        key={tag.value}
        className="tag-cloud__item"
        style={{
          opacity: tagOpacity(data.findIndex((el) => el.value === tag.value)),
          fontSize: wordSize(tag.count),
        }}
      >
        {`#${tag.value}`}
      </span>
    );
  };

  return (
    <TagCloud
      className={classNames("tag-cloud", {
        "tag-cloud--green": green,
        "tag-cloud--blue": blue,
      })}
      renderer={customRenderer}
      style={{
        height: data.length * 25,
      }}
      tags={data}
    />
  );
}
