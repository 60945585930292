import React from "react";
import "./SingleFilter.scss";

import { capitalizeFirstLetter } from "src/utils/methods";
import { MultiCheckboxFilter } from "../MultiCheckboxFilter/MultiCheckboxFilter";
import { IPerformanceFilters } from "../../PerformanceFilters";
import { DatePickerFilter } from "../DatePickerFilter/DatePickerFilter";

interface Props {
  name: string;
  type: "date" | "checkbox";
  data: any;
  filters: IPerformanceFilters;
  updateFilter: (key: string, value: any) => void;
}

export const SingleFilter: React.FC<Props> = (props) => {
  const { name, type, data, filters, updateFilter } = props;

  const renderInput = () => {
    switch (type) {
      case "date":
        return (
          <DatePickerFilter
            name={name}
            filters={filters}
            updateFilter={updateFilter}
          />
        );

      case "checkbox":
        return (
          <MultiCheckboxFilter
            name={name}
            data={data}
            filters={filters}
            updateFilter={updateFilter}
          />
        );

      default:
        return <i>unknown type</i>;
    }
  };

  return (
    <div className="single-filter">
      <label className="single-filter__label">
        {capitalizeFirstLetter(name)}
      </label>
      {renderInput()}
    </div>
  );
};
