import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";
import { ReactComponent as CloseIcon } from "src/images/close.svg";
import { useOnClickOutside } from "../../base/utils/useOnOutsideClick";
import "./ContentCardPreview.scss";

class ContentCardPreview extends React.Component {
  el = document.createElement("div");

  componentDidMount() {
    this.el.classList.add("preview-overlay");
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      <ContentCardPreviewContent {...this.props} />,
      this.el,
    );
  }
}

function ContentCardPreviewContent({ onClose, preview, ...props }) {
  const ref = useRef();
  const [activeSlide, setActiveSlide] = useState(0);
  const [loadedImages, setLoadedImages] = useState({});

  useOnClickOutside(ref, () => {
    onClose();
  });

  useEffect(() => {
    const videoPreviews = document.getElementsByClassName("preview__video");
    for (let i = 0; i < videoPreviews.length; i++) {
      if (videoPreviews[i].classList.contains("preview__video--active")) {
        videoPreviews[i].play();
      } else {
        videoPreviews[i].pause();
      }
    }
  }, [activeSlide]);

  return (
    <div
      ref={ref}
      className={classNames("preview", "post-preview-modal", {
        "preview--video": preview[activeSlide].type === "video",
      })}
    >
      {preview.length > 1 && (
        <>
          <div
            className={classNames("preview__button preview__button--prev", {
              "preview__button--disabled": activeSlide === 0,
            })}
            onClick={() => setActiveSlide(activeSlide - 1)}
          />
          <div
            className={classNames("preview__button preview__button--next", {
              "preview__button--disabled": activeSlide === preview.length - 1,
            })}
            onClick={() => setActiveSlide(activeSlide + 1)}
          />
          <div className="preview__counter">
            {activeSlide + 1}/{preview.length}
          </div>
        </>
      )}

      <div className="preview__slider">
        {preview.map((item, index) => (
          <div
            key={index}
            className={classNames("preview__slide", {
              "preview__slide--active": index === activeSlide,
            })}
          >
            {/* Load content only if you need it -> improved time to load first slide */}
            {index <= activeSlide &&
              (item.type === "image" ? (
                <div className="preview__img-wrapper" key={index}>
                  <img
                    src={item.mediaUrl}
                    alt="post image preview"
                    className="preview__img"
                    onLoad={() =>
                      setLoadedImages({
                        ...loadedImages,
                        [index]: true,
                      })
                    }
                  />
                  {!loadedImages[index] && item.thumbnailMediaUrl && (
                    <img
                      src={item.thumbnailMediaUrl}
                      alt="post image preview"
                      className="preview__img-loader"
                    />
                  )}
                </div>
              ) : (
                <video
                  key={index}
                  className={classNames("preview__video", {
                    "preview__video--active": index === activeSlide,
                  })}
                  controls="controls"
                  autoPlay
                  playsInline
                >
                  <source src={item.mediaUrl} type="video/mp4" />
                  <source src={item.mediaUrl} type="video/webm" />
                  <source src={item.mediaUrl} type="video/ogg" />
                  <p>
                    Your user agent does not support the HTML5 Video element.
                  </p>
                </video>
              ))}
          </div>
        ))}
      </div>

      <div className="preview__close" onClick={onClose}>
        <CloseIcon width={24} height={24} />
      </div>
    </div>
  );
}

export default ContentCardPreview;
