import React, { useState, Dispatch, SetStateAction } from "react";
import { uuidv7 } from "uuidv7";
import classNames from "classnames";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, {
  ModalRow,
  ModalText,
  ModalTitle,
} from "src/app/components/Modal/Modal";
import { AttachmentMetaDataAndKey } from "../AddCommentField/AddCommentField";
import {
  isValidUrl,
  tryConvertToCorrectUrl,
} from "../../../../helpers/textHelper";
import { showToast } from "../../../methods/showToast";

interface AddLinksModalProps {
  onClose: () => void;
  setAttachments: Dispatch<SetStateAction<AttachmentMetaDataAndKey[]>>;
}

export default function AddLinksModal({
  onClose,
  setAttachments,
}: AddLinksModalProps) {
  const [value, setValue] = useState("");

  const onConfirmClick = () => {
    const links = value.split("\n").filter((el) => el !== "");

    const externalLinksAttachments: AttachmentMetaDataAndKey[] = links.map(
      (link) => ({
        attachment: {
          name: null,
          mimeType: null,
          externalUrl: link,
        },
        localUuid: uuidv7(),
      }),
    );

    externalLinksAttachments.forEach((link: AttachmentMetaDataAndKey) => {
      link.attachment.externalUrl =
        tryConvertToCorrectUrl(link.attachment.externalUrl ?? null) ??
        link.attachment.externalUrl;
    });

    let existsAtLeastOneErrorUrl = false;
    const lastUrlInvalid: string[] = [];
    externalLinksAttachments.map((link: AttachmentMetaDataAndKey) => {
      if (!isValidUrl(link.attachment.externalUrl ?? null)) {
        if (link.attachment.externalUrl) {
          lastUrlInvalid.push(link.attachment.externalUrl);
        }

        existsAtLeastOneErrorUrl = true;
      }
    });

    if (existsAtLeastOneErrorUrl) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage id="ws_error_at_least_one_url_invalid" />,
      );

      lastUrlInvalid.forEach((externalUrl: string) => {
        showToast("error", null, externalUrl);
      });

      return;
    }

    setAttachments((prev) => [...prev, ...externalLinksAttachments]);
    onClose();
  };

  const stringLongerThenOneLine = value.includes("\n");

  return (
    <Modal
      className="add-links-modal"
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      confirmButtonText={
        <IDHFormattedMessage id="ws_confirm" defaultMessage="Confirm" />
      }
      onClose={onClose}
      displayCancelButton
      onConfirmClick={onConfirmClick}
      closeButtonVariant="white-with-black-border"
      confirmButtonDisabled={value.length === 0}
    >
      <ModalText>
        <ModalTitle>
          <IDHFormattedMessage
            id="ws_paste_links"
            defaultMessage="Paste link(s)"
          />
        </ModalTitle>

        <IDHFormattedMessage
          id="ws_you_can_add_several_at_once"
          defaultMessage="You can add several at once, separating with spaces."
        />
      </ModalText>
      <ModalRow>
        <textarea
          className={classNames("add-links-modal__textarea", {
            "add-links-modal__textarea--not-empty": stringLongerThenOneLine,
          })}
          value={value}
          onChange={(e) => setValue(e.target.value.replace(/[ ,]/g, "\n"))}
        />
      </ModalRow>
    </Modal>
  );
}
