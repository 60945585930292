import React, { useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import "./AddCreatorsModal.scss";

import Modal from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as WarningIcon } from "src/images/warning-circle.svg";
import { showToast } from "src/app/methods/showToast";
import {
  addCreatorsToListFromSocials,
  getInfluencersListData,
} from "../audiencesManagerActions";
import CONSTS from "../../helpers/consts";

function AddCreatorsModal(props) {
  const {
    onClose,
    intl,
    addCreatorsToListFromSocials,
    listId,
    showAddCreatorsInfoModal,
    getInfluencersListData,
    influencersInList,
    awaitingUsers,
    sortByChanged,
    listSocialProvider,
  } = props;
  const [value, setValue] = useState("");
  const [showError, setShowError] = useState(false);
  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);

  const onConfirmClick = async () => {
    const usersSocials = value.split("\n").filter((el) => el !== "");
    const usersCountAfterAdding =
      usersSocials.length + awaitingUsers.length + influencersInList.length;

    if (usersCountAfterAdding > CONSTS.audienceListUsersLimit) {
      setShowError(true);
      return;
    }

    setConfirmButtonLoading(true);
    try {
      const data = await addCreatorsToListFromSocials(listId, usersSocials);
      const { awaitingUsers } = data.payload.data.content;
      setConfirmButtonLoading(false);
      onClose();

      if (awaitingUsers.length > 0) {
        showAddCreatorsInfoModal();
      }

      sortByChanged({ value: "recentlyAdded", label: "Recently added" });
      getInfluencersListData(listId, 1, null);
    } catch (err) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="front_error_occurred_during_adding_influencers_to_the_list"
          defaultMessage="Error occurred during adding influencers to the list"
        />,
      );
      onClose();
    }
  };

  const stringLongerThenOneLine = value.includes("\n");

  return (
    <Modal
      onClose={onClose}
      title={
        <IDHFormattedMessage
          id="front_modal_add_creator"
          defaultMessage="Add creator"
        />
      }
      displayCancelButton
      confirmButtonLoading={confirmButtonLoading}
      confirmButtonDisabled={value.length === 0}
      closeButtonText={
        <IDHFormattedMessage id="front_bd_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={onConfirmClick}
    >
      <div className="modal__text">
        <IDHFormattedMessage
          id={`front_modal_here_you_can_add_one_or_many_creators_${listSocialProvider}`}
          defaultMessage="Here you can add one or many Creators."
        />
      </div>
      <textarea
        className={classNames("add-creators-modal__textarea", {
          "add-creators-modal__textarea--not-empty": stringLongerThenOneLine,
        })}
        placeholder={intl.formatMessage({
          id: "front_enter_links_separated_commas_or_spaces",
          defaultMessage: "Enter links separated by commas or spaces",
        })}
        value={value}
        onChange={(e) => setValue(e.target.value.replace(/[ ,]/g, "\n"))}
        disabled={confirmButtonLoading}
      />
      {showError && (
        <div className="add-creators-modal__error">
          <WarningIcon className="add-creators-modal__error-icon" />
          <IDHFormattedMessage
            id="front_you_can_have_a_maximum_of_100_creators_on_the_list"
            defaultMessage="You can have a maximum of 100 Creators on the list."
          />
        </div>
      )}
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addCreatorsToListFromSocials: (listId, usersSocials) =>
      dispatch(addCreatorsToListFromSocials(listId, usersSocials)),
    getInfluencersListData: (listId, pageSelected, sortBy) =>
      dispatch(getInfluencersListData(listId, pageSelected, sortBy)),
  };
};

export default connect(null, mapDispatchToProps)(injectIntl(AddCreatorsModal));
