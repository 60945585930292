import React, { useState } from "react";
import Modal from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { injectIntl } from "react-intl";

function EditNameModal(props) {
  const { onClose, onConfirmClick, name, intl, confirmButtonLoading } = props;

  const [listName, setListName] = useState(name || "");

  return (
    <Modal
      onClose={onClose}
      className="edit-name-modal"
      title={
        <IDHFormattedMessage
          id="front_modal_edit_name"
          defaultMessage="Edit name"
        />
      }
      confirmButtonLoading={confirmButtonLoading}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="front_bd_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={() => onConfirmClick(listName)}
      confirmButtonDisabled={!listName.length}
      confirmButtonText={
        <IDHFormattedMessage id="front_save" defaultMessage="Save" />
      }
    >
      <div className="modal__text">
        <IDHFormattedMessage
          id="front_modal_edit_list_name_text"
          defaultMessage="Type new name of audience list"
        />
      </div>
      <input
        className="idh-form__input"
        placeholder={intl.formatMessage({
          id: "front_type_audience_name",
          defaultMessage: "Type audience name...",
        })}
        disabled={confirmButtonLoading}
        value={listName}
        onChange={(e) => setListName(e.target.value)}
        onKeyPress={(e) =>
          e.key === "Enter" && listName.length > 0 && onConfirmClick(listName)
        }
      />
    </Modal>
  );
}

export default injectIntl(EditNameModal);
