export default {
  "200_OK": 200,
  "201_CREATED": 201,
  "401_UNAUTHORIZED": 401,
  "402_PAYMENT_REQUIRED": 402,
  "403_FORBIDDEN": 403,
  "404_NOT_FOUND": 404,
  "409_CONFLICT": 409,
  "500_INTERNAL_SERVER_ERROR": 500,
};
