import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { showToast } from "src/app/methods/showToast";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { GalleryLightbox } from "src/app/components/GalleryLightbox/GalleryLightbox";
import { setGallerySources } from "../../../redux";
import { AttachmentPlacement, TaskType } from "../../../types";
import { ImageAttachment } from "./AttachmentTypes/ImageAttachment";
import { DefaultAttachment } from "./AttachmentTypes/DefaultAttachment";
import { VideoAttachment } from "./AttachmentTypes/VideoAttachment";
import getAttachmentIcon from "./utils/getAttachmentIcon";
import UrlAttachment from "./AttachmentTypes/UrlAttachment";

import "./Attachment.scss";

export interface AttachmentMetaData {
  id?: string;
  mimeType: string | null;
  name: string | null;
  publicUrl?: string;
  publicPreviewUrl?: string;
  parentUuid?: string;
  downloadUrl?: string;
  externalUrl?: string;
  downloadPreviewUrl?: string;
  thumbnails?: {
    tiny?: string | null;
    small?: string | null;
    big?: string | null;
  };
}

const getFileType = (data: AttachmentMetaData) => {
  let type = "";

  if (!data) {
    return type;
  }

  const { mimeType, externalUrl } = data;
  if (mimeType && typeof mimeType === "string") {
    [type] = mimeType.split("/");
  }

  if (externalUrl) {
    type = "url";
  }

  return type;
};

const getFileExtension = (data: AttachmentMetaData) => {
  let extension = "";
  if (!data) {
    return extension;
  }
  const { name, externalUrl } = data;
  if (name && typeof name === "string") {
    const fileNameAr = name.split(".");
    if (fileNameAr.length) {
      extension = fileNameAr[fileNameAr.length - 1];
    }
  }
  if (externalUrl) {
    extension = "url";
  }
  return extension;
};

interface Props {
  data: AttachmentMetaData;
  place: AttachmentPlacement;
  taskType: TaskType;
  customGalleryClickHandler?: any;
  handleRemoveAttachment?: (e: React.MouseEvent) => void;
}

function Attachment(props: Props) {
  const {
    data,
    place,
    taskType,
    customGalleryClickHandler,
    handleRemoveAttachment,
  } = props;
  const [isHovered, setIsHovered] = useState(false);

  const type = getFileType(data);
  const fileExtension = getFileExtension(data);

  const dispatch = useDispatch();

  const onGalleryAttachmentClick = () => {
    const publicUrl = data?.publicUrl || data?.publicPreviewUrl;

    const generatedSources = [{ url: publicUrl, type }];

    if (customGalleryClickHandler) customGalleryClickHandler(generatedSources);
    else if (publicUrl) {
      dispatch(setGallerySources(generatedSources));
    } else {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_error_opening_preview"
          defaultMessage="Couldn't open file preview"
        />,
      );
    }
  };

  const getAttachmentByType = () => {
    switch (type) {
      case "image":
        return (
          <ImageAttachment
            place={place}
            data={data}
            onGalleryAttachmentClick={onGalleryAttachmentClick}
            setIsHovered={setIsHovered}
            isHovered={isHovered}
            taskType={taskType}
            fileExtension={fileExtension}
            handleRemoveAttachment={handleRemoveAttachment}
          />
        );
      case "video":
        return (
          <VideoAttachment
            place={place}
            data={data}
            onGalleryAttachmentClick={onGalleryAttachmentClick}
            setIsHovered={setIsHovered}
            isHovered={isHovered}
            taskType={taskType}
            fileExtension={fileExtension}
            handleRemoveAttachment={handleRemoveAttachment}
          />
        );
      case "application":
      case "text":
      case "audio":
        return (
          <DefaultAttachment
            data={data}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            place={place}
            getIcon={() => getAttachmentIcon(fileExtension)}
            fileExtension={fileExtension}
            taskType={taskType}
            handleRemoveAttachment={handleRemoveAttachment}
            onGalleryAttachmentClick={onGalleryAttachmentClick}
          />
        );
      case "url":
        return (
          <UrlAttachment
            data={data}
            place={place}
            handleRemoveAttachment={handleRemoveAttachment}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            fileExtension={fileExtension}
            onGalleryAttachmentClick={onGalleryAttachmentClick}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {getAttachmentByType()}
      <GalleryLightbox />
    </>
  );
}

export default Attachment;
