import React from "react";
import { useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as MagnifierBlueIcon } from "../../../images/magnifier-blue.svg";
import { ReactComponent as SmileBlueIcon } from "../../../images/smile-blue.svg";
import { ReactComponent as RocketBlueIcon } from "../../../images/menu-icons/rocket-blue.svg";
import { ReactComponent as PlusTransparentIcon } from "../../../images/plus-transparent.svg";
import { TrialLimitationsListItem } from "./TrialLimitationsListItem";

function TrialLimitationsList(props) {
  const { trialLimits } = props;

  return (
    <ul className="trial-limitations__list">
      <TrialLimitationsListItem
        title={
          <IDHFormattedMessage
            id="ws_trial_limitations_list_search_requests_limit"
            defaultMessage="20 searches in Creator Discovery Tool"
          />
        }
        icon={
          <MagnifierBlueIcon className="trial-limitations-list__item-icon" />
        }
        actionsLeft={trialLimits.searchRequestsLimit}
        maxActions={20}
      />
      <TrialLimitationsListItem
        title={
          <IDHFormattedMessage
            id="ws_trial_limitations_list_lists_limit"
            defaultMessage="Creating 2 lists in Creator Discovery Tool"
          />
        }
        icon={<SmileBlueIcon className="trial-limitations-list__item-icon" />}
        actionsLeft={trialLimits.listsLimit}
        maxActions={2}
      />
      <TrialLimitationsListItem
        title={
          <IDHFormattedMessage
            id="ws_trial_limitations_list_ws_projects_limit"
            defaultMessage="Running 2 full range campaigns"
          />
        }
        icon={<RocketBlueIcon className="trial-limitations-list__item-icon" />}
        actionsLeft={trialLimits.wsProjectsLimit}
        maxActions={2}
      />
      <TrialLimitationsListItem
        title={
          <IDHFormattedMessage
            id="ws_trial_limitations_list_ws_tasks_limit"
            defaultMessage="Adding 50 objects (2 campaigns in total)"
          />
        }
        description={
          <IDHFormattedMessage
            id="ws_trial_limitations_list_wsProjectsLimit_description"
            defaultMessage="Creators, Content, Publications, Actions, Payments"
          />
        }
        icon={
          <PlusTransparentIcon className="trial-limitations-list__item-icon" />
        }
        actionsLeft={trialLimits.wsTasksLimit}
        maxActions={50}
      />
    </ul>
  );
}

export { TrialLimitationsList };
