import React from "react";
import { useHistory } from "react-router";
import { SettingsContext, SettingsTab } from "src/app/SettingsModal/enums";
import { openSettingsModal } from "src/app/SettingsModal/methods";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalText } from "src/app/components/Modal/Modal";
import { AppLocation } from "src/redux/main/mainReducer";

interface Props {
  onClose: () => void;
}

export const StepOne: React.FC<Props> = (props) => {
  const { onClose } = props;

  const history = useHistory();

  const openConfiguration = () => {
    onClose();
    openSettingsModal(
      history,
      AppLocation.PromoCodes,
      SettingsContext.Workspace,
      SettingsTab.Coupons,
    );
  };

  return (
    <AnimatedDiv>
      <span className="how-it-works__step-title">
        <IDHFormattedMessage
          id="ws_store_configuration"
          defaultMessage="Store configuration"
        />
      </span>
      <ModalText>
        <IDHFormattedMessage
          id="ws_how_it_works_step_1_a"
          defaultMessage="In the first step, set up your e-commerce platform. Go to"
        />
        &nbsp;
        <span className="how-it-works__link" onClick={openConfiguration}>
          "
          <IDHFormattedMessage
            id="ws_store_configuration"
            defaultMessage="Store configuration"
          />
          "
        </span>
        &nbsp;
        <IDHFormattedMessage
          id="ws_how_it_works_step_1_b"
          defaultMessage="where you will find full instructions for proper integration."
        />
      </ModalText>
      <video autoPlay loop>
        <source src={require("../../../videos/step-1.mp4")} type="video/mp4" />
      </video>
    </AnimatedDiv>
  );
};
