import React from "react";
import "./ReportFooter.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as IndaHash } from "../../../../images/menu-icons/idh-logotype.svg";
import ProjectPlaceholder from "../../../../images/project-placeholder.svg";
import { getImageSource } from "../../../../utils/methods";

export function ReportFooter(props) {
  const { data } = props;

  return (
    <div className="report-footer">
      <div className="report-footer__campaign">
        <div className="report-footer__campaign-data">
          <img
            className="report-footer__campaign-data-logo"
            src={getImageSource(data?.cover, "small", ProjectPlaceholder)}
            alt="logo"
          />
          <span className="report-footer__campaign-data-title">
            {data.title}
          </span>
          <span className="report-footer__campaign-data-thank-you">
            <IDHFormattedMessage id="ws_thank_you" defaultMessage="Thank you" />
          </span>
        </div>
      </div>
      <div className="report-footer__indahash">
        <div className="report-footer__indahash-logo">
          <IDHFormattedMessage id="ws_powered_by" defaultMessage="Powered by" />
          <IndaHash />
        </div>
      </div>
    </div>
  );
}
