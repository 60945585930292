import React from "react";
import { useDispatch } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as ReplyIcon } from "src/images/reply.svg";
import { setReplyToCommentData } from "src/redux";
import { ContentProposalElement } from "src/redux/comment/types";

interface ReplyToCommentButtonProps {
  wsTaskUuid: string;
  parentWsTaskCommentUuid: string | null;
  replyToCommentText: string;
  replyToCommentContent?: ContentProposalElement[];
}

export default function ReplyToCommentButton({
  wsTaskUuid,
  parentWsTaskCommentUuid,
  replyToCommentText,
  replyToCommentContent = [],
}: ReplyToCommentButtonProps) {
  const dispatch = useDispatch();

  const handleSetReplyCommentData = () => {
    dispatch(
      setReplyToCommentData({
        wsTaskUuid,
        parentWsTaskCommentUuid,
        replyToCommentText,
        replyToCommentContent,
      }),
    );
  };

  return (
    <button
      onClick={handleSetReplyCommentData}
      className="comment__reply-btn"
      aria-label="Reply"
      type="button"
    >
      <ReplyIcon />
      <IDHFormattedMessage id="ws_reply" defaultMessage="Reply" />
    </button>
  );
}
