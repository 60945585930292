import React, { Dispatch, SetStateAction } from "react";
import "./FieldSynchronizationWarningModal.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "../../components/Button/Button";
import Modal, { ModalText, ModalTitle } from "../../components/Modal/Modal";

interface Props {
  onClose: Dispatch<SetStateAction<boolean>>;
  onAccept: () => void;
}

export const FieldSynchronizationWarningModal: React.FC<Props> = (props) => {
  const { onClose, onAccept } = props;

  return (
    <Modal
      className="field-synchronization-warning-modal"
      variant="medium"
      onClose={onClose}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={onAccept}
      confirmButtonText={
        <IDHFormattedMessage id="ws_yes" defaultMessage="Yes" />
      }
    >
      <ModalTitle>
        <IDHFormattedMessage id="ws_warning" defaultMessage="Warning" />
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_field_synchronization_text_a"
          defaultMessage="You are editing a field that is synchronizable. When you enter your own value, the field will not be synced."
        />
        <br />
        <br />
        <IDHFormattedMessage
          id="ws_field_synchronization_text_b"
          defaultMessage="You can delete your own value at any time. Then field will be synchronized immediately according to the selected data type."
        />
        <br />
        <br />
        <b>
          <IDHFormattedMessage
            id="ws_field_synchronization_text_c"
            defaultMessage="Do you want to enter your own value for this field?"
          />
        </b>
      </ModalText>
    </Modal>
  );
};
