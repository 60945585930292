import React, { useState, useRef } from "react";
import "./InviteMemberTeam.scss";

import classNames from "classnames";
import { injectIntl } from "react-intl";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

import { useSelector } from "react-redux";

import { Button } from "src/app/components/Button/Button";
import { SimpleSelect } from "src/app/components/SimpleSelect/SimpleSelect";

import spinnySpinner from "src/images/spinner-small.gif";

import { translateMessage } from "src/app/methods/translateMessage";
import CreatableSelect from "react-select/creatable";
import { ReactComponent as EnvelopeIcon } from "src/images/envelope.svg";
import { ReactComponent as CloseIcon } from "src/images/mutableCloseIcon.svg";
import { API_URLS } from "../../../../../../../../utils/API_URLS";
import { showToast } from "../../../../../../../methods/showToast";
import { inviteSelectStyles } from "../../../../../../../modals/ShareModal/InviteUser/utils/inviteSelectStyles";
import { StackedAvatars } from "../../../../../../../components/StackedAvatars/StackedAvatars";
import { wsAxiosPost } from "../../../../../../../../helpers/wsAxios";

function InviteMemberTeam(props) {
  const { intl, teams, getMembersList, currentMemberList } = props;

  const [users, setUsers] = useState([]);
  const selectRef = useRef(null);
  const valueContainerRef = useRef(null);
  const anchorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedAccessLevel, setSelectedAccessLevel] = useState({
    name: <IDHFormattedMessage id="ws_viewer" defaultMessage="Viewer" />,
    value: "viewer",
  });

  const {
    mainReducer: { activeWorkspaceUuid, settingsModalData },
    projectReducer: { membersList },
  } = useSelector((state) => state);

  const { objectUuid } = settingsModalData;
  const filterOption = (candidate, input) => {
    return (
      candidate.data.name?.toLowerCase().includes(input.toLowerCase()) ||
      candidate.data.email?.toLowerCase().includes(input.toLowerCase())
    );
  };

  const prepareData = () => {
    const groupedOptionsNew = [
      {
        label: "Teams",
        options: teams
          ?.filter(
            (identityTeam) =>
              !currentMemberList?.find(
                (memberTeamRow) =>
                  memberTeamRow.wsTeamUuid === identityTeam.uuid,
              ) && identityTeam,
          )
          .map((team) => ({
            name: team.name,
            label: team.name,
            value: team.uuid,
            members: team.wsMembers,
            type: "team",
          })),
      },
      {
        label: "Members",
        options: membersList
          ?.filter(
            (identityMember) =>
              !currentMemberList?.find(
                (memberTeamRow) =>
                  memberTeamRow.wsMemberUuid === identityMember.id,
              ) && identityMember,
          )
          .map((member) => ({
            label: member.name,
            name: member.name,
            email: member.email,
            value: member.id,
            avatarUrl: member.avatarUrl,
            type: "member",
          })),
      },
    ];

    return groupedOptionsNew;
  };

  const addMultipleMemberOrTeamToMetaField = () => {
    wsAxiosPost(
      API_URLS.assignMultipleMemberTeamToGlobalMetaField,
      {
        wsGlobalTaskMetaFieldUuid: objectUuid,
        wsWorkspaceUuid: activeWorkspaceUuid,
        wsMemberUuids:
          users
            ?.filter((item) => item.type !== "team")
            .map((item) => {
              return item?.value;
            }) ?? [],
        wsTeamUuids:
          users
            ?.filter((item) => item.type === "team")
            .map((item) => {
              return item?.value;
            }) ?? [],
        accessLevel: selectedAccessLevel.value,
      },
      "ws_member_assign_to_global_task_meta_field",
      () => {
        setLoading(true);
      },
      () => {
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id="ws_member_assign_to_global_task_meta_field"
            defaultMessage="Member assign to meta field"
          />,
        );
        setUsers([]);
        getMembersList(activeWorkspaceUuid);
      },
      () => {
        setLoading(false);
      },
    );
  };

  const formatOptionLabel = (option) => (
    <div className="meta-field-members__label-row">
      <div className="meta-field-members__label-row-name">{option.label}</div>
      {option.type === "team" ? (
        <StackedAvatars
          avatars={option.members?.map((member) => ({
            url: member?.avatarUrl,
          }))}
          maxAvatars={5}
        />
      ) : (
        <StackedAvatars avatars={[{ url: option?.avatarUrl }]} maxAvatars={1} />
      )}
    </div>
  );

  return (
    <div className="meta-field-members__user-select" ref={valueContainerRef}>
      <div className="meta-field-members__user-select-row">
        <div className="meta-field-members__add-container-member-list">
          <CreatableSelect
            className={classNames(["meta-field-members__user-select-input"])}
            closeMenuOnSelect={false}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
              MultiValueRemove: (props) => (
                <div {...props.innerProps}>
                  <CloseIcon
                    {...props}
                    width={16}
                    height={16}
                    fill={props.data.color || "#8E93AC"}
                  />
                </div>
              ),
            }}
            value={users}
            isSearchable
            isMulti
            withTiles
            onChange={setUsers}
            options={prepareData()}
            placeholder={translateMessage({
              intl,
              id: "ws_select_member_or_team",
              defaultMessage: "Select member or team",
            })}
            filterOption={filterOption}
            openMenuOnFocus
            menuPortalTarget={document.querySelector(".Overlays") || undefined}
            ref={selectRef}
            styles={inviteSelectStyles}
            formatOptionLabel={formatOptionLabel}
            formatCreateLabel={(inputText) => (
              <div className="teams__user-select-invite">
                <EnvelopeIcon className="teams__user-select-invite-env" />
                <IDHFormattedMessage id="ws_invite" defaultMessage="Invite" />
                <div className="teams__user-select-invite-email">
                  {inputText}
                </div>
              </div>
            )}
          />
        </div>

        <div className="meta-field-members__add-container-roles">
          <SimpleSelect
            options={[
              {
                name: (
                  <IDHFormattedMessage id="ws_viewer" defaultMessage="Viewer" />
                ),
                value: "viewer",
              },
              {
                name: (
                  <IDHFormattedMessage id="ws_editor" defaultMessage="Editor" />
                ),
                value: "editor",
              },
            ]}
            selectedOption={selectedAccessLevel}
            setSelectedOption={setSelectedAccessLevel}
          />
        </div>
        {loading !== true ? (
          <div className="meta-field-members__add-container-btn">
            <Button
              variant="blue"
              size="large"
              disabled={loading || !selectedAccessLevel || !(users.length > 0)}
              onClick={() => addMultipleMemberOrTeamToMetaField()}
            >
              <IDHFormattedMessage id="ws_add" defaultMessage="Add" />
            </Button>
          </div>
        ) : (
          <div className="meta-field-members__user-select-spinner">
            <img src={spinnySpinner} width={48} alt="Loading..." />
          </div>
        )}
      </div>
      <div
        className="meta-field-members__user-select__anchor"
        ref={anchorRef}
      />
    </div>
  );
}

export default injectIntl(InviteMemberTeam);
