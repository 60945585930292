import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  MouseEvent,
} from "react";

import { WrappedComponentProps, injectIntl } from "react-intl";
import classNames from "classnames";
import {
  DragDropContext,
  DragUpdate,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { uuidv7 } from "uuidv7";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { usePopper } from "react-popper";

import { updateDictionaryElementSelectMetaField } from "src/redux";
import { translateMessage } from "src/app/methods/translateMessage";
import SelectLabel from "src/app/components/Select/components/SelectLabel/SelectLabel";
import AddButton from "src/app/components/AddButton/AddButton";
import { generateRankString } from "src/utils/rankStrings";
import {
  getUnusedColor,
  shouldDisplayAction,
} from "src/app/components/Select/utils";
import { showToast } from "src/app/methods/showToast";
import { DropdownMenuItem } from "src/app/components/Dropdown/Dropdown";
import { ReactComponent as TickIcon } from "src/images/tick-blue.svg";
import { ReactComponent as GridIcon } from "src/images/grid.svg";
import { ReactComponent as EditIcon } from "src/images/edit.svg";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Mode, SelectOption, Variant } from "src/app/components/Select/types";
import { MenuOverlay } from "src/app/components/Select/components/MenuOverlay/MenuOverlay";
import useOnClickOutside from "src/app/methods/useOnClickOutside";
import { updateCreatorDatabaseSelectField } from "src/redux/creator-database/creatorDatabaseActions";
import { tableDataType } from "src/app/components/Table/Table";
import { RootState } from "src/redux/reducers";
import { GlideDataGridContext } from "../../types";
import { dropdownRef } from "../GlideDataGridDropdownWrapper/GlideDataGridDropdownWrapper";
import EditOptionPane from "../../../Select/components/EditOptionPane/EditOptionPane";
import NoResultsMessage from "../../../NoResultsMessage/NoResultsMessage";

interface SelectDropdownProps extends WrappedComponentProps<"intl"> {
  optionsData?: SelectOption[];
  mode: Mode;
  fieldValue: string | string[] | undefined;
  variant?: Variant;
  updateValue: (newValue: string | string[]) => void;
  context: GlideDataGridContext;
  uuid: string;
  closeDropdownHanler: () => void;
  wsSelectDataSetUuid?: string | null;
}

type PlaceholderProps = {
  clientHeight: number;
  clientWidth: number;
  clientY: number;
  clientX: number;
};

function SelectDropdown({
  optionsData,
  uuid,
  intl,
  mode,
  fieldValue,
  variant = "default",
  updateValue,
  context,
  closeDropdownHanler,
  wsSelectDataSetUuid,
}: SelectDropdownProps) {
  const [showEditOptionPane, setShowEditOptionPane] = useState(false);
  const [multiValue, setMultiValue] = useState<SelectOption[]>([]);
  const [initialized, setInitialized] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>([]);
  const [placeholderProps, setPlaceholderProps] =
    useState<PlaceholderProps | null>(null);
  const [optionToEdit, setOptionToEdit] = useState<SelectOption | null>();

  const inputRef = useRef<HTMLInputElement>(null);
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "left",
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["right"],
        },
      },
    ],
  });

  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );

  const selectDataSetList = useSelector(
    (state: RootState) => state.selectDataSetReducer.selectDataSetList,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (options.length) {
      setInitialized(true);
    }
    if (options.length && fieldValue) {
      if (mode === "multi") {
        const newOptions = options.filter((item: SelectOption) =>
          fieldValue.includes(item.value),
        );
        setMultiValue(newOptions);
      }
    }
  }, [fieldValue, options]);

  const updateMultiValueIfNeeded = () => {
    if (mode !== "multi") return;
    const newValue = multiValue.map((el: SelectOption) => el.value);
    const currentValue = fieldValue || [];
    if (!_.isEqual(currentValue, newValue)) {
      updateValue(newValue);
    }
  };

  useEffect(() => {
    if (initialized) {
      updateMultiValueIfNeeded();
    }
  }, [multiValue, initialized]);

  useOnClickOutside(dropdownRef, () => {
    closeDropdownHanler();
  });

  const changeEditData = (
    e: MouseEvent<HTMLDivElement>,
    item: SelectOption,
  ) => {
    e.stopPropagation();
    setOptionToEdit(item);
    setShowEditOptionPane(true);
  };

  const handleLabelClick = () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  const rejectMulti = (item: SelectOption) => {
    const newValue = multiValue.filter(
      (el: SelectOption) => el.value !== item.value,
    );
    setMultiValue(newValue);
  };

  const selectMulti = (item: SelectOption) => {
    const newValue = [...multiValue, item];
    setMultiValue(newValue);
  };

  const setNewValue = (item: SelectOption) => {
    if (mode === "multi") {
      if (multiValue.find((el: SelectOption) => el.value === item.value)) {
        rejectMulti(item);
      } else {
        selectMulti(item);
      }
    } else {
      updateValue(item.value);
    }
  };

  const selectNewValue = (item: SelectOption) => {
    setNewValue(item);
    setShowEditOptionPane(false);

    if (mode === "multi") return;
    setSearchValue("");
  };

  const updateSingleSelect = async (
    newName: string,
    newOptions: SelectOption[],
  ) => {
    switch (context) {
      case tableDataType.Dictionary:
        dispatch(updateDictionaryElementSelectMetaField(uuid, newOptions));
        break;
      case tableDataType.CreatorDatabase:
        dispatch(
          updateCreatorDatabaseSelectField(
            uuid,
            newOptions,
            activeWorkspaceUuid,
          ),
        );
        break;
      default:
        console.error("Unknown data type");
        break;
    }
  };

  const createSelectOption = async (e: any = undefined) => {
    e.stopPropagation();
    setShowEditOptionPane(false);
    try {
      const newRank = options?.length ? options[options.length - 1].rank : "a";

      const newOptions = [
        ...options,
        {
          name: searchValue,
          value: uuidv7(),
          color: getUnusedColor(options).color.replace("#", ""),
          backgroundColor: getUnusedColor(options).backgroundColor.replace(
            "#",
            "",
          ),
          rank: generateRankString(newRank, ""),
        },
      ];

      setSearchValue("");
      updateMultiValueIfNeeded();
      await updateSingleSelect("", newOptions);

      setOptions(newOptions);
    } catch (error) {
      showToast(
        "error",
        "Error",
        translateMessage({
          intl,
          id: "ws_could_not_create_option",
          defaultMessage: "Could not create a new option",
        }),
      );
    }
  };

  const handleDragUpdate = (update: DragUpdate) => {
    if (!update.destination) {
      return;
    }

    const queryAttr = "data-rbd-drag-handle-draggable-id";

    const { draggableId } = update;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery) as {
      clientHeight: number;
      clientWidth: number;
      parentNode: any;
    };

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, destinationIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft,
      ),
    });
  };

  const handleOptionClick = (e: Event, item: SelectOption) => {
    if (mode === "edit-global") {
      e.stopPropagation();
      setOptionToEdit(item);
      setShowEditOptionPane(true);
    } else {
      selectNewValue(item);
    }
  };

  const handleDragEnd = async (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }
    const { destination, source } = result;

    const sourceField = options[source.index];
    const targetField = options[destination.index];
    const fieldIndex = options.findIndex(
      (option: SelectOption) => option.value === targetField.value,
    );

    let newRank = "";
    if (destination.index < source.index) {
      newRank = generateRankString(
        options[fieldIndex - 1]?.rank || "",
        options[fieldIndex].rank,
      );
      // move bottom
    } else {
      newRank = generateRankString(
        options[fieldIndex].rank,
        options[fieldIndex + 1]?.rank || "",
      );
    }

    const newOptions = options.map((option: SelectOption) => {
      if (sourceField.value === option.value) {
        return {
          ...option,
          rank: newRank,
        };
      }

      return option;
    });

    setPlaceholderProps(null);

    setOptions(newOptions);
    await updateSingleSelect("", newOptions);
  };

  useEffect(() => {
    if (optionsData) {
      setOptions(optionsData);
    } else {
      setOptions([]);
    }
  }, [optionsData]);

  useEffect(() => {
    if (wsSelectDataSetUuid) {
      if (
        selectDataSetList[`dataSetType_${wsSelectDataSetUuid}`] &&
        options !== selectDataSetList[`dataSetType_${wsSelectDataSetUuid}`]
      ) {
        const newOptions =
          selectDataSetList[`dataSetType_${wsSelectDataSetUuid}`] || [];
        setOptions(newOptions);
      } else {
        setOptions([]);
      }
    }
  }, [wsSelectDataSetUuid]);

  useEffect(() => {
    if (searchValue.length) {
      const searchResults = options.filter((item: { name: string }) =>
        item.name.toUpperCase().includes(searchValue.toUpperCase()),
      );
      setFilteredOptions(searchResults);
    } else {
      setFilteredOptions(options);
    }
  }, [searchValue, options]);

  const SelectedLabels = useCallback(() => {
    return multiValue.length > 1 ? (
      <div className="select__selected-labels">
        {multiValue.map((item: SelectOption) => (
          <SelectLabel
            key={item.value}
            value={item}
            onClickHandler={handleLabelClick}
            emptyFieldPlaceholder={<AddButton variant="rect" />}
            rejectMulti={rejectMulti}
            disableTooltip
          />
        ))}
      </div>
    ) : null;
  }, [multiValue]);

  const renderMenuContent = () => (
    <>
      <SelectedLabels />
      <input
        ref={inputRef}
        className="search-input"
        value={searchValue}
        onClick={(e) => {
          e.stopPropagation();
          setShowEditOptionPane(false);
        }}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            createSelectOption(e);
          }
        }}
        placeholder={
          shouldDisplayAction(variant, "add_new_option")
            ? translateMessage({
                intl,
                id: "ws_create_an_option",
                defaultMessage: "Create an option",
              })
            : translateMessage({
                intl,
                id: "ws_search_for_options",
                defaultMessage: "Search for options",
              })
        }
      />

      <div
        className="drag-n-drop-wrapper"
        onClick={(e) => {
          e.stopPropagation();
          setShowEditOptionPane(false);
        }}
      >
        <DragDropContext
          onDragUpdate={handleDragUpdate}
          onDragEnd={(result) => handleDragEnd(result)}
        >
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={classNames("options-list", {
                  "options-list--dragging-over": snapshot.isDraggingOver,
                })}
                {...provided.droppableProps}
              >
                {filteredOptions
                  .sort((a: any, b: any) => {
                    return a.rank.localeCompare(b.rank);
                  })
                  .map((item: SelectOption, index: number) => {
                    const showTick = multiValue.find(
                      (el: SelectOption) => el.value === item.value,
                    );
                    return (
                      <Draggable
                        key={item.value}
                        draggableId={item.value}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            className={classNames("option", {
                              "option--dragging-over": snapshot.isDragging,
                            })}
                            {...provided.draggableProps}
                            style={
                              mode === "edit-global"
                                ? {
                                    ...provided.draggableProps.style,
                                    top: index * 40 + 129,
                                  }
                                : provided.draggableProps.style
                            }
                          >
                            <DropdownMenuItem
                              key={item.value}
                              toggleGlobalState
                              data-value={item.name}
                              onClick={(e: Event) => handleOptionClick(e, item)}
                            >
                              <div className="dropdown__menu-item-left">
                                <div
                                  className={classNames(
                                    "dropdown__menu-item-left-drag",
                                    {
                                      "dropdown__menu-item-left-drag--disabled":
                                        searchValue.length,
                                      "dropdown__menu-item-left-drag--hidden":
                                        !shouldDisplayAction(
                                          variant,
                                          "drag_and_drop",
                                        ),
                                    },
                                  )}
                                  {...provided.dragHandleProps}
                                >
                                  <GridIcon />
                                </div>
                                <span
                                  className="select__label"
                                  style={{
                                    backgroundColor: `#${item.backgroundColor}`,
                                    color: `#${item.color}`,
                                    transition: `color 0.2s ease, background-color 0.2s ease`,
                                  }}
                                >
                                  {item.name}
                                </span>
                                {showTick && <TickIcon />}
                              </div>
                              {shouldDisplayAction(variant, "edit") && (
                                <div
                                  className="dropdown__menu-item-right"
                                  onClick={(e) => changeEditData(e, item)}
                                >
                                  <EditIcon />
                                </div>
                              )}
                            </DropdownMenuItem>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
                {placeholderProps && snapshot.isDraggingOver && (
                  <AnimatedDiv
                    className="option__placeholder"
                    style={{
                      top: placeholderProps?.clientY,
                      left: placeholderProps?.clientX,
                      height: placeholderProps?.clientHeight,
                      width: placeholderProps?.clientWidth,
                    }}
                  />
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {searchValue && shouldDisplayAction(variant, "add_new_option") && (
        <DropdownMenuItem onClick={(e: MouseEvent) => createSelectOption(e)}>
          <div className="dropdown__menu-item-left">
            <span className="dropdown__menu-item-left-create">
              <IDHFormattedMessage id="ws_create" defaultMessage="Create" />
            </span>
            <span
              className="select__label select__label--to-create"
              style={{
                backgroundColor: getUnusedColor(options).backgroundColor,
                color: getUnusedColor(options).color,
              }}
            >
              {searchValue}
            </span>
          </div>
          <div className="dropdown__menu-item-right" />
        </DropdownMenuItem>
      )}

      {!shouldDisplayAction(variant, "add_new_option") &&
        searchValue &&
        !filteredOptions.length && (
          <DropdownMenuItem className="dropdown__menu-item-no-results">
            <NoResultsMessage />
          </DropdownMenuItem>
        )}
      {showEditOptionPane && (
        <div
          ref={setPopperElement}
          style={styles.popper as React.CSSProperties}
          {...attributes.popper}
        >
          <EditOptionPane
            data={optionToEdit}
            setData={setOptionToEdit}
            options={options}
            isOpen={showEditOptionPane}
            setIsOpen={setShowEditOptionPane}
            setOptions={setOptions}
            fieldName=""
            updateSingleSelect={updateSingleSelect}
          />
        </div>
      )}
    </>
  );

  return (
    <div className="select__wrapper" ref={setReferenceElement}>
      <MenuOverlay
        showEditOptionPane={showEditOptionPane}
        handleClick={() => setShowEditOptionPane(false)}
      >
        {renderMenuContent()}
      </MenuOverlay>
    </div>
  );
}

export default injectIntl(SelectDropdown);
