import React from "react";
import classNames from "classnames";
import "./SectionRowWrapper.scss";

function SectionRowWrapper(props) {
  const { children, className } = props;
  return (
    <div className={classNames("pane-section-row-wrapper", {}, className)}>
      {children}
    </div>
  );
}

export { SectionRowWrapper };
