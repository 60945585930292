import React, { useState } from "react";
import { useSelector } from "react-redux";

import { SettingsModal } from "src/app/SettingsModal/SettingsModal";
import { SettingsContext, SettingsTab } from "src/app/SettingsModal/enums";
import {
  closeSettingsModal,
  openSettingsModal,
} from "src/app/SettingsModal/methods";
import { useHistory } from "react-router";
import { AppLocation } from "src/redux/main/mainReducer";
import { RootState } from "src/redux/reducers";
import { Button } from "../../../components/Button/Button";
import SeeAsClientModeSwitch from "../../../components/SeeAsClientModeSwitch/SeeAsClientModeSwitch";
import { StackedAvatars } from "../../../components/StackedAvatars/StackedAvatars";
import { RolesInfoModal } from "../../../modals/ShareModal/RolesInfoModal/RolesInfoModal";
import { SeeAsClientInfoModal } from "../../../modals/ShareModal/SeeAsClientInfoModal/SeeAsClientInfoModal";
import { ReactComponent as InviteIcon } from "../../../../images/share-plus.svg";

function TopbarRight() {
  const [showSeeAsClientInfoModal, setShowSeeAsClientInfoModal] =
    useState<boolean>(false);
  const [showRulesInfoModal, setShowRolesInfoModal] = useState(false);

  const {
    mainReducer: { settingsModalData },
    projectReducer: { projectBasicData },
  } = useSelector((state: RootState) => state);

  const history = useHistory();

  const openShareModal = () => {
    openSettingsModal(
      history,
      AppLocation.TopbarRight,
      SettingsContext.Project,
      SettingsTab.Members,
    );
  };

  return (
    <div className="projects__topbar-right">
      {projectBasicData?.permissions?.project?.includes("see_as_client_mode") &&
        projectBasicData?.clientMode && <SeeAsClientModeSwitch />}
      {projectBasicData?.clientMode && (
        <div className="projects__right-separator" />
      )}
      <StackedAvatars
        avatars={projectBasicData?.members ?? []}
        maxAvatars={4}
        stackedUsers
        onClick={openShareModal}
      />
      <Button
        variant="white-with-grey-border"
        size="square"
        onClick={openShareModal}
      >
        <InviteIcon />
      </Button>

      {settingsModalData.location === AppLocation.TopbarRight && (
        <SettingsModal
          onClose={() => closeSettingsModal(history)}
          members={{
            openShareProjectModal: openShareModal,
            openSeeAsClientInfoModal: () => setShowSeeAsClientInfoModal(true),
            openRolesInfoModal: () => setShowRolesInfoModal(true),
          }}
          contextTargetUuid={projectBasicData?.projectId}
        />
      )}

      {showSeeAsClientInfoModal && (
        <SeeAsClientInfoModal
          onClose={() => setShowSeeAsClientInfoModal(false)}
          openShareModal={openShareModal}
        />
      )}
      {showRulesInfoModal && (
        <RolesInfoModal
          onClose={() => setShowRolesInfoModal(false)}
          openShareModal={openShareModal}
        />
      )}
    </div>
  );
}

export { TopbarRight };
