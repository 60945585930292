import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootState } from "src/redux/reducers";

import { setActiveWorkspaceUuid } from "src/redux/main/mainActions";
import { isStringUuid } from "src/utils/methods";

function WorkspaceCatcher() {
  const { activeWorkspaceUuid, identity } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const params = useParams<{ workspaceUuid: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      isStringUuid(params.workspaceUuid) &&
      activeWorkspaceUuid !== params.workspaceUuid
    ) {
      dispatch(setActiveWorkspaceUuid(params.workspaceUuid));
    }
  }, [params.workspaceUuid]);

  return null;
}

export default WorkspaceCatcher;
