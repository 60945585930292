import React from "react";
import "./InfluencersListAuthorsDropdown.scss";
import { ReactComponent as FilterIcon } from "src/images/filter-list.svg";

import { Button } from "src/app/components/Button/Button";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";

function InfluencersListAuthorsDropdown(props) {
  const { filters, setFilters, initialFilters } = props;

  const toggleFilterAll = () => {
    if (filters === initialFilters) {
      setFilters([]);
    } else {
      setFilters(initialFilters);
    }
  };

  const toggleFilter = (author) => {
    if (!filters.includes(author)) {
      setFilters((filters) => [...filters, author]);
    } else {
      setFilters(filters.filter((item) => item !== author));
    }
  };

  const equals = (a, b) => {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
      const aCount = a.filter((e) => e === v).length;
      const bCount = b.filter((e) => e === v).length;
      if (aCount !== bCount) return false;
    }
    return true;
  };

  return (
    <Dropdown className="influencers-list-authors-dropdown">
      {(isOpen, setIsOpen) => (
        <>
          <Button
            className="filter-button"
            transparent
            onClick={() => setIsOpen(true)}
          >
            {isOpen || !equals(filters, initialFilters) ? (
              <FilterIcon
                alt="filter-icon"
                className="filter-button-icon filter-button-icon--active"
              />
            ) : (
              <FilterIcon alt="filter-icon" className="filter-button-icon" />
            )}
          </Button>
          {isOpen && (
            <DropdownMenu>
              <DropdownMenuItem>
                <CustomCheckbox
                  id="all"
                  name="all"
                  checked={equals(filters, initialFilters)}
                  label="All"
                  onChange={() => toggleFilterAll()}
                />
              </DropdownMenuItem>
              {initialFilters.map((brandUser) => (
                <DropdownMenuItem>
                  <CustomCheckbox
                    id={brandUser}
                    name={brandUser}
                    checked={filters.includes(brandUser)}
                    label={brandUser}
                    onChange={() => toggleFilter(brandUser)}
                  />
                </DropdownMenuItem>
              ))}
            </DropdownMenu>
          )}
        </>
      )}
    </Dropdown>
  );
}

export default InfluencersListAuthorsDropdown;
