import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";

import { Rectangle } from "@glideapps/glide-data-grid";
import usePopper from "react-overlays/cjs/usePopper";
import { Portal } from "react-overlays";

import { overlaysRef } from "src/App";

interface GlideDataGridDropdownWrapperProps {
  bounds: Rectangle;
  handleClickOverlay?: () => void;
  referenceElement: HTMLElement | null;
}

export const dropdownRef = React.createRef<HTMLDivElement>();

export function GlideDataGridDropdownWrapper({
  children,
  bounds,
  handleClickOverlay,
  referenceElement,
}: PropsWithChildren<GlideDataGridDropdownWrapperProps>) {
  const [popperElement, setPopperElement] = useState<any>(null);

  const innerPopperReferenceElement = useMemo(() => referenceElement, [bounds]);

  const { styles, attributes, state, update } = usePopper(
    innerPopperReferenceElement,
    popperElement,
    {
      placement: "bottom-start",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    },
  );

  useEffect(() => {
    if (state) {
      update();
    }
  }, [state, dropdownRef, update]);

  return (
    <Portal container={overlaysRef}>
      <>
        <div
          className="glide-data-grid__dropdown-overlay"
          onClick={handleClickOverlay}
        />
        <div
          style={styles.popper as React.CSSProperties}
          {...attributes.popper}
          ref={setPopperElement}
        >
          <div className="glide-data-grid__dropdown" ref={dropdownRef}>
            {children}
          </div>
        </div>
      </>
    </Portal>
  );
}
