import React from "react";
import "./Flag.scss";
import classNames from "classnames";

function Flag({ className, countryCode, ...rest }) {
  return (
    <img
      src={`/workspace/flags/${countryCode}.svg`}
      className={classNames("flag", className)}
      role="presentation"
      {...rest}
    />
  );
}

export default Flag;
