import React from "react";

import { ReactComponent as EyesEmoji } from "src/images/eyes.svg";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import "./NoResultsMessage.scss";

function NoResultsMessage() {
  return (
    <div className="no-results-message">
      <EyesEmoji />
      <IDHFormattedMessage
        id="ws_no_results_found"
        defaultMessage="No results found"
      />
    </div>
  );
}

export default NoResultsMessage;
