import React from "react";
import classNames from "classnames";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as DragAndDropIcon } from "src/images/dnd.svg";
import { ReactComponent as RemoveIcon } from "src/images/remove.svg";
import { AttachmentPlacement } from "../../../../types";
import AttachmentSettingsDropdown from "../../../dropdowns/AttachmentSettingsDropdown/AttachmentSettingsDropdown";
import { getAttachmentClickMethod } from "../utils/getAttachmentClickMethod";
import { AttachmentDetails } from "./ImageAttachment";

export function DefaultAttachment({
  data,
  isHovered,
  setIsHovered,
  place,
  getIcon,
  fileExtension,
  taskType,
  handleRemoveAttachment,
  onGalleryAttachmentClick,
}) {
  const downloadUrl =
    data.publicDownloadUrl || data.downloadUrl || data.publicPreviewUrl;

  if (place === AttachmentPlacement.Dropdown) {
    return (
      <a
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        href={downloadUrl}
        onClick={(e) => !downloadUrl && e.preventDefault()}
        download
        className="attachment-wrapper"
      >
        <div className="attachment-file">{getIcon(fileExtension)}</div>
      </a>
    );
  }

  if (place === AttachmentPlacement.AttachmentPreview) {
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="attachment-wrapper attachment-wrapper__preview-default"
      >
        <div className="attachment-file">
          <div>{getIcon(fileExtension)}</div>
          <div className="attachment-file__details">
            <div className="attachment-file__name">{data.name}</div>
            <div className="attachment-file__download-wrapper">
              <div className="attachment-file__type">
                {fileExtension.toUpperCase()}
              </div>
            </div>
          </div>
          <DragAndDropIcon
            className={classNames("attachment-file__dnd-icon dnd-icon--gray", {
              "attachment-file__dnd-icon--visible": isHovered,
            })}
          />
        </div>
        <RemoveIcon
          onClick={handleRemoveAttachment}
          className="attachment-file__remove-icon"
        />
      </div>
    );
  }

  if (place === AttachmentPlacement.ContentProposalSummary) {
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="attachment-wrapper"
        onClick={onGalleryAttachmentClick}
      >
        <div className="attachment-file">
          <div>{getIcon(fileExtension)}</div>
          <div className="attachment-file__details">
            <div className="attachment-file__name">{data.name}</div>
          </div>
        </div>
      </div>
    );
  }

  if (place === AttachmentPlacement.ContentProposal) {
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="attachment-wrapper"
        onClick={(e) =>
          getAttachmentClickMethod(
            e,
            data.name,
            data.publicPreviewUrl || data.publicUrl,
          )
        }
      >
        <div className="attachment-file">
          <div>{getIcon(fileExtension)}</div>
        </div>
        <AttachmentDetails name={data.name} downloadUrl={downloadUrl} />
        {data.parentUuid &&
          isHovered &&
          place === AttachmentPlacement.Attachments && (
            <AttachmentSettingsDropdown
              downloadUrl={data.downloadUrl}
              commentId={data.parentUuid}
              assetId={data.id}
              taskType={taskType}
            />
          )}
      </div>
    );
  }

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={classNames("attachment-wrapper", {
        "attachment-wrapper--in-comment": place === AttachmentPlacement.Comment,
      })}
      onClick={(e) =>
        getAttachmentClickMethod(
          e,
          data.name,
          data.publicPreviewUrl || data.publicUrl,
        )
      }
    >
      <div className="attachment-file">
        <div>{getIcon(fileExtension)}</div>
        <div className="attachment-file__details">
          <div className="attachment-file__name">{data.name}</div>
          <div className="attachment-file__download-wrapper">
            <div className="attachment-file__type">
              {fileExtension.toUpperCase()}
            </div>
            {(data.downloadUrl || data.publicPreviewUrl || data.publicUrl) && (
              <a
                className="attachment-file__download"
                href={downloadUrl}
                download
                onClick={(e) => {
                  e.stopPropagation();
                  !downloadUrl && e.preventDefault();
                }}
              >
                <IDHFormattedMessage
                  id="ws_download"
                  defaultMessage="Download"
                />
              </a>
            )}
          </div>
        </div>
      </div>
      {data.parentUuid &&
        isHovered &&
        place === AttachmentPlacement.Attachments && (
          <AttachmentSettingsDropdown
            downloadUrl={data.downloadUrl}
            commentId={data.parentUuid}
            assetId={data.id}
            taskType={taskType}
          />
        )}
    </div>
  );
}
