import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as KeywordsIcon } from "../../../../../images/profile-data/keywords.svg";
import { CustomTagCloud } from "../components/CustomTagCloud/CustomTagCloud";
import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";

export function Keywords(props) {
  const { keywords, height } = props;

  const onShowcase = height;

  return (
    <ProfileBox className="profile-box--tags">
      <PBHeader
        icon={<KeywordsIcon />}
        title={
          <IDHFormattedMessage id="ws_keywords" defaultMessage="Keywords" />
        }
        onShowcase={onShowcase}
      />
      <CustomTagCloud data={keywords} green />
    </ProfileBox>
  );
}
