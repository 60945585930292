import React, { useRef } from "react";
import "./ProgressField.scss";

import { TooltipedEllipsis } from "src/app/components/TooltipedEllipsis/TooltipedEllipsis";
import { splitNumbers, splitPercents } from "../../../../../utils/methods";
import { MetaFieldOptions } from "../../../../Task/Subtask/Subtask";

interface Props {
  value: string;
  options: MetaFieldOptions;
}

export const ProgressField: React.FC<Props> = (props) => {
  const { value, options } = props;

  const prefixRef = useRef<HTMLSpanElement>(null);

  const { currencyCode } = options;
  const maxValue = options.progressBarOptions?.maxValue || 0;
  const isValueDerivedFromPercentType =
    options.progressBarOptions?.isValueDerivedFromPercentType || false;
  const percentValue = ((Number.parseFloat(value) / maxValue) * 100).toFixed(2);

  const getMaxWidth = () => {
    let width = 80;
    if (isValueDerivedFromPercentType) width -= 10.33;
    if (currencyCode && prefixRef.current) {
      width -= prefixRef.current.clientWidth + 4;
    }
    return width;
  };

  return (
    <div className="progress-field">
      <span className="progress-field__value">
        {!Number(value) || !options.progressBarOptions?.maxValue
          ? 0
          : percentValue}
        %
      </span>
      <div className="progress-field__bar">
        <div
          className="progress-field__bar-progress"
          style={{ width: `${percentValue}%` }}
        />
      </div>
      <div className="progress-field__range">
        {currencyCode && (
          <span className="progress-field__range-prefix" ref={prefixRef}>
            {currencyCode}
          </span>
        )}
        <TooltipedEllipsis
          className="progress-field__range-value"
          maxWidth={getMaxWidth()}
          content={`${
            isValueDerivedFromPercentType
              ? `${splitPercents(value)}%`
              : splitNumbers(value)
          }`}
        />
        <span className="progress-field__range-slash">/</span>
        {currencyCode && (
          <span className="progress-field__range-prefix">{currencyCode}</span>
        )}
        <TooltipedEllipsis
          className="progress-field__range-value"
          maxWidth={getMaxWidth()}
          content={`${
            isValueDerivedFromPercentType
              ? `${splitPercents(maxValue)}%`
              : splitNumbers(maxValue)
          }`}
        />
      </div>
    </div>
  );
};
