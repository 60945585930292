import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import {
  Breadcrumbs,
  BreadcrumbsItem,
} from "src/app/components/Breadcrumbs/Breadcrumbs";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Pane } from "src/app/components/Pane/Pane";
import {
  PaneTopbar,
  PaneTopbarLeft,
  PaneTopbarToolbar,
} from "src/app/components/Pane/PaneTopbar/PaneTopbar";
import { Button } from "src/app/components/Button/Button";
import { ReactComponent as XLSIcon } from "src/images/xls.svg";
import { ReactComponent as OverlapIcon } from "src/images/audience-overlap.svg";
import { downloadUrl } from "src/app/pages/CreatorDiscoveryTool/utils/downloadUrl";
import { showToast } from "src/app/methods/showToast";
import METHODS from "../../helpers/methods";
import CONSTS from "../../helpers/consts";
import { OverlappedUsersTable } from "./OverlappedUsersTable/OverlappedUsersTable";
import Loader from "../../components/Loader/Loader";
import DottedBox from "../../components/DottedBox/DottedBox";
import { Doughnut } from "../../components/Doughnut/Doughnut";

import "./AudienceOverlapPane.scss";

function AudienceOverlapPane({ onClose, influencersInList, listId, listName }) {
  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state) => state);

  const [loading, setLoading] = useState(true);
  const [overlapData, setOverlapData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [attempts, setAttempts] = useState(5);

  const getAudienceOverlap = async () => {
    try {
      const {
        data: { content: overlapDataResponse },
      } = await axios.get(
        CONSTS.getAudienceOverlap.replace(":creators_list_id:", listId),
      );
      setOverlapData(overlapDataResponse);

      if (overlapDataResponse?.reportInfo) {
        setChartData([
          {
            name: "front_audience_overlap_total_overlapping_followers",
            amount:
              overlapDataResponse.reportInfo.totalFollowers -
              overlapDataResponse.reportInfo.totalUniqueFollowers,
            percent:
              (overlapDataResponse.reportInfo.totalFollowers -
                overlapDataResponse.reportInfo.totalUniqueFollowers) /
              overlapDataResponse.reportInfo.totalFollowers,
          },
          {
            name: "front_audience_overlap_total_unique_followers",
            amount: overlapDataResponse.reportInfo.totalUniqueFollowers,
            percent:
              overlapDataResponse.reportInfo.totalUniqueFollowers /
              overlapDataResponse.reportInfo.totalFollowers,
          },
        ]);
      }
      setLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (
          error.response?.data?.content?.errors?.includes(
            "calculating_audience_overlap_in_progress",
          )
        ) {
          setTimeout(() => {
            setAttempts((prev) => prev - 1);
          }, 10000);
        } else {
          error.response?.data?.content?.errors?.forEach((errorMessage) => {
            showToast(
              "error",
              <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
              errorMessage,
            );
          });
        }
      } else {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_something_went_wrong"
            defaultMessage="Something went wrong!"
          />,
        );
      }
    }
  };

  const downloadXls = () => {
    const url = CONSTS.downloadAudienceOverlapXls.replace(
      ":creators_list_id:",
      listId,
    );
    downloadUrl(url, "overlapData.xlsx");
  };

  useEffect(() => {
    if (attempts > 0) getAudienceOverlap();
  }, [attempts]);

  return (
    <Pane showPane hidePane={onClose} width={798}>
      <PaneTopbar>
        <PaneTopbarLeft>
          <Breadcrumbs>
            <BreadcrumbsItem
              text={
                <IDHFormattedMessage
                  id="front_breadcrumbs_creator_lists"
                  defaultMessage="Creator lists"
                />
              }
              to={`/workspace/${activeWorkspaceUuid}/creator-discovery-tool/lists`}
            />
            <BreadcrumbsItem text={listName} />
          </Breadcrumbs>
        </PaneTopbarLeft>
        <PaneTopbarToolbar hidePane={onClose} />
      </PaneTopbar>
      <div className="pane__container audience-overlap-pane">
        {loading ? (
          <Loader
            text={
              <IDHFormattedMessage
                id="front_audience_overlap_please_wait_message"
                defaultMessage="It may take a while..."
              />
            }
          />
        ) : (
          <>
            <div className="audience-overlap-pane__title-bar">
              <h2 className="audience-overlap-pane__title">
                <IDHFormattedMessage
                  id="front_audience_overlap_title"
                  defaultMessage="Audience overlap"
                />
              </h2>
              {overlapData && overlapData?.reportInfo && (
                <Button
                  size="medium"
                  variant="white"
                  className="audience-results-page__overlap-btn"
                  onClick={downloadXls}
                >
                  <XLSIcon className="icon-left" />
                  <IDHFormattedMessage
                    id="front_download_xls"
                    defaultMessage="Download .xls"
                  />
                </Button>
              )}
            </div>
            <p className="audience-overlap-pane__paragraph">
              <IDHFormattedMessage
                id="front_audience_overlap_pane_paragraph"
                defaultMessage="Percentage of overlapping and unique followers, both number and
            percentage-wise across all followers of Creators on Selected Creators
            list."
              />
            </p>
            <div className="audience-overlap-pane__row">
              <div className="audience-overlap-pane__column audience-overlap-pane__column--boxes">
                {overlapData && overlapData?.reportInfo && (
                  <>
                    <DottedBox className="audience-overlap-pane__box">
                      <div className="audience-overlap-pane__row">
                        <div className="audience-overlap-pane__box-icon">
                          <OverlapIcon
                            height={24}
                            width={24}
                            className="audience-overlap-pane__box-icon__overlapping"
                          />
                        </div>
                        <div className="audience-overlap-pane__box-content">
                          <div className="audience-overlap-pane__box-title">
                            <IDHFormattedMessage
                              id="front_audience_overlap_total_overlapping_followers"
                              defaultMessage="Total overlapping Followers"
                            />
                          </div>
                          <div className="audience-overlap-pane__box-amount">
                            <div className="audience-overlap-pane__box-amount__number audience-overlap-pane__box-amount__number">
                              {METHODS.splitNumbers(chartData[0].amount)}
                            </div>
                            <div className="audience-overlap-pane__box-amount__percent audience-overlap-pane__box-amount__percent--overlapping">
                              {METHODS.splitPercents(
                                chartData[0].percent,
                                " ",
                                1,
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </DottedBox>
                    <DottedBox className="audience-overlap-pane__box">
                      <div className="audience-overlap-pane__row">
                        <div className="audience-overlap-pane__box-icon">
                          <OverlapIcon
                            height={24}
                            width={24}
                            className="audience-overlap-pane__box-icon__unique"
                          />
                        </div>
                        <div className="audience-overlap-pane__box-content">
                          <div className="audience-overlap-pane__box-title">
                            <IDHFormattedMessage
                              id="front_audience_overlap_total_unique_followers"
                              defaultMessage="Total unique Followers"
                            />
                          </div>
                          <div className="audience-overlap-pane__box-amount">
                            <div className="audience-overlap-pane__box-amount__number">
                              {METHODS.splitNumbers(chartData[1].amount)}
                            </div>
                            <div className="audience-overlap-pane__box-amount__percent audience-overlap-pane__box-amount__percent--unique">
                              {METHODS.splitPercents(
                                chartData[1].percent,
                                " ",
                                1,
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </DottedBox>
                  </>
                )}
              </div>
              <div className="audience-overlap-pane__column audience-overlap-pane__column--chart">
                {overlapData && overlapData?.reportInfo && (
                  <Doughnut
                    data={chartData}
                    width={252}
                    height={252}
                    dataKey="amount"
                    size={90}
                    colors={["#1267FB", "#5EC7EB"]}
                  />
                )}
              </div>
            </div>
            <OverlappedUsersTable
              influencersInList={influencersInList}
              overlapData={overlapData}
            />
          </>
        )}
      </div>
    </Pane>
  );
}

export { AudienceOverlapPane };
