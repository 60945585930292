import React from "react";
import "./Loader.scss";
import Lottie from "lottie-react";
import classNames from "classnames";

function Loader({ grey, text }) {
  const animationData = {
    v: "5.5.8",
    fr: 60,
    ip: 0,
    op: 90,
    w: 170,
    h: 130,
    nm: "loader3",
    ddd: 0,
    assets: [],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: "Shape Layer 2",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 20,
                s: [0],
              },
              { t: 89, s: [180] },
            ],
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.145, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 0,
                s: [34, 65, 0],
                to: [0, -2.917, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.588 },
                o: { x: 0.732, y: 0 },
                t: 20,
                s: [34, 47.5, 0],
                to: [0, 0, 0],
                ti: [-55.765, 0.067, 0],
              },
              {
                i: { x: 0.096, y: 1 },
                o: { x: 0.167, y: 0.414 },
                t: 45,
                s: [90.504, 121.958, 0],
                to: [42.185, -0.051, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.984, y: 0 },
                t: 70,
                s: [139, 40, 0],
                to: [0, 0, 0],
                ti: [0, -0.833, 0],
              },
              { t: 90, s: [139, 65, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: "rc",
            d: 1,
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667], y: [1, 1] },
                  o: { x: [0.333, 0.333], y: [0, 0] },
                  t: 20,
                  s: [20, 20],
                },
                {
                  i: { x: [0.667, 0.667], y: [1, 1] },
                  o: { x: [0.333, 0.333], y: [0, 0] },
                  t: 45,
                  s: [15, 15],
                },
                { t: 70, s: [20, 20] },
              ],
              ix: 2,
            },
            p: { a: 0, k: [0, 0], ix: 3 },
            r: { a: 0, k: 3, ix: 4 },
            nm: "Rectangle Path 1",
            mn: "ADBE Vector Shape - Rect",
            hd: false,
          },
          {
            ty: "fl",
            c: {
              a: 0,
              k: [0.125490196078, 0.125490196078, 0.133333333333, 1],
              ix: 4,
              x: "var $bm_rt;\n$bm_rt = comp('CHANGE COLORS').layer('CHANGE COLOR').content('CHANGE COLOR HERE').color;",
            },
            o: { a: 0, k: 100, ix: 5 },
            r: 1,
            bm: 0,
            nm: "Fill 1",
            mn: "ADBE Vector Graphic - Fill",
            hd: false,
          },
        ],
        ip: 0,
        op: 90,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: "Shape Layer 4",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.408, y: 1 },
                o: { x: 0.518, y: 0 },
                t: 35,
                s: [139, 65, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.396, y: 1 },
                o: { x: 0.461, y: 0 },
                t: 59,
                s: [94, 65, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              { t: 83, s: [104, 65, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: "rc",
            d: 1,
            s: { a: 0, k: [20, 20], ix: 2 },
            p: { a: 0, k: [0, 0], ix: 3 },
            r: { a: 0, k: 3, ix: 4 },
            nm: "Rectangle Path 1",
            mn: "ADBE Vector Shape - Rect",
            hd: false,
          },
          {
            ty: "fl",
            c: {
              a: 0,
              k: [0.125490196078, 0.125490196078, 0.133333333333, 1],
              ix: 4,
              x: "var $bm_rt;\n$bm_rt = comp('CHANGE COLORS').layer('CHANGE COLOR').content('CHANGE COLOR HERE').color;",
            },
            o: { a: 0, k: 100, ix: 5 },
            r: 1,
            bm: 0,
            nm: "Fill 1",
            mn: "ADBE Vector Graphic - Fill",
            hd: false,
          },
        ],
        ip: 0,
        op: 90,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: "Shape Layer 3",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.408, y: 1 },
                o: { x: 0.518, y: 0 },
                t: 32,
                s: [104, 65, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.396, y: 1 },
                o: { x: 0.461, y: 0 },
                t: 56,
                s: [59, 65, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              { t: 80, s: [69, 65, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: "rc",
            d: 1,
            s: { a: 0, k: [20, 20], ix: 2 },
            p: { a: 0, k: [0, 0], ix: 3 },
            r: { a: 0, k: 3, ix: 4 },
            nm: "Rectangle Path 1",
            mn: "ADBE Vector Shape - Rect",
            hd: false,
          },
          {
            ty: "fl",
            c: {
              a: 0,
              k: [0.125490196078, 0.125490196078, 0.133333333333, 1],
              ix: 4,
              x: "var $bm_rt;\n$bm_rt = comp('CHANGE COLORS').layer('CHANGE COLOR').content('CHANGE COLOR HERE').color;",
            },
            o: { a: 0, k: 100, ix: 5 },
            r: 1,
            bm: 0,
            nm: "Fill 1",
            mn: "ADBE Vector Graphic - Fill",
            hd: false,
          },
        ],
        ip: 0,
        op: 90,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: "Shape Layer 1",
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 30,
                s: [0],
              },
              { t: 54, s: [-90] },
            ],
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.408, y: 1 },
                o: { x: 0.518, y: 0 },
                t: 30,
                s: [69, 65, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.396, y: 1 },
                o: { x: 0.461, y: 0 },
                t: 54,
                s: [24, 65, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              { t: 78, s: [34, 65, 0] },
            ],
            ix: 2,
          },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: "rc",
            d: 1,
            s: { a: 0, k: [20, 20], ix: 2 },
            p: { a: 0, k: [0, 0], ix: 3 },
            r: { a: 0, k: 3, ix: 4 },
            nm: "Rectangle Path 1",
            mn: "ADBE Vector Shape - Rect",
            hd: false,
          },
          {
            ty: "fl",
            c: {
              a: 0,
              k: [0.125490196078, 0.125490196078, 0.133333333333, 1],
              ix: 4,
              x: "var $bm_rt;\n$bm_rt = comp('CHANGE COLORS').layer('CHANGE COLOR').content('CHANGE COLOR HERE').color;",
            },
            o: { a: 0, k: 100, ix: 5 },
            r: 1,
            bm: 0,
            nm: "Fill 1",
            mn: "ADBE Vector Graphic - Fill",
            hd: false,
          },
        ],
        ip: 0,
        op: 90,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  };

  return (
    <div className="loader-col">
      {text && <div className="loader-text">{text}</div>}
      <div
        className={classNames("loader-wrapper", {
          "loader-wrapper--grey": grey,
        })}
      >
        <Lottie animationData={animationData} />
      </div>
    </div>
  );
}
const MemoizedLoader = React.memo(Loader);
export default MemoizedLoader;
