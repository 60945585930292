import React, { useEffect, useState } from "react";
import "./AddCouponsModal.scss";

import { useIntl } from "react-intl";

import Modal, { ModalTitle } from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import { CustomTokenInput } from "src/app/components/CustomTokenInput/CustomTokenInput";
import { translateMessage } from "src/app/methods/translateMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  getCouponGroupDetails,
  setShouldCloseModal,
} from "src/redux/coupon/couponActions";
import { uuidv7 as uuid } from "uuidv7";
import axios from "axios";
import { RootState } from "src/redux/reducers";
import { showToast } from "../../../methods/showToast";
import { API_URLS } from "../../../../utils/API_URLS";

interface Props {
  onClose: () => void;
}

export const AddCouponsModal: React.FC<Props> = (props) => {
  const { onClose } = props;

  const [codes, setCodes] = useState([]);

  const {
    couponReducer: { couponGroupDetails, shouldCloseModal },
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleConfirm = () => {
    if (!couponGroupDetails.uuid) return;

    const couponsData = codes.map((code) => ({
      campaign: null,
      code,
      task: null,
      uuid: uuid(),
      isAssigned: false,
      hasAccess: true,
    }));

    axios
      .post(API_URLS.createCoupon, {
        wsWorkspaceUuid: activeWorkspaceUuid,
        couponsData,
        wsCouponGroupUuid: couponGroupDetails.uuid,
      })
      .then((response) => {
        const {
          data: { content },
        } = response;
        if (content?.duplicateCoupons) {
          showToast(
            "warning",
            <IDHFormattedMessage id="ws_warning" defaultMessage="Warning" />,
            <IDHFormattedMessage
              id="ws_coupon_duplicates"
              defaultMessage="Coupon duplicates"
            />,
          );
        }

        if (content?.addedCoupons?.length > 0) {
          showToast(
            "success",
            <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
            <IDHFormattedMessage
              id="ws_coupon_added"
              defaultMessage="Coupon added"
            />,
          );
        }

        dispatch(getCouponGroupDetails(couponGroupDetails.uuid));
        dispatch(setShouldCloseModal(true));
      })
      .catch((error) => {
        const errorMessages = error?.response?.data?.errors;

        if (errorMessages?.length > 0) {
          for (let i = 0; i < errorMessages.length; i += 1) {
            showToast(
              "error",
              <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
              <IDHFormattedMessage
                id={errorMessages[i].message}
                defaultMessage={
                  errorMessages[i].message ?? "Something went wrong"
                }
              />,
            );
          }
        } else {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id="ws_something_went_wrong"
              defaultMessage="Something went wrong"
            />,
          );
        }
      });
  };

  useEffect(() => {
    if (shouldCloseModal) {
      dispatch(setShouldCloseModal(false));
      onClose();
    }
  }, [shouldCloseModal]);

  return (
    <Modal
      className="add-coupons-modal"
      onClose={onClose}
      onConfirmClick={handleConfirm}
      confirmButtonText={
        <IDHFormattedMessage id="ws_create" defaultMessage="Create" />
      }
      displayCancelButton
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_add_promo_codes"
          defaultMessage="Add Promo Codes"
        />
      </ModalTitle>
      <div className="add-coupons-modal__input-wrapper">
        <CustomInputLabel htmlFor="codes">
          <IDHFormattedMessage
            id="ws_promo_codes"
            defaultMessage="Promo Codes"
          />
        </CustomInputLabel>
        <CustomTokenInput
          value={codes}
          onChange={(newValue: any) => setCodes(newValue)}
          placeholder={
            !codes.length
              ? translateMessage({
                  intl,
                  id: "ws_enter_promo_codes_separated_by_spaces",
                  defaultMessage: "Enter promo codes separated by spaces",
                })
              : ""
          }
          separators={[" ", "\n"]}
        />
      </div>
    </Modal>
  );
};
