import { CSSProperties } from "react";

const getCommonPinningStyles = (
  column: any,
  draggable?: boolean,
): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === "left" && column.getIsLastColumn("left");
  const isFirstRightPinnedColumn =
    isPinned === "right" && column.getIsFirstColumn("right");

  return {
    boxShadow: isLastLeftPinnedColumn ? "inset -1px 0 0 0 #e0e2ea" : undefined,
    borderLeft: isFirstRightPinnedColumn ? "1px dashed #d3d5e1" : undefined,
    ...(!draggable
      ? {
          left:
            isPinned === "left" ? `${column.getStart("left")}px` : undefined,
          right:
            isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
          width: column.getSize() || "auto",
        }
      : {}),
    position: isPinned ? "sticky" : "relative",
    zIndex: isPinned ? 1 : 0,
  };
};

export default getCommonPinningStyles;
