import React from "react";

import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import OuterLink from "../components/OuterLink/OuterLink";
import { setShowTask } from "../../redux";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";

import "./SidebarItem.scss";
import { MetaFieldType } from "../methods/getMetaFieldTypeOptions";

export interface DictionarySidebarElement {
  type: string;
  wsDictionaryElementName: MetaFieldType;
  wsDictionaryIcon: string;
  wsDictionaryName: string;
  wsDictionaryUrl: string;
  wsDictionaryUuid: string;
}

interface Props {
  to?: string;
  messageId?: string;
  defaultMessage: string;
  overrideHtml?: React.ReactNode;
  outerLink?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

const SidebarItem: React.FC<Props> = ({
  to,
  messageId,
  defaultMessage,
  outerLink,
  children,
  onClick,
  overrideHtml,
}) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  if (overrideHtml) {
    return <>{overrideHtml}</>;
  }

  return (
    <li
      className={classNames("sidebar__item", {
        "sidebar__item--active": to && pathname.includes(to),
      })}
      onClick={() => dispatch(setShowTask(false))}
    >
      {outerLink && to ? (
        <OuterLink href={to}>
          {children}
          <span className="sidebar__item-text">
            {messageId ? (
              <IDHFormattedMessage
                id={messageId}
                defaultMessage={defaultMessage}
              />
            ) : (
              defaultMessage
            )}
          </span>
        </OuterLink>
      ) : to ? (
        <Link to={to}>
          {children}
          <span className="sidebar__item-text">
            {messageId ? (
              <IDHFormattedMessage
                id={messageId}
                defaultMessage={defaultMessage}
              />
            ) : (
              defaultMessage
            )}
          </span>
        </Link>
      ) : (
        <span className="sidebar__item__span" onClick={onClick}>
          {children}
          <span className="sidebar__item-text">
            {messageId ? (
              <IDHFormattedMessage
                id={messageId}
                defaultMessage={defaultMessage}
              />
            ) : (
              defaultMessage
            )}
          </span>
        </span>
      )}
    </li>
  );
};

export default SidebarItem;
