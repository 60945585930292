import React from "react";
import classNames from "classnames";

import Tooltip from "src/app/components/Tooltip/Tooltip";
import { injectIntl } from "react-intl";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { EditAutomationButton } from "./EditAutomationButton";

function PublicationSynchronizationList(props) {
  const {
    publicationSynchronizationList,
    projectBasicData,
    handlePublicationSynchronization,
    automationListLoading,
  } = props;

  const tooltipContent = (
    <IDHFormattedMessage
      id="ws_tooltip_automatically_refresh_publications_data"
      defaultMessage="Automatically refresh publications data."
    />
  );

  return !automationListLoading && publicationSynchronizationList.length > 0 ? (
    publicationSynchronizationList.map((automation) => (
      <div className="automations__list-element" key={automation.uuid}>
        <span className="automations__list-element-text">
          <span
            className={classNames("automations__list-element-status", {
              "automations__list-element-status--active": automation.enabled,
            })}
          />
          <IDHFormattedMessage
            id="ws_publications_synchronization"
            defaultMessage="Publications synchronization"
          />
          <Tooltip content={tooltipContent} />
        </span>
        <EditAutomationButton
          automation={automation}
          handlePublication={handlePublicationSynchronization}
          disabled={
            !projectBasicData?.permissions?.project?.includes(
              "manage_project_configuration",
            )
          }
        />
      </div>
    ))
  ) : (
    <div className="automations__list-element">
      <span className="automations__list-element-text">
        <span className="automations__list-element-status" />
        <IDHFormattedMessage
          id="ws_publications_synchronization"
          defaultMessage="Publications synchronization"
        />
        <Tooltip content={tooltipContent} />
      </span>
      <EditAutomationButton
        handlePublication={handlePublicationSynchronization}
        disabled={
          !projectBasicData?.permissions?.project?.includes(
            "manage_project_configuration",
          )
        }
      />
    </div>
  );
}

export default injectIntl(PublicationSynchronizationList);
