import { API_URLS } from "src/utils/API_URLS";
import {
  CREATE_COUPON,
  CREATE_COUPON_GROUP,
  DELETE_COUPON,
  GET_COUPONS_LIST,
  GET_COUPON_GROUPS,
  GET_COUPON_GROUP_DETAILS,
  RENAME_COUPON,
  SET_LOADING,
  SET_SHOULD_CLOSE_MODAL,
  UPDATE_COUPON_GROUP,
  GET_COUPON_REDEMPTION_LOG_STRATEGY,
} from "./couponTypes";

export function getCouponGroups(workspaceUuid: string, disableLoading = false) {
  const url = API_URLS.getCouponGroups.replace(
    ":workspaceUuid:",
    workspaceUuid,
  );

  return {
    type: GET_COUPON_GROUPS,
    payload: {
      request: { url },
      disableLoading,
    },
  };
}

export function getCouponGroupDetails(couponGroupUuid: string) {
  const url = API_URLS.getCouponGroupDetails.replace(
    ":couponGroupUuid:",
    couponGroupUuid,
  );

  return {
    type: GET_COUPON_GROUP_DETAILS,
    payload: {
      request: { url },
    },
  };
}

export function getCouponsList(wsWorkspaceUuid: string) {
  const url = API_URLS.getCouponList.replace(
    ":workspaceUuid:",
    wsWorkspaceUuid,
  );

  return {
    type: GET_COUPONS_LIST,
    payload: {
      request: { url },
    },
  };
}

export function createCoupon(
  wsWorkspaceUuid: string,
  couponsData: any,
  wsCouponGroupUuid: string,
) {
  return {
    type: CREATE_COUPON,
    payload: {
      request: {
        method: "post",
        url: API_URLS.createCoupon,
        data: { wsWorkspaceUuid, couponsData, wsCouponGroupUuid },
      },
      couponsData,
    },
  };
}

export function createCouponGroup(
  name: string,
  coupons: any,
  wsWorkspaceUuid: string,
) {
  return {
    type: CREATE_COUPON_GROUP,
    payload: {
      request: {
        method: "post",
        url: API_URLS.createCouponGroup,
        data: { name, coupons, wsWorkspaceUuid },
      },
      name,
      coupons,
    },
  };
}

export function updateCouponGroup(
  name: string,
  coupons: any,
  wsCouponGroupUuid: string,
  wsWorkspaceUuid: string,
) {
  return {
    type: UPDATE_COUPON_GROUP,
    payload: {
      request: {
        method: "post",
        url: API_URLS.updateCouponGroup,
        data: { name, coupons, wsCouponGroupUuid, wsWorkspaceUuid },
      },
      name,
      coupons,
    },
  };
}

export function renameCoupon(
  code: string,
  uuid: string,
  wsCouponGroupUuid: string,
) {
  const url = API_URLS.renameCoupon.replace(":couponUuid:", uuid);
  return {
    type: RENAME_COUPON,
    payload: {
      request: {
        method: "put",
        url,
        data: { code, wsCouponGroupUuid },
      },
      code,
      uuid,
    },
  };
}

export function deleteCoupon(couponUuid: string, wsCouponGroupUuid: string) {
  const url = API_URLS.deleteCoupon.replace(":couponUuid:", couponUuid);

  return {
    type: DELETE_COUPON,
    payload: {
      request: {
        method: "delete",
        url,
        data: {
          wsCouponGroupUuid,
        },
      },
      couponUuid,
    },
  };
}

export function getCouponDataImportStrategies(wsWorkspaceUuid: string) {
  const url = API_URLS.getCouponDataImportStrategies.replace(
    ":wsWorkspaceUuid:",
    wsWorkspaceUuid,
  );
  return {
    type: GET_COUPON_REDEMPTION_LOG_STRATEGY,
    payload: {
      request: {
        method: "get",
        url,
      },
    },
  };
}

export function setShouldCloseModal(shouldCloseModal: boolean) {
  return {
    type: SET_SHOULD_CLOSE_MODAL,
    payload: { shouldCloseModal },
  };
}

export function setCouponsLoading(loading: boolean) {
  return {
    type: SET_LOADING,
    payload: { loading },
  };
}
