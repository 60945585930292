import React from "react";
import { correctAgeLabel } from "../../../../../../utils/methods";
import Flag from "../../../../../components/Flag/Flag";
import ProgressBar from "../../../../../components/ProgressBar/ProgressBar";

export function ProgressBarListItem(props) {
  const { item, barColor, backgroundColor, icon, flag, language, city } = props;

  return (
    <div className="progress-bar-data__item">
      <div className="progress-bar-data__label">
        {item.age ? (
          correctAgeLabel(item.age)
        ) : (
          <>
            {icon && <div className="progress-bar-data__icon">{icon}</div>}
            {flag && (
              <div className="progress-bar-data__country">
                <Flag countryCode={item.country} />
                {item.country}
              </div>
            )}
            {item.name}
            {language && item.languageName}
          </>
        )}
      </div>

      <div className="progress-bar-data__bar">
        <ProgressBar
          percent={Math.round(item.value)}
          barColor={barColor}
          backgroundColor={backgroundColor}
        />
      </div>
      <div className="progress-bar-data__value">{Math.round(item.value)}%</div>
    </div>
  );
}
