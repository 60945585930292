import React, { Ref, PropsWithChildren, forwardRef } from "react";
import classNames from "classnames";

import { ReactComponent as PlusIcon } from "src/images/plus-transparent.svg";

interface EmptyCellProps {
  isScrolling: boolean;
  handleEmptyField?: () => void;
  className?: string;
}

function EmptyCell(
  {
    isScrolling,
    handleEmptyField,
    children,
    className,
  }: PropsWithChildren<EmptyCellProps>,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <div
      className={classNames(
        "glide-data-grid__hovered-item-empty-field",
        {
          "glide-data-grid__hovered-item--non-clickable": isScrolling,
        },
        className,
      )}
      onClick={handleEmptyField}
      ref={ref}
    >
      <PlusIcon />
      {children}
    </div>
  );
}

export default forwardRef<HTMLDivElement, PropsWithChildren<EmptyCellProps>>(
  EmptyCell,
);
