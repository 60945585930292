import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ProjectsTable.scss";

import { useTable } from "react-table";
import { useSticky } from "react-table-sticky";

import AddCampaignRow from "src/app/components/Table/components/AddCampaignRow";
import NoResultsScreen from "src/app/components/NoResultsScreen/NoResultsScreen";
import noFilteredResultsImg from "src/images/empty-folder-group.svg";
import {
  ProjectsDataTableCell,
  ProjectsDataTableHeaderCell,
  ProjectsDataTableRow,
} from "./tableComponents";
import ProjectsListElementLoader from "./tableComponents/ProjectsListElementLoader/ProjectsListElementLoader";
import IDHFormattedMessage from "../../../../components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "../../../../components/Button/Button";
import { setProjectFilters } from "../../../../../redux/projectFilters/projectFiltersActions";

export default function ProjectsTable({
  data,
  columns,
  filters,
  listLoading,
  setShowCreateProjectModal,
  filtersRefreshing,
}) {
  const dispatch = useDispatch();
  const identity = useSelector((state) => state.mainReducer.identity);

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSticky,
  );

  const renderTable = () => (
    <table className="projects-data-table" {...getTableProps()}>
      <tbody>
        {headerGroups.map((headerGroup) => (
          <ProjectsDataTableRow {...headerGroup.getHeaderGroupProps()} isHeader>
            {headerGroup.headers.map((column) => (
              <ProjectsDataTableHeaderCell column={column} />
            ))}
          </ProjectsDataTableRow>
        ))}

        {filtersRefreshing ? (
          <ProjectsListElementLoader />
        ) : (
          rows.length > 0 &&
          rows.map((row, i) => {
            prepareRow(row);
            return (
              <ProjectsDataTableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <ProjectsDataTableCell cell={cell} />
                ))}
              </ProjectsDataTableRow>
            );
          })
        )}
      </tbody>
    </table>
  );

  const renderAddCampaignRow = () => (
    <AddCampaignRow
      addTableElement={() => setShowCreateProjectModal(true)}
      taskAvatarShapeType={1}
      taskType="project"
      dataType="project"
    />
  );

  const renderNoResultsScreen = () => (
    <div className="no-results-screen-wrapper ">
      <NoResultsScreen
        title={
          <IDHFormattedMessage
            id="ws_no_results_found"
            defaultMessage="No results found"
          />
        }
        subtitle={
          <IDHFormattedMessage
            id="ws_remove_filters"
            defaultMessage="No results match the filter criteria. Remove filter or clear all filters to show results."
          />
        }
        imgUrl={noFilteredResultsImg}
        bottomContent={
          <Button
            size="large"
            variant="blue"
            onClick={() => dispatch(setProjectFilters([]))}
          >
            <IDHFormattedMessage
              id="ws_clear_filters"
              defaultMessage="Clear filters"
            />
          </Button>
        }
      />
    </div>
  );

  return (
    <>
      {(rows.length !== 0 ||
        (rows.length === 0 && Object.keys(filters).length === 0)) &&
        renderTable()}

      {rows.length === 0 &&
        identity.permissions?.workspace.includes("create_projects") &&
        !listLoading &&
        Object.keys(filters).length === 0 &&
        renderAddCampaignRow()}

      {rows.length === 0 &&
        identity.permissions?.workspace.includes("create_projects") &&
        !listLoading &&
        Object.keys(filters).length !== 0 &&
        renderNoResultsScreen()}
    </>
  );
}
