import { Dispatch } from "redux";
import {
  getAvailableProjectMetaFields,
  getAvailableWorkspaceGlobalTaskMetaFields,
} from "src/redux";
import { ExtensionTypeEnum } from "src/types";

export const extensionDetailsInitialState = {
  uuid: "",
  wsProjectUuid: "",
  wsWorkspaceUuid: null,
  type: "",
  settings: { token: "", wsTaskMetaFieldUuid: "" },
  enabled: false,
  recruitmentFormUuid: "",
  recruitmentFormMetaFields: [],
  recruitmentFormMembersWithNotification: [],
  recruitmentFormMetaFieldsAvailable: [],
  recruitmentFormCaption: "",
  recruitmentFormDescription: "",
  recruitmentIframeHtml: "",
  recruitmentFormUrl: "",
  wsAccessLinkCover: null,
};

export const initialUserData = {
  recruitmentFormMetaFields: {
    metaFields: [],
    membersWithNotification: [],
    caption: "",
    description: "",
  },
  wsAccessLinkCover: null,
  recruitmentFormUuid: null,
  wsExtensionType: null,
};

export const getAvailableMetaFields = ({
  dispatch,
  contextUuid,
  extensionType,
}: {
  dispatch: Dispatch<any>;
  contextUuid: string;
  extensionType: ExtensionTypeEnum;
}) => {
  if (extensionType === ExtensionTypeEnum.RecruitmentForm) {
    dispatch(getAvailableProjectMetaFields(contextUuid));
  }

  if (extensionType === ExtensionTypeEnum.CreatorDatabaseSubmissionForm) {
    dispatch(getAvailableWorkspaceGlobalTaskMetaFields(contextUuid));
  }
};
