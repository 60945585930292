import React from "react";
import classNames from "classnames";
import "./UserInterestList.scss";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tooltip from "src/app/components/Tooltip/Tooltip";
import { ReactComponent as DotsIcon } from "src/images/settings-horizontal.svg";

function UserInterestList({ interestList, selectedInterest, visibleLimit }) {
  let list = interestList ? [...interestList] : [];
  if (visibleLimit) {
    list = list?.slice(0, visibleLimit);
  }

  function List({ list }) {
    return (
      <div className="interest-content">
        {list?.map((interest, index) => (
          <div
            key={`interest-${index}`}
            className={classNames("interest", {
              "interest--selected":
                selectedInterest?.indexOf(interest.value) > -1,
            })}
          >
            <IDHFormattedMessage
              id={interest.name}
              defaultMessage={interest.value}
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="interest-wrapper">
      <List list={list} />

      {visibleLimit >= 0 && interestList?.length > visibleLimit && (
        <Tooltip
          up
          clickable
          autoSize
          content={<List list={interestList} />}
          contentClassName="interest-wrapper__tooltip-content"
        >
          <button className="interest-wrapper__button">
            <DotsIcon />
          </button>
        </Tooltip>
      )}
    </div>
  );
}

export default UserInterestList;
