import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SummaryBoxListItem } from "../SummaryBoxListItem/SummaryBoxListItem";

function SummaryBoxList(props) {
  const { foundFields } = props;
  const allFieldsAreEmpty =
    foundFields.filter((el) => el.value === null).length === foundFields.length;

  return (
    <>
      {foundFields.length === 0 || allFieldsAreEmpty ? (
        <div className="project-summary-boxes-empty">
          <IDHFormattedMessage
            id="ws_nothing_to_display_here"
            defaultMessage="Nothing to display here"
          />
        </div>
      ) : (
        <div className="project-summary-boxes">
          {foundFields.map(
            (el, i) =>
              el.value && <SummaryBoxListItem el={el} key={el.taskType + i} />,
          )}
        </div>
      )}
    </>
  );
}

export { SummaryBoxList };
