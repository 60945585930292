import React, { SetStateAction, Dispatch } from "react";
import { uuidv7 as uuid } from "uuidv7";

import { showToast } from "src/app/methods/showToast";
import { MAX_SIZE_SINGLE_ATTACHMENT } from "src/utils/uploadFunctionsUtils";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { uploadFilesToS3 } from "src/utils/uploadFunctions";
import { API_URLS } from "src/utils/API_URLS";
import { AxiosResponse } from "axios";
import {
  ALLOWED_ATTACHMENT_EXTENSIONS,
  MAX_PRESIGNED_REQUESTS,
} from "./constants";
import { AttachmentMetaDataAndKey } from "../components/AddCommentField/AddCommentField";
import { CommentDisplayType } from "../Task";

export const generateAttachmentsWithMetaData = (files: File[]) =>
  files.map((file) => {
    const localUrl =
      file.type.includes("image") || file.type.includes("video")
        ? URL.createObjectURL(file)
        : undefined;

    return {
      file,
      attachment: {
        name: file.name,
        mimeType: file.type,
        publicUrl: localUrl,
        thumbnails: {
          small: localUrl,
        },
      },
      isLoading: true,
      localUuid: uuid(),
    };
  });

const filesWithSizeGreaterThanZero = (files: File[]) =>
  files.filter((file) => file.size > 0);

const filesWithCorrectSizeAndType = (files: File[]) =>
  files.filter((file) => {
    const fileNameAr = file.name.split(".");
    const fileExtension = fileNameAr[fileNameAr.length - 1];

    const size = file.size / parseInt((1000 * 1000).toFixed(2));

    return (
      size <= MAX_SIZE_SINGLE_ATTACHMENT &&
      ALLOWED_ATTACHMENT_EXTENSIONS.includes(fileExtension.toLowerCase())
    );
  });

interface HandleAddAttachmentsProps {
  files: File[];
  commentId: string;
  activeWorkspaceUuid: string;
  clearInputValue: () => void;
  setAttachments: Dispatch<SetStateAction<AttachmentMetaDataAndKey[]>>;
  commentType: CommentDisplayType;
}

export const handleAddAttachments = async ({
  files,
  commentId,
  activeWorkspaceUuid,
  clearInputValue,
  setAttachments,
  commentType,
}: HandleAddAttachmentsProps) => {
  if (files.length > MAX_PRESIGNED_REQUESTS) {
    showToast(
      "error",
      <IDHFormattedMessage
        id="ws_something_went_wrong"
        defaultMessage="Something went wrong."
      />,
      <IDHFormattedMessage
        id={`ws_maximum_number_attachments_is_${MAX_PRESIGNED_REQUESTS}`}
        defaultMessage={`Maximum number of attachments is ${MAX_PRESIGNED_REQUESTS}.`}
      />,
    );
    return;
  }

  const filesWithValidatedSize = filesWithSizeGreaterThanZero(files);

  if (filesWithValidatedSize.length < files.length) {
    showToast(
      "error",
      <IDHFormattedMessage
        id="ws_something_went_wrong"
        defaultMessage="Something went wrong."
      />,
      <IDHFormattedMessage
        id="ws_file_upload_empty_size"
        defaultMessage="One of selected files is empty. Please select different file."
      />,
    );
    clearInputValue();
  }

  if (!filesWithValidatedSize.length) return;

  const filesWithValidatedSizeAndType = filesWithCorrectSizeAndType(
    filesWithValidatedSize,
  );

  if (filesWithValidatedSizeAndType.length < filesWithValidatedSize.length) {
    showToast(
      "error",
      <IDHFormattedMessage
        id="ws_something_went_wrong"
        defaultMessage="Something went wrong."
      />,
      <IDHFormattedMessage
        id="ws_invalid_size_or_type"
        defaultMessage="One of selected files has invalid file size or type. Please select different file."
      />,
    );
    clearInputValue();
  }

  if (!filesWithValidatedSizeAndType.length) return;

  const attachmentsWithMetaData = generateAttachmentsWithMetaData(
    filesWithValidatedSizeAndType,
  );

  setAttachments((prev) => [...prev, ...attachmentsWithMetaData]);

  try {
    let promiseResults:
      | PromiseSettledResult<{
          result: AxiosResponse<any>;
          promiseFileName: string;
        }>[]
      | undefined = [];

    const sharedFunctionProps = {
      filesArray: filesWithValidatedSizeAndType,
      commentId,
      allowedTypes: ALLOWED_ATTACHMENT_EXTENSIONS,
      setAttachments,
    };

    switch (commentType) {
      case CommentDisplayType.Comment:
        const presignedUploadRequestUrl = `${API_URLS.presignedRequestForTaskCommentAttachment}?wsTaskCommentUuid=${commentId}&wsWorkspaceUuid=${activeWorkspaceUuid}&numberOfPresignedRequests=${filesWithValidatedSizeAndType.length}`;

        promiseResults = await uploadFilesToS3({
          ...sharedFunctionProps,
          presignedUploadRequestUrl,
          commentType,
          wsWorkspaceUuid: activeWorkspaceUuid,
        });
        break;
      case CommentDisplayType.ContentProposal:
        promiseResults = await uploadFilesToS3({
          ...sharedFunctionProps,
          presignedUploadRequestUrl:
            API_URLS.presignedRequestForContentProposal,
          commentType,
          wsWorkspaceUuid: activeWorkspaceUuid,
        });
        break;
      default:
        throw new Error("This comment type is not supported.");
    }

    if (promiseResults?.every((res: any) => res.status === "fulfilled")) {
      promiseResults?.map((object: any) => {
        setAttachments((prev) =>
          prev.map((item) => {
            const updatedItem = {
              ...item,
              isLoading:
                item.file && item.file.name === object.value.promiseFileName
                  ? false
                  : item.isLoading,
            };
            return updatedItem;
          }),
        );
      });
    } else {
      showToast(
        "error",
        <IDHFormattedMessage
          id="ws_something_went_wrong"
          defaultMessage="Something went wrong."
        />,
        <IDHFormattedMessage
          id="ws_could_not_upload_attachments"
          defaultMessage="Could not upload attachments."
        />,
      );
    }
  } catch (error) {
    console.error(error);
    showToast(
      "error",
      <IDHFormattedMessage
        id="ws_something_went_wrong"
        defaultMessage="Something went wrong."
      />,
      <IDHFormattedMessage
        id="ws_could_not_upload_attachments"
        defaultMessage="Could not upload attachments."
      />,
    );
  }

  clearInputValue();
};
