import { MetaFieldOptions } from "src/app/Task/Subtask/Subtask";
import { TaskType } from "src/types";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import * as actionTypes from "./settingsTypes";

export interface GlobalFieldBase {
  data: MetaFieldOptions;
  description: string;
  name: string;
  origin: string;
  type: MetaFieldType;
  rank: string;
  uuid: string;
  wsWorkspaceUuid: string;
  valueSource: string | null;
  existsInProject: boolean;
}

export interface GlobalProjectMetaField extends GlobalFieldBase {
  taskType: TaskType | null;
  valueRequired: boolean;
}

export interface GlobalTaskMetaField extends GlobalFieldBase {
  taskType: TaskType;
  isRequired: boolean;
  showInCreatorDatabase: boolean;
}

export interface SettingsReducerState {
  globalProjectMetaFields: GlobalProjectMetaField[];
  areGlobalMetaFieldsLoading: boolean;
  globalTaskMetaFields: GlobalTaskMetaField[];
  shouldReloadGlobalFields: boolean;
}

const initialState: SettingsReducerState = {
  globalProjectMetaFields: [],
  areGlobalMetaFieldsLoading: false,
  globalTaskMetaFields: [],
  shouldReloadGlobalFields: false,
};

function settingsReducer(
  state = initialState,
  action: any,
): SettingsReducerState {
  switch (action.type) {
    case actionTypes.GET_SETTINGS_GLOBAL_FIELDS:
      return {
        ...state,
        areGlobalMetaFieldsLoading: true,
      };

    case actionTypes.CLEAR_SETTINGS_GLOBAL_FIELDS:
      return {
        ...state,
        globalProjectMetaFields: [],
        globalTaskMetaFields: [],
      };

    case actionTypes.GET_SETTINGS_GLOBAL_FIELDS_SUCCESS: {
      const { globalProjectMetaFields, globalTaskMetaFields } =
        action.payload.data.content;

      return {
        ...state,
        globalProjectMetaFields,
        globalTaskMetaFields,
        areGlobalMetaFieldsLoading: false,
      };
    }

    case actionTypes.CREATE_NEW_TASK_GLOBAL_FIELD_SUCCESS:
    case actionTypes.CREATE_NEW_PROJECT_GLOBAL_FIELD_SUCCESS:
    case actionTypes.UPDATE_GLOBAL_TASK_FIELD_SUCCESS:
    case actionTypes.UPDATE_GLOBAL_PROJECT_FIELD_SUCCESS:
      return {
        ...state,
        shouldReloadGlobalFields: true,
      };

    case actionTypes.SET_RELOAD_TASK_FIELDS: {
      const { shouldReloadGlobalFields } = action.payload;
      return {
        ...state,
        shouldReloadGlobalFields,
      };
    }

    case actionTypes.UPDATE_TASK_GLOBAL_SELECT_FIELD: {
      const { metaFieldUuid, singleSelectOptions } = action.payload;

      return {
        ...state,
        globalTaskMetaFields: state.globalTaskMetaFields.map(
          (field: GlobalTaskMetaField) => {
            if (field.uuid === metaFieldUuid) {
              return {
                ...field,
                settings: {
                  ...field.data,
                  singleSelectOptions,
                },
              };
            }
            return field;
          },
        ),
      };
    }

    case actionTypes.UPDATE_PROJECT_GLOBAL_SELECT_FIELD: {
      const { metaFieldUuid, singleSelectOptions } = action.payload;

      return {
        ...state,
        globalProjectMetaFields: state.globalProjectMetaFields.map(
          (field: GlobalProjectMetaField) => {
            if (field.uuid === metaFieldUuid) {
              return {
                ...field,
                settings: {
                  ...field.data,
                  singleSelectOptions,
                },
              };
            }
            return field;
          },
        ),
      };
    }

    case actionTypes.UPDATE_GLOBAL_TASK_FIELD_RANK: {
      const { uuid, rank } = action.payload;
      return {
        ...state,
        globalTaskMetaFields: state.globalTaskMetaFields.map(
          (field: GlobalTaskMetaField) => {
            if (field.uuid === uuid) {
              return {
                ...field,
                rank,
              };
            }
            return field;
          },
        ),
      };
    }

    case actionTypes.UPDATE_GLOBAL_PROJECT_FIELD_RANK: {
      const { uuid, rank } = action.payload;
      return {
        ...state,
        globalProjectMetaFields: state.globalProjectMetaFields.map(
          (field: GlobalProjectMetaField) => {
            if (field.uuid === uuid) {
              return {
                ...field,
                rank,
              };
            }
            return field;
          },
        ),
      };
    }

    default:
      return state;
  }
}

export default settingsReducer;
