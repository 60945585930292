import React, { useEffect, useState } from "react";
import "./PublicationsDetection.scss";
import { ReactComponent as AggregationTypeAndIcon } from "src/images/aggregation_type_and.svg";
import { ReactComponent as AggregationTypeOrIcon } from "src/images/aggregation_type_or.svg";
import { ReactComponent as CheckIcon } from "src/images/selected.svg";

import axios from "axios";
import { uuidv7 as uuid } from "uuidv7";
import { useDispatch, useSelector } from "react-redux";

import { differenceInDays } from "date-fns";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import { Status } from "src/app/components/Status/Status";
import { getProject } from "src/redux";
import { RootState } from "src/redux/reducers";
import { API_URLS } from "../../../../../../utils/API_URLS";
import { showToast } from "../../../../../methods/showToast";
import {
  selectOptionsAreEqual,
  showErrorToast,
} from "../../../../../../utils/methods";
import { refreshAutomationList } from "../../../../../../redux/automation/automationActions";

import { ModalText, ModalTitle } from "../../../../../components/Modal/Modal";
import { Button } from "../../../../../components/Button/Button";
import { CustomDateRange } from "../../../../../components/CustomDateRange/CustomDateRange";
import { CustomInputLabel } from "../../../../../components/CustomInput/CustomInput";

import { ReactComponent as LighteningIcon } from "../../../../../../images/lightening.svg";
import { ReactComponent as PowerIcon } from "../../../../../../images/power.svg";
import { ReactComponent as SaveIcon } from "../../../../../../images/floppy-disk.svg";
import { CustomTokenInput } from "../../../../../components/CustomTokenInput/CustomTokenInput";
import {
  AutomationAggregationTypes,
  AutomationsSection,
} from "../../Automations";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "../../../../../components/Dropdown/Dropdown";

interface Props {
  setSection: (section: AutomationsSection) => void;
  basicData?: any;
}

export const PublicationsDetection: React.FC<Props> = (props) => {
  const { setSection, basicData } = props;

  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [aggregationType, setAggregationType] = useState<string | null>(
    AutomationAggregationTypes.OrType,
  );
  const [isOpenAggregationType, setIsOpenAggregationType] =
    useState<boolean>(false);
  const [keywords, setKeywords] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [hashtags, setHashtags] = useState<any[]>([]);

  // loading
  const [createLoading, setCreateLoading] = useState(false);
  const [enableLoading, setEnableLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const { projectId } = basicData;

  const {
    automationReducer: { editedAutomationData },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const intl = useIntl();

  const goBack = () => setSection(AutomationsSection.AutomationsMain);

  useEffect(() => {
    if (
      !editedAutomationData?.settings ||
      editedAutomationData?.wsProjectUuid !== projectId
    )
      return;

    // Date From
    if (editedAutomationData.settings?.dateFrom) {
      setDateFrom(editedAutomationData.settings?.dateFrom);
    }
    // Date To
    if (editedAutomationData.settings?.dateTo) {
      setDateTo(editedAutomationData.settings?.dateTo);
    }
    // Aggregation Type
    if (editedAutomationData.settings?.aggregationType) {
      setAggregationType(editedAutomationData.settings?.aggregationType);
    }
    // Keywords
    if (editedAutomationData.settings?.keywords) {
      const newKeywords = editedAutomationData.settings?.keywords || [];

      setKeywords(newKeywords);
    }
    // Hashtags
    if (editedAutomationData.settings?.hashtags) {
      const newHashtags = editedAutomationData.settings?.hashtags || [];

      setHashtags(newHashtags);
    }
    // Tags
    if (editedAutomationData.settings?.mentions) {
      const newTags = editedAutomationData.settings?.mentions || [];

      setTags(newTags);
    }
  }, [editedAutomationData]);

  const reloadData = () => {
    dispatch(refreshAutomationList(projectId || basicData.projectId));
    dispatch(getProject(projectId || basicData.projectId));
  };

  const toggleAutomation = async () => {
    setEnableLoading(true);
    const url = API_URLS.toggleAutomation;

    const newValue = !editedAutomationData.enabled;

    try {
      await axios.post(url, {
        uuid: editedAutomationData.uuid,
        enabled: newValue,
      });
      if (newValue) {
        showToast(
          "info",
          <IDHFormattedMessage
            id="ws_automation_enabled"
            defaultMessage="Automation enabled"
          />,
          <IDHFormattedMessage
            id="ws_we_will_inform_you_about_detected_publications"
            defaultMessage="We will inform you about detected publications."
          />,
        );
        runAutomation();
      }
      goBack();
      setEnableLoading(false);
      reloadData();
    } catch (err) {
      console.error(err);
      showErrorToast();
    }
  };

  const saveAutomationChanges = async () => {
    setSaveLoading(true);
    const url = API_URLS.updateAutomation;

    try {
      await axios.put(url, {
        uuid: editedAutomationData.uuid,
        wsProjectUuid: projectId,
        wsAutomationName: "PublicationDetectionRunnableAutomation",
        settings: {
          dateTo,
          dateFrom,
          aggregationType,
          keywords,
        },
      });
      setSaveLoading(false);
      reloadData();
      goBack();
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const runAutomation = async () => {
    const url = API_URLS.runAutomation;

    try {
      await axios.post(url, {
        uuid: editedAutomationData.uuid,
        wsProjectUuid: projectId || basicData.projectId,
        wsAutomationName: "PublicationDetectionRunnableAutomation",
        settings: {
          dateTo,
          dateFrom,
          aggregationType,
          keywords,
        },
      });
    } catch (err) {
      console.error(err);
      showErrorToast();
    }
  };

  const createAutomation = async () => {
    setCreateLoading(true);
    const url = API_URLS.createAutomation;
    const automationUuid = uuid();

    try {
      await axios.post(url, {
        uuid: automationUuid,
        wsProjectUuid: projectId || basicData.projectId,
        wsAutomationName: "PublicationDetectionRunnableAutomation",
        settings: {
          dateTo,
          dateFrom,
          aggregationType,
          keywords,
        },
      });
      setCreateLoading(false);
      reloadData();
      goBack();
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const datesFromEqual = dateFrom === editedAutomationData.settings?.dateFrom;
  const datesToEqual = dateTo === editedAutomationData.settings?.dateTo;
  const keywordsEqual = selectOptionsAreEqual(
    keywords,
    editedAutomationData.settings?.keywords,
  );
  const hashtagsEqual = selectOptionsAreEqual(
    hashtags,
    editedAutomationData.settings?.hashtags,
  );
  const tagsEqual = selectOptionsAreEqual(
    tags,
    editedAutomationData.settings?.mentions,
  );

  const differencesDetected =
    editedAutomationData.uuid &&
    (!datesFromEqual ||
      !datesToEqual ||
      !hashtagsEqual ||
      !tagsEqual ||
      !keywordsEqual);

  const dayDifference =
    dateFrom && dateTo
      ? differenceInDays(new Date(dateTo), new Date(dateFrom)) || 31
      : 31;

  const blockDate = dateFrom !== dateTo && dayDifference > 30;

  return (
    <>
      <AnimatedDiv className="publications-detection">
        <span className="publications-detection__link" onClick={goBack}>
          <IDHFormattedMessage
            id="ws_automations"
            defaultMessage="Automations"
          />
        </span>
        <ModalTitle>
          <IDHFormattedMessage
            id="ws_publications_detection"
            defaultMessage="Publications detection"
          />
          <Status
            className={
              editedAutomationData?.enabled
                ? "status--green-01"
                : "status--grey-01"
            }
          >
            {editedAutomationData?.enabled ? (
              <IDHFormattedMessage id="ws_active" defaultMessage="Active" />
            ) : (
              <IDHFormattedMessage id="ws_disabled" defaultMessage="Disabled" />
            )}
          </Status>
        </ModalTitle>
        <ModalText>
          <IDHFormattedMessage
            id="ws_publications_detection_modal_text"
            defaultMessage="Please set the timeframe for the automatic post detection to be active. Please note that all provided hashtags and mentions must be included in the publication caption for it to be detected."
          />
        </ModalText>

        {/* Duration */}
        <div className="publications-detection__row">
          <CustomInputLabel htmlFor="duration">
            <IDHFormattedMessage id="ws_duration" defaultMessage="Duration" />
          </CustomInputLabel>
          <CustomDateRange
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
            startingRangeDays={7}
            showError={blockDate}
            minDate={new Date()}
            errorMessage={
              <IDHFormattedMessage
                id="ws_date_must_be_within_30_days"
                defaultMessage="Date range must be within 30 days"
              />
            }
          />
        </div>

        {/* Keywords */}
        <div className="publications-detection__row">
          <CustomInputLabel htmlFor="tags">
            <IDHFormattedMessage id="ws_keywords" defaultMessage="Keywords" />
          </CustomInputLabel>
          <div className="publications-detection__keywords-row">
            <CustomTokenInput
              value={keywords}
              onChange={(newValue: any) => setKeywords(newValue)}
              placeholder={translateMessage({
                intl,
                id: "ws_enter_keywords_to_detect",
                defaultMessage: "Enter keywords to detect",
              })}
              separators={[","]}
            />
            <Dropdown toRight>
              {(isOpenAggregationType, setIsOpenAggregationType) => (
                <>
                  <Button
                    variant="light-grey"
                    size="large"
                    onClick={() => setIsOpenAggregationType(true)}
                    className="action-dropdown__button"
                  >
                    {aggregationType === AutomationAggregationTypes.OrType ? (
                      <AggregationTypeOrIcon />
                    ) : (
                      <AggregationTypeAndIcon />
                    )}
                  </Button>
                  <DropdownMenu
                    isOpen={isOpenAggregationType}
                    onClick={() => setIsOpenAggregationType(false)}
                  >
                    <DropdownMenuItem
                      className="publications-detection__aggregation-row"
                      onClick={() =>
                        setAggregationType(AutomationAggregationTypes.OrType)
                      }
                    >
                      <AggregationTypeOrIcon className="publications-detection__aggregation-icon" />
                      <div className="publications-detection__aggregation-text">
                        <div className="publications-detection__aggregation-text-title">
                          <IDHFormattedMessage
                            id="ws_publication_detection_or"
                            defaultMessage="At least one"
                          />
                          {aggregationType ===
                            AutomationAggregationTypes.OrType && (
                            <CheckIcon className="publications-detection__aggregation-check-icon" />
                          )}
                        </div>
                        <div className="publications-detection__aggregation-text-description">
                          <IDHFormattedMessage
                            id="ws_publication_detection_or_description"
                            defaultMessage="The publication must contain at least one entered keyword."
                          />
                        </div>
                      </div>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="publications-detection__aggregation-row"
                      onClick={() =>
                        setAggregationType(AutomationAggregationTypes.AndType)
                      }
                    >
                      <AggregationTypeAndIcon className="publications-detection__aggregation-icon" />
                      <div className="publications-detection__aggregation-text">
                        <div className="publications-detection__aggregation-text-title">
                          <IDHFormattedMessage
                            id="ws_publication_detection_and"
                            defaultMessage="All selected"
                          />
                          {aggregationType ===
                            AutomationAggregationTypes.AndType && (
                            <CheckIcon className="publications-detection__aggregation-check-icon" />
                          )}
                        </div>
                        <div className="publications-detection__aggregation-text-description">
                          <IDHFormattedMessage
                            id="ws_publication_detection_and_description"
                            defaultMessage="The publication must contain all entered keywords."
                          />
                        </div>
                      </div>
                    </DropdownMenuItem>
                  </DropdownMenu>
                </>
              )}
            </Dropdown>
          </div>
        </div>
      </AnimatedDiv>
      <SettingsButtons>
        <div className="publications-detection__buttons">
          <div className="publications-detection__buttons-wrapper">
            <Button
              className="publications-detection__buttons-back"
              size="large"
              variant="white-with-border"
              onClick={goBack}
            >
              <IDHFormattedMessage id="ws_back" defaultMessage="Back" />
            </Button>
          </div>
          <div className="publications-detection__buttons-wrapper publications-detection__buttons-wrapper--multiple">
            {differencesDetected ? (
              <Button
                size="large"
                variant="white-with-border"
                onClick={saveAutomationChanges}
                disabled={saveLoading || keywords.length === 0}
              >
                <SaveIcon />
                <IDHFormattedMessage id="ws_save" defaultMessage="Save" />
              </Button>
            ) : null}
            {editedAutomationData.enabled === true ? (
              <Button
                size="large"
                variant="blue"
                onClick={toggleAutomation}
                disabled={enableLoading}
              >
                <PowerIcon />
                <IDHFormattedMessage id="ws_disable" defaultMessage="Disable" />
              </Button>
            ) : editedAutomationData.enabled === false ? (
              <Button
                size="large"
                variant="blue"
                onClick={toggleAutomation}
                disabled={enableLoading}
              >
                <LighteningIcon />
                <IDHFormattedMessage
                  id="ws_enable_run_now"
                  defaultMessage="Enable & run now"
                />
              </Button>
            ) : null}
            {!editedAutomationData.uuid ? (
              <Button
                size="large"
                variant="blue"
                onClick={createAutomation}
                disabled={!keywords.length || blockDate || createLoading}
              >
                <LighteningIcon />
                <IDHFormattedMessage
                  id="ws_enable_run_now"
                  defaultMessage="Enable & run now"
                />
              </Button>
            ) : null}
          </div>
        </div>
      </SettingsButtons>
    </>
  );
};
