import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { LanguageSelector } from "src/app/components/LanguageSelector/LanguageSelector";
import { ModalTitle } from "src/app/components/Modal/Modal";
import { getTranslationsForMember, identify } from "src/redux/main/mainActions";
import { API_URLS } from "src/utils/API_URLS";
import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import { ReactComponent as IdhLogo } from "src/images/idh-logo-color.svg";
import { RootState } from "src/redux/reducers";
import { showErrorToast } from "src/utils/methods";

export const TrialLanguage: React.FC<{}> = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<any>({});

  const {
    mainReducer: { activeWorkspaceUuid, locale },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedLanguage.value && locale !== selectedLanguage.value) {
      updateLanguage();
    }
  }, [selectedLanguage]);

  const updateLanguage = async () => {
    try {
      await axios.put(API_URLS.setLocale, {
        locale: selectedLanguage.value,
      });
      dispatch(getTranslationsForMember());
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const handleClickNext = async () => {
    try {
      await axios.post(API_URLS.hideTrialLanguage);
      dispatch(identify(activeWorkspaceUuid));
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  return (
    <>
      <IdhLogo className="trial-limitations-modal__idh-logo" />

      <ModalTitle>
        <IDHFormattedMessage id="ws_language" defaultMessage="Language" />
      </ModalTitle>

      <CustomInputLabel aboveInput>
        <IDHFormattedMessage
          id="ws_select_language_used_in_interface"
          defaultMessage="Select language used in the platform interface"
        />
      </CustomInputLabel>

      <LanguageSelector
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />

      <div className="trial-limitations-modal__buttons trial-limitations-modal__buttons--full-width">
        <Button variant="blue" size="large" onClick={handleClickNext}>
          <IDHFormattedMessage id="ws_next" defaultMessage="Next" />
        </Button>
      </div>
    </>
  );
};
