import React, { useEffect } from "react";
import "./LoginPage.scss";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";

import { LeftSide } from "./components/LeftSide";
import { RightSide } from "./components/RightSide";

function LoginPage() {
  const { identity, identifyError } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  useEffect(() => {
    if (Object.keys(identity).length > 0) {
      window.location.pathname = `/workspace`;
    }
  }, [identity, window.location.pathname]);

  return identifyError ? (
    <div className="login-page">
      <LeftSide />
      <RightSide />
    </div>
  ) : null;
}

export { LoginPage };
