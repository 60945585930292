import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { wsAxiosPut } from "src/helpers/wsAxios";
import Modal, { ModalText, ModalTitle } from "../../components/Modal/Modal";
import { showToast } from "../../methods/showToast";
import {
  localSetColumnsGroupSharing,
  localSetTasksSharing,
  setSelectedTasks,
} from "../../../redux";

import { API_URLS } from "../../../utils/API_URLS";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import ShareMultipleColumnsTaskDetails from "./ShareMultipleTaskDetails";

import "./ShareMultipleColumns.scss";

function ShareMultipleColumnsModal({
  onClose,
  taskAvatarShape,
  tasks,
  taskType,
  shouldSelectTasksAfterSharing = false,
}) {
  const {
    taskReducer: { tasksColumns },
    projectReducer: {
      projectBasicData: { projectId },
    },
  } = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(false);

  const [checkboxList, setCheckboxList] = useState(
    tasksColumns.map(({ metaFieldId, metaFieldShared, metaFieldIsSystem }) => ({
      id: metaFieldId,
      checked: metaFieldIsSystem || metaFieldShared,
    })),
  );

  const dispatch = useDispatch();

  const shareWithClient = async (e) => {
    e.preventDefault();
    const columnIds = checkboxList
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.id);

    setIsLoading(true);

    try {
      await axios.post(
        API_URLS.shareMultipleColumns.replace(":wsProjectUuid:", projectId),
        {
          wsTaskMetaFieldUuids: columnIds,
          wsTaskType: taskType,
        },
      );

      dispatch(localSetColumnsGroupSharing(columnIds));
      const taskIds = tasks.map((task) => task.id);

      await wsAxiosPut(
        API_URLS.shareGroupTask,
        {
          wsTaskUuids: taskIds,
        },
        `Error occurred during sharing column`,
        () => {},
        () => {
          showToast(
            "success",
            <IDHFormattedMessage id="ws_sharing" defaultMessage="Sharing" />,
            <IDHFormattedMessage
              id="ws_sharing_for_selected_enabled"
              defaultMessage="Sharing for selected items are enable"
            />,
          );
          dispatch(localSetTasksSharing(taskIds, true));
          shouldSelectTasksAfterSharing &&
            dispatch(
              setSelectedTasks(
                tasks.map((task) => {
                  task.shared = true;
                  return task;
                }),
              ),
            );
        },
      );
      onClose();
    } catch (err) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_cannot_share_fields"
          defaultMessage="Cannot share fields"
        />,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onCheckboxChange = (metaFieldId) => {
    const foundIndex = checkboxList.findIndex(
      (checkbox) => checkbox.id === metaFieldId,
    );

    const updatedCheckboxList = [...checkboxList];
    updatedCheckboxList[foundIndex].checked =
      !updatedCheckboxList[foundIndex].checked;

    setCheckboxList(updatedCheckboxList);
  };

  return (
    <Modal
      isLoading={isLoading}
      className="share-multiple-columns"
      onClose={onClose}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={shareWithClient}
      confirmButtonText={
        <IDHFormattedMessage id="ws_share" defaultMessage="Share" />
      }
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_share_with_client"
          defaultMessage="Share with Client"
        />
      </ModalTitle>

      <ShareMultipleColumnsTaskDetails
        tasks={tasks}
        taskAvatarShape={taskAvatarShape}
      />

      <ModalText>
        <IDHFormattedMessage
          id="ws_share_multiple_columns_modal_text"
          defaultMessage="Please select the columns you want to share. The columns visibility will be applied to all shared items."
        />
      </ModalText>

      <form onSubmit={shareWithClient}>
        <div className="share-multiple-columns__checkbox-wrapper">
          {tasksColumns.map(
            ({ metaFieldName, metaFieldId, metaFieldIsSystem }) => (
              <CustomCheckbox
                key={`checkbox-${metaFieldName}`}
                className="share-multiple-columns__checkbox"
                id={`${metaFieldName}-checkbox`}
                name={`${metaFieldName}-checkbox`}
                label={metaFieldName}
                disabled={metaFieldIsSystem}
                checked={checkboxList.find(
                  (checkbox) => checkbox.id === metaFieldId && checkbox.checked,
                )}
                onChange={() => onCheckboxChange(metaFieldId)}
              />
            ),
          )}
        </div>
      </form>
    </Modal>
  );
}

export { ShareMultipleColumnsModal };
