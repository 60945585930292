import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./PublicationSynchronization.scss";

import axios from "axios";
import { uuidv7 as uuid } from "uuidv7";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import { getProject } from "src/redux";
import { RootState } from "src/redux/reducers";
import { showErrorToast } from "src/utils/methods";
import { API_URLS } from "../../../../../../utils/API_URLS";
import { showToast } from "../../../../../methods/showToast";
import { refreshAutomationList } from "../../../../../../redux/automation/automationActions";

import Modal, {
  ModalText,
  ModalTitle,
} from "../../../../../components/Modal/Modal";
import { Button } from "../../../../../components/Button/Button";
import { CustomInputLabel } from "../../../../../components/CustomInput/CustomInput";

import { ReactComponent as LighteningIcon } from "../../../../../../images/lightening.svg";
import { ReactComponent as PowerIcon } from "../../../../../../images/power.svg";
import { ReactComponent as SaveIcon } from "../../../../../../images/floppy-disk.svg";
import CustomSelect from "../../../../../components/CustomSelect/CustomSelect";
import { AutomationsSection } from "../../Automations";

interface Props {
  setSection: (section: AutomationsSection) => void;
  basicData?: any;
}

export const refreshIntervalOptions = [
  {
    label: (
      <>
        1&nbsp;
        <IDHFormattedMessage id="ws_hour" defaultMessage="hour" />
      </>
    ),
    value: 1,
  },
  {
    label: (
      <>
        2&nbsp;
        <IDHFormattedMessage id="ws_hours" defaultMessage="hours" />
      </>
    ),
    value: 2,
  },
  {
    label: (
      <>
        4&nbsp;
        <IDHFormattedMessage id="ws_hours" defaultMessage="hours" />
      </>
    ),
    value: 4,
  },
  {
    label: (
      <>
        6&nbsp;
        <IDHFormattedMessage id="ws_hours" defaultMessage="hours" />
      </>
    ),
    value: 6,
  },
  {
    label: (
      <>
        12&nbsp;
        <IDHFormattedMessage id="ws_hours" defaultMessage="hours" />
      </>
    ),
    value: 12,
  },
  {
    label: (
      <>
        24&nbsp;
        <IDHFormattedMessage id="ws_hours" defaultMessage="hours" />
      </>
    ),
    value: 24,
  },
];

export const activeForOptions = [
  {
    label: (
      <>
        1&nbsp;
        <IDHFormattedMessage
          id="ws_day_after_publication"
          defaultMessage="day after publication"
        />
      </>
    ),
    value: 1,
  },
  {
    label: (
      <>
        3&nbsp;
        <IDHFormattedMessage
          id="ws_days_after_publication"
          defaultMessage="days after publication"
        />
      </>
    ),
    value: 3,
  },
  {
    label: (
      <>
        5&nbsp;
        <IDHFormattedMessage
          id="ws_days_after_publication"
          defaultMessage="days after publication"
        />
      </>
    ),
    value: 5,
  },
  {
    label: (
      <>
        7&nbsp;
        <IDHFormattedMessage
          id="ws_days_after_publication"
          defaultMessage="days after publication"
        />
      </>
    ),
    value: 7,
  },
  {
    label: (
      <>
        30&nbsp;
        <IDHFormattedMessage
          id="ws_days_after_publication"
          defaultMessage="days after publication"
        />
      </>
    ),
    value: 30,
  },
];

export const PublicationsSynchronization: React.FC<Props> = (props) => {
  const { setSection, basicData } = props;

  const [refreshInterval, setRefreshInterval] = useState<any>(null);
  const [activeFor, setActiveFor] = useState<any>(null);

  // loading
  const [createLoading, setCreateLoading] = useState(false);
  const [enableLoading, setEnableLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const { projectId } = basicData;

  const {
    automationReducer: { editedAutomationData },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const intl = useIntl();

  const goBack = () => setSection(AutomationsSection.AutomationsMain);

  useEffect(() => {
    setRefreshInterval(
      refreshIntervalOptions?.find(
        (item) =>
          item.value === (editedAutomationData.settings?.refreshInterval ?? 2),
      ) ?? null,
    );

    setActiveFor(
      activeForOptions?.find(
        (item) =>
          item.value === (editedAutomationData.settings?.activeFor ?? 7),
      ) ?? null,
    );
  }, [editedAutomationData]);

  const reloadData = () => {
    dispatch(refreshAutomationList(projectId || basicData.projectId));
    dispatch(getProject(projectId || basicData.projectId));
  };

  const toggleAutomation = async () => {
    setEnableLoading(true);
    const url = API_URLS.toggleAutomation;

    const newValue = !editedAutomationData.enabled;

    try {
      await axios.post(url, {
        uuid: editedAutomationData.uuid,
        enabled: newValue,
      });
      if (newValue) {
        showToast(
          "info",
          <IDHFormattedMessage
            id="ws_automation_enabled"
            defaultMessage="Automation enabled"
          />,
          <IDHFormattedMessage
            id="ws_we_will_inform_you_about_detected_publications"
            defaultMessage="We will inform you about detected publications."
          />,
        );
        runAutomation();
      }
      goBack();
      setEnableLoading(false);
      reloadData();
    } catch (err) {
      console.error(err);
      showErrorToast();
    }
  };

  const saveAutomationChanges = async () => {
    setSaveLoading(true);
    const url = API_URLS.updateAutomation;

    try {
      await axios.put(url, {
        uuid: editedAutomationData.uuid,
        wsProjectUuid: projectId,
        wsAutomationName: "PublicationSynchronizationRunnableAutomation",
        settings: {
          refreshInterval: refreshInterval?.value,
          activeFor: activeFor?.value,
        },
      });
      setSaveLoading(false);
      reloadData();
      goBack();
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const runAutomation = async () => {
    const url = API_URLS.runAutomation;

    try {
      await axios.post(url, {
        uuid: editedAutomationData.uuid,
        wsProjectUuid: projectId || basicData.projectId,
        wsAutomationName: "PublicationSynchronizationRunnableAutomation",
        settings: {
          refreshInterval: refreshInterval?.value,
          activeFor: activeFor?.value,
        },
      });
    } catch (err) {
      console.error(err);
      showErrorToast({
        id: "ws_something_went_wrong_please_try_again",
        defaultMessage: "Something went wrong, please try again.",
      });
    }
  };

  const createAutomation = async () => {
    setCreateLoading(true);
    const url = API_URLS.createAutomation;
    const automationUuid = uuid();

    try {
      await axios.post(url, {
        uuid: automationUuid,
        wsProjectUuid: projectId || basicData.projectId,
        wsAutomationName: "PublicationSynchronizationRunnableAutomation",
        settings: {
          refreshInterval: refreshInterval?.value,
          activeFor: activeFor?.value,
        },
      });
      setCreateLoading(false);
      reloadData();
      goBack();
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  return (
    <>
      <AnimatedDiv className="publication-synchronization">
        <span className="publication-synchronization__link" onClick={goBack}>
          <IDHFormattedMessage
            id="ws_automations"
            defaultMessage="Automations"
          />
        </span>
        <ModalTitle>
          <IDHFormattedMessage
            id="ws_publications_synchronization"
            defaultMessage="Publications synchronization"
          />
          <span
            className={classNames([
              "publication-synchronization__label",
              editedAutomationData?.enabled &&
                "publication-synchronization__label--active",
            ])}
          >
            {editedAutomationData?.enabled ? (
              <IDHFormattedMessage id="ws_active" defaultMessage="Active" />
            ) : (
              <IDHFormattedMessage id="ws_disabled" defaultMessage="Disabled" />
            )}
          </span>
        </ModalTitle>
        <ModalText>
          <IDHFormattedMessage
            id="ws_publications_synchronization_modal_text"
            defaultMessage="Please specify the frequency at which each publication should be refreshed."
          />
        </ModalText>

        <div className="publication-synchronization__row">
          <CustomInputLabel htmlFor="refreshInterval">
            <IDHFormattedMessage
              id="ws_refresh_publications_every"
              defaultMessage="Refresh publications every"
            />
          </CustomInputLabel>
          <CustomSelect
            id="refreshInterval"
            options={refreshIntervalOptions}
            value={refreshInterval}
            onChange={(newValue: any) => setRefreshInterval(newValue)}
            placeholder={`${translateMessage({
              intl,
              id: "ws_select_interval",
              defaultMessage: "Select interval",
            })}...`}
            disabled={saveLoading}
            noOptionsMessage="Add at least one publication to use it in report"
          />
        </div>
        <div className="publication-synchronization__row">
          <CustomInputLabel htmlFor="interval">
            <IDHFormattedMessage id="ws_for" defaultMessage="For" />
          </CustomInputLabel>
          <CustomSelect
            id="activeFor"
            options={activeForOptions}
            value={activeFor}
            onChange={(newValue: any) => setActiveFor(newValue)}
            placeholder={`${translateMessage({
              intl,
              id: "ws_synchronization_active_for",
              defaultMessage: "Synchronization active for",
            })}...`}
            disabled={saveLoading}
            noOptionsMessage={`${translateMessage({
              intl,
              id: "ws_add_at_least_one_publication_to_use",
              defaultMessage:
                "Add at least one publication to use it in report",
            })}...`}
          />
        </div>
      </AnimatedDiv>
      <SettingsButtons>
        <div className="publication-synchronization__buttons">
          <div className="publication-synchronization__buttons-wrapper">
            <Button
              className="publication-synchronization__buttons-back"
              size="large"
              variant="white-with-border"
              onClick={goBack}
            >
              <IDHFormattedMessage id="ws_back" defaultMessage="Back" />
            </Button>
          </div>
          <div className="publication-synchronization__buttons-wrapper publication-synchronization__buttons-wrapper--multiple">
            {editedAutomationData.enabled === true ? (
              <>
                <Button
                  size="large"
                  variant="white-with-border"
                  onClick={saveAutomationChanges}
                  disabled={saveLoading}
                >
                  <SaveIcon />
                  <IDHFormattedMessage id="ws_save" defaultMessage="Save" />
                </Button>
                <Button
                  size="large"
                  variant="blue"
                  onClick={toggleAutomation}
                  disabled={enableLoading}
                >
                  <PowerIcon />
                  <IDHFormattedMessage
                    id="ws_disable"
                    defaultMessage="Disable"
                  />
                </Button>
              </>
            ) : editedAutomationData.enabled === false ? (
              <Button
                size="large"
                variant="blue"
                onClick={toggleAutomation}
                disabled={enableLoading}
              >
                <LighteningIcon />
                <IDHFormattedMessage
                  id="ws_enable_run_now"
                  defaultMessage="Enable & run now"
                />
              </Button>
            ) : null}
            {!editedAutomationData.uuid ? (
              <Button
                size="large"
                variant="blue"
                onClick={createAutomation}
                disabled={!(activeFor && refreshInterval) || createLoading}
              >
                <LighteningIcon />
                <IDHFormattedMessage
                  id="ws_enable_run_now"
                  defaultMessage="Enable & run now"
                />
              </Button>
            ) : null}
          </div>
        </div>
      </SettingsButtons>
    </>
  );
};
