import React from "react";
import "./Skeleton.scss";
import classNames from "classnames";

enum SkeletonVariant {
  Circle = "circle",
  Rect = "rect",
  Text = "text",
}

interface MarginProps {
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  marginBottom?: number;
}

interface SkeletonProps extends MarginProps {
  variant?: "circle" | "rect" | "text";
  width: number | string;
  height: number | string;
  borderRadius?: number | string;
}

const Skeleton: React.FC<SkeletonProps> = (props) => {
  const {
    variant,
    width,
    height,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    borderRadius,
  } = props;

  return (
    <div
      style={{
        width,
        height,
        marginLeft,
        marginRight,
        marginTop,
        marginBottom,
        borderRadius,
      }}
      className={classNames("ws-skeleton", {
        "ws-skeleton--circle": variant === SkeletonVariant.Circle,
        "ws-skeleton--rect": variant === SkeletonVariant.Rect,
        "ws-skeleton--text": variant === SkeletonVariant.Text,
      })}
    />
  );
};

export default Skeleton;

interface SkeletonCircleProps extends MarginProps {
  size: number | string;
}

export const SkeletonCircle: React.FC<SkeletonCircleProps> = (props) => {
  const { size, marginLeft, marginRight, marginTop, marginBottom } = props;

  return (
    <div
      style={{
        width: size,
        height: size,
        marginLeft,
        marginRight,
        marginTop,
        marginBottom,
      }}
      className="ws-skeleton ws-skeleton--circle"
    />
  );
};

interface SkeletonTextProps extends MarginProps {
  width: number | string;
  height: number | string;
}

export const SkeletonText: React.FC<SkeletonTextProps> = (props) => {
  const { width, height, marginLeft, marginRight, marginTop, marginBottom } =
    props;

  return (
    <div
      style={{
        width,
        height,
        marginLeft,
        marginRight,
        marginTop,
        marginBottom,
      }}
      className="ws-skeleton ws-skeleton--text"
    />
  );
};

interface SkeletonRectProps extends MarginProps {
  width: number | string;
  height: number | string;
}

export const SkeletonRect: React.FC<SkeletonRectProps> = (props) => {
  const { width, height, marginLeft, marginRight, marginTop, marginBottom } =
    props;

  return (
    <div
      style={{
        width,
        height,
        marginLeft,
        marginRight,
        marginTop,
        marginBottom,
      }}
      className="ws-skeleton ws-skeleton--rect"
    />
  );
};
