import { addSpaceIfNeeded } from "./addSpaceIfNeeded";

export const addSelectedCommentsToLayout = (comments, newLayout, format) => {
  if (!comments) return;

  Object.keys(comments?.all)?.forEach((key) => {
    const lastEl = addSpaceIfNeeded("comments", newLayout, format);

    let x = 0;
    let y = 0;
    const w = 6;
    const h = 5;

    if (lastEl.w === 12 || lastEl.x === 6) {
      x = 0;
      y = lastEl.y + lastEl.h;
    }
    // second in row and half the width
    else if (lastEl.x === 0) {
      x = lastEl.x + 6;
      y = lastEl.y;
    }

    newLayout.push({ i: `comments-${key}`, x, y, w, h });
  });
};
