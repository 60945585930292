export const GET_GLOBAL_TASKS = "GET_GLOBAL_TASKS";
export const GET_GLOBAL_TASKS_SUCCESS = "GET_GLOBAL_TASKS_SUCCESS";
export const GET_GLOBAL_TASKS_FAIL = "GET_GLOBAL_TASKS_FAIL";

export const GET_SINGLE_GLOBAL_TASK_FROM_LIST =
  "GET_SINGLE_GLOBAL_TASK_FROM_LIST";
export const GET_SINGLE_GLOBAL_TASK_FROM_LIST_SUCCESS =
  "GET_SINGLE_GLOBAL_TASK_FROM_LIST_SUCCESS";
export const GET_SINGLE_GLOBAL_TASK_FROM_LIST_FAIL =
  "GET_SINGLE_GLOBAL_TASK_FROM_LIST_FAIL";

// GLOBAL TASK
export const GET_GLOBAL_TASK_DETAILS = "GET_GLOBAL_TASK_DETAILS";
export const GET_GLOBAL_TASK_DETAILS_SUCCESS =
  "GET_GLOBAL_TASK_DETAILS_SUCCESS";
export const GET_GLOBAL_TASK_DETAILS_SUCCESS_FAIL =
  "GET_GLOBAL_TASK_DETAILS_SUCCESS_FAIL";
export const CLEAR_GLOBAL_TASK_DETAILS = "CLEAR_GLOBAL_TASK_DETAILS";

export const SET_GLOBAL_TASK_META_VALUE = "SET_GLOBAL_TASK_META_VALUE";
export const SET_GLOBAL_TASK_META_VALUE_SUCCESS =
  "SET_GLOBAL_TASK_META_VALUE_SUCCESS";
export const SET_GLOBAL_TASK_META_VALUE_FAIL =
  "SET_GLOBAL_TASK_META_VALUE_FAIL";

export const CREATE_GLOBAL_TASKS = "CREATE_GLOBAL_TASKS";
export const CREATE_GLOBAL_TASKS_SUCCESS = "CREATE_GLOBAL_TASKS_SUCCESS";
export const ADD_EXISTING_TASKS_TO_DATABASE = "ADD_EXISTING_TASKS_TO_DATABASE";

export const REMOVE_GLOBAL_TASKS = "REMOVE_GLOBAL_TASKS";
export const REMOVE_GLOBAL_TASKS_FAIL = "REMOVE_GLOBAL_TASKS_FAIL";
export const REMOVE_GLOBAL_TASKS_SUCCESS = "REMOVE_GLOBAL_TASKS_SUCCESS";

export const UPDATE_CREATOR_DATABASE_SELECT_FIELD =
  "UPDATE_CREATOR_DATABASE_SELECT_FIELD";
export const UPDATE_CREATOR_DATABASE_SELECT_FIELD_SUCCESS =
  "UPDATE_CREATOR_DATABASE_SELECT_FIELD_SUCCESS";
export const UPDATE_CREATOR_DATABASE_SELECT_FIELD_FAIL =
  "UPDATE_CREATOR_DATABASE_SELECT_FIELD_FAIL";

export const GET_GLOBAL_TASK_PROJECTS = "GET_GLOBAL_TASK_PROJECTS";
export const GET_GLOBAL_TASK_PROJECTS_SUCCESS =
  "GET_GLOBAL_TASK_PROJECTS_SUCCESS";

export const CLEAR_GLOBAL_TASK_PROJECTS = "CLEAR_GLOBAL_TASK_PROJECTS";

export const GET_GLOBAL_TASK_CREATOR_LISTS = "GET_GLOBAL_TASK_CREATOR_LISTS";
export const GET_GLOBAL_TASK_CREATOR_LISTS_SUCCESS =
  "GET_GLOBAL_TASK_CREATOR_LISTS_SUCCESS";

export const CLEAR_GLOBAL_TASK_CREATOR_LISTS =
  "CLEAR_GLOBAL_TASK_CREATOR_LISTS";

export const GET_GLOBAL_TASK_RELATED_TASKS = "GET_GLOBAL_TASK_RELATED_TASKS";
export const GET_GLOBAL_TASK_RELATED_TASKS_SUCCESS =
  "GET_GLOBAL_TASK_RELATED_TASKS_SUCCESS";

export const CLEAR_GLOBAL_TASK_RELATED_TASKS =
  "CLEAR_GLOBAL_TASK_RELATED_TASKS";
