import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";
import { ReactComponent as CityIcon } from "../../../../../images/profile-data/location-by-city.svg";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { LocationByCityList } from "../components/LocationByCityList/LocationByCityList";

export function LocationByCity(props) {
  const { audienceCityReach, height, onProjectDetails } = props;

  const onShowcase = height;

  return (
    <ProfileBox onProjectDetails={onProjectDetails}>
      <PBHeader
        title={
          <IDHFormattedMessage
            id="ws_location_by_city"
            defaultMessage="Location by City"
          />
        }
        icon={<CityIcon />}
        onShowcase={onShowcase}
        onProjectDetails={onProjectDetails}
        tooltip={
          !onShowcase && (
            <Tooltip
              alignRight
              content={
                <IDHFormattedMessage
                  id="ws_tooltip_location_by_city"
                  defaultMessage="Followers location broken by cities, based on analyzing location tags, language and caption of the recent posts and text in profile description (Bio)."
                />
              }
              center
            />
          )
        }
      />
      <LocationByCityList data={audienceCityReach} height={height} />
    </ProfileBox>
  );
}
