import React from "react";
import "./CommentsBox.scss";

import { ReactComponent as EyesIcon } from "src/images/eyes.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import ProfileBox, {
  PBHeader,
} from "src/app/Task/tabs/ProfileDataTab/components/ProfileBox/ProfileBox";
import Pictogram, {
  PictogramVariants,
} from "src/app/components/Pictogram/Pictogram";

export function CommentsBox(props) {
  const { data } = props;

  const { commentList } = data;

  const renderIcon = () => {
    switch (data?.key) {
      case "positive":
        return (
          <Pictogram
            identifier="Sentiment/positive"
            variant={PictogramVariants.ForestCrayola}
          />
        );

      case "neutral":
        return (
          <Pictogram
            identifier="Sentiment/neutral"
            variant={PictogramVariants.PhilippineSilver}
          />
        );

      case "negative":
        return (
          <Pictogram
            identifier="Sentiment/negative"
            variant={PictogramVariants.Vermilion}
          />
        );

      case "productRelated":
        return (
          <Pictogram
            identifier="Sentiment/productMentions"
            variant={PictogramVariants.Instagram}
          />
        );

      default:
        return (
          <Pictogram
            identifier="Sentiment/neutral"
            variant={PictogramVariants.PhilippineSilver}
          />
        );
    }
  };

  const renderTitle = () => {
    switch (data?.key) {
      case "positive":
        return "Positive sentiment";

      case "neutral":
        return "Neutral sentiment";

      case "negative":
        return "Negative sentiment";

      case "productRelated":
        return "Product mentions";

      default:
        return "";
    }
  };

  const renderContent = () => {
    if (!commentList?.length) {
      return (
        <div className="empty-comments-list">
          <EyesIcon />
          <IDHFormattedMessage
            id="ws_no_comments_to_display"
            defaultMessage="No comments to display"
          />
        </div>
      );
    }

    return commentList.map((item) => (
      <div
        key={`${item.commentAuthor}-${item.commentText}`}
        className="comments-box__list-item"
      >
        <span className="comments-box__list-item-author">
          @{item.commentAuthor}
        </span>
        <div className="comments-box__list-item-text">{item.commentText}</div>
      </div>
    ));
  };

  return (
    <ProfileBox className="comments-box" block>
      <PBHeader icon={renderIcon()} title={renderTitle()} onShowcase />

      {renderContent()}
    </ProfileBox>
  );
}
