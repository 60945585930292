import React, { useState } from "react";
import "./LiveSearchBox.scss";

import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { ReactComponent as SocialChartIcon } from "src/images/social-chart.svg";
import { ReactComponent as LikeIcon } from "src/images/like.svg";
import { ReactComponent as CommentIcon } from "src/images/comment.svg";
import { ReactComponent as InstagramIcon } from "src/images/social/instagram-color.svg";
import { formatNumber } from "src/utils/methods";
import { useHistory } from "react-router";

import publicationPlaceholder from "src/images/publication-placeholder2.svg";
import classNames from "classnames";
import CustomImage from "src/app/components/CustomImage/CustomImage";
import { useSelector } from "react-redux";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import { RootState } from "src/redux/reducers";
import { LiveSearchPost } from "../../HashtagLiveSearch";

interface Props {
  data: LiveSearchPost;
}

export const LiveSearchBox: React.FC<Props> = (props) => {
  const { data } = props;
  const [isPlaceholder, setIsPlaceholder] = useState(false);

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const history = useHistory();

  const tooltipContent = (
    <IDHFormattedMessage
      id="ws_full_profile_data"
      defaultMessage="Full Profile Data"
    />
  );

  return (
    <AnimatedDiv className="live-search-box">
      <CustomImage
        className="live-search-box__photo"
        onClick={() => window.open(data.postUrl, "_blank")}
        src={data.postImgUrl}
        fallbackImageSrc={publicationPlaceholder}
        loading="lazy"
        onErrorCallback={() => {
          setIsPlaceholder(true);
        }}
      />

      <a
        href={data.postUrl}
        rel="noopener noreferrer"
        target="_blank"
        className="live-search-box__provider"
      >
        {isPlaceholder && (
          <span>
            <IDHFormattedMessage defaultMessage="View on" id="ws_view_on" />{" "}
            Instagram
          </span>
        )}
        <InstagramIcon
          className={classNames(
            "live-search-box__provider-logo",
            isPlaceholder
              ? "live-search-box__provider-logo--visible"
              : "live-search-box__provider-logo--hidden",
          )}
        />
      </a>

      <div className="live-search-box__data">
        <div className="live-search-box__data-info">
          <span
            className={classNames("live-search-box__data-info-creator", {
              "live-search-box__data-info-creator--clickable": Boolean(
                data.userId,
              ),
            })}
            onClick={() => {
              if (data.userId) {
                history.push(
                  `/workspace/${activeWorkspaceUuid}/hashtag-live-search/profile/instagram/${data.username}`,
                );
              }
            }}
          >
            {/* <CustomImage
              src={data.influencerImgUrl}
              alt="avatar"
              fallbackImageSrc={creatorPlaceholder}
              loading="lazy"
            /> */}
            <span>{data.username}</span>
            {data.userId && (
              <TooltipPortal content={tooltipContent}>
                <SocialChartIcon />
              </TooltipPortal>
            )}
          </span>
          <span className="live-search-box__data-info-social-data">
            <span>
              <LikeIcon />
              {formatNumber(data.likesCount)}
            </span>
            <span>
              <CommentIcon />
              {formatNumber(data.commentsCount)}
            </span>
          </span>
        </div>

        <div className="live-search-box__data-description">{data.caption}</div>
      </div>
    </AnimatedDiv>
  );
};
