import React, { useState } from "react";
import Modal from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { injectIntl } from "react-intl";
import moment from "moment";

function DuplicateModal(props) {
  const { onClose, onConfirmClick, name, intl, confirmButtonLoading } = props;

  const [listName, setListName] = useState(
    `${name}_copy_${moment().format("YYYY.MM.DD_HH:mm")}` || "",
  );

  const onChangeHandler = (e) => {
    const text = e.target.value;
    setListName(text);
  };

  return (
    <Modal
      onClose={onClose}
      title={
        <IDHFormattedMessage
          id="front_modal_duplicate_title"
          defaultMessage="Duplicate audience"
        />
      }
      closeButtonText={
        <IDHFormattedMessage id="front_bd_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={() => onConfirmClick(listName)}
      confirmButtonLoading={confirmButtonLoading}
      confirmButtonDisabled={!listName.length}
      displayCancelButton
      className="modal--duplicate-list"
    >
      <div className="modal__text">
        <IDHFormattedMessage
          id="front_modal_duplicate_text"
          defaultMessage="To duplicate an audience you must enter a name."
        />
      </div>
      <input
        className="idh-form__input"
        placeholder={intl.formatMessage({
          id: "front_type_audience_name",
          defaultMessage: "Type audience name...",
        })}
        value={listName}
        onChange={onChangeHandler}
      />
    </Modal>
  );
}

export default injectIntl(DuplicateModal);
