import React from "react";
import SocialProfileIcons from "../../../../../components/IconsWithStatus/SocialProfileIcons/SocialProfileIcons";

export function TaskItemName(props) {
  const { data } = props;

  return (
    <span className="search-section__item-name">
      <div className="name-section__main">{data.wsTaskName}</div>
      {data.projectTitle && (
        <div className="name-section__description">{data.projectTitle}</div>
      )}
      {data?.socialProfilesData?.length ? (
        <SocialProfileIcons data={data.socialProfilesData} size="small" />
      ) : null}
    </span>
  );
}
