import React, { useReducer, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";

import {
  getMembersList,
  getProjectDetails,
  updateProject,
  updateTasksList,
  getTaskDetails,
  getTasksCounters,
  getSingleTaskForList,
  getSingleTaskForMyTaskList,
  getMyTasks,
  getActivityList,
  getTasksAutocomplete,
  getFilteredProjectsList,
  getDictionaryListElement,
  getDictionaryList,
  getDictionaryDetails,
  getWsContentProposalElementsSummary,
  getProjectsList,
} from "./redux";
import { showToast } from "./app/methods/showToast";
import { sendNotification, setWebSocket } from "./redux/main/mainActions";
import { getDetectedPublications } from "./redux/automation/automationActions";
import { useInterval } from "./app/methods/useInterval";
import IDHFormattedMessage from "./app/components/IDHFormattedMessage/IDHFormattedMessage";
import {
  getCouponGroupDetails,
  getCouponGroups,
} from "./redux/coupon/couponActions";
import { useQuery } from "./app/methods/useQuery";
import { customEqual, isStringUuid, urlIncludes } from "./utils/methods";
import {
  getGlobalTasks,
  getSingleGlobalTaskFromList,
  getGlobalTaskDetails,
} from "./redux/creator-database/creatorDatabaseActions";
import dictionaryFiltersReducer, {
  dictionaryFiltersReducerInitialState,
} from "./app/Dictionary/redux/dictionaryFiltersReducer";
import { RootState } from "./redux/reducers";
import creatorDatabaseFiltersReducer, {
  creatorDatabaseFiltersReducerInitialState,
} from "./app/CreatorDatabase/redux/creatorDatabaseFiltersReducer";

interface WebSocketData {
  actionTaken: string;
  actionToTake: string;
  objectUuid: string;
  wsProjectUuid?: string;
  wsWorkspaceUuid: string;
  messageType?: string;
  messageHeader?: string;
  messageText?: string;
  wsTaskUuids?: string[];
  dispatchType?: string;
  payload?: any;
}

const WEBSOCKETS_ACTIONS_TO_TAKE = {
  ACTION_UPDATE_TASK: "UPDATE_TASK",
  ACTION_UPDATE_TASK_COMMENTS: "UPDATE_TASK_COMMENTS",
  ACTION_UPDATE_CONTENT_PROPOSAL_ELEMENTS_SUMMARY:
    "UPDATE_CONTENT_PROPOSAL_ELEMENTS_SUMMARY",
  ACTION_UPDATE_PROJECTS_LIST: "UPDATE_PROJECTS_LIST",
  ACTION_UPDATE_PROJECT_TASKS_LIST: "UPDATE_PROJECT_TASKS_LIST",
  ACTION_UPDATE_PROJECT_TASKS_LIST_ELEMENT: "UPDATE_PROJECT_TASKS_LIST_ELEMENT",
  ACTION_UPDATE_MY_TASKS_LIST: "UPDATE_MY_TASKS_LIST",
  ACTION_UPDATE_MY_TASKS_LIST_ELEMENT: "UPDATE_MY_TASKS_LIST_ELEMENT",
  ACTION_UPDATE_PROJECT: "UPDATE_PROJECT",
  ACTION_UPDATE_ACTIVITY_LIST: "UPDATE_ACTIVITY_LIST",
  ACTION_UPDATE_WORKSPACE_MEMBERS: "UPDATE_WORKSPACE_MEMBERS",
  ACTION_UPDATE_COUPON_GROUP_LIST: "UPDATE_COUPON_GROUP_LIST",
  ACTION_UPDATE_COUPON_LIST: "UPDATE_COUPON_LIST",
  ACTION_UPDATE_GLOBAL_TASK_LIST: "UPDATE_GLOBAL_TASK_LIST",
  ACTION_UPDATE_GLOBAL_TASK_DETAILS: "UPDATE_GLOBAL_TASK_DETAILS",
  ACTION_UPDATE_GLOBAL_TASK: "UPDATE_GLOBAL_TASK",
  ACTION_SHOW_RELOAD_APP_MODAL: "SHOW_RELOAD_APP_MODAL",
  ACTION_SHOW_NOTIFICATION_TOAST: "SHOW_NOTIFICATION_TOAST",
  ACTION_SHOW_REPORT_IS_READY_MODAL: "SHOW_REPORT_IS_READY_MODAL",
  ACTION_SHOW_PUBLICATION_DETECTION_MODAL: "SHOW_PUBLICATION_DETECTION_MODAL",
  ACTION_UPDATE_DICTIONARY_ELEMENT: "UPDATE_DICTIONARY_ELEMENT",
  ACTION_UPDATE_DICTIONARY_ELEMENTS_LIST: "UPDATE_DICTIONARY_ELEMENTS_LIST",
  ACTION_UPDATE_DICTIONARY_ELEMENT_LIST_ELEMENT:
    "UPDATE_DICTIONARY_ELEMENT_LIST_ELEMENT",
  TRIGGER_DISPATCH_FRONT_ACTION: "TRIGGER_DISPATCH_FRONT_ACTION",
};

const WEBSOCKET_PROCESS_INTERVAL = 3000;

function WebsocketListener() {
  const params = useParams<{
    projectId: string;
    taskId: string;
    couponGroupId: string;
    dictionaryUuid: string;
  }>();
  const dispatch = useDispatch();
  const query = useQuery();
  const { url } = useRouteMatch();
  const location = useLocation();

  const onMyTasks = urlIncludes("/my-actions");
  const onCreatorDatabase = urlIncludes("/creator-database");
  const onDictionary = urlIncludes("/dict");

  const {
    activityReducer: { filters: activityFilters, offset, limit },
    projectFiltersReducer: { filters: projectFilters, sortBy, sort },
    mainReducer: { websocket, identity, activeWorkspaceUuid },
    projectReducer: { taskType, projectsListOffset },
    creatorDatabaseReducer: { globalTaskDetails, globalTaskColumns },
    taskReducer: { tableInputFocused },
  } = useSelector((state: RootState) => state);

  const dictionaryList = useSelector(
    (state: RootState) => state.dictionaryReducer.dictionaryList,
    customEqual,
  );

  const [dictionaryFilters, dispatchDictionaryFilters] = useReducer(
    dictionaryFiltersReducer,
    dictionaryFiltersReducerInitialState(dictionaryList.columns),
  );

  const [creatorDatabaseFilters, dispatchCreatorDatabaseFilters] = useReducer(
    creatorDatabaseFiltersReducer,
    creatorDatabaseFiltersReducerInitialState(globalTaskColumns),
  );

  const {
    filters: filtersCreatorDatabase,
    sortBy: sortByCreatorDatabase,
    sort: sortCreatorDatabase,
  } = creatorDatabaseFilters;

  const [tabFocused, setTabFocused] = useState(true);
  const [webSocketMessageQueue, setWebSocketMessageQueue] = useState<{
    [actionToTake: string]: WebSocketData[];
  }>({});

  useEffect(() => {
    Object.values(WEBSOCKETS_ACTIONS_TO_TAKE).forEach((actionName) => {
      setWebSocketMessageQueue((state) => ({
        ...state,
        [actionName]: [],
      }));
    });
  }, []);

  const isWebSocketActionAlreadyEnqueued = (data: WebSocketData): boolean => {
    for (const enqueuedWs of webSocketMessageQueue[data.actionToTake]) {
      if (
        data.wsWorkspaceUuid === enqueuedWs.wsWorkspaceUuid &&
        data.actionToTake === enqueuedWs.actionToTake &&
        data.objectUuid === enqueuedWs.objectUuid
      ) {
        return true;
      }
    }

    return false;
  };

  const handleWebSocketAction = async (data: WebSocketData): Promise<void> => {
    removeMessageFromQueue(data);

    switch (data.actionToTake) {
      case "UPDATE_TASK":
        if (data.objectUuid === params.taskId) {
          await dispatch(getTaskDetails(data.objectUuid));
        }

        break;
      case "UPDATE_TASK_COMMENTS":
        if (data.objectUuid === params.taskId) {
          await dispatch(getTaskDetails(data.objectUuid));
        }

        break;
      case "UPDATE_CONTENT_PROPOSAL_ELEMENTS_SUMMARY":
        if (data.objectUuid === params.taskId) {
          await dispatch(getWsContentProposalElementsSummary(data.objectUuid));
        }

        break;
      case "UPDATE_PROJECTS_LIST":
        if (data.objectUuid === activeWorkspaceUuid) {
          if (url.endsWith("/projects")) {
            await dispatch(
              getFilteredProjectsList(activeWorkspaceUuid, {
                ...(projectFilters && {
                  filters: JSON.stringify(projectFilters),
                }),
                ...(sortBy && { sortBy }),
                ...(sort && { sort }),
                offset: 0,
                limit: projectsListOffset,
              }),
            );
          } else {
            dispatch(getProjectsList(activeWorkspaceUuid));
          }
        }

        break;
      case "UPDATE_PROJECT_TASKS_LIST":
        if (data.objectUuid === params.projectId) {
          await dispatch(updateTasksList(params.projectId, taskType));
          await dispatch(getTasksCounters(params.projectId));
          await dispatch(getTasksAutocomplete(params.projectId, "content"));
          await dispatch(getTasksAutocomplete(params.projectId, "creator"));
          await dispatch(getTasksAutocomplete(params.projectId, "publication"));
        }

        break;

      case "UPDATE_PROJECT_TASKS_LIST_ELEMENT":
        if (data.wsProjectUuid === params.projectId) {
          await dispatch(getSingleTaskForList(data.objectUuid));
        }

        break;

      case "UPDATE_MY_TASKS_LIST":
        if (onMyTasks) {
          await dispatch(getMyTasks(activeWorkspaceUuid));
        }

        break;

      case "UPDATE_MY_TASKS_LIST_ELEMENT":
        if (data.wsWorkspaceUuid === activeWorkspaceUuid && onMyTasks) {
          await dispatch(getSingleTaskForMyTaskList(data.objectUuid));
        }

        break;

      case "UPDATE_PROJECT":
        if (data.objectUuid === params.projectId) {
          await dispatch(updateProject(params.projectId));

          if (url.includes("/details")) {
            await dispatch(getProjectDetails(params.projectId));
          }
        }

        break;

      case "UPDATE_ACTIVITY_LIST":
        if (window.location.pathname.includes("/activity")) {
          await dispatch(
            getActivityList(activeWorkspaceUuid, activityFilters, 0, limit),
          );
        }

        break;

      case "UPDATE_COUPON_GROUP_LIST":
        await dispatch(getCouponGroups(activeWorkspaceUuid, true));

        break;

      case "UPDATE_COUPON_LIST":
        if (params.couponGroupId) {
          await dispatch(getCouponGroupDetails(params.couponGroupId));
        }

        break;

      case "UPDATE_WORKSPACE_MEMBERS":
        await dispatch(getMembersList(activeWorkspaceUuid));

        break;

      case "SHOW_NOTIFICATION_TOAST":
        if (!data.messageHeader || !data.messageText) {
          break;
        }

        switch (data.messageType) {
          case "error":
          case "success":
          case "info":
          case "warning":
            showToast(
              data.messageType,
              data.messageHeader ? (
                <IDHFormattedMessage
                  id={data.messageHeader}
                  defaultMessage={data.messageHeader}
                />
              ) : (
                ""
              ),
              data.messageText ? (
                <IDHFormattedMessage
                  id={data.messageText}
                  defaultMessage={data.messageText}
                />
              ) : (
                ""
              ),
            );

            break;
          default:
            break;
        }

        break;
      case "SHOW_RELOAD_APP_MODAL":
        await dispatch(sendNotification({ type: "reload" }));

        break;
      case "SHOW_PUBLICATION_DETECTION_MODAL":
        if (data?.wsTaskUuids && data?.wsTaskUuids?.length > 0) {
          await dispatch(getDetectedPublications(data.wsTaskUuids));
        }

        break;

      case "UPDATE_GLOBAL_TASK":
        if (onCreatorDatabase) {
          dispatch(getSingleGlobalTaskFromList(data.objectUuid));
        }
        break;

      case "UPDATE_GLOBAL_TASK_LIST":
        if (onCreatorDatabase) {
          dispatch(
            getGlobalTasks(activeWorkspaceUuid, {
              filters: filtersCreatorDatabase,
              sortBy: sortByCreatorDatabase,
              sort: sortCreatorDatabase,
            }),
          );
        }
        break;

      case "UPDATE_GLOBAL_TASK_DETAILS":
        if (data.objectUuid === globalTaskDetails?.uuid) {
          dispatch(getGlobalTaskDetails(data.objectUuid, true));
        }
        break;
      case "UPDATE_DICTIONARY_ELEMENT":
        const dictionaryElementUuid =
          query.get("displayDictionaryPreview") || "";
        if (isStringUuid(dictionaryElementUuid)) {
          dispatch(
            getDictionaryDetails(params.dictionaryUuid, dictionaryElementUuid),
          );
        }
        break;
      case "UPDATE_DICTIONARY_ELEMENTS_LIST":
        if (onDictionary) {
          dispatch(
            getDictionaryList(data.objectUuid, {
              ...(Object.keys(dictionaryFilters?.filters ?? {}).length > 0 && {
                filters: JSON.stringify(dictionaryFilters.filters),
              }),
              ...(dictionaryFilters?.sortBy && {
                sortBy: dictionaryFilters.sortBy,
              }),
              ...(dictionaryFilters?.sort && { sort: dictionaryFilters.sort }),
            }),
          );
        }
        break;
      case "UPDATE_DICTIONARY_ELEMENT_LIST_ELEMENT":
        if (onDictionary) {
          await dispatch(
            getDictionaryListElement(params.dictionaryUuid, data.objectUuid),
          );
        }
        break;
      case "TRIGGER_DISPATCH_FRONT_ACTION":
        dispatch({
          type: data.dispatchType,
          payload: { reportUrl: data.payload.reportUrl },
        });

        break;
      default:
        break;
    }
  };

  const removeMessageFromQueue = (data: WebSocketData) => {
    setWebSocketMessageQueue((enqueuedWebSockets) => ({
      ...enqueuedWebSockets,
      [data.actionToTake]: enqueuedWebSockets[data.actionToTake].filter(
        (webSocketMessage: WebSocketData) =>
          data.wsWorkspaceUuid !== webSocketMessage.wsWorkspaceUuid &&
          data.actionToTake !== webSocketMessage.actionToTake &&
          data.objectUuid !== webSocketMessage.objectUuid,
      ),
    }));
  };

  const checkTabFocused = () => {
    if (document.visibilityState === "visible") {
      setTabFocused(true);
    } else {
      setTabFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", checkTabFocused);

    return () => {
      document.removeEventListener("visibilitychange", checkTabFocused);
    };
  }, []);

  useEffect(() => {
    connectWebSocket();
  }, [websocket]);

  const connectWebSocket = () => {
    if (websocket) {
      websocket.onopen = () => {
        console.info("WebSocket connected");
        websocket.send(JSON.stringify({ messageType: "ping" }));
      };

      websocket.onclose = () => {
        console.info("WebSocket disconnected");
        reconnectWebSocket();
      };
    }
  };

  const reconnectWebSocket = () => {
    setTimeout(() => {
      dispatch(
        setWebSocket(
          activeWorkspaceUuid,
          identity.id,
          identity.websocketChallenge,
        ),
      );
      connectWebSocket();
    }, 10000);
  };

  useInterval(() => {
    handleEnqueuedWebSockets();
  }, WEBSOCKET_PROCESS_INTERVAL);

  const handleEnqueuedWebSockets = () => {
    if (!tabFocused || tableInputFocused) return;

    Object.keys(webSocketMessageQueue).forEach((taskType) => {
      webSocketMessageQueue[taskType].forEach((data) => {
        setWebSocketMessageQueue((enqueuedWebSockets) => ({
          ...enqueuedWebSockets,
          [data.actionToTake]: enqueuedWebSockets[data.actionToTake].filter(
            (webSocketMessage: WebSocketData) =>
              data.wsWorkspaceUuid !== webSocketMessage.wsWorkspaceUuid &&
              data.actionToTake !== webSocketMessage.actionToTake &&
              data.objectUuid !== webSocketMessage.objectUuid,
          ),
        }));

        handleWebSocketAction(data);
      });
    });
  };

  useEffect(() => {
    if (websocket) {
      websocket.onmessage = (event: MessageEvent): void => {
        const dataObject: WebSocketData = {
          ...JSON.parse(event.data),
        };

        if (!dataObject.wsWorkspaceUuid) return;

        if (!isWebSocketActionAlreadyEnqueued(dataObject)) {
          setWebSocketMessageQueue((enqueuedWebSockets) => ({
            ...enqueuedWebSockets,
            [dataObject.actionToTake]: [
              ...enqueuedWebSockets[dataObject.actionToTake],
              dataObject,
            ],
          }));
        }
      };
    }
  }, [
    websocket,
    taskType,
    activeWorkspaceUuid,
    activityFilters.campaigns,
    activityFilters.members,
    activityFilters.dateRange,
    params.projectId,
    params.taskId,
    params.dictionaryUuid,
    params.couponGroupId,
    webSocketMessageQueue,
    offset,
    limit,
    query,
  ]);

  return null;
}

export default WebsocketListener;
