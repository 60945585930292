import { renderAudienceAge } from "./metrics/renderAudienceAge";
import { renderBio } from "./metrics/renderBio";
import { renderCredibility } from "./metrics/renderCredibility";
import { renderEr } from "./metrics/renderEr";
import { renderFemalePerAge } from "./metrics/renderFemalePerAge";
import { renderFollowers } from "./metrics/renderFollowers";
import { renderGeographicalReach } from "./metrics/renderGeographicalReach";
import { renderLanguages } from "./metrics/renderLanguages";
import { renderLocationByCity } from "./metrics/renderLocationByCity";
import { renderMalePerAge } from "./metrics/renderMalePerAge";
import { renderRecentlyPublished } from "./metrics/renderRecentlyPublished";
import { ShowcaseGridBox } from "./components/ShowcaseGridBox/ShowcaseGridBox";
import { renderCreatorInterests } from "./metrics/renderCreatorInterests";
import { renderCreatorsBrandAffinity } from "./metrics/renderCreatorsBrandAffinity";
import { renderAudienceInterests } from "./metrics/renderAudienceInterests";
import { renderAudienceBrandAffinity } from "./metrics/renderAudienceBrandAffinity";
import { renderKeywords } from "./metrics/renderKeywords";
import { renderHashtags } from "./metrics/renderHashtags";
import { renderCreatorGrowth } from "./metrics/renderCreatorGrowth";
import { SocialProvider } from "../../../../types";
import { renderAvgViews } from "./metrics/renderAvgViews";

export const renderMetrics = (
  metric,
  data,
  layout,
  recentlyPublishedReady,
  setRecentlyPublishedReady,
) => {
  const metricData = data.socialProfilesData[metric.provider];

  if (!metricData) return null;

  const GridBoxComponent = ShowcaseGridBox;

  switch (metric.type) {
    case "audience-age":
      return renderAudienceAge(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "audience-brand-affinity":
      return renderAudienceBrandAffinity(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "audience-interests":
      return renderAudienceInterests(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "bio":
      return renderBio(metric.provider, metricData, layout, GridBoxComponent);

    case "creators-brand-affinity":
      return renderCreatorsBrandAffinity(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "creator-growth":
      return renderCreatorGrowth(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "creator-interests":
      return renderCreatorInterests(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "credibility":
      if (metric.provider !== SocialProvider.Instagram) return null;

      return renderCredibility(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "er":
      return renderEr(metric.provider, metricData, layout, GridBoxComponent);

    case "female-per-age":
      return renderFemalePerAge(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "followers":
      return renderFollowers(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "geographical-reach":
      return renderGeographicalReach(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "hashtags":
      return renderHashtags(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "keywords":
      return renderKeywords(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "languages":
      return renderLanguages(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "location-by-city":
      if (metric.provider !== SocialProvider.Instagram) return null;

      return renderLocationByCity(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "male-per-age":
      return renderMalePerAge(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );

    case "recently-published":
      const { socialUrl } = data.socialProfiles.find(
        (item) => item.socialProvider === metric.provider,
      );

      return renderRecentlyPublished(
        metric.provider,
        metricData,
        socialUrl,
        layout,
        GridBoxComponent,
        recentlyPublishedReady,
        setRecentlyPublishedReady,
      );

    case "avg-views":
      return renderAvgViews(
        metric.provider,
        metricData,
        layout,
        GridBoxComponent,
      );
  }
};
