import React, { useEffect, useState } from "react";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { getProject, getProjectsList } from "src/redux";

import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { Button } from "src/app/components/Button/Button";
import ImageCropModal from "src/app/components/CroppedImageUploader/ImageCropModal/ImageCropModal";
import CustomImage from "src/app/components/CustomImage/CustomImage";
import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalTitle } from "src/app/components/Modal/Modal";
import { showToast } from "src/app/methods/showToast";
import { API_URLS } from "src/utils/API_URLS";
import { uploadProjectCover } from "src/utils/uploadFunctions";
import { useLocation } from "react-router";
import { RootState } from "src/redux/reducers";
import { showErrorToast } from "src/utils/methods";
import ProjectPlaceholder from "../../../../../../images/project-placeholder.svg";

interface Props {
  onClose: () => void;
  contextData: any;
  getData: () => void;
}

export const ProjectGeneral: React.FC<Props> = (props) => {
  const { onClose, contextData, getData } = props;

  const [name, setName] = useState(contextData.name);
  const [showImageCropModal, setShowImageCropModal] = useState(false);
  const [coverUrl, setCoverUrl] = useState(contextData.image);
  const [coverToUpdate, setCoverToUpdate] = useState(null);
  const [nameLoading, setNameLoading] = useState(false);
  const [coverLoading, setCoverLoading] = useState(false);

  const nameChanged = contextData.name && name && contextData.name !== name;

  const location = useLocation();

  const {
    projectReducer: { projectBasicData, projectsListOffset },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProject(contextData.uuid));
  }, []);

  useEffect(() => {
    if (contextData?.name) {
      setName(contextData?.name);
    }
  }, [contextData?.name]);

  const onChangeAvatar = async (file: any) => {
    setCoverToUpdate(file);
    setCoverUrl(file.localSrc);
  };

  const updateCover = async () => {
    setCoverLoading(true);

    return uploadProjectCover(
      coverToUpdate,
      dispatch,
      contextData.uuid,
      contextData.workspaceUuid,
      setCoverLoading,
    );
  };

  const updateName = async () => {
    setNameLoading(true);

    const titleMetaFieldId = projectBasicData?.metadata.find(
      (item) => item.name.toLowerCase() === "Campaign name".toLowerCase(),
    )?.uuid;

    if (typeof titleMetaFieldId !== "string") {
      showErrorToast();
      return false;
    }

    try {
      await axios.put(API_URLS.updateProjectMetaValue, {
        metaFieldUuid: titleMetaFieldId,
        metaValue: name,
        uuids: [contextData.uuid],
      });
      return true;
    } catch (error) {
      console.error(error);
      showErrorToast();
      return false;
    }
  };

  const handleUpdate = async () => {
    let avatarUpdated = false;
    let userNameUpdated = false;

    try {
      if (coverToUpdate) {
        avatarUpdated = await updateCover();
        setCoverToUpdate(null);
      }

      if (nameChanged) {
        userNameUpdated = await updateName();
        setTimeout(() => {
          setNameLoading(false);
        }, 1000);
      }

      if (avatarUpdated || userNameUpdated) {
        const urlParts = location.pathname.split("/");
        const onCampaignList = urlParts[urlParts.length - 1] === "projects";

        if (onCampaignList) {
          dispatch(
            getProjectsList(contextData.workspaceUuid, projectsListOffset, 0),
          );
        }
        await getData();
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id="ws_settings_have_been_saved"
            defaultMessage="Settings have been saved!"
          />,
        );
      }
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  return (
    <>
      <AnimatedDiv className="general">
        <ModalTitle>
          <IDHFormattedMessage id="ws_general" defaultMessage="General" />
        </ModalTitle>

        <h6 className="general__subheader">
          <IDHFormattedMessage
            id="ws_campaign_cover"
            defaultMessage="Campaign cover"
          />
        </h6>

        <div className="general__cover">
          <CustomImage src={coverUrl || ProjectPlaceholder} alt="avatar" />

          <span
            className="general__cover-button"
            onClick={() => setShowImageCropModal(true)}
          >
            <IDHFormattedMessage
              id="ws_change_image"
              defaultMessage="Change image"
            />
          </span>
        </div>

        <h6 className="general__subheader">
          <IDHFormattedMessage
            id="ws_campaign_info"
            defaultMessage="Campaign info"
          />
        </h6>

        <CustomInputLabel htmlFor="name">
          <IDHFormattedMessage id="ws_name" defaultMessage="Name" />
        </CustomInputLabel>

        <CustomInput
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        {showImageCropModal && (
          <ImageCropModal
            uploadFunction={onChangeAvatar}
            onClose={() => setShowImageCropModal(false)}
            circle
          />
        )}
      </AnimatedDiv>

      <SettingsButtons onClose={onClose}>
        <Button
          size="large"
          variant="blue"
          onClick={handleUpdate}
          disabled={
            !name?.length ||
            (!coverToUpdate && !nameChanged) ||
            coverLoading ||
            nameLoading
          }
        >
          <IDHFormattedMessage id="ws_update" defaultMessage="Update" />
        </Button>
      </SettingsButtons>
    </>
  );
};
