import { setPublicationsMetadata } from "src/redux";
import { stripEmojis } from "../../../../utils/methods";
import { sortTasksByColumn } from "../sortTasksByColumn";
import { addHeaderToArray } from "../addHeaderToArray";

export const REPORT_ADDITIONAL_PUBLICATION_METAFIELDS = [
  "currency",
  "number",
  "percent",
];

export const addPublicationsToGridElements = (
  publicationList,
  dispatch,
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  creatorsList,
  membersList,
  contentList,
  formatMessage,
) => {
  addHeaderToArray(
    formatMessage({
      id: "ws_all_publications",
      defaultMessage: "All Publications",
    }),
    newGridElements,
    "subheader",
    `publication`,
    savedTemplate,
    templateApplied,
  );

  dispatch(
    setPublicationsMetadata(
      publicationList[0]?.metadata
        .filter((item) => {
          const containsPublicationVS =
            item.fieldValueSource?.includes("publication.");

          return (
            !containsPublicationVS &&
            REPORT_ADDITIONAL_PUBLICATION_METAFIELDS.includes(item.type)
          );
        })
        .map((item) => ({
          ...item,
          reportVisible: false,
        })),
    ),
  );

  const settings = JSON.parse(localStorage.getItem("ws-project-settings"));
  const projectId = window.location.pathname.split("/")[4];
  const projectSettings = settings?.find(
    (item) => item.projectId === projectId,
  );
  const { activeSortingColumn, sortingDirection } =
    projectSettings?.publication || {};

  publicationList
    .sort((a, b) =>
      sortTasksByColumn(
        a,
        b,
        activeSortingColumn,
        sortingDirection,
        creatorsList,
        membersList,
        contentList,
      ),
    )
    .forEach((item) => {
      const label = `publication-${stripEmojis(item.title)
        .slice(0, 15)
        .replace(/(\r\n|\n|\r)/gm, "")
        .replaceAll(" ", "-")
        .replaceAll(".", "")
        .replaceAll("/", "")
        .replaceAll("?", "")
        .toLowerCase()}-${item.taskId}`;

      const element = {
        ...item,
        label,
        gridBoxType: "publication",
        section: "all-publications",
      };

      if (savedTemplate?.length && !templateApplied) {
        if (savedTemplate.find((item) => item.i === element.label)) {
          newGridElements.push(element);
        } else {
          newHiddenGridElements.push(element);
        }
      }
      // applying default template
      else {
        newGridElements.push(element);
      }
    });
};
