import React from "react";
import "./StatisticList.scss";

import { StatisticListItem } from "../StatisticListItem/StatisticListItem";

function StatisticList(props) {
  const { statistics, platform, platformDisplayName } = props;

  return (
    <div className="task-statistics-container">
      {statistics &&
        statistics.map((item) => (
          <StatisticListItem
            item={item}
            platform={platform}
            platformDisplayName={platformDisplayName}
            key={item.statistic}
          />
        ))}
    </div>
  );
}

export { StatisticList };
