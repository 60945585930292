import React, { FormEvent, useState } from "react";
import "./OnboardingPage.scss";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { identify } from "src/redux/main/mainActions";
import { RootState } from "src/redux/reducers";
import { API_URLS } from "../../utils/API_URLS";
import { showToast } from "../methods/showToast";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { EventHelper } from "../../helpers/EventHelper";
import { CreateProfile } from "./CreateProfile/CreateProfile";
import { SelectLanguage } from "./SelectLanguage/SelectLanguage";
import { AppConfig } from "../../config/AppConfig";

export type OnboardingStep = "language" | "profile";

function OnboardingPage() {
  const {
    mainReducer: { supportedLanguages },
  } = useSelector((state: RootState) => state);

  const [name, setName] = useState("");
  const [currentStep, setCurrentStep] = useState<OnboardingStep>(
    supportedLanguages.length > 1 ? "language" : "profile",
  );

  const onboardingPageLogo = AppConfig.getAssetForHostname("onboarding_page/onboarding-logo.svg");

  const dispatch = useDispatch();

  const handleSubmit = (e: FormEvent) => {
    if (currentStep === "language") {
      setCurrentStep("profile");
    } else {
      handleCreateProfile(e);
    }
  };

  const handleCreateProfile = async (e: FormEvent) => {
    e.preventDefault();

    try {
      await axios.post(API_URLS.createProfile, {
        wsMemberName: name,
      });
      dispatch(identify());
    } catch (err: any) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        err.response.data.errors[0],
      );
    }

    EventHelper.emitEvent("ws:created.member-profile");
  };

  const renderStep = () => {
    switch (currentStep) {
      case "language":
        return <SelectLanguage setCurrentStep={setCurrentStep} />;
      case "profile":
        return (
          <CreateProfile
            name={name}
            setName={setName}
            setCurrentStep={setCurrentStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="onboarding-page">
      <form className="onboarding-page__container" onSubmit={handleSubmit}>
        <img src={onboardingPageLogo} className="onboarding-page__logo" style={{width: "126px"}} alt="logo"/>
        <img src={onboardingPageLogo} className="onboarding-page__logo-centered" style={{width: "126px"}} alt="logo"/>
        {renderStep()}
      </form>
    </div>
  );
}

export default OnboardingPage;
