import React from "react";

interface Props {
  href: string;
  className?: string;
  children: React.ReactNode;
}

const OuterLink: React.FC<Props> = (props) => {
  const { href, className, children } = props;
  return (
    <a
      className={className}
      href={href}
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      {children}
    </a>
  );
};

export default OuterLink;
