import { stripEmojis } from "../../../utils/methods";
import { addSpaceIfNeeded } from "./addSpaceIfNeeded";

export const addPublicationToLayout = (publication, newLayout, format) => {
  const lastEl = addSpaceIfNeeded("publication", newLayout, format);

  let x = 0;
  let y = 0;
  const w = 4;
  const h = 7;

  if (lastEl.w === 12 || lastEl.x === 8) {
    x = 0;
    y = lastEl.y + lastEl.h;
  }
  // second in row and half the width
  else if (lastEl.x === 0 || lastEl.x === 4) {
    x = lastEl.x + 4;
    y = lastEl.y;
  }
  const key = `publication-${stripEmojis(publication.title)
    .slice(0, 15)
    .replace(/(\r\n|\n|\r)/gm, "")
    .replaceAll(" ", "-")
    .replaceAll(".", "")
    .replaceAll("/", "")
    .replaceAll("?", "")
    .toLowerCase()}-${publication.taskId}`;
  newLayout.push({ i: key, x, y, w, h });
};
