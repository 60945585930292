import { ContentProposalElement } from "./types";

export function removeOrAddContentElementToArray(
  elements: ContentProposalElement[],
  element: ContentProposalElement,
) {
  const result: ContentProposalElement[] = [...elements];

  const foundIndex = elements.findIndex((item) => item.uuid === element.uuid);
  if (foundIndex === -1) {
    result.push(element);
  } else {
    result.splice(foundIndex, 1);
  }

  return result;
}
