import React from "react";
import Modal from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import "./CompetitorsPostDetectedModal.scss";
import moment from "moment";
import OuterLink from "../../components/OuterLink/OuterLink";

function CompetitorsPostDetectedModal(props) {
  const { onClose, onConfirmClick, confirmButtonLoading, keywords, username } =
    props;

  const keywordNames = Object.keys(keywords);

  return (
    <Modal
      onClose={onClose}
      title={
        <IDHFormattedMessage
          id="front_modal_competitors_post_detected"
          defaultMessage="Competitor’s Post detected"
        />
      }
      confirmButtonLoading={confirmButtonLoading}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_close" defaultMessage="Close" />
      }
      onConfirmClick={onConfirmClick}
      className="cpd-modal"
    >
      <div className="cpd-modal__username">{username}</div>
      <div className="cpd-modal__list">
        {keywordNames.map((name, index) => (
          <div key={`keyword${index}`}>
            <div className="cpd-modal__keywords">
              <div className="cpd-modal__keyword">
                <IDHFormattedMessage
                  id="front_modal_competitors_post_detected_keyword"
                  defaultMessage="Keyword"
                />
                : {name}
              </div>
              <div className="cpd-modal__counter">{keywords[name].length}</div>
            </div>
            {keywords[name].map((post, index) => (
              <div className="cpd-modal__links" key={`post${index}`}>
                <div className="cpd-modal__link-row">
                  <OuterLink href={post.url} className="cpd-modal__link">
                    {post.url}
                  </OuterLink>
                  <div className="cpd-modal__date">
                    {moment(post.created).format("DD.MM.YYYY")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default CompetitorsPostDetectedModal;
