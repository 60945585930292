import React from "react";
import "./SelectedLabels.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SingleSelectedLabel } from "./components/SingleSelectedLabel/SingleSelectedLabel";

export function SelectedLabels({
  values,
  unsetValue,
  clearValues,
  translative,
  getFullObject,
  disabled,
}) {
  return (
    <div className="select-tags__selected-labels-wrap">
      <div className="select-tags__selected-labels">
        {values.map((v) => (
          <SingleSelectedLabel
            value={v}
            onRemove={() => unsetValue(v)}
            translative={translative}
            getFullObject={getFullObject}
            disabled={disabled}
          />
        ))}
      </div>
      <div
        className="select-tags__selected-labels__clear"
        onClick={clearValues}
      >
        <IDHFormattedMessage id="front_clear" defaultMessage="Clear" />
      </div>
    </div>
  );
}
