import React from "react";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalText } from "src/app/components/Modal/Modal";

export function StepThree() {
  return (
    <AnimatedDiv>
      <span className="how-it-works__step-title">
        <IDHFormattedMessage
          id="ws_add_fields_to_track_sales_results"
          defaultMessage="Add fields to track sales results"
        />
      </span>
      <ModalText>
        <IDHFormattedMessage
          id="ws_how_it_works_step_3"
          defaultMessage="Add a field that allows assigning a promotional coupon and fields for sales metrics that you would like to track in the specific campaign. Then, assign appropriate codes to selected records in the table to track campaign results accordingly."
        />
      </ModalText>
      <video autoPlay loop>
        <source src={require("../../../videos/step-3.mp4")} type="video/mp4" />
      </video>
    </AnimatedDiv>
  );
}
