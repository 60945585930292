import React from "react";
import "./RolesInfoModal.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalTitle } from "../../../components/Modal/Modal";

function RolesInfoModal({ onClose, openShareModal }) {
  const closeModal = () => {
    onClose();
    openShareModal();
  };

  return (
    <Modal
      className="roles-info-modal"
      onClose={closeModal}
      displayCancelButton
    >
      <ModalTitle backBtnFunc={closeModal}>
        <IDHFormattedMessage id="ws_roles" defaultMessage="Roles" />
      </ModalTitle>
      <div className="roles-info-modal__permissions">
        <div className="roles-info-modal__column">
          <div className="roles-info-modal__role roles-info-modal__role--admin">
            <IDHFormattedMessage id="ws_admin" defaultMessage="Admin" />
          </div>

          <div className="roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_roles_modal_permission_1"
              defaultMessage="Can add and remove Creators/Content/Publications/Actions"
            />
          </div>
          <div className="roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_roles_modal_permission_4"
              defaultMessage="Can see and modify all data sets"
            />
          </div>
          <div className="roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_roles_modal_permission_7"
              defaultMessage="Can share data sets to Clients"
            />
          </div>
          <div className="roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_roles_modal_permission_9"
              defaultMessage="Can invite Members and Clients"
            />
          </div>
          <div className="roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_roles_modal_permission_10"
              defaultMessage="Can assign other campaign Admins"
            />
          </div>
        </div>
        <div className="roles-info-modal__column">
          <div className="roles-info-modal__role roles-info-modal__role--member">
            <IDHFormattedMessage id="ws_member" defaultMessage="Member" />
          </div>

          <div className="roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_roles_modal_permission_2"
              defaultMessage="Can add and remove Creators/Content/Publications/Actions"
            />
          </div>
          <div className="roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_roles_modal_permission_5"
              defaultMessage="Can see and modify all data sets"
            />
          </div>
          <div className="roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_roles_modal_permission_8"
              defaultMessage="Can preview shared data sets with Clients"
            />
          </div>
        </div>
        <div className="roles-info-modal__column">
          <div className="roles-info-modal__role roles-info-modal__role--client">
            <IDHFormattedMessage id="ws_client" defaultMessage="Client" />
          </div>

          <div className="roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_roles_modal_permission_3"
              defaultMessage="Can see only shared Creators/Content/Publications/Actions"
            />
          </div>
          <div className="roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_roles_modal_permission_6"
              defaultMessage="Can see and modify only shared data sets"
            />
          </div>
          <div className="roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_roles_modal_permission_11"
              defaultMessage="Can export campaign data to XLS"
            />
          </div>
          <div className="roles-info-modal__permission">
            <IDHFormattedMessage
              id="ws_roles_modal_permission_12"
              defaultMessage="Can generate campaign PDF reports"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export { RolesInfoModal };
