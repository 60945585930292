import CONSTS from "./consts";

const {
  instagram,
  instagramStories,
  tiktok,
  universal,
  // facebook,
  // snapchat,
  // recruitment,
} = CONSTS.socialProvider;

export const postType = {
  awaiting: "awaiting",
  accepted: "accepted",
  revised: "revised",
  published: "published",
  rejected: "rejected",
};

export const postColumnNames = {
  preview: "preview",
  name: "name",
  avgRealReach: "avgRealReach",
  estimatedViewsNumber: "estimatedViewsNumber",
  estimatedInteractionsNumber: "estimatedInteractionsNumber",
  views: "views",
  realReach: "realReach",
  interactions: "interactions",
  metrics: "metrics",
  likes: "likes",
  comments: "comments",
  saves: "saves",
  ervr: "ervr",
  ereer: "ereer",
  followers: "followers",
  impressions: "impressions",
  vr: "vr",
  er: "er",
  videoViews: "videoViews",
  caption: "caption",
  submissionDate: "submissionDate",
  scheduledPublicationDate: "scheduledPublicationDate",
  publicationDate: "publicationDate",
  postVisibility: "postVisibility",
  remarks: "remarks",
  action: "action",
  status: "status",
  actionRemarks: "actionRemarks",
  actionUndo: "actionUndo",
  actionNotes: "actionNotes",
};

const {
  preview,
  name,
  avgRealReach,
  estimatedViewsNumber,
  estimatedInteractionsNumber,
  views,
  realReach,
  interactions,
  metrics,
  likes,
  comments,
  saves,
  ervr,
  ereer,
  followers,
  impressions,
  vr,
  er,
  videoViews,
  caption,
  submissionDate,
  scheduledPublicationDate,
  publicationDate,
  postVisibility,
  remarks,
  action,
  status,
  actionRemarks,
  actionUndo,
  actionNotes,
} = postColumnNames;

export const postColumns = {
  [instagram]: {
    [postType.awaiting]: {
      noApiData: [
        preview,
        name,
        estimatedInteractionsNumber,
        estimatedViewsNumber,
        caption,
        submissionDate,
        scheduledPublicationDate,
        postVisibility,
        action,
      ],
      apiData: [
        preview,
        name,
        estimatedInteractionsNumber,
        estimatedViewsNumber,
        avgRealReach,
        caption,
        submissionDate,
        scheduledPublicationDate,
        postVisibility,
        action,
      ],
    },
    [postType.accepted]: {
      noApiData: [
        preview,
        name,
        estimatedInteractionsNumber,
        estimatedViewsNumber,
        caption,
        submissionDate,
        postVisibility,
        status,
      ],
      apiData: [
        preview,
        name,
        estimatedInteractionsNumber,
        estimatedViewsNumber,
        avgRealReach,
        caption,
        submissionDate,
        postVisibility,
        status,
      ],
    },
    [postType.revised]: {
      noApiData: [
        preview,
        name,
        estimatedInteractionsNumber,
        estimatedViewsNumber,
        caption,
        submissionDate,
        postVisibility,
        actionRemarks,
      ],
      apiData: [
        preview,
        name,
        estimatedInteractionsNumber,
        estimatedViewsNumber,
        caption,
        submissionDate,
        postVisibility,
        actionRemarks,
      ],
    },
    [postType.published]: {
      noApiData: [
        preview,
        name,
        followers,
        {
          [interactions]: {
            [likes]: true,
            [comments]: true,
          },
        },
        er,
        caption,
        publicationDate,
        actionNotes,
      ],
      apiData: [
        preview,
        name,
        realReach,
        impressions,
        {
          [interactions]: {
            [likes]: true,
            [comments]: true,
            [saves]: true,
          },
        },
        er,
        caption,
        publicationDate,
        actionNotes,
      ],
    },
    [postType.rejected]: {
      noApiData: [
        preview,
        name,
        estimatedInteractionsNumber,
        estimatedViewsNumber,
        caption,
        submissionDate,
        postVisibility,
        remarks,
        actionUndo,
      ],
      apiData: [
        preview,
        name,
        estimatedInteractionsNumber,
        estimatedViewsNumber,
        caption,
        submissionDate,
        postVisibility,
        remarks,
        actionUndo,
      ],
    },
  },
  [instagramStories]: {
    [postType.awaiting]: {
      noApiData: [
        preview,
        name,
        estimatedViewsNumber,
        submissionDate,
        scheduledPublicationDate,
        postVisibility,
        action,
      ],
      apiData: [
        preview,
        name,
        avgRealReach,
        submissionDate,
        scheduledPublicationDate,
        postVisibility,
        action,
      ],
    },
    [postType.accepted]: {
      noApiData: [
        preview,
        name,
        estimatedViewsNumber,
        submissionDate,
        postVisibility,
        status,
      ],
      apiData: [
        preview,
        name,
        avgRealReach,
        submissionDate,
        postVisibility,
        status,
      ],
    },
    [postType.revised]: {
      noApiData: [
        preview,
        name,
        estimatedViewsNumber,
        submissionDate,
        postVisibility,
        actionRemarks,
      ],
      apiData: [preview, name, submissionDate, postVisibility, actionRemarks],
    },
    [postType.published]: {
      noApiData: [preview, name, views, publicationDate, actionNotes],
      apiData: [
        preview,
        name,
        followers,
        realReach,
        impressions,
        vr,
        publicationDate,
        actionNotes,
      ],
    },
    [postType.rejected]: {
      noApiData: [
        preview,
        name,
        estimatedViewsNumber,
        submissionDate,
        postVisibility,
        remarks,
        actionUndo,
      ],
      apiData: [
        preview,
        name,
        caption,
        submissionDate,
        remarks,
        postVisibility,
        actionUndo,
      ],
    },
  },
  [tiktok]: {
    [postType.awaiting]: [
      preview,
      name,
      {
        [interactions]: {
          [likes]: true,
        },
      },
      caption,
      submissionDate,
      scheduledPublicationDate,
      postVisibility,
      action,
    ],
    [postType.accepted]: [
      preview,
      name,
      caption,
      submissionDate,
      postVisibility,
      status,
    ],
    [postType.revised]: [
      preview,
      name,
      caption,
      submissionDate,
      postVisibility,
      actionRemarks,
    ],
    [postType.published]: [
      preview,
      name,
      {
        [interactions]: {
          [likes]: true,
          [comments]: true,
        },
      },
      ervr,
      videoViews,
      caption,
      publicationDate,
      actionNotes,
    ],
    [postType.rejected]: [
      preview,
      name,
      caption,
      submissionDate,
      postVisibility,
      remarks,
      actionUndo,
    ],
  },
  [universal]: {
    [postType.awaiting]: [
      preview,
      name,
      caption,
      submissionDate,
      postVisibility,
      action,
    ],
    [postType.accepted]: [
      preview,
      name,
      caption,
      metrics,
      submissionDate,
      postVisibility,
      status,
    ],
    [postType.revised]: [
      preview,
      name,
      caption,
      metrics,
      submissionDate,
      postVisibility,
      actionRemarks,
    ],
    [postType.rejected]: [
      preview,
      name,
      caption,
      metrics,
      submissionDate,
      postVisibility,
      remarks,
      actionUndo,
    ],
  },
  cpcon: {
    [postType.awaiting]: [
      preview,
      name,
      submissionDate,
      postVisibility,
      action,
    ],
    [postType.accepted]: [
      preview,
      name,
      submissionDate,
      postVisibility,
      actionNotes,
    ],
    [postType.revised]: [
      preview,
      name,
      submissionDate,
      postVisibility,
      actionRemarks,
    ],
    [postType.rejected]: [
      preview,
      name,
      submissionDate,
      postVisibility,
      remarks,
      actionUndo,
    ],
  },
};
