import React, { useState } from "react";

import Modal from "src/app/components/Modal/Modal";
import "./HowItWorksModal.scss";
import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import { Button } from "src/app/components/Button/Button";
import { ReactComponent as LeftArrowIcon } from "src/images/arrow-left-white.svg";
import { ReactComponent as RightArrowIcon } from "src/images/arrow-right-white.svg";
import step1_1x from "src/images/step1@1x.png";
import step1_2x from "src/images/step1@2x.png";
import step2_1x from "src/images/step2@1x.png";
import step2_2x from "src/images/step2@2x.png";
import step3_1x from "src/images/step3@1x.png";
import step3_2x from "src/images/step3@2x.png";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

const stepsContent = [
  {
    image1x: step1_1x,
    image2x: step1_2x,
    title: (
      <IDHFormattedMessage
        id="ws_customize_the_form_to_your_needs"
        defaultMessage="Customize the form to your needs"
      />
    ),
    description: (
      <IDHFormattedMessage
        id="ws_customize_the_form_to_your_needs_description"
        defaultMessage={`Select the fields from the campaign table that you want the applying creators to fill out. If the form will be shared through a link, fill out the "Title" and "Description" fields in the "General" tab to provide context for the shared fields in the form.`}
      />
    ),
  },
  {
    image1x: step2_1x,
    image2x: step2_2x,
    title: (
      <IDHFormattedMessage
        id="ws_share_the_form"
        defaultMessage="Share the form"
      />
    ),
    description: (
      <IDHFormattedMessage
        id="ws_share_the_form_description"
        defaultMessage="After creating the form, share it in the places where you want to collect submissions. You can do this by pasting the form link, for example, on your social media profile. You can also embed the form on a website."
      />
    ),
  },
  {
    image1x: step3_1x,
    image2x: step3_2x,
    title: (
      <IDHFormattedMessage
        id="ws_wait_for_campaign_recruitments"
        defaultMessage="Wait for campaign registrations"
      />
    ),
    description: (
      <IDHFormattedMessage
        id="ws_wait_for_campaign_recruitments_description"
        defaultMessage={`After creating the form and sharing it, all that's left is to wait for campaign recruitments. Once that happens, you will receive a notification via email and see a notification in the Activity panel. The list of creators who applied by filling out the form will be visible in the "Creators" table.`}
      />
    ),
  },
];

interface HowItWorksModalProps {
  showTransition?: boolean;
  onClose: () => void;
}

export function HowItWorksModal({
  showTransition,
  onClose,
}: HowItWorksModalProps) {
  const [step, setStep] = useState(0);

  return (
    <Modal className="how-it-works-modal" onClose={onClose}>
      <div className="how-it-works-modal__header">
        <div className="how-it-works-modal__title">
          <IDHFormattedMessage
            id="ws_campaign_recruitment_form_how_it_works"
            defaultMessage="Campaign Recruitment Form - How it works"
          />
        </div>
        <div className="how-it-works-modal__step-indicator">
          <span>{step + 1}</span>
          <span>/</span>
          <span>3</span>
        </div>
      </div>
      <div className="how-it-works-modal__content">
        <img
          src={stepsContent[step].image1x}
          srcSet={`${stepsContent[step].image1x} 1x, ${stepsContent[step].image2x} 2x`}
          alt="Campaign Recruitment Form - How it works"
        />
        <div className="how-it-works-modal__content-title">
          {stepsContent[step].title}
        </div>
        <div className="how-it-works-modal__content-description">
          {stepsContent[step].description}
        </div>
      </div>
      <SettingsButtons onClose={onClose}>
        <div className="how-it-works-modal__controls">
          <Button
            variant="blue"
            size="large"
            className="how-it-works-modal__btn"
            disabled={step === 0}
            onClick={() => setStep((prev) => prev - 1)}
          >
            <LeftArrowIcon />
          </Button>
          <Button
            variant="blue"
            size="large"
            className="how-it-works-modal__btn"
            disabled={step === 2}
            onClick={() => setStep((prev) => prev + 1)}
          >
            <RightArrowIcon />
          </Button>
        </div>
      </SettingsButtons>
    </Modal>
  );
}
