import React, { useEffect, useState } from "react";

import axios from "axios";
import { useSelector } from "react-redux";

import { ProfileTab } from "src/app/components/GlobalTaskDetails/tabs/GlobalTaskSocialData/GlobalTaskSocialData";
import { SocialData } from "src/app/components/SocialData/SocialData";
import { SocialProfile } from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import { SOCIAL_ICONS, SocialProvider } from "src/types";
import { API_URLS } from "src/utils/API_URLS";
import { capitalizeFirstLetter } from "src/utils/methods";
import { RootState } from "src/redux/reducers";
import { useIntl } from "react-intl";
import { translateMessage } from "../../../methods/translateMessage";

interface TaskSocialDataProps {
  hasAnySocialProfile: boolean;
  socialProfiles: SocialProfile[];
  data: { uuid: string; title: string };
}

export function TaskSocialData({
  hasAnySocialProfile,
  socialProfiles,
  data,
}: TaskSocialDataProps) {
  const [profileData, setProfileData] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState<ProfileTab>({
    value: hasAnySocialProfile
      ? socialProfiles[0].socialProvider
      : SocialProvider.Instagram,
    label: hasAnySocialProfile
      ? capitalizeFirstLetter(socialProfiles[0].socialProvider)
      : SocialProvider.Instagram,
  });
  const [profileNotFound, setProfileNotFound] = useState(false);
  const [providers, setProviders] = useState<ProfileTab[]>([]);
  const intl = useIntl();

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const getProfileData = async (provider: ProfileTab) => {
    setProfileNotFound(false);
    try {
      const profile = socialProfiles.find(
        (item) => item.socialProvider === provider.value,
      ) as SocialProfile;

      const url = API_URLS.getProfileData
        .replace(":workspaceUuid:", activeWorkspaceUuid)
        .replace(":socialProvider:", provider.value)
        .replace(
          ":socialUsername:",
          provider.value === "custom"
            ? encodeURIComponent(profile.socialUsername)
            : profile.socialUsername,
        );

      const data = await axios.get(url);

      setProfileData(data.data.content);
    } catch (err) {
      setProfileNotFound(true);
    }
  };

  useEffect(() => {
    if (hasAnySocialProfile) {
      getProfileData(selectedProvider);
      setProfileData(null);
    }
  }, [hasAnySocialProfile, selectedProvider]);

  useEffect(() => {
    const newProviders: SocialProfile[] = [];
    Object.keys(SOCIAL_ICONS).forEach((icon) => {
      const provider = socialProfiles.find(
        (item) => item.socialProvider === icon,
      );
      if (provider) newProviders.push(provider);
    });

    const newTabs =
      newProviders.map((profile, index: number) => ({
        value: profile.socialProvider,
        label: (
          <span className="social-data__tab">
            <img src={SOCIAL_ICONS[profile.socialProvider]} />
            {translateMessage({
              intl,
              id: profile.socialProvider?.toLowerCase(),
              defaultMessage: profile.socialProvider,
            })}
          </span>
        ),
        index,
      })) || [];

    setProviders(newTabs);

    if (
      socialProfiles.length &&
      !socialProfiles.find(
        (social) => social.socialProvider === selectedProvider?.value,
      )
    ) {
      setSelectedProvider(newTabs[0]);
    }
  }, [socialProfiles]);

  return (
    <SocialData
      providers={providers}
      socialProfiles={socialProfiles}
      profileNotFound={profileNotFound}
      selectedProvider={selectedProvider}
      data={data}
      profileData={profileData}
      setSelectedProvider={setSelectedProvider}
    />
  );
}
