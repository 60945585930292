import React, { PropsWithChildren } from "react";
import classNames from "classnames";

export type SocialProfileIconSizes = "small" | "default" | "big";

interface SocialProfileIconsWrapperProps {
  size?: SocialProfileIconSizes;
}

export default function SocialProfileIconsWrapper({
  size,
  children,
}: PropsWithChildren<SocialProfileIconsWrapperProps>) {
  return (
    <div
      className={classNames("social-profile-icons", {
        "social-profile-icons--small": size === "small",
        "social-profile-icons--big": size === "big",
      })}
    >
      {children}
    </div>
  );
}
