import React from "react";
import "./ContentDataTab.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { injectIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { TextBoxWrapper } from "../../../components/TextBoxWrapper/TextBoxWrapper";
import { CustomTextareaLabel } from "../../../components/CustomTextarea/CustomTextarea";

function ContentDataTab(props) {
  const { description, setDescription, onDescriptionChange, intl } = props;

  return (
    <div className="content-data-tab">
      <CustomTextareaLabel
        htmlFor="task-brief"
        text={<IDHFormattedMessage id="ws_brief" defaultMessage="Brief" />}
      />

      <TextBoxWrapper
        id="task-brief"
        className="brief-text-box"
        value={description || ""}
        onChange={setDescription}
        placeholder={translateMessage({
          intl,
          id: "ws_content_data_tab_placeholder",
          defaultMessage:
            "Here you can add content guidelines e.g.:&#10;&#8226;&nbsp;&nbsp;Content Concept&#10;&#8226;&nbsp;&nbsp;Talking Points&#10;&#8226;&nbsp;&nbsp;Photo Guidelines&#10;&#8226;&nbsp;&nbsp;Caption Guidelines&#10;&#8226;&nbsp;&nbsp;Post Instruction&#10;&#8226;&nbsp;&nbsp;Content Samples",
        })}
        onBlur={(newValue) => onDescriptionChange(newValue, description || "")}
        enableEmoji
      />
    </div>
  );
}

export default injectIntl(ContentDataTab);
