import React, { useEffect, useMemo, useState } from "react";
import "./DictionaryDetails.scss";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { SidebarItemType } from "src/app/Sidebar/SidebarItems";
import { AddedBy } from "src/app/Task/components/AddedBy/AddedBy";
import { convertToLocalTimezone } from "src/app/methods/convertToLocalTimezone";
import { useQuery } from "src/app/methods/useQuery";
import { useShowPane } from "src/hooks/useShowPane";
import { ReactComponent as CloseIcon } from "src/images/cross-alt.svg";
import { clearDictionaryDetails, getDictionaryDetails } from "src/redux";
import { RootState } from "src/redux/reducers";
import { Breadcrumbs, BreadcrumbsItem } from "../Breadcrumbs/Breadcrumbs";
import { Pane } from "../Pane/Pane";
import PreviewInfo from "../Preview/components/PreviewInfo";
import { PreviewContext } from "../Preview/utils";
import DictionaryMetadata from "./components/DictionaryMetadata/DictionaryMetadata";
import { closeDictionaryDetails } from "./methods";

export default function DictionaryDetails() {
  const {
    mainReducer: { activeWorkspaceUuid, identity },
    dictionaryReducer: { dictionaryDetails },
    projectReducer: { membersList },
  } = useSelector((state: RootState) => state);

  const { dictionaryUuid } = useParams<{ dictionaryUuid: string }>();
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const dictionaryElementUuid = query.get("displayDictionaryPreview") || "";

  const [showPane, hidePane] = useShowPane(() => {
    closeDictionaryDetails(history);
  });

  const [dateCreated, setDateCreated] = useState<moment.Moment | null>(null);

  const taskOwner = useMemo(() => {
    return membersList?.find(
      (member) => member.id === dictionaryDetails?.ownerWsMemberUuid,
    );
  }, [dictionaryDetails?.ownerWsMemberUuid, membersList]);

  const foundDictionarySidebarItem = identity?.sidebarItems?.find(
    (item: { type: SidebarItemType; wsDictionaryUuid: string }) =>
      item.type === SidebarItemType.Dictionary &&
      item.wsDictionaryUuid === dictionaryDetails.wsDictionaryUuid,
  );

  const header = foundDictionarySidebarItem?.wsDictionaryName;

  useEffect(() => {
    dispatch(getDictionaryDetails(dictionaryUuid, dictionaryElementUuid));
    return () => {
      dispatch(clearDictionaryDetails());
    };
  }, [dictionaryUuid, dictionaryElementUuid]);

  useEffect(() => {
    if (dictionaryDetails?.created) {
      const dateValue = dictionaryDetails.created.date;
      if (dateValue !== null) {
        setDateCreated(convertToLocalTimezone(dateValue));
      }
    }
  }, [dictionaryDetails?.created]);

  return (
    <Pane
      className="dictionary-details"
      showPane={showPane}
      hidePane={hidePane}
    >
      {/* TODO FRONT: mamy juz enumy i klasy do linkow to mozna korzystac :) https://indahash.slack.com/archives/G4R8J0ZFU/p1695193798225969 */}
      <div className="dictionary-details__topbar">
        <Breadcrumbs>
          <BreadcrumbsItem
            text={header}
            to={`/workspace/${activeWorkspaceUuid}/dict/${header?.toLowerCase()}/${
              dictionaryDetails.wsDictionaryUuid
            }`}
          />
          <BreadcrumbsItem
            text={dictionaryDetails?.title}
            to={`/workspace/${activeWorkspaceUuid}/dict/${header?.toLowerCase()}/${
              dictionaryDetails.wsDictionaryUuid
            }?displayDictionaryPreview=${dictionaryElementUuid}`}
          />
        </Breadcrumbs>

        <CloseIcon
          onClick={hidePane}
          className="dictionary-details__topbar-close-icon"
        />
      </div>

      <div className="dictionary-details__content">
        <PreviewInfo
          context={PreviewContext.Dictionary}
          previewObjectUuid={dictionaryDetails.uuid}
          title={dictionaryDetails.title}
          titleMetaFieldUuid={dictionaryDetails?.titleMetaFieldUuid}
        />

        <DictionaryMetadata
          elementUuid={dictionaryElementUuid}
          metadata={dictionaryDetails.metadata}
        />

        {taskOwner && (
          <AddedBy
            name={taskOwner.name}
            avatarUrl={taskOwner.avatarUrl}
            dateCreated={dateCreated}
          />
        )}
      </div>
    </Pane>
  );
}
