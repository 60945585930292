import React, { useEffect } from "react";
import "./FieldIcons.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { IconSelector } from "../../../../components/IconSelector/IconSelector";
import { IconColorSelector } from "../../../../components/IconColorSelector/IconColorSelector";

export function FieldIcons(props) {
  const {
    selectedIcon,
    setSelectedIcon,
    selectedColor,
    setSelectedColor,
    addIcon,
    setAddIcon,
    disabled,
  } = props;

  useEffect(() => {
    if (!addIcon) {
      setSelectedColor("Instagram");
      setSelectedIcon({});
    }
  }, [addIcon]);

  return (
    <div className="field-icons">
      <div className="field-icons__switch">
        <CustomSwitch
          checked={addIcon}
          onChange={() => setAddIcon(!addIcon)}
          disabled={disabled}
        />
        <IDHFormattedMessage id="ws_icon" defaultMessage="Icon" />
      </div>
      {addIcon && (
        <div className="field-icons__selectors">
          <IconColorSelector
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
          <IconSelector
            selectedIcon={selectedIcon}
            setSelectedIcon={setSelectedIcon}
            selectedColor={selectedColor}
          />
        </div>
      )}
    </div>
  );
}
