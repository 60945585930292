import classNames from "classnames";
import React, {
  Dispatch,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
} from "react";
import "./CustomTextarea.scss";

type Props = {
  className?: string;
  id: string;
  value?: string | null;
  onChange?: Dispatch<SetStateAction<any>>;
  onBlur?: () => void;
  placeholder?: string;
  autoGrow?: boolean;
};

const CustomTextarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { className, placeholder, id, value, onChange, autoGrow, onBlur } =
    props;

  useEffect(() => {
    if (autoGrow) {
      const textarea = document.querySelector(".ws-textarea") as HTMLElement;

      if (!value) {
        textarea.style.height = "40px";
      }

      const scrollHeight = textarea?.scrollHeight;

      textarea.style.height = `${scrollHeight}px`;
    }
  }, [value]);

  return (
    <textarea
      ref={ref}
      className={classNames(
        "ws-textarea",
        {
          "ws-textarea--auto-grow": autoGrow,
        },
        className,
      )}
      id={id}
      placeholder={placeholder}
      value={value || ""}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
});

type LabelProps = {
  htmlFor: string;
  text: string | ReactNode;
};

export const CustomTextareaLabel: React.FC<LabelProps> = ({
  htmlFor,
  text,
}) => {
  return (
    <label htmlFor={htmlFor} className="ws-textarea-label">
      {text}
    </label>
  );
};

export default CustomTextarea;
