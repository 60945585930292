import React from "react";

import { MalePerAge } from "../../../../Task/tabs/ProfileDataTab/single/MalePerAge";

export const renderMalePerAge = (label, data, layout, GridBoxComponent) => {
  const {
    user_audience_data: { audienceMen, gendersPerAge },
  } = data;

  const key = `${label}-male-per-age`;
  const height = layout?.find((item) => item.i === key)?.h;

  return (
    <GridBoxComponent key={key} id={key}>
      <MalePerAge
        audienceMen={audienceMen}
        male={gendersPerAge?.male}
        height={height}
      />
    </GridBoxComponent>
  );
};
