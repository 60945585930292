import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export const METRIC_FIELDS = [
  {
    value: "bio",
    label: <IDHFormattedMessage id="ws_bio" defaultMessage="Bio" />,
  },
  {
    value: "credibility",
    label: (
      <IDHFormattedMessage id="ws_credibility" defaultMessage="Credibility" />
    ),
  },
  {
    value: "er",
    label: <IDHFormattedMessage id="ws_er" defaultMessage="ER" />,
  },
  {
    value: "followers",
    label: <IDHFormattedMessage id="ws_followers" defaultMessage="Followers" />,
  },
  {
    value: "audience-age",
    label: (
      <IDHFormattedMessage id="ws_audience_age" defaultMessage="Audience Age" />
    ),
  },
  {
    value: "female-per-age",
    label: (
      <IDHFormattedMessage
        id="ws_female_per_age"
        defaultMessage="Female Per Age"
      />
    ),
  },
  {
    value: "geographical-reach",
    label: (
      <IDHFormattedMessage
        id="ws_geographical_reach"
        defaultMessage="Geographical Reach"
      />
    ),
  },
  {
    value: "languages",
    label: <IDHFormattedMessage id="ws_languages" defaultMessage="Languages" />,
  },
  {
    value: "location-by-city",
    label: (
      <IDHFormattedMessage
        id="ws_location_by_city"
        defaultMessage="Location By City"
      />
    ),
  },
  {
    value: "male-per-age",
    label: (
      <IDHFormattedMessage id="ws_male_per_age" defaultMessage="Male Per Age" />
    ),
  },
  {
    value: "creator-interests",
    label: (
      <IDHFormattedMessage
        id="ws_creator_interests"
        defaultMessage="Creator Interests"
      />
    ),
    initialHidden: true,
  },
  {
    value: "creators-brand-affinity",
    label: (
      <IDHFormattedMessage
        id="ws_creators_brand_affinity"
        defaultMessage="Creator Brand Affinity"
      />
    ),
    initialHidden: true,
  },
  {
    value: "audience-interests",
    label: (
      <IDHFormattedMessage
        id="ws_audience_interests"
        defaultMessage="Audience Interests"
      />
    ),
    initialHidden: true,
  },
  {
    value: "audience-brand-affinity",
    label: (
      <IDHFormattedMessage
        id="ws_audience_brand_affinity"
        defaultMessage="Audience Brand Affinity"
      />
    ),
    initialHidden: true,
  },
  {
    value: "hashtags",
    label: <IDHFormattedMessage id="ws_hashtags" defaultMessage="Hashtags" />,
    initialHidden: true,
  },
  {
    value: "creator-growth",
    label: (
      <IDHFormattedMessage
        id="ws_creator_growth"
        defaultMessage="Creator growth"
      />
    ),
    initialHidden: true,
  },
  {
    value: "keywords",
    label: <IDHFormattedMessage id="ws_keywords" defaultMessage="Keywords" />,
    initialHidden: true,
  },
  {
    value: "recently-published",
    label: (
      <IDHFormattedMessage
        id="ws_recently_published"
        defaultMessage="Recently Published"
      />
    ),
  },
  {
    value: "avg-views",
    label: (
      <IDHFormattedMessage id="ws_avg_views" defaultMessage="Avg. Views" />
    ),
    initialHidden: true,
  },
];

export const METRIC_SIZES = [
  { i: "audience-age", w: 2, h: 3 },
  { i: "bio", w: 6, h: 2 },
  { i: "credibility", w: 2, h: 1 },
  { i: "er", w: 2, h: 1 },
  { i: "female-per-age", w: 2, h: 3 },
  { i: "followers", w: 2, h: 1 },
  { i: "geographical-reach", w: 2, h: 3 },
  { i: "languages", w: 2, h: 3 },
  { i: "location-by-city", w: 2, h: 3 },
  { i: "male-per-age", w: 2, h: 3 },
  { i: "recently-published", w: 4, h: 4 },
  { i: "creator-interests", w: 2, h: 3 },
  { i: "creators-brand-affinity", w: 2, h: 3 },
  { i: "audience-interests", w: 2, h: 3 },
  { i: "audience-brand-affinity", w: 2, h: 3 },
  { i: "hashtags", w: 4, h: 3 },
  { i: "keywords", w: 4, h: 3 },
  { i: "creator-growth", w: 2, h: 3 },
  { i: "avg-views", w: 2, h: 3 },
];
