import React from "react";

import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as ReachIcon } from "../../../../../images/profile-data/reach.svg";
import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";
import { mapObjectToKeyPairArray } from "../../../../../utils/methods";
import { ProgressBarList } from "../components/ProgressBarList/ProgressBarList";

export function GeographicalReach(props) {
  const { audienceGeographicalReach, height, onProjectDetails } = props;

  const onShowcase = height;

  return (
    <ProfileBox onProjectDetails={onProjectDetails}>
      <PBHeader
        onProjectDetails={onProjectDetails}
        icon={<ReachIcon />}
        title={
          <IDHFormattedMessage
            id="ws_geographical_reach"
            defaultMessage="Geographical Reach"
          />
        }
        tooltip={
          !onShowcase && (
            <MaterialTooltip
              center
              content={
                <IDHFormattedMessage
                  id="ws_tooltip_geographical_reach"
                  defaultMessage="Audience divided by countries. Please note: only countries with the biggest percentage of the audience are displayed"
                />
              }
            />
          )
        }
        onShowcase={onShowcase}
      />

      {audienceGeographicalReach && (
        <ProgressBarList
          data={mapObjectToKeyPairArray(audienceGeographicalReach, "country")}
          barColor="#47C4AB"
          backgroundColor="rgba(71, 196, 171, 0.1)"
          flag
          height={height}
        />
      )}
    </ProfileBox>
  );
}
