import { SocialProfile } from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import {
  CLEAR_SELECTED_CREATOR_DATA,
  CLEAR_SHOWCASE_DATA,
  GET_SHOWCASE_DATA_SUCCESS,
  SET_GENERATING,
  SET_GRID_ELEMENTS,
  SET_HIDDEN_GRID_ELEMENTS,
  SET_LAYOUT,
  SET_MULTIPLE_LAYOUTS,
  SET_PRINTING,
  SET_SAVED_TEMPLATE,
  SET_SELECTED_CREATOR_DATA,
  SET_SELECTED_CREATORS,
  SET_SELECTED_TEMPLATE,
  SET_SHOWCASE_ELEMENTS,
  SET_SHOWCASE_TRANSITION,
  SET_STARTING_TEMPLATES,
  SET_TEMPLATE_BASE,
} from "./showcaseTypes";

export interface ShowcaseCreator {
  contextAttributeValues: { [key: string]: string | string[] | number };
  name: string;
  socialProfiles: SocialProfile[];
  socialProfilesData: { [key: string]: {} };
  uuid: string;
}

const initialState = {
  showcaseData: {},
  selectedCreatorData: {},
  generating: true,
  printing: false,
  layout: [],
  savedTemplate: [],
  gridElements: [],
  hiddenGridElements: [],
  selectedCreators: [],
  multipleLayouts: [],
  selectedTemplate: { label: "Default", value: {} },
  startingTemplates: [],
  templateBase: [],
  showcaseTransition: null as number | null,
  previousCreatorId: null,
};

function showcaseReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_SHOWCASE_DATA_SUCCESS:
      return {
        ...state,
        showcaseData: action?.payload?.data?.content,
      };

    case CLEAR_SHOWCASE_DATA:
      return {
        ...state,
        showcaseData: {},
      };

    case CLEAR_SELECTED_CREATOR_DATA:
      return {
        ...state,
        selectedCreatorData: {},
      };

    case SET_SELECTED_CREATOR_DATA:
      return {
        ...state,
        selectedCreatorData: action.payload.selectedCreatorData,
      };

    case SET_GENERATING:
      return {
        ...state,
        generating: action.payload.generating,
      };

    case SET_PRINTING:
      return {
        ...state,
        printing: action.payload.printing,
      };

    case SET_LAYOUT:
      return {
        ...state,
        layout: action.payload.layout,
      };

    case SET_SAVED_TEMPLATE:
      return {
        ...state,
        savedTemplate: action.payload.savedTemplate,
      };

    case SET_GRID_ELEMENTS:
      return {
        ...state,
        gridElements: action.payload.gridElements,
      };

    case SET_HIDDEN_GRID_ELEMENTS:
      return {
        ...state,
        hiddenGridElements: action.payload.hiddenGridElements,
      };

    case SET_SELECTED_CREATORS:
      return {
        ...state,
        selectedCreators: action.payload.selectedCreators,
      };

    case SET_SHOWCASE_ELEMENTS:
      return {
        ...state,
        showcaseData: {
          ...state.showcaseData,
          elements: action.payload.elements,
        },
      };

    case SET_MULTIPLE_LAYOUTS:
      window.localStorage.setItem(
        "showcaseMultipleLayouts",
        JSON.stringify(action.payload.multipleLayouts),
      );

      return {
        ...state,
        multipleLayouts: action.payload.multipleLayouts,
      };

    case SET_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload.selectedTemplate,
      };

    case SET_STARTING_TEMPLATES:
      return {
        ...state,
        startingTemplates: action.payload.startingTemplates,
      };

    case SET_TEMPLATE_BASE:
      return {
        ...state,
        templateBase: action.payload.templateBase,
      };

    case SET_SHOWCASE_TRANSITION:
      return {
        ...state,
        showcaseTransition: action.payload.showcaseTransition,
      };

    default:
      return state;
  }
}

export default showcaseReducer;
