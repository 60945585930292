import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RootState } from "src/redux/reducers";
import { IMyTask } from "src/redux/task/taskReducer";
import placeholderIcon from "../../../images/placeholder-image.svg";
import publicationPlaceholder from "../../../images/publication-placeholder-grey.svg";
import { changeTaskMetaFieldValue } from "../../../redux";
import { TaskType } from "../../../types";
import { getImageSource } from "../../../utils/methods";
import Check from "../../components/Check/Check";
import CustomDatePicker, {
  DatePickerWrapper,
} from "../../components/CustomDatePicker/CustomDatePicker";
import CustomImage from "../../components/CustomImage/CustomImage";
import Skeleton, { SkeletonText } from "../../components/Skeleton/Skeleton";
import { taskAvatarShape } from "../../components/Table/Table";
import { convertTimezone } from "../../methods/convertTimezone";
import { showToast } from "../../methods/showToast";

interface Props {
  task: IMyTask;
}

function TaskRow(props: Props) {
  const { task } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const [dueDate, setDueDate] = useState<any>(null);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const { myTasksLoading } = useSelector(
    (state: RootState) => state.taskReducer,
  );

  useEffect(() => {
    if (task.dueDate && task.dueDate.date.localeCompare(dueDate) !== 0) {
      const newDueDate = convertTimezone(task.dueDate.date);

      setDueDate(newDueDate);
    }
  }, [task.dueDate]);

  const onDateChange = async (newDate: any) => {
    try {
      if (newDate && task.dueDateMetaFieldUuid) {
        dispatch(
          changeTaskMetaFieldValue(
            task.wsTaskUuid,
            task.dueDateMetaFieldUuid,
            newDate,
          ),
        );
        setDueDate(newDate);
      }
    } catch (err) {}
  };

  useEffect(() => {
    setIsComplete(task.completed);
  }, [task]);

  const setTaskCompleted = async () => {
    try {
      await dispatch(
        changeTaskMetaFieldValue(
          task.wsTaskUuid,
          task.completedMetaFieldUuid,
          !isComplete,
        ),
      );

      // dispatch(getTaskDetails(taskInfo.taskId));
    } catch (error) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_error_while_completing_action"
          defaultMessage="Error while completing an action!"
        />,
      );
    }
  };

  const getPlaceholderImage = () => {
    const src = getImageSource(task?.wsTaskCover, "small");

    if (src) {
      return src;
    }

    if (task.wsTaskType === TaskType.Publication) {
      return publicationPlaceholder;
    }

    return placeholderIcon;
  };

  const isCircleShape = () => {
    if (task.wsTaskType === TaskType.Creator) {
      return taskAvatarShape.Circle;
    }
  };

  const isSquareShape = () => {
    if (
      task.wsTaskType === TaskType.Publication ||
      task.wsTaskType === TaskType.Content
    ) {
      return taskAvatarShape.Square;
    }
  };

  return (
    <tr>
      <td
        className={classNames("recently-assigned__list-cover", {
          "recently-assigned__list-cover--square":
            task.wsTaskType === "content",
        })}
      >
        {myTasksLoading ? (
          <Skeleton width={48} height={48} borderRadius="50%" />
        ) : (
          <>
            {task.wsTaskType === TaskType.Action ||
            task.wsTaskType === TaskType.Payment ? (
              <Check
                isCheck={isComplete}
                setIsCheck={(value) => {
                  setIsComplete(value);
                  setTaskCompleted();
                }}
                large
              />
            ) : (
              <CustomImage
                className={classNames("ws-table__image-cover", {
                  "ws-table__image-cover--circle": isCircleShape(),
                  "ws-table__image-cover--square": isSquareShape(),
                })}
                src={getPlaceholderImage()}
                alt=""
              />
            )}
          </>
        )}
      </td>
      <td>
        {myTasksLoading ? (
          <SkeletonText width={160} height={20} />
        ) : (
          <span
            onClick={() =>
              history.push(
                `/workspace/${activeWorkspaceUuid}/my-actions/${task.wsProjectUuid}/${task.wsTaskUuid}`,
              )
            }
            className="recently-assigned__list-name"
          >
            {task.wsTaskName}
          </span>
        )}
      </td>
      <td className="recently-assigned__list-project-name">
        {myTasksLoading ? (
          <SkeletonText width={100} height={20} />
        ) : (
          <>
            {task.wsProjectName && (
              <span
                onClick={() =>
                  history.push(
                    `/workspace/${activeWorkspaceUuid}/projects/${task.wsProjectUuid}`,
                  )
                }
              >
                {task.wsProjectName}
              </span>
            )}
          </>
        )}
      </td>
      <td className="recently-assigned__list-due-date">
        {myTasksLoading ? (
          <SkeletonText width={80} height={20} />
        ) : (
          <>
            {task.dueDateMetaFieldUuid && (
              <DatePickerWrapper value={dueDate}>
                <CustomDatePicker
                  initialDate={dueDate}
                  dateChangeHandler={onDateChange}
                  nullable
                />
              </DatePickerWrapper>
            )}
          </>
        )}
      </td>
    </tr>
  );
}

export default TaskRow;
