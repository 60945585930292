import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as EyesIcon } from "../../../images/eyes.svg";

function EmptyPublishersTab() {
  return (
    <div className="project-details-empty-tab project-details-empty-tab--publishers">
      <EyesIcon className="project-details-empty-tab__icon" />
      <div className="project-details-empty-tab__text">
        <IDHFormattedMessage
          id="ws_empty_publishers_text"
          defaultMessage="There is nothing to display here. \nAdd publications to your project to see data here."
        />
      </div>
    </div>
  );
}

export { EmptyPublishersTab };
