import React from "react";
import ReactDOM from "react-dom";
import AppContainer from "./AppContainer";

ReactDOM.render(
  <AppContainer />,
  document.getElementById("Workspace-App"),
  () => {
    try {
      const element = document?.getElementById("page-preloader-container");
      element?.parentNode?.removeChild(element);
    } catch (e) {
      // If it failed, maybe it wasn't there in the first place...?
    }
  },
);
