import React from "react";

import { Button } from "src/app/components/Button/Button";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import { DropdownPortal } from "src/app/components/DropdownPortal/DropdownPortal";

import { ReactComponent as FilterIcon } from "src/images/filter.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as TickBlueIcon } from "../../../images/tick-blue.svg";

export function UserListFilter({ filteredGroup, setFilteredGroup }) {
  const menu = (
    <DropdownMenu isOpen>
      <DropdownMenuItem onClick={() => setFilteredGroup("all")}>
        <IDHFormattedMessage id="ws_all" defaultMessage="All" />
        {filteredGroup === "all" && (
          <TickBlueIcon
            width={20}
            height={20}
            className="dropdown__tick-blue"
          />
        )}
      </DropdownMenuItem>
      <DropdownMenuItem onClick={() => setFilteredGroup("admin")}>
        <IDHFormattedMessage id="ws_admin" defaultMessage="Admin" />
        {filteredGroup === "admin" && (
          <TickBlueIcon
            width={20}
            height={20}
            className="dropdown__tick-blue"
          />
        )}
      </DropdownMenuItem>
      <DropdownMenuItem onClick={() => setFilteredGroup("member")}>
        <IDHFormattedMessage id="ws_member" defaultMessage="Member" />
        {filteredGroup === "member" && (
          <TickBlueIcon
            width={20}
            height={20}
            className="dropdown__tick-blue"
          />
        )}
      </DropdownMenuItem>
      <DropdownMenuItem onClick={() => setFilteredGroup("client")}>
        <IDHFormattedMessage id="ws_client" defaultMessage="Client" />
        {filteredGroup === "client" && (
          <TickBlueIcon
            width={20}
            height={20}
            className="dropdown__tick-blue"
          />
        )}
      </DropdownMenuItem>
      <DropdownMenuItem onClick={() => setFilteredGroup("supervisor")}>
        <IDHFormattedMessage id="ws_supervisor" defaultMessage="Supervisor" />
        {filteredGroup === "supervisor" && (
          <TickBlueIcon
            width={20}
            height={20}
            className="dropdown__tick-blue"
          />
        )}
      </DropdownMenuItem>
    </DropdownMenu>
  );

  return (
    <DropdownPortal overlay={menu}>
      <Button variant="white">
        <FilterIcon />
        &nbsp;
        <IDHFormattedMessage id="ws_show" defaultMessage="Show" />
        :&nbsp;
        <span style={{ textTransform: "capitalize" }}>{filteredGroup}</span>
      </Button>
    </DropdownPortal>
  );
}
