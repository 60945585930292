import React from "react";
import "./EmptyPromoCodes.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "src/app/components/Button/Button";

import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";
import { ReactComponent as ToolIcon } from "src/images/tool.svg";
import { ReactComponent as QuestionMarkIcon } from "src/images/question-mark.svg";
import NoResultsImg from "src/images/no-promo-codes.svg";
import NoResults from "src/app/components/NoResultsScreen/NoResultsScreen";
import { openSettingsModal } from "src/app/SettingsModal/methods";
import { SettingsContext, SettingsTab } from "src/app/SettingsModal/enums";
import { useHistory } from "react-router";
import { AppLocation } from "src/redux/main/mainReducer";

interface Props {
  openCreateCouponGroupModal: () => void;
  openLearnMoreModal: () => void;
}

export const EmptyPromoCodes: React.FC<Props> = (props) => {
  const { openCreateCouponGroupModal, openLearnMoreModal } = props;

  const history = useHistory();

  const openConfiguration = () => {
    openSettingsModal(
      history,
      AppLocation.PromoCodes,
      SettingsContext.Workspace,
      SettingsTab.Coupons,
    );
  };

  return (
    <div className="empty-promo-codes">
      <NoResults
        title={
          <IDHFormattedMessage
            id="ws_there_are_no_promo_codes"
            defaultMessage="There are no promo codes"
          />
        }
        subtitle={
          <div className="empty-promo-codes__subtitle-wrapper">
            <IDHFormattedMessage
              id="ws_enter_promo_codes_and_track_results"
              defaultMessage="Enter promo codes and track the results. <br> Increase sales and awareness of your brand."
            />
            <div className="empty-promo-codes__subtitle-wrapper-buttons">
              <Button
                variant="white-with-border"
                size="large"
                onClick={openLearnMoreModal}
              >
                <QuestionMarkIcon />
                <IDHFormattedMessage
                  id="ws_learn_more"
                  defaultMessage="Learn more"
                />
              </Button>

              <Button
                variant="white-with-border"
                size="large"
                onClick={openConfiguration}
              >
                <ToolIcon />
                <IDHFormattedMessage
                  id="ws_store_configuration"
                  defaultMessage="Store configuration"
                />
              </Button>

              <Button
                variant="blue"
                size="large"
                onClick={openCreateCouponGroupModal}
              >
                <PlusIcon />
                <IDHFormattedMessage
                  id="ws_create_coupon_group"
                  defaultMessage="Create coupon group"
                />
              </Button>
            </div>
          </div>
        }
        imgUrl={NoResultsImg}
      />
    </div>
  );
};
