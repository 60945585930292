import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { GlobalField } from "src/app/modals/ManageFieldModal/components/GlobalFields/GlobalFields";
import {
  Column,
  CustomTable,
} from "src/app/components/CustomTable/CustomTable";
import {
  clearGlobalTaskProjects,
  getGlobalTaskProjects,
} from "src/redux/creator-database/creatorDatabaseActions";
import { GlobalTaskProject } from "src/redux/creator-database/types";
import { formatDate } from "src/app/components/GlideDataGrid/utils";
import { Img } from "src/app/components/Img/Img";
import { getImageSource } from "src/utils/methods";
import projectPlaceholder from "src/images/project-placeholder.svg";
import { CoverImage } from "src/redux/project/types";
import goToImage from "src/images/share-alt.svg";
import avatarWithLock from "src/images/avatar-with-lock.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { EmptyActivityTab } from "src/app/Task/tabs/ActivityTab/EmptyActivityTab";
import { ROUTES, getRoute } from "src/utils/ROUTES";
import { TaskType } from "src/types";
import { RootState } from "src/redux/reducers";
import {
  SkeletonLoader,
  actionColumnWidth,
  dateColumnWidth,
  nameColumnWidth,
} from "./utils";

interface GlobalActivityTabCampaignsProps {
  workspaceUuid: string;
  taskId: string;
}

const columnSettings = [
  {
    Header: "NAME",
    accessor: "name",
    width: nameColumnWidth,
  },
  {
    Header: "START DATE",
    accessor: "",
    width: dateColumnWidth,
  },
  {
    Header: "END DATE",
    accessor: "",
    width: dateColumnWidth,
  },
  {
    Header: "ACTION",
    accessor: "action",
    width: actionColumnWidth,
  },
];

export function GlobalActivityTabCampaigns({
  workspaceUuid,
  taskId,
}: GlobalActivityTabCampaignsProps) {
  const [rows, setRows] = useState<
    {
      action?: JSX.Element;
      onRowClick?: () => void;
      name: JSX.Element;
    }[]
  >([]);
  const [columns, setColumns] = useState<Column[]>([]);

  const dispatch = useDispatch();
  const {
    creatorDatabaseReducer: { isGlobalTaskProjectsLoading, globalTaskProjects },
  } = useSelector((state: RootState) => state);

  const handleRowClick = (
    wsWorkspaceUuid: string,
    wsProjectUuid: string,
    wsTaskUuid: string,
  ) => {
    const url = getRoute(
      ROUTES.TASK_DETAILS,
      {
        wsWorkspaceUuid,
        wsProjectUuid,
        wsTaskUuid,
      },
      {
        taskType: TaskType.Creator,
      },
    );

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.target = "_blank";
    anchor.rel = "noopener noreferrer";

    document.body.appendChild(anchor);

    anchor.click();

    document.body.removeChild(anchor);
  };

  const generateColumns = (data: GlobalField[]) => {
    const generatedColumns = columnSettings.map((col) => {
      const foundCol = data.find(
        (item) => item.name.toUpperCase() === col.Header,
      );
      if (foundCol) {
        return { ...col, accessor: foundCol.uuid };
      }
      return col;
    });
    return generatedColumns;
  };

  const generateRows = (
    projects: GlobalTaskProject[],
    columns: GlobalField[],
  ) => {
    return projects.map((wsProject) => {
      const projectKeys = {};

      columns.forEach((col) => {
        const foundMetaData = wsProject.metadata.find(
          (metaDataItem) =>
            metaDataItem.wsGlobalProjectMetaFieldUuid === col.uuid,
        );
        if (
          foundMetaData &&
          foundMetaData.type === "date" &&
          foundMetaData?.value?.date
        ) {
          Object.assign(projectKeys, {
            [col.uuid]: formatDate(foundMetaData?.value?.date),
          });
        }
      });

      if (wsProject.uuid) {
        return {
          onRowClick: () =>
            handleRowClick(
              workspaceUuid,
              wsProject.uuid!,
              wsProject.wsTaskUuid,
            ),
          name: (
            <AvatarAndName
              avatar={wsProject.cover}
              name={wsProject.projectName}
              placeholder={projectPlaceholder}
            />
          ),
          ...projectKeys,
          action: <Img src={goToImage} fallbackImageSrc={goToImage} />,
        };
      }
      return {
        name: (
          <AvatarAndName
            avatar={wsProject.cover}
            name={wsProject.projectName}
            placeholder={projectPlaceholder}
            isPrivate
          />
        ),
      };
    });
  };

  useEffect(() => {
    if (
      globalTaskProjects.columns?.length &&
      globalTaskProjects.projects?.length
    ) {
      setColumns(generateColumns(globalTaskProjects.columns));
      setRows(
        generateRows(globalTaskProjects.projects, globalTaskProjects.columns),
      );
    }
  }, [globalTaskProjects]);

  useEffect(() => {
    if (!workspaceUuid || !taskId) return;
    dispatch(getGlobalTaskProjects(workspaceUuid, taskId));
  }, [workspaceUuid, taskId]);

  useEffect(() => {
    return () => {
      dispatch(clearGlobalTaskProjects());
    };
  }, []);

  if (isGlobalTaskProjectsLoading) {
    return <SkeletonLoader avatarShape="rect" />;
  }

  return (
    <div className="activity-tab__table-wrapper">
      <CustomTable
        columns={columns}
        data={rows}
        headerClassName="activity-tab__table-header"
        isLoading={isGlobalTaskProjectsLoading}
        noRowsPlaceholder={<EmptyActivityTab />}
      />
    </div>
  );
}

export function AvatarAndName({
  avatar,
  name,
  isPrivate,
  placeholder,
  avatarWidth = 32,
  avatarHeight = 32,
  wrapperBackgroundColor,
}: {
  avatar?: CoverImage | null;
  name: string;
  isPrivate?: boolean;
  placeholder?: string;
  avatarWidth?: number;
  avatarHeight?: number;
  wrapperBackgroundColor?: string;
}) {
  let avatarSrc;
  let avatarClassName;
  if (isPrivate) {
    avatarSrc = avatarWithLock;
    avatarClassName =
      "avatar-and-name__avatar avatar-and-name__avatar-placeholder";
  } else {
    avatarSrc = avatar
      ? getImageSource(avatar, "tiny", placeholder)
      : placeholder;
    avatarClassName = avatar
      ? "avatar-and-name__avatar"
      : "avatar-and-name__avatar avatar-and-name__avatar-placeholder";
  }

  return (
    <div className="avatar-and-name">
      {placeholder && (
        <div
          className="avatar-and-name__avatar-wrapper"
          style={{
            width: avatarWidth,
            height: avatarHeight,
            backgroundColor: wrapperBackgroundColor,
          }}
        >
          <Img
            src={avatarSrc}
            fallbackImageSrc={placeholder}
            alt="avatar"
            className={avatarClassName}
            fallbackImageClassName="avatar-and-name__avatar-placeholder"
            loading="lazy"
            style={{ width: avatarWidth, height: avatarHeight }}
          />
        </div>
      )}
      <div>
        {isPrivate ? (
          <IDHFormattedMessage id="ws_private" defaultMessage="Private" />
        ) : (
          name
        )}
      </div>
    </div>
  );
}
