import React from "react";
import { connect } from "react-redux";
import "./AddCreatorsInfoModal.scss";

import Modal from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as WarningYellowIcon } from "src/images/warning.svg";

function AddCreatorsInfoModal(props) {
  const { onConfirmClick, creatorsAddedFromSocials, onClose } = props;

  return (
    <Modal
      onConfirmClick={onConfirmClick}
      displayCancelButton={false}
      onClose={onClose}
      title={
        <IDHFormattedMessage
          id="front_modal_add_creator_info"
          defaultMessage="Info"
        />
      }
      confirmButtonText={
        <IDHFormattedMessage
          id="front_ok_got_it_btn"
          defaultMessage="Ok, got it"
        />
      }
    >
      <div className="modal__text">
        <IDHFormattedMessage
          id="front_the_following_accounts_will_be_added_to_the_list"
          defaultMessage="The following accounts will be added to the list in about an hour:"
        />
      </div>
      <div className="add-creators-info-modal__list">
        {creatorsAddedFromSocials.map((username, index) => (
          <div
            className="add-creators-info-modal__username"
            key={username + index}
          >
            {username}
          </div>
        ))}
      </div>
      <div className="add-creators-info-modal__warning">
        <WarningYellowIcon className="add-creators-info-modal__warning-icon" />
        <IDHFormattedMessage
          id="front_add_creators_info_modal_warning"
          defaultMessage="Warning"
        />
      </div>
      <div className="add-creators-info-modal__conditions-title">
        <IDHFormattedMessage
          id="front_accounts_wont_be_added_after_this_time_if"
          defaultMessage="Accounts won’t be added after this time if:"
        />
      </div>
      <ul className="add-creators-info-modal__conditions-list">
        <li>
          <IDHFormattedMessage
            id="front_you_have_entered_the_incorrect_accounts_name"
            defaultMessage="You have entered the incorrect accounts name / Accounts don’t exist"
          />
        </li>
        <li>
          <IDHFormattedMessage
            id="front_accounts_were_previously_added_to_this_list"
            defaultMessage="Accounts were previously added to this list"
          />
        </li>
        <li>
          <IDHFormattedMessage
            id="front_accounts_dont_have_at_least_10000_followers_or_are_private_accounts"
            defaultMessage="Accounts don’t have at least 10,000. followers or are private accounts"
          />
        </li>
      </ul>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  creatorsAddedFromSocials:
    state.audiencesManagerReducer.creatorsAddedFromSocials,
});

export default connect(mapStateToProps)(AddCreatorsInfoModal);
