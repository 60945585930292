import React from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { Button } from "src/app/components/Button/Button";
import Emoji from "../../components/Emoji/Emoji";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import "./EmptyAudiencesManager.scss";
import { ReactComponent as AudiencesManagerImage } from "src/images/audiences-manager.svg";

import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";

function EmptyAudiencesManager() {
  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state) => state);

  return (
    <div className="audiences-manager-empty">
      <div className="audiences-manager-empty__text">
        <h4 className="audiences-manager-empty__text-header">
          <IDHFormattedMessage
            id="ws_no_list_has_been_created"
            defaultMessage="No list has been created"
          />
        </h4>
        <br />
        <IDHFormattedMessage
          id="ws_create_your_first_creator_list"
          defaultMessage="Create your first creator list and match your brand perfectly to your target audience."
        />
        <br />
        <Link
          to={`/workspace/${activeWorkspaceUuid}/creator-discovery-tool/search`}
        >
          <Button
            size="large"
            variant="blue"
            className="audiences-manager-empty__button"
          >
            <PlusIcon />
            <IDHFormattedMessage
              id="ws_create_list"
              defaultMessage="Create list"
            />
          </Button>
        </Link>
      </div>
      <AudiencesManagerImage className="audiences-manager-empty__image" />
    </div>
  );
}

export default EmptyAudiencesManager;
