import React from "react";
import "./EmptyTeams.scss";

import { ReactComponent as PlusIcon } from "src/images/plus-transparent.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import NoResults from "src/app/components/NoResultsScreen/NoResultsScreen";
import { Button } from "src/app/components/Button/Button";

interface Props {
  createTeamClick: () => void;
}

export const EmptyTeams: React.FC<Props> = (props) => {
  const { createTeamClick } = props;

  return (
    <div className="empty-teams">
      <NoResults
        title={
          <IDHFormattedMessage
            id="ws_no_team_has_been_created"
            defaultMessage="No team has been created"
          />
        }
        subtitle={
          <div className="empty-promo-codes__subtitle-wrapper">
            <IDHFormattedMessage
              id="ws_create_your_first_team"
              defaultMessage="Create your first team to coordinate work easily and effectively."
            />
            <div className="empty-promo-codes__subtitle-wrapper-buttons">
              <Button variant="blue" size="medium" onClick={createTeamClick}>
                <PlusIcon />
                <IDHFormattedMessage
                  id="ws_create_team"
                  defaultMessage="Create team"
                />
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};
