import React, { PropsWithChildren } from "react";
import classNames from "classnames";

import { PublicationStatus } from "src/types/publication";

interface SocialProfileLinkProps {
  status: PublicationStatus;
  url: string;
}

export default function PublicationLink({
  status,
  url,
  children,
}: PropsWithChildren<SocialProfileLinkProps>) {
  return (
    <a
      className={classNames("social-profile-icons__icon", {
        "social-profile-icons__icon--awaiting":
          status === PublicationStatus.Fresh,
        "social-profile-icons__icon--verified":
          status === PublicationStatus.Verified,
        "social-profile-icons__icon--not-found":
          status === PublicationStatus.NotFound,
        "social-profile-icons__icon--error": status === PublicationStatus.Error,
        "social-profile-icons__icon--restricted":
          status === PublicationStatus.Restricted,
        "social-profile-icons__icon--private":
          status === PublicationStatus.Private,
      })}
      href={url}
      rel="noopener noreferrer nofollow"
      target="_blank"
      onClick={(e) => {
        if (status === PublicationStatus.Verified) {
          e.stopPropagation();
        }
      }}
    >
      {children}
    </a>
  );
}
