import React from "react";
import "./EmptyPerformance.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as EmptyPerformanceImage } from "src/images/empty-performance.svg";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";

export function EmptyPerformance() {
  return (
    <AnimatedDiv className="empty-performance">
      <EmptyPerformanceImage />
      <div className="empty-performance__text">
        <IDHFormattedMessage
          id="ws_there_are_no_performance_statistics_yet"
          defaultMessage="There are no performance statistics yet"
        />
      </div>

      <div className="empty-performance__subtext">
        <IDHFormattedMessage
          id="ws_patiently_wait_for_the_first_publication_from_this_creator"
          defaultMessage="Patiently wait for the first publication from this creator."
        />
      </div>
    </AnimatedDiv>
  );
}
