import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SettingsModal } from "src/app/SettingsModal/SettingsModal";
import {
  SettingsContext,
  ProjectSettingsTab,
} from "src/app/SettingsModal/enums";
import {
  closeSettingsModal,
  openSettingsModal,
} from "src/app/SettingsModal/methods";
import { AutomationsSection } from "src/app/SettingsModal/tabs/Automations/Automations";
import { ExtensionsSection } from "src/app/SettingsModal/tabs/Extensions/Extensions";
import DropdownPortal, {
  DropdownPortalMenu,
} from "src/app/components/DropdownPortal";
import { DropdownMenuItem } from "src/app/components/Dropdown/Dropdown";
import { tableDataType } from "src/app/components/Table/Table";
import RenameModal from "src/app/modals/RenameModal/RenameModal";
import { DropdownPopper } from "src/app/components/DropdownPopper/DropdownPopper";
import { getProjectsList } from "../../../../redux";

import DeleteMetaFieldModal from "../../../modals/DeleteMetaFieldModal/DeleteMetaFieldModal";
import ConfirmationOfDeleteProjectModal from "../../../modals/ConfirmationOfDeleteProjectModal/ConfirmationOfDeleteProjectModal";
import DuplicateProjectModal from "../../../modals/DuplicateProjectModal/DuplicateProjectModal";
import { SeeAsClientInfoModal } from "../../../modals/ShareModal/SeeAsClientInfoModal/SeeAsClientInfoModal";
import { RolesInfoModal } from "../../../modals/ShareModal/RolesInfoModal/RolesInfoModal";
import { copyProjectOrTaskLink } from "../../../methods/copyProjectOrTaskLink";
import { AppConfig } from "../../../../config/AppConfig";

import { ReactComponent as CopyLinkIcon } from "../../../../images/link.svg";
import { ReactComponent as LighteningIcon } from "../../../../images/dots.svg";
import { ReactComponent as DuplicateIcon } from "../../../../images/duplicate.svg";
import { ReactComponent as DeleteIcon } from "../../../../images/trash-can.svg";
import { ReactComponent as DownloadIcon } from "../../../../images/download.svg";
import { ReactComponent as SettingsIcon } from "../../../../images/settings.svg";
import { ReactComponent as ReportIcon } from "../../../../images/report.svg";
import { ReactComponent as InfoGreyIcon } from "../../../../images/info-grey.svg";
import { ReactComponent as WandIcon } from "../../../../images/wand.svg";
import { ReactComponent as SwitchIcon } from "../../../../images/switch.svg";
import { ReactComponent as PlugIcon } from "../../../../images/plug.svg";
import {
  setEditedAutomationData,
  setShowPublicationsDetectedModal,
} from "../../../../redux/automation/automationActions";
import { PublicationsDetectedModal } from "../../../modals/PublicationsDetectedModal/PublicationsDetectedModal";

export function ProjectMenuDropdownPopper(props) {
  const { data, singleProject, openProjectDetailsPane } = props;

  const [editedFieldData, setEditedFieldData] = useState({});
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDuplicateProjectModal, setShowDuplicateProjectModal] =
    useState(false);
  const [
    showConfirmationOfDeleteProjectModal,
    setShowConfirmationOfDeleteProjectModal,
  ] = useState(false);
  const [showDeleteMetaFieldModal, setShowDeleteMetaFieldModal] =
    useState(false);
  const [showSeeAsClientInfoModal, setShowSeeAsClientInfoModal] =
    useState(false);
  const [showRulesInfoModal, setShowRolesInfoModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const dropdownRef = useRef();
  const location = useLocation();
  const {
    mainReducer: { activeWorkspaceUuid, settingsModalData },
    automationReducer: {
      editedAutomationData,
      automationList,
      showPublicationsDetectedModal,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!editedAutomationData.uuid) return;

    const newAutomationData =
      automationList.find((item) => item.uuid === editedAutomationData.uuid) ||
      {};

    dispatch(setEditedAutomationData(newAutomationData));
  }, [automationList]);

  const onCloseDuplicateProjectModal = () => {
    setShowDuplicateProjectModal(false);
  };

  const openReportGenerator = () => {
    window.localStorage.setItem("reportGeneratorEntryUrl", location.pathname);

    history.push(
      `/workspace/${activeWorkspaceUuid}/report-generator/${data.projectId}`,
    );
  };

  const handleOpenSettingsModal = (tab, section) => {
    openSettingsModal(
      history,
      `project-menu-dropdown-${data.projectId}`,
      SettingsContext.Project,
      tab,
      section,
    );
  };

  const menu = (
    <DropdownPortalMenu
      className="project-menu-dropdown-popper__menu"
      onClick={() => dropdownRef.current.forceVisible(false)}
    >
      {singleProject && (
        <Link
          to={`/workspace/${activeWorkspaceUuid}/projects/${data.projectId}/details`}
        >
          <DropdownMenuItem onClick={openProjectDetailsPane}>
            <InfoGreyIcon />
            <IDHFormattedMessage
              id="ws_show_details"
              defaultMessage="Show details"
            />
          </DropdownMenuItem>
        </Link>
      )}
      <DropdownMenuItem
        onClick={() => handleOpenSettingsModal(ProjectSettingsTab.General)}
      >
        <SwitchIcon />
        <IDHFormattedMessage id="ws_settings" defaultMessage="Settings" />
      </DropdownMenuItem>
      {data.permissions?.project?.includes("display_project_configuration") ? (
        <>
          <DropdownMenuItem
            onClick={() =>
              handleOpenSettingsModal(
                ProjectSettingsTab.Automations,
                AutomationsSection.AutomationsMain,
              )
            }
          >
            <WandIcon />
            <IDHFormattedMessage
              id="ws_automations"
              defaultMessage="Automations"
            />
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() =>
              handleOpenSettingsModal(
                ProjectSettingsTab.Extensions,
                ExtensionsSection.ExtensionsList,
              )
            }
          >
            <PlugIcon />
            <IDHFormattedMessage
              id="ws_extensions"
              defaultMessage="Extensions"
            />
          </DropdownMenuItem>
        </>
      ) : null}

      <DropdownMenuItem
        onClick={() =>
          copyProjectOrTaskLink(activeWorkspaceUuid, data.projectId)
        }
      >
        <CopyLinkIcon />
        <IDHFormattedMessage id="ws_copy_link" defaultMessage="Copy link" />
      </DropdownMenuItem>

      {data.permissions?.project.includes("download_report") && (
        <DropdownMenuItem
          onClick={() =>
            (window.location.href = `${AppConfig.getApiRoute()}/project/xlsx-report-file/${
              data.projectId
            }`)
          }
        >
          <DownloadIcon />
          <IDHFormattedMessage
            id="ws_download_xlsx"
            defaultMessage="Download XLSX"
          />
        </DropdownMenuItem>
      )}

      {data.permissions?.project.includes("download_report") && (
        <DropdownMenuItem onClick={openReportGenerator}>
          <ReportIcon />
          <IDHFormattedMessage
            id="ws_generate_report"
            defaultMessage="Generate report"
          />
        </DropdownMenuItem>
      )}

      {data.permissions?.project.includes("project_management") && (
        <>
          <DropdownMenuItem onClick={() => setShowDuplicateProjectModal(true)}>
            <DuplicateIcon />
            <IDHFormattedMessage id="ws_duplicate" defaultMessage="Duplicate" />
          </DropdownMenuItem>
          <DropdownMenuItem
            className="dropdown__menu-item--danger"
            onClick={() => setShowConfirmationOfDeleteProjectModal(true)}
          >
            <DeleteIcon />
            <IDHFormattedMessage id="ws_remove" defaultMessage="Remove" />
          </DropdownMenuItem>
        </>
      )}
    </DropdownPortalMenu>
  );

  return (
    <>
      <DropdownPopper overlay={menu} ref={dropdownRef} placement="left-start">
        <div className="project-menu-dropdown-popper__icon">
          <SettingsIcon />
        </div>
      </DropdownPopper>

      {showRenameModal && (
        <RenameModal
          onClose={() => setShowRenameModal(false)}
          objectId={data.projectId}
          taskName={data.projectName}
          titleFieldId={
            data?.metadata?.find((field) => field.key === "project_name")
              ?.uuid || ""
          }
          projectId={data.projectId}
          dataType={tableDataType.Project}
          isListElement
        />
      )}
      {settingsModalData.location ===
        `project-menu-dropdown-${data.projectId}` && (
        <SettingsModal
          onClose={() => closeSettingsModal(history)}
          members={{
            openShareModal: () =>
              handleOpenSettingsModal(ProjectSettingsTab.Members),
            openSeeAsClientInfoModal: () => setShowSeeAsClientInfoModal(true),
            openRolesInfoModal: () => setShowRolesInfoModal(true),
          }}
          contextTargetUuid={data.projectId}
        />
      )}

      {showPublicationsDetectedModal ? (
        <PublicationsDetectedModal
          onClose={() => dispatch(setShowPublicationsDetectedModal(false))}
        />
      ) : null}

      {showDuplicateProjectModal && (
        <DuplicateProjectModal
          wsProjectUuid={data.projectId}
          projectName={data.projectName}
          onClose={() => onCloseDuplicateProjectModal()}
        />
      )}

      {showConfirmationOfDeleteProjectModal && (
        <ConfirmationOfDeleteProjectModal
          wsProjectUuid={data.projectId}
          wsProjectName={data.projectName}
          wsWorkspaceUuid={activeWorkspaceUuid}
          onClose={() => setShowConfirmationOfDeleteProjectModal(false)}
          singleProject={singleProject}
        />
      )}

      {showDeleteMetaFieldModal && (
        <DeleteMetaFieldModal
          uuid={editedFieldData.uuid}
          fieldName={editedFieldData.name}
          onClose={() => setShowDeleteMetaFieldModal(false)}
        />
      )}

      {showSeeAsClientInfoModal && (
        <SeeAsClientInfoModal
          onClose={() => setShowSeeAsClientInfoModal(false)}
          openShareModal={() =>
            handleOpenSettingsModal(ProjectSettingsTab.Members)
          }
        />
      )}

      {showRulesInfoModal && (
        <RolesInfoModal
          onClose={() => setShowRolesInfoModal(false)}
          openShareModal={() =>
            handleOpenSettingsModal(ProjectSettingsTab.Members)
          }
        />
      )}
    </>
  );
}
