import React from "react";
import "./CouponGroups.scss";

import WebsocketListener from "src/WebsocketListener";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router";
import { AppLocation } from "src/redux/main/mainReducer";
import { RootState } from "src/redux/reducers";
import AnimatedDiv from "../components/AnimatedDiv/AnimatedDiv";
import { closeSettingsModal } from "../SettingsModal/methods";
import { SettingsModal } from "../SettingsModal/SettingsModal";
import { CouponGroupsTable } from "./components/CouponGroupsTable/CouponGroupsTable";
import { CouponGroupView } from "./components/CouponGroupView/CouponGroupView";

export function CouponGroups() {
  const {
    mainReducer: { activeWorkspaceUuid, settingsModalData },
  } = useSelector((state: RootState) => state);

  const history = useHistory();

  return (
    <AnimatedDiv className="coupon-groups">
      <Switch>
        <Route
          exact
          path="/workspace/:workspaceUuid/coupon-groups"
          component={CouponGroupsTable}
        />
        <Route
          exact
          path="/workspace/:workspaceUuid/coupon-groups/:couponGroupId"
          component={CouponGroupView}
        />
      </Switch>

      {settingsModalData?.location === AppLocation.PromoCodes && (
        <SettingsModal
          onClose={() => closeSettingsModal(history)}
          contextTargetUuid={activeWorkspaceUuid}
          disableTransition
        />
      )}
      <WebsocketListener />
    </AnimatedDiv>
  );
}
