import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { ReactComponent as AvatarWithoutPhoto } from "../../images/avatar-without-photo.svg";
import { ReactComponent as AvatarWithoutPhotoHover } from "../../images/avatar-without-photo-hover.svg";
import { uploadAvatarBlob } from "../../utils/uploadFunctions";
import { API_URLS } from "../../utils/API_URLS";
import { identify } from "src/redux/main/mainActions";
import { showToast } from "../methods/showToast";
import { getImageSource } from "../../utils/methods";
import ImageCropModal from "../components/CroppedImageUploader/ImageCropModal/ImageCropModal";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";

function Avatar() {
  const { identity, activeWorkspaceUuid } = useSelector(
    (state) => state.mainReducer,
  );

  const [showImageCropModal, setShowImageCropModal] = useState(false);

  const dispatch = useDispatch();

  const changeAvatar = async (file) => {
    await uploadAvatarBlob(
      file,
      dispatch,
      identity.id,
      identity.lastWorkspaceUuid,
    );
  };

  const removeAvatar = async () => {
    try {
      await axios.delete(
        `${API_URLS.deleteAsset}/${identity.lastWorkspaceUuid}/${identity.avatar.id}`,
      );
      dispatch(identify());
    } catch (err) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        err.response.data.errors[0],
      );
    }
  };

  return (
    <div className="onboarding-page__upload-avatar-wrapper">
      <label
        className="onboarding-page__choose-image-wrapper"
        onClick={() => setShowImageCropModal(true)}
      >
        {identity.avatar ? (
          <div
            className="onboarding-page__avatar-img"
            style={{
              backgroundImage: `url(${getImageSource(
                identity.avatar,
                "small",
              )})`,
            }}
          />
        ) : (
          <div className="onboarding-page__avatar-without-photo" />
        )}
        {/* <input type="file" accept=".png, .jpg, .jpeg" onChange={changeAvatar} /> */}
        <div className="onboarding-page__choose-image">
          <IDHFormattedMessage
            id="ws_choose_image"
            defaultMessage="Choose image"
          />
        </div>
      </label>

      {identity.avatar && (
        <>
          <div className="onboarding-page__dot">・</div>
          <div className="onboarding-page__delete-image" onClick={removeAvatar}>
            <IDHFormattedMessage
              id="ws_delete_image"
              defaultMessage="Delete image"
            />
          </div>
        </>
      )}
      {showImageCropModal && (
        <ImageCropModal
          uploadFunction={changeAvatar}
          onClose={() => setShowImageCropModal(false)}
          circle
        />
      )}
    </div>
  );
}

export { Avatar };
