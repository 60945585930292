import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import "./AccessLinkPageInputField.scss";

import { linkifyString, splitNumbers, splitPercents } from "src/utils/methods";
import classNames from "classnames";

import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import { PercentInput } from "src/app/components/MaskedInputs/PercentInput";
import { CurrencyInput } from "src/app/components/MaskedInputs/CurrencyInput";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";

import { ReactComponent as RequiredStar } from "src/images/required.svg";
import { ReactComponent as InfoRed } from "src/images/toasts/info-red.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Select from "src/app/components/Select/Select";
import { ReactComponent as PlusIcon } from "src/images/plus-transparent.svg";
import { TextBoxWrapper } from "src/app/components/TextBoxWrapper/TextBoxWrapper";
import SelectLabel from "src/app/components/Select/components/SelectLabel/SelectLabel";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import RatingSelector from "src/app/components/RatingSelector/RatingSelector";
import { useSelector } from "react-redux";
import AggregatedSelect from "src/app/components/AggregatedSelect/AggregatedSelect";
import XssFilter from "../../../../../../components/XssFilter/XssFilter";
import { ExternalFileField } from "../inputs/ExternalFileField/ExternalFileField";

const emptyFieldPlaceholder = (
  <div className="ws-table__empty-field">
    <PlusIcon />
  </div>
);

export function AccessLinkPageInputField({
  metafield,
  globalValue,
  dispatchValue,
  wsWorkspaceUuid,
  errors,
  setSubmitDisabled,
  extensionUuid,
  formType,
}) {
  const { taskUuid, type, metafieldUuid, token } = useParams();
  const [value, setValue] = useState(metafield.value);

  const {
    selectDataSetReducer: { selectDataSetList },
  } = useSelector((state) => state);

  const inputRef = useRef(null);

  useEffect(() => {
    dispatchValue(
      metafield.accessMode,
      metafield.uuid,
      metafield.metaFieldOrigin,
      metafield.metaFieldIsRequired,
      value,
    );
  }, [value, metafield]);

  useEffect(() => {
    if (metafield.accessMode !== "read" && globalValue !== value) {
      setValue(globalValue || "");
    }
  }, [globalValue, metafield]);

  const getSelectTypeOptions = () => {
    if (metafield.data?.wsSelectDataSetUuid) {
      return selectDataSetList[
        `dataSetType_${metafield.data.wsSelectDataSetUuid}`
      ];
    }
    if (metafield.data?.singleSelectOptions) {
      return metafield.data?.singleSelectOptions;
    }
    return [];
  };

  const renderPreview = () => {
    switch (metafield.type) {
      case MetaFieldType.File:
        return (
          <ExternalFileField
            files={value}
            setFiles={setValue}
            uuid={metafield.uuid}
            metafieldUuid={metafieldUuid}
            objectId={taskUuid}
            dataType={type}
            token={token}
            wsWorkspaceUuid={wsWorkspaceUuid}
            readOnly
            metaFieldType={metafield.metaFieldOrigin}
            extensionUuid={extensionUuid}
            formType={formType}
          />
        );
      case MetaFieldType.Percent:
        return (
          <div className="access-link-page__input-field__preview">
            {splitPercents(value)}%
          </div>
        );
      case MetaFieldType.Currency:
        return (
          <div className="access-link-page__input-field__preview">
            <div className="access-link-page__input-field__preview-prefix">
              {metafield.data.currencyCode}
            </div>
            {splitNumbers(value)}
          </div>
        );
      case MetaFieldType.BoolVal:
        return (
          <CustomCheckbox
            id={`${metafield.uuid}-${taskUuid}`}
            name={metafield.uuid}
            checked={value}
            onChange={(e) => {
              setValue(e.target.checked);
            }}
            disabled
          />
        );
      case MetaFieldType.Number:
        return (
          <div className="access-link-page__input-field__preview">
            {value ? splitNumbers(value) : "-"}
          </div>
        );
      case MetaFieldType.Text:
      case MetaFieldType.SocialProfiles:
        return (
          <div className="access-link-page__input-field__preview">
            <div
              className="access-link-page__input-field__preview--text"
              dangerouslySetInnerHTML={value && { __html: value || "-" }}
            />
          </div>
        );
      case MetaFieldType.SingleSelect: {
        const options = getSelectTypeOptions();
        return (
          <SelectLabel
            value={options.find((o) => o.value === value)}
            emptyFieldPlaceholder={emptyFieldPlaceholder}
          />
        );
      }
      case MetaFieldType.MultiSelect:
      case MetaFieldType.AggregatedSelect: {
        const options = getSelectTypeOptions();
        return (
          <span className="access-link-page__input-field__multi-select">
            {metafield.value.map((value) => (
              <SelectLabel
                value={options.find((o) => o.value === value)}
                emptyFieldPlaceholder={emptyFieldPlaceholder}
              />
            ))}
          </span>
        );
      }
      case MetaFieldType.Rating:
        return <RatingSelector disabled value={parseInt(value || "0")} />;
      default:
        return (
          <div className="access-link-page__input-field__preview">
            {value || "-"}
          </div>
        );
    }
  };

  const renderInput = () => {
    switch (metafield.type) {
      case MetaFieldType.Text:
        return (
          <TextBoxWrapper
            id="access-link-textbox"
            value={value || ""}
            onChange={(e) => setValue(e)}
            className={errors.includes(metafield.uuid) && "error"}
            placeholder=""
          />
        );
      case MetaFieldType.SocialProfiles:
        return (
          <TextBoxWrapper
            id="access-link-textbox"
            value={value || ""}
            onChange={(e) => setValue(e)}
            className={errors.includes(metafield.fieldId) && "error"}
            placeholder=""
          />
        );
      case MetaFieldType.Number:
        return (
          <CustomInput
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyPress={(e) => ["e", "E"].includes(e.key) && e.preventDefault()}
            type="number"
            disabled={metafield.accessMode === "read"}
            fullWidth
            className={errors.includes(metafield.uuid) && "error"}
          />
        );
      case MetaFieldType.File:
        return (
          <ExternalFileField
            files={value}
            setFiles={setValue}
            uuid={metafield.uuid}
            metafieldUuid={metafieldUuid}
            objectId={taskUuid}
            dataType={type}
            token={token}
            wsWorkspaceUuid={wsWorkspaceUuid}
            metaFieldType={metafield.metaFieldOrigin}
            setSubmitDisabled={setSubmitDisabled}
            extensionUuid={extensionUuid}
            formType={formType}
          />
        );
      case MetaFieldType.Percent:
        return (
          <PercentInput
            className={classNames([
              "external-percent-input",
              errors.includes(metafield.uuid) && "error",
            ])}
            rawValue={value}
            setRawValue={setValue}
          />
        );
      case MetaFieldType.Currency:
        return (
          <div
            className={classNames([
              "external-currency-input",
              errors.includes(metafield.uuid) && "error",
            ])}
            onClick={() => inputRef.current.focus()}
          >
            <CurrencyInput
              inputRef={inputRef}
              rawValue={value}
              setRawValue={setValue}
              prefix={metafield.data.currencyCode}
            />
          </div>
        );
      case MetaFieldType.BoolVal:
        return (
          <CustomCheckbox
            id={`${metafield.uuid}-${taskUuid}`}
            name={metafield.uuid}
            checked={value}
            onChange={(e) => {
              setValue(e.target.checked);
            }}
            blue
          />
        );
      case MetaFieldType.SingleSelect: {
        return (
          <Select
            mode="single"
            variant="disable_all"
            uuid={metafield.uuid}
            optionsData={getSelectTypeOptions()}
            fieldValue={value}
            emptyFieldPlaceholder={emptyFieldPlaceholder}
            updateValue={(newValue) => setValue(newValue)}
          />
        );
      }
      case MetaFieldType.MultiSelect: {
        return (
          <Select
            mode="multi"
            variant="disable_all"
            uuid={metafield.uuid}
            optionsData={getSelectTypeOptions()}
            fieldValue={value}
            emptyFieldPlaceholder={emptyFieldPlaceholder}
            updateValue={(newValue) => setValue(newValue)}
          />
        );
      }
      case MetaFieldType.AggregatedSelect: {
        return (
          <AggregatedSelect
            uuid={metafield.uuid}
            optionsData={getSelectTypeOptions()}
            fieldValue={value}
            emptyFieldPlaceholder={emptyFieldPlaceholder}
            updateValue={(newValue) => setValue(newValue)}
          />
        );
      }
      case MetaFieldType.Rating:
        return (
          <RatingSelector
            onValueChange={setValue}
            value={parseInt(value || "0")}
          />
        );
      default:
        return (
          <div className="access-link-page__input-field__placeholder">
            <IDHFormattedMessage
              id="ws_field_unavailable"
              defaultMessage="Field unavailable."
            />
          </div>
        );
    }
  };

  const safeWsMetaFieldDescription = XssFilter(
    linkifyString(metafield.metaFieldDescription),
    ["b", "i", "br", "a"],
    ["target", "rel", "href"],
  );

  return (
    <div className="access-link-page__input-field">
      <div className="access-link-page__input-field-name-and-description">
        <CustomInputLabel
          aboveInput
          required={metafield.metaFieldIsRequired}
          className="access-link-page__input-field-label"
        >
          {metafield.name}
        </CustomInputLabel>
        {metafield.metaFieldDescription && (
          <div
            className="access-link-page__input-field-description"
            dangerouslySetInnerHTML={{
              __html: safeWsMetaFieldDescription,
            }}
          />
        )}
      </div>
      {metafield.accessMode === "write" ? renderInput() : renderPreview()}

      {errors.includes(metafield.uuid) && (
        <div className="access-link-page__input-field__error-message">
          <InfoRed />
          &nbsp;
          <IDHFormattedMessage
            id="ws_filling_this_field_is_required"
            defaultMessage="Filling this field is required"
          />
        </div>
      )}
    </div>
  );
}
