import React from "react";
import "./AfterExportModal.scss";

import Modal, {
  ModalColumn,
  ModalRow,
  ModalText,
  ModalTitle,
} from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

import { ReactComponent as SolvingProblemImage } from "src/images/solving-problem.svg";

function AfterExportModal(props) {
  const { onClose } = props;

  return (
    <Modal
      onClose={onClose}
      className="after-export-modal"
      displayCancelButton
      closeButtonVariant="white-with-border"
    >
      <ModalRow>
        <ModalColumn>
          <ModalTitle>
            <IDHFormattedMessage
              id="front_generating_report"
              defaultMessage="Generating report"
            />
          </ModalTitle>
          <div className="after-export-modal__text">
            <IDHFormattedMessage
              id="front_generating_report_text"
              defaultMessage="This process may take some time. <br> The report will be sent to your email
            address once it is ready."
            />
          </div>
        </ModalColumn>
        <ModalColumn>
          <SolvingProblemImage />
        </ModalColumn>
      </ModalRow>
    </Modal>
  );
}

export default AfterExportModal;
