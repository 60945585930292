import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  clearGlobalTaskCreatorLists,
  getGlobalTaskCreatorLists,
} from "src/redux/creator-database/creatorDatabaseActions";
import { CustomTable } from "src/app/components/CustomTable/CustomTable";
import { GlobalTaskCreatorListItem } from "src/redux/creator-database/types";
import { Img } from "src/app/components/Img/Img";
import { StackedAvatars } from "src/app/components/StackedAvatars/StackedAvatars";
import goToImage from "src/images/share-alt.svg";
import { EmptyActivityTab } from "src/app/Task/tabs/ActivityTab/EmptyActivityTab";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import avatarWithLock from "src/images/avatar-with-lock.svg";
import { ROUTES, getRoute } from "src/utils/ROUTES";
import { RootState } from "src/redux/reducers";
import { SkeletonLoader, actionColumnWidth, nameColumnWidth } from "./utils";

const columns = [
  {
    Header: "NAME",
    accessor: "name",
    width: nameColumnWidth,
  },
  {
    Header: "ACTION",
    accessor: "action",
    width: actionColumnWidth,
  },
];

interface GlobalActivityTabCampaignsProps {
  workspaceUuid: string;
  taskId: string;
}

interface Row {
  name: JSX.Element;
  action?: JSX.Element;
  onRowClick?: () => void;
}

export function GlobalActivityTabCreatorLists({
  workspaceUuid,
  taskId,
}: GlobalActivityTabCampaignsProps) {
  const [rows, setRows] = useState<Row[]>([]);

  const dispatch = useDispatch();
  const {
    creatorDatabaseReducer: {
      isGlobalTaskCreatorListsLoading,
      globalTaskCreatorLists,
    },
  } = useSelector((state: RootState) => state);

  const handleRowClick = (
    wsWorkspaceUuid: string,
    wsListUuid: string,
    socialProvider: string,
    socialUsername: string,
  ) => {
    const url = getRoute(
      ROUTES.CREATOR_DISCOVERY_PROFILE,
      {
        wsWorkspaceUuid,
        wsListUuid,
        socialProvider,
        socialUsername,
      },
      {
        activeTab: "selected",
      },
    );

    window.open(url);
  };

  const generateRows = (data: GlobalTaskCreatorListItem[]) => {
    return data.map((item) => {
      if (item.listId) {
        return {
          name: (
            <AvatarsAndListName
              avatars={item.userAvatars}
              listName={item.listName}
            />
          ),
          action: <Img src={goToImage} fallbackImageSrc={goToImage} />,
          onRowClick: () =>
            handleRowClick(
              workspaceUuid,
              item.listId!,
              item.socialProvider!,
              item.socialUsername!,
            ),
        };
      }
      return {
        name: (
          <AvatarsAndListName
            avatars={item.userAvatars}
            listName={item.listName}
            isPrivate
          />
        ),
      };
    });
  };

  useEffect(() => {
    if (!workspaceUuid || !taskId) return;
    dispatch(getGlobalTaskCreatorLists(workspaceUuid, taskId));
  }, [workspaceUuid, taskId]);

  useEffect(() => {
    if (globalTaskCreatorLists && globalTaskCreatorLists.length) {
      setRows(generateRows(globalTaskCreatorLists));
    }
  }, [globalTaskCreatorLists]);

  useEffect(() => {
    return () => {
      dispatch(clearGlobalTaskCreatorLists());
    };
  }, []);

  if (isGlobalTaskCreatorListsLoading) {
    return <SkeletonLoader avatarShape="circle" />;
  }

  return (
    <div className="activity-tab__table-wrapper">
      <CustomTable
        headerClassName="activity-tab__table-header"
        columns={columns}
        data={rows}
        isLoading={isGlobalTaskCreatorListsLoading}
        noRowsPlaceholder={<EmptyActivityTab />}
      />
    </div>
  );
}

interface AvatarsAndListNameProps {
  avatars: string[];
  listName: string;
  isPrivate?: boolean;
}

function AvatarsAndListName({
  avatars,
  listName,
  isPrivate,
}: AvatarsAndListNameProps) {
  return (
    <div className="avatar-and-list-name">
      {isPrivate ? (
        <Img src={avatarWithLock} fallbackImageSrc={avatarWithLock} />
      ) : (
        <StackedAvatars avatars={avatars} maxAvatars={3} />
      )}
      {isPrivate ? (
        <IDHFormattedMessage id="ws_private" defaultMessage="Private" />
      ) : (
        listName
      )}
    </div>
  );
}
