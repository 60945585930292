import axios from "axios";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { CoverImage } from "src/redux/project/types";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { PictogramVariants } from "src/app/components/Pictogram/Pictogram";
import { showToast } from "src/app/methods/showToast";
import { API_URLS } from "src/utils/API_URLS";
import "./Performance.scss";
import { RootState } from "src/redux/reducers";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import { TaskType } from "src/types";
import Loader from "src/app/components/Loader/Loader";
import { EmptyPerformance } from "./components/EmptyPerformance/EmptyPerformance";
import { PerformanceBox } from "./components/PerformanceBox/PerformanceBox";
import {
  IPerformanceFilters,
  InitialPerformanceFilters,
} from "./components/PerformanceFilters/PerformanceFilters";

export enum PerformanceContext {
  GlobalTaskDetails = "global-task-details",
  Task = "task",
}

export interface BreakdownValues {
  value: string;
  projectUuid: string;
  projectTitle: string;
  projectCover: CoverImage;
  extraFieldsValues: Metadata[];
  wsTaskType: TaskType;
  wsTaskUuid: string;
}
export interface PerformanceElement {
  defaultVisualizationBreakdown: string;
  icon: {
    name: string;
    color: PictogramVariants;
  };
  title: string;
  value: number;
  breakdownValues: {
    campaign: { [key: string]: BreakdownValues };
    month: { [key: string]: BreakdownValues };
  };
  breakdownValuesDetails: {
    campaign: { [key: string]: { [key: string]: string } };
    month: { [key: string]: { [key: string]: string } };
  };
}

interface Props {
  context: PerformanceContext;
  taskId: string;
  data: PerformanceElement[];
  setData: Dispatch<SetStateAction<PerformanceElement[]>>;
  filters: IPerformanceFilters;
  hasInitialFilters?: boolean;
}

export const Performance: React.FC<Props> = (props) => {
  const { context, taskId, data, setData, filters, hasInitialFilters } = props;

  const [loading, setLoading] = useState(true);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [filteredDataGathered, setFilteredDataGathered] =
    useState(!hasInitialFilters);

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    if (firstLoaded) {
      getData();
    }
  }, [firstLoaded]);

  const getData = () => {
    const url = API_URLS.getGlobalCreatorAnalytics
      .replace(":wsWorkspaceUuid:", activeWorkspaceUuid)
      .replace(":wsGlobalCreatorUuid:", taskId);

    let filtersToSend = filters;

    if (!firstLoaded && hasInitialFilters) {
      filtersToSend = InitialPerformanceFilters;
    }

    setLoading(true);

    axios
      .get(url, {
        params: {
          date: filtersToSend.time,
          projectUuids: filtersToSend.campaigns,
        },
      })
      .then((response) => {
        const {
          data: { content },
        } = response;
        setData(content);

        if (hasInitialFilters && content.length) {
          if (!firstLoaded) {
            setFirstLoaded(true);
          } else if (!filteredDataGathered) {
            setFilteredDataGathered(true);
          }
        }
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_something_went_wrong"
            defaultMessage="Something went wrong"
          />,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const performanceData = useMemo(() => {
    const filteredData = data.filter((item) => item.breakdownValues);

    if (hasInitialFilters) {
      if (filteredDataGathered) {
        return filteredData;
      }

      return [];
    }

    return filteredData;
  }, [data, filteredDataGathered, context]);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (!performanceData.length && !loading) {
    return <EmptyPerformance />;
  }

  return (
    <AnimatedDiv className="performance">
      {performanceData.map((item) => (
        <PerformanceBox data={item} />
      ))}
    </AnimatedDiv>
  );
};
