import React from "react";
import "./AddElementsToReportDropdown.scss";

import { useDispatch, useSelector } from "react-redux";
import { uuidv7 as uuid } from "uuidv7";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as PlusIcon } from "../../../../images/plus-transparent.svg";
import { ReactComponent as HeaderIcon } from "../../../../images/generator/header.svg";
import { ReactComponent as SubheaderIcon } from "../../../../images/generator/subheader.svg";
import { ReactComponent as ParagraphIcon } from "../../../../images/generator/paragraph.svg";
import { ReactComponent as TextFieldIcon } from "../../../../images/generator/text-field.svg";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "../../../components/Dropdown/Dropdown";
import { setNewGridElements, setReportLayout } from "../../../../redux";
import { addCustomGridElement } from "../../functions/grid-elements/addCustomGridElement";
import { addElementToLayout } from "../../../generator-data/functions/addElementToLayout";
import { capitalizeFirstLetter } from "../../../../utils/methods";

export function AddElementsToReportDropdown(props) {
  const { format } = props;

  const {
    reportReducer: { layout, gridElements, hiddenGridElements },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const addItemToReport = (type, setIsOpen) => {
    const id = `${type}-${uuid()}`;

    const element = {
      label: id,
      gridBoxType: type,
      section: id,
      text: capitalizeFirstLetter(type),
    };
    dispatch(setNewGridElements([...gridElements, element]));
    addElementToLayout(
      id,
      layout,
      dispatch,
      type,
      format,
      setReportLayout,
      hiddenGridElements,
      true,
    );

    setIsOpen(false);
  };

  return (
    <Dropdown toRight>
      {(isOpen, setIsOpen) => (
        <>
          <div
            className="add-elements-to-report-dropdown__button"
            onClick={() => setIsOpen(true)}
          >
            <PlusIcon />
            <IDHFormattedMessage id="ws_add" defaultMessage="Add" />
          </div>

          <DropdownMenu isOpen={isOpen}>
            <DropdownMenuItem
              onClick={() => addItemToReport("header", setIsOpen)}
            >
              <HeaderIcon />
              <IDHFormattedMessage id="ws_header" defaultMessage="Header" />
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => addItemToReport("subheader", setIsOpen)}
            >
              <SubheaderIcon />
              <IDHFormattedMessage
                id="ws_subheader"
                defaultMessage="Subheader"
              />
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => addItemToReport("paragraph", setIsOpen)}
            >
              <ParagraphIcon />
              <IDHFormattedMessage
                id="ws_paragraph"
                defaultMessage="Paragraph"
              />
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => addItemToReport("text", setIsOpen)}
            >
              <TextFieldIcon />
              <IDHFormattedMessage
                id="ws_text_field"
                defaultMessage="Text field"
              />
            </DropdownMenuItem>
          </DropdownMenu>
        </>
      )}
    </Dropdown>
  );
}
