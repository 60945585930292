import { IColumn } from "src/app/project/Project";
import {
  SET_ACTIVE_SORTING_COLUMN,
  SET_SORTING_DIRECTION,
  SET_AVAILABLE_FILTERS,
  SET_SELECTED_FILTERS,
  SET_LOCAL_SYNC_TOGGLE,
} from "./taskFiltersTypes";
import { TaskSortingDirection } from "./types";

interface TaskFiltersReducerState {
  activeSortingColumn: IColumn | string | null;
  sortingDirection: TaskSortingDirection;
  availableFilters: any;
  selectedFilters: any;
  localSyncToggle: boolean;
}

const initialState: TaskFiltersReducerState = {
  activeSortingColumn: null,
  sortingDirection: TaskSortingDirection.Descending,
  availableFilters: [],
  selectedFilters: { selections: null },
  localSyncToggle: false,
};

function taskFiltersReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_ACTIVE_SORTING_COLUMN:
      return {
        ...state,
        activeSortingColumn: action.payload.activeSortingColumn,
      };
    case SET_SORTING_DIRECTION:
      return {
        ...state,
        sortingDirection: action.payload.sortingDirection,
      };
    case SET_AVAILABLE_FILTERS:
      return {
        ...state,
        availableFilters: action.payload.availableFilters,
      };
    case SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: action.payload.selectedFilters,
      };
    case SET_LOCAL_SYNC_TOGGLE:
      return {
        ...state,
        localSyncToggle: action.payload.localSyncToggle,
      };
    default:
      return state;
  }
}

export default taskFiltersReducer;
