import React from "react";
import classNames from "classnames";
import "./DashedBox.scss";

function DashedBox(props) {
  const { children, className, focused } = props;

  return (
    <div
      className={classNames(
        "dashed-box",
        { "dashed-box--focused": focused },
        className,
      )}
    >
      {children}
    </div>
  );
}

export { DashedBox };
