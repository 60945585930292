import React from "react";

import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalColumn } from "src/app/components/Modal/Modal";
import { XtrmPaymentsDetails } from "src/types";

interface XTRMPaymentsGeneralProps {
  xtrmPaymentsDetails: XtrmPaymentsDetails;
}

export default function XTRMPaymentsGeneral({
  xtrmPaymentsDetails,
}: XTRMPaymentsGeneralProps) {
  const firstName = xtrmPaymentsDetails.companyContact.split(" ")[0] ?? "";
  const lastName = xtrmPaymentsDetails.companyContact.split(" ")[1] ?? "";

  return (
    <div className="xtrmPayments__general-wrapper">
      <div className="xtrmPayments__input-row">
        <ModalColumn>
          <CustomInputLabel htmlFor="legal-company-name">
            <IDHFormattedMessage
              id="ws_legal_company_name"
              defaultMessage="Legal company name"
            />
          </CustomInputLabel>
          <CustomInput
            id="legal-company-name"
            type="text"
            defaultValue={xtrmPaymentsDetails.companyName}
            disabled
          />
        </ModalColumn>
        <ModalColumn>
          <CustomInputLabel htmlFor="email-address">
            <IDHFormattedMessage
              id="ws_email_address"
              defaultMessage="E-mail address"
            />
          </CustomInputLabel>
          <CustomInput
            id="email-address"
            type="text"
            defaultValue={xtrmPaymentsDetails.companyEmail}
            disabled
          />
        </ModalColumn>
      </div>

      <div className="xtrmPayments__input-row">
        <ModalColumn>
          <CustomInputLabel htmlFor="first-name">
            <IDHFormattedMessage
              id="ws_first_name"
              defaultMessage="First name"
            />
          </CustomInputLabel>
          <CustomInput
            id="first-name"
            type="text"
            defaultValue={firstName}
            disabled
          />
        </ModalColumn>
        <ModalColumn>
          <CustomInputLabel htmlFor="last-name">
            <IDHFormattedMessage id="ws_last_name" defaultMessage="Last name" />
          </CustomInputLabel>
          <CustomInput
            id="last-name"
            type="text"
            defaultValue={lastName}
            disabled
          />
        </ModalColumn>
      </div>
    </div>
  );
}
