import React, { useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { uuidv7 as uuid } from "uuidv7";
import { isEqual, isEmpty } from "lodash";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { AccessLinkMetaField } from "src/app/metafields/AccessLinkMetaField/AccessLinkMetaField";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { Coupon } from "src/redux/coupon/couponReducer";
import { getCouponsList } from "src/redux/coupon/couponActions";
import { TooltipedEllipsis } from "src/app/components/TooltipedEllipsis/TooltipedEllipsis";
import { setGlobalTaskMetaValue } from "src/redux/creator-database/creatorDatabaseActions";
import { DictionaryElementSelector } from "src/app/dropdowns/DictionaryElementSelector/DictionaryElementSelector";
import { isAccessLevelReadOnly } from "src/app/components/GlideDataGrid/utils";
import RatingSelector from "src/app/components/RatingSelector/RatingSelector";
import { getRoute, ROUTES } from "src/utils/ROUTES";
import { RootState } from "src/redux/reducers";
import AggregatedSelect from "src/app/components/AggregatedSelect/AggregatedSelect";
import Select from "../../../components/Select/Select";
import ChooseActionDropdown from "../../../dropdowns/ChooseActionDropdown/ChooseActionDropdown";
import ChoosePersonDropdown from "../../../dropdowns/ChoosePersonDropdown/ChoosePersonDropdown";
import CustomDatePicker, {
  DatePickerWrapper,
} from "../../../components/CustomDatePicker/CustomDatePicker";
import { showToast } from "../../../methods/showToast";
import {
  changeTaskMetaFieldValue,
  getProjectDetails,
  getTaskDetails,
  setDictionaryElementMetaValue,
  setTableInputFocused,
  updateGlobalMetaFieldProjectWizardFields,
  updateProjectMetaFieldOnList,
  updateProjectMetaValue,
} from "../../../../redux";
import { Metadata } from "../../Subtask/Subtask";
import { ACTION_TYPES } from "../../../project/TableField/utils";

import AvatarPlaceholder from "../../../../images/avatar-purple.svg";
import ContentPlaceholder from "../../../../images/content-placeholder.svg";
import { ReactComponent as ShareAltIcon } from "../../../../images/share-alt.svg";
import { ReactComponent as RemoveIcon } from "../../../../images/close-alt.svg";
import { ReactComponent as EditIcon } from "../../../../images/edit.svg";
import { ReactComponent as PlusIcon } from "../../../../images/plus-transparent.svg";

import { IMember } from "../../../project/ProjectTypes";
import AddButton from "../../../components/AddButton/AddButton";
import { SeperatedNumberInput } from "../../../components/MaskedInputs/SeperatedNumberInput";
import { PercentInput } from "../../../components/MaskedInputs/PercentInput";
import { CurrencyInput } from "../../../components/MaskedInputs/CurrencyInput";
import { tableDataType } from "../../../components/Table/Table";
import { FieldSynchronizationWarningModal } from "../../../modals/FieldSynchronizationWarningModal/FieldSynchronizationWarningModal";
import { MultipleFileField } from "../../../metafields/MultipleFileField/MultipleFileField";
import { TextBoxWrapper } from "../../../components/TextBoxWrapper/TextBoxWrapper";
import { getImageSource } from "../../../../utils/methods";
import SelectDropdown from "../../../dropdowns/SelectDropdown/SelectDropdown";
import { isMetaFieldRemovable, showValueIsRequiredError } from "./utils";

interface SingleMetaFieldProps {
  metaData: Metadata;
  dataType: tableDataType;
  externalTaskId?: string;
  exteralProjectId?: any;
  wsGlobalProjectMetaFieldUuid?: string;
  columnValueSource?: string | null;
  overrideValue?: boolean;
  blockManualEdit?: boolean;
}

function SingleMetaField({
  metaData,
  dataType,
  externalTaskId,
  exteralProjectId,
  wsGlobalProjectMetaFieldUuid,
  columnValueSource,
  overrideValue,
  blockManualEdit,
}: SingleMetaFieldProps) {
  const [value, setValue] = useState<any>(metaData.value || "");
  const [placeholderValue, setPlaceholderValue] = useState("");
  const [member, setMember] = useState<any>({});
  const [action, setAction] = useState<any>({});

  const [showEditMark, setShowEditMark] = useState(metaData.manuallyEdited);
  const [disableFieldClick, setDisableFieldClick] = useState(
    metaData.shouldTriggerEditWarning,
  );
  const [showWarningModal, setShowWarningModal] = useState(false);

  const params = useParams<{
    projectId: string;
    taskId: string;
    dictionaryUuid: string;
  }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const fieldContentRef = useRef<HTMLSpanElement>(null);
  const placeholderRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<any>();

  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );

  const couponsList = useSelector(
    (state: RootState) => state.couponReducer.couponsList,
  );

  const membersList = useSelector(
    (state: RootState) => state.projectReducer.activeMembersList,
  );
  const projectBasicData = useSelector(
    (state: RootState) => state.projectReducer.projectBasicData,
  );

  const creatorAutocomplete = useSelector(
    (state: RootState) => state.taskReducer.creatorAutocomplete,
  );

  const contentAutocomplete = useSelector(
    (state: RootState) => state.taskReducer.contentAutocomplete,
  );
  const publicationAutocomplete = useSelector(
    (state: RootState) => state.taskReducer.publicationAutocomplete,
  );

  const updateMetaValue = async (newValue: any) => {
    try {
      switch (dataType) {
        case tableDataType.Dictionary:
          if (externalTaskId) {
            dispatch(
              setDictionaryElementMetaValue(
                [externalTaskId],
                metaData.uuid,
                newValue,
              ),
            );
          }
          break;

        case tableDataType.GlobalTask:
          if (externalTaskId) {
            dispatch(
              setGlobalTaskMetaValue(externalTaskId, metaData.uuid, newValue),
            );
          }
          break;

        case tableDataType.Task:
          dispatch(
            changeTaskMetaFieldValue(params.taskId, metaData.uuid, newValue),
          );
          break;

        case tableDataType.ProjectDetails:
          await dispatch(
            updateProjectMetaValue(params.projectId, metaData.uuid, newValue),
          );
          dispatch(getProjectDetails(params.projectId));
          break;

        case tableDataType.Project:
          if (exteralProjectId && wsGlobalProjectMetaFieldUuid) {
            dispatch(
              updateProjectMetaFieldOnList(
                exteralProjectId,
                metaData.uuid,
                newValue,
                wsGlobalProjectMetaFieldUuid,
              ),
            );
          }
          break;
        case tableDataType.GlobalMetaFieldProjectWizardFields:
          dispatch(
            updateGlobalMetaFieldProjectWizardFields(metaData.uuid, newValue),
          );
          break;
        default:
          return null;
      }

      if (metaData.shouldTriggerEditWarning) {
        setShowEditMark(true);
      }
    } catch (error) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_could_not_update_meta_field"
          defaultMessage="Could not update meta field."
        />,
      );
    }
  };

  const getTaskAutocompleteData = () => {
    switch (metaData.type) {
      case MetaFieldType.Creator:
        return creatorAutocomplete;
      case MetaFieldType.Content:
        return contentAutocomplete;
      case MetaFieldType.PublicationRelated:
        return publicationAutocomplete;
      default:
        return null;
    }
  };

  const objectId = useMemo(() => {
    if (dataType === tableDataType.Task) {
      return params.taskId;
    }
    if (externalTaskId) {
      return externalTaskId;
    }
    if (exteralProjectId) {
      return exteralProjectId;
    }

    return params.projectId;
  }, [dataType, params, externalTaskId, exteralProjectId]);

  useEffect(() => {
    setValue(metaData.value);
  }, [metaData.value]);

  useEffect(() => {
    if (member?.id && metaData.value !== member?.id) {
      updateMetaValue(member.id);
    }
  }, [member]);

  useEffect(() => {
    if (action?.taskId && metaData.value !== action?.taskId) {
      updateMetaValue(action.taskId);
    }
  }, [action]);

  useEffect(() => {
    if (metaData.type === MetaFieldType.Member && membersList?.length) {
      const memberCopy = membersList.find(
        (member: IMember) => member.id === metaData.value,
      );

      setMember(memberCopy);
    }
  }, [metaData.value, membersList]);

  useEffect(() => {
    setDisableFieldClick(metaData.shouldTriggerEditWarning);
  }, [metaData.shouldTriggerEditWarning]);

  useEffect(() => {
    setShowEditMark(metaData.manuallyEdited);
  }, [metaData.manuallyEdited]);

  useEffect(() => {
    if (ACTION_TYPES.includes(metaData.type)) {
      const actionCopy = getTaskAutocompleteData()?.find(
        (item) => item.taskId === metaData.value,
      );

      setAction({
        ...actionCopy,
        metaFieldId: metaData.uuid,
        metaFieldValue: metaData.value,
        metaFieldName: metaData.name,
      });
    }
  }, [
    metaData,
    creatorAutocomplete,
    contentAutocomplete,
    publicationAutocomplete,
  ]);

  const onDateChange = async (newDate: any) => {
    if (metaData.uuid || exteralProjectId) {
      try {
        await updateMetaValue(newDate);

        setValue((prevValue: any) => ({
          ...prevValue,
          date: newDate,
        }));
      } catch (error) {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_could_not_change_due_date"
            defaultMessage="Could not change due date."
          />,
        );
      }
    }
  };

  const removeValue = () => {
    updateMetaValue(null);
    setValue(null);
  };

  const handleUpdateValue = (
    inputValue: string | null,
    isRemovable: boolean,
  ) => {
    if (isEmpty(inputValue) && !isEmpty(metaData.value) && !isRemovable) {
      setValue(metaData.value);
      showValueIsRequiredError();
      return;
    }

    if (isEmpty(inputValue) && !isEmpty(metaData.value) && isRemovable) {
      removeValue();
      return;
    }

    if (isEmpty(inputValue) && isEmpty(metaData.value)) {
      setValue(null);
      return;
    }

    if (!isEqual(inputValue, metaData.value)) {
      updateMetaValue(inputValue);
    }
  };

  const inputOnBlur = (isRemovable: boolean, isReadOnly: boolean) => {
    if (isReadOnly) return;
    handleUpdateValue(value, isRemovable);
  };

  const inputOnBlurWithValue = (newValue: string, isRemovable: boolean) => {
    handleUpdateValue(newValue, isRemovable);
  };

  const [tempWidth, setTempWidth] = useState(50);

  const handleTaskOpen = (targetTaskId: string) => {
    const projectId = params.projectId || projectBasicData?.projectId;
    history.push(
      getRoute(ROUTES.TASK_DETAILS, {
        wsWorkspaceUuid: activeWorkspaceUuid,
        wsProjectUuid: projectId,
        wsTaskUuid: targetTaskId,
      }),
    );
  };

  function SingleMetaFieldAddButton() {
    if (columnValueSource) {
      // disable create local values if parent global meta field has value source
      return null;
    }

    return (
      <AddButton
        variant="rect"
        onClick={() => {
          setValue("");
          setTimeout(() => {
            inputRef.current?.focus();
          }, 15);
        }}
      />
    );
  }

  const clearMetaField = () => {
    updateMetaValue(null);
    setValue(null);
  };

  useEffect(() => {
    if (placeholderRef?.current) {
      setTempWidth(placeholderRef?.current?.clientWidth);
    }
  }, [placeholderValue]);

  const isProjectCurrencyInitialized = !(
    dataType === tableDataType.Project &&
    exteralProjectId &&
    metaData.value === null
  );

  useEffect(() => {
    if (inputRef?.current?.value) {
      const currencyCode = metaData?.data?.currencyCode;
      if (
        metaData.type === MetaFieldType.Currency &&
        typeof currencyCode === "string" &&
        !inputRef.current.value.includes(currencyCode)
      ) {
        setPlaceholderValue(`${currencyCode} ${inputRef.current.value}`);
      } else {
        setPlaceholderValue(inputRef.current.value);
      }
    }
  }, [inputRef?.current, inputRef?.current?.value, value, metaData.value]);

  const renderMetaField = () => {
    const isValueSourceReadOnly = Boolean(
      (dataType === tableDataType.Project ||
        dataType === tableDataType.GlobalTask ||
        dataType === tableDataType.ProjectDetails) &&
        metaData?.valueSource &&
        metaData.valueSource.length > 0,
    );

    const isReadOnly =
      overrideValue ||
      blockManualEdit ||
      isAccessLevelReadOnly(metaData.accessLevel) ||
      isValueSourceReadOnly;

    const isRemovable = isMetaFieldRemovable(dataType, metaData.valueRequired);

    const wsSelectDataSetUuid = metaData?.data?.wsSelectDataSetUuid;

    switch (metaData.type) {
      case MetaFieldType.Text:
        return value || value === "" ? (
          <div className="value-field">
            <TextBoxWrapper
              ref={inputRef}
              className="value-textbox"
              value={value || ""}
              onFocus={() => dispatch(setTableInputFocused(true))}
              onChange={(v: any) => {
                if (value !== v) setValue(v);
              }}
              onBlur={(newValue: string) => {
                inputOnBlurWithValue(newValue, isRemovable);
                dispatch(setTableInputFocused(false));
              }}
              readOnly={isReadOnly}
            />
            {value && !isReadOnly && isRemovable && (
              <RemoveIcon className="value-field__icon" onClick={removeValue} />
            )}
          </div>
        ) : (
          !isReadOnly && <SingleMetaFieldAddButton />
        );

      case MetaFieldType.Percent: {
        return value || value === "" ? (
          <div className="value-field">
            <span ref={placeholderRef} className="value-placeholder">
              {placeholderValue}
            </span>
            <PercentInput
              inputRef={inputRef}
              className="value-input"
              rawValue={value}
              setRawValue={setValue}
              onBlur={() => inputOnBlur(isRemovable, isReadOnly)}
              style={{ width: `${tempWidth}px` }}
              readOnly={isReadOnly}
            />
            {showEditMark && metaData.value && !isReadOnly && (
              <div className="task-meta-fields__value-content-edit-icon">
                <EditIcon />
                <IDHFormattedMessage id="ws_edited" defaultMessage="Edited" />
              </div>
            )}

            {!disableFieldClick && value && !isReadOnly && isRemovable && (
              <RemoveIcon className="value-field__icon" onClick={removeValue} />
            )}
          </div>
        ) : (
          !isReadOnly && <SingleMetaFieldAddButton />
        );
      }

      case MetaFieldType.Currency: {
        const currencyCode = metaData?.data?.currencyCode;
        const inlinePrefix =
          isProjectCurrencyInitialized &&
          dataType !== tableDataType.GlobalMetaFieldProjectWizardFields
            ? `${currencyCode} `
            : undefined;
        return value || value === "" ? (
          <div className="value-field">
            <span ref={placeholderRef} className="value-placeholder">
              {placeholderValue}
            </span>

            <CurrencyInput
              inputRef={inputRef}
              className="value-input"
              rawValue={value}
              setRawValue={setValue}
              onBlur={() => inputOnBlur(isRemovable, isReadOnly)}
              inlinePrefix={inlinePrefix}
              // @ts-ignore
              style={{ width: `${tempWidth}px` }}
              readOnly={isReadOnly}
            />

            {showEditMark && metaData.value && !isReadOnly && (
              <div className="task-meta-fields__value-content-edit-icon">
                <EditIcon />
                <IDHFormattedMessage id="ws_edited" defaultMessage="Edited" />
              </div>
            )}

            {!disableFieldClick && value && !isReadOnly && isRemovable && (
              <RemoveIcon className="value-field__icon" onClick={removeValue} />
            )}
          </div>
        ) : (
          !isReadOnly && <SingleMetaFieldAddButton />
        );
      }

      case MetaFieldType.Number:
      case MetaFieldType.SocialUrl: {
        return value || value === "" ? (
          <div className="value-field">
            <span ref={placeholderRef} className="value-placeholder">
              {placeholderValue}
            </span>
            <SeperatedNumberInput
              inputRef={inputRef}
              className="value-input"
              rawValue={value}
              setRawValue={(newValue: string) => setValue(newValue)}
              onBlur={() => inputOnBlur(isRemovable, isReadOnly)}
              style={{ width: `${tempWidth}px` }}
              readOnly={isReadOnly}
            />

            {showEditMark && metaData.value && !isReadOnly && (
              <div className="task-meta-fields__value-content-edit-icon">
                <EditIcon />
                <IDHFormattedMessage id="ws_edited" defaultMessage="Edited" />
              </div>
            )}
            {!disableFieldClick && value && !isReadOnly && isRemovable && (
              <RemoveIcon className="value-field__icon" onClick={removeValue} />
            )}
          </div>
        ) : (
          !isReadOnly && <SingleMetaFieldAddButton />
        );
      }

      case MetaFieldType.Member:
        return (
          <div className="value-field">
            {member?.id && (
              <>
                <ChoosePersonDropdown
                  value={member}
                  placeholder={member?.name}
                  choosePerson={setMember}
                  uuid={metaData?.uuid}
                  dataType={dataType}
                  wsTeamUuids={metaData?.data?.wsTeamUuids}
                  globalFieldId={
                    metaData?.wsGlobalTaskMetaFieldUuid ||
                    metaData?.wsGlobalProjectMetaFieldUuid
                  }
                  disabled={isReadOnly}
                >
                  <div
                    className={classNames(
                      "assignee-field",
                      isReadOnly && "assignee-field--disabled",
                    )}
                  >
                    <img
                      src={member.avatarUrl || AvatarPlaceholder}
                      alt="avatar"
                    />
                    <TooltipedEllipsis
                      className="assignee-field__username"
                      maxWidth={
                        dataType === tableDataType.Project && exteralProjectId
                          ? 125
                          : 250
                      }
                      content={member.name}
                    />
                  </div>
                </ChoosePersonDropdown>
                {!isReadOnly && isRemovable && (
                  <RemoveIcon
                    className="value-field__icon"
                    onClick={() => {
                      removeValue();
                      setMember(null);
                    }}
                  />
                )}
              </>
            )}
            {!member?.id && !isReadOnly && (
              <ChoosePersonDropdown
                value={member}
                placeholder={member?.name}
                choosePerson={setMember}
                uuid={metaData?.uuid}
                dataType={dataType}
                wsTeamUuids={metaData?.data?.wsTeamUuids}
                globalFieldId={
                  metaData?.wsGlobalTaskMetaFieldUuid ||
                  metaData?.wsGlobalProjectMetaFieldUuid
                }
              >
                {!isReadOnly && <AddButton variant="circle" />}
              </ChoosePersonDropdown>
            )}
          </div>
        );

      case MetaFieldType.SingleSelect:
        return (
          <div className="value-field">
            <SelectDropdown
              uuid={metaData?.uuid}
              dataType={dataType}
              fieldData={metaData}
              fieldValue={value}
              onValueChange={updateMetaValue}
              selectOptionType="single"
              isRemovable={isRemovable}
              wsSelectDataSetUuid={wsSelectDataSetUuid}
              emptyFieldPlaceholder={<AddButton variant="rect" />}
              readOnly={isReadOnly}
              removeValueButton={
                <RemoveIcon
                  className="value-field__icon"
                  onClick={clearMetaField}
                />
              }
            />
          </div>
        );

      case MetaFieldType.MultiSelect:
        return (
          <div className="value-field">
            <SelectDropdown
              uuid={metaData?.uuid}
              dataType={dataType}
              fieldData={metaData}
              fieldValue={value}
              onValueChange={updateMetaValue}
              selectOptionType="multi"
              isRemovable={isRemovable}
              wsSelectDataSetUuid={wsSelectDataSetUuid}
              stackLabels={
                dataType === tableDataType.GlobalMetaFieldProjectWizardFields
              }
              emptyFieldPlaceholder={<AddButton variant="rect" />}
              readOnly={isReadOnly}
              removeValueButton={
                value && (
                  <RemoveIcon
                    className="value-field__icon"
                    onClick={clearMetaField}
                  />
                )
              }
            />
          </div>
        );

      case MetaFieldType.AggregatedSelect:
        return (
          <div className="value-field">
            <AggregatedSelect
              uuid={metaData?.uuid}
              dataType={dataType}
              optionsData={metaData?.data?.singleSelectOptions}
              fieldValue={value}
              updateValue={updateMetaValue}
              emptyFieldPlaceholder={<AddButton variant="rect" />}
            />
            {value && (
              <RemoveIcon
                className="value-field__icon"
                onClick={clearMetaField}
              />
            )}
          </div>
        );

      case MetaFieldType.Coupon:
        const couponOptions = couponsList
          .filter((coupon: Coupon) =>
            coupon.isAssigned ? coupon?.wsTask?.taskId === objectId : true,
          )
          .map((coupon: Coupon) => ({
            name: coupon.code,
            value: coupon.uuid,
            rank: "a",
            color: "45485b",
            backgroundColor: "f6f6f7",
          }));

        return (
          <>
            <Select
              mode="single"
              optionsData={couponOptions}
              uuid={metaData?.uuid || ""}
              fieldValue={value}
              fieldName={metaData?.name}
              variant="disable_all"
              updateValue={(newValue: any) => {
                setValue(newValue);
                updateMetaValue(newValue);
                dispatch(getCouponsList(activeWorkspaceUuid));
              }}
              emptyFieldPlaceholder={
                !isReadOnly && (
                  <div className="tasks-table__empty-field">
                    <PlusIcon />
                  </div>
                )
              }
              dataType={dataType}
              readOnly={isReadOnly}
            />

            {value && !isReadOnly && isRemovable && (
              <RemoveIcon className="value-field__icon" onClick={removeValue} />
            )}
          </>
        );

      case MetaFieldType.Date:
        return (
          <div className="value-field">
            <DatePickerWrapper value={value?.date} readOnly={isReadOnly}>
              <CustomDatePicker
                initialDate={value?.date}
                dateChangeHandler={onDateChange}
                readOnly={isReadOnly}
              />
            </DatePickerWrapper>

            {value && !isReadOnly && isRemovable && (
              <RemoveIcon className="value-field__icon" onClick={removeValue} />
            )}
          </div>
        );

      case MetaFieldType.Creator:
      case MetaFieldType.Content:
      case MetaFieldType.PublicationRelated: {
        return (
          <div className="value-field">
            <ChooseActionDropdown
              value={action}
              data={getTaskAutocompleteData()}
              chooseAction={setAction}
              placeholder={action?.title}
              fieldType={metaData.type}
              disabled={isReadOnly}
            >
              <span
                className={classNames("action-field", {
                  "action-field--circle":
                    metaData.type === MetaFieldType.Creator,
                })}
              >
                {action?.title && (
                  <>
                    {metaData.type === MetaFieldType.Creator ? (
                      <img
                        src={getImageSource(
                          action?.cover,
                          "tiny",
                          AvatarPlaceholder,
                        )}
                        alt="avatar"
                      />
                    ) : (
                      <img
                        src={getImageSource(
                          action?.cover,
                          "tiny",
                          ContentPlaceholder,
                        )}
                        alt="content"
                      />
                    )}
                    {action?.title}
                  </>
                )}
                {!action?.title && !isReadOnly && (
                  <AddButton
                    variant={
                      metaData.type === MetaFieldType.Creator
                        ? "circle"
                        : "square"
                    }
                  />
                )}
              </span>
            </ChooseActionDropdown>

            {action?.title && (
              <>
                <ShareAltIcon
                  className="value-field__icon"
                  onClick={() => {
                    handleTaskOpen(action.taskId);
                  }}
                />

                {!isReadOnly && isRemovable && (
                  <RemoveIcon
                    className="value-field__icon"
                    onClick={() => {
                      setAction(null);
                      removeValue();
                    }}
                  />
                )}
              </>
            )}
          </div>
        );
      }
      case MetaFieldType.AccessLink:
        return (
          <AccessLinkMetaField
            accessLinkValue={value}
            uuid={metaData?.uuid}
            objectId={objectId}
            dataType={dataType}
            readOnly={isReadOnly}
          />
        );
      case MetaFieldType.File:
        return (
          <MultipleFileField
            files={value?.length ? value : []}
            uuid={metaData.uuid || uuid()}
            objectId={objectId}
            dataType={dataType}
            readOnly={isReadOnly}
          />
        );
      case MetaFieldType.BoolVal:
        return (
          <CustomCheckbox
            id={
              metaData.uuid && objectId
                ? `${metaData.uuid}-${objectId}2`
                : uuid()
            }
            name={metaData.uuid}
            checked={value}
            onChange={(e) => {
              setValue(e.target.checked);
              updateMetaValue(e.target.checked);
            }}
            disabled={isReadOnly}
            blue
          />
        );

      case MetaFieldType.DictionaryElement:
        const wsDictionaryUuid = metaData?.data?.wsDictionaryUuid;

        const wsDictionarySubFieldUuid =
          metaData?.data?.wsDictionarySubFieldUuid;

        return (
          <div className="value-field">
            <DictionaryElementSelector
              onValueChange={updateMetaValue}
              value={metaData.value}
              wsDictionaryUuid={wsDictionaryUuid}
              wsDictionarySubFieldUuid={wsDictionarySubFieldUuid}
              disabled={isReadOnly}
              dataType={dataType}
            />

            {value && !isReadOnly && isRemovable && (
              <RemoveIcon className="value-field__icon" onClick={removeValue} />
            )}
          </div>
        );

      case MetaFieldType.Rating:
        return (
          <RatingSelector
            value={parseInt(value || "0")}
            disabled={isReadOnly}
            onValueChange={(i) => {
              updateMetaValue(i);
              setValue(i);
            }}
          />
        );
    }
  };

  const onFieldClick = () => {
    if (disableFieldClick && !showWarningModal) {
      setShowWarningModal(true);
    }
  };

  const confirmWarning = () => {
    setDisableFieldClick(false);
    setShowWarningModal(false);

    fieldContentRef?.current?.click();
  };

  return (
    <div className="task-meta-fields__value" onClick={onFieldClick}>
      <span
        ref={fieldContentRef}
        className={classNames("task-meta-fields__value-content", {
          "task-meta-fields__value-content--disabled": disableFieldClick,
        })}
      >
        {renderMetaField()}
      </span>

      {showWarningModal && (
        <FieldSynchronizationWarningModal
          onClose={() => setShowWarningModal(false)}
          onAccept={confirmWarning}
        />
      )}
    </div>
  );
}

export default SingleMetaField;
