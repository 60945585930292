import DOMPurify from "dompurify";
import he from "he";

const XssFilter = (
  html: string,
  allowedTags: string[] = [],
  allowedAttr: string[] = [],
  decodeHtmlEntities: boolean = false,
) => {
  if (!html) {
    return html;
  }

  const htmlWithNewLines = html.split(/\r\n|\r|\n/g).join("<br/>");

  const sanitizedHtml = DOMPurify.sanitize(htmlWithNewLines, {
    ALLOWED_TAGS: allowedTags,
    ALLOWED_ATTR: allowedAttr,
    // RETURN_TRUSTED_TYPE: true,
  }); // checkout more parameters: https://www.npmjs.com/package/dompurify#user-content-can-i-configure-dompurify

  if (!decodeHtmlEntities) {
    return sanitizedHtml;
  }

  return he.decode(sanitizedHtml);
};

export default XssFilter;
