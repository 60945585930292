import React, { useEffect, useMemo } from "react";
import { WrappedComponentProps, injectIntl, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { tableDataType } from "src/app/components/Table/Table";
import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import { currencySearchFunction } from "src/app/components/CustomSelect/functions/currencySearchFunction";
import { translateMessage } from "src/app/methods/translateMessage";
import { RootState } from "src/redux/reducers";
import { CurrencyOption, MFState, MFStateEntries } from "../../types";
import { FieldIcons } from "../FieldIcons/FieldIcons";

interface Props extends WrappedComponentProps<"intl"> {
  dataType: tableDataType;
  disabled?: boolean;
  state: MFState;
  setState: (type: MFStateEntries, value: any) => void;
  isEdited: boolean;
}

function Currencies(props: Props) {
  const {
    state: {
      currenciesData,
      selectedCurrency,
      selectedIcon,
      selectedColor,
      addIcon,
    },
    disabled,
    dataType,
    setState,
    isEdited,
  } = props;
  const {
    projectReducer: { projectBasicData },
  } = useSelector((state: RootState) => state);

  const intl = useIntl();

  const currencyOptions = useMemo(() => {
    return Object.keys(currenciesData).map((code) => ({
      key: code,
      label: (
        <span className="currency-option">
          <span className="currency-code">{code}</span>
          {currenciesData[code]}
        </span>
      ),
      value: code,
    }));
  }, [currenciesData]);

  useEffect(() => {
    if (!isEdited && projectBasicData?.data?.currencyCode) {
      setState(
        "selectedCurrency",
        currencyOptions.find(
          (item) => item.value === projectBasicData.data.currencyCode,
        ),
      );
    }
  }, [isEdited, currencyOptions, projectBasicData?.data?.currencyCode]);

  return (
    <div className="currency-wrapper">
      <div className="currency-wrapper__row">
        <div className="currency-select">
          <CustomInputLabel htmlFor="currencyType">
            <IDHFormattedMessage id="ws_currency" defaultMessage="Currency" />
          </CustomInputLabel>
          <CustomSelect
            id="currencyType"
            value={selectedCurrency}
            options={currencyOptions}
            placeholder={`${translateMessage({
              intl,
              id: "ws_select_currency",
              defaultMessage: "Select currency",
            })}...`}
            isSearchable
            onChange={(newValue: CurrencyOption) => {
              setState("selectedCurrency", newValue);
            }}
            disabled={disabled}
            filterOption={currencySearchFunction}
          />
        </div>
      </div>
      {dataType !== tableDataType.Task &&
      dataType !== tableDataType.RecruitmentForm ? (
        <div className="currency-wrapper__row">
          <FieldIcons
            selectedIcon={selectedIcon}
            setSelectedIcon={(newValue: any) =>
              setState("selectedIcon", newValue)
            }
            selectedColor={selectedColor}
            setSelectedColor={(newValue: any) =>
              setState("selectedColor", newValue)
            }
            addIcon={addIcon}
            setAddIcon={(newValue: any) => setState("addIcon", newValue)}
            disabled={disabled}
          />
        </div>
      ) : null}
    </div>
  );
}

export default injectIntl(Currencies);
