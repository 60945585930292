import { capitalizeFirstLetter } from "src/utils/methods";

const SENTIMENT_NAMES = ["positive", "neutral", "negative"];

export const addSelectedCommentsToGridElements = (
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  comments,
) => {
  if (!comments) return;

  Object.keys(comments?.all).forEach((key) => {
    const name = SENTIMENT_NAMES.includes(key) ? `${key} sentiment` : key;

    const element = {
      key,
      commentList: comments?.all[key] || [],
      label: `comments-${key}`,
      provider: "comments",
      name: capitalizeFirstLetter(name),
      gridBoxType: "comments",
      section: "comments-analysis",
    };

    // applying saved template
    if (savedTemplate?.length && !templateApplied) {
      if (savedTemplate.find((item) => item.i === element.label)) {
        newGridElements.push(element);
      } else {
        newHiddenGridElements.push(element);
      }
    }
    // applying default template
    // else if (comments.all[key]?.length) {
    newGridElements.push(element);
    // } else {
    // newHiddenGridElements.push(element);
    // }
  });
};
