import React from "react";
import "./SocialProviderIcon.scss";
import classNames from "classnames";
import METHODS from "../../helpers/methods";

function SocialProviderIcon({ provider, cpcon, universal, user, big, white }) {
  return (
    <span
      className={classNames(
        "social-provider-icon",
        METHODS.getSocialProviderIconClass("social-provider-icon", provider),
        {
          "social-provider-icon--cpcon": cpcon && !universal,
          "social-provider-icon--universal": cpcon && universal,
          "social-provider-icon--user": user,
          "social-provider-icon--big": big,
          "social-provider-icon--white": white,
        },
      )}
    />
  );
}

export default SocialProviderIcon;
