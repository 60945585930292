import React from "react";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalText } from "src/app/components/Modal/Modal";

export function WhatAreCoupons() {
  return (
    <AnimatedDiv>
      <ModalText>
        <IDHFormattedMessage
          id="ws_its_an_effective_strategy_in_influencer_marketing_campaigns"
          defaultMessage="It’s an effective strategy in influencer marketing campaigns, helping both brands and influencers achieve diverse objectives such as sales growth, audience engagement, credibility building, and more. The potential advantages of coupons are:"
        />
      </ModalText>
      <video autoPlay loop>
        <source
          src={require("../../videos/what-are-coupons.mp4")}
          type="video/mp4"
        />
      </video>
    </AnimatedDiv>
  );
}
