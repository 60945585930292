import React, { Dispatch, SetStateAction, useState } from "react";
import "./Check.scss";

import classNames from "classnames";
import { ReactComponent as TickIcon } from "../../../images/tick-green.svg";

interface Props {
  isCheck: boolean;
  setIsCheck: Dispatch<SetStateAction<boolean>>;
  big?: boolean;
  large?: boolean;
}

const Check: React.FC<Props> = (props) => {
  const { isCheck, setIsCheck, big, large } = props;

  return (
    <span
      className={classNames("check", {
        "check--active": isCheck,
        "check--big": big,
        "check--large": large,
      })}
      onClick={() => setIsCheck((v) => !v)}
    >
      <div className="check__background" />
      <TickIcon className="tick-icon" />
    </span>
  );
};

export default Check;
