export enum AttachmentPlacement {
  Comment,
  Attachments,
  Dropdown,
  InlineWithLabel,
  WsTable,
  AttachmentPreview,
  ContentProposalSummary,
  ContentProposal,
}
