import React, { useEffect, useMemo, useRef, useState } from "react";

import usePopper from "react-overlays/cjs/usePopper";
import { Rectangle } from "@glideapps/glide-data-grid";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";

import "./GlideDataGridTooltip.scss";
import classNames from "classnames";

interface GlideDataGridTooltipProps {
  content: string;
  bounds: Rectangle;
  type: MetaFieldType | "header" | null;
  referenceElement: HTMLElement | null;
}

export function GlideDataGridTooltip({
  content,
  referenceElement,
  type,
  bounds,
}: GlideDataGridTooltipProps) {
  const [popperElement, setPopperElement] = useState<any>(null);
  const tooltipRef = useRef(null);

  const innerPopperReferenceElement = useMemo(() => referenceElement, [bounds]);

  const { styles, attributes, state, update } = usePopper(
    innerPopperReferenceElement,
    popperElement,
    {
      placement: "bottom",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    },
  );

  useEffect(() => {
    if (state) {
      update();
    }
  }, [state, tooltipRef, update]);

  // For TypeScript compatibility
  const popperStyles = styles.popper as React.CSSProperties;

  return (
    <div
      ref={setPopperElement}
      style={popperStyles}
      {...attributes.popper}
      className={classNames("glide-data-grid__tooltip", {
        "glide-data-grid__tooltip--header": type === "header",
      })}
    >
      {content}
    </div>
  );
}
