import React, { useState } from "react";

import { ReactComponent as EyesIcon } from "src/images/eyes.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import RecruitmentFormNotificationMemberListItem from "./RecruitmentFormNotificationMemberListItem";
import { MemberWithNotificationType } from "../../../../../../../../types";

function RecruitmentFormNotificationMemberList(props) {
  const {
    setShowConfirmDeleteMemberModal,
    setDeleteMemberData,
    membersWithNotification,
    members,
    teams,
  } = props;

  const [filteredGroup, setFilteredGroup] = useState("all");

  const getMemberTeamList = () => {
    return [
      ...teams
        ?.filter(
          (identityTeam) =>
            membersWithNotification?.find(
              (memberTeamRow) =>
                memberTeamRow.wsTeamUuid === identityTeam.uuid &&
                memberTeamRow.type === MemberWithNotificationType.team,
            ) && identityTeam,
        )
        ?.map((team) => ({
          name: team.name,
          label: team.name,
          value: team.uuid,
          members: team.wsMembers,
          type: MemberWithNotificationType.team,
        })),
      ...members
        ?.filter(
          (identityMember) =>
            membersWithNotification?.find(
              (memberTeamRow) =>
                memberTeamRow.wsMemberUuid === identityMember.id &&
                memberTeamRow.type === MemberWithNotificationType.member,
            ) && identityMember,
        )
        ?.map((member) => ({
          label: member.name,
          name: member.name,
          email: member.email,
          value: member.id,
          avatarUrl: member.avatarUrl,
          members: [],
          type: MemberWithNotificationType.member,
        })),
    ];
  };

  return (
    <div className="notification-members__user-list-wrap">
      <div className="notification-members__user-list__header">
        <IDHFormattedMessage
          id="ws_creator_submission_form_member_with_notifications"
          defaultMessage="Members with notification"
        />
      </div>
      <div className="notification-members__user-list">
        {getMemberTeamList() && getMemberTeamList()?.length > 0 ? (
          getMemberTeamList().map((member) => (
            <RecruitmentFormNotificationMemberListItem
              key={member.value}
              member={member}
              setShowConfirmDeleteMemberModal={setShowConfirmDeleteMemberModal}
              filteredGroup={filteredGroup}
              setDeleteMemberData={setDeleteMemberData}
            />
          ))
        ) : (
          <div className="notification-members__user-list-empty">
            <EyesIcon className="notification-members__eyes-icon" />
            &nbsp;
            <IDHFormattedMessage
              id="ws_you_have_no_member_assign_to_receive_notification"
              defaultMessage="You have no Members assign to receive notification."
            />
          </div>
        )}
      </div>
      <div className="notification-members__user-list__footer" />
    </div>
  );
}

export default RecruitmentFormNotificationMemberList;
