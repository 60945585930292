import { toDate } from "date-fns";
import { API_URLS } from "../../utils/API_URLS";
import {
  CLEAR_ACTIVITY_FILTERS,
  GET_ACTIVITY_LIST,
  SET_ACTIVITY_FILTERS,
  SET_ACTIVITY_LIST,
  SET_ACTIVITY_LOADING,
  SET_ACTIVITY_OFFSET,
  SET_OLDEST_ACTIVITY_DATE,
} from "./activityTypes";

export function getActivityList(
  workspaceUuid: string,
  filters: any,
  offset: number,
  limit: number,
) {
  const url = new URL(API_URLS.getActivityList);

  url.searchParams.append("wsWorkspaceUuid", workspaceUuid);
  url.searchParams.append(
    "filters",
    JSON.stringify({
      campaigns: filters.campaigns.map(
        (item: { value: { id: string } }) => item.value.id,
      ),
      members: filters.members.map(
        (item: { value: { id: string } }) => item.value.id,
      ),
      dateRange: filters.dateRange,
    }),
  );
  url.searchParams.append("offset", offset.toString());
  url.searchParams.append("limit", limit.toString());

  return {
    type: GET_ACTIVITY_LIST,
    payload: {
      request: {
        method: "get",
        url,
      },
    },
  };
}

export function setActivityFilters(filters: any) {
  return {
    type: SET_ACTIVITY_FILTERS,
    payload: { filters },
  };
}

export function clearActivityFilters() {
  return { type: CLEAR_ACTIVITY_FILTERS };
}

export function setActivityList(activityList: any) {
  return { type: SET_ACTIVITY_LIST, payload: { activityList } };
}

export function setOldestActivityDate(oldestActivityDate: any) {
  return { type: SET_OLDEST_ACTIVITY_DATE, payload: { oldestActivityDate } };
}

export function setActivityOffset(offset: number) {
  return { type: SET_ACTIVITY_OFFSET, payload: { offset } };
}

export function setActivityLoading(loading: boolean) {
  return { type: SET_ACTIVITY_LOADING, payload: { loading } };
}
