export const GET_COUPON_GROUPS = "GET_COUPON_GROUPS";
export const GET_COUPON_GROUPS_SUCCESS = "GET_COUPON_GROUPS_SUCCESS";

export const GET_COUPON_GROUP_DETAILS = "GET_COUPON_GROUP_DETAILS";
export const GET_COUPON_GROUP_DETAILS_SUCCESS =
  "GET_COUPON_GROUP_DETAILS_SUCCESS";

export const GET_COUPONS_LIST = "GET_COUPONS_LIST";
export const GET_COUPONS_LIST_SUCCESS = "GET_COUPONS_LIST_SUCCESS";
export const GET_COUPONS_LIST_FAIL = "GET_COUPONS_LIST_FAIL";

export const CREATE_COUPON_GROUP = "CREATE_COUPON_GROUP";
export const CREATE_COUPON_GROUP_SUCCESS = "CREATE_COUPON_GROUP_SUCCESS";
export const GET_COUPON_REDEMPTION_LOG_STRATEGY =
  "GET_COUPON_REDEMPTION_LOG_STRATEGY";
export const GET_COUPON_REDEMPTION_LOG_STRATEGY_SUCCESS =
  "GET_COUPON_REDEMPTION_LOG_STRATEGY_SUCCESS";

export const UPDATE_COUPON_GROUP = "UPDATE_COUPON_GROUP";
export const UPDATE_COUPON_GROUP_FAIL = "UPDATE_COUPON_GROUP_FAIL";
export const UPDATE_COUPON_GROUP_SUCCESS = "UPDATE_COUPON_GROUP_SUCCESS";

export const CREATE_COUPON = "CREATE_COUPON";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAIL = "CREATE_COUPON_FAIL";

export const RENAME_COUPON = "RENAME_COUPON";
export const RENAME_COUPON_FAIL = "RENAME_COUPON_FAIL";

export const DELETE_COUPON = "DELETE_COUPON";
export const DELETE_COUPON_FAIL = "DELETE_COUPON_FAIL";

export const SET_SHOULD_CLOSE_MODAL = "SET_SHOULD_CLOSE_MODAL";
export const SET_LOADING = "SET_LOADING";
