import React, { useRef } from "react";
import "./GalleryLightboxLocal.scss";

import FsLightbox from "fslightbox-react";
import { Portal } from "react-overlays";
import { overlaysRef } from "src/App";

export function GalleryLightboxLocal({ sources, show, hide }) {
  const lightboxRef = useRef(null);

  const handleOpen = () => {
    const absoluted =
      lightboxRef.current.elements.container.current.querySelector(
        ".fslightbox-absoluted",
      );

    absoluted.addEventListener(
      "click",
      (e) => {
        if (e.target.classList.contains("fslightbox-source")) return;
        lightboxRef.current.core.lightboxCloser.closeLightbox();
      },
      false,
    );
  };

  return (
    <Portal container={overlaysRef}>
      <FsLightbox
        // toggler={show}
        openOnMount
        types={sources.map((gallerySource) => gallerySource.type)}
        sources={sources.map((gallerySource) => gallerySource.url)}
        key={sources}
        // key is remountComponent toggler so in future i suggest using gallerySuccess.length
        ref={lightboxRef}
        onOpen={handleOpen}
        onClose={hide}
      />
    </Portal>
  );
}
