import React, { useState } from "react";
import "./MemberOrClientModal.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalText, ModalTitle } from "../../components/Modal/Modal";
import { ReactComponent as InfoGreyIcon } from "../../../images/info-grey.svg";

function MemberOrClientModal(props) {
  const {
    onClose,
    openRolesInfoModal,
    setIsNewOverviewForClient,
    showAddProjectFieldModal,
  } = props;

  const setOverviewForClient = () => {
    setIsNewOverviewForClient(true);
    onClose();
    showAddProjectFieldModal();
  };

  const setOverviewForMember = () => {
    setIsNewOverviewForClient(false);
    onClose();
    showAddProjectFieldModal();
  };

  return (
    <Modal
      className="member-or-client-modal"
      onClose={onClose}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_add_overview"
          defaultMessage="Add overview"
        />
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_select_the_role_for_overview"
          defaultMessage="Select the role for which you want to create an overview"
        />
        <div className="members__roles-button" onClick={openRolesInfoModal}>
          <InfoGreyIcon className="member-or-client-modal__info-icon" />
          <IDHFormattedMessage id="ws_roles" defaultMessage="Roles" />
        </div>
      </ModalText>
      <div className="member-or-client-modal__options-wrapper">
        <div
          className="member-or-client-modal__option"
          onClick={setOverviewForMember}
        >
          <div className="member-or-client-modal__option-icon member-or-client-modal__option-icon--member" />
          <IDHFormattedMessage id="ws_member" defaultMessage="Member" />
        </div>
        <div
          className="member-or-client-modal__option"
          onClick={setOverviewForClient}
        >
          <div className="member-or-client-modal__option-icon member-or-client-modal__option-icon--client" />
          <IDHFormattedMessage id="ws_client" defaultMessage="Client" />
        </div>
      </div>
    </Modal>
  );
}

export default MemberOrClientModal;
