import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";

export enum TaskType {
  Creator = "creator",
  Action = "action",
  Content = "content",
  Publication = "publication",
  Payment = "payment",
}

export interface ColumnOption {
  uuid: string;
  label: string;
  value: string;
  type: MetaFieldType;
}

export interface ProjectPermissions {
  project: string[];
}

export interface CellSizes {
  [key: string]: { [key: string]: number };
}

export interface ColumnSizes {
  [key: string]: number;
}
