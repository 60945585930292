import { CREATOR_INSIGHTS } from "../../utils/variables";

export const addCreatorInsightsToGridElements = (
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  creatorInsights,
) => {
  if (!creatorInsights) return;

  CREATOR_INSIGHTS.forEach((item) => {
    const element = {
      label: `creator-insights-${item.value}`,
      provider: "creator-insights",
      type: item.value,
      name: item.label,
      gridBoxType: "creator-insights",
    };

    // applying saved template
    if (savedTemplate?.length && !templateApplied) {
      if (savedTemplate.find((item) => item.i === element.label)) {
        newGridElements.push(element);
      } else {
        newHiddenGridElements.push(element);
      }
    } else if (
      item.initialHidden ||
      !creatorInsights[item.source] ||
      creatorInsights[item.source].length === 0
    ) {
      newHiddenGridElements.push(element);
    }
    // applying default template
    else {
      newGridElements.push(element);
    }
  });
};
