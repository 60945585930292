import React, { FormEvent, useEffect, useState } from "react";
import "./CredibilityToolListPage.scss";
import { useIntl } from "react-intl";

import { useDispatch } from "react-redux";
import axios from "axios";

import { Redirect, useHistory, useParams } from "react-router";
import { API_URLS } from "../../utils/API_URLS";
import { ReactComponent as IdhLogo } from "../../images/idh-logo-color.svg";
import { ReactComponent as WarningIcon } from "../../images/credibility-tool/warning-icon.svg";
import LoaderMini from "../../images/loader.gif";
import { showToast } from "../methods/showToast";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import CustomInput from "../components/CustomInput/CustomInput";
import { translateMessage } from "../methods/translateMessage";
import { Button } from "../components/Button/Button";
import ProfileDetails from "./ProfileDetails";
import UpgradeAccountModal from "./components/UpgradeAccountModal";

const INSTAGRAM_URL_REGEX =
  /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_.]+)/;
const INSTAGRAM_URL_DOMAIN = "https://www.instagram.com/";
const newProfileItem = {
  id: null,
  profile: null,
  url: "",
  warning: null,
  disabled: false,
  checked: false,
};

function CredibilityToolListPage() {
  const { userUuid }: { userUuid: string } = useParams();

  const intl = useIntl();
  const history = useHistory();

  const [name, setName] = useState("");
  const [influencerList, setInfluencerList] = useState<any[]>([
    {
      ...newProfileItem,
      id: 1,
    },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingItem, setIsLoadingItem] = useState(null);
  const [customWarning, setCustomWarning] = useState(null);
  const [isCustomWarning, setIsCustomWarning] = useState(null);
  const [isWarningItem, setIsWarningItem] = useState(null);
  const [isWarningTryAgainItem, setIsWarningTryAgainItem] = useState(null);
  const [isWarningAccountPrivateItem, setIsWarningAccountPrivateItem] =
    useState(null);
  const [isWarningAccountTooSmallItem, setIsWarningAccountTooSmallItem] =
    useState(null);
  const [profileDetails, setProfileDetails] = useState(null);
  const [showProfileDetails, setShowProfileDetails] = useState(false);
  const [showUpgradeAccountModal, setShowUpgradeAccountModal] = useState(false);
  const [userListLimit, setUserListLimit] = useState(20);
  const [showModalAfterLastCheck, setShowModalAfterLastCheck] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const items: any[] = [];

    const formData = new FormData();
    formData.append("userUuid", userUuid);

    axios
      .post(API_URLS.credibilityInfluencersList, formData)
      .then(function (response) {
        const listLimit = response.data?.content?.listLimit ?? 20;

        if (response.data?.status_code === 200) {
          const list = response.data.content.list ?? [];

          setUserListLimit(listLimit);

          list?.map((item: any, index: number) => {
            items.push({
              id: index + 1,
              profile: item.influencerName,
              url: INSTAGRAM_URL_DOMAIN + item.influencerName,
              warning: null,
              disabled: true,
              checked: item.status === "available",
            });
          });
        } else {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id="ws_something_went_wrong"
              defaultMessage="Something went wrong!"
            />,
          );
        }

        if (listLimit > items.length) {
          items.push({
            ...newProfileItem,
            id: items.length + 1,
          });
        } else {
          setShowUpgradeAccountModal(true);
        }
      })
      .catch((error) => {
        if (error.response.data?.status_code === 401) {
          history.push(`/workspace/credibility-tool`);
        } else {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id="ws_something_went_wrong"
              defaultMessage="Something went wrong!"
            />,
          );
        }
      })
      .finally(() => {
        setInfluencerList(items);
        setIsLoading(false);
      });
  }, [userUuid]);

  const checkIfEmptyInfluRowExists = (id?: number) => {
    return influencerList.some(
      (obj) => ((id && obj.id !== id) || !id) && obj.disabled === false,
    );
  };

  const checkCredibilitySocial = (id: any, profile: string) => {
    const formData = new FormData();
    const exists = influencerList.some(
      (obj) => obj.profile?.trim() === profile?.trim() && obj.id !== id,
    );

    formData.append("userUuid", userUuid);
    formData.append("influencerUsername", profile);

    setIsWarningItem(null);
    setIsCustomWarning(null);
    setIsWarningAccountTooSmallItem(null);
    setIsWarningAccountPrivateItem(null);
    setIsWarningTryAgainItem(null);

    if (exists) {
      showToast(
        "warning",
        <IDHFormattedMessage id="ws_warning" defaultMessage="Warning" />,
        <IDHFormattedMessage
          id="ws_credibility_tool-profile-exist-on-list"
          defaultMessage="This profile was added to the list"
        />,
      );

      return;
    }

    setIsLoadingItem(id);

    axios
      .post(API_URLS.credibilityCheckInfluencer, formData)
      .then((response) => {
        if (
          response.data?.status_code === 200 ||
          response.data?.status_code === 201
        ) {
          const updatedInfluencerList = influencerList.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                checked:
                  response.data?.status_code === 200 ||
                  response.data.content.result === "Available",
                disabled: true,
              };
            }

            return item;
          });

          if (userListLimit > updatedInfluencerList.length) {
            if (!checkIfEmptyInfluRowExists(id)) {
              updatedInfluencerList.push({
                ...newProfileItem,
                id: influencerList.length + 1,
              });
            }
          } else {
            setShowModalAfterLastCheck(true);
          }

          setInfluencerList(updatedInfluencerList);

          if (
            response.data?.status_code === 200 ||
            response.data.content.result === "Available"
          ) {
            viewCredibilitySocial(id, profile);
          } else if (response.data?.status_code === 201) {
            setIsWarningTryAgainItem(id);
          }
        } else {
          setIsWarningItem(id);
        }
      })
      .catch((error) => {
        if (error.response.data?.status_code === 400) {
          if (error.response.data?.content?.result === "Account is private") {
            setIsWarningAccountPrivateItem(id);
          } else if (
            error.response.data?.content?.result === "Account is too small"
          ) {
            setIsWarningAccountTooSmallItem(id);
          } else if (
            error.response.data?.content?.error === "Influencers limit reached"
          ) {
            setShowUpgradeAccountModal(true);
          } else {
            if (error.response.data?.content?.result) {
              setIsCustomWarning(id);
              setCustomWarning(error.response.data?.content?.result);
            } else if (error.response.data?.content?.error) {
              setIsCustomWarning(id);
              setCustomWarning(error.response.data?.content?.error);
            } else {
              showToast(
                "error",
                <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
                <IDHFormattedMessage
                  id="ws_something_went_wrong"
                  defaultMessage="Something went wrong!"
                />,
              );
            }
          }
        } else {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id="ws_something_went_wrong"
              defaultMessage="Something went wrong!"
            />,
          );
        }
      })
      .finally(() => {
        setIsLoadingItem(null);
      });
  };

  const viewCredibilitySocial = (id: any, profile: string) => {
    const formData = new FormData();
    formData.append("userUuid", userUuid);
    formData.append("influencerUsername", profile);

    setIsLoadingItem(id);
    setIsCustomWarning(null);
    setIsWarningItem(null);
    setIsWarningAccountTooSmallItem(null);
    setIsWarningAccountPrivateItem(null);
    setIsWarningTryAgainItem(null);

    axios
      .post(API_URLS.credibilityShowInfluencer, formData)
      .then((response) => {
        if (
          response.data.status_code === 200 &&
          response.data.content.userData
        ) {
          setProfileDetails({
            ...response.data.content.userData,
            profile,
          });
          setShowProfileDetails(true);
        } else {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id="ws_something_went_wrong"
              defaultMessage="Something went wrong"
            />,
          );
        }
      })
      .catch((error) => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_something_went_wrong"
            defaultMessage="Something went wrong"
          />,
        );
      })
      .finally(() => {
        setIsLoadingItem(null);
      });
  };

  const handleChangeSocialInput = (event: any, id: number) => {
    const inputValue = event.target.value.trim();
    const match = inputValue.match(INSTAGRAM_URL_REGEX);
    let profile = inputValue;
    let url = "";

    if (match && match[1]) {
      profile = match[1];
    }

    if (INSTAGRAM_URL_DOMAIN === profile || profile.trim() === "") {
      profile = null;
    } else {
      url = INSTAGRAM_URL_DOMAIN + profile;
    }

    const updatedInfluencerList = influencerList.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          profile,
          url,
        };
      }
      return item;
    });
    setInfluencerList(updatedInfluencerList);
  };

  const handleCloseProfileDetail = () => {
    setShowProfileDetails(false);

    if (showModalAfterLastCheck) {
      setShowUpgradeAccountModal(true);
      setShowModalAfterLastCheck(false);
    }
  };

  const handleCloseUpgradeAccountModal = () => {
    setShowUpgradeAccountModal(false);
    setShowModalAfterLastCheck(false);
  };

  const renderInfluencerWarnings = (id: number) => {
    if (isWarningItem === id) {
      return (
        <div className="warning-label">
          <WarningIcon />
          <IDHFormattedMessage
            id="ws_the_account_with_the_given_link_does_no_exist"
            defaultMessage="The account with the given link does not exist."
          />
        </div>
      );
    }
    if (isWarningTryAgainItem === id) {
      return (
        <div className="warning-label">
          <WarningIcon />
          <IDHFormattedMessage
            id="ws_credibility_tool_try_again_later"
            defaultMessage="Please try again later."
          />
        </div>
      );
    }
    if (isWarningAccountPrivateItem === id) {
      return (
        <div className="warning-label">
          <WarningIcon />
          <IDHFormattedMessage
            id="ws_credibility_tool_account_private"
            defaultMessage="Account is private"
          />
        </div>
      );
    }
    if (isWarningAccountTooSmallItem === id) {
      return (
        <div className="warning-label">
          <WarningIcon />
          <IDHFormattedMessage
            id="ws_credibility_tool_account_too_small"
            defaultMessage="Account is too small"
          />
        </div>
      );
    }
    if (isCustomWarning === id) {
      return (
        <div className="warning-label">
          <WarningIcon />
          {customWarning}
        </div>
      );
    }

    return <></>;
  };

  const influInputAction = (item: any) => {
    if (item.checked) {
      viewCredibilitySocial(item.id, item.profile);
    } else {
      if (item.profile && isWarningItem !== item.id) {
        checkCredibilitySocial(item.id, item.profile);
      }
    }
  };

  const handleKeyPress = (event: any, item: any) => {
    if (event.key === "Enter") {
      influInputAction(item);
    }
  };

  const renderInfluencerInputItems = () => {
    return (
      <>
        {influencerList?.map((item) => (
          <div className="credibility-tool-list-page__social-item">
            <div className="credibility-tool-list-page__social-item-couter">
              {item.id}.
            </div>
            <div className="credibility-tool-list-page__social-item-input">
              <CustomInput
                name={`item-${item.id}-${new Date().getTime()}`}
                value={item.url}
                disabled={item.disabled || isLoadingItem === item.id}
                onChange={(event) => handleChangeSocialInput(event, item.id)}
                placeholder={translateMessage({
                  intl,
                  id: "ws_creadibility_tool_input_social_placeholder",
                  defaultMessage: "https://www.instagram.com/...",
                })}
                onKeyPress={(event) => handleKeyPress(event, item)}
                required
                fullWidth
                type="text"
                autoComplete="off"
              />
              {renderInfluencerWarnings(item.id)}
            </div>
            {isLoadingItem === item.id ? (
              <div className="credibility-tool-list-page__social-item-loader">
                <img src={LoaderMini} />
              </div>
            ) : (
              <div className="credibility-tool-list-page__social-item-button">
                {item.checked ? (
                  <Button variant="blue" onClick={() => influInputAction(item)}>
                    <IDHFormattedMessage id="ws_view" defaultMessage="View" />
                  </Button>
                ) : (
                  <Button
                    variant="white-with-grey-border"
                    onClick={() => influInputAction(item)}
                    disabled={!item.profile || isWarningItem === item.id}
                  >
                    <IDHFormattedMessage id="ws_check" defaultMessage="Check" />
                  </Button>
                )}
              </div>
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="credibility-tool-list-page">
      <div className="credibility-tool-list-page__container">
        <div className="">
          <IdhLogo className="credibility-tool-list-page__logo" />
          <IdhLogo className="credibility-tool-list-page__logo-centered" />
        </div>
        <div className="">
          <div className="credibility-tool-list-page__header">
            <IDHFormattedMessage
              id="ws_credibility_tool_header"
              defaultMessage="Free Credibility Checker Tool"
            />
          </div>
          <div className="credibility-tool-list-page__text">
            <IDHFormattedMessage
              id="ws_credibility_tool_header_description"
              defaultMessage="Enter the links to the Instagram accounts you want to check out."
            />
          </div>
        </div>
        <div className="credibility-tool-list-page__social-list">
          {renderInfluencerInputItems()}
          {influencerList.length >= userListLimit &&
            !checkIfEmptyInfluRowExists() && (
              <div className="credibility-tool-list-page__social-list-footer">
                <IDHFormattedMessage
                  id="ws_credibility_tool_you_have_reached_your_limit_1"
                  defaultMessage="You have reached your limit of"
                />
                &nbsp;
                {userListLimit}
                &nbsp;
                <IDHFormattedMessage
                  id="ws_credibility_tool_you_have_reached_your_limit_2"
                  defaultMessage="accounts"
                />
                &nbsp;
                <Button
                  onClick={() =>
                    (window.location.href = userUuid
                      ? `/workspace/trial?fccId=${userUuid}`
                      : "/workspace/trial")
                  }
                >
                  <IDHFormattedMessage
                    id="ws_upgrade_account"
                    defaultMessage="Upgrade account"
                  />
                </Button>
              </div>
            )}
        </div>
      </div>
      {showProfileDetails && (
        <ProfileDetails
          onCloseHandler={() => handleCloseProfileDetail()}
          profileData={profileDetails}
        />
      )}
      {showUpgradeAccountModal && (
        <UpgradeAccountModal onClose={handleCloseUpgradeAccountModal} />
      )}
    </div>
  );
}

export default CredibilityToolListPage;
