import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import AutoSuggest from "react-autosuggest";
import { cancelRequestAxios } from "./utils/CancelRequest";
import "./AutoSuggestionInput.scss";
import classNames from "classnames";
import { fi } from "date-fns/locale";
import { useSelector } from "react-redux";
import { renderFakeSuggestion } from "../../modals/AddCreatorsModal/components/RenderSuggestion";

const cancelRequestAxiosObject = new cancelRequestAxios();

export function AutoSuggestionInput({
  placeholder,
  value,
  setValue,
  queryUrl,
  getSuggestionValue,
  renderSuggestion,
  renderSelectedOptions,
  storedObjects,
  setStoredObjects,
  threshold = 0,
  generateObject,
  globalTaskMode,
}) {
  const areaRef = useRef(null);
  const autoSuggestRef = useRef(null);
  const [isFocused, setFocus] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state) => state);

  useEffect(() => {
    autoSuggestRef.current.autowhatever.onFocus();
    // getSuggestions(value)
  }, [value]);

  const searchForResults = async (v, url) => {
    if (v.length >= threshold) {
      setLoadingStatus(true);
      cancelRequestAxiosObject.cancelAndCreateToken();
      try {
        const response = await axios.get(url, {
          params: {
            query: v,
            wsWorkspaceUuid: activeWorkspaceUuid,
          },
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          withCredentials: true,
          cancelToken: cancelRequestAxiosObject.cancel_resquest.token,
        });
        cancelRequestAxiosObject.resetCancelToken();
        return response.data;
      } catch (error) {
        if (axios.isCancel(error)) {
          // console.error("Request canceled", error.message);
        }
      }
    }
  };

  const getSuggestions = (value) => {
    searchForResults(value, queryUrl).then((res) => {
      setSuggestions(res?.content ?? []);
    });
  };

  const setSelectedValue = (newValue) => {
    setStoredObjects((prev) => [...prev, newValue]);
    setValue("");
    areaRef.current.innerHTML = "";
  };

  const setNewObjectFromValue = () => {
    value.length > 0 && setSelectedValue(generateObject(value));
  };

  const handleInput = (e) => {
    setValue(e.target.innerText.trimStart());
    setTimeout(() => {
      autoSuggestRef.current.autowhatever.onHighlightedItemChange();
    }, "200");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setNewObjectFromValue();
    }

    if (e.key === " " && !value.length) {
      e.preventDefault();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && value.length === 0) {
      setStoredObjects(storedObjects.slice(0, storedObjects.length - 1));
    }
  };

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    let foundUrl = false;
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text/plain");
    areaRef.current.textContent = pastedData;
    setCaret();

    const pastedObjects = pastedData.trim().split(/\s+/);
    pastedObjects.forEach((obj) => {
      if (isValidUrl(obj)) {
        setSelectedValue(generateObject(obj));
        foundUrl = true;
      } else {
        setValue(obj.trim());
      }
    });
  };

  const setCaret = () => {
    const range = document.createRange();
    const sel = window.getSelection();

    sel.removeAllRanges();
    range.selectNodeContents(areaRef.current);
    range.collapse(false);
    sel.addRange(range);
    areaRef.current.focus();
  };

  const renderTextArea = (inputProps) => {
    return (
      <div
        className={classNames(
          "ws-textarea",
          "ws-textarea--auto-grow",
          value.length === 0 &&
            storedObjects.length === 0 &&
            "ws-textarea--empty",
        )}
        onClick={(e) => {
          if (value.length > 0) setCaret();
          areaRef.current.focus();
        }}
        onMouseDownCapture={(e) => {
          if (e.target !== areaRef.current) e.preventDefault();
        }}
      >
        <span className="ws-textarea-placeholder">{placeholder}</span>
        {renderSelectedOptions(storedObjects)}
        <span
          contentEditable="true"
          className="real-input"
          onInput={handleInput}
          {...inputProps}
          onKeyPress={handleKeyPress}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    );
  };

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        {children}
        {renderFakeSuggestion(
          containerProps.className.includes(
            "react-autosuggest__suggestions-container--open",
          ) ||
            (value.length > 0 && isFocused),
          value,
          setNewObjectFromValue,
        )}
      </div>
    );
  };

  useEffect(() => {
    if (typeof value !== "string") return;
    getSuggestions(value);
  }, [value]);

  return (
    <AutoSuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={() => {}}
      onSuggestionSelected={(event, { suggestion }) => {
        if (suggestion.hasCreatorDatabaseProfile && globalTaskMode) return;
        setSelectedValue(suggestion);
      }}
      renderInputComponent={renderTextArea}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      inputProps={{
        // onBlur: handleBlur,
        onFocus: () => setFocus(true),
        onBlur: () => setFocus(false),
        onChange: (_, { newValue, method }) => {},
        value,
        ref: areaRef,
      }}
      ref={autoSuggestRef}
    />
  );
}
