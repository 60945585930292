import React from "react";
import classNames from "classnames";
import "./SectionHeader.scss";
import { ReactComponent as InfoIcon } from "src/images/info-alt.svg";
import { MaterialTooltip } from "../../MaterialTooltip/MaterialTooltip";

function SectionHeader(props) {
  const { name, className, tooltip } = props;
  return (
    <div className={classNames("pane-section-header", {}, className)}>
      {name}

      {tooltip && (
        <MaterialTooltip alignLeft content={tooltip}>
          <InfoIcon />
        </MaterialTooltip>
      )}
    </div>
  );
}

export { SectionHeader };
