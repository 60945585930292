import { addSpaceIfNeeded } from "./addSpaceIfNeeded";

export const addPostsDataToLayout = (data, newLayout, format) => {
  const lastEl = addSpaceIfNeeded(data.type, newLayout, format);

  let x = 0;
  let y = 0;
  const w = 3;
  const h = 2;

  // first in row and half the width
  const spaceLeft = 12 - (lastEl.x + lastEl.w);

  if (spaceLeft >= 3) {
    x = lastEl.x + lastEl.w;
    y = lastEl.y;
  } else {
    x = 0;
    y = lastEl.y + lastEl.h;
  }

  const key = `post-data-${data.name
    .replaceAll(" ", "-")
    ?.replaceAll(".", "")
    .toLowerCase()}-${data.uuid}`;
  newLayout.push({ i: key, x, y, w, h });
};
