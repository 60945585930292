import React from "react";
import Flag from "../../../../../components/Flag/Flag";

function LocationList(props) {
  const { countryLocations, height } = props;

  const getData = () => {
    switch (height) {
      case 3:
        return countryLocations?.slice(0, 4);

      default:
        return countryLocations;
    }
  };

  return (
    <div className="profile-box__location-list">
      {getData()?.map((country, i) => (
        <div key={country} className="profile-box__location-list-item">
          <Flag countryCode={countryLocations[i]} />
          {country}
        </div>
      ))}
    </div>
  );
}

export { LocationList };
