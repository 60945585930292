import React, { useMemo } from "react";
import Loader from "../../../components/Loader/Loader";
import "./GeneratorLoader.scss";

export function GeneratorLoader(props) {
  const { text } = props;

  const LoaderComponent = useMemo(() => <Loader />, []);

  return (
    <div className="generator-loader">
      <div className="generator-loader__wrapper">
        {LoaderComponent}
        <span className="generator-loader__counter">{text}</span>
      </div>
    </div>
  );
}
