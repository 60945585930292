import React from "react";

import axios from "axios";
import { useSelector } from "react-redux";
import { cancelRequestAxios } from "./CancelRequest";
import { API_URLS } from "../../../../utils/API_URLS";

const cancelRequestAxiosObject = new cancelRequestAxios();

function SearchInput(props) {
  const { className, setLoadingStatus } = props;

  const { activeWorkspaceUuid } = useSelector((state) => state.mainReducer);

  const searchForResults = async (e) => {
    setLoadingStatus(true);
    props.updateSearchQueryValue(e.target.value);

    cancelRequestAxiosObject.cancelAndCreateToken();
    try {
      const response = await axios.get(API_URLS.getSearchResultsUrl, {
        params: {
          query: e.target.value,
          workspaceUuid: activeWorkspaceUuid,
        },
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        withCredentials: true,
        cancelToken: cancelRequestAxiosObject.cancel_resquest.token,
      });
      // On success reset cancelToken
      cancelRequestAxiosObject.resetCancelToken();

      props.response(response.data);
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // console.error("Request canceled", error.message);
      }
    }
  };

  const onFocus = async (e) => {
    if (e.target?.value === null) {
      return;
    }

    props.onFocus();
    await searchForResults(e);
  };

  return (
    <input
      id="ws-search-input"
      type="text"
      onChange={searchForResults}
      onFocus={onFocus}
      onBlur={props.onBlur}
      name="search"
      className={className}
      autoComplete="off"
      maxLength={100}
      placeholder={props.placeholder}
    />
  );
}

export default SearchInput;
