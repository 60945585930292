import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { showToast } from "./app/methods/showToast";
import IDHFormattedMessage from "./app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RootState } from "./redux/reducers";

export default function MessageListener() {
  const {
    mainReducer: { identifyError, errorMessages },
    projectReducer: { successMessage },
  } = useSelector((state: RootState) => state);

  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      identifyError &&
      pathname !== "/workspace/" &&
      pathname !== "/workspace/trial"
    ) {
      history.push("/workspace/");
    }
  }, [identifyError]);

  useEffect(() => {
    if (errorMessages && errorMessages.length > 0) {
      for (let i = 0; i < errorMessages.length; i++) {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage id={errorMessages[i].message} />,
        );
      }
    }
  }, [errorMessages]);

  useEffect(() => {
    if (successMessage && successMessage.length > 0) {
      showToast(
        "success",
        <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
        <IDHFormattedMessage id={successMessage} />,
      );
    }
  }, [successMessage]);

  return null;
}
