import React, { useRef } from "react";
import ReactDOM from "react-dom";
import "./YouTubeEmbed.scss";
import ReactPlayer from "react-player/youtube";
import classNames from "classnames";

import { useOnClickOutside } from "../../base/utils/useOnOutsideClick";
import Loader from "../Loader/Loader";

class YouTubeEmbed extends React.Component {
  el = document.createElement("div");

  componentDidMount() {
    this.el.classList.add("youtube-embed-overlay");
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      <YouTubeEmbedContent {...this.props} />,
      this.el,
    );
  }
}

function YouTubeEmbedContent({ onClose, postUrl, ...props }) {
  const ref = useRef();

  useOnClickOutside(ref, () => {
    onClose();
  });

  return (
    <div
      ref={ref}
      className={classNames("youtube-embed-container", "post-preview-modal")}
    >
      <Loader />
      <div className="youtube-embed-wrapper">
        <ReactPlayer
          url={postUrl}
          playing={1}
          controls={1}
          config={{
            playerVars: { controls: 1 },
          }}
          width={960}
          height={540}
        />
      </div>
      <div className="youtube-embed-container__close" onClick={onClose} />
    </div>
  );
}

export default YouTubeEmbed;
