import React from "react";
import { ReactComponent as ReportSentImg } from "../../../images/report-send-email.svg";

import Modal, {
  ModalRow,
  ModalText,
  ModalTitle,
} from "../../components/Modal/Modal";
import IDHFormattedMessage from "../../components/IDHFormattedMessage/IDHFormattedMessage";
import "./WsProjectReportShowcasePdfReadyModal.scss";

export interface WsProjectReportShowcasePdfReadyModalProps {
  reportUrl: string;
  closePdfReadyModal: () => void;
}

const WsProjectReportShowcasePdfReadyModal: React.FC<
  WsProjectReportShowcasePdfReadyModalProps
> = ({ reportUrl, closePdfReadyModal }) => {
  const urlToReport = () => {
    return (
      <a
        download="Report"
        target="_blank"
        href={reportUrl ?? ""}
        rel="noreferrer"
        className="ws-button ws-button--blue ws-button--large"
      >
        <IDHFormattedMessage id="ws_download" defaultMessage="Download" />
      </a>
    );
  };
  return (
    <Modal
      className="wsProjectReportShowcasePdfReadyModal"
      onClose={closePdfReadyModal}
      displayCancelButton
      customButton={urlToReport()}
      positionActionButtons="left"
    >
      <ModalRow className="wsProjectReportShowcasePdfReadyModal__text">
        <ModalTitle>
          <IDHFormattedMessage
            id="ws_report_modal_ready"
            defaultMessage="Your report is ready!"
          />
        </ModalTitle>
        <ModalText>
          <IDHFormattedMessage
            id="ws_raport_generated_download_copy_email"
            defaultMessage="Click the button below to download the file. You will also receive a copy in your email."
          />
        </ModalText>
      </ModalRow>
      <ModalRow className="wsProjectReportShowcasePdfReadyModal__icon">
        <ReportSentImg />
      </ModalRow>
    </Modal>
  );
};

export default WsProjectReportShowcasePdfReadyModal;
