import React from "react";

import ImageCropModal from "../CroppedImageUploader/ImageCropModal/ImageCropModal";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as PlusIcon } from "../../../images/plus-transparent.svg";
import CustomImage from "../CustomImage/CustomImage";
import "./CoverImageUploader.scss";

interface CoverImageUploaderProps {
  showUploadLogoModal: boolean;
  shouldShowControls?: boolean;
  uploadFunction: (file: File) => void;
  openModal: () => void;
  closeModal: () => void;
  coverImageUrl: string | null;
  removeImage: () => void;
}

export function CoverImageUploader({
  showUploadLogoModal,
  shouldShowControls = true,
  uploadFunction,
  openModal,
  closeModal,
  coverImageUrl,
  removeImage,
}: CoverImageUploaderProps) {
  return (
    <div className="cover-image-uploader__cover-photo-wrapper">
      <div className="cover-image-uploader__image">
        {showUploadLogoModal && (
          <ImageCropModal
            uploadFunction={uploadFunction}
            onClose={closeModal}
          />
        )}
        {!coverImageUrl ? (
          <div
            className="cover-image-uploader__placeholder"
            onClick={openModal}
          >
            <PlusIcon />
          </div>
        ) : (
          <CustomImage
            src={coverImageUrl}
            loading="lazy"
            alt="Cover image"
            className="cover-image-uploader__image-cover"
            onClick={openModal}
          />
        )}
      </div>
      {shouldShowControls && (
        <div className="cover-image-uploader__cover-photo-controls">
          {coverImageUrl ? (
            <>
              <button type="button" onClick={openModal}>
                <IDHFormattedMessage
                  id="ws_change_image"
                  defaultMessage="Change image"
                />
              </button>
              <span>・</span>
              <button type="button" onClick={removeImage}>
                <IDHFormattedMessage
                  id="ws_remove_image"
                  defaultMessage="Remove image"
                />
              </button>
            </>
          ) : (
            <button type="button" onClick={openModal}>
              <IDHFormattedMessage
                id="ws_upload_image"
                defaultMessage="Upload image"
              />
            </button>
          )}
        </div>
      )}
    </div>
  );
}
