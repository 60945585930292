import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  clearRelatedGlobalTasks,
  getRelatedGlobalTasks,
} from "src/redux/creator-database/creatorDatabaseActions";
import { TaskType } from "src/types";
import { RootState } from "src/redux/reducers";
import { GlobalContentTab } from "./tabs/GlobalContentTab";
import { GlobalPublicationsTab } from "./tabs/GlobalPublicationsTab";
import { GlobalPaymentsTab } from "./tabs/GlobalPaymentsTab";
import { GlobalActionsTab } from "./tabs/GlobalActionsTab";
import { SkeletonLoader } from "./utils";

interface GlobalActivityTaskTypeTabWrapperProps {
  taskId: string;
  activeWorkspaceUuid: string;
  taskType: TaskType;
}

export function GlobalActivityTaskTypeTabWrapper({
  taskId,
  taskType,
  activeWorkspaceUuid,
}: GlobalActivityTaskTypeTabWrapperProps) {
  const dispatch = useDispatch();
  const {
    creatorDatabaseReducer: {
      isGlobalTaskRelatedTasksLoading,
      globalTaskRelatedTasks,
    },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(getRelatedGlobalTasks(activeWorkspaceUuid, taskId, taskType));
  }, [taskType, taskId, activeWorkspaceUuid]);

  useEffect(() => {
    () => {
      dispatch(clearRelatedGlobalTasks());
    };
  }, []);

  if (isGlobalTaskRelatedTasksLoading) {
    return <SkeletonLoader avatarShape="rect" />;
  }

  switch (taskType) {
    case TaskType.Content:
      return (
        <GlobalContentTab tasks={globalTaskRelatedTasks.relatedContentTasks} />
      );
    case TaskType.Publication:
      return (
        <GlobalPublicationsTab
          tasks={globalTaskRelatedTasks.relatedPublicationTasks}
        />
      );
    case TaskType.Payment:
      return (
        <GlobalPaymentsTab tasks={globalTaskRelatedTasks.relatedPaymentTasks} />
      );
    case TaskType.Action:
      return (
        <GlobalActionsTab tasks={globalTaskRelatedTasks.relatedActionTasks} />
      );
    default:
      return null;
  }
}
