import React, { useEffect, useRef, useState } from "react";
import "./TopSearch.scss";

import { useSelector } from "react-redux";
import classNames from "classnames";
import axios from "axios";

import { API_URLS } from "src/utils/API_URLS";
import { ReactComponent as MagnifierIcon } from "src/images/magnifier-grey.svg";
import { ReactComponent as NoResultsIcon } from "src/images/eyes.svg";
import { ReactComponent as CrossIcon } from "src/images/cross.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { injectIntl, IntlShape } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { TaskType } from "src/types";
import useOnClickOutside from "../../methods/useOnClickOutside";

import Loader from "../../components/Loader/Loader";
import SearchInput from "./components/SearchInput";
import { ProjectsResults } from "./sections/ProjectsResults/ProjectsResults";
import { TasksResults } from "./sections/TasksResults/TasksResults";
import { GlobalTaskResults } from "./sections/GlobalTaskResults/GlobalTaskResults";
import { DictionarySearchResults } from "./sections/DictionaryResults/DictionarySearchResults";

interface Props {
  intl: IntlShape;
}

interface TaskSearchResult {
  hasAccessToProject: boolean;
  isCompleted: boolean;
  projectUuid: string;
  taskType: TaskType;
  workspaceUuid: string;
  wsTaskCover: any;
  wsTaskName: string;
  wsTaskUuid: string;
}

interface TasksSearchResults {
  actions: TaskSearchResult[];
  contents: TaskSearchResult[];
  payments: TaskSearchResult[];
  publications: TaskSearchResult[];
}

export interface GlobalTaskSearchResult {
  avatarUrl: string;
  socialProfiles: any;
  title: string;
  uuid: string;
  wsWorkspaceUuid: string;
}

export interface DictionaryElementSearchResultItem {
  wsWorkspaceUuid: string;
  wsDictionaryTitle: string;
  wsDictionaryUuid: string;
  wsDictionaryIcon: string;
  wsDictionaryElementTitle: string;
  wsDictionaryElementUuid: string;
  wsDictionaryElementUrl: string;
}

export interface DictionarySearchResult {
  wsDictionaryTitle: string;
  wsDictionaryUuid: string;
  wsDictionaryIcon: string;
  wsDictionaryUrl: string;
  wsDictionaryElements: DictionaryElementSearchResultItem[];
}

function TopSearch(props: Props) {
  const { intl } = props;

  const containerRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any | null>(null);
  const [tasksResults, setTasksResults] = useState<TasksSearchResults | null>(
    null,
  );
  const [globalTasksResults, setGlobalTasksResults] = useState<
    GlobalTaskSearchResult[]
  >([]);
  const [dictionarySearchResults, setDictionarySearchResults] = useState<
    DictionarySearchResult[]
  >([]);
  const [hideSearchMenu, setHideSearchMenu] = useState<boolean>(false);

  const { activeWorkspaceUuid } = useSelector(
    (state: any) => state.mainReducer,
  );
  const searchInput = document.getElementById(
    "ws-search-input",
  ) as HTMLInputElement;

  useOnClickOutside(containerRef, () => setHideSearchMenu(true));

  useEffect(() => {
    if (searchResults?.tasks) {
      setTasksResults(searchResults?.tasks);
    }

    if (searchResults?.globalTasks) {
      setGlobalTasksResults(searchResults.globalTasks);
    }

    if (searchResults?.dictionaries) {
      setDictionarySearchResults(searchResults.dictionaries);
    }
  }, [searchResults]);

  const updateSearchQueryValue = (value: any) => {
    setSearchResults(null);
    setSearchQuery(value);
  };

  const response = (data: any) => {
    setSearchResults(data?.content);
    setLoading(false);
  };

  const freshResults = () => {
    setLoading(true);
    axios
      .get(API_URLS.getSearchResultsUrl, {
        params: {
          query: "",
          workspaceUuid: activeWorkspaceUuid,
        },
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((result: any) => {
        setSearchResults(result?.data?.content);
        setLoading(false);
      });
  };

  const clearResults = () => {
    freshResults();
    setSearchQuery("");

    if (!searchInput) {
      return;
    }

    searchInput.value = "";
  };

  const showMenu = searchQuery.length >= 3 && !hideSearchMenu;
  const existsAtLeastOneTask =
    tasksResults?.actions?.length ||
    tasksResults?.contents?.length ||
    tasksResults?.publications?.length ||
    tasksResults?.payments?.length;
  const existsAtLeastOneGlobalTask = globalTasksResults?.length;
  const existsAtLeastDictionary = dictionarySearchResults?.length;
  const notEmpty =
    searchResults?.projects?.length ||
    existsAtLeastOneTask ||
    existsAtLeastOneGlobalTask ||
    existsAtLeastDictionary;

  return (
    <div
      ref={containerRef}
      className={classNames("top-search", {
        "top-search--focused": inputFocused || showMenu || notEmpty,
      })}
      onClick={() => {
        if (inputRef?.current) {
          inputRef.current.focus();
        }
        setHideSearchMenu(false);
      }}
    >
      <MagnifierIcon className="top-search__magnifier-icon" />
      <div className="top-search__input-wrapper">
        <SearchInput
          className="top-search__input"
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          updateSearchQueryValue={updateSearchQueryValue}
          searchQuery={searchQuery}
          response={response}
          placeholder={`${translateMessage({
            intl,
            id: "ws_search",
            defaultMessage: "Search",
          })}...`}
          setLoadingStatus={setLoading}
        />
        <CrossIcon
          className={classNames("top-search__clear-button", {
            "top-search__clear-button--hidden": !searchQuery.length,
          })}
          onClick={clearResults}
        />
      </div>

      {showMenu && (
        <div className="top-search__menu">
          {notEmpty && !loading ? (
            <>
              <GlobalTaskResults
                data={globalTasksResults}
                clearResults={clearResults}
              />
              <ProjectsResults
                data={searchResults?.projects}
                showSeparator={existsAtLeastOneTask}
                clearResults={clearResults}
              />
              <TasksResults data={tasksResults} clearResults={clearResults} />
              <DictionarySearchResults
                data={dictionarySearchResults}
                clearResults={clearResults}
              />
            </>
          ) : loading ? (
            <span className="top-search__menu-loader">
              <Loader />
            </span>
          ) : (
            <span className="top-search__menu-placeholder">
              <NoResultsIcon />
              <IDHFormattedMessage
                id="ws_no_results_found"
                defaultMessage="No results found"
              />
              ...
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default injectIntl(TopSearch);
