import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RootState } from "src/redux/reducers";
import NoResultsScreen from "src/app/components/NoResultsScreen/NoResultsScreen";
import { IMyTask } from "src/redux/task/taskReducer";
import NoResultsImg from "../../../images/be-patient.svg";
import TaskRow from "../TaskRow/TaskRow";

import "./RecentlyAssigned.scss";

interface Props {
  myTasks: IMyTask[];
}

function RecentlyAssigned(props: Props) {
  const { myTasks } = props;

  const { myTasksLoading } = useSelector(
    (state: RootState) => state.taskReducer,
  );

  if (myTasks.length === 0 && !myTasksLoading)
    return (
      <div className="recently-assigned__no-results">
        <NoResultsScreen
          title={
            <IDHFormattedMessage
              id="ws_there_are_no_tasks"
              defaultMessage="There are no tasks"
            />
          }
          subtitle={
            <IDHFormattedMessage
              id="ws_patiently_wait_for_the_tasks"
              defaultMessage="Patiently wait for the tasks assigned to you to appear."
            />
          }
          imgUrl={NoResultsImg}
        />
      </div>
    );

  return (
    <div className={classNames("recently-assigned", {})}>
      <div className={classNames("recently-assigned__list", {})}>
        <table>
          <thead>
            <tr>
              <th />
              <th className="recently-assigned__list-name-header">
                <IDHFormattedMessage id="ws_name" defaultMessage="Name" />
              </th>
              <th>
                <IDHFormattedMessage
                  id="ws_campaign"
                  defaultMessage="campaign"
                />
              </th>
              <th>
                <IDHFormattedMessage
                  id="ws_due_date"
                  defaultMessage="Due date"
                />
              </th>
            </tr>
          </thead>

          <tbody>
            {myTasks.map((task) => (
              <TaskRow key={task.wsTaskUuid} task={task} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RecentlyAssigned;
