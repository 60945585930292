import React from "react";

interface DictionaryElementTableLabelProps {
  title: string;
  icon: JSX.Element | null;
}

export default function DictionaryElementTableLabel({
  title,
  icon,
}: DictionaryElementTableLabelProps) {
  return (
    <span className="image-select-option dictionary-element-option">
      {icon}
      {title}
    </span>
  );
}
