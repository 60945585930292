import { getWorkspaceUuidFromCurrentUrl } from "src/utils/methods";

import { renderCell } from "./renderCell";
import { renderTitle } from "./renderTitle";
import { renderAction } from "./renderAction";

export const generateRows = (projects, columns = []) => {
  const activeWorkspaceUuid = getWorkspaceUuidFromCurrentUrl();

  return projects.map((p) => {
    const projectKeys = {};

    columns.forEach((col) => {
      Object.assign(projectKeys, {
        [col.uuid]: renderCell(
          p.metadata.find(
            (pm) => pm.wsGlobalProjectMetaFieldUuid === col.uuid,
          ) || {
            wsGlobalProjectMetaFieldUuid: col.uuid,
            type: col.type,
            value: null,
            data: col.data,
          },
          p.projectId,
          col.valueSource,
        ),
      });
    });

    return {
      projectName: renderTitle({
        activeWorkspaceUuid,
        ...p,
      }),
      ...projectKeys,
      projectAction: renderAction(p),
    };
  });
};
