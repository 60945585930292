import {AppConfig} from "../../../../../../config/AppConfig";

const API_HOST = AppConfig.getApiHost();

const CONSTS = {
  getUrls: `${API_HOST}/brand-panel-api/url`,
  brandLogin: `${API_HOST}/brand/agency-login`,
  getAuthorizationIsLogin: `${API_HOST}/authorization/brand/is-brand-login`,
  getTranslations: `${API_HOST}/trans/get-trans-for-domain/identity-brand`,
  getTranslationsForBrand: `${API_HOST}/trans/get-trans-for-brand-panel-domain`,
  getTranslationsForCampaignCreator: `${API_HOST}/trans/get-trans-for-campaign-creator-domain`,
  getProfiledata: `${API_HOST}/brand-panel-api/profile/get`,
  getProfileSubusers: `${API_HOST}/brand-panel-api/sub-user/get-sub-users`,
  postProfileSubuser: `${API_HOST}/brand-panel-api/sub-user/register-new-sub-user`,
  getProfileCountries: `${API_HOST}/brand-panel-api/countries`,
  postProfileData: `${API_HOST}/brand-panel-api/profile/edit`,
  getBrandBasicData: `${API_HOST}/brand-panel-api/brand-identity`,
  getBrandAuthorization: `${API_HOST}/brand-panel-api/brand-authorization`,
  getTranslationCountries: `${API_HOST}/trans/get-trans-available-countries`,
  subuserRemove: `${API_HOST}/brand-panel-api/sub-user/delete/:id:`,
  subuserToggleActive: `${API_HOST}/brand-panel-api/sub-user/active/:id:`,
  subuserToggleNotifications: `${API_HOST}/brand-panel-api/sub-user/notifications/:id:`,
  subuserToggleFinancialData: `${API_HOST}/brand-panel-api/sub-user/change-financial-data-access/:id:`,
  subuserTogglePostVisibility: `${API_HOST}/brand-panel-api/sub-user/change-post-visbility-managment/:id:`,
  subuserToggleAudienceManager: `${API_HOST}/brand-panel-api/sub-user/influencer-insights-tool-access/:id:`,
  getDashboardStats: `${API_HOST}/brand-panel-api/campaign-group/statistics/dashboard-stats`,
  getCampaignGroups: `${API_HOST}/brand-panel-api/campaign-groups/list/:type:?page=:page:`,
  getCampaignGroupsCount: `${API_HOST}/brand-panel-api/campaign-groups/count/:type:?folder_id=:id:`,
  getCampaignGroup: `${API_HOST}/brand-panel-api/campaign-groups/:group_campaign_id:/get`,
  getCampaignGroupPostsAggregatedPublished: `${API_HOST}/entities/aggregated/get-published/:campaign_group_id:/?onPage=:onPage:&size=:size:`,
  dashboardEntitiesAggregatedPremoderate: `${API_HOST}/entity_presubmission/brand/aggregated/get-forwarded-to-brand?page=:page:`,
  dashboardEntitiesAggregatedCPCon: `${API_HOST}/entity_presubmission/brand/aggregated/get-cpcon-accepted?page=:page:`,
  dashboardEntitiesAggregatedPremoderateCount: `${API_HOST}/entity_presubmission/brand/aggregated/count-multi-forwarded-to-brand`,
  dashboardEntitiesAggregatedCPConCount: `${API_HOST}/entity_presubmission/brand/aggregated/count-cpcon-accepted`,
  dashboardEntitiesAggregatedRecentlyCount: `${API_HOST}/entities/aggregated/count-published-by-filter`,
  dashboardAggregatedWhitelistCount: `${API_HOST}/brand-panel-api/whitelist-group/count-awaiting`,
  dashboardEntitiesAggregatedRecent: `${API_HOST}/entities/aggregated/get-published-by-filter?page=:page:`,
  postsCampaignGroupUrl: `${API_HOST}/brand-panel-api/campaign-group/:campaign_group_id:/posts/:type:`,
  statsCampaignGroupUrl: `${API_HOST}/brand-panel-api/campaign-group/:campaign_group_id:/statistics`,
  getPostsAggregatedPublished: `${API_HOST}/entities/aggregated/get-published/:campaign_group_id:/?page=:page:&size=:size:`,
  getPostsAggregatedAwaiting: `${API_HOST}/entity_presubmission/brand/aggregated/get-forwarded-to-brand/:campaign_group_id:/?page=:page:&onPage=:onPage:`,
  getPostsAggregatedAccepted: `${API_HOST}/entity_presubmission/brand/aggregated/get-accepted/:campaign_group_id:/?page=:page:`,
  getPostsAggregatedRevised: `${API_HOST}/entity_presubmission/brand/aggregated/get-revised/:campaign_group_id:/?page=:page:`,
  getPostsAggregatedRejected: `${API_HOST}/entity_presubmission/brand/aggregated/get-rejected/:campaign_group_id:/?page=:page:`,
  postEntityRevise: `${API_HOST}/entity_presubmission/brand/revise`,
  postEntityReviseNote: `${API_HOST}/entity_presubmission/brand/note/revise`,
  postEntityAccept: `${API_HOST}/entity_presubmission/brand/accept`,
  postEntityAcceptNote: `${API_HOST}/entity_presubmission/brand/note/accept`,
  postEntityAcceptCpcon: `${API_HOST}/entity_presubmission/brand/accept-cpcon`,
  postEntityAcceptCpconExternalContent: `${API_HOST}/entity_presubmission/brand/accept-cpcon-external-content`,
  postEntityReject: `${API_HOST}/entity_presubmission/brand/reject`,
  postEntityRejectNote: `${API_HOST}/entity_presubmission/brand/note/reject`,
  postEntityUndoReject: `${API_HOST}/entity_presubmission/brand/undo-rejected`,
  postEntityForwardToBrand: `${API_HOST}/entity_presubmission/brand/forward-to-brand`,
  downloadAllCampaignsXlsReportUrl: `${API_HOST}/brand-panel-api/brand-report/download-xls-report`,
  downloadCampaignGroupReportXlsUrl: `${API_HOST}/brand-panel-api/campaign-group-report/download-xls-report?reach=:reach:&:campaign_groups_ids:`,
  downloadCampaignGroupReportAggregatedXlsUrl: `${API_HOST}/brand-panel-api/campaign-group-report/download-combined-xls-report?reach=:reach:&:campaign_groups_ids:`,
  getPostsAggregatedPublishedCount: `${API_HOST}/entities/aggregated/count-published/:campaign_group_id:`,
  getPostsAggregatedAwaitingCount: `${API_HOST}/entity_presubmission/brand/aggregated/count-forwarded-to-brand/:campaign_group_id:`,
  getPostsAggregatedAcceptedCount: `${API_HOST}/entity_presubmission/brand/aggregated/count-accepted/:campaign_group_id:`,
  getPostsAggregatedRejectedCount: `${API_HOST}/entity_presubmission/brand/aggregated/count-rejected/:campaign_group_id:`,
  getPostsAggregatedRevisedCount: `${API_HOST}/entity_presubmission/brand/aggregated/count-revised/:campaign_group_id:`,
  getCampaignGroupReport: `${API_HOST}/brand-panel-api/campaign-group-report/get-campaign-group-report`,
  getCampaignGroupReportAggregated: `${API_HOST}/brand-panel-api/campaign-group-report/get-campaign-group-combined-report`,
  getCampaignGroupReportData: `${API_HOST}/campaign_report/campaign_generate_new_report_json_preview`,
  generateCampaignGroupReportPdf: `${API_HOST}/brand-panel-api/campaign-group-report/generate-pdf-report`,
  generateCampaignGroupReportPptx: `${API_HOST}/brand-panel-api/campaign-group-report/generate-pptx-report`,
  getCampaignContent: `${API_HOST}/brand-panel-api/campaigns/download-content`,
  getCampaignGroupContents: `${API_HOST}/brand-panel-api/campaign-group-content/download-content`,
  downloadCombinedXlsxReport: `${API_HOST}/brand-panel-api/campaign-groups/download-combined-xls-report`,
  getLastEndedCampaignForFeedback: `${API_HOST}/brand-panel-api/campaign-feedback/get-last-ended-campaign`,
  postCampaignFeedback: `${API_HOST}/brand-panel-api/campaign-feedback/:id:`,
  postCampaignFeedbackClose: `${API_HOST}/brand-panel-api/campaign-feedback/resign`,
  postCampaignFeedbackIamNotResponsible: `${API_HOST}/brand-panel-api/campaign-feedback/i-am-not-responsible`,
  postPostVisibility: `${API_HOST}/entity_presubmission/brand/change-post-visibility`,
  getFilterCampaignGroups: `${API_HOST}/brand-panel-api/campaign-groups/list-filter?type=:type:&folder_id=:id:`,
  getFilterCampaignGroupsComingSoon: `${API_HOST}/brand-panel-api/campaign-groups/list-coming-soon-filter?type=:type:`,
  getFilterAgencies: "",
  getLimitedCampaignGroups: `${API_HOST}/brand-panel-api/campaign-groups/list-limited/:limit:`,
  getCampaignGroupReachStats: `${API_HOST}/brand-panel-api/campaign-group/statistics/:campaign_group_id:/reach-stats`,
  getCampaignGroupImpressionsStats: `${API_HOST}/brand-panel-api/campaign-group/statistics/:campaign_group_id:/impressions-stats`,
  getCampaignGroupRealReachStats: `${API_HOST}/brand-panel-api/campaign-group/statistics/:campaign_group_id:/real-reach-stats`,
  getCampaignGroupViewsStats: `${API_HOST}/brand-panel-api/campaign-group/statistics/:campaign_group_id:/view-stats`,
  getCampaignGroupLikesStats: `${API_HOST}/brand-panel-api/campaign-group/statistics/:campaign_group_id:/likes-stats`,
  getCampaignGroupCommentsStats: `${API_HOST}/brand-panel-api/campaign-group/statistics/:campaign_group_id:/comments-stats`,
  getCampaignGroupPostsStats: `${API_HOST}/brand-panel-api/campaign-group/statistics/:campaign_group_id:/posts-stats`,
  getCampaignGroupBiggestDigitalInfluencers: `${API_HOST}/brand-panel-api/campaign-group/statistics/:campaign_group_id:/most-biggest-digital-influencers-stats`,
  getCampaignGroupBiggestRealReachInfluencers: `${API_HOST}/brand-panel-api/campaign-group/statistics/:campaign_group_id:/biggest-real-reach-influencers-stats`,
  getCampaignGroupMostEngagingInfluencers: `${API_HOST}/brand-panel-api/campaign-group/statistics/:campaign_group_id:/most-engaging-influencers-stats`,
  addSubuserToCampaignGroup: `${API_HOST}/brand-panel-api/sub-user/add-campaign-group-id-to-campaign-group-acl`,
  deleteSubuserFromCampaignGroup: `${API_HOST}/brand-panel-api/sub-user/delete-campaign-group-id-from-campaign-group-acl`,
  getSubuserCampaignGroupAccess: `${API_HOST}/brand-panel-api/sub-user/get-sub-users-campaign-group-access/:campaignGroupId:`,
  getSubuserCampaignGroupWlAccess: `${API_HOST}/brand-panel-api/sub-user/get-sub-users-coming-soon-campaign-group-access/:campaignGroupId:`,
  addSubuserToWlCampaignGroup: `${API_HOST}/brand-panel-api/sub-user/add-campaign-group-id-to-coming-soon-campaign-group-acl`,
  deleteSubuserFromWlCampaignGroup: `${API_HOST}/brand-panel-api/sub-user/delete-campaign-group-id-from-coming-soon-campaign-group-acl`,
  getPage: `${API_HOST}/static_pages/:name:/:locale:/get-static-page`,
  postConactData: "TODO",
  postSetLocale: `${API_HOST}/trans/set-locale`,
  getSubuserPermissionGroups: `${API_HOST}/authorization/brand/get-brand-user-permission-groups`,
  postSubuserPermissionGroup: `${API_HOST}/authorization/brand/set-brand-permission-group`,
  getPermissions: `${API_HOST}/authorization/brand/get-available-permission/:id:`,
  getPermissionsForBrandUser: `${API_HOST}/authorization/brand-user/get-available-permission/:id:`,
  postSubuserAllowedCampaignGroups: `${API_HOST}/brand-panel-api/sub-user/set-allowed-campaign-groups/:id:`,
  postSubuserAllowedWhitelists: `${API_HOST}/brand-panel-api/sub-user/set-allowed-coming-soon-campaign-groups/:id:`,
  postSubuserAllowedAgencies: `${API_HOST}/brand-panel-api/sub-user/set-allowed-super-brand-admin-users/:id:`,
  postUserCampaignGroupsNotifications: `${API_HOST}/brand-panel-api/change-campaign-group-notifications`,
  postUserWhitelistsNotifications: `${API_HOST}/brand-panel-api/change-whitelist-notifications`,
  getSuperBrandAdminUsers: `${API_HOST}/brand-panel-api/get-super-brand-admin-users`,
  getSuperBrandAdminUsersFilter: `${API_HOST}/brand-panel-api/get-super-brand-admin-users-filter`,
  getSuperBrandExternalLoginToBrand: `${API_HOST}/brand-panel-api/external-login-to-brand`,
  getCarouselMedia: `${API_HOST}/entity_presubmission/brand/get-entity-presubmission-group/:entityId:`,
  postCarouselEntityRevise: `${API_HOST}/entity-presubmission-group-element/brand/revise`,
  postCarouselEntityAccept: `${API_HOST}/entity-presubmission-group-element/brand/accept`,
  postCarouselEntityUndoAccept: `${API_HOST}/entity-presubmission-group-element/brand/undo-accept`,
  postCarouselEntityAcceptAll: `${API_HOST}/entity-presubmission-group-element/brand/accept-all`,
  postCarouselEntityAcceptAllNote: `${API_HOST}/entity-presubmission-group-element/brand/note/accept-all`,
  postCarouselEntityRejectAll: `${API_HOST}/entity-presubmission-group-element/brand/reject-all`,
  postCarouselEntityRejectAllNote: `${API_HOST}/entity-presubmission-group-element/brand/note/reject-all`,
  postCarouselEntitySend: `${API_HOST}/entity-presubmission-group-element/brand/send-revise`,
  postCarouselEntitySendNote: `${API_HOST}/entity-presubmission-group-element/brand/note/send-revise`,
  getCarouselReviseCaptions: `${API_HOST}/entity-presubmission-group-element/brand/get-revised-photos-captions/:groupId:/:appId:`,
  getBrandAccounts: `${API_HOST}/brand-panel-api/switch-brand-user/:brandUserId:`,
  getEntityPresubmissionDescriptionTranslation: `${API_HOST}/entity_presubmission/brand/translate-description/:entityPresubmissionId:/:appId:`,
  getEntityPresubmissionDescriptionTranslationPublished: `${API_HOST}/entity/brand/translate-description/:entityId:/:appId:`,
  getEntityPresubmissionActivities: `${API_HOST}/brand-panel-api/entity-presubmission-activities/brand/entity`,
  getEntityPresubmissionGroupElementActivities: `${API_HOST}/brand-panel-api/entity-presubmission-activities/brand/group-element`,
  getWhitelists: `${API_HOST}/brand-panel-api/whitelist-group/get-list/:page:?onPage=:onPage:`,
  getFilterWhitelists: `${API_HOST}/brand-panel-api/whitelist-group/list-filter`,
  getFilterAwaitingWhitelists: `${API_HOST}/brand-panel-api/whitelist-group/list-filter?type=:query:`,
  loginWhitelistAsBrand: `${API_HOST}/authentication/login-whitelist-as-brand/:id:`,
  postWhitelistsSwitchStatus: `${API_HOST}/brand-panel-api/campaign-groups/coming-soon-status`,
  getCampaignSettings: `${API_HOST}/brand-panel-api/campaign/creator/get-campaign-settings/:campaignId:`,
  postCampaignCreatorRecruitment: `${API_HOST}/brand-panel-api/campaign/recruitment-campaign/creator/create-new/:socialProvider:`,
  postCampaignCreatorRecruitmentEdit: `${API_HOST}/brand-panel-api/campaign/recruitment-campaign/creator/edit`,
  postValidateCampaignCreatorRecruitment: `${API_HOST}/brand-panel-api/campaign/recruitment-campaign/creator/check-new-campaign-valid/:socialProvider:`,
  postValidateCampaignCreatorRecruitmentEdit: `${API_HOST}/brand-panel-api/campaign/recruitment-campaign/creator/check-edited-campaign-valid`,
  postCampaignCreator: `${API_HOST}/brand-panel-api/campaign/creator/create-new/:socialProvider:/:billingType:/:whitelistType:/:country:`,
  postCampaignCreatorEdit: `${API_HOST}/brand-panel-api/campaign/creator/edit/:campaignId:`,
  postValidateCampaignCreator: `${API_HOST}/brand-panel-api/campaign/creator/valid-new-campaign/:socialProvider:/:billingType:/:whitelistType:/:country:`,
  postValidateCampaignCreatorEdit: `${API_HOST}/brand-panel-api/campaign/creator/valid_edited_campaign/:campaignId:`,
  getCampaignCreatorPresignedS3Upload: `${API_HOST}/brand-panel-api/campaign/recruitment-campaign/creator/get-upload-photo-data`,
  getCampaignCreatorData: `${API_HOST}/brand-panel-api/campaign/recruitment-campaign/creator/get-creator-data/:locale:`,
  getCampaignCreatorRecruitmentEditData: `${API_HOST}/brand-panel-api/campaign/recruitment-campaign/creator/get-campaign/:id:`,
  getCampaignCreatorEditData: `${API_HOST}/brand-panel-api/campaign/creator/get-campaign/:id:`,
  getCamppaignCreatorDuplicateData: `${API_HOST}/brand-panel-api/campaign/duplicate/get-data-by-campaign/:campaignId:`,
  getCampaignWhitelists: `${API_HOST}/brand-panel-api/campaign/creator/get-coming-soon-campaigns/:country:/:socialProvider:`,
  getCampaignWhitelistsEdit: `${API_HOST}/brand-panel-api/campaign/creator/get-coming-soon-campaigns-edit/:country:/:id:/:socialProvider:`,
  getSelectIoNumbers: `${API_HOST}/pipedrive/deals/won-select-data?q=:query:&campaignId=:campaignId:`,
  getCampaignCurrency: `${API_HOST}/brand-panel-api/campaign/creator/get-currency-by-country/:country:`,
  getCampaignTimezone: `${API_HOST}/brand-panel-api/campaign/creator/get-timezone-by-country/:country:`,
  getInfluencerActivity: `${API_HOST}/user/get-influencer-activity-record`,
  postNewFolderAdd: `${API_HOST}/brand-panel-api/campaign-folders/create`,
  campaignFolderPresignedUpload: `${API_HOST}/brand-panel-api/campaign-folders/presigned-upload-request`,
  createAsset: `${API_HOST}/brand-panel-api/asset/create`,
  deleteAsset: `${API_HOST}/brand-panel-api/asset/delete/:brandAssetId:`,
  getCampaignFolders: `${API_HOST}/brand-panel-api/campaign-folders?page=:page:`,
  postFoldersDelete: `${API_HOST}/brand-panel-api/campaign-folders/delete`,
  postFolderRename: `${API_HOST}/brand-panel-api/campaign-folders/update-name`,
  postCampaignToFolderAssign: `${API_HOST}/brand-panel-api/campaign-folders/assign-to-folder`,
  postCampaignGroupCreateAndAssign: `${API_HOST}/brand-panel-api/campaign-folders/create-and-assign-campaign-group`,
  getFolderStatistics: `${API_HOST}/brand-panel-api/campaign-folders/stats?folder_id=:id:`,
  getNotes: `${API_HOST}/publication-note/list?entityId=:entityId:&entityPresubmissionId=:entityPresubmissionId:`,
  createNote: `${API_HOST}/publication-note/create`,
  deleteNote: `${API_HOST}/publication-note/delete`,
  editNote: `${API_HOST}/publication-note/edit`,
  getActivityPostNotes: `${API_HOST}/activity-post-note/list?entityId=:entityId:&entityPresubmissionId=:entityPresubmissionId:`,
  createActivityPostNote: `${API_HOST}/activity-post-note/create`,
  deleteActivityPostNote: `${API_HOST}/activity-post-note/delete/?activityPostNoteId=:activityPostNoteId:`,
  setActivityPostRating: `${API_HOST}/activity-post-rating/set`,
  getSettings: `${API_HOST}/influencer-insights-tool/influencer-search/get-influencer-discovery-settings`,
  getInterests: `${API_HOST}/influencer-insights-tool/influencer-search/get-audience-interests-list`,
  getCountries: `${API_HOST}/influencer-insights-tool/influencer-search/get-countries-list`,
  getAudienceBrandAffinity: `${API_HOST}/influencer-insights-tool/influencer-search/get-audience-brands`,
  getKeywords: `${API_HOST}/influencer-insights-tool/influencer-search/get-ai-tags`,
  searchAudience: `${API_HOST}/influencer-insights-tool/influencer-search/search-by-filters`,
  getInfluencerInterestsList: `${API_HOST}/influencer-insights-tool/influencer-search/get-influencer-interests-list`,
  createInfluencersList: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/create-new-list`,
  getInfluencersList: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/show-lists`,
  getInfluencersListData: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/show-list-content`,
  refreshInfluencersList: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/refresh-list`,
  deleteInfluencersListData: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/delete-list`,
  editName: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/rename-list`,
  deleteInfluencersFromList: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/remove-users-from-list`,
  deleteAwaitingInfluencersFromList: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/remove-awaiting-user-from-list`,
  addToAudiences: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/add-users-to-list`,
  addCreatorsToListFromSocials: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/add-users-to-list-from-socials`,
  duplicateInfluencersListData: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/duplicate-list`,
  updateSearchFilters: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/update-list-search-filters`,
  changeListVisibility: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/change-list-visibility`,
  changeListBrandSafetyEmailNotification: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/change-list-brand-safety-email-notification`,
  getVelocitySummaryData: `${API_HOST}/influencer-insights-tool/influencer-insights-velocity-index/summary`,
  getProfile: `${API_HOST}/influencer-insights-tool/influencer-profile/profile-data/:userId:`,
  checkProfileData: `${API_HOST}/influencer-insights-tool/influencer-profile/profile-data-check`,
  getProfileLists: `${API_HOST}/influencer-insights-tool/influencer-insights-lists/get-lists-with-user`,
  getProfileListDetails: `${API_HOST}/influencer-insights-tool/influencer-insights-velocity-index/list-summary`,
  getProfileCampaignGroup: `${API_HOST}/user/get-profile-data`,
  exportListXls: `${API_HOST}/influencer-insights-tool/influencer-insights-exports/export-xls`,
  exportFullListXls: `${API_HOST}/influencer-insights-tool/influencer-insights-exports/list-export-xls`,
  exportListPdfPptx: `${API_HOST}/influencer-insights-tool/influencer-insights-exports/queue-export`,
  exportFullListPdfPptx: `${API_HOST}/influencer-insights-tool/influencer-insights-exports/queue-list-export`,
  getPublishedPosts: `${API_HOST}/influencer-insights-tool/influencer-profile/brand-entities/:userId:/:page:?onPage=5`,
  getPopularHashtags: `${API_HOST}/influencer-insights-tool/popular-hashtags/autocomplete?`,
  getPopularMentions: `${API_HOST}/influencer-insights-tool/popular-mentions/autocomplete?`,
  getinfluencerCities: `${API_HOST}/influencer-insights-tool/cities/autocomplete?`,
  getAudienceOverlap: `${API_HOST}/influencer-insights-tool/influencer-search/get-audience-overlap/:creators_list_id:`,
  getPublishedPostsOnCampaign: `${API_HOST}/user/brand-entities/:page:?onPage=5`,
  syncAudienceAndSocialInfluencerSearch: `${API_HOST}/influencer-insights-tool/influencer-profile/sync-influencer`,
  getSearchResultsUrl: `${API_HOST}/brand-panel-api/search`,
  postProfileAvatarUpdate: `${API_HOST}/brand-panel-api/profile/update-avatar`,
  getProfileAvatarPresignedRequest: `${API_HOST}/brand-panel-api/profile/avatar-presigned-request`,
  postUpdateBrandSafetySettings: `${API_HOST}/influencer-insights-tool/brand-safety/update-brand-safety-settings`,
  downloadAudienceOverlapXls: `${API_HOST}/influencer-insights-tool/influencer-insights-exports/download-audience-overlap-xlsx/:creators_list_id:`,

  whitelists: {
    getMainData: `${API_HOST}/brand-whitelist-group/whitelist-group-data/:whitelist_id:`,
    getRecommendedBrandUserAccount: `${API_HOST}/brand-panel-api/get-recommended-brand-user-account/:whitelistGroupId:`,
    getUsers: `${API_HOST}/brand-whitelist-group/get-users/:whitelist_id:?status=:status:`,
    getUsersTotal: `${API_HOST}/brand-whitelist-group/count-total/:whitelist_group_id:`,
    acceptUser: `${API_HOST}/brand-whitelist-group/user-accept`,
    rejectUser: `${API_HOST}/brand-whitelist-group/user-reject`,
    undoStatus: `${API_HOST}/brand-whitelist-group/undo-status`,
    addComment: `${API_HOST}/brand-whitelist-group/create-note`,
    editComment: `${API_HOST}/brand-whitelist-group/save-note`,
    deleteComment: `${API_HOST}/brand-whitelist-group/delete-note`,
    downloadXls: `${API_HOST}/brand-whitelist-group/export-xlsx/:whitelist_group_id:`,
    getAdditionalData: `${API_HOST}/brand-panel-api/whitelist-user/get-additional-data-in-group/:whitelistUserAppId:/:whitelistUserId:`,
    getPublishedPostsOnWl: `${API_HOST}/brand-panel-api/whitelist-user/get-additional-data-brand-entities-in-group/:whitelistUserAppId:/:whitelistUserId:/:page:?whitelistGroupId=:whitelistGroupId:&onPage=5`,
  },

  // ROUTES (MOST OF THESE DO NOT EXIST :/ I JUST COPIED THEM FROM URLS ABOVE):
  getProfileSubusersRoute: "/brand-dashboard/sub-user/get-sub-users",
  postProfileSubuserRoute: "/brand-dashboard/sub-user/register-new-sub-user",
  getProfileCountriesRoute: "/brand-dashboard/countries",
  postProfileDataRoute: "/brand-dashboard/profile/edit",
  getBrandBasicDataRoute: "/brand-dashboard/brand-identity",
  getBrandAuthorizationRoute: "/brand-dashboard/brand-authorization",
  getTranslationCountriesRoute: "/trans/get-trans-available-countries",
  subuserRemoveRoute: "/brand-dashboard/sub-user/delete/:id:",
  subuserToggleActiveRoute: "/brand-dashboard/sub-user/active/:id:",
  subuserToggleNotificationsRoute:
    "/brand-dashboard/sub-user/notifications/:id:",
  subuserToggleFinancialDataRoute:
    "/brand-dashboard/sub-user/change-financial-data-access/:id:",
  subuserTogglePostVisibilityRoute:
    "/brand-dashboard/sub-user/change-post-visbility-managment/:id:",
  getDashboardStatsRoute:
    "/brand-dashboard/campaign-group/statistics/dashboard-stats",
  getCampaignGroupsRoute:
    "/brand-dashboard/campaign-groups/list/:type:?page=:page:",
  getCampaignGroupsCountRoute: "/brand-dashboard/campaign-groups/count/:type:",
  getCampaignGroupRoute:
    "/brand-dashboard/campaign-groups/:group_campaign_id:/get",
  dashboardAggregatedWhitelistCountRoute:
    "/brand-dashboard/whitelist-group/count-awaiting",
  postsCampaignGroupUrlRoute:
    "/brand-dashboard/campaign-group/:campaign_group_id:/posts/:type:",
  statsCampaignGroupUrlRoute:
    "/brand-dashboard/campaign-group/:campaign_group_id:/statistics",
  postsModerationUrlRoute:
    "/brand-dashboard/campaign-group/:campaign_group_id:/moderation/:post_id:",
  downloadAllCampaignsXlsReportUrlRoute:
    "/brand-dashboard/brand-report/download-xls-report",
  downloadCampaignGroupReportXlsUrlRoute:
    "/brand-dashboard/campaign-group-report/download-xls-report?reach=:reach:&:campaign_groups_ids:",
  downloadCampaignGroupReportAggregatedXlsUrlRoute:
    "/brand-dashboard/campaign-group-report/download-combined-xls-report?reach=:reach:&:campaign_groups_ids:",
  getCampaignGroupReportRoute:
    "/brand-dashboard/campaign-group-report/get-campaign-group-report",
  getCampaignGroupReportAggregatedRoute:
    "/brand-dashboard/campaign-group-report/get-campaign-group-combined-report",
  generateCampaignGroupReportPdfRoute:
    "/brand-dashboard/campaign-group-report/generate-pdf-report",
  generateCampaignGroupReportPptxRoute:
    "/brand-dashboard/campaign-group-report/generate-pptx-report",
  getCampaignContentRoute: "/brand-dashboard/campaigns/download-content",
  getCampaignGroupContentRoute:
    "/brand-dashboard/campaign-groups/download-content",
  getCampaignGroupVerificationRoute:
    "/brand-dashboard/campaign-groups/download-verification-content",
  getLastEndedCampaignForFeedbackRoute:
    "/brand-dashboard/campaign-feedback/get-last-ended-campaign",
  postCampaignFeedbackRoute: "/brand-dashboard/campaign-feedback/:id:",
  postCampaignFeedbackCloseRoute: "/brand-dashboard/campaign-feedback/resign",
  postPostVisibilityRoute: "/entity_presubmission/brand/change-post-visibility",
  getFilterCampaignGroupsRoute:
    "/brand-dashboard/campaign-groups/list-filter?type=:type:",
  getFilterCampaignGroupsComingSoonRoute:
    "/brand-dashboard/campaign-groups/list-coming-soon-filter?type=:type:",
  getFilterAgenciesRoute: "",
  getLimitedCampaignGroupsRoute:
    "/brand-dashboard/campaign-groups/list-limited/:limit:",
  getCampaignGroupReachStatsRoute:
    "/brand-dashboard/campaign-group/statistics/:campaign_group_id:/reach-stats",
  getCampaignGroupViewsStatsRoute:
    "/brand-dashboard/campaign-group/statistics/:campaign_group_id:/view-stats",
  getCampaignGroupLikesStatsRoute:
    "/brand-dashboard/campaign-group/statistics/:campaign_group_id:/likes-stats",
  getCampaignGroupCommentsStatsRoute:
    "/brand-dashboard/campaign-group/statistics/:campaign_group_id:/comments-stats",
  getCampaignGroupPostsStatsRoute:
    "/brand-dashboard/campaign-group/statistics/:campaign_group_id:/posts-stats",
  getCampaignGroupBiggestDigitalInfluencersRoute:
    "/brand-dashboard/campaign-group/statistics/:campaign_group_id:/most-biggest-digital-influencers-stats",
  getCampaignGroupMostEngagingInfluencersRoute:
    "/brand-dashboard/campaign-group/statistics/:campaign_group_id:/most-engaging-influencers-stats",
  addSubuserToCampaignGroupRoute:
    "/brand-dashboard/sub-user/add-campaign-group-id-to-campaign-group-acl",
  deleteSubuserFromCampaignGroupRoute:
    "/brand-dashboard/sub-user/delete-campaign-group-id-from-campaign-group-acl",
  getSubuserCampaignGroupAccessRoute:
    "/brand-dashboard/sub-user/get-sub-users-campaign-group-access/:campaignGroupId:",
  getSubuserCampaignGroupWlAccessRoute:
    "/brand-dashboard/sub-user/get-sub-users-coming-soon-campaign-group-access/:campaignGroupId:",
  addSubuserToWlCampaignGroupRoute:
    "/brand-dashboard/sub-user/add-campaign-group-id-to-coming-soon-campaign-group-acl",
  deleteSubuserFromWlCampaignGroupRoute:
    "/brand-dashboard/sub-user/delete-campaign-group-id-from-coming-soon-campaign-group-acl",
  postSubuserAllowedCampaignGroupsRoute:
    "/brand-dashboard/sub-user/set-allowed-campaign-groups/:id:",
  postSubuserAllowedWhitelistsRoute:
    "/brand-dashboard/sub-user/set-allowed-coming-soon-campaign-groups/:id:",
  postSubuserAllowedAgenciesRoute:
    "/brand-dashboard/sub-user/set-allowed-super-brand-admin-users/:id:",
  postUserCampaignGroupsNotificationsRoute:
    "/brand-dashboard/change-campaign-group-notifications",
  postUserWhitelistsNotificationsRoute:
    "/brand-dashboard/change-whitelist-notifications",
  getSuperBrandAdminUsersRoute: "/brand-dashboard/get-super-brand-admin-users",
  getSuperBrandAdminUsersFilterRoute:
    "/brand-dashboard/get-super-brand-admin-users-filter",
  getSuperBrandExternalLoginToBrandRoute:
    "/brand-dashboard/external-login-to-brand",
  getBrandAccountsRoute: "/brand-dashboard/switch-brand-user/:brandUserId:",
  getEntityPresubmissionActivitiesRoute:
    "/brand-dashboard/entity-presubmission-activities/brand/entity",
  getEntityPresubmissionGroupElementActivitiesRoute:
    "/brand-dashboard/entity-presubmission-activities/brand/group-element",
  getWhitelistsRoute: "/brand-dashboard/whitelist-group/get-list/:page:",
  getFilterWhitelistsRoute: "/brand-dashboard/whitelist-group/list-filter",
  getFilterAwaitingWhitelistsRoute:
    "/brand-dashboard/whitelist-group/list-filter?type=:query:",
  loginWhitelistAsBrandRoute: "/authentication/login-whitelist-as-brand/:id:",
  postWhitelistsSwitchStatusRoute:
    "/brand-dashboard/campaign-groups/coming-soon-status",
  getCampaignSettingsRoute:
    "/brand-dashboard/campaign/creator/get-campaign-settings/:campaignId:",
  postCampaignCreatorRecruitmentRoute:
    "/brand-dashboard/campaign/recruitment-campaign/creator/create-new",
  postCampaignCreatorRecruitmentEditRoute:
    "/brand-dashboard/campaign/recruitment-campaign/creator/edit",
  postValidateCampaignCreatorRecruitmentRoute:
    "/brand-dashboard/campaign/recruitment-campaign/creator/check-new-campaign-valid/:socialProvider:",

  postValidateCampaignCreatorRecruitmentEditRoute:
    "/brand-dashboard/campaign/recruitment-campaign/creator/check-edited-campaign-valid",
  postCampaignCreatorRoute:
    "/brand-dashboard/campaign/creator/create-new/:socialProvider:/:billingType:/:whitelistType:/:country:",
  postCampaignCreatorEditRoute:
    "/brand-dashboard/campaign/creator/edit/:campaignId:",
  postValidateCampaignCreatorRoute:
    "/brand-dashboard/campaign/creator/valid-new-campaign/:socialProvider:/:billingType:/:whitelistType:/:country:",
  postValidateCampaignCreatorEditRoute:
    "/brand-dashboard/campaign/creator/valid_edited_campaign/:campaignId:",
  getCampaignCreatorPresignedS3UploadRoute:
    "/brand-dashboard/campaign/recruitment-campaign/creator/get-upload-photo-data",
  getCampaignCreatorDataRoute:
    "/brand-dashboard/campaign/recruitment-campaign/creator/get-creator-data/:locale:",
  getCampaignCreatorRecruitmentEditDataRoute:
    "/brand-dashboard/campaign/recruitment-campaign/creator/get-campaign/:id:",
  getCampaignCreatorEditDataRoute:
    "/brand-dashboard/campaign/creator/get-campaign/:id:",
  getCamppaignCreatorDuplicateDataRoute:
    "/brand-dashboard/campaign/duplicate/get-data-by-campaign/:campaignId:",
  getCampaignWhitelistsRoute:
    "/brand-dashboard/campaign/creator/get-coming-soon-campaigns/:country:",
  // getCampaignWhitelistsEditRoute: "/brand-dashboard/campaign/creator/get-coming-soon-campaigns/:country:/:id:",
  getCampaignCurrencyRoute:
    "/brand-dashboard/campaign/creator/get-currency-by-country/:country:",
  getCampaignTimezoneRoute:
    "/brand-dashboard/campaign/creator/get-timezone-by-country/:country:",

  XHRHeaders: {
    headers: {
      "Access-Control-Allow-Credential": true,
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
    },
  },

  notificationsMessages: {
    success: "front_data_has_been_sent_successfully",
    fail: "front_server_error",
  },

  entityPremoderationStatus: {
    AWAITING_REJECTED: 3,
    ACCEPTED_BY_BRAND: 6,
    REVISED_BY_BRAND: 7,
  },

  campaignGroupStatusCode: {
    RUNNING: 0,
    INACTIVE: 1,
    ENDED: 2,
  },

  moderationStatusCode: {
    ACCEPTED_BY_BRAND: "6",
    REVISED_BY_BRAND: "7",
    REJECTED_BY_BRAND: "8",
  },

  brandWhiteLabelTemplates: {
    DEFAULT: "brand-dashboard",
    GROUPM: "brand-dashboard-groupm",
    DEM: "brand-dashboard-dem",
  },

  reportTypes: {
    serial: "serial",
    aggregated: "aggregated",
  },

  keyCodes: {
    escape: 27,
  },

  keys: {
    escape: "Escape",
  },

  fileFormat: {
    pdf: "pdf",
    odp: "odp",
    pptx: "pptx",
    xlsx: "xlsx",
    xls: "xls",
  },

  socialProvider: {
    instagram: "instagram",
    instagramStories: "instagram_story",
    facebook: "facebook",
    snapchat: "snapchat",
    tiktok: "tik_tok",
    recruitment: "other",
    universal: "universal",
    douyin: "douyin",
    youtube: "youtube",
  },

  recruitmentSocialProviders: ["tik_tok", "other"],

  campaignWizard: {
    wizardUrl: "/brand-dashboard/campaign-creator/campaign",
    typeSelectorUrl: "/brand-dashboard/campaign-creator",
    socialProvider: {
      instagram: "instagram",
      instagramStory: "instagram_story",
      instagramCarousel: "instagram_carousel",
      facebook: "facebook",
      snapchat: "snapchat",
      cpcon: "cpcon",
      universal: "universal",
      tiktok: "tik_tok",
    },
    billingType: {
      cpm: "cpm",
      cpe: "cpe",
      cpv: "cpv",
      cpcon: "cpcon",
      universal: "universal",
    },
    whitelistType: {
      manual: "manual",
      organic: "organic",
      whitelist: "whitelist",
    },
  },

  confirmationModalContent: {
    socialProvider: "socialProvider",
    billingType: "billingType",
    whitelistType: "whitelistType",
  },

  response: {
    response401: 401,
    response403: 403,
  },

  statistics: {
    reach: "reach",
    likes: "likes",
    comments: "comments",
    er: "er",
    impressions: "impressions",
    posts: "posts",
    saves: "saves",
    views: "views",
    views_tiktok: "views_tiktok",
    views_instagram_api: "views_instagram_api",
    views_instagram_no_api: "views_instagram_no_api",
    swipe_ups: "swipe_ups",
    sticker_taps: "sticker_taps",
    shares: "shares",
    api_shares: "api_shares",
    vr: "vr",
    eer: "eer",
    engagements: "engagements",
    followers: "followers",
  },

  uploadFileSizeLimit: 20 * 1048576,
  lowestPossibleAge: 7,
  highestPossibleAge: 81,

  localeFullKey: "locale_full",
  activeLanguageKey: "active_language",

  audienceListNameMaxLength: 300,
  audienceListPageSize: 100,
  audienceSearchResultLimit: 320,
  audienceListUsersLimit: 10000,
};

export default CONSTS;
