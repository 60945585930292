import React, { useState, useEffect } from "react";

import moment from "moment";
import {
  TagsCellType,
  UserProfileCellType,
} from "@glideapps/glide-data-grid-cells";

import {
  BooleanCell,
  ImageCell,
  Rectangle,
  TextCell,
  DrilldownCell,
  GridCell,
  GridCellKind,
  Theme,
  GridColumn,
  measureTextCached,
  CompactSelection,
} from "@glideapps/glide-data-grid";
import { IntlShape } from "react-intl";

import {
  getExtensionFromName,
  isThumbnailsExist,
  splitNumbers,
  splitPercents,
} from "src/utils/methods";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { IMember } from "src/app/project/ProjectTypes";
import { Dispatch } from "redux";
import { setDictionaryElementMetaValue } from "src/redux";
import { showToast } from "src/app/methods/showToast";
import { setGlobalTaskMetaValue } from "src/redux/creator-database/creatorDatabaseActions";
import { AccessLevelEnum } from "src/app/dropdowns/SetMetaFieldAccessLevelDropdown/SetMetaFieldAccessLevelDropdown";
import PdfIcon from "src/images/pdf-attachment.svg";
import XlsIcon from "src/images/xls-attachment.svg";
import DocIcon from "src/images/doc-attachment.svg";
import PptxIcon from "src/images/pptx-attachment.svg";
import ZipIcon from "src/images/zip-attachment.svg";
import TxtIcon from "src/images/txt-attachment.svg";
import MusicIcon from "src/images/music-attachment.svg";
import VideoIcon from "src/images/video-attachment.svg";
import FileGreyIcon from "src/images/files/file-grey.svg";
import { translateMessage } from "src/app/methods/translateMessage";
import {
  AccessLevel,
  Column,
  ColumnSettings,
  CurrencySettings,
  MetaDataFileValue,
  MetaDataValue,
  Row,
  SingleSelectSettings,
} from "src/redux/dictionary/types";
import { DateObject } from "src/app/project/Project";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import { SelectOption } from "../Select/types";
import {
  DropdownSettings,
  GlideDataGridContext,
  GlideDataGridFiltersAndSortingOptions,
  HoveredCellSettings,
  HoveredHeaderSettings,
  StaticCellSettings,
  TooltipSettings,
} from "./types";
import { tableDataType } from "../Table/Table";

const getAttachmentIconSrc = (fileExtension: string) => {
  switch (fileExtension) {
    case "pdf":
      return PdfIcon;
    case "xls":
    case "xlsx":
    case "csv":
      return XlsIcon;
    case "doc":
    case "odc":
      return DocIcon;
    case "docx":
      return DocIcon;
    case "ppt":
    case "pptx":
    case "ods":
      return PptxIcon;
    case "zip":
      return ZipIcon;
    case "txt":
      return TxtIcon;
    case "mp3":
      return MusicIcon;
    case "mp4":
    case "mov":
    case "avi":
      return VideoIcon;
    default:
      return FileGreyIcon;
  }
};

export const formatDate = (date: string) =>
  moment(date).format("DD.MM.YYYY").toString();

export const shortenString = (str: string, maxChars: number) => {
  if (str.length > maxChars) {
    return `${str.slice(0, maxChars)}...`;
  }
  return str;
};

export const HEADER_HEIGHT = 48;
export const ROW_HEIGHT = 86;

export const initialTheme: Partial<Theme> = {
  bgHeader: "#fff",
  textDark: "#74788d",
  textHeader: "#a7abc3",
  fontFamily: "Poppins",
  baseFontStyle: "500 13px",
  headerFontStyle: "600 10px",
  borderColor: "transparent",
  horizontalBorderColor: "#d3d5e1",
  headerBottomBorderColor: "#d3d5e1",
  bgHeaderHasFocus: "transparent",
  bgHeaderHovered: "transparent",
  accentLight: "transparent",
  bgSearchResult: "#EDEDEF",
  cellHorizontalPadding: 20,
  cellVerticalPadding: 20,
};

export const isDateObject = (
  value: MetaDataValue | null,
): value is DateObject => {
  return typeof value === "object" && value !== null && "date" in value;
};

export const isSingleSelectOptionsObject = (
  value: ColumnSettings,
): value is SingleSelectSettings => {
  return (
    typeof value === "object" &&
    value !== null &&
    "singleSelectOptions" in value
  );
};

export const initialHoveredHeaderSettings: HoveredHeaderSettings = {
  value: null,
  style: {},
  bounds: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  uuid: "",
};

export const initialHoveredCellSettings: HoveredCellSettings = {
  value: null,
  type: null,
  style: {},
  cell: [0, 0],
  bounds: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  readOnly: false,
};

export const initialTooltipSettings: TooltipSettings = {
  value: null,
  type: null,
  bounds: { x: 0, y: 0, width: 0, height: 0 },
};

export const initialDropdownSettings: DropdownSettings = {
  value: null,
  type: null,
  options: {},
  cell: [0, 0],
  bounds: { x: 0, y: 0, width: 0, height: 0 },
  readOnly: false,
};

export const MAX_VISIBLE_MULTI_SELECT_OPTIONS = 2;
export const MAX_CHARS_LENGTH = 20;

export const generateSpecificGridCell = (
  metaDataValue: MetaDataValue,
  colDataItem: Column,
  membersList: IMember[],
  dictionaryAutoCompletes: { [key: string]: Row[] },
  wsSelectDataSetOptions: any,
  intl: IntlShape,
): GridCell => {
  const cursor = isAccessLevelReadOnly(colDataItem.accessLevel)
    ? "default"
    : "pointer";

  switch (colDataItem.type) {
    case MetaFieldType.File:
      const metaValueFile = metaDataValue as MetaDataFileValue[];

      if (!metaValueFile.length) {
        return {
          kind: GridCellKind.Text,
          allowOverlay: false,
          displayData: " ",
          data: " ",
        };
      }

      let fileDrillDownCell: DrilldownCell = {
        kind: GridCellKind.Drilldown,
        allowOverlay: false,
        cursor,
        data: [],
      };

      if (metaValueFile.length > 1) {
        fileDrillDownCell = {
          ...fileDrillDownCell,
          data: [
            {
              text: `${metaValueFile.length} ${translateMessage({
                intl,
                id: "ws_files",
                defaultMessage: "files",
              })}`,
              img: FileGreyIcon,
            },
          ],
        };
      } else {
        const file = metaValueFile[0];
        fileDrillDownCell = {
          ...fileDrillDownCell,
          data: [
            {
              text: file.name,
              img: isThumbnailsExist(file, "tiny")
                ? (file.thumbnails.tiny as string)
                : getAttachmentIconSrc(getExtensionFromName(file.name)),
            },
          ],
        };
      }

      return fileDrillDownCell;

    case MetaFieldType.Date:
      let displayDate;
      let date;

      if (isDateObject(metaDataValue)) {
        displayDate = formatDate(metaDataValue.date);
        date = moment(metaDataValue.date);
      } else if (typeof metaDataValue === "string") {
        displayDate = formatDate(metaDataValue);
        date = moment(metaDataValue);
      }

      if (!displayDate || !date) {
        return {
          kind: GridCellKind.Text,
          allowOverlay: false,
          displayData: " ",
          data: " ",
        };
      }

      return {
        kind: GridCellKind.Custom,
        allowOverlay: false,
        copyData: displayDate,
        cursor,
        data: {
          kind: "date-picker-cell",
          date,
          displayDate,
          format: "date",
        },
      };
    case MetaFieldType.Percent:
      const percent =
        typeof metaDataValue === "string"
          ? `${splitPercents(metaDataValue)}%`
          : " ";
      return {
        kind: GridCellKind.Text,
        allowOverlay: false,
        displayData: percent,
        data: percent,
      };

    case MetaFieldType.Number:
      const metaValueNumber = metaDataValue as string;
      return {
        kind: GridCellKind.Text,
        allowOverlay: false,
        displayData: `${splitNumbers(metaValueNumber)}` || " ",
        data: metaValueNumber || " ",
      };
    case MetaFieldType.Currency:
      const currencySettings = colDataItem.data as CurrencySettings;
      const displayData =
        typeof metaDataValue === "string"
          ? `${currencySettings.currencyCode} ${splitNumbers(metaDataValue)}`
          : " ";
      return {
        kind: GridCellKind.Text,
        allowOverlay: false,
        displayData,
        data: metaDataValue || "",
      } as TextCell;
    case MetaFieldType.BoolVal:
      return {
        kind: GridCellKind.Boolean,
        readonly: isAccessLevelReadOnly(colDataItem.accessLevel),
        allowOverlay: false,
        data: metaDataValue as boolean,
      } as BooleanCell;

    case MetaFieldType.Rating:
      return {
        kind: GridCellKind.Image,
        allowOverlay: false,
        rounding: 0,
        data: [`/workspace/images/star${metaDataValue ?? ""}.svg`],
      } as ImageCell;

    case MetaFieldType.Member:
      const foundMember: IMember | undefined = membersList?.find(
        (member: IMember) => member.id === metaDataValue,
      );

      if (!foundMember) {
        return {
          kind: GridCellKind.Text,
          allowOverlay: false,
          displayData: " ",
          data: " ",
        };
      }

      const memberCell: UserProfileCellType = {
        kind: GridCellKind.Custom,
        allowOverlay: false,
        copyData: foundMember.name,
        cursor,
        data: {
          kind: "user-profile-cell",
          image: foundMember.avatarUrl || "",
          initial: foundMember.name[0] || "A",
          tint: "#99A8FD",
          name: shortenString(foundMember.name, 18),
        },
      };

      return memberCell;

    case MetaFieldType.SingleSelect:
      const singleSelectSettings = colDataItem.data as SingleSelectSettings;

      const wsSelectDataSetUuid = colDataItem.data.wsSelectDataSetUuid ?? null;
      const selectOptions = wsSelectDataSetUuid
        ? wsSelectDataSetOptions[`dataSetType_${wsSelectDataSetUuid}`]
        : singleSelectSettings.singleSelectOptions;

      const selectedSingleOption = selectOptions?.find(
        (item: SelectOption) => item.value === metaDataValue,
      );

      if (selectedSingleOption) {
        const singleSelectData = {
          kind: "tags-cell",
          possibleTags: selectedSingleOption
            ? [
                {
                  tag: selectedSingleOption.name,
                  color: `#${selectedSingleOption.backgroundColor}`,
                },
              ]
            : [],
          readonly: false,
          tags: [selectedSingleOption.name],
        };

        return {
          kind: GridCellKind.Custom,
          allowOverlay: false,
          cursor,
          copyData: selectedSingleOption.name,
          data: singleSelectData,
          themeOverride: {
            textDark: `#${selectedSingleOption.color}`,
          },
        } as TagsCellType;
      }
      return {
        kind: GridCellKind.Text,
        allowOverlay: false,
        displayData: " ",
        data: " ",
      };

    case MetaFieldType.MultiSelect:
      const singleMultiSettings = colDataItem.data as SingleSelectSettings;
      const metaValueMultiSelect = metaDataValue as string[];

      const wsSelectMultiDataSetUuid =
        colDataItem.data.wsSelectDataSetUuid ?? null;
      const selectMultiOptions = wsSelectMultiDataSetUuid
        ? wsSelectDataSetOptions[`dataSetType_${wsSelectMultiDataSetUuid}`]
        : singleMultiSettings.singleSelectOptions;

      const allSelectedMultiOptions = selectMultiOptions?.filter(
        (item: SelectOption) => {
          return metaValueMultiSelect?.find(
            (value: string) => value === item.value,
          );
        },
      );

      if (!allSelectedMultiOptions?.length) {
        return {
          kind: GridCellKind.Text,
          allowOverlay: false,
          displayData: " ",
          data: " ",
        };
      }

      let multiOptions: SelectOption[] = [];

      if (allSelectedMultiOptions?.length > MAX_VISIBLE_MULTI_SELECT_OPTIONS) {
        multiOptions = allSelectedMultiOptions.slice(
          0,
          MAX_VISIBLE_MULTI_SELECT_OPTIONS,
        );
        multiOptions = [
          ...multiOptions,
          {
            name: `+${allSelectedMultiOptions.length - 2}`,
            backgroundColor: "fff",
          } as SelectOption,
        ];
      } else {
        multiOptions = allSelectedMultiOptions;
      }

      const multiSelectData = {
        kind: "tags-cell",
        possibleTags: multiOptions.map((option) => {
          return {
            tag: option.name,
            color: `#${option.backgroundColor}`,
          };
        }),
        readonly: false,
        tags: multiOptions.map((option) => option.name),
      };

      return {
        kind: GridCellKind.Custom,
        allowOverlay: false,
        cursor,
        copyData: multiOptions.map((option) => option.name).join(", "),
        data: multiSelectData,
      } as TagsCellType;

    case MetaFieldType.DictionaryElement:
      const autoCompletesList = dictionaryAutoCompletes[
        `dictionaryType_${colDataItem?.data.wsDictionaryUuid}`
      ] as Row[];
      const dictionaryElement = autoCompletesList?.find(
        (item) => item.uuid === metaDataValue,
      );

      return {
        kind: GridCellKind.Text,
        allowOverlay: false,
        displayData: dictionaryElement?.title || " ",
        data: dictionaryElement?.title || " ",
        cursor,
      };

    case MetaFieldType.Text:
      const metaValueText = metaDataValue as string;
      return {
        kind: GridCellKind.Text,
        allowOverlay: false,
        displayData: metaValueText || " ",
        data: metaValueText || " ",
        themeOverride: {
          baseFontStyle: "500 12px",
          cellVerticalPadding: 0,
        },
      } as TextCell;

    default:
      const metaValueDefault = metaDataValue as string;
      return {
        kind: GridCellKind.Text,
        allowOverlay: false,
        displayData: metaValueDefault || " ",
        data: metaValueDefault || " ",
      } as TextCell;
  }
};

export const generateColumns = (
  columnsData: Column[],
  totalRowsCount: number,
  displayedRowsCount: number,
  filtersAndSortingOptions: GlideDataGridFiltersAndSortingOptions | undefined,
) => {
  const mappedCols = columnsData.map((col, index) => {
    let width;

    switch (col.type) {
      case MetaFieldType.BoolVal:
        width = 88;
        break;
      case MetaFieldType.Date:
        width = 120;
        break;
      case MetaFieldType.Currency:
      case MetaFieldType.Number:
      case MetaFieldType.Percent:
      case MetaFieldType.SingleSelect:
      case MetaFieldType.Rating:
        width = 150;
        break;
      default:
        width = 232;
        break;
    }

    let gridCol: GridColumn = {
      id: col.uuid,
      width,
      grow: (columnsData.length + index) / columnsData.length,
      title: col.name.toUpperCase(),
    };

    if (
      filtersAndSortingOptions &&
      col.uuid === filtersAndSortingOptions.sortBy
    ) {
      gridCol = {
        ...gridCol,
        icon: filtersAndSortingOptions.sort,
      };
    }

    return gridCol;
  });

  const actionCol: GridColumn = {
    width: 88,
    title: "ACTION",
    id: "action",
    grow: 0,
  };

  const titleCol: GridColumn = {
    width: 232,
    title: `DISPLAYING: ${splitNumbers(displayedRowsCount)} OF ${splitNumbers(totalRowsCount)}`,
    id: "title",
    grow: 0,
  };

  const emptyCol: GridColumn = {
    width: 40,
    title: "",
    id: "empty",
    grow: 999,
  };

  const columns = [titleCol, actionCol, ...mappedCols, emptyCol];

  return columns;
};

export const headerIconsObj = {
  ASC: () =>
    '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" class="sortingTasksArrow sortingTasksArrow--sortingAscending"><rect x="7.83337" y="3.33334" width="1.33333" height="9.33333" rx="0.666667" fill="#74788D"></rect><path d="M4.97145 8.47141C4.7111 8.73176 4.28899 8.73176 4.02864 8.47141C3.76829 8.21106 3.76829 7.78895 4.02864 7.5286L8.02864 3.5286C8.28102 3.27621 8.68741 3.26738 8.95052 3.50857L12.9505 7.17523C13.2219 7.42403 13.2403 7.84574 12.9915 8.11715C12.7427 8.38857 12.321 8.4069 12.0496 8.15811L8.52009 4.92276L4.97145 8.47141Z" fill="#74788D"></path></svg>',
  DESC: () =>
    '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" class="sortingTasksArrow sortingTasksArrow--sortingDescending" style="transform:rotate(180deg)"><rect x="7.83337" y="3.33334" width="1.33333" height="9.33333" rx="0.666667" fill="#74788D"></rect><path d="M4.97145 8.47141C4.7111 8.73176 4.28899 8.73176 4.02864 8.47141C3.76829 8.21106 3.76829 7.78895 4.02864 7.5286L8.02864 3.5286C8.28102 3.27621 8.68741 3.26738 8.95052 3.50857L12.9505 7.17523C13.2219 7.42403 13.2403 7.84574 12.9915 8.11715C12.7427 8.38857 12.321 8.4069 12.0496 8.15811L8.52009 4.92276L4.97145 8.47141Z" fill="#74788D"></path></svg>',
};

export const dispatchUpdatedMetaDataValue = (
  rowDataItems: Row[],
  columnItemId: string,
  newValue: any,
  context: GlideDataGridContext,
  dispatch: Dispatch,
) => {
  const rowUuids = rowDataItems.map((rowDataItem) => rowDataItem.uuid);

  switch (context) {
    case tableDataType.Dictionary:
      dispatch(setDictionaryElementMetaValue(rowUuids, columnItemId, newValue));
      break;

    case tableDataType.CreatorDatabase:
      dispatch(setGlobalTaskMetaValue(rowUuids, columnItemId, newValue));
      break;
    default:
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_something_went_wrong"
          defaultMessage="Something went wrong"
        />,
      );
      break;
  }
};

export const willElementRenderInsideHoverArea = (
  areaRef: Element | null,
  bounds: Rectangle,
) => {
  if (areaRef) {
    const areaRect = areaRef.getBoundingClientRect();

    // Calculate the coordinates and dimensions of the hoverable area
    const areaLeft = areaRect.left;
    const areaRight = areaRect.right;
    const areaTop = areaRect.top + HEADER_HEIGHT; // add the header height
    const areaBottom = areaRect.bottom;

    // Calculate the coordinates and dimensions of the hovered element
    const elementLeft = bounds.x;
    const elementRight = bounds.x + bounds.width;
    const elementTop = bounds.y;
    const elementBottom = bounds.y + bounds.height;

    // Check if the element is entirely inside the hoverable area
    if (
      elementLeft >= areaLeft &&
      elementRight <= areaRight &&
      elementTop >= areaTop &&
      elementBottom <= areaBottom
    ) {
      return true;
    }
  }

  return false;
};

export const areArraysTheSame = (array1: number[], array2: number[]) => {
  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();

  return JSON.stringify(sortedArray1) === JSON.stringify(sortedArray2);
};

export const useCanOverrideSearch = () => {
  const [canOverride, setCanOverride] = useState(false);

  useEffect(() => {
    const checkPresence = () => {
      const pane = document.querySelector(".pane");
      const modal = document.querySelector(".modal");
      setCanOverride(!pane && !modal);
    };

    checkPresence();

    const observer = new MutationObserver(checkPresence);

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  return canOverride;
};

export const isAccessLevelReadOnly = (
  accessLevel: AccessLevel | null | undefined,
): boolean => {
  if (!Array.isArray(accessLevel)) return false;
  return accessLevel[0] === AccessLevelEnum.Viewer;
};

export const getEmHeight = (
  ctx: CanvasRenderingContext2D,
  fontStyle: string,
): number => {
  const textMetrics = measureTextCached("ABCi09jgqpy", ctx, fontStyle);
  return (
    textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent
  );
};

export const initialStaticCellSettings: StaticCellSettings = {
  numberOfFiles: 0,
  style: {},
};

export const getCellPosition = (bounds: Rectangle) => ({
  width: `${bounds.width}px`,
  height: `${bounds.height - 2}px`,
  top: `${bounds.y + 1}px`,
  left: `${bounds.x}px`,
});

export const rowMarkersSettings = {
  kind: "both" as const,
  theme: {
    cellHorizontalPadding: 0,
    cellVerticalPadding: 0,
  },
};

export const addNewSelectedRows = (newRowsIndexesArray: number[]) => {
  let updatedSelectedRows = CompactSelection.empty();
  for (let index = 0; index < newRowsIndexesArray.length; index++) {
    updatedSelectedRows = updatedSelectedRows.add(newRowsIndexesArray[index]);
  }

  return updatedSelectedRows;
};
