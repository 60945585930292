import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";

export const sortTasksByColumn = (
  a,
  b,
  activeSortingColumn,
  sortingDirection,
  creatorsList,
  membersList,
  contentList,
) => {
  if (!activeSortingColumn) {
    return b.rank?.localeCompare(a.rank);
  }

  if (
    activeSortingColumn === "creator" ||
    activeSortingColumn === "publication"
  ) {
    if (sortingDirection === "sortingDescending") {
      return b.title?.localeCompare(a.title);
    }
    return a.title?.localeCompare(b.title);
  }

  const metaDataA = a.metadata.find(
    (item) => item.uuid === activeSortingColumn,
  );
  const metaDataB = b.metadata.find(
    (item) => item.uuid === activeSortingColumn,
  );

  // Comparing metafield values
  const type = metaDataA?.type;
  let valueA = metaDataA?.value;
  let valueB = metaDataB?.value;

  if (!valueA && !valueB) {
    return b.rank.localeCompare(a.rank);
  }
  if (!valueA) {
    return 1;
  }
  if (!valueB) {
    return -1;
  }
  if (valueA === valueB) {
    if (sortingDirection === "sortingDescending") {
      return b.rank.localeCompare(a.rank);
    }
    return a.rank.localeCompare(b.rank);
  }

  switch (type) {
    case MetaFieldType.Date:
      if (metaDataA?.value?.date) {
        valueA = new Date(metaDataA?.value?.date).getTime();
      }
      if (metaDataB?.value?.date) {
        valueB = new Date(metaDataB?.value?.date).getTime();
      }
      break;

    case MetaFieldType.Number:
    case MetaFieldType.Currency:
      if (metaDataA?.value) {
        valueA = Number(metaDataA?.value);
      }
      if (metaDataB?.value) {
        valueB = Number(metaDataB?.value);
      }

      break;

    case MetaFieldType.Creator:
      const creatorA = creatorsList.find(
        (item) => item.taskId === metaDataA?.value,
      );
      const creatorB = creatorsList.find(
        (item) => item.taskId === metaDataB?.value,
      );
      valueA = creatorA?.title;
      valueB = creatorB?.title;

      break;

    case MetaFieldType.Content:
      const contentA = contentList.find(
        (item) => item.taskId === metaDataA?.value,
      );
      const contentB = contentList.find(
        (item) => item.taskId === metaDataB?.value,
      );
      valueA = contentA?.title;
      valueB = contentB?.title;

      break;

    case MetaFieldType.SingleSelect: {
      {
        const { singleSelectOptions: options } = metaDataA.data;
        const optionA = options.find((item) => item.value === metaDataA?.value);
        const optionB = options.find((item) => item.value === metaDataB?.value);
        valueA = optionA?.name;
        valueB = optionB?.name;
      }

      break;
    }

    case MetaFieldType.MultiSelect:
    case MetaFieldType.AggregatedSelect: {
      const { singleSelectOptions: options } = metaDataA.data;
      const optionA = options.find(
        (item) => item.value[0] === metaDataA?.value[0],
      );
      const optionB = options.find(
        (item) => item.value[0] === metaDataB?.value[0],
      );
      valueA = optionA?.name;
      valueB = optionB?.name;

      break;
    }

    case MetaFieldType.Member:
      const memberA = membersList?.find((item) => item.id === metaDataB?.value);
      const memberB = membersList?.find((item) => item.id === metaDataA?.value);
      valueA = memberA?.name;
      valueB = memberB?.name;

      break;
  }

  if (typeof valueA === "string" || typeof valueB === "string") {
    return compareStrings(valueA, valueB, sortingDirection);
  }

  if (sortingDirection === "sortingDescending") {
    return valueB - valueA;
  }
  return valueA - valueB;
};

const compareStrings = (valueA, valueB, sortingDirection) => {
  if (sortingDirection === "sortingDescending") {
    return valueB.localeCompare(valueA);
  }
  return valueA.localeCompare(valueB);
};
