import { capitalizeFirstLetter, stripEmojis } from "../../../../utils/methods";
import { addHeaderToArray } from "../addHeaderToArray";

export const REPORT_ADDITIONAL_PUBLICATION_METAFIELDS = [
  "currency",
  "number",
  "percent",
];

export const addTopPerformingPublicationsToGridElements = (
  publicationList,
  formatMessage,
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
) => {
  const publicationTopMetadata = publicationList[0]?.metadata.filter((item) =>
    REPORT_ADDITIONAL_PUBLICATION_METAFIELDS.includes(item.type),
  );

  // const settings = JSON.parse(localStorage.getItem("ws-project-settings"));
  // const projectId = window.location.pathname.split("/")[4];
  // const projectSettings = settings?.find(
  //   (item) => item.projectId === projectId
  // );
  // const { activeSortingColumn, sortingDirection } =
  //   projectSettings?.publication || {};

  publicationTopMetadata?.forEach((item) => {
    addHeaderToArray(
      `${formatMessage({
        id: "ws_top_performing_publications",
        defaultMessage: "Top Performing Publications",
      })}: ${capitalizeFirstLetter(item.name)}`,
      newHiddenGridElements,
      "subheader",
      `top-publication-${item.uuid}`,
      savedTemplate,
      templateApplied,
    );

    publicationList
      .sort((a, b) => {
        const valueA = a?.metadata.find(
          (pubA) => pubA.uuid === item.uuid,
        )?.value;
        const valueB = b?.metadata.find(
          (pubB) => pubB.uuid === item.uuid,
        )?.value;
        return valueB - valueA;
      })
      .slice(0, 6)
      .filter(
        (publication) =>
          publication.metadata.find((field) => field.uuid === item.uuid)?.value,
      )
      .forEach((publication) => {
        const label = `top-publication-${item.uuid}-${stripEmojis(
          publication.title,
        )
          .slice(0, 15)
          .replace(/(\r\n|\n|\r)/gm, "")
          .replaceAll(" ", "-")
          .replaceAll(".", "")
          .replaceAll("/", "")
          .replaceAll("?", "")
          .toLowerCase()}-${publication.taskId}`;

        const element = {
          ...publication,
          label,
          gridBoxType: `top-publication`,
          section: `top-publication-${item.uuid}`,
        };

        if (savedTemplate?.length && !templateApplied) {
          if (savedTemplate.find((item) => item.i === element.label)) {
            newGridElements.push(element);
          } else {
            newHiddenGridElements.push(element);
          }
        }
        // applying default template
        else {
          newHiddenGridElements.push(element);
        }
      });
  });
};
