import React, { useState } from "react";

import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ProjectPermissionsEnum } from "src/utils/PermissionsEnums";
import { wsAxiosPost, wsAxiosPut } from "src/helpers/wsAxios";
import { API_URLS } from "src/utils/API_URLS";
import { useDispatch } from "react-redux";
import { WsExtension } from "src/types";
import { getExtensionList } from "src/redux";
import ExtensionsListItemContent from "./ExtensionsListItemContent";

interface ExtensionListLockWsProjectEditingProps {
  extensionData: WsExtension | undefined;
  permissions: { project: string[]; workspace: string[] };
  wsProjectUuid: string | null;
}

const changeWsExtensionStatus = async (
  wsExtensionUuid: string,
  enabled: boolean,
) => {
  await wsAxiosPut(
    API_URLS.updateRecruitmentFormStatus.replace(
      ":wsExtensionUuid:",
      wsExtensionUuid,
    ),
    {
      enabled,
    },
    `Cannot change status`,
  );
};

const createLockWsProjectExtension = async (wsProjectUuid: string | null) => {
  if (!wsProjectUuid) {
    console.error(
      "Missing wsProjectUuid to create extension, got: ",
      wsProjectUuid,
    );

    return;
  }

  await wsAxiosPost(
    API_URLS.createNewLockWsProjectEditingExtension,
    {
      wsProjectUuid,
    },
    `Cannot creating extension`,
  );
};

const existsExtension = (extensionData: WsExtension | undefined): boolean => {
  return !!extensionData;
};

export default function ExtensionListLockWsProjectEditing({
  extensionData,
  permissions,
  wsProjectUuid,
}: ExtensionListLockWsProjectEditingProps) {
  const [toggled, setToggled] = useState(
    existsExtension(extensionData) ? extensionData?.enabled : false,
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  return (
    <ExtensionsListItemContent
      name={
        <IDHFormattedMessage
          id="ws_lock_campaign"
          defaultMessage="Lock campaign editing"
        />
      }
      description={
        <IDHFormattedMessage
          id="ws_lock_campaign_description"
          defaultMessage="Extension for locking campaign editing functionality."
        />
      }
      controls={
        <CustomSwitch
          checked={toggled}
          onChange={async (e) => {
            setToggled(e.target.checked);
            setLoading(true);

            if (existsExtension(extensionData) && extensionData?.uuid) {
              setTimeout(() => {
                changeWsExtensionStatus(extensionData?.uuid, !toggled);
                setLoading(false);
              }, 200);

              return;
            }

            await createLockWsProjectExtension(wsProjectUuid);
            setLoading(false);
            if (wsProjectUuid) {
              dispatch(getExtensionList(wsProjectUuid));
            }
          }}
          disabled={
            !permissions?.project?.includes(
              ProjectPermissionsEnum.MANAGE_PROJECT_CONFIGURATION,
            ) || loading
          }
        />
      }
    />
  );
}
