import React from "react";
import "./InfluencerPhotosCell.scss";
import { Link } from "react-router-dom";

export function InfluencerPhotosCell({ headerLink, listItem }) {
  return (
    <Link
      to={headerLink}
      className="influencers-list__cell influencers-list__cell--photos"
    >
      {listItem?.userAvatars?.length > 0 ? (
        <>
          {listItem?.userAvatars?.slice(0, 3).map((photo, index) => (
            <div
              key={index}
              className="influencers-list__cell--photos__item"
              style={{
                backgroundImage: `url(${photo})`,
              }}
            />
          ))}
        </>
      ) : (
        <div className="influencers-list__cell--photos__empty" />
      )}
    </Link>
  );
}
