export const applySystemTemplate = (
  newLayout,
  format,
  systemTemplate,
  gridElements
) => {
  if (!systemTemplate?.length) return;

  const lastRow = Math.max(...newLayout.map((o) => o.y));
  const lastEl = newLayout.find((item) => item.y === lastRow);
  const currentRow = lastEl.y + lastEl.h;
  const rowDifference = currentRow % format.rows;
  const newRow = rowDifference
    ? currentRow + format.rows - rowDifference
    : currentRow;

  systemTemplate.forEach((item) => {
    if (gridElements.find((i) => i.label === item.i)) {
      newLayout.push({
        ...item,
        y: newRow + item.y,
      });
    }
  });
};
