import React from "react";

import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { TooltipedEllipsis } from "src/app/components/TooltipedEllipsis/TooltipedEllipsis";
import { ReactComponent as SortIcon } from "src/images/up-down.svg";

export default function SortFieldsButton({ active, activeSortingColumnName }) {
  return (
    <Button variant="white" size="small" textWithIcon active={active}>
      <SortIcon />
      <IDHFormattedMessage id="ws_sort" defaultMessage="Sort" />
      {activeSortingColumnName ? (
        <>
          :{" "}
          <TooltipedEllipsis
            className="sort-fields-dropdown__active-filter-ellipsis"
            maxWidth={100}
            content={activeSortingColumnName}
          />
        </>
      ) : null}
    </Button>
  );
}
