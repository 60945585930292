import { getWorkspaceUuidFromCurrentUrl } from "src/utils/methods";
import { valueExists } from "src/app/methods/valueExists";
import {
  SET_PROJECT_COLUMN_RANK,
  SET_PROJECT_FILTER,
  SET_PROJECT_FILTERS,
  SET_PROJECT_INITIAL_COLUMN_RANKS,
  SET_PROJECT_INITIAL_HIDDEN_COLUMNS,
  SET_PROJECT_SORT,
  SET_PROJECT_SORT_BY,
  TOGGLE_PROJECTS_LIST_COLUMN_VISIBILITY,
} from "./projectFiltersTypes";

const wsWorkspaceUuid = getWorkspaceUuidFromCurrentUrl();

const initialState =
  localStorage.getItem("ws-projects-list-settings") &&
  JSON.parse(localStorage.getItem("ws-projects-list-settings"))[wsWorkspaceUuid]
    ? JSON.parse(localStorage.getItem("ws-projects-list-settings"))[
        wsWorkspaceUuid
      ]
    : {
        sortBy: null,
        sort: "DESC",
        filters: [],
        hiddenColumns: [],
        projectColumnRanks: [],
      };

const save = (obj) => {
  localStorage.setItem(
    "ws-projects-list-settings",
    JSON.stringify({
      ...JSON.parse(localStorage.getItem("ws-projects-list-settings")),
      [wsWorkspaceUuid]: obj,
    }),
  );
  return sanitizeFilters(obj);
};

const sanitizeFilters = (obj) => {
  // stupid but it works, gets rid of keys for undefined values
  return JSON.parse(JSON.stringify(obj));
};

const removeHiddenSettings = (state, columnId) => {
  return {
    ...state,
    sortBy: state.sortBy === columnId ? undefined : state.sortBy,
    filters: { ...state.filters, [columnId]: undefined },
  };
};

function projectFiltersReducer(state = initialState, action) {
  if (
    action?.payload?.data?.status === "fail" ||
    action?.error?.response?.status >= 500
  ) {
    return {
      ...state,
      errorMessages: [{ message: "An unexpected error occurred." }],
    };
  }
  switch (action.type) {
    case SET_PROJECT_SORT_BY:
      return save({
        ...state,
        sortBy: action.payload.sortBy,
      });

    case SET_PROJECT_SORT:
      return save({
        ...state,
        sort: action.payload.sort,
      });

    case SET_PROJECT_FILTERS:
      return save({
        ...state,
        filters: action.payload.filters,
      });

    case SET_PROJECT_FILTER:
      return save({
        ...state,
        filters: {
          ...state.filters,
          [action.payload.fieldUuid]: !valueExists(action.payload.value)
            ? undefined
            : { value: action.payload.value },
        },
      });

    case SET_PROJECT_INITIAL_HIDDEN_COLUMNS:
      return save({
        ...state,
        hiddenColumns: action.payload.hiddenColumns,
      });

    case TOGGLE_PROJECTS_LIST_COLUMN_VISIBILITY:
      return save({
        // ...state,
        ...removeHiddenSettings(state, action.payload.uuid),
        hiddenColumns: state.hiddenColumns.includes(action.payload.uuid)
          ? state.hiddenColumns.filter((hc) => hc !== action.payload.uuid)
          : [...state.hiddenColumns, action.payload.uuid],
      });

    case SET_PROJECT_INITIAL_COLUMN_RANKS:
      if (action.payload.saveConfig) {
        save({
          ...state,
          projectColumnRanks: action.payload.ranks,
        });
      }

      return {
        ...state,
        projectColumnRanks: action.payload.ranks,
      };

    case SET_PROJECT_COLUMN_RANK: {
      const { uuid, rank } = action.payload;

      let foundRank = false;

      const newRanks = state.projectColumnRanks.map((col) => {
        if (col.uuid === uuid) {
          foundRank = true;
          return {
            ...col,
            rank,
          };
        }

        return col;
      });

      if (!foundRank) newRanks.push({ uuid, rank });

      return save({
        ...state,
        projectColumnRanks: newRanks,
      });
    }

    default:
      return state;
  }
}

export default projectFiltersReducer;
