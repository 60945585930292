import React, { useContext, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { ReactComponent as TrashcanIcon } from "src/images/trash-can.svg";
import { ReactComponent as CopyLinkIcon } from "src/images/copy-link2.svg";
import { ReactComponent as CopiedLinkIcon } from "src/images/copied-link.svg";

import { TooltipedEllipsis } from "src/app/components/TooltipedEllipsis/TooltipedEllipsis";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MembersContext } from "src/app/SettingsModal/tabs/Members/Members";
import { SettingsContext } from "src/app/SettingsModal/enums";
import CustomImage from "src/app/components/CustomImage/CustomImage";
import LazyLoad from "react-lazy-load";
import { Img } from "src/app/components/Img/Img";
import { copyLink } from "../../../utils/methods";
import AvatarPlaceholder from "../../../images/avatar-purple.svg";
import { SetAsRoleDropdown } from "../../dropdowns/SetAsRoleDropdown/SetAsRoleDropdown";

function UserListItem({ user, getData }) {
  const {
    permissions,
    workspaceId,
    projectId,
    taskId,
    openShowRemoveUserModal,
    setRemoveUserData,
  } = useContext(MembersContext);

  const beingOnboarded = user.errors.includes("onboarding");

  const [copied, setCopied] = useState(false);

  const {
    mainReducer: { identity, settingsModalData },
  } = useSelector((state) => state);

  const { context } = settingsModalData;

  const copyActivationLink = (link) => {
    copyLink(link);
    setCopied(true);

    setTimeout(() => setCopied(false), 5000);
  };

  return (
    <div className="members__user-list-item" key={user.id} data-qa={user.email}>
      <div className="members__user-list-item-left">
        <LazyLoad className="members__user-list-item-avatar-wrapper">
          <Img
            className="members__user-list-item-avatar"
            src={user.avatarUrl}
            fallbackImageSrc={AvatarPlaceholder}
            alt="avatar"
          />
        </LazyLoad>
        <div className="members__user-list-item-info">
          <div className="members__user-list-item-name">
            <TooltipedEllipsis content={user.name} maxWidth={290} />
          </div>
          {!beingOnboarded && (
            <span className="members__user-list-item-email">{user.email}</span>
          )}
        </div>
      </div>
      <div className="members__user-list-item-right">
        {user.isClient &&
          permissions?.project?.includes("display_member_magic_link") &&
          beingOnboarded &&
          user.magicLinkLoginUrl && (
            <TooltipPortal
              content={
                copied ? (
                  <IDHFormattedMessage
                    id="ws_copied"
                    defaultMessage="Copied!"
                  />
                ) : (
                  <IDHFormattedMessage
                    id="ws_copy_activation_link"
                    defaultMessage="Copy activation link"
                  />
                )
              }
            >
              <div
                className={classNames("members__user-list-item__copy", {
                  "members__user-list-item__copy--copied": copied,
                })}
                onClick={() => copyActivationLink(user.magicLinkLoginUrl)}
              >
                {copied ? (
                  <CopiedLinkIcon className="members__user-list-item__copy-icon" />
                ) : (
                  <CopyLinkIcon className="members__user-list-item__copy-icon" />
                )}
              </div>
            </TooltipPortal>
          )}
        {context === SettingsContext.Task ? null : (
          <SetAsRoleDropdown
            wsMemberUuid={user.id}
            memberRole={user.memberRole}
            projectId={projectId}
            workspaceId={workspaceId}
            objectType={context}
            disabled={
              (context === SettingsContext.Workspace &&
                !permissions?.workspace?.includes("manage_members_roles")) ||
              (context !== SettingsContext.Workspace &&
                !permissions?.project?.includes("set_member_as_admin") &&
                !permissions?.project?.includes("set_member_as_operator"))
            }
            getData={getData}
          />
        )}
        {(permissions?.project?.includes("remove_project_members") ||
          permissions?.workspace?.includes("manage_members")) && (
          <>
            {identity.id !== user.id ? (
              <TrashcanIcon
                onClick={() => {
                  openShowRemoveUserModal();
                  setRemoveUserData({
                    uuid: user.id,
                    userName: user.name,
                    projectId,
                    taskId,
                  });
                }}
                className="members__user-list-item-trashcan"
                data-qa={user.email}
              />
            ) : (
              <TrashcanIcon className="members__user-list-item-trashcan members__user-list-item-trashcan--disabled" />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export { UserListItem };
