import classNames from "classnames";
import React from "react";

export function InterestsListItem({ icon, item, fullWidthItem }) {
  return (
    <div
      className={classNames("interests-list__item", {
        "interests-list__item--full-width": fullWidthItem,
      })}
    >
      <div className="interests-list__item-icon">{icon}</div>
      {item.name}
    </div>
  );
}
