import CONSTS from "../../helpers/consts";
import { postColumns } from "../../helpers/postColumnsConst";

const postsUtils = {
  makePostProps: (post, campaignSettings) => ({
    ...post,
    key: post.id,
    appId: post.appId,
    image: post.mediaUrl,
    imageScaled: post.mediaScaledUrl,
    carousel: post.isEntityGroup,
    descriptionLanguage: post.socialDescriptionLanguage,
    cpcon: campaignSettings ? campaignSettings.is_cpcon : false,
    cpconExternalContent: campaignSettings
      ? campaignSettings.is_universal_campaign
      : false,
    cpconExternalContentUrl: post.externalContentUrl,
    avgRealReachCount: post.userAvgRealReach,
    gatheringData:
      post.socialProvider === "instagram_story" && post.isGatheringData,
  }),
  getColumnList: (campaignParsed, type) => {
    const { tiktok, instagram } = CONSTS.socialProvider;

    if (campaignParsed) {
      if (campaignParsed.isUniversalCampaign) {
        return postColumns.universal[type];
      }
      if (campaignParsed.cpcon) {
        return postColumns.cpcon[type];
      }
      if (campaignParsed.provider === tiktok) {
        return postColumns[campaignParsed.provider][type];
        // } else if (campaignParsed.provider === instagram) {
        //   postColumnsList =
        //     postColumns[campaignParsed.provider][
        //       // campaignParsed.isEntityGroup ? "multiple" : "single"
        //       "multiple"
        //     ][type];
      }
      return postColumns[campaignParsed.provider][type][
        campaignParsed.showApiData ? "apiData" : "noApiData"
      ];
    }
  },
  hasVideo: (post) => {
    if (post.mediaType === "video") {
      return true;
    }
    if (post.groupElementsMediaUrls?.length > 0) {
      const elementsWithVideo = post.groupElementsMediaUrls.filter(
        (item) => item.type === "video",
      );
      return elementsWithVideo.length > 0;
    }
    return false;
  },
  createPreviewArray: (post) => {
    const { mediaType, videoUrl, mediaUrl } = post;
    return [
      {
        mediaUrl: mediaType === "video" ? videoUrl : mediaUrl,
        type: mediaType,
      },
    ];
  },
};

export default postsUtils;
