import React from "react";
import "./SuccessBlock.scss";

import { ReactComponent as TickIcon } from "src/images/tick-grey.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { AccessLinkPageBlock } from "../AccessLinkPageBlock/AccessLinkPageBlock";

export function SuccessBlock() {
  return (
    <AccessLinkPageBlock>
      <div className="access-link-page__block--success">
        <TickIcon
          className="access-link-page__block--success__icon"
          width={50}
          height={50}
        />
        <div className="access-link-page__block--success__title">
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />
        </div>
        <div className="access-link-page__block--success__subtitle">
          <IDHFormattedMessage
            id="ws_your_response_has_been_submitted"
            defaultMessage="Your response has been submitted."
          />
        </div>
      </div>
    </AccessLinkPageBlock>
  );
}
