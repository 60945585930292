import React from "react";
import classNames from "classnames";

import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import { ReactComponent as InfoIcon } from "src/images/info-dark.svg";
import { ReactComponent as TickIcon } from "src/images/tick-blue.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Link } from "react-router-dom";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import placeholderIcon from "../../../../images/placeholder-image.svg";
import SocialProfileIcons from "../../../components/IconsWithStatus/SocialProfileIcons/SocialProfileIcons";

export const renderSuggestion = (suggestion) => {
  return (
    <span
      className={classNames("suggestion", {
        "suggestion--disabled": suggestion.hasCreatorDatabaseProfile,
      })}
    >
      <div className="suggestion-data">
        <div
          className="suggestion-placeholder"
          style={{ backgroundImage: `url(${placeholderIcon})` }}
        >
          <div
            style={{ backgroundImage: `url(${suggestion.avatar})` }}
            className="suggestion-img"
          />
        </div>
        <div className="suggestion-details">
          <div className="suggestion-details__username">
            {suggestion.username}
            {suggestion.hasCreatorDatabaseProfile && (
              <TickIcon className="suggestion-details__username-tick" />
            )}
          </div>
          <div className="suggestion-details__socialIcons">
            <SocialProfileIcons data={suggestion.socialProfiles} size="small" />
          </div>
        </div>
      </div>

      {suggestion.hasCreatorDatabaseProfile && (
        <TooltipPortal
          id="suggestion-tooltip"
          contentClassName="suggestion-tooltip-content"
          content={renderSuggestionTooltipContent(suggestion)}
          autoSize
        >
          <InfoIcon />
        </TooltipPortal>
      )}
    </span>
  );
};

export const renderFakeSuggestion = (open, value, create) => {
  return (
    <div
      className={classNames("fake-suggestion", open && "fake-suggestion--open")}
      onClick={create}
    >
      <div className="suggestion-details">
        <span className="fake-suggestion__title">Create</span>
        <span className="fake-suggestion__value">{value}</span>
      </div>
    </div>
  );
};

const renderSuggestionTooltipContent = (data) => {
  const getCreatorLink = () => {
    const url = new URL(
      `${window.location.href.split("/projects")[0]}/creator-database`,
    );
    url.searchParams.set("displayGlobalTask", data.wsGlobalTaskUuid);
    return url.pathname + url.search;
  };

  return (
    <span className="suggestion-tooltip-content">
      <span className="suggestion-tooltip-content__header">
        <IDHFormattedMessage
          id="ws_the_creator_appears_in"
          defaultMessage="The creator appears in"
        />
        :
      </span>
      <ul>
        <li>
          <IDHFormattedMessage
            id="ws_creator_database"
            defaultMessage="Creator Database"
          />
        </li>
      </ul>

      <Link to={getCreatorLink()} target="_blank">
        <IDHFormattedMessage
          id="ws_show_details"
          defaultMessage="Show details"
        />
      </Link>
    </span>
  );
};
