import React, { useState } from "react";

import axios from "axios";
import { API_URLS } from "src/utils/API_URLS";

import regenerateIcon from "src/images/regenerate-link.svg";

import { DropdownMenuItem } from "src/app/components/Dropdown/Dropdown";
import { DropdownPortalMenu } from "src/app/components/DropdownPortal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { tableDataType } from "src/app/components/Table/Table";

export function AccessLinkMetaFieldDropdownMenu({
  uuid,
  objectId,
  newAccessLinkValues,
  setNewAccessLinkValues,
  toggle,
  dataType,
}) {
  const [regenerated, setRegenerated] = useState(false);

  const regenerateAccessLink = () => {
    const regenerateURL =
      dataType === tableDataType.Task
        ? API_URLS.updateTaskMetaFieldAccessLinkValue
        : API_URLS.updateProjectAccessLinkMetaValue;

    const params =
      dataType === tableDataType.Task
        ? {
            taskId: objectId,
            metaFieldId: uuid,
          }
        : {
            projectUuid: objectId,
            metaFieldUuid: uuid,
            isEnabled: newAccessLinkValues?.isEnabled,
          };

    setRegenerated(true);
    axios
      .put(regenerateURL, {
        ...params,
        refreshToken: true,
      })
      .then((res) => setNewAccessLinkValues(res.data.content.value));
    setTimeout(() => setRegenerated(false), 5000);
  };

  return (
    <DropdownPortalMenu className="access-link-metafield__dropdown-menu">
      <DropdownMenuItem onClick={toggle}>
        <CustomSwitch
          checked={newAccessLinkValues?.isEnabled}
          onChange={toggle}
        />
        <IDHFormattedMessage id="ws_active" defaultMessage="Active" />
      </DropdownMenuItem>
      <DropdownMenuItem className={regenerated && "dropdown__menu-item--done"}>
        <div
          className="access-link-metafield__regenerate"
          onClick={regenerateAccessLink}
        >
          <img
            src={regenerateIcon}
            className="access-link-metafield__regenerate-icon"
            alt=""
          />
          {regenerated ? (
            <>
              <IDHFormattedMessage
                id="ws_regenerated"
                defaultMessage="Regenerated"
              />
              .
            </>
          ) : (
            <IDHFormattedMessage
              id="ws_regenerate"
              defaultMessage="Regenerate"
            />
          )}
        </div>
      </DropdownMenuItem>
    </DropdownPortalMenu>
  );
}
