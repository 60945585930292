import classNames from "classnames";
import React from "react";

import DOMPurify from "dompurify";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { wrapEmojis } from "src/utils/methods";
import { useSelector } from "react-redux";
import { ReactComponent as PostsRecentlyPublished } from "../../../../../images/profile-data/posts-recently-published.svg";
import { ReactComponent as InstagramIcon } from "../../../../../images/social/instagram-color.svg";
import { ReactComponent as TiktokIcon } from "../../../../../images/social/tiktok-color.svg";
import { ReactComponent as YoutubeIcon } from "../../../../../images/social/youtube-color.svg";
import { SocialProvider } from "../../../../../types";
import { Button } from "../../../../components/Button/Button";
import OuterLink from "../../../../components/OuterLink/OuterLink";
import PostImagesChecker from "../../../../components/PostImagesChecker/PostImagesChecker";
import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";

export function RecentlyPublished(props) {
  const {
    lastPostsData,
    socialUrl,
    height,
    provider,
    recentlyPublishedReady,
    setRecentlyPublishedReady,
  } = props;

  const printing = useSelector((state) => state.showcaseReducer.printing);

  const getBtnText = () => {
    switch (provider) {
      case SocialProvider.Instagram:
        return (
          <IDHFormattedMessage
            id="ws_watch_more_on_instagram"
            defaultMessage="Watch more on Instagram"
          />
        );
      case "tiktok":
      case "tik_tok":
        return (
          <IDHFormattedMessage
            id="ws_watch_more_on_tik_tok"
            defaultMessage="Watch more on Tiktok"
          />
        );
      case SocialProvider.Youtube:
        return (
          <IDHFormattedMessage
            id="ws_watch_more_on_youtube"
            defaultMessage="Watch more on Youtube"
          />
        );
      default:
        return (
          <IDHFormattedMessage id="ws_watch_more" defaultMessage="Watch more" />
        );
    }
  };

  const getBtnIcon = () => {
    switch (provider) {
      case SocialProvider.Instagram:
        return <InstagramIcon className="recent-posts__social-provider-icon" />;
      case "tiktok":
      case "tik_tok":
        return <TiktokIcon className="recent-posts__social-provider-icon" />;
      case SocialProvider.Youtube:
        return <YoutubeIcon className="recent-posts__social-provider-icon" />;
      default:
        return <></>;
    }
  };

  return (
    <ProfileBox block>
      <PBHeader
        icon={<PostsRecentlyPublished />}
        title={
          <IDHFormattedMessage
            id="ws_recently_published"
            defaultMessage="Recently published"
          />
        }
        onShowcase={height}
      />
      {!height && provider !== SocialProvider.Twitter && (
        <OuterLink href={socialUrl}>
          <Button
            variant="light"
            size="medium"
            className="recent-posts__watch-more"
          >
            {getBtnText()}
            {getBtnIcon()}
          </Button>
        </OuterLink>
      )}
      <div className="recent-posts__list">
        {provider === SocialProvider.Twitter ? (
          lastPostsData
            .slice(0, 3)
            .map((item) => <PostDescription data={item} onShowcase={height} />)
        ) : (
          <PostImagesChecker
            max={3}
            data={lastPostsData || []}
            render={(item, index) => (
              <div
                className={classNames("recent-posts__item", {
                  "recent-posts__item--on-showcase": height,
                })}
                key={index}
              >
                <OuterLink href={item.postUrl}>
                  <img
                    className="recent-posts__image"
                    src={!printing ? item.thumbnailSrc : undefined}
                    data-img-src={printing ? item.thumbnailSrc : undefined}
                    alt="post-image"
                    loading="lazy"
                  />
                </OuterLink>
                {provider !== SocialProvider.Snapchat && (
                  <div className="recent-posts__stats recent-posts__stats--er">
                    {item.er}%
                  </div>
                )}
              </div>
            )}
            provider={provider}
            recentlyPublishedReady={recentlyPublishedReady}
            setRecentlyPublishedReady={setRecentlyPublishedReady}
          />
        )}
      </div>
    </ProfileBox>
  );
}

function PostDescription({ data, onShowcase }) {
  const description = wrapEmojis(data.description);

  return (
    <div
      className={classNames("recent-posts__item", {
        "recent-posts__item--on-showcase": onShowcase,
      })}
    >
      <OuterLink href={data.postUrl}>
        <div
          className="recent-posts__text"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
        />
      </OuterLink>
      <div className="recent-posts__stats-wrapper">
        <div className="recent-posts__stats recent-posts__stats--er">
          {data.er}%
        </div>
        {data.imgSrc && (
          <div className="recent-posts__stats recent-posts__stats--images">
            1
          </div>
        )}
      </div>
    </div>
  );
}
