import React, { useRef, useState } from "react";

import { StackedAvatars } from "src/app/components/StackedAvatars/StackedAvatars";

import { ReactComponent as TrashCanIcon } from "src/images/trash-can.svg";
import { ReactComponent as EditIcon } from "src/images/edit.svg";
import { ReactComponent as TickIcon } from "src/images/tick-light-grey.svg";
import { ReactComponent as CrossIcon } from "src/images/cross.svg";

import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { showToast } from "src/app/methods/showToast";
import { API_URLS } from "src/utils/API_URLS";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import { Team } from "../../../utils";

interface Props {
  team: Team;
  teamNameClick: (uuid: string) => void;
  getTeams: () => void;
}

export const TeamOnList: React.FC<Props> = (props) => {
  const { team, teamNameClick, getTeams } = props;

  const [localName, setLocalName] = useState(team.name);
  const [editMode, setEditMode] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const ref = useRef<HTMLInputElement>(null);

  const removeTeam = () => {
    axios
      .delete(API_URLS.deleteTeam.replace(":wsTeamUuid:", team.uuid))
      .then(() => {
        getTeams();
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id="ws_team_has_been_deleted"
            defaultMessage="Team has been deleted."
          />,
        );
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_could_not_delete_team"
            defaultMessage="Could not delete team."
          />,
        );
      });
  };

  const editModeClick = () => {
    setEditMode((v) => !v);
    setTimeout(() => ref.current?.focus(), 25);
  };

  const renameTeam = () => {
    if (localName === team.name) {
      setEditMode(false);
      return;
    }

    axios
      .put(API_URLS.renameTeam, {
        wsTeamUuid: team.uuid,
        wsWorkspaceUuid: activeWorkspaceUuid,
        name: localName,
      })
      .then(() => {
        getTeams();
        setEditMode(false);
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_could_not_rename_team"
            defaultMessage="Could not rename Team."
          />,
        );
      });
  };

  const disableEditMode = () => {
    setLocalName(team.name);
    setEditMode(false);
  };

  return (
    <>
      <div className="team-list__table-body-row">
        <span
          className="team-list__table-body-row-el team-name"
          onClick={() => !editMode && teamNameClick(team.uuid)}
        >
          {editMode ? (
            <input
              ref={ref}
              value={localName}
              onChange={(e) => setLocalName(e.target.value)}
            />
          ) : (
            localName
          )}
        </span>
        <span className="team-list__table-body-row-el">
          <StackedAvatars
            avatars={team.wsMembers}
            maxAvatars={5}
            stackedUsers
          />
        </span>
        <span className="team-list__table-body-row-el team-options">
          {editMode ? (
            <>
              <TickIcon onClick={renameTeam} />
              <CrossIcon onClick={disableEditMode} />
            </>
          ) : (
            <EditIcon onClick={editModeClick} />
          )}
          <TrashCanIcon onClick={() => setShowRemoveModal(true)} />
        </span>
      </div>

      {showRemoveModal && (
        <RemoveModal
          onClose={() => setShowRemoveModal(false)}
          objectNames={[team.name]}
          removeFunction={removeTeam}
        />
      )}
    </>
  );
};
