import React from "react";

import { SkeletonText } from "../../../components/Skeleton/Skeleton";

export function ToolbarListWrapper(props) {
  const { children, loading } = props;

  if (loading) {
    return (
      <div className="toolbar-list">
        <SkeletonText width="100%" height={20} />
      </div>
    );
  }

  return children;
}
