import React, { useContext, useEffect, useState } from "react";
import { CustomDateRange } from "src/app/components/CustomDateRange/CustomDateRange";
import { DictionaryFiltersContext } from "../../DictionaryFilters";

export function SingleDatePickerRange({ col, sendFilter }) {
  const { dictionaryFilters } = useContext(DictionaryFiltersContext);
  const { filters } = dictionaryFilters;

  const [waitEvent, setWaitEvent] = useState(null);

  const [value, setValue] = useState(filters[col.uuid]?.value.from);
  const [value2, setValue2] = useState(filters[col.uuid]?.value.to);

  useEffect(() => {
    let timer = 0;
    const delay = value === value2 ? 2500 : 500;

    if (waitEvent && sendFilter) {
      timer = setTimeout(() => {
        sendFilter(col.uuid, waitEvent.target.value);
      }, delay);
    }
    return () => clearTimeout(timer);
  }, [waitEvent]);

  useEffect(() => {
    value !== undefined &&
      value2 !== undefined &&
      (value !== filters[col.uuid]?.value.from ||
        value2 !== filters[col.uuid]?.value.to) &&
      setWaitEvent({
        target: {
          value: value === null ? undefined : { from: value, to: value2 },
        },
      });
  }, [value, value2]);

  return (
    <CustomDateRange
      dateFrom={value}
      setDateFrom={setValue}
      dateTo={value2}
      setDateTo={setValue2}
      toRight
    />
  );
}
