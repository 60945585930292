import React, { Dispatch, FC, ReactNode, SetStateAction, useMemo } from "react";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import SelectLabel from "../SelectLabel/SelectLabel";

interface Props {
  multiValue: any;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  emptyFieldPlaceholder: ReactNode;
  handleLabelClick: any;
  stackLabels?: boolean;
  readOnly?: boolean;
}

export const AccumulatedLabels: FC<Props> = (props) => {
  const {
    multiValue,
    setIsOpen,
    emptyFieldPlaceholder,
    handleLabelClick,
    stackLabels,
    readOnly,
  } = props;

  const getLabelStyles = () => {
    if (!stackLabels) return {};

    if (multiValue.length === 1) {
      return { flexBasis: "100%" };
    }

    if (multiValue.length === 2) {
      return { flexBasis: "calc((100% - 4px) / 2)" };
    }

    return {
      flexBasis: "calc((100% - 8px) / 3)",
    };
  };

  const tooltipContent = useMemo(() => {
    return multiValue.map((item: { name: string }) => item.name).join(", ");
  }, [multiValue]);

  const labels = stackLabels ? multiValue.slice(0, 5) : multiValue;

  const Content = useMemo(() => {
    return (
      <span
        onClick={readOnly ? undefined : () => setIsOpen(true)}
        className="select__value-wrapper"
      >
        {labels.map((item: any) => (
          <SelectLabel
            key={item.value}
            value={item}
            onClickHandler={handleLabelClick}
            emptyFieldPlaceholder={emptyFieldPlaceholder}
            style={getLabelStyles()}
            readOnly={readOnly}
          />
        ))}
        {stackLabels && multiValue.length > 5 && (
          <span className="select__value-wrapper-counter">
            +{multiValue.length - 5}
          </span>
        )}
      </span>
    );
  }, [stackLabels, multiValue, getLabelStyles]);

  if (stackLabels) {
    return (
      <TooltipPortal content={tooltipContent} noHeightLimit>
        {Content}
      </TooltipPortal>
    );
  }

  return Content;
};
