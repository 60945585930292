import React from "react";
import "./ExportTypes.scss";
import classNames from "classnames";
import { ReactComponent as PdfIcon } from "src/images/pdf-alt.svg";
import { ReactComponent as PptxIcon } from "src/images/pptx.svg";
import { ReactComponent as XlsxIcon } from "src/images/xlsx.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import { useSelector } from "react-redux";
import { MAX_CREATORS_FOR_GENERATE_SHOWCASE } from "src/app/CreatorShowcase/utils";

function ExportTypes(props) {
  const {
    showXls,
    text,
    disabled,
    selectedInfluencers,
    onExportClick,
    paginationLimit = false,
  } = props;

  const {
    audiencesManagerReducer: { hasAccessToFullExports },
  } = useSelector((state) => state);

  const typesArray = ["pdf"];

  function TypeIcon({ type }) {
    switch (type) {
      case "xls":
        return <XlsxIcon />;
      case "pptx":
        return <PptxIcon />;
      case "pdf":
        return <PdfIcon />;
    }

    return null;
  }

  return (
    <div className="export-wrapper">
      <div className="export-text">
        {text || (
          <IDHFormattedMessage id="front_generate" defaultMessage="Generate:" />
        )}
      </div>

      <MaterialTooltip
        center
        content={
          selectedInfluencers.length === 0 ? (
            <IDHFormattedMessage
              id="front_creator_export_pdf_tooltip"
              defaultMessage="Export PDF tooltip"
            />
          ) : (
            <IDHFormattedMessage
              id="front_cannot_generate_showcase"
              defaultMessage="Cannot generate showcase for more than 100 creators"
            />
          )
        }
        contentHidden={
          selectedInfluencers.length > 0 &&
          selectedInfluencers.length <= MAX_CREATORS_FOR_GENERATE_SHOWCASE
        }
      >
        <div
          className={classNames("export-types", {
            "export-types--disabled":
              disabled ||
              selectedInfluencers.length > MAX_CREATORS_FOR_GENERATE_SHOWCASE,
          })}
        >
          <div
            className="export-type"
            onClick={() => onExportClick("pdf")}
            key="pdf"
            data-export-type="pdf"
          >
            <TypeIcon type="pdf" />
            <span>.PDF</span>
          </div>
        </div>
      </MaterialTooltip>

      {showXls && (
        <MaterialTooltip
          center
          content={
            <IDHFormattedMessage
              id="front_creator_export_xls_tooltip"
              defaultMessage="Export XLSX tooltip"
            />
          }
          contentHidden={selectedInfluencers.length}
        >
          <div
            className={classNames("export-types", {
              "export-types--disabled": disabled,
            })}
          >
            <div
              className="export-type"
              onClick={() => onExportClick("xls")}
              key="xls"
              data-export-type="xls"
            >
              <TypeIcon type="xls" />
              <span>.xlsx</span>
            </div>
          </div>
        </MaterialTooltip>
      )}
    </div>
  );
}

export default ExportTypes;
