import React, { Dispatch, SetStateAction } from "react";
import { useIntl } from "react-intl";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import { translateMessage } from "src/app/methods/translateMessage";
import { Button } from "src/app/components/Button/Button";
import OuterLink from "../../components/OuterLink/OuterLink";
import { Avatar } from "../Avatar";
import { OnboardingStep } from "../OnboardingPage";

interface Props {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  setCurrentStep: Dispatch<SetStateAction<OnboardingStep>>;
}

export const CreateProfile: React.FC<Props> = (props) => {
  const { name, setName, setCurrentStep } = props;

  const intl = useIntl();

  const checkboxLabel = (
    <div className="onboarding-page__terms-label">
      <IDHFormattedMessage
        id="ws_by_signing_up_you_agree"
        defaultMessage="By signing up, you agree with our"
      />
      &nbsp;
      <OuterLink
        href={translateMessage({
          intl,
          id: "ws_onboarding_privacy_policy_url",
          defaultMessage: "https://docs.indahash.com/doc/?n=privacy-policy",
        })}
      >
        <IDHFormattedMessage
          id="ws_onboarding_privacy_policy"
          defaultMessage="Privacy Policy"
        />
      </OuterLink>
      &nbsp;
      <IDHFormattedMessage id="ws_onboarding_and" defaultMessage="and" />
      &nbsp;
      <OuterLink
        href={translateMessage({
          intl,
          id: "ws_onboarding_terms_url",
          defaultMessage: "https://docs.indahash.com/doc/?n=terms-conditions-for-self-serve",
        })}
      >
        <IDHFormattedMessage
          id="ws_onboarding_terms_of_service"
          defaultMessage="Terms of Service"
        />
      </OuterLink>
    </div>
  );

  return (
    <>
      <div className="onboarding-page__header">
        <IDHFormattedMessage
          id="ws_create_your_profile"
          defaultMessage="Create your profile!"
        />
      </div>
      <div className="onboarding-page__text">
        <IDHFormattedMessage
          id="ws_upload_photo_for_avatar"
          defaultMessage="Upload photo for avatar"
        />
      </div>
      <Avatar />
      <CustomInputLabel aboveInput>
        <IDHFormattedMessage id="ws_full_name" defaultMessage="Full name" />
      </CustomInputLabel>
      <CustomInput
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder={translateMessage({
          intl,
          id: "ws_enter_full_name",
          defaultMessage: "Enter full name",
        })}
        required
        fullWidth
        type="text"
        data-qa="full-name"
      />
      {checkboxLabel}
      <div className="onboarding-page__buttons">
        <Button
          type="button"
          size="large"
          variant="white"
          className="onboarding-page__btn"
          onClick={() => setCurrentStep("language")}
        >
          <IDHFormattedMessage id="ws_back" defaultMessage="Back" />
        </Button>
        <Button
          type="submit"
          size="large"
          variant="blue"
          className="onboarding-page__btn"
          disabled={name.length < 3}
          data-qa="next-button"
        >
          <IDHFormattedMessage id="ws_next" defaultMessage="Next" />
        </Button>
      </div>
    </>
  );
};
