import React from "react";

import { PublicationStatus } from "src/types/publication";
import { getPublicationStatusNameBasedOnStatus } from "./utils";
import AwaitingIcon from "../AwaitingIcon";
import WarningIcon from "../WarningIcon";

interface PublicationStatusIconProps {
  status: PublicationStatus;
  addDataImgSrc?: boolean;
}

export default function PublicationStatusIcon({
  addDataImgSrc,
  status,
}: PublicationStatusIconProps) {
  if (status === PublicationStatus.Fresh) {
    return (
      <AwaitingIcon
        alt={getPublicationStatusNameBasedOnStatus(PublicationStatus.Fresh)}
        addDataImgSrc={addDataImgSrc}
      />
    );
  }
  if (status === PublicationStatus.NotFound) {
    return (
      <WarningIcon
        alt={getPublicationStatusNameBasedOnStatus(PublicationStatus.Fresh)}
        addDataImgSrc={addDataImgSrc}
      />
    );
  }
  return null;
}
