import React, { useState, useEffect } from "react";
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from "react-number-format";

interface PercentInputProps extends NumberFormatProps {
  inputRef?: React.RefObject<HTMLInputElement>;
  rawValue?: string | null;
  setRawValue: React.Dispatch<React.SetStateAction<string>>;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
}

const displayDecimal = (percent: number | undefined) => {
  if (typeof percent !== "number") return "";
  return (percent / 100).toFixed(4);
};

const displayPercent = (decimal: string | null | number | undefined) => {
  if (decimal === null || decimal === undefined || decimal === "") return "";
  return Number(decimal) * 100;
};

export function PercentInput({
  rawValue,
  setRawValue,
  inputRef,
  ...rest
}: PercentInputProps) {
  const [percentValue, setPercentValue] = useState<
    string | null | number | undefined
  >();

  const validate = (values: NumberFormatValues) => {
    const { floatValue } = values;
    if (!floatValue) return true;
    return floatValue > -10e11 && floatValue < 10e11;
  };

  const handleChange = (NFvalues: NumberFormatValues) => {
    const { floatValue } = NFvalues;
    setRawValue(displayDecimal(floatValue)?.toString() || "");
    setPercentValue(floatValue);
  };

  useEffect(() => {
    setPercentValue(displayPercent(rawValue));
  }, []);

  useEffect(() => {
    if (rawValue !== percentValue) setPercentValue(displayPercent(rawValue));
  }, [rawValue]);

  return (
    <NumberFormat
      value={percentValue}
      decimalScale={2}
      onValueChange={handleChange}
      suffix="%"
      thousandSeparator=" "
      isAllowed={validate}
      getInputRef={inputRef}
      {...rest}
    />
  );
}
