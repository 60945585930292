export const addSummaryLearningsToLayout = (newLayout) => {
  const lastEl = newLayout[newLayout.length - 1];

  const x = 0;
  const y = lastEl.y + lastEl.h;
  const w = 12;
  const h = 15;

  newLayout.push({ i: "summary-learnings", x, y, w, h });
};
