import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tab from "src/app/components/Tabs/Tab";
import Tabs from "src/app/components/Tabs/Tabs";
import { capitalizeFirstLetter } from "src/utils/methods";
import { ActiveTabs } from "./XTRMPayments";

interface XTRMPaymentsTabsProps {
  tabs: ActiveTabs[];
  activeTab: ActiveTabs;
  setActiveTab: (tab: ActiveTabs) => void;
}

export default function XTRMPaymentsTabs({
  tabs,
  activeTab,
  setActiveTab,
}: XTRMPaymentsTabsProps) {
  return (
    <Tabs>
      <div className="extensions__tabs">
        {tabs.map((tab) => (
          <Tab
            key={tab}
            tabText={
              <IDHFormattedMessage
                id={`ws_${tab}`}
                defaultMessage={capitalizeFirstLetter(tab)}
              />
            }
            onClick={() => setActiveTab(tab)}
            active={activeTab === tab}
          />
        ))}
      </div>
    </Tabs>
  );
}
