export const getNumberedHeaders = (layout, gridElements) => {
  if (!gridElements?.length || !layout?.length) return;

  const sections = [];
  const fullHeaders = [];

  const headers = gridElements
    ?.filter(
      (item) =>
        item.gridBoxType === "header" || item.gridBoxType === "subheader",
    )
    .map((item) => {
      const y = layout?.find((el) => el.i === item.label)?.y;

      if (y) {
        return { ...item, y };
      }
      return item;
    })
    .sort((a, b) => a.y - b.y);

  headers.forEach((item) => {
    if (item.gridBoxType === "header") {
      const newItem = {
        ...item,
        number: fullHeaders.length + 1,
      };

      sections.push([newItem]);
      fullHeaders.push(newItem);
    } else {
      let mainNumber = 0;
      if (sections[sections.length - 1]) {
        mainNumber = sections[sections.length - 1][0]?.number;
      } else {
        mainNumber = 0;
      }

      const headerFound = sections[sections.length - 1]?.find(
        (item) => item.gridBoxType === "header",
      );

      const numDiff = headerFound ? 0 : 1;
      const subNumber = sections[sections.length - 1]?.length + numDiff || 1;
      const number = `${Math.floor(mainNumber)}.${subNumber}`;

      const newItem = {
        ...item,
        number,
      };

      if (sections[sections.length - 1]) {
        sections[sections.length - 1].push(newItem);
      } else {
        sections.push([newItem]);
      }
    }
  });

  const numberedHeaders = sections.flat(1);

  return numberedHeaders;
};
