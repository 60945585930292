import React, { PropsWithChildren, ReactNode } from "react";

import { IMember } from "src/app/project/ProjectTypes";
import AvatarPlaceholder from "src/images/avatar-purple.svg";
import { Comment } from "src/redux/comment/types";
import CommentHeader from "./CommentHeader";

interface CommentWrapperProps {
  comment: Comment;
  commentId: string;
  commentAuthor: IMember | undefined;
  commentAuthorCustomElement?: ReactNode;
  hasAccessToRemoveWsTaskComment: boolean;
}

export default function CommentWrapper({
  comment,
  commentId,
  commentAuthor,
  commentAuthorCustomElement,
  children,
  hasAccessToRemoveWsTaskComment,
}: PropsWithChildren<CommentWrapperProps>) {
  return (
    <div
      className="comment__wrapper"
      key={commentId}
      data-comment-id={commentId}
    >
      <img
        src={commentAuthor?.avatarUrl ?? AvatarPlaceholder}
        className="comment__avatar"
        alt="author-avatar"
        loading="lazy"
      />
      <div className="comment__block">
        <CommentHeader
          comment={comment}
          commentId={commentId}
          commentAuthor={commentAuthor}
          commentAuthorCustomElement={commentAuthorCustomElement}
          hasAccessToRemoveWsTaskComment={hasAccessToRemoveWsTaskComment}
        />
        <div className="comment__body">{children}</div>
      </div>
    </div>
  );
}
