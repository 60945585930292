import React, { Dispatch, SetStateAction, RefObject } from "react";

import { useSelector } from "react-redux";

import { RootState } from "src/redux/reducers";
import { IMember } from "src/app/project/ProjectTypes";
import { CommentDisplayType } from "../../Task";
import ContentProposalField from "../ContentProposalField/ContentProposalField";
import AddCommentWrapper from "../AddCommentField/AddCommentWrapper";
import AddCommentField from "../AddCommentField/AddCommentField";
import ReplyToCommentField from "../ReplyToCommentField/ReplyToCommentField";

interface TaskFooterProps {
  commentDisplayType: CommentDisplayType;
  taskId: string;
  userAvatar: JSX.Element;
  showAddContentProposalField: boolean;
  setShowAddContentProposalField: Dispatch<SetStateAction<boolean>>;
  activeWorkspaceUuid: string;
  taskDetails: any;
  hasAccessToCreateContentProposal: any;
  setContentProposalFieldNotSubmitted: Dispatch<SetStateAction<boolean>>;
  membersList: IMember[];
}

export default function TaskFooter({
  commentDisplayType,
  taskId,
  userAvatar,
  showAddContentProposalField,
  setShowAddContentProposalField,
  activeWorkspaceUuid,
  hasAccessToCreateContentProposal,
  taskDetails,
  setContentProposalFieldNotSubmitted,
  membersList,
}: TaskFooterProps) {
  const { replyToCommentData } = useSelector(
    (state: RootState) => state.commentReducer,
  );

  const renderField = () => {
    if (replyToCommentData.wsTaskUuid) {
      return (
        <AddCommentWrapper>
          {userAvatar}
          <ReplyToCommentField membersList={membersList} />
        </AddCommentWrapper>
      );
    }

    if (commentDisplayType === CommentDisplayType.ContentProposal) {
      return (
        <ContentProposalField
          activeWorkspaceUuid={activeWorkspaceUuid}
          taskId={taskId}
          userAvatar={userAvatar}
          showAddContentProposalField={showAddContentProposalField}
          setShowAddContentProposalField={setShowAddContentProposalField}
          displayFixedAddContentProposalField={
            taskDetails?.comments.length > 0 && hasAccessToCreateContentProposal
          }
          setContentProposalFieldNotSubmitted={
            setContentProposalFieldNotSubmitted
          }
        />
      );
    }
    return (
      <AddCommentWrapper>
        {userAvatar}
        <AddCommentField
          taskId={taskId}
          activeWorkspaceUuid={activeWorkspaceUuid}
          setContentProposalFieldNotSubmitted={
            setContentProposalFieldNotSubmitted
          }
        />
      </AddCommentWrapper>
    );
  };

  return <div className="task__footer">{renderField()}</div>;
}
