import React from "react";
import "./SingleProvider.scss";
import classNames from "classnames";

import METHODS from "../../../helpers/methods";
import OuterLink from "../../OuterLink/OuterLink";
import { SocialProviderIcon2 } from "../../SocialProviderIcon2/SocialProviderIcon2";

export function SingleProvider({ providerInfo, name, highlight }) {
  return (
    <OuterLink
      href={providerInfo.socialProfileUrl}
      className={classNames([
        "single-provider",
        `single-provider--${name}`,
        highlight && "single-provider--highlight",
      ])}
    >
      <SocialProviderIcon2 provider={name} grey={!highlight} />
      {providerInfo.followersCount > 0 && (
        <div className="single-provider__followers">
          {METHODS.numbersWithPostfixNumbers(providerInfo.followersCount)}
        </div>
      )}
    </OuterLink>
  );
}
