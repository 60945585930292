import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios, { AxiosResponse } from "axios";
import classNames from "classnames";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { API_URLS } from "src/utils/API_URLS";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import { showToast } from "src/app/methods/showToast";
import { ModalColumn, ModalTitle } from "src/app/components/Modal/Modal";
import { Button } from "src/app/components/Button/Button";
import { CreatorDatabaseConfigurationSection } from "src/app/SettingsModal/tabs/CreatorDatabaseConfiguration/CreatorDatabaseConfiguration";
import { RootState } from "src/redux/reducers";
import { ChangeSettingsSectionParam } from "src/app/SettingsModal/methods";
import { ExtensionsSection } from "../../../../Extensions";
import InviteMemberTeam from "./InviteMemberTeam";
import { GlobalMetaFieldMemberList } from "./GlobalMetaFieldMemberList";
import { ConfirmDeleteGlobalTaskMetaFieldMemberModal } from "./ConfirmDeleteGlobalTaskMetaFieldMemberModal/ConfirmDeleteGlobalTaskMetaFieldMemberModal";

import "./GlobalMetaFieldMembers.scss";

interface GlobalMetaFieldMembersProps {
  onClose: () => void;
  setExtensionsSection: (section: ChangeSettingsSectionParam) => void;
}

export default function GlobalMetaFieldMembers(
  props: GlobalMetaFieldMembersProps,
) {
  const { onClose, setExtensionsSection } = props;

  const [loading, setLoading] = useState(false);
  const [currentMetaField, setCurrentMetaField] = useState<any | null>(null);
  const [teams, setTeams] = useState<any[] | null>([]);
  const [currentMemberList, setCurrentMemberList] = useState<any[] | null>(
    null,
  );
  const [showConfirmDeleteMemberModal, setShowConfirmDeleteMemberModal] =
    useState<boolean>(false);
  const [deleteMemberData, setDeleteMemberData] = useState<any>(null);

  const {
    mainReducer: { activeWorkspaceUuid, settingsModalData },
    projectReducer: { membersList },
  } = useSelector((state: RootState) => state);

  const { objectUuid } = settingsModalData;

  const getTeams = () => {
    setLoading(true);

    const url = API_URLS.teamsForWorkspace.replace(
      ":wsWorkspaceUuid:",
      activeWorkspaceUuid,
    );

    axios
      .get(url)
      .then((response: AxiosResponse) => {
        const { content } = response.data;
        setTeams(content);
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_something_went_wrong"
            defaultMessage="Something went wrong"
          />,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getMembersList = () => {
    if (!objectUuid) {
      setExtensionsSection(CreatorDatabaseConfigurationSection.MetaFieldList);

      return;
    }

    setLoading(true);
    const url = API_URLS.getGlobalMetaFieldMemberTeamList
      .replace(":workspaceUuid:", activeWorkspaceUuid)
      .replace(":globalTaskMetaFieldUuid:", objectUuid);

    axios
      .get(url)
      .then((response) => {
        setCurrentMetaField(response.data.content?.metaField ?? null);
        setCurrentMemberList(response.data.content?.members ?? []);
        setLoading(false);
      })
      .catch(() => {
        setCurrentMetaField(null);
        setCurrentMemberList([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTeams();
    getMembersList();
  }, []);

  return (
    <>
      <ModalTitle>
        <div className="extensions__header">
          <div>
            <button
              onClick={() =>
                setExtensionsSection(ExtensionsSection.ExtensionsList)
              }
              className="extensions__back-btn"
            >
              <IDHFormattedMessage
                id="ws_creator_database_settings"
                defaultMessage="Settings"
              />
            </button>
            <span className="extensions__back-label">/</span>
            <button
              onClick={() =>
                setExtensionsSection(
                  CreatorDatabaseConfigurationSection.MetaFieldList,
                )
              }
              className="extensions__back-btn"
            >
              <IDHFormattedMessage
                id="ws_creator_database_meta_field_header"
                defaultMessage="Visibility and permissions"
              />
            </button>
          </div>
          <div className="extensions__header-text">
            {currentMetaField?.name ?? (
              <IDHFormattedMessage
                id="ws_creator_database_meta_field_header"
                defaultMessage="Visibility and permissions"
              />
            )}
          </div>
        </div>
      </ModalTitle>
      <ModalColumn>
        <AnimatedDiv className={classNames("meta-field-members")}>
          <InviteMemberTeam
            teams={teams}
            getMembersList={getMembersList}
            currentMemberList={currentMemberList}
          />
          <GlobalMetaFieldMemberList
            currentMemberList={currentMemberList}
            setShowConfirmDeleteMemberModal={setShowConfirmDeleteMemberModal}
            setDeleteMemberData={setDeleteMemberData}
            isLoading={loading}
          />
        </AnimatedDiv>
      </ModalColumn>

      {showConfirmDeleteMemberModal && (
        <ConfirmDeleteGlobalTaskMetaFieldMemberModal
          onClose={() => setShowConfirmDeleteMemberModal(false)}
          deleteMemberData={deleteMemberData}
          getMembersList={getMembersList}
        />
      )}

      <SettingsButtons
        className="settings-tab-content__buttons-left-align"
        children={
          <>
            <Button
              size="large"
              onClick={() => {
                setExtensionsSection(
                  CreatorDatabaseConfigurationSection.MetaFieldList,
                );
              }}
              variant="white-with-border"
            >
              <IDHFormattedMessage id="ws_back" defaultMessage="Back" />
            </Button>
            <Button
              size="large"
              onClick={() => {
                onClose();
              }}
              variant="white-with-border"
            >
              <IDHFormattedMessage id="ws_close" defaultMessage="Close" />
            </Button>
          </>
        }
      />
    </>
  );
}
