import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./TaskTableHeaderOptionsDropdown.scss";

import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SortIcon } from "src/images/up-down.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import DeleteMetaFieldModal from "src/app/modals/DeleteMetaFieldModal/DeleteMetaFieldModal";
import { TasksColumn } from "src/redux/task/taskReducer";
import { RootState } from "src/redux/reducers";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "../../components/Dropdown/Dropdown";

import { ReactComponent as ClientGreyIcon } from "../../../images/client-grey.svg";
import { ReactComponent as ClientOrangeIcon } from "../../../images/client-orange.svg";
import { ReactComponent as EditIcon } from "../../../images/edit.svg";
import { ReactComponent as HideIcon } from "../../../images/hide.svg";
import { ReactComponent as MoveLeftIcon } from "../../../images/move-left.svg";
import { ReactComponent as MoveRightIcon } from "../../../images/move-right.svg";
import { ReactComponent as TickIcon } from "../../../images/tick.svg";
import { ReactComponent as DeleteIcon } from "../../../images/trash-can.svg";
import { generateRankString } from "../../../utils/rankStrings";

import {
  changeFieldVisibility,
  setActiveSortingColumn,
  updateTaskMetaFieldRank,
} from "../../../redux";
import { tableDataType } from "../../components/Table/Table";
import ManageFieldModal from "../../modals/ManageFieldModal/ManageFieldModal";

interface TaskTableHeaderOptionsDropdownProps {
  uuid: string;
  columnFromApi: TasksColumn;
  setIsDropdownOpened: React.Dispatch<React.SetStateAction<boolean>>;
  openShareWithClientModal: () => void;
  openStopSharingWithClientModal: () => void;
}

function TaskTableHeaderOptionsDropdown({
  uuid,
  columnFromApi,
  setIsDropdownOpened,
  openShareWithClientModal,
  openStopSharingWithClientModal,
}: TaskTableHeaderOptionsDropdownProps) {
  const params = useParams<{ projectId: string }>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSetupModal, setShowSetupModal] = useState(false);

  const activeSortingColumn = useSelector(
    (state: RootState) => state.taskFiltersReducer.activeSortingColumn,
  );

  const permissions = useSelector(
    (state: RootState) => state.projectReducer.projectBasicData?.permissions,
  );
  const clientMode = useSelector(
    (state: RootState) => state.projectReducer.projectBasicData?.clientMode,
  );

  const tasksColumns: TasksColumn[] = useSelector(
    (state: RootState) => state.taskReducer.tasksColumns,
  );

  const dispatch = useDispatch();

  const moveRight = () => {
    const colIndex = tasksColumns.findIndex(
      (x) => x.metaFieldId === columnFromApi.metaFieldId,
    );
    const columnsLength = tasksColumns.length;

    if (colIndex === columnsLength - 1) return;

    const newRank = generateRankString(
      tasksColumns[colIndex + 1].metaFieldRank,
      tasksColumns[colIndex + 2]?.metaFieldRank || "",
    );

    dispatch(updateTaskMetaFieldRank(columnFromApi.metaFieldId, newRank));
  };

  const moveLeft = () => {
    const colIndex = tasksColumns.findIndex(
      (x) => x.metaFieldId === columnFromApi.metaFieldId,
    );

    if (colIndex === 0) return;

    const newRank = generateRankString(
      tasksColumns[colIndex - 2]?.metaFieldRank || "",
      tasksColumns[colIndex - 1].metaFieldRank,
    );

    dispatch(updateTaskMetaFieldRank(columnFromApi.metaFieldId, newRank));
  };

  const changeVisibility = (newVisibilityValue: boolean) => {
    dispatch(
      changeFieldVisibility(columnFromApi.metaFieldId, newVisibilityValue),
    );
  };

  return (
    <div>
      <Dropdown toRight className="tasks-table-header-options-dropdown">
        {(isOpen, setIsOpen) => {
          if (!isOpen) {
            setIsDropdownOpened(false);
          }
          return (
            <>
              <div
                className="tasks-table-header-options-dropdown__dots"
                onClick={() => {
                  setIsOpen(true);
                  setIsDropdownOpened(true);
                }}
              />
              <DropdownMenu
                isOpen={isOpen}
                onClick={() => {
                  setIsOpen(false);
                  setIsDropdownOpened(false);
                }}
              >
                <DropdownMenuItem
                  onClick={() => {
                    if (
                      activeSortingColumn !== uuid &&
                      setActiveSortingColumn &&
                      uuid
                    ) {
                      dispatch(setActiveSortingColumn(uuid));
                    } else {
                      dispatch(setActiveSortingColumn(""));
                    }
                  }}
                >
                  <SortIcon />
                  <IDHFormattedMessage
                    id="ws_sort_by"
                    defaultMessage="Sort by"
                  />
                  {activeSortingColumn === uuid && (
                    <TickIcon className="dropdown__menu-item-tick" />
                  )}
                </DropdownMenuItem>
                {columnFromApi && (
                  <>
                    {permissions?.project.includes(
                      "task_meta_fields_management",
                    ) && (
                      <>
                        <DropdownMenuItem
                          onClick={() => setShowSetupModal(true)}
                        >
                          <EditIcon />
                          <IDHFormattedMessage
                            id="ws_edit"
                            defaultMessage="Edit"
                          />
                        </DropdownMenuItem>

                        <DropdownMenuItem onClick={moveLeft}>
                          <MoveLeftIcon />
                          <IDHFormattedMessage
                            id="ws_move_left"
                            defaultMessage="Move left"
                          />
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={moveRight}>
                          <MoveRightIcon />
                          <IDHFormattedMessage
                            id="ws_move_right"
                            defaultMessage="Move right"
                          />
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => changeVisibility(false)}
                        >
                          <HideIcon />
                          <IDHFormattedMessage
                            id="ws_hide"
                            defaultMessage="Hide"
                          />
                        </DropdownMenuItem>
                      </>
                    )}

                    {permissions?.project.includes("share_project_resources") &&
                      clientMode &&
                      columnFromApi?.metaFieldType !==
                        MetaFieldType.DictionaryElement && (
                        <>
                          {columnFromApi.metaFieldShared ? (
                            <DropdownMenuItem
                              onClick={openStopSharingWithClientModal}
                            >
                              <ClientGreyIcon />
                              <IDHFormattedMessage
                                id="ws_stop_sharing"
                                defaultMessage="Stop sharing"
                              />
                            </DropdownMenuItem>
                          ) : (
                            <DropdownMenuItem
                              onClick={openShareWithClientModal}
                            >
                              <ClientOrangeIcon />
                              <IDHFormattedMessage
                                id="ws_share_with_client"
                                defaultMessage="Share with Client"
                              />
                            </DropdownMenuItem>
                          )}
                        </>
                      )}

                    {!columnFromApi?.metaFieldIsRequired &&
                      permissions?.project.includes(
                        "task_meta_fields_management",
                      ) && (
                        <DropdownMenuItem
                          onClick={() => setShowDeleteModal(true)}
                          className="dropdown__menu-item--danger"
                        >
                          <DeleteIcon />
                          <IDHFormattedMessage
                            id="ws_remove"
                            defaultMessage="Remove"
                          />
                        </DropdownMenuItem>
                      )}
                  </>
                )}
              </DropdownMenu>
            </>
          );
        }}
      </Dropdown>

      {showDeleteModal && (
        <DeleteMetaFieldModal
          uuid={uuid}
          fieldName={columnFromApi?.metaFieldName}
          onClose={() => setShowDeleteModal(false)}
          dataType={tableDataType.Task}
        />
      )}

      {showSetupModal && (
        <ManageFieldModal
          // remap object props to format acceptable by ManageFieldModal
          data={{
            id: columnFromApi.metaFieldId,
            isRequired: columnFromApi.metaFieldIsRequired,
            isVisible: columnFromApi.metaFieldVisible,
            key: columnFromApi.metaFieldKey,
            metaFieldOptions: columnFromApi.data,
            metaFieldSource: columnFromApi.metaFieldValueSource,
            name: columnFromApi.metaFieldName,
            rank: columnFromApi.metaFieldRank,
            title: columnFromApi.metaFieldName,
            type: columnFromApi.metaFieldType,
            valueSource: columnFromApi.metaFieldValueSource,
            wsGlobalTaskMetaFieldUuid:
              columnFromApi.metaFieldGlobalTaskMetaFieldUuid,
          }}
          onClose={() => setShowSetupModal(false)}
          dataType={tableDataType.Task}
          projectId={params.projectId}
        />
      )}
    </div>
  );
}

export { TaskTableHeaderOptionsDropdown };
