import React from "react";
import "./ProjectsListElementLoader.scss";

import {
  SkeletonRect,
  SkeletonText,
} from "src/app/components/Skeleton/Skeleton";

export default function ProjectsListElementLoader() {
  return (
    <>
      <tr className="projects-list-element-loader projects-data-table__row">
        <div className="projects-list-element-loader__left">
          <div className="projects-list-element-loader__before" />
          <SkeletonRect
            width={48}
            height={48}
            marginLeft={24}
            marginRight={12}
          />
          <SkeletonText width={500} height={18} />
        </div>
      </tr>
      <tr className="projects-list-element-loader projects-data-table__row">
        <div className="projects-list-element-loader__left">
          <div className="projects-list-element-loader__before" />
          <SkeletonRect
            width={48}
            height={48}
            marginLeft={24}
            marginRight={12}
          />
          <SkeletonText width={500} height={18} />
        </div>
      </tr>
      <tr className="projects-list-element-loader projects-data-table__row">
        <div className="projects-list-element-loader__left">
          <div className="projects-list-element-loader__before" />
          <SkeletonRect
            width={48}
            height={48}
            marginLeft={24}
            marginRight={12}
          />
          <SkeletonText width={500} height={18} />
        </div>
      </tr>
    </>
  );
}
