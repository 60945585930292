import React, { useState } from "react";
import "./SeeAsClientInfoModal.scss";
import classNames from "classnames";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalText, ModalTitle } from "../../../components/Modal/Modal";

function SeeAsClientInfoModal(props) {
  const { onClose, openShareModal } = props;

  const [page, setPage] = useState(1);

  const closeModal = () => {
    onClose();
    openShareModal();
  };

  const onNextClick = () => {
    setPage(2);
  };

  const onFirstDotClick = () => {
    setPage(1);
  };

  const onSecondDotClick = () => {
    setPage(2);
  };

  return (
    <Modal
      className="share-with-client-info-modal"
      onClose={closeModal}
      onConfirmClick={page === 1 ? onNextClick : closeModal}
      confirmButtonText={
        page === 1 ? (
          <IDHFormattedMessage id="ws_next" defaultMessage="Next" />
        ) : (
          <IDHFormattedMessage id="ws_close" defaultMessage="Close" />
        )
      }
    >
      <div>
        <video
          width="566"
          height="350"
          autoPlay
          loop
          muted
          preload="auto"
          key={page}
        >
          <source
            src={
              page === 1
                ? "https://static.indahash.com/assets/videos/workspace/share-with-client.mov"
                : "https://static.indahash.com/assets/videos/workspace/see-it-as-a-client.mov"
            }
            type="video/mp4"
          />
          <IDHFormattedMessage
            id="ws_your_browser_does_not_support_video"
            defaultMessage="Your browser does not support the video tag."
          />
        </video>
      </div>

      <ModalTitle>
        {page === 1 ? (
          <IDHFormattedMessage
            id="ws_share_with_client_column"
            defaultMessage='"Share with Client" column'
          />
        ) : (
          <IDHFormattedMessage
            id="ws_see_it_as_client_column"
            defaultMessage='"See it as a Client" mode'
          />
        )}
      </ModalTitle>
      <ModalText>
        {page === 1 ? (
          <IDHFormattedMessage
            id="ws_see_as_client_modal_text_a"
            defaultMessage="Control what you want to share with your clients. An additional column has appeared in the campaign summary and in the table, which will allow you to share only specific items from the table with the parameters you choose."
          />
        ) : (
          <IDHFormattedMessage
            id="ws_see_as_client_modal_text_b"
            defaultMessage="In order to have full control over what elements of the campaign you share, use the See it as a Client. By enabling it, you will see the exact information that will be seen by others who have client access to the campaign."
          />
        )}
      </ModalText>
      <div className="share-with-client-info-modal__dots">
        <div
          className={classNames("share-with-client-info-modal__dot", {
            "share-with-client-info-modal__dot--blue": page === 1,
            "share-with-client-info-modal__dot--grey": page === 2,
          })}
          onClick={onFirstDotClick}
        />
        <div
          className={classNames("share-with-client-info-modal__dot", {
            "share-with-client-info-modal__dot--blue": page === 2,
            "share-with-client-info-modal__dot--grey": page === 1,
          })}
          onClick={onSecondDotClick}
        />
      </div>
    </Modal>
  );
}

export { SeeAsClientInfoModal };
