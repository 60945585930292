import React, { useMemo } from "react";
import "./CommentList.scss";

import { ReactComponent as EyesIcon } from "src/images/eyes.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

function CommentList(props) {
  const { sentimentCommentsData, activeTab } = props;

  const commentList = useMemo(
    () =>
      sentimentCommentsData?.all
        ? sentimentCommentsData?.all[activeTab]?.filter(
            (item) => item?.commentText?.length > 0,
          ) || []
        : [],
    [sentimentCommentsData, activeTab],
  );

  if (!commentList?.length)
    return (
      <div className="empty-comments-list">
        <EyesIcon />
        <IDHFormattedMessage
          id="ws_no_comments_to_display"
          defaultMessage="No comments to display"
        />
      </div>
    );

  return (
    <div key={activeTab} className="comment-list">
      {commentList.map((item, index) => (
        <div
          key={`${item.commentAuthor}-${item.commentText}-${index}`}
          className="comment-list__item"
        >
          <span className="comment-list__item-author">
            {item.commentAuthor ? `@${item.commentAuthor}` : ""}
          </span>
          <div className="comment-list__item-text">{item.commentText}</div>
        </div>
      ))}
    </div>
  );
}

export { CommentList };
