import React, { useEffect } from "react";

import Loader from "src/app/components/Loader/Loader";
import { connect } from "react-redux";
import {
  clearInfluencersListInfo,
  clearResults,
  getInfluencersList,
} from "../legacy/app/audiences-manager/audiencesManagerActions";

import EmptyAudiencesManager from "../legacy/app/audiences-manager/EmptyAudiencesManager/EmptyAudiencesManager";
import InfluencersList from "../legacy/app/audiences-manager/InfluencersList/InfluencersList";

function InfluencersListHandler({
  influencersListLoading,
  influencersList,
  getInfluencersList,
  clearInfluencersListInfo,
  clearResults,
}) {
  useEffect(() => {
    getInfluencersList();
    clearInfluencersListInfo();
    clearResults();
  }, []);

  return influencersListLoading ? (
    <Loader />
  ) : influencersList.length ? (
    <InfluencersList />
  ) : (
    <EmptyAudiencesManager />
  );
}

const mapStateToProps = (state) => ({
  influencersList: state.audiencesManagerReducer.influencersList,
  influencersListLoading: state.audiencesManagerReducer.influencersListLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getInfluencersList: () => dispatch(getInfluencersList()),
    clearInfluencersListInfo: () => dispatch(clearInfluencersListInfo()),
    clearResults: () => dispatch(clearResults()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InfluencersListHandler);
