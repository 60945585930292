import React, { useState } from "react";
import classNames from "classnames";

import { ReactComponent as EditIcon } from "src/images/edit.svg";

import { Button } from "src/app/components/Button/Button";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export function EditAutomationButton({
  automation,
  handlePublication,
  disabled,
}) {
  const [toggled, setToggled] = useState(false);
  return automation?.enabled ? (
    <Button
      className={classNames([
        automation.enabled
          ? "automations-modal__list-element-edit-button"
          : "automations-modal__list-element-activate-button",
      ])}
      size="large"
      variant="white"
      onClick={() => handlePublication(automation)}
      disabled={disabled}
    >
      <EditIcon /> <IDHFormattedMessage id="ws_edit" defaultMessage="Edit" />
    </Button>
  ) : (
    <CustomSwitch
      checked={automation?.enabled || toggled}
      disabled={disabled}
      onChange={(e) => {
        setToggled(e.target.checked);
        setTimeout(() => {
          handlePublication(automation);
        }, 200);
      }}
    />
  );
}
