import React, { ReactNode, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

import { overlaysRef } from "src/App";
import { ReactComponent as CloseIcon } from "src/images/close.svg";
import { INPUT_TYPES_TO_FOCUS, hideModal } from "../Modal/Modal";
import { Button, ButtonVariantDefault, ButtonVariants } from "../Button/Button";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import Loader from "../Loader/Loader";

import "./WSModal.scss";

interface WSModalProps {
  children?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  onClose: () => void;
  className: string;
  disableTransition?: boolean;
  overlayClassName?: string;
  displayCancelButton?: boolean;
  closeButtonText?: ReactNode;
  cancelButtonIcon?: ReactNode;
  closeButtonVariant?: ButtonVariants;
  onCancelClick?: () => void;
  onConfirmClick?: (e: any) => void;
  confirmButtonLoading?: boolean;
  confirmButtonVariant?: ButtonVariants;
  confirmButtonDisabled?: boolean;
  confirmButtonText?: ReactNode;
  closeIcon?: boolean;
  customButton?: ReactNode;
  scrollModalBodyToBottomCb?: () => void;
  scrollModalBodyToBottom?: boolean;
}

export const WSModal = ({
  overlayClassName,
  disableTransition,
  ...otherProps
}: WSModalProps) => {
  const div = useRef(document.createElement("div"));

  useEffect(() => {
    const overlayClassNames = [
      "modal-overlay",
      ...(disableTransition ? [] : ["modal-overlay--hidden"]),
    ];

    div.current.classList.add(...overlayClassNames);
    if (overlayClassName) {
      div.current.classList.add(overlayClassName);
    }

    document.body.classList.add("block-ui");
    overlaysRef.current.appendChild(div.current);

    const timeoutId = setTimeout(() => {
      div.current.classList.remove("modal-overlay--hidden");
    }, 10);

    return () => {
      clearTimeout(timeoutId);
      overlaysRef.current.removeChild(div.current);
      document.body.classList.remove("block-ui");
    };
  }, [overlayClassName, disableTransition]);

  return ReactDOM.createPortal(<WSModalContent {...otherProps} />, div.current);
};

type WSModalContentProps = Omit<WSModalProps, "overlayClassName">;

export function WSModalContent({
  children,
  title,
  description,
  disableTransition,
  closeButtonVariant,
  closeButtonText,
  displayCancelButton,
  confirmButtonLoading,
  confirmButtonVariant,
  confirmButtonDisabled,
  onCancelClick,
  onConfirmClick,
  cancelButtonIcon,
  className,
  onClose,
  closeIcon,
  confirmButtonText,
  customButton,
  scrollModalBodyToBottom,
  scrollModalBodyToBottomCb,
}: WSModalContentProps) {
  const modalBodyRef = useRef<HTMLDivElement>(null);

  const handleScrollModalBodyToBottom = (callback?: () => void) => {
    setTimeout(() => {
      if (!modalBodyRef.current) return;
      modalBodyRef.current.scrollTo({
        top: modalBodyRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 150);

    callback && callback();
  };

  const focusOnFirstInput = () => {
    const input = document.querySelector(
      `.${className} .ws-modal__body input`,
    ) as HTMLInputElement;

    if (input && INPUT_TYPES_TO_FOCUS.includes(input.type)) {
      setTimeout(() => {
        input?.focus();
      }, 300);
    }
  };

  const closeOnEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      const modals = document.querySelectorAll(".modal-overlay .ws-modal");
      const upperModal = modals[modals.length - 1];
      if (modals.length === 1 || upperModal.className.includes(className)) {
        onClose();
      }
    }
  };

  useEffect(() => {
    focusOnFirstInput();
    if (!onClose) return;
    window.addEventListener("keyup", closeOnEscape);
    return () => window.removeEventListener("keyup", closeOnEscape);
  }, []);

  useEffect(() => {
    if (!scrollModalBodyToBottom) return;
    handleScrollModalBodyToBottom(scrollModalBodyToBottomCb);
  }, [scrollModalBodyToBottomCb, scrollModalBodyToBottom]);

  return (
    <div className={classNames("ws-modal", className)}>
      {/* HEADER */}
      {(title || description) && (
        <div className="ws-modal__header">
          {title && <h4 className="ws-modal__title">{title}</h4>}
          {description && (
            <div className="ws-modal__description">{description}</div>
          )}
        </div>
      )}
      {closeIcon && (
        <CloseIcon
          className="modal__close-icon"
          onClick={() => hideModal(onClose, undefined, disableTransition)}
        />
      )}
      {/* BODY */}
      <div className="ws-modal__body" ref={modalBodyRef}>
        {children}
      </div>
      {/* FOOTER */}
      <div className="ws-modal__footer">
        {customButton}
        {displayCancelButton && (
          <Button
            type="button"
            variant={closeButtonVariant ?? "white-with-border"}
            size="large"
            onClick={onCancelClick ?? onClose}
          >
            {cancelButtonIcon}
            {closeButtonText ?? (
              <IDHFormattedMessage id="ws_close" defaultMessage="Close" />
            )}
          </Button>
        )}
        {onConfirmClick && (
          <>
            {confirmButtonLoading ? (
              <Loader />
            ) : (
              <Button
                variant={confirmButtonVariant ?? ButtonVariantDefault}
                size="large"
                type="submit"
                onClick={onConfirmClick}
                disabled={confirmButtonDisabled}
              >
                {confirmButtonText ?? (
                  <IDHFormattedMessage
                    id="ws_confirm"
                    defaultMessage="Confirm"
                  />
                )}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
