import React, {
  DetailedHTMLProps,
  Dispatch,
  ImgHTMLAttributes,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import "./CustomImage.scss";
import classNames from "classnames";
import placeholderIcon from "../../../images/placeholder-image.svg";

interface Props
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  src: string;
  disableImageLoad?: boolean;
  setDisableImageLoad?: Dispatch<SetStateAction<boolean>>;
  fallbackImageSrc?: any;
  fallbackImageClassName?: string;
  onErrorCallback?: () => void;
}

function CustomImage(props: Props) {
  const {
    className,
    alt,
    loading,
    src,
    disableImageLoad,
    setDisableImageLoad,
    onClick,
    fallbackImageSrc,
    fallbackImageClassName,
    onErrorCallback,
  } = props;

  const [newHidden, setNewHidden] = useState(true);
  const [hasError, setHasError] = useState(false);

  const [baseSrc, setBaseSrc] = useState<string>(src);
  const [newSrc, setNewSrc] = useState<string | null>(src);

  const UNHANDLED_VALUES = ["", undefined, null];

  useEffect(() => {
    setNewSrc(src);
  }, [src]);

  const loadNewImage = (imageUrl: string) => {
    if (disableImageLoad && setDisableImageLoad) {
      setDisableImageLoad(true);
    }

    setNewHidden(false);
    setTimeout(() => {
      setBaseSrc(imageUrl);
      setTimeout(
        () => {
          setNewSrc(null);
          setNewHidden(true);
        },
        baseSrc?.length ? 0 : 200,
      );
    }, 200);
  };

  return (
    <div className={classNames("custom-image-wrapper", className)}>
      <img
        className={classNames(
          "custom-image",
          hasError && fallbackImageClassName,
          className,
        )}
        src={
          !UNHANDLED_VALUES.includes(baseSrc)
            ? baseSrc
            : fallbackImageSrc || placeholderIcon
        }
        alt={alt}
        loading={loading}
        onClick={onClick}
      />
      {newSrc && (
        <img
          className={classNames(
            "custom-image",
            "custom-image--new",
            {
              "custom-image--hidden": newHidden,
            },
            className,
          )}
          src={newSrc}
          alt={alt}
          onClick={onClick}
          loading={loading}
          onLoad={() => loadNewImage(src)}
          onError={() => {
            setHasError(true);
            loadNewImage(fallbackImageSrc || placeholderIcon);
            if (onErrorCallback) {
              onErrorCallback();
            }
          }}
        />
      )}
    </div>
  );
}

export default CustomImage;
