import { REGEX_BY_PROVIDER } from "src/types";

const matchProvider = (str) => {
  const regexKeys = Object.keys(REGEX_BY_PROVIDER);

  for (let i = 0; i < regexKeys.length; i++) {
    const match = RegExp(REGEX_BY_PROVIDER[regexKeys[i]]).exec(str);
    if (match) return { provider: regexKeys[i], username: match[1] };
  }

  return false;
};

export const generateCreatorFromString = (string) => {
  // here i should manage conversion from links to insta boii
  const decodedText = decodeURIComponent(string) ?? "";
  const matchedProvider = matchProvider(decodedText);
  if (matchedProvider)
    return {
      username: matchedProvider.username,
      socialProfiles: [
        {
          socialProfileUrl: decodedText,
          socialUrl: decodedText,
          socialProvider: matchedProvider.provider,
          socialUsername: matchedProvider.username,
        },
      ],
    };

  return {
    username: string,
    socialProfiles: [],
  };
};
