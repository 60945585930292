import React, { useEffect, useState } from "react";
import "./SingleRangeBox.scss";

import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { translateMessage } from "src/app/methods/translateMessage";

import CustomInput from "src/app/components/CustomInput/CustomInput";
import { valueExists } from "src/app/methods/valueExists";

export function SingleRangeBox({ col, setWaitEvent }) {
  const intl = useIntl();

  const { filters } = useSelector((state) => state.projectFiltersReducer);

  const [value, setValue] = useState(filters[col.uuid]?.value.from);
  const [value2, setValue2] = useState(filters[col.uuid]?.value.to);

  useEffect(() => {
    (value !== undefined || value2 !== undefined) &&
      (value !== filters[col.uuid]?.value.from ||
        value2 !== filters[col.uuid]?.value.to) &&
      setWaitEvent({
        target: {
          value:
            !valueExists(value) && !valueExists(value2)
              ? undefined
              : { from: value, to: value2 },
        },
      });
  }, [value, value2]);

  return (
    <div className="single-range-box">
      <CustomInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        type="number"
        placeholder={translateMessage({
          intl,
          id: "ws_from",
          defaultMessage: "From",
        })}
      />
      <CustomInput
        value={value2}
        onChange={(e) => setValue2(e.target.value)}
        type="number"
        placeholder={translateMessage({
          intl,
          id: "ws_to",
          defaultMessage: "To",
        })}
      />
    </div>
  );
}
