import React from "react";

import {
  ContentProposalElementType,
  IContentProposalElementModerationComment,
} from "src/redux/comment/types";
import { IMember } from "src/app/project/ProjectTypes";
import { ReactComponent as ReplyIcon } from "src/images/reply.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { getPreviewFileType } from "src/app/metafields/FileField/FilesDropdown";
import { getExtensionFromName } from "src/utils/methods";
import CommentWrapper from "./CommentWrapper";
import { ContentProposalElementStatusBadge } from "../../components/ContentProposalField/AcceptedRejectedContentHeader";
import XssFilter from "../../../components/XssFilter/XssFilter";
import CommentText, { decodeComment } from "./CommentText";
import ReplyToCommentButton from "./ReplyToCommentButton";

import "./ContentProposalElementModerationComment.scss";

export const renderContentProposalElementsModeration = (
  comment: IContentProposalElementModerationComment,
) => {
  return comment.contentProposalElements.map((element) => {
    if (element.type === ContentProposalElementType.Caption) {
      return (
        <div
          className="content-proposal-moderation-comment-body__caption"
          key={element.uuid}
        >
          <div
            className="content-proposal-comment__caption"
            dangerouslySetInnerHTML={{
              __html: XssFilter(element.caption, ["br"]),
            }}
          />
          <ContentProposalElementStatusBadge status={element.status} />
        </div>
      );
    }
    if (
      element.type === ContentProposalElementType.Media ||
      element.type === ContentProposalElementType.ExternalUrl
    ) {
      const isExternalLink =
        element.type === ContentProposalElementType.ExternalUrl;
      return (
        <div
          className="content-proposal-media-element-wrapper"
          key={element.uuid}
        >
          {getPreviewFileType(
            getExtensionFromName(isExternalLink ? "url" : element.name),
            element,
          )}
          <ContentProposalElementStatusBadge status={element.status} />
        </div>
      );
    }

    return null;
  });
};

interface ContentProposalElementModerationCommentProps {
  comment: IContentProposalElementModerationComment;
  commentAuthor: IMember | undefined;
  membersList: IMember[];
  handleScrollToComment: () => void;
  hasAccessToRemoveWsTaskComment: boolean;
}

export default function ContentProposalElementModerationComment({
  comment,
  commentAuthor,
  membersList,
  handleScrollToComment,
  hasAccessToRemoveWsTaskComment,
}: ContentProposalElementModerationCommentProps) {
  return (
    <CommentWrapper
      comment={comment}
      commentId={comment.uuid}
      commentAuthor={commentAuthor}
      commentAuthorCustomElement={
        <div
          className="comment-header-element__action-info comment-header-element__action-info--scroll"
          onClick={handleScrollToComment}
        >
          <ReplyIcon />
          <IDHFormattedMessage
            id="ws_moderated_content_proposal"
            defaultMessage="moderated content proposal"
          />
        </div>
      }
      hasAccessToRemoveWsTaskComment={hasAccessToRemoveWsTaskComment}
    >
      <div className="content-proposal-moderation-comment-body">
        {renderContentProposalElementsModeration(comment)}
        {comment.comment?.length > 0 && (
          <CommentText>
            <span
              className="mentionText"
              dangerouslySetInnerHTML={{
                __html: decodeComment(comment.comment, membersList),
              }}
            />
          </CommentText>
        )}
        <ReplyToCommentButton
          wsTaskUuid={comment.wsTaskUuid}
          parentWsTaskCommentUuid={comment.uuid}
          replyToCommentText={comment.comment}
          replyToCommentContent={comment.contentProposalElements}
        />
      </div>
    </CommentWrapper>
  );
}
