import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import axios from "axios";

import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalColumn } from "src/app/components/Modal/Modal";
import { ExtensionTypeEnum, RecruitmentFormDetails } from "src/types";
import { CoverImageUploader } from "src/app/components/CoverImageUploader/CoverImageUploader";
import { showToast } from "src/app/methods/showToast";
import { API_URLS } from "src/utils/API_URLS";
import { TextBoxWrapper } from "src/app/components/TextBoxWrapper/TextBoxWrapper";
import {
  WorkspacePermissionsEnum,
  ProjectPermissionsEnum,
} from "src/utils/PermissionsEnums";
import { showErrorToast } from "src/utils/methods";
import { uploadExtensionCover } from "../Buttons/uploadExtensionCover";
import { RecruitmentFormUserData } from "../../types";

const INPUT_FIELD_CALLBACK_DELAY = 2000;

interface RecruitmentFormGeneralProps {
  userData: RecruitmentFormUserData;
  setUserData: Dispatch<SetStateAction<RecruitmentFormUserData>>;
  coverImageFile: File | null;
  setNewImage: (file: File) => void;
  setCoverImageFile: Dispatch<SetStateAction<File | null>>;
  recruitmentFormDetails: RecruitmentFormDetails;
  contextData: any;
  extensionUuid?: string;
  extensionType: ExtensionTypeEnum;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isCoverPhotoRemoved: boolean;
  setIsCoverPhotoRemoved: Dispatch<SetStateAction<boolean>>;
}

export function RecruitmentFormGeneral({
  userData,
  setUserData,
  coverImageFile,
  setNewImage,
  setCoverImageFile,
  recruitmentFormDetails,
  contextData,
  extensionUuid,
  extensionType,
  setIsLoading,
  isCoverPhotoRemoved,
  setIsCoverPhotoRemoved,
}: RecruitmentFormGeneralProps) {
  const [showUploadLogoModal, setShowUploadLogoModal] =
    useState<boolean>(false);
  const [coverImageUrl, setCoverImageUrl] = useState<string | null>(null);
  const [caption, setCaption] = useState(
    userData.recruitmentFormMetaFields.caption,
  );
  const [description, setDescription] = useState(
    userData.recruitmentFormMetaFields.description,
  );

  const handleUpdateCaptionOrDescription = async (
    wsExtensionUuid: string,
    type: "caption" | "description",
    value: string,
  ) => {
    try {
      const result = await axios.put(
        API_URLS.updateExtensionDetails.replace(
          ":wsExtensionUuid:",
          wsExtensionUuid,
        ),
        {
          ...userData,
          recruitmentFormMetaFields: {
            ...userData.recruitmentFormMetaFields,
            [type]: value,
          },
          wsAccessLinkCover: null,
        },
      );
      if (result.statusText === "OK") {
        showToast("success", "Success", "Updated form");
      }
    } catch (err) {
      showToast("error", "Error", "Cannot change form");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadCoverImage = async (
    wsExtensionUuid: string,
    recruitmentFormUuid: string,
    file: File,
  ) => {
    let realName;
    let updatedUserData = userData;

    try {
      realName = await uploadExtensionCover(
        file,
        contextData.workspaceUuid,
        recruitmentFormUuid,
      );
      if (realName) {
        updatedUserData = {
          ...userData,
          wsExtensionType: extensionType,
          wsAccessLinkCover: {
            mimeType: file.type,
            name: file.name,
            parentUuid: recruitmentFormUuid,
            realName,
            size: file.size,
          },
        };
        await axios.put(
          API_URLS.updateExtensionDetails.replace(
            ":wsExtensionUuid:",
            wsExtensionUuid,
          ),
          {
            ...updatedUserData,
          },
        );
      }
    } catch (err) {
      console.error(err);
      showErrorToast();
    }
  };

  const handleDeleteRecruitmentFormCover = async (
    recruitmentFormUuid: string,
  ) => {
    try {
      await axios.delete(
        `${API_URLS.deleteRecruitmentFormCover.replace(
          ":wsExtensionUuid:",
          recruitmentFormUuid,
        )}?wsExtensionType=${recruitmentFormDetails.type}`,
      );
    } catch (err) {
      console.error(err);
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_something_went_wrong"
          defaultMessage="Something went wrong!"
        />,
      );
    }
  };

  useEffect(() => {
    setUserData((prev) => {
      let wsAccessLinkCover = isCoverPhotoRemoved
        ? null
        : recruitmentFormDetails.wsAccessLinkCover;
      if (coverImageFile) {
        wsAccessLinkCover = {
          parentUuid: "",
          name: coverImageFile.name,
          mimeType: coverImageFile.type,
          realName: "",
          size: coverImageFile.size,
        };
      }
      return {
        ...prev,
        recruitmentFormMetaFields: {
          ...prev.recruitmentFormMetaFields,
          caption,
          description,
        },
        wsAccessLinkCover,
      };
    });

    if (
      !contextData?.permissions?.project?.includes(
        ProjectPermissionsEnum.MANAGE_PROJECT_CONFIGURATION,
      ) &&
      !contextData?.permissions?.workspace?.includes(
        WorkspacePermissionsEnum.CREATOR_DATABASE_CONFIGURATION,
      )
    )
      return;
    if (!extensionUuid) return;
    if (
      caption === userData.recruitmentFormMetaFields.caption &&
      description === userData.recruitmentFormMetaFields.description
    )
      return;

    const changedMetaFieldName =
      caption !== userData.recruitmentFormMetaFields.caption
        ? "caption"
        : "description";

    setIsLoading(true);

    const timerId = setTimeout(() => {
      handleUpdateCaptionOrDescription(
        extensionUuid,
        changedMetaFieldName,
        changedMetaFieldName === "caption" ? caption : description,
      );
    }, INPUT_FIELD_CALLBACK_DELAY);

    return () => {
      clearTimeout(timerId);
      setIsLoading(false);
    };
  }, [
    caption,
    description,
    coverImageFile,
    isCoverPhotoRemoved,
    recruitmentFormDetails,
    extensionUuid,
    contextData,
  ]);

  useEffect(() => {
    if (coverImageFile) {
      setCoverImageUrl(URL.createObjectURL(coverImageFile));
      return;
    }
    if (typeof userData.wsAccessLinkCover === "string") {
      setCoverImageUrl(userData.wsAccessLinkCover);
    }
    if (isCoverPhotoRemoved) {
      setCoverImageUrl(null);
    }
  }, [coverImageFile, userData.wsAccessLinkCover, isCoverPhotoRemoved]);

  useEffect(() => {
    setCaption(userData.recruitmentFormMetaFields.caption);
    setDescription(userData.recruitmentFormMetaFields.description);
  }, [userData]);

  return (
    <>
      <div className="recruitment-form__description">
        <IDHFormattedMessage
          id="ws_recruitment_form_general_description"
          defaultMessage="Create a form that you can then share with people outside of the workspace. Add a brand logo, title and description that will help those you share it with understand the context and needs."
        />
      </div>
      <div className="recruitment-form__general-wrapper">
        <ModalColumn>
          <div className="recruitment-form__title">
            <IDHFormattedMessage
              id="ws_form_cover"
              defaultMessage="Form cover"
            />
          </div>
          <CoverImageUploader
            showUploadLogoModal={showUploadLogoModal}
            uploadFunction={(file: File) => {
              setNewImage(file);
              if (
                contextData?.permissions?.project?.includes(
                  ProjectPermissionsEnum.MANAGE_PROJECT_CONFIGURATION,
                ) ||
                contextData?.permissions?.workspace?.includes(
                  WorkspacePermissionsEnum.CREATOR_DATABASE_CONFIGURATION,
                )
              ) {
                if (extensionUuid && userData.recruitmentFormUuid) {
                  handleUploadCoverImage(
                    extensionUuid,
                    userData.recruitmentFormUuid,
                    file,
                  );
                }
              }
              setIsCoverPhotoRemoved(false);
            }}
            closeModal={() => setShowUploadLogoModal(false)}
            coverImageUrl={coverImageUrl}
            openModal={() => setShowUploadLogoModal(true)}
            removeImage={() => {
              if (
                userData.recruitmentFormUuid &&
                (contextData?.permissions?.project?.includes(
                  ProjectPermissionsEnum.MANAGE_PROJECT_CONFIGURATION,
                ) ||
                  contextData?.permissions?.workspace?.includes(
                    WorkspacePermissionsEnum.CREATOR_DATABASE_CONFIGURATION,
                  ))
              ) {
                setIsCoverPhotoRemoved(true);
                handleDeleteRecruitmentFormCover(userData.recruitmentFormUuid);
                setCoverImageUrl(null);
                setCoverImageFile(null);
              }
            }}
          />
        </ModalColumn>
        <ModalColumn>
          <div className="recruitment-form__title">
            <IDHFormattedMessage id="ws_form_info" defaultMessage="Form info" />
          </div>
          <CustomInputLabel htmlFor="title">
            <IDHFormattedMessage id="ws_title" defaultMessage="Title" />
          </CustomInputLabel>
          <CustomInput
            id="title"
            type="text"
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
          />
        </ModalColumn>
        <ModalColumn>
          <CustomInputLabel htmlFor="description">
            <IDHFormattedMessage
              id="ws_description"
              defaultMessage="Description"
            />
          </CustomInputLabel>

          <TextBoxWrapper
            id="recruitment-form-textbox"
            className="ws-input recruitment-form__text-area"
            value={description || ""}
            onChange={setDescription}
            placeholder=""
          />
        </ModalColumn>
      </div>
    </>
  );
}
