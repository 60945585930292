import React, { useState } from "react";
import {
  CreatorShowcaseContext,
  openCreatorShowcase,
  OpenCreatorShowcaseProps,
} from "src/app/CreatorShowcase/utils";
import { RootStateOrAny, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ReactComponent as BinIcon } from "src/images/trash-can.svg";
import { ReactComponent as SettingsIcon } from "src/images/settings.svg";
import { ReactComponent as LinkIcon } from "src/images/link.svg";
import { ReactComponent as ShowcaseIcon } from "src/images/showcase.svg";
import { copyLink } from "src/utils/methods";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "../../Dropdown/Dropdown";
import { Button } from "../../Button/Button";
import IDHFormattedMessage from "../../IDHFormattedMessage/IDHFormattedMessage";

interface Props {
  showcaseData?: OpenCreatorShowcaseProps;
  linkToCopy?: string;
  removeData?: {
    objectNames: string[];
    removeFunction: () => void;
  };
}

export function PreviewSettingsDropdown(props: Props) {
  const { showcaseData, linkToCopy, removeData } = props;

  const [showRemoveOptionModal, setShowRemoveOptionModal] = useState(false);

  return (
    <>
      <Dropdown toRight>
        {(isOpen, setIsOpen) => (
          <>
            <Button
              variant="light-grey"
              size="square-small"
              onClick={() => setIsOpen(true)}
            >
              <SettingsIcon />
            </Button>

            <DropdownMenu isOpen={isOpen}>
              {showcaseData && (
                <DropdownMenuItem
                  onClick={() => openCreatorShowcase(showcaseData)}
                >
                  <ShowcaseIcon />
                  <IDHFormattedMessage
                    id="front_generate_showcase"
                    defaultMessage="Generate showcase"
                  />
                </DropdownMenuItem>
              )}

              {linkToCopy && (
                <DropdownMenuItem onClick={() => copyLink(linkToCopy)}>
                  <LinkIcon />
                  <IDHFormattedMessage
                    id="front_copy_link"
                    defaultMessage="Copy link"
                  />
                </DropdownMenuItem>
              )}

              {removeData && (
                <DropdownMenuItem
                  className="dropdown__menu-item--danger"
                  onClick={() => setShowRemoveOptionModal(true)}
                >
                  <BinIcon />
                  <IDHFormattedMessage
                    id="front_remove"
                    defaultMessage="Remove"
                  />
                </DropdownMenuItem>
              )}
            </DropdownMenu>
          </>
        )}
      </Dropdown>

      {showRemoveOptionModal && removeData && (
        <RemoveModal
          onClose={() => setShowRemoveOptionModal(false)}
          objectNames={removeData.objectNames}
          removeFunction={removeData.removeFunction}
        />
      )}
    </>
  );
}
