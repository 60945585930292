import { loadImage } from "../../../utils/methods";

export const loadAllTemplateImages = async (element, setImagesLoaded) => {
  const template = await document.querySelector(element).cloneNode(true);

  const images = [].slice.call(template.getElementsByTagName("img"));

  if (images.length) {
    await Promise.all(
      images.map((image) => {
        const src = image.src.replace(":3003", "");
        loadImage(src);
      }),
    )
      .then(() => {
        setImagesLoaded(true);
      })
      .catch((err) => {});
  } else {
    setImagesLoaded(true);
  }
};
