import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import EmptyActionClientImg from "src/images/empty-state-client.svg";

function ClientEmptyTable() {
  return (
    <>
      <div className="empty-table__left">
        <div className="empty-table__text empty-table__text-client">
          <div className="empty-table__empty-client-text">
            <IDHFormattedMessage
              id="ws_empty_table_text_client"
              defaultMessage="You have nothing to do"
            />
          </div>
        </div>
        <div className="empty-table__empty-client-bottom-text">
          <IDHFormattedMessage
            id="ws_empty_table_bottom_text_client"
            defaultMessage="Patiently wait for the tasks shared to you to appear."
          />
        </div>
      </div>
      <div className="empty-table__right">
        <img src={EmptyActionClientImg} className="empty-table__img" />
      </div>
    </>
  );
}

export default ClientEmptyTable;
