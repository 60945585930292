import React, { useState } from "react";
import "./MaterialTooltip.scss";

import { Tooltip as ReactTooltip } from "react-tooltip";

import { uuidv7 as uuid } from "uuidv7";
import classNames from "classnames";
import TooltipSign from "../TooltipSign/TooltipSign";

export function MaterialTooltip({
  id,
  children,
  content,
  contentHidden,
  center,
  clickable,
  className,
  contentClassName,
}) {
  const tooltipId = id || uuid();
  const [isHover, setIsHover] = useState(false);
  return (
    <>
      <div
        data-tooltip-id={tooltipId}
        data-tooltip-place="bottom"
        className={classNames(["material-tooltip__toggle", className])}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {children || <TooltipSign blue={isHover} />}
      </div>
      {!contentHidden && (
        <ReactTooltip
          id={tooltipId}
          clickable={clickable}
          className={classNames([
            "material-tooltip",
            contentClassName,
            center && "material-tooltip--center",
          ])}
        >
          {content}
        </ReactTooltip>
      )}
    </>
  );
}
