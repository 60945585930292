import { API_URLS } from "src/utils/API_URLS";
import {
  GET_SELECT_DATA_SET_OPTIONS,
  SET_SELECT_DATA_SET_OPTIONS_LOADING,
} from "./selectDataSetTypes";

const getSelectDataSetOptions = (wsSelectDataSetUuid: string) => {
  const url = API_URLS.getSelectDataSetOptions.replace(
    ":wsSelectDataSetUuid:",
    wsSelectDataSetUuid,
  );
  return {
    type: GET_SELECT_DATA_SET_OPTIONS,
    payload: {
      request: { url },
    },
  };
};

const setSelectDataSetOptionsLoading = (
  selectDataSetOptionsLoading: boolean,
) => {
  return {
    type: SET_SELECT_DATA_SET_OPTIONS_LOADING,
    payload: { selectDataSetOptionsLoading },
  };
};

export { getSelectDataSetOptions, setSelectDataSetOptionsLoading };
