import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { tableDataType } from "src/app/components/Table/Table";
import { showToast } from "src/app/methods/showToast";

export const showValueIsRequiredError = () => {
  showToast(
    "info",
    <IDHFormattedMessage id="ws_info" defaultMessage="Info" />,
    <IDHFormattedMessage
      id="ws_this_field_is_required"
      defaultMessage="This field is required, and must not remain empty"
    />,
  );
};

export const isMetaFieldRemovable = (
  dataType: tableDataType,
  valueRequired?: boolean,
): boolean => {
  if (dataType === tableDataType.GlobalMetaFieldProjectWizardFields) {
    return false;
  }
  return !valueRequired;
};
