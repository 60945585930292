import React, { useState } from "react";
import "./Attachments.scss";

import { useSelector } from "react-redux";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RootState } from "src/redux/reducers";
import { ReactComponent as AttachmentTransparentIcon } from "../../../images/attachment-transparent-blue.svg";
import SingleAttachment, { AttachmentMetaData } from "./Attachment";
import { TaskType, AttachmentPlacement } from "../../../types";
import AttachmentLoader from "./AttachmentLoader";

interface Props {
  attachmentsCount: number;
  attachments: AttachmentMetaData[];
  taskType: TaskType;
}

function Attachments(props: Props) {
  const { attachmentsCount, attachments, taskType } = props;
  const [allAttachmentsVisible, setAllAttachmentsVisible] = useState(false);
  const {
    attachmentReducer: { attachmentLoading, loadingFileName },
  } = useSelector((state: RootState) => state);

  const toggleAttachmentsVisibility = () => {
    setAllAttachmentsVisible((prev) => !prev);
  };

  const onAddAttachmentClick = () => {
    // For testing purposes only. We need to create reusable method or class fo this
    const attachmentIcon = document.querySelector<HTMLDivElement>(
      ".add-comment-field__add-attachment",
    );

    if (attachmentIcon !== null) {
      attachmentIcon.click();
    }
  };

  return (
    <div className="attachments-container">
      {attachmentsCount > 6 && (
        <div
          className="attachment-square"
          onClick={toggleAttachmentsVisibility}
        >
          <div>
            {allAttachmentsVisible ? (
              <IDHFormattedMessage id="ws_hide" defaultMessage="Hide" />
            ) : (
              <IDHFormattedMessage id="ws_show_all" defaultMessage="Show all" />
            )}
          </div>
        </div>
      )}

      {allAttachmentsVisible
        ? attachments.map((attachment) => (
            <SingleAttachment
              data={attachment}
              key={attachment.id}
              place={AttachmentPlacement.Attachments}
              taskType={taskType}
            />
          ))
        : attachments
            .slice(0, 6)
            .map((attachment) => (
              <SingleAttachment
                data={attachment}
                key={attachment.id}
                place={AttachmentPlacement.Attachments}
                taskType={taskType}
              />
            ))}

      {attachmentLoading && <AttachmentLoader fileName={loadingFileName} />}
      <div
        className="attachment-square add-attachment-square"
        onClick={onAddAttachmentClick}
      >
        <AttachmentTransparentIcon className="attachment-square__add-icon" />
        <IDHFormattedMessage
          id="ws_add_attachment"
          defaultMessage="Add Attachment"
        />
      </div>
    </div>
  );
}

export default Attachments;
