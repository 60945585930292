export const addHeaderToArray = (
  text,
  array,
  type,
  section,
  savedTemplate,
  templateApplied
) => {
  const header = {
    text,
    label: `${type}-${section}`,
    gridBoxType: type,
    visible: true,
    section,
  };

  if (savedTemplate?.length && !templateApplied) {
    if (savedTemplate.find((item) => item.i === header.label)) {
      array.push(header);
    }
  } else {
    array.push(header);
  }
};
