import React, { useEffect, useState, forwardRef, RefObject } from "react";
import { PercentInput } from "src/app/components/MaskedInputs/PercentInput";
import { SeperatedNumberInput } from "src/app/components/MaskedInputs/SeperatedNumberInput";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import useOnClickOutside from "src/app/methods/useOnClickOutside";

interface ValueInputWrapperProps {
  value: string;
  onChange: (newValue: string) => void;
  onBlur?: (newValue: string) => void;
  onFocus?: () => void;
  type: MetaFieldType.Number | MetaFieldType.Currency | MetaFieldType.Percent;
  handleUseOnClickOutside?: () => void;
  readOnly: boolean;
}

export const ValueInputWrapper = forwardRef<
  HTMLInputElement,
  ValueInputWrapperProps
>(
  (
    {
      value,
      onChange,
      onBlur,
      onFocus,
      type,
      handleUseOnClickOutside,
      readOnly,
    },
    ref,
  ) => {
    const [localValue, setLocalValue] = useState("");

    const refObject = ref as RefObject<HTMLInputElement>;

    useOnClickOutside(refObject, () => {
      if (handleUseOnClickOutside) {
        handleUseOnClickOutside();
        if (refObject.current) {
          refObject.current.blur();
        }
      }
    });

    useEffect(() => {
      setLocalValue(value);
    }, [value]);

    const handleBlur = () => {
      if (onBlur) {
        onBlur(localValue);
      }
      onChange(localValue);
    };
    switch (type) {
      case MetaFieldType.Number:
      case MetaFieldType.Currency:
        return (
          <SeperatedNumberInput
            inputRef={refObject}
            className="value-input"
            rawValue={localValue}
            setRawValue={setLocalValue}
            onBlur={handleBlur}
            onFocus={onFocus}
            readOnly={readOnly}
          />
        );
      case MetaFieldType.Percent:
        return (
          <PercentInput
            inputRef={refObject}
            className="value-input"
            rawValue={localValue}
            setRawValue={setLocalValue}
            onBlur={handleBlur}
            onFocus={onFocus}
            readOnly={readOnly}
          />
        );
      default:
        return null;
    }
  },
);
