import React, { useEffect, useState } from "react";
import "./RelatedField.scss";

import axios from "axios";
import { useIntl } from "react-intl";

import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

import { useSelector } from "react-redux";
import { API_URLS } from "src/utils/API_URLS";
import {
  capitalizeFirstLetter,
  isStringNumeric,
  showErrorToast,
} from "src/utils/methods";
import { RootState } from "src/redux/reducers";
import { TasksColumn } from "src/redux/task/taskReducer";
import { SelectOption } from "../../SocialMetrics";
import { MFState, MFStateEntries } from "../../types";
import { MetaFieldType } from "../../../../methods/getMetaFieldTypeOptions";

const ALLOWED_ELEMENT_TYPES = [
  MetaFieldType.Number,
  MetaFieldType.Percent,
  MetaFieldType.Currency,
  MetaFieldType.Date,
];

const aggregationTypeOptions = [
  {
    label: (
      <IDHFormattedMessage
        id="ws_related_field_aggregation_first"
        defaultMessage="First match"
      />
    ),
    value: "first",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_related_field_aggregation_sum"
        defaultMessage="Sum values"
      />
    ),
    value: "sum",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_related_field_aggregation_avg"
        defaultMessage="Values average"
      />
    ),
    value: "avg",
  },
  {
    label: (
      <IDHFormattedMessage
        id="ws_related_field_aggregation_count"
        defaultMessage="Count items"
      />
    ),
    value: "count",
  },
];

interface Props {
  state: MFState;
  setState: (type: MFStateEntries, value: any) => void;
  projectId: string;
  source?: string;
  disabled?: boolean;
  isGlobalMode?: boolean;
  currentRelatedMetaFieldAggregationType?: string | null;
}

export const RelatedField: React.FC<Props> = (props) => {
  const {
    state: { relatedMetaField, relatedMetaFieldAggregationType },
    setState,
    projectId,
    source,
    disabled,
    isGlobalMode,
    currentRelatedMetaFieldAggregationType,
  } = props;

  const [fields, setFields] = useState<TasksColumn[]>();
  const [options, setOptions] = useState<SelectOption[]>([]);

  const {
    projectReducer: { taskType },
    settingsReducer: { globalTaskMetaFields },
  } = useSelector((state: RootState) => state);

  const intl = useIntl();

  const getMetaFields = () => {
    if (isGlobalMode) {
      const globalFields = globalTaskMetaFields
        ?.filter((element) => element.taskType)
        ?.map((element) => {
          // For example, multiply each element by 2
          return {
            metaFieldId: element.uuid,
            metaFieldName: element.name,
            metaFieldKey: element.origin,
            metaFieldType: element.type,
            metaFieldVisible: true,
            metaFieldRank: element.rank,
            metaFieldTaskType: element.taskType,
            metaFieldIsRequired: element.isRequired,
            metaFieldIsSystem: true,
            metaFieldShared: false,
            data: element.data,
            metaFieldValueSource: element.valueSource,
            metaFieldSource: null,
            metaFieldGlobalTaskMetaFieldUuid: element.uuid,
          };
        });
      setFields(globalFields);
    } else {
      const url = API_URLS.getAllTaskMetaFieldsForProject.replace(
        ":wsProjectUuid:",
        projectId,
      );

      axios
        .get(url)
        .then((response) => {
          const {
            data: { content },
          } = response;

          setFields(content);
        })
        .catch((error) => {
          console.error(error);
          showErrorToast();
        });
    }
  };

  useEffect(() => {
    getMetaFields();
  }, []);

  useEffect(() => {
    setState("relatedMetaField", {});
    const newFields = fields
      ?.filter(
        (item) =>
          ALLOWED_ELEMENT_TYPES.includes(item.metaFieldType) &&
          item.metaFieldTaskType !== taskType,
      )
      .map((item) => ({
        label: `${capitalizeFirstLetter(item.metaFieldTaskType)} > ${
          item.metaFieldName
        }`,
        value: {
          id: item.metaFieldId,
          name: item.metaFieldName,
          currencyCode: item?.data?.currencyCode,
        },
      }));

    setOptions(newFields || []);
  }, [fields]);

  useEffect(() => {
    if (!source || relatedMetaField.value || !options?.length) {
      const selectedRelatedAggregationType = aggregationTypeOptions.find(
        (o) => o.value === "first",
      );

      if (selectedRelatedAggregationType?.value) {
        setState(
          "relatedMetaFieldAggregationType",
          selectedRelatedAggregationType,
        );
      }

      return;
    }

    const relatedUuid = source.split(".")[2];
    const selectedOption = options?.find((o) => o.value.id === relatedUuid);

    if (selectedOption?.value) {
      setState("relatedMetaField", selectedOption);

      const selectedRelatedAggregationType = aggregationTypeOptions.find(
        (o) => o.value === (currentRelatedMetaFieldAggregationType ?? "first"),
      );

      if (selectedRelatedAggregationType?.value) {
        setState(
          "relatedMetaFieldAggregationType",
          selectedRelatedAggregationType,
        );
      }
    }
  }, [options, source]);

  return (
    <div className="related-field">
      <div className="row-wrapper">
        <div className="modal__column">
          <CustomInputLabel>
            <IDHFormattedMessage
              id="ws_related_column"
              defaultMessage="Related column"
            />
          </CustomInputLabel>

          <CustomSelect
            value={relatedMetaField}
            onChange={(newValue: SelectOption) =>
              setState("relatedMetaField", newValue)
            }
            options={options}
            isSearchable
            placeholder={intl.formatMessage({
              id: "ws_select_field",
              defaultMessage: "Select field",
            })}
            isValidNewOption={(inputValue: string) =>
              isStringNumeric(inputValue)
            }
            disabled={disabled}
          />
        </div>
        <div className="modal__column">
          <CustomInputLabel>
            <IDHFormattedMessage
              id="ws_related_column_aggregation_type"
              defaultMessage="Aggregation column type"
            />
          </CustomInputLabel>

          <CustomSelect
            value={relatedMetaFieldAggregationType}
            onChange={(newValue: SelectOption) =>
              setState("relatedMetaFieldAggregationType", newValue)
            }
            options={aggregationTypeOptions}
            placeholder={intl.formatMessage({
              id: "ws_select_aggregation_type_field",
              defaultMessage: "Select aggregation type",
            })}
            isValidNewOption={(inputValue: string) =>
              isStringNumeric(inputValue)
            }
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};
