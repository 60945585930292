import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";

import { ReactComponent as TickIcon } from "src/images/tick.svg";
import CustomInput from "src/app/components/CustomInput/CustomInput";
import { DropdownPopper } from "src/app/components/DropdownPopper/DropdownPopper";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import { setProjectSortBy } from "src/redux";
import { translateMessage } from "src/app/methods/translateMessage";
import { searchObjectsByName } from "src/app/components/DictionarySortDropdown/DictionarySortDropdown";
import SortFieldsButton from "src/app/dropdowns/SortFieldsDropdown/SortFieldsButton";

import "./SortProjectsDropdown.scss";

function SortProjectsDropdown({ fields = [], intl }) {
  const [displayOptions, setDisplayOptions] = useState([]);
  const [value, setValue] = useState("");

  const dispatch = useDispatch();

  const {
    projectFiltersReducer: { sortBy, hiddenColumns },
  } = useSelector((state) => state);

  const dropdownRef = useRef();

  const foundActiveSortingColumn = displayOptions.find(
    (option) => option.uuid === sortBy,
  );

  useEffect(() => {
    const filteredFields = fields.filter(
      (c) => !hiddenColumns.includes(c.uuid),
    );
    if (!value) {
      setDisplayOptions(filteredFields);
    } else {
      const searchResults = searchObjectsByName(value, filteredFields);
      setDisplayOptions(searchResults);
    }
  }, [value, fields]);

  return (
    <DropdownPopper
      overlay={
        <DropdownMenu isOpen className="sort-projects-dropdown__menu">
          <CustomInput
            type="text"
            placeholder={translateMessage({
              intl,
              id: "ws_enter_field_name",
              defaultMessage: "Enter field name",
            })}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <div className="dropdown__menu-list">
            {displayOptions.map((f) => {
              const active = sortBy === f.uuid;
              return (
                <DropdownMenuItem
                  key={f.uuid}
                  onClick={() => {
                    dispatch(setProjectSortBy(active ? null : f.uuid));
                    dropdownRef.current.forceVisible(false);
                  }}
                >
                  {f.name}
                  {active && <TickIcon />}
                </DropdownMenuItem>
              );
            })}
          </div>
        </DropdownMenu>
      }
      ref={dropdownRef}
      placement="bottom-end"
    >
      <SortFieldsButton
        active={typeof sortBy === "string" && sortBy !== ""}
        activeSortingColumnName={foundActiveSortingColumn?.name}
      />
    </DropdownPopper>
  );
}

export default injectIntl(SortProjectsDropdown);
