import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as GendersIcon } from "../../../../../images/profile-data/genders.svg";
import { mapObjectToKeyPairArray } from "../../../../../utils/methods";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";
import { ProgressBarList } from "../components/ProgressBarList/ProgressBarList";

export function AudienceAgeAndGender(props) {
  const { audienceAgeGroups, height, onProjectDetails } = props;

  const title = height ? (
    <IDHFormattedMessage id="ws_audience_age" defaultMessage="Audience age" />
  ) : onProjectDetails ? (
    <IDHFormattedMessage id="ws_age" defaultMessage="Age" />
  ) : (
    <IDHFormattedMessage
      id="ws_age_gender"
      defaultMessage="Audience age & gender"
    />
  );

  return (
    <ProfileBox onProjectDetails={onProjectDetails}>
      <PBHeader
        icon={<GendersIcon />}
        title={title}
        onShowcase={height}
        onProjectDetails={onProjectDetails}
      />
      {!height && (
        <div className="profile-box__heading">
          <IDHFormattedMessage id="ws_age_group" defaultMessage="Age group" />

          <Tooltip
            center
            content={
              <IDHFormattedMessage
                id="ws_tooltip_audience_data_gender"
                defaultMessage="Share of followers broken down by age groups"
              />
            }
          />
        </div>
      )}
      {audienceAgeGroups && (
        <ProgressBarList
          data={mapObjectToKeyPairArray(audienceAgeGroups, "age")}
          barColor="#7848C7"
          backgroundColor="rgba(120, 72, 199, 0.1)"
          height={height}
        />
      )}
    </ProfileBox>
  );
}
