import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { LiveSearchPost } from "../../HashtagLiveSearch";
import { LiveSearchBox } from "../LiveSearchBox/LiveSearchBox";

interface Props {
  posts: LiveSearchPost[];
}

export const PostList: React.FC<Props> = (props) => {
  const { posts } = props;

  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setHidden(false);
    }, 15);
  }, []);

  return (
    <div
      className={classNames("hashtag-live-search__results", {
        "hashtag-live-search__results--hidden": hidden,
      })}
    >
      {posts.map((post) => (
        <LiveSearchBox key={post.postUrl} data={post} />
      ))}
    </div>
  );
};
