import classNames from "classnames";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { RootState } from "src/redux/reducers";
import { getFullDate, getImageSource } from "../../../utils/methods";
import { getDateLabel } from "../../methods/getDateLabel";

import AvatarPlaceholder from "../../../images/avatar-purple.svg";
import ContentPlaceholder from "../../../images/content-placeholder.svg";
import CustomImage from "../../components/CustomImage/CustomImage";

interface Props {
  columnType: string;
  // colData: {
  //   value: string;
  //   options: { singleSelectOptions: SingleSelectOption[] };
  //   name: string;
  // };
  colData: any;
  values: any;
}

export const BoardColumnLabel = (props: Props) => {
  const { columnType, colData, values } = props;

  const intl = useIntl();

  const {
    projectReducer: { membersList },
    taskReducer: {
      contentAutocomplete,
      creatorAutocomplete,
      publicationAutocomplete,
    },
  } = useSelector((state: RootState) => state);

  const getColumnLabel = () => {
    switch (columnType) {
      case MetaFieldType.Member:
        const memberData = membersList.find(
          (member) => member.id === colData.value,
        );
        if (!memberData) return null;
        return (
          <span className="board-column__label">
            <CustomImage
              className="board-column__label-image"
              src={memberData.avatarUrl}
              alt="avatar"
              loading="lazy"
            />
            <span className="board-column__label-text">{memberData.name}</span>
          </span>
        );

      case MetaFieldType.Date:
        const formattedValue = getDateLabel(
          "en-EN",
          moment(colData.value),
          false,
          intl,
        );

        return (
          <span
            className={classNames(
              "board-column__label",
              formattedValue !== null ? formattedValue.style : null,
            )}
          >
            {typeof formattedValue?.date === "string"
              ? formattedValue?.date
              : getFullDate(formattedValue?.date.toDate())}
          </span>
        );

      case MetaFieldType.Creator:
        const creatorData = creatorAutocomplete?.find(
          (creator: { taskId: string }) => creator.taskId === colData.value,
        );

        return (
          <span className="board-column__label">
            <CustomImage
              className="board-column__label-image"
              src={getImageSource(
                creatorData?.cover,
                "tiny",
                AvatarPlaceholder,
              )}
              alt="avatar"
              loading="lazy"
            />
            <span className="board-column__label-text">
              {creatorData?.title}
            </span>
          </span>
        );

      case MetaFieldType.Content:
        const contentData = contentAutocomplete?.find(
          (content: { taskId: string }) => content.taskId === colData.value,
        );

        return (
          <span className="board-column__label">
            <CustomImage
              className={classNames(
                "board-column__label-image",
                "board-column__label-image--content",
              )}
              src={getImageSource(
                contentData?.cover,
                "small",
                ContentPlaceholder,
              )}
              alt="avatar"
              loading="lazy"
            />
            <span className="board-column__label-text">
              {contentData?.title}
            </span>
          </span>
        );

      case "publicationRelated":
        const publicationData = publicationAutocomplete?.find(
          (publication: { taskId: string }) =>
            publication.taskId === colData.value,
        );

        return (
          <span className="board-column__label">
            <CustomImage
              className={classNames(
                "board-column__label-image",
                "board-column__label-image--content",
              )}
              src={getImageSource(
                publicationData?.cover,
                "small",
                ContentPlaceholder,
              )}
              alt="avatar"
              loading="lazy"
            />
            <span className="board-column__label-text">
              {publicationData?.title}
            </span>
          </span>
        );

      case MetaFieldType.SingleSelect:
        return colData.name;

      default:
        return values.find((value: string) => {
          if (value === colData.value) {
            return colData.name;
          }
        });
    }
  };

  return getColumnLabel();
};
