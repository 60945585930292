import React from "react";
import "./General.scss";

import { ProjectGeneral } from "./components/ProjectGeneral/ProjectGeneral";

interface Props {
  onClose: () => void;
  contextData: any;
  getData: () => void;
}

export const General: React.FC<Props> = (props) => {
  const { onClose, contextData, getData } = props;

  const renderGeneralContent = () => {
    switch (contextData.type) {
      case "wsProject":
        return (
          <ProjectGeneral
            onClose={onClose}
            contextData={contextData}
            getData={getData}
          />
        );

      default:
        return null;
    }
  };

  return renderGeneralContent();
};
