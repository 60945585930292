import React from "react";
import { AudienceBrandAffinity } from "../../../../Task/tabs/ProfileDataTab/single/AudienceBrandAffinity";

export const renderAudienceBrandAffinity = (
  label,
  data,
  layout,
  GridBoxComponent,
) => {
  const {
    user_audience_data: { brandAffinity },
  } = data;

  const key = `${label}-audience-brand-affinity`;
  const height = layout?.find((item) => item.i === key)?.h;

  return (
    <GridBoxComponent key={key} id={key}>
      <AudienceBrandAffinity brandAffinity={brandAffinity} height={height} />
    </GridBoxComponent>
  );
};
