import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tab from "../../components/Tabs/Tab";
import Tabs from "../../components/Tabs/Tabs";
import ContentDataTab from "../tabs/ContentDataTab/ContentDataTab";
import { PublicationsTab } from "../tabs/PublicationsTab/PublicationsTab";
import { ActionsTab } from "../tabs/ActionsTab/ActionsTab";
import { ContentTaskTab } from "../Task";

function Content(props) {
  const {
    description,
    setDescription,
    onDescriptionChange,
    setActiveContentTaskTab,
    activeContentTaskTab,
    tasks: { actionTasks, publicationTasks },
    permissions,
    taskType,
  } = props;

  return (
    <div className="activity-tab">
      <Tabs>
        <Tab
          tabText={
            <IDHFormattedMessage
              id="ws_content_data"
              defaultMessage="Content Data"
            />
          }
          active={activeContentTaskTab === ContentTaskTab.ContentData}
          onClick={() => setActiveContentTaskTab(ContentTaskTab.ContentData)}
          medium
        />
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_actions" defaultMessage="Actions" />
          }
          active={activeContentTaskTab === ContentTaskTab.Actions}
          onClick={() => setActiveContentTaskTab(ContentTaskTab.Actions)}
          count={actionTasks?.length ?? 0}
          medium
        />
        <Tab
          tabText={
            <IDHFormattedMessage
              id="ws_publications"
              defaultMessage="Publications"
            />
          }
          active={activeContentTaskTab === ContentTaskTab.Publications}
          onClick={() => setActiveContentTaskTab(ContentTaskTab.Publications)}
          count={publicationTasks?.length ?? 0}
          medium
        />
      </Tabs>

      {activeContentTaskTab === ContentTaskTab.ContentData ? (
        <ContentDataTab
          description={description}
          setDescription={setDescription}
          onDescriptionChange={onDescriptionChange}
        />
      ) : activeContentTaskTab === ContentTaskTab.Actions ? (
        <ActionsTab
          tasks={actionTasks}
          taskType={taskType}
          permissions={permissions}
        />
      ) : (
        <PublicationsTab
          tasks={publicationTasks}
          taskType={taskType}
          permissions={permissions}
        />
      )}
    </div>
  );
}

export default Content;
