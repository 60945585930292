import React, { useEffect, useState } from "react";
import "./PublicationsDetectedModal.scss";

import { useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RootState } from "src/redux/reducers";
import Modal, { ModalText, ModalTitle } from "../../components/Modal/Modal";

import { DetectedPublicationsSection } from "./DetectedPublicationsSection/DetectedPublicationsSection";

interface Props {
  onClose: () => void;
}

export const PublicationsDetectedModal: React.FC<Props> = (props) => {
  const { onClose } = props;

  const [runningDone, setRunningDone] = useState(false);
  const { projectsList } = useSelector(
    (state: RootState) => state.automationReducer,
  );

  useEffect(() => {
    if (runningDone) {
      setTimeout(() => {
        setRunningDone(false);
      }, 3000);
    }
  }, [runningDone]);

  return (
    <Modal
      onClose={onClose}
      className="publications-detected-modal"
      disableTransition
      displayCancelButton
      closeButtonVariant="white-with-border"
      closeButtonText="Close"
    >
      <span className="publications-detected-modal__text">
        <IDHFormattedMessage id="ws_automations" defaultMessage="Automations" />
      </span>
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_publications_detected"
          defaultMessage="Publications detected"
        />
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_publications_detected_modal_text"
          defaultMessage="Make sure that the published content is exactly the same as the approved one."
        />
      </ModalText>

      <div className="publications-detected-modal__list">
        {projectsList?.length > 0 &&
          projectsList.map((item: any) => (
            <DetectedPublicationsSection data={item} />
          ))}
      </div>
    </Modal>
  );
};
