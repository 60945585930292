import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export const generateVelocityIndexTooltip = (socialProvider) => {
  switch (socialProvider) {
    case "instagram":
      return {
        id: "front_velocity_index_ig_tooltip",
        defaultMessage: "velocity index tooltip",
        alignRight: true,
      };
    case "tik_tok":
      return {
        id: "front_velocity_index_tt_tooltip",
        defaultMessage: "velocity index tooltip",
        alignRight: true,
      };
    case "douyin":
      return {
        id: "front_velocity_index_dy_tooltip",
        defaultMessage: "velocity index tooltip",
        alignRight: true,
      };
    default:
      return {
        id: "front_velocity_index_ig_tooltip",
        defaultMessage: "velocity index tooltip",
        alignRight: true,
      };
  }
};

export const generateVelocityIndexModalTooltip = (socialProvider) => {
  switch (socialProvider) {
    case "instagram":
      return (
        <IDHFormattedMessage
          id="front_velocity_index_ig_tooltip"
          defaultMessage="velocity index tooltip"
        />
      );
    case "tik_tok":
      return (
        <IDHFormattedMessage
          id="front_velocity_index_tt_tooltip"
          defaultMessage="velocity index tooltip"
        />
      );
    case "douyin":
      return (
        <IDHFormattedMessage
          id="front_velocity_index_dy_tooltip"
          defaultMessage="velocity index tooltip"
        />
      );
    default:
      return (
        <IDHFormattedMessage
          id="front_velocity_index_ig_tooltip"
          defaultMessage="velocity index tooltip"
        />
      );
  }
};

export const generateRelevanceTooltip = (socialProvider) => {
  switch (socialProvider) {
    case "instagram":
      return {
        id: "front_relevance_ig_tooltip",
        defaultMessage: "relevance tooltip",
        alignRight: true,
      };
    case "tik_tok":
      return {
        id: "front_relevance_tt_tooltip",
        defaultMessage: "relevance tooltip",
        alignRight: true,
      };
    case "douyin":
      return {
        id: "front_relevance_dy_tooltip",
        defaultMessage: "relevance tooltip",
        alignRight: true,
      };
    default:
      return {
        id: "front_relevance_ig_tooltip",
        defaultMessage: "relevance tooltip",
        alignRight: true,
      };
  }
};
