import React from "react";

import WarningYellowIcon from "src/images/warning-orange.svg";

interface WarningIconProps {
  alt: string;
  addDataImgSrc: boolean | undefined;
}

export default function WarningIcon({ alt, addDataImgSrc }: WarningIconProps) {
  return (
    <span className="social-profile-icons__icon social-profile-icons__icon--at-least-one-error">
      <img
        src={!addDataImgSrc ? WarningYellowIcon : undefined}
        data-img-src={addDataImgSrc ? WarningYellowIcon : undefined}
        alt={alt}
      />
    </span>
  );
}
