import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./FiltersView.scss";
import { TaskType } from "src/types";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as FilterIcon } from "../../../../images/filter.svg";
import {
  PaneTopbar,
  PaneTopbarLeft,
  PaneTopbarToolbar,
} from "../../../components/Pane/PaneTopbar/PaneTopbar";

import { setAvailableFilters, setSelectedFilters } from "../../../../redux";
import FiltersWrap from "./FiltersWrap";
import { Button } from "../../../components/Button/Button";
import { actionPredefinedFilters } from "./ActionPredefinedFilters";
import { generateFilters } from "../TasksTable/tasksTableSorting/tableSortingFunctions/generateFilters";

export default function FiltersView({
  filtersVisible,
  setFiltersVisible,
  taskType,
  dataType,
  data,
}) {
  const {
    taskFiltersReducer: { availableFilters, selectedFilters },
    dictionaryReducer: { dictionaryAutoCompletes },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!filtersVisible) return;
    const filters = generateFilters(data, dictionaryAutoCompletes);
    dispatch(setAvailableFilters(filters));
  }, [filtersVisible, dictionaryAutoCompletes]);

  const handleCheckboxChange = (key) => {
    const selections = selectedFilters.selections || [];
    const find = selections.indexOf(key);
    if (find > -1) {
      selections.splice(find, 1);
    } else {
      selections.push(key);
    }

    dispatch(
      setSelectedFilters({
        selections,
      }),
    );
  };

  const clearFilters = () => {
    dispatch(setSelectedFilters({ selections: [] }));
  };

  return (
    <>
      <PaneTopbar>
        <PaneTopbarLeft>
          <FilterIcon className="filters-header__icon" />
          <span className="filters-header__title">
            <IDHFormattedMessage id="ws_filter" defaultMessage="Filter" />
          </span>
        </PaneTopbarLeft>
        <PaneTopbarToolbar hidePane={() => setFiltersVisible(false)} />
      </PaneTopbar>
      <div className="pane__container filters campaign-items-filters">
        {(taskType === TaskType.Action || taskType === TaskType.Payment) && (
          <FiltersWrap
            handleCheckboxChange={handleCheckboxChange}
            selectedFilters={selectedFilters}
            feed={actionPredefinedFilters}
          />
        )}

        <FiltersWrap
          handleCheckboxChange={handleCheckboxChange}
          selectedFilters={selectedFilters}
          feed={availableFilters}
        />

        {taskType !== "action" &&
          !taskType !== "payment" &&
          availableFilters.length === 0 && (
            <div className="filter-box-empty">
              <IDHFormattedMessage
                id="ws_no_data_to_display"
                defaultMessage="No data to display"
              />
            </div>
          )}
      </div>
      <Button
        variant="light"
        size="medium"
        className="filters-button"
        onClick={clearFilters}
      >
        <IDHFormattedMessage
          id="ws_clear_filters"
          defaultMessage="Clear filters"
        />
      </Button>
    </>
  );
}
