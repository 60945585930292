import React, { Dispatch, SetStateAction } from "react";

import ContentPlaceholder from "src/images/content-placeholder2.svg";
import PublicationPlaceholder from "src/images/publication-placeholder2.svg";
import CustomImage from "../../../components/CustomImage/CustomImage";

interface Props {
  src: string;
  showContent: boolean;
  actionTaskType: string;
  showTaskDetails: () => void;
  disableImageLoad?: boolean;
  setDisableImageLoad?: Dispatch<SetStateAction<boolean>>;
}

export function TaskBoxContentImage(props: Props) {
  const {
    showContent,
    src,
    actionTaskType,
    showTaskDetails,
    disableImageLoad,
    setDisableImageLoad,
  } = props;

  const getPlaceholder = () => {
    switch (actionTaskType) {
      case "content":
        return ContentPlaceholder;
      case "publication":
        return PublicationPlaceholder;
      default:
        return "";
    }
  };

  const placeholder = getPlaceholder();

  return showContent ? (
    <CustomImage
      className="task-box__main-content-image"
      src={src || placeholder}
      disableImageLoad={disableImageLoad}
      setDisableImageLoad={setDisableImageLoad}
      onClick={showTaskDetails}
      loading="lazy"
    />
  ) : null;
}
