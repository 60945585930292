import React, { createContext, useState } from "react";
import { useSelector } from "react-redux";

import { ReactComponent as TrashCanIcon } from "src/images/trash-can.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import DetailsPopup from "./PopUps/DetailsPopup";
import { generateMetafieldOptions } from "./utils/mapTasksColumnsToSelectable";
import { tableDataType } from "../../../../components/Table/Table";

import "./AccessLinkField.scss";

export const AccessLinkFieldContext = createContext();

export function AccessLinkField({
  accessLinkData,
  setAccessLinkData,
  noManageModal,
  handleConfirm,
  onManageClose,
  dataType,
}) {
  const {
    taskReducer: { tasksColumns, titleMetaFieldId },
    projectReducer: { projectMetaFields },
  } = useSelector((state) => state);

  const [showDetailsPopup, setShowDetailsPopup] = useState(noManageModal);

  const options = generateMetafieldOptions(
    dataType,
    tasksColumns,
    projectMetaFields,
    titleMetaFieldId,
  );

  let mapAccessLinkDataToSelectedVals = [
    ...accessLinkData.metaFields.map((m) => {
      return {
        field: options.find((o) => o.value === m.wsMetaFieldUuid),
        mode: m.wsMetaFieldAccessMode,
        required: m.wsMetaFieldIsRequired,
        wsMetaFieldDescription: m.wsMetaFieldDescription ?? null,
      };
    }),
  ];

  if (
    dataType === tableDataType.Task &&
    titleMetaFieldId &&
    !mapAccessLinkDataToSelectedVals.find(
      (element) => element?.field?.value === titleMetaFieldId,
    )
  ) {
    mapAccessLinkDataToSelectedVals = [
      {
        field: options.find((o) => o.value === titleMetaFieldId),
        mode: "read",
        required: true,
        wsMetaFieldDescription: "",
      },
      ...mapAccessLinkDataToSelectedVals,
    ];
  }

  const [selectedVals, setSelectedVals] = useState(
    mapAccessLinkDataToSelectedVals,
  );

  return (
    <AccessLinkFieldContext.Provider
      value={{
        accessLinkData,
        setAccessLinkData,
        options,
        selectedVals,
        projectMetaFields,
        showDetailsPopup,
        noManageModal,
        handleConfirm,
        onManageClose,
        setSelectedVals,
        setShowDetailsPopup,
      }}
    >
      <div className="access-link-field">
        <div className="access-link-field__row">
          <div className="access-link-field__paragraph">
            <IDHFormattedMessage
              id="ws_access_link_field_paragraph"
              defaultMessage="To share fields from your project through a dedicated access link, use the form. Click the 'Form settings' button to continue."
            />
          </div>
        </div>
        <div className="access-link-field__row">
          <div className="access-link-field__options">
            <div className="access-link-field__option">
              <div className="access-link-field__option__text">
                <span
                  className="access-link-field__option__text-left"
                  onClick={() => {
                    setSelectedVals(mapAccessLinkDataToSelectedVals);
                    setShowDetailsPopup(true);
                  }}
                >
                  <IDHFormattedMessage
                    id="ws_form_settings"
                    defaultMessage="Form settings"
                  />
                </span>
                <span className="access-link-field__option__text-right">
                  {(accessLinkData.caption ||
                    accessLinkData.description ||
                    accessLinkData.metaFields.length > 0) && (
                    <>
                      {accessLinkData.metaFields.length} field
                      {accessLinkData.metaFields.length > 1 ? "s" : ""}{" "}
                      <TrashCanIcon
                        className="access-link-field__option__trash"
                        onClick={() => {
                          setAccessLinkData({
                            caption: "",
                            description: "",
                            metaFields: [],
                          });
                          setSelectedVals([]);
                        }}
                      />
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDetailsPopup && <DetailsPopup />}
    </AccessLinkFieldContext.Provider>
  );
}
