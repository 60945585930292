import React from "react";
import "./PaneContainer.scss";
import classNames from "classnames";

export function PaneContainer({ children, noBorder }) {
  return (
    <div
      className={classNames("pane__container-wrap", "scroll-fixed", {
        "pane__container-wrap--no-border": noBorder,
      })}
    >
      <div className="pane__container">{children}</div>
    </div>
  );
}
