import { tableDataType } from "src/app/components/Table/Table";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";

const PROJECT_FIELDS_BLACKLIST = ["project_name", "owner"];
const allowedTypes = [
  MetaFieldType.Text,
  MetaFieldType.Number,
  MetaFieldType.Percent,
  MetaFieldType.Currency,
  MetaFieldType.File,
  MetaFieldType.BoolVal,
  MetaFieldType.SingleSelect,
  MetaFieldType.MultiSelect,
  MetaFieldType.AggregatedSelect,
  MetaFieldType.Rating,
];

const mapTasksColumnsToSelectable = (
  tasksColumns,
  projectMetaFields,
  titleMetaFieldId,
) => {
  const TASK_FIELDS_BLACKLIST = [
    "description",
    "title",
    "owner",
    "completed",
    "socialProfiles",
  ];

  let currentTabTaskColumns = tasksColumns
    .filter(
      (tcol) =>
        allowedTypes.includes(tcol.metaFieldType) &&
        !TASK_FIELDS_BLACKLIST.includes(tcol.metaFieldKey),
    )
    .map((el, index) => ({
      value: el.metaFieldId,
      label: el.metaFieldName,
      metafieldType: "wsTaskMetaField",
      index,
      disabled: false,
    }));

  if (titleMetaFieldId) {
    currentTabTaskColumns = [
      {
        value: titleMetaFieldId,
        label: "Name",
        metafieldType: "wsTaskMetaField",
        index: 0,
        disabled: true,
      },
      ...currentTabTaskColumns,
    ];
  }

  const currentProjectOverviewColumns = projectMetaFields.filter(
    (pcol) =>
      allowedTypes.includes(pcol.type) &&
      pcol.valueSource === null &&
      !PROJECT_FIELDS_BLACKLIST.includes(pcol.key),
  );

  return [
    ...currentTabTaskColumns,
    ...currentProjectOverviewColumns
      .filter((e) => e.taskType === null)
      .map((el) => ({
        value: el.uuid,
        label: `Campaign > ${el.name}`,
        metafieldType: "wsProjectMetaField",
      })),
    ...currentProjectOverviewColumns
      .filter((e) => e.taskType !== null)
      .map((el) => ({
        value: el.uuid,
        label: `${el.taskType} > ${el.name}`,
        metafieldType: "wsProjectMetaField",
      })),
  ];
};

const mapProjectMetafieldOptions = (projectMetaFields) => {
  return [
    ...projectMetaFields
      .filter(
        (e) =>
          !PROJECT_FIELDS_BLACKLIST.includes(e.key) &&
          allowedTypes.includes(e.type) &&
          e.valueSource === null,
      )
      .map((el) => ({
        value: el.uuid,
        label: `${el.taskType || "Campaign"} > ${el.name}`,
        metafieldType: "wsProjectMetaField",
      })),
  ];
};

export const generateMetafieldOptions = (
  dataType,
  tasksColumns,
  projectMetaFields,
  taskTitleMetaFieldId,
) => {
  switch (dataType) {
    case tableDataType.Task:
      return mapTasksColumnsToSelectable(
        tasksColumns,
        projectMetaFields,
        taskTitleMetaFieldId,
      );
    case tableDataType.Project:
      return mapProjectMetafieldOptions(projectMetaFields);
    case tableDataType.ProjectDetails:
      return mapProjectMetafieldOptions(projectMetaFields);
    default:
      return [];
  }
};
