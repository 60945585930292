import React from "react";
import moment from "moment";
import RatingSelector, {
  RatingSelectorSize,
} from "src/app/components/RatingSelector/RatingSelector";
import { FieldType } from "../../../../../../types";

export const isToday = (date, selectedFilters, id) => {
  return (
    date.startOf("day").isSame(moment().startOf("day")) &&
    selectedFilters.selections.includes(`due_today_${id}`)
  );
};

export const isThisWeek = (date, selectedFilters, id) => {
  return (
    date
      .startOf("day")
      .isBetween(moment().startOf("isoWeek"), moment().endOf("isoWeek")) &&
    selectedFilters.selections.includes(`due_this_week_${id}`)
  );
};

export const isNextWeek = (date, selectedFilters, id) => {
  return (
    date
      .startOf("day")
      .isBetween(
        moment().add(1, "weeks").startOf("isoWeek"),
        moment().add(1, "weeks").endOf("isoWeek"),
      ) && selectedFilters.selections.includes(`due_next_week_${id}`)
  );
};

export const containsObject = (obj, arr) => {
  for (const element of arr) {
    if (element.metaFieldOptions?.singleSelectOptions) {
      for (const element2 of element.metaFieldOptions?.singleSelectOptions) {
        if (element2.value === obj.value) return true;
      }
    }
  }
  return false;
};
export const getFilterObjectIndex = (name, arr) => {
  let i = -1;
  for (const element of arr) {
    i += 1;
    if (element.name === name) {
      return i;
    }
  }
  return -1;
};

export const pushOptionToExistingSelectFilter = (
  activeFilters,
  foundOption,
  objIndex,
) => {
  activeFilters[objIndex].metaFieldOptions.singleSelectOptions.push(
    foundOption,
  );
};

export const injectNullSelectOption = (fieldId) => ({
  name: "Blanks",
  value: `${fieldId}_null`,
  color: "#fff",
  backgroundColor: "#fff",
  rank: "aaaaaaaaaaaaaaa",
  outOfOrder: true,
});

export const initiateNewSelectFilter = (
  type,
  activeFilters,
  name,
  uuid,
  foundOptions,
) => {
  const options = Array.isArray(foundOptions) ? foundOptions : [foundOptions];

  activeFilters.push({
    type,
    name,
    uuid,
    metaFieldOptions: {
      singleSelectOptions: [injectNullSelectOption(uuid), ...options],
    },
  });
};

export const initiateNewBoolValFilter = (activeFilters, name, id) => {
  activeFilters.push({
    type: FieldType.BoolVal,
    name,
    metaFieldOptions: {
      singleSelectOptions: [
        {
          value: `true`,
          name: "checked",
          label: "checked",
        },
        {
          value: `false`,
          name: "unchecked",
          label: "unchecked",
        },
      ],
    },
  });
};

export const initiateNewDateFilter = (activeFilters, name, id) => {
  activeFilters.push({
    type: FieldType.Date,
    name,
    metaFieldOptions: {
      singleSelectOptions: [
        {
          value: `due_today_${id}`,
          name: "Due Today",
          label: "Due Today",
        },
        {
          value: `due_this_week_${id}`,
          name: "Due this week",
          label: "Due this week",
        },
        {
          value: `due_next_week_${id}`,
          name: "Due next week",
          label: "Due next week",
        },
      ],
    },
  });
};

export const initiateNewRatingFilter = (activeFilters, name, fieldId) => {
  activeFilters.push({
    type: FieldType.Rating,
    name,
    fieldId,
    metaFieldOptions: {
      singleSelectOptions: [
        {
          value: null,
          name: "0",
          label: (
            <RatingSelector
              value={0}
              disabled
              size={RatingSelectorSize.Medium}
            />
          ),
        },
        {
          value: "1",
          name: "1",
          label: (
            <RatingSelector
              value={1}
              disabled
              size={RatingSelectorSize.Medium}
            />
          ),
        },
        {
          value: "2",
          name: "2",
          label: (
            <RatingSelector
              value={2}
              disabled
              size={RatingSelectorSize.Medium}
            />
          ),
        },
        {
          value: "3",
          name: "3",
          label: (
            <RatingSelector
              value={3}
              disabled
              size={RatingSelectorSize.Medium}
            />
          ),
        },
        {
          value: "4",
          name: "4",
          label: (
            <RatingSelector
              value={4}
              disabled
              size={RatingSelectorSize.Medium}
            />
          ),
        },
        {
          value: "5",
          name: "5",
          label: (
            <RatingSelector
              value={5}
              disabled
              size={RatingSelectorSize.Medium}
            />
          ),
        },
      ],
    },
  });
};

export const actionStrings = ["incompleted", "completed", "my_actions"];

export const getFieldIdFromDueFilter = (filterName) => {
  const parts = filterName.split("_");
  return parts[parts.length - 1];
};
