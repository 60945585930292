import React from "react";
import { Row } from "src/redux/dictionary/types";

interface DictionaryElementLabelProps {
  listItem: Row;
  wsDictionarySubFieldUuid: string | null | undefined;
  subMetaFieldValue: string | null;
  icon: JSX.Element | null;
}

export default function DictionaryElementLabel({
  listItem,
  wsDictionarySubFieldUuid,
  subMetaFieldValue,
  icon,
}: DictionaryElementLabelProps) {
  return (
    <div className="dictionary-element-option__row" data-qa={listItem.title}>
      {icon && (
        <span className="image-select-option dictionary-element-option">
          {icon}
        </span>
      )}
      <div className="dictionary-element-option__column">
        <span className="dictionary-element-option__column-title">
          {listItem.title}
        </span>
        {wsDictionarySubFieldUuid &&
          listItem.metadata?.find(
            (metaDataItem) => metaDataItem.uuid === wsDictionarySubFieldUuid,
          )?.value && (
            <div className="dictionary-element-option__column-sub-field">
              <div className="dictionary-element-option__column-sub-field-text">
                {subMetaFieldValue}
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
