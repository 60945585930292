import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { isEqual } from "lodash";
import { RootState } from "./redux/reducers";

import {
  AppLocation,
  initialHowItWorksModalData,
  initialSettingsModalData,
} from "./redux/main/mainReducer";
import {
  setHowItWorksModalData,
  setSettingsModalData,
} from "./redux/main/mainActions";
import { setTaskType } from "./redux";

export function SearchParamWatcher() {
  const {
    mainReducer: { settingsModalData, howItWorksModalData },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const { search } = useLocation();

  const getModalParams = (params: URLSearchParams) => {
    const view = JSON.parse(params.get("view") || "{}");
    const {
      settings: {
        location,
        context,
        tab,
        section,
        objectUuid,
      } = initialSettingsModalData,
      howItWorks = initialHowItWorksModalData,
    } = view;

    if (howItWorks.location && !isEqual(howItWorks, howItWorksModalData)) {
      dispatch(setHowItWorksModalData({ location: howItWorks.location }));
    }

    if (!howItWorks.location) {
      dispatch(setHowItWorksModalData({ location: AppLocation.Empty }));
    }

    if (
      location &&
      context &&
      tab &&
      !isEqual(view.settings, settingsModalData)
    ) {
      dispatch(
        setSettingsModalData({ location, context, tab, section, objectUuid }),
      );
    }

    if (!location && !context && !tab) {
      dispatch(
        setSettingsModalData({
          location: AppLocation.Empty,
          context: "",
          tab: "",
          section: "",
          objectUuid: "",
        }),
      );
    }
  };

  const getTaskTypeParam = (params: URLSearchParams) => {
    const taskType = params.get("taskType");
    if (taskType) {
      dispatch(setTaskType(taskType));
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    getModalParams(params);
    getTaskTypeParam(params);
  }, [search]);

  return null;
}
