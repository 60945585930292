import React from "react";
import "./UserNotFound.scss";
import { EmptyTabContent } from "src/app/Task/tabs/ProfileDataTab/components/EmptyTabs/EmptyTabContent/EmptyTabContent";
import { Button } from "src/app/components/Button/Button";
import {
  PaneTopbar,
  PaneTopbarCol,
  PaneTopbarToolbar,
} from "src/app/components/Pane/PaneTopbar/PaneTopbar";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as NotFoundImg } from "src/images/empty-state-no-user-found.svg";
import { PaneContainer } from "src/app/components/Pane/PaneContainer/PaneContainer";
import { ReactComponent as ArrowLeftIcon } from "src/images/inda-icons/Navigation/Arrow-left.svg";

export function UserNotFound({ closePane, goBack }) {
  return (
    <div className="profile-data-pane__user-not-found">
      {closePane && (
        <PaneTopbar>
          <PaneTopbarCol />
          <PaneTopbarCol className="pane-top-bar__col--right">
            <PaneTopbarToolbar hidePane={closePane} />
          </PaneTopbarCol>
        </PaneTopbar>
      )}
      <PaneContainer>
        <NotFoundImg className="profile-data-pane__user-not-found__image" />
        <div className="profile-data-pane__user-not-found__text">
          <IDHFormattedMessage
            id="ws_profile_data_tab_header"
            defaultMessage="Profile data for this account was not found."
          />
        </div>
        <Button
          variant="blue"
          size="large"
          className="profile-data-pane__user-not-found__btn"
          onClick={goBack}
        >
          <ArrowLeftIcon />
          Previous page
        </Button>
      </PaneContainer>
    </div>
  );
}
