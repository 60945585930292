import React, { useEffect, useState } from "react";

import axios from "axios";
import { useSelector } from "react-redux";

import { SocialData } from "src/app/components/SocialData/SocialData";
import { SocialProfile } from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import { SOCIAL_ICONS } from "src/types";
import { API_URLS } from "src/utils/API_URLS";
import { RootState } from "src/redux/reducers";
import { useIntl } from "react-intl";
import { translateMessage } from "../../../../methods/translateMessage";

export interface ProfileTab {
  value: string;
  label: React.ReactNode;
}

interface GlobalTaskSocialDataProps {
  data: {
    uuid: string;
    title: string;
  };
  socialProfiles: SocialProfile[];
}

export function GlobalTaskSocialData({
  data,
  socialProfiles = [],
}: GlobalTaskSocialDataProps) {
  const [selectedProvider, setSelectedProvider] = useState<ProfileTab>({
    value: "",
    label: <div />,
  });
  const [providers, setProviders] = useState<ProfileTab[]>([]);
  const [profileData, setProfileData] = useState(null);
  const [profileNotFound, setProfileNotFound] = useState(false);
  const intl = useIntl();

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    const newProviders: SocialProfile[] = [];
    Object.keys(SOCIAL_ICONS).forEach((icon) => {
      const provider = socialProfiles.find(
        (item) => item.socialProvider === icon,
      );
      if (provider) newProviders.push(provider);
    });

    const newTabs =
      newProviders.map((profile) => ({
        value: profile.socialProvider,
        label: (
          <span className="social-data__tab">
            <img src={SOCIAL_ICONS[profile.socialProvider]} />
            {translateMessage({
              intl,
              id: profile.socialProvider?.toLowerCase(),
              defaultMessage: profile.socialProvider,
            })}
          </span>
        ),
      })) || [];

    setProviders(newTabs);

    if (
      socialProfiles.length &&
      !socialProfiles.find(
        (social) => social.socialProvider === selectedProvider?.value,
      )
    ) {
      setSelectedProvider(newTabs[0]);
    }
  }, [socialProfiles]);

  useEffect(() => {
    getProfileData();
    setProfileData(null);
  }, [selectedProvider]);

  const getProfileData = async () => {
    setProfileNotFound(false);
    const selectedProviderData = socialProfiles.find(
      (item) => item.socialProvider === selectedProvider.value,
    );
    if (!selectedProviderData) return;

    try {
      const url = API_URLS.getProfileData
        .replace(":workspaceUuid:", activeWorkspaceUuid)
        .replace(":socialProvider:", selectedProviderData?.socialProvider)
        .replace(":socialUsername:", selectedProviderData.socialUsername);
      const { data } = await axios.get(url);
      setProfileData(data.content);
    } catch (err) {
      setProfileNotFound(true);
    }
  };

  return (
    <SocialData
      providers={providers}
      socialProfiles={socialProfiles}
      profileNotFound={profileNotFound}
      selectedProvider={selectedProvider}
      data={data}
      profileData={profileData}
      setSelectedProvider={setSelectedProvider}
    />
  );
}
