import React from "react";
import { IIdentity } from "src/app/Navbar/Navbar";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as EarthIcon } from "src/images/earth.svg";
import { ReactComponent as GearIcon } from "src/images/gear.svg";
import { ReactComponent as MembersIcon } from "src/images/members.svg";
import { ReactComponent as TeamsIcon } from "src/images/teams.svg";
import { ReactComponent as WandIcon } from "src/images/wand.svg";
import { ReactComponent as PlugIcon } from "src/images/plug.svg";
import { ReactComponent as ToolIcon } from "src/images/tool.svg";
import { ReactComponent as FieldIcon } from "src/images/field.svg";
import { ReactComponent as CompassIcon } from "src/images/compass.svg";
import { ReactComponent as CreatorDatabaseIcon } from "src/images/menu-icons/creator-database.svg";
import { ReactComponent as PuzzleIcon } from "src/images/puzzle.svg";

import CustomImage from "src/app/components/CustomImage/CustomImage";
import { SettingsTab } from "../../enums";

export type TabButton = {
  label: React.ReactNode;
  value: SettingsTab;
};

export const getTabButtons = (identity: IIdentity) => {
  const tabButtons: TabButton[] = [
    {
      label: (
        <>
          <GearIcon />
          <IDHFormattedMessage id="ws_general" defaultMessage="General" />
        </>
      ),
      value: SettingsTab.General,
    },
    {
      label: (
        <>
          <MembersIcon />
          <IDHFormattedMessage id="ws_members" defaultMessage="Members" />
        </>
      ),
      value: SettingsTab.Members,
    },
    {
      label: (
        <>
          <TeamsIcon />
          <IDHFormattedMessage id="ws_teams" defaultMessage="Teams" />
        </>
      ),
      value: SettingsTab.Teams,
    },
    {
      label: (
        <>
          <ToolIcon />
          <IDHFormattedMessage
            id="ws_store_configuration"
            defaultMessage="Store configuration"
          />
        </>
      ),
      value: SettingsTab.Coupons,
    },
    {
      label: (
        <>
          <GearIcon />
          <IDHFormattedMessage
            id="ws_workspace_setup"
            defaultMessage="Workspace setup"
          />
        </>
      ),
      value: SettingsTab.WorkspaceSetup,
    },
    {
      label: (
        <>
          <CompassIcon />
          <IDHFormattedMessage
            id="ws_creator_discovery"
            defaultMessage="Creator discovery"
          />
        </>
      ),
      value: SettingsTab.CreatorDiscovery,
    },
    {
      label: (
        <>
          <WandIcon />
          <IDHFormattedMessage
            id="ws_automations"
            defaultMessage="Automations"
          />
        </>
      ),
      value: SettingsTab.Automations,
    },
    {
      label: (
        <>
          <PlugIcon />
          <IDHFormattedMessage id="ws_extensions" defaultMessage="Extensions" />
        </>
      ),
      value: SettingsTab.Extensions,
    },
    {
      label: (
        <>
          <CustomImage src={identity?.avatarUrl} />
          <IDHFormattedMessage id="ws_my_account" defaultMessage="My account" />
        </>
      ),
      value: SettingsTab.Account,
    },
    {
      label: (
        <>
          <EarthIcon />
          <IDHFormattedMessage id="ws_language" defaultMessage="Language" />
        </>
      ),
      value: SettingsTab.Language,
    },
    {
      label: (
        <>
          <FieldIcon />
          <IDHFormattedMessage
            id="ws_manage_fields"
            defaultMessage="Manage fields"
          />
        </>
      ),
      value: SettingsTab.ManageFields,
    },
    {
      label: (
        <>
          <CreatorDatabaseIcon />
          <IDHFormattedMessage
            id="ws_creator_database_extensions"
            defaultMessage="Creator database"
          />
        </>
      ),
      value: SettingsTab.CreatorDatabaseConfiguration,
    },
    {
      label: (
        <>
          <PuzzleIcon />
          <IDHFormattedMessage
            id="ws_integrations"
            defaultMessage="Integrations"
          />
        </>
      ),
      value: SettingsTab.Integrations,
    },
  ];

  return tabButtons;
};
