import React from "react";
import "../SearchSection.scss";
import classNames from "classnames";
import { useHistory } from "react-router";
import { getIconByPath } from "src/utils/methods";
import { getRoute, ROUTES } from "src/utils/ROUTES";
import { DictionaryElementName } from "./components/DictionaryElementName";
import {
  DictionaryElementSearchResultItem,
  DictionarySearchResult,
} from "../../TopSearch";
import { PictogramVariants } from "../../../../components/Pictogram/Pictogram";

interface Props {
  data: DictionarySearchResult[];
  clearResults: () => void;
}

export function DictionarySearchResults(props: Props) {
  const history = useHistory();

  const openDictionaryElement = (
    wsWorkspaceUuid: string,
    wsDictionaryUrl: string,
    wsDictionaryUuid: string,
    wsDictionaryElementUuid: string,
  ) => {
    props.clearResults();
    history.push(
      getRoute(
        ROUTES.DICTIONARY_ELEMENT_DETAILS,
        {
          wsWorkspaceUuid,
          wsDictionaryUrl,
          wsDictionaryUuid,
        },
        {
          displayDictionaryPreview: wsDictionaryElementUuid,
        },
      ),
    );
  };

  return (
    <div>
      {props.data.map((dictionarySearchResult: any, index: any) => (
        <div
          key={dictionarySearchResult.wsDictionaryTitle}
          className={classNames("search-section", {
            "search-section--with-separator": true,
          })}
        >
          <h4 className="search-section__header">
            {dictionarySearchResult.wsDictionaryTitle}
          </h4>

          {dictionarySearchResult.wsDictionaryElements.map(
            (wsDictionaryElement: DictionaryElementSearchResultItem) => (
              <div
                key={wsDictionaryElement.wsDictionaryElementUuid}
                className="search-section__item search-section__item--dictionary-element"
                onClick={() =>
                  openDictionaryElement(
                    wsDictionaryElement.wsWorkspaceUuid,
                    wsDictionaryElement.wsDictionaryElementUrl,
                    wsDictionaryElement.wsDictionaryUuid,
                    wsDictionaryElement.wsDictionaryElementUuid,
                  )
                }
              >
                <span className="search-section__item-icon search-section__item-icon--dictionary-element">
                  {getIconByPath(
                    wsDictionaryElement.wsDictionaryIcon,
                    PictogramVariants.Lightgrey,
                  )}
                </span>
                <DictionaryElementName data={wsDictionaryElement} />
              </div>
            ),
          )}
        </div>
      ))}
    </div>
  );
}
