import React, { useState, useRef } from "react";
import "./FormNotificationMemberTeam.scss";

import classNames from "classnames";
import { injectIntl } from "react-intl";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

import { Button } from "src/app/components/Button/Button";

import spinnySpinner from "src/images/spinner-small.gif";

import { translateMessage } from "src/app/methods/translateMessage";
import CreatableSelect from "react-select/creatable";
import { ReactComponent as EnvelopeIcon } from "src/images/envelope.svg";
import { ReactComponent as CloseIcon } from "src/images/mutableCloseIcon.svg";
import { inviteSelectStyles } from "../../../../../../../modals/ShareModal/InviteUser/utils/inviteSelectStyles";
import { StackedAvatars } from "../../../../../../../components/StackedAvatars/StackedAvatars";

function FormNotificationMemberTeam(props) {
  const {
    intl,
    members,
    teams,
    membersWithNotification,
    assignMultipleMember,
  } = props;

  const [users, setUsers] = useState([]);
  const selectRef = useRef(null);
  const valueContainerRef = useRef(null);
  const anchorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const filterOption = (candidate, input) => {
    return (
      candidate.data.name?.toLowerCase().includes(input.toLowerCase()) ||
      candidate.data.email?.toLowerCase().includes(input.toLowerCase())
    );
  };

  const prepareData = () => {
    const groupedOptions = [
      {
        label: "Teams",
        options: teams
          ?.filter(
            (identityTeam) =>
              !membersWithNotification?.find(
                (memberTeamRow) =>
                  memberTeamRow.wsTeamUuid === identityTeam.uuid,
              ) && identityTeam,
          )
          .map((team) => ({
            name: team.name,
            label: team.name,
            value: team.uuid,
            members: team.wsMembers,
            type: "team",
          })),
      },
      {
        label: "Members",
        options: members
          ?.filter(
            (identityMember) =>
              !membersWithNotification?.find(
                (memberTeamRow) =>
                  memberTeamRow.wsMemberUuid === identityMember.id,
              ) && identityMember,
          )
          .map((member) => ({
            label: member.name,
            name: member.name,
            email: member.email,
            value: member.id,
            avatarUrl: member.avatarUrl,
            type: "member",
            members: [],
          })),
      },
    ];

    return groupedOptions;
  };

  const addMultipleMemberOrTeamToMetaField = async () => {
    setLoading(true);

    const membersToAssign = users.map((item) => {
      return {
        wsMemberUuid: item.type === "member" ? item?.value : null,
        wsTeamUuid: item.type === "team" ? item?.value : null,
        type: item.type,
      };
    });

    await assignMultipleMember(membersToAssign);

    setUsers([]);
    setLoading(false);
  };

  const formatOptionLabel = (option) => (
    <div className="notification-members__label-row">
      <div className="notification-members__label-row-name">{option.label}</div>
      {option.type === "team" ? (
        <StackedAvatars
          avatars={option.members?.map((member) => ({
            url: member?.avatarUrl,
          }))}
          maxAvatars={5}
        />
      ) : (
        <StackedAvatars avatars={[{ url: option?.avatarUrl }]} maxAvatars={1} />
      )}
    </div>
  );

  return (
    <div className="notification-members__user-select" ref={valueContainerRef}>
      <div className="notification-members__user-select-row">
        <div className="notification-members__add-container-member-list">
          <CreatableSelect
            className={classNames(["notification-members__user-select-input"])}
            closeMenuOnSelect={false}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
              MultiValueRemove: (props) => (
                <div {...props.innerProps}>
                  <CloseIcon
                    {...props}
                    width={16}
                    height={16}
                    fill={props.data.color || "#8E93AC"}
                  />
                </div>
              ),
            }}
            value={users}
            isSearchable
            isMulti
            withTiles
            onChange={setUsers}
            options={prepareData()}
            placeholder={translateMessage({
              intl,
              id: "ws_select_member_or_team",
              defaultMessage: "Select member or team",
            })}
            filterOption={filterOption}
            openMenuOnFocus
            menuPortalTarget={document.querySelector(".Overlays") || undefined}
            ref={selectRef}
            styles={inviteSelectStyles}
            formatOptionLabel={formatOptionLabel}
            formatCreateLabel={(inputText) => (
              <div className="teams__user-select-invite">
                <EnvelopeIcon className="teams__user-select-invite-env" />
                <IDHFormattedMessage id="ws_invite" defaultMessage="Invite" />
                <div className="teams__user-select-invite-email">
                  {inputText}
                </div>
              </div>
            )}
          />
        </div>
        {loading !== true ? (
          <div className="notification-members__add-container-btn">
            <Button
              variant="blue"
              size="large"
              disabled={loading || !(users.length > 0)}
              onClick={() => addMultipleMemberOrTeamToMetaField()}
            >
              <IDHFormattedMessage id="ws_add" defaultMessage="Add" />
            </Button>
          </div>
        ) : (
          <div className="notification-members__user-select-spinner">
            <img src={spinnySpinner} width={48} alt="Loading..." />
          </div>
        )}
      </div>
      <div
        className="notification-members__user-select__anchor"
        ref={anchorRef}
      />
    </div>
  );
}

export default injectIntl(FormNotificationMemberTeam);
