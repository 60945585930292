import React from "react";

import { TaskType } from "src/types";
import { StackedAvatars } from "src/app/components/StackedAvatars/StackedAvatars";
import { getImageSource } from "../../../utils/methods";
import publicationPlaceholderIcon from "../../../images/publication-placeholder.svg";
import placeholderIcon from "../../../images/placeholder-image.svg";

import "./ShareTaskList.scss";

export const getPlaceholderImage = (task) => {
  if (task?.cover && getImageSource(task?.cover, "tiny")) {
    return getImageSource(task?.cover, "tiny");
  }

  if (task?.taskType === TaskType.Publication) {
    return publicationPlaceholderIcon;
  }

  return placeholderIcon;
};

function ShareTaskList({ tasks }) {
  return (
    <div className="share-task-details__container">
      <StackedAvatars
        avatars={tasks?.map((item) => ({
          url: getPlaceholderImage(item),
        }))}
        maxAvatars={17}
      />
    </div>
  );
}

export { ShareTaskList };
