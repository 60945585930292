import React, { useState } from "react";

import { StackedAvatars } from "src/app/components/StackedAvatars/StackedAvatars";
import CustomCheckbox from "../../../../components/CustomCheckbox/CustomCheckbox";
import { Team } from "../../../../SettingsModal/tabs/Teams/utils";

interface Props {
  team: Team;
  disabled?: boolean;
  wsTeamUuids: string[];
  onCheckboxChange: (checked: boolean, teamUuid: string) => void;
}

export const MemberTeamItem: React.FC<Props> = (props) => {
  const { disabled, team, onCheckboxChange, wsTeamUuids } = props;

  const [value, setValue] = useState<boolean>(wsTeamUuids.includes(team.uuid));

  return (
    <div className="member-team-list__table-body-row">
      <div className="member-team-list__table-body-row-cell-checkbox">
        <CustomCheckbox
          id={`team-${team.uuid}`}
          disabled={disabled}
          name={team.uuid}
          checked={value}
          onChange={(e) => {
            onCheckboxChange(e.target.checked, team.uuid);
            setValue(e.target.checked);
          }}
          blue
        />
      </div>
      <span className="member-team-list__table-body-row-cell-name">
        {team.name}
      </span>
      <span className="member-team-list__table-body-row-cell-members">
        <StackedAvatars avatars={team.wsMembers} maxAvatars={2} stackedUsers />
      </span>
    </div>
  );
};
