export const addPostsDataToGridElements = (
  postsData,
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
) => {
  postsData.forEach((item) => {
    const label = `post-data-${item.name
      .replaceAll(" ", "-")
      ?.replaceAll(".", "")
      .toLowerCase()}-${item.uuid}`;

    const element = {
      ...item,
      label,
      gridBoxType: "post-data",
      section: "postsData",
    };

    if (savedTemplate?.length && !templateApplied) {
      if (savedTemplate.find((item) => item.i === element.label)) {
        newGridElements.push(element);
      } else {
        newHiddenGridElements.push(element);
      }
    }
    // applying default template
    else {
      newGridElements.push(element);
    }
  });
};
