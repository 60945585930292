import React from "react";
import "./ReportGlossary.scss";

import classNames from "classnames";
import { GLOSSARY_DATA } from "./glossaryData";
import { GlossaryItem } from "./GlossaryItem";

export function GlossaryWrapper(props) {
  const { data, className, iconFolder } = props;

  return (
    <div className={classNames("report-glossary", className)}>
      {/* Left column */}
      <div className="report-glossary__left">
        {data.left.map((item, i) => (
          <GlossaryItem
            key={`glossary-left-${i}`}
            iconFolder={iconFolder}
            data={item}
          />
        ))}
      </div>
      {/* Right column */}
      <div className="report-glossary__right">
        {data.right.map((item, i) => (
          <GlossaryItem
            key={`glossary-right-${i}`}
            iconFolder={iconFolder}
            data={item}
          />
        ))}
      </div>
    </div>
  );
}
