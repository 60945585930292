import React from "react";
import "./RemoveTemplateModal.scss";

import axios from "axios";
import { injectIntl } from "react-intl";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { showErrorToast } from "src/utils/methods";
import { API_URLS } from "../../../../utils/API_URLS";
import IDHFormattedMessage from "../../../components/IDHFormattedMessage/IDHFormattedMessage";

function RemoveTemplateModal(props) {
  const { onClose, templateUuid, getTemplates, intl } = props;

  const removeTemplate = async () => {
    const url = API_URLS.deleteReportTemplate.replace(
      ":templateUuid:",
      templateUuid,
    );

    try {
      await axios.delete(url);

      getTemplates();
      onClose();
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  return (
    <RemoveModal
      onClose={onClose}
      text={
        <IDHFormattedMessage
          id="ws_are_you_sure_you_want_to_remove_this_template"
          defaultMessage="Are you sure you want to remove this template? This action cannot be undone."
        />
      }
      removeFunction={removeTemplate}
    />
  );
}

export default injectIntl(RemoveTemplateModal);
