import React from "react";
import "./TableItem.scss";

import { useDispatch, useSelector } from "react-redux";
import { CouponGroup } from "src/redux/coupon/couponReducer";
import { Link } from "react-router-dom";
import {
  getCouponGroupDetails,
  setCouponsLoading,
} from "src/redux/coupon/couponActions";
import Skeleton from "src/app/components/Skeleton/Skeleton";
import { RootState } from "src/redux/reducers";
import { CouponGroupSettingsDropdown } from "../../../dropdowns/CouponGroupSettingsDropdown/CouponGroupSettingsDropdown";

interface Props {
  data: CouponGroup;
}

export const GroupTableItem: React.FC<Props> = (props) => {
  const { data } = props;

  const { name, campaignCounter, promoCodesCounter, uuid } = data;

  const {
    couponReducer: { listLoading },
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const shouldDisplaySkeleton = (content: any) => {
    if (listLoading)
      return <Skeleton width={80} height={20} borderRadius={6} />;
    return content;
  };

  return (
    <div className="table-item" data-group-name={name}>
      <span key={name} className="table-item__offer animate-on-update">
        {shouldDisplaySkeleton(
          <Link
            to={`/workspace/${activeWorkspaceUuid}/coupon-groups/${uuid}`}
            onClick={() => dispatch(setCouponsLoading(true))}
          >
            {name}
          </Link>,
        )}
      </span>
      <span
        key={campaignCounter}
        className="table-item__campaigns animate-on-update"
      >
        {shouldDisplaySkeleton(campaignCounter)}
      </span>
      <span
        key={promoCodesCounter}
        className="table-item__coupons animate-on-update"
      >
        {shouldDisplaySkeleton(promoCodesCounter)}
      </span>
      <span className="table-item__action">
        <CouponGroupSettingsDropdown
          couponGroupUuid={uuid}
          groupData={data}
          onOpenSettings={() => dispatch(getCouponGroupDetails(uuid))}
        />
      </span>
    </div>
  );
};
