import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { DashedBox } from "../../../../components/DashedBox/DashedBox";
import { SectionHeader } from "../../../../components/Pane/SectionHeader/SectionHeader";
import XssFilter from "../../../../components/XssFilter/XssFilter";

function Caption(props) {
  const { caption } = props;
  return (
    <div>
      <SectionHeader
        name={<IDHFormattedMessage id="ws_caption" defaultMessage="Caption" />}
      />
      <DashedBox className="caption-dashed-box">
        <div
          className="caption-dashed-box__text"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: XssFilter(caption) }}
        />
      </DashedBox>
    </div>
  );
}

export { Caption };
