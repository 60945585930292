import React, { useEffect, useState } from "react";
import "./ProjectDetails.scss";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";

import { ReactComponent as InviteIcon } from "src/images/share-plus.svg";
import classNames from "classnames";
import { AppLocation } from "src/redux/main/mainReducer";
import { RootState } from "src/redux/reducers";
import {
  Breadcrumbs,
  BreadcrumbsItem,
} from "../components/Breadcrumbs/Breadcrumbs";
import TaskMenuDropdown from "../dropdowns/TaskMenuDropdown/TaskMenuDropdown";

import TaskMetaFields from "../Task/components/TaskMetaFields/TaskMetaFields";
import { tableDataType } from "../components/Table/Table";
import { TabsContainer } from "./TabsContainer/TabsContainer";
import { Button } from "../components/Button/Button";
import AvatarPlaceholder from "../../images/avatar-purple.svg";
import {
  PaneTopbar,
  PaneTopbarLeft,
  PaneTopbarToolbar,
} from "../components/Pane/PaneTopbar/PaneTopbar";
import ProjectCoverImage from "../components/ProjectCoverImage/ProjectCoverImage";
import ProjectInfo from "../components/ProjectInfo/ProjectInfo";
import {
  clearProjectDetails,
  getProject,
  getProjectDetails,
} from "../../redux";
import { copyLink, getImageSource } from "../../utils/methods";
import RenameModal from "../modals/RenameModal/RenameModal";
import { SeeAsClientInfoModal } from "../modals/ShareModal/SeeAsClientInfoModal/SeeAsClientInfoModal";
import { RolesInfoModal } from "../modals/ShareModal/RolesInfoModal/RolesInfoModal";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { SettingsModal } from "../SettingsModal/SettingsModal";
import { SettingsContext, SettingsTab } from "../SettingsModal/enums";
import {
  closeSettingsModal,
  openSettingsModal,
} from "../SettingsModal/methods";
import { AddedBy } from "../Task/components/AddedBy/AddedBy";
import { AppConfig } from "../../config/AppConfig";
import { Metadata } from "../Task/Subtask/Subtask";

interface ProjectDetailsProps {
  hidePane: () => void;
}

function ProjectDetails({ hidePane }: ProjectDetailsProps) {
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showSeeAsClientInfoModal, setShowSeeAsClientInfoModal] =
    useState(false);
  const [showRulesInfoModal, setShowRolesInfoModal] = useState(false);

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const { projectId }: { projectId: string } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    projectReducer: { projectDetails, projectBasicData, membersList },
    mainReducer: {
      settingsModalData: { location },
    },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(getProjectDetails(projectId));
  }, [projectId]);

  useEffect(() => {
    return () => {
      dispatch(clearProjectDetails());
    };
  }, []);

  if (projectDetails === null || projectBasicData === null) {
    return null;
  }

  const projectOwner = membersList.find(
    (member) => member.id === projectDetails.ownerWsMemberUuid,
  );

  const projectNameMetaField = projectDetails.metadata?.find(
    (el) => el.key === "project_name",
  );

  const taskMetaFieldsData = projectDetails.metadata?.filter(
    (el) =>
      el.taskType === null && el.name !== "project_name" && el.name !== "owner",
  ) as unknown as Metadata[];

  const openShareModal = () => {
    openSettingsModal(
      history,
      AppLocation.ProjectDetails,
      SettingsContext.Project,
      SettingsTab.Members,
    );
  };

  return (
    <div className="project-details">
      <PaneTopbar>
        <PaneTopbarLeft>
          <Breadcrumbs>
            <BreadcrumbsItem
              text={
                <IDHFormattedMessage
                  id="ws_campaigns"
                  defaultMessage="Campaigns"
                />
              }
              to={`/workspace/${activeWorkspaceUuid}/projects/${projectDetails.projectId}`}
            />
          </Breadcrumbs>
        </PaneTopbarLeft>
        <PaneTopbarToolbar hidePane={hidePane}>
          <TaskMenuDropdown
            data={projectDetails}
            editTask={() => setShowRenameModal(true)}
            copyLink={() =>
              copyLink(
                `${AppConfig.getAppRoute()}/${activeWorkspaceUuid}/projects/${projectDetails.projectId}`,
              )
            }
            projectId={projectId}
          />
        </PaneTopbarToolbar>
      </PaneTopbar>

      <div className="pane__container-wrap scroll-fixed">
        <div className="pane__container">
          <div className={classNames("task__person", {})}>
            <ProjectCoverImage
              src={getImageSource(projectDetails?.cover, "small")}
              updateData={() => dispatch(getProject(projectDetails.projectId))}
              item={projectDetails}
              parentLoading={false}
            />
            <ProjectInfo
              title={projectDetails.title}
              projectUuid={projectDetails.projectId}
              projectNameMetaField={projectNameMetaField}
            />

            <div
              className="projects__topbar-right-members-avatars"
              onClick={openShareModal}
            >
              {projectBasicData.members.slice(0, 3).map((member) => (
                <div className="avatar" key={member.id}>
                  <img
                    src={member.avatarUrl || AvatarPlaceholder}
                    alt="avatar"
                  />
                </div>
              ))}
            </div>

            <Button
              variant="white-with-grey-border"
              size="square"
              onClick={openShareModal}
              className="task__btn-share"
            >
              <InviteIcon />
            </Button>
          </div>

          <TaskMetaFields
            data={taskMetaFieldsData}
            dataType={tableDataType.ProjectDetails}
            settingsContextData={{
              context: SettingsContext.Project,
              contextTargetUuid: projectDetails.projectId,
            }}
          />

          <AddedBy
            name={projectOwner?.name}
            avatarUrl={projectOwner?.avatarUrl}
            dateCreated={projectDetails.created}
          />

          <TabsContainer metadata={projectDetails.metadata} />
        </div>

        {showSeeAsClientInfoModal && (
          <SeeAsClientInfoModal
            onClose={() => setShowSeeAsClientInfoModal(false)}
            openShareModal={openShareModal}
          />
        )}

        {location === AppLocation.ProjectDetails && (
          <SettingsModal
            onClose={() => closeSettingsModal(history)}
            members={{
              openShareModal,
              openSeeAsClientInfoModal: () => setShowSeeAsClientInfoModal(true),
              openRolesInfoModal: () => setShowRolesInfoModal(true),
            }}
            contextTargetUuid={projectId}
          />
        )}

        {showSeeAsClientInfoModal && (
          <SeeAsClientInfoModal
            onClose={() => setShowSeeAsClientInfoModal(false)}
            openShareModal={openShareModal}
          />
        )}

        {showRulesInfoModal && (
          <RolesInfoModal
            onClose={() => setShowRolesInfoModal(false)}
            openShareModal={openShareModal}
          />
        )}

        {showRenameModal && (
          <RenameModal
            onClose={() => setShowRenameModal(false)}
            objectId={projectDetails.projectId}
            taskName={projectDetails.title}
            titleFieldId={
              projectDetails.metadata.find(
                (field) => field.key === "project_name",
              )?.uuid ?? ""
            }
            projectId={projectDetails.projectId}
            dataType={tableDataType.ProjectDetails}
          />
        )}
      </div>
    </div>
  );
}

export { ProjectDetails };
