import React from "react";

import { useSelector } from "react-redux";
import axios from "axios";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { API_URLS } from "src/utils/API_URLS";
import { showToast } from "src/app/methods/showToast";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";

import "./ConfirmDeleteGlobalTaskMetaFieldMemberModal.scss";

function ConfirmDeleteGlobalTaskMetaFieldMemberModal(props) {
  const { onClose, deleteMemberData, getMembersList } = props;

  const {
    mainReducer: { activeWorkspaceUuid, settingsModalData },
  } = useSelector((state) => state);

  const removeMember = () => {
    axios
      .post(API_URLS.deleteMemberTeamToGlobalMetaField, {
        wsWorkspaceUuid: activeWorkspaceUuid,
        wsGlobalTaskMetaFieldUuid: settingsModalData.objectUuid,
        wsMemberUuid: deleteMemberData.wsMemberUuid,
        wsTeamUuid: deleteMemberData.wsTeamUuid,
      })
      .then(() => {
        getMembersList();
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_error_while_delete_member"
            defaultMessage="Member was removed successfully."
          />,
        );
      });

    onClose();
  };

  return (
    <RemoveModal
      customClassName="confirmation-modal-remove"
      objectNames={[deleteMemberData?.name ?? ""]}
      onClose={onClose}
      removeFunction={removeMember}
    />
  );
}

export { ConfirmDeleteGlobalTaskMetaFieldMemberModal };
