import React from "react";

import { ReactComponent as InfoGreyIcon } from "../../../images/info-grey.svg";
import { ClientModeSwitch } from "../../components/ClientModeSwitch/ClientModeSwitch";

function ClientMode(props) {
  const {
    openSeeAsClientInfoModal,
    members,
    projectId,
    permissions,
    clientMode,
    closeShareProjectModal,
    getData,
  } = props;

  return (
    <div className="members__see-as-client-mode">
      <ClientModeSwitch
        members={members}
        projectId={projectId}
        permissions={permissions}
        clientMode={clientMode}
        getData={getData}
      />
      <InfoGreyIcon
        className="members__see-as-client-mode-info-icon"
        onClick={() => {
          openSeeAsClientInfoModal();
          closeShareProjectModal();
        }}
      />
    </div>
  );
}

export { ClientMode };
