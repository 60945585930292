import React, { useMemo } from "react";
import "./BasicInfo.scss";

import CreatorPlaceholder from "src/images/creator-placeholder.svg";
import CampaignPlaceholder from "src/images/project-placeholder.svg";
import { useSelector } from "react-redux";
import {
  CreatorShowcaseContext,
  getShowcaseSettingByKey,
} from "src/app/CreatorShowcase/utils";
import SocialProfileIcons from "../../../../../components/IconsWithStatus/SocialProfileIcons/SocialProfileIcons";

export function BasicInfo(props) {
  const { data } = props;

  const showcaseData = useSelector(
    (state) => state.showcaseReducer.showcaseData,
  );
  const printing = useSelector((state) => state.showcaseReducer.printing);

  const context = getShowcaseSettingByKey("context");

  const socialProfiles = useMemo(
    () =>
      data?.socialProfiles?.map((profile) => ({ ...profile, status: 1 })) || [],
    [data?.socialProfiles],
  );

  const getSrc = (src, placeholder) => src || placeholder;

  return (
    <div className="basic-info">
      <div className="basic-info__left">
        <img
          className="basic-info__left-cover"
          src={!printing ? getSrc(data?.cover, CreatorPlaceholder) : undefined}
          data-img-src={
            printing ? getSrc(data?.cover, CreatorPlaceholder) : undefined
          }
          alt=""
        />
        <span className="basic-info__left-title">{data?.name}</span>
        {socialProfiles?.length > 0 && (
          <SocialProfileIcons
            data={socialProfiles}
            addDataImgSrc={printing}
            size="big"
          />
        )}
      </div>
      <div className="basic-info__right">
        {context === CreatorShowcaseContext.CreatorList &&
          showcaseData?.contextCover && (
            <img
              className="basic-info__right-campaign-logo"
              src={
                !printing
                  ? getSrc(showcaseData?.contextCover, CampaignPlaceholder)
                  : undefined
              }
              data-img-src={
                printing
                  ? getSrc(showcaseData?.contextCover, CampaignPlaceholder)
                  : undefined
              }
              alt="campaign"
            />
          )}
      </div>
    </div>
  );
}
