import { addSpaceIfNeeded } from "../../generator-data/functions/addSpaceIfNeeded";

export const addHeaderToLayout = (section, newLayout, format, type) => {
  const lastEl = addSpaceIfNeeded(type, newLayout, format);

  const currentRow = lastEl.y + lastEl.h;

  const x = 0;
  let y = currentRow;
  const w = 12;
  const h = 1;

  const spaceLeftY = format.rows - (currentRow % format.rows) - 1;
  if (type === "header") {
    y = currentRow + spaceLeftY + 1;
  } else if (type === "subheader" && section.includes("publication")) {
    if (spaceLeftY < 15) {
      y = currentRow + spaceLeftY + 1;
    }
  }

  const key = `${type}-${section}`;

  newLayout.push({ i: key, x, y, w, h });
};
