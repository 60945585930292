enum WorkspacePermissionsEnum {
  /**
   * Workspace related
   */
  CREATOR_DISCOVERY = "creator_discovery",
  CREATOR_DISCOVERY_MANAGEMENT = "creator_discovery_management",
  CREATOR_DISCOVERY_LIST_VIEW_ALL = "creator_discovery_list_view_all",
  CREATOR_DISCOVERY_LIST_MANAGE_ALL = "creator_discovery_list_manage_all",
  GLOBAL_META_FIELDS_MANAGEMENT = "global_meta_fields_management",
  CREATOR_DATABASE_CONFIGURATION = "creator_database_management",
  DASHBOARD = "view_dashboard",
  MANAGE_MEMBERS = "manage_members",
  MANAGE_MEMBERS_ROLES = "manage_members_roles",
  ACTIVATE_TRIAL = "activate_trial",
  CREATOR_DATABASE = "creator_database",
  DICTIONARIES = "dictionaries",
  CREATOR_DATABASE_META_FIELD_ACCESS_MANAGEMENT = "creator_database_meta_field_access_management",
  WORKSPACE_SETUP_MANAGEMENT = "workspace_setup",
  /**
   * Projects related
   */
  CREATE_PROJECTS = "create_projects",
  PROJECTS_VIEW_ALL = "view_all_projects",
  PROJECTS_EXPORT_XLSX = "xlsx_export_projects",

  /**
   * Coupons related
   */
  COUPONS_MANAGEMENT = "manage_coupons",
  COUPONS_STORE_CONFIGURATION = "coupons_store_configuration",
  /**
   * Identity related
   */
  LOGIN_AS_MEMBER = "login_as_member",
  /**
   * XTRM payments related
   */
  EXTENSION_XTRM_PAYMENT_TRANSFER = "extension_xtrm_payment_transfer",
}

enum ProjectPermissionsEnum {
  /**
   * Project related
   */
  PROJECT_MANAGEMENT = "project_management",
  PROJECT_META_FIELDS_MANAGEMENT = "project_meta_fields_management",
  SEE_AS_CLIENT_MODE = "see_as_client_mode",
  /**
   * Project members management related
   */
  PROJECT_INVITE_CLIENT = "invite_client",
  PROJECT_INVITE_OPERATOR = "invite_operator",
  PROJECT_INVITE_SET_MEMBER_AS_ADMIN = "set_member_as_admin",
  PROJECT_INVITE_SET_MEMBER_AS_OPERATOR = "set_member_as_operator",
  DISPLAY_MEMBER_MAGIC_LINK = "display_member_magic_link",
  REMOVE_PROJECT_MEMBERS = "remove_project_members",
  ADD_PROJECT_TEAM = "add_project_team",
  REMOVE_PROJECT_TEAM = "remove_project_team",
  CREATE_CONTENT_PROPOSAL = "create_content_proposal",
  REMOVE_ALL_TASK_COMMENTS = "remove_all_task_comments",
  /**
   * Project resources sharing related
   */
  SHARE_PROJECT_RESOURCES = "share_project_resources",
  SHARE_PROJECT_RESOURCES_PREVIEW = "share_project_resources_preview",
  /**
   * Task related
   */
  TASK_MANAGEMENT = "task_management",
  TASK_META_FIELDS_MANAGEMENT = "task_meta_fields_management",
  /**
   * Reports related
   */
  DOWNLOAD_REPORT = "download_report",
  /**
   * Automations & Extensions related
   */
  DISPLAY_PROJECT_CONFIGURATION = "display_project_configuration",
  MANAGE_PROJECT_CONFIGURATION = "manage_project_configuration",
  MANAGE_AUTOMATIONS = "manage_automations",
}

export { WorkspacePermissionsEnum, ProjectPermissionsEnum };
