import React, { useState } from "react";
import "./PostPreview.scss";
import classNames from "classnames";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import ImgChecker from "src/app/components/ImgChecker/ImgChecker";
import ContentCardPreview from "../../components/ContentCard/ContentCardPreview";
import Emoji from "../../components/Emoji/Emoji";
import CONSTS from "../../helpers/consts";
import postsUtils from "../utils/postsUtils";
import TikTokEmbed from "../../components/TikTokEmbed/TikTokEmbed";
import YoutubeEmbed from "../../components/YouTubeEmbed/YouTubeEmbed";

function PostPreview(props) {
  const {
    multiple,
    noteStatus,
    imageUrl,
    preview,
    mediaType,
    externalLink,
    labelType,
    universal,
    groupElementsMediaUrls,
    hasBonusContent,
    numberOfBonusContents,
    hideErrorImg,
    postUrl,
    socialProvider,
    isAudienceManager,
  } = props;

  const { ACCEPTED_BY_BRAND, REVISED_BY_BRAND, AWAITING_REJECTED } =
    CONSTS.entityPremoderationStatus;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [imageError, setImageError] = useState(false);

  const onPreviewClick = () => {
    if (externalLink) {
      window.open(externalLink, "_blank", "noopener, noreferrer");
    } else if (socialProvider === "douyin") {
      window.open(postUrl, "_blank", "noopener, noreferrer");
    } else {
      setPreviewOpen(true);
    }
  };

  const renderStatusText = (statusType) => {
    switch (statusType) {
      case ACCEPTED_BY_BRAND:
        return (
          <>
            <Emoji icon="👍🏻" />
            &nbsp;&nbsp;
            <IDHFormattedMessage
              id="front_status_accepted"
              defaultMessage="Accepted"
            />
          </>
        );
      case REVISED_BY_BRAND:
        return (
          <>
            <Emoji icon="👀" />
            &nbsp;&nbsp;
            <IDHFormattedMessage
              id="front_status_reviewed"
              defaultMessage="Reviewed"
            />
          </>
        );
      case AWAITING_REJECTED:
        return (
          <>
            <Emoji icon="👎🏻" />
            &nbsp;&nbsp;
            <IDHFormattedMessage
              id="front_status_rejected"
              defaultMessage="Rejected"
            />
          </>
        );
    }
    return null;
  };

  const getStatusClassName = (statusType) => {
    switch (statusType) {
      case ACCEPTED_BY_BRAND:
        return "post-preview__status--accepted";
      case REVISED_BY_BRAND:
        return "post-preview__status--revised";
      case AWAITING_REJECTED:
        return "post-preview__status--rejected";
    }
    return null;
  };

  return (
    <div
      className={classNames("cdt-table__image-content post-preview", {
        "cdt-table__image-content--multiple": multiple,
        "post-preview--universal": universal,
        "post-preview--video": postsUtils.hasVideo({
          groupElementsMediaUrls,
          mediaType,
        }),
        "post-preview--error": !universal && imageError && !hideErrorImg,
        "post-preview--error-video":
          !universal && imageError && mediaType === "video",
        "post-preview--hide-error-img": imageError && hideErrorImg,
      })}
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <ImgChecker src={imageUrl} onError={() => setImageError(true)} />
      {(preview?.length > 0 || externalLink) && (
        <div
          className={classNames("post-preview__hover-layer", {
            "post-preview__hover-layer--video": postsUtils.hasVideo({
              groupElementsMediaUrls,
              mediaType,
            }),
            "post-preview__hover-layer--link": externalLink,
          })}
          onClick={() => {
            onPreviewClick();
          }}
        />
      )}

      {hasBonusContent && (
        <div
          className="post-preview__bonus-content"
          data-count={numberOfBonusContents}
        />
      )}

      {noteStatus && (
        <div
          className={classNames(
            "post-preview__status",
            getStatusClassName(noteStatus),
          )}
        >
          <div className="post-preview__status-text">
            {renderStatusText(noteStatus)}
          </div>
        </div>
      )}

      {labelType === "amended" && (
        <div className="post-preview__label post-preview__label--amended">
          <div className="post-preview__label-text">
            <Emoji icon="✍🏻" />
            &nbsp;&nbsp;
            <IDHFormattedMessage
              id="front_post_amended"
              defaultMessage="Amended"
            />
          </div>
        </div>
      )}

      {previewOpen && preview?.length > 0 && (
        <>
          {isAudienceManager && socialProvider === "tik_tok" ? (
            <TikTokEmbed
              onClose={() => setPreviewOpen(false)}
              postUrl={postUrl}
            />
          ) : isAudienceManager && socialProvider === "youtube" ? (
            <YoutubeEmbed
              onClose={() => setPreviewOpen(false)}
              postUrl={postUrl}
            />
          ) : isAudienceManager && socialProvider === "douyin" ? null : (
            <ContentCardPreview
              onClose={() => setPreviewOpen(false)}
              preview={preview}
              postUrl={postUrl}
            />
          )}
        </>
      )}
    </div>
  );
}

export default PostPreview;
