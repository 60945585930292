import React, { useEffect, useState } from "react";
import "./Table.scss";

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import InfiniteScroll from "react-infinite-scroller";

import { RootState } from "src/redux/reducers";
import { IProject } from "src/redux/project/types";
import { SkeletonCircle, SkeletonText } from "../Skeleton/Skeleton";
import { DateObject, IColumn } from "../../project/Project";
import {
  getProject,
  getTasksAutocomplete,
  setGallerySources,
  updateProjectMetaFieldRank,
  updateTaskMetaFieldRank,
  updateTasksList,
} from "../../../redux";
import { generateRankString } from "../../../utils/rankStrings";
import { HIDDEN_FIELDS, TASK_TYPES } from "../../../utils/variables";

import TableRow from "./components/TableRow";
import TableHeader from "./components/TableHeader";
import AddCampaignRow from "./components/AddCampaignRow";

export enum tableDataType {
  Project = "project",
  GlobalProject = "global-project",
  Task = "task",
  GlobalTask = "global-task",
  ProjectDetails = "project details",
  RecruitmentForm = "recruitment form",
  Dictionary = "dictionary",
  CreatorDatabase = "creatorDatabase",
  GlobalMetaFieldProjectWizardFields = "global-metafield-project-wizard-fields",
}

export enum taskAvatarShape {
  Circle,
  Square,
}

interface Props {
  className?: string;
  data: IProject[];
  fields: any[];
  disableLink?: boolean;
  filtersVisible?: boolean;
  onTableValueChange?: (
    taskId: string,
    uuid: string,
    newValue: string | DateObject | null,
  ) => void;
  actionButton: (projectData: IProject) => React.ReactNode | React.FC;
  updateData?: () => void;
  addTableElement?: () => void;
  addTableMetaField?: () => void;
  taskType: string;
  dataType: tableDataType;
  taskAvatarShape: taskAvatarShape;
  singleRow?: boolean;
  loading?: boolean;
  handleChangePage?: () => void;
  handleScrollParent?: () => HTMLElement | null;
  shouldLoadMoreResults?: boolean;
}

function Table(props: Props) {
  const {
    className,
    data,
    fields,
    disableLink,
    filtersVisible,
    onTableValueChange,
    actionButton,
    updateData,
    addTableMetaField,
    addTableElement,
    taskType,
    dataType,
    singleRow,
    loading,
    handleChangePage,
    handleScrollParent,
    shouldLoadMoreResults,
    taskAvatarShape: taskAvatarShapeType,
  } = props;

  const [filteredFields, setFilteredFields] = useState<IColumn[]>([]);
  const [autoCompletes, setAutoCompletes] = useState<any>([]);

  const dispatch = useDispatch();

  const params = useParams<{ projectId: string }>();

  const {
    mainReducer: { identity },
    taskReducer: { tasksLoading },
    projectReducer: { projectLoading, projectBasicData },
  } = useSelector((state: RootState) => state);

  // clears data after TaskType change and sets data to display
  useEffect(() => {
    setAutoCompletes([]);
    dispatch(setGallerySources([]));
  }, [taskType]);

  useEffect(() => {
    const objectMetaData = data[0]?.metadata;
    const autoCompletesLocal: any = [];
    const newFilteredFields = fields.filter((field) => {
      if (
        TASK_TYPES.includes(field.type) &&
        !autoCompletes.includes(field.type) &&
        !autoCompletesLocal.includes(field.type)
      ) {
        autoCompletesLocal.push(field.type);
        setAutoCompletes((list: any) => [...list, field.type]);
        dispatch(getTasksAutocomplete(params.projectId, field.type));
      }
      const uuid = field.id || field.uuid;
      return (
        field.isVisible &&
        !HIDDEN_FIELDS.includes(field.name) &&
        objectMetaData?.find((metafield: any) => metafield.uuid === uuid)
      );
    });

    setFilteredFields(newFilteredFields);
  }, [fields, data]);

  const handleDragUpdate = (update: any) => {
    if (!update.destination) {
    }

    // const queryAttr = "data-rbd-drag-handle-draggable-id";

    // const draggableId = update.draggableId;
    // const destinationIndex = update.destination.index;

    // const domQuery = `[${queryAttr}='${draggableId}']`;
    // const draggedDOM = document.querySelector(domQuery) as {
    //   clientHeight: number;
    //   clientWidth: number;
    //   parentNode: any;
    // };

    // if (!draggedDOM) {
    //   return;
    // }
    // const { clientHeight, clientWidth } = draggedDOM;

    // const clientY =
    //   parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
    //   [...draggedDOM.parentNode.children]
    //     .slice(0, destinationIndex)
    //     .reduce((total, curr) => {
    //       const style = curr.currentStyle || window.getComputedStyle(curr);
    //       const marginBottom = parseFloat(style.marginBottom);
    //       return total + curr.clientHeight + marginBottom;
    //     }, 0);

    // setPlaceholderProps({
    //   clientHeight,
    //   clientWidth,
    //   clientY,
    //   clientX: parseFloat(
    //     window.getComputedStyle(draggedDOM.parentNode).paddingLeft
    //   ),
    // });
  };

  const handleDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const { destination, source } = result;

    const sourceField: any = filteredFields[source.index];
    const fieldIndex = destination.index;

    let newRank = "";

    // move left
    if (destination.index < source.index) {
      newRank = generateRankString(
        filteredFields[fieldIndex - 1]?.rank || "",
        filteredFields[fieldIndex].rank,
      );
      // move right
    } else {
      newRank = generateRankString(
        filteredFields[fieldIndex].rank,
        filteredFields[fieldIndex + 1]?.rank || "",
      );
    }
    updateMetaFieldRank(sourceField, newRank);
  };

  const updateMetaFieldRank = async (
    sourceField: { id: string; uuid: string },
    newRank: string,
  ) => {
    switch (dataType) {
      case tableDataType.Project:
        await dispatch(
          updateProjectMetaFieldRank(
            params.projectId,
            sourceField.uuid,
            newRank,
          ),
        );
        dispatch(getProject(params.projectId));
        break;

      case tableDataType.Task:
        await dispatch(updateTaskMetaFieldRank(sourceField.id, newRank));
        dispatch(updateTasksList(params.projectId, taskType));
        break;
    }
  };

  return (
    <>
      {data && (
        <div className={classNames("ws-table", "ws-table--tall", className)}>
          <TableHeader dataType={dataType} taskType={taskType} />
          {loading && !data.length && <Loader />}
          {data.length > 0 ? (
            handleChangePage ? (
              <InfiniteScroll
                pageStart={0}
                loadMore={handleChangePage}
                useWindow={false}
                getScrollParent={handleScrollParent}
                hasMore={shouldLoadMoreResults}
                loader={<Loader key={0} />}
                initialLoad={false}
              >
                {data.map((item, index) => (
                  <TableRow
                    item={item}
                    key={index}
                    disableLink={disableLink}
                    dataType={dataType}
                    singleRow={singleRow}
                    taskAvatarShapeType={taskAvatarShapeType}
                    updateData={updateData}
                    actionButton={actionButton}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              data.map((item, index) => (
                <TableRow
                  item={item}
                  key={index}
                  disableLink={disableLink}
                  dataType={dataType}
                  singleRow={singleRow}
                  taskAvatarShapeType={taskAvatarShapeType}
                  updateData={updateData}
                  actionButton={actionButton}
                />
              ))
            )
          ) : identity.permissions?.workspace.includes("create_projects") &&
            !loading ? (
            <AddCampaignRow
              addTableElement={addTableElement}
              taskAvatarShapeType={taskAvatarShapeType}
              taskType={taskType}
              dataType={dataType}
            />
          ) : null}
        </div>
      )}
    </>
  );
}

export default Table;

function ActivityElementLoader() {
  return (
    <div className="ws-table__row-flex">
      <div className="ws-table__info">
        <div className="ws-table__content">
          <SkeletonCircle size={40} marginRight={12} />
          <SkeletonText width={500} height={18} />
        </div>
      </div>
      <div className="ws-table__column-container">
        <div className="ws-table__content ws-table__content--center">
          <SkeletonText width="66px" height={18} />
        </div>
      </div>
      <div className="ws-table__column-container">
        <div className="ws-table__content ws-table__content--center">
          <SkeletonText width="66px" height={18} />
        </div>
      </div>
      <div className="ws-table__actions">
        <div className="ws-table__content ws-table__content--center">
          <SkeletonText width="44px" height={18} />
        </div>
      </div>
    </div>
  );
}

function Loader() {
  return (
    <div>
      <ActivityElementLoader />
      <ActivityElementLoader />
      <ActivityElementLoader />
      <ActivityElementLoader />
      <ActivityElementLoader />
      <ActivityElementLoader />
    </div>
  );
}
