import React, { useEffect, useState } from "react";
import "./CreatorDiscoveryLink.scss";

import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

import {
  clearInfluencersListInfo,
  clearResults,
} from "src/app/pages/CreatorDiscoveryTool/legacy/app/audiences-manager/audiencesManagerActions";
import { RootState } from "src/redux/reducers";
import { ReactComponent as CompassIcon } from "../../../images/compass.svg";
import { ReactComponent as ChevronIcon } from "../../../images/chevron-down.svg";

export function CreatorDiscoveryLink() {
  const [visible, setVisible] = useState(
    window.location.pathname.includes(`/creator-discovery-tool`),
  );

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const location = window.location.pathname + window.location.search;

  const baseUrl = `/workspace/${activeWorkspaceUuid}`;
  const searchPathnames = [`/search`, `/search-results`];
  const listPathnames = [`/lists`, `/list/`];

  const isCDT = location.includes(`/creator-discovery-tool`);
  const isSearch = isCDT && searchPathnames.some((el) => location.includes(el));
  const isList = isCDT && location.includes("/hashtag-live-search");

  useEffect(() => {
    setVisible(window.location.pathname.includes(`/creator-discovery-tool`));
  }, [window.location.pathname]);

  const handleOpenSearch = () => {
    dispatch(clearInfluencersListInfo());
    dispatch(clearResults());
  };

  return (
    <li className="creator-discovery-link">
      <span
        className={classNames("sidebar__item", {
          "sidebar__item--active": isCDT,
          "sidebar__item--open": visible,
        })}
        onClick={() => setVisible((v) => !v)}
      >
        <CompassIcon />
        <span className="sidebar__item-text creator-discovery-link__button-text">
          <IDHFormattedMessage id="ws_discovery" defaultMessage="Discovery" />
          <ChevronIcon className="chevron-icon" />
        </span>
      </span>

      <ul
        className={classNames("creator-discovery-link__elements", {
          "creator-discovery-link__elements--visible": visible,
        })}
      >
        <li
          className={classNames("sidebar__item", {
            "sidebar__item--active-light": isSearch,
          })}
        >
          <Link
            to={`/workspace/${activeWorkspaceUuid}/creator-discovery-tool/search`}
            onClick={handleOpenSearch}
          >
            &nbsp;
            <span className="sidebar__item-text">
              <IDHFormattedMessage
                id="ws_creator_search"
                defaultMessage="Creator Search"
              />
            </span>
          </Link>
        </li>

        <li
          className={classNames("sidebar__item", {
            "sidebar__item--active-light": isList,
          })}
        >
          <Link to={`${baseUrl}/creator-discovery-tool/lists`}>
            &nbsp;
            <span className="sidebar__item-text">
              <IDHFormattedMessage
                id="ws_creator_lists"
                defaultMessage="Creator Lists"
              />
            </span>
          </Link>
        </li>

        <li
          className={classNames("sidebar__item", {
            "sidebar__item--active-light": isList,
          })}
        >
          <Link to={`${baseUrl}/hashtag-live-search`}>
            &nbsp;
            <span className="sidebar__item-text">
              <IDHFormattedMessage
                id="ws_hashtag_live_search"
                defaultMessage="Hashtag Live Search"
              />
            </span>
          </Link>
        </li>
      </ul>
    </li>
  );
}
