import React, { useEffect, useState } from "react";

import classNames from "classnames";
import DatePicker from "react-datepicker";
import { Portal } from "react-overlays";
import { useSelector } from "react-redux";
import moment from "moment";
import { injectIntl } from "react-intl";

import { ReactComponent as PlusIcon } from "src/images/plus-transparent.svg";
import { overlaysRef } from "src/App";
import { useScrollLock } from "src/app/methods/useScrollLock";
import { RootState } from "src/redux/reducers";
import { getDateLabel } from "../../methods/getDateLabel";
import { dateFormatForBackend, datePickerDateFormat, languages } from "./utils";

import "./CustomDatePicker.scss";
import "react-datepicker/dist/react-datepicker.css";

function CalendarContainer(props: any) {
  return <Portal container={overlaysRef}>{props.children}</Portal>;
}

function CustomDatePicker(props: any) {
  const { initialDate, dateChangeHandler, disableRedLabel, intl, readOnly } =
    props;

  const [date, setDate] = useState<moment.Moment | null>(
    initialDate ? moment(initialDate) : null,
  );

  const { lockScroll, unlockScroll } = useScrollLock();

  const formattedValue = getDateLabel("en-EN", date, disableRedLabel, intl);

  const value = date === null ? null : formattedValue?.date;

  const handleChangeRaw = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  const {
    mainReducer: { locale },
  } = useSelector((state: RootState) => state);

  const handleDateChange = (newDate: any) => {
    const momentDate = moment(newDate);
    const momentDateBackend = moment(newDate);
    const utcDate = momentDateBackend.format(dateFormatForBackend);
    setDate(momentDate);
    dateChangeHandler(utcDate);
  };

  useEffect(() => {
    setDate(initialDate ? moment(initialDate) : null);
  }, [initialDate]);

  return (
    <div className="idh-date-picker">
      <DatePicker
        locale={languages[locale]}
        onChange={handleDateChange}
        popperContainer={CalendarContainer}
        onChangeRaw={handleChangeRaw}
        className={classNames(
          "idh-date-picker__input",
          formattedValue !== null ? formattedValue.style : null,
          {
            "idh-date-picker__input--transparent": date,
            "idh-date-picker__input--readOnly": readOnly,
          },
        )}
        dateFormat={datePickerDateFormat}
        selected={date === null ? null : date.toDate()}
        value={value}
        {...props}
        onCalendarOpen={lockScroll}
        onCalendarClose={unlockScroll}
        popperModifiers={[
          {
            name: "flip",
            options: {
              fallbackPlacements: ["bottom-end", "left"],
            },
          }, // this allows datepicker popper to choose whether should it be rendered right or left, but never up or down - that way it is always rendered under the popper initiator to avoid hiding under the tasks toolbar on tasks table (which it did if tried to render to the top)
        ]}
        readOnly={readOnly}
      />
      <div className="idh-date-picker__icon" />
    </div>
  );
}

export default injectIntl(CustomDatePicker);

interface Props {
  children: React.ReactNode;
  value: string | number | null;
  readOnly?: boolean;
}

export const DatePickerWrapper: React.FC<Props> = (props) => {
  const { children, value, readOnly } = props;

  if (!value && readOnly) return null;

  return (
    <div
      className={classNames("date-picker-wrapper", {
        "date-picker-wrapper--empty": !value,
      })}
    >
      {!value && <PlusIcon className="date-picker-wrapper__plus" />}
      {children}
    </div>
  );
};
