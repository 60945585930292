import React from "react";

import { useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import classNames from "classnames";

import "./CustomTable.scss";

export interface CustomTableColumn {
  Header: string;
  accessor: string;
}

export interface Column {
  Header: string;
  accessor: string;
  width?: number;
}

interface CustomTableProps {
  columns: Column[];
  data: any;
  rowClassName?: string;
  headerClassName?: string;
  isLoading?: boolean;
  noRowsPlaceholder?: JSX.Element;
}

export function CustomTable({
  columns,
  data,
  rowClassName,
  headerClassName,
  isLoading,
  noRowsPlaceholder,
}: CustomTableProps) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSticky,
    );

  return (
    <table {...getTableProps()} className="ws-custom-table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{ width: column.width }}
                className={headerClassName}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {!isLoading && rows.length === 0 && noRowsPlaceholder ? (
        <tbody>
          <tr>
            <td colSpan={columns.length}>{noRowsPlaceholder}</td>
          </tr>
        </tbody>
      ) : (
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                // @ts-ignore
                onClick={row.original?.onRowClick}
                className={classNames(
                  "ws-custom-table-row",
                  rowClassName,
                  // @ts-ignore
                  row.original?.onRowClick && "ws-custom-table-row--clickable",
                )}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      )}
    </table>
  );
}
