import React, { useEffect, useRef, useState } from "react";
import "./ImageCropModal.scss";

import Modal, { ModalTitle } from "../../Modal/Modal";
import CroppedImageUploader from "../CroppedImageUploader";
import IDHFormattedMessage from "../../IDHFormattedMessage/IDHFormattedMessage";

function ImageCropModal({
  onClose,
  title = "Adjust image",
  confirmText = "Save",
  circle = false,
  uploadFunction,
}) {
  const ref = useRef();
  const [loaded, setLoaded] = useState(false);

  return (
    <Modal
      onClick={(e) => e.stopPropagation()}
      className="image-crop-modal"
      overlayClassName="image-crop-modal__overlay"
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={() => {
        ref.current.handleSubmit();
        onClose();
      }}
      confirmButtonText={confirmText}
      confirmButtonDisabled={!loaded}
      onClose={onClose}
    >
      <ModalTitle>{title}</ModalTitle>
      <CroppedImageUploader
        loaded={loaded}
        setLoaded={setLoaded}
        ref={ref}
        uploadFunction={uploadFunction}
        circle={circle}
      />
    </Modal>
  );
}

export default ImageCropModal;
