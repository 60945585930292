import React, { Dispatch, SetStateAction } from "react";
import { Dispatch as ReduxDispatch } from "redux";
import axios from "axios";

import { AttachmentMetaDataAndKey } from "src/app/Task/components/AddCommentField/AddCommentField";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { CommentDisplayType } from "src/app/Task/Task";
import { PresignedUploadRequest } from "src/types";
import { uuidv7 } from "uuidv7";
import { identify } from "src/redux/main/mainActions";
import { getAttachmentsWithFile } from "src/app/Task/components/ContentProposalField/AddContentProposalField";
import { tableDataType } from "src/app/components/Table/Table";
import { API_URLS } from "./API_URLS";
import { getProject, getProjectsList } from "../redux";
import { UploadFile } from "../app/libs/uploadFile";
import { showToast } from "../app/methods/showToast";
import { UploadMultiFiles } from "../app/libs/uploadMultiFiles";
import {
  getMetaFileParams,
  MAX_SIZE_SINGLE_ATTACHMENT,
  MAX_SIZE_PROJECT_COVER,
  MAX_SIZE_AVATAR,
  allowedTypesForAttachmentUpload,
  ParentType,
} from "./uploadFunctionsUtils";
import { showErrorToast } from "./methods";

export interface AssetData {
  wsWorkspaceUuid?: string;
  parentUuid?: string;
  parentType?: ParentType;
  name: string;
  realName: string;
  mimeType: string;
  size: number;
}

export const uploadAvatarBlob = async (
  file: any,
  dispatch: ReduxDispatch,
  wsMemberUuid: string,
  wsWorkspaceUuid: string,
) => {
  const allowedTypes = ["png", "jpg", "jpeg"];

  const attachment = new UploadFile(
    file,
    allowedTypes,
    MAX_SIZE_AVATAR,
    API_URLS.predesignedRequestForUploadAvatar,
    wsMemberUuid,
    "ws_member",
    null,
  );

  try {
    attachment.validate();
    await attachment.sendAllRequests();
    dispatch(identify(wsWorkspaceUuid));
    dispatch(getProjectsList(wsWorkspaceUuid));
    return true;
  } catch (err) {
    console.error("error", err);
    showToast("error", "Error", "An error occurred when uploading the avatar.");
    return false;
  }
};

interface UploadMultipleFilesProps {
  e: React.ChangeEvent<HTMLInputElement>;
  wsMetaFieldUuid: string;
  wsWorkspaceUuid: string;
  wsProjectUuid?: string;
  wsTaskUuid?: string;
  context?: tableDataType;
  thenCallback?: () => void;
  catchCallback?: () => void;
  finallyCallback?: () => void;
}

export const uploadMultipleFiles = async ({
  e,
  wsMetaFieldUuid,
  wsProjectUuid,
  wsWorkspaceUuid,
  wsTaskUuid,
  context,
  thenCallback,
  catchCallback,
  finallyCallback,
}: UploadMultipleFilesProps) => {
  const { files } = e.target;

  if (!files?.length) return;

  const filesArray = Array.from(files);

  const { url, parentType } = getMetaFileParams(wsTaskUuid, context);

  const uploadFiles = new UploadMultiFiles({
    files: filesArray,
    allowedTypes: allowedTypesForAttachmentUpload,
    maxSize: MAX_SIZE_SINGLE_ATTACHMENT,
    presignedUploadRequestUrl: url,
    parentUuid: wsMetaFieldUuid,
    parentType,
    wsWorkspaceUuid,
    wsProjectUuid,
    wsTaskUuid,
    context,
  });
  try {
    uploadFiles.validate();
    await uploadFiles.sendAllRequests();
    if (thenCallback) {
      thenCallback();
    }
  } catch (err: any) {
    console.error(err);
    if (!uploadFiles.hasCorrectType || !uploadFiles.hasCorrectSize) {
      showToast("error", "Error", err.message);
    } else {
      showToast("error", "Error", "An error occurred during uploading files.");
    }
    if (catchCallback) {
      catchCallback();
    }
  } finally {
    if (finallyCallback) {
      finallyCallback();
    }
  }
};

interface UploadFilesToS3Props {
  filesArray: File[];
  commentId: string;
  allowedTypes: string[];
  setAttachments: Dispatch<SetStateAction<AttachmentMetaDataAndKey[]>>;
  presignedUploadRequestUrl: string;
  commentType: CommentDisplayType;
  wsWorkspaceUuid: string;
}

export const uploadFilesToS3 = async ({
  filesArray,
  commentId,
  allowedTypes,
  setAttachments,
  presignedUploadRequestUrl,
  commentType,
  wsWorkspaceUuid,
}: UploadFilesToS3Props) => {
  const attachment = new UploadMultiFiles({
    files: filesArray,
    allowedTypes,
    maxSize: MAX_SIZE_SINGLE_ATTACHMENT,
    presignedUploadRequestUrl,
    parentUuid: commentId,
  });

  attachment.validate();

  let presignedRequestData: PresignedUploadRequest = { content: [] };

  try {
    switch (commentType) {
      case CommentDisplayType.Comment:
        const { data } = await attachment.sendPresignedUploadRequest();
        presignedRequestData = data;
        break;
      case CommentDisplayType.ContentProposal:
        const response = await axios.post(
          presignedUploadRequestUrl,
          filesArray.map(() => ({
            wsContentProposalElementUuid: uuidv7(),
            wsWorkspaceUuid,
          })),
        );
        presignedRequestData = response.data;
        break;

      default:
        throw new Error("This comment type is not supported.");
    }

    setAttachments((prev: AttachmentMetaDataAndKey[]) => {
      const newAssetsWithKey = prev
        .filter((item) => item.file && !item.key)
        .map((attachment, index) => ({
          ...attachment,
          key: presignedRequestData.content[index].token.requestInputs.key,
        }));

      return [
        ...prev.filter((item) => {
          return item.key || item.attachment.id || item.attachment.externalUrl;
        }),
        ...newAssetsWithKey,
      ];
    });

    return await attachment.sendAttachmentsToS3(presignedRequestData.content);
  } catch (err) {
    console.error("error", err);
    showToast(
      "error",
      <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
      <IDHFormattedMessage
        id="ws_an_error_occurred_when_uploading_the_attachments"
        defaultMessage="An error occurred when uploading the attachments."
      />,
    );
  }
};

export const createAssetRelationInDb = async (
  attachments: AttachmentMetaDataAndKey[],
) => {
  const assetsForDb = {
    assets: getAttachmentsWithFile(attachments),
  };

  try {
    await axios.post(API_URLS.createTaskCommentAttachment, assetsForDb);
  } catch (err) {
    console.error("error", err);
    showToast(
      "error",
      <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
      <IDHFormattedMessage
        id="ws_an_error_occurred_when_uploading_the_attachments"
        defaultMessage="An error occurred when uploading the attachments."
      />,
    );
  }
};

export const updateTaskCommentAttachments = async (
  attachments: AttachmentMetaDataAndKey[],
  commentUuid: string,
) => {
  const assetsForDb = {
    assets: attachments.map(({ attachment, key, file }) => {
      return {
        assetUuid: attachment.id || undefined,
        key: key || undefined,
        name: attachment.name,
        mimeType: attachment.mimeType,
        size: file?.size,
      };
    }),
  };

  try {
    await axios.put(
      `${API_URLS.updateTaskCommentAttachments}/${commentUuid}`,
      assetsForDb,
    );
  } catch (err) {
    console.error("error", err);
    showToast(
      "error",
      <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
      <IDHFormattedMessage
        id="ws_an_error_occurred_when_uploading_the_attachments"
        defaultMessage="An error occurred when uploading the attachments."
      />,
    );
  }
};

export const uploadProjectCover = async (
  file: any,
  dispatch: ReduxDispatch,
  wsProjectUuid: string,
  wsWorkspaceUuid: string,
  setLoading: (state: boolean) => void,
) => {
  const allowedTypes = ["png", "jpg", "jpeg"];

  const cover = new UploadFile(
    file,
    allowedTypes,
    MAX_SIZE_PROJECT_COVER,
    `${API_URLS.presignedRequestForProjectCover}?wsProjectUuid=${wsProjectUuid}&wsWorkspaceUuid=${wsWorkspaceUuid}`,
    wsProjectUuid,
    "ws_project",
    wsWorkspaceUuid,
  );

  try {
    cover.validate();
    await cover.sendAllRequests();
    await dispatch(getProject(wsProjectUuid));
    return true;
  } catch (err) {
    console.error("error", err);
    showToast("error", "Error", "An error occurred when uploading the avatar.");
    return false;
  } finally {
    setLoading(false);
  }
};

export const addTaskComment = async (
  commentId: string,
  taskId: string,
  comment: string,
) => {
  try {
    await axios.post(API_URLS.createTaskComment, {
      wsTaskCommentUuid: commentId,
      wsTaskUuid: taskId,
      comment,
    });
  } catch (error) {
    console.error(error);
    showErrorToast();
  }
};
