import React, { useEffect, useState } from "react";
import "./CouponGroupsTable.scss";

import { useDispatch, useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import PageHeader from "src/app/components/PageHeader/PageHeader";
import {
  getCouponDataImportStrategies,
  getCouponGroups,
} from "src/redux/coupon/couponActions";
import { Button } from "src/app/components/Button/Button";

import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";
import { ReactComponent as ToolIcon } from "src/images/tool.svg";
import { ReactComponent as ImportIcon } from "src/images/download-rounded.svg";
import { ReactComponent as QuestionMarkIcon } from "src/images/question-mark.svg";
import { openSettingsModal } from "src/app/SettingsModal/methods";
import { SettingsContext, SettingsTab } from "src/app/SettingsModal/enums";
import { useHistory } from "react-router";
import { AppLocation } from "src/redux/main/mainReducer";
import { RootState } from "src/redux/reducers";
import { CouponDataImportModal } from "../../modals/CouponDataImportModal/CouponDataImportModal";
import { CouponsLearnModeModal } from "../../modals/CouponsLearnModeModal/CouponsLearnModeModal";
import { CodesTable } from "../CodesTable/CodesTable";
import { EmptyPromoCodes } from "../EmptyPromoCodes/EmptyPromoCodes";
import { AddCouponGroupModal } from "../../modals/AddCouponGroupModal/AddCouponGroupModal";

export function CouponGroupsTable() {
  const [showAddCouponsModal, setShowAddCouponGroupModal] = useState(false);
  const [showDataImportModal, setShowDataImportModal] = useState(false);
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);

  const {
    couponReducer: { couponGroups, listLoading },
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getCouponGroups(activeWorkspaceUuid));
  }, []);

  const openConfiguration = () => {
    openSettingsModal(
      history,
      AppLocation.PromoCodes,
      SettingsContext.Workspace,
      SettingsTab.Coupons,
    );
  };

  const renderContent = () => {
    if (!couponGroups.length && !listLoading) {
      return (
        <EmptyPromoCodes
          openCreateCouponGroupModal={() => setShowAddCouponGroupModal(true)}
          openLearnMoreModal={() => setShowLearnMoreModal(true)}
        />
      );
    }

    return <CodesTable viewType="group-list" />;
  };

  return (
    <div className="coupon-groups-table">
      <div className="coupon-groups-table__topbar">
        <div>
          <PageHeader
            title={
              <IDHFormattedMessage
                id="ws_coupon_groups"
                defaultMessage="Coupon groups"
              />
            }
            description={
              <IDHFormattedMessage
                id="ws_coupon_groups_description"
                defaultMessage="Here you can create and manage groups of discount coupons used in campaigns."
              />
            }
          />
        </div>

        {!listLoading && (
          <div className="coupon-groups-table__buttons">
            <Button
              variant="blue"
              textWithIcon
              size="small"
              onClick={() => setShowAddCouponGroupModal(true)}
            >
              <PlusIcon />
              <IDHFormattedMessage
                id="ws_create_coupon_group"
                defaultMessage="Create a Coupon Group"
              />
            </Button>

            <Button
              variant="white"
              size="small"
              textWithIcon
              onClick={openConfiguration}
            >
              <ToolIcon />
              <IDHFormattedMessage
                id="ws_store_configuration"
                defaultMessage="Store configuration"
              />
            </Button>

            <Button
              variant="white"
              textWithIcon
              size="small"
              onClick={() => {
                dispatch(getCouponDataImportStrategies(activeWorkspaceUuid));
                setShowDataImportModal(true);
              }}
            >
              <ImportIcon />
              <IDHFormattedMessage
                id="ws_coupon_redemptions_data_import"
                defaultMessage="Import coupon redemptions"
              />
            </Button>

            <Button
              variant="white"
              size="small"
              textWithIcon
              onClick={() => setShowLearnMoreModal(true)}
            >
              <QuestionMarkIcon />
              <IDHFormattedMessage
                id="ws_learn_more"
                defaultMessage="Learn more"
              />
            </Button>
          </div>
        )}
      </div>

      {renderContent()}

      {showLearnMoreModal && (
        <CouponsLearnModeModal onClose={() => setShowLearnMoreModal(false)} />
      )}

      {showAddCouponsModal && (
        <AddCouponGroupModal
          onClose={() => setShowAddCouponGroupModal(false)}
        />
      )}

      {showDataImportModal && (
        <CouponDataImportModal onClose={() => setShowDataImportModal(false)} />
      )}
    </div>
  );
}
