import classNames from "classnames";
import "./RatingSelector.scss";
import React from "react";
import { ReactComponent as StarIcon } from "src/images/star.svg";
import { DateObject } from "src/app/project/Project";
import { uuidv7 as uuid } from "uuidv7";

export enum RatingSelectorSize {
  Small = "small",
  Medium = "medium",
}

interface RatingSelectorInterface {
  length?: number;
  disabled?: boolean;
  value: number;
  onValueChange?: (
    newValue: string | boolean | DateObject | null | number,
  ) => void;
  size?: RatingSelectorSize;
}

export default function RatingSelector({
  length = 5,
  disabled = false,
  value,
  onValueChange,
  size,
}: RatingSelectorInterface) {
  const handleOnClick = (i: number) => {
    if (typeof onValueChange === "function") {
      onValueChange(i !== value ? i : null);
    }
  };

  return (
    <div className="rating-selector">
      {Array.from({ length }).map((elem, i) => (
        <StarIcon
          key={uuid()}
          onClick={() => !disabled && handleOnClick(length - i)}
          className={classNames("rating-selector-item", {
            "rating-selector-item--empty": length - i > value,
            "rating-selector-item--selected": length - i === value,
            "rating-selector-item--disabled": disabled,
            "rating-selector-item--small": size === RatingSelectorSize.Small,
            "rating-selector-item--medium": size === RatingSelectorSize.Medium,
          })}
        />
      ))}
    </div>
  );
}
