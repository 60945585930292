import { addSpaceIfNeeded } from "./addSpaceIfNeeded";

export const addCreatorToLayout = (creator, newLayout, format) => {
  const lastEl = addSpaceIfNeeded("creator", newLayout, format);

  const x = 0;
  const y = lastEl.y + lastEl.h;
  const w = 12;
  const h = 2;

  const key = `creator-${creator.title
    .replaceAll(" ", "-")
    ?.replaceAll(".", "")
    .toLowerCase()}-${creator.taskId}`;

  newLayout.push({ i: key, x, y, w, h });
};
