import React, { useState } from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { LanguageSelector } from "src/app/components/LanguageSelector/LanguageSelector";
import { ModalText, ModalTitle } from "src/app/components/Modal/Modal";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import { Button } from "src/app/components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "src/app/methods/showToast";
import { getTranslationsForMember } from "src/redux/main/mainActions";
import axios from "axios";
import { API_URLS } from "src/utils/API_URLS";
import { RootState } from "src/redux/reducers";
import { showErrorToast } from "src/utils/methods";

interface Props {
  onClose: () => void;
}

export const Language: React.FC<Props> = (props) => {
  const { onClose } = props;

  const [selectedLanguage, setSelectedLanguage] = useState<any>({});

  const {
    mainReducer: { locale },
  } = useSelector((state: RootState) => state);

  const languageChanged =
    selectedLanguage.value && locale && selectedLanguage.value !== locale;

  const dispatch = useDispatch();

  const handleConfirm = () => {
    if (languageChanged) {
      updateLanguage();
    }

    showToast(
      "success",
      <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
      <IDHFormattedMessage
        id="ws_language_has_been_changed"
        defaultMessage="Language has been changed!"
      />,
    );
  };

  const updateLanguage = async () => {
    try {
      await axios.put(API_URLS.setLocale, {
        locale: selectedLanguage.value,
      });
      dispatch(getTranslationsForMember());
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  return (
    <>
      <AnimatedDiv>
        <ModalTitle>
          <IDHFormattedMessage id="ws_language" defaultMessage="Language" />
        </ModalTitle>
        <ModalText>
          <IDHFormattedMessage
            id="ws_change_the_language"
            defaultMessage="Change the language used in the platform interface"
          />
        </ModalText>
        <LanguageSelector
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      </AnimatedDiv>

      <SettingsButtons onClose={onClose}>
        <Button
          size="large"
          variant="blue"
          onClick={handleConfirm}
          disabled={!languageChanged}
        >
          <IDHFormattedMessage id="ws_update" defaultMessage="Update" />
        </Button>
      </SettingsButtons>
    </>
  );
};
