import React, { useEffect, useState } from "react";
import "./AddCouponGroupModal.scss";

import { useIntl } from "react-intl";

import Modal, { ModalTitle } from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import { CustomTokenInput } from "src/app/components/CustomTokenInput/CustomTokenInput";
import { translateMessage } from "src/app/methods/translateMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  createCouponGroup,
  getCouponGroups,
  setShouldCloseModal,
} from "src/redux/coupon/couponActions";
import { uuidv7 as uuid } from "uuidv7";
import { RootState } from "src/redux/reducers";

interface Props {
  onClose: () => void;
}

export const AddCouponGroupModal: React.FC<Props> = (props) => {
  const { onClose } = props;

  const [name, setName] = useState("");
  const [codes, setCodes] = useState([]);

  const {
    couponReducer: { shouldCloseModal },
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleConfirm = async () => {
    const coupons = codes.map((code) => ({
      campaign: null,
      code,
      task: null,
      uuid: uuid(),
      isAssigned: false,
      hasAccess: true,
    }));

    await dispatch(createCouponGroup(name, coupons, activeWorkspaceUuid));
    dispatch(getCouponGroups(activeWorkspaceUuid));
  };

  useEffect(() => {
    if (shouldCloseModal) {
      dispatch(setShouldCloseModal(false));
      onClose();
    }
  }, [shouldCloseModal]);

  return (
    <Modal
      className="add-coupon-group-modal"
      onClose={onClose}
      onConfirmClick={handleConfirm}
      confirmButtonText={
        <IDHFormattedMessage id="ws_create" defaultMessage="Create" />
      }
      displayCancelButton
      confirmButtonDisabled={name.length < 3}
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_create_a_coupon_group"
          defaultMessage="Create a Coupon group"
        />
      </ModalTitle>

      <div className="add-coupon-group-modal__input-wrapper">
        <CustomInputLabel htmlFor="name">
          <IDHFormattedMessage id="ws_name" defaultMessage="Name" />
        </CustomInputLabel>
        <CustomInput
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={translateMessage({
            intl,
            id: "ws_enter_group_name",
            defaultMessage: "Enter group name",
          })}
        />
      </div>

      <div className="add-coupon-group-modal__input-wrapper">
        <CustomInputLabel htmlFor="codes">
          <IDHFormattedMessage
            id="ws_promo_codes"
            defaultMessage="Promo Codes"
          />
        </CustomInputLabel>
        <CustomTokenInput
          value={codes}
          onChange={(newValue: any) => setCodes(newValue)}
          placeholder={
            !codes.length
              ? translateMessage({
                  intl,
                  id: "ws_enter_promo_codes_separated_by_spaces",
                  defaultMessage: "Enter promo codes separated by spaces",
                })
              : ""
          }
          separators={[" ", "\n"]}
        />
      </div>
    </Modal>
  );
};
