import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";

import { TooltipPopper } from "../TooltipPopper/TooltipPopper";

import "./TooltipedEllipsis.scss";

interface TooltipedEllipsisProps {
  content: React.ReactNode;
  maxWidth?: number;
  className?: string;
}

export function TooltipedEllipsis({
  content,
  maxWidth = 0,
  className,
}: TooltipedEllipsisProps) {
  const [width, setWidth] = useState(0);
  const observedElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (observedElementRef.current) {
      const observer = new ResizeObserver((entries) => {
        setWidth(entries[0].contentRect.width);
      });

      observer.observe(observedElementRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <TooltipPopper
      content={content}
      className="TooltipedEllipsis"
      contentHidden={Math.ceil(width) < maxWidth}
    >
      <div className="TooltipedEllipsis-wrap" style={{ maxWidth }}>
        <div
          className={classNames(["TooltipedEllipsis-content", className])}
          ref={observedElementRef}
        >
          {content}
        </div>
      </div>
    </TooltipPopper>
  );
}
