import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import "./FieldSwitch.scss";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { ReactComponent as EditIcon } from "../../../images/edit.svg";
import { ReactComponent as TrashCanIcon } from "../../../images/trash-can.svg";
import {
  changeFieldVisibility,
  changeProjectFieldVisibility,
  getProjectDetails,
} from "../../../redux";
import { tableDataType } from "../Table/Table";
import { CustomSwitch } from "../CustomSwitch/CustomSwitch";

interface Props {
  field: any;
  setField: Dispatch<SetStateAction<any>>;
  setShowSetupModal: Dispatch<SetStateAction<boolean>>;
  dataType: tableDataType;
  openDeleteMetaFieldModal?: () => void;
  setDeleteTaskMetaFieldOpen?: Dispatch<SetStateAction<boolean>>;
}

const FieldSwitch: React.FC<Props> = (props) => {
  const {
    field,
    setField,
    setShowSetupModal,
    dataType,
    setDeleteTaskMetaFieldOpen,
  } = props;
  const technicalFieldKeys = ["owner", "project_name"];

  const [isVisible, setIsVisible] = useState<boolean>(field.isVisible);

  const dispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    if (isVisible !== field.isVisible) {
      setIsVisible(field.isVisible);
    }
  }, [field]);

  const changeVisibility = async (newVisibilityValue: boolean) => {
    switch (dataType) {
      case tableDataType.Task:
        dispatch(changeFieldVisibility(field.id, newVisibilityValue));
        break;
      case tableDataType.Project:
        dispatch(changeProjectFieldVisibility(field.uuid, newVisibilityValue));
        break;
      case tableDataType.ProjectDetails:
        await dispatch(
          changeProjectFieldVisibility(field.uuid, newVisibilityValue),
        );
        dispatch(getProjectDetails(projectId));
        break;
      default:
        console.error("Unknown data type.");
        break;
    }
  };

  if (field?.key && technicalFieldKeys.includes(field?.key)) return null;

  return (
    <div className="field-switch" data-qa={field.title}>
      <div className="field-switch__data">
        <span className="field-switch__data-name">{field.title}</span>
        <EditIcon
          className="field-switch__data-edit-icon"
          onClick={() => {
            setField(field);
            setShowSetupModal(true);
          }}
        />
        {setDeleteTaskMetaFieldOpen && field.isRequired === false && (
          <TrashCanIcon
            className="field-switch__data-trash-can-icon"
            onClick={() => {
              setField(field);
              setDeleteTaskMetaFieldOpen(true);
            }}
          />
        )}
      </div>
      <CustomSwitch
        checked={isVisible}
        onChange={() => changeVisibility(!isVisible)}
      />
    </div>
  );
};

export default FieldSwitch;
