import React, { useState } from "react";

import { useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { PreviewText } from "src/app/components/Preview/components/PreviewText";
import Tab from "src/app/components/Tabs/Tab";
import Tabs from "src/app/components/Tabs/Tabs";
import { WorkspacePermissionsEnum } from "src/utils/PermissionsEnums";
import { TaskType } from "src/types";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import { CoverImage } from "src/redux/project/types";
import { RootState } from "src/redux/reducers";
import { GlobalActivityTaskTypeTabWrapper } from "./GlobalActivityTaskTypeTabWrapper";
import { GlobalActivityTabCreatorLists } from "./GlobalActivityTabCreatorLists";
import { GlobalActivityTabCampaigns } from "./GlobalActivityTabCampaigns";

import "src/app/Task/tabs/ActivityTab/ActivityTab.scss";

enum GlobalActivityTabs {
  Campaigns = "Campaigns",
  CreatorLists = "Creator Lists",
}

interface GlobalActivityTabProps {
  taskId: string;
}

export interface GlobalTask {
  id: string;
  rank: string;
  title: string;
  metadata: Metadata[];
  wsProjectUuid: string;
  cover: CoverImage | null;
}

export function GlobalActivityTab({ taskId }: GlobalActivityTabProps) {
  const [activeTab, setActiveTab] = useState<GlobalActivityTabs | TaskType>(
    GlobalActivityTabs.Campaigns,
  );

  const { identity, activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const renderTabContent = () => {
    switch (activeTab) {
      case GlobalActivityTabs.Campaigns:
        return (
          <GlobalActivityTabCampaigns
            workspaceUuid={activeWorkspaceUuid}
            taskId={taskId}
          />
        );
      case GlobalActivityTabs.CreatorLists:
        return (
          <GlobalActivityTabCreatorLists
            workspaceUuid={activeWorkspaceUuid}
            taskId={taskId}
          />
        );
      default:
        return (
          <GlobalActivityTaskTypeTabWrapper
            activeWorkspaceUuid={activeWorkspaceUuid}
            taskId={taskId}
            taskType={activeTab}
          />
        );
    }
  };

  return (
    <div className="activity-tab">
      <PreviewText>
        {activeTab === GlobalActivityTabs.Campaigns && (
          <IDHFormattedMessage
            id="ws_below_you_will_find_a_list_of_campaigns"
            defaultMessage="Below you will find a list of Campaigns on which a given creator appeared."
          />
        )}
        {activeTab === GlobalActivityTabs.CreatorLists && (
          <IDHFormattedMessage
            id="ws_below_you_will_find_a_list_of_creator_lists"
            defaultMessage="Below you will find a list of Creator Lists on which a given creator appeared."
          />
        )}
      </PreviewText>
      <Tabs>
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_campaigns" defaultMessage="Campaigns" />
          }
          active={activeTab === GlobalActivityTabs.Campaigns}
          onClick={() => setActiveTab(GlobalActivityTabs.Campaigns)}
        />
        {identity?.permissions?.workspace?.includes(
          WorkspacePermissionsEnum.CREATOR_DISCOVERY,
        ) && (
          <Tab
            tabText={
              <IDHFormattedMessage
                id="ws_creator_lists"
                defaultMessage="Creator Lists"
              />
            }
            active={activeTab === GlobalActivityTabs.CreatorLists}
            onClick={() => setActiveTab(GlobalActivityTabs.CreatorLists)}
          />
        )}
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_content" defaultMessage="Content" />
          }
          active={activeTab === TaskType.Content}
          onClick={() => setActiveTab(TaskType.Content)}
        />
        <Tab
          tabText={
            <IDHFormattedMessage
              id="ws_publications"
              defaultMessage="Publications"
            />
          }
          active={activeTab === TaskType.Publication}
          onClick={() => setActiveTab(TaskType.Publication)}
        />
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_payments" defaultMessage="Payments" />
          }
          active={activeTab === TaskType.Payment}
          onClick={() => setActiveTab(TaskType.Payment)}
        />
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_actions" defaultMessage="Actions" />
          }
          active={activeTab === TaskType.Action}
          onClick={() => setActiveTab(TaskType.Action)}
        />
      </Tabs>
      {renderTabContent()}
    </div>
  );
}
