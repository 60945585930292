import React from "react";
import "./SimpleContent.scss";
import Pictogram, {
  PictogramVariants,
} from "src/app/components/Pictogram/Pictogram";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { PerformanceElement } from "../../../../Performance";

interface Props {
  data: PerformanceElement;
}

export const SimpleContent: React.FC<Props> = (props) => {
  const { data } = props;

  return (
    <AnimatedDiv className="simple-content">
      <div className="simple-content__title">{data.title}</div>
      <div className="simple-content__value">
        <Pictogram
          identifier={data.icon.name}
          variant={PictogramVariants[data.icon.color]}
        />
        {data.value}
      </div>
    </AnimatedDiv>
  );
};
