import React from "react";
import Modal from "src/app/components/Modal/Modal";
import "./EmbedFormModal.scss";
import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RecruitmentFormDetails } from "src/types";
import { ReactComponent as CopyIcon } from "../../../../../../../../../../images/link-white.svg";
import { ReactComponent as TickIcon } from "../../../../../../../../../../images/tick-green.svg";
import { useCopyToClipboard } from "../../../../../../../../../methods/useCopyToClipboard";

interface EmbedFormModalProps {
  onClose: () => void;
  recruitmentFormDetails: RecruitmentFormDetails;
}

export function EmbedFormModal({
  onClose,
  recruitmentFormDetails,
}: EmbedFormModalProps) {
  const { isCopied, copy } = useCopyToClipboard();
  return (
    <Modal
      className="embed-form-modal-component"
      onClose={onClose}
      buttonsHidden
    >
      <div className="embed-form-modal">
        <div className="embed-form-modal__title">
          <IDHFormattedMessage id="ws_embed_form" defaultMessage="Embed form" />
        </div>
        <textarea
          readOnly
          value={recruitmentFormDetails.recruitmentIframeHtml}
          className="ws-input embed-form-modal__input"
        />
        <SettingsButtons onClose={onClose}>
          {isCopied ? (
            <Button variant="green" size="large">
              <TickIcon />
              <IDHFormattedMessage id="ws_copied" defaultMessage="Copied" />
            </Button>
          ) : (
            <Button
              variant="blue"
              size="large"
              onClick={() => copy(recruitmentFormDetails.recruitmentIframeHtml)}
            >
              <CopyIcon />
              <IDHFormattedMessage id="ws_copy" defaultMessage="Copy" />
            </Button>
          )}
        </SettingsButtons>
      </div>
    </Modal>
  );
}
