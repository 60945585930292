import React, { useState } from "react";
import "./CodeSettingsDropdown.scss";

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";

import { ReactComponent as DotsIcon } from "src/images/dots.svg";
import { ReactComponent as RenameIcon } from "src/images/rename.svg";
import { ReactComponent as DeleteIcon } from "src/images/trash-can.svg";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "src/app/components/Button/Button";
import { Coupon } from "src/redux/coupon/couponReducer";
import { useDispatch, useSelector } from "react-redux";
import { deleteCoupon } from "src/redux/coupon/couponActions";
import { RootState } from "src/redux/reducers";
import { RenameCouponsModal } from "../../modals";

interface Props {
  data: Coupon;
}

export const CodeSettingsDropdown: React.FC<Props> = (props) => {
  const { data } = props;

  const [showRenameCouponModal, setShowRenameCouponModal] = useState(false);

  const {
    couponReducer: { couponGroupDetails },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const handleDeleteCoupon = () => {
    if (!couponGroupDetails.uuid) return;
    dispatch(deleteCoupon(data.uuid, couponGroupDetails.uuid));
  };

  return (
    <Dropdown>
      {(isOpen, setIsOpen) => (
        <>
          <Button
            variant="light-grey"
            size="large"
            onClick={() => setIsOpen(true)}
            className="code-settings-dropdown__button"
          >
            <DotsIcon />
          </Button>

          <DropdownMenu isOpen={isOpen}>
            <DropdownMenuItem onClick={() => setShowRenameCouponModal(true)}>
              <RenameIcon />
              <IDHFormattedMessage id="ws_rename" defaultMessage="Rename" />
            </DropdownMenuItem>
            <DropdownMenuItem
              className="dropdown__menu-item--danger"
              onClick={handleDeleteCoupon}
            >
              <DeleteIcon />
              <IDHFormattedMessage id="ws_remove" defaultMessage="Remove" />
            </DropdownMenuItem>
          </DropdownMenu>

          {showRenameCouponModal && (
            <RenameCouponsModal
              onClose={() => setShowRenameCouponModal(false)}
              data={data}
            />
          )}
        </>
      )}
    </Dropdown>
  );
};
