import React from "react";
import "./BrandSafetyEmailNotificationCell.scss";

import axios from "axios";

import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import { showToast } from "src/app/methods/showToast";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { BrandSafetyEmailNotificationCellTooltip } from "./BrandSafetyEmailNotificationCellTooltip/BrandSafetyEmailNotificationCellTooltip";
import CONSTS from "../../../../helpers/consts";
import { CustomSwitch } from "../../../../../../../../components/CustomSwitch/CustomSwitch";

function BrandSafetyEmailNotificationCell({
  listItem,
  isBrandSafetyEmailNotificationEnabled,
  setIsBrandSafetyEmailNotificationEnabled,
}) {
  const { socialProvider, canManageList = false } = listItem;

  const changeListBrandSafetyEmailNotification = () => {
    const newIsBrandSafetyEmailNotificationEnabled =
      !isBrandSafetyEmailNotificationEnabled;
    setIsBrandSafetyEmailNotificationEnabled(
      newIsBrandSafetyEmailNotificationEnabled,
    );
    axios
      .post(CONSTS.changeListBrandSafetyEmailNotification, {
        listId: listItem.listId,
        isBrandSafetyEmailNotificationEnabled:
          newIsBrandSafetyEmailNotificationEnabled,
      })
      .catch((error) => {
        error?.response?.data?.content?.errors[0] &&
          showToast("error", "Error", error.response.data.content.errors[0]);
        setIsBrandSafetyEmailNotificationEnabled(
          !newIsBrandSafetyEmailNotificationEnabled,
        );
      })
      .then((res) => {
        if (!res.data?.content) {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id="ws_front_error_while_change_brand_safety_email_notification"
              defaultMessage="Error occure while change Brand Safety email notification status"
            />,
          );
          setIsBrandSafetyEmailNotificationEnabled(
            !newIsBrandSafetyEmailNotificationEnabled,
          );
        }
      });
  };

  return (
    <div className="influencers-list__cell--brand-safety-email-notification-info brand-safety-email-notification-info">
      <div className="brand-safety-email-notification-info__col">
        <div className="brand-safety-email-notification-info__name-wrapper">
          {listItem.canManageList ? (
            <CustomSwitch
              checked={isBrandSafetyEmailNotificationEnabled}
              onChange={() => changeListBrandSafetyEmailNotification()}
            />
          ) : (
            <>
              {!isBrandSafetyEmailNotificationEnabled ? (
                <MaterialTooltip
                  contentClassName="campaing-info-tooltip"
                  content={<BrandSafetyEmailNotificationCellTooltip />}
                >
                  <CustomSwitch
                    checked={isBrandSafetyEmailNotificationEnabled}
                    disabled
                    onChange={() => undefined}
                  />
                </MaterialTooltip>
              ) : (
                <CustomSwitch
                  checked={isBrandSafetyEmailNotificationEnabled}
                  disabled
                  onChange={() => undefined}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BrandSafetyEmailNotificationCell;
