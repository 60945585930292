import classNames from "classnames";
import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as FollowersIcon } from "../../../../../images/profile-data/followers.svg";
import { formatNumber } from "../../../../../utils/methods";
import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";

function FollowersRange(props) {
  const { followersRange, onProjectDetails, height } = props;

  const showContent = !height || height > 3;

  return (
    <ProfileBox onProjectDetails className="profile-box__followers-range">
      <PBHeader
        onProjectDetails={onProjectDetails}
        icon={<FollowersIcon />}
        title={
          <IDHFormattedMessage id="ws_followers" defaultMessage="Followers" />
        }
        onShowcase={height}
      />
      <div
        className={classNames("profile-box__followers-range-count", {
          "profile-box__followers-range-count--on-generator": height,
        })}
      >
        {formatNumber(followersRange.min)} <span>-</span>{" "}
        {formatNumber(followersRange.max)}
      </div>
    </ProfileBox>
  );
}

export { FollowersRange };
