import {
  setActiveSortingColumn,
  setSelectedFilters,
  setSortingDirection,
} from "../../../../../../redux";

export const loadLocalProjectSettings = (
  projectId,
  data,
  activeTab,
  dispatch,
) => {
  const settingsStr = localStorage.getItem("ws-project-settings");
  const settings = JSON.parse(settingsStr || "[]");
  if (settings && settings.length > 0) {
    const result = settings.find((project) => project?.projectId === projectId);
    if (result && result[activeTab]) {
      dispatch(setActiveSortingColumn(result[activeTab].activeSortingColumn));
      dispatch(
        setSelectedFilters({ selections: result[activeTab].selectedFilters }),
      );
      dispatch(setSortingDirection(result[activeTab].sortingDirection));
    } else if (result && result[activeTab] == null) {
      dispatch(setActiveSortingColumn(""));
      dispatch(setSelectedFilters({ selections: [] }));
      dispatch(setSortingDirection("sortingDescending"));
    }
  }
};
