export enum SettingsContext {
  Workspace = "workspace",
  Project = "project",
  Task = "task",
  Member = "member",
}

export enum SettingsTab {
  General = "general",
  Members = "members",
  Teams = "wsTeams",
  CouponsConfiguration = "wsCouponsConfiguration",
  WorkspaceSetup = "workspaceSetup",
  CreatorDiscovery = "creatorDiscovery",
  Automations = "automations",
  Extensions = "extensions",
  Account = "account",
  Language = "language",
  ManageFields = "globalMetaFields",
  Coupons = "wsCoupons",
  CreatorDatabaseConfiguration = "wsCreatorDatabaseConfiguration",
  Integrations = "integrations",
}

export enum WorkspaceSettingsTab {
  Members = "members",
  CreatorDiscovery = "creatorDiscovery",
}

export enum ProjectSettingsTab {
  General = "general",
  Members = "members",
  Automations = "automations",
  Extensions = "extensions",
}

export enum TaskSettingsTab {
  Members = "members",
  Extensions = "extensions",
}

export enum MemberSettingsTab {
  Account = "account",
  Language = "language",
}

export enum ActivityLogOperationType {
  WsTaskCreated = "ws_task_created",
  WsGlobalTaskCreated = "ws_global_task_created",
  WsGlobalTaskMetaValueUpdated = "ws_global_task_meta_value_updated",
  WsGlobalTaskMetaValueDeleted = "ws_global_task_meta_value_deleted",
  WsGlobalTaskMetaFieldCreated = "ws_global_task_meta_field_created",
  WsGlobalTaskMetaFieldDeleted = "ws_global_task_meta_field_deleted",
  WsGlobalTaskMetaFieldUpdated = "ws_global_task_meta_field_updated",
  WsGlobalTaskMetaFieldSelectOptionsUpdated = "ws_global_task_meta_field_select_options_updated",
  WsRecruitmentTaskCreated = "ws_recruitment_task_created",
  WsTaskRankUpdated = "ws_task_rank_updated",
  WsTaskDuplicated = "ws_task_duplicated",
  WsTaskDeleted = "ws_task_deleted",
  WsTaskMetaFieldUpdated = "ws_task_meta_field_updated",
  WsTaskMetaFieldCreated = "ws_task_meta_field_created",
  WsTaskMetaFieldDeleted = "ws_task_meta_field_deleted",
  WsTaskMetaValueUpdated = "ws_task_meta_value_updated",
  WsTaskMetaValueDataUpdated = "ws_task_meta_value_data_updated",
  WsTaskCommentCreated = "ws_task_comment_created",
  WsContentProposalElementCreated = "ws_content_proposal_element_created",
  WsContentProposalElementModerated = "ws_content_proposal_element_moderated",
  WsContentProposalCreated = "ws_content_proposal_created",
  WsContentProposalDeleted = "ws_content_proposal_deleted",
  WsContentProposalModerated = "ws_content_proposal_moderated",
  WsProjectCreated = "ws_project_created",
  WsProjectDeleted = "ws_project_deleted",
  WsProjectDuplicated = "ws_project_duplicated",
  WsProjectMetaFieldUpdated = "ws_project_meta_field_updated",
  WsProjectMetaFieldCreated = "ws_project_meta_field_created",
  WsProjectMetaFieldDeleted = "ws_project_meta_field_deleted",
  WsProjectMetaValueUpdated = "ws_project_meta_value_updated",
  WsProjectMetaValueDeleted = "ws_project_meta_value_deleted",
  WsDictionaryMetaFieldCreated = "ws_dictionary_meta_field_created",
  WsDictionaryMetaValueUpdated = "ws_dictionary_meta_value_updated",
  WsDictionaryElementCreated = "ws_dictionary_element_created",
  WsGlobalTaskMetaFieldWorkspaceSubmissionFormUpdated = "ws_global_task_meta_field_workspace_submission_form_updated",
  WsCreatorSubmissionFormStatusUpdated = "ws_creator_submission_form_status_updated",
  WsWorkspaceMemberRoleChanged = "ws_workspace_member_role_changed",
  WsWorkspaceMemberAdded = "ws_member_added_to_workspace",
  WsWorkspaceMemberRemoved = "ws_member_removed_from_workspace",
  WsProjectMemberAdded = "ws_project_member_added",
  WsProjectMemberRemoved = "ws_project_member_removed",
  WsProjectMemberChangedRole = "ws_project_member_changed_role",
}

export const ActivityLogsWithoutProjectContext = [
  ActivityLogOperationType.WsGlobalTaskCreated,
  ActivityLogOperationType.WsGlobalTaskMetaValueUpdated,
  ActivityLogOperationType.WsGlobalTaskMetaValueDeleted,
  ActivityLogOperationType.WsGlobalTaskMetaFieldCreated,
  ActivityLogOperationType.WsGlobalTaskMetaFieldUpdated,
  ActivityLogOperationType.WsGlobalTaskMetaFieldSelectOptionsUpdated,
  ActivityLogOperationType.WsDictionaryMetaFieldCreated,
  ActivityLogOperationType.WsDictionaryMetaValueUpdated,
  ActivityLogOperationType.WsDictionaryElementCreated,
  ActivityLogOperationType.WsGlobalTaskMetaFieldWorkspaceSubmissionFormUpdated,
  ActivityLogOperationType.WsCreatorSubmissionFormStatusUpdated,
];

export enum ContextType {
  wsProject = "wsProject",
  wsWorkspace = "wsWorkspace",
}
