import React, { useContext, useMemo, useRef } from "react";
import "./AccessFields.scss";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { moveInArray } from "src/utils/methods";
import classNames from "classnames";
import SingleAccessField from "./components/SingleAccessField";
import { AccessLinkFieldContext } from "../AccessLinkField";

export function AccessFields() {
  const {
    selectedVals,
    setSelectedVals,
    tasksColumns,
    projectMetaFields,
    options,
  } = useContext(AccessLinkFieldContext);

  const accessFieldsRef = useRef(null);

  const pushValues = (field, required, editable, wsMetaFieldDescription) => {
    setSelectedVals([
      ...selectedVals,
      {
        field,
        required,
        mode: editable ? "write" : "read",
        wsMetaFieldDescription,
      },
    ]);
    // scroll down
    setTimeout(() => {
      accessFieldsRef.current.scrollTo({
        top: accessFieldsRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 150);
  };

  const updateValues = (
    index,
    field,
    required,
    editable,
    wsMetaFieldDescription,
  ) => {
    const tmp = [...selectedVals];
    tmp[index] = {
      field,
      required,
      mode: editable ? "write" : "read",
      wsMetaFieldDescription,
    };
    setSelectedVals([...tmp]);
  };

  const dropValue = (index) => {
    const tmp = [...selectedVals];
    tmp.splice(index, 1);
    setSelectedVals(tmp);
  };

  const handleDragEnd = async (result) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }
    const { destination, source } = result;

    const indexDiff = selectedVals.filter(
      (item) => item?.field.disabled,
    ).length;

    setSelectedVals(
      moveInArray(
        selectedVals,
        destination.index + indexDiff,
        source.index + indexDiff,
      ) || [],
    );
  };

  const getElementTop = (style) => {
    if (style && "top" in style) {
      return style.top - 27;
    }
    return "auto";
  };

  const nonDeletableFieldsCount = useMemo(
    () => selectedVals.filter((item) => item?.field?.disabled).length,
    [selectedVals],
  );

  return (
    <div className="access-fields" ref={accessFieldsRef}>
      {selectedVals
        .filter((item) => item?.field.disabled)
        .map((v, i) => (
          <SingleAccessField
            key={v?.field?.value}
            index={i}
            field={v?.field}
            required={v?.required}
            editable={v?.mode !== "read"}
            tasksColumns={tasksColumns}
            projectMetaFields={projectMetaFields}
            updateValues={updateValues}
            dropValue={dropValue}
            options={options}
            selectedVals={selectedVals}
            isFieldDisabled={v?.field?.disabled ?? false}
            getElementTop={getElementTop}
          />
        ))}
      <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided, snapshot) => (
            <div
              className={classNames("access-fields__droppable-wrapper", {
                "access-fields__droppable-wrapper--dragging":
                  snapshot.isDraggingOver,
              })}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {selectedVals
                .filter((item) => !item?.field.disabled)
                .map((v, i) => (
                  <SingleAccessField
                    key={v?.field?.value}
                    index={i + nonDeletableFieldsCount}
                    dragIndex={i}
                    field={v?.field}
                    required={v?.required}
                    editable={v?.mode !== "read"}
                    tasksColumns={tasksColumns}
                    projectMetaFields={projectMetaFields}
                    updateValues={updateValues}
                    dropValue={dropValue}
                    options={options}
                    selectedVals={selectedVals}
                    isFieldDisabled={v?.field?.disabled ?? false}
                    getElementTop={getElementTop}
                  />
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <SingleAccessField
        key={selectedVals.length}
        tasksColumns={tasksColumns}
        projectMetaFields={projectMetaFields}
        pushValues={pushValues}
        dropValue={dropValue}
        options={options}
        selectedVals={selectedVals}
        getElementTop={getElementTop}
      />
    </div>
  );
}
