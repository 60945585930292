import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as AudienceInterestsIcon } from "../../../../../images/profile-data/audience-interests.svg";
import { ReactComponent as TickPurple } from "../../../../../images/tick-purple.svg";

import Tooltip from "../../../../components/Tooltip/Tooltip";
import { InterestsList } from "../components/InterestsList/InterestsList";
import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";
import { ProgressBarList } from "../components/ProgressBarList/ProgressBarList";

export function AudienceInterests(props) {
  const { audienceInterests, height, onProjectDetails } = props;

  const onShowcase = height;

  return (
    <ProfileBox onProjectDetails={onProjectDetails} block={!onProjectDetails}>
      <PBHeader
        icon={<AudienceInterestsIcon />}
        title={
          <IDHFormattedMessage
            id="ws_audience_interests"
            defaultMessage="Audience Interests"
          />
        }
        tooltip={
          !onShowcase && (
            <Tooltip
              center
              content={
                <IDHFormattedMessage
                  id="ws_tooltip_audience_interests"
                  defaultMessage="Top 10 the most popular audience interests"
                />
              }
            />
          )
        }
        onShowcase={onShowcase}
        onProjectDetails={onProjectDetails}
      />

      {onProjectDetails ? (
        <InterestsList
          data={audienceInterests}
          icon={<TickPurple />}
          fullWidthItem={onProjectDetails}
        />
      ) : (
        <ProgressBarList
          data={audienceInterests}
          icon={<TickPurple />}
          barColor="#7848C7"
          backgroundColor="rgba(120, 72, 199, 0.1)"
        />
      )}
    </ProfileBox>
  );
}
