import React, { useRef, useState } from "react";
import "./IconColorSelector.scss";

import classNames from "classnames";
import { PictogramVariants } from "../Pictogram/Pictogram";
import useOnClickOutside from "../../methods/useOnClickOutside";

import { colorNameToHex } from "../../../utils/colorFunctionsUtils";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";

const SELECTOR_COLORS = {
  social: [
    PictogramVariants.Instagram,
    PictogramVariants.TikTok,
    PictogramVariants.YouTube,
    PictogramVariants.Twitter,
    PictogramVariants.Facebook,
    PictogramVariants.Discord,
    PictogramVariants.Twitch,
    PictogramVariants.Snapchat,
  ],
  others: [
    // 1
    PictogramVariants.Beaver,
    PictogramVariants.Deer,
    PictogramVariants.LemonCurry,
    PictogramVariants.GreenCrayola,
    PictogramVariants.SilverLakeBlue,
    PictogramVariants.MediumPurpleAlt,
    PictogramVariants.PersianPink,
    PictogramVariants.FandangoPink,
    // 2
    PictogramVariants.PaleSilver,
    PictogramVariants.DarkVanilla,
    PictogramVariants.GoldCrayola,
    PictogramVariants.PearlAqua,
    PictogramVariants.LightSteelBlueAlt,
    PictogramVariants.Vodka,
    PictogramVariants.LightHotPink,
    PictogramVariants.MetallicPink,
    // 3
    PictogramVariants.LavenderBlue,
    PictogramVariants.PaleCornflowerBlue,
    PictogramVariants.JordyBlue,
    PictogramVariants.JordyBlueAlt,
    PictogramVariants.CornflowerBlue2,
    PictogramVariants.CornflowerBlue3,
    PictogramVariants.VeryLightBlue,
    PictogramVariants.RoyalBlueAlt,
    // 4
    PictogramVariants.LightSilver,
    PictogramVariants.LightPeriwinkle,
    PictogramVariants.CadetBlueCrayola,
    PictogramVariants.CoolGrey,
    PictogramVariants.SlateGrayAlt,
    PictogramVariants.OuterSpace,
    PictogramVariants.Gunmetal,
    PictogramVariants.EerieBlack,
  ],
};

export function IconColorSelector(props) {
  const { selectedColor, setSelectedColor } = props;

  const [showMenu, setShowMenu] = useState(false);

  const selectorRef = useRef();

  useOnClickOutside(selectorRef, () => setShowMenu(false));

  const getColor = (variant) => {
    let color = "";

    switch (variant) {
      case PictogramVariants.Instagram:
        color =
          "linear-gradient(180deg, #5A5DCA 0%, #BB368F 32.29%, #E13568 70.08%, #F8CD78 100%)";

        break;
      case PictogramVariants.TikTok:
        color = "linear-gradient(180deg, #00F2EA 0%, #DF0045 100%)";

        break;
      case PictogramVariants.YouTube:
        color = "#FF0302";

        break;
      case PictogramVariants.Twitter:
        color = "#000000";

        break;
      case PictogramVariants.Facebook:
        color = "#1877F2";

        break;
      case PictogramVariants.Discord:
        color = "#5865F2";

        break;
      case PictogramVariants.Twitch:
        color = "#9146FF";

        break;
      case PictogramVariants.Snapchat:
        color = "#FFFB54";

        break;

      default:
        // get enum key (color name):
        color = colorNameToHex(PictogramVariants[variant].toLowerCase());

        break;
    }

    return color;
  };

  const selectColor = (color) => {
    setSelectedColor(PictogramVariants[color]);
    setShowMenu(false);
  };

  return (
    <div ref={selectorRef} className="icon-color-selector">
      <div
        className="icon-color-selector__button"
        onClick={() => setShowMenu(true)}
      >
        <span
          className="icon-color-selector__button-color"
          style={{ background: getColor(PictogramVariants[selectedColor]) }}
        />
      </div>

      {showMenu && (
        <div
          className="icon-color-selector__menu"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="icon-color-selector__menu-name">
            <IDHFormattedMessage
              id="ws_social_color_themes"
              defaultMessage="Social color themes"
            />
          </span>
          <div className="icon-color-selector__menu-section">
            {SELECTOR_COLORS.social.map((color) => (
              <span
                key={color}
                onClick={() => selectColor(color)}
                className={classNames("icon-color-selector__menu-item", {
                  "icon-color-selector__menu-item--selected":
                    color === selectedColor,
                })}
              >
                <span
                  className="icon-color-selector__menu-item-color"
                  style={{ background: getColor(color) }}
                />
              </span>
            ))}
          </div>

          <span className="icon-color-selector__menu-name">
            <IDHFormattedMessage id="ws_others" defaultMessage="Others" />
          </span>
          <div className="icon-color-selector__menu-section">
            {SELECTOR_COLORS.others.map((color) => (
              <span
                key={color}
                onClick={() => selectColor(color)}
                className={classNames("icon-color-selector__menu-item", {
                  "icon-color-selector__menu-item--selected":
                    color === selectedColor,
                })}
              >
                <span
                  className="icon-color-selector__menu-item-color"
                  style={{ background: getColor(color) }}
                />
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
