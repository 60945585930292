import React, { useMemo } from "react";
import Loader from "../../../components/Loader/Loader";
import "./ShowcaseLoader.scss";

export const ShowcaseLoader = () => {
  const LoaderComponent = useMemo(
    () => (
      <div className="showcase-loader">
        <Loader />
      </div>
    ),
    [],
  );

  return LoaderComponent;
};
