import React from "react";
import { PostsDataBox } from "../components/ReportTemplate/components/PostsDataBox/PostsDataBox";

export const renderPostsData = (data, GridBox, postsData) => {
  const key = data.label;

  const post = postsData.find((item) => item.uuid === data.uuid);
  const postData = {
    ...data,
    ...post,
  };

  return (
    <GridBox key={key} id={key}>
      <PostsDataBox data={postData} />
    </GridBox>
  );
};
