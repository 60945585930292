import classNames from "classnames";
import React from "react";
import "./ProfileBox.scss";

import { splitNumbers } from "../../../../../../utils/methods";
import ProgressBar from "../../../../../components/ProgressBar/ProgressBar";

interface Props {
  className?: string;
  children: React.ReactNode;
  block?: boolean;
  small?: boolean;
  onProjectDetails?: boolean;
  adaptiveHeight?: boolean;
}

const ProfileBox: React.FC<Props> = (props) => {
  const {
    className,
    children,
    block,
    small,
    onProjectDetails,
    adaptiveHeight,
  } = props;

  return (
    <div
      className={classNames(
        "profile-box",
        {
          "profile-box--block": block,
          "profile-box--small": small,
          "profile-box--on-project-details": onProjectDetails,
          "profile-box--adaptive-height": adaptiveHeight,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default ProfileBox;

interface HeaderProps {
  children: React.ReactNode;
}

export function PBHeader(props: any) {
  const { count, title, icon, tooltip, onShowcase, onProjectDetails } = props;

  return (
    <div
      className={classNames("profile-box__header", {
        "profile-box__header--on-showcase": onShowcase,
        "profile-box__header--showcase-bio": title === "Bio" && onShowcase,
        "profile-box__header--on-project-details": onProjectDetails,
      })}
    >
      {icon}
      {count || count === 0 ? (
        <div className="profile-box__header-count">{splitNumbers(count)}</div>
      ) : null}
      <div
        className={classNames("profile-box__header-title", {
          "profile-box__header-title--big": !count && count !== 0,
        })}
      >
        {title}
      </div>
      {tooltip}
    </div>
  );
}

export function PBHeaderWithProgressBar(props: any) {
  const { count, title, icon, tooltip, onShowcase, onProjectDetails } = props;

  return (
    <div
      className={classNames("profile-box__header", {
        "profile-box__header--on-showcase": onShowcase,
        "profile-box__header--showcase-bio": title === "Bio" && onShowcase,
        "profile-box__header--on-project-details": onProjectDetails,
      })}
    >
      {icon}
      <div className={classNames("profile-box__header-title--with-scroll")}>
        {title}
      </div>
      {count || count === 0 ? (
        <div className="profile-box__header-progress-bar">
          <ProgressBar
            percent={Math.round(count || 0)}
            barColor="#8358FF"
            progressSeparator={null}
            backgroundColor="rgba(237, 237, 239, 1)"
          />
        </div>
      ) : null}
      {count || count === 0 ? (
        <div className="profile-box__header-count">{splitNumbers(count)}%</div>
      ) : null}
      {tooltip}
    </div>
  );
}

interface TitleProps {
  children: React.ReactNode;
}

export const PBTitle: React.FC<TitleProps> = (props) => {
  const { children } = props;

  return <h4 className="profile-box__title">{children}</h4>;
};

export function PBContent(props: any) {
  const { children, column } = props;

  return (
    <div
      className={classNames("profile-box__content", {
        "profile-box__content--column": column,
      })}
    >
      {children}
    </div>
  );
}

export function PBParam(props: any) {
  const {
    name,
    firstValue,
    secondValue,
    thirdValue,
    fourthValue,
    tooltip,
    numberOfParams,
    firstValueType,
    secondValueType,
  } = props;

  return (
    <>
      <div className="profile-box__parameter">
        <h5 className="profile-box__parameter-name">{name}</h5>
        {tooltip}
        {numberOfParams === 1 && (
          <div className="profile-box__parameter-value">
            {splitNumbers(firstValue)}
          </div>
        )}
      </div>
      {numberOfParams === 2 && (
        <div className="profile-box__parameter-values">
          <div className="profile-box__value-type">
            {splitNumbers(firstValueType)}
          </div>
          <div className="profile-box__parameter-value">
            {splitNumbers(firstValue)}
          </div>
          {secondValue && (
            <div className="profile-box__parameter-value profile-box__parameter-value--grey">
              {splitNumbers(secondValue)}
            </div>
          )}
          <div className="profile-box__value-type profile-box__value-type--second">
            {secondValueType}
          </div>
          <div className="profile-box__parameter-value">
            {splitNumbers(thirdValue)}
          </div>
          {fourthValue && (
            <div className="profile-box__parameter-value profile-box__parameter-value--grey">
              {splitNumbers(fourthValue)}
            </div>
          )}
        </div>
      )}
    </>
  );
}
