import React, { useState } from "react";

import axios from "axios";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { IntlShape, WrappedComponentProps, injectIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import Modal, { ModalTitle } from "../../components/Modal/Modal";
import CustomInput from "../../components/CustomInput/CustomInput";
import { API_URLS } from "../../../utils/API_URLS";
import { showToast } from "../../methods/showToast";

interface Props extends WrappedComponentProps<"intl"> {
  onClose: () => void;
  taskId: string;
  publicationUrl?: string;
  intl: IntlShape;
}

function UpdatePublicationLinkModal(props: Props) {
  const { onClose, taskId, publicationUrl, intl } = props;

  const [value, setValue] = useState<string>(publicationUrl || "");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await axios.put(API_URLS.setPublicationMetaValue, {
        taskUuid: taskId,
        publicationUrl: value,
      });
    } catch (error) {
      console.error(error);
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_something_wrong_with_adding_link"
          defaultMessage="Something went wrong during adding link"
        />,
      );
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      className="update-publication-link-modal"
      onClose={onClose}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={handleSubmit}
      confirmButtonDisabled={value.length === 0 || publicationUrl === value}
    >
      <ModalTitle>
        {publicationUrl ? (
          <IDHFormattedMessage
            id="ws_edit_publication_link"
            defaultMessage="Edit Publication Link"
          />
        ) : (
          <IDHFormattedMessage
            id="ws_add_publication_link"
            defaultMessage="Add Publication Link"
          />
        )}
      </ModalTitle>
      <form onSubmit={handleSubmit}>
        <CustomInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={translateMessage({
            intl,
            id: "ws_enter_publication_link",
            defaultMessage: "Enter publication link",
          })}
          fullWidth
        />
      </form>
    </Modal>
  );
}

export default injectIntl(UpdatePublicationLinkModal);
