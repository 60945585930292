import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { wsAxiosGet } from "src/helpers/wsAxios";
import { TaskType } from "src/types";
import { API_URLS } from "src/utils/API_URLS";
import { capitalizeFirstLetter } from "src/utils/methods";
import { ProjectMetaData } from "src/redux/project/types";
import { TasksColumn } from "src/redux/task/taskReducer";
import {
  GlobalProjectAvailableTarget,
  GlobalProjectFormulaSummaryField,
  GlobalTaskMetricsValues,
} from "./types";
import { GlobalField } from "../GlobalFields/GlobalFields";

export const getGlobalProjectFormulaSummaryFields = async (
  taskType: TaskType | null,
  wsWorkspaceUuid: string,
) =>
  wsAxiosGet<{ content: GlobalProjectFormulaSummaryField[] }>({
    url: API_URLS.getGlobalProjectFormulaSummaryFields
      .replace(":wsWorkspaceUuid:", wsWorkspaceUuid)
      .replace(":taskType:", taskType ?? "null"),
    errorMessage: (
      <IDHFormattedMessage
        id="ws_something_went_wrong"
        defaultMessage="Something went wrong"
      />
    ),
  });

export const getGlobalProjectAvailableTargets = async (
  taskType: TaskType | null,
  wsWorkspaceUuid: string,
) =>
  wsAxiosGet<{ content: GlobalProjectAvailableTarget[] }>({
    url: API_URLS.getGlobalProjectAvailableTargets
      .replace(":wsWorkspaceUuid:", wsWorkspaceUuid)
      .replace(":taskType:", taskType ?? "null"),
    errorMessage: (
      <IDHFormattedMessage
        id="ws_something_went_wrong"
        defaultMessage="Something went wrong"
      />
    ),
  });

export const getGlobalTaskMetricsValues = async (
  taskType: TaskType | null,
  wsWorkspaceUuid: string,
) =>
  wsAxiosGet<{ content: GlobalTaskMetricsValues[] }>({
    url: API_URLS.getGlobalProjectProviderMetricsPossibleValues
      .replace(":wsWorkspaceUuid:", wsWorkspaceUuid)
      .replace(":taskType:", taskType ?? "null"),
    errorMessage: (
      <IDHFormattedMessage
        id="ws_something_went_wrong"
        defaultMessage="Something went wrong"
      />
    ),
  });

export const generateGlobalTaskOptions = async (
  taskType: TaskType | null,
  wsWorkspaceUuid: string,
) => {
  let options: {
    label: string;
    value: { id: string; currencyCode: string | null };
  }[] = [];

  const summaryFields = await getGlobalProjectFormulaSummaryFields(
    taskType,
    wsWorkspaceUuid,
  );

  if (summaryFields) {
    options = summaryFields.content.map((option) => ({
      label: `${taskType ? `${capitalizeFirstLetter(taskType)} > ${option.metaFieldName}` : option.metaFieldName}`,
      value: {
        id: option.metaFieldUuid,
        currencyCode: option.metaFieldCurrencyCode,
      },
    }));
  }

  return options;
};

export const generateGlobalProjectOptions = async (
  taskType: TaskType | null,
  wsWorkspaceUuid: string,
) => {
  const data = await getGlobalProjectAvailableTargets(
    taskType,
    wsWorkspaceUuid,
  );

  if (!data) return;

  return data.content.map((option) => ({
    label: option.targetName,
    value: {
      id: option.wsGlobalProjectMetaFieldUuid,
    },
  }));
};

export const ALLOWED_ELEMENT_TYPES = ["number", "percent", "currency", "date"];

export const generateOptionsFromGlobalFields = (
  globalFields: Array<GlobalField>,
  selectedTaskTypeValue: TaskType | null,
) => {
  return globalFields
    .filter(
      (field) =>
        field.taskType === selectedTaskTypeValue &&
        ALLOWED_ELEMENT_TYPES.includes(field.type),
    )
    .map((field) => ({
      label: `Overview > ${field.name}`,
      value: {
        id: field.uuid,
      },
    }));
};

interface GenerateTaskOptionsProps {
  tasksColumns: TasksColumn[];
  isNewOverviewForClient: boolean | undefined;
  taskType: TaskType;
}

export const generateTaskOptions = ({
  tasksColumns,
  isNewOverviewForClient,
  taskType,
}: GenerateTaskOptionsProps) =>
  tasksColumns
    .filter(
      (item) =>
        ALLOWED_ELEMENT_TYPES.includes(item.metaFieldType) &&
        (isNewOverviewForClient ? item.metaFieldShared : true),
    )
    .map((item) => ({
      label: `${capitalizeFirstLetter(taskType)} > ${item.metaFieldName}`,
      value: {
        id: item.metaFieldId,
        currencyCode: item?.data?.currencyCode,
      },
    }));

interface GenerateProjectOptionsProps {
  projectMetaFields: ProjectMetaData[];
  filterCallback: (item: ProjectMetaData) => void;
  prefix?: string;
}

export const generateProjectOptions = ({
  projectMetaFields,
  filterCallback,
  prefix,
}: GenerateProjectOptionsProps) =>
  projectMetaFields.filter(filterCallback).map((item) => ({
    label: prefix
      ? `${prefix} > ${item.name}`
      : `${capitalizeFirstLetter(item.taskType) || "Campaign"} > ${item.name}`,
    value: {
      id: item.uuid,
      currencyCode: item?.data?.currencyCode,
    },
  }));
