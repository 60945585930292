import { toast } from "react-toastify";
import React from "react";

import { ReactComponent as SuccessIcon } from "../../images/toasts/success.svg";
import { ReactComponent as ErrorIcon } from "../../images/toasts/error.svg";
import { ReactComponent as WarningIcon } from "../../images/toasts/warning.svg";
import { ReactComponent as InfoIcon } from "../../images/toasts/info.svg";

export const showToast = (
  type: "success" | "error" | "warning" | "info",
  header: string | React.ReactNode,
  text: string | React.ReactNode,
) => {
  switch (type) {
    case "success":
      toast.success(
        <Message Icon={SuccessIcon} header={header} message={text} />,
      );
      break;
    case "error":
      toast.error(<Message Icon={ErrorIcon} header={header} message={text} />);
      break;
    case "warning":
      toast.warn(<Message Icon={WarningIcon} header={header} message={text} />);
      break;
    case "info":
      toast.info(<Message Icon={InfoIcon} header={header} message={text} />);
      break;
    default:
      console.error("?");
  }
};

interface MessageProps {
  Icon: any;
  header: string | React.ReactNode;
  message: string | React.ReactNode;
}

function Message(props: MessageProps) {
  const { Icon, header, message } = props;
  return (
    <div className="Toastify__toast-body">
      <Icon />
      <div>
        <h6 className="Toastify__toast-body-header toast-header">{header}</h6>
        <span>{message}</span>
      </div>
    </div>
  );
}
