import React from "react";
import { GlossaryWrapper } from "./GlossaryWrapper";
import { GLOSSARY_DATA_PART_TWO } from "./glossaryData";

export function ReportGlossaryPartTwo() {
  return (
    <GlossaryWrapper
      className="report-glossary--small"
      data={GLOSSARY_DATA_PART_TWO}
      iconFolder="Sentiment"
    />
  );
}
