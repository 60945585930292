import React from "react";
import Modal from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

function CrisisDetectionModal(props) {
  const { onClose, onConfirmClick, confirmButtonLoading } = props;

  return (
    <Modal
      onClose={onClose}
      title={
        <IDHFormattedMessage
          id="front_modal_crisis_detection_scanner_launched"
          defaultMessage="Crisis Detection Scanner launched"
        />
      }
      confirmButtonText={
        <IDHFormattedMessage
          id="front_ok_got_it_btn"
          defaultMessage="Ok, got it"
        />
      }
      displayCancelButton={false}
      onConfirmClick={onConfirmClick}
    >
      <div className="modal__text">
        <IDHFormattedMessage
          id="front_modal_crisis_detection_content"
          defaultMessage="From now on, you will be informed about the activities on the selected accounts, which may indicate an image crisis on these accounts. You will receive email notifications."
        />
      </div>
    </Modal>
  );
}

export default CrisisDetectionModal;
