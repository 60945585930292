import React, { useRef } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { useIsOverflow } from "src/app/methods/useIsOverflow";

function TaskMetaFieldName({ field, required = false }) {
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);

  const isRequired = field.valueRequired || required;
  const tooltipId = `task-meta-fields__tooltip-${field.uuid}`;

  const getTooltipContent = () => {
    if (isRequired) {
      return `${field.name} (required)`;
    }
    if (isOverflow) {
      return field.name;
    }
    return null;
  };

  return (
    <div className="task-meta-fields__name-wrapper">
      <div
        className="task-meta-fields__name"
        data-tooltip-id={`task-meta-fields__tooltip-${field.uuid}`}
        data-tooltip-place="bottom"
        ref={ref}
      >
        {field.name}
        {isRequired && (
          <span className="task-meta-fields__name--required">*</span>
        )}
      </div>
      <ReactTooltip id={tooltipId} className="react-tooltip__dark-mode">
        {getTooltipContent()}
      </ReactTooltip>
    </div>
  );
}

export { TaskMetaFieldName };
