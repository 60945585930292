import { showToast } from "src/app/methods/showToast";
import {
  MAX_SIZE_SINGLE_ATTACHMENT,
  getMetaFileParams,
} from "src/utils/uploadFunctionsUtils";
import { uuidv7 as uuid } from "uuidv7";
import { UploadMultiFilesExt } from "./uploadMultiFilesExt";

export const uploadExternalFile = async (
  e,
  fieldId,
  metafieldUuid,
  metaFieldType,
  taskType,
  taskId,
  wsWorkspaceUuid,
  token,
  setFilesData,
  extensionUuid,
  formType,
) => {
  const { target } = e;
  const { files } = target;

  const newFiles = Array.from(files).map((item) => ({
    assetId: uuid(),
    mimeType: item.type,
    publicPreviewUrl: "",
    publicDownloadUrl: "",
    name: item.name,
    loading: true,
    new: true,
    size: item.size,
  }));

  return uploadMultipleFiles(
    e,
    fieldId,
    metafieldUuid,
    metaFieldType,
    taskType,
    taskId,
    () => {
      setFilesData((p) => [...p, ...newFiles]);
    },
    () => {
      setFilesData((p) =>
        p.map((f) => {
          return { ...f, loading: false };
        }),
      );
    },
    wsWorkspaceUuid,
    token,
    newFiles,
    extensionUuid,
    formType,
    setFilesData,
  );
};

export const uploadMultipleFiles = async (
  e,
  fieldId,
  wsMetaFieldUuid,
  wsMetaFieldType,
  taskType,
  wsTaskUuid,
  beforeCallback,
  thenCallback,
  wsWorkspaceUuid,
  token,
  newFiles,
  extensionUuid,
  formType,
) => {
  const { files } = e.target;

  if (!files?.length) return;

  const allowedTypes = [
    "png",
    "jpg",
    "jpeg",
    "pdf",
    "xls",
    "xlsx",
    "doc",
    "docx",
    "pptx",
    "ppt",
    "txt",
    "zip",
    "mp3",
    "mp4",
    "avi",
    "webm",
    "mov",
    "csv",
    "odt",
    "ods",
  ];

  const isTask = wsTaskUuid;
  const { url, parentType } = getMetaFileParams(isTask);

  const uploadFiles = new UploadMultiFilesExt(
    files,
    allowedTypes,
    MAX_SIZE_SINGLE_ATTACHMENT,
    fieldId,
    wsMetaFieldUuid,
    wsMetaFieldType,
    parentType,
    taskType,
    wsTaskUuid,
    wsWorkspaceUuid,
    token,
    newFiles,
    extensionUuid,
  );

  try {
    uploadFiles.validate();
    if (beforeCallback) beforeCallback();
    if (formType === "recruitmentForm") {
      return await uploadFiles.sendAllRequestsForRecruitmentFormField();
    }
    await uploadFiles.sendAllRequestsForExternalTaskField();

    if (thenCallback) thenCallback();
  } catch (err) {
    console.error(err);
    if (!uploadFiles.hasCorrectType || !uploadFiles.hasCorrectSize) {
      showToast("error", "Error", err.message);
    } else {
      showToast("error", "Error", "An error occurred during uploading files.");
    }
  }
};
