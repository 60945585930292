import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { UploadFile } from "src/app/libs/uploadFile";
import { showToast } from "src/app/methods/showToast";
import { API_URLS } from "src/utils/API_URLS";
import { MAX_SIZE_PROJECT_COVER } from "src/utils/uploadFunctionsUtils";

export const uploadExtensionCover = async (
  file: File,
  activeWorkspaceUuid: string,
  wsRecruitmentFormUuid: string,
) => {
  const cover = new UploadFile(
    file,
    [],
    MAX_SIZE_PROJECT_COVER,
    `${API_URLS.getPresignedRequestExtension}?wsWorkspaceUuid=${activeWorkspaceUuid}&wsRecruitmentFormUuid=${wsRecruitmentFormUuid}`,
    "",
    "",
    activeWorkspaceUuid,
  );
  try {
    return await cover.sendRequests();
  } catch (err) {
    showToast(
      "error",
      <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
      <IDHFormattedMessage
        id="ws_something_went_wrong"
        defaultMessage="Something went wrong"
      />,
    );
    console.error(err);
  }
};
