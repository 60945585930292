import React, { ChangeEventHandler } from "react";

interface DropdownMenuSearchProps {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
}

export const DropdownMenuSearch = React.forwardRef<
  HTMLInputElement,
  DropdownMenuSearchProps
>((props, ref) => {
  const { value, onChange, placeholder } = props;

  return (
    <input
      ref={ref}
      className="dropdown__menu-search"
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
});
