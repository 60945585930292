import React, {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  useEffect,
  useState,
} from "react";

import "./Img.scss";

import classNames from "classnames";

interface Props
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  fallbackImageSrc: string;
  fallbackImageClassName?: string;
  className?: string;
}

export const Img: React.FC<Props> = (props) => {
  const { src, fallbackImageSrc, fallbackImageClassName, className, ...rest } =
    props;
  const [SRC, setSrc] = useState(src);
  const [hasError, setHasError] = useState(false);
  const [initialHidden, setInitialHidden] = useState(true);

  useEffect(() => {
    setSrc(src);
  }, [src]);

  const handleLoad = () => {
    setInitialHidden(false);
  };

  const handleError = () => {
    setSrc(fallbackImageSrc);
    setHasError(true);
    setInitialHidden(false);
  };

  return (
    <img
      src={SRC || ""}
      onLoad={handleLoad}
      onError={handleError}
      className={classNames("custom-img", className, {
        fallbackImageClassName: hasError,
        "custom-img--hidden": initialHidden,
      })}
      {...rest}
    />
  );
};

export default Image;
