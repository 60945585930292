import React from "react";
import { SentimentAnalysisBox } from "../components/ReportTemplate/components/SentimentAnalysisBox/SentimentAnalysisBox";

export const renderSentimentAnalysis = (data, GridBox) => {
  const key = data.label;

  return (
    <GridBox key={key} id={key}>
      <SentimentAnalysisBox data={data} />
    </GridBox>
  );
};
