import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { isStringUuid } from "src/utils/methods";
import { RootState } from "src/redux/reducers";
import { deleteTask, getTasksList, setShowTask } from "../../../redux";
import { RemoveModal } from "../RemoveModal/RemoveModal";

interface Props {
  onClose: () => void;
  wsTaskUuid: string;
  wsProjectUuid: string;
  taskName: string;
}

const DeleteTaskConfirmationModal: React.FC<Props> = (props) => {
  const { onClose, wsTaskUuid, wsProjectUuid, taskName } = props;

  const [loading, setLoading] = useState(false);

  const { taskId, projectId } = useParams<{
    taskId: string;
    projectId: string;
  }>();
  const history = useHistory();

  const {
    mainReducer: { activeWorkspaceUuid },
    projectReducer: { taskType },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const confirmDeleting = async () => {
    setLoading(true);

    try {
      await dispatch(deleteTask(wsTaskUuid));
      if (taskId && isStringUuid(taskId)) {
        history.push({
          pathname: `/workspace/${activeWorkspaceUuid}/projects/${projectId}`,
          search: `taskType=${taskType}`,
        });
        dispatch(setShowTask(false));
      }

      dispatch(getTasksList(wsProjectUuid, taskType));
    } catch (err) {
      console.error("Something went wrong");
    } finally {
      onClose();
      setLoading(false);
    }
  };

  return (
    <RemoveModal
      onClose={onClose}
      objectNames={[taskName ?? ""]}
      removeFunction={confirmDeleting}
      loading={loading}
    />
  );
};

export { DeleteTaskConfirmationModal };
