export const matchRoleName = (backendRoleName) => {
  switch (backendRoleName) {
    case "role::project_operator":
      return "member";
    case "role::project_admin":
      return "admin";
    case "role::project_client":
      return "client";
    default:
      return "unknown";
  }
};
