import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IntlShape, useIntl } from "react-intl";

import { useParams } from "react-router";
import { copyLink } from "src/utils/methods";
import { RootState } from "src/redux/reducers";
import { DropdownMenuItem } from "../components/Dropdown/Dropdown";
import {
  deleteTaskComment,
  setCommentAsEditable,
  getTaskDetails,
} from "../../redux";
import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { ReactComponent as BinIcon } from "../../images/trash-can.svg";
import { ReactComponent as MenuIcon } from "../../images/menu-dots.svg";
import { ReactComponent as CopyLinkGrey } from "../../images/copy-link-grey.svg";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import DropdownPortal, {
  DropdownPortalMenu,
} from "../components/DropdownPortal";
import { useScrollLock } from "../methods/useScrollLock";
import { getRoute, ROUTES } from "../../utils/ROUTES";
import { CommentType } from "../../redux/comment/types";
import { RemoveModal } from "../modals/RemoveModal/RemoveModal";
import { translateMessage } from "../methods/translateMessage";
import { AppConfig } from "../../config/AppConfig";

function mapCommentTypeToObjectName(type: CommentType, intl: IntlShape) {
  switch (type) {
    case CommentType.ContentProposal:
      return translateMessage({
        intl,
        id: "ws_content_proposal",
        defaultMessage: "Content proposal",
      });
    case CommentType.ContentProposalElementModeration:
      return translateMessage({
        intl,
        id: "ws_moderated_content_proposal",
        defaultMessage: "Moderated content proposal",
      });
    default:
      return translateMessage({
        intl,
        id: "ws_comment",
        defaultMessage: "Comment",
      });
  }
}

function mapCommentTypeToWarningMessage(type: CommentType) {
  switch (type) {
    case CommentType.ContentProposal:
      return (
        <IDHFormattedMessage
          id="ws_all_elements_related_to_this_proposal_will_be_removed"
          defaultMessage="All elements related to this proposal will be removed. This action cannot be undone."
        />
      );
    default:
      return null;
  }
}

interface CommentMenuDropdownProps {
  commentId: string;
  commentAuthor: string;
  commentType: CommentType;
  hasAccessToRemoveWsTaskComment: boolean;
}

function CommentMenuDropdown({
  commentId,
  commentAuthor,
  commentType,
  hasAccessToRemoveWsTaskComment,
}: CommentMenuDropdownProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmRemoveModalOpen, setIsConfirmRemoveModalOpen] =
    useState(false);

  const { lockScroll, unlockScroll } = useScrollLock();

  const intl = useIntl();

  const dispatch = useDispatch();
  const params = useParams<{
    taskId: string;
    workspaceUuid: string;
    projectId: string;
  }>();

  const {
    mainReducer: { identity },
  } = useSelector((state: RootState) => state);

  const deleteComment = async () => {
    setIsLoading(true);
    await dispatch(deleteTaskComment(commentId));
    await dispatch(getTaskDetails(params.taskId));
    setIsLoading(false);
  };

  const editCommentClick = async () => {
    await dispatch(setCommentAsEditable(commentId));
    (document.getElementById(commentId) as HTMLDivElement)?.click();
  };

  const copyLinkClick = () => {
    const urlParameters = new URLSearchParams(window.location.search);
    let queryParameters = {};

    if (urlParameters && urlParameters.get("taskType")) {
      queryParameters = {
        taskType: urlParameters.get("taskType"),
      };
    }

    const wsCommentUrl = getRoute(
      ROUTES.COMMENT_DETAILS,
      {
        wsWorkspaceUuid: params.workspaceUuid,
        wsProjectUuid: params.projectId,
        wsTaskUuid: params.taskId,
        wsCommentUuid: commentId,
      },
      queryParameters,
    );

    copyLink(`${AppConfig.getAppHost()}${wsCommentUrl}`);
  };

  const menu = (
    <DropdownPortalMenu>
      {identity.id === commentAuthor && commentType === CommentType.Comment && (
        <DropdownMenuItem
          onClick={() => editCommentClick()}
          disabled={isLoading}
        >
          <EditIcon />
          <IDHFormattedMessage id="ws_edit" defaultMessage="Edit" />
        </DropdownMenuItem>
      )}
      <DropdownMenuItem onClick={copyLinkClick} disabled={isLoading}>
        <CopyLinkGrey />
        <IDHFormattedMessage id="ws_copy_link" defaultMessage="Copy link" />
      </DropdownMenuItem>
      {(identity.id === commentAuthor || hasAccessToRemoveWsTaskComment) && (
        <DropdownMenuItem
          onClick={() => setIsConfirmRemoveModalOpen(true)}
          className="dropdown__menu-item--danger"
          disabled={isLoading}
        >
          <BinIcon />
          <IDHFormattedMessage id="ws_remove" defaultMessage="Remove" />
        </DropdownMenuItem>
      )}
    </DropdownPortalMenu>
  );

  return (
    <>
      <DropdownPortal
        overlay={menu}
        overlayClassName="comment-menu-options"
        placement="bottomRight"
        visible={isOpen}
        onVisibleChange={(v: boolean) => {
          v ? lockScroll() : unlockScroll();
          setIsOpen(v);
        }}
      >
        <MenuIcon className="comment__more" />
      </DropdownPortal>
      {isConfirmRemoveModalOpen && (
        <RemoveModal
          loading={isLoading}
          onClose={() => setIsConfirmRemoveModalOpen(false)}
          removeFunction={deleteComment}
          objectNames={[mapCommentTypeToObjectName(commentType, intl)]}
          warningMessage={mapCommentTypeToWarningMessage(commentType)}
        />
      )}
    </>
  );
}

export default CommentMenuDropdown;
