import React, { useEffect } from "react";
import "./PopUp.scss";

import { Portal } from "react-overlays";
import { overlaysRef } from "src/App";
import { useTransition, animated, easings } from "react-spring";
import classNames from "classnames";
import spinnySpinner from "src/images/spinner-small.gif";
import { PopupSection } from "./components/PopupSection";
import { Button } from "../Button/Button";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import { INPUT_TYPES_TO_FOCUS } from "../Modal/Modal";

export function PopUp({
  width = 600,
  title,
  paragraph,
  show,
  close,
  confirm,
  confirmText = "Set",
  confirmDisabled,
  className,
  loading,
  children,
}) {
  const transition = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 100, easing: easings.easeInOutQuart },
  });

  useEffect(() => {
    focusOnFirstInput();
  }, []);

  const focusOnFirstInput = () => {
    const input = document.querySelector(".popup input");

    if (input && INPUT_TYPES_TO_FOCUS.includes(input.type)) {
      setTimeout(() => {
        input.focus();
      }, 300);
    }
  };

  return show ? (
    <Portal container={overlaysRef}>
      <div className="popup__overlay" />
      {transition(
        (styles, item) =>
          item && (
            <animated.div
              style={{ ...styles, width }}
              className={classNames([
                "popup",
                loading && "popup--loading",
                className,
              ])}
            >
              {loading ? (
                <img src={spinnySpinner} width={48} alt="Loading..." />
              ) : (
                <>
                  {(title || paragraph) && (
                    <PopupSection className="popup__section--title">
                      {title && <div className="popup__title">{title}</div>}
                      {paragraph && (
                        <div className="popup__paragraph">{paragraph}</div>
                      )}
                    </PopupSection>
                  )}
                  {children}
                  <div className="popup__buttons">
                    {close && (
                      <Button
                        type="button"
                        variant="white-with-border"
                        size="large"
                        onClick={close}
                      >
                        <IDHFormattedMessage
                          id="ws_cancel"
                          defaultMessage="Cancel"
                        />
                      </Button>
                    )}
                    {confirm && (
                      <Button
                        type="button"
                        variant="blue"
                        size="large"
                        onClick={confirm}
                        disabled={confirmDisabled}
                      >
                        {confirmText}
                      </Button>
                    )}
                  </div>
                </>
              )}
            </animated.div>
          ),
      )}
    </Portal>
  ) : null;
}
