import React, { useMemo } from "react";
import Loader from "../../../components/Loader/Loader";
import "./ShowcasePrintingLoader.scss";

export function ShowcasePrintingLoader(props) {
  const { value, target } = props;

  const LoaderComponent = useMemo(() => <Loader />, []);

  return (
    <div className="showcase-printing-loader">
      <div className="showcase-printing-loader__wrapper">
        {LoaderComponent}
        {!isNaN(value) && target && (
          <span className="showcase-printing-loader__bar">
            <span
              className="showcase-printing-loader__bar-progress"
              style={{ width: `${(value / (target + 1)) * 100}%` }}
            />
          </span>
        )}
        <span className="showcase-printing-loader__counter">
          {value === target ? "Generating..." : `${value} out of ${target}`}
        </span>
      </div>
    </div>
  );
}
