import React, { useEffect, useState } from "react";
import "./TargetValueModal.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { injectIntl, useIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import CustomInput, {
  CustomInputLabel,
} from "../../../../components/CustomInput/CustomInput";
import CustomRadio from "../../../../components/CustomRadio/CustomRadio";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import Modal, {
  ModalText,
  ModalTitle,
} from "../../../../components/Modal/Modal";

function TargetValueModal(props) {
  const {
    onClose,
    targetValueOption,
    availableTargetsValue,
    availableTargets,
    targetValue,
    setState,
  } = props;

  const [localTargetValue, setLocalTargetValue] = useState(targetValue);
  const [localAvailableTargetsValue, setLocalAvailableTargetsValue] = useState(
    availableTargetsValue,
  );

  const intl = useIntl();

  const handleConfirm = () => {
    setState("targetValue", localTargetValue);
    setState("availableTargetsValue", localAvailableTargetsValue);
    onClose();
  };

  return (
    <Modal
      onClick={(e) => e.stopPropagation()}
      className="target-value-modal"
      overlayClassName="target-value-modal__overlay"
      displayCancelButton
      onCancelClick={onClose}
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={handleConfirm}
      confirmButtonText={
        <IDHFormattedMessage id="ws_set" defaultMessage="Set" />
      }
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_set_target_value"
          defaultMessage="Set target value"
        />
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_target_value_modal_text"
          defaultMessage="Sepecify the target value by manually entering the value or selecting the source (field) that defines it."
        />
      </ModalText>

      <div className="target-value-modal__radio-wrapper">
        <CustomRadio
          id="custom-target-value"
          name="fileType"
          label={
            <IDHFormattedMessage
              id="ws_target_value"
              defaultMessage="Target value"
            />
          }
          checked={targetValueOption === "custom"}
          onChange={() => setState("targetValueOption", "custom")}
        />
        <CustomRadio
          id="target-value-from-resource"
          name="fileType"
          label={
            <IDHFormattedMessage
              id="ws_target_source"
              defaultMessage="Target source"
            />
          }
          checked={targetValueOption === "from-source"}
          onChange={() => setState("targetValueOption", "from-source")}
        />
      </div>

      <div className="target-value-modal__inputs">
        {targetValueOption === "custom" ? (
          <>
            <CustomInputLabel htmlFor="custom-target-value">
              <IDHFormattedMessage
                id="ws_target_value"
                defaultMessage="Target value"
              />
            </CustomInputLabel>
            <div className="target-value-select">
              <CustomInput
                id="custom-target-value"
                value={localTargetValue}
                onChange={(e) => setLocalTargetValue(e.target.value)}
                type="number"
                placeholder={translateMessage({
                  intl,
                  id: "ws_enter_value",
                  defaultMessage: "Enter value",
                })}
              />
            </div>
          </>
        ) : (
          <>
            <CustomInputLabel htmlFor="target-value-source">
              <IDHFormattedMessage
                id="ws_target_source"
                defaultMessage="Target source"
              />
            </CustomInputLabel>
            <div className="target-value-select">
              <CustomSelect
                id="target-value-source"
                value={localAvailableTargetsValue}
                options={availableTargets}
                placeholder={`${translateMessage({
                  intl,
                  id: "ws_select",
                  defaultMessage: "Select",
                })}...`}
                onChange={(newValue) => setLocalAvailableTargetsValue(newValue)}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

export default injectIntl(TargetValueModal);
