import React, { useMemo } from "react";
import { StackedLabels } from "src/app/components/StackedLabels/StackedLabels";
import { ProfileDataText } from "../components/ProfileDataText/ProfileDataText";
import HtmlParser from "../../../../pages/CreatorDiscoveryTool/legacy/app/components/HtmlParser/HtmlParser";

export function CategoriesAndBio(props) {
  const {
    profileData: {
      user_data: { bio },
      user_audience_data: { influencersInterests },
    },
  } = props;

  const interests = useMemo(
    () => influencersInterests?.map((item) => item.name) || [],
    [influencersInterests],
  );

  return (
    <>
      {interests.length ? <StackedLabels values={interests} /> : null}
      {bio ? (
        <ProfileDataText>
          <HtmlParser html={bio} />
        </ProfileDataText>
      ) : null}
    </>
  );
}
