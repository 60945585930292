import React, { useRef, useState } from "react";
import "./TooltipPopper.scss";
import classNames from "classnames";

import { Portal } from "react-overlays";
import { usePopper } from "react-popper";
import { overlaysRef } from "src/App";
import zIndex from "src/utils/zIndex";

export function TooltipPopper({
  content,
  contentHidden = false,
  className,
  placement = "bottom",
  children,
}) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
      { name: "preventOverflow", enabled: true },
    ],
  });

  const popperContainer = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        ref={setReferenceElement}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        className={classNames([
          "tooltip-popper__toggle",
          isOpen && "tooltip-popper__toggle--open",
        ])}
      >
        {children}
      </div>

      {isOpen && contentHidden === false && (
        <Portal container={overlaysRef}>
          <div
            ref={popperContainer}
            className="tooltip-popper"
            // stuff for clickable tooltip
            // onMouseEnter={() => setIsOpen(true)}
            // onMouseLeave={() => setIsOpen(false)}
            // onClick={(e) => e.stopPropagation()}
          >
            <div
              ref={setPopperElement}
              style={{ ...styles.popper, zIndex: zIndex.dropdown }}
              {...attributes.popper}
              className={classNames(["tooltip-popper__content", className])}
              onWheel={(e) => e.preventDefault()}
            >
              {content}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
}
