import axios, { AxiosResponse } from "axios";
import { API_URLS } from "../../utils/API_URLS";
import { showToast } from "../methods/showToast";
import { RequestInputs } from "../../types";

export class UploadFile {
  private _hasCorrectType = false;

  private _hasCorrectSize = false;

  constructor(
    private readonly _file: File,
    private readonly _allowedTypes: string[],
    private readonly _maxSize: number,
    private readonly presignedUploadRequestUrl: string,
    private readonly parentUuid: string,
    private readonly parentType: string,
    private readonly wsWorkspaceUuid: string | null,
  ) {}

  validate() {
    const fileNameAr = this._file.name.split(".");
    const fileExtension = fileNameAr[fileNameAr.length - 1];

    const size = this._file.size / parseInt((1000 * 1000).toFixed(2));

    if (!this._allowedTypes.includes(fileExtension.toLowerCase())) {
      this._hasCorrectType = false;
      showToast("error", "Error", "Invalid file type.");
      throw new Error("Invalid file type.");
    } else if (size > this._maxSize) {
      this._hasCorrectType = true;
      this._hasCorrectSize = false;
      showToast("error", "Error", "File is too large.");
      throw new Error("File is too large.");
    } else {
      this._hasCorrectType = true;
      this._hasCorrectSize = true;
    }
  }

  get hasCorrectType() {
    return this._hasCorrectType;
  }

  get hasCorrectSize() {
    return this._hasCorrectSize;
  }

  get maxSize() {
    return this._maxSize;
  }

  async sendAllRequests(): Promise<any> {
    // PresignedUploadRequest
    const presignedUploadRes = await this.sendPresignedUploadRequest(
      this.presignedUploadRequestUrl,
    );
    const { requestInputs } = presignedUploadRes.data.content;
    const { action } = presignedUploadRes.data.content.requestAttributes;

    // S3
    await this.sendRequestToS3(action, requestInputs);

    const path = requestInputs.key.split("/");
    const realName = path[path.length - 1];

    // createAsset
    await this.createAsset(
      this.parentUuid,
      this.parentType,
      this.wsWorkspaceUuid,
      realName,
    );
  }

  async sendRequests(): Promise<string> {
    // PresignedUploadRequest
    const presignedUploadRes = await this.sendPresignedUploadRequest(
      this.presignedUploadRequestUrl,
    );
    const { requestInputs } = presignedUploadRes.data.content.token;
    const { action } = presignedUploadRes.data.content.token.requestAttributes;

    // S3
    await this.sendRequestToS3(action, requestInputs);

    const path = requestInputs.key.split("/");
    const realName = path[path.length - 1];
    return realName;
  }

  private async sendPresignedUploadRequest(
    url: string,
  ): Promise<AxiosResponse<any>> {
    return axios.get(url);
  }

  private getFormDataToUploadS3(requestInputs: RequestInputs) {
    const formData = new FormData();

    formData.set("key", requestInputs.key);
    formData.set("acl", requestInputs.acl);
    formData.set("X-Amz-Credential", requestInputs["X-Amz-Credential"]);
    formData.set("X-Amz-Algorithm", requestInputs["X-Amz-Algorithm"]);
    formData.set("X-Amz-Date", requestInputs["X-Amz-Date"]);
    formData.set("Policy", requestInputs.Policy);
    formData.set("X-Amz-Signature", requestInputs["X-Amz-Signature"]);
    formData.set("success_action_status", requestInputs.success_action_status);
    formData.set("file", this._file);

    return formData;
  }

  async sendRequestToS3(
    action: string,
    requestInputs: RequestInputs,
  ): Promise<AxiosResponse<any>> {
    return axios.post(action, this.getFormDataToUploadS3(requestInputs));
  }

  async createAsset(
    parentUuid: string,
    parentType: string,
    wsWorkspaceUuid: string | null,
    realName: string,
  ): Promise<AxiosResponse<any>> {
    return axios.post(API_URLS.createAsset, {
      wsWorkspaceUuid,
      parentUuid,
      parentType,
      name: this._file.name,
      realName,
      mimeType: this._file.type,
      size: this._file.size,
    });
  }
}
