import zIndex from "src/utils/zIndex";

export const inviteSelectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: "50px",
    border: "solid #b4b6be 1px",
    outline: "none",
    borderRadius: "8px",
    fontSize: "12px",
    ":hover": { border: "solid #b4b6be 1px" },
    ":focus-within": { border: "solid #8095ff 1px" },
    transition: "all .1s",
    boxShadow: "none",
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    overflow: "hidden",
    borderRadius: "8px",
  }),
  menuPortal: (baseStyles, state) => ({
    ...baseStyles,
    zIndex: zIndex.dropdown,
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    "::-webkit-scrollbar": { width: "4px" },
    "::-webkit-scrollbar-track": {
      width: "5px",
      boxShadow: "inset 0 0 5px transparent",
      borderRadius: "2px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#b8d1fe",
      borderRadius: "2px",
      width: "5px",
    },
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    padding: "6px",
    gap: "6px",
  }),
  multiValue: (baseStyles, { data }) => {
    return {
      display: "flex",
      gap: "6px",
      backgroundColor:
        data.isValid === false ? "#FCEBEA" : data.backgroundColor || "#EDEDEF",
      maxWidth: "200px",
      width: "fit-content",
      alignItems: "center",
      color: "#8e93ac",
      padding: "8px",
      borderRadius: "4px",
      height: "36px",
      ".share-modal__user-select-option__details-client": { display: "none" },
    };
  },
  multiValueLabel: (baseStyles, { data }) => ({
    padding: 0,
    color: data.color || "#6A6D7C",
    fontWeight: 500,
    fontSize: "12px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  }),
  multiValueRemove: (baseStyles, { data }) => ({
    display: "flex",
    cursor: "pointer",
    svg: { path: { fill: data.color || "#6A6D7C" } },
    ":hover": {
      svg: {
        path: {
          fill: "#3e3e3e",
        },
      },
    },
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: 500,
    fontSize: "12px",
    color: "#b4b6be",
    paddingLeft: "2px",
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    transition: "all .1s",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    padding: "4px 16px",
    color: "#8e93ac",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    wordBreak: "break-wrod",
    minHeight: "32px",
    ":hover": {
      background: "#f6f7f9",
    },
    background: state.isFocused || state.isSelected ? "#f6f7f9" : "transparent",
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    paddingLeft: "2px",
    maxWidth: "50px",
  }),
};
