import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { uuidv7 as uuid } from "uuidv7";
import classNames from "classnames";
import { injectIntl } from "react-intl";
import axios from "axios";

import PublicationPlaceholder from "src/images/publication-placeholder-grey-sm.svg";
import { translateMessage } from "src/app/methods/translateMessage";
import { TaskType } from "src/types";
import { showErrorToast } from "src/utils/methods";
import Modal, { ModalTitle } from "../../components/Modal/Modal";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { API_URLS } from "../../../utils/API_URLS";
import PlaceholderImage from "../../../images/placeholder-image.svg";
import {
  addNewTask,
  changeTaskMetaFieldValue,
  getTaskDetails,
  getTasksCounters,
} from "../../../redux";
import { generateRankString } from "../../../utils/rankStrings";
import { showToast } from "../../methods/showToast";

import "./AssociateTaskModal.scss";

function AssociateTaskModal({
  onClose,
  projectId,
  lastTaskRank,
  taskType,
  tasksList,
  intl,
}) {
  const [loading, setLoading] = useState(false);
  const [taskOptions, setTaskOptions] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);

  const {
    mainReducer: { activeWorkspaceUuid },
    taskReducer: { taskDetails },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const getPlaceholder = () => {
    switch (taskType) {
      case TaskType.Content:
        return PlaceholderImage;
      case TaskType.Publication:
        return PublicationPlaceholder;
      default:
        return PlaceholderImage;
    }
  };

  useEffect(() => {
    getTaskOptions();
  }, []);

  const getTasksData = async () => {
    const url = `${API_URLS.getTasksList.replace(
      ":projectUuid:",
      projectId,
    )}?wsTaskType=${taskType}`;

    try {
      const {
        data: {
          content: { tasks },
        },
      } = await axios.get(url);

      return tasks.filter(
        (task) => !tasksList.find((item) => item.id === task.taskId),
      );
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const getTaskOptions = async (selectedOptions) => {
    const url = `${API_URLS.getTasksList.replace(
      ":projectUuid:",
      projectId,
    )}?wsTaskType=${taskType}`;

    const {
      data: {
        content: { tasks },
      },
    } = await axios.get(url);

    const options = tasks
      .filter((task) => !tasksList.find((item) => item.id === task.taskId))
      .map((task) => ({
        label: (
          <div className="member-option">
            {taskType !== TaskType.Action && taskType !== TaskType.Payment && (
              <div
                className={classNames(
                  "member-option__photo-wrapper",
                  taskType === TaskType.Content &&
                    "member-option__photo-wrapper--rect",
                )}
                style={{ backgroundImage: `url(${getPlaceholder()})` }}
              >
                <div
                  className="member-option__photo"
                  style={{
                    backgroundImage: `url(${task?.cover?.publicUrl})`,
                  }}
                />
              </div>
            )}
            <div>
              <span className="member-option__name">{task.title}</span>
            </div>
          </div>
        ),
        value: { id: task.taskId, title: task.title, existing: true },
      }));

    if (selectedOptions) {
      setSelectedTasks(
        options.filter((item) =>
          selectedOptions.find((el) => el.value.id === item.value.id),
        ),
      );
    }
    setTaskOptions(options);
  };

  const handleConfirmClick = async () => {
    setLoading(true);
    const tasksToCreate = selectedTasks.filter((item) => !item.value.existing);

    try {
      for (let i = 0; i < tasksToCreate.length; i++) {
        const {
          value: { title, id },
        } = tasksToCreate[i];

        await dispatch(
          addNewTask(
            title,
            projectId,
            id,
            generateRankString(lastTaskRank, ""),
            taskType,
            activeWorkspaceUuid,
            [],
          ),
        );
      }

      const tasksData = await getTasksData();

      await selectedTasks.forEach((task) => {
        const taskData = tasksData.find(
          (item) => item.taskId === task.value.id,
        );

        const taskMetaField = taskData.metadata.find(
          (item) => item.key === taskDetails.taskType,
        );

        dispatch(
          changeTaskMetaFieldValue(
            taskData.taskId,
            taskMetaField.uuid,
            taskDetails.taskId,
          ),
        );
      });

      await dispatch(getTasksCounters(projectId));
      dispatch(getTaskDetails(taskDetails.taskId));
      setLoading(false);
      onClose();
    } catch {
      showToast(
        "error",
        translateMessage({
          intl,
          id: "ws_something_went_wrong",
          defaultMessage: "Something went wrong.",
        }),
        translateMessage({
          intl,
          id: "ws_please_try_again",
          defaultMessage: "Please try again.",
        }),
      );
    }
  };

  const createNewOption = async (inputValue) => {
    const taskUuid = uuid();

    const newTaskOption = {
      value: { id: taskUuid, title: inputValue, existing: false },
      label: (
        <div className="member-option">
          {taskType !== TaskType.Action && taskType !== TaskType.Payment && (
            <div
              className={classNames(
                "member-option__photo-wrapper",
                taskType === TaskType.Content &&
                  "member-option__photo-wrapper--rect",
              )}
            >
              <img
                className="member-option__photo"
                src={getPlaceholder()}
                alt="avatar"
              />
            </div>
          )}
          <div>
            <span className="member-option__name">{inputValue}</span>
          </div>
        </div>
      ),
    };

    const newSelectedTasks = [...selectedTasks, newTaskOption];

    setTaskOptions([...taskOptions, newTaskOption]);
    setSelectedTasks(newSelectedTasks);
  };

  const renderModalTitle = () => {
    switch (taskType) {
      case TaskType.Content:
        return translateMessage({
          intl,
          id: "ws_connecting_content",
          defaultMessage: "Connecting content",
        });
      case TaskType.Publication:
        return translateMessage({
          intl,
          id: "ws_connecting_publication",
          defaultMessage: "Connecting publication",
        });
      case TaskType.Payment:
        return translateMessage({
          intl,
          id: "ws_connecting_payment",
          defaultMessage: "Connecting payment",
        });
      default:
        return translateMessage({
          intl,
          id: "ws_connecting_action",
          defaultMessage: "Connecting action",
        });
    }
  };

  const filterOption = (candidate, input) => {
    return (
      candidate.data.__isNew__ ||
      candidate.value.title.toUpperCase().includes(input.toUpperCase())
    );
  };

  return (
    <Modal
      className="associate-task-modal"
      onClose={onClose}
      displayCancelButton
      closeButtonText={translateMessage({
        intl,
        id: "ws_cancel",
        defaultMessage: "Cancel",
      })}
      onConfirmClick={handleConfirmClick}
      confirmButtonDisabled={!selectedTasks.length}
      confirmButtonText={translateMessage({
        intl,
        id: "ws_connect",
        defaultMessage: "Connect",
      })}
      confirmButtonLoading={loading}
    >
      <ModalTitle>{renderModalTitle()}</ModalTitle>
      <CustomSelect
        creatable
        value={selectedTasks}
        isSearchable
        isMulti
        withTiles
        onChange={setSelectedTasks}
        closeMenuOnSelect={false}
        options={taskOptions}
        placeholder={`${translateMessage({
          intl,
          id: "ws_add_or_select",
          defaultMessage: "Add or select",
        })} ${taskType}`}
        onCreateOption={createNewOption}
        filterOption={filterOption}
        formatCreateLabel={(inputText) =>
          `${translateMessage({
            intl,
            id: "ws_create",
            defaultMessage: "Create",
          })} ${inputText}`
        }
      />
    </Modal>
  );
}

export default injectIntl(AssociateTaskModal);
