import React from "react";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalText } from "src/app/components/Modal/Modal";

export function StepTwo() {
  return (
    <AnimatedDiv>
      <span className="how-it-works__step-title">
        <IDHFormattedMessage
          id="ws_add_a_coupon_group"
          defaultMessage="Add a Coupon Group"
        />
      </span>
      <ModalText>
        <IDHFormattedMessage
          id="ws_how_it_works_step_2"
          defaultMessage="Generate promotional coupons in your ecommerce platform that entitled to discounts on purchases. Then, create a group and add the created discount coupons to it."
        />
      </ModalText>
      <video autoPlay loop>
        <source src={require("../../../videos/step-2.mp4")} type="video/mp4" />
      </video>
    </AnimatedDiv>
  );
}
