import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as LanguagesIcon } from "../../../../../images/profile-data/languages.svg";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";
import { ProgressBarList } from "../components/ProgressBarList/ProgressBarList";

export function Languages(props) {
  const { audienceLanguages, height, onProjectDetails } = props;

  const onShowcase = height;

  return (
    <ProfileBox onProjectDetails={onProjectDetails}>
      <PBHeader
        icon={<LanguagesIcon />}
        title={
          <IDHFormattedMessage id="ws_languages" defaultMessage="Languages" />
        }
        tooltip={
          !onShowcase && (
            <Tooltip
              center
              content={
                <IDHFormattedMessage
                  id="ws_tooltip_languages"
                  defaultMessage="The most popular languages spoken by the influencer's audience"
                />
              }
            />
          )
        }
        onShowcase={onShowcase}
        onProjectDetails={onProjectDetails}
      />
      <ProgressBarList
        data={audienceLanguages}
        barColor="#FFB822"
        backgroundColor="rgba(255, 184, 34, 0.1)"
        language
        height={height}
      />
    </ProfileBox>
  );
}
