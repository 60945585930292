import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import "./ScalingInput.scss";
import classNames from "classnames";

interface Props
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  id?: string;
  value?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: "text" | "number" | "password" | "email";
  placeholder?: string;
  minWidth?: number;
}

export const ScalingInput = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { className, value, minWidth, ...rest } = props;

    const [width, setWidth] = useState(12);

    const spanRef = useRef<HTMLSpanElement>(null);

    const updateWidth = () => {
      if (!spanRef?.current?.offsetWidth) setWidth(12);
      else setWidth(spanRef?.current?.offsetWidth + 12 || 12);
    };

    useEffect(() => {
      updateWidth();
    }, [spanRef?.current?.offsetWidth]);

    return (
      <div className="scaling-input__wrapper">
        <span
          ref={spanRef}
          className={classNames("scaling-input__content", className)}
        >
          {value}"
        </span>
        <input
          ref={ref}
          className={classNames("scaling-input", className)}
          value={value}
          style={{ width, minWidth }}
          {...rest}
        />
      </div>
    );
  },
);
