import React from "react";
import "./ContactSupportModal.scss";

import { useSelector } from "react-redux";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalTitle, ModalText } from "../../components/Modal/Modal";

import { ReactComponent as Lifebuoy } from "../../../images/lifebuoy.svg";

function ContactSupportModal(props) {
  const { onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const {
    mainReducer: { identity },
  } = useSelector((state) => state);

  return (
    <Modal
      className="contact-support-modal"
      onClose={handleClose}
      displayCancelButton
      closeButtonText="Close"
      variant="medium"
    >
      <ModalTitle icon={<Lifebuoy />}>
        <IDHFormattedMessage
          id="ws_contact_support"
          defaultMessage="Contact support"
        />
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_to_contact_our_support_team_please_write_to"
          defaultMessage="To contact our support team, please write to"
        />
        :
        <span className="contact-support-modal__email">
          &nbsp;
          <a href={`mailto:${identity?.trialLimits?.bdWsMemberEmail}`}>
            {identity?.trialLimits?.bdWsMemberEmail}
          </a>
        </span>
      </ModalText>
    </Modal>
  );
}

export { ContactSupportModal };
