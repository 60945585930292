import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { isEmpty } from "../../../../../utils/methods";
import { SectionHeader } from "../components/SectionHeader";
import { AudienceAgeAndGender } from "../single/AudienceAgeAndGender";
import { FemalePerAge } from "../single/FemalePerAge";
import { GeographicalReach } from "../single/GeographicalReach";
import { Languages } from "../single/Languages";
import { MalePerAge } from "../single/MalePerAge";

export function AudienceData(props) {
  const {
    profileData: {
      user_audience_data: {
        audienceWomen,
        audienceMen,
        gendersPerAge,
        audienceAgeGroups,
        audienceGeographicalReach,
        audienceLanguages,
      },
    },
  } = props;

  if (isEmpty(props.profileData.user_audience_data)) {
    return null;
  }

  return (
    <div className="profile-data-tab__section-wrapper">
      <SectionHeader
        text={
          <IDHFormattedMessage
            id="ws_audience_data"
            defaultMessage="Audience Data"
          />
        }
      />
      <div className="profile-data-tab__section-two-boxes">
        {audienceWomen && gendersPerAge?.female && (
          <FemalePerAge
            audienceWomen={audienceWomen}
            female={gendersPerAge?.female}
          />
        )}
        {audienceMen && gendersPerAge?.male && (
          <MalePerAge audienceMen={audienceMen} male={gendersPerAge?.male} />
        )}
      </div>
      <div className="profile-data-tab__section-two-boxes">
        {audienceAgeGroups && (
          <AudienceAgeAndGender audienceAgeGroups={audienceAgeGroups} />
        )}
        {audienceGeographicalReach && (
          <GeographicalReach
            audienceGeographicalReach={audienceGeographicalReach}
          />
        )}
      </div>
      <div className="profile-data-tab__section-two-boxes">
        {audienceLanguages && (
          <Languages audienceLanguages={audienceLanguages} />
        )}
      </div>
    </div>
  );
}
