import React from "react";
import "../SearchSection.scss";

import classNames from "classnames";
import { useHistory } from "react-router";

import { GlobalTaskSearchResult } from "../../TopSearch";
import { GlobalTaskItemName } from "./components/GlobalTaskItemName";
import IDHFormattedMessage from "../../../../components/IDHFormattedMessage/IDHFormattedMessage";
import { GlobalTaskCover } from "./components/GlobalTaskCover";
import { getRoute, ROUTES } from "../../../../../utils/ROUTES";

interface Props {
  data: GlobalTaskSearchResult[];
  clearResults: () => void;
}

export function GlobalTaskResults(props: Props) {
  const history = useHistory();

  const openGlobalTask = (
    wsWorkspaceUuid: string,
    wsGlobalTaskUuid: string,
  ) => {
    props.clearResults();

    history.push(
      getRoute(
        ROUTES.GLOBAL_TASK_DETAILS,
        {
          wsWorkspaceUuid,
        },
        {
          displayGlobalTask: wsGlobalTaskUuid,
        },
      ),
    );
  };

  if (props.data.length === 0) {
    return null;
  }

  return (
    <div>
      <div
        key="creator-database"
        className={classNames("search-section", {
          "search-section--with-separator": true,
        })}
      >
        <h4 className="search-section__header">
          <IDHFormattedMessage
            id="ws_search_creators_in_database"
            defaultMessage="Cerators in database"
          />
        </h4>

        {props.data.map((wsGlobalTask) => (
          <div
            key={wsGlobalTask.title}
            className="search-section__item"
            onClick={() =>
              openGlobalTask(wsGlobalTask.wsWorkspaceUuid, wsGlobalTask.uuid)
            }
          >
            <GlobalTaskCover data={wsGlobalTask} />
            <GlobalTaskItemName data={wsGlobalTask} />
          </div>
        ))}
      </div>
    </div>
  );
}
