import React from "react";
import "./AccessLinkPageHeaderContent.scss";

import { ReactComponent as RequiredStar } from "src/images/required.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import XssFilter from "src/app/components/XssFilter/XssFilter";
import CustomImage from "src/app/components/CustomImage/CustomImage";

export function AccessLinkPageHeaderContent({
  caption,
  description,
  showLegend,
  cover,
}) {
  return (
    <div className="access-link-page__header-content">
      {(caption || cover) && (
        <div className="access-link-page__header-content__caption">
          {cover && (
            <CustomImage
              src={cover}
              loading="lazy"
              alt="Recruitment form cover photo"
              className="access-link-page__header-content__caption-cover"
            />
          )}
          {caption && (
            <h1 className="access-link-page__header-content__caption-title">
              {caption}
            </h1>
          )}
        </div>
      )}
      {description && (
        <div
          className="access-link-page__header-content__description"
          dangerouslySetInnerHTML={{
            __html: XssFilter(description, ["b", "i", "br"]),
          }}
        />
      )}
      {showLegend && (
        <div className="access-link-page__header-content__legend">
          <RequiredStar />
          &nbsp;
          <IDHFormattedMessage id="ws_required" defaultMessage="Required" />
        </div>
      )}
    </div>
  );
}
