import React, { useEffect } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import "./VelocitySummaryModal.scss";

import Modal from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import VelocityIcon from "../VelocityIcon/VelocityIcon";
import PostInfo from "../../campaign-details/PostInfo/PostInfo";
import UserInterestList from "../UserInterestList/UserInterestList";
import { clearVelocitySummaryData } from "../audiencesManagerActions";
import Loader from "../../components/Loader/Loader";
import VelocityItems from "./VelocityItems";
import { generateVelocityIndexModalTooltip } from "../../helpers/velocityIndexTooltip";

function VelocitySummaryModal(props) {
  const {
    onClose,
    velocitySummary: {
      userId,
      profilePictureUrl,
      socialProvider,
      socialUsername,
      followersCount,
      audienceInterests,
      velocityIndex,
      velocityIndexSubmetrics,
      profileUrl,
    },
    searchAudienceFilters,
  } = props;

  useEffect(() => {
    return () => {
      props.clearVelocitySummaryData();
    };
  }, []);

  return (
    <Modal
      className="velocity-modal"
      onClose={onClose}
      displayCancelButton
      title={
        <IDHFormattedMessage
          id="front_modal_relevance_breakdown"
          defaultMessage="Relevance breakdown"
        />
      }
      displayCloseIcon
    >
      {userId ? (
        <div className="velocity">
          <div
            className={classNames("velocity__top", {
              "velocity__top--tt":
                socialProvider === "tik_tok" ||
                socialProvider === "douyin" ||
                socialProvider === "youtube" ||
                socialProvider === "snapchat",
            })}
          >
            <div
              className={
                socialProvider === "instagram" ? "velocity__info" : null
              }
            >
              <div className="velocity__user">
                <div
                  className="velocity__photo"
                  style={{
                    backgroundImage: `url(${profilePictureUrl})`,
                  }}
                />
                <PostInfo
                  post={{
                    socialProfileUrl: profileUrl,
                    cpconExternalContent: null,
                    cpcon: null,
                    socialProvider,
                    socialUserName: socialUsername,
                    followersCount,
                  }}
                />
              </div>
              {socialProvider === "instagram" && (
                <div className="velocity__interests">
                  <div className="velocity__interests-header">
                    <IDHFormattedMessage
                      id="front_audience_interests"
                      defaultMessage="Audience interests"
                    />
                  </div>

                  <UserInterestList
                    interestList={audienceInterests}
                    selectedInterest={searchAudienceFilters?.chosenTopics || []}
                  />
                </div>
              )}
            </div>
            <div
              className={
                socialProvider === "instagram" ? "velocity__stats" : null
              }
            >
              <div className="velocity__header">
                <IDHFormattedMessage
                  id="front_relevance"
                  defaultMessage="relevance"
                />
                <MaterialTooltip
                  center
                  alignRight
                  content={generateVelocityIndexModalTooltip(socialProvider)}
                />
              </div>

              <div
                className={classNames("velocity__box", {
                  "velocity__box--high": (velocityIndex || 0) > 50,
                  "velocity__box--low": (velocityIndex || 0) === 0,
                })}
              >
                <VelocityIcon percent={velocityIndex || 0} />
                {velocityIndex || 0}
              </div>
              {socialProvider === "instagram" && (
                <VelocityItems
                  velocityIndexSubmetrics={velocityIndexSubmetrics}
                  socialProvider={socialProvider}
                />
              )}
            </div>
          </div>
          {(socialProvider === "tik_tok" ||
            socialProvider === "douyin" ||
            socialProvider === "youtube") && (
            <VelocityItems
              velocityIndexSubmetrics={velocityIndexSubmetrics}
              socialProvider={socialProvider}
            />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  velocitySummary: state.audiencesManagerReducer.velocitySummary,
  searchAudienceFilters: state.audiencesManagerReducer.searchAudienceFilters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearVelocitySummaryData: () => dispatch(clearVelocitySummaryData()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VelocitySummaryModal);
