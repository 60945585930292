import React from "react";
import { useIntl } from "react-intl";
import { translateMessage } from "../../../../methods/translateMessage";

function WsRecruitmentTaskCreated({ taskLink, projectLink }) {
  const intl = useIntl();
  return (
    <>
      {taskLink} &nbsp;
      {translateMessage({
        intl,
        id: "ws_applied_to_participate_in",
        defaultMessage: "applied to participate in",
      })}
    </>
  );
}

export default WsRecruitmentTaskCreated;
