import React from "react";
import "./LinkExpiredBlock.scss";

import { ReactComponent as LinkBrokenIcon } from "src/images/inda-icons/General/link-broken.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { AccessLinkPageBlock } from "../AccessLinkPageBlock/AccessLinkPageBlock";

export function LinkExpiredBlock() {
  return (
    <AccessLinkPageBlock>
      <div className="access-link-page__block--expired">
        <LinkBrokenIcon className="access-link-page__block--expired__icon" />
        <div className="access-link-page__block--expired__title">
          <IDHFormattedMessage
            id="ws_ooops_something_went_wrong"
            defaultMessage="Oops, something went wrong"
          />
          ...
        </div>
        <div className="access-link-page__block--expired__subtitle">
          <IDHFormattedMessage
            id="ws_were_sorry_but_this_link_is_no_longer_active"
            defaultMessage="We're sorry, but this link is no longer active."
          />
        </div>
      </div>
    </AccessLinkPageBlock>
  );
}
