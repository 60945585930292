import React, { Dispatch, SetStateAction, useEffect } from "react";
import { uuidv7 as uuid } from "uuidv7";

import classNames from "classnames";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { WrappedComponentProps, injectIntl, useIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { CustomInputLabel } from "../../../../components/CustomInput/CustomInput";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import { ModalColumn } from "../../../../components/Modal/Modal";
import { ReactComponent as DeleteIcon } from "../../../../../images/close-alt.svg";

interface Props {
  conditionColumns: any[];
  conditionValues: any;
  setConditionValues: Dispatch<SetStateAction<any>>;
}

const FieldCountConditions: React.FC<Props> = (props) => {
  const { conditionColumns, conditionValues, setConditionValues } = props;

  const addEmptyCondition = () => {
    setConditionValues((values: any) => [
      ...values,
      {
        uuid: uuid(),
        column: {
          label: "",
          value: null,
        },
        value: {
          label: "",
          value: null,
        },
      },
    ]);
  };

  useEffect(() => {
    const lastConditionIndex = conditionValues.length - 1;

    if (conditionValues[lastConditionIndex]?.value?.value) {
      addEmptyCondition();
    }
  }, [conditionValues]);

  return (
    <>
      {conditionValues.map((item: any) => (
        <CountCondition
          key={item.uuid}
          conditionData={item}
          conditionColumns={conditionColumns}
          conditionValues={conditionValues}
          setConditionValues={setConditionValues}
        />
      ))}
    </>
  );
};

function CountCondition(props: any) {
  const {
    conditionData,
    conditionColumns,
    conditionValues,
    setConditionValues,
  } = props;

  const intl = useIntl();

  const updateCondition = (newValue: any, key: string) => {
    setConditionValues((values: any) =>
      values.map((item: any) => {
        if (item.uuid === conditionData.uuid) {
          return {
            ...item,
            [key]: newValue,
          };
        }

        return item;
      }),
    );
  };

  const deleteCondition = () => {
    setConditionValues((values: { uuid: string }[]) =>
      values.filter((item: any) => {
        return item.uuid !== conditionData.uuid;
      }),
    );
  };

  const showDeleteIcon =
    conditionData.uuid !== conditionValues[conditionValues.length - 1].uuid;

  return (
    <div
      className={classNames("summary__row", "summary__row--equal-inputs", {
        "summary__row--with-cross": conditionValues.length > 1,
      })}
    >
      <ModalColumn>
        <CustomInputLabel htmlFor="column">
          <IDHFormattedMessage id="ws_column" defaultMessage="Column" />
        </CustomInputLabel>
        <CustomSelect
          id="column"
          value={conditionData.column}
          options={conditionColumns}
          placeholder={`${translateMessage({
            intl,
            id: "ws_select",
            defaultMessage: "Select",
          })}...`}
          onChange={(newValue: any) => updateCondition(newValue, "column")}
        />
      </ModalColumn>
      <ModalColumn>
        <CustomInputLabel htmlFor="value">
          <IDHFormattedMessage id="ws_value" defaultMessage="Value" />
        </CustomInputLabel>
        <CustomSelect
          creatable
          id="value"
          isSearchable
          value={conditionData.value}
          options={conditionData?.column?.value?.options}
          placeholder={`${translateMessage({
            intl,
            id: "ws_select",
            defaultMessage: "Select",
          })}...`}
          noOptionsMessage={() =>
            `${translateMessage({
              intl,
              id: "ws_start_typing",
              defaultMessage: "Start typing",
            })}...`
          }
          formatCreateLabel={(inputValue: string) => inputValue}
          onChange={(newValue: any) => updateCondition(newValue, "value")}
          disabled={!conditionData?.column?.value}
        />
      </ModalColumn>
      {showDeleteIcon && <DeleteIcon onClick={deleteCondition} />}
    </div>
  );
}

export default FieldCountConditions;
