export const isValidUrl = (url: string | null): boolean => {
  if (!url) {
    return false;
  }

  const urlRegex =
    /\b(?:https?|www)\:\/\/[-a-z0-9+&@#\/%?=~_|!:,.;]*\.[a-z0-9.-]+\b/i;

  return urlRegex.test(url);
};

export const containHttpOrHttpsPrefix = (url: string | null): boolean => {
  if (!url) {
    return false;
  }

  return !!url.match(/^https?:\/\//);
};

export const tryConvertToCorrectUrl = (url: string | null): string | null => {
  if (!url) {
    return null;
  }

  if (containHttpOrHttpsPrefix(url)) {
    return url;
  }

  return `https://${url}`;
};
