import React from "react";
import moment from "moment";
import "moment/locale/pl";
import "moment/locale/en-gb";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { translateMessage } from "./translateMessage";

export const getDateLabel = (
  lang: "en-EN" | "pl-PL",
  date: any | null,
  disableRedLabel: boolean = false,
  intl: any,
) => {
  if (!date) {
    return null;
  }

  moment.locale(lang);
  const yesterday = moment().subtract(1, "days").startOf("day");
  const tomorrow = moment().add(1, "days").startOf("day");
  const isYesterday = date.startOf("day").isSame(yesterday);
  const isTomorrow = date.startOf("day").isSame(tomorrow);
  const isToday = date.startOf("day").isSame(moment().startOf("day"));
  const oneWeekLater = moment().add(7, "days").endOf("day");
  const isInComingWeek =
    date.startOf("day").isBefore(oneWeekLater) &&
    date.startOf("day").isAfter(moment().startOf("day"));
  const isExpiredDate = date.startOf("day").isBefore(moment().startOf("day"));

  if (isYesterday && !disableRedLabel) {
    return {
      date: translateMessage({
        intl,
        id: "ws_yesterday",
        defaultMessage: "Yesterday",
      }),
      style: "idh-date-picker__input--red",
    };
  }

  if (isTomorrow) {
    return {
      date: translateMessage({
        intl,
        id: "ws_tomorrow",
        defaultMessage: "Tomorrow",
      }),
      style: null,
    };
  }

  if (isToday) {
    return {
      date: translateMessage({
        intl,
        id: "ws_today",
        defaultMessage: "Today",
      }),
      style: "idh-date-picker__input--green",
    };
  }

  if (isInComingWeek) {
    return {
      date: date.format("dddd"),
      style: null,
    };
  }

  if (isExpiredDate && !disableRedLabel) {
    return { date, style: "idh-date-picker__input--red" };
  }

  return {
    date,
    style: null,
  };

  // Safari and Chrome Windows don't support below version
  //
  // const targetDate = new Date(date);
  // const todayDate = new Date();
  //
  // const utc1 = Date.UTC(
  //   todayDate.getFullYear(),
  //   todayDate.getMonth(),
  //   todayDate.getDate()
  // );
  // const utc2 = Date.UTC(
  //   targetDate.getFullYear(),
  //   targetDate.getMonth(),
  //   targetDate.getDate()
  // );
  //
  // const daysDifference = Math.floor((utc2 - utc1) / 86400000);
  //
  // const isTomorrow =
  //   targetDate.getFullYear() == todayDate.getFullYear() &&
  //   targetDate.getMonth() == todayDate.getMonth() &&
  //   targetDate.getDate() == todayDate.getDate() + 1;
  //
  // const isYesterday =
  //   targetDate.getFullYear() == todayDate.getFullYear() &&
  //   targetDate.getMonth() == todayDate.getMonth() &&
  //   targetDate.getDate() == todayDate.getDate() - 1;
  //
  // const inOneWeek = daysDifference <= 7;
  //
  // if (
  //   targetDate.toLocaleDateString("pl-PL") ===
  //   new Date().toLocaleDateString("pl-PL")
  // ) {
  //   return { date: "Today", style: "idh-date-picker__input--green" };
  // } else if (isTomorrow) {
  //   return { date: "Tomorrow", style: null };
  // } else if (isYesterday) {
  //   return { date: "Yesterday", style: "idh-date-picker__input--red" };
  // } else if (targetDate.getTime() < todayDate.getTime()) {
  //   return {
  //     date: getFullDate(targetDate),
  //     style: "idh-date-picker__input--red",
  //   };
  // } else if (inOneWeek) {
  //   return {
  //     date: targetDate.toLocaleDateString(lang, { weekday: "long" }),
  //     style: null,
  //   };
  // } else {
  //   return { date: getFullDate(targetDate), style: null };
  // }
};
