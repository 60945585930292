import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { SortingArrow } from "src/app/components/SortingArrow/SortingArrow";
import { setProjectSort } from "src/redux";

import "./ProjectsDataTableHeaderCell.scss";

const COLUMN_ID_TOOLTIP_BLACKLIST = ["projectAction"];

export function ProjectsDataTableHeaderCell({ column }) {
  const dispatch = useDispatch();
  const { sortBy, sort } = useSelector((state) => state.projectFiltersReducer);

  const ref = useRef();

  const doesOverflowWidth =
    ref?.current?.offsetWidth < ref?.current?.scrollWidth;
  const doesOverflowHeight =
    ref?.current?.offsetHeight < ref?.current?.scrollHeight;

  return (
    <th
      className="projects-data-table__header-cell"
      {...column.getHeaderProps()}
      data-column-name={column.Header}
      data-column-origin={column.origin || column.id}
    >
      <div
        className="projects-data-table__header-cell__content"
        style={{ width: column.width }}
      >
        {sortBy === column.id && (
          <SortingArrow
            sortingDirection={sort}
            setSortingDirection={(s) => dispatch(setProjectSort(s))}
          />
        )}
        <div
          ref={ref}
          style={{ maxWidth: 102 }}
          className="projects-data-table__header-cell__column-title"
          data-tooltip-id={`projects-data-table__header-name-tooltip-${column.id}`}
          data-tooltip-place="bottom"
        >
          {column.render("Header")}
        </div>
        {(doesOverflowHeight || doesOverflowWidth) &&
          !COLUMN_ID_TOOLTIP_BLACKLIST.includes(column.id) && (
            <ReactTooltip
              className="react-tooltip__dark-mode"
              id={`projects-data-table__header-name-tooltip-${column.id}`}
            >
              {column.render("Header")}
            </ReactTooltip>
          )}
      </div>
    </th>
  );
}
