import { useState, useEffect } from "react";

export function useFreeSpaceFinder(elRef, toggled) {
  const [variant, setVariant] = useState();

  useEffect(() => {
    if (toggled === true) {
      const rect = elRef.current.getBoundingClientRect();
      rect.x < 72 && setVariant("right");
      rect.right >
        (window.innerWidth || document.documentElement.clientWidth) &&
        setVariant("left");
    }
  }, [toggled]);

  return variant;
}
