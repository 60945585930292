import { setCreatorsMetadata } from "src/redux";
import { sortTasksByColumn } from "../sortTasksByColumn";
import { isMetaFieldMetric } from "../../components/ReportTemplate/components/CreatorBox/CreatorBox";

export const REPORT_ADDITIONAL_CREATOR_METAFIELDS = [
  "currency",
  "number",
  "percent",
  "rating",
];

export const addCreatorsToGridElements = (
  creatorsList,
  dispatch,
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  membersList,
  contentList,
) => {
  dispatch(
    setCreatorsMetadata(
      creatorsList[0]?.metadata
        .filter((item) =>
          REPORT_ADDITIONAL_CREATOR_METAFIELDS.includes(item.type),
        )
        .map((item) => ({
          ...item,
          reportVisible: isMetaFieldMetric(item),
        })),
    ),
  );

  const settings = JSON.parse(localStorage.getItem("ws-project-settings"));
  const projectId = window.location.pathname.split("/")[4];
  const projectSettings = settings?.find(
    (item) => item.projectId === projectId,
  );
  const { activeSortingColumn, sortingDirection } =
    projectSettings?.creator || {};

  creatorsList
    .sort((a, b) =>
      sortTasksByColumn(
        a,
        b,
        activeSortingColumn,
        sortingDirection,
        creatorsList,
        membersList,
        contentList,
      ),
    )
    .forEach((item) => {
      const label = `creator-${item.title
        .replaceAll(" ", "-")
        ?.replaceAll(".", "")
        .toLowerCase()}-${item.taskId}`;

      const element = {
        ...item,
        label,
        gridBoxType: "creator",
        section: "creatorsInTheCampaign",
      };

      if (savedTemplate?.length && !templateApplied) {
        if (savedTemplate.find((item) => item.i === element.label)) {
          newGridElements.push(element);
        } else {
          newHiddenGridElements.push(element);
        }
      }
      // applying default template
      else {
        newGridElements.push(element);
      }
    });
};
