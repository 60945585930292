import React, { useEffect, useState } from "react";
import "./ConditionsModal.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, {
  ModalText,
  ModalTitle,
} from "../../../../components/Modal/Modal";
import FieldCountConditions from "../../components/Summary/FieldCountConditions";

export function ConditionsModal(props) {
  const { onClose, conditionColumns, conditionValues, setConditionValues } =
    props;

  const [localConditionValues, setLocalConditionValues] =
    useState(conditionValues);

  const handleConfirm = () => {
    setConditionValues(localConditionValues);
    onClose();
  };

  return (
    <Modal
      onClick={(e) => e.stopPropagation()}
      className="conditions-modal"
      overlayClassName="conditions-modal__overlay"
      displayCancelButton
      onCancelClick={onClose}
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={handleConfirm}
      confirmButtonText="Set"
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_set_conditions"
          defaultMessage="Set conditions"
        />
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_conditions_modal_text"
          defaultMessage="Specify the conditions (values of specific fields) that must be met in order for a given field to be counted."
        />
      </ModalText>

      <FieldCountConditions
        conditionColumns={conditionColumns}
        conditionValues={localConditionValues}
        setConditionValues={setLocalConditionValues}
      />
    </Modal>
  );
}
