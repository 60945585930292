import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import TaskBox from "../TaskBox/TaskBox";
import "./BoardColumn.scss";

import { Droppable } from "react-beautiful-dnd";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useIsOverflow } from "src/app/methods/useIsOverflow";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { TaskType } from "src/types";
import { RootState } from "src/redux/reducers";
import { ReactComponent as DragNDropIcon } from "../../../images/drag-n-drop.svg";
import { TaskColumn } from "../Board";
import AnimatedDiv from "../../components/AnimatedDiv/AnimatedDiv";
import { Metadata } from "../../Task/Subtask/Subtask";

interface Props {
  tasks: any;
  columnData: any;
  placeholderProps?: any;
  boardColumns: Metadata[];
  disableImageLoad: boolean;
  setDisableImageLoad: Dispatch<SetStateAction<boolean>>;
}

const BoardColumn: React.FC<Props> = (props) => {
  const {
    tasks,
    columnData,
    placeholderProps,
    boardColumns,
    disableImageLoad,
    setDisableImageLoad,
  } = props;

  const [filteredTasks, setFilteredTasks] = useState<any>([]);

  const ref = useRef<any>(null);
  const isOverflow = ref?.current?.scrollWidth >= 264;

  const { taskType } = useSelector((state: RootState) => state.projectReducer);

  useEffect(() => {
    setFilteredTasks(
      tasks.filter((item: any) =>
        item.metadata.find(
          (item: TaskColumn) =>
            item.uuid === columnData.uuid && item.value === columnData.value,
        ),
      ),
    );
  }, [tasks]);

  return (
    <Droppable
      droppableId={columnData.value || "valueToNull"}
      type="CARD"
      direction="vertical"
      isCombineEnabled={false}
    >
      {(provided, snapshot) => (
        <div
          className={classNames("board-column", {
            "board-column--dragging-over": snapshot.isDraggingOver,
          })}
          {...provided.droppableProps}
        >
          <div className="board-column__topbar">
            <div className="board-column__topbar-content">
              <h1
                ref={ref}
                data-tooltip-id={`board-column__${columnData.value}`}
                data-tooltip-place="bottom"
              >
                {columnData.label}
              </h1>

              {isOverflow && (
                <ReactTooltip
                  id={`board-column__${columnData.value}`}
                  className="react-tooltip__dark-mode"
                >
                  {columnData.label}
                </ReactTooltip>
              )}

              <span className="board-column__topbar-counter">
                {filteredTasks.length}
              </span>
            </div>
          </div>
          <div className="board-column__content" ref={provided.innerRef}>
            <div className="board-column__task-placeholder">
              <DragNDropIcon />
              <div>
                Drag and drop a
                {taskType === TaskType.Action ||
                  (taskType === TaskType.Payment && "n")}
                &nbsp;
                <span style={{ textTransform: "capitalize" }}>{taskType}</span>
              </div>
              <div>here to change its value</div>
            </div>

            {tasks
              .filter((task: any) =>
                task.metadata.find(
                  (item: TaskColumn) =>
                    item.uuid === columnData.uuid &&
                    item.value === columnData.value,
                ),
              )
              .sort((a: { rank: string }, b: { rank: string }) => {
                return a.rank.localeCompare(b.rank);
              })
              .map((task: any, index: number) => (
                <TaskBox
                  key={task.id}
                  data={task}
                  index={index}
                  boardColumns={boardColumns.filter(
                    (column: { value: string }) =>
                      column.value !== columnData.value,
                  )}
                  disableImageLoad={disableImageLoad}
                  setDisableImageLoad={setDisableImageLoad}
                />
              ))}

            {provided.placeholder}
            {placeholderProps && snapshot.isDraggingOver && (
              <AnimatedDiv
                className="board-column__placeholder"
                style={{
                  top: placeholderProps?.clientY,
                  left: placeholderProps?.clientX,
                  height: placeholderProps?.clientHeight,
                  width: placeholderProps?.clientWidth,
                }}
              />
            )}
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default BoardColumn;
