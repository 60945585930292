import { getPages } from "./getPages";

export const addSeparatorsToLayout = (props) => {
  const {
    dispatch,
    format,
    gridElements,
    layout,
    newlyAddedPages,
    pages,
    separators,
    //
    setPages,
    setLayout,
    setSeparators,
  } = props;

  const newLayout = [...layout].filter(
    (item) =>
      !item.i.includes("separator") ||
      newlyAddedPages?.find((page) => item.y === page.end),
  );

  const newSeparators = [];

  const lastRow = Math.max(...newLayout.map((item) => item.y));
  const spaceLeft = format.rows - (lastRow % format.rows);
  const fullSpace = lastRow + spaceLeft;

  let previousBorder = 0;
  for (let i = format.rows - 1; i <= fullSpace; i += format.rows) {
    const end = i;
    const key = `separator-${end}`;

    const provider =
      separators?.find((item) => item.key === key)?.provider || "default";

    newSeparators.push({ key, provider });
    newLayout.push({
      i: key,
      x: 0,
      y: i,
      w: 12,
      h: 1,
      static: true,
    });

    previousBorder = i + 1;
  }

  const getPagesProps = {
    dispatch,
    format,
    gridElements,
    newlyAddedPages,
    oldLayout: newLayout,
    oldSeparators: newSeparators,
    pages,
    separators,
    //
    setPages,
    setLayout,
    setSeparators,
  };

  getPages(getPagesProps);
};
