import React from "react";
import "./CustomRadio.scss";
import classNames from "classnames";

function CustomRadio(props) {
  const { id, name, label, checked, onChange, disabled, ...rest } = props;

  return (
    <div
      className={classNames("ws-radio", {
        "ws-radio--disabled": disabled,
      })}
      {...rest}
    >
      <input
        type="radio"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

export default CustomRadio;
