import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import axios from "axios";

import classNames from "classnames";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { TaskType } from "src/types";
import { RootState } from "src/redux/reducers";
import { showErrorToast } from "src/utils/methods";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "../../components/Dropdown/Dropdown";
import { ReactComponent as SettingsIcon } from "../../../images/settings-light-grey.svg";
import { ReactComponent as TrashCanIcon } from "../../../images/trash-can.svg";
import { ReactComponent as DownloadIcon } from "../../../images/download.svg";
import { ReactComponent as SetAsCoverIcon } from "../../../images/set-as-cover.svg";
import { deleteTaskComment, getTaskDetails } from "../../../redux";
import { API_URLS } from "../../../utils/API_URLS";
import { showToast } from "../../methods/showToast";

interface Props {
  downloadUrl: string;
  commentId: string;
  assetId: number;
  taskType: TaskType;
  isImage?: boolean;
}

function AttachmentSettingsDropdown(props: Props) {
  const { downloadUrl, commentId, assetId, taskType, isImage } = props;
  const dispatch = useDispatch();
  const params = useParams<{ taskId: string }>();

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const deleteComment = async () => {
    try {
      await axios.delete(
        `${API_URLS.deleteAsset}/${activeWorkspaceUuid}/${assetId}`,
      );
      await dispatch(getTaskDetails(params.taskId));
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const downloadImage = () => {
    window.location.href = downloadUrl;
  };

  const setAsTaskCover = async () => {
    try {
      await axios.post(API_URLS.setAsTaskCover, {
        wsWorkspaceUuid: activeWorkspaceUuid,
        parentUuid: params.taskId,
        wsAssetId: assetId,
      });
      dispatch(getTaskDetails(params.taskId));
    } catch (err) {
      console.error(err);
      showToast("error", "Error", "The task cover has not been changed");
    }
  };

  return (
    <Dropdown
      className={classNames("attachment-settings-dropdown", {
        "attachment-settings-dropdown--dark": !isImage,
      })}
    >
      {(isOpen, setIsOpen) => (
        <>
          <SettingsIcon
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
            }}
          />
          <DropdownMenu isOpen={isOpen}>
            {taskType === TaskType.Content && isImage && (
              <DropdownMenuItem
                onClick={(e: any) => {
                  e.stopPropagation();
                  setAsTaskCover();
                }}
              >
                <SetAsCoverIcon />
                <IDHFormattedMessage
                  id="ws_set_as_cover"
                  defaultMessage="Set as cover"
                />
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              onClick={(e: any) => {
                e.stopPropagation();
                downloadImage();
              }}
            >
              <DownloadIcon />
              <IDHFormattedMessage id="ws_download" defaultMessage="Download" />
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={(e: any) => {
                e.stopPropagation();
                deleteComment();
              }}
              className="dropdown__menu-item--danger"
            >
              <TrashCanIcon />
              <IDHFormattedMessage id="ws_remove" defaultMessage="Remove" />
            </DropdownMenuItem>
          </DropdownMenu>
        </>
      )}
    </Dropdown>
  );
}

export default AttachmentSettingsDropdown;
