import React from "react";
import useInterval from "../../../../../utils/useInterval";
import "./PendingUserLoading.scss";

import { ReactComponent as PlaceholderAvatar } from "src/images/members-grey.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Loader from "../../../../components/Loader/Loader";
import {
  CDTableColumn,
  CDTableRow,
} from "../../../../components/CDTable/CDTable";

function PendingUserLoading({ getListData, influencerSocialUsername }) {
  useInterval(() => {
    getListData();
  }, 5000);

  const trimQuotes = (string) => {
    return string?.replace(/(^"|"$)/g, "") || string;
  };

  return (
    <div className="pending-user-loading">
      <CDTableRow>
        <CDTableColumn className="pending-user-loading__user">
          <PlaceholderAvatar width={80} height={80} />
          <div className="pending-user-loading__user-name">
            {trimQuotes(influencerSocialUsername)}
          </div>
        </CDTableColumn>
        <CDTableColumn className="pending-user-loading__text">
          <Loader grey />
          <IDHFormattedMessage
            id="front_analyzing_creator_data"
            defaultMessage="We are analyzing this creator’s data. Please check back in a minute."
          />
        </CDTableColumn>
      </CDTableRow>
    </div>
  );
}

export default PendingUserLoading;
