import React, { useState, useMemo, useEffect, useRef } from "react";

import axios from "axios";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import OuterLink from "src/app/components/OuterLink/OuterLink";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { translateMessage } from "src/app/methods/translateMessage";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import Loader from "src/app/components/Loader/Loader";
import { useLocation } from "react-router";
import { ReactComponent as WarningIcon } from "../../../images/warning-circle.svg";
import { Button } from "../../components/Button/Button";
import CustomInput, {
  CustomInputLabel,
} from "../../components/CustomInput/CustomInput";
import { API_URLS } from "../../../utils/API_URLS";
import { EventHelper } from "../../../helpers/EventHelper";
import {
  getCountryOptions,
  useSoftwareAsOptions,
  getPhoneNumberPrefixBaseOnCountryCode,
  formatPhoneNumber,
} from "./utils";
import { TrialRegistrationSuccess } from "./TrialRegistrationSuccess";

const PHONE_NUMBER_MIN_LENGTH = 4;

function TrialFormContent({ showForm, setShowForm }) {
  const { search } = useLocation();
  const [person, setPerson] = useState({
    name: "",
    contactNumber: "",
    companyWebsite: "",
    market: [],
    email: "",
    useSoftwareAs: { label: "", value: "" },
    phoneNumberPrefix: "",
  });

  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailExistsError, setEmailExistsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fccId, setFccId] = useState(null);

  const [waitingForActivationError, setWaitingForActivationError] =
    useState(false);
  const [countryCode, setCountryCode] = useState({
    label: "",
    value: "",
    code: "",
  });
  const [bdMemberData, setBdMemberData] = useState([]);

  const intl = useIntl();
  const phoneNumberRef = useRef(null);

  const countryOptions = useMemo(() => getCountryOptions(), []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      wsTrialName: person.name,
      wsTrialContactNumber: `${person.phoneNumberPrefix} ${person.contactNumber}`,
      wsTrialCompanyWebsite: person.companyWebsite,
      wsTrialEmail: person.email,
      wsTrialUseSoftwareAs: person.useSoftwareAs.value,
      wsTrialCountryCode: countryCode.code,
      wsTrialUtms: window?.localStorage?.getItem("idhUtms") ?? "",
    };

    try {
      setIsLoading(true);
      await axios.post(API_URLS.trialAccount, data).then((res) => {
        setBdMemberData(res.data.content.bdMemberData);
        setShowForm(false);
        setWaitingForActivationError(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    } catch (err) {
      setShowForm(true);
      if (err.response.data.statusCode !== 200) {
        setEmailErrorMessage(err.response.data.errors[0]);
        if (err.response.data.statusCode === 409) {
          setEmailExistsError(true);
        }
        if (err.response.data.statusCode === 403) {
          setWaitingForActivationError(true);
        }
      }
    } finally {
      setIsLoading(false);
    }

    EventHelper.emitEvent("ws:submitted.trial-registration-form");
  };

  const handleChange = (e) => {
    let newValue = e.target.value;

    if (e.target.name === "contactNumber") {
      if (person.phoneNumberPrefix) {
        newValue = newValue.slice(person.phoneNumberPrefix.length).trim();
      }
      newValue = formatPhoneNumber(newValue);
    }

    setPerson((prev) => ({
      ...prev,
      [e.target.name]: newValue,
    }));
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const paramFccId = params.get("fccId");
    if (paramFccId) {
      const formData = new FormData();
      formData.append("userUuid", paramFccId);

      axios
        .post(API_URLS.credibilityRefererAccount, formData)
        .then(function (response) {
          if (response.data?.status_code === 200) {
            setFccId(paramFccId);
            const { userData } = response.data.content;
            let currentPhoneNumber = userData.phone;

            const currentCountry = countryOptions.find(
              (c) => c.code === userData.country,
            );

            if (currentCountry) {
              const currentCountryPhoneNumberPrefix =
                getPhoneNumberPrefixBaseOnCountryCode(userData.country);
              setCountryCode(currentCountry);

              currentPhoneNumber = userData.phone.replace(
                currentCountryPhoneNumberPrefix,
                "",
              );
            }

            setPerson((prev) => ({
              ...prev,
              name: userData.name,
              email: userData.contactEmail,
              companyWebsite: userData.website,
              contactNumber: currentPhoneNumber,
              yourPosition: userData.position,
            }));
          }
        })
        .catch((error) => {});
    }
  }, [search]);

  useEffect(() => {
    if (!countryCode.code) return;
    setPerson((prev) => ({
      ...prev,
      phoneNumberPrefix: getPhoneNumberPrefixBaseOnCountryCode(
        countryCode.code,
      ),
    }));
  }, [countryCode.code]);

  useEffect(() => {
    if (!phoneNumberRef.current) return;
    if (person.phoneNumberPrefix) {
      if (
        person.contactNumber.split(" ").join("").length <
        PHONE_NUMBER_MIN_LENGTH
      ) {
        phoneNumberRef.current.setCustomValidity(
          translateMessage({
            intl,
            id: "ws_phone_number_validation",
            defaultMessage: `Phone number must have at least ${PHONE_NUMBER_MIN_LENGTH} characters.`,
          }),
        );
      } else {
        phoneNumberRef.current.setCustomValidity("");
      }
    }
  }, [person.contactNumber, person.phoneNumberPrefix, phoneNumberRef.current]);

  return (
    <div className="trial-view-content-right">
      <div className="trial-view-content-right__wrapper">
        <div
          className={classNames("trial-view-content-right__container", {
            "trial-view-content-right__container--confirm": !showForm,
          })}
        >
          {showForm ? (
            <form onSubmit={handleSubmit}>
              <CustomInputLabel aboveInput htmlFor="name">
                <IDHFormattedMessage id="ws_name" defaultMessage="Name" />
              </CustomInputLabel>
              <CustomInput
                name="name"
                id="name"
                value={person.name}
                onChange={handleChange}
                required
                type="text"
                placeholder="Jane Doe"
                fullWidth
              />
              <CustomInputLabel aboveInput htmlFor="companyWebsite">
                <IDHFormattedMessage
                  id="ws_company_website"
                  defaultMessage="Company website"
                />
              </CustomInputLabel>
              <CustomInput
                name="companyWebsite"
                id="companyWebsite"
                value={person.companyWebsite}
                onChange={handleChange}
                required
                placeholder="www.company.com"
                pattern="[^ ]+\.[A-Za-z0-9]+"
                size="30"
                type="text"
                fullWidth
              />
              <CustomInputLabel htmlFor="email" aboveInput>
                <IDHFormattedMessage
                  id="ws_business_email"
                  defaultMessage="Business Email"
                />
              </CustomInputLabel>
              <CustomInput
                name="email"
                id="email"
                value={person.email}
                onChange={handleChange}
                required
                type="email"
                placeholder="jane.doe@company.com"
                fullWidth
              />
              <div className="trial-view-content-right__row">
                <div className="trial-view-content-right__column">
                  <CustomInputLabel aboveInput>
                    <IDHFormattedMessage
                      id="ws_country_code"
                      defaultMessage="Country code"
                    />
                  </CustomInputLabel>
                  <CustomSelect
                    id="ws-country-code"
                    value={countryCode}
                    options={countryOptions}
                    placeholder={`${translateMessage({
                      intl,
                      id: "ws_select_country",
                      defaultMessage: "Select country",
                    })}...`}
                    onChange={setCountryCode}
                    isSearchable
                    required
                  />
                </div>
                <div className="trial-view-content-right__column">
                  <CustomInputLabel aboveInput htmlFor="contactNumber">
                    <IDHFormattedMessage
                      id="ws_contact_number"
                      defaultMessage="Contact number"
                    />
                  </CustomInputLabel>
                  <CustomInput
                    name="contactNumber"
                    id="contactNumber"
                    value={
                      person.phoneNumberPrefix
                        ? `${person.phoneNumberPrefix} ${person.contactNumber}`
                        : person.contactNumber
                    }
                    onChange={handleChange}
                    ref={phoneNumberRef}
                    required
                    type="tel"
                    maxLength={22}
                    fullWidth
                    placeholder="e.g. 999-999-9999"
                  />
                </div>
              </div>

              <CustomInputLabel aboveInput>
                <IDHFormattedMessage
                  id="ws_you_will_use_indahash_software_as"
                  defaultMessage="You will use IndaHash Software as"
                />
              </CustomInputLabel>
              <CustomSelect
                id="ws-software"
                value={person.useSoftwareAs}
                options={useSoftwareAsOptions}
                placeholder={`${translateMessage({
                  intl,
                  id: "ws_agency",
                  defaultMessage: "Agency",
                })}...`}
                onChange={(newValue) =>
                  setPerson({
                    ...person,
                    useSoftwareAs: newValue,
                  })
                }
                required
              />
              {emailErrorMessage && (
                <>
                  <div className="trial-view-content-right__invalid-email-error">
                    <WarningIcon className="login-page-right__invalid-email-error-icon" />
                    <span
                      dangerouslySetInnerHTML={{ __html: emailErrorMessage }}
                    />
                    &nbsp;
                  </div>
                  {emailExistsError && (
                    <div className="trial-view-content-right__sign-in">
                      <span>
                        <Link to="/workspace">
                          <IDHFormattedMessage
                            id="ws_sign_in"
                            defaultMessage="Sign in"
                          />
                        </Link>
                        &nbsp;
                        <IDHFormattedMessage
                          id="ws_instead"
                          defaultMessage="instead"
                        />
                        .
                      </span>
                    </div>
                  )}
                </>
              )}

              <div className="trial-view-content-right__disclaimer">
                <IDHFormattedMessage
                  id="ws_by_signing_up_you_agree_with"
                  defaultMessage="By signing up, you agree with our"
                />
                <OuterLink href="https://docs.indahash.com/doc/?n=privacy-policy">
                  &nbsp;
                  <IDHFormattedMessage
                    id="ws_privacy_policy"
                    defaultMessage="Privacy Policy"
                  />
                </OuterLink>
                &nbsp;
                <IDHFormattedMessage id="ws_and" defaultMessage="and" />
                &nbsp;
                <OuterLink href="https://docs.indahash.com/doc/?n=terms-conditions-for-self-serve">
                  <IDHFormattedMessage
                    id="ws_terms_of_service"
                    defaultMessage="Terms of Service"
                  />
                </OuterLink>
              </div>
              <div className="trial-view-content-right__submit-wrapper">
                {isLoading ? (
                  <Loader />
                ) : (
                  <Button
                    size="large"
                    variant="blue"
                    className="trial-view-content-right__btn"
                  >
                    <IDHFormattedMessage
                      id="ws_continue"
                      defaultMessage="Continue"
                    />
                  </Button>
                )}
              </div>
            </form>
          ) : (
            <TrialRegistrationSuccess
              setShowForm={setShowForm}
              bdMemberData={bdMemberData}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export { TrialFormContent };
