import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { AttachmentPlacement, TaskType } from "src/types";
import { RootState } from "src/redux/reducers";
import { CommentType, IRegularComment } from "src/redux/comment/types";
import { IMember } from "src/app/project/ProjectTypes";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as ReplyIcon } from "src/images/reply.svg";
import Attachment, { AttachmentMetaData } from "../../Attachments/Attachment";
import EditCommentField from "../../components/EditCommentField/EditCommentField";
import CommentWrapper from "./CommentWrapper";
import CommentText, { decodeComment } from "./CommentText";
import ReplyToCommentButton from "./ReplyToCommentButton";
import { renderContentProposalElementsModeration } from "./ContentProposalElementModerationComment";

interface RegularCommentProps {
  comment: IRegularComment;
  commentAuthor: IMember | undefined;
  membersList: IMember[];
  taskType: TaskType;
  handleScrollToComment: () => void;
  hasAccessToRemoveWsTaskComment: boolean;
}

export default function RegularComment({
  comment,
  commentAuthor,
  membersList,
  taskType,
  handleScrollToComment,
  hasAccessToRemoveWsTaskComment,
}: RegularCommentProps) {
  const {
    commentReducer: { currentEditedComment },
  } = useSelector((state: RootState) => state);

  return (
    <CommentWrapper
      comment={comment}
      commentId={comment.commentId}
      commentAuthor={commentAuthor}
      commentAuthorCustomElement={
        comment.replyWsTaskComment && (
          <div
            className="comment-header-element__action-info comment-header-element__action-info--scroll"
            onClick={handleScrollToComment}
          >
            <ReplyIcon />
            <IDHFormattedMessage id="ws_replied" defaultMessage="replied" />
          </div>
        )
      }
      hasAccessToRemoveWsTaskComment={hasAccessToRemoveWsTaskComment}
    >
      {currentEditedComment === comment.commentId ? (
        <EditCommentField
          comment={comment.comment}
          commentId={comment.commentId}
          taskType={taskType}
        />
      ) : (
        <div className="content-proposal-moderation-comment-body">
          {comment.replyWsTaskComment?.type ===
          CommentType.ContentProposalElementModeration
            ? renderContentProposalElementsModeration(
                comment.replyWsTaskComment,
              )
            : null}
          {comment.comment.length > 0 || comment.replyWsTaskComment ? (
            <CommentText>
              {comment.replyWsTaskComment &&
              comment.replyWsTaskComment.comment.length > 0 ? (
                <div
                  className={classNames(
                    "content-proposal-comment__content-wrapper",
                    "content-proposal-comment__content-wrapper--caption",
                  )}
                >
                  <div className="content-proposal-comment__caption">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: decodeComment(
                          comment.replyWsTaskComment.comment,
                          membersList,
                        ),
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {comment.comment.length > 0 ? (
                <span
                  className="mentionText"
                  dangerouslySetInnerHTML={{
                    __html: decodeComment(comment.comment, membersList),
                  }}
                />
              ) : null}
              <ReplyToCommentButton
                wsTaskUuid={comment.taskId}
                parentWsTaskCommentUuid={comment.commentId}
                replyToCommentText={comment.comment}
              />
            </CommentText>
          ) : null}
          {comment.attachments?.length > 0 &&
            comment.attachments.map((attachment: AttachmentMetaData) => (
              <Attachment
                data={attachment}
                place={AttachmentPlacement.Comment}
                taskType={taskType}
                key={attachment.id}
              />
            ))}
        </div>
      )}
    </CommentWrapper>
  );
}
