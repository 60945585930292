import React, { useState } from "react";

import { useParams } from "react-router";

import { CustomTable } from "src/app/components/CustomTable/CustomTable";
import {
  nameColumnWidth,
  actionColumnWidth,
} from "src/app/components/GlobalTaskDetails/tabs/GlobalActivityTab/utils";
import goToImage from "src/images/share-alt.svg";
import { Img } from "src/app/components/Img/Img";
import { EmptyActivityTab } from "src/app/Task/tabs/ActivityTab/EmptyActivityTab";
import { AvatarAndName } from "src/app/components/GlobalTaskDetails/tabs/GlobalActivityTab/GlobalActivityTabCampaigns";
import { TaskType } from "src/types";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import AssociateTaskModal from "src/app/modals/AssociateTaskModal/AssociateTaskModal";
import { ProjectPermissionsEnum } from "src/utils/PermissionsEnums";
import { Button } from "src/app/components/Button/Button";
import { ReactComponent as PlusWhiteIcon } from "src/images/plus-white.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import placeholder from "src/images/placeholder-image.svg";
import { EmptyContentTab } from "../ProfileDataTab/components/EmptyTabs/EmptyContentTab";
import { handleRowClick } from "../../utils/methods";
import Select from "../../../components/Select/Select";
import { Task } from "../ActivityTab/ActivityTab";

interface ContentTabProps {
  tasks: Task[];
  taskType: TaskType;
  permissions: { project: string[] };
}

export const contentTabColumnSettings = [
  {
    Header: "NAME",
    accessor: "name",
    width: nameColumnWidth,
  },
  {
    Header: "TYPE",
    accessor: "type",
    width: 100,
  },
  {
    Header: "ACTION",
    accessor: "action",
    width: actionColumnWidth,
  },
];

export function ContentTab({ tasks, taskType, permissions }: ContentTabProps) {
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);

  const params = useParams<{ projectId: string; workspaceUuid: string }>();

  const generateRows = (tasks: Task[]) => {
    return tasks.map((task) => {
      const keys = {};

      contentTabColumnSettings.forEach((colSettings) => {
        const foundMetDataItem = task.metadata.find(
          (metaDataItem) =>
            metaDataItem.key === colSettings.accessor &&
            colSettings.accessor !== "title",
        );

        if (foundMetDataItem) {
          if (foundMetDataItem.type === MetaFieldType.SingleSelect) {
            Object.assign(keys, {
              [foundMetDataItem.key]: (
                <Select
                  mode="single"
                  optionsData={foundMetDataItem?.data?.singleSelectOptions}
                  uuid={foundMetDataItem?.uuid}
                  fieldValue={foundMetDataItem.value}
                  fieldName={foundMetDataItem.name}
                  readOnly
                />
              ),
            });
          } else {
            Object.assign(keys, {
              [foundMetDataItem.key]: foundMetDataItem.value,
            });
          }
        }
      });

      return {
        name: (
          <AvatarAndName
            name={task.title}
            avatar={task.cover}
            placeholder={placeholder}
            wrapperBackgroundColor="#F2F2F6"
          />
        ),
        ...keys,
        action: <Img src={goToImage} fallbackImageSrc={goToImage} />,
        onRowClick: () =>
          handleRowClick(
            task.id,
            TaskType.Content,
            params.workspaceUuid,
            params.projectId,
          ),
      };
    });
  };

  return (
    <div className="activity-tab__table-wrapper">
      <CustomTable
        columns={contentTabColumnSettings}
        data={generateRows(tasks)}
        headerClassName="activity-tab__table-header"
        noRowsPlaceholder={
          permissions?.project.includes(
            ProjectPermissionsEnum.TASK_MANAGEMENT,
          ) ? (
            <EmptyContentTab
              taskType={taskType}
              onButtonClick={() => setShowAddTaskModal(true)}
            />
          ) : (
            <EmptyActivityTab />
          )
        }
      />

      {tasks.length > 0 &&
        permissions?.project.includes(
          ProjectPermissionsEnum.TASK_MANAGEMENT,
        ) && (
          <Button
            className="activity-tab__add-button"
            size="thin"
            variant="blue"
            onClick={() => setShowAddTaskModal(true)}
          >
            <PlusWhiteIcon />
            <IDHFormattedMessage
              id="ws_add_content"
              defaultMessage="Add content"
            />
          </Button>
        )}

      {showAddTaskModal && (
        <AssociateTaskModal
          projectId={params.projectId}
          onClose={() => setShowAddTaskModal(false)}
          taskType={TaskType.Content}
          tasksList={tasks}
          lastTaskRank={tasks.length ? tasks[tasks.length - 1].rank : ""}
        />
      )}
    </div>
  );
}
