import React from "react";
import Pictogram, {
  PictogramVariants,
} from "../../../components/Pictogram/Pictogram";
import { tableDataType } from "../../../components/Table/Table";

export const renderMetaFieldIcon = (data, dataType) => {
  const {
    data: { icon },
  } = data || {};

  const canDisplayIcon =
    (dataType === tableDataType.Project ||
      (dataType === tableDataType.Task && icon?.showIcon)) &&
    Boolean(icon);

  if (canDisplayIcon) {
    const iconName = icon.name;
    const folder = iconName.includes("/") ? iconName.split("/")[0] : "Social";
    const name = iconName.includes("/") ? iconName.split("/")[1] : iconName;
    const variant =
      folder === "SocialProviders"
        ? PictogramVariants.Original
        : PictogramVariants[icon.color];

    return (
      <span className="ws-table__field-value-icon">
        <Pictogram identifier={`${folder}/${name}`} variant={variant} />
      </span>
    );
  }

  return null;
};
