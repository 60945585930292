import React, { useState } from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import classNames from "classnames";
import { ReactComponent as CredibilityIcon } from "../../../../../images/profile-data/credibility.svg";
import { ReactComponent as TickPurple } from "../../../../../images/tick-purple.svg";
import ProfileBox, {
  PBContent,
  PBHeaderWithProgressBar,
} from "../components/ProfileBox/ProfileBox";
import { Chevron } from "../../../../components/Chevron/Chevron";
import { Button } from "../../../../components/Button/Button";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { formatAudienceReachabilityLabel } from "../../../../../utils/methods";

export function Credibility(props) {
  const {
    credibility,
    height,
    credibilityAudienceTypes,
    credibilityAudienceReachability,
  } = props;
  const [showCredibilityDetails, setShowCredibilityDetails] = useState(false);

  const showContent = !height || height > 1;
  const onShowcase = Boolean(height);

  return (
    <ProfileBox block>
      <PBHeaderWithProgressBar
        icon={<CredibilityIcon />}
        count={credibility}
        title={
          <IDHFormattedMessage
            id="ws_credibility"
            defaultMessage="Credibility"
          />
        }
        onShowcase={onShowcase}
      />
      {showContent && (
        <PBContent column>
          <div
            className={classNames("profile-box__credibility-info", {
              "profile-box__credibility-info--on-showcase": onShowcase,
            })}
          >
            {!onShowcase && (
              <div className="profile-box__credibility-info__show-details">
                <div className="profile-box__credibility-info__show-details-header">
                  <IDHFormattedMessage
                    id="ws_credibility_info-header"
                    defaultMessage="Credibility Score for influential Creators"
                  />
                  <Button
                    className="profile-box__credibility-info__show-details-header-btn"
                    onClick={() =>
                      setShowCredibilityDetails(!showCredibilityDetails)
                    }
                    variant="profile-box__credibility-info__show-details-header-btn"
                  >
                    {showCredibilityDetails ? (
                      <IDHFormattedMessage
                        id="ws_show_less"
                        defaultMessage="Show less"
                      />
                    ) : (
                      <IDHFormattedMessage
                        id="ws_show_more"
                        defaultMessage="Show more"
                      />
                    )}
                    <Chevron
                      expanded={showCredibilityDetails}
                      setExpanded={setShowCredibilityDetails}
                    />
                  </Button>
                </div>

                {showCredibilityDetails ? (
                  <div className="profile-box__credibility-info__show-details-description">
                    <div className="profile-box__credibility-info-table">
                      <div className="profile-box__credibility-info-table-row">
                        <div className="profile-box__credibility-info-table-row-range">
                          <IDHFormattedMessage
                            id="ws_credibility_info-table-brand-secure-percentage"
                            defaultMessage="60-70%"
                          />
                        </div>
                        <div className="profile-box__credibility-info-table-row-description">
                          <div className="profile-box__credibility-info-table-row-type">
                            <IDHFormattedMessage
                              id="ws_credibility_info-table-brand-secure"
                              defaultMessage="Brand Secure"
                            />
                          </div>
                          <IDHFormattedMessage
                            id="ws_credibility_info-table-brand-secure-description"
                            defaultMessage="Ensures enought credibility for brand safety during campaigns."
                          />
                        </div>
                      </div>
                      <div className="profile-box__credibility-info-table-row">
                        <div className="profile-box__credibility-info-table-row-range">
                          <IDHFormattedMessage
                            id="ws_credibility_info-table-market-average-percentage"
                            defaultMessage="70-80%"
                          />
                        </div>
                        <div className="profile-box__credibility-info-table-row-description">
                          <div className="profile-box__credibility-info-table-row-type">
                            <IDHFormattedMessage
                              id="ws_credibility_info-table-market-average"
                              defaultMessage="Market Average"
                            />
                          </div>
                          <IDHFormattedMessage
                            id="ws_credibility_info-table-market-average-description"
                            defaultMessage="Represents a good quality followers base compared to the industry average."
                          />
                        </div>
                      </div>
                      <div className="profile-box__credibility-info-table-row">
                        <div className="profile-box__credibility-info-table-row-range">
                          <IDHFormattedMessage
                            id="ws_credibility_info-table-above-market-avg-percentage"
                            defaultMessage="80-90%"
                          />
                        </div>
                        <div className="profile-box__credibility-info-table-row-description">
                          <div className="profile-box__credibility-info-table-row-type">
                            <IDHFormattedMessage
                              id="ws_credibility_info-table-above-market-avg"
                              defaultMessage="Above Market Average"
                            />
                          </div>
                          <IDHFormattedMessage
                            id="ws_credibility_info-table-above-market-avg-description"
                            defaultMessage="Indicates higher quality followers compared to the industry average."
                          />
                        </div>
                      </div>
                      <div className="profile-box__credibility-info-table-row">
                        <div className="profile-box__credibility-info-table-row-range">
                          <IDHFormattedMessage
                            id="ws_credibility_info-table-rare-quality-percentage"
                            defaultMessage="+90%"
                          />
                        </div>
                        <div className="profile-box__credibility-info-table-row-description">
                          <div className="profile-box__credibility-info-table-row-type">
                            <IDHFormattedMessage
                              id="ws_credibility_info-table-rare-quality"
                              defaultMessage="Rare quality"
                            />
                          </div>
                          <IDHFormattedMessage
                            id="ws_credibility_info-table-rare-quality-description"
                            defaultMessage="Represents exceptional quality of followers."
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        "profile-box__credibility-description",
                        {
                          "profile-box__credibility-description--on-showcase":
                            onShowcase,
                        },
                      )}
                    >
                      <IDHFormattedMessage
                        id="ws_credibility_description"
                        defaultMessage={`This parameter determines how reliable are the users following this account. The higher the parameter, the more real and engaged the
            account's audience. Following activities are considered as "not
            credible":`}
                      />
                    </div>
                    <div className="profile-box__credibility-list">
                      <div className="profile-box__credibility-list-item">
                        <TickPurple className="profile-box__credibility-tick" />
                        <IDHFormattedMessage
                          id="ws_credibility_item-50-100"
                          defaultMessage="An account has no profile photo or photos or bio, but likes 50-100
              posts per day."
                        />
                      </div>
                      <div className="profile-box__credibility-list-item">
                        <TickPurple className="profile-box__credibility-tick" />
                        <IDHFormattedMessage
                          id="ws_credibility_item-from-50"
                          defaultMessage="An account followers no-one, but likes all posts from 50
                individuals."
                        />
                      </div>
                      <div className="profile-box__credibility-list-item">
                        <TickPurple className="profile-box__credibility-tick" />
                        <IDHFormattedMessage
                          id="ws_credibility_item-no-bio"
                          defaultMessage="An account has posted 100 comments in a day on multiple days. But
                has no bio or posts."
                        />
                      </div>
                      <div className="profile-box__credibility-list-item">
                        <TickPurple className="profile-box__credibility-tick" />
                        <IDHFormattedMessage
                          id="ws_credibility_item-liked-500"
                          defaultMessage="A profile
                has been active for months, but has no photos and has liked 500+
                posts per day."
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
            <div
              className={classNames("profile-box__credibility-info-stats", {
                "profile-box__credibility-info-stats--on-showcase": onShowcase,
              })}
            >
              {credibilityAudienceTypes &&
                credibilityAudienceTypes?.length > 0 && (
                  <div className="profile-box__credibility-info-stats-col">
                    <div className="profile-box__heading">
                      <IDHFormattedMessage
                        id="ws_credibility_audience_types"
                        defaultMessage="Audience Types"
                      />
                      {!onShowcase && (
                        <Tooltip
                          center
                          content={
                            <IDHFormattedMessage
                              id="ws_credibility_audience_types_tooltip"
                              defaultMessage="Audience Types Tooltip"
                            />
                          }
                        />
                      )}
                    </div>
                    {credibilityAudienceTypes
                      ?.sort((a, b) => b.weight - a.weight)
                      ?.map((item, index) => (
                        <div className="progress-bar-data__item" key={index}>
                          <div className="progress-bar-data__label">
                            <IDHFormattedMessage
                              id={`ws_credibility_audience_type_${item.code}`}
                              defaultMessage={item.code}
                            />
                          </div>
                          <div className="progress-bar-data__bar">
                            <ProgressBar
                              percent={Math.round(item.weight * 100)}
                              barColor="#7848C7"
                              backgroundColor="rgba(120, 72, 199, 0.1)"
                            />
                          </div>
                          <div className="progress-bar-data__value">
                            {Math.round(item.weight * 100)}%
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              {credibilityAudienceReachability &&
                credibilityAudienceReachability?.length > 0 && (
                  <div className="profile-box__credibility-info-stats-col">
                    <div className="profile-box__heading">
                      <IDHFormattedMessage
                        id="ws_credibility_audience_reachability"
                        defaultMessage="Audience Reachability"
                      />
                      {!onShowcase && (
                        <Tooltip
                          center
                          content={
                            <IDHFormattedMessage
                              id="ws_credibility_audience_reachability_tooltip"
                              defaultMessage="Audience Reachability Tooltip"
                            />
                          }
                        />
                      )}
                    </div>
                    <div className="">
                      {credibilityAudienceReachability?.map((item, index) => (
                        <div className="progress-bar-data__item" key={index}>
                          <div className="progress-bar-data__label">
                            {formatAudienceReachabilityLabel(item.code)}
                          </div>
                          <div className="progress-bar-data__bar">
                            <ProgressBar
                              percent={Math.round(item.weight * 100)}
                              barColor="#7848C7"
                              backgroundColor="rgba(120, 72, 199, 0.1)"
                            />
                          </div>
                          <div className="progress-bar-data__value">
                            {Math.round(item.weight * 100)}%
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </PBContent>
      )}
    </ProfileBox>
  );
}
