import React from "react";
import classNames from "classnames";
import "./VelocityIcon.scss";

function VelocityIcon({ percent }) {
  return (
    <div
      className={classNames("velocity-icon", {
        "velocity-icon--high": percent > 50,
        "velocity-icon--low": percent === 0,
      })}
    />
  );
}

export default VelocityIcon;
