import React from "react";

import { SectionHeader } from "../components/ReportTemplate/components/SectionHeader/SectionHeader";

export const renderHeader = (header, GridBox, subHeader) => {
  const key = header.label;

  return (
    <GridBox key={key} id={key} smallPadding={!subHeader}>
      <SectionHeader id={key} text={header.text} subHeader={subHeader} />
    </GridBox>
  );
};
