import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

import AvatarIcon from "../../../images/credibility-tool/avatar-left-panel.png";
import { ReactComponent as CredibilityIcon } from "../../../images/profile-data/credibility.svg";

import ProgressBar from "../../components/ProgressBar/ProgressBar";

function LeftSide() {
  return (
    <div className="credibility-tool-page-left">
      <div className="credibility-tool-page-left__video-wrapper">
        <video autoPlay loop muted>
          <source
            src={require("../../../images/credibility-tool/credibility-main-video.mp4")}
            type="video/mp4"
          />
        </video>
      </div>
      <div className="credibility-tool-page-left__container">
        <div className="credibility-tool-page-left__header">
          <IDHFormattedMessage
            id="ws_credibility_tool_left_panel__header"
            defaultMessage="86%"
          />
        </div>
        <div className="credibility-tool-page-left__description">
          <IDHFormattedMessage
            id="ws_credibility_tool_left_panel_description"
            defaultMessage="of brands achieving their campaign goals collaborate with creators with at least 70.00% credibility.*"
          />
        </div>
        <div className="credibility-tool-page-left__boxes">
          <div className="credibility-tool-page-left__box">
            <div className="credibility-tool-page-left__box-column">
              <div className="credibility-tool-page-left__profile">
                <div className="credibility-tool-page-left__profile-avatar">
                  <img
                    src={AvatarIcon}
                    className="credibility-tool-page-left__box-icon"
                  />
                </div>
                <div className="credibility-tool-page-left__profile-data">
                  <div className="credibility-tool-page-left__profile-data-header">
                    <IDHFormattedMessage
                      id="ws_credibility_tool_example_profile"
                      defaultMessage="romina_paret"
                    />
                  </div>
                  <div className="credibility-tool-page-left__profile-data-description">
                    <IDHFormattedMessage
                      id="ws_credibility_tool_example_profile_sex"
                      defaultMessage="Female"
                    />
                    <IDHFormattedMessage
                      id="ws_credibility_tool_example_profile_country"
                      defaultMessage="Aarschot, BE"
                    />
                  </div>
                </div>
              </div>
              <div className="credibility-tool-page-left__credibility-score">
                <CredibilityIcon />

                <div className="credibility-score-text">
                  <IDHFormattedMessage
                    id="ws_credibility"
                    defaultMessage="Credibility"
                  />
                </div>
                <ProgressBar
                  percent={93}
                  barColor="#8358FF"
                  progressSeparator={null}
                  backgroundColor="rgba(237, 237, 239, 1)"
                />
                <div className="credibility-score-value">93.85%</div>
              </div>
            </div>
          </div>
        </div>
        <div className="credibility-tool-page-left__social-platform">
          <IDHFormattedMessage
            id="ws_credibility_tool_image_source"
            defaultMessage="Image source: instagram.com/romina_paret/ Campaign: Wella Professionals"
          />
        </div>
        <div className="credibility-tool-page-left__copyrights">
          <IDHFormattedMessage
            id="ws_credibility_tool_left_panel_footer"
            defaultMessage="The statistics are based on 100 randomly selected campaigns conducted by our managed service team."
          />
        </div>
      </div>
    </div>
  );
}

export { LeftSide };
