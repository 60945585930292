import React, { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import RatingSelector, {
  RatingSelectorSize,
} from "src/app/components/RatingSelector/RatingSelector";
import { RootState } from "src/redux/reducers";

interface Props {
  col: Metadata;
  sendFilter: (colUuid: string, value: any) => void;
}

function SingleRatingFilter(props: Props) {
  const { col, sendFilter } = props;

  const { filters } = useSelector(
    (state: RootState) => state.projectFiltersReducer,
  );

  const stars = ["0", "1", "2", "3", "4", "5"];

  const onChange = (checked: boolean, rating: string | null) => {
    if (checked) {
      sendFilter(col.uuid, [...(filters[col.uuid]?.value || []), rating]);
    } else {
      sendFilter(col.uuid, [
        ...filters[col.uuid]?.value.filter((r: string) => r !== rating),
      ]);
    }
  };

  return stars.map((rating) => {
    const ratingValue = rating === "0" ? null : rating;
    return (
      <CustomCheckbox
        name={`filters-${col?.uuid}-${rating}`}
        label={
          <RatingSelector
            value={Number.parseInt(rating)}
            disabled
            size={RatingSelectorSize.Medium}
          />
        }
        id={`filters-${col?.uuid}-${rating}`}
        checked={!!filters[col.uuid]?.value.includes(ratingValue)}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.checked, ratingValue)
        }
      />
    );
  });
}

export default SingleRatingFilter;
