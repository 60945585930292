import React from "react";
import axios from "axios";
import fileDownload from "js-file-download";

import { showToast } from "src/app/methods/showToast";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export const downloadUrl = (url, defaultName) => {
  axios({
    url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      const fileName = response.headers["content-disposition"]
        ? response.headers["content-disposition"]
            .split("filename=")[1]
            .split("; ")[0]
            .slice(1, -1)
        : defaultName;
      fileDownload(response.data, fileName);
    })
    .catch((e) => {
      e.response.status === 404
        ? showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage id="front_invalid_list_id_list_does_not_exist" />,
          )
        : showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id="ws_something_went_wrong"
              defaultMessage="Something went wrong"
            />,
          );
    });
};
