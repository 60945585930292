import { SET_ATTACHMENT_LOADING } from "./attachmentTypes";
import { AttachmentReducerState, SetAttachmentLoadingAction } from "./types";

const initialState: AttachmentReducerState = {
  attachmentLoading: false,
  loadingFileName: null,
};

function attachmentReducer(
  state = initialState,
  action: any,
): AttachmentReducerState {
  switch (action.type) {
    case SET_ATTACHMENT_LOADING: {
      const { loading, fileName }: SetAttachmentLoadingAction["payload"] =
        action.payload;

      return {
        ...state,
        attachmentLoading: loading,
        loadingFileName: fileName,
      };
    }

    default:
      return state;
  }
}

export default attachmentReducer;
