import React from "react";
import { Location } from "../../../Task/tabs/ProfileDataTab/single/Location";

export const renderLocation = (label, data, layout, GridBoxComponent) => {
  const { countryLocations } = data;

  const key = label;

  const height = layout?.find((item) => item.i === key)?.h;

  return (
    <GridBoxComponent key={key} id={key}>
      <Location countryLocations={countryLocations} height={height} />
    </GridBoxComponent>
  );
};
