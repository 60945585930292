import React from "react";

import { ReactComponent as ActivityIcon } from "src/images/menu-icons/activity.svg";
import { ReactComponent as CompassIcon } from "src/images/compass.svg";
import { ReactComponent as RocketBlueIcon } from "src/images/menu-icons/rocket-blue.svg";
import { ReactComponent as MyTasksIcon } from "src/images/menu-icons/my-tasks.svg";
import { ReactComponent as DocumentsIcon } from "src/images/menu-icons/documents.svg";
import { ReactComponent as PromoCodesIcon } from "src/images/menu-icons/promo.svg";
import { ReactComponent as CreatorDatabaseIcon } from "src/images/menu-icons/creator-database.svg";
import { CreatorDiscoveryLink } from "./CreatorDiscoveryLink/CreatorDiscoveryLink";
import { getIconByPath } from "../../utils/methods";

export type SidebarItemTypes = SidebarSystemItem | SidebarDictionaryItem;

export type SidebarElement = {
  icon: React.ReactNode;
  path: string | null;
  key: string;
  translateKey?: string;
  defaultMessage: string;
  overrideHtml?: React.ReactNode;
};

export enum SidebarItemType {
  System = "system",
  Dictionary = "wsDictionary",
}

export type SidebarDictionaryItem = {
  type: SidebarItemType.Dictionary;
  wsDictionaryName: string;
  wsDictionaryElementName: string;
  wsDictionaryUrl: string;
  wsDictionaryIcon: string;
  wsDictionaryUuid: string;
};

type SidebarSystemItem = { type: SidebarItemType.System; key: string };

export const getSidebarElement = (item: SidebarItemTypes) => {
  switch (item.type) {
    case SidebarItemType.System:
      return sidebarSystemElements.find((el) => el.key === item.key) ?? null;
    case SidebarItemType.Dictionary:
      const path = `dict/${item.wsDictionaryUrl}/${item.wsDictionaryUuid}`;
      return {
        icon: getIconByPath(item.wsDictionaryIcon),
        path,
        key: `front_sidebar_item_${item.wsDictionaryUrl}`,
        defaultMessage: item.wsDictionaryName,
      };
    default:
      return null;
  }
};

const sidebarSystemElements: SidebarElement[] = [
  {
    icon: <ActivityIcon />,
    path: "activity",
    key: "front_sidebar_item_activity_logs",
    translateKey: "ws_activity",
    defaultMessage: "Activity",
  },
  {
    icon: <CompassIcon />,
    path: null,
    key: "front_sidebar_item_creator_discovery",
    translateKey: "ws_discovery",
    defaultMessage: "Discovery",
    overrideHtml: <CreatorDiscoveryLink />,
  },
  {
    icon: <CreatorDatabaseIcon />,
    path: "creator-database",
    key: "front_sidebar_item_creator_database",
    translateKey: "ws_creator_database",
    defaultMessage: "Creator Database",
  },
  {
    icon: <RocketBlueIcon />,
    path: "projects",
    key: "front_sidebar_item_campaigns",
    translateKey: "ws_campaigns",
    defaultMessage: "Campaigns",
  },
  {
    icon: <PromoCodesIcon />,
    path: "coupon-groups",
    key: "front_sidebar_item_ws_coupons",
    translateKey: "ws_promo_codes",
    defaultMessage: "Promo Codes",
  },
  {
    icon: <MyTasksIcon />,
    path: "my-actions",
    key: "front_sidebar_item_my_tasks",
    translateKey: "ws_my_tasks",
    defaultMessage: "My tasks",
  },
];
