import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import "./Status.scss";

interface StatusProps {
  className?: string;
}

export function Status({
  children,
  className,
}: PropsWithChildren<StatusProps>) {
  return <span className={classNames("status", className)}>{children}</span>;
}
