import React from "react";
import classNames from "classnames";

import Tooltip from "src/app/components/Tooltip/Tooltip";
import { injectIntl } from "react-intl";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { EditAutomationButton } from "./EditAutomationButton";

function PublicationDetectionList(props) {
  const {
    publicationDetectionList,
    projectBasicData,
    handlePublication,
    automationListLoading,
  } = props;

  const tooltipContent = (
    <IDHFormattedMessage
      id="ws_tooltip_detect_publications_by_hashtags"
      defaultMessage="Automatically detect publications by hashtags and/or tags published by creators in your campaign."
    />
  );

  return !automationListLoading && publicationDetectionList.length > 0 ? (
    publicationDetectionList.map((automation) => (
      <div className="automations__list-element" key={automation.uuid}>
        <span className="automations__list-element-text">
          <span
            className={classNames("automations__list-element-status", {
              "automations__list-element-status--active": automation.enabled,
            })}
          />
          <IDHFormattedMessage
            id="ws_publications_detection"
            defaultMessage="Publications detection"
          />
          <Tooltip content={tooltipContent} />
        </span>
        <EditAutomationButton
          automation={automation}
          handlePublication={handlePublication}
          disabled={
            !projectBasicData?.permissions?.project?.includes(
              "manage_project_configuration",
            )
          }
        />
      </div>
    ))
  ) : (
    <div className="automations__list-element">
      <span className="automations__list-element-text">
        <span className="automations__list-element-status" />
        <IDHFormattedMessage
          id="ws_publications_detection"
          defaultMessage="Publications detection"
        />
        <Tooltip content={tooltipContent} />
      </span>
      <EditAutomationButton
        handlePublication={handlePublication}
        disabled={
          !projectBasicData?.permissions?.project?.includes(
            "manage_project_configuration",
          )
        }
      />
    </div>
  );
}

export default injectIntl(PublicationDetectionList);
