import React, { useState } from "react";
import "./CountrySelector.scss";

import { useIntl } from "react-intl";
import { SelectTags } from "src/app/components/SelectTags/SelectTags";
import CustomInput from "src/app/components/CustomInput/CustomInput";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import IDHFormattedMessage from "../../../../../components/IDHFormattedMessage/IDHFormattedMessage";
import { CustomSwitch } from "../../../../../components/CustomSwitch/CustomSwitch";

interface Props {
  data: any;
  selectedCountries: any;
  setSelectedCountries: any;
  providerEnabled: boolean;
  setProviderEnabled: any;
  allCountriesEnabled: boolean;
  setAllCountriesEnabled: any;
}

export const CountrySelector: React.FC<Props> = (props) => {
  const {
    data,
    selectedCountries,
    setSelectedCountries,
    providerEnabled,
    setProviderEnabled,
    allCountriesEnabled,
    setAllCountriesEnabled,
  } = props;

  const { countries } = data;

  const [countriesInputValue, setCountriesInputValue] = useState("");

  const intl = useIntl();

  const matchCountry = (label: string) => {
    return countries.find(
      (country: { label: string }) => country.label === label,
    );
  };

  const countrySet = (label: string) => {
    const newValue = matchCountry(label);

    if (!newValue) return;

    const newCountries = [...selectedCountries, newValue];
    setSelectedCountries(newCountries);

    if (!providerEnabled) {
      setProviderEnabled(true);
    }
  };

  const countryUnset = (label: string) => {
    const newCountries = selectedCountries.filter(
      (country: { label: string }) => country.label !== label,
    );
    setSelectedCountries(newCountries);

    if (!newCountries.length) {
      setProviderEnabled(false);
    }
  };

  const handleEnabledChange = () => {
    const newValue = !providerEnabled;

    if (!newValue) {
      setSelectedCountries([]);
    }

    setProviderEnabled(!providerEnabled);
  };

  return (
    <div className="country-selector">
      <div className="country-selector__checkbox-wrapper">
        <CustomCheckbox
          id={data.displayName}
          name={data.displayName}
          checked={providerEnabled}
          onChange={handleEnabledChange}
          disabled={data.fieldDisabled}
        />
        <label
          htmlFor={data.displayName}
          className="country-selector__provider"
        >
          {data.displayName}
        </label>

        <div className="country-selector__select-all-countries">
          <IDHFormattedMessage
            id="ws_all_countries"
            defaultMessage="All countries:"
          />
          <CustomSwitch
            checked={allCountriesEnabled}
            onChange={() => {
              setAllCountriesEnabled(!allCountriesEnabled);
            }}
          />
        </div>
      </div>

      {!allCountriesEnabled && (
        <>
          <CustomInput
            className="country-selector__input"
            placeholder={intl.formatMessage({
              id: "front_enter_country_name_placeholder",
              defaultMessage: "Enter country name",
            })}
            value={countriesInputValue}
            onChange={(e) => setCountriesInputValue(e.target.value)}
            data-cdt-input="countries"
          />

          <SelectTags
            feed={countries
              ?.filter((country: { label: string }) =>
                country.label
                  .toLocaleLowerCase()
                  .includes(countriesInputValue.toLowerCase()),
              )
              .map((c: { label: string }) => c.label)}
            values={selectedCountries.map((c: { label: string }) => c.label)}
            setValue={countrySet}
            unsetValue={countryUnset}
            clearValues={() => {
              setSelectedCountries([]);
              setProviderEnabled(false);
            }}
            translative={false}
            getFullObject={null}
          />
        </>
      )}
    </div>
  );
};
