import classNames from "classnames";
import React, { useEffect, useState, forwardRef } from "react";
import "./AnimatedDiv.scss";

interface Props {
  id?: string;
  className?: string;
  children?: React.ReactNode;
  outerHidden?: boolean;
  style?: any;
  ref?: React.ForwardedRef<HTMLDivElement>;
  onClick?: any;
}

const AnimatedDiv: React.FC<Props> = forwardRef((props, ref) => {
  const { id, className, children, outerHidden, onClick } = props;

  const [hidden, setHidden] = useState<boolean>(true);

  const showContent = () => {
    setTimeout(() => {
      setHidden(false);
    }, 15);
  };

  useEffect(() => {
    showContent();
  }, []);

  useEffect(() => {
    if (outerHidden) {
      setHidden(true);
    } else {
      showContent();
    }
  }, [outerHidden]);

  return (
    <div
      {...props}
      ref={ref}
      id={id}
      onClick={onClick}
      className={classNames(
        "animated-div",
        {
          "animated-div--hidden": hidden,
        },
        className,
      )}
    >
      {children}
    </div>
  );
});

export default AnimatedDiv;
