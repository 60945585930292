import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import "./ChoosePersonDropdown.scss";

import LazyLoad from "react-lazy-load";
import { useSelector } from "react-redux";

import DropdownPortal, {
  DropdownPortalMenu,
} from "src/app/components/DropdownPortal";

import { useScrollLock } from "src/app/methods/useScrollLock";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/redux/reducers";
import AvatarPlaceholder from "../../../images/avatar-purple.svg";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { IMember } from "../../project/ProjectTypes";
import { showToast } from "../../methods/showToast";
import IDHFormattedMessage from "../../components/IDHFormattedMessage/IDHFormattedMessage";
import { API_URLS } from "../../../utils/API_URLS";
import { tableDataType } from "../../components/Table/Table";
import {
  SkeletonCircle,
  SkeletonText,
} from "../../components/Skeleton/Skeleton";

interface MemberValue {
  email: string;
  name: string;
  id: string;
}

export interface MemberOption {
  label: JSX.Element;
  value: MemberValue;
}
interface Props {
  uuid?: string;
  dataType?: tableDataType;
  globalFieldId?: string;
  children?: React.ReactNode;
  value?: any;
  choosePerson: Dispatch<SetStateAction<any>>;
  placeholder?: string;
  toRight?: boolean;
  wsTeamUuids?: any;
  disabled?: boolean;
}

const ChoosePersonDropdown: React.FC<Props> = (props) => {
  const {
    uuid,
    dataType,
    globalFieldId,
    children,
    value,
    choosePerson,
    toRight,
    wsTeamUuids,
    disabled,
  } = props;

  const { membersList } = useSelector(
    (state: RootState) => state.projectReducer,
  );

  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );

  const { lockScroll, unlockScroll } = useScrollLock();

  const [memberOptions, setMemberOptions] = useState<MemberOption[]>([]);
  const [teamMemberUuids, setTeamMemberUuids] = useState<string[]>([]);
  const [assignee, setAssignee] = useState<MemberOption | null>(null);
  const [isVisible, setVisible] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const filterByTeamMember = () => {
    if (wsTeamUuids?.length > 0) {
      return membersList.filter(
        (member: IMember) =>
          teamMemberUuids.includes(member.id) && member?.status !== "removed",
      );
    }

    return membersList?.filter(
      (member: IMember) => member?.status !== "removed",
    );
  };

  useEffect(() => {
    if (membersList) {
      const options = filterByTeamMember().map((member: IMember) => ({
        label: (
          <div
            key={member.id}
            className="member-option"
            data-value={member.name}
          >
            <LazyLoad
              offset={300}
              width={24}
              height={24}
              className="member-option__photo-loader"
            >
              <img
                className="member-option__photo"
                src={member.avatarUrl || AvatarPlaceholder}
                alt="avatar"
              />
            </LazyLoad>
            <div>
              <span className="member-option__name">{member.name}</span>
            </div>
          </div>
        ),
        value: { email: member.email, name: member.name, id: member.id },
      }));

      setMemberOptions(options);
    }
  }, [membersList, teamMemberUuids]);

  useEffect(() => {
    const memberValue: MemberOption | undefined = memberOptions.find(
      (item: MemberOption) => item.value.id === value?.id,
    );

    if (memberValue) {
      setAssignee(memberValue);
    } else {
      setAssignee(null);
    }
  }, [memberOptions, value]);

  const filterOption = (option: MemberOption, rawInput: string) => {
    return (
      option.value.name.toLowerCase().includes(rawInput.toLowerCase()) ||
      option.value.email.toLowerCase().includes(rawInput.toLowerCase())
    );
  };

  const changeAssignee = (newValue: MemberOption) => {
    const newAssignee: IMember | undefined = membersList.find(
      (member: IMember) => member.id === newValue.value.id,
    );

    if (!newAssignee) return;

    choosePerson(newAssignee);
  };

  const ddRef = useRef(null);

  const forceClose = () => {
    // @ts-ignore
    ddRef.current?.triggerRef?.current?.click();
  };

  const checkMemberFilterByTeam = () => {
    if (wsTeamUuids?.length > 0) {
      setIsLoader(true);

      let url = null;

      switch (dataType) {
        case tableDataType.Task:
          url = globalFieldId
            ? API_URLS.globalTaskMetaFieldTeamMembers
            : API_URLS.taskMetaFieldTeamMembers;
          break;
        case tableDataType.ProjectDetails:
        case tableDataType.Project:
          url = globalFieldId
            ? API_URLS.globalProjectMetaFieldTeamMembers
            : API_URLS.projectMetaFieldTeamMembers;
          break;
        case tableDataType.GlobalTask:
          url = API_URLS.globalTaskMetaFieldTeamMembers;
          break;
        case tableDataType.GlobalProject:
        case tableDataType.GlobalMetaFieldProjectWizardFields:
          url = API_URLS.globalProjectMetaFieldTeamMembers;
          break;
        case tableDataType.Dictionary:
          url = API_URLS.dictionaryMetaFieldTeamMembers;
          break;

        default:
          setIsLoader(false);
          return;
      }

      url = globalFieldId
        ? url.replace(":wsMetaFieldUuid:", globalFieldId ?? "")
        : url.replace(":wsMetaFieldUuid:", uuid ?? "");

      url = url.replace(":wsWorkspaceUuid:", activeWorkspaceUuid);

      axios
        .get(url)
        .then((response: AxiosResponse) => {
          const {
            data: { content },
          } = response;
          setTeamMemberUuids(content);
        })
        .catch(() => {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id="ws_something_went_wrong"
              defaultMessage="Something went wrong"
            />,
          );
        })
        .finally(() => setIsLoader(false));
    }
  };

  useEffect(() => {
    if (!isVisible) {
      unlockScroll();
    }
  }, [isVisible]);

  const Menu = (
    <DropdownPortalMenu
      className="choose-person-dropdown-menu"
      onMouseLeave={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        e.stopPropagation()
      }
    >
      {isLoader ? (
        <div className="choose-person-dropdown__skeleton">
          <div className="choose-person-dropdown__skeleton-row">
            <SkeletonCircle size={24} marginRight={12} />
            <SkeletonText height={12} width={300} />
          </div>

          <div className="choose-person-dropdown__skeleton-row">
            <SkeletonCircle size={24} marginRight={12} />
            <SkeletonText height={12} width={300} />
          </div>

          <div className="choose-person-dropdown__skeleton-row">
            <SkeletonCircle size={24} marginRight={12} />
            <SkeletonText height={12} width={300} />
          </div>
        </div>
      ) : (
        <CustomSelect
          value={assignee}
          onChange={(newValue: MemberOption) => {
            setAssignee(newValue);
            changeAssignee(newValue);
            forceClose();
          }}
          isSearchable
          options={memberOptions}
          filterOption={filterOption}
          menuIsOpen
          forceFocus={isVisible}
          onMenuOpen={() => null}
          onMenuClose={() => unlockScroll()}
          openOnFocus={false}
        />
      )}
    </DropdownPortalMenu>
  );

  return (
    <DropdownPortal
      className="choose-person-dropdown"
      toRight={toRight}
      overlay={Menu}
      onVisibleChange={(v: boolean) => {
        checkMemberFilterByTeam();
        setVisible(v);
        if (v) {
          lockScroll();
        }
      }}
      ddRef={ddRef}
      disabled={disabled}
    >
      <span>{children}</span>
    </DropdownPortal>
  );
};

export default ChoosePersonDropdown;
