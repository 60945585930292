import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import NoResults from "../../../components/NoResults/NoResults";
import PendingUserLoading from "./components/PendingUserLoading";

function PendingUsersHandler({
  getListData,
  pendingUsers,
  influencerSocialUsername,
}) {
  switch (pendingUsers) {
    case true:
      return (
        <PendingUserLoading
          getListData={getListData}
          pendingUsers={pendingUsers}
          influencerSocialUsername={influencerSocialUsername}
        />
      );
    case false:
      return (
        <NoResults
          withUnderline
          customMessage={
            <IDHFormattedMessage
              id="front_creator_does_not_exist"
              defaultMessage="Creator with the given nickname does not exist on the selected platform."
            />
          }
        />
      );
    default:
      return <NoResults withUnderline />;
  }
}

export default PendingUsersHandler;
