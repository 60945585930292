import React, { ReactNode } from "react";
import "./PageHeader.scss";

export interface PageHeaderProps {
  title: ReactNode;
  description?: ReactNode;
}

function PageHeader(props: PageHeaderProps) {
  const { title, description } = props;

  return (
    <div>
      {title && <h2 className="page-header">{title}</h2>}
      {description && <h6 className="page-description">{description}</h6>}
    </div>
  );
}

export default PageHeader;
