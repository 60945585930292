import React, { useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import axios from "axios";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import { Route, Switch } from "react-router";

import { ReactComponent as InstagramIcon } from "src/images/social/instagram-color.svg";
import { splitNumbers } from "src/utils/methods";
import { API_URLS } from "src/utils/API_URLS";
import { RootState } from "src/redux/reducers";
import AnimatedDiv from "../components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { translateMessage } from "../methods/translateMessage";
import Tabs from "../components/Tabs/Tabs";
import Tab from "../components/Tabs/Tab";
import { Button } from "../components/Button/Button";
import { showToast } from "../methods/showToast";
import Loader from "../components/Loader/Loader";
import { PostList } from "./components/PostList/PostList";
import { ProfileDataPane } from "../pages/CreatorDiscoveryTool/legacy/app/profile/ProfileDataPane/ProfileDataPane";
import NoResultsMessage from "../components/NoResultsMessage/NoResultsMessage";

import "./HashtagLiveSearch.scss";

export interface LiveSearchPost {
  caption: string;
  commentsCount: number;
  influencerImgUrl: string;
  isVideo: boolean;
  likesCount: number;
  postImgUrl: string;
  postUrl: string;
  userId: null;
  username: string;
}

enum SearchTab {
  MostPopularPosts = "most-popular-posts",
  SearchResults = "search-results",
}

export function HashtagLiveSearch() {
  const [activeTab, setActiveTab] = useState(SearchTab.MostPopularPosts);
  const [value, setValue] = useState("");
  const [currentSearch, setCurrentSearch] = useState("");

  // data
  const [after, setAfter] = useState<undefined | string>(undefined);
  const [recentPosts, setRecentPosts] = useState<LiveSearchPost[]>([]);
  const [topPosts, setTopPosts] = useState<LiveSearchPost[]>([]);
  const [totalPostsCount, setTotalPostsCount] = useState(0);
  const [loadCounter, setLoadCounter] = useState(0);
  const [fullNewListLoading, setNewListLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFinishedRequest, setHasFinishedRequest] = useState(false);

  const scrollRef = useRef<HTMLDivElement>(null);
  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const canLoadMore = loadCounter <= 50 && Boolean(after);
  const buttonDisabled =
    currentSearch === value || isLoading || fullNewListLoading || !value;

  const intl = useIntl();

  const getPosts = (after?: string) => {
    if (isLoading || fullNewListLoading || !value) return;

    setHasFinishedRequest(false);

    if (after) {
      setIsLoading(true);
      setLoadCounter((v) => v + 1);
    } else {
      setNewListLoading(true);
      setLoadCounter(1);
    }

    axios
      .get(API_URLS.getLiveHashtagsFeed, {
        params: {
          hashtag: value,
          ...(after && { after }),
        },
      })
      .then((response) => {
        setCurrentSearch(value);
        const {
          data: { content },
        } = response;
        if (after) {
          setRecentPosts((recentPosts) => [
            ...recentPosts,
            ...content.recentPosts,
          ]);
        } else {
          setAfter(content.after);
          setRecentPosts(content.recentPosts);
          setTopPosts(content.topPosts);
          setTotalPostsCount(content.totalPostsCount);
        }
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_something_went_wrong"
            defaultMessage="Something went wrong"
          />,
        );
      })
      .finally(() => {
        setHasFinishedRequest(true);
        setIsLoading(false);
        setNewListLoading(false);
      });
  };

  const loadMorePosts = () => {
    if (isLoading) return;
    getPosts(after);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[^ !@#$%^&*(),.?":{}|<>\s]*$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setValue(e.target.value);
    }
  };

  function LoaderComponent() {
    return (
      <div className="hashtag-live-search__loader hashtag-live-search__loader--empty-page">
        <Loader />
      </div>
    );
  }

  const renderResults = () => {
    switch (activeTab) {
      case SearchTab.MostPopularPosts:
        if (!topPosts?.length && hasFinishedRequest && !isLoading) {
          return (
            <div className="no-results-message__wrapper">
              <NoResultsMessage />
            </div>
          );
        }

        return <PostList posts={topPosts} />;

      case SearchTab.SearchResults:
        if (!recentPosts?.length && hasFinishedRequest && !isLoading) {
          return (
            <div className="no-results-message__wrapper">
              <NoResultsMessage />
            </div>
          );
        }

        return (
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMorePosts}
            hasMore={canLoadMore}
            loader={
              <div className="hashtag-live-search__loader" key={0}>
                <Loader />
              </div>
            }
            useWindow={false}
            getScrollParent={() => scrollRef.current}
          >
            <PostList posts={recentPosts} />
          </InfiniteScroll>
        );
    }
  };

  return (
    <AnimatedDiv className="hashtag-live-search" ref={scrollRef}>
      <div>
        <h2 className="hashtag-live-search__title">
          <IDHFormattedMessage
            id="ws_hashtag_live_search"
            defaultMessage="Hashtag Live Search"
          />
        </h2>
        <h6 className="hashtag-live-search__description">
          <IDHFormattedMessage
            id="ws_hashtag_live_search_description"
            defaultMessage="Here you can search for the most popular posts using the entered hashtag."
          />
        </h6>
      </div>

      <div className="hashtag-live-search__input">
        <InstagramIcon className="hashtag-live-search__input-social" />
        #
        <input
          value={value}
          onChange={handleChange}
          name="hashtag"
          onKeyPress={(event) => {
            if (event.key === "Enter" && !isLoading && !fullNewListLoading) {
              getPosts();
            }
          }}
          type="text"
          placeholder={translateMessage({
            intl,
            id: "ws_enter_hashtag",
            defaultMessage: "Enter hashtag",
          })}
          disabled={isLoading}
        />
        {/* <CloseIcon
          onClick={() => setValue("")}
          className={classNames("hashtag-live-search__input-clear", {
            "hashtag-live-search__input-clear--hidden": !value.length,
          })}
        /> */}
      </div>

      <div
        className={classNames("hashtag-live-search__button-wrapper", {
          "hashtag-live-search__button-wrapper--hidden": buttonDisabled,
        })}
      >
        <Button
          size="medium"
          variant="blue"
          onClick={() => getPosts()}
          disabled={buttonDisabled}
        >
          <IDHFormattedMessage id="ws_search" defaultMessage="Search" />
        </Button>
      </div>

      {fullNewListLoading && <LoaderComponent />}

      {recentPosts.length > 0 && (
        <Tabs>
          <Tab
            tabText={
              <IDHFormattedMessage
                id="ws_most_popular_posts"
                defaultMessage="Most Popular Posts"
              />
            }
            active={activeTab === SearchTab.MostPopularPosts}
            onClick={() => setActiveTab(SearchTab.MostPopularPosts)}
          />

          <Tab
            tabText={
              <IDHFormattedMessage
                id="ws_search_results"
                defaultMessage="Search Results"
              />
            }
            active={activeTab === SearchTab.SearchResults}
            onClick={() => setActiveTab(SearchTab.SearchResults)}
            count={splitNumbers(totalPostsCount)}
          />
        </Tabs>
      )}

      {renderResults()}

      <Switch>
        <Route
          path={`/workspace/${activeWorkspaceUuid}/hashtag-live-search/profile/:socialProvider/:socialUsername`}
          render={(props) => (
            <ProfileDataPane
              {...props}
              activeTab=""
              listId=""
              refreshAudience={() => null}
              addToAudience={() => null}
              setSelectedInfluencers={() => null}
              influencersInList={[]}
              influencersInListUuids={[]}
            />
          )}
        />
      </Switch>
    </AnimatedDiv>
  );
}
