import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import { setCreatorsMetadata } from "src/redux";

export const ToolbarListCreatorMetaFields = () => {
  const {
    reportReducer: { creatorsMetadata },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const toggleFieldVisibility = (newValue, uuid) => {
    const newCreatorsMetadata = creatorsMetadata.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          reportVisible: newValue,
        };
      }
      return item;
    });

    dispatch(setCreatorsMetadata(newCreatorsMetadata));
  };

  return creatorsMetadata.map((item) => {
    return (
      <div className="toolbar-list__list-item">
        <CustomCheckbox
          id={item.uuid}
          checked={item.reportVisible}
          onChange={() => toggleFieldVisibility(!item.reportVisible, item.uuid)}
        />
        {item.name}
      </div>
    );
  });
};
