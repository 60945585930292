import React, { useState } from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as PostCheckLightGrey } from "src/images/post-check-awaiting.svg";
import { ReactComponent as PostCheckTick } from "src/images/post-check-tick.svg";
import { ReactComponent as PostCheckWarning } from "src/images/post-check-warning.svg";
import { ReactComponent as PostCheckAlert } from "src/images/post-check-alert.svg";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import classNames from "classnames";
import CompetitorsPostDetectedModal from "../CompetitorsPostDetectedModal/CompetitorsPostDetectedModal";

function BrandSafetyItem(props) {
  const { status, keywords, competitorsPostDetected, username, count } = props;
  const [
    showCompetitorsPostDetectedModal,
    setShowCompetitorsPostDetectedModal,
  ] = useState(false);

  const renderWarningText = (warningKey, warningDM) => {
    return (
      <>
        <div
          className={classNames(
            "brand-safety-item__tooltip-header",
            "brand-safety-item__tooltip-header--yellow",
          )}
        >
          <IDHFormattedMessage id="front_warning" defaultMessage="Warning" />
        </div>
        <IDHFormattedMessage id={warningKey} defaultMessage={warningDM} />
      </>
    );
  };

  const renderTooltipContent = () => {
    if (competitorsPostDetected) {
      return (
        <>
          <div
            className={classNames(
              "brand-safety-item__tooltip-header",
              "brand-safety-item__tooltip-header--red",
            )}
          >
            <IDHFormattedMessage
              id="front_competitors_post_detected"
              defaultMessage="Competitor’s Post detected"
            />
          </div>
          <IDHFormattedMessage
            id="front_during_recent_account_scanning_there_were_the_post_detected"
            defaultMessage="During recent account scanning there were post detected that can be related to competitve Brand."
          />
          <span
            className="brand-safety-item__tooltip-button"
            onClick={() => setShowCompetitorsPostDetectedModal((v) => !v)}
          >
            <IDHFormattedMessage
              id="front_show_posts"
              defaultMessage="Show posts"
            />
          </span>
        </>
      );
    }

    switch (status) {
      case "Pending":
        return (
          <>
            <div className="brand-safety-item__tooltip-header">
              <IDHFormattedMessage
                id="front_competitors_please_wait"
                defaultMessage="Please wait..."
              />
            </div>
            <IDHFormattedMessage
              id="front_competitor_post_checking"
              defaultMessage="Competitor post checking in progress."
            />
          </>
        );
      case "FailedNotFound":
        return renderWarningText(
          "front_bs_failed_not_found",
          "The account has not been found. Competitor Post Check not possible.",
        );
      case "FailedRestricted":
        return renderWarningText(
          "front_bs_failed_restricted",
          "The account is restricted. Competitor Post Check not possible.",
        );
      case "FailedPrivate":
        return renderWarningText(
          "front_bs_failed_private",
          "The account is private. Competitor Post Check not possible.",
        );
      case "FailedTooSmall":
        return renderWarningText(
          "front_bs_failed_too_small",
          "The account is too small. Competitor Post Check not possible.",
        );
      case "FailedDataError":
        return renderWarningText(
          "front_bs_failed_data_error",
          "The data for the account is unavailable. Competitor Post Check not possible.",
        );
      default:
        return (
          <>
            <div className="brand-safety-item__tooltip-header">
              <IDHFormattedMessage
                id="front_no_competitors_post_detected"
                defaultMessage="No Competitor's post detected"
              />
            </div>

            <IDHFormattedMessage
              id="front_this_account_has_been_positively_verified_in_terms"
              defaultMessage="This account has been positively verified in terms of the presence of posts related to competitive Brands."
            />
          </>
        );
    }
  };

  const renderPostCheckIcon = () => {
    if (competitorsPostDetected) {
      return (
        <div className="brand-safety-item__counter-wrapper">
          <PostCheckAlert />
          <div className="brand-safety-item__count">{count}</div>
        </div>
      );
    }

    switch (status) {
      case "Pending":
        return <PostCheckLightGrey />;
      case "FailedNotFound":
      case "FailedRestricted":
      case "FailedPrivate":
      case "FailedTooSmall":
      case "FailedDataError":
        return <PostCheckWarning />;
      default:
        return <PostCheckTick />;
    }
  };

  return (
    <MaterialTooltip
      contentClassName="brand-safety__tooltip"
      content={renderTooltipContent()}
      clickable
    >
      {renderPostCheckIcon()}
      {showCompetitorsPostDetectedModal && (
        <CompetitorsPostDetectedModal
          onClose={() => setShowCompetitorsPostDetectedModal((prev) => !prev)}
          keywords={keywords}
          username={username}
        />
      )}
    </MaterialTooltip>
  );
}

export default BrandSafetyItem;
