// odpowiednie proporcje osiągniemy poprzez manipulację szerokością strony lub gapem

export const formatOptions = [
  {
    label: "Panoramic (16:9)",
    value: {
      cols: 6,
      rows: 7,
      rowHeight: 50,
      pageHeight: 490,
      pageWidth: 871,
    },
  },
  {
    label: "A4 (Portrait)",
    value: {
      cols: 6,
      rows: 18,
      rowHeight: 50,
      pageHeight: 1260,
      pageWidth: 891,
    },
  },
];
