import React from "react";
import "./AuthorCell.scss";

export function AuthorCell({ name, avatarUrl }) {
  return (
    <div className="influencers-list__cell influencers-list__cell--author">
      <img className="influencers-list__cell--author__avatar" src={avatarUrl} />
      <div className="influencers-list__cell--author__name">{name}</div>
    </div>
  );
}
