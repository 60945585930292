import React from "react";
import "./FieldSelect.scss";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as TickIcon } from "../../../images/tick.svg";
import { setActiveSortingColumn } from "../../../redux";

function FieldSelect({ field, capitalize }) {
  const dispatch = useDispatch();
  const { activeSortingColumn } = useSelector(
    (state) => state.taskFiltersReducer,
  );

  const selectField = () => {
    if (activeSortingColumn !== field.id) {
      dispatch(setActiveSortingColumn(field.id));
    } else {
      dispatch(setActiveSortingColumn(""));
    }
  };

  return (
    <div className="field-select" onClick={selectField}>
      <span
        className="field-select__data-name"
        style={{ textTransform: capitalize && "capitalize" }}
      >
        {field.title}
      </span>
      {activeSortingColumn === field.id && <TickIcon />}
    </div>
  );
}

export { FieldSelect };
