import React, { forwardRef, useEffect, useRef } from "react";
import "./ShowcaseGridBox.scss";

import { useDispatch, useSelector } from "react-redux";
import { getShowcaseSettingByKey } from "src/app/CreatorShowcase/utils";
import {
  setNewGridElements,
  setNewHiddenGridElements,
} from "../../../../../../redux";
import { GridBox } from "../../../../../generator-data/components/GridBox/GridBox";

export const ShowcaseGridBox = forwardRef((props, ref) => {
  const { key, id, children, ...rest } = props;

  const creatorId = getShowcaseSettingByKey("creatorId");

  const contentRef = useRef();

  useEffect(() => {
    if (!ref.current) return;

    contentRef.current = ref?.current?.querySelector(
      ".react-grid-item__content",
    );
  }, [ref]);

  const dispatch = useDispatch();

  const gridElements = useSelector(
    (state) => state.showcaseReducer.gridElements,
  );

  const hiddenGridElements = useSelector(
    (state) => state.showcaseReducer.hiddenGridElements,
  );

  const generating = useSelector((state) => state.showcaseReducer.generating);

  useEffect(() => {
    const savedBoxes = JSON.parse(
      window.localStorage.getItem("showcaseSavedBoxes"),
    );

    if (generating || !savedBoxes?.length) return;

    const creatorBoxes = savedBoxes?.find(
      (item) => item.creatorId === creatorId,
    );

    if (!creatorBoxes) return;

    const boxContent = creatorBoxes?.boxes?.find((item) => item.id === id);

    if (boxContent) {
      contentRef.current.outerHTML = boxContent.html;
    }
  }, [generating]);

  const saveBoxContent = () => {
    const savedBoxes = JSON.parse(
      window.localStorage.getItem("showcaseSavedBoxes"),
    );

    let newSavedBoxes = [];
    const newBoxContent = { id, html: contentRef.current.outerHTML };

    if (savedBoxes?.length) {
      const creatorBoxes = savedBoxes.find(
        (item) => item.creatorId === creatorId,
      );

      if (creatorBoxes) {
        const boxContent = creatorBoxes?.boxes?.find((item) => item.id === id);

        if (!boxContent) {
          newSavedBoxes = savedBoxes.map((item) => {
            if (item.creatorId === creatorId) {
              return {
                ...item,
                boxes: [...item.boxes, newBoxContent],
              };
            }

            return item;
          });
        } else {
          newSavedBoxes = savedBoxes.map((item) => {
            if (item.creatorId === creatorId) {
              return {
                ...item,
                boxes: item.boxes.map((box) => {
                  if (box.id === id) {
                    return newBoxContent;
                  }
                  return box;
                }),
              };
            }

            return item;
          });
        }
      } else {
        newSavedBoxes = [...savedBoxes, { creatorId, boxes: [newBoxContent] }];
      }
    } else {
      newSavedBoxes = [{ creatorId, boxes: [newBoxContent] }];
    }

    window.localStorage.setItem(
      "showcaseSavedBoxes",
      JSON.stringify(newSavedBoxes),
    );
  };

  const removeFromLayout = () => {
    const newElement = gridElements.find((item) => item.label === id);
    const newGridElements = gridElements.filter((item) => item.label !== id);

    dispatch(setNewGridElements(newGridElements));
    dispatch(setNewHiddenGridElements([...hiddenGridElements, newElement]));
  };

  return (
    <GridBox
      key={key}
      ref={ref}
      {...rest}
      saveBoxContent={saveBoxContent}
      removeFromLayout={removeFromLayout}
    >
      {children}
    </GridBox>
  );
});
