import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ChangeSettingsSectionParam } from "src/app/SettingsModal/methods";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalColumn } from "src/app/components/Modal/Modal";
import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import { RootState } from "src/redux/reducers";
import { Button } from "src/app/components/Button/Button";
import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import { wsAxiosPost } from "src/helpers/wsAxios";
import { API_URLS } from "src/utils/API_URLS";
import { uuidv7 } from "uuidv7";
import Loader from "src/app/components/Loader/Loader";
import { WsApiConnection } from "src/types";
import { AppConfig } from "src/config/AppConfig";
import { getWorkspaceExtensionList } from "src/redux";
import ExtensionView from "../../ExtensionView";
import CopyValueInput from "../CopyValueInput/CopyValueInput";

interface InstagramAccountVerificationProps {
  data: WsApiConnection;
  setExtensionsSection: (section: ChangeSettingsSectionParam) => void;
  onClose: () => void;
  contextData: any;
}

export default function InstagramAccountVerification({
  data,
  setExtensionsSection,
  onClose,
  contextData,
}: InstagramAccountVerificationProps) {
  const [brandName, setBrandName] = useState("");
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    mainReducer: { activeWorkspaceUuid, workspacesList },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const updateWorkspaceExtensionList = () => {
    if (typeof contextData?.uuid === "string") {
      dispatch(getWorkspaceExtensionList(contextData.uuid));
    }
  };

  const createInstagramAccountVerification = async () => {
    await wsAxiosPost(
      API_URLS.createInstagramAccountVerification,
      {
        wsExtensionUuid: uuidv7(),
        wsWorkspaceUuid: activeWorkspaceUuid,
        token,
        brandName,
      },
      undefined,
      () => setIsLoading(true),
      () => {},
      () => setIsLoading(false),
    );
    updateWorkspaceExtensionList();
  };

  const updateInstagramAccountVerification = async () => {
    await wsAxiosPost(
      API_URLS.updateInstagramAccountVerification,
      {
        wsExtensionUuid: data.uuid,
        wsWorkspaceUuid: activeWorkspaceUuid,
        token,
        brandName,
      },
      undefined,
      () => setIsLoading(true),
      () => {},
      () => setIsLoading(false),
    );
    updateWorkspaceExtensionList();
  };

  useEffect(() => {
    if (data.uuid) {
      setBrandName(data.settings.brandName);
      setToken(data.settings.token);
    } else {
      const foundWorkspaceData = workspacesList?.find(
        (workspace) => workspace.uuid === activeWorkspaceUuid,
      );

      if (foundWorkspaceData) {
        setBrandName(foundWorkspaceData.name);
      }
      setToken(activeWorkspaceUuid);
    }
  }, [workspacesList, data, activeWorkspaceUuid]);

  return (
    <ExtensionView
      setExtensionsSection={setExtensionsSection}
      title={
        <IDHFormattedMessage
          id="ws_instagram_account_verification"
          defaultMessage="Instagram Account Verification"
        />
      }
    >
      <div className="extensions__content-wrapper">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="extensions__col">
              <div className="extensions__row">
                <ModalColumn>
                  <CustomInputLabel htmlFor="brand-name">
                    <IDHFormattedMessage
                      id="ws_brand_name"
                      defaultMessage="Brand name"
                    />
                  </CustomInputLabel>
                  <CustomInput
                    id="brand-name"
                    type="text"
                    value={brandName}
                    onChange={(e) => {
                      setBrandName(e.target.value);
                    }}
                  />
                </ModalColumn>

                <ModalColumn>
                  <CustomInputLabel htmlFor="token">
                    <IDHFormattedMessage id="ws_token" defaultMessage="Token" />
                  </CustomInputLabel>
                  <CustomInput
                    id="token"
                    type="text"
                    value={token}
                    onChange={(e) => {
                      setToken(e.target.value);
                    }}
                  />
                </ModalColumn>
              </div>
              {data.uuid && (
                <ModalColumn>
                  <CopyValueInput
                    displayedValue={`${AppConfig.getApiHost()}/fb-int/social-onboarding/workspace-onboard?data=${data.settings.encodedData}`}
                    copiedValue={`${AppConfig.getApiHost()}/fb-int/social-onboarding/workspace-onboard?data=${data.settings.encodedData}`}
                  />
                </ModalColumn>
              )}
            </div>
            <SettingsButtons onClose={onClose}>
              {data.uuid ? (
                <Button
                  size="large"
                  variant="blue"
                  onClick={updateInstagramAccountVerification}
                  disabled={isLoading}
                >
                  <IDHFormattedMessage id="ws_update" defaultMessage="Update" />
                </Button>
              ) : (
                <Button
                  size="large"
                  variant="blue"
                  onClick={createInstagramAccountVerification}
                  disabled={isLoading}
                >
                  <IDHFormattedMessage id="ws_create" defaultMessage="Create" />
                </Button>
              )}
            </SettingsButtons>
          </>
        )}
      </div>
    </ExtensionView>
  );
}
