import React from "react";

import { ReactComponent as ActionIcon } from "../../../../images/action.svg";
import { ReactComponent as ContentIcon } from "../../../../images/picture.svg";
import { ReactComponent as TreeIcon } from "../../../../images/tree.svg";
import { ReactComponent as TwigIcon } from "../../../../images/twig.svg";

export function TaskBoxBottom(props: any) {
  const { data, dueDate } = props;

  return (
    <div className="task-box__bottom">
      <div className="task-box__bottom-icons">
        {data.hasSubtasks && <TreeIcon />}
        {data.hasRelatedActionTasks && <ActionIcon />}
        {data.hasRelatedContentTasks && <ContentIcon />}
        {data.hasRelatedPublicationTasks && <TwigIcon />}
      </div>
      <div className="task-box__main-additional-data-wrapper">
        <span className="task-box__main-due-date">{dueDate}</span>
      </div>
    </div>
  );
}
