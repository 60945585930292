import React from "react";

import CreatorAwaitingIcon from "src/images/post-check-awaiting.svg";

interface AwaitingIconProps {
  alt: string;
  addDataImgSrc: boolean | undefined;
}

export default function AwaitingIcon({
  alt,
  addDataImgSrc,
}: AwaitingIconProps) {
  return (
    <span className="social-profile-icons__icon social-profile-icons__icon--awaiting">
      <img
        src={!addDataImgSrc ? CreatorAwaitingIcon : undefined}
        data-img-src={addDataImgSrc ? CreatorAwaitingIcon : undefined}
        alt={alt}
      />
    </span>
  );
}
