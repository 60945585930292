import {
  CLEAR_ACTIVITY_FILTERS,
  GET_ACTIVITY_LIST_SUCCESS,
  SET_ACTIVITY_FILTERS,
  SET_ACTIVITY_LIST,
  SET_ACTIVITY_LOADING,
  SET_ACTIVITY_OFFSET,
} from "./activityTypes";
import { ACTIVITY_LOGS_LIMIT } from "../../utils/variables";

export const initialActivityFilters = {
  campaigns: [],
  members: [],
  dateRange: {
    dateFrom: null,
    dateTo: null,
  },
};

export const initialActivityList = [
  "item-0",
  "item-1",
  "item-2",
  "item-3",
  "item-4",
  "item-5",
  "item-6",
  "item-7",
  "item-8",
  "item-9",
  "item-10",
];

const initialState = {
  activityList: initialActivityList,
  loading: true,
  filters: initialActivityFilters,
  offset: 0,
  limit: ACTIVITY_LOGS_LIMIT,
  oldestActivityDate: null,
};

function activityReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        activityList: action.payload.data.content.activityLogs,
        lastItem: action.payload.data.content.lastItem,
        loading: false,
      };

    case SET_ACTIVITY_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
      };

    case CLEAR_ACTIVITY_FILTERS:
      return { ...state, filters: initialState.filters };

    case SET_ACTIVITY_LIST:
      return {
        ...state,
        activityList: action.payload.activityList,
        loading: false,
      };

    case SET_ACTIVITY_OFFSET:
      return {
        ...state,
        offset: action.payload.offset,
      };

    case SET_ACTIVITY_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    default:
      return state;
  }
}

export default activityReducer;
