import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import IdhLogo from "../../../images/idh-logo-color.svg";
import AvatarIcon from "../../../images/trial/avatar.png";
import OuterLink from "../../components/OuterLink/OuterLink";

function TrialRegistrationSuccess({ setShowForm, bdMemberData }) {
  return (
    <div className="trial-view-content__registration-success">
      <img src={IdhLogo} className="trial-view-content-left__logo" />
      <div className="trial-view-content__registration-success-header">
        <IDHFormattedMessage
          id="ws_trial_thanks_for_reaching_out"
          defaultMessage="Thanks for reaching out."
        />
      </div>
      <div className="trial-view-content__registration-success-description">
        <IDHFormattedMessage
          id="ws_trial_thanks_for_reaching_out_description"
          defaultMessage="Registration completed successfully.<br>Schedule a meeting where we will guide you through our platform. You will then receive a 7-day trial period."
        />
      </div>

      {bdMemberData ? (
        <>
          <div className="trial-view-content__registration-success-author">
            <img
              src={bdMemberData.avatarUrl}
              className="trial-view-content__registration-success-author-logo"
            />
            <div className="trial-view-content__registration-success-author-text">
              {bdMemberData.name}
              <div className="trial-view-content__registration-success-author-text-description">
                {bdMemberData.position}
              </div>
              <div className="trial-view-content__registration-success-author-row">
                <OuterLink
                  className="trial-view-content__registration-success-author-mailto"
                  href={`mailto:${bdMemberData?.email}`}
                  children="ws_trial_how_it_works_author_mailto"
                >
                  {bdMemberData?.email}
                </OuterLink>
              </div>
            </div>
          </div>
          <OuterLink href={bdMemberData.calendlyInvitationUrl}>
            <div className="trial-view-content__registration-success-btn">
              <IDHFormattedMessage
                id="ws_trial_schedule_a_meeting"
                defaultMessage="Schedule a meeting"
              />
            </div>
          </OuterLink>
        </>
      ) : (
        <>
          <div className="trial-view-content__registration-success-author">
            <img
              src={AvatarIcon}
              className="trial-view-content__registration-success-author-logo"
            />
            <div className="trial-view-content__registration-success-author-text">
              <IDHFormattedMessage
                id="ws_trial_how_it_works_author"
                defaultMessage="Tomasz Zembrzycki"
              />
              <div className="trial-view-content__registration-success-author-text-description">
                <IDHFormattedMessage
                  id="ws_trial_how_it_works_author_description"
                  defaultMessage="Head of SaaS"
                />
              </div>
              <div className="trial-view-content__registration-success-author-row">
                <OuterLink
                  className="trial-view-content__registration-success-author-mailto"
                  href="mailto:tomasz.zembrzycki@indahash.com"
                  children="ws_trial_how_it_works_author_mailto"
                >
                  <IDHFormattedMessage
                    id="ws_trial_mail_to"
                    defaultMessage="tomasz.zembrzycki@indahash.com"
                  />
                </OuterLink>
              </div>
            </div>
          </div>
          <OuterLink href="https://docs.indahash.com/doc/?n=terms-conditions-for-self-serve">
            <div className="trial-view-content__registration-success-btn">
              <IDHFormattedMessage
                id="ws_trial_schedule_a_meeting"
                defaultMessage="Schedule a meeting"
              />
            </div>
          </OuterLink>
        </>
      )}
    </div>
  );
}

export { TrialRegistrationSuccess };
