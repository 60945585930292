import React, { useEffect, useState } from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as VictoryHand } from "../../../images/victory-hand.svg";
import { AppConfig } from "../../../config/AppConfig";

const useMediaQuery = (query) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);
  }, [mediaMatch]);

  return matches;
};

function LeftSide() {
  const loginPageLogo = AppConfig.getAssetForHostname("login_page/login-logo.svg");
  let loginPageImage = AppConfig.getAssetForHostname("login_page/login-group-small.png");
  const heightOver660 = useMediaQuery("(min-height: 660px)");
  const heightOver840 = useMediaQuery("(min-height: 840px)");

  if (heightOver660) {
    loginPageImage = AppConfig.getAssetForHostname("login_page/login-group-medium.png");
  }

  if (heightOver840) {
    loginPageImage = AppConfig.getAssetForHostname("login_page/login-group.png");
  }

  return (
    <div className="login-page-left">
      <div className="login-page-left__group">
        <div>
          <img className="login-page-left__logo" src={loginPageLogo} alt="Logo" />
          <div className="login-page-left__header">
            <div className="login-page-left__header-top">
              <IDHFormattedMessage
                id="ws_achieve_your_goals_through"
                defaultMessage="Achieve your goals through"
              />
            </div>
            <div className="login-page-left__header-bottom">
              <IDHFormattedMessage
                id="ws_a_community_of_creators"
                defaultMessage="a community of Creators"
              />
              <VictoryHand className="login-page-left__victory-hand" />
            </div>
          </div>
        </div>

        <div className="login-page-left__group-image">
          <img
            src={loginPageImage}
            style={{ width: "100%", height: "auto" }}
            alt=""
          />
        </div>
      </div>

      <div className="login-page-left__copyrights">
        <IDHFormattedMessage
          id="ws_idh_media_limited_all_rights_reserved"
          defaultMessage="IDH Media Limited. All rights reserved."
        />
      </div>
    </div>
  );
}

export { LeftSide };
