import React from "react";
import "./SnackBar.scss";

import { Button } from "../Button/Button";
import { ReactComponent as CloseIcon } from "../../../images/close.svg";

interface SnackBarProps {
  text: React.ReactNode;
  hideSnackBar: () => void;
  buttonText: React.ReactNode;
  buttonOnClick: () => void;
}

function SnackBar({
  text,
  hideSnackBar,
  buttonText,
  buttonOnClick,
}: SnackBarProps) {
  return (
    <div className="snack-bar">
      <div className="snack-bar__text">{text}</div>
      <Button
        variant="transparent"
        size="small"
        onClick={buttonOnClick}
        className="snack-bar__cta-btn"
      >
        {buttonText}
      </Button>
      <CloseIcon className="snack-bar__close-icon" onClick={hideSnackBar} />
    </div>
  );
}

export default SnackBar;
