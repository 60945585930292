import React, { ReactElement, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import PublicationPlaceholder from "src/images/publication-placeholder-grey-sm.svg";
import classNames from "classnames";
import { TooltipedEllipsis } from "src/app/components/TooltipedEllipsis/TooltipedEllipsis";
import RatingSelector from "src/app/components/RatingSelector/RatingSelector";
import { RootState } from "src/redux/reducers";
import ChooseActionDropdown from "../../../../dropdowns/ChooseActionDropdown/ChooseActionDropdown";
import { ACTION_TYPES } from "../../utils";

import { ReactComponent as ShareAltIcon } from "../../../../../images/share-alt.svg";
import { ReactComponent as PlusIcon } from "../../../../../images/plus-transparent.svg";
import { ReactComponent as CloseIcon } from "../../../../../images/cross.svg";

import AvatarPlaceholder from "../../../../../images/avatar-purple.svg";
import ContentPlaceholder from "../../../../../images/content-placeholder.svg";

import { DateObject } from "../../../Project";
import { getImageSource } from "../../../../../utils/methods";

interface TaskTableFieldInterface {
  fieldType: string;
  fieldValue: any;
  onValueChange: (
    newValue: string | DateObject | null | number | boolean,
  ) => void;
  clearFieldValue: () => void;
  renderBasicTypes: () => ReactElement;
  shouldTriggerEditWarning?: boolean | undefined;
}

export default function TaskTableField({
  fieldType,
  fieldValue,
  onValueChange,
  clearFieldValue,
  renderBasicTypes,
  shouldTriggerEditWarning,
}: TaskTableFieldInterface) {
  const [action, setAction] = useState<any>({});

  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );

  const creatorAutocomplete = useSelector(
    (state: RootState) => state.taskReducer.creatorAutocomplete,
  );

  const contentAutocomplete = useSelector(
    (state: RootState) => state.taskReducer.contentAutocomplete,
  );

  const publicationAutocomplete = useSelector(
    (state: RootState) => state.taskReducer.publicationAutocomplete,
  );

  const history = useHistory();
  const params = useParams<{ projectId: string }>();

  const getTaskAutocompleteData = () => {
    switch (fieldType) {
      case "creator":
        return creatorAutocomplete;
      case "content":
        return contentAutocomplete;
      case "publicationRelated":
        return publicationAutocomplete;
      default:
        return [];
    }
  };

  useEffect(() => {
    if (ACTION_TYPES.includes(fieldType)) {
      const actionCopy = getTaskAutocompleteData()?.find(
        (item) => item.taskId === fieldValue,
      );

      setAction(actionCopy);
    }
  }, [
    fieldValue,
    creatorAutocomplete,
    contentAutocomplete,
    publicationAutocomplete,
  ]);

  useEffect(() => {
    if (action && fieldValue !== action?.taskId) {
      onValueChange(action.taskId);
    }
  }, [action]);

  const clearAction = () => {
    setAction(null);
    clearFieldValue();
  };

  const handleTaskOpen = (targetTaskId: string) => {
    history.push(
      `/workspace/${activeWorkspaceUuid}/projects/${params.projectId}/${targetTaskId}`,
    );
  };

  const renderTaskField = () => {
    switch (fieldType) {
      case "creator":
      case "publicationRelated":
      case "content": {
        return action?.taskId ? (
          <span className="action-field-wrapper">
            <ChooseActionDropdown
              data={getTaskAutocompleteData()}
              chooseAction={setAction}
              placeholder={action.title}
              fieldType={fieldType}
              toRight
            >
              <span
                className={classNames("action-field", {
                  "action-field--circle": fieldType === "creator",
                })}
              >
                {fieldType === "creator" ? (
                  <img
                    src={getImageSource(
                      action?.cover,
                      "tiny",
                      AvatarPlaceholder,
                    )}
                    alt="avatar"
                  />
                ) : fieldType === "publicationRelated" ? (
                  <img
                    src={getImageSource(
                      action?.cover,
                      "tiny",
                      PublicationPlaceholder,
                    )}
                    alt={fieldType}
                  />
                ) : (
                  <img
                    src={getImageSource(
                      action?.cover,
                      "tiny",
                      ContentPlaceholder,
                    )}
                    alt={fieldType}
                  />
                )}
                <TooltipedEllipsis maxWidth={160} content={action.title} />
              </span>
            </ChooseActionDropdown>
            <ShareAltIcon
              className="share-icon"
              onClick={(e) => {
                e.stopPropagation();
                handleTaskOpen(action.taskId);
              }}
            />
            {!shouldTriggerEditWarning && (
              <div onClick={clearAction} className="tasks-table__field-close">
                <CloseIcon />
              </div>
            )}
          </span>
        ) : (
          <ChooseActionDropdown
            data={getTaskAutocompleteData()}
            chooseAction={setAction}
            fieldType={fieldType}
            toRight
          >
            <div className="tasks-table__empty-field">
              <PlusIcon />
            </div>
          </ChooseActionDropdown>
        );
      }
      case "rating":
        return (
          <RatingSelector
            onValueChange={onValueChange}
            value={parseInt(fieldValue || "0", 10)}
          />
        );
      default:
        return renderBasicTypes();
    }
  };

  return renderTaskField();
}
