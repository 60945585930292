import React, { useEffect, useState } from "react";

import { GridSelection } from "@glideapps/glide-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { SelectedTasksBar } from "src/app/components/SelectedTasksBar/SelectedTasksBar";
import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as RubberIcon } from "src/images/rubber.svg";
import { ReactComponent as DeleteIcon } from "src/images/trash-can.svg";
import { ReactComponent as ShowcaseIcon } from "src/images/showcase.svg";
import { deleteDictionaryElement } from "src/redux";
import { deleteGlobalTasks } from "src/redux/creator-database/creatorDatabaseActions";
import Loader from "src/app/components/Loader/Loader";
import AddCreatorsCDTWorkspaceProjectModal, {
  SelectedCreator,
} from "src/app/modals/AddCreatorsCDTWorkspaceProjectModal/AddCreatorsCDTWorkspaceProjectModal";
import { selectedCreatorsPayload } from "src/app/project/SingleProject/components/SingleProjectSelectedTasksBar";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { tableDataType } from "src/app/components/Table/Table";
import { navigateToCreateNewProject } from "src/app/SettingsModal/methods";
import { setCreateNewProjectData } from "src/redux/main/mainActions";
import { RootState } from "src/redux/reducers";
import { Row } from "src/redux/dictionary/types";
import {
  CreatorShowcaseContext,
  MAX_CREATORS_FOR_GENERATE_SHOWCASE,
  openCreatorShowcase,
} from "src/app/CreatorShowcase/utils";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import AddToDropdownSelectedRowsBar from "./AddToDropdownSelectedRowsBar";
import { GlideDataGridContext } from "../../types";

interface GlideDataGridSelectedRowsBarProps {
  selectedRowsData: Row[];
  setSelection: React.Dispatch<React.SetStateAction<GridSelection | undefined>>;
  context: GlideDataGridContext;
}

export default function GlideDataGridSelectedRowsBar({
  selectedRowsData,
  setSelection,
  context,
}: GlideDataGridSelectedRowsBarProps) {
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [
    showModalAddCreatorsToWorkspaceProject,
    setShowModalAddCreatorsToWorkspaceProject,
  ] = useState(false);
  const [selectedCreators, setSelectedCreators] = useState<SelectedCreator[]>(
    [],
  );
  const [isTooltipOpen, setIsTooltipOpen] = useState(
    Boolean(!window.localStorage.getItem("selectedRowsBarTooltipHidden")),
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ dictionaryUuid: string }>();

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const deleteSelected = async () => {
    const uuids = selectedRowsData.map((item) => item.uuid);
    setIsLoading(true);
    switch (context) {
      case tableDataType.CreatorDatabase:
        await dispatch(deleteGlobalTasks(uuids));
        setSelection(undefined);
        break;
      case tableDataType.Dictionary:
        await dispatch(deleteDictionaryElement(params.dictionaryUuid, uuids));
        setSelection(undefined);
        break;
      default:
        break;
    }
    setIsLoading(false);
  };

  const hideTooltip = () => {
    localStorage.setItem("selectedRowsBarTooltipHidden", JSON.stringify(true));
    setIsTooltipOpen(false);
  };

  const dispatchDataAndNavigateToCreateNewProject = () => {
    dispatch(
      setCreateNewProjectData({
        selectedCreatorsPayload: selectedCreatorsPayload(selectedCreators),
      }),
    );
    navigateToCreateNewProject(history, activeWorkspaceUuid);
  };

  useEffect(() => {
    setSelectedCreators(
      selectedRowsData.map((selectedRowData) => ({
        name: selectedRowData.title,
        socialProfiles: selectedRowData.socialProfiles,
      })),
    );
  }, [selectedRowsData]);

  const openShowcase = () => {
    if (context !== "creatorDatabase") {
      return;
    }

    const ids = selectedRowsData.map((el) => el.uuid);
    openCreatorShowcase({
      history,
      context: CreatorShowcaseContext.CreatorDatabase,
      creatorId: ids[0],
      ids,
      workspaceId: activeWorkspaceUuid,
    });
  };

  return (
    <SelectedTasksBar
      selectedTasksLength={selectedRowsData.length}
      hideTooltip={hideTooltip}
      isTooltipOpen={isTooltipOpen}
    >
      <>
        {/* Generate Showcase Button */}
        {context === tableDataType.CreatorDatabase && (
          <TooltipPortal
            content={
              <IDHFormattedMessage
                id="ws_cannot_generate_showcase"
                defaultMessage="Cannot generate showcase for more than 100 creators"
              />
            }
            contentHidden={
              selectedRowsData.length <= MAX_CREATORS_FOR_GENERATE_SHOWCASE
            }
            up
          >
            <Button
              size="large"
              variant="white"
              onClick={openShowcase}
              data-action="generateShowcase"
              disabled={
                selectedRowsData.length > MAX_CREATORS_FOR_GENERATE_SHOWCASE
              }
            >
              <ShowcaseIcon />
              <IDHFormattedMessage
                id="ws_generate_showcase"
                defaultMessage="Generate showcase"
              />
            </Button>
          </TooltipPortal>
        )}

        {context === tableDataType.CreatorDatabase && (
          <AddToDropdownSelectedRowsBar
            setShowModalAddCreatorsToWorkspaceProject={
              setShowModalAddCreatorsToWorkspaceProject
            }
            setSelectedCreators={setSelectedCreators}
          />
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <Button
            size="large"
            variant="white"
            onClick={() => setShowDeleteTaskModal(true)}
          >
            <DeleteIcon />
            <IDHFormattedMessage
              id="ws_delete_selected"
              defaultMessage="Delete"
            />
          </Button>
        )}
        <Button
          size="large"
          variant="white"
          onClick={() => setSelection(undefined)}
        >
          <RubberIcon />
          <IDHFormattedMessage id="ws_clear_selected" defaultMessage="Clear" />
        </Button>
        {showDeleteTaskModal && (
          <RemoveModal
            onClose={() => setShowDeleteTaskModal(false)}
            objectNames={
              selectedRowsData?.map((selectedRowData) => {
                return selectedRowData.title;
              }) ?? []
            }
            removeFunction={() => {
              deleteSelected();
              setShowDeleteTaskModal(false);
            }}
          />
        )}
        {showModalAddCreatorsToWorkspaceProject && (
          <AddCreatorsCDTWorkspaceProjectModal
            onClose={() => setShowModalAddCreatorsToWorkspaceProject(false)}
            selectedCreators={selectedCreators}
            openCreateNewProjectModal={
              dispatchDataAndNavigateToCreateNewProject
            }
          />
        )}
      </>
    </SelectedTasksBar>
  );
}
