import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "src/app/components/Button/Button";
import "./AccessLinkButtonsComponent.scss";

export function AccessLinkButtonsComponent({
  clearValues,
  submitValues,
  submitDisabled,
}) {
  return (
    <div className="access-link-page__form-buttons">
      <Button
        type="button"
        variant="white-with-border"
        size="large"
        onClick={clearValues}
      >
        <IDHFormattedMessage id="ws_clear" defaultMessage="Clear" />
      </Button>
      <Button
        type="button"
        variant="blue"
        size="large"
        onClick={submitValues}
        disabled={submitDisabled}
      >
        <IDHFormattedMessage id="ws_submit" defaultMessage="Submit" />
      </Button>
    </div>
  );
}
