import React from "react";

import { ReactComponent as PdfIcon } from "../../../../images/pdf-attachment.svg";
import { ReactComponent as XlsIcon } from "../../../../images/xls-attachment.svg";
import { ReactComponent as DocIcon } from "../../../../images/doc-attachment.svg";
import { ReactComponent as PptxIcon } from "../../../../images/pptx-attachment.svg";
import { ReactComponent as ZipIcon } from "../../../../images/zip-attachment.svg";
import { ReactComponent as TxtIcon } from "../../../../images/txt-attachment.svg";
import { ReactComponent as MusicIcon } from "../../../../images/music-attachment.svg";
import { ReactComponent as VideoIcon } from "../../../../images/video-attachment.svg";
import { ReactComponent as FileGreyIcon } from "../../../../images/files/file-grey.svg";

export default function getAttachmentIcon(fileExtension: string) {
  switch (fileExtension) {
    case "pdf":
      return <PdfIcon className="attachment-file__icon" />;
    case "xls":
    case "xlsx":
    case "csv":
      return <XlsIcon className="attachment-file__icon" />;
    case "doc":
    case "odc":
      return <DocIcon className="attachment-file__icon" />;
    case "docx":
      return <DocIcon className="attachment-file__icon" />;
    case "ppt":
    case "pptx":
    case "ods":
      return <PptxIcon className="attachment-file__icon" />;
    case "zip":
      return <ZipIcon className="attachment-file__icon" />;
    case "txt":
      return <TxtIcon className="attachment-file__icon" />;
    case "mp3":
      return <MusicIcon className="attachment-file__icon" />;
    case "mp4":
    case "mov":
    case "avi":
      return <VideoIcon className="attachment-file__icon" />;
    default:
      return <FileGreyIcon className="attachment-file__icon" />;
  }
}
