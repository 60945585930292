import React from "react";
import "./CampaignInfoCellTooltip.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export function CampaignInfoCellTooltip({ isPublic }) {
  return (
    <div className="campaign-info-tooltip">
      <div className="campaign-info-tooltip__header">
        {isPublic ? (
          <IDHFormattedMessage id="ws_public" defaultMessage="Public" />
        ) : (
          <IDHFormattedMessage id="ws_private" defaultMessage="Private" />
        )}
      </div>
      <div className="campaign-info-tooltip__text">
        {isPublic ? (
          <IDHFormattedMessage
            id="ws_public_campaign_tooltip"
            defaultMessage="The list is visible to all members of your team."
          />
        ) : (
          <IDHFormattedMessage
            id="ws_private_campaign_tooltip"
            defaultMessage="The list is visible only for you."
          />
        )}
      </div>
    </div>
  );
}
