import React from "react";

import { useSelector } from "react-redux";
import LazyLoad from "react-lazy-load";

import { ReactComponent as TrashcanIcon } from "src/images/trash-can.svg";
import { TooltipedEllipsis } from "src/app/components/TooltipedEllipsis/TooltipedEllipsis";
import { StackedAvatars } from "src/app/components/StackedAvatars/StackedAvatars";
import { SetMetaFieldAccessLevelDropdown } from "src/app/dropdowns/SetMetaFieldAccessLevelDropdown/SetMetaFieldAccessLevelDropdown";

function GlobalMetaFieldMemberListItem({
  member,
  setShowConfirmDeleteMemberModal,
  setDeleteMemberData,
}) {
  const {
    mainReducer: { identity },
  } = useSelector((state) => state);

  return (
    <div
      className="meta-field-members__user-list-item"
      data-qa={member.email}
      key={member.uuid}
    >
      <div className="meta-field-members__user-list-item-left">
        <LazyLoad className="meta-field-members__user-list-item-avatar-wrapper">
          <StackedAvatars
            avatars={member.members?.map((memberRow) => ({
              url: memberRow?.avatarUrl ?? null,
            }))}
            maxAvatars={5}
          />
        </LazyLoad>
        <div className="meta-field-members__user-list-item-info">
          <div className="meta-field-members__user-list-item-name">
            <TooltipedEllipsis content={member.name} maxWidth={290} />
            {member.type === "member" && (
              <TooltipedEllipsis
                content={member.email}
                className="meta-field-members__user-list-item-email"
                maxWidth={290}
              />
            )}
          </div>
        </div>
      </div>
      <div className="meta-field-members__user-list-item-right">
        <SetMetaFieldAccessLevelDropdown
          wsMemberUuid={member.wsMemberUuid ?? null}
          wsTeamUuid={member.wsTeamUuid ?? null}
          currentAccessLevel={member.accessLevel}
          wsGlobalTaskMetaFieldUuid={member.wsGlobalTaskMetaFieldUuid}
        />
        {identity.id !== member.wsMemberUuid ? (
          <TrashcanIcon
            onClick={() => {
              setShowConfirmDeleteMemberModal(true);
              setDeleteMemberData(member);
            }}
            className="meta-field-members__user-list-item-trashcan"
          />
        ) : (
          <TrashcanIcon className="meta-field-members__user-list-item-trashcan members__user-list-item-trashcan--disabled" />
        )}
      </div>
    </div>
  );
}

export { GlobalMetaFieldMemberListItem };
