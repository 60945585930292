import React, { useEffect, useRef, useState } from "react";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";

function ColumnHeaderName(props) {
  const ref = useRef(null);

  const { column, children, showNameTooltip } = props;

  const [doesOverflowWidth, setDoesOverflowWidth] = useState(false);
  const [doesOverflowHeight, setDoesOverflowHeight] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setDoesOverflowWidth(ref.current.offsetWidth < ref.current.scrollWidth);
      setDoesOverflowHeight(
        ref.current.offsetHeight < ref.current?.scrollHeight,
      );
    }
  }, []);

  return (
    <div
      className="tasks-table__column-header-name"
      data-tooltip-id={`tasks-table__header-name-tooltip-${column.id}`}
      data-tooltip-place="bottom"
      ref={ref}
    >
      {showNameTooltip ? (
        <TooltipPortal
          center
          content={children}
          contentHidden={!(doesOverflowHeight || doesOverflowWidth)}
        >
          {children}
        </TooltipPortal>
      ) : (
        children
      )}
    </div>
  );
}

export { ColumnHeaderName };
