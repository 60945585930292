import React from "react";

import "./EmptyTabContent.scss";
import { Button } from "src/app/components/Button/Button";

function EmptyTabContent(props) {
  const { header, text, buttonText, onButtonClick, buttonIcon, imgUrl } = props;

  return (
    <div className="empty-tab-content">
      <img src={imgUrl} className="empty-tab-content__not-found-img" />
      {header && <div className="empty-tab-content__header">{header}</div>}
      {text && <div className="empty-tab-content__text">{text}</div>}
      {(buttonText || buttonIcon) && onButtonClick && (
        <Button size="medium" variant="blue" onClick={onButtonClick}>
          {buttonIcon}
          {buttonText}
        </Button>
      )}
    </div>
  );
}

export { EmptyTabContent };
