import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { uuidv7 as uuid } from "uuidv7";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  DragDropContext,
  DraggingStyle,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import classNames from "classnames";

import SingleAccessField from "src/app/modals/ManageFieldModal/components/AccessLinkField/PopUps/components/SingleAccessField";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MetafieldAccessMode, ExtensionTypeEnum } from "src/types";
import { ReactComponent as PlusIcon } from "src/images/plus-alt.svg";
import ManageFieldModal from "src/app/modals/ManageFieldModal/ManageFieldModal";
import { tableDataType } from "src/app/components/Table/Table";
import { API_URLS } from "src/utils/API_URLS";
import { showToast } from "src/app/methods/showToast";
import {
  WorkspacePermissionsEnum,
  ProjectPermissionsEnum,
} from "src/utils/PermissionsEnums";
import { RootState } from "src/redux/reducers";
import { TasksColumn } from "src/redux/task/taskReducer";
import { moveInArray, showErrorToast } from "src/utils/methods";
import { getMetaFieldTypeByExtensionType } from "../../../../Extensions";
import {
  RecruitmentFormUserData,
  RecruitmentFormUserDataMetafields,
} from "../../types";

interface RecruitmentFormFieldsProps {
  setUserData: Dispatch<SetStateAction<RecruitmentFormUserData>>;
  userData: RecruitmentFormUserData;
  extensionUuid: string;
  permissions: { project: string[]; workspace: string[] };
  contextData: any;
  extensionType: ExtensionTypeEnum;
  setAvailableMetaFields: Dispatch<SetStateAction<Field[]>>;
  availableMetaFields: Field[];
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export interface Field {
  label: string;
  metafieldType: string;
  value: string;
  isNotDeletable?: boolean;
}

interface MetaFieldsToDisplay {
  field: Field | undefined;
  mode: MetafieldAccessMode;
  required: boolean;
  wsMetaFieldDescription: string | null;
}

export const TASK_METAFIELD_TYPE = "wsTaskMetaField";
export const GLOBAL_TASK_METAFIELD_TYPE = "wsGlobalTaskMetaField";

export function RecruitmentFormFields({
  setUserData,
  userData,
  extensionUuid,
  permissions,
  contextData,
  extensionType,
  setAvailableMetaFields,
  availableMetaFields,
  setIsLoading,
}: RecruitmentFormFieldsProps) {
  const accessFieldsRef = useRef<HTMLDivElement | null>(null);
  const [showAddFieldModal, setShowAddFieldModal] = useState(false);
  const [createdFieldsIds, setCreatedFieldsIds] = useState<string[]>([]);
  const [metaFieldsToDisplay, setMetaFieldsToDisplay] = useState<
    MetaFieldsToDisplay[]
  >([]);

  const {
    taskReducer: { tasksColumns },
  } = useSelector((state: RootState) => state);

  const handleUpdateMetaFields = async (
    metaFields: RecruitmentFormUserDataMetafields[],
  ) => {
    if (!extensionUuid) return;
    try {
      setIsLoading(true);
      const result = await axios.put(
        API_URLS.updateExtensionDetails.replace(
          ":wsExtensionUuid:",
          extensionUuid,
        ),
        {
          ...userData,
          recruitmentFormMetaFields: {
            ...userData.recruitmentFormMetaFields,
            metaFields,
          },
          wsAccessLinkCover: null,
        },
      );
      if (result.statusText === "OK") {
        showToast("success", "Success", "Updated form fields");
      }
    } catch (err) {
      console.error(err);
      showErrorToast();
    } finally {
      setIsLoading(false);
    }
  };

  const updateValues = (
    index: number,
    field: Field,
    required: boolean,
    editable: boolean,
    wsMetaFieldDescription: string | null,
  ) => {
    setUserData((prevState) => {
      const tmp = [...prevState.recruitmentFormMetaFields.metaFields];
      tmp[index] = {
        wsMetaFieldUuid: field.value,
        wsMetaFieldAccessMode: editable
          ? MetafieldAccessMode.write
          : MetafieldAccessMode.read,
        wsMetaFieldIsRequired: required,
        wsMetaFieldType: getMetaFieldTypeByExtensionType(extensionType),
        wsMetaFieldDescription,
      };

      if (
        permissions?.project?.includes(
          ProjectPermissionsEnum.MANAGE_PROJECT_CONFIGURATION,
        ) ||
        permissions?.workspace?.includes(
          WorkspacePermissionsEnum.CREATOR_DATABASE_CONFIGURATION,
        )
      ) {
        handleUpdateMetaFields(tmp);
      }

      return {
        ...prevState,
        recruitmentFormMetaFields: {
          ...prevState.recruitmentFormMetaFields,
          metaFields: tmp,
        },
      };
    });
  };

  const dropValue = (index: number) => {
    setUserData((prevState) => {
      const tmp = [...prevState.recruitmentFormMetaFields.metaFields];
      tmp.splice(index, 1);
      if (
        permissions?.project?.includes(
          ProjectPermissionsEnum.MANAGE_PROJECT_CONFIGURATION,
        ) ||
        permissions?.workspace?.includes(
          WorkspacePermissionsEnum.CREATOR_DATABASE_CONFIGURATION,
        )
      ) {
        handleUpdateMetaFields(tmp);
      }
      return {
        ...prevState,
        recruitmentFormMetaFields: {
          ...prevState.recruitmentFormMetaFields,
          metaFields: tmp,
        },
      };
    });
  };

  const pushValues = (
    fields: Field | Field[],
    required: boolean,
    editable: boolean,
    wsMetaFieldDescription: string | null,
  ) => {
    setUserData((prevState) => {
      const fieldsArray = Array.isArray(fields) ? fields : [fields];

      const tmp = [
        ...prevState.recruitmentFormMetaFields.metaFields,
        ...fieldsArray.map((field) => ({
          wsMetaFieldUuid: field.value,
          wsMetaFieldAccessMode: editable
            ? MetafieldAccessMode.write
            : MetafieldAccessMode.read,
          wsMetaFieldIsRequired: required,
          wsMetaFieldType: getMetaFieldTypeByExtensionType(extensionType),
          wsMetaFieldDescription,
        })),
      ];

      if (
        permissions?.project?.includes(
          ProjectPermissionsEnum.MANAGE_PROJECT_CONFIGURATION,
        ) ||
        permissions?.workspace?.includes(
          WorkspacePermissionsEnum.CREATOR_DATABASE_CONFIGURATION,
        )
      ) {
        handleUpdateMetaFields(tmp);
      }

      return {
        ...prevState,
        recruitmentFormMetaFields: {
          ...prevState.recruitmentFormMetaFields,
          metaFields: tmp,
        },
      };
    });

    setTimeout(() => {
      if (!accessFieldsRef.current) return;
      accessFieldsRef.current.scrollTo({
        top: accessFieldsRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 150);
  };

  const newlyAddedFields = tasksColumns.filter((tasksColumn: TasksColumn) =>
    createdFieldsIds.find((id) => id === tasksColumn.metaFieldId),
  );

  useEffect(() => {
    setMetaFieldsToDisplay(
      userData.recruitmentFormMetaFields.metaFields.map((m) => {
        const field = availableMetaFields?.find(
          (o) => o.value === m.wsMetaFieldUuid,
        );
        return {
          field,
          mode: m.wsMetaFieldAccessMode,
          required: m.wsMetaFieldIsRequired,
          wsMetaFieldDescription: m.wsMetaFieldDescription ?? null,
        };
      }) || [],
    );
  }, [userData.recruitmentFormMetaFields.metaFields, availableMetaFields]);

  const addNewField = async () => {
    setAvailableMetaFields((prevOptions) => [
      ...prevOptions,
      ...newlyAddedFields.map((newlyAddedField: TasksColumn) => ({
        label: newlyAddedField.metaFieldName,
        metafieldType: getMetaFieldTypeByExtensionType(extensionType),
        value: newlyAddedField.metaFieldId,
      })),
    ]);

    pushValues(
      newlyAddedFields.map((newlyAddedField: TasksColumn) => ({
        label: "",
        metafieldType: TASK_METAFIELD_TYPE,
        value: newlyAddedField.metaFieldId,
      })),
      true,
      true,
      "",
    );

    setCreatedFieldsIds([]);
  };

  useEffect(() => {
    if (createdFieldsIds.length > 0 && newlyAddedFields.length > 0) {
      addNewField();
    }
  }, [createdFieldsIds, JSON.stringify(newlyAddedFields)]);

  const handleDragEnd = async (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }
    const { destination, source } = result;

    const indexDiff = metaFieldsToDisplay.filter(
      (item) => item?.field?.isNotDeletable,
    ).length;

    setMetaFieldsToDisplay(
      moveInArray(
        metaFieldsToDisplay,
        destination.index + indexDiff,
        source.index + indexDiff,
      ) || [],
    );

    const newMetaFields =
      moveInArray(
        userData.recruitmentFormMetaFields.metaFields,
        destination.index + indexDiff,
        source.index + indexDiff,
      ) || [];

    handleUpdateMetaFields(newMetaFields);
    setUserData((prevState) => ({
      ...prevState,
      recruitmentFormMetaFields: {
        ...prevState.recruitmentFormMetaFields,
        metaFields: newMetaFields,
      },
    }));
  };

  const getElementTop = (style: DraggingStyle) => {
    if (style && "top" in style) {
      if (window.innerWidth < 1024) {
        return style.top - 235;
      }

      if (window.innerWidth < 1400) {
        return style.top - 205;
      }

      return style.top - 135;
    }
    return "auto";
  };

  const nonDeletableFieldsCount = useMemo(
    () =>
      metaFieldsToDisplay.filter((item) => item?.field?.isNotDeletable).length,
    [metaFieldsToDisplay],
  );

  return (
    <>
      <div className="recruitment-form__description">
        <IDHFormattedMessage
          id="ws_recruitment_form_fields_description"
          defaultMessage="Select the fields in the campaign where the person you share this form with should enter the necessary data. You can select only fields that have already been created."
        />
      </div>
      <div ref={accessFieldsRef} className="recruitment-form__access-fields">
        {metaFieldsToDisplay
          .filter((item) => item?.field?.isNotDeletable)
          .map((item, i) => {
            return (
              <SingleAccessField
                key={item.field?.value}
                index={i}
                editable={Boolean(item?.mode === MetafieldAccessMode.write)}
                required={item?.required}
                options={availableMetaFields}
                updateValues={updateValues}
                dropValue={dropValue}
                selectedVals={metaFieldsToDisplay}
                field={item?.field}
                isFieldDisabled={item.field?.isNotDeletable}
                shouldHideEditableSwitch
                getElementTop={getElementTop}
                setIsLoading={setIsLoading}
              />
            );
          })}

        <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <div
                className={classNames("recruitment-form__droppable-wrapper", {
                  "recruitment-form__droppable-wrapper":
                    snapshot.isDraggingOver,
                })}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {metaFieldsToDisplay
                  .filter((item) => !item?.field?.isNotDeletable)
                  .map((item, i) => {
                    const idx = i + nonDeletableFieldsCount;
                    return (
                      <SingleAccessField
                        key={item.field?.value}
                        index={idx}
                        dragIndex={i}
                        editable={Boolean(
                          item?.mode === MetafieldAccessMode.write,
                        )}
                        required={item?.required}
                        options={availableMetaFields}
                        updateValues={updateValues}
                        dropValue={dropValue}
                        selectedVals={metaFieldsToDisplay}
                        field={item?.field}
                        isFieldDisabled={item.field?.isNotDeletable}
                        shouldHideEditableSwitch
                        getElementTop={getElementTop}
                        setIsLoading={setIsLoading}
                      />
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <SingleAccessField
          key={uuid()}
          pushValues={pushValues}
          dropValue={dropValue}
          options={availableMetaFields}
          selectedVals={metaFieldsToDisplay}
          shouldHideControls
          afterMenuList={
            extensionType === ExtensionTypeEnum.RecruitmentForm && (
              <div
                className="recruitment-form__access-fields-extra-option-btn"
                onClick={() => setShowAddFieldModal(true)}
              >
                <PlusIcon width={20} height={20} />
                <IDHFormattedMessage
                  id="ws_add_new_field"
                  defaultMessage="Add new field"
                />
              </div>
            )
          }
          getElementTop={getElementTop}
        />
      </div>

      {showAddFieldModal && (
        <ManageFieldModal
          onSuccessCallback={(uuids?: string[]) => {
            if (uuids?.length) {
              setCreatedFieldsIds(uuids);
            }
          }}
          projectId={contextData?.uuid}
          onClose={() => setShowAddFieldModal(false)}
          dataType={tableDataType.RecruitmentForm}
          title={
            <IDHFormattedMessage
              id="ws_add_recruitment_form_field"
              defaultMessage="Add recruitment form field"
            />
          }
          inputLabelText={
            <IDHFormattedMessage
              id="ws_field_name"
              defaultMessage="Field name"
            />
          }
        />
      )}
    </>
  );
}
