import { useState, Dispatch, SetStateAction } from "react";

export const useShowPane = (
  onHide?: () => void,
): [boolean, () => void, Dispatch<SetStateAction<boolean>>] => {
  const [showPane, setShowPane] = useState(true);

  const hidePane = () => {
    setShowPane(false);
    setTimeout(() => {
      if (onHide) {
        onHide();
      }
    }, 300);
  };

  return [showPane, hidePane, setShowPane];
};
