import axios from "axios";

export class cancelRequestAxios {
  constructor() {
    this.cancel_request = null;
  }

  cancelAndCreateToken = () => {
    if (this.cancel_request) {
      this.cancel_request.cancel();
    }
    this.cancel_request = axios.CancelToken.source();
  };

  // reset Cancel token
  resetCancelToken = () => {
    this.cancel_request = null;
  };
}
