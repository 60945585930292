import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./CroppedImageUploader.scss";

import Croppie from "croppie";
import "croppie/croppie.css";

import classNames from "classnames";
import { dataURLtoFile } from "./utils/dataURLtoFile";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";

const CroppedImageUploader = forwardRef(
  ({ uploadFunction, circle, loaded, setLoaded }, ref) => {
    const [croppie, setCroppie] = useState(null);
    const [fileUrl, setFileUrl] = useState("");
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
      inputRef.current.click();
    }, []);

    useImperativeHandle(ref, () => ({
      loaded,
      handleSubmit() {
        if (croppie !== null) {
          croppie
            .result({
              type: "canvas",
              circle: false,
              size: {
                width: 300,
                height: 300,
              },
            })
            .then((blob) => {
              const file = dataURLtoFile(blob, "ws_avatar.png");
              file.localSrc = blob;
              uploadFunction(file);
            });
        }
      },
    }));

    function handleImage(image) {
      setLoaded(true);
      if (wrapperRef?.current) {
        const croppieInstance = new Croppie(wrapperRef.current, {
          enableExif: true,
          viewport: {
            height: 200,
            width: 200,
            type: circle && "circle",
          },
          boundary: {
            height: 300,
            width: 300,
          },
          minZoom: 0.05,
          enforceBoundary: false,
        });
        croppieInstance.bind({
          url: image,
        });
        setCroppie(croppieInstance);
      }
    }

    function onChange(changeEvent) {
      const file = changeEvent.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (loadEvent) {
        handleImage(reader.result);
      };
    }

    return (
      <div
        className={classNames([
          "cropped-image-uploader",
          { "cropped-image-uploader--circle": circle },
        ])}
      >
        {loaded === false && (
          <div className="cropped-image-uploader__label">
            <div className="cropped-image-uploader__label__avatar-without-photo" />
            <div className="cropped-image-uploader__label__choose-image">
              <IDHFormattedMessage
                id="ws_choose_image"
                defaultMessage="Choose image"
              />
            </div>
            <input
              type="file"
              className="cropped-image-uploader__input"
              onChange={onChange}
              accept="image/*"
              ref={inputRef}
            />
          </div>
        )}
        <div ref={wrapperRef} />
      </div>
    );
  },
);

export default CroppedImageUploader;
