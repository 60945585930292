import React from "react";
import { AudienceInterests } from "../../../../Task/tabs/ProfileDataTab/single/AudienceInterests";

export const renderAudienceInterests = (
  label,
  data,
  layout,
  GridBoxComponent,
) => {
  const {
    user_audience_data: { audienceInterests },
  } = data;

  const key = `${label}-audience-interests`;
  const height = layout?.find((item) => item.i === key)?.h;

  return (
    <GridBoxComponent key={key} id={key}>
      <AudienceInterests
        audienceInterests={audienceInterests}
        height={height}
      />
    </GridBoxComponent>
  );
};
