import React, { useState } from "react";
import Modal from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { injectIntl } from "react-intl";
import Emoji from "../../components/Emoji/Emoji";

function CreateAudienceModal(props) {
  const { onClose, onConfirmClick, intl, confirmButtonLoading } = props;

  const [listName, setListName] = useState("");

  const onChangeHandler = (e) => {
    const text = e.target.value;
    setListName(text);
  };

  return (
    <Modal
      onClose={onClose}
      title={
        <>
          <IDHFormattedMessage
            id="front_modal_create_audience_title"
            defaultMessage="Create audience"
          />
          &nbsp;
          <Emoji icon="⚡️" />
        </>
      }
      closeButtonText={
        <IDHFormattedMessage id="front_bd_cancel" defaultMessage="Cancel" />
      }
      displayCancelButton
      confirmButtonLoading={confirmButtonLoading}
      onConfirmClick={() => onConfirmClick(listName)}
      confirmButtonDisabled={!listName.length}
    >
      <div className="modal__text">
        <IDHFormattedMessage
          id="front_modal_create_audience_text"
          defaultMessage="To create an audience you must enter a name."
        />
      </div>
      <input
        className="idh-form__input"
        placeholder={intl.formatMessage({
          id: "front_type_audience_name",
          defaultMessage: "Type audience name...",
        })}
        data-cdt-input="create-influencer-list"
        value={listName}
        disabled={confirmButtonLoading}
        onChange={onChangeHandler}
        onKeyPress={(e) =>
          e.key === "Enter" && listName.length > 0 && onConfirmClick(listName)
        }
      />
    </Modal>
  );
}

export default injectIntl(CreateAudienceModal);
