import React from "react";

import { ReactComponent as FileGreyIcon } from "src/images/files/file-grey.svg";
import { StaticCellSettings } from "../../../types";

interface StaticFilesLoadingCellProps {
  settings: StaticCellSettings;
}

function StaticFilesLoadingCell({ settings }: StaticFilesLoadingCellProps) {
  return (
    <div
      className="glide-data-grid__static-files-loading-cell"
      style={{
        ...settings.style,
      }}
    >
      <div className="glide-data-grid__file-loader-wrapper">
        <span className="glide-data-grid__file-counter">
          <FileGreyIcon className="glide-data-grid__file-counter-icon" />
          {settings.numberOfFiles} files
        </span>
        <div className="glide-data-grid__loader">
          <span className="glide-data-grid__loader-progress" />
        </div>
      </div>
    </div>
  );
}

export default StaticFilesLoadingCell;
