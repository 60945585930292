import React from "react";

import { PublicationStatus } from "src/types/publication";
import IDHFormattedMessage from "../../IDHFormattedMessage/IDHFormattedMessage";

export const publicationStatusesWithTooltip = [
  PublicationStatus.Fresh,
  PublicationStatus.Error,
  PublicationStatus.NotFound,
  PublicationStatus.Private,
  PublicationStatus.Restricted,
];

export const publicationStatusesWithWarning = [
  PublicationStatus.Error,
  PublicationStatus.NotFound,
  PublicationStatus.Private,
  PublicationStatus.Restricted,
];

export const getPublicationStatusNameBasedOnStatus = (
  status: PublicationStatus,
): string => {
  if (status === PublicationStatus.Fresh) {
    return "Fresh";
  }

  if (status === PublicationStatus.Verified) {
    return "Verified";
  }

  if (status === PublicationStatus.Error) {
    return "Error";
  }

  if (status === PublicationStatus.NotFound) {
    return "NotFound";
  }

  if (status === PublicationStatus.Restricted) {
    return "Restricted";
  }

  if (status === PublicationStatus.Private) {
    return "Private";
  }

  return "unknown";
};

export const getTooltipByPublicationStatus = (status: PublicationStatus) => {
  if (status === PublicationStatus.Fresh) {
    return (
      <div>
        <strong>
          <IDHFormattedMessage
            id="ws_publication_fresh_header"
            defaultMessage="Please wait patiently..."
          />
        </strong>
        <br />
        <IDHFormattedMessage
          id="ws_publication_fresh"
          defaultMessage="We are gathering data for this publication. It will be available within a few minutes."
        />
      </div>
    );
  }

  if (status === PublicationStatus.Error) {
    return (
      <IDHFormattedMessage
        id="ws_publication_error"
        defaultMessage={getPublicationStatusNameBasedOnStatus(status)}
      />
    );
  }

  if (status === PublicationStatus.NotFound) {
    return (
      <div>
        <strong>
          <IDHFormattedMessage
            id="ws_publication_not_found_header"
            defaultMessage="Publication was not detected."
          />
        </strong>
        <br />
        <IDHFormattedMessage
          id="ws_publication_not_found"
          defaultMessage="Please check the link's correctness."
        />
      </div>
    );
  }

  if (status === PublicationStatus.Restricted) {
    return (
      <div>
        <strong>
          <IDHFormattedMessage
            id="ws_publication_restricted_header"
            defaultMessage="Profile data for this account is unavailable."
          />
        </strong>
        <br />
        <IDHFormattedMessage
          id="ws_publication_restricted"
          defaultMessage="The publication's account is restricted."
        />
      </div>
    );
  }

  if (status === PublicationStatus.Private) {
    return (
      <div>
        <strong>
          <IDHFormattedMessage
            id="ws_publication_private_header"
            defaultMessage="Publication was not detected."
          />
        </strong>
        <br />
        <IDHFormattedMessage
          id="ws_publication_private"
          defaultMessage="The publication's account is private."
        />
      </div>
    );
  }

  return (
    <IDHFormattedMessage
      id="ws_publication_status_unknown"
      defaultMessage={getPublicationStatusNameBasedOnStatus(status)}
    />
  );
};
