import React, { useEffect, useState } from "react";

import { ReactSVG } from "react-svg";
import { AppConfig } from "../../../config/AppConfig";
import { colorNameToHex } from "../../../utils/colorFunctionsUtils";

export enum PictogramVariants {
  // Social
  Instagram = "Instagram",
  TikTok = "TikTok",
  YouTube = "YouTube",
  Twitter = "Twitter",
  Facebook = "Facebook",
  Discord = "Discord",
  Twitch = "Twitch",
  Snapchat = "Snapchat",
  Pinterest = "Pinterest",
  // Basic
  Aliceblue = "Aliceblue",
  Antiquewhite = "Antiquewhite",
  Aqua = "Aqua",
  Aquamarine = "Aquamarine",
  Azure = "Azure",
  Beige = "Beige",
  Bisque = "Bisque",
  Black = "Black",
  Blanchedalmond = "Blanchedalmond",
  Blue = "Blue",
  Blueviolet = "Blueviolet",
  Brown = "Brown",
  Burlywood = "Burlywood",
  Cadetblue = "Cadetblue",
  Chartreuse = "Chartreuse",
  Chocolate = "Chocolate",
  Coral = "Coral",
  Cornflowerblue = "Cornflowerblue",
  Cornsilk = "Cornsilk",
  Coyotebrown = "Coyotebrown",
  Crimson = "Crimson",
  Cyan = "Cyan",
  Darkblue = "Darkblue",
  Darkcyan = "Darkcyan",
  Darkgoldenrod = "Darkgoldenrod",
  Darkgray = "Darkgray",
  Darkgreen = "Darkgreen",
  Darkkhaki = "Darkkhaki",
  Darkmagenta = "Darkmagenta",
  Darkolivegreen = "Darkolivegreen",
  Darkorange = "Darkorange",
  Darkorchid = "Darkorchid",
  Darkred = "Darkred",
  Darksalmon = "Darksalmon",
  Darkseagreen = "Darkseagreen",
  Darkslateblue = "Darkslateblue",
  Darkslategray = "Darkslategray",
  Darkturquoise = "Darkturquoise",
  Darkviolet = "Darkviolet",
  Deeplilac = "Deeplilac",
  Deeppink = "Deeppink",
  Deepskyblue = "Deepskyblue",
  Dimgray = "Dimgray",
  Dodgerblue = "Dodgerblue",
  Firebrick = "Firebrick",
  Floralwhite = "Floralwhite",
  Forestgreen = "Forestgreen",
  Fuchsia = "Fuchsia",
  Gainsboro = "Gainsboro",
  Ghostwhite = "Ghostwhite",
  Gold = "Gold",
  Goldenrod = "Goldenrod",
  Graphite = "Graphite",
  Gray = "Gray",
  Green = "Green",
  Greenyellow = "Greenyellow",
  Honeydew = "Honeydew",
  Hotpink = "Hotpink",
  Indianred = "Indianred",
  Indigo = "Indigo",
  Ivory = "Ivory",
  Khaki = "Khaki",
  Lavender = "Lavender",
  Lavenderblush = "Lavenderblush",
  Lawngreen = "Lawngreen",
  Lemonchiffon = "Lemonchiffon",
  Lightblue = "Lightblue",
  Lightcoral = "Lightcoral",
  Lightcyan = "Lightcyan",
  Lightgoldenrodyellow = "Lightgoldenrodyellow",
  Lightgrey = "Lightgrey",
  Lightgreen = "Lightgreen",
  Lightpink = "Lightpink",
  Lightsalmon = "Lightsalmon",
  Lightsalmonalt = "Lightsalmonalt",
  Lightseagreen = "Lightseagreen",
  Lightskyblue = "Lightskyblue",
  Lightslategray = "Lightslategray",
  Lightsteelblue = "Lightsteelblue",
  Lightyellow = "Lightyellow",
  Lime = "Lime",
  Limegreen = "Limegreen",
  Linen = "Linen",
  Magenta = "Magenta",
  Mantis = "Mantis",
  Marigold = "Marigold",
  Maroon = "Maroon",
  Mediumaquamarine = "Mediumaquamarine",
  Mediumblue = "Mediumblue",
  Mediumorchid = "Mediumorchid",
  Mediumpurple = "Mediumpurple",
  Mediumseagreen = "Mediumseagreen",
  Mediumslateblue = "Mediumslateblue",
  Mediumspringgreen = "Mediumspringgreen",
  Mediumturquoise = "Mediumturquoise",
  Mediumvioletred = "Mediumvioletred",
  Midnightblue = "Midnightblue",
  Mintcream = "Mintcream",
  Mistyrose = "Mistyrose",
  Moccasin = "Moccasin",
  Navajowhite = "Navajowhite",
  Navy = "Navy",
  Oldlace = "Oldlace",
  Olive = "Olive",
  Olivedrab = "Olivedrab",
  Orange = "Orange",
  Orangered = "Orangered",
  Orchid = "Orchid",
  Original = "Original",
  Palegoldenrod = "Palegoldenrod",
  Palegreen = "Palegreen",
  Paleturquoise = "Paleturquoise",
  Palevioletred = "Palevioletred",
  Papayawhip = "Papayawhip",
  Peachpuff = "Peachpuff",
  Peru = "Peru",
  Pink = "Pink",
  Plum = "Plum",
  Powderblue = "Powderblue",
  Purple = "Purple",
  Rebeccapurple = "Rebeccapurple",
  Red = "Red",
  Rosybrown = "Rosybrown",
  Royalblue = "Royalblue",
  Saddlebrown = "Saddlebrown",
  Salmon = "Salmon",
  Sandybrown = "Sandybrown",
  Sasquatchsocks = "Sasquatchsocks",
  Seagreen = "Seagreen",
  Seashell = "Seashell",
  Sienna = "Sienna",
  Silver = "Silver",
  Skyblue = "Skyblue",
  Slateblue = "Slateblue",
  Slategray = "Slategray",
  Snow = "Snow",
  Springgreen = "Springgreen",
  Steelblue = "Steelblue",
  Tan = "Tan",
  Teal = "Teal",
  Thistle = "Thistle",
  Tomato = "Tomato",
  Turquoise = "Turquoise",
  Violet = "Violet",
  Wheat = "Wheat",
  White = "White",
  Whitesmoke = "Whitesmoke",
  Yellow = "Yellow",
  Violetsareblue = "Violetsareblue",
  Yellowgreen = "Yellowgreen",
  // Custom
  Beaver = "Beaver",
  Deer = "Deer",
  LemonCurry = "LemonCurry",
  GreenCrayola = "GreenCrayola",
  SilverLakeBlue = "SilverLakeBlue",
  MediumPurpleAlt = "MediumPurpleAlt",
  PersianPink = "PersianPink",
  FandangoPink = "FandangoPink",
  PaleSilver = "PaleSilver",
  DarkVanilla = "DarkVanilla",
  GoldCrayola = "GoldCrayola",
  PearlAqua = "PearlAqua",
  LightSteelBlueAlt = "LightSteelBlueAlt",
  Vodka = "Vodka",
  LightHotPink = "LightHotPink",
  MetallicPink = "MetallicPink",
  LavenderBlue = "LavenderBlue",
  PaleCornflowerBlue = "PaleCornflowerBlue",
  JordyBlue = "JordyBlue",
  JordyBlueAlt = "JordyBlueAlt",
  CornflowerBlue2 = "CornflowerBlue2",
  CornflowerBlue3 = "CornflowerBlue3",
  VeryLightBlue = "VeryLightBlue",
  RoyalBlueAlt = "RoyalBlueAlt",
  LightSilver = "LightSilver",
  LightPeriwinkle = "LightPeriwinkle",
  CadetBlueCrayola = "CadetBlueCrayola",
  CoolGrey = "CoolGrey",
  SlateGrayAlt = "SlateGrayAlt",
  OuterSpace = "OuterSpace",
  Gunmetal = "Gunmetal",
  EerieBlack = "EerieBlack",
  ForestCrayola = "ForestCrayola",
  PhilippineSilver = "PhilippineSilver",
  Vermilion = "Vermilion",
}

interface Props {
  identifier: string;
  variant: PictogramVariants;
}

function Pictogram(props: Props) {
  const { identifier, variant } = props;

  const [src, setSrc] = useState<string | null>(null);

  // Replace all unsafe for HTML attributes (ID) chars with underscore (it leaves alphanumeric chars and underscores):
  const safeForHtmlAttributeIdentifier = identifier.replace(/[\W_]+/g, "_");

  // Create a path for /workspace/public/pictograms SVG file (with replacing hash to allow URL file access):
  const loadSvg = () => {
    const url =
      `${AppConfig.getAppPath()}/pictograms/${identifier}.svg`.replace(
        "#",
        "%23",
      );
    const img = new Image();
    img.onload = () => setSrc(url);
    img.onerror = () => setSrc(null);
    img.src = url;
  };

  const afterSvgInjection = (
    error: Error | null,
    // eslint-disable-next-line no-undef
    svg?: SVGSVGElement | undefined | null,
  ): void => {
    // if unable to find SVG, it's better to throw error rather than continue executing app without pictogram
    if (error) {
      throw error;
    }

    if (!svg) {
      throw new Error("Unable to inject SVG");
    }

    if (variant === PictogramVariants.Original) return;

    let svgFill: string;
    let gradientId: string;

    // First handle gradients, then custom social-like colors, then (in default block) basic colors by their names:
    switch (variant) {
      case PictogramVariants.Instagram:
        gradientId = `pictogram-fill-gradient-instagram-${safeForHtmlAttributeIdentifier}`;

        // eslint-disable-next-line no-param-reassign
        svg.innerHTML += `
          <defs>
            <linearGradient id=${gradientId} x1="0" x2="0" y1="0" y2="1">
              <stop stop-color="#5A5DCA" />
              <stop offset="0.25" stop-color="#BB368F" />
              <stop offset="0.75" stop-color="#E13568" />
              <stop offset="1" stop-color="#F8CD78" />
            </linearGradient>
          </defs>
        `;

        svgFill = `url(#${gradientId})`;

        break;
      case PictogramVariants.TikTok:
        gradientId = `pictogram-fill-gradient-tiktok-${safeForHtmlAttributeIdentifier}`;

        // eslint-disable-next-line no-param-reassign
        svg.innerHTML += `
          <defs>
            <linearGradient id=${gradientId} x1="0" x2="0" y1="0" y2="1">
              <stop stop-color="#00F2EA" />
              <stop offset="1" stop-color="#DF0045" />
            </linearGradient>
          </defs>
        `;

        svgFill = `url(#${gradientId})`;

        break;
      case PictogramVariants.YouTube:
        svgFill = "#FF0302";

        break;
      case PictogramVariants.Twitter:
        svgFill = "#000000";

        break;
      case PictogramVariants.Facebook:
        svgFill = "#1877F2";

        break;
      case PictogramVariants.Discord:
        svgFill = "#5865F2";

        break;
      case PictogramVariants.Twitch:
        svgFill = "#9146FF";

        break;
      case PictogramVariants.Snapchat:
        svgFill = "#FFFB54";

        break;
      case PictogramVariants.Pinterest:
        svgFill = "#BD081C";

        break;
      default:
        // get enum key (color name):
        svgFill = colorNameToHex(PictogramVariants[variant].toLowerCase());

        break;
    }

    if (!svgFill) {
      throw new Error("Unable to determine svg fill");
    }

    // Get all nodes within <svg> and apply fill if required:
    const svgInnerNodes = svg.getElementsByTagName("*");

    Array.from(svgInnerNodes).forEach((node) => {
      if (node.hasAttribute("fill") && node.getAttribute("fill") !== "none") {
        node.setAttribute("fill", svgFill);
      }
    });
  };

  useEffect(() => {
    loadSvg();
  }, [safeForHtmlAttributeIdentifier, identifier, afterSvgInjection]);

  return (
    // Additional wrapper allows rendering pictograms with dynamic src inside containers with multiple children
    <span className="pictogram">
      {src ? (
        // We are using span wrapper, because we need to place these SVGs inline (as they normally do) - by default it's div
        <ReactSVG src={src} wrapper="span" afterInjection={afterSvgInjection} />
      ) : null}
    </span>
  );
}

export default Pictogram;
