import React, { useEffect, useState } from "react";
import "./PerformanceFiltersButton.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as FilterIcon } from "src/images/filter.svg";
import classNames from "classnames";
import { IPerformanceFilters } from "../../PerformanceFilters";

interface Props {
  openFilters: () => void;
  filters: IPerformanceFilters;
}

export const PerformanceFiltersButton: React.FC<Props> = (props) => {
  const { openFilters, filters } = props;

  const [filtersCount, setFiltersCount] = useState(0);

  useEffect(() => {
    let newCount = 0;

    filters.time.from && newCount++;
    filters.campaigns.length && newCount++;

    setFiltersCount(newCount);
  }, [filters]);

  return (
    <li
      className={classNames("tab tab--medium", "filter-button", {
        "filter-button--active": filtersCount > 0,
      })}
    >
      <button className="tab__button" onClick={openFilters}>
        <FilterIcon />
        <IDHFormattedMessage id="ws_filter" defaultMessage="Filter" />
        {filtersCount > 0 && `: ${filtersCount}`}
      </button>
    </li>
  );
};
