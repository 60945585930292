import React from "react";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import { SeperatedNumberInput } from "../../../components/MaskedInputs/SeperatedNumberInput";
import { PercentInput } from "../../../components/MaskedInputs/PercentInput";
import { CurrencyInput } from "../../../components/MaskedInputs/CurrencyInput";

interface Props {
  fieldType: string;
  initialDate: string;
  dateChangeHandler: (newDate: any) => void;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  onBlur: () => void;
  inputRef: React.RefObject<HTMLInputElement>;
  prefix: string;
}

export function ProjectFieldEditSwitch(props: Props) {
  const {
    fieldType,
    initialDate,
    dateChangeHandler,
    value,
    setValue,
    onBlur,
    inputRef,
    prefix,
  } = props;
  const renderSwitch = (fieldType: string) => {
    switch (fieldType) {
      case "date":
        return (
          <CustomDatePicker
            initialDate={initialDate}
            dateChangeHandler={dateChangeHandler}
          />
        );
      case "number":
        return (
          <SeperatedNumberInput
            inputRef={inputRef}
            rawValue={value}
            setRawValue={setValue}
            onBlur={onBlur}
          />
        );
      case "percent":
        return (
          <PercentInput
            inputRef={inputRef}
            rawValue={value}
            setRawValue={setValue}
            onBlur={onBlur}
          />
        );
      case "currency":
        return (
          <CurrencyInput
            inputRef={inputRef}
            rawValue={value}
            setRawValue={setValue}
            onBlur={onBlur}
            prefix={prefix}
          />
        );
      default:
        return null;
    }
  };
  return <>{renderSwitch(fieldType)}</>;
}
