import classNames from "classnames";
import React from "react";
import "./CustomCheckbox.scss";

interface Props {
  id: string;
  name: string;
  label?: string | React.ReactNode;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  required?: boolean;
  blue?: boolean;
  className?: string;
}

const CustomCheckbox: React.FC<Props> = ({
  id,
  name,
  label,
  checked,
  onChange,
  disabled,
  required,
  blue,
  className,
  ...rest
}) => (
  <div
    className={classNames("ws-checkbox", className, {
      "ws-checkbox--blue": blue,
    })}
    {...rest}
  >
    <input
      type="checkbox"
      id={id}
      name={name}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      required={required}
    />
    <label htmlFor={id}>{label && <span>{label}</span>}</label>
  </div>
);

export default CustomCheckbox;
