import React from "react";
import { CommentsBox } from "../components/ReportTemplate/components/CommentsBox/CommentsBox";

export const renderComments = (data, GridBox) => {
  const key = data.label;

  return (
    <GridBox key={key} id={key}>
      <CommentsBox data={data} />
    </GridBox>
  );
};
