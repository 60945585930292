import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EmptyTableCreatorsImg from "src/images/empty-table-creators.svg";
import EmptyTableContentImg from "src/images/empty-table-content.svg";
import EmptyTablePublicationsImg from "src/images/empty-table-publications.svg";
import EmptyTableActionsImg from "src/images/empty-table-actions.svg";
import EmptyTablePaymentsImg from "src/images/empty-table-payments.svg";
import { Button } from "src/app/components/Button/Button";
import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";
import { ReactComponent as WandIcon } from "src/images/wand.svg";
import {
  openAddCreatorsModal,
  openAddPublicationModal,
  openAddTaskModal,
} from "src/redux";
import { TaskType } from "src/types";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { openSettingsModal } from "src/app/SettingsModal/methods";
import {
  ProjectSettingsTab,
  SettingsContext,
} from "src/app/SettingsModal/enums";
import { AutomationsSection } from "src/app/SettingsModal/tabs/Automations/Automations";
import { ReactComponent as CheckIcon } from "src/images/rectangle-check-white.svg";
import { ReactComponent as CreatorIcon } from "src/images/creator.svg";
import { ReactComponent as CreatorBlueIcon } from "src/images/smile-blue.svg";
import { ExtensionsSection } from "src/app/SettingsModal/tabs/Extensions/Extensions";
import {
  clearInfluencersListInfo,
  clearResults,
} from "src/app/pages/CreatorDiscoveryTool/legacy/app/audiences-manager/audiencesManagerActions";
import ClientEmptyTable from "./components/ClientEmptyTable";

import "./EmptyTable.scss";
import { AppLocation } from "src/redux/main/mainReducer";

function EmptyTable(props) {
  const { taskType } = props;

  const {
    mainReducer: { activeWorkspaceUuid, identity },
    projectReducer: { projectBasicData },
  } = useSelector((state) => state);

  const { permissions, wsExtensions, isBarter } = projectBasicData;

  const dispatch = useDispatch();
  const history = useHistory();
  const [recruitmentForm, setRecruitmentForm] = useState();

  const handleOpenRecruitmentForm = () =>
    openSettingsModal(
      history,
      AppLocation.SingleProject,
      SettingsContext.Project,
      ProjectSettingsTab.Extensions,
      ExtensionsSection.RecruitmentForm,
    );

  const creatorDiscoverySidebarItem = identity?.sidebarItems?.find(
    (item) => item.key === "front_sidebar_item_creator_discovery",
  );

  const handleOpenCDTSearch = () => {
    dispatch(clearInfluencersListInfo());
    dispatch(clearResults());
    history.push(
      `/workspace/${activeWorkspaceUuid}/creator-discovery-tool/search`,
    );
  };

  const renderText = (taskType) => {
    switch (taskType) {
      case TaskType.Creator:
        return (
          <IDHFormattedMessage
            id="ws_empty_table_text_creator"
            defaultMessage="Create and manage Creator lists. \n Modify the table structure according to your needs."
          />
        );
      case TaskType.Content:
        return (
          <IDHFormattedMessage
            id="ws_empty_table_text_content"
            defaultMessage="Define the overall scope and brief each piece of content separately. \n Assign them to individual Creators, moderate and track progress."
          />
        );
      case TaskType.Publication:
        return (
          <IDHFormattedMessage
            id="ws_empty_table_text_publication"
            defaultMessage="Check the statistics of published content. \n Manage your results and generate reports."
          />
        );
      case TaskType.Action:
        return (
          <IDHFormattedMessage
            id="ws_empty_table_text_action"
            defaultMessage="Track progress and discuss work. Add and assign action items. \n Campaign members will know what needs to be done, which tasks are a priority, \n and when work is due."
          />
        );
      case TaskType.Payment:
        return (
          <IDHFormattedMessage
            id="ws_empty_table_text_payment"
            defaultMessage="Aggregate and manage billing-related data. \n Stay up to date with your payments."
          />
        );
      default:
        return "";
    }
  };

  const renderBottomText = (taskType) => {
    if (taskType === TaskType.Creator) {
      return (
        <div className="empty-table__cdt-link">
          <IDHFormattedMessage
            id="ws_or_manually"
            defaultMessage="or manually"
          />{" "}
          <button onClick={() => dispatch(getOpenTaskFunction(taskType))}>
            <IDHFormattedMessage
              id="ws_add_creators"
              defaultMessage="Add Creators"
            />
          </button>
        </div>
      );
    }
  };

  const renderImg = (taskType) => {
    switch (taskType) {
      case TaskType.Creator:
        return EmptyTableCreatorsImg;
      case TaskType.Content:
        return EmptyTableContentImg;
      case TaskType.Publication:
        return EmptyTablePublicationsImg;
      case TaskType.Action:
        return EmptyTableActionsImg;
      case TaskType.Payment:
        return EmptyTablePaymentsImg;
      default:
        return "";
    }
  };

  const renderBtn = (taskType) => {
    if (taskType === TaskType.Creator && isBarter) {
      return (
        <>
          {identity.permissions?.workspace.includes("creator_discovery") &&
            creatorDiscoverySidebarItem && (
              <Button
                variant="white-with-border"
                size="medium"
                className="empty-table__buttons-add__btn"
                onClick={handleOpenCDTSearch}
              >
                <CreatorBlueIcon />
                <IDHFormattedMessage
                  id="ws_use_creator_search"
                  defaultMessage="Use Creator Search"
                />
              </Button>
            )}
          <Button
            variant="blue"
            size="medium"
            className="empty-table__buttons-add__btn"
            onClick={handleOpenRecruitmentForm}
          >
            <CheckIcon />
            {recruitmentForm?.enabled ? (
              <IDHFormattedMessage
                id="ws_edit_submission_form"
                defaultMessage="Edit Submission Form"
              />
            ) : (
              <IDHFormattedMessage
                id="ws_use_submission_form"
                defaultMessage="Use Submission Form"
              />
            )}
          </Button>
        </>
      );
    }
    if (taskType === TaskType.Creator) {
      if (recruitmentForm?.enabled) {
        return (
          <>
            {identity.permissions?.workspace.includes("creator_discovery") &&
              creatorDiscoverySidebarItem && (
                <Button
                  variant="white-with-border"
                  size="medium"
                  className="empty-table__buttons-add__btn"
                  onClick={handleOpenCDTSearch}
                >
                  <CreatorBlueIcon />
                  <IDHFormattedMessage
                    id="ws_use_creator_search"
                    defaultMessage="Use Creator Search"
                  />
                </Button>
              )}
            <Button
              variant="blue"
              size="medium"
              className="empty-table__buttons-add__btn"
              onClick={handleOpenRecruitmentForm}
            >
              <CheckIcon />
              <IDHFormattedMessage
                id="ws_edit_submission_form"
                defaultMessage="Edit Submission Form"
              />
            </Button>
          </>
        );
      }
      return (
        <>
          <Button
            variant="white-with-border"
            size="medium"
            className="empty-table__buttons-add__btn"
            onClick={handleOpenRecruitmentForm}
          >
            <CheckIcon />
            <IDHFormattedMessage
              id="ws_use_submission_form"
              defaultMessage="Use Submission Form"
            />
          </Button>
          {identity.permissions?.workspace.includes("creator_discovery") &&
            creatorDiscoverySidebarItem && (
              <Button
                variant="blue"
                size="medium"
                className="empty-table__buttons-add__btn"
                onClick={handleOpenCDTSearch}
              >
                <CreatorIcon />
                <IDHFormattedMessage
                  id="ws_use_creator_search"
                  defaultMessage="Use Creator Search"
                />
              </Button>
            )}
        </>
      );
    }
    return (
      <>
        <Button
          variant={
            taskType === TaskType.Publication ? "white-with-border" : "blue"
          }
          size="medium"
          className="empty-table__buttons-add__btn"
          onClick={() => dispatch(getOpenTaskFunction(taskType))}
        >
          <PlusIcon />
          <span>
            <IDHFormattedMessage id="ws_add" defaultMessage="Add" />
            &nbsp;
            {renderBtnText(taskType)}
          </span>
        </Button>
        {taskType === TaskType.Publication &&
          permissions?.project.includes("display_project_configuration") && (
            <Button
              className="empty-table__buttons-detection"
              variant="blue"
              size="medium"
              onClick={() =>
                openSettingsModal(
                  history,
                  AppLocation.SingleProject,
                  SettingsContext.Project,
                  ProjectSettingsTab.Automations,
                  AutomationsSection.PublicationsDetection,
                )
              }
            >
              <WandIcon />
              <IDHFormattedMessage
                id="ws_activate_auto_detection"
                defaultMessage="Activate auto-detection"
              />
            </Button>
          )}
      </>
    );
  };

  const renderBtnText = (taskType) => {
    switch (taskType) {
      case TaskType.Content:
        return <IDHFormattedMessage id="ws_content" defaultMessage="Content" />;

      case TaskType.Publication:
        return (
          <IDHFormattedMessage
            id="ws_publication"
            defaultMessage="Publication"
          />
        );
      case TaskType.Action:
        return <IDHFormattedMessage id="ws_action" defaultMessage="Action" />;
      case TaskType.Payment:
        return <IDHFormattedMessage id="ws_payment" defaultMessage="Payment" />;
      default:
        return "";
    }
  };

  const getOpenTaskFunction = (taskType) => {
    switch (taskType) {
      case TaskType.Creator:
        return openAddCreatorsModal();
      case TaskType.Content:
      case TaskType.Action:
      case TaskType.Payment:
        return openAddTaskModal();
      case TaskType.Publication:
        return openAddPublicationModal();
      default:
        return "";
    }
  };

  useEffect(() => {
    if (!wsExtensions.length) return;
    const recruitmentForm = wsExtensions.find(
      (item) => item.type === "recruitmentForm",
    );
    if (!recruitmentForm) return;
    setRecruitmentForm(recruitmentForm);
  }, [wsExtensions]);

  return (
    <div className="empty-table">
      <div className="empty-table__wrapper">
        {permissions?.project.includes("task_management") ? (
          <>
            <div className="empty-table__left">
              <div className="empty-table__text">{renderText(taskType)}</div>
              <div className="empty-table__buttons">
                {permissions?.project.includes("task_management") &&
                  renderBtn(taskType)}
              </div>
              {renderBottomText(taskType)}
            </div>
            <div className="empty-table__right">
              <img src={renderImg(taskType)} className="empty-table__img" />
            </div>
          </>
        ) : (
          <ClientEmptyTable />
        )}
      </div>
    </div>
  );
}

export { EmptyTable };
