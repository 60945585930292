import React from "react";
import "./AddButton.scss";
import classNames from "classnames";
import { ReactComponent as PlusTransparentIcon } from "../../../images/plus-transparent.svg";

export enum AddButtonVariant {
  Circle = "circle",
  Rect = "rect",
  Square = "square",
}

interface Props {
  variant: "circle" | "rect" | "square";
  className?: string;
  onClick?: any; // change any
}

function AddButton(props: Props) {
  const { variant, className, onClick } = props;
  return (
    <div
      className={classNames(
        "add-button",
        {
          "add-button--circle": variant === AddButtonVariant.Circle,
          "add-button--rect": variant === AddButtonVariant.Rect,
          "add-button--square": variant === AddButtonVariant.Square,
        },
        className,
      )}
      onClick={onClick}
    >
      <PlusTransparentIcon />
    </div>
  );
}

export default AddButton;
