import moment from "moment";
import { TaskAutocomplete } from "src/redux/task/taskReducer";
import { IntlShape } from "react-intl";
import { getFullDate } from "../../utils/methods";
import { IMember } from "../project/ProjectTypes";
import { getDateLabel } from "./getDateLabel";
import { MetaFieldType } from "./getMetaFieldTypeOptions";

export const getColumnTextLabel = (
  columnType: string,
  colData: any,
  values: any,
  membersList: Array<IMember>,
  creatorAutocomplete: Array<TaskAutocomplete>,
  contentAutocomplete: Array<TaskAutocomplete>,
  publicationAutocomplete: Array<TaskAutocomplete>,
  intl: IntlShape,
) => {
  switch (columnType) {
    case MetaFieldType.Member:
      const memberData = membersList.find(
        (member: IMember) => member.id === colData.value,
      );
      return memberData?.name;

    case MetaFieldType.Date:
      const formattedValue = getDateLabel(
        "en-EN",
        moment(colData.value),
        false,
        intl,
      );

      return typeof formattedValue?.date === "string"
        ? formattedValue?.date
        : getFullDate(formattedValue?.date.toDate());

    case MetaFieldType.Creator:
      const creatorData = creatorAutocomplete?.find(
        (creator) => creator.taskId === colData.value,
      );

      return creatorData?.title;

    case MetaFieldType.Content:
      const contentData = contentAutocomplete?.find(
        (content) => content.taskId === colData.value,
      );

      return contentData?.title;

    case MetaFieldType.PublicationRelated:
      const publicationData = publicationAutocomplete?.find(
        (publication) => publication.taskId === colData.value,
      );

      return contentData?.title;

    case MetaFieldType.SingleSelect:
    case MetaFieldType.MultiSelect:
    case MetaFieldType.AggregatedSelect:
      return colData.name;

    default:
      return values.find((value: string) => {
        if (value === colData.value) {
          return colData.name;
        }
      });
  }
};
