import React, { useState, useEffect, useRef } from "react";
import "./ProfileDetails.scss";
import { useHistory, useParams } from "react-router";
import "moment-timezone";
import { Credibility } from "../Task/tabs/ProfileDataTab/single/Credibility";
import { Followers } from "../Task/tabs/ProfileDataTab/single/Followers";
import { Bio } from "../Task/tabs/ProfileDataTab/single/Bio";
import { Er } from "../Task/tabs/ProfileDataTab/single/Er";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "../components/Button/Button";
import { ReactComponent as InstagramIcon } from "../../images/credibility-tool/instagram.svg";
import { ReactComponent as CloseIcon } from "../../images/cross.svg";
import { InfluencerGrowth } from "../Task/tabs/ProfileDataTab/single/InfluencerGrowth";
import { EngagementRate } from "../Task/tabs/ProfileDataTab/single/EngagementRate";
import { AudienceData } from "../Task/tabs/ProfileDataTab/sections/AudienceData";
import { InterestsAndBrandAffinity } from "../Task/tabs/ProfileDataTab/sections/InterestsAndBrandAffinity";
import { HashtagsAndKeywords } from "../Task/tabs/ProfileDataTab/sections/HashtagsAndKeywords";

export interface ITaskParent {
  id: string;
  title: string;
  onMyTasks?: boolean;
}

function ProfileDetails(props: any) {
  const { userUuid }: { userUuid: string } = useParams();
  const { onCloseHandler, profileData } = props;
  const fixedElementRef = useRef<HTMLDivElement | null>(null);

  if (!profileData) {
    onCloseHandler();
    return <></>;
  }

  const mockErValues = {
    er: {
      er: 3.12,
      avgEngagements: 60,
      avgLikes: 60,
      avgImpressionsPerPost: 60,
      avgImpressionsPerStory: 60,
      avgRealReachPerPost: 60,
      avgRealReachPerStory: 60,
      height: 60,
      socialProvider: 60,
      avgViews: 60,
      avgComments: 60,
      avgReelsPlays: 60,
    },
  };

  const mockUserDataValue = {
    user_data: {
      user_audience_data: {
        audienceMen: 83.0,
        audienceWomen: 16.9,
        audienceAgeGroups: {
          below18: 7.6,
          "18-24": 42.5,
          "25-34": 39.3,
          "35-45": 8.7,
          above45: 1.8,
        },
        audienceGeographicalReach: {
          PL: "11.8",
          BR: "10.3",
          IT: "5.6",
          DE: "4.8",
          ID: "3.8",
        },
        audienceCityReach: [
          {
            countryCode: "BR",
            name: "São Paulo",
            percent: "1.8",
          },
          {
            countryCode: "PL",
            name: "Warsaw",
            percent: "0.9",
          },
          {
            countryCode: "BR",
            name: "Rio de Janeiro",
            percent: "0.8",
          },
          {
            countryCode: "EG",
            name: "Cairo",
            percent: "0.8",
          },
          {
            countryCode: "NG",
            name: "Lagos",
            percent: "0.6",
          },
          {
            countryCode: "TR",
            name: "Istanbul",
            percent: "0.5",
          },
          {
            countryCode: "CL",
            name: "Santiago",
            percent: "0.5",
          },
          {
            countryCode: "US",
            name: "New York City",
            percent: "0.5",
          },
          {
            countryCode: "GB",
            name: "London",
            percent: "0.5",
          },
        ],
        credibility: 73.53,
        notable: 9.87,
        gendersPerAge: {
          male: [
            {
              value: 7.08,
              age: "below18",
            },
            {
              value: 41.730000000000004,
              age: "18-24",
            },
            {
              value: 40.489999999999995,
              age: "25-34",
            },
            {
              value: 8.93,
              age: "35-44",
            },
            {
              value: 1.7700000000000102,
              age: "above45",
            },
          ],
          female: [
            {
              value: 10.290000000000001,
              age: "below18",
            },
            {
              value: 46.410000000000004,
              age: "18-24",
            },
            {
              value: 33.53,
              age: "25-34",
            },
            {
              value: 7.000000000000001,
              age: "35-44",
            },
            {
              value: 2.769999999999996,
              age: "above45",
            },
          ],
        },
        influencersBrandAffinity: [
          {
            name: "Dolce & Gabbana",
          },
          {
            name: "Huawei",
          },
          {
            name: "Chopard",
          },
        ],
        influencersInterests: [
          {
            name: "Luxury Goods",
          },
          {
            name: "Friends, Family & Relationships",
          },
          {
            name: "Jewellery & Watches",
          },
          {
            name: "Electronics & Computers",
          },
          {
            name: "Sports",
          },
        ],
        audienceLanguages: [
          {
            code: "EN",
            value: 26.694699999999997,
            languageName: "English",
          },
          {
            code: "ES",
            value: 13.7385,
            languageName: "Spanish",
          },
          {
            code: "PL",
            value: 10.8504,
            languageName: "Polish",
          },
          {
            code: "PT",
            value: 10.469299999999999,
            languageName: "Portuguese",
          },
          {
            code: "AR",
            value: 9.4264,
            languageName: "Arabic",
          },
          {
            code: "DE",
            value: 5.2347,
            languageName: "German",
          },
          {
            code: "FR",
            value: 4.372199999999999,
            languageName: "French",
          },
          {
            code: "IT",
            value: 4.3522,
            languageName: "Italian",
          },
        ],
        brandAffinity: [
          {
            velocity_index_value: 90.12,
            name: "Nike",
            value: 9.6955,
          },
          {
            velocity_index_value: 87.46,
            name: "Trip",
            value: 9.074,
          },
          {
            velocity_index_value: 84,
            name: "Adidas",
            value: 8.266,
          },
          {
            velocity_index_value: 73.88,
            name: "Apple",
            value: 5.9043,
          },
          {
            velocity_index_value: 58.3,
            name: "FIFA",
            value: 4.1640999999999995,
          },
          {
            velocity_index_value: 33.93,
            name: "BMW",
            value: 2.4239,
          },
          {
            velocity_index_value: 33.93,
            name: "Starbucks",
            value: 2.4239,
          },
          {
            velocity_index_value: 32.19,
            name: "Audi",
            value: 2.2996,
          },
          {
            velocity_index_value: 32.19,
            name: "Puma",
            value: 2.2996,
          },
          {
            velocity_index_value: 29.58,
            name: "Travelgram",
            value: 2.1131,
          },
        ],
        audienceInterests: [
          {
            name: "Sports",
            value: 37.8496,
          },
          {
            name: "Friends, Family & Relationships",
            value: 35.674299999999995,
          },
          {
            name: "Travel, Tourism & Aviation",
            value: 26.165300000000002,
          },
          {
            name: "Clothes, Shoes, Handbags & Accessories",
            value: 24.5494,
          },
          {
            name: "Camera & Photography",
            value: 24.238699999999998,
          },
          {
            name: "Cars & Motorbikes",
            value: 20.6339,
          },
          {
            name: "Restaurants, Food & Grocery",
            value: 20.136699999999998,
          },
          {
            name: "Toys, Children & Baby",
            value: 16.2834,
          },
          {
            name: "Music",
            value: 15.848400000000002,
          },
          {
            name: "Activewear",
            value: 15.5998,
          },
        ],
        paidPostPerformance: 52.62,
      },
      keywords: {
        person: 120,
        people: 85,
        clothing: 35,
        soccer: 48,
        ball: 31,
        tie: 41,
        beverage: 22,
        helmet: 74,
      },
      hashtags: {
        ad: 10,
        test: 7,
        Polska: 4,
        "123": 3,
        USA: 3,
        "564564": 2,
        fifa: 2,
        UCL: 2,
      },
      erStatisticsData: {
        "2022-12-1": 2.3,
        "2023-1-1": 2.3,
        "2023-2-1": 2.3,
        "2023-3-1": 2.3,
        "2023-4-1": 2.3,
        "2023-5-1": 2.4,
        "2023-6-1": 2.5,
        "2023-7-1": 2.5,
        "2023-8-1": 2.5,
        "2023-9-1": 2.5,
        "2023-10-1": 2.5,
        "2023-11-1": 2.6,
      },
      viewsStatisticsData: {
        "2022-12-1": 0,
        "2023-1-1": 0,
        "2023-2-1": 0,
        "2023-3-1": 0,
        "2023-4-1": 0,
        "2023-5-1": 0,
        "2023-6-1": 0,
        "2023-7-1": 0,
        "2023-8-1": 0,
        "2023-9-1": 0,
        "2023-10-1": 0,
        "2023-11-1": 0,
      },
      followersStatisticsData: {
        "2022-12-1": 31624249,
        "2023-1-1": 31624249,
        "2023-2-1": 31624249,
        "2023-3-1": 31624249,
        "2023-4-1": 31624249,
        "2023-5-1": 33893287,
        "2023-6-1": 34343295,
        "2023-7-1": 34343295,
        "2023-8-1": 34343295,
        "2023-9-1": 34343295,
        "2023-10-1": 34343295,
        "2023-11-1": 35605755,
      },
    },
  };
  const taskContainerRef = useRef<any>(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    // Function to handle clicks outside the fixed element
    const handleClickOutside = (event: MouseEvent) => {
      if (fixedElementRef.current && event.target instanceof Node) {
        // Ensure fixedElementRef.current exists and verify event.target is a Node
        if (!fixedElementRef.current.contains(event.target)) {
          onCloseHandler();
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formatNumber = (number: number) => {
    const thousand = 1000;
    const million = 1000000;
    const billion = 1000000000;

    if (number >= billion) {
      return `${(number / billion).toFixed(2)}B`;
    }
    if (number >= million) {
      return `${(number / million).toFixed(2)}M`;
    }
    if (number >= thousand) {
      return `${(number / thousand).toFixed(0)}K`;
    }
    return number.toString();
  };

  return (
    <div ref={fixedElementRef} className="profile-details">
      <div
        className="profile-details__container scroll-fixed"
        ref={taskContainerRef}
      >
        <div className="profile-details__container__fixed-header">
          <div className="profile-details__person">
            <div className="person-profile-picture">
              <div className="custom-image-wrapper person-profile-picture__photo">
                <img src={profileData.img} />
              </div>
            </div>
            <div className="person-info">
              <div className="person-info-username">{profileData.profile}</div>
              {profileData.gender && (
                <div className="person-info-description">
                  {profileData.gender}, {profileData.age} {profileData.city},{" "}
                  {profileData.country}
                </div>
              )}
              <div className="person-info-social-icon">
                <InstagramIcon />
                {formatNumber(profileData.followers)}
              </div>
            </div>
            <div className="person-info-close-button" onClick={onCloseHandler}>
              <CloseIcon />
            </div>
          </div>
          <div className="profile-details__profile-box-row__without-margin">
            <div className="profile-details__upgrade-account">
              <div className="profile-details__upgrade-account-info">
                <IDHFormattedMessage
                  id="ws_credibility_tool_header"
                  defaultMessage="Want to see all the details of this and other profiles? Upgrade your account right now."
                />
              </div>
              <Button
                variant="orange"
                onClick={() =>
                  (window.location.href = userUuid
                    ? `/workspace/trial?fccId=${userUuid}`
                    : "/workspace/trial")
                }
              >
                <IDHFormattedMessage
                  id="ws_upgrade_account"
                  defaultMessage="Upgrade account"
                />
              </Button>
            </div>
          </div>
        </div>
        <div className="profile-details__container__inner-wrap">
          <div className="profile-details__profile-box-container">
            <div className="profile-data-tab__section-header">
              <IDHFormattedMessage
                id="ws_credibility_tool_profile_data"
                defaultMessage="Profile data"
              />
            </div>
            <div className="profile-details__profile-box-row">
              <Credibility credibility={profileData.credibility} />
            </div>

            <div className="profile-details__profile-box-row">
              <div className="profile-details__profile-box-column">
                <Bio description={profileData.bio} />
                <Followers
                  followers={profileData.followers}
                  notableUsers={null}
                  paidPostPerformance={12}
                />
              </div>
              <div className="profile-details__profile-box-column__shadow">
                <Er
                  er={mockErValues.er}
                  avgEngagements={mockErValues.er.avgEngagements}
                  avgLikes={mockErValues.er.avgLikes}
                  avgImpressionsPerPost={mockErValues.er.avgImpressionsPerPost}
                  avgImpressionsPerStory={
                    mockErValues.er.avgImpressionsPerStory
                  }
                  avgRealReachPerPost={mockErValues.er.avgRealReachPerPost}
                  avgRealReachPerStory={mockErValues.er.avgRealReachPerStory}
                  socialProvider={mockErValues.er.socialProvider}
                  avgViews={mockErValues.er.avgViews}
                  avgComments={mockErValues.er.avgComments}
                  avgReelsPlays={mockErValues.er.avgReelsPlays}
                />
              </div>
            </div>
            <div className="profile-details__profile-box-column__shadow">
              <InfluencerGrowth
                followersStatisticsData={
                  mockUserDataValue.user_data.followersStatisticsData
                }
                followers={profileData.followers}
              />
              <EngagementRate
                er={mockErValues.er}
                erStatisticsData={mockUserDataValue.user_data.erStatisticsData}
              />
            </div>
            <div className="profile-details__profile-box-column__shadow">
              <AudienceData profileData={mockUserDataValue.user_data} />
              <InterestsAndBrandAffinity
                profileData={mockUserDataValue.user_data}
              />
              <HashtagsAndKeywords profileData={mockUserDataValue.user_data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileDetails;
