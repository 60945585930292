import React, { PropsWithChildren } from "react";

import XssFilter from "src/app/components/XssFilter/XssFilter";
import { decodeHyperlinks, decodeMention } from "src/utils/methods";
import { IMember } from "src/app/project/ProjectTypes";

export const decodeComment = (_comment: string, _membersList: IMember[]) => {
  const decodedValue = decodeMention(
    decodeHyperlinks(_comment),
    _membersList,
    true,
  );

  return XssFilter(
    decodedValue,
    ["span", "br", "a", "b", "i"],
    ["target", "class", "rel", "contenteditable"],
  );
};

interface CommentTextProps {}

export default function CommentText({
  children,
}: PropsWithChildren<CommentTextProps>) {
  return <div className="comment__text">{children}</div>;
}
