import React, { useState } from "react";
import "./SingleSelectableLabel.scss";

import { ReactComponent as PlusGreyIcon } from "src/images/plus-grey.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import { getPercentOptions } from "src/app/pages/CreatorDiscoveryTool/legacy/app/audiences-manager/AudiencesSearch/utils/getPercentOptions";
import { useIntl } from "react-intl";
import classNames from "classnames";

export function SingleSelectableLabel({
  value,
  onClick,
  translative,
  selectDensity,
  setLockScroll,
  disabled,
}) {
  const [showDensitySelector, setShowDensitySelector] = useState(false);

  const intl = useIntl();

  const handleClick = () => {
    if (selectDensity) {
      setShowDensitySelector(true);
      setLockScroll(true);
      return;
    }

    onClick(value);
  };

  const handleMenuClose = () => {
    setShowDensitySelector(false);
    setLockScroll(false);
  };

  const handleSelectDensity = async (density) => {
    const selectedCountry = {
      ...value,
      percent: density.value,
    };
    await onClick(selectedCountry);
    setShowDensitySelector(false);
    setLockScroll(false);
  };

  const renderValue = () => {
    if (translative)
      return (
        <IDHFormattedMessage id={value.name} defaultMessage={value.value} />
      );

    if (selectDensity) return value.label;

    return value;
  };

  return (
    <div
      className={classNames("select-tags__selectable-labels__single", {
        "select-tags__selectable-labels__single--active": showDensitySelector,
        "select-tags__selectable-labels__single--disabled": disabled,
      })}
      onClick={handleClick}
      data-filter-name="select_density"
    >
      {renderValue()}
      <PlusGreyIcon
        className="select-tags__selectable-labels__single__add"
        height={14}
        width={14}
      />

      {showDensitySelector && (
        <CustomSelect
          style={{ display: "none" }}
          placeholder={intl.formatMessage({
            id: "front_min_density",
            defaultMessage: "Min density",
          })}
          forceOpen
          value={{}}
          options={getPercentOptions(70)}
          onMenuClose={handleMenuClose}
          onChange={(newValue) => handleSelectDensity(newValue)}
        />
      )}
    </div>
  );
}
