import React from "react";
import InfoIcon from "src/images/info.svg";
import InfoBlueIcon from "src/images/info-blue.svg";
import classNames from "classnames";

interface Props {
  className?: string;
  blue?: boolean;
  handleClick?: () => void;
  rest?: any;
}

const TooltipSign: React.FC<Props> = (props) => {
  const { className, blue, handleClick, rest } = props;

  return (
    <img
      src={blue ? InfoBlueIcon : InfoIcon}
      className={classNames("tooltip-sign", className)}
      role="presentation"
      onClick={handleClick}
      {...rest}
    />
  );
};

export default TooltipSign;
