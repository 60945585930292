import React from "react";

import Skeleton, {
  SkeletonCircle,
  SkeletonRect,
} from "src/app/components/Skeleton/Skeleton";

function SkeletonLoader({ avatarShape }: { avatarShape: "rect" | "circle" }) {
  return (
    <div className="activity-tab__skeleton-wrapper">
      <div className="activity-tab__skeleton-item">
        <div className="activity-tab__skeleton-avatar-and-text">
          {avatarShape === "rect" ? (
            <SkeletonRect
              width={skeletonAvatarSize}
              height={skeletonItemHeight}
            />
          ) : (
            <SkeletonCircle size={skeletonAvatarSize} />
          )}
          <Skeleton width={nameColumnWidth} height={skeletonItemHeight} />
        </div>
        <Skeleton width={dateColumnWidth} height={skeletonItemHeight} />
        <Skeleton width={dateColumnWidth} height={skeletonItemHeight} />
        <Skeleton width={actionColumnWidth} height={skeletonItemHeight} />
      </div>

      <div className="activity-tab__skeleton-item">
        <div className="activity-tab__skeleton-avatar-and-text">
          {avatarShape === "rect" ? (
            <SkeletonRect
              width={skeletonAvatarSize}
              height={skeletonItemHeight}
            />
          ) : (
            <SkeletonCircle size={skeletonAvatarSize} />
          )}
          <Skeleton width={nameColumnWidth} height={skeletonItemHeight} />
        </div>
        <Skeleton width={dateColumnWidth} height={skeletonItemHeight} />
        <Skeleton width={dateColumnWidth} height={skeletonItemHeight} />
        <Skeleton width={actionColumnWidth} height={skeletonItemHeight} />
      </div>

      <div className="activity-tab__skeleton-item">
        <div className="activity-tab__skeleton-avatar-and-text">
          {avatarShape === "rect" ? (
            <SkeletonRect
              width={skeletonAvatarSize}
              height={skeletonItemHeight}
            />
          ) : (
            <SkeletonCircle size={skeletonAvatarSize} />
          )}
          <Skeleton width={nameColumnWidth} height={skeletonItemHeight} />
        </div>
        <Skeleton width={dateColumnWidth} height={skeletonItemHeight} />
        <Skeleton width={dateColumnWidth} height={skeletonItemHeight} />
        <Skeleton width={actionColumnWidth} height={skeletonItemHeight} />
      </div>

      <div className="activity-tab__skeleton-item">
        <div className="activity-tab__skeleton-avatar-and-text">
          {avatarShape === "rect" ? (
            <SkeletonRect
              width={skeletonAvatarSize}
              height={skeletonItemHeight}
            />
          ) : (
            <SkeletonCircle size={skeletonAvatarSize} />
          )}
          <Skeleton width={nameColumnWidth} height={skeletonItemHeight} />
        </div>
        <Skeleton width={dateColumnWidth} height={skeletonItemHeight} />
        <Skeleton width={dateColumnWidth} height={skeletonItemHeight} />
        <Skeleton width={actionColumnWidth} height={skeletonItemHeight} />
      </div>
    </div>
  );
}

const nameColumnWidth = 446;
const dateColumnWidth = 80;
const actionColumnWidth = 44;
const skeletonItemHeight = 32;
const skeletonAvatarSize = 32;

export {
  nameColumnWidth,
  dateColumnWidth,
  actionColumnWidth,
  skeletonItemHeight,
  skeletonAvatarSize,
  SkeletonLoader,
};
