export interface PublicationCopy {
  url: string;
  platform: PublicationTypeEnum;
  status: PublicationStatus;
}

export interface Publication {
  publicationCopy: PublicationCopy | null;
}

export enum PublicationStatus {
  Fresh = 0,
  Verified = 1,
  Error = 2,
  NotFound = 3,
  Restricted = 4,
  Private = 5,
}

export enum PublicationTypeEnum {
  INSTAGRAM_POST = "instagram",
  INSTAGRAM_REEL = "instagramReels",
  INSTAGRAM_STORY = "instagramStory",
  TIKTOK_POST = "tiktok",
  YOUTUBE_VIDEO = "youtubeVideo",
  YOUTUBE_SHORT = "youtubeShort",
  FACEBOOK_POST = "facebook",
  SNAPCHAT_STORY = "snapchatStory",
  SNAPCHAT_SPOTLIGHT = "snapchatSpotlight",
  DISCORD_POST = "discordPost",
  TWITTER_POST = "twitterPost",
  TWITCH_VIDEO = "twitchVideo",
}
