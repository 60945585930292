import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as ERIcon } from "../../../../../images/profile-data/er.svg";
import { SocialProvider } from "../../../../../types";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import ProfileBox, {
  PBContent,
  PBHeader,
  PBParam,
} from "../components/ProfileBox/ProfileBox";

export function Er(props) {
  const {
    er,
    avgEngagements,
    avgLikes,
    avgViews,
    avgComments,
    avgReelsPlays,
    avgImpressionsPerPost,
    avgImpressionsPerStory,
    avgRealReachPerPost,
    avgRealReachPerStory,
    avgRealReachPerPostToFollowers,
    avgRealReachPerStoryToFollowers,
    height,
    socialProvider,
    adaptiveHeight,
  } = props;

  const showContent = !height || height > 1;

  return (
    <ProfileBox adaptiveHeight={adaptiveHeight}>
      <PBHeader
        icon={<ERIcon />}
        count={`${er}%`}
        title={<IDHFormattedMessage id="ws_er" defaultMessage="ER" />}
        onShowcase={height}
      />
      {showContent && (
        <PBContent column>
          <PBParam
            name={
              <IDHFormattedMessage
                id="ws_avg_engagements"
                defaultMessage="Avg. Engagements"
              />
            }
            tooltip={
              <Tooltip
                center
                content={
                  <IDHFormattedMessage
                    id="ws_tooltip_er"
                    defaultMessage="Average number of interactions (likes, comments, shares, saves etc) per one published post"
                  />
                }
              />
            }
            firstValue={avgEngagements}
            numberOfParams={1}
          />

          {/* TODO: create socialProviders enum */}
          {socialProvider === SocialProvider.Youtube && (
            <PBParam
              name={
                <IDHFormattedMessage
                  id="ws_avg_views"
                  defaultMessage="Avg. Views"
                />
              }
              tooltip={
                <Tooltip
                  center
                  content={
                    <IDHFormattedMessage
                      id="ws_tooltip_avg_views"
                      defaultMessage="Average views per post"
                    />
                  }
                />
              }
              firstValue={avgViews}
              numberOfParams={1}
            />
          )}

          <PBParam
            name={
              <IDHFormattedMessage
                id="ws_avg_likes"
                defaultMessage="Avg. Likes"
              />
            }
            tooltip={
              <Tooltip
                center
                content={
                  <IDHFormattedMessage
                    id="ws_tooltip_avg_likes"
                    defaultMessage="Average number of likes per post"
                  />
                }
              />
            }
            firstValue={avgLikes}
            numberOfParams={1}
          />

          {socialProvider === SocialProvider.Youtube && (
            <PBParam
              name={
                <IDHFormattedMessage
                  id="ws_avg_comments"
                  defaultMessage="Avg. Comments"
                />
              }
              tooltip={
                <Tooltip
                  center
                  content={
                    <IDHFormattedMessage
                      id="ws_tooltip_avg_comments"
                      defaultMessage="Average number of comments per post"
                    />
                  }
                />
              }
              firstValue={avgComments}
              numberOfParams={1}
            />
          )}

          {socialProvider === SocialProvider.Instagram && (
            <PBParam
              name={
                <IDHFormattedMessage
                  id="ws_avg_impressions"
                  defaultMessage="Avg. Impressions"
                />
              }
              tooltip={
                <Tooltip
                  center
                  content={
                    <div>
                      <div className="profile-data-tab__tooltip-header">
                        <IDHFormattedMessage
                          id="ws_tooltip_avg_impressions_1"
                          defaultMessage="Avg. Impressions per post"
                        />
                      </div>
                      <div className="profile-data-tab__tooltip-first-part">
                        <IDHFormattedMessage
                          id="ws_tooltip_avg_impressions_2"
                          defaultMessage="Summed up number of all posts and views on each of the
                          last 28 days, divided by the number of pieces published
                          in same time period."
                        />
                      </div>
                      <div className="profile-data-tab__tooltip-header">
                        <IDHFormattedMessage
                          id="ws_tooltip_avg_impressions_3"
                          defaultMessage="Avg. Impressions per story"
                        />
                      </div>
                      <IDHFormattedMessage
                        id="ws_tooltip_avg_impressions_4"
                        defaultMessage="Impressions Avg. count (number of story views) of each
                          available story published, divided by the number of those
                          publications."
                      />
                    </div>
                  }
                />
              }
              firstValue={avgImpressionsPerPost}
              thirdValue={avgImpressionsPerStory}
              firstValueType="per post"
              secondValueType="per story"
              numberOfParams={2}
            />
          )}

          {socialProvider === SocialProvider.Instagram && (
            <>
              <PBParam
                name={
                  <IDHFormattedMessage
                    id="ws_avg_real_reach"
                    defaultMessage="Avg. Real Reach"
                  />
                }
                tooltip={
                  <Tooltip
                    center
                    content={
                      <div>
                        <div className="profile-data-tab__tooltip-header">
                          <IDHFormattedMessage
                            id="ws_tooltip_avg_real_reach_1"
                            defaultMessage="Avg. Real reach per post"
                          />
                        </div>
                        <div className="profile-data-tab__tooltip-first-part">
                          <IDHFormattedMessage
                            id="ws_tooltip_avg_real_reach_2"
                            defaultMessage="Summed up unique posts views on each of the last 28
                            days (real reach) divided by the number of pieces
                            published in same time period."
                          />
                        </div>
                        <div className="profile-data-tab__tooltip-header">
                          <IDHFormattedMessage
                            id="ws_tooltip_avg_real_reach_3"
                            defaultMessage="Avg. Real reach per story"
                          />
                        </div>
                        <IDHFormattedMessage
                          id="ws_tooltip_avg_real_reach_4"
                          defaultMessage="Summed up Real reach (number of unique story views) of
                            each available (detectable and returned via API) story
                            published, divided by the number of those publications."
                        />
                      </div>
                    }
                  />
                }
                firstValue={avgRealReachPerPost}
                secondValue={
                  avgRealReachPerPostToFollowers > 0 &&
                  `${avgRealReachPerPostToFollowers}%`
                }
                thirdValue={avgRealReachPerStory}
                fourthValue={
                  avgRealReachPerStoryToFollowers > 0 &&
                  `${avgRealReachPerStoryToFollowers}%`
                }
                firstValueType="per post"
                secondValueType="per story"
                numberOfParams={2}
              />

              {avgReelsPlays && (
                <PBParam
                  name={
                    <IDHFormattedMessage
                      id="ws_avg_reels_plays"
                      defaultMessage="Avg. Reels Plays"
                    />
                  }
                  tooltip={
                    <Tooltip
                      center
                      content={
                        <IDHFormattedMessage
                          id="ws_tooltip_avg_reels_plays"
                          defaultMessage="The average sum of reels plays on the last 30 posts."
                        />
                      }
                    />
                  }
                  firstValue={avgReelsPlays}
                  numberOfParams={1}
                />
              )}
            </>
          )}
        </PBContent>
      )}
    </ProfileBox>
  );
}
