import React from "react";

import { useSelector } from "react-redux";
import LazyLoad from "react-lazy-load";

import { ReactComponent as TrashcanIcon } from "src/images/trash-can.svg";
import { TooltipedEllipsis } from "src/app/components/TooltipedEllipsis/TooltipedEllipsis";
import { StackedAvatars } from "src/app/components/StackedAvatars/StackedAvatars";

function RecruitmentFormNotificationMemberListItem({
  member,
  setShowConfirmDeleteMemberModal,
  setDeleteMemberData,
}) {
  const {
    mainReducer: { identity },
  } = useSelector((state) => state);

  return (
    <div
      className="notification-members__user-list-item"
      data-qa={member.email}
      key={member.uuid}
    >
      <div className="notification-members__user-list-item-left">
        <LazyLoad className="notification-members__user-list-item-avatar-wrapper">
          {member.type === "team" ? (
            <StackedAvatars
              avatars={
                member.members?.map((memberRow) => ({
                  url: memberRow?.avatarUrl ?? null,
                })) ?? []
              }
              maxAvatars={5}
            />
          ) : (
            <StackedAvatars
              avatars={[{ url: member?.avatarUrl }]}
              maxAvatars={1}
            />
          )}
        </LazyLoad>
        <div className="notification-members__user-list-item-info">
          <div className="notification-members__user-list-item-name">
            <TooltipedEllipsis content={member.name} maxWidth={290} />
            {member.type === "member" && (
              <TooltipedEllipsis
                content={member.email}
                className="notification-members__user-list-item-email"
                maxWidth={290}
              />
            )}
          </div>
        </div>
      </div>
      <div className="notification-members__user-list-item-right">
        {identity.id !== member.wsMemberUuid ? (
          <TrashcanIcon
            onClick={() => {
              setShowConfirmDeleteMemberModal(true);
              setDeleteMemberData(member);
            }}
            className="notification-members__user-list-item-trashcan"
          />
        ) : (
          <TrashcanIcon className="notification-members__user-list-item-trashcan members__user-list-item-trashcan--disabled" />
        )}
      </div>
    </div>
  );
}

export default RecruitmentFormNotificationMemberListItem;
