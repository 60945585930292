import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import "./SortingTasksArrow.scss";
import { ReactComponent as ArrowIcon } from "../../../../../../images/arrow-down.svg";
import { setSortingDirection } from "../../../../../../redux";

export function SortingTasksArrow() {
  const dispatch = useDispatch();
  const { sortingDirection } = useSelector((state) => state.taskFiltersReducer);

  const sortingStates = ["sortingDescending", "sortingAscending"];
  const [count, setCount] = useState(
    sortingStates.indexOf(sortingDirection) >= 0
      ? sortingStates.indexOf(sortingDirection)
      : 0,
  );

  useEffect(() => {
    dispatch(setSortingDirection(sortingStates[count]));
  }, [count]);

  const increaseCounter = () => {
    count < sortingStates.length - 1
      ? setCount((prev) => prev + 1)
      : setCount(0);
  };
  return (
    <div className="sortingTasksArrow__wrapper">
      <ArrowIcon
        className={classNames(
          "sortingTasksArrow",
          `sortingTasksArrow--${sortingStates[count]}`,
        )}
        onClick={(e) => {
          e.preventDefault();
          increaseCounter();
        }}
      />
    </div>
  );
}
