import React, { useState } from "react";
import "./UserInfo.scss";
import ImgChecker from "src/app/components/ImgChecker/ImgChecker";
import classNames from "classnames";
import Flag from "src/app/components/Flag/Flag";
import { MultipleSocialProviders } from "../../../../components/MultipleSocialProviders/MultipleSocialProviders";

export function UserInfo({ userData }) {
  const { gender, age, country, city } = userData;

  const [imageError, setImageError] = useState(false);
  return (
    <div className="profile-data-pane__user-info">
      <div
        className={classNames([
          "profile-data-pane__user-info__img",
          imageError && "profile-data-pane__user-info__img--error",
        ])}
        style={{ backgroundImage: `url(${userData.profilePictureUrl})` }}
      >
        <ImgChecker
          src={userData.profilePictureUrl}
          className="profile-data-pane__user-info__img"
          onError={() => setImageError(true)}
        />
        {userData.country && <Flag countryCode={userData.country} />}
      </div>
      <div className="profile-data-pane__user-info__data">
        <div className="profile-data-pane__user-info__header">
          {userData.username}
        </div>
        <div className="profile-data-pane__user-info__subheader">
          <span>
            {gender === 2 ? "Female" : "Male"}
            {age > 0 && `, ${age}`}
          </span>
          <span>
            {city}, {country}
          </span>
        </div>
        <MultipleSocialProviders
          currentSocialProvider={userData.socialProvider}
          socialProviders={userData.socialProviders}
        />
      </div>
    </div>
  );
}
