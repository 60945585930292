import React, { useState } from "react";
import { useIntl } from "react-intl";

import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import { translateMessage } from "src/app/methods/translateMessage";
import { getPercentOptions } from "../../utils/getPercentOptions";
import METHODS from "../../../../helpers/methods";

const OPTIONS_FROM_INITIAL = [
  1000, 5000, 10000, 25000, 50000, 100000, 250000, 500000, 1000000,
];
const OPTIONS_TO_INITIAL = [
  5000, 10000, 25000, 50000, 100000, 250000, 500000, 1000000,
];

const generateOptions = (data) => {
  const resultArray = [...data];
  return resultArray.map((item) => {
    return {
      value: item,
      label: METHODS.splitNumbers(item),
    };
  });
};

const sortOptions = (options) => {
  const sortedOptions = [...options];
  return sortedOptions.sort((a, b) => a.value - b.value);
};

const markOptionsAsDisabled = (options, range, selectedOpposed) => {
  return options.map((item) => {
    return {
      ...item,
      isDisabled:
        range === "from" && selectedOpposed
          ? item.value >= selectedOpposed?.value
          : item.value <= selectedOpposed?.value,
    };
  });
};

export default function FollowersCountRange({
  influencerFollowersFrom,
  setInfluencerFollowersFrom,
  influencerFollowersTo,
  setInfluencerFollowersTo,
  influencerSocialUsername,
  paidPostPerformanceMin,
  setPaidPostPerformanceMin,
  audienceCredibilityScore,
  setAudienceCredibilityScore,
  influencerSocialProvider,
}) {
  const [rangeFromOptions, setRangeFromOptions] = useState(
    generateOptions(OPTIONS_FROM_INITIAL),
  );
  const [rangeToOptions, setRangeToOptions] = useState(
    generateOptions(OPTIONS_TO_INITIAL),
  );

  const intl = useIntl();

  /** newValue: string | number */
  const isNewValueValid = (newValue) => {
    const newValueNumber = Number(newValue);
    if (typeof newValueNumber === "number" && !Number.isNaN(newValueNumber)) {
      if (newValueNumber > 0 && newValueNumber <= Number.MAX_SAFE_INTEGER) {
        return true;
      }
    }
    return false;
  };

  /** newValue: string */
  const handleCreateNewOption = (newValue, range) => {
    if (isNewValueValid(newValue)) {
      const newValueNumber = Number(newValue);
      const newOption = {
        value: newValueNumber,
        label: METHODS.splitNumbers(newValueNumber),
      };
      if (range === "from") {
        setRangeFromOptions((prev) => [...prev, newOption]);

        if (influencerFollowersTo) {
          if (newValueNumber >= influencerFollowersTo.value) {
            setInfluencerFollowersFrom({
              value: influencerFollowersTo.value - 1,
              label: METHODS.splitNumbers(influencerFollowersTo.value - 1),
            });
          } else {
            setInfluencerFollowersFrom(newOption);
          }
        } else {
          setInfluencerFollowersFrom(newOption);
        }
      }
      if (range === "to") {
        setRangeToOptions((prev) => [...prev, newOption]);

        if (influencerFollowersFrom) {
          if (newValueNumber <= influencerFollowersFrom.value) {
            setInfluencerFollowersTo({
              value: influencerFollowersFrom.value + 1,
              label: METHODS.splitNumbers(influencerFollowersFrom.value + 1),
            });
          } else {
            setInfluencerFollowersTo(newOption);
          }
        } else {
          setInfluencerFollowersTo(newOption);
        }
      }
    }
  };

  return (
    <div className="audience-search__field-box">
      <div className="audience-search__field-wrap">
        <div className="audience-search__field">
          <div className="idh-label idh-label--with-tooltip">
            <IDHFormattedMessage
              id="front_followers_count"
              defaultMessage="Followers count"
            />
            <MaterialTooltip
              id="front_followers_count_tooltip"
              content={
                <IDHFormattedMessage
                  id="front_followers_count_tooltip"
                  defaultMessage="Here, you can specify the influencer’s reach."
                />
              }
            />
          </div>
          <div className="audience-search__field">
            <div className="audience-search__field-columns">
              <div className="audience-search__field-col-1">
                <div className="input-wrapper">
                  <div className="input-wrapper__range">
                    <IDHFormattedMessage
                      id="front_from"
                      defaultMessage="From"
                    />
                  </div>
                  <div
                    className="input-wrapper__item"
                    data-filter-name="followers_from"
                  >
                    <CustomSelect
                      creatable
                      placeholder={intl.formatMessage({
                        id: "front_select_followers_from",
                        defaultMessage: "Select",
                      })}
                      isClearable
                      value={influencerFollowersFrom}
                      options={sortOptions(
                        markOptionsAsDisabled(
                          rangeFromOptions,
                          "from",
                          influencerFollowersTo,
                        ),
                      )}
                      onCreateOption={(newValue) =>
                        handleCreateNewOption(newValue, "from")
                      }
                      onChange={(newValue) => {
                        if (newValue && isNewValueValid(newValue.value)) {
                          setInfluencerFollowersFrom(newValue);
                        }
                        if (newValue === null) {
                          setInfluencerFollowersFrom(null);
                        }
                      }}
                      isDisabled={influencerSocialUsername.length}
                      dataAttr="influencer-followers-from"
                      formatCreateLabel={(inputText) =>
                        `${translateMessage({
                          intl,
                          id: "front_add",
                          defaultMessage: "Add",
                        })} ${inputText}`
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="audience-search__field-col-1">
                <div className="input-wrapper">
                  <div className="input-wrapper__range">
                    <IDHFormattedMessage id="front_to" defaultMessage="To" />
                  </div>
                  <div
                    className="input-wrapper__item"
                    data-filter-name="followers_to"
                  >
                    <CustomSelect
                      creatable
                      placeholder={intl.formatMessage({
                        id: "front_select_followers_to",
                        defaultMessage: "Select",
                      })}
                      isClearable
                      value={influencerFollowersTo}
                      options={sortOptions(
                        markOptionsAsDisabled(
                          rangeToOptions,
                          "to",
                          influencerFollowersFrom,
                        ),
                      )}
                      onCreateOption={(newValue) =>
                        handleCreateNewOption(newValue, "to")
                      }
                      onChange={(newValue) => {
                        if (newValue && isNewValueValid(newValue.value)) {
                          setInfluencerFollowersTo(newValue);
                        }
                        if (newValue === null) {
                          setInfluencerFollowersTo(null);
                        }
                      }}
                      isDisabled={influencerSocialUsername.length}
                      dataAttr="influencer-followers-to"
                      formatCreateLabel={(inputText) =>
                        `${translateMessage({
                          intl,
                          id: "front_add",
                          defaultMessage: "Add",
                        })} ${inputText}`
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {influencerSocialProvider?.value === "instagram" && (
        <div
          className="audience-search__field"
          data-filter-name="credibility_score"
        >
          <div className="idh-label idh-label--with-tooltip">
            <IDHFormattedMessage
              id="front_credibility_score"
              defaultMessage="Min. credibility score (%)"
            />
            <MaterialTooltip
              id="front_credibility_score_tooltip"
              content={
                <IDHFormattedMessage
                  id="front_credibility_score_tooltip"
                  defaultMessage="The parameter that determines how reliable the users following a given account are. The higher the parameter value, the more real and engaged the account's recipients are. Accounts with a credibility score equal to or higher than 60% are considered to be good."
                />
              }
            />
          </div>
          <CustomSelect
            placeholder={intl.formatMessage({
              id: "front_select_credibility_score",
              defaultMessage: "Select",
            })}
            isClearable
            value={audienceCredibilityScore}
            options={getPercentOptions(100)}
            onChange={(newValue) => setAudienceCredibilityScore(newValue)}
            isDisabled={influencerSocialUsername.length}
            dataAttr="min-credibility"
          />
        </div>
      )}
      {influencerSocialProvider?.value === "instagram" && (
        <div
          className="audience-search__field"
          data-filter-name="paid_post_performance"
        >
          <div className="idh-label idh-label--with-tooltip">
            <IDHFormattedMessage
              id="front_paid_post_performance"
              defaultMessage="Min. paid post performance (%)"
            />
            <MaterialTooltip
              right
              content={
                <IDHFormattedMessage
                  id="front_paid_post_performance_tooltip"
                  defaultMessage="The parameter that determines how users' paid posts are performing."
                />
              }
            />
          </div>
          <CustomSelect
            placeholder={intl.formatMessage({
              id: "front_select_paid_post_performance",
              defaultMessage: "Select",
            })}
            isClearable
            value={paidPostPerformanceMin}
            options={getPercentOptions(200, 20)}
            onChange={(newValue) => setPaidPostPerformanceMin(newValue)}
            isDisabled={influencerSocialUsername.length}
            dataAttr="min-paid-post"
          />
        </div>
      )}
    </div>
  );
}
