import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tab from "src/app/components/Tabs/Tab";
import Tabs from "src/app/components/Tabs/Tabs";
import { Button } from "src/app/components/Button/Button";
import { ReactComponent as FilterIcon } from "src/images/filter.svg";
import { ReactComponent as DownloadIcon } from "src/images/download-rounded.svg";
import { ReactComponent as EmptyIcon } from "src/images/empty-state-no-user-found.svg";
import { RootState } from "src/redux/reducers";
import { getWsContentProposalElementsSummary } from "../../../redux";
import {
  ContentProposalElementStatus,
  ContentProposalElementType,
} from "../../../redux/comment/types";
import Attachment from "../Attachments/Attachment";
import { AttachmentPlacement, TaskType } from "../../../types";
import DotIndicator from "../Comments/components/DotIndicator";
import ContentProposalElementsSummaryFilterDropdown from "../../dropdowns/ContentProposalElementsSummaryFilterDropdown/ContentProposalElementsSummaryFilterDropdown";
import ContentProposalElementsSummaryDownloadDropdown from "../../dropdowns/ContentProposalElementsSummaryDownloadDropdown/ContentProposalElementsSummaryDownloadDropdown";
import XssFilter from "../../components/XssFilter/XssFilter";

import "./ContentProposalSummary.scss";
import { WsContentProposalDownloadModal } from "../../modals/WsContentProposalDownloadModal/WsContentProposalDownloadModal";

enum ContentSummaryTabs {
  Content = "content",
  Caption = "caption",
}

interface WsContentProposalElementsSummaryInterface {
  wsTaskUuid: string;
  wsProjectUuid: string;
  onClickContentProposalSummaryElement: (wsTaskCommentUuid: string) => void;
  numberOfComments: number;
}

function WsContentProposalElementsSummary({
  wsTaskUuid,
  wsProjectUuid,
  onClickContentProposalSummaryElement,
  numberOfComments,
}: WsContentProposalElementsSummaryInterface) {
  const [activeTab, setActiveTab] = useState<ContentSummaryTabs>(
    ContentSummaryTabs.Content,
  );
  const [showAllMediaElements, setShowAllMediaElements] = useState(false);
  const [showAllCaptionElements, setShowAllCaptionElements] = useState(false);
  const [
    contentProposalSummaryFilterStatus,
    setContentProposalSummaryFilterStatus,
  ] = useState<ContentProposalElementStatus[]>([]);

  const { wsContentProposalMediaElements, wsContentProposalCaptionElements } =
    useSelector((state: RootState) => state.commentReducer);
  const [showContentDownloadModal, setShowContentDownloadModal] =
    useState(false);

  const numberOfProposalMediaElements = wsContentProposalMediaElements.length;
  const numberOfProposalCaptionElements =
    wsContentProposalCaptionElements.length;

  const dispatch = useDispatch();

  const onFilterChanged = (filterStatus: ContentProposalElementStatus[]) => {
    dispatch(getWsContentProposalElementsSummary(wsTaskUuid, filterStatus));
  };

  useEffect(() => {
    dispatch(getWsContentProposalElementsSummary(wsTaskUuid));
  }, [wsTaskUuid]);

  const existsAtLeastOneContentProposalFilterStatus = (): boolean => {
    return contentProposalSummaryFilterStatus.length > 0;
  };

  if (numberOfComments === 0) {
    return null;
  }

  return (
    <div className="content-proposal-summary">
      <div className="content-proposal-summary__header">
        <Tabs>
          <Tab
            tabText={
              <IDHFormattedMessage id="ws_content" defaultMessage="Content" />
            }
            active={activeTab === ContentSummaryTabs.Content}
            onClick={() => setActiveTab(ContentSummaryTabs.Content)}
            count={numberOfProposalMediaElements}
          />
          <Tab
            tabText={
              <IDHFormattedMessage id="ws_caption" defaultMessage="Caption" />
            }
            active={activeTab === ContentSummaryTabs.Caption}
            onClick={() => setActiveTab(ContentSummaryTabs.Caption)}
            count={numberOfProposalCaptionElements}
          />
          <div
            className={classNames("content-proposal-summary__filters", {
              active: existsAtLeastOneContentProposalFilterStatus(),
            })}
          >
            <ContentProposalElementsSummaryDownloadDropdown
              setShowContentDownloadModal={setShowContentDownloadModal}
              wsTaskUuids={[wsTaskUuid]}
              wsProjectUuid={wsProjectUuid}
            >
              <Button variant="white" size="small" textWithIcon>
                <DownloadIcon />
                <span>
                  <IDHFormattedMessage
                    id="ws_download"
                    defaultMessage="Download"
                  />
                </span>
              </Button>
            </ContentProposalElementsSummaryDownloadDropdown>
            <ContentProposalElementsSummaryFilterDropdown
              onFilterChanged={onFilterChanged}
              contentProposalSummaryFilterStatus={
                contentProposalSummaryFilterStatus
              }
              setContentProposalSummaryFilterStatus={
                setContentProposalSummaryFilterStatus
              }
            >
              <Button variant="white" size="small" textWithIcon>
                <FilterIcon />
                <span>
                  <IDHFormattedMessage id="ws_filter" defaultMessage="Filter" />
                  {existsAtLeastOneContentProposalFilterStatus() && ":"}
                </span>
                {existsAtLeastOneContentProposalFilterStatus() && (
                  <span>
                    {contentProposalSummaryFilterStatus.map(
                      (filterStatus: ContentProposalElementStatus) => {
                        return (
                          <span className="selected-filter" key={filterStatus}>
                            <IDHFormattedMessage
                              id={`ws_filter_status_${filterStatus}`}
                              defaultMessage={filterStatus}
                            />
                          </span>
                        );
                      },
                    )}
                  </span>
                )}
              </Button>
            </ContentProposalElementsSummaryFilterDropdown>
          </div>
        </Tabs>
      </div>
      {activeTab === ContentSummaryTabs.Content && (
        <>
          <div
            className={classNames(
              "content-proposal-summary-elements content-proposal-summary-elements--media",
              {
                expanded: showAllMediaElements,
              },
            )}
          >
            {wsContentProposalMediaElements.map((wsContentProposalElement) => {
              if (
                wsContentProposalElement.type ===
                ContentProposalElementType.Media
              ) {
                return (
                  <div
                    key={wsContentProposalElement.uuid}
                    className={`content-proposal-summary-element
                      content-proposal-summary-element--media
                      content-proposal-summary-element__media-wrapper
                      content-proposal-summary-element--${wsContentProposalElement.status}`}
                  >
                    <Attachment
                      data={wsContentProposalElement}
                      place={AttachmentPlacement.ContentProposalSummary}
                      taskType={TaskType.Content}
                      customGalleryClickHandler={() =>
                        onClickContentProposalSummaryElement(
                          wsContentProposalElement.wsTaskCommentUuid,
                        )
                      }
                    />
                    <DotIndicator status={wsContentProposalElement.status} />
                  </div>
                );
              }
              return (
                <div
                  key={wsContentProposalElement.uuid}
                  className={`content-proposal-summary-element
                  content-proposal-summary-element--media
                  content-proposal-summary-element__media-wrapper
                  content-proposal-summary-element--${wsContentProposalElement.status}`}
                >
                  <Attachment
                    data={wsContentProposalElement}
                    key={wsContentProposalElement.uuid}
                    place={AttachmentPlacement.ContentProposalSummary}
                    taskType={TaskType.Content}
                    customGalleryClickHandler={() =>
                      onClickContentProposalSummaryElement(
                        wsContentProposalElement.wsTaskCommentUuid,
                      )
                    }
                  />
                  <DotIndicator status={wsContentProposalElement.status} />
                </div>
              );
            })}
            {numberOfProposalMediaElements === 0 && (
              <div className="content-proposal-summary__empty">
                <EmptyIcon />
                <IDHFormattedMessage
                  id="ws_there_are_no_content_proposals"
                  defaultMessage="There are no content proposals."
                />
              </div>
            )}
          </div>
          {numberOfProposalMediaElements > 5 && (
            <div className="content-proposal-summary__collapse">
              {!showAllMediaElements && (
                <Button
                  variant="white-with-black-border"
                  className="content-proposal-summary__button"
                  onClick={() => setShowAllMediaElements(true)}
                >
                  <IDHFormattedMessage
                    id="ws_show_more"
                    defaultMessage="Show more"
                  />
                </Button>
              )}
              {showAllMediaElements && (
                <Button
                  variant="white-with-black-border"
                  className="content-proposal-summary__button content-proposal-summary__button--less"
                  onClick={() => setShowAllMediaElements(false)}
                >
                  <IDHFormattedMessage
                    id="ws_show_less"
                    defaultMessage="Show less"
                  />
                </Button>
              )}
            </div>
          )}
        </>
      )}

      {activeTab === ContentSummaryTabs.Caption && (
        <>
          <div
            className={classNames(
              "content-proposal-summary-elements content-proposal-summary-elements--caption",
              {
                expanded: showAllCaptionElements,
              },
            )}
          >
            {numberOfProposalCaptionElements > 0 &&
              wsContentProposalCaptionElements.map(
                (wsContentProposalElement) => {
                  return (
                    <div
                      key={wsContentProposalElement.uuid}
                      className={`content-proposal-summary-element
                    content-proposal-summary-element--caption
                    content-proposal-summary-element--${wsContentProposalElement.status}`}
                    >
                      <div
                        className="content-proposal-comment__caption"
                        onClick={() =>
                          onClickContentProposalSummaryElement(
                            wsContentProposalElement.wsTaskCommentUuid,
                          )
                        }
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: XssFilter(
                              wsContentProposalElement.caption,
                              [],
                            ),
                          }}
                        />
                        <DotIndicator
                          status={wsContentProposalElement.status}
                        />
                      </div>
                    </div>
                  );
                },
              )}
            {numberOfProposalCaptionElements === 0 && (
              <div className="content-proposal-summary__empty">
                <EmptyIcon />
                <IDHFormattedMessage
                  id="ws_there_are_no_content_proposals"
                  defaultMessage="There are no content proposals."
                />
              </div>
            )}
          </div>
          {numberOfProposalCaptionElements > 4 && (
            <div className="content-proposal-summary__collapse">
              {!showAllCaptionElements && (
                <Button
                  variant="white-with-black-border"
                  className="content-proposal-summary__button"
                  onClick={() => setShowAllCaptionElements(true)}
                >
                  <IDHFormattedMessage
                    id="ws_show_more"
                    defaultMessage="Show more"
                  />
                </Button>
              )}
              {showAllCaptionElements && (
                <Button
                  variant="white-with-black-border"
                  className="content-proposal-summary__button content-proposal-summary__button--less"
                  onClick={() => setShowAllCaptionElements(false)}
                >
                  <IDHFormattedMessage
                    id="ws_show_less"
                    defaultMessage="Show less"
                  />
                </Button>
              )}
            </div>
          )}
        </>
      )}
      {showContentDownloadModal && (
        <WsContentProposalDownloadModal
          onClose={() => {
            setShowContentDownloadModal(false);
          }}
        />
      )}
    </div>
  );
}

export default WsContentProposalElementsSummary;
