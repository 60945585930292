import React, { CSSProperties } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";

const validate = (values: NumberFormatValues) => {
  const { floatValue } = values;
  if (!floatValue) return true;
  return floatValue > -10e15 && floatValue < 10e15;
};

interface SeperatedNumberInputProps {
  inputRef?: React.RefObject<HTMLInputElement>;
  rawValue?: string;
  setRawValue: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
  style?: CSSProperties;
}

export function SeperatedNumberInput({
  inputRef,
  rawValue,
  setRawValue,
  onFocus,
  onBlur,
  className,
  placeholder,
  readOnly,
  style,
}: SeperatedNumberInputProps) {
  return (
    <NumberFormat
      value={rawValue}
      decimalScale={2}
      onValueChange={(values) => {
        const { floatValue } = values;
        setRawValue(floatValue?.toString() || "");
      }}
      thousandSeparator=" "
      isAllowed={validate}
      getInputRef={inputRef}
      style={style}
      onFocus={onFocus}
      onBlur={onBlur}
      className={className}
      placeholder={placeholder}
      readOnly={readOnly}
    />
  );
}
