import React from "react";
import { useSelector } from "react-redux";
import { Button } from "src/app/components/Button/Button";
import { Dropdown, DropdownMenu } from "src/app/components/Dropdown/Dropdown";
import { useHistory } from "react-router";
import { AppLocation } from "src/redux/main/mainReducer";
import { RootState } from "src/redux/reducers";
import { WsExtension, ExtensionTypeEnum } from "src/types";
import { ReactComponent as LightningIcon } from "../../../images/lightning.svg";
import { CreatorDatabaseConfigurationSection } from "../../SettingsModal/tabs/CreatorDatabaseConfiguration/CreatorDatabaseConfiguration";
import { openSettingsModal } from "../../SettingsModal/methods";
import { SettingsContext, SettingsTab } from "../../SettingsModal/enums";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";

export default function CreatorDatabaseExtensionDropdown() {
  const {
    mainReducer: {
      identity: { wsWorkspaceExtensions },
    },
  } = useSelector((state: RootState) => state);
  const history = useHistory();

  const allowedExtensionTypesToCount = [
    ExtensionTypeEnum.CreatorDatabaseSubmissionForm,
    ExtensionTypeEnum.ApiConnection,
  ];

  const numberOfEnabledWorkspaceExtensions = wsWorkspaceExtensions.filter(
    (e: WsExtension) =>
      e?.enabled && allowedExtensionTypesToCount.includes(e?.type),
  );

  if (!numberOfEnabledWorkspaceExtensions.length) return null;

  const openExtensions = (section: string) => {
    openSettingsModal(
      history,
      AppLocation.Navbar,
      SettingsContext.Workspace,
      SettingsTab.CreatorDatabaseConfiguration,
      section,
    );
  };

  const renderExtensionsListOptions = (extensionList: WsExtension[]) => {
    if (!extensionList.length) return;

    return (
      <>
        <div className="extensions-dropdown__subheader">
          <IDHFormattedMessage id="ws_extensions" defaultMessage="Extensions" />
        </div>
        {extensionList.map((extensionData) => {
          switch (extensionData.type) {
            case ExtensionTypeEnum.CreatorDatabaseSubmissionForm:
              return (
                <div
                  className="dropdown__menu-item"
                  onClick={() =>
                    openExtensions(
                      CreatorDatabaseConfigurationSection.SubmissionForm,
                    )
                  }
                  key={extensionData.uuid}
                >
                  <IDHFormattedMessage
                    id="ws_creator_submission_form"
                    defaultMessage="Creator Submission Form"
                  />
                </div>
              );
            case ExtensionTypeEnum.ApiConnection:
              return (
                <div
                  className="dropdown__menu-item"
                  onClick={() =>
                    openExtensions(
                      CreatorDatabaseConfigurationSection.InstagramAccountVerification,
                    )
                  }
                  key={extensionData.uuid}
                >
                  <IDHFormattedMessage
                    id="ws_instagram_account_verification"
                    defaultMessage="Instagram Account Verification"
                  />
                </div>
              );
            default:
              return null;
          }
        })}
      </>
    );
  };

  return (
    <Dropdown className="extensions-dropdown" toRight>
      {(isOpen, setIsOpen) => (
        <>
          <Button
            variant="white"
            size="small"
            textWithIcon
            onClick={() => setIsOpen(true)}
          >
            <LightningIcon />
            {numberOfEnabledWorkspaceExtensions.length}
          </Button>

          <DropdownMenu isOpen={isOpen}>
            {renderExtensionsListOptions(wsWorkspaceExtensions)}
          </DropdownMenu>
        </>
      )}
    </Dropdown>
  );
}
