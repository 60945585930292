import React, { ReactNode } from "react";

interface AddCommentWrapperProps {
  children: ReactNode;
}

export default function AddCommentWrapper({
  children,
}: AddCommentWrapperProps) {
  return <div className="add-comment-wrapper">{children}</div>;
}
