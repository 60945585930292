import React from "react";

export function SectionHeader(props) {
  const { text } = props;
  return (
    <div>
      <div className="profile-data-tab__section-header">{text}</div>
    </div>
  );
}
