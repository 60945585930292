import React, { useEffect } from "react";
import { useHistory } from "react-router";
import "./PageNotFound.scss";

import { ReactComponent as ArrowLeftIcon } from "src/images/inda-icons/Navigation/Arrow-left.svg";
import { ReactComponent as PageNotFoundIcon } from "src/images/page-not-found.svg";
import { topbarRef } from "src/app/pages/layouts/DefaultLayout";
import { Button } from "../Button/Button";

export function PageNotFound() {
  const history = useHistory();

  useEffect(() => {
    if (topbarRef?.current) topbarRef.current.style.display = "none";
  }, []);

  return (
    <div className="page-not-found">
      <div className="page-not-found__col page-not-found__col--left">
        <div className="page-not-found__col__header">Oops. Page not found.</div>
        <div className="page-not-found__col__sub">
          The page you are looking for got lost.
        </div>
        <Button
          variant="blue"
          size="large"
          className="page-not-found__col__btn"
          onClick={() => history.goBack()}
        >
          <ArrowLeftIcon />
          Previous page
        </Button>
      </div>
      <div className="page-not-found__col page-not-found__col--right">
        <PageNotFoundIcon />
      </div>
    </div>
  );
}
