import React, { useState } from "react";

import { ReactComponent as EditIcon } from "src/images/edit.svg";
import AddSocialProfilesModal, {
  SocialProfile,
} from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import NotFoundImg from "src/images/empty-state-no-user-found.svg";
import { EmptyTabContainer } from "./EmptyTabContainer/EmptyTabContainer";
import { EmptyTabContent } from "./EmptyTabContent/EmptyTabContent";

interface Props {
  socialProfiles: SocialProfile[];
  taskTitle: string;
  taskId: string;
}

export default function ProfileError(props: Props) {
  const { socialProfiles, taskTitle, taskId } = props;

  const [showAddSocialProfilesModal, setShowAddSocialProfilesModal] =
    useState(false);

  const openSocialMediaModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowAddSocialProfilesModal(true);
  };

  return (
    <EmptyTabContainer className="empty-social-profiles-tab">
      <EmptyTabContent
        header={
          <IDHFormattedMessage
            id="ws_profile_data_error"
            defaultMessage="Profile data error."
          />
        }
        buttonText={
          <IDHFormattedMessage
            id="add_edit_profiles"
            defaultMessage="Edit social profiles"
          />
        }
        onButtonClick={openSocialMediaModal}
        buttonIcon={<EditIcon />}
        imgUrl={NotFoundImg}
      />

      {showAddSocialProfilesModal && (
        <AddSocialProfilesModal
          objectType="task"
          onClose={() => setShowAddSocialProfilesModal(false)}
          name={taskTitle}
          taskId={taskId}
          socialProfiles={socialProfiles}
        />
      )}
    </EmptyTabContainer>
  );
}
