import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import "./TikTokEmbed.scss";
import { TikTok } from "react-tiktok";
import { ReactComponent as CloseIcon } from "src/images/close.svg";
import { ReactComponent as GoToIcon } from "src/images/share-alt.svg";
import { ReactComponent as InfoDarkIcon } from "src/images/info-dark.svg";
import classNames from "classnames";

import { useOnClickOutside } from "../../base/utils/useOnOutsideClick";
import Loader from "../Loader/Loader";
import IDHFormattedMessage from "../../../../../../components/IDHFormattedMessage/IDHFormattedMessage";
import OuterLink from "../OuterLink/OuterLink";

class TikTokEmbed extends React.Component {
  el = document.createElement("div");

  constructor(props) {
    super(props);
    this.state = { hasError: false, loadComplete: false };
  }

  componentDidMount() {
    this.el.classList.add("tiktok-embed-overlay");
    if (!this.state.hasError) {
      document.body.appendChild(this.el);
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      <TikTokEmbedContent {...this.props} />,
      this.el,
    );
  }
}

function TikTokEmbedContent({ onClose, postUrl, ...props }) {
  const ref = useRef();
  const [videoExists, setVideoExists] = useState(false);
  const [loadComplete, setLoadComplete] = useState(false);

  useOnClickOutside(ref, () => {
    onClose();
  });

  useEffect(() => {
    const checkVideoExists = async () => {
      try {
        const response = await fetch(
          `https://www.tiktok.com/oembed?url=${postUrl}`,
        );
        if (response.ok) {
          setVideoExists(true);
        }
      } catch (error) {
        console.error("Error checking video existence:", error);
      } finally {
        setLoadComplete(true);
      }
    };

    checkVideoExists();
  }, [postUrl]);

  return (
    <div
      ref={ref}
      className={classNames("tiktok-embed-container", "post-preview-modal")}
    >
      {(!loadComplete || (loadComplete && videoExists)) && <Loader />}
      {loadComplete && videoExists && <TikTok url={postUrl} />}
      {loadComplete && !videoExists && (
        <div className="post-preview-not-available">
          <div className="post-preview-not-available__title">
            <InfoDarkIcon />
            <IDHFormattedMessage
              id="front_post_preview_not_avaliable"
              defaultMessage="Post preview is not available"
            />
          </div>
          <div className="post-preview-not-available__description">
            <IDHFormattedMessage
              id="front_post_preview_not_avaliable_description"
              defaultMessage="Either post or account were removed or creator has blocked embedding."
            />
          </div>
          <OuterLink href={postUrl}>
            <div className="post-preview-not-available__view-post">
              <IDHFormattedMessage
                id="front_post_preview_go_to_post"
                defaultMessage="View post on TikTok"
              />
              <GoToIcon />
            </div>
          </OuterLink>
        </div>
      )}
      <div className="tiktok-embed-container__close" onClick={onClose}>
        <CloseIcon width={24} height={24} />
      </div>
    </div>
  );
}

export default TikTokEmbed;
