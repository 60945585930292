import React from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  ContentProposalElement,
  ContentProposalElementType,
  IContentProposalElementComment,
} from "src/redux/comment/types";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import XssFilter from "src/app/components/XssFilter/XssFilter";
import { AttachmentPlacement, TaskType } from "src/types";
import { UserContent } from "src/redux/comment/commentReducer";
import {
  clearUserRejectedContent,
  clearUserAcceptedContent,
  setUserAcceptedContent,
  setUserRejectedContent,
  clearReplyToCommentData,
} from "src/redux";
import { RootState } from "src/redux/reducers";
import Attachment from "../../Attachments/Attachment";
import AcceptRejectButtons from "./AcceptRejectButtons";
import DotIndicator from "./DotIndicator";

interface ContentProposalElementProps {
  data: ContentProposalElement;
  taskType: TaskType;
  comment: IContentProposalElementComment;
  currentElementIndex: number;
  firstMediaElementIndex: number;
}

export default function ContentProposalItem({
  data,
  taskType,
  comment,
  currentElementIndex,
  firstMediaElementIndex,
}: ContentProposalElementProps) {
  const dispatch = useDispatch();
  const {
    userAcceptedContent: {
      contentProposalElements: userAcceptedContentElements,
    },
    userRejectedContent: {
      contentProposalElements: userRejectedContentElements,
    },
  }: { userAcceptedContent: UserContent; userRejectedContent: UserContent } =
    useSelector((state: RootState) => state.commentReducer);

  const handleAddUserAcceptedContent = (
    wsTaskCommentUuid: string,
    contentProposalElement: ContentProposalElement,
  ) => {
    if (userRejectedContentElements.length) {
      dispatch(clearUserRejectedContent());
    }
    dispatch(clearReplyToCommentData());
    dispatch(
      setUserAcceptedContent({
        wsTaskCommentUuid,
        contentProposalElement,
      }),
    );
  };

  const handleAddUserRejectedContent = (
    wsTaskCommentUuid: string,
    contentProposalElement: ContentProposalElement,
  ) => {
    if (userAcceptedContentElements.length) {
      dispatch(clearUserAcceptedContent());
    }
    dispatch(clearReplyToCommentData());
    dispatch(
      setUserRejectedContent({
        wsTaskCommentUuid,
        contentProposalElement,
      }),
    );
  };

  if (data.type === ContentProposalElementType.Caption && data.caption) {
    return (
      <div
        className={classNames(
          "content-proposal-comment__content-wrapper",
          "content-proposal-comment__content-wrapper--caption",
          `content-proposal-comment__content-wrapper--${data.status}`,
        )}
      >
        <div className="content-proposal-comment__element-wrapper">
          <span className="content-proposal-comment__element-title">
            <IDHFormattedMessage id="ws_caption" defaultMessage="Caption" />
          </span>
          <div className="content-proposal-comment__caption">
            <div
              dangerouslySetInnerHTML={{
                __html: XssFilter(data.caption, ["br"]),
              }}
            />
            <DotIndicator status={data.status} />
          </div>
        </div>
        <AcceptRejectButtons
          status={data.status}
          hasUserAccepted={userAcceptedContentElements.some(
            (el) => el.uuid === data.uuid,
          )}
          hasUserRejected={userRejectedContentElements.some(
            (el) => el.uuid === data.uuid,
          )}
          handleClickAccept={() =>
            handleAddUserAcceptedContent(comment.uuid, data)
          }
          handleClickReject={() =>
            handleAddUserRejectedContent(comment.uuid, data)
          }
        />
      </div>
    );
  }

  if (
    data.type === ContentProposalElementType.Media ||
    data.type === ContentProposalElementType.ExternalUrl
  ) {
    return (
      <div
        className={classNames(
          "content-proposal-comment__content-wrapper",
          `content-proposal-comment__content-wrapper--${data.status}`,
        )}
      >
        {firstMediaElementIndex === currentElementIndex ? (
          <div className="content-proposal-comment__element-wrapper">
            <span className="content-proposal-comment__element-title">
              <IDHFormattedMessage id="ws_content" defaultMessage="Content" />
            </span>
            <div className="content-proposal-comment__media-wrapper">
              <Attachment
                data={data}
                place={AttachmentPlacement.ContentProposal}
                taskType={taskType}
              />
              <DotIndicator status={data.status} />
            </div>
          </div>
        ) : (
          <div className="content-proposal-comment__media-wrapper">
            <Attachment
              data={data}
              place={AttachmentPlacement.ContentProposal}
              taskType={taskType}
            />
            <DotIndicator status={data.status} />
          </div>
        )}
        <AcceptRejectButtons
          status={data.status}
          hasUserAccepted={userAcceptedContentElements.some(
            (el) => el.uuid === data.uuid,
          )}
          hasUserRejected={userRejectedContentElements.some(
            (el) => el.uuid === data.uuid,
          )}
          handleClickAccept={() =>
            handleAddUserAcceptedContent(comment.uuid, data)
          }
          handleClickReject={() =>
            handleAddUserRejectedContent(comment.uuid, data)
          }
        />
      </div>
    );
  }
  return null;
}
