import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export const CREATOR_INSIGHTS = [
  // { value: "age", label: "Age", initialHidden: true },
  // { value: "female", label: "Female", initialHidden: true },
  // { value: "male", label: "Male", initialHidden: true },
  {
    value: "location",
    label: "Location",
    source: "countryLocations",
  },
  {
    value: "followers-range",
    label: "Followers",
    source: "followersRange",
  },
  {
    value: "creator-interests",
    label: "Creator interests",
    w: 6,
    h: 3,
    source: "creatorsInterests",
  },
  {
    value: "creators-brand-affinity",
    label: "Creator's Brand Affinity",
    w: 6,
    h: 3,
    source: "creatorsBrandAffinity",
  },
];

export const CAMPAIGN_AUDIENCE_DATA = [
  {
    value: "credibility",
    label: "Credibility",
    w: 12,
    h: 1,
    source: "credibility",
  },
  {
    value: "audience-age",
    label: "Audience Age",
    w: 4,
    h: 3,
    source: "audienceAgeGroups",
  },
  {
    value: "female-per-age",
    label: "Female",
    w: 4,
    h: 3,
    source: "audienceWomen",
  },
  {
    value: "male-per-age",
    label: "Male",
    w: 4,
    h: 3,
    source: "audienceMen",
  },
  {
    value: "languages",
    label: "Languages",
    w: 4,
    h: 3,
    source: "audienceLanguages",
  },
  {
    value: "geographical-reach",
    label: "Geographical Reach",
    w: 4,
    h: 3,
    source: "audienceGeographicalReach",
  },
  {
    value: "location-by-city",
    label: "Location by City",
    w: 4,
    h: 3,
    source: "audienceCityReach",
  },
  {
    value: "audience-interests",
    label: "Audience Interests",
    w: 6,
    h: 3,
    source: "audienceInterests",
  },
  {
    value: "audience-brand-affinity",
    label: "Audience Brand Affinity",
    w: 6,
    h: 3,
    source: "brandAffinity",
  },
];
