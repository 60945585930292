import { getStatisticHeader } from "src/helpers/statistic-list-item";
import { capitalizeFirstLetter } from "src/utils/methods";

export const addSentimentAnalysisToGridElements = (
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  sentimentValues,
) => {
  if (!sentimentValues) return;

  Object.entries(sentimentValues).forEach((item) => {
    const [key, value] = item;

    const element = {
      key,
      value,
      label: `sentiment-analysis-${key}`,
      name: capitalizeFirstLetter(getStatisticHeader(key)),
      gridBoxType: "sentiment-analysis",
      section: "comments-analysis",
    };

    // applying saved template
    if (savedTemplate?.length && !templateApplied) {
      if (savedTemplate.find((item) => item.i === element.label)) {
        newGridElements.push(element);
      } else {
        newHiddenGridElements.push(element);
      }
    }
    // applying default template
    // else if (comments.all[key]?.length) {
    newGridElements.push(element);
    // } else {
    // newHiddenGridElements.push(element);
    // }
  });
};
