import React from "react";
import { SummaryBoxList } from "../../../components/SummaryBoxList/SummaryBoxList";

function Publications(props) {
  const { foundFields } = props;

  return <SummaryBoxList foundFields={foundFields} />;
}

export { Publications };
