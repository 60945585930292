import React, { ReactNode, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { IntlShape, WrappedComponentProps, injectIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { RootState } from "src/redux/reducers";
import { showToast } from "../../methods/showToast";
import { API_URLS } from "../../../utils/API_URLS";
import { getProject, getProjectDetails, getTasksList } from "../../../redux";
import { tableDataType } from "../../components/Table/Table";
import { RemoveModal } from "../RemoveModal/RemoveModal";

interface Props extends WrappedComponentProps<"intl"> {
  onClose: () => void;
  uuid: string;
  fieldName: string;
  dataType?: string;
  onTable?: boolean;
  intl: IntlShape;
}

const DeleteMetaFieldModal: React.FC<Props> = (props) => {
  const { onClose, uuid, fieldName = "", dataType, onTable, intl } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const { projectId } = useParams<{ projectId: string }>();

  const {
    projectReducer: { taskType },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const deleteMetaField = async () => {
    setLoading(true);

    try {
      await axios.delete(
        API_URLS.deleteProjectMetaField.replace(":fieldId:", uuid),
      );
      if (onTable) {
        dispatch(getProject(projectId));
      } else {
        dispatch(getProjectDetails(projectId));
      }
      onClose();
      showToast(
        "success",
        translateMessage({
          intl,
          id: "ws_success",
          defaultMessage: "Success",
        }),
        translateMessage({
          intl,
          id: "ws_field_has_been_deleted",
          defaultMessage: "Field has been deleted",
        }),
      );
    } catch (err) {
      showToast(
        "error",
        translateMessage({
          intl,
          id: "ws_error",
          defaultMessage: "Error",
        }),
        translateMessage({
          intl,
          id: "ws_error_on_deleting_field",
          defaultMessage: "Error on deleting field",
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteTaskMetaField = async () => {
    setLoading(true);

    try {
      await axios.delete(
        API_URLS.deleteTaskMetaField.replace(":wsTaskMetaFieldUuid:", uuid),
      );
      dispatch(getTasksList(projectId, taskType));
      onClose();
      showToast(
        "success",
        translateMessage({
          intl,
          id: "ws_success",
          defaultMessage: "Success",
        }),
        translateMessage({
          intl,
          id: "ws_field_has_been_deleted",
          defaultMessage: "Field has been deleted",
        }),
      );
    } catch (err) {
      showToast(
        "error",
        translateMessage({
          intl,
          id: "ws_error",
          defaultMessage: "Error",
        }),
        translateMessage({
          intl,
          id: "ws_error_on_deleting_field",
          defaultMessage: "Error on deleting field",
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <RemoveModal
      onClose={onClose}
      objectNames={[fieldName]}
      removeFunction={
        dataType === tableDataType.Task ? deleteTaskMetaField : deleteMetaField
      }
      loading={loading}
    />
  );
};

export default injectIntl(DeleteMetaFieldModal);
