import React, { Dispatch, ReactNode, SetStateAction, useState } from "react";
import "./CustomizeFieldsDropdown.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "../../components/Button/Button";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuSeparator,
  DropdownMenuTitle,
} from "../../components/Dropdown/Dropdown";
import FieldSwitch from "../../components/FieldSwitch/FieldSwitch";

import { ReactComponent as CustomizeIcon } from "../../../images/settings-alt.svg";
import { tableDataType } from "../../components/Table/Table";
import DeleteMetaFieldModal from "../../modals/DeleteMetaFieldModal/DeleteMetaFieldModal";
import { TaskType } from "../../../types";

interface Props {
  fields?: any;
  setShowAddFieldModal: Dispatch<SetStateAction<boolean>>;
  setShowFieldSetupModal: Dispatch<SetStateAction<boolean>>;
  fieldData: any;
  setFieldData: Dispatch<SetStateAction<any>>;
  dropdownButton?: JSX.Element;
  dataType: tableDataType;
  taskType?: string;
  title?: string | ReactNode;
  subheader?: string | ReactNode;
  addBtnText?: string | ReactNode;
}

const CustomizeFieldsDropdown: React.FC<Props> = (props) => {
  const {
    fields,
    dropdownButton,
    setShowAddFieldModal,
    setShowFieldSetupModal,
    fieldData,
    setFieldData,
    dataType,
    taskType,
    title,
    subheader,
    addBtnText,
  } = props;

  const [deleteTaskMetaFieldOpen, setDeleteTaskMetaFieldOpen] = useState(false);

  const renderTabName = (taskType: string) => {
    switch (taskType) {
      case TaskType.Creator:
        return (
          <IDHFormattedMessage id="ws_creators" defaultMessage="Creators" />
        );
      case TaskType.Content:
        return <IDHFormattedMessage id="ws_content" defaultMessage="Content" />;
      case TaskType.Publication:
        return (
          <IDHFormattedMessage
            id="ws_publications"
            defaultMessage="Publications"
          />
        );
      case TaskType.Action:
        return <IDHFormattedMessage id="ws_actions" defaultMessage="Actions" />;

      default:
        return "";
    }
  };

  return (
    <>
      <Dropdown size="big" className="customize-fields-dropdown" toRight>
        {(isOpen, setIsOpen) => (
          <>
            <span
              className="customize-fields-dropdown__button"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {dropdownButton || (
                <Button variant="white" size="thin" disabled={!fields.length}>
                  <CustomizeIcon />
                  {title}
                </Button>
              )}
            </span>
            <DropdownMenu isOpen={isOpen}>
              {taskType && (
                <div className="customize-fields-dropdown__tab-name">
                  {renderTabName(taskType)}
                </div>
              )}
              {title && <DropdownMenuTitle>{title}</DropdownMenuTitle>}
              <div className="customize-fields-dropdown__subheader">
                {subheader}
              </div>
              <DropdownMenuSeparator />

              <div className="field-switch-list">
                {fields &&
                  fields.map((field: any) => (
                    <FieldSwitch
                      key={field.id || field.uuid}
                      field={field}
                      setField={setFieldData}
                      setShowSetupModal={setShowFieldSetupModal}
                      dataType={dataType}
                      setDeleteTaskMetaFieldOpen={setDeleteTaskMetaFieldOpen}
                    />
                  ))}
              </div>
            </DropdownMenu>
          </>
        )}
      </Dropdown>

      {deleteTaskMetaFieldOpen && (
        <DeleteMetaFieldModal
          uuid={fieldData.id || fieldData.uuid}
          fieldName={fieldData?.title ?? ""}
          onClose={() => setDeleteTaskMetaFieldOpen(false)}
          dataType={dataType}
        />
      )}
    </>
  );
};

export default CustomizeFieldsDropdown;
