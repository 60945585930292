import React, { useMemo, useState } from "react";
import "./Publication.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SectionRowWrapper } from "src/app/components/Pane/SectionRowWrapper/SectionRowWrapper";
import { useSelector } from "react-redux";
import Tab from "../../components/Tabs/Tab";
import Tabs from "../../components/Tabs/Tabs";
import { PublicationSummaryTab } from "../tabs/PublicationSummaryTab/PublicationSummaryTab";
import { CommentsAnalysisTab } from "../tabs/CommentsAnalysisTab/CommentsAnalysisTab";
import { Published } from "../tabs/PublicationSummaryTab/section/Published";
import { Caption } from "../tabs/PublicationSummaryTab/section/Caption";

function Publication(props) {
  const {
    publication: {
      platform,
      platformDisplayName,
      statistics,
      sentimentData,
      sentimentCommentsData,
      caption,
      publicationDate,
      synchronizable,
      synchronizationDate,
      status,
      url,
    },
    setShowUpdatePublicationLinkModal,
  } = props;

  const [activeTab, setActiveTab] = useState("publication-summary");

  const identity = useSelector((state) => state.mainReducer.identity);
  const taskDetailsLoading = useSelector(
    (state) => state.taskReducer.taskDetailsLoading,
  );

  const showSentimentData = useMemo(
    () =>
      identity?.sentimentsAvailability &&
      !sentimentData?.every((item) => item.value === null),
    [identity, sentimentData],
  );

  const renderTabContent = () => {
    switch (activeTab) {
      case "publication-summary":
        return (
          <PublicationSummaryTab
            platform={platform}
            platformDisplayName={platformDisplayName}
            statistics={statistics}
            synchronizable={synchronizable}
            synchronizationDate={synchronizationDate}
            status={status}
            setShowUpdatePublicationLinkModal={
              setShowUpdatePublicationLinkModal
            }
            publicationUrl={url}
            taskDetailsLoading={taskDetailsLoading}
          />
        );

      case "comments-analysis":
        if (showSentimentData) {
          return (
            <CommentsAnalysisTab
              platform={platform}
              platformDisplayName={platformDisplayName}
              sentimentData={sentimentData}
              sentimentCommentsData={sentimentCommentsData}
            />
          );
        }
        return null;

      default:
        return null;
    }
  };

  return (
    <div className="publication">
      <SectionRowWrapper className="publication__inline-sections">
        <Published publicationDate={publicationDate} />
        {caption && <Caption caption={caption} />}
      </SectionRowWrapper>

      <Tabs>
        <Tab
          tabText={
            <IDHFormattedMessage
              id="ws_publication_summary"
              defaultMessage="Publication summary"
            />
          }
          active={activeTab === "publication-summary"}
          onClick={() => setActiveTab("publication-summary")}
          medium
        />

        {showSentimentData && (
          <Tab
            tabText={
              <IDHFormattedMessage
                id="ws_comments_analysis"
                defaultMessage="Comments analysis"
              />
            }
            active={activeTab === "comments-analysis"}
            onClick={() => setActiveTab("comments-analysis")}
            medium
          />
        )}
      </Tabs>

      {renderTabContent()}
    </div>
  );
}

export { Publication };
