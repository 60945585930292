import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { showToast } from "src/app/methods/showToast";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { getCouponGroups } from "src/redux/coupon/couponActions";
import { API_URLS } from "src/utils/API_URLS";
import { RootState } from "src/redux/reducers";

interface Props {
  onClose: () => void;
  couponGroupUuid: string;
  couponGroupName: string;
}

export const DeleteCouponGroupModal: React.FC<Props> = (props) => {
  const { onClose, couponGroupUuid, couponGroupName } = props;

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const { couponGroupId: couponGroupIdParam } = useParams<{
    couponGroupId: string;
  }>();
  const dispatch = useDispatch();

  const confirmDeleting = async () => {
    setLoading(true);
    const url = API_URLS.deleteCouponGroup.replace(
      ":couponGroupUuid:",
      couponGroupUuid,
    );

    axios
      .delete(url)
      .then(() => {
        if (couponGroupIdParam) {
          history.push(`/workspace/${activeWorkspaceUuid}/coupon-groups`);
        }
        dispatch(getCouponGroups(activeWorkspaceUuid));
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_something_went_wrong"
            defaultMessage="Something went wrong"
          />,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <RemoveModal
      onClose={onClose}
      objectNames={[couponGroupName]}
      removeFunction={confirmDeleting}
    />
  );
};
