import React, { useEffect, useState } from "react";
import "./TableOfContents.scss";

import { useSelector } from "react-redux";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { TableOfContentsElement } from "./components/TableOfContentsElement";

export function TableOfContents(props) {
  const { pages } = props;

  const [headers, setHeaders] = useState([]);

  const {
    reportReducer: { layout, gridElements },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!layout.length || !gridElements.length) return;

    const newHeaders = gridElements
      ?.filter(
        (item) =>
          item.gridBoxType === "header" || item.gridBoxType === "subheader",
      )
      .map((item) => {
        const layoutEl = layout?.find((el) => el.i === item.label);

        if (layoutEl) {
          const { y } = layoutEl;

          return { ...item, y };
        }
        return item;
      })
      .sort((a, b) => a.y - b.y);

    setHeaders(newHeaders);
  }, [layout, gridElements]);

  return (
    <div id="table-of-contents-container" className="table-of-contents">
      <div className="table-of-contents__heading">
        <IDHFormattedMessage
          id="ws_table_of_contents"
          defaultMessage="Table of contents"
        />
      </div>
      {headers.map((item) => (
        <TableOfContentsElement key={item.text} data={item} pages={pages} />
      ))}
    </div>
  );
}
