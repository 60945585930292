import React, { useEffect, useState } from "react";

import { ReactComponent as EyesIcon } from "src/images/eyes.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { GlobalMetaFieldMemberListItem } from "./GlobalMetaFieldMemberListItem";
import { GlobalMetaFieldMemberListFilter } from "./GlobalMetaFieldMemberListFilter";

function GlobalMetaFieldMemberList(props) {
  const {
    currentMemberList,
    setShowConfirmDeleteMemberModal,
    setDeleteMemberData,
  } = props;

  const [filteredGroup, setFilteredGroup] = useState("all");

  const getMemberTeamList = () => {
    return currentMemberList?.filter((row) =>
      filteredGroup !== "all" ? row?.accessLevel === filteredGroup : true,
    );
  };

  return (
    <div className="meta-field-members__user-list-wrap">
      <div className="meta-field-members__user-list__header">
        <IDHFormattedMessage
          id="ws_people_with_access"
          defaultMessage="People with access"
        />
        <GlobalMetaFieldMemberListFilter
          filteredGroup={filteredGroup}
          setFilteredGroup={setFilteredGroup}
        />
      </div>
      <div className="meta-field-members__user-list">
        {getMemberTeamList()?.length > 0 ? (
          getMemberTeamList().map((member, index) => (
            <GlobalMetaFieldMemberListItem
              key={`project-user-${index}`}
              member={member}
              setShowConfirmDeleteMemberModal={setShowConfirmDeleteMemberModal}
              filteredGroup={filteredGroup}
              setDeleteMemberData={setDeleteMemberData}
            />
          ))
        ) : (
          <div className="meta-field-members__user-list-empty">
            <EyesIcon className="meta-field-members__eyes-icon" />
            &nbsp;
            <IDHFormattedMessage
              id="ws_you_have_no_member_assign_to_meta_field"
              defaultMessage="You have no Members assign to meta field."
            />
          </div>
        )}
      </div>
      <div className="meta-field-members__user-list__footer" />
    </div>
  );
}

export { GlobalMetaFieldMemberList };
