import { useEffect } from "react";
import "./ImgChecker.scss";

function ImgChecker({ src, onError, onSuccess }) {
  const onErrorHandler = () => {
    onError();
  };

  const onSuccessHandler = () => {
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  };

  useEffect(() => {
    if (!src) {
      onErrorHandler();

      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.open("GET", src, true);
    xhr.setRequestHeader("Range", "bytes=0-0");
    xhr.onreadystatechange = () => {
      if (xhr?.readyState === 4) {
        const responseStatus = xhr?.status;

        if (responseStatus === 200 || responseStatus === 206 || responseStatus === 304) {
          onSuccessHandler();

          return;
        }

        onErrorHandler();
      }
    };

    xhr.send();
  }, [src]);

  return null;
}

export default ImgChecker;
