import React from "react";
import { Portal } from "react-overlays";
import { overlaysRef } from "src/App";
import { useTransition, animated } from "react-spring";
import "./Pane.scss";
import classNames from "classnames";

function Pane(props) {
  const {
    children,
    showPane,
    hidePane,
    unsetOverflow,
    width,
    className,
    backdropClassName,
  } = props;
  const transitions = useTransition(showPane, {
    from: { x: 1200 },
    enter: { x: 0 },
    leave: { x: 1200 },
  });

  const backdropTransition = useTransition(showPane, {
    from: { opacity: 0 },
    enter: { opacity: 0.25 },
    leave: { opacity: 0 },
  });

  return (
    <Portal container={overlaysRef}>
      {backdropTransition(
        (style, item) =>
          item && (
            <animated.div
              style={style}
              className={classNames("pane-backdrop", backdropClassName)}
              onClick={hidePane}
            />
          ),
      )}
      {transitions(
        (styles, item) =>
          item && (
            <animated.div onClick={hidePane} className="pane-wrapper">
              <animated.div
                onClick={(e) => e.stopPropagation()}
                style={{ ...styles, width }}
                className={classNames(
                  "pane",
                  {
                    "pane--default-position": !unsetOverflow,
                  },
                  className,
                )}
              >
                {children}
              </animated.div>
            </animated.div>
          ),
      )}
    </Portal>
  );
}

export { Pane };
