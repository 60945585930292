import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./ShareWithClientModal.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { localSetColumnsSharing } from "src/redux";
import Modal, { ModalText, ModalTitle } from "../../components/Modal/Modal";
import { ReactComponent as ClientsGreyIcon } from "../../../images/clients-grey.svg";
import { API_URLS } from "../../../utils/API_URLS";
import { wsAxiosPatch } from "../../../helpers/wsAxios";
import { StackedAvatars } from "../../components/StackedAvatars/StackedAvatars";

function ShareWithClientModal(props) {
  const { onClose, metaFieldId } = props;

  const {
    projectReducer: { projectBasicData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const shareColumn = async () => {
    await wsAxiosPatch(
      API_URLS.shareColumn.replace(":metaFieldId:", metaFieldId),
      {},
      `Error occurred during sharing column`,
      () => {},
      () => {
        dispatch(localSetColumnsSharing([metaFieldId], true));
      },
    );
    onClose();
  };

  return (
    <Modal
      className="share-with-client-modal"
      onClose={onClose}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={shareColumn}
      confirmButtonText={
        <IDHFormattedMessage id="ws_share" defaultMessage="Share" />
      }
    >
      <ModalTitle>
        <ClientsGreyIcon className="share-with-client-modal__icon" />
        <IDHFormattedMessage
          id="ws_share_with_client"
          defaultMessage="Share with Client"
        />
        <StackedAvatars
          avatars={projectBasicData.members.filter((user) => user.isClient)}
          maxAvatars={2}
          stackedUsers
        />
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_share_with_client_modal_text"
          defaultMessage="Are you sure you want to share this column with Clients?"
        />
      </ModalText>
    </Modal>
  );
}

export default ShareWithClientModal;
