import React, { useEffect, useRef, useState } from "react";
import "./CriteriaOptionSelector.scss";

import DropdownPortal, {
  DropdownPortalMenu,
} from "src/app/components/DropdownPortal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import useOnClickOutside from "src/app/methods/useOnClickOutside";
import { ReactComponent as AtLeastOneIcon } from "src/images/at-least-one.svg";
import { ReactComponent as AllSelectedIcon } from "src/images/all-selected.svg";
import { ReactComponent as TickIcon } from "src/images/tick.svg";

export function CriteriaOptionSelector(props) {
  const { value, setValue } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    {
      value: "or",
      icon: <AtLeastOneIcon />,
      header: (
        <IDHFormattedMessage
          id="ws_at_least_one"
          defaultMessage="At least one"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_at_least_one_text"
          defaultMessage="Search results must meet at least one criterion from the given section. You will get more but less relevant results."
        />
      ),
    },
    {
      value: "and",
      icon: <AllSelectedIcon />,
      header: (
        <IDHFormattedMessage
          id="ws_all_selected"
          defaultMessage="All selected"
        />
      ),
      text: (
        <IDHFormattedMessage
          id="ws_all_selected_text"
          defaultMessage="Search results must meet all selected criteria from the given section. You will get fewer but more relevant results."
        />
      ),
    },
  ];

  useEffect(() => {
    const foundOption = options.find((item) => item.value === value);
    setSelectedOption(foundOption || options[0]);
  }, [value]);

  const dropdownRef = useRef();

  const handleOptionClick = (newValue) => {
    setIsOpen(false);
    setValue(newValue);
  };

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <span ref={dropdownRef}>
      <DropdownPortal
        className="criteria-option-selector"
        placement="bottomRight"
        overlay={
          <DropdownPortalMenu className="criteria-option-selector__menu">
            {options.map((item) => (
              <div
                onClick={() => handleOptionClick(item)}
                className="criteria-option-selector__menu-item"
              >
                <span className="criteria-option-selector__menu-item-icon">
                  {item.icon}
                </span>
                <div className="criteria-option-selector__menu-item-data">
                  <span className="criteria-option-selector__menu-item-header">
                    {item.header}
                    {value === item.value && <TickIcon />}
                  </span>
                  <span className="criteria-option-selector__menu-item-text">
                    {item.text}
                  </span>
                </div>
              </div>
            ))}
          </DropdownPortalMenu>
        }
        visible={isOpen}
      >
        <span
          className="criteria-option-selector__value"
          onClick={() => setIsOpen(true)}
        >
          {selectedOption?.icon}
        </span>
      </DropdownPortal>
    </span>
  );
}
