import React, { useEffect, useState } from "react";
import "./Teams.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import axios, { AxiosResponse } from "axios";
import { API_URLS } from "src/utils/API_URLS";
import { showToast } from "src/app/methods/showToast";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "src/redux/reducers";
import { changeSettingsObjectUuid, changeSettingsSection } from "../../methods";
import { TeamList } from "./components/TeamList/TeamList";
import { TeamDetails } from "./components/TeamDetails/TeamDetails";
import { AddTeam } from "./components/AddTeam/AddTeam";

export enum TeamsSection {
  TeamList = "team-list",
  TeamDetails = "team-details",
  AddTeam = "add-team",
}

interface Props {
  onClose: () => void;
}

export const Teams: React.FC<Props> = (props) => {
  const { onClose } = props;

  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    mainReducer: { settingsModalData, activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const { section } = settingsModalData;

  const history = useHistory();

  const setSection = (newSection: TeamsSection) => {
    changeSettingsSection(history, newSection);
  };

  const setObjectUuid = (objectUuid: string) => {
    changeSettingsObjectUuid(history, objectUuid);
  };

  useEffect(() => {
    getTeams();
  }, []);

  const getTeams = () => {
    setLoading(true);

    const url = API_URLS.teamsForWorkspace.replace(
      ":wsWorkspaceUuid:",
      activeWorkspaceUuid,
    );

    axios
      .get(url)
      .then((response: AxiosResponse) => {
        const { content } = response.data;
        setTeams(content);
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_something_went_wrong"
            defaultMessage="Something went wrong"
          />,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderTeamsSection = () => {
    const sharedProps = {
      getTeams,
      setSection,
      setObjectUuid,
      onClose,
    };

    switch (section) {
      case TeamsSection.TeamList:
        return <TeamList teams={teams} loading={loading} {...sharedProps} />;

      case TeamsSection.TeamDetails:
        return <TeamDetails teams={teams} {...sharedProps} />;

      case TeamsSection.AddTeam:
        return <AddTeam {...sharedProps} />;

      default:
        return null;
    }
  };

  return renderTeamsSection();
};
