import React, { useState } from "react";
import "./CouponsLearnModeModal.scss";

import Modal from "src/app/components/Modal/Modal";
import Tabs from "src/app/components/Tabs/Tabs";
import Tab from "src/app/components/Tabs/Tab";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

import { Button } from "src/app/components/Button/Button";
import { ReactComponent as ChevronIcon } from "src/images/chevron-left.svg";
import { HowItWorks } from "./sections/HowItWorks/HowItWorks";
import { WhatAreCoupons } from "./sections/WhatAreCoupons/WhatAreCoupons";

interface Props {
  onClose: () => void;
}

type ActiveTab = "what-are-coupons" | "how-it-works";

export const CouponsLearnModeModal: React.FC<Props> = (props) => {
  const { onClose } = props;

  const [activeTab, setActiveTab] = useState<ActiveTab>("what-are-coupons");
  const [currentStep, setCurrentStep] = useState(1);

  const Chevrons = (
    <AnimatedDiv className="chevrons">
      <Button
        className="chevron-button"
        variant="blue"
        size="large"
        disabled={currentStep === 1}
        onClick={() => setCurrentStep((v) => v - 1)}
      >
        <ChevronIcon />
      </Button>
      <Button
        className="chevron-button"
        variant="blue"
        size="large"
        disabled={currentStep === 3}
        onClick={() => setCurrentStep((v) => v + 1)}
      >
        <ChevronIcon />
      </Button>
    </AnimatedDiv>
  );

  const CustomButtons = activeTab === "how-it-works" ? Chevrons : null;

  return (
    <Modal
      className="coupons-learn-more-modal"
      onClose={onClose}
      onCancelClick={onClose}
      displayCancelButton
      customButton={CustomButtons}
    >
      <div className="coupons-learn-more-modal__topbar">
        <span className="coupons-learn-more-modal__title">
          <IDHFormattedMessage
            id="ws_coupon_groups"
            defaultMessage="Coupon groups"
          />
        </span>

        {activeTab === "how-it-works" && (
          <AnimatedDiv className="coupons-learn-more-modal__steps">
            {currentStep}
            <span className="coupons-learn-more-modal__steps-divider">/</span>3
          </AnimatedDiv>
        )}
      </div>

      <Tabs>
        <Tab
          tabText={
            <IDHFormattedMessage
              id="ws_what_are_coupons"
              defaultMessage="What are coupons?"
            />
          }
          active={activeTab === "what-are-coupons"}
          onClick={() => setActiveTab("what-are-coupons")}
        />
        <Tab
          tabText={
            <IDHFormattedMessage
              id="ws_how_it_works"
              defaultMessage="How it works"
            />
          }
          active={activeTab === "how-it-works"}
          onClick={() => setActiveTab("how-it-works")}
        />
      </Tabs>

      {activeTab === "what-are-coupons" && <WhatAreCoupons />}
      {activeTab === "how-it-works" && (
        <HowItWorks
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};
