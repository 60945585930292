import React, {
  useEffect,
  useRef,
  useState,
  ChangeEvent,
  useReducer,
} from "react";

import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import AttachmentLoader from "src/app/Task/Attachments/AttachmentLoader";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import {
  getGlobalTaskDetails,
  getGlobalTasks,
} from "src/redux/creator-database/creatorDatabaseActions";
import { RootState } from "src/redux/reducers";
import AddButton from "../../components/AddButton/AddButton";
import { ReactComponent as PlusIcon } from "../../../images/plus-transparent.svg";
import { ReactComponent as TrashCanIcon } from "../../../images/trash-can.svg";
import { showToast } from "../../methods/showToast";
import { tableDataType } from "../../components/Table/Table";
import {
  deleteFileBasingOnContext,
  deleteTaskFile,
  uploadFileBasingOnContext,
  uploadTaskFile,
} from "../FileField/taskFunctions";
import {
  deleteProjectFile,
  uploadProjectFile,
} from "../FileField/projectFunctions";
import Attachment from "../../Task/Attachments/Attachment";
import { AttachmentPlacement, TaskType } from "../../../types";

import "./MultipleFileField.scss";
import creatorDatabaseFiltersReducer, {
  creatorDatabaseFiltersReducerInitialState,
} from "../../CreatorDatabase/redux/creatorDatabaseFiltersReducer";
import { getDictionaryListElement } from "../../../redux";

export interface MetaFileData {
  assetId: string;
  mimeType: string;
  publicPreviewUrl: string;
  publicDownloadUrl: string;
  name: string;
  loading?: boolean;
}

interface Props {
  files: MetaFileData[];
  uuid: string;
  objectId: string;
  dataType: tableDataType;
  readOnly?: boolean;
}

export const MultipleFileField: React.FC<Props> = (props) => {
  const { files, uuid, objectId, dataType, readOnly } = props;

  const [filesData, setFilesData] = useState(files);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<{
    assetId: string;
    assetName: string;
  }>({ assetId: "", assetName: "" });

  const inputRef = useRef<HTMLInputElement>(null);

  const {
    creatorDatabaseReducer: { globalTaskColumns },
    mainReducer: { activeWorkspaceUuid },
    projectReducer: { taskType },
  } = useSelector((state: RootState) => state);

  const [creatorDatabaseFilters] = useReducer(
    creatorDatabaseFiltersReducer,
    creatorDatabaseFiltersReducerInitialState(globalTaskColumns),
  );
  const { filters, sortBy, sort } = creatorDatabaseFilters;
  const dispatch = useDispatch();
  const params = useParams<{
    projectId: string;
    taskId: string;
    dictionaryUuid: string;
  }>();

  useEffect(() => {
    setFilesData(files);
  }, [files]);

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setIsLoading(true);
      switch (dataType) {
        case tableDataType.Project:
        case tableDataType.ProjectDetails:
          await uploadProjectFile(
            e,
            dispatch,
            uuid,
            objectId,
            activeWorkspaceUuid,
            setFilesData,
          );
          break;

        case tableDataType.Task:
          await uploadTaskFile(
            e,
            dispatch,
            uuid,
            activeWorkspaceUuid,
            setFilesData,
            params.projectId,
            objectId,
          );
          break;
        case tableDataType.GlobalTask:
        case tableDataType.Dictionary:
          await uploadFileBasingOnContext({
            e,
            wsMetaFieldUuid: uuid,
            wsWorkspaceUuid: activeWorkspaceUuid,
            setFilesData,
            wsTaskUuid: objectId,
            thenCallback: () => {
              if (dataType === tableDataType.GlobalTask) {
                setTimeout(() => {
                  dispatch(getGlobalTaskDetails(objectId));
                }, 1000);
              }
            },
            context: dataType,
          });
          break;
        default:
          throw new Error("Unsupported dataType");
      }
    } catch (error) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_something_went_wrong"
          defaultMessage="Something went wrong."
        />,
      );
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      if (inputRef?.current) {
        inputRef.current.value = "";
      }
    }
  };

  const deleteFile = async (assetId: string) => {
    switch (dataType) {
      case tableDataType.ProjectDetails:
        deleteProjectFile(
          assetId,
          uuid,
          dispatch,
          params.projectId,
          setFilesData,
          files,
        );
        break;

      case tableDataType.Task:
        deleteTaskFile(
          assetId,
          uuid,
          dispatch,
          params.projectId,
          setFilesData,
          files,
          objectId,
          taskType,
        );
        break;
      case tableDataType.GlobalTask:
      case tableDataType.Dictionary:
        deleteFileBasingOnContext({
          assetId,
          fieldId: uuid,
          taskId: objectId,
          thenCallback: () => {
            setFilesData((list) =>
              list.filter((item) => item.assetId !== assetId),
            );
            if (dataType === tableDataType.GlobalTask) {
              dispatch(
                getGlobalTasks(activeWorkspaceUuid, { filters, sortBy, sort }),
              );
              dispatch(getGlobalTaskDetails(objectId));
            } else if (dataType === tableDataType.Dictionary) {
              dispatch(
                getDictionaryListElement(params.dictionaryUuid, objectId),
              );
            }
          },
          errorCallback: () => {
            setFilesData(files);
          },
          context: dataType,
        });
        break;
      default:
        showToast("error", "Error", "Could not delete file.");
        break;
    }
  };

  //
  // DOM
  //

  if (!filesData.length && !readOnly) {
    return (
      <div className="add-file-button">
        <AddButton variant="rect" />
        <input ref={inputRef} type="file" multiple onChange={uploadFile} />
      </div>
    );
  }

  return (
    <div className="multiple-file-field">
      {filesData.map((file: MetaFileData, index) => {
        return (
          <div
            className="multiple-file-field__box"
            key={`${index}-${file.name}`}
          >
            {file.loading ? (
              <AttachmentLoader fileName={file.name} />
            ) : (
              <>
                <Attachment
                  data={file}
                  place={AttachmentPlacement.InlineWithLabel}
                  taskType={TaskType.Creator}
                />
                {!readOnly && (
                  <TrashCanIcon
                    className="multiple-file-field__box-delete"
                    onClick={() => {
                      setShowConfirmationModal(true);
                      setFileToDelete({
                        assetId: String(file.assetId),
                        assetName: file?.name,
                      });
                    }}
                  />
                )}
              </>
            )}
          </div>
        );
      })}
      {!isLoading && !readOnly && (
        <span className="multiple-file-field__add-button">
          <input ref={inputRef} type="file" multiple onChange={uploadFile} />
          <PlusIcon />
        </span>
      )}
      {showConfirmationModal && (
        <RemoveModal
          onClose={() => setShowConfirmationModal(false)}
          objectNames={[fileToDelete?.assetName ?? ""]}
          removeFunction={() => {
            setShowConfirmationModal(false);
            deleteFile(fileToDelete.assetId);
          }}
        />
      )}
    </div>
  );
};
