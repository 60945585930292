import React, { useRef, useEffect, useState } from "react";
import "./CurrencyInput.scss";

import NumberFormat, { NumberFormatValues } from "react-number-format";
import { splitNumbers } from "../../../utils/methods";

interface Props {
  inputRef?: React.RefObject<HTMLInputElement>;
  rawValue?: string;
  setRawValue: React.Dispatch<React.SetStateAction<string>>;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  placeholder?: string;
  prefix?: string;
  inlinePrefix?: string;
}

export function CurrencyInput(props: Props) {
  const { rawValue, setRawValue, inputRef, prefix, inlinePrefix, ...rest } =
    props;
  const [formattedValue, setFormattedValue] = useState<string | number>(
    splitNumbers(rawValue!),
  );
  const [width, setWidth] = useState(0);
  const span = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setupWidth();
  }, [formattedValue]);

  const validate = (values: NumberFormatValues) => {
    const { floatValue } = values;
    if (!floatValue) return true;
    return floatValue > -10e13 && floatValue < 10e13;
  };

  const setupWidth = () => {
    if (!inlinePrefix) {
      if (span.current?.offsetWidth! < 7) setWidth(10);
      else setWidth(span.current?.offsetWidth!);
    }
  };

  return (
    <>
      {prefix && (
        <span
          className="currency-code"
          onClick={() => inputRef?.current?.focus()}
        >
          {prefix}
        </span>
      )}
      <NumberFormat
        className="currency-input"
        value={rawValue}
        decimalScale={2}
        onValueChange={(values) => {
          const { formattedValue, value, floatValue } = values;
          setFormattedValue(formattedValue);
          setRawValue(floatValue?.toString() || "");
        }}
        thousandSeparator=" "
        isAllowed={validate}
        getInputRef={inputRef}
        prefix={inlinePrefix}
        style={!inlinePrefix ? { width } : {}}
        {...rest}
      />
      <span className="currency-value currency-value--hidden" ref={span}>
        {formattedValue}
      </span>
    </>
  );
}
