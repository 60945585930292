import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as InfluencersBrandAffinityIcon } from "../../../../../images/profile-data/influencers-brand-affinity.svg";
import { ReactComponent as TickPink } from "../../../../../images/tick-pink.svg";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { InterestsList } from "../components/InterestsList/InterestsList";

import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";

export function CreatorsBrandAffinity(props) {
  const { data, height, onProjectDetails } = props;

  const onShowcase = height;

  return (
    <ProfileBox onProjectDetails={onProjectDetails} block={!onProjectDetails}>
      <PBHeader
        icon={<InfluencersBrandAffinityIcon />}
        title={
          <IDHFormattedMessage
            id="ws_creators_brand_affinity"
            defaultMessage="Creator's Brand Affinity"
          />
        }
        tooltip={
          !onShowcase && (
            <Tooltip
              center
              content={
                <IDHFormattedMessage
                  id="ws_tooltip_creators_brand_affinity"
                  defaultMessage={`List of Brands that fit the most to the Creator’s content.
                E.g. if "Apple" is the brand affinity, it is likely that the creator has:
    
                 Followed @Apple
                 Liked @apple content frequently
                 Written about @apple`}
                />
              }
            />
          )
        }
        onShowcase={onShowcase}
        onProjectDetails={onProjectDetails}
      />

      <InterestsList
        data={data}
        icon={<TickPink />}
        fullWidthItem={onProjectDetails || onShowcase}
      />
    </ProfileBox>
  );
}
