export const HIDDEN_FIELDS = ["completed"];
// Use enum TaskType instead of below
export const CREATOR_TASK_TYPE = "creator";
export const CONTENT_TASK_TYPE = "content";
export const ACTION_TASK_TYPE = "action";
export const PAYMENT_TASK_TYPE = "payment";
export const PUBLICATION_TASK_TYPE = "publication";
export const TASK_TYPES = [
  CREATOR_TASK_TYPE,
  CONTENT_TASK_TYPE,
  ACTION_TASK_TYPE,
  PUBLICATION_TASK_TYPE,
  PAYMENT_TASK_TYPE,
];

export const ACTIVITY_LOGS_LIMIT = 200;
