import React, { useEffect, useState } from "react";
import "./SimpleChart.scss";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";

function SimpleChart({ data, detailedData, color, height }) {
  const [chartData, setChartData] = useState([]);

  const YAxisMargin = 8;

  useEffect(() => {
    if (data) {
      setChartData(parseChartStats(data));
    }
  }, [data]);

  const getMaxValue = () => Math.max(...chartData.map((i) => i.value));

  const setYAxisWidth = () => {
    const maxValue = getMaxValue();
    const maxValueDigitsCount = maxValue.toString().length;
    const approximatelyDigitWidth = 11;

    return maxValueDigitsCount * approximatelyDigitWidth;
  };

  const parseChartStats = (receivedData) => {
    const keys = Object.keys(receivedData);
    return keys.map((label) => {
      return {
        name: label,
        value: Math.ceil(receivedData[label].value),
      };
    });
  };

  const checkChartValues = () => {
    const chartsItemsWithValue = chartData.filter((chartDataItem) => {
      return chartDataItem.value > 0;
    });

    return chartsItemsWithValue.length === 0;
  };

  if (!chartData || chartData?.length === 0 || checkChartValues()) {
    return null;
  }

  return (
    <AnimatedDiv className="chart">
      <ResponsiveContainer width="100%" height={height || 300}>
        <AreaChart
          data={chartData}
          margin={{ top: 5, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id={`color-${color}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color} stopOpacity={0.2} />
              <stop offset="75%" stopColor={color} stopOpacity={0} />
            </linearGradient>
          </defs>

          <XAxis
            dataKey="name"
            padding={{ left: 15, right: 15 }}
            interval={Math.floor(chartData.length / 10)}
            tick={{ fontSize: 14, fontWeight: 600 }}
            tickMargin={16}
          />
          <YAxis
            tickCount={7}
            allowDecimals={false}
            type="number"
            domain={[0, getMaxValue()]}
            // Some kind of magic - recharts do not support ticks to left by default,
            // so I've added textAnchor: "left" , and it destroyed package calculations its width
            // Now it calculates approximately axis width based on numbers of digits of max value
            tick={{ fontSize: 14, fontWeight: 600, textAnchor: "left" }}
            tickMargin={setYAxisWidth()}
            width={setYAxisWidth() + YAxisMargin}
          />
          <CartesianGrid strokeDasharray="2 2" vertical={false} />
          <Tooltip
            content={
              <CustomTooltip color={color} detailedData={detailedData} />
            }
          />

          <Area
            dataKey="value"
            stroke={color}
            fillOpacity={1}
            strokeWidth={4}
            dot={{ strokeWidth: 3, stroke: color, fill: color }}
            activeDot={{ strokeWidth: 3, stroke: color, fill: color }}
            fill={`url(#color-${color})`}
            isAnimationActive
            type="linear"
          />
        </AreaChart>
      </ResponsiveContainer>
    </AnimatedDiv>
  );
}

function CustomTooltip({ active, payload, label, color, detailedData }) {
  if (active && payload && payload.length) {
    const data = detailedData[payload[0]?.payload.name];
    return (
      <div className="chart-tooltip">
        {Object.entries(data).map((item) => {
          const [key, values] = item;
          return (
            <div className="chart-tooltip__row">
              <div>{values.map((v) => v).join(", ")}</div>
              <div className="chart-tooltip__label">{key}</div>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
}

export default SimpleChart;
