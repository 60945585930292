import React from "react";
import classNames from "classnames";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { AttachmentPlacement } from "src/types";
import { getImageSource } from "../../../../utils/methods";
import AttachmentSettingsDropdown from "../../../dropdowns/AttachmentSettingsDropdown/AttachmentSettingsDropdown";
import { ReactComponent as ImageIcon } from "../../../../images/image-attachment.svg";
import { ReactComponent as DragAndDropIcon } from "../../../../images/dnd.svg";
import { ReactComponent as RemoveIcon } from "../../../../images/remove.svg";

export function AttachmentDetails({ name, downloadUrl }) {
  return (
    <div className="attachment-details">
      <div className="attachment-details__name">{name}</div>
      <a
        className="attachment-details__download-link"
        href={downloadUrl}
        download
        onClick={(e) => {
          e.stopPropagation();
          !downloadUrl && e.preventDefault();
        }}
      >
        <IDHFormattedMessage id="ws_download" defaultMessage="Download" />
      </a>
    </div>
  );
}

export function ImageAttachment({
  place,
  data,
  onGalleryAttachmentClick,
  setIsHovered,
  isHovered,
  taskType,
  fileExtension,
  handleRemoveAttachment,
}) {
  const downloadUrl =
    data.publicDownloadUrl || data.downloadUrl || data.publicPreviewUrl;

  if (
    place === AttachmentPlacement.Comment ||
    place === AttachmentPlacement.ContentProposal
  ) {
    return (
      <div className="attachment-wrapper attachment-wrapper--comments">
        {getImageSource(data, "small") !== null ? (
          <img
            src={getImageSource(data, "small")}
            className="comment-image"
            loading="lazy"
            onClick={onGalleryAttachmentClick}
          />
        ) : (
          <ImageIcon
            className="extension-icon"
            onClick={onGalleryAttachmentClick}
          />
        )}
        <AttachmentDetails name={data.name} downloadUrl={downloadUrl} />
      </div>
    );
  }

  if (place === AttachmentPlacement.Dropdown) {
    return (
      <div
        className="attachment-wrapper"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onGalleryAttachmentClick}
      >
        {getImageSource(data, "small") !== null ? (
          <div
            className={classNames(
              "attachment-image",
              "attachment-image__small",
              {
                "attachment-image--hovered": isHovered,
              },
            )}
            style={{
              backgroundImage: `url(${getImageSource(data, "small")})`,
            }}
          />
        ) : (
          <ImageIcon className="extension-icon" />
        )}
        {data.parentUuid && isHovered && (
          <AttachmentSettingsDropdown
            downloadUrl={data.downloadUrl}
            commentId={data.parentUuid}
            assetId={data.id}
            taskType={taskType}
            isImage
          />
        )}
      </div>
    );
  }
  if (place === AttachmentPlacement.InlineWithLabel) {
    return (
      <div
        className={classNames(
          "attachment-wrapper",
          "attachment-wrapper__inline",
        )}
        onClick={onGalleryAttachmentClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {getImageSource(data, "small") !== null ? (
          <div
            className={classNames("attachment-image", {
              "attachment-image--hovered": isHovered,
            })}
            style={{
              backgroundImage: `url(${getImageSource(data, "small")})`,
            }}
          />
        ) : (
          <ImageIcon className="extension-icon" />
        )}
        <div className="attachment-file__details">
          <div className="attachment-file__name">{data.name}</div>
          <div className="attachment-file__download-wrapper">
            <div className="attachment-file__type">
              {fileExtension.toUpperCase()}
            </div>
            {(data.downloadUrl || data.publicPreviewUrl || data.publicUrl) && (
              <a
                className="attachment-file__download"
                href={downloadUrl}
                download
                onClick={(e) => {
                  e.stopPropagation();
                  !downloadUrl && e.preventDefault();
                }}
              >
                <IDHFormattedMessage
                  id="ws_download"
                  defaultMessage="Download"
                />
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
  if (place === AttachmentPlacement.AttachmentPreview) {
    return (
      <div
        className={classNames(
          "attachment-wrapper",
          "attachment-wrapper__preview-img",
          {
            "attachment-wrapper__preview--hovered": isHovered,
          },
        )}
        onClick={onGalleryAttachmentClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {getImageSource(data, "small") !== null ? (
          <>
            <img
              src={getImageSource(data, "small")}
              alt={data.name}
              loading="lazy"
              className="attachment-preview-image"
            />
            <div
              className={classNames("overlay", {
                "overlay--visible": isHovered,
              })}
            />
            <DragAndDropIcon
              className={classNames("dnd-icon dnd-icon--white", {
                "dnd-icon--visible": isHovered,
              })}
            />
            <RemoveIcon
              onClick={handleRemoveAttachment}
              className="remove-icon"
            />
          </>
        ) : (
          <ImageIcon className="extension-icon" />
        )}
      </div>
    );
  }

  if (place === AttachmentPlacement.ContentProposalSummary) {
    return (
      <div
        className="attachment-wrapper"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onGalleryAttachmentClick}
      >
        {getImageSource(data, "small") !== null ? (
          <div
            className={classNames("attachment-image", {
              "attachment-image--hovered": isHovered,
            })}
            style={{
              backgroundImage: `url(${getImageSource(data, "small")})`,
            }}
          />
        ) : (
          <ImageIcon className="extension-icon" />
        )}
      </div>
    );
  }

  return (
    <div
      className="attachment-wrapper"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onGalleryAttachmentClick}
    >
      {getImageSource(data, "small") !== null ? (
        <div
          className={classNames("attachment-image", {
            "attachment-image--hovered": isHovered,
          })}
          style={{
            backgroundImage: `url(${getImageSource(data, "small")})`,
          }}
        />
      ) : (
        <ImageIcon className="extension-icon" />
      )}
      {data.parentUuid && isHovered && (
        <AttachmentSettingsDropdown
          downloadUrl={data.downloadUrl}
          commentId={data.parentUuid}
          assetId={data.id}
          taskType={taskType}
          isImage
        />
      )}
    </div>
  );
}
