import React, { ReactNode } from "react";

import Modal, { ModalText, ModalTitle } from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as TrashCanIcon } from "src/images/trash-can.svg";

import "./RemoveModal.scss";

interface Props {
  onClose: () => void;
  warningMessage?: ReactNode;
  objectNames?: string[];
  loading?: boolean;
  removeFunction: () => Promise<void> | void;
}

const OBJECT_ITEM_LIMIT = 3;

export const RemoveModal: React.FC<Props> = (props) => {
  const {
    onClose,
    warningMessage,
    objectNames = [],
    loading,
    removeFunction,
  } = props;

  const handleConfirm = async () => {
    await removeFunction();
    onClose();
  };

  return (
    <Modal
      variant="small"
      className="remove-modal"
      overlayClassName="remove-modal__overlay"
      onClose={onClose}
      onCancelClick={onClose}
      displayCancelButton
      closeButtonVariant="white-with-black-border"
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      confirmButtonText={
        <IDHFormattedMessage id="ws_remove" defaultMessage="Remove" />
      }
      confirmButtonVariant="red"
      onConfirmClick={handleConfirm}
      confirmButtonLoading={loading}
    >
      <ModalTitle>
        <TrashCanIcon className="remove-modal__trashcan" />
        <IDHFormattedMessage id="ws_remove" defaultMessage="Remove" />
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_remove_modal_are_you_sure_you_want_to_remove"
          defaultMessage="Are you sure you want to remove:"
        />
      </ModalText>
      {objectNames?.length > 0 && (
        <div className="remove-modal__object-name-list">
          <ul>
            {objectNames.slice(0, OBJECT_ITEM_LIMIT).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
            {objectNames?.length > OBJECT_ITEM_LIMIT && (
              <li
                key={1000}
                className="remove-modal__object-name-list-over-limit"
              >
                <IDHFormattedMessage id="ws_and" defaultMessage="and" />
                &nbsp;{objectNames?.length - OBJECT_ITEM_LIMIT}&nbsp;
                <IDHFormattedMessage
                  id="ws_remove_modal_items_more"
                  defaultMessage="items more"
                />
              </li>
            )}
          </ul>
        </div>
      )}
      <ModalText>
        {warningMessage || (
          <IDHFormattedMessage
            id="ws_this_action_cannot_be_undone"
            defaultMessage="This action cannot be undone."
          />
        )}
      </ModalText>
    </Modal>
  );
};
