import React, { HTMLProps, MouseEvent, useRef } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setLastSelectedRowID } from "src/redux";

export function IndeterminateCheckbox({
  row,
  table,
  indeterminate,
  className = "",
  ...rest
}: {
  row?: any;
  table: any;
  indeterminate?: boolean;
} & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  const lastSelectedRowID = useSelector(
    (state: RootStateOrAny) => state.taskReducer.lastSelectedRowID,
  );

  const dispatch = useDispatch();

  const getRowRange = (
    rows: any,
    currentIndex: number,
    selectedIndex: number,
  ) => {
    const rangeStart =
      selectedIndex > currentIndex ? currentIndex : selectedIndex;
    const rangeEnd = rangeStart === currentIndex ? selectedIndex : currentIndex;
    return rows.slice(rangeStart, rangeEnd + 1);
  };

  const handleCheckboxClick = (e: MouseEvent<HTMLInputElement>) => {
    if (!row) {
      dispatch(setLastSelectedRowID(""));
      return;
    }

    if (e.shiftKey && lastSelectedRowID) {
      const { rows, rowsById } = table.getRowModel();
      const rowsToToggle = getRowRange(
        rows,
        rowsById[row.id].index,
        rowsById[lastSelectedRowID].index,
      );
      const isCellSelected = rowsById[row.id].getIsSelected();
      rowsToToggle.forEach((_row: any) => _row.toggleSelected(!isCellSelected));
    } else {
      row.toggleSelected();
    }

    if (e.shiftKey) {
      dispatch(setLastSelectedRowID(row.id));
    } else {
      dispatch(setLastSelectedRowID(""));
    }
  };

  return (
    <input
      type="checkbox"
      ref={ref}
      className={`${className} cursor-pointer`}
      onClick={handleCheckboxClick}
      {...rest}
    />
  );
}
