import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalTitle } from "src/app/components/Modal/Modal";
import Loader from "src/app/components/Loader/Loader";
import {
  WsExtensionXtrmPayments,
  XtrmPaymentsDetails,
  ExtensionTypeEnum,
} from "src/types";
import { clearSettingsGlobalFields } from "src/redux";
import { SettingsButtons } from "../../../components/SettingsTabContent/SettingsTabContent";
import { IntegrationsSection } from "../Integrations";
import { ChangeSettingsSectionParam } from "../../../methods";
import { getExtensionDetailsByUuid } from "../../Extensions/utils";
import XTRMPaymentsTabs from "./XTRMPaymentsTabs";
import XTRMPaymentsWallets from "./tabs/XTRMPaymentsWallets";
import XTRMPaymentsGeneral from "./tabs/XTRMPaymentsGeneral";
import XTRMPaymentsSettings from "./tabs/XTRMPaymentsSettings";

import "./XTRMPayments.scss";

interface XTRMPaymentsProps {
  setExtensionsSection: (section: ChangeSettingsSectionParam) => void;
}

export enum ActiveTabs {
  Wallets = "wallets",
  General = "general",
  Settings = "settings",
}

export const xtrmPaymentsDetailsInitialData: XtrmPaymentsDetails = {
  amountWsTaskGlobalFieldUuid: null,
  emailWsTaskGlobalFieldUuid: null,
  beneficiaryId: "",
  companyContact: "",
  companyEmail: "",
  companyName: "",
  identityLevel: "",
  paymentsHistoryUrl: "",
  type: ExtensionTypeEnum.XtrmPayments,
  uuid: "",
  wallets: [],
};

interface XTRMPaymentsProps {
  onClose: () => void;
  setExtensionsSection: (section: ChangeSettingsSectionParam) => void;
  xtrmPaymentsExtension: WsExtensionXtrmPayments;
}

export default function XTRMPayments({
  onClose,
  setExtensionsSection,
  xtrmPaymentsExtension,
}: XTRMPaymentsProps) {
  const [activeTab, setActiveTab] = useState<ActiveTabs>(ActiveTabs.Wallets);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [xtrmPaymentsDetails, setXtrmPaymentsDetails] =
    useState<XtrmPaymentsDetails>(xtrmPaymentsDetailsInitialData);

  const dispatch = useDispatch();

  const renderActiveTab = () => {
    switch (activeTab) {
      case ActiveTabs.Wallets:
        return <XTRMPaymentsWallets wallets={xtrmPaymentsDetails.wallets} />;
      case ActiveTabs.General:
        return (
          <XTRMPaymentsGeneral xtrmPaymentsDetails={xtrmPaymentsDetails} />
        );
      case ActiveTabs.Settings:
        return (
          <XTRMPaymentsSettings xtrmPaymentsDetails={xtrmPaymentsDetails} />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const getXtrmPaymentsDetails = async (uuid: string) => {
      const data = await getExtensionDetailsByUuid<{
        content: XtrmPaymentsDetails;
      }>({
        uuid,
        initialCallback: () => setIsLoading(true),
        finallyCallback: () => setIsLoading(false),
      });
      if (data) {
        setXtrmPaymentsDetails(data.content);
      }
    };
    getXtrmPaymentsDetails(xtrmPaymentsExtension.uuid);
  }, [xtrmPaymentsExtension.uuid]);

  useEffect(() => {
    return () => {
      dispatch(clearSettingsGlobalFields());
    };
  }, []);

  return (
    <>
      <div className="extensions__header">
        <button
          onClick={() =>
            setExtensionsSection(IntegrationsSection.ExtensionsList)
          }
          className="extensions__back-btn"
        >
          <IDHFormattedMessage
            id="ws_integrations"
            defaultMessage="Integrations"
          />
        </button>
        <ModalTitle>
          <IDHFormattedMessage id="ws_xtrm" defaultMessage="XTRM" />
        </ModalTitle>
      </div>
      <div className="xtrmPayments">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="xtrmPayments__content">
              <XTRMPaymentsTabs
                tabs={[
                  ActiveTabs.Wallets,
                  ActiveTabs.General,
                  ActiveTabs.Settings,
                ]}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              {renderActiveTab()}
            </div>
            <SettingsButtons onClose={onClose} />
          </>
        )}
      </div>
    </>
  );
}
