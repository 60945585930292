import React from "react";

import { AvgViews } from "src/app/Task/tabs/ProfileDataTab/single/AvgViews";
import { Followers } from "../../../../Task/tabs/ProfileDataTab/single/Followers";

export const renderAvgViews = (label, data, layout, GridBoxComponent) => {
  const {
    user_data: { avgViews },
    viewsStatisticsData,
  } = data;

  const key = `${label}-avg-views`;
  const height = layout?.find((item) => item.i === key)?.h;

  return (
    <GridBoxComponent key={key} id={key}>
      <AvgViews
        avgViews={avgViews}
        viewsStatisticsData={viewsStatisticsData}
        height={height}
      />
    </GridBoxComponent>
  );
};
