import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import LazyLoad from "react-lazy-load";
import { useSelector } from "react-redux";

import { DropdownPortalMenu } from "src/app/components/DropdownPortal";

import AvatarPlaceholder from "src/images/avatar-purple.svg";
import { IMember } from "src/app/project/ProjectTypes";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import { MemberOption } from "src/app/dropdowns/ChoosePersonDropdown/ChoosePersonDropdown";
import axios, { AxiosResponse } from "axios";
import { tableDataType } from "src/app/components/Table/Table";
import { RootState } from "src/redux/reducers";
import { API_URLS } from "../../../../../utils/API_URLS";
import { showToast } from "../../../../methods/showToast";
import IDHFormattedMessage from "../../../IDHFormattedMessage/IDHFormattedMessage";
import { SkeletonCircle, SkeletonText } from "../../../Skeleton/Skeleton";
import { DropdownType, GlideDataGridContext } from "../../types";

interface Props {
  uuid?: string;
  dataType?: GlideDataGridContext;
  choosePerson: Dispatch<SetStateAction<any>>;
  displayOnlyMenu?: boolean;
  wsTeamUuids?: any;
  onClose: () => void;
}

const MembersDropdown: React.FC<Props> = (props) => {
  const { uuid, dataType, choosePerson, onClose, wsTeamUuids } = props;

  const { membersList } = useSelector(
    (state: RootState) => state.projectReducer,
  );

  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );

  const [memberOptions, setMemberOptions] = useState<MemberOption[]>([]);
  const [teamMemberUuids, setTeamMemberUuids] = useState<string[]>([]);
  const [isLoader, setIsLoader] = useState<boolean>(false);

  useEffect(() => {
    checkMemberFilterByTeam();
  }, []);

  useEffect(() => {
    if (membersList) {
      const options = filterByTeamMember().map((member: IMember) => ({
        label: (
          <div
            key={member.id}
            className="member-option"
            data-value={member.name}
          >
            <LazyLoad
              offset={300}
              width={24}
              height={24}
              className="member-option__photo-loader"
            >
              <img
                className="member-option__photo"
                src={member.avatarUrl || AvatarPlaceholder}
                alt="avatar"
              />
            </LazyLoad>
            <div>
              <span className="member-option__name">{member.name}</span>
            </div>
          </div>
        ),
        value: { email: member.email, name: member.name, id: member.id },
      }));

      setMemberOptions(options);
    }
  }, [membersList, teamMemberUuids]);

  const filterOption = (option: MemberOption, rawInput: string) => {
    return (
      option.value.name.toLowerCase().includes(rawInput.toLowerCase()) ||
      option.value.email.toLowerCase().includes(rawInput.toLowerCase())
    );
  };

  const changeAssignee = (newValue: MemberOption) => {
    const newAssignee: IMember | undefined = membersList.find(
      (member: IMember) => member.id === newValue.value.id,
    );

    if (!newAssignee) return;

    choosePerson(newAssignee);
  };

  const filterByTeamMember = () => {
    if (wsTeamUuids?.length > 0) {
      return membersList.filter(
        (member: IMember) =>
          teamMemberUuids.includes(member.id) && member?.status !== "removed",
      );
    }

    return membersList?.filter(
      (member: IMember) => member?.status !== "removed",
    );
  };

  const checkMemberFilterByTeam = () => {
    if (wsTeamUuids?.length > 0) {
      setIsLoader(true);
      let url =
        dataType === tableDataType.Dictionary
          ? API_URLS.dictionaryMetaFieldTeamMembers
          : API_URLS.globalTaskMetaFieldTeamMembers;

      url = url.replace(":wsWorkspaceUuid:", activeWorkspaceUuid);

      axios
        .get(url.replace(":wsMetaFieldUuid:", uuid ?? ""))
        .then((response: AxiosResponse) => {
          const {
            data: { content },
          } = response;
          setTeamMemberUuids(content);
        })
        .catch((error) => {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage
              id="ws_something_went_wrong"
              defaultMessage="Something went wrong"
            />,
          );
        })
        .finally(() => setIsLoader(false));
    }
  };

  const Menu = (
    <DropdownPortalMenu
      className="choose-person-dropdown-menu"
      onMouseLeave={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        e.stopPropagation()
      }
    >
      {isLoader ? (
        <div className="choose-person-dropdown__skeleton">
          <div className="choose-person-dropdown__skeleton-row">
            <SkeletonCircle size={24} marginRight={12} />
            <SkeletonText height={12} width={300} />
          </div>

          <div className="choose-person-dropdown__skeleton-row">
            <SkeletonCircle size={24} marginRight={12} />
            <SkeletonText height={12} width={300} />
          </div>

          <div className="choose-person-dropdown__skeleton-row">
            <SkeletonCircle size={24} marginRight={12} />
            <SkeletonText height={12} width={300} />
          </div>
        </div>
      ) : (
        <CustomSelect
          onChange={(newValue: MemberOption) => {
            changeAssignee(newValue);
          }}
          isSearchable
          forceFocus
          options={memberOptions}
          filterOption={filterOption}
          menuIsOpen
          onMenuClose={onClose}
        />
      )}
    </DropdownPortalMenu>
  );

  return <div className="choose-person-dropdown">{Menu}</div>;
};

export default MembersDropdown;
