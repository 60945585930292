import React, { useState } from "react";
import moment from "moment";
import axios from "axios";
import METHODS from "../../../../helpers/methods";

import {
  CDTableColumn,
  CDTableRow,
} from "../../../../components/CDTable/CDTable";

import { InfluencerPhotosCell } from "../../cells/InfluencerPhotosCell/InfluencerPhotosCell";
import CampaignInfoCell from "../../cells/CampaignInfoCell/CampaignInfoCell";
import { AuthorCell } from "../../cells/AuthorCell/AuthorCell";
import { ActionCell } from "../../cells/ActionCell/ActionCell";
import { CustomSwitch } from "../../../../../../../../components/CustomSwitch/CustomSwitch";
import CONSTS from "../../../../helpers/consts";
import { showToast } from "../../../../../../../../methods/showToast";
import IDHFormattedMessage from "../../../../../../../../components/IDHFormattedMessage/IDHFormattedMessage";
import { CampaignInfoCellTooltip } from "../../cells/CampaignInfoCell/CampaignInfoCellTooltip/CampaignInfoCellTooltip";
import { MaterialTooltip } from "../../../../../../../../components/MaterialTooltip/MaterialTooltip";
import BrandSafetyEmailNotificationCell from "../../cells/BrandSafetyEmailNotificationCell/BrandSafetyEmailNotificationCell";

export function InfluencersListRow({
  activeWorkspaceUuid,
  listItem,
  hasAccessToDisplayListAuthor,
  exportList,
  goToDetails,
  onDeleteListClick,
  onDuplicateClick,
}) {
  const [published, setPublished] = useState(listItem.isPublic);
  const [
    isBrandSafetyEmailNotificationEnabled,
    setIsBrandSafetyEmailNotificationEnabled,
  ] = useState(listItem.isBrandSafetyEmailNotificationEnabled);

  return (
    <CDTableRow key={listItem.listId}>
      <CDTableColumn>
        <InfluencerPhotosCell
          headerLink={`/workspace/${activeWorkspaceUuid}/creator-discovery-tool/list/${listItem.listId}?activeTab=selected`}
          listItem={listItem}
        />
      </CDTableColumn>
      <CDTableColumn>
        <CampaignInfoCell
          name={METHODS.limitListName(listItem.listName)}
          headerLink={`/workspace/${activeWorkspaceUuid}/creator-discovery-tool/list/${listItem.listId}?activeTab=selected`}
          listItem={listItem}
          published={published}
          setPublished={setPublished}
        />
      </CDTableColumn>
      <CDTableColumn>{listItem.usersCount}</CDTableColumn>
      <CDTableColumn>
        {listItem.avgEr ? `${listItem.avgEr}%` : "-"}
      </CDTableColumn>
      <CDTableColumn>{listItem.avgVelocityIndex}</CDTableColumn>
      <CDTableColumn>
        {moment(listItem.lastEdited).format("DD.MM.YYYY")}
      </CDTableColumn>
      <CDTableColumn>
        <BrandSafetyEmailNotificationCell
          listItem={listItem}
          isBrandSafetyEmailNotificationEnabled={
            isBrandSafetyEmailNotificationEnabled
          }
          setIsBrandSafetyEmailNotificationEnabled={
            setIsBrandSafetyEmailNotificationEnabled
          }
        />
      </CDTableColumn>
      <CDTableColumn>
        <AuthorCell
          name={listItem.wsMemberName}
          avatarUrl={listItem.wsMemberAvatarUrl}
        />
      </CDTableColumn>
      <CDTableColumn>
        <ActionCell
          listItem={listItem}
          exportList={exportList}
          goToDetails={goToDetails}
          onDeleteListClick={onDeleteListClick}
          onDuplicateClick={onDuplicateClick}
          published={published}
          setPublished={setPublished}
        />
      </CDTableColumn>
    </CDTableRow>
  );
}
