import React, { useState } from "react";

import { useParams } from "react-router";
import axios from "axios";

import { API_URLS } from "src/utils/API_URLS";
import { showToast } from "src/app/methods/showToast";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { validate, clearValues } from "./utils/utils";
import { AccessLinkButtonsComponent } from "./AccessLinkButtonsComponent";

export function AccessLinkPageFormButtons({
  values,
  setValues,
  setPageHandler,
  setErrors,
  submitDisabled,
}) {
  const { taskUuid, type, metafieldUuid, token } = useParams();
  const [loading, setLoading] = useState(false);

  const submitValues = async (e) => {
    if (validate(values, setErrors)) {
      setLoading(true);
      try {
        await axios.put(
          API_URLS.updateTaskLinkData,
          {
            metafields: values.filter((v) => v.accessMode === "write"),
          },
          {
            params: {
              wsMetaFieldUuid: metafieldUuid,
              token,
              metaFieldType: type,
              wsTaskUuid: taskUuid,
            },
          },
        );
        setPageHandler("success");
      } catch (error) {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_something_went_wrong_during_adding_link"
            defaultMessage="Something went wrong during adding link"
          />,
        );
      } finally {
        setLoading(false);
      }
    } else {
      showToast(
        "info",
        <IDHFormattedMessage
          id="ws_some_fields_are_required"
          defaultMessage="Some fields are required."
        />,
        <IDHFormattedMessage
          id="ws_please_fill_required_fields"
          defaultMessage="Please fill required fields."
        />,
      );
    }
  };

  return (
    <AccessLinkButtonsComponent
      clearValues={() => clearValues(values, setValues, setErrors)}
      submitValues={submitValues}
      submitDisabled={submitDisabled || loading}
    />
  );
}
