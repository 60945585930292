import React from "react";
import "./SocialProviderIcon2.scss";
import classNames from "classnames";

import { ReactComponent as InstagramIcon } from "src/images/social/instagram-color.svg";
import { ReactComponent as YouTubeIcon } from "src/images/social/youtube-color.svg";
import { ReactComponent as TikTokIcon } from "src/images/social/tiktok-color.svg";
import { ReactComponent as SnapchatIcon } from "src/images/social/snapchat.svg";

import { ReactComponent as InstagramGreyIcon } from "src/images/social/instagram.svg";
import { ReactComponent as YouTubeGreyIcon } from "src/images/social/youtube.svg";
import { ReactComponent as TikTokGreyIcon } from "src/images/social/tiktok.svg";
import { ReactComponent as SnapchatGreyIcon } from "src/images/social/snapchat-grey.svg";

export function SocialProviderIcon2({ provider, grey = false }) {
  const classNameStr = classNames([
    "social-provider-icon2",
    `social-provider-icon2--${provider}`,
    grey && "social-provider-icon2--grey",
  ]);

  switch (provider) {
    case "instagram":
      return grey ? (
        <InstagramGreyIcon className={classNameStr} />
      ) : (
        <InstagramIcon className={classNameStr} />
      );
    case "youtube":
      return grey ? (
        <YouTubeGreyIcon className={classNameStr} />
      ) : (
        <YouTubeIcon className={classNameStr} />
      );
    case "tik_tok":
      return grey ? (
        <TikTokGreyIcon className={classNameStr} />
      ) : (
        <TikTokIcon className={classNameStr} />
      );
    case "snapchat":
      return grey ? (
        <SnapchatGreyIcon className={classNameStr} />
      ) : (
        <SnapchatIcon className={classNameStr} />
      );
    default:
      return <></>;
  }
}
