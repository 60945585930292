import React from "react";
import "./SingleSelectedLabel.scss";

import classNames from "classnames";
import { ReactComponent as CrossBlueIcon } from "src/images/cross-blue.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export function SingleSelectedLabel({
  value,
  onRemove,
  translative,
  getFullObject,
  disabled,
}) {
  return (
    <div
      className={classNames("select-tags__selected-labels__single", {
        "select-tags__selected-labels__single--disabled": disabled,
      })}
      data-filter-name="paid_post_performance"
    >
      {translative ? (
        <IDHFormattedMessage
          id={getFullObject(value).name}
          defaultMessage={getFullObject(value).value}
        />
      ) : (
        value
      )}
      <CrossBlueIcon
        className="select-tags__selected-labels__single__close"
        onClick={onRemove}
      />
    </div>
  );
}
