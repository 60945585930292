import React from "react";
import { injectIntl } from "react-intl";
import { AppConfig } from "../../../config/AppConfig";
import XssFilter from "../XssFilter/XssFilter";

function IDHFormattedMessage(props) {
  const { defaultMessage, id, intl } = props;

  if (!id) return null;
  if (!AppConfig.isRunningLocalDevelopmentMode()) {
    if (AppConfig.getEnvironmentName() !== "live" && !(id in intl.messages)) {
      return id;
    }
  }

  const html = intl.formatMessage({
    id,
    defaultMessage: defaultMessage || id,
  });
  const cleanHtml = XssFilter(
    html,
    ["p", "b", "a", "u", "i", "ul", "ol", "li", "br"],
    ["style", "target", "href"],
  );

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: cleanHtml,
      }}
    />
  );
}

export default injectIntl(IDHFormattedMessage);
