import React from "react";

import { SocialProfileStatus } from "src/types";
import { getSocialProfileStatusNameBasedOnStatus } from "./utils";
import WarningIcon from "../WarningIcon";
import AwaitingIcon from "../AwaitingIcon";

interface SocialProfileStatusIconProps {
  status: SocialProfileStatus;
  addDataImgSrc?: boolean;
}

export default function SocialProfileStatusIcon({
  addDataImgSrc,
  status,
}: SocialProfileStatusIconProps) {
  if (status === SocialProfileStatus.Fresh) {
    return (
      <AwaitingIcon
        alt={getSocialProfileStatusNameBasedOnStatus(SocialProfileStatus.Fresh)}
        addDataImgSrc={addDataImgSrc}
      />
    );
  }
  if (status === SocialProfileStatus.NotFound) {
    return (
      <WarningIcon
        alt={getSocialProfileStatusNameBasedOnStatus(
          SocialProfileStatus.NotFound,
        )}
        addDataImgSrc={addDataImgSrc}
      />
    );
  }
  return null;
}
