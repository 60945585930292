import React from "react";

interface Props {
  fieldType: string;
  renderBasicTypes: () => JSX.Element;
}

export const ProjectTableField = (props: Props) => {
  const { fieldType, renderBasicTypes } = props;

  const renderProjectField = () => {
    switch (fieldType) {
      default:
        return renderBasicTypes();
    }
  };

  return renderProjectField();
};
