import React from "react";
import "./ReportSummaryLearnings.scss";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export function ReportSummaryLearnings() {
  return (
    <div className="report-summary-learnings">
      <span className="report-summary-learnings__heading">
        <IDHFormattedMessage
          id="ws_summary_learnings"
          defaultMessage="Summary & Learnings"
        />
      </span>
      <div className="report-summary-learnings__content">
        •
        <br />•
        <br />•
        <br />•
        <br />•
      </div>
    </div>
  );
}
