import React, { useState, useEffect, useRef, ChangeEvent } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { uuidv7 as uuid } from "uuidv7";

import "./EditCommentField.scss";
import { showToast } from "src/app/methods/showToast";
import { updateTaskCommentAttachments } from "src/utils/uploadFunctions";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { IntlShape, WrappedComponentProps, injectIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import AttachmentsField from "src/app/components/TextBox/components/AttachmentsField/AttachmentsField";
import { RootState } from "src/redux/reducers";
import {
  editTaskComment,
  setCommentAsEditable,
  getTaskDetails,
} from "src/redux";
import { TextBox } from "src/app/components/TextBox/TextBox";
import { TaskType } from "src/types";
import { AttachmentMetaData } from "../../Attachments/Attachment";
import { AttachmentMetaDataAndKey } from "../AddCommentField/AddCommentField";
import EditCommentFieldButton from "./EditCommentFieldButton";
import AddAttachmentInput from "../AddAttachmentInput/AddAttachmentInput";
import { ALLOWED_ATTACHMENT_EXTENSIONS } from "../../utils/constants";
import { getAcceptedFileExtensions } from "../../Attachments/utils/getAcceptedFileExtensions";
import { handleAddAttachments } from "../../utils/handleAddAttachments";
import { CommentDisplayType } from "../../Task";

interface Props extends WrappedComponentProps<"intl"> {
  comment: string;
  commentId: string;
  taskType: TaskType;
  intl: IntlShape;
}

interface IComment {
  commentId: string;
  attachments: AttachmentMetaData[];
}

function EditCommentField({ commentId, comment, taskType, intl }: Props) {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const params = useParams<{ taskId: string }>();
  const [showMentionList, setShowMentionList] = useState(false);
  const [encodedValue, setEncodedValue] = useState("");
  const [attachments, setAttachments] = useState<AttachmentMetaDataAndKey[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const editCommentFieldRef = useRef<HTMLDivElement>(null);

  const {
    taskReducer: { taskDetails },
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const inputRef = useRef<HTMLInputElement>(null);

  const onSaveChangesClick = async () => {
    setIsLoading(true);
    try {
      await dispatch(editTaskComment(commentId, encodedValue));
      if (taskType !== TaskType.Content) {
        await updateTaskCommentAttachments(attachments, commentId);
      }
      await dispatch(getTaskDetails(params.taskId));
      await dispatch(setCommentAsEditable(null));
    } catch (error) {
      showToast(
        "error",
        <IDHFormattedMessage
          id="ws_something_went_wrong"
          defaultMessage="Something went wrong."
        />,
        <IDHFormattedMessage
          id="ws_could_not_update_comment"
          defaultMessage="Could not update comment."
        />,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onCancelClick = () => {
    dispatch(setCommentAsEditable(null));
  };

  const clearInputValue = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleAddAttachmentFromInput = async (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    if (!e.target.files) return;

    const files = Array.from(e.target.files);

    if (!files.length) return;

    await handleAddAttachments({
      files,
      commentId,
      activeWorkspaceUuid,
      clearInputValue,
      setAttachments,
      commentType: CommentDisplayType.Comment,
    });
  };

  const handleAddAttachmentsFromClipboard = async (
    e: globalThis.ClipboardEvent,
    commentId: string,
  ) => {
    if (!e.clipboardData) return;

    const files = Array.from(e.clipboardData.files);

    if (!files.length) return;

    await handleAddAttachments({
      files,
      commentId,
      activeWorkspaceUuid,
      clearInputValue,
      setAttachments,
      commentType: CommentDisplayType.Comment,
    });
  };

  useEffect(() => {
    setValue(comment);
  }, [comment]);

  useEffect(() => {
    const currentComment: IComment | undefined = taskDetails.comments.find(
      (comment: IComment) => comment.commentId === commentId,
    );

    if (!currentComment) return;

    setAttachments((prev) => {
      const currentCommentAttachments: AttachmentMetaDataAndKey[] =
        currentComment.attachments.map((attachment) => ({
          attachment,
          localUuid: uuid(),
        }));

      return [...prev, ...currentCommentAttachments];
    });
  }, [commentId]);

  useEffect(() => {
    const pasteHandler = async (e: Event) => {
      const clipboardEvent = e as globalThis.ClipboardEvent;
      if (isLoading) return;
      await handleAddAttachmentsFromClipboard(clipboardEvent, commentId);
    };

    editCommentFieldRef.current?.addEventListener("paste", pasteHandler);

    return () =>
      editCommentFieldRef.current?.removeEventListener("paste", pasteHandler);
  }, [isLoading, commentId]);

  return (
    <div className="edit-comment-field" ref={editCommentFieldRef}>
      <TextBox
        id={commentId}
        className="edit-comment-field__textarea"
        value={value}
        placeholder={translateMessage({
          intl,
          id: "ws_write_comment_or_post_update",
          defaultMessage: "Write a comment or post an update...",
        })}
        setEncodedValue={setEncodedValue}
        showMentionList={showMentionList}
        setShowMentionList={setShowMentionList}
        editorBottomChildren={
          taskType !== TaskType.Content && (
            <AttachmentsField
              attachments={attachments}
              setAttachments={setAttachments}
            />
          )
        }
        showButtons
        enableMentions
        bottomLeftButton={
          taskType !== TaskType.Content && (
            <AddAttachmentInput
              handleAddAttachments={handleAddAttachmentFromInput}
              inputRef={inputRef}
              acceptedFileExtensions={getAcceptedFileExtensions(
                ALLOWED_ATTACHMENT_EXTENSIONS,
              )}
            />
          )
        }
        bottomRightButton={
          <EditCommentFieldButton
            onCancelClick={onCancelClick}
            onSaveChangesClick={onSaveChangesClick}
            disabled={
              isLoading || (!encodedValue.length && !attachments.length)
            }
          />
        }
      />
    </div>
  );
}

export default injectIntl(EditCommentField);
