import React from "react";
import "./ContentBox.scss";

import classNames from "classnames";
import DOMPurify from "dompurify";

import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import RatingSelector, {
  RatingSelectorSize,
} from "src/app/components/RatingSelector/RatingSelector";
import { getCountedSelectValues } from "src/app/components/AggregatedSelect/components/AggregatedLabels/AggregatedLabels";
import { useGetMetaField } from "../../../methods/useGetMetaField";
import {
  decodeHyperlinks,
  formatNumber,
  splitNumbers,
  toFixedIfNecessary,
} from "../../../../utils/methods";

const ALLOWED_TAGS = ["b", "u", "br", "i", "a"];

export const renderGeneratorMetaValue = (data, clearValue) => {
  if (clearValue && data?.type !== MetaFieldType.Number) {
    return decodeHyperlinks(data?.value);
  }

  if (data.fieldValueSource === MetaFieldType.EMV) {
    return `${data.data.currencyCode} ${formatNumber(data?.value)}`;
  }

  switch (data.type) {
    case MetaFieldType.Number:
      return formatNumber(data?.value);

    case MetaFieldType.Currency:
      return `${data.data.currencyCode} ${splitNumbers(
        toFixedIfNecessary(data?.value, 2),
      )}`;

    case MetaFieldType.Percent:
      return `${toFixedIfNecessary(data?.value * 100, 2)}%`;

    case MetaFieldType.SingleSelect:
      const selectedOption = data.data.singleSelectOptions?.find(
        (item) => item.value === data.value,
      );
      return selectedOption?.name;

    case MetaFieldType.MultiSelect: {
      const multiValue =
        data?.data?.singleSelectOptions?.filter((item) =>
          data.value?.includes(item.value),
        ) || [];
      return multiValue.map((item) => item.name).join(", ");
    }

    case MetaFieldType.AggregatedSelect: {
      const multiValue =
        data?.data?.singleSelectOptions?.filter((item) =>
          data.value?.includes(item.value),
        ) || [];
      return getCountedSelectValues(multiValue)
        .map((item) => `${item.name} x ${item.count}`)
        .join(", ");
    }

    default: {
      if (typeof data?.value === "string") {
        return decodeHyperlinks(data?.value);
      }

      return null;
    }
  }
};

export function ContentBox(props) {
  const { fieldName, data, block, textField, clearValue } = props;

  const fieldData = useGetMetaField(fieldName, data.metadata);

  if (!fieldData) return null;

  const value = textField
    ? "•\n•\n•"
    : renderGeneratorMetaValue(fieldData, clearValue);

  const renderContent = () => {
    if (fieldData.type === MetaFieldType.Rating) {
      return (
        <RatingSelector
          disabled
          value={parseInt(fieldData?.value || "0")}
          size={RatingSelectorSize.Small}
        />
      );
    }

    return (
      <div
        className="content-box__value"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(value, {
            ALLOWED_TAGS,
          }),
        }}
      />
    );
  };

  return (
    <div className="content-box">
      <div
        className={classNames("content-box__header", {
          "content-box__header--with-margin": fieldData.type === "text",
        })}
      >
        {fieldName}
      </div>
      {renderContent()}
    </div>
  );
}
