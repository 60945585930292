export enum FieldType {
  SingleSelect = "singleSelect",
  MultiSelect = "multiSelect",
  AggregatedSelect = "aggregatedSelect",
  Date = "date",
  BoolVal = "boolVal",
  DictionaryElement = "dictionaryElement",
  Rating = "rating",
}

export enum FieldKey {
  Completed = "completed",
  Assignee = "assignee",
  DueDate = "dueDate",
  PublishingDueDate = "Publishing Due Date",
}

export enum MetafieldAccessMode {
  write = "write",
  read = "read",
}
export enum MemberWithNotificationType {
  member = "member",
  team = "team",
}
