import React from "react";
import moment from "moment";
import classNames from "classnames";
import "./SummaryBoxListItem.scss";

import { useSelector } from "react-redux";
import { useParams } from "react-router";
import DOMPurify from "dompurify";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { momentDateFormat } from "src/app/components/CustomDatePicker/utils";
import { DashedBox } from "../../../components/DashedBox/DashedBox";
import { ProgressField } from "../../../project/TableField/meta-types/ProgressField/ProgressField";
import {
  decodeHyperlinks,
  splitNumbers,
  splitPercents,
} from "../../../../utils/methods";
import { FileField } from "../../../metafields/FileField/FileField";
import { tableDataType } from "../../../components/Table/Table";
import { renderMetaFieldIcon } from "../../../project/TableField/functions/renderMetaFieldIcon";

function SummaryBoxListItem(props) {
  const { el } = props;
  const { membersList } = useSelector((state) => state.projectReducer);

  const params = useParams();

  const renderValue = (el) => {
    switch (el.type) {
      case MetaFieldType.Percent:
        return (
          <div className="project-summary-dashed-box__value">
            {renderMetaFieldIcon(el, tableDataType.Project)}
            {`${splitPercents(el.value)}%`}
          </div>
        );

      case MetaFieldType.Number:
        return (
          <div className="project-summary-dashed-box__value">
            {renderMetaFieldIcon(el, tableDataType.Project)}
            {splitNumbers(el.value)}
          </div>
        );
      case MetaFieldType.Currency:
        return (
          <div className="project-summary-dashed-box__value">
            {renderMetaFieldIcon(el, tableDataType.Project)}
            {splitNumbers(`${el.data.currencyCode} ${el.value}`)}
          </div>
        );
      case MetaFieldType.Date:
        return (
          <div className="project-summary-dashed-box__value">
            {moment(el.value.date, momentDateFormat).format("DD.MM.YYYY")}
          </div>
        );
      case MetaFieldType.ProgressBar:
        return <ProgressField value={el.value || ""} options={el.data} />;
      case MetaFieldType.SingleSelect:
      case MetaFieldType.MultiSelect:
      case MetaFieldType.AggregatedSelect: {
        const options = el.data.singleSelectOptions || [];
        return (
          <div
            className="project-summary-dashed-box__label"
            style={{
              backgroundColor: `#${options[0]?.backgroundColor}`,
              color: `#${options[0]?.color}`,
            }}
          >
            {options[0]?.name}
          </div>
        );
      }
      case MetaFieldType.Member:
        const foundMember = membersList.find(
          (member) => member.id === el.value,
        );
        return (
          <div className="project-summary-dashed-box__member">
            <img
              className="project-summary-dashed-box__member-avatar"
              src={foundMember.avatarUrl}
            />
            <div className="project-summary-dashed-box__member-name">
              {foundMember.name}
            </div>
          </div>
        );
      case MetaFieldType.File:
        return (
          <FileField
            files={el.value}
            uuid={el.uuid}
            objectId={params.projectId}
            dataType={tableDataType.Project}
            readOnly
          />
        );

      case MetaFieldType.Text:
        const ALLOWED_TAGS = ["a", "br"];
        const ADD_ATTR = ["target", "class", "rel"];

        const html = DOMPurify.sanitize(decodeHyperlinks(el.value), {
          ALLOWED_TAGS,
          ADD_ATTR,
        });

        return (
          <div
            className={classNames(
              "project-summary-dashed-box__value",
              "project-summary-dashed-box__value--text",
            )}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        );

      default:
        return (
          <div className="project-summary-dashed-box__value">{el.value}</div>
        );
    }
  };

  return (
    <DashedBox className={classNames("project-summary-dashed-box", {})}>
      <div className="dashed-box-header">{el.name}</div>
      {renderValue(el)}
    </DashedBox>
  );
}

export { SummaryBoxListItem };
