const validate = (values, setErrors) => {
  if (JSON.stringify(values) === "[]") return false;
  const errorFieldIds = [];

  for (let i = 0; i < values.length; i++) {
    if (
      values[i].accessMode === "write" &&
      values[i].required === true &&
      (!values[i].value || values[i].value?.length === 0)
    ) {
      errorFieldIds.push(values[i].wsMetaFieldUuid);
    }
  }
  // set errors
  setErrors(errorFieldIds);
  return errorFieldIds.length === 0;
};

const clearValues = (values, setValues, setErrors) => {
  const newVals = values.map((v) => {
    const isArray = Array.isArray(v.value);

    if (isArray) return { ...v, value: v.value.filter((w) => !w?.new) };

    return v.accessMode === "read" ? v : { ...v, value: "" };
  });
  setValues(newVals);
  setErrors([]);
};

export { validate, clearValues };
