import classNames from "classnames";
import React from "react";

import SingleMetaField from "src/app/Task/components/TaskMetaFields/SingleMetaField";
import { TaskMetaFieldName } from "src/app/Task/components/TaskMetaFields/TaskMetaFieldName";
import { HIGHER_FIELDS } from "src/app/Task/components/TaskMetaFields/TaskMetaFields";
import { tableDataType } from "src/app/components/Table/Table";

interface Props {
  field: any;
  taskId: string;
}

export const GlobalTaskMetaField: React.FC<Props> = (props) => {
  const { field, taskId } = props;

  return (
    <div
      className={classNames("global-data__row", {
        "global-data__row--high": HIGHER_FIELDS.includes(field.type),
      })}
      key={field.uuid}
      data-column-name={field.name}
      data-column-key={field.uuid}
    >
      <TaskMetaFieldName field={field} />
      <SingleMetaField
        metaData={field}
        dataType={tableDataType.GlobalTask}
        externalTaskId={taskId}
      />
    </div>
  );
};
