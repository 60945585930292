import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./StopSharingGroupWithClientModal.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalText, ModalTitle } from "../../components/Modal/Modal";
import { ReactComponent as ClientsGreyIcon } from "../../../images/client-grey.svg";
import { API_URLS } from "../../../utils/API_URLS";
import { wsAxiosPut, wsAxiosPatch } from "../../../helpers/wsAxios";
import { StackedAvatars } from "../../components/StackedAvatars/StackedAvatars";
import { getImageSource } from "../../../utils/methods";
import publicationPlaceholderIcon from "../../../images/publication-placeholder.svg";
import placeholderIcon from "../../../images/placeholder-image.svg";
import { ShareTaskList } from "../ShareMultipleColumnsModal/ShareTaskList";
import { showToast } from "../../methods/showToast";
import {
  localSetColumnsSharing,
  localSetTasksSharing,
  setSelectedTasks,
} from "../../../redux";

function StopSharingGroupWithClientModal(props) {
  const { onClose, metaFieldId, tasks, taskAvatarShape, taskType } = props;

  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    projectReducer: { projectBasicData },
  } = useSelector((state) => state);

  const unshareColumn = async () => {
    setLoading(true);
    await wsAxiosPatch(
      API_URLS.unshareColumn.replace(":metaFieldId:", metaFieldId),
      {},
      `Error occurred during unsharing column`,
    );
    dispatch(localSetColumnsSharing([metaFieldId], false));
    setLoading(false);
    onClose();
  };

  const unshareTask = async () => {
    setLoading(true);
    const taskIds = tasks.map((task) => task.id);
    await wsAxiosPut(
      API_URLS.unshareGroupTask,
      {
        wsTaskUuids: tasks.map((task) => task.id),
      },
      `Error occurred during unsharing task`,
      () => {},
      () => {
        showToast(
          "success",
          <IDHFormattedMessage
            id="ws_sharing_stopped"
            defaultMessage="Sharing stopped."
          />,
          <IDHFormattedMessage
            id="ws_sharing_for_selected_items_disabled"
            defaultMessage="Sharing for selected items is disabled."
          />,
        );
        dispatch(localSetTasksSharing(taskIds, false));
        dispatch(
          setSelectedTasks(
            tasks.map((task) => {
              task.shared = false;
              return task;
            }),
          ),
        );
      },
    );
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      className="stop-sharing-group-with-client-modal"
      onClose={onClose}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      closeButtonVariant="grey"
      onConfirmClick={tasks ? unshareTask : unshareColumn}
      confirmButtonText={
        <IDHFormattedMessage
          id="ws_stop_sharing"
          defaultMessage="Stop sharing"
        />
      }
      confirmButtonVariant="red"
      confirmButtonDisabled={loading}
      confirmButtonLoading={loading}
    >
      <ModalTitle>
        <ClientsGreyIcon className="stop-sharing-with-client-modal__icon" />
        <IDHFormattedMessage
          id="ws_stop_sharing"
          defaultMessage="Stop sharing"
        />
        ,
        <StackedAvatars
          avatars={projectBasicData.members.filter((user) => user.isClient)}
          maxAvatars={2}
          stackedUsers
        />
      </ModalTitle>
      <ModalText>
        {tasks?.length > 0 ? (
          <IDHFormattedMessage
            id="stop_sharing_width_client_modal_text_a"
            defaultMessage="Are you sure you want to stop sharing these items with Clients?"
          />
        ) : (
          <IDHFormattedMessage
            id="stop_sharing_width_client_modal_text_b"
            defaultMessage="Are you sure you want to stop sharing this column with Clients?"
          />
        )}
      </ModalText>

      <ShareTaskList tasks={tasks} taskAvatarShape={taskAvatarShape} />
    </Modal>
  );
}

export default StopSharingGroupWithClientModal;
