import React, { useState, useRef, useEffect } from "react";
import "./Subtask.scss";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Draggable } from "react-beautiful-dnd";
import classNames from "classnames";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { AccessLevel } from "src/redux/dictionary/types";
import { RootState } from "src/redux/reducers";
import { TaskType } from "src/types";
import {
  createSubtask,
  deleteEmptySubtask,
  deleteNewSubtaskWithoutTitle,
  changeTaskMetaFieldValue,
  deleteTask,
} from "../../../redux";
import { showToast } from "../../methods/showToast";

import TaskMenuDropdown from "../../dropdowns/TaskMenuDropdown/TaskMenuDropdown";
import AddButton from "../../components/AddButton/AddButton";
import {
  changeSubtaskName,
  getTaskDetails,
} from "../../../redux/task/taskActions";
import { IMember } from "../../project/ProjectTypes";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import { ReactComponent as PlusTransparentIcon } from "../../../images/plus-transparent.svg";
import { ReactComponent as CloseIcon } from "../../../images/cross.svg";
import ChoosePersonDropdown from "../../dropdowns/ChoosePersonDropdown/ChoosePersonDropdown";
import AvatarPlaceholder from "../../../images/avatar-purple.svg";
import { convertTimezone } from "../../methods/convertTimezone";
import Check from "../../components/Check/Check";
import { PictogramVariants } from "../../components/Pictogram/Pictogram";
import { AppConfig } from "../../../config/AppConfig";

type Props = {
  name: string;
  data: any;
  taskDetails: any;
  subtaskId: string;
  subtaskRank: string;
  isNew?: boolean;
  index: number;
  projectId: string;
};

export interface MetaFieldOptions {
  singleSelectOptions?: any;
  multiSelectOptions?: any;
  progressBarOptions?: {
    maxValue: number;
    isValueDerivedFromPercentType: undefined | boolean;
  };
  icon?: {
    name: string;
    color: PictogramVariants;
  };
  currencyCode?: string;
  wsDictionaryUuid?: string;
  wsTeamUuids?: string[];
  wsDictionarySubFieldUuid?: string;
  wsSelectDataSetUuid?: string;
  relatedFieldAggregationType?: string;
}

export interface Metadata {
  uuid: string;
  name: string;
  type: MetaFieldType;
  data: MetaFieldOptions;
  isVisible: boolean;
  isRequired: boolean;
  label: any;
  value: any;
  key: string;
  formattedValue: string;
  rank: string;
  shared?: boolean;
  taskType: TaskType | null;
  manuallyEdited?: boolean;
  shouldTriggerEditWarning?: boolean;
  valueSource: string | null;
  fieldValueSource?: string | null;
  wsGlobalTaskMetaFieldUuid?: string;
  wsGlobalProjectMetaFieldUuid?: string;
  accessLevel?: AccessLevel;
  valueRequired?: boolean;
  overrideValue?: Metadata;
  blockManualEdit?: boolean;
}
export interface ISubtask {
  id: string;
  metadata: Metadata[];
  rank: string;
  title: string;
  isNew?: boolean;
}

const Subtask: React.FC<Props> = (props) => {
  const {
    name,
    data,
    taskDetails,
    subtaskId,
    subtaskRank,
    index,
    projectId,
    isNew,
  } = props;

  const [value, setValue] = useState<string>("");
  const [assignee, setAssignee] = useState<any>();
  const [assigneeId, setAssigneeId] = useState("");
  const [dueDate, setDueDate] = useState<any>();
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [assigneeMetaField, setAssigneeMetaField] = useState<any>({});
  const [dueDateMetaField, setDueDateMetaField] = useState<any>({});

  const dispatch = useDispatch();
  const inputRef = useRef<any>(null);

  const history = useHistory();
  const params = useParams<{ taskId: string }>();

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );
  const { membersList } = useSelector(
    (state: RootState) => state.projectReducer,
  );

  const { taskId } = params;

  const handleCreateSubtask = async () => {
    try {
      await dispatch(
        createSubtask(
          projectId,
          value.trim(),
          taskDetails.taskId,
          subtaskId,
          subtaskRank,
          activeWorkspaceUuid,
        ),
      );

      dispatch(getTaskDetails(taskDetails.taskId));
    } catch (error) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_could_not_create_action_point"
          defaultMessage="Could not create Action point."
        />,
      );
    }
  };

  const onBlurHandler = () => {
    if (value.length !== 0) {
      if (name.length) {
        handleSubtaskNameChange();
      } else {
        handleCreateSubtask();
      }
    } else if (isNew) {
      dispatch(deleteNewSubtaskWithoutTitle(subtaskId));
    } else {
      handleDeleteEmptySubtask();
    }
  };

  const onDateChange = (newDate: any) => {
    if (newDate && dueDateMetaField?.uuid) {
      dispatch(
        changeTaskMetaFieldValue(data.id, dueDateMetaField.uuid, newDate),
      );
    }
  };

  const handleSubtaskNameChange = () => {
    const titleColumn = taskDetails.metadata.filter(
      (data: { key: string }) => data.key === "title",
    )[0];

    setValue(value.trim());
    dispatch(changeSubtaskName(value.trim(), subtaskId, titleColumn?.uuid));
  };

  const handleDeleteEmptySubtask = async () => {
    await dispatch(deleteEmptySubtask(subtaskId));
    dispatch(getTaskDetails(taskId));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value.trimStart());
  };

  const handleSubtaskDelete = async () => {
    try {
      await dispatch(deleteTask(data.id));

      dispatch(getTaskDetails(taskId));
      showToast(
        "success",
        <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
        <IDHFormattedMessage
          id="ws_goal_deleted"
          defaultMessage="Goal deleted."
        />,
      );
    } catch (error) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_something_went_wrong"
          defaultMessage="Something went wrong."
        />,
      );
    }
  };

  const handleSubtaskOpen = (e?: React.MouseEvent<HTMLDivElement>) => {
    e && e.stopPropagation();
    history.push(
      `/workspace/${activeWorkspaceUuid}/projects/${projectId}/${subtaskId}`,
    );
  };

  const copyLink = (id: string) => {
    const shareLink = `${AppConfig.getAppRoute()}/${activeWorkspaceUuid}/projects/${projectId}/${id}`;
    navigator.clipboard.writeText(shareLink).then(
      () => {
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id="ws_link_copied"
            defaultMessage="Link copied!"
          />,
        );
      },
      (err) => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_error_while_copying"
            defaultMessage="Error while copying!"
          />,
        );
        console.error("Async: Could not copy text: ", err);
      },
    );
  };

  useEffect(() => {
    if (name.length > 0) {
      setValue(name);
    } else {
      inputRef.current.focus();
    }
  }, [name]);

  useEffect(() => {
    if (data.metadata && data.id === subtaskId && membersList) {
      const assigneeMetaFieldData = data.metadata.find(
        (item: any) => item.key === "assignee",
      );

      setAssigneeId(assigneeMetaFieldData.value);
      setAssigneeMetaField(assigneeMetaFieldData);

      const taskAssignee = membersList.find(
        (member: IMember) => member.id === assigneeMetaFieldData.value,
      );

      setAssignee(taskAssignee);
    }
  }, [data, membersList]);

  useEffect(() => {
    if (data.metadata && data.id === subtaskId) {
      const taskDueDate = data.metadata.find(
        (item: any) => item.key === "dueDate",
      );

      if (taskDueDate?.uuid !== dueDateMetaField.uuid) {
        setDueDateMetaField(taskDueDate);
      }

      if (
        taskDueDate?.value &&
        taskDueDate?.value.date.localeCompare(dueDate) !== 0
      ) {
        setDueDate(convertTimezone(taskDueDate.value.date));
      }
    }
  }, [data]);

  useEffect(() => {
    if (assignee && assigneeMetaField.uuid && assignee.id !== assigneeId) {
      dispatch(
        changeTaskMetaFieldValue(
          subtaskId,
          assigneeMetaField.uuid,
          assignee?.id || null,
        ),
      );
      setAssigneeId(assignee.id);
    }
  }, [assignee]);

  useEffect(() => {
    const completed = data?.metadata?.find(
      (field: Metadata) => field.key === "completed",
    );

    if (completed) {
      setIsComplete(completed.value);
    }
  }, [data]);

  const setTaskCompleted = async () => {
    try {
      const completed = data.metadata.find(
        (field: Metadata) => field.key === "completed",
      );

      await dispatch(
        changeTaskMetaFieldValue(data.id, completed.uuid, !isComplete),
      );
      // dispatch(getTaskDetails(taskInfo.taskId));
    } catch (error) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_error_while_completing_an_action"
          defaultMessage="Error while completing an action!"
        />,
      );
    }
  };

  return (
    <Draggable key={subtaskId} draggableId={subtaskId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className={classNames("subtask-container", {
            "subtask-container--dragging-over": snapshot.isDragging,
          })}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <div
            className={classNames("subtask", {
              "subtask--complete": isComplete,
            })}
            onClick={handleSubtaskOpen}
          >
            <span onClick={(e) => e.stopPropagation()}>
              <Check
                isCheck={isComplete}
                setIsCheck={(value) => {
                  setIsComplete(value);
                  setTaskCompleted();
                }}
                big
              />
            </span>
            <div className="subtask__right">
              <div
                className="subtask__right-button"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="date-picker-wrapper">
                  {!dueDate && (
                    <PlusTransparentIcon className="date-picker-wrapper__plus" />
                  )}
                  <CustomDatePicker
                    initialDate={dueDate}
                    dateChangeHandler={onDateChange}
                    nullable
                  />
                </div>
              </div>
              <div
                className="subtask__right-button"
                onClick={(e) => e.stopPropagation()}
              >
                {assignee ? (
                  <>
                    <ChoosePersonDropdown
                      value={assignee}
                      placeholder={assignee?.name}
                      choosePerson={setAssignee}
                    >
                      <div className="assignee-field">
                        <img
                          src={assignee.avatarUrl || AvatarPlaceholder}
                          alt="avatar"
                        />
                      </div>
                    </ChoosePersonDropdown>
                    <div className="ws-table__field-close">
                      <CloseIcon />
                    </div>
                  </>
                ) : (
                  <ChoosePersonDropdown
                    value={assignee}
                    placeholder={assignee?.name}
                    choosePerson={setAssignee}
                  >
                    <AddButton variant="circle" />
                  </ChoosePersonDropdown>
                )}
              </div>
              <div onClick={(e) => e.stopPropagation()}>
                <TaskMenuDropdown
                  taskId={subtaskId}
                  deleteSubtask={
                    value.length === 0 && isNew
                      ? () => dispatch(deleteNewSubtaskWithoutTitle(subtaskId))
                      : handleSubtaskDelete
                  }
                  copyLink={() => copyLink(subtaskId)}
                  openLink={() => handleSubtaskOpen()}
                />
              </div>
            </div>
          </div>
          <input
            ref={inputRef}
            type="text"
            className="subtask__name"
            value={value}
            onChange={(e) => handleNameChange(e)}
            onBlur={onBlurHandler}
          />
        </div>
      )}
    </Draggable>
  );
};

export default Subtask;
