import { colorNameToHex } from "src/utils/colorFunctionsUtils";
import { PictogramVariants } from "./Pictogram";

export const getHexFromPictogramVariant = (variant: PictogramVariants) => {
  let color = "#fff";

  switch (variant) {
    case PictogramVariants.Instagram:
      color = "#C13584";
      break;
    case PictogramVariants.TikTok:
      color = "#00f2ea";
      break;
    case PictogramVariants.YouTube:
      color = "#FF0302";
      break;
    case PictogramVariants.Twitter:
      color = "#1DA1F2";
      break;
    case PictogramVariants.Facebook:
      color = "#1877F2";
      break;
    case PictogramVariants.Discord:
      color = "#5865F2";
      break;
    case PictogramVariants.Twitch:
      color = "#9146FF";
      break;
    case PictogramVariants.Snapchat:
      color = "#FFFB54";
      break;
    default:
      color = colorNameToHex(PictogramVariants[variant].toLowerCase());
      break;
  }

  return color;
};
