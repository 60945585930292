import React, { useEffect, useRef, useState } from "react";
import "./Tooltip.scss";
import classNames from "classnames";
import TooltipSign from "../TooltipSign/TooltipSign";
import useOnClickOutside from "../../methods/useOnClickOutside";

interface Props {
  alignRight?: boolean;
  alignLeft?: boolean;
  autoSize?: boolean;
  big?: boolean;
  center?: boolean;
  children?: React.ReactNode;
  className?: string;
  clickable?: boolean;
  content?: any;
  contentClassName?: string;
  contentHidden?: boolean;
  noBreak?: boolean;
  small?: boolean;
  up?: boolean;
}

function Tooltip({
  alignRight,
  alignLeft,
  autoSize,
  big,
  center,
  children,
  className,
  clickable,
  content,
  contentClassName,
  contentHidden,
  noBreak,
  small,
  up,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    if (clickable) {
      setIsOpen(false);
    }
  });

  const clickHandler = () => {
    if (clickable) {
      setIsOpen(!isOpen);
    }
  };

  // if (bypass) return children;

  return (
    <span
      ref={ref}
      className={classNames(
        "tooltip",
        {
          "tooltip--no-break": noBreak,
          "tooltip--clickable": clickable,
          "tooltip--up": up,
          "tooltip--small": small,
          "tooltip--big": big,
          "tooltip--auto-size": autoSize,
          "tooltip--align-right": alignRight,
          "tooltip--align-left": alignLeft,
          "tooltip--open": clickable && isOpen,
          "tooltip--hovered": isHover,
        },
        className,
      )}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <span onClick={clickHandler}>
        {typeof children === "function"
          ? children(isOpen)
          : children || (
              <TooltipSign
                blue={(clickable && isOpen) || (!clickable && isHover)}
              />
            )}
      </span>
      <TooltipContent
        clickable={clickable}
        isOpen={(clickable ? isOpen : isHover) && !contentHidden}
        center={center}
        content={content}
        className={contentClassName}
      />
    </span>
  );
}

export default Tooltip;

interface ContentProps {
  clickable?: boolean;
  isOpen: boolean;
  center?: boolean;
  content: React.ReactNode;
  className?: string;
}

const TooltipContent: React.FC<ContentProps> = (props) => {
  const { clickable, isOpen, center, content, className } = props;

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowContent(true);
    } else {
      setTimeout(() => {
        setShowContent(false);
      }, 200);
    }
  }, [isOpen]);

  return isOpen || showContent ? (
    <span
      className={classNames(
        "tooltip__content",
        {
          "tooltip__content--open": showContent && isOpen,
          "tooltip__content--center": center,
        },
        className,
      )}
    >
      <span className="tooltip__text">{content}</span>
    </span>
  ) : null;
};
