import React, { ReactElement, ReactNode } from "react";
import "./ActivityFilters.scss";

function ActivityFiltersItem({
  children,
  label,
}: {
  children: ReactNode;
  label: ReactElement;
}) {
  return (
    <div className="filter">
      <div className="filter__title">{label}</div>
      <div className="filter__options">
        <div className="filter__option">{children}</div>
      </div>
    </div>
  );
}

export default ActivityFiltersItem;
