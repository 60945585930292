import React from "react";
import { DictionaryElementSearchResultItem } from "../../../TopSearch";

interface Props {
  data: DictionaryElementSearchResultItem;
}
export function DictionaryElementName(props: Props) {
  const { data } = props;
  return (
    <span className="search-section__item-name">
      {data.wsDictionaryElementTitle}
    </span>
  );
}
