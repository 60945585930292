import React from "react";
import { API_URLS } from "../../../../utils/API_URLS";
import { AutoSuggestionInput } from "../../../components/AutoSuggestionInput/AutoSuggestionInput";
import SocialProfileIcons from "../../../components/IconsWithStatus/SocialProfileIcons/SocialProfileIcons";
import { renderSuggestion } from "./RenderSuggestion";
import { generateCreatorFromString } from "./GenerateCreatorFromString";
import { ReactComponent as CloseIcon } from "../../../../images/close.svg";
import "./AutosuggestedCreatorsInput.scss";
import { injectIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";

function AutosuggestedCreatorsInput(props) {
  const {
    value,
    setValue,
    storedCreators,
    setStoredCreators,
    globalTaskMode,
    intl,
  } = props;

  const dropObject = (obj) => {
    setStoredCreators((prev) => prev.filter((creator) => creator !== obj));
  };

  const renderSelectedOptions = (storedObjects) => {
    return storedObjects.map((obj) => {
      return (
        <span className="selected-option">
          <SocialProfileIcons data={obj.socialProfiles} />
          {obj.username}
          <CloseIcon
            className="close-icon"
            onClick={(e) => {
              e.stopPropagation();
              dropObject(obj);
            }}
          />
        </span>
      );
    });
  };

  return (
    <div className="autosuggested-creators-input autosuggested-creators-input-wrap">
      <AutoSuggestionInput
        // placeholder still not present!!!
        placeholder={translateMessage({
          intl,
          id: "ws_enter_creator_name_or_link",
          defaultMessage: "Enter Creator name or link to profile",
        })}
        value={value}
        setValue={setValue}
        queryUrl={API_URLS.getCreatorAutoSuggestions}
        getSuggestionValue={(suggestion) => suggestion.username}
        renderSuggestion={renderSuggestion}
        renderSelectedOptions={renderSelectedOptions}
        generateObject={generateCreatorFromString}
        storedObjects={storedCreators}
        setStoredObjects={setStoredCreators}
        threshold={2}
        globalTaskMode={globalTaskMode}
      />
    </div>
  );
}

export default injectIntl(AutosuggestedCreatorsInput);
