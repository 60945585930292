export const searchToObject = () => {
  const pairs = window.location.search.substring(1).split("&");
  const obj = {};
  let pair;
  let i;

  for (i in pairs) {
    if (pairs[i] === "") continue;
    pair = pairs[i].split("=");
    if (pair[0] === "filters") {
      obj[decodeURIComponent(pair[0])] = JSON.parse(
        decodeURIComponent(pair[1].replace(/\+/g, "%20")),
      );
    } else obj[pair[0]] = pair[1];
  }
  return obj;
};
