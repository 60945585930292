import React, { useContext, useEffect, useState } from "react";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import { DictionaryFiltersContext } from "../../DictionaryFilters";

export function SingleCheckboxFilter({ col, setWaitEvent }) {
  const { dictionaryFilters } = useContext(DictionaryFiltersContext);
  const { filters } = dictionaryFilters;

  const [value, setValue] = useState(filters[col.uuid]?.value);

  useEffect(() => {
    value !== undefined &&
      value !== filters[col.uuid]?.value &&
      setWaitEvent({
        target: {
          value,
        },
      });
  }, [value]);

  return (
    <>
      <CustomCheckbox
        label="Checked"
        id={`filters-${col.uuid}-true`}
        checked={value === true}
        onChange={(e) => setValue(e.target.checked ? true : null)}
      />
      <CustomCheckbox
        label="Not checked"
        id={`filters-${col.uuid}-false`}
        checked={value === false}
        onChange={(e) => setValue(e.target.checked ? false : null)}
      />
    </>
  );
}
