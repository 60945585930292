import React, { useState } from "react";

import { useSelector } from "react-redux";
import classNames from "classnames";
import axios from "axios";

import {
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import { DropdownPortal } from "src/app/components/DropdownPortal/DropdownPortal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { DropdownMenuPortal } from "src/app/components/DropdownMenuPortal/DropdownMenuPortal";
import { RootState } from "src/redux/reducers";
import { ReactComponent as TickBlueIcon } from "../../../images/tick-blue.svg";
import { ReactComponent as ChevronDownIcon } from "../../../images/chevron-down-alt.svg";
import { API_URLS } from "../../../utils/API_URLS";

import "./SetMetaFieldAccessLevelDropdown.scss";

export enum AccessLevelEnum {
  Viewer = "viewer",
  Editor = "editor",
}

//  TODO: ADD PROPS TYPES !!!!!!!!!!
function SetMetaFieldAccessLevelDropdown(props: any) {
  const {
    wsMemberUuid,
    wsTeamUuid,
    currentAccessLevel,
    wsGlobalTaskMetaFieldUuid,
    disabled,
    getData,
  } = props;

  const [accessLevel, setAccessLevel] = useState(currentAccessLevel);
  const [isOpen, setIsOpen] = useState(false);

  const { activeWorkspaceUuid, identity } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const changeAccessLevel = (newAccessLevel: AccessLevelEnum) => {
    axios
      .put(API_URLS.updateAccessLevelToGlobalMetaField, {
        wsWorkspaceUuid: activeWorkspaceUuid,
        wsGlobalTaskMetaFieldUuid,
        wsTeamUuid,
        wsMemberUuid,
        accessLevel: newAccessLevel,
      })
      .then(() => {
        getData();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChangeAccessLevel = (accessLevel: AccessLevelEnum) => {
    setAccessLevel(accessLevel);
    changeAccessLevel(accessLevel);
    setIsOpen(false);
  };

  const menu = isOpen ? (
    <DropdownMenuPortal
      scrollableContainerIdentifier=".share-modal__user-list"
      setIsOpen={setIsOpen}
      hideOnScroll
    >
      <DropdownMenu isOpen>
        <DropdownMenuItem
          onClick={() => handleChangeAccessLevel(AccessLevelEnum.Viewer)}
        >
          <IDHFormattedMessage id="ws_viewer" defaultMessage="Viewer" />
          {accessLevel === "viewer" && (
            <TickBlueIcon className="dropdown-set-as-access-level-dropdown__tick-blue" />
          )}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => handleChangeAccessLevel(AccessLevelEnum.Editor)}
        >
          <IDHFormattedMessage id="ws_editor" defaultMessage="Editor" />
          {accessLevel === AccessLevelEnum.Editor && (
            <TickBlueIcon className="dropdown-set-as-access-level-dropdown__tick-blue" />
          )}
        </DropdownMenuItem>
      </DropdownMenu>
    </DropdownMenuPortal>
  ) : null;

  const isDisabled = identity?.id === wsMemberUuid || disabled;

  return (
    <DropdownPortal overlay={menu} disabled={isDisabled} visible={isOpen}>
      <div
        className={classNames("dropdown-set-as-access-level-dropdown__button", {
          "dropdown-set-as-access-level-dropdown__button--disabled": isDisabled,
        })}
        onClick={() => !isDisabled && setIsOpen(true)}
      >
        <IDHFormattedMessage
          id={`ws_${accessLevel.toLowerCase()}`}
          defaultMessage={accessLevel}
        />
        <ChevronDownIcon className="dropdown-set-as-access-level-dropdown__chevron-down" />
      </div>
    </DropdownPortal>
  );
}

export { SetMetaFieldAccessLevelDropdown };
