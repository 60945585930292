import React, { useEffect, useState } from "react";

import { useParams } from "react-router";
import axios from "axios";

import { API_URLS } from "src/utils/API_URLS";
import { getSelectDataSetForColumns } from "src/utils/methods";
import { useDispatch } from "react-redux";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import {
  AccessLinkPageContent,
  PageHandler,
} from "./components/AccessLinkPageContent/AccessLinkPageContent";

export function RecruitmentFormAccessLinkPage() {
  const [pageHandler, setPageHandler] = useState<PageHandler>();
  const [anyFieldRequired, setAnyFieldRequired] = useState(false);
  const [response, setResponse] = useState<any>(null);

  const { extensionUuid, token }: { extensionUuid: string; token: string } =
    useParams();

  const dispatch = useDispatch();

  const getRecruitmentFormLinkData = async () => {
    try {
      const url = API_URLS.getRecruitmentFormLinkData
        .replace(":wsExtensionUuid:", extensionUuid)
        .replace(":token:", token);

      const {
        data: { content },
      } = await axios.get(url);
      setResponse({ ...content, extensionUuid });
      if (!content) setPageHandler("expired");
      setAnyFieldRequired(
        content?.metaFields.filter((m: any) => m.metaFieldIsRequired).length >
          0,
      );
    } catch (err) {
      setPageHandler("expired");
    }
  };

  useEffect(() => {
    if (document.body.classList.contains("external-page"))
      getRecruitmentFormLinkData();
    document.body.classList.add("external-page");
  }, []);

  useEffect(() => {
    if (response?.metaFields?.length) {
      getSelectDataSetForColumns(
        dispatch,
        response.metaFields.map((field: Metadata) => ({
          ...field,
          options: field.data,
        })),
      );
    }
  }, [response?.metaFields]);

  return (
    <AccessLinkPageContent
      pageHandler={pageHandler}
      setPageHandler={setPageHandler}
      response={response}
      anyFieldRequired={anyFieldRequired}
      type="recruitmentForm"
    />
  );
}
