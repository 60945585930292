import React from "react";

import Pictogram from "src/app/components/Pictogram/Pictogram";

export default function StatisticsItem({
  id,
  folder,
  name,
  variant,
  children,
}) {
  return (
    <div id={id} className="publication-box__statistics-item">
      <Pictogram identifier={`${folder}/${name}`} variant={variant} />
      {children}
    </div>
  );
}
