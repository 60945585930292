import React from "react";

import { GeographicalReach } from "../../../../Task/tabs/ProfileDataTab/single/GeographicalReach";

export const renderGeographicalReach = (
  label,
  data,
  layout,
  GridBoxComponent,
) => {
  const {
    user_audience_data: { audienceGeographicalReach },
  } = data;

  const key = `${label}-geographical-reach`;
  const height = layout?.find((item) => item.i === key)?.h;

  return (
    <GridBoxComponent key={key} id={key}>
      <GeographicalReach
        audienceGeographicalReach={audienceGeographicalReach}
        height={height}
      />
    </GridBoxComponent>
  );
};
