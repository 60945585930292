import React from "react";

import { ReactComponent as PlusWhiteIcon } from "src/images/plus-white.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import NotFoundImg from "src/images/clickbait.svg";
import { EmptyTabContainer } from "./EmptyTabContainer/EmptyTabContainer";
import { EmptyTabContent } from "./EmptyTabContent/EmptyTabContent";

function EmptyActionsTab(props) {
  const { taskType, onButtonClick } = props;

  const renderText = () => {
    switch (taskType) {
      case "creator":
        return (
          <IDHFormattedMessage
            id="ws_empty_actions_tab_creator_text"
            defaultMessage="No actions has been assigned to this creator. \n Add new or assign already added action."
          />
        );
      case "content":
        return (
          <IDHFormattedMessage
            id="ws_empty_actions_tab_content_text"
            defaultMessage="No actions has been assigned to this content. \n Add new or assign already added action."
          />
        );
    }
  };

  return (
    <EmptyTabContainer className="empty-actions-tab">
      <EmptyTabContent
        text={renderText()}
        buttonText={
          <IDHFormattedMessage id="ws_add_action" defaultMessage="Add action" />
        }
        onButtonClick={onButtonClick}
        buttonIcon={<PlusWhiteIcon />}
        imgUrl={NotFoundImg}
      />
    </EmptyTabContainer>
  );
}

export { EmptyActionsTab };
