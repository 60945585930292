import React from "react";
import "./ProjectsDataTableCell.scss";
import classNames from "classnames";

export function ProjectsDataTableCell({ cell }) {
  const { column, getCellProps, render } = cell;
  const cellProps = getCellProps();

  return (
    <td
      className={classNames([
        "projects-data-table__cell",
        `projects-data-table__cell--${column.id.trim()}`,
      ])}
      {...cellProps}
      data-project-column-title={column.Header}
      data-project-column-origin={column?.origin || column.id}
    >
      <div
        className="projects-data-table__cell__content"
        style={{ width: column.width }}
      >
        {render("Cell")}
      </div>
    </td>
  );
}
