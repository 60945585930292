import React, { useContext, useEffect, useState } from "react";
import "./ClientModeSwitch.scss";

import { useDispatch, useSelector } from "react-redux";
import {
  getProject,
  getProjectDetails,
  getProjectsList,
  getTasksList,
} from "src/redux";
import { AppLocation } from "src/redux/main/mainReducer";
import { API_URLS } from "../../../utils/API_URLS";
import { wsAxiosPatch } from "../../../helpers/wsAxios";
import Tooltip from "../Tooltip/Tooltip";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import { CustomSwitch } from "../CustomSwitch/CustomSwitch";

function ClientModeSwitch(props) {
  const { members, projectId, permissions, clientMode, getData } = props;
  const [isClientModeOn, setIsClientModeOn] = useState(clientMode);

  const {
    mainReducer: { activeWorkspaceUuid, settingsModalData },
    projectReducer: { taskType },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsClientModeOn(clientMode);
  }, [clientMode]);

  const toggleSwitch = async () => {
    await wsAxiosPatch(
      API_URLS.toggleClientMode.replace(":wsProjectUuid:", projectId),
      {
        enabled: !isClientModeOn,
      },
      `Error occurred during switching client mode`,
    );
    setIsClientModeOn((prev) => !prev);
    getData();

    if (settingsModalData.location === AppLocation.ProjectList) {
      dispatch(getProjectsList(activeWorkspaceUuid));
    } else {
      dispatch(getProjectDetails(projectId));
      dispatch(getProject(projectId));
      dispatch(getTasksList(projectId, taskType));
    }
  };

  const hasOneClient = () => {
    return members.find((user) => user.isClient);
  };

  return (
    <div className="client-mode-switch">
      <Tooltip
        center
        content={
          <IDHFormattedMessage
            id="ws_tooltip_cannot_disable_client_mode"
            defaultMessage="You cannot disable client mode as long as you have invited users to the campaign with client permissions."
          />
        }
        contentHidden={!hasOneClient()}
      >
        <CustomSwitch
          checked={isClientModeOn}
          onChange={toggleSwitch}
          disabled={
            !permissions?.project.includes("share_project_resources") ||
            hasOneClient()
          }
        />
      </Tooltip>
      <IDHFormattedMessage
        id="ws_enable_client_mode"
        defaultMessage="Enable “Client mode”"
      />
    </div>
  );
}

export { ClientModeSwitch };
