import React from "react";
import classNames from "classnames";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

function VelocityItem(props) {
  const { title, value, socialProvider } = props;

  return (
    <div
      className={classNames("velocity__item", {
        "velocity__item--ig": socialProvider === "instagram",
        "velocity__item--tt":
          socialProvider === "tik_tok" ||
          socialProvider === "douyin" ||
          socialProvider === "youtube",
      })}
    >
      <div className="velocity__item-title">{title}</div>
      <div className="velocity__item-content">
        <div className="velocity__item-value">{value}%</div>
        <ProgressBar percent={value} />
      </div>
    </div>
  );
}

export default VelocityItem;
