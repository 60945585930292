import React from "react";
import "./MultipleSocialProviders.scss";

import { SingleProvider } from "./SingleProvider/SingleProvider";

export function MultipleSocialProviders({
  currentSocialProvider,
  socialProviders,
}) {
  return (
    <div className="multiple-social-providers">
      {socialProviders &&
        Object.keys(socialProviders).map((key) => (
          <SingleProvider
            key={key}
            providerInfo={socialProviders[key]}
            name={key}
            highlight={currentSocialProvider === key}
          />
        ))}
    </div>
  );
}
