import React, { useEffect, useState } from "react";
import "./SingleAccessField.scss";

import { ReactComponent as TrashCanIcon } from "src/images/trash-can.svg";
import { PopupCol, PopupRow } from "src/app/components/PopUp";
import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import classNames from "classnames";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { injectIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { TextBoxWrapper } from "src/app/components/TextBoxWrapper/TextBoxWrapper";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { Draggable } from "react-beautiful-dnd";
import { ReactComponent as GridIcon } from "src/images/grid.svg";

function SingleAccessField(props) {
  const {
    index,
    dragIndex = null,
    pushValues,
    updateValues,
    field,
    required = true,
    editable = true,
    dropValue,
    options,
    selectedVals,
    intl,
    isFieldDisabled = false,
    shouldHideEditableSwitch = false,
    shouldHideControls = false,
    afterMenuList,
    getElementTop,
    setIsLoading,
  } = props;

  const [selectedField, setSelectedField] = useState(field);
  const [selectedRequired, setSelectedRequired] = useState(required);
  const [selectedEditable, setSelectedEditable] = useState(editable);
  const [wsMetaFieldDescription, setWsMetaFieldDescription] = useState(
    selectedVals[index]?.wsMetaFieldDescription !== undefined
      ? selectedVals[index]?.wsMetaFieldDescription
      : "",
  );
  const [
    showDeleteAccessLinkMetaFieldModal,
    setShowDeleteAccessLinkMetaFieldModal,
  ] = useState(false);
  const [
    loadingDeletingAccessLinkMetaField,
    setLoadingDeletingAccessLinkMetaField,
  ] = useState(false);

  const valuesDiffer = Boolean(
    field &&
      selectedField &&
      (selectedRequired !== required ||
        selectedEditable !== editable ||
        wsMetaFieldDescription !==
          selectedVals[index]?.wsMetaFieldDescription ||
        field?.value !== selectedField?.value),
  );

  const clear = () => {
    setSelectedField(null);
    setSelectedRequired(null);
    setSelectedEditable(null);
  };

  useEffect(() => {
    if (pushValues && selectedField) {
      pushValues(
        selectedField,
        selectedRequired,
        selectedEditable,
        wsMetaFieldDescription,
      );
      clear();
    }
  }, [
    selectedField,
    selectedRequired,
    selectedEditable,
    wsMetaFieldDescription,
  ]);

  useEffect(() => {
    if (valuesDiffer && updateValues) {
      updateValues(
        index,
        selectedField || field,
        selectedRequired,
        selectedEditable,
        wsMetaFieldDescription,
      );
    }
  }, [valuesDiffer]);

  const isRemoveDisabled = !field || isFieldDisabled;

  const filteredOptions = options.filter(
    (o) =>
      selectedVals.filter((sv) => sv?.field?.value === o?.value).length === 0,
  );

  const handleDeleteAccessLinkMetaField = (index) => {
    setLoadingDeletingAccessLinkMetaField(true);
    dropValue(index);
    setLoadingDeletingAccessLinkMetaField(false);
    setShowDeleteAccessLinkMetaFieldModal(false);
  };

  const renderContent = () => (
    <PopupRow className="single-access-field">
      <PopupCol>
        <CustomInputLabel htmlFor="fieldSelect">
          <IDHFormattedMessage id="ws_field" defaultMessage="Field" />
        </CustomInputLabel>
        <CustomSelect
          id="fieldSelect"
          afterMenuList={afterMenuList}
          value={selectedField}
          options={filteredOptions}
          placeholder={`${translateMessage({
            intl,
            id: "ws_select",
            defaultMessage: "Select",
          })}...`}
          onChange={setSelectedField}
          isSearchable
          portalized
          isDisabled={isFieldDisabled}
        />
        {field && (
          <TextBoxWrapper
            className="ws-input single-access-field__text-area"
            value={wsMetaFieldDescription || ""}
            onChange={setWsMetaFieldDescription}
            onClick={() => setIsLoading && setIsLoading(true)}
            onBlur={() => setIsLoading && setIsLoading(false)}
            placeholder={`${translateMessage({
              intl,
              id: "ws_submission_form_description",
              defaultMessage: "Enter field description",
            })}...`}
          />
        )}
      </PopupCol>

      {!shouldHideControls && (
        <PopupCol>
          <PopupRow>
            <div className="access-fields-popup__select">
              <CustomInputLabel htmlFor="fieldAccess">
                <IDHFormattedMessage
                  id="ws_required"
                  defaultMessage="Required"
                />
              </CustomInputLabel>
              <CustomSwitch
                checked={selectedRequired}
                onChange={(e) => setSelectedRequired(e.target.checked)}
                disabled={!selectedEditable || isFieldDisabled}
              />
            </div>
            {!shouldHideEditableSwitch && (
              <div className="access-fields-popup__select">
                <CustomInputLabel htmlFor="fieldAccess">
                  <IDHFormattedMessage
                    id="ws_editable"
                    defaultMessage="Editable"
                  />
                </CustomInputLabel>
                <CustomSwitch
                  checked={selectedEditable}
                  onChange={(e) => {
                    const { checked } = e.target;
                    setSelectedEditable(checked);
                    if (checked === false) setSelectedRequired(false);
                  }}
                  disabled={isFieldDisabled}
                />
              </div>
            )}
            <TrashCanIcon
              className={classNames([
                "access-fields-popup__trash",
                isRemoveDisabled && "access-fields-popup__trash--disabled",
              ])}
              onClick={() =>
                !isRemoveDisabled && setShowDeleteAccessLinkMetaFieldModal(true)
              }
            />

            {showDeleteAccessLinkMetaFieldModal && (
              <RemoveModal
                onClose={() => setShowDeleteAccessLinkMetaFieldModal(false)}
                objectNames={[field?.label ?? ""]}
                removeFunction={() => handleDeleteAccessLinkMetaField(index)}
                loading={loadingDeletingAccessLinkMetaField}
              />
            )}
          </PopupRow>
        </PopupCol>
      )}
    </PopupRow>
  );

  if (Number.isInteger(dragIndex)) {
    return (
      <Draggable
        key={field?.value || "empty"}
        draggableId={field?.value || "empty"}
        index={dragIndex}
        isDragDisabled={!Number.isInteger(dragIndex) || isFieldDisabled}
      >
        {(provided) => (
          <div
            className="single-access-field__draggable-wrapper"
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
              left: "auto",
              top: getElementTop(provided.draggableProps.style),
            }}
          >
            {!isFieldDisabled && (
              <div
                className="single-access-field__draggable-wrapper-drag"
                {...provided.dragHandleProps}
              >
                <GridIcon />
              </div>
            )}
            {renderContent()}
          </div>
        )}
      </Draggable>
    );
  }

  return renderContent();
}

export default injectIntl(SingleAccessField);
