import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import VelocityItem from "./VelocityItem";

function VelocityItems({ velocityIndexSubmetrics, socialProvider }) {
  return (
    <>
      {velocityIndexSubmetrics.interestsAndContentMatch >= 0 && (
        <VelocityItem
          title={
            <IDHFormattedMessage
              id="front_interests_match"
              defaultMessage="Interests match"
            />
          }
          value={velocityIndexSubmetrics.interestsAndContentMatch}
          socialProvider={socialProvider}
        />
      )}
      {velocityIndexSubmetrics.growthIndex >= 0 && (
        <VelocityItem
          title={
            <IDHFormattedMessage
              id="front_growth_index"
              defaultMessage="Growth index"
            />
          }
          value={velocityIndexSubmetrics.growthIndex}
          socialProvider={socialProvider}
        />
      )}
      {velocityIndexSubmetrics.er >= 0 && (
        <VelocityItem
          title={
            <IDHFormattedMessage
              id="front_engagement_rate_vs_average_er"
              defaultMessage="Engagement rate vs average ER"
            />
          }
          value={velocityIndexSubmetrics.er}
          socialProvider={socialProvider}
        />
      )}
      {velocityIndexSubmetrics.audienceQuality >= 0 && (
        <VelocityItem
          title={
            <IDHFormattedMessage
              id="front_audience_quality"
              defaultMessage="Audience quality"
            />
          }
          value={velocityIndexSubmetrics.audienceQuality}
          socialProvider={socialProvider}
        />
      )}
      {velocityIndexSubmetrics.audienceGenderCountry >= 0 && (
        <VelocityItem
          title={
            <IDHFormattedMessage
              id="front_age_gender_country"
              defaultMessage="Age, gender, country of the audience"
            />
          }
          value={velocityIndexSubmetrics.audienceGenderCountry}
          socialProvider={socialProvider}
        />
      )}
    </>
  );
}

export default VelocityItems;
