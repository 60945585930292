import React from "react";

import { CustomTable } from "src/app/components/CustomTable/CustomTable";
import { XtrmPaymentsDetails } from "src/types";
import { ReactComponent as ActionPlus } from "src/images/actionPlus.svg";

const columns = [
  {
    Header: "WALLET ID",
    accessor: "id",
    width: 200,
  },
  {
    Header: "CURRENCY",
    accessor: "currency",
  },
  {
    Header: "BALANCE",
    accessor: "balance",
  },
  {
    Header: "ACTION",
    accessor: "action",
    width: 32,
  },
];

interface XTRMPaymentsWalletsProps {
  wallets: XtrmPaymentsDetails["wallets"];
}

export default function XTRMPaymentsWallets({
  wallets,
}: XTRMPaymentsWalletsProps) {
  const rows = wallets.map((wallet) => {
    return {
      id: wallet.id,
      balance: wallet.balance,
      currency: wallet.currency,
      action: (
        <a href={wallet.actionLink} target="_blank" rel="noopener noreferrer">
          <ActionPlus />
        </a>
      ),
    };
  });
  return (
    <div className="xtrmPayments__table-wrapper">
      <CustomTable
        headerClassName="xtrmPayments__table-header"
        columns={columns}
        data={rows}
      />
    </div>
  );
}
