import React from "react";

import { Button } from "src/app/components/Button/Button";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import { DropdownPortal } from "src/app/components/DropdownPortal/DropdownPortal";

import { ReactComponent as FilterIcon } from "src/images/filter.svg";
import { ReactComponent as TickBlueIcon } from "src/images/tick-blue.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export function GlobalMetaFieldMemberListFilter({
  filteredGroup,
  setFilteredGroup,
}) {
  const menu = (
    <DropdownMenu isOpen>
      <DropdownMenuItem onClick={() => setFilteredGroup("all")}>
        <IDHFormattedMessage id="ws_all" defaultMessage="All" />
        {filteredGroup === "all" && (
          <TickBlueIcon
            width={20}
            height={20}
            className="dropdown__tick-blue"
          />
        )}
      </DropdownMenuItem>
      <DropdownMenuItem onClick={() => setFilteredGroup("viewer")}>
        <IDHFormattedMessage id="ws_viewer" defaultMessage="Viewer" />
        {filteredGroup === "viewer" && (
          <TickBlueIcon
            width={20}
            height={20}
            className="dropdown__tick-blue"
          />
        )}
      </DropdownMenuItem>
      <DropdownMenuItem onClick={() => setFilteredGroup("editor")}>
        <IDHFormattedMessage id="ws_editor" defaultMessage="Editor" />
        {filteredGroup === "editor" && (
          <TickBlueIcon
            width={20}
            height={20}
            className="dropdown__tick-blue"
          />
        )}
      </DropdownMenuItem>
    </DropdownMenu>
  );

  return (
    <DropdownPortal overlay={menu}>
      <Button variant="white">
        <FilterIcon />
        &nbsp;
        <IDHFormattedMessage id="ws_show" defaultMessage="Show" />
        :&nbsp;
        <span style={{ textTransform: "capitalize" }}>{filteredGroup}</span>
      </Button>
    </DropdownPortal>
  );
}
