import React from "react";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { showToast } from "./showToast";
import {AppConfig} from "../../config/AppConfig";

export const copyProjectOrTaskLink = (
  workspaceUuid: string,
  projectId: string,
  taskId?: string,
) => {
  const taskLink = taskId ? `/${taskId}` : "";

  const shareLink = `${AppConfig.getAppRoute()}/${workspaceUuid}/projects/${projectId}${taskLink}`;
  navigator.clipboard.writeText(shareLink).then(
    () => {
      showToast(
        "success",
        <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
        <IDHFormattedMessage
          id="ws_link_copied"
          defaultMessage="Link copied!"
        />,
      );
    },
    (err) => {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_error_while_copying"
          defaultMessage="Error while copying!"
        />,
      );
      console.error("Async: Could not copy text: ", err);
    },
  );
};
