import { useEffect } from "react";

// In parseJsonToAudienceSearch function in methods.jsx we receive only value from backend for some selects,
// so we use this custom hook to set also label for it.
const useSelectWithoutLabel = (state, setFunction, options) => {
  useEffect(() => {
    if (state && state.value && !state.label) {
      const fullOption = options.filter((option) => {
        return option.value == state.value;
      });
      if (fullOption.length === 1) {
        setFunction(fullOption[0]);
      } else {
        setFunction(null);
      }
    }
  }, [state]);
};

export default useSelectWithoutLabel;
