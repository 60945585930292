import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { isEmpty } from "../../../../../utils/methods";
import { SectionHeader } from "../components/SectionHeader";
import { RecentlyPublished } from "../single/RecentlyPublished";

export function Posts(props) {
  const {
    profileData: { lastPostsData },
    socialUrl,
    socialProvider,
  } = props;

  if (isEmpty(lastPostsData)) {
    return null;
  }

  return (
    <div className="profile-data-tab__section-wrapper">
      <SectionHeader
        text={<IDHFormattedMessage id="ws_posts" defaultMessage="Posts" />}
      />
      {!isEmpty(lastPostsData) && (
        <RecentlyPublished
          lastPostsData={lastPostsData}
          socialUrl={socialUrl}
          provider={socialProvider}
        />
      )}
    </div>
  );
}
