import { wsAxiosGet, AxiosGetProps } from "src/helpers/wsAxios";
import { API_URLS } from "src/utils/API_URLS";

interface GetExtensionDetailsProps extends Omit<AxiosGetProps, "url"> {
  uuid: string;
}

export const getExtensionDetailsByUuid = async <T>({
  uuid,
  ...rest
}: GetExtensionDetailsProps) => {
  const url = API_URLS.getExtensionDetails.replace(":wsExtensionUuid:", uuid);
  return wsAxiosGet<T>({ url, ...rest });
};
