import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Chart from "../../../../components/Chart/Chart";
import { ReactComponent as AvgViewsIcon } from "../../../../../images/profile-data/avg-views.svg";

import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";

export function AvgViews(props) {
  const { avgViews, viewsStatisticsData, height } = props;

  const showContent = !height || height > 1;

  return (
    <ProfileBox block>
      <PBHeader
        icon={<AvgViewsIcon />}
        count={avgViews}
        title={
          <IDHFormattedMessage id="ws_avg_views" defaultMessage="Avg. Views" />
        }
        onShowcase={height}
      />
      {showContent && (
        <Chart
          title=""
          color="#FFA781"
          icon=""
          data={viewsStatisticsData}
          height={150}
        />
      )}
    </ProfileBox>
  );
}
