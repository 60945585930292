import React, { PropsWithChildren, ReactNode, useState } from "react";
import AnimateHeight, { Height } from "react-animate-height";

import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";

import "./SimpleExpander.scss";

interface SimpleExpanderProps {
  contentClassName?: string;
  expandText: ReactNode;
}

const INITIAL_HEIGHT = 0;

export default function SimpleExpander({
  contentClassName,
  expandText = "More",
  children,
}: PropsWithChildren<SimpleExpanderProps>) {
  const [height, setHeight] = useState<Height>(INITIAL_HEIGHT);

  return (
    <div className="simple-expander__wrapper">
      <AnimateHeight height={height} contentClassName={contentClassName}>
        {children}
      </AnimateHeight>
      <span
        onClick={() =>
          setHeight(height === INITIAL_HEIGHT ? "auto" : INITIAL_HEIGHT)
        }
        className="simple-expander__toggle"
      >
        {height === INITIAL_HEIGHT ? (
          expandText
        ) : (
          <IDHFormattedMessage id="ws_less" defaultMessage="Less" />
        )}
      </span>
    </div>
  );
}
