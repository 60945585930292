import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "./SortingArrow.scss";
import { ReactComponent as ArrowIcon } from "src/images/arrow-down.svg";

export function SortingArrow({ sortingDirection, setSortingDirection }) {
  const sortingStates = ["DESC", "ASC"];
  const [count, setCount] = useState(
    sortingStates.indexOf(sortingDirection) >= 0
      ? sortingStates.indexOf(sortingDirection)
      : 0,
  );

  useEffect(() => {
    setSortingDirection(sortingStates[count]);
  }, [count]);

  const increaseCounter = () => {
    count < sortingStates.length - 1
      ? setCount((prev) => prev + 1)
      : setCount(0);
  };
  return (
    <ArrowIcon
      className={classNames(
        "sortingArrow",
        `sortingArrow--${sortingStates[count]}`,
      )}
      onClick={(e) => {
        e.preventDefault();
        increaseCounter();
      }}
    />
  );
}
