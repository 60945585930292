import axios, { AxiosResponse } from "axios";
import { ParentType } from "src/utils/uploadFunctionsUtils";
import { API_URLS } from "../../utils/API_URLS";
import { showToast } from "../methods/showToast";
import { RequestInputs } from "../../types";
import { AssetData } from "../../utils/uploadFunctions";
import { Content, PresignedUploadRequest } from "../../types/uploadFile";
import { tableDataType } from "../components/Table/Table";

interface UploadMultiFilesProps {
  files: File[];
  allowedTypes: string[];
  maxSize: number;
  presignedUploadRequestUrl: string;
  parentUuid: string;
  parentType?: ParentType;
  wsWorkspaceUuid?: string;
  wsProjectUuid?: string;
  wsTaskUuid?: string;
  context?: tableDataType;
}

export class UploadMultiFiles {
  private _hasCorrectType = false;

  private _hasCorrectSize = false;

  private readonly _files: File[];

  private readonly _allowedTypes: string[];

  private readonly _maxSize: number;

  private readonly presignedUploadRequestUrl: string;

  private readonly parentUuid: string;

  private readonly parentType?: ParentType;

  private readonly wsWorkspaceUuid?: string;

  private readonly wsProjectUuid?: string;

  private readonly wsTaskUuid?: string;

  private readonly context?: tableDataType;

  constructor({
    files,
    allowedTypes,
    maxSize,
    presignedUploadRequestUrl,
    parentUuid,
    parentType,
    wsWorkspaceUuid,
    wsProjectUuid,
    wsTaskUuid,
    context,
  }: UploadMultiFilesProps) {
    this._files = files;
    this._allowedTypes = allowedTypes;
    this._maxSize = maxSize;
    this.presignedUploadRequestUrl = presignedUploadRequestUrl;
    this.parentUuid = parentUuid;
    this.parentType = parentType;
    this.wsWorkspaceUuid = wsWorkspaceUuid;
    this.wsProjectUuid = wsProjectUuid;
    this.wsTaskUuid = wsTaskUuid;
    this.context = context;
  }

  validate() {
    this._files.forEach((file) => {
      const fileNameAr = file.name.split(".");
      const fileExtension = fileNameAr[fileNameAr.length - 1];

      const size = file.size / parseInt((1000 * 1000).toFixed(2));

      if (!this._allowedTypes.includes(fileExtension.toLowerCase())) {
        this._hasCorrectType = false;
        // showToast("error", "Error", "Invalid file type.");
        throw new Error("Invalid file type.");
      } else if (size > this._maxSize) {
        this._hasCorrectType = true;
        this._hasCorrectSize = false;
        showToast("error", "Error", "File is too large.");
        throw new Error("File is too large.");
      } else {
        this._hasCorrectType = true;
        this._hasCorrectSize = true;
      }
    });
  }

  get hasCorrectType() {
    return this._hasCorrectType;
  }

  get hasCorrectSize() {
    return this._hasCorrectSize;
  }

  get maxSize() {
    return this._maxSize;
  }

  async sendAllRequests(): Promise<void> {
    const assets: AssetData[] = [];
    const fileNames: string[] = [];

    this._files.forEach((file) => {
      fileNames.push(file.name);
    });

    let requestDataStructure = {};

    switch (this.context) {
      case tableDataType.Dictionary:
        requestDataStructure = {
          wsWorkspaceUuid: this.wsWorkspaceUuid,
          fileNames,
          wsDictionaryElementUuid: this.wsTaskUuid,
          wsDictionaryMetaFieldUuid: this.parentUuid,
        };
        break;
      case tableDataType.GlobalTask:
        requestDataStructure = {
          wsWorkspaceUuid: this.wsWorkspaceUuid,
          fileNames,
          wsGlobalTaskUuid: this.wsTaskUuid,
          wsGlobalTaskMetaFieldUuid: this.parentUuid,
        };
        break;
      default:
        requestDataStructure = {
          wsWorkspaceUuid: this.wsWorkspaceUuid,
          fileNames,
          ...(this.wsTaskUuid
            ? { wsTaskMetaFieldUuid: this.parentUuid }
            : { wsProjectMetaFieldUuid: this.parentUuid }),
          ...(this.wsTaskUuid
            ? { wsTaskUuid: this.wsTaskUuid }
            : { wsProjectUuid: this.wsProjectUuid }),
        };
        break;
    }

    const response = await axios.put(
      this.presignedUploadRequestUrl,
      requestDataStructure,
    );

    // S3

    let i = 0;

    for (const item of response.data.content) {
      const { action } = item.token.requestAttributes;
      const { requestInputs } = item.token;

      const path = requestInputs.key.split("/");
      const realName = path[path.length - 1];

      await this.sendRequestToS3(this._files[i], action, requestInputs);
      const dataToCreateAsset = {
        wsWorkspaceUuid: this.wsWorkspaceUuid,
        parentUuid: this.parentUuid,
        parentType: this.parentType,
        name: this._files[i].name,
        realName,
        mimeType: this._files[i].type,
        size: this._files[i].size,
        ...(this.context === tableDataType.Dictionary && {
          wsDictionaryMetaFieldUuid: this.wsTaskUuid,
        }),
        ...(this.context === tableDataType.GlobalTask && {
          wsGlobalTaskUuid: this.wsTaskUuid,
        }),
        ...(this.wsTaskUuid && { wsTaskUuid: this.wsTaskUuid }),
      };

      assets.push(dataToCreateAsset);
      i++;
    }

    await this.createAssets(assets);
  }

  private getFormDataToUploadS3(file: File, requestInputs: RequestInputs) {
    const formData = new FormData();

    formData.set("key", requestInputs.key);
    formData.set("acl", requestInputs.acl);
    formData.set("X-Amz-Credential", requestInputs["X-Amz-Credential"]);
    formData.set("X-Amz-Algorithm", requestInputs["X-Amz-Algorithm"]);
    formData.set("X-Amz-Date", requestInputs["X-Amz-Date"]);
    formData.set("Policy", requestInputs.Policy);
    formData.set("X-Amz-Signature", requestInputs["X-Amz-Signature"]);
    formData.set("success_action_status", requestInputs.success_action_status);
    formData.set("file", file);

    return formData;
  }

  async sendPresignedUploadRequest(): Promise<
    AxiosResponse<PresignedUploadRequest>
  > {
    return axios.get(this.presignedUploadRequestUrl);
  }

  async sendAttachmentsToS3(presignedRequestsContent: Content[]) {
    return Promise.allSettled([
      ...presignedRequestsContent.map(async (item, i) => {
        const { action } = item.token.requestAttributes;
        const { requestInputs } = item.token;

        const result = await this.sendRequestToS3(
          this._files[i],
          action,
          requestInputs,
        );
        const promiseFileName = this._files[i].name;

        return { result, promiseFileName };
      }),
    ]);
  }

  private async sendRequestToS3(
    file: File,
    action: string,
    requestInputs: RequestInputs,
  ): Promise<AxiosResponse<any>> {
    return axios.post(action, this.getFormDataToUploadS3(file, requestInputs));
  }

  private async createAssets(assets: AssetData[]): Promise<AxiosResponse<any>> {
    return axios.post(API_URLS.createMultipleAssets, {
      assets,
    });
  }
}
