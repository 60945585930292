import React from "react";
import classNames from "classnames";

import "./Button.scss";

export const ButtonVariantDefault = "blue";

type ButtonSizes =
  | "small"
  | "thin"
  | "medium"
  | "large"
  | "square"
  | "square-small";

type ButtonFontSizes = "medium" | "big";

export type ButtonVariants =
  | "blue"
  | "light-blue"
  | "green"
  | "red"
  | "light"
  | "bare"
  | "grey"
  | "light-grey"
  | "white"
  | "transparent"
  | "white-to-light"
  | "white-to-red"
  | "white-with-border"
  | "white-with-black-border"
  | "white-with-grey-border"
  | "orange";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariants;
  size?: ButtonSizes;
  className?: string;
  transparent?: boolean;
  disabled?: boolean;
  active?: boolean;
  buttonRef?: any;
  fontSize?: ButtonFontSizes;
  textWithIcon?: boolean;
}

export function Button(props: ButtonProps) {
  const {
    children,
    variant,
    size,
    transparent,
    className,
    disabled,
    active,
    buttonRef,
    fontSize,
    textWithIcon,
    ...remainingProps
  } = props;

  return (
    <button
      className={classNames(
        "ws-button",
        variant && `ws-button--${variant}`,
        fontSize && `ws-button--font-${fontSize}`,
        size && `ws-button--${size}`,
        {
          "ws-button--disabled": disabled,
          "ws-button--no-background": transparent,
          "ws-button--text-with-icon": textWithIcon,
        },
        className,
      )}
      ref={buttonRef}
      {...remainingProps}
    >
      {children}
    </button>
  );
}
