import React from "react";
import "./VelocityValue.scss";
import classNames from "classnames";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as CrossIcon } from "src/images/close.svg";
import { ReactComponent as TickGreen } from "src/images/tick-green.svg";

function VelocityValueTooltipContent(props) {
  const { value, filterMatchSummary } = props;

  const labelNames = [
    {
      id: "front_audience_age",
      defaultMessage: "Audience age",
    },
    {
      id: "front_audience_country",
      defaultMessage: "Audience country",
    },
    {
      id: "front_audience_gender",
      defaultMessage: "Audience gender",
    },
    {
      id: "front_audience_brand_affinity",
      defaultMessage: "Audience brand affinity",
    },
    {
      id: "front_audience_interests",
      defaultMessage: "Audience interests",
    },
    {
      id: "front_influencer_age",
      defaultMessage: "Influencer age",
    },
    {
      id: "front_influencer_country",
      defaultMessage: "Influencer country",
    },
    {
      id: "front_followers_count",
      defaultMessage: "Followers count",
    },
    {
      id: "front_influencer_social_platform",
      defaultMessage: "Social platform",
    },
    {
      id: "front_influencer_social_username",
      defaultMessage: "Username",
    },
    {
      id: "front_influencer_gender",
      defaultMessage: "Influencer gender",
    },
    {
      id: "front_profile_data_credibility",
      defaultMessage: "Credibility",
    },
    {
      id: "front_minimum_er",
      defaultMessage: "Minimum ER",
    },
    {
      id: "front_maximum_er",
      defaultMessage: "Maximum ER",
    },
    {
      id: "front_keyword",
      defaultMessage: "Keyword",
    },

    {
      id: "front_hashtag",
      defaultMessage: "Hashtag",
    },
    {
      id: "front_male",
      defaultMessage: "Male",
    },
    {
      id: "front_female",
      defaultMessage: "Female",
    },
  ];

  const findDefaultMessage = (filterName) => {
    const found = labelNames.find((obj) => obj.id === filterName);
    if (found !== undefined) {
      return found.defaultMessage;
    }

    return filterName;
  };

  return (
    <div className="velocity-value-tooltip__content-wrap">
      <div className="velocity-value-tooltip__title">
        {filterMatchSummary.doesResultMatchAllFilters ? (
          <IDHFormattedMessage
            id="front_velocity_value_tooltip_based_on_the_provided"
            defaultMessage="Based on the provided search criteria, we calculated this creator's score as"
          />
        ) : (
          <IDHFormattedMessage
            id="front_velocity_value_tooltip_this_creator_doesnt_match"
            defaultMessage="This creator doesn’t match one or more of the specified search criteria"
          />
        )}
        {filterMatchSummary.doesResultMatchAllFilters && (
          <span className="velocity-value-tooltip__value">{value}</span>
        )}
        .&nbsp;
        <IDHFormattedMessage
          id="front_velocity_value_tooltip_search_criteria_match"
          defaultMessage="Search criteria match:"
        />
      </div>
      <div className="velocity-value-tooltip__filters-container">
        {filterMatchSummary.matches.map((el, index) => (
          <div
            className={classNames("velocity-value-tooltip__filter", {
              "velocity-value-tooltip__filter--matched":
                el.doesResultMatchFilter,
              "velocity-value-tooltip__filter--mismatched":
                !el.doesResultMatchFilter,
            })}
            key={`velocity-tooltip${index}`}
          >
            <div
              key={el.filterName}
              className="velocity-value-tooltip__filter-label"
            >
              <IDHFormattedMessage
                id={el.filterName}
                defaultMessage={findDefaultMessage(el.filterName)}
              />
              :
              <span className="velocity-value-tooltip__filter-value">
                {el.filterValue}
              </span>
            </div>
            {el.resultValue && (
              <span className="velocity-value-tooltip__result-value">
                {el.resultValue}
              </span>
            )}
            {el.doesResultMatchFilter ? (
              <TickGreen className="velocity-value-tooltip__filter-fit-icon" />
            ) : (
              <CrossIcon className="velocity-value-tooltip__filter-fit-icon" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default VelocityValueTooltipContent;
