import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as CreatorInterestsIcon } from "../../../../../images/profile-data/influencer-interests.svg";
import { ReactComponent as TickBlue } from "../../../../../images/tick-blue.svg";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { InterestsList } from "../components/InterestsList/InterestsList";

import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";

export function CreatorInterests(props) {
  const { data, height, onProjectDetails } = props;

  const onShowcase = height;

  return (
    <ProfileBox onProjectDetails={onProjectDetails} block={!onProjectDetails}>
      <PBHeader
        icon={<CreatorInterestsIcon />}
        title={
          <IDHFormattedMessage
            id="ws_creator_interests"
            defaultMessage="Creator Interests"
          />
        }
        tooltip={
          !onShowcase && (
            <Tooltip
              center
              content={
                <IDHFormattedMessage
                  id="ws_tooltip_creator_interests"
                  defaultMessage="The list of the most popular creator’s interests"
                />
              }
            />
          )
        }
        onShowcase={onShowcase}
        onProjectDetails={onProjectDetails}
      />
      <InterestsList
        data={data}
        icon={<TickBlue />}
        fullWidthItem={onProjectDetails || onShowcase}
      />
    </ProfileBox>
  );
}
