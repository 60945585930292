import React from "react";

import classNames from "classnames";

import { ReactComponent as CloseIcon } from "../../../images/close.svg";
import { ReactComponent as ChevronLeftIcon } from "../../../images/chevron-left.svg";

import "./FullscreenModal.scss";

export interface FullscreenModalProps {
  children: React.ReactNode;
  onClose?: () => void;
  onGoBack?: () => void;
  className?: string;
}

function FullscreenModal({
  children,
  onClose,
  onGoBack,
  className,
}: FullscreenModalProps) {
  return (
    <div className={classNames("ws-fullscreen-modal", className)}>
      {onGoBack ||
        (onClose && (
          <div className="ws-fullscreen-modal__topbar">
            {onGoBack && <ChevronLeftIcon onClick={onGoBack} />}
            {onClose && <CloseIcon onClick={onClose} />}
          </div>
        ))}
      <div className="ws-fullscreen-modal__content">{children}</div>
    </div>
  );
}

export default FullscreenModal;
