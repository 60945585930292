import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SectionHeader } from "../components/SectionHeader";
import { AudienceBrandAffinity } from "../single/AudienceBrandAffinity";
import { AudienceInterests } from "../single/AudienceInterests";
import { CreatorInterests } from "../single/CreatorInterests";
import { CreatorsBrandAffinity } from "../single/CreatorsBrandAffinity";

export function InterestsAndBrandAffinity(props) {
  const {
    profileData: {
      user_audience_data: {
        influencersBrandAffinity,
        influencersInterests,
        audienceInterests,
        brandAffinity,
      },
    },
  } = props;

  if (
    !influencersInterests &&
    !influencersBrandAffinity &&
    !audienceInterests &&
    !brandAffinity
  ) {
    return null;
  }

  return (
    <div className="profile-data-tab__section-wrapper">
      <SectionHeader
        text={
          <IDHFormattedMessage
            id="ws_interests_brand_affinity"
            defaultMessage="Interests Brand Affinity"
          />
        }
      />
      {influencersInterests && <CreatorInterests data={influencersInterests} />}
      {influencersBrandAffinity && (
        <CreatorsBrandAffinity data={influencersBrandAffinity} />
      )}
      {audienceInterests && (
        <AudienceInterests audienceInterests={audienceInterests} />
      )}
      {brandAffinity && <AudienceBrandAffinity brandAffinity={brandAffinity} />}
    </div>
  );
}
