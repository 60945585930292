import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import NumberFormat, { NumberFormatValues } from "react-number-format";

import { getExtensionDetailsByUuid } from "src/app/SettingsModal/tabs/Extensions/utils";
import { xtrmPaymentsDetailsInitialData } from "src/app/SettingsModal/tabs/Integrations/XTRMPayments/XTRMPayments";
import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalTitle, ModalRow } from "src/app/components/Modal/Modal";
import { wsAxiosGet, wsAxiosPost } from "src/helpers/wsAxios";
import { Wallet, XtrmPaymentsDetails, PaymentDefaultValues } from "src/types";
import { API_URLS } from "src/utils/API_URLS";
import { showToast } from "src/app/methods/showToast";
import { translateMessage } from "src/app/methods/translateMessage";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import { ReactComponent as InfoIcon } from "src/images/info-dark.svg";
import { ReactComponent as ErrorIcon } from "src/images/post-check-alert.svg";
import PaymentRecipient from "./PaymentRecipient";

import "./SendPaymentModal.scss";

const FEE = 2.5;
const FEE_CURRENCY = "USD";

const amountInitialData = {
  floatValue: undefined,
  formattedValue: "",
  value: "",
};

const generateOptionFromWallet = (wallet: Wallet) => {
  return {
    value: wallet.id,
    label: (
      <div className="send-payment-modal__wallet-option">
        <span>{wallet.name}</span>{" "}
        <div className="send-payment-modal__line-separator" />
        <span>{`${wallet.currency} ${wallet.balance}`}</span>
      </div>
    ),
  };
};

interface SendPaymentModalProps {
  onClose: () => void;
  wsExtensionXtrmPaymentsUuid: string | null;
  activeWorkspaceUuid: string;
  taskId: string;
}

interface WalletSelectOption {
  value: number;
  label: string;
}

export default function SendPaymentModal({
  onClose,
  wsExtensionXtrmPaymentsUuid,
  activeWorkspaceUuid,
  taskId,
}: SendPaymentModalProps) {
  const [xtrmPaymentsDetails, setXtrmPaymentsDetails] =
    useState<XtrmPaymentsDetails>(xtrmPaymentsDetailsInitialData);
  const [selectedWallet, setSelectedWallet] = useState<Wallet>();
  const [recipientEmail, setRecipientEmail] = useState<string>("");
  const [amount, setAmount] = useState<NumberFormatValues>(amountInitialData);
  const [paymentDefaultValues, setPaymentDefaultValues] =
    useState<PaymentDefaultValues | null>(null);

  const [arePaymentDefaultValuesLoading, setArePaymentDefaultValuesLoading] =
    useState<boolean>(false);
  const [arePaymentDetailsLoading, setArePaymentDetailsLoading] =
    useState<boolean>(false);
  const [isSendingPayment, setIsSendingPayment] = useState<boolean>(false);

  const intl = useIntl();

  const isLoading =
    arePaymentDefaultValuesLoading ||
    arePaymentDetailsLoading ||
    isSendingPayment;

  const handleSendPayment = () => {
    if (!selectedWallet || !paymentDefaultValues?.creator) return;

    const url = API_URLS.sendXtrmPayment;
    wsAxiosPost(
      url,
      {
        wsWorkspaceUuid: activeWorkspaceUuid,
        walletId: selectedWallet.id,
        recipientName: paymentDefaultValues.creator.title,
        recipientEmail,
        amount: amount.value,
      },
      "ws_error_while_sending_payment",
      () => {
        setIsSendingPayment(true);
      },
      () => {
        setPaymentDefaultValues(null);
        setRecipientEmail("");
        setAmount(amountInitialData);
        setSelectedWallet(undefined);
        onClose();
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id="ws_payment_completed_successfully"
            defaultMessage="Payment completed successfully."
          />,
        );
      },
      () => {
        setIsSendingPayment(false);
      },
    );
  };

  const doesExceedAvailableFunds = (walletId: number | undefined) => {
    if (!walletId) return false;

    const wallet = xtrmPaymentsDetails.wallets.find(
      (item) => item.id === walletId,
    );
    if (!wallet) return false;

    return (amount.floatValue ?? 0) > wallet.balance;
  };

  const isCreatorSelected = () => {
    return Boolean(paymentDefaultValues?.creator);
  };

  const isAmountAllowed = (values: NumberFormatValues) => {
    const { floatValue } = values;
    if (!floatValue) return true;
    return floatValue >= 0 && floatValue <= Number.MAX_SAFE_INTEGER;
  };

  const isConfirmButtonDisabled = () => {
    if (!selectedWallet) return true;
    if (!isCreatorSelected()) return true;
    if (!recipientEmail) return true;
    if (Number(amount.value) <= 0) return true;
    if (doesExceedAvailableFunds(selectedWallet?.id)) return true;
    return false;
  };

  const getSelectedWalletOption = () => {
    if (!selectedWallet) return null;
    return generateOptionFromWallet(selectedWallet);
  };

  const options: WalletSelectOption[] = xtrmPaymentsDetails.wallets.map(
    (wallet) => ({
      value: wallet.id,
      label: wallet.name,
    }),
  );

  useEffect(() => {
    if (!wsExtensionXtrmPaymentsUuid) return;
    const getXtrmPaymentsDetails = async (uuid: string) => {
      const data = await getExtensionDetailsByUuid<{
        content: XtrmPaymentsDetails;
      }>({
        uuid,
        initialCallback: () => setArePaymentDetailsLoading(true),
        finallyCallback: () => setArePaymentDetailsLoading(false),
      });
      if (data) {
        setXtrmPaymentsDetails(data.content);
      }
    };
    getXtrmPaymentsDetails(wsExtensionXtrmPaymentsUuid);
  }, [wsExtensionXtrmPaymentsUuid]);

  useEffect(() => {
    if (!wsExtensionXtrmPaymentsUuid) return;
    const getXtrmPaymentsDefaultValues = async (
      wsExtensionUuid: string,
      wsPaymentTaskUuid: string,
    ) => {
      const url = API_URLS.getXtrmPaymentsDefaultValues
        .replace(":wsExtensionUuid:", wsExtensionUuid)
        .replace(":wsPaymentTaskUuid:", wsPaymentTaskUuid);
      const data = await wsAxiosGet<{ content: PaymentDefaultValues }>({
        url,
        initialCallback: () => setArePaymentDefaultValuesLoading(true),
        finallyCallback: () => setArePaymentDefaultValuesLoading(false),
      });
      if (data) {
        setPaymentDefaultValues(data.content);
        if (data.content.defaultEmail) {
          setRecipientEmail(data.content.defaultEmail);
        }
        if (data.content.defaultAmount) {
          setAmount({
            floatValue: Number(data.content.defaultAmount),
            formattedValue: data.content.defaultAmount.toString(),
            value: data.content.defaultAmount.toString(),
          });
        }
      }
    };
    getXtrmPaymentsDefaultValues(wsExtensionXtrmPaymentsUuid, taskId);
  }, [taskId, wsExtensionXtrmPaymentsUuid]);

  useEffect(() => {
    if (!paymentDefaultValues) return;

    if (paymentDefaultValues.defaultCurrency) {
      const foundWallet = xtrmPaymentsDetails.wallets.find(
        (wallet) => wallet.currency === paymentDefaultValues.defaultCurrency,
      );
      if (foundWallet) {
        setSelectedWallet(foundWallet);
      }
      if (!foundWallet && xtrmPaymentsDetails.wallets.length > 0) {
        setSelectedWallet(xtrmPaymentsDetails.wallets[0]);
      }
    }
  }, [paymentDefaultValues, xtrmPaymentsDetails]);

  return (
    <Modal
      onClose={onClose}
      className={classNames("send-payment-modal", {
        "send-payment-modal--isLoading": isLoading,
      })}
      displayCancelButton
      onCancelClick={onClose}
      isLoading={isLoading}
      closeButtonVariant="white-with-black-border"
      confirmButtonDisabled={isConfirmButtonDisabled()}
      onConfirmClick={handleSendPayment}
      confirmButtonText={
        <IDHFormattedMessage id="ws_send" defaultMessage="Send" />
      }
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_sending_payment"
          defaultMessage="Sending payment"
        />
      </ModalTitle>
      <div className="send-payment-modal__content-wrapper">
        <ModalRow>
          <CustomInputLabel
            fontWeight="medium"
            fontColor="dark-grey"
            fontSize="big"
            required
          >
            <IDHFormattedMessage id="ws_wallet" defaultMessage="Wallet" />
          </CustomInputLabel>
          <div className="send-payment-modal__select-wallet-wrapper">
            <CustomSelect
              className={classNames("ws-react-select", {
                "send-payment-modal__select-wallet-error":
                  doesExceedAvailableFunds(selectedWallet?.id),
              })}
              placeholder={translateMessage({
                intl,
                id: "ws_select_wallet",
                defaultMessage: "Select wallet",
              })}
              options={options}
              value={getSelectedWalletOption()}
              onChange={(option: WalletSelectOption) => {
                setSelectedWallet(
                  xtrmPaymentsDetails.wallets.find(
                    (wallet) => wallet.id === option.value,
                  ),
                );
              }}
            />
            <div
              className={classNames("send-payment-modal__error-message", {
                "send-payment-modal__error-message--visible":
                  doesExceedAvailableFunds(selectedWallet?.id),
              })}
            >
              <ErrorIcon className="send-payment-modal__icon" />
              <IDHFormattedMessage
                id="ws_not_enough_funds"
                defaultMessage="Not enough funds in the selected wallet"
              />
            </div>
          </div>
        </ModalRow>
        <ModalRow>
          <CustomInputLabel
            htmlFor="recipient-email"
            fontWeight="medium"
            fontColor="dark-grey"
            fontSize="big"
            required
          >
            <IDHFormattedMessage id="ws_recipient" defaultMessage="Recipient" />
          </CustomInputLabel>
          {paymentDefaultValues?.creator ? (
            <PaymentRecipient
              paymentDefaultValues={paymentDefaultValues}
              paymentsHistoryUrl={xtrmPaymentsDetails.paymentsHistoryUrl}
            />
          ) : (
            <div className="send-payment-modal__error-message send-payment-modal__error-message--visible">
              <ErrorIcon className="send-payment-modal__icon" />
              <IDHFormattedMessage
                id="ws_creator_not_selected"
                defaultMessage="Creator not selected"
              />
            </div>
          )}
        </ModalRow>
        <ModalRow>
          <CustomInputLabel
            htmlFor="recipient-email"
            fontWeight="medium"
            fontColor="dark-grey"
            fontSize="big"
            required
          >
            <IDHFormattedMessage
              id="ws_recipient_email"
              defaultMessage="Recipient email"
            />
          </CustomInputLabel>

          <CustomInput
            id="recipient-email"
            type="email"
            value={recipientEmail}
            placeholder={translateMessage({
              intl,
              id: "ws_enter_email_address",
              defaultMessage: "Enter email address",
            })}
            onChange={(e) => setRecipientEmail(e.target.value)}
            required
          />
        </ModalRow>
        <ModalRow>
          <CustomInputLabel
            fontWeight="medium"
            fontColor="dark-grey"
            fontSize="big"
            htmlFor="amount"
            required
          >
            <IDHFormattedMessage id="ws_amount" defaultMessage="Amount" />
          </CustomInputLabel>
          <NumberFormat
            id="amount"
            className="ws-input send-payment-modal__text--big send-payment-modal__amount-input"
            value={amount.value}
            isAllowed={isAmountAllowed}
            decimalScale={2}
            disabled={selectedWallet === undefined}
            onValueChange={(values) => {
              setAmount(values);
            }}
            prefix={`${selectedWallet?.currency ? selectedWallet.currency : ""} `}
            thousandSeparator=" "
            required
          />
        </ModalRow>
        <ModalRow>
          <div className="send-payment-modal__text send-payment-modal__text--small">
            <IDHFormattedMessage id="ws_fee" defaultMessage="Fee" />
            <TooltipPortal
              id="suggestion-tooltip"
              contentClassName="suggestion-tooltip-content"
              content={
                <div>
                  <strong>
                    <IDHFormattedMessage id="ws_fee" defaultMessage="Fee" />
                  </strong>
                  <br />
                  <IDHFormattedMessage
                    id="ws_fee_description"
                    defaultMessage="Lorem ipsum dolor sit amet consectetur."
                  />
                </div>
              }
              autoSize
            >
              <InfoIcon className="send-payment-modal__icon send-payment-modal__info-icon" />
            </TooltipPortal>
          </div>
          <span className="send-payment-modal__text send-payment-modal__text--small">
            <span className="send-payment-modal__currency">{FEE_CURRENCY}</span>{" "}
            <NumberFormat
              className="send-payment-modal__text--small"
              value={FEE}
              decimalScale={2}
              disabled
              fixedDecimalScale
              displayType="text"
            />
          </span>
        </ModalRow>
      </div>
    </Modal>
  );
}
