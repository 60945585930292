import React from "react";

export const RADIAN = Math.PI / 180;
export const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const small = midAngle + 270 < 14;
  const radius = small ? 0 : innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill={small ? "blue" : "white"}
      textAnchor="middle"
      dominantBaseline="central"
      fontFamily="Poppins"
      fontSize="16px"
      fontWeight="500"
    >
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  );
};

export const calculateStrokeWidth = (data, dataKey) => {
  if (data[0][dataKey] / data[1][dataKey] < 0.08) return 3;
  return 8;
};
