import React from "react";
import "./Chevron.scss";
import classNames from "classnames";

import { ReactComponent as ChevronUpGrey } from "src/images/chevron-up-grey.svg";
import { Button } from "../Button/Button";

export function Chevron({ expanded, setExpanded }) {
  return (
    <Button
      light
      size="small"
      className={classNames(["chevron", expanded && "chevron--expanded"])}
      onClick={() => setExpanded((v) => !v)}
    >
      <ChevronUpGrey className="chevron__image" width={16} height={16} />
    </Button>
  );
}
