import React from "react";
import "./CDTable.scss";
import PropTypes from "prop-types";
import classNames from "classnames";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import { uuidv7 as uuid } from "uuidv7";
import NoResults from "../NoResults/NoResults";

export function CDTable(props) {
  const {
    columns,
    className,
    children,
    showNoResults,
    autoLayout,
    customNoResults,
  } = props;

  return (
    <>
      <table
        className={classNames("cdt-table", className, {
          "cdt-table--layout-auto": showNoResults || autoLayout,
        })}
      >
        <CDTableHead columns={columns} />
        {!showNoResults && children}
      </table>

      {showNoResults && (
        <div className="table-no-result">
          {customNoResults || <NoResults />}
        </div>
      )}
    </>
  );
}

CDTable.propTypes = {
  showNoResults: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      defaultMessage: PropTypes.string,
      toLeft: PropTypes.bool,
      tooltip: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string,
      }),
    }),
  ).isRequired,
  children: PropTypes.node.isRequired,
};

export function CDTableHead({ columns = [] }) {
  return (
    <thead>
      <tr>
        {columns.map((column) => {
          if (!column) return null;
          return (
            <th
              id={`${column.id}-${uuid()}`}
              className={column.className}
              width={column?.width}
            >
              <div
                className={classNames(
                  "cdt-table__header-column",
                  `cdt-table__header--column-${column.id}`,
                  {
                    "cdt-table__header-column--left": column.toLeft,
                  },
                )}
              >
                {!column.empty && (
                  <>
                    {column.beforeTextComponent}
                    <div className="cdt-table__header-column__text">
                      <IDHFormattedMessage
                        id={column.id}
                        defaultMessage={column.defaultMessage}
                      />
                    </div>
                    {column.afterTextComponent}
                    {column.tooltip && (
                      <MaterialTooltip
                        center
                        className={column.tooltip.className}
                        clickable={column.tooltip.clickable}
                        alignRight={column.tooltip.alignRight}
                        content={
                          <IDHFormattedMessage
                            id={column.tooltip.id}
                            defaultMessage={column.tooltip.defaultMessage}
                          />
                        }
                      />
                    )}
                  </>
                )}
                {column.selectAll && (
                  <CustomCheckbox
                    id={`${column.id}-${uuid()}`}
                    name={column.id}
                    label={
                      <IDHFormattedMessage
                        id={column.id}
                        defaultMessage={column.defaultMessage}
                      />
                    }
                    {...column.selectAll}
                  />
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

export function CDTableBody({ children }) {
  return <tbody>{children}</tbody>;
}

export function CDTableRow({ children, className, ...props }) {
  return (
    <tr className={classNames(["cdt-table__row", className])} {...props}>
      {children}
    </tr>
  );
}

export function CDTableColumn({ children, className, ...props }) {
  return (
    <td className={classNames(["cdt-table__cell", className])} {...props}>
      {children}
    </td>
  );
}
