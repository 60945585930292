import React from "react";

interface Props {
  fill: string;
  onClick?: () => void;
}

export const CrossIcon: React.FC<Props> = (props) => {
  const { fill, onClick } = props;
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect
        x="3.28772"
        y="9.88745"
        width="9.33333"
        height="1.16667"
        rx="0.583333"
        transform="rotate(-45 3.28772 9.88745)"
        fill={fill}
      />
      <rect
        x="4.11267"
        y="3.2876"
        width="9.33333"
        height="1.16667"
        rx="0.583333"
        transform="rotate(45 4.11267 3.2876)"
        fill={fill}
      />
    </svg>
  );
};
