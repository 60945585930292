import {
  getWorkspaceUuidFromCurrentUrl,
  parseErrorMessages,
} from "src/utils/methods";
import {
  RESET_DICTIONARY_FILTERS_REDUCER,
  RESET_HIDDEN_COLUMNS,
  SET_DICTIONARY_COLUMN_RANK,
  SET_DICTIONARY_FILTER,
  SET_DICTIONARY_FILTERS,
  SET_DICTIONARY_INITIAL_COLUMN_RANKS,
  SET_DICTIONARY_SORT,
  SET_DICTIONARY_SORT_BY,
  TOGGLE_DICTIONARY_COLUMN_VISIBILITY,
} from "src/app/components/DictionaryFilters/redux/dictionaryFiltersTypes";
import { valueExists } from "src/app/methods/valueExists";
import { generateRankString } from "src/utils/rankStrings";

const wsWorkspaceUuid = getWorkspaceUuidFromCurrentUrl();

export const shouldUpdateRanks = (ranks, items) => {
  ranks.forEach((rank) => {
    const foundRank = items.find((item) => item.uuid === rank.uuid);
    if (!foundRank) return false;
  });
  return true;
};

export const generateRanksForNewMetaFields = (lastItemRank, columns) => {
  if (!lastItemRank || !columns.length) return [];
  let newRank = null;
  return (
    columns?.map((col) => {
      newRank = generateRankString(newRank || lastItemRank, "");
      return { uuid: col.uuid, rank: newRank };
    }) || []
  );
};

export const generateMetadataRanks = (columns) => {
  return columns?.map((col) => ({ uuid: col.uuid, rank: col.rank })) || [];
};

export const generateLackingRanks = (columns, ranks) => {
  if (!columns?.length && ranks?.length) return ranks;

  const sortedRanks = [];
  const newTasks = [];

  ranks
    ?.sort((a, b) => a.rank.localeCompare(b.rank))
    ?.forEach((rank) => {
      if (columns.find((item) => item.uuid === rank.uuid)) {
        sortedRanks.push(rank);
      }
    });

  columns?.forEach((col) => {
    if (!sortedRanks.find((item) => item.uuid === col.uuid)) {
      newTasks.push(col);
    }
  });

  const lastRank = sortedRanks[sortedRanks.length - 1]?.rank;

  const newRanks = [
    ...sortedRanks,
    ...generateRanksForNewMetaFields(lastRank, newTasks),
  ];

  return newRanks;
};

export const creatorDatabaseFiltersReducerInitialState = (
  globalTaskColumns,
) => {
  const settingsData = JSON.parse(
    localStorage.getItem("ws-creator-database-settings"),
  );
  const settings = settingsData ? settingsData[wsWorkspaceUuid] : null;

  if (settings?.creatorDatabaseColumnRanks) {
    const newSettings = {
      ...settings,
      creatorDatabaseColumnRanks: generateLackingRanks(
        globalTaskColumns,
        settings?.creatorDatabaseColumnRanks,
      ),
    };

    return newSettings;
  }

  const newColumnRanks = generateMetadataRanks(globalTaskColumns);

  if (settings)
    return { ...settings, creatorDatabaseColumnRanks: newColumnRanks };

  return {
    sortBy: null,
    sort: "DESC",
    filters: [],
    hiddenGlobalTaskColumnIds: [],
    creatorDatabaseColumnRanks: newColumnRanks,
  };
};

export const save = (obj) => {
  const previousSave = JSON.parse(
    localStorage.getItem("ws-creator-database-settings"),
  );

  const itemToSet = previousSave?.hasOwnProperty(wsWorkspaceUuid)
    ? {
        ...previousSave[wsWorkspaceUuid],
        ...obj,
      }
    : obj;

  localStorage.setItem(
    "ws-creator-database-settings",
    JSON.stringify({
      ...previousSave,
      [wsWorkspaceUuid]: itemToSet,
    }),
  );
  return sanitizeFilters(obj);
};

const sanitizeFilters = (obj) => {
  // stupid but it works, gets rid of keys for undefined values
  return JSON.parse(JSON.stringify(obj));
};

function creatorDatabaseFiltersReducer(state, action) {
  if (
    action?.payload?.data?.status === "fail" ||
    action?.error?.response?.status >= 500
  ) {
    return {
      ...state,
      errorMessages: [{ message: "An unexpected error occurred." }],
    };
  }

  switch (action.type) {
    case SET_DICTIONARY_SORT_BY:
      return save({
        ...state,
        sortBy: action.payload.sortBy,
      });

    case SET_DICTIONARY_SORT:
      return save({
        ...state,
        sort: action.payload.sort,
      });

    case SET_DICTIONARY_FILTERS:
      return save({
        ...state,
        filters: action.payload.filters,
      });

    case SET_DICTIONARY_FILTER:
      return save({
        ...state,
        filters: {
          ...state.filters,
          [action.payload.fieldUuid]: !valueExists(action.payload.value)
            ? undefined
            : { value: action.payload.value },
        },
      });

    case RESET_DICTIONARY_FILTERS_REDUCER:
      return dictionaryFiltersReducerInitialState();

    case TOGGLE_DICTIONARY_COLUMN_VISIBILITY: {
      const hiddenGlobalTaskColumnIds = state.hiddenGlobalTaskColumnIds || [];
      return save({
        ...state,
        hiddenGlobalTaskColumnIds: hiddenGlobalTaskColumnIds?.includes(
          action.payload.uuid,
        )
          ? hiddenGlobalTaskColumnIds?.filter(
              (hc) => hc !== action.payload.uuid,
            )
          : [...hiddenGlobalTaskColumnIds, action.payload.uuid],
      });
    }

    case SET_DICTIONARY_INITIAL_COLUMN_RANKS:
      return {
        ...state,
        creatorDatabaseColumnRanks: action.payload.ranks,
      };

    case RESET_HIDDEN_COLUMNS:
      return {
        ...state,
        hiddenGlobalTaskColumnIds: [],
      };

    case SET_DICTIONARY_COLUMN_RANK: {
      const { uuid, rank } = action.payload;
      return save({
        ...state,
        creatorDatabaseColumnRanks: state.creatorDatabaseColumnRanks.map(
          (col) => {
            if (col.uuid === uuid) {
              return {
                ...col,
                rank,
              };
            }

            return col;
          },
        ),
      });
    }

    default:
      if (action?.error?.response?.data && action.type.includes("_FAIL")) {
        return {
          ...state,
          errorMessages: parseErrorMessages(
            action?.error?.response?.data?.errors,
          ),
        };
      }

      return state;
  }
}

export default creatorDatabaseFiltersReducer;
