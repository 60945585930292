import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { getWorkspaceExtensionList } from "src/redux";
import { RootState } from "src/redux/reducers";
import Loader from "src/app/components/Loader/Loader";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import {
  ExtensionTypeEnum,
  WsExtension,
  WsExtensionXtrmPayments,
} from "src/types";
import { SettingsTab } from "../../enums";
import {
  ChangeSettingsSectionParam,
  changeSettingsSection,
} from "../../methods";
import ExtensionsList from "../Extensions/components/ExtensionsList/ExtensionsList";
import XTRMPayments from "./XTRMPayments/XTRMPayments";

export enum IntegrationsSection {
  ExtensionsList = "ExtensionsList",
  XTRMPayments = "XTRMPayments",
}

const findXtmPaymentsExtension = (
  workspaceExtension: WsExtension[],
): WsExtensionXtrmPayments | undefined => {
  const foundExtension = workspaceExtension.find(
    (extension) => extension.type === ExtensionTypeEnum.XtrmPayments,
  );

  if (!foundExtension) return undefined;

  return foundExtension.type === ExtensionTypeEnum.XtrmPayments
    ? foundExtension
    : undefined;
};

interface IntegrationsProps {
  onClose: () => void;
  contextData: any;
}

export default function Integrations({
  onClose,
  contextData,
}: IntegrationsProps) {
  const dispatch = useDispatch();
  const {
    mainReducer: { settingsModalData, activeWorkspaceUuid },
    extensionReducer: {
      workspaceExtensionList,
      isWorkspaceExtensionListLoading,
    },
  } = useSelector((state: RootState) => state);

  const history = useHistory();

  const { section: integrationsSection } = settingsModalData;

  const setIntegrationsSection = (section: ChangeSettingsSectionParam) => {
    changeSettingsSection(history, section);
  };

  const renderComponent = () => {
    switch (integrationsSection) {
      case IntegrationsSection.ExtensionsList:
        return (
          <ExtensionsList
            title={
              <IDHFormattedMessage
                id="ws_integrations"
                defaultMessage="Integrations"
              />
            }
            currentSettingsTab={SettingsTab.Integrations}
            onClose={onClose}
            setExtensionsSection={setIntegrationsSection}
            extensionList={workspaceExtensionList}
            permissions={contextData?.permissions}
            wsProjectUuid={null}
            isProjectExtensions={false}
          />
        );
      case IntegrationsSection.XTRMPayments:
        const xtrmPaymentsExtension = findXtmPaymentsExtension(
          workspaceExtensionList,
        );
        if (!xtrmPaymentsExtension) return null;
        return (
          <XTRMPayments
            onClose={onClose}
            setExtensionsSection={setIntegrationsSection}
            xtrmPaymentsExtension={xtrmPaymentsExtension}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(getWorkspaceExtensionList(activeWorkspaceUuid));
  }, [activeWorkspaceUuid]);

  return (
    <div className="extensions">
      {isWorkspaceExtensionListLoading ? <Loader /> : renderComponent()}
    </div>
  );
}
