import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Hourglass from "src/images/hourglass-numbers.svg";
import { EmptyTabContainer } from "./EmptyTabContainer/EmptyTabContainer";
import { EmptyTabContent } from "./EmptyTabContent/EmptyTabContent";

function GatheringProfileData() {
  return (
    <EmptyTabContainer className="empty-social-profiles-tab">
      <EmptyTabContent
        imgUrl={Hourglass}
        header={
          <IDHFormattedMessage
            id="ws_please_wait_patiently"
            defaultMessage="Please wait patiently..."
          />
        }
        text={
          <IDHFormattedMessage
            id="ws_we_are_gathering_data"
            defaultMessage="We are gathering data for this account. It will be available within a few minutes."
          />
        }
      />
    </EmptyTabContainer>
  );
}

export default GatheringProfileData;
