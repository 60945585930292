import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalTitle } from "src/app/components/Modal/Modal";
import { Button } from "src/app/components/Button/Button";
import { ReactComponent as QuestionMarkOval } from "../../../../images/question-mark-oval.svg";
import { TrialLimitationsList } from "../TrialLimitationsList";

export function Limitations({ onClose, trialLimits }) {
  return (
    <>
      <ModalTitle icon={<QuestionMarkOval />}>
        <IDHFormattedMessage
          id="ws_your_7d_trial_allows_to"
          defaultMessage="Your 7-day trial allows you to"
        />
      </ModalTitle>

      <TrialLimitationsList trialLimits={trialLimits} />

      <div className="trial-limitations-modal__buttons">
        <Button variant="light" size="large" onClick={onClose}>
          <IDHFormattedMessage id="ws_close" defaultMessage="Close" />
        </Button>
      </div>
    </>
  );
}
