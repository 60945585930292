import React from "react";
import "./Emoji.scss";

function Emoji({ icon }) {
  switch (icon) {
    case "⚡️":
    case "🔍":
    case "👻":
    case "👀":
    case "🎧":
    case "☕️":
    case "✌🏻":
    case "👋🏻":
    case "💬":
    case "🎮":
    case "✏️":
    case "💾️":
    case "⏳":
    case "⏰":
    case "⏱":
    case "❌":
    case "✅":
    case "🚀":
    case "💪🏻":
    case "👈🏻":
    case "👍🏻":
    case "✍🏻":
    case "👎🏻": {
      return (
        <img
          src={`/workspace/emoji/${icon}.svg`}
          role="presentation"
          className="emoji"
        />
      );
    }
    default:
      return icon;
  }
}

export default Emoji;
