import React from "react";
import SocialProviderIcon from "../../../components/SocialProviderIcon/SocialProviderIcon";

export function OverlapInfluencerInfo({
  socialProvider,
  username,
  openProfile,
}) {
  return (
    <div className="influencer-info">
      <div
        onClick={() => {
          openProfile(socialProvider, username);
        }}
        className="influencer-info__username"
      >
        <SocialProviderIcon provider={socialProvider} user big />
        <span>{username}</span>
      </div>
    </div>
  );
}
