import { CAMPAIGN_AUDIENCE_DATA } from "../../utils/variables";

export const addAudienceDataToGridElements = (
  newGridElements,
  newHiddenGridElements,
  savedTemplate,
  templateApplied,
  audienceData,
) => {
  if (!audienceData) return;

  CAMPAIGN_AUDIENCE_DATA.forEach((item) => {
    const element = {
      label: `audience-data-${item.value}`,
      provider: "audience-data",
      type: item.value,
      gridBoxType: "audience-data",
      name: item.label,
      w: item.w,
      h: item.h,
    };

    // applying saved template
    if (savedTemplate?.length && !templateApplied) {
      if (savedTemplate.find((item) => item.i === element.label)) {
        newGridElements.push(element);
      } else {
        newHiddenGridElements.push(element);
      }
    } else if (
      item.initialHidden ||
      !audienceData[item.source] ||
      audienceData[item.source].length === 0
    ) {
      newHiddenGridElements.push(element);
    }
    // applying default template
    else {
      newGridElements.push(element);
    }
  });
};
