import React, { Dispatch, SetStateAction } from "react";

import classNames from "classnames";

import spinnySpinner from "src/images/spinner-small.gif";
import { FormHandler } from "../AccessLinkPageForm/components/FormHandler/FormHandler";
import { AccessLinkPageBlock } from "../AccessLinkPageBlock/AccessLinkPageBlock";
import { AccessLinkPageForm } from "../AccessLinkPageForm/AccessLinkPageForm";
import { AccessLinkPageHeaderContent } from "../AccessLinkPageHeaderContent/AccessLinkPageHeaderContent";

export type PageHandler = "success" | "expired" | undefined;

interface AccessLinkPageContentProps {
  pageHandler: PageHandler;
  setPageHandler: Dispatch<SetStateAction<PageHandler>>;
  response: any;
  anyFieldRequired: boolean;
  type: string;
}

export function AccessLinkPageContent({
  pageHandler,
  response,
  anyFieldRequired,
  setPageHandler,
  type,
}: AccessLinkPageContentProps) {
  return (
    <div
      className={classNames([
        "access-link-page",
        pageHandler && "access-link-page--single",
      ])}
    >
      {pageHandler ? (
        <FormHandler pageHandler={pageHandler} />
      ) : response ? (
        <>
          {(response?.caption ||
            response?.description ||
            response?.wsAccessLinkCover) && (
            <AccessLinkPageBlock>
              <AccessLinkPageHeaderContent
                caption={response?.caption}
                description={response?.description}
                cover={response?.wsAccessLinkCover}
                showLegend={anyFieldRequired}
              />
            </AccessLinkPageBlock>
          )}
          <AccessLinkPageBlock>
            {response?.metaFields && (
              <AccessLinkPageForm
                metafields={response?.metaFields}
                wsWorkspaceUuid={response?.wsWorkspaceUuid}
                extensionUuid={response?.extensionUuid}
                setPageHandler={setPageHandler}
                type={type}
              />
            )}
          </AccessLinkPageBlock>
        </>
      ) : (
        <AccessLinkPageBlock>
          <div className="access-link-page__spinner">
            <img src={spinnySpinner} width={48} alt="Loading..." />
          </div>
        </AccessLinkPageBlock>
      )}
    </div>
  );
}
