import { useEffect, useState } from "react";
import { Metadata } from "../Task/Subtask/Subtask";

export const useGetMetaField = (name: string, metadata: Metadata[]) => {
  const [metaField, setMetaField] = useState<Partial<Metadata>>({});

  useEffect(() => {
    if (!metadata?.length) return;

    setMetaField(
      metadata?.find(
        (item: Metadata) => item.name.toLowerCase() === name.toLowerCase(),
      ) || {},
    );
  }, [metadata]);

  return metaField;
};
