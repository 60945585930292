import React, { useState } from "react";
import "./AddTeam.scss";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import { Button } from "src/app/components/Button/Button";
import axios from "axios";
import { API_URLS } from "src/utils/API_URLS";
import { uuidv7 as uuid } from "uuidv7";
import { useSelector } from "react-redux";
import { showToast } from "src/app/methods/showToast";
import { RootState } from "src/redux/reducers";
import { TeamsSection } from "../../Teams";

interface Props {
  setSection: (newSection: TeamsSection) => void;
  setObjectUuid: (objectUuid: string) => void;
  onClose: () => void;
}

export const AddTeam: React.FC<Props> = (props) => {
  const { setSection, setObjectUuid, onClose } = props;

  const [name, setName] = useState("");

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const intl = useIntl();

  const goBack = () => setSection(TeamsSection.TeamList);

  const createTeam = () => {
    const teamUuid = uuid();

    axios
      .post(API_URLS.createTeam, {
        uuid: teamUuid,
        wsWorkspaceUuid: activeWorkspaceUuid,
        name,
      })
      .then(() => {
        setSection(TeamsSection.TeamDetails);
        setObjectUuid(teamUuid);
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id="ws_team_has_been_created"
            defaultMessage="Team has been created!"
          />,
        );
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_could_not_create_team"
            defaultMessage="Could not create Team."
          />,
        );
      });
  };

  return (
    <>
      <AnimatedDiv className="add-team">
        <div className="add-team__link" onClick={goBack}>
          <IDHFormattedMessage id="ws_teams" defaultMessage="Teams" />
        </div>

        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder={translateMessage({
            intl,
            id: "ws_enter_team_name",
            defaultMessage: "Enter Team Name",
          })}
        />
      </AnimatedDiv>

      <SettingsButtons onClose={onClose}>
        <Button
          variant="blue"
          size="large"
          onClick={createTeam}
          disabled={name.length < 1}
        >
          <IDHFormattedMessage id="ws_create" defaultMessage="Create" />
        </Button>
      </SettingsButtons>
    </>
  );
};
