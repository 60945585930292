import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as CrosshairIcon } from "../../../../../images/profile-data/crosshair.svg";
import Flag from "../../../../components/Flag/Flag";
import { LocationList } from "../components/LocationList/LocationList";
import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";

function Location(props) {
  const { countryLocations, onProjectDetails, height } = props;

  return (
    <ProfileBox onProjectDetails>
      <PBHeader
        onProjectDetails={onProjectDetails}
        icon={<CrosshairIcon />}
        title={
          <IDHFormattedMessage id="ws_location" defaultMessage="Location" />
        }
        onShowcase={height}
      />
      <LocationList countryLocations={countryLocations} height={height} />
    </ProfileBox>
  );
}

export { Location };
