import { RouterChildContext } from "react-router";
import _ from "lodash";
import { getRoute, ROUTES } from "src/utils/ROUTES";
import { AppLocation } from "src/redux/main/mainReducer";
import { AutomationsSection } from "./tabs/Automations/Automations";
import { UniversalSettingsTab } from "./SettingsModal";
import { ExtensionsSection } from "./tabs/Extensions/Extensions";
import { TeamsSection } from "./tabs/Teams/Teams";
import { CreatorDatabaseConfigurationSection } from "./tabs/CreatorDatabaseConfiguration/CreatorDatabaseConfiguration";
import { IntegrationsSection } from "./tabs/Integrations/Integrations";

export const openSettingsModal = (
  history: RouterChildContext["router"]["history"],
  location: AppLocation | string,
  context: string,
  tab: string,
  section?: string,
) => {
  const url = new URL(window.location.href);
  const view = JSON.parse(url.searchParams.get("view") || "{}");
  const settings = {
    location,
    context,
    tab,
    section,
  };
  const newView = {
    ...view,
    settings,
  };

  url.searchParams.set("view", JSON.stringify(newView));
  history.push(url.pathname + url.search);
};

export const closeSettingsModal = (
  history: RouterChildContext["router"]["history"],
) => {
  const url = new URL(window.location.href);
  const view = JSON.parse(url.searchParams.get("view") || "{}");
  const { settings, ...newView } = view;

  if (_.isEqual(newView, {})) {
    url.searchParams.delete("view");
  } else {
    url.searchParams.set("view", JSON.stringify(newView));
  }
  history.push(url.pathname + url.search);
};

export const updateViewParam = (
  history: RouterChildContext["router"]["history"],
  objName: string,
  key: string,
  value: string,
) => {
  const url = new URL(window.location.href);
  const view = JSON.parse(url.searchParams.get("view") || "{}");

  const newParams = {
    ...view[objName],
    [key]: value,
  };
  const newView = {
    ...view,
    [objName]: newParams,
  };

  url.searchParams.set("view", JSON.stringify(newView));
  history.push(url.pathname + url.search);
};

export const changeSettingsTab = (
  history: RouterChildContext["router"]["history"],
  tab: UniversalSettingsTab,
) => {
  updateViewParam(history, "settings", "tab", tab);
};

export type ChangeSettingsSectionParam =
  | AutomationsSection
  | ExtensionsSection
  | CreatorDatabaseConfigurationSection
  | TeamsSection
  | IntegrationsSection;

export const changeSettingsSection = (
  history: RouterChildContext["router"]["history"],
  section: ChangeSettingsSectionParam,
) => {
  updateViewParam(history, "settings", "section", section);
};

export const changeSettingsObjectUuid = (
  history: RouterChildContext["router"]["history"],
  objectUuid: string,
) => {
  updateViewParam(history, "settings", "objectUuid", objectUuid);
};

export const openHowItWorksModal = (
  history: RouterChildContext["router"]["history"],
  location: AppLocation,
) => {
  const url = new URL(window.location.href);
  const view = JSON.parse(url.searchParams.get("view") || "{}");
  const howItWorks = {
    location,
  };
  const newView = {
    ...view,
    howItWorks,
  };

  url.searchParams.set("view", JSON.stringify(newView));
  history.push(url.pathname + url.search);
};

export const removePropertyFromViewParam = (
  history: RouterChildContext["router"]["history"],
  objName: string,
) => {
  const url = new URL(window.location.href);
  const viewParam = JSON.parse(url.searchParams.get("view") || "{}");

  delete viewParam[objName];

  if (Object.keys(viewParam).length === 0) {
    url.searchParams.delete("view");
  } else {
    url.searchParams.set("view", JSON.stringify(viewParam));
  }

  history.push(url.pathname + url.search);
};

export const navigateToCreateNewProject = (
  history: RouterChildContext["router"]["history"],
  wsWorkspaceUuid: string,
) => {
  history.push(
    getRoute(ROUTES.CREATE_NEW_PROJECT, {
      wsWorkspaceUuid,
    }),
  );
};
