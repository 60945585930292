import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { CustomDateRange } from "src/app/components/CustomDateRange/CustomDateRange";
import { translateMessage } from "src/app/methods/translateMessage";
import { useIntl } from "react-intl";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import { ReactComponent as FilterIcon } from "../../../../../images/filter.svg";
import {
  PaneTopbar,
  PaneTopbarLeft,
  PaneTopbarToolbar,
} from "../../../Pane/PaneTopbar/PaneTopbar";
import {
  clearActivityFilters,
  getMembersList,
  getProjectsList,
  setActivityFilters,
} from "../../../../../redux";

import ActivityFiltersItem from "./ActivityFiltersItem";
import CampaignPlaceholder from "../../../../../images/project-placeholder.svg";
import AvatarPlaceholder from "../../../../../images/avatar-purple.svg";

export function ActivityFilters(props: any) {
  const {
    activeWorkspaceUuid,
    setFiltersVisible,
    projectsList,
    membersList,
    filtersApplied,
    filters,
    initialActivityFilters,
  } = props;

  const [projectOptions, setProjectOptions] = useState<any[]>([]);
  const [memberOptions, setMemberOptions] = useState<any[]>([]);

  useEffect(() => {
    setProjectOptions(
      projectsList?.map((project: any) => ({
        value: { name: project.projectName, id: project.projectId },
        label: (
          <span className="activity-filters__option">
            <img
              loading="lazy"
              className="activity-filters__option-image activity-filters__option-image--square"
              src={project?.cover?.thumbnails?.tiny || CampaignPlaceholder}
              alt="avatar"
            />
            <span className="activity-filters__option-text">
              {project.projectName}
            </span>
          </span>
        ),
      })) || [],
    );
  }, [projectsList]);

  useEffect(() => {
    setMemberOptions(
      membersList?.map((member: any) => ({
        value: { email: member.email, name: member.name, id: member.id },
        label: (
          <span className="activity-filters__option">
            <img
              loading="lazy"
              className="activity-filters__option-image"
              src={member?.avatarUrl || AvatarPlaceholder}
              alt="avatar"
            />
            <span className="activity-filters__option-text">{member.name}</span>
          </span>
        ),
      })) || [],
    );
  }, [membersList]);

  const dispatch = useDispatch();

  const getMembers = () => {
    dispatch(getMembersList(activeWorkspaceUuid));
  };

  const getProjects = () => {
    dispatch(getProjectsList(activeWorkspaceUuid));
  };

  useEffect(() => {
    getMembers();
    getProjects();
  }, []);

  useEffect(() => {
    if (initialActivityFilters === filters) {
      setDateFrom(null);
      setDateTo(null);
      setCampaigns([]);
      setMembers([]);
    }
  }, [filters]);

  const [dateFrom, setDateFrom] = useState<string | null>(
    filters.dateRange.dateFrom,
  );
  const [dateTo, setDateTo] = useState<string | null>(filters.dateRange.dateTo);
  const [campaigns, setCampaigns] = useState(filters.campaigns);
  const [members, setMembers] = useState(filters.members);

  useEffect(() => {
    if (!filtersApplied) return;

    const filtersToSave = {
      campaigns,
      members,
      dateRange: { dateFrom, dateTo },
    };

    dispatch(setActivityFilters(filtersToSave));

    let currentActivityFiltersObject = {
      [activeWorkspaceUuid]: filtersToSave,
    };

    const currentActivityFilters = localStorage.getItem("activityFilters");

    if (currentActivityFilters) {
      currentActivityFiltersObject = {
        ...JSON.parse(currentActivityFilters),
        [activeWorkspaceUuid]: filtersToSave,
      };
    }

    localStorage.setItem(
      "activityFilters",
      JSON.stringify(currentActivityFiltersObject),
    );
  }, [dateFrom, dateTo, members, campaigns]);

  const intl = useIntl();

  const showClearButton =
    campaigns?.length || members?.length || dateFrom || dateTo;

  const filterOption = (candidate: any, input: any) => {
    return (
      candidate.data.__isNew__ ||
      candidate.value.name.toUpperCase().includes(input.toUpperCase())
    );
  };

  return (
    <>
      <PaneTopbar>
        <PaneTopbarLeft>
          <FilterIcon className="filters-header__icon" />
          <span className="filters-header__title">
            <IDHFormattedMessage id="ws_filter" defaultMessage="Filter" />
          </span>
        </PaneTopbarLeft>
        <PaneTopbarToolbar hidePane={() => setFiltersVisible(false)} />
      </PaneTopbar>

      <div className="pane__container filters activity-filters">
        <ActivityFiltersItem
          key="time"
          label={<IDHFormattedMessage id="ws_time" defaultMessage="Time" />}
          children={
            <CustomDateRange
              dateFrom={dateFrom}
              setDateFrom={(date) => setDateFrom(date)}
              dateTo={dateTo}
              setDateTo={(date) => setDateTo(date)}
              maxDate={new Date()}
              minDate={new Date("01 Jan 2022 00:00:00 GMT")}
              errorMessage={translateMessage({
                intl,
                id: "ws_date_must_be_within_7_days",
                defaultMessage: "Date range must be within 7 days",
              })}
              toRight
            />
          }
        />

        <ActivityFiltersItem
          key="campaign"
          label={
            <IDHFormattedMessage id="ws_campaign" defaultMessage="Campaign" />
          }
          children={
            <CustomSelect
              value={projectOptions?.filter((project) =>
                campaigns.includes(project?.value?.id),
              )}
              onChange={(newValues: any) =>
                setCampaigns(newValues.map((el: any) => el?.value?.id))
              }
              placeholder={translateMessage({
                intl,
                id: "ws_all",
                defaultMessage: "All",
              })}
              options={projectOptions}
              disabled={projectOptions?.length === 0}
              isMulti
              withTiles
              openMenuOnFocus={false}
              isSearchable
              filterOption={filterOption}
            />
          }
        />

        <ActivityFiltersItem
          key="member"
          label={
            <IDHFormattedMessage
              id="ws_member_client"
              defaultMessage="Member / Client"
            />
          }
          children={
            <CustomSelect
              value={memberOptions?.filter((member) =>
                members.includes(member?.value?.id),
              )}
              onChange={(newValues: any) =>
                setMembers(newValues.map((el: any) => el?.value?.id))
              }
              placeholder={translateMessage({
                intl,
                id: "ws_all",
                defaultMessage: "All",
              })}
              options={memberOptions}
              disabled={memberOptions?.length === 0}
              isMulti
              withTiles
              openMenuOnFocus={false}
              isSearchable
              filterOption={filterOption}
            />
          }
        />
      </div>
      {showClearButton ? (
        <Button
          variant="light"
          size="medium"
          className="filters-button"
          onClick={() => dispatch(clearActivityFilters())}
        >
          <IDHFormattedMessage
            id="ws_clear_filters"
            defaultMessage="Clear filters"
          />
        </Button>
      ) : null}
    </>
  );
}
