import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

import { Img } from "src/app/components/Img/Img";
import emptyImage from "src/images/empty-folder.svg";

export function EmptyActivityTab() {
  return (
    <div className="activity-tab__empty">
      <Img
        src={emptyImage}
        fallbackImageSrc={emptyImage}
        className="activity-tab__empty-img"
      />
      <IDHFormattedMessage
        id="ws_there_is_nothing_to_display"
        defaultMessage="There is nothing to display here."
      />
    </div>
  );
}
