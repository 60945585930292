import React from "react";

export function ButtonCounter({
  count,
  backgroundColor = "#7fa4e6a8",
  color = "white",
}) {
  return (
    <span
      className="idh-button__counter"
      style={{
        backgroundColor,
        color,
        padding: "0px 7px",
        borderRadius: "8px",
      }}
    >
      {count}
    </span>
  );
}
