import { TaskType } from "src/types";
import { ROUTES, getRoute } from "src/utils/ROUTES";

export const handleRowClick = (
  wsTaskUuid: string,
  taskType: TaskType,
  wsWorkspaceUuid: string,
  wsProjectUuid: string,
) => {
  const url = getRoute(ROUTES.TASK_DETAILS, {
    wsWorkspaceUuid,
    wsProjectUuid,
    wsTaskUuid,
  });
  window.open(`${url}?taskType=${taskType}`);
};

export function removeCommentIdFromUrl() {
  const url = window.location.href;

  const queryStringIndex = url.indexOf("?");
  if (queryStringIndex === -1) {
    // If there's no query string, return the original URL
    return url;
  }

  const queryString = url.substring(queryStringIndex + 1);
  const queryParams = new URLSearchParams(queryString);

  // Check if the query parameters include 'taskType'
  if (queryParams.has("taskType")) {
    // Get the position of the 'taskType' parameter
    const taskTypeIndex = url.indexOf("taskType");

    // Extract the substring before the 'taskType' parameter
    const substringBeforeTaskType = url.substring(0, taskTypeIndex);

    // Extract the substring after the 'taskType' parameter
    const substringAfterTaskType = url.substring(taskTypeIndex);

    // Extract the substring before the last '/' character in the substring before 'taskType'
    const lastSlashIndex = substringBeforeTaskType.lastIndexOf("/");
    const updatedUrl = `${substringBeforeTaskType.substring(
      0,
      lastSlashIndex,
    )}?${substringAfterTaskType}`;

    // Update the browser's history state
    window.history.replaceState({}, "", updatedUrl);

    return updatedUrl;
  }

  // If 'taskType' is not found, return the original URL
  return url;
}
