import React from "react";

import { ReactComponent as WarningYellowIcon } from "src/images/warning-orange.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export default function WarningInfo({ error, errorMessage }) {
  return (
    <div className="audience-result-loading__adding-result">
      <div className="audience-result-loading__warning">
        <WarningYellowIcon className="audience-result-loading__warning-icon" />
        <IDHFormattedMessage
          id="front_account_hasnt_been_added_warning"
          defaultMessage="Warning!"
        />
      </div>
      {error && <div className="audience-result-loading__text">{error}</div>}
      {errorMessage && (
        <div className="audience-result-loading__text">{errorMessage}</div>
      )}
    </div>
  );
}
