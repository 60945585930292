import React, { useState } from "react";
import "./GlobalFieldActionDropdown.scss";
import { Button } from "src/app/components/Button/Button";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";

import { ReactComponent as DotsIcon } from "src/images/dots.svg";
import { ReactComponent as EditIcon } from "src/images/edit.svg";
import { ReactComponent as DeleteIcon } from "src/images/trash-can.svg";
import { ReactComponent as SingleSelectIcon } from "src/images/metafield-types/single-select.svg";
import { ReactComponent as MultiSelectIcon } from "src/images/metafield-types/multi-select.svg";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import ManageFieldModal from "src/app/modals/ManageFieldModal/ManageFieldModal";
import { tableDataType } from "src/app/components/Table/Table";
import EditSelectOptionsModal from "src/app/SettingsModal/tabs/ManageFields/modals/EditSelectOptionsModal/EditSelectOptionsModal";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { DeleteGlobalFieldModal } from "../DeleteGlobalFieldModal/DeleteGlobalFIeldModal";

interface Props {
  data: any;
  dataType: tableDataType;
  projectId: string;
}

const SELECT_TYPES = [MetaFieldType.SingleSelect, MetaFieldType.MultiSelect];

export const GlobalFieldActionDropdown: React.FC<Props> = (props) => {
  const { data, dataType, projectId } = props;

  const [showSetupModal, setShowSetupModal] = useState(false);
  const [showManageOptionsModal, setShowManageOptionsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getSelectIcon = () => {
    switch (data.type) {
      case MetaFieldType.MultiSelect:
        return <MultiSelectIcon />;

      case MetaFieldType.SingleSelect:
        return <SingleSelectIcon />;

      default:
        return <SingleSelectIcon />;
    }
  };

  const getGlobalType = () => {
    switch (dataType) {
      case tableDataType.Task:
        return tableDataType.GlobalTask;
      case tableDataType.Project:
        return tableDataType.GlobalProject;

      default:
        return dataType;
    }
  };

  const globalDataType = getGlobalType();

  const showSelectEditButton =
    SELECT_TYPES.includes(data.type) && !data?.data?.wsSelectDataSetUuid;

  return (
    <Dropdown toRight>
      {(isOpen, setIsOpen) => (
        <>
          <Button
            variant="light-grey"
            size="large"
            onClick={() => setIsOpen(true)}
            className="dropdown-button"
          >
            <DotsIcon />
          </Button>
          <DropdownMenu isOpen={isOpen}>
            <DropdownMenuItem onClick={() => setShowSetupModal(true)}>
              <EditIcon />
              <IDHFormattedMessage id="ws_edit" defaultMessage="Edit" />
            </DropdownMenuItem>
            {showSelectEditButton && (
              <DropdownMenuItem onClick={() => setShowManageOptionsModal(true)}>
                {getSelectIcon()}
                <IDHFormattedMessage
                  id="ws_manage_options"
                  defaultMessage="Manage options"
                />
              </DropdownMenuItem>
            )}
            {data.data.nonRemovable !== true && (
              <DropdownMenuItem
                onClick={() => setShowDeleteModal(true)}
                className="dropdown__menu-item--danger"
              >
                <DeleteIcon />
                <IDHFormattedMessage id="ws_remove" defaultMessage="Remove" />
              </DropdownMenuItem>
            )}
          </DropdownMenu>

          {showSetupModal && (
            <ManageFieldModal
              data={{
                ...data,
                id: data.uuid,
                options: data.data,
                metaFieldOptions: data.data,
                metaFieldSource: data.valueSource,
              }}
              onClose={() => setShowSetupModal(false)}
              dataType={dataType}
              projectId={projectId}
              editingGlobalField
            />
          )}

          {showManageOptionsModal && (
            <EditSelectOptionsModal
              onClose={() => setShowManageOptionsModal(false)}
              data={data}
              dataType={globalDataType}
            />
          )}

          {showDeleteModal && (
            <DeleteGlobalFieldModal
              onClose={() => setShowDeleteModal(false)}
              metaFieldUuid={data.uuid}
              metaFieldName={data.name}
              dataType={globalDataType}
            />
          )}
        </>
      )}
    </Dropdown>
  );
};
