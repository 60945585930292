import React from "react";
import "./NoResults.scss";
import classNames from "classnames";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Emoji from "../Emoji/Emoji";

function NoResults({ withLine, withUnderline, customMessage }) {
  return (
    <span
      className={classNames("no-results", {
        "no-results--with-line": withLine,
        "no-results--with-underline": withUnderline,
      })}
    >
      <Emoji icon="👀" />
      &nbsp;&nbsp;
      {customMessage || (
        <IDHFormattedMessage
          id="front_posts_empty_tab"
          defaultMessage="Seems like there's nothing to be displayed here yet."
        />
      )}
    </span>
  );
}

export default NoResults;
