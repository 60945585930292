import React from "react";
import "./ProjectsTableTitleCell.scss";

import { tableDataType, taskAvatarShape } from "src/app/components/Table/Table";
import { ProjectOrTaskName } from "src/app/project/ProjectOrTaskName";

export function ProjectsTableTitleCell({ project }) {
  return (
    <ProjectOrTaskName
      key={project.projectId}
      item={project}
      disableLink={false}
      singleRow={false}
      dataType={tableDataType.Project}
      taskAvatarShape={taskAvatarShape.Square}
    />
  );
}
