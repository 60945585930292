import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { getTranslationsForMember } from "src/redux/main/mainActions";
import { API_URLS } from "src/utils/API_URLS";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "src/app/components/Button/Button";
import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import { LanguageSelector } from "src/app/components/LanguageSelector/LanguageSelector";
import { RootState } from "src/redux/reducers";
import { showErrorToast } from "src/utils/methods";
import { OnboardingStep } from "../OnboardingPage";

interface Props {
  setCurrentStep: Dispatch<SetStateAction<OnboardingStep>>;
}

export const SelectLanguage: React.FC<Props> = (props) => {
  const { setCurrentStep } = props;

  const [selectedLanguage, setSelectedLanguage] = useState<any>({});

  const {
    mainReducer: { locale },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedLanguage.value && locale !== selectedLanguage.value) {
      updateLanguage();
    }
  }, [selectedLanguage]);

  const updateLanguage = async () => {
    try {
      await axios.put(API_URLS.setLocale, {
        locale: selectedLanguage.value,
      });
      dispatch(getTranslationsForMember());
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  return (
    <>
      <div className="onboarding-page__header">
        <IDHFormattedMessage id="ws_language" defaultMessage="Language" />
      </div>
      <CustomInputLabel aboveInput>
        <IDHFormattedMessage
          id="ws_select_language_used_in_interface"
          defaultMessage="Select language used in the platform interface"
        />
      </CustomInputLabel>
      <LanguageSelector
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
      <Button
        size="large"
        variant="blue"
        className="onboarding-page__btn onboarding-page__btn--block"
        onClick={() => setCurrentStep("profile")}
      >
        <IDHFormattedMessage id="ws_next" defaultMessage="Next" />
      </Button>
    </>
  );
};
