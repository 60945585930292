import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import "./InviteUser.scss";

import classNames from "classnames";
import { injectIntl } from "react-intl";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

import { useSelector } from "react-redux";

import { Button } from "src/app/components/Button/Button";
import { SimpleSelect } from "src/app/components/SimpleSelect/SimpleSelect";
import CreatableSelect from "react-select/creatable";

import spinnySpinner from "src/images/spinner-small.gif";
import { ReactComponent as EnvelopeIcon } from "src/images/envelope.svg";
import { ReactComponent as CloseIcon } from "src/images/mutableCloseIcon.svg";
import { ReactComponent as InfoIcon } from "src/images/toasts/info-red.svg";

import { translateMessage } from "src/app/methods/translateMessage";
import { isEmailValid } from "src/utils/methods";
import { MembersContext } from "src/app/SettingsModal/tabs/Members/Members";
import { SettingsContext } from "src/app/SettingsModal/enums";
import Tooltip from "src/app/components/Tooltip/Tooltip";
import { inviteSelectStyles } from "./utils/inviteSelectStyles";
import { createUserOptions } from "./utils/createUserOptions";

function InviteUser(props) {
  const { intl } = props;

  const {
    users,
    setUsers,
    members,
    addMember,
    loading,
    selectedRole,
    setSelectedRole,
  } = useContext(MembersContext);

  const [userOptions, setUserOptions] = useState([]);

  const [previousUsers, setPreviousUsers] = useState(users);

  const selectRef = useRef(null);
  const valueContainerRef = useRef(null);
  const anchorRef = useRef(null);

  const {
    mainReducer: { settingsModalData },
    projectReducer: { activeMembersList },
  } = useSelector((state) => state);

  const { context } = settingsModalData;

  const filterOption = (candidate, input) => {
    return (
      candidate.data.__isNew__ ||
      candidate.value.name.toLowerCase().includes(input.toLowerCase()) ||
      candidate.value.email.toLowerCase().includes(input.toLowerCase())
    );
  };

  const createOption = (inputValue) => {
    const trimValue = inputValue?.trim();
    const isValid = isEmailValid(trimValue);
    setUsers((p) => [
      ...p,
      {
        label: trimValue,
        value: trimValue,
        __isNew__: true,
        isValid,
      },
    ]);
  };

  const invalidOptions = useMemo(
    () => users.filter((user) => !user.isValid && user.__isNew__),
    [users],
  );

  const isInviteButtonDisabled =
    invalidOptions.length > 0 || users.length === 0;

  useEffect(() => {
    if (users.length > previousUsers.length) {
      anchorRef.current.scrollIntoView({
        block: "end",
        inline: "nearest",
      });
    }
    setPreviousUsers(users);
  }, [users]);

  useEffect(() => {
    const options = createUserOptions(activeMembersList, members);
    setUserOptions(options);
  }, [activeMembersList, members]);

  return (
    <>
      <div className="members__user-select" ref={valueContainerRef}>
        <div className="members__user-select-row">
          <CreatableSelect
            className={classNames([
              "members__user-select-input",
              invalidOptions.length > 0 &&
                "members__user-select-input--invalid",
            ])}
            closeMenuOnSelect={false}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
              MultiValueRemove: (props) => (
                <div {...props.innerProps}>
                  <CloseIcon
                    {...props}
                    width={16}
                    height={16}
                    fill={props.data.color || "#8E93AC"}
                  />
                </div>
              ),
            }}
            value={users}
            createOptionPosition="first"
            isSearchable
            isMulti
            withTiles
            onChange={setUsers}
            onCreateOption={createOption}
            options={userOptions}
            placeholder={translateMessage({
              intl,
              id: "ws_add_email_or_name",
              defaultMessage: "Add email or name",
            })}
            filterOption={filterOption}
            openMenuOnFocus
            menuPortalTarget={document.querySelector(".Overlays") || undefined}
            ref={selectRef}
            styles={inviteSelectStyles}
            formatCreateLabel={(inputText) => (
              <div className="members__user-select-invite">
                <EnvelopeIcon className="members__user-select-invite-env" />
                <IDHFormattedMessage id="ws_invite" defaultMessage="Invite" />
                <div className="members__user-select-invite-email">
                  {inputText}
                </div>
              </div>
            )}
          />
          {context === SettingsContext.Project && (
            <SimpleSelect
              options={[
                {
                  name: (
                    <IDHFormattedMessage
                      id="ws_member"
                      defaultMessage="Member"
                    />
                  ),
                  value: "member",
                },
                {
                  name: (
                    <IDHFormattedMessage
                      id="ws_client"
                      defaultMessage="Client"
                    />
                  ),
                  value: "client",
                },
              ]}
              selectedOption={selectedRole}
              setSelectedOption={setSelectedRole}
            />
          )}
          {loading === false ? (
            <Tooltip
              content={
                <IDHFormattedMessage
                  id="ws_invite_user_button_tooltip_content"
                  defaultMessage="Input at least 1 valid user name or email address"
                />
              }
              contentHidden={!isInviteButtonDisabled}
            >
              <Button
                variant="blue"
                className="members__user-select-invite-btn"
                size="medium"
                disabled={isInviteButtonDisabled}
                onClick={addMember}
              >
                <IDHFormattedMessage id="ws_invite" defaultMessage="Invite" />
              </Button>
            </Tooltip>
          ) : (
            <div className="members__user-select-spinner">
              <img src={spinnySpinner} width={48} alt="Loading..." />
            </div>
          )}
        </div>
        <div className="members__user-select__anchor" ref={anchorRef} />
      </div>
      {invalidOptions.length > 0 && (
        <div className="members__user-select__validation-message">
          <InfoIcon />
          <span>
            <IDHFormattedMessage
              id="ws_invite_you_have_entered"
              defaultMessage="You have entered"
            />
          </span>

          <span>{invalidOptions.length}</span>

          <span>
            <IDHFormattedMessage
              id="ws_invite_invalid_email"
              defaultMessage="invalid email"
            />
          </span>

          {invalidOptions.length > 1 ? (
            <span>
              <IDHFormattedMessage
                id="ws_invite_address_plural"
                defaultMessage="adresses"
              />
            </span>
          ) : (
            <span>
              <IDHFormattedMessage
                id="ws_invite_address_single"
                defaultMessage="address"
              />
            </span>
          )}
        </div>
      )}
    </>
  );
}

export default injectIntl(InviteUser);
