import React from "react";
import "./RemoveUserModal.scss";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SettingsContext } from "src/app/SettingsModal/enums";
import { getMembersList } from "src/redux";
import { API_URLS } from "../../../../utils/API_URLS";
import { showToast } from "../../../methods/showToast";
import { RemoveModal } from "../../RemoveModal/RemoveModal";

function RemoveUserModal(props) {
  const { onClose, removeUserData, getData } = props;

  const {
    mainReducer: { activeWorkspaceUuid, workspacesList, settingsModalData },
  } = useSelector((state) => state);

  const workspaceData = workspacesList.find(
    (workspace) => workspace.uuid === activeWorkspaceUuid,
  );

  const { context } = settingsModalData;

  const dispatch = useDispatch();

  const removeWorkspaceMember = () => {
    axios
      .post(API_URLS.removeWorkspaceMember, {
        wsMemberUuid: removeUserData.uuid,
        wsWorkspaceUuid: workspaceData.uuid,
      })
      .then(() => {
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id="ws_member_was_removed_successfully"
            defaultMessage="Member was removed successfully."
          />,
        );
        dispatch(getMembersList(activeWorkspaceUuid));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeProjectMember = () => {
    axios
      .post(API_URLS.removeProjectMember, {
        wsMemberUuid: removeUserData.uuid,
        wsProjectUuid: removeUserData.projectId,
      })
      .then(() => {
        updateData();
      })
      .catch((error) => {
        // TODO: Kacper obsłużyć błędy tak jak rozmawialiśmy: https://indahash.slack.com/archives/G4R8J0ZFU/p1660040576727539
        console.error(error);
      });
  };

  const removeTaskMember = () => {
    axios
      .post(API_URLS.removeTaskMember, {
        wsMemberUuid: removeUserData.uuid,
        wsTaskUuid: removeUserData.taskId,
      })
      .then(() => {
        updateData();
      })
      .catch((error) => {
        // TODO: Kacper obsłużyć błędy tak jak rozmawialiśmy: https://indahash.slack.com/archives/G4R8J0ZFU/p1660040576727539
        console.error(error);
      });
  };

  const removeMember = () => {
    if (context === SettingsContext.Workspace) {
      removeWorkspaceMember();
    } else if (context === SettingsContext.Task) {
      removeTaskMember();
    } else {
      removeProjectMember();
    }

    onClose();
  };

  const updateData = () => {
    showToast(
      "success",
      <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
      <IDHFormattedMessage
        id="ws_member_was_removed_successfully"
        defaultMessage="Member was removed successfully."
      />,
    );
    getData();
  };

  return (
    <RemoveModal
      onClose={onClose}
      objectNames={[removeUserData?.userName ?? ""]}
      removeFunction={removeMember}
    />
  );
}

export { RemoveUserModal };
