import React from "react";
import { ReactComponent as RectangleCheckIcon } from "src/images/rectangle-check.svg";
import { ReactComponent as TickIcon } from "src/images/tick-grey.svg";
import { ReactComponent as StarIcon } from "src/images/star.svg";
import { TaskType } from "src/types";
import { ImageOption } from "../components/CustomSelect/CustomSelect";

import { ReactComponent as DateIcon } from "../../images/date-icon.svg";
import { ReactComponent as PercentIcon } from "../../images/percent.svg";
import { ReactComponent as CurrencyIcon } from "../../images/currency.svg";
import { ReactComponent as HashIcon } from "../../images/hash.svg";
import { ReactComponent as AvatarIcon } from "../../images/avatar.svg";
import { ReactComponent as TextIcon } from "../../images/text.svg";
import { ReactComponent as CreatorIcon } from "../../images/creator.svg";
import { ReactComponent as MessageIcon } from "../../images/menu-icons/message.svg";
import { ReactComponent as PictureIcon } from "../../images/picture.svg";
import { ReactComponent as SingleSelectIcon } from "../../images/metafield-types/single-select.svg";
import { ReactComponent as MultiSelectIcon } from "../../images/metafield-types/multi-select.svg";
import { ReactComponent as DeliverablesIcon } from "../../images/metafield-types/deliverables.svg";
import { ReactComponent as MetricsIcon } from "../../images/metafield-types/metrics.svg";
import { ReactComponent as FormulaIcon } from "../../images/metafield-types/formula.svg";
import { ReactComponent as FileIcon } from "../../images/metafield-types/file.svg";
import { ReactComponent as CouponIcon } from "../../images/metafield-types/coupon.svg";
import { ReactComponent as SalesTracingIcon } from "../../images/metafield-types/sales-tracing.svg";
import { ReactComponent as LinkIcon } from "../../images/metafield-types/link.svg";
import { tableDataType } from "../components/Table/Table";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { MetaFieldTypeOption } from "../modals/ManageFieldModal/types";
import { GlobalField } from "../modals/ManageFieldModal/components/GlobalFields/GlobalFields";
import { ReactComponent as EMVIcon } from "../../images/stacked-coins.svg";

export enum MetaFieldType {
  // Universal fields
  Formula = "formula",
  Member = "member",
  Text = "text",
  Number = "number",
  Percent = "percent",
  Currency = "currency",
  Date = "date",
  SingleSelect = "singleSelect",
  MultiSelect = "multiSelect",
  AggregatedSelect = "aggregatedSelect",
  BoolVal = "boolVal",
  File = "file",
  AccessLink = "accessLink",
  ProgressBar = "progressBar",
  SocialUrl = "socialUrl",
  DictionaryElement = "dictionaryElement",
  Rating = "rating",

  // Task fields
  Metrics = "metrics",
  Creator = "creator",
  PublicationRelated = "publicationRelated",
  Content = "content",
  Coupon = "coupon",
  SalesMetrics = "salesMetrics",
  RelatedField = "relatedField",
  SocialProfiles = "socialProfiles",
  EMV = "emv",
}

const FIELDS_WHITELISTS: Record<string, TaskType[]> = {
  metrics: [TaskType.Creator, TaskType.Publication],
  content: [TaskType.Action, TaskType.Publication],
  creator: [
    TaskType.Action,
    TaskType.Publication,
    TaskType.Content,
    TaskType.Payment,
  ],
  publication: [TaskType.Payment],
  emv: [TaskType.Publication],
};

const RECRUITMENT_FORM_METAFIELDS_BLACKLIST = [
  MetaFieldType.AccessLink,
  MetaFieldType.Date,
  MetaFieldType.Member,
  MetaFieldType.Formula,
  MetaFieldType.Coupon,
  MetaFieldType.DictionaryElement,
];

export const getMetaFieldIcon = (type: MetaFieldType) => {
  switch (type) {
    case MetaFieldType.Formula:
      return FormulaIcon;
    case MetaFieldType.Member:
      return AvatarIcon;
    case MetaFieldType.Text:
      return TextIcon;
    case MetaFieldType.Number:
      return HashIcon;
    case MetaFieldType.Percent:
      return PercentIcon;
    case MetaFieldType.Currency:
      return CurrencyIcon;
    case MetaFieldType.Date:
      return DateIcon;
    case MetaFieldType.SingleSelect:
      return SingleSelectIcon;
    case MetaFieldType.MultiSelect:
      return MultiSelectIcon;
    case MetaFieldType.AggregatedSelect:
      return DeliverablesIcon;
    case MetaFieldType.BoolVal:
      return TickIcon;
    case MetaFieldType.File:
      return FileIcon;
    case MetaFieldType.AccessLink:
      return RectangleCheckIcon;
    case MetaFieldType.Coupon:
      return CouponIcon;
    case MetaFieldType.SalesMetrics:
      return SalesTracingIcon;
    case MetaFieldType.Metrics:
      return MetricsIcon;
    case MetaFieldType.Creator:
      return CreatorIcon;
    case MetaFieldType.Content:
      return PictureIcon;
    case MetaFieldType.PublicationRelated:
      return MessageIcon;
    case MetaFieldType.RelatedField:
      return LinkIcon;
    case MetaFieldType.Rating:
      return StarIcon;
    default:
      return TextIcon;
  }
};

export const getMetaFieldTypeOptions = (
  taskType: TaskType,
  dataType: tableDataType,
): MetaFieldTypeOption[] => {
  const taskOptions = [
    ...(FIELDS_WHITELISTS.metrics.includes(taskType)
      ? [
          {
            value: MetaFieldType.Metrics,
            label: (
              <ImageOption icon={MetricsIcon}>
                <IDHFormattedMessage id="ws_metrics" defaultMessage="Metrics" />
              </ImageOption>
            ),
          },
        ]
      : []),
    ...(FIELDS_WHITELISTS.creator.includes(taskType)
      ? [
          {
            value: MetaFieldType.Creator,
            label: (
              <ImageOption icon={CreatorIcon}>
                <IDHFormattedMessage id="ws_creator" defaultMessage="Creator" />
              </ImageOption>
            ),
          },
        ]
      : []),
    ...(FIELDS_WHITELISTS.publication.includes(taskType)
      ? [
          {
            value: MetaFieldType.PublicationRelated,
            label: (
              <ImageOption icon={MessageIcon}>
                <IDHFormattedMessage
                  id="ws_publication"
                  defaultMessage="Publication"
                />
              </ImageOption>
            ),
          },
        ]
      : []),
    ...(FIELDS_WHITELISTS.content.includes(taskType)
      ? [
          {
            value: MetaFieldType.Content,
            label: (
              <ImageOption icon={PictureIcon}>
                <IDHFormattedMessage id="ws_content" defaultMessage="Content" />
              </ImageOption>
            ),
          },
        ]
      : []),
    ...(FIELDS_WHITELISTS.emv.includes(taskType)
      ? [
          {
            value: MetaFieldType.EMV,
            label: (
              <ImageOption icon={EMVIcon}>
                <IDHFormattedMessage id="ws_emv" defaultMessage="EMV" />
              </ImageOption>
            ),
          },
        ]
      : []),
    {
      value: MetaFieldType.Coupon,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.Coupon)}>
          <IDHFormattedMessage id="ws_coupon" defaultMessage="Coupon" />
        </ImageOption>
      ),
    },
    {
      value: MetaFieldType.SalesMetrics,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.SalesMetrics)}>
          <IDHFormattedMessage
            id="ws_sales_tracing_metric"
            defaultMessage="Sales tracing metric"
          />
        </ImageOption>
      ),
    },
    {
      value: MetaFieldType.RelatedField,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.RelatedField)}>
          <IDHFormattedMessage
            id="ws_related_field"
            defaultMessage="Related field"
          />
        </ImageOption>
      ),
    },
  ];

  const options = [
    {
      value: MetaFieldType.Formula,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.Formula)}>
          <IDHFormattedMessage id="ws_formula" defaultMessage="Formula" />
        </ImageOption>
      ),
    },
    {
      value: MetaFieldType.Member,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.Member)}>
          <IDHFormattedMessage id="ws_member" defaultMessage="Member" />
        </ImageOption>
      ),
    },
    {
      value: MetaFieldType.Text,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.Text)}>
          <IDHFormattedMessage id="ws_text" defaultMessage="Text" />
        </ImageOption>
      ),
    },
    {
      value: MetaFieldType.Number,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.Number)}>
          <IDHFormattedMessage id="ws_number" defaultMessage="Number" />
        </ImageOption>
      ),
    },
    {
      value: MetaFieldType.Percent,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.Percent)}>
          <IDHFormattedMessage id="ws_percent" defaultMessage="Percent" />
        </ImageOption>
      ),
    },
    {
      value: MetaFieldType.Currency,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.Currency)}>
          <IDHFormattedMessage id="ws_currency" defaultMessage="Currency" />
        </ImageOption>
      ),
    },
    {
      value: MetaFieldType.Date,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.Date)}>
          <IDHFormattedMessage id="ws_date" defaultMessage="Date" />
        </ImageOption>
      ),
    },
    {
      value: MetaFieldType.SingleSelect,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.SingleSelect)}>
          <IDHFormattedMessage
            id="ws_single_select"
            defaultMessage="Single-select"
          />
        </ImageOption>
      ),
    },
    {
      value: MetaFieldType.MultiSelect,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.MultiSelect)}>
          <IDHFormattedMessage
            id="ws_multi_select"
            defaultMessage="Multi-select"
          />
        </ImageOption>
      ),
    },
    {
      value: MetaFieldType.AggregatedSelect,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.AggregatedSelect)}>
          <IDHFormattedMessage
            id="ws_deliverables"
            defaultMessage="Deliverables"
          />
        </ImageOption>
      ),
    },
    {
      value: MetaFieldType.BoolVal,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.BoolVal)}>
          <IDHFormattedMessage id="ws_checkbox" defaultMessage="Checkbox" />
        </ImageOption>
      ),
    },
    {
      value: MetaFieldType.File,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.File)}>
          <IDHFormattedMessage id="ws_file" defaultMessage="File" />
        </ImageOption>
      ),
    },
    ...(dataType === tableDataType.Task ? [...taskOptions] : []),
    {
      value: MetaFieldType.AccessLink,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.AccessLink)}>
          <IDHFormattedMessage id="ws_form" defaultMessage="Form" />
        </ImageOption>
      ),
    },
    {
      value: MetaFieldType.Rating,
      label: (
        <ImageOption icon={getMetaFieldIcon(MetaFieldType.Rating)}>
          <IDHFormattedMessage id="ws_rating" defaultMessage="Rating" />
        </ImageOption>
      ),
    },
  ];

  return options.filter((option) => {
    switch (dataType) {
      case tableDataType.RecruitmentForm:
        return !RECRUITMENT_FORM_METAFIELDS_BLACKLIST.includes(option.value);
      default:
        return true;
    }
  });
};

export const filterGlobalMetaFields = (
  fields: Array<GlobalField>,
  dataType: tableDataType | undefined,
) => {
  switch (dataType) {
    case tableDataType.RecruitmentForm:
      return fields.filter(
        (field) =>
          !RECRUITMENT_FORM_METAFIELDS_BLACKLIST.includes(field.type) &&
          !field.valueSource,
      );

    default:
      return fields;
  }
};
