const ALLOWED_ATTACHMENT_EXTENSIONS = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "pdf",
  "xls",
  "doc",
  "docx",
  "pptx",
  "ppt",
  "txt",
  "zip",
  "mp3",
  "mp4",
  "avi",
  "mov",
  "csv",
  "xlsx",
  "odt",
  "ods",
  "webm",
];

const MAX_PRESIGNED_REQUESTS = 10;

export { ALLOWED_ATTACHMENT_EXTENSIONS, MAX_PRESIGNED_REQUESTS };
