import React from "react";

import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { ModalText, ModalTitle } from "src/app/components/Modal/Modal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SettingsButtons } from "../../components/SettingsTabContent/SettingsTabContent";
import ManageGlobalFields from "./ManageGlobalFields";

import "./ManageFields.scss";

interface ManageFieldsProps {
  onClose: () => void;
}

export default function ManageFields({ onClose }: ManageFieldsProps) {
  return (
    <>
      <AnimatedDiv className="manage-fields">
        <ModalTitle>
          <IDHFormattedMessage
            id="ws_manage_data_fields"
            defaultMessage="Manage data fields"
          />
        </ModalTitle>

        <ModalText>
          <IDHFormattedMessage
            id="ws_enhance_your_campaigns_by_creating_and_managing"
            defaultMessage="Enhance your campaigns by creating and managing your own library of custom data fields. It allows you to streamline campaign attributes and object data, tailored to your requirements."
          />
        </ModalText>

        <ManageGlobalFields />
      </AnimatedDiv>

      <SettingsButtons onClose={onClose} />
    </>
  );
}
