import React, { useState } from "react";
import "./SaveAsModal.scss";

import axios from "axios";
import { uuidv7 as uuid } from "uuidv7";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { injectIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { showErrorToast } from "src/utils/methods";
import { API_URLS } from "../../../../utils/API_URLS";
import Modal, { ModalText, ModalTitle } from "../../../components/Modal/Modal";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { getShowcaseSettingByKey } from "../../utils";

function SaveAsModal({
  onClose,
  getTemplates,
  layout,
  dataType,
  setSelectedTemplate,
  intl,
}) {
  const [templateName, setTemplateName] = useState("");

  const workspaceUuid = getShowcaseSettingByKey("workspaceId");

  const createNewTemplate = async () => {
    const template = layout.map((item) => ({
      i: item.i,
      x: item.x,
      y: item.y,
      w: item.w,
      h: item.h,
    }));

    const templateUuid = uuid();

    try {
      await axios.post(API_URLS.createReportTemplate, {
        uuid: templateUuid,
        wsWorkspaceUuid: workspaceUuid,
        name: templateName,
        layout: JSON.stringify(template),
        type: dataType,
      });

      const templateData = {
        label: templateName,
        value: {
          type: "custom",
          uuid: templateUuid,
          layout: template,
        },
      };

      setSelectedTemplate(templateData);
      getTemplates();
      onClose();
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  return (
    <Modal
      onClose={onClose}
      className="save-as-modal"
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={createNewTemplate}
      confirmButtonDisabled={!templateName}
      confirmButtonText={
        <IDHFormattedMessage id="ws_save" defaultMessage="Save" />
      }
    >
      <ModalTitle>
        <IDHFormattedMessage id="ws_save_as" defaultMessage="Save as" />
        ...
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_save_creators_card_structure"
          defaultMessage="Save the creator's card structure as a template."
        />
      </ModalText>
      <CustomInput
        value={templateName}
        onChange={(e) => setTemplateName(e.target.value)}
        placeholder={translateMessage({
          intl,
          id: "ws_enter_template_name",
          defaultMessage: "Enter template name",
        })}
      />
    </Modal>
  );
}

export default injectIntl(SaveAsModal);
