import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import { TextBox } from "src/app/components/TextBox/TextBox";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { UserContent } from "src/redux/comment/commentReducer";
import { API_URLS } from "src/utils/API_URLS";
import { wsAxiosPost } from "src/helpers/wsAxios";
import {
  clearContentProposalFilterStatus,
  clearUserAcceptedContent,
  clearUserRejectedContent,
  getTaskDetails,
  getWsContentProposalElementsSummary,
} from "src/redux";
import { ContentProposalElementStatus } from "src/redux/comment/types";
import { Button } from "src/app/components/Button/Button";
import { translateMessage } from "src/app/methods/translateMessage";
import AddCommentFieldButton from "../AddCommentField/AddCommentFieldButton";
import AcceptedRejectedContentHeader, {
  ReplyingToHeader,
} from "./AcceptedRejectedContentHeader";
import scrollElementToBottom from "../../../../helpers/scrollElementToBottom";

interface ModerateContentProposalFieldProps {
  status: ContentProposalElementStatus;
  moderatedProposalElements: UserContent;
  taskId: string;
  hideContentProposalField: () => void;
}

export default function ModerateContentProposalField({
  status,
  moderatedProposalElements,
  taskId,
  hideContentProposalField,
}: ModerateContentProposalFieldProps) {
  const [value, setValue] = useState("");
  const [encodedValue, setEncodedValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const intl = useIntl();

  const handleModerateContentProposal = async () => {
    setIsLoading(true);

    const wsContentProposalElementUuids =
      moderatedProposalElements.contentProposalElements.map((el) => el.uuid);
    try {
      await wsAxiosPost(
        API_URLS.moderateContentProposal,
        {
          comment: encodedValue.length > 0 ? encodedValue : null,
          wsTaskUuid: taskId,
          wsContentProposalElementUuids,
          wsTaskCommentUuid: moderatedProposalElements.wsTaskCommentUuid,
          status,
        },
        undefined,
        () => {
          setIsLoading(true);
        },
        () => {
          dispatch(clearUserAcceptedContent());
          dispatch(clearUserRejectedContent());

          setValue(" ");
          setValue("");
          dispatch(getWsContentProposalElementsSummary(taskId));
          dispatch(clearContentProposalFilterStatus());
        },
        () => {
          setIsLoading(false);
        },
      );
      await dispatch(getTaskDetails(taskId));
      setTimeout(() => {
        scrollElementToBottom("task__container");
      }, 1500);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="add-comment-field add-comment-field--focus">
      <TextBox
        className="add-comment-field__textarea, add-comment-field__textarea--focus"
        value={value}
        setEncodedValue={setEncodedValue}
        placeholder={translateMessage({
          intl,
          id: "ws_write_a_comment_on_the_content",
          defaultMessage: "Write a comment on the content",
        })}
        showButtons
        enableMentions
        enableEmoji
        bottomRightButton={
          <>
            <Button
              variant="white-with-black-border"
              size="medium"
              className="cancel-button"
              onClick={hideContentProposalField}
            >
              <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
            </Button>
            <AddCommentFieldButton
              disabled={isLoading}
              onCommentClick={handleModerateContentProposal}
              buttonText={
                <IDHFormattedMessage id="ws_send" defaultMessage="Send" />
              }
            />
          </>
        }
        editorHeaderChildren={
          <ReplyingToHeader>
            <AcceptedRejectedContentHeader
              status={status}
              content={moderatedProposalElements.contentProposalElements}
            />
          </ReplyingToHeader>
        }
      />
    </div>
  );
}
