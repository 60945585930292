import React from "react";
import classNames from "classnames";

import { Button } from "src/app/components/Button/Button";
import CustomInput from "src/app/components/CustomInput/CustomInput";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useCopyToClipboard } from "src/app/methods/useCopyToClipboard";
import { ReactComponent as CopyIcon } from "src/images/link-white.svg";
import { ReactComponent as TickIcon } from "src/images/tick-green.svg";

import "./CopyValueInput.scss";

interface CopyValueInputProps {
  displayedValue: string;
  copiedValue: string;
  className?: string;
}

export default function CopyValueInput({
  displayedValue,
  copiedValue,
  className,
}: CopyValueInputProps) {
  const { isCopied, copy } = useCopyToClipboard();
  return (
    <div className={classNames("copy-value-input", className)}>
      <CustomInput type="text" readOnly value={displayedValue} />
      <Button
        size="large"
        variant="blue"
        className="copy-value-input__share-btn"
        onClick={() => copy(copiedValue)}
      >
        {isCopied ? (
          <>
            <TickIcon />
            <IDHFormattedMessage id="ws_copied" defaultMessage="Copied" />{" "}
          </>
        ) : (
          <>
            <CopyIcon />
            <IDHFormattedMessage id="ws_copy_link" defaultMessage="Copy link" />
          </>
        )}
      </Button>
    </div>
  );
}
