import React from "react";
import "./SentimentList.scss";

import { StatisticListItem } from "../../../PublicationSummaryTab/components/StatisticListItem/StatisticListItem";

function SentimentList(props) {
  const { sentimentData, platform, platformDisplayName } = props;

  return (
    <div className="task-sentiment-container">
      {sentimentData &&
        sentimentData.map((item) => (
          <StatisticListItem
            item={item}
            platform={platform}
            platformDisplayName={platformDisplayName}
            key={item.statistic}
          />
        ))}
    </div>
  );
}

export { SentimentList };
