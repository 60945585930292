import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { ReactComponent as EditIcon } from "src/images/edit.svg";
import { ReactComponent as PlusAltIcon } from "src/images/plus-alt.svg";
import { IntlShape, WrappedComponentProps, injectIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { TaskType } from "src/types";
import { CreatorInDatabaseLink } from "src/app/CreatorDatabase/components/CreatorInDatabaseLink/CreatorInDatabaseLink";
import { RootState } from "src/redux/reducers";
import {
  changeTaskMetaFieldValue,
  getMyTasks,
  getSingleTaskForList,
  getTaskDetails,
} from "../../../redux";
import { Metadata } from "../../Task/Subtask/Subtask";
import Check from "../Check/Check";
import { showToast } from "../../methods/showToast";
import SocialProfileIcons from "../IconsWithStatus/SocialProfileIcons/SocialProfileIcons";
import AddSocialProfilesModal from "../../modals/AddSocialProfilesModal/AddSocialProfilesModal";
import { Button } from "../Button/Button";
import UpdatePublicationLinkModal from "../../modals/UpdatePublicationLinkModal/UpdatePublicationLinkModal";
import OuterLink from "../OuterLink/OuterLink";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import { ScalingInput } from "../ScalingInput/ScalingInput";

import "./PersonInfo.scss";
import XssFilter from "../XssFilter/XssFilter";

interface Props extends WrappedComponentProps<"intl"> {
  projectId: string;
  taskType: string;
  onMyTasks?: boolean;
  intl: IntlShape;
  hasAccessToCreatorDatabase: boolean;
  showUpdatePublicationLinkModal: boolean;
  setShowUpdatePublicationLinkModal: Dispatch<SetStateAction<boolean>>;
}

const PersonInfo: React.FC<Props> = (props) => {
  const {
    taskType,
    onMyTasks,
    hasAccessToCreatorDatabase,
    intl,
    showUpdatePublicationLinkModal,
    setShowUpdatePublicationLinkModal,
  } = props;

  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [showAddSocialProfilesModal, setShowAddSocialProfilesModal] =
    useState(false);

  const dispatch = useDispatch();

  const {
    mainReducer: { activeWorkspaceUuid },
    taskReducer: { taskDetails },
    projectReducer: { projectBasicData },
  } = useSelector((state: RootState) => state);

  const [title, setTitle] = useState(taskDetails?.title);

  const renameTask = async (e: React.FormEvent) => {
    e.preventDefault();

    if (title === taskDetails.title) {
    } else if (title.length === 0) {
      setTitle(taskDetails?.title);
    } else {
      const titleFieldId = taskDetails?.metadata?.find(
        (metafield: Metadata) => metafield.key === "title",
      ).uuid;
      await dispatch(
        changeTaskMetaFieldValue(taskDetails?.taskId, titleFieldId, title),
      );
      await dispatch(getSingleTaskForList(taskDetails?.taskId));
      await dispatch(getTaskDetails(taskDetails?.taskId));
    }
  };

  useEffect(() => {
    const completed = taskDetails?.metadata?.find(
      (field: Metadata) => field.key === "completed",
    );

    setTitle(taskDetails?.title);

    if (completed) {
      setIsComplete(completed.value);
    }
  }, [taskDetails]);

  const setTaskCompleted = async () => {
    try {
      const completed = taskDetails.metadata.find(
        (field: Metadata) => field.key === "completed",
      );

      await dispatch(
        changeTaskMetaFieldValue(
          taskDetails.projectId || taskDetails.taskId,
          completed.uuid,
          !isComplete,
        ),
      );

      // This is temporary, we should update single task but right now we don't have endpoint for it.
      if (onMyTasks) {
        await dispatch(getMyTasks(activeWorkspaceUuid));
      }

      // dispatch(getTaskDetails(taskDetails.taskId));
    } catch (error) {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_error_while_completing_an_action"
          defaultMessage="Error while completing an action!"
        />,
      );
    }
  };

  return (
    <div className="person-info">
      {(taskType !== TaskType.Publication ||
        (taskType === TaskType.Publication && !taskDetails.publication)) && (
        <div className="person-info__input-wrapper">
          {(taskType === TaskType.Payment || taskType === TaskType.Action) && (
            <Check
              isCheck={isComplete}
              setIsCheck={(value) => {
                setIsComplete(value);
                setTaskCompleted();
              }}
              big
            />
          )}
          <ScalingInput
            className={classNames("person-info__name", {
              "person-info__name--complete": isComplete,
            })}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onBlur={renameTask}
            placeholder={`${translateMessage({
              intl,
              id: "ws_type_action_name",
              defaultMessage: "Type Action name",
            })}...`}
          />
          {hasAccessToCreatorDatabase && !projectBasicData?.seeAsClientMode && (
            <CreatorInDatabaseLink
              globalTaskUuid={taskDetails.wsGlobalTaskUuid}
            />
          )}
        </div>
      )}

      {taskType === TaskType.Publication &&
      taskDetails.publication &&
      taskDetails.publication.url ? (
        <div className="person-info__publication-title">
          <div
            className="person-info__description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: XssFilter(title) }}
          />
          <div className="person-info__publication-url-wrapper">
            <OuterLink
              href={taskDetails.publication.url}
              className="person-info__publication-url"
            >
              {taskDetails.publication.url}
            </OuterLink>
            <EditIcon
              className="person-info__edit-publication-url"
              onClick={() => setShowUpdatePublicationLinkModal(true)}
            />
          </div>
        </div>
      ) : taskType === TaskType.Publication && !taskDetails.publication ? (
        <Button
          variant="bare"
          size="small"
          onClick={() => setShowUpdatePublicationLinkModal(true)}
        >
          <PlusAltIcon />
          <IDHFormattedMessage id="ws_add_link" defaultMessage="Add link" />
        </Button>
      ) : (
        taskType === TaskType.Publication && (
          <div className="person-info__publication-title">
            <div className="person-info__description">{title}</div>
            <div className="person-info__publication-url-wrapper">
              <Button
                variant="bare"
                size="small"
                onClick={() => setShowUpdatePublicationLinkModal(true)}
              >
                <PlusAltIcon />
                <IDHFormattedMessage
                  id="ws_add_link"
                  defaultMessage="Add link"
                />
              </Button>
            </div>
          </div>
        )
      )}

      {/* <div className="person-info__name">{title}</div> */}

      {taskType === TaskType.Creator && (
        <>
          {taskDetails?.socialProfiles?.length ? (
            <div className="person-info__icons-wrapper">
              <SocialProfileIcons
                data={taskDetails.socialProfiles}
                size="big"
              />
              <EditIcon
                className="person-info__edit-icon"
                onClick={() => setShowAddSocialProfilesModal(true)}
              />
            </div>
          ) : (
            <Button
              variant="bare"
              size="small"
              onClick={() => setShowAddSocialProfilesModal(true)}
            >
              <PlusAltIcon />
              <IDHFormattedMessage
                id="ws_add_social_profiles"
                defaultMessage="Add social profiles"
              />
            </Button>
          )}
        </>
      )}

      {showAddSocialProfilesModal && (
        <AddSocialProfilesModal
          objectType="task"
          onClose={() => setShowAddSocialProfilesModal(false)}
          name={taskDetails.title}
          taskId={taskDetails.taskId}
          socialProfiles={taskDetails.socialProfiles}
        />
      )}

      {showUpdatePublicationLinkModal && (
        <UpdatePublicationLinkModal
          onClose={() => setShowUpdatePublicationLinkModal(false)}
          taskId={taskDetails.taskId}
          publicationUrl={taskDetails?.publication?.url}
        />
      )}
    </div>
  );
};

export default injectIntl(PersonInfo);
