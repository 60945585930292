import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import classNames from "classnames";

import { ProjectPermissions, TaskType } from "src/types";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ROUTES, getRoute } from "src/utils/ROUTES";
import { Img } from "src/app/components/Img/Img";
import { ReactComponent as PlusAltIcon } from "../../../../images/plus-alt.svg";
import { ReactComponent as ClientOrangeIcon } from "../../../../images/client-orange.svg";
import placeholderIcon from "../../../../images/placeholder-image.svg";
import publicationPlaceholder from "../../../../images/publication-placeholder-grey.svg";
import projectPlaceholder from "../../../../images/project-placeholder.svg";

import {
  tableDataType,
  taskAvatarShape as TaskAvatarShape,
} from "../../../components/Table/Table";
import { Metadata } from "../../../Task/Subtask/Subtask";
import {
  changeTaskMetaFieldValue,
  hideShareMultipleColumnsModal,
  hideStopSharingWithClientModal,
  openShareMultipleColumnsModal,
  openStopSharingWithClientModal,
  setShowTask,
} from "../../../../redux";
import { TASK_TYPES } from "../../../../utils/variables";
import Skeleton, {
  SkeletonCircle,
  SkeletonText,
} from "../../../components/Skeleton/Skeleton";
import Check from "../../../components/Check/Check";
import SocialProfileIcons from "../../../components/IconsWithStatus/SocialProfileIcons/SocialProfileIcons";
import { Button } from "../../../components/Button/Button";
import AddSocialProfilesModal from "../../../modals/AddSocialProfilesModal/AddSocialProfilesModal";
import UpdatePublicationLinkModal from "../../../modals/UpdatePublicationLinkModal/UpdatePublicationLinkModal";
import { ShareMultipleColumnsModal } from "../../../modals/ShareMultipleColumnsModal/ShareMultipleColumnsModal";
import Tooltip from "../../../components/Tooltip/Tooltip";
import StopSharingWithClientModal from "../../../modals/StopSharingWithClientModal/StopSharingWithClientModal";
import { getImageSource } from "../../../../utils/methods";
import Pictogram, {
  PictogramVariants,
} from "../../../components/Pictogram/Pictogram";
import XssFilter from "../../../components/XssFilter/XssFilter";
import TaskInfoPublicationControls from "./components/TaskInfoPublicationControls";

interface TaskInfoInterface {
  activeWorkspaceUuid: string;
  clientMode: boolean;
  dataType: tableDataType;
  disableLink?: boolean;
  item: any;
  openProjectDetailsPane?: () => void;
  parentLoading?: boolean;
  permissions: ProjectPermissions;
  showShareMultipleColumnsModal: string;
  showStopSharingWithClientModal: string;
  singleRow?: boolean;
  taskAvatarShape: TaskAvatarShape;
  taskType: string;
  tasksLoading: boolean;
}

export default function TaskInfo({
  activeWorkspaceUuid,
  clientMode,
  dataType,
  disableLink,
  item,
  openProjectDetailsPane,
  parentLoading,
  permissions,
  showShareMultipleColumnsModal,
  showStopSharingWithClientModal,
  singleRow,
  taskAvatarShape,
  taskType,
  tasksLoading,
}: TaskInfoInterface) {
  const params = useParams<{ projectId: string }>();
  const dispatch = useDispatch();

  const [coverImageUrl, setCoverImageUrl] = useState<any>(
    getImageSource(item?.cover, "tiny"),
  );
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [showAddSocialProfilesModal, setShowAddSocialProfilesModal] =
    useState(false);
  const [showUpdatePublicationLinkModal, setShowUpdatePublicationLinkModal] =
    useState<boolean>(false);

  useEffect(() => {
    const src = getImageSource(item?.cover, "tiny");

    if (item.cover === null) {
      setCoverImageUrl(null);
    } else if (src && src !== coverImageUrl) {
      setCoverImageUrl(src);
    }
  }, [item.cover]);

  useEffect(() => {
    const completed = item.metadata.find(
      (field: Metadata) => field.key === "completed",
    );

    if (completed) {
      setIsCompleted(completed.value);
    }
  }, [item]);

  const setTaskCompleted = () => {
    const completed = item.metadata.find(
      (field: Metadata) => field.key === "completed",
    );

    dispatch(
      changeTaskMetaFieldValue(
        item.projectId || item.taskId,
        completed.uuid,
        !isCompleted,
      ),
    );
  };

  const isTask = () => {
    return TASK_TYPES.includes(item?.taskType);
  };

  const isProject = () => {
    return !isTask();
  };

  const openSocialMediaModal = (e: any) => {
    e.stopPropagation();
    setShowAddSocialProfilesModal(true);
  };

  const openUpdatePublicationLinkModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    // TODO: check why here is stopPropagation
    e.stopPropagation();
    setShowUpdatePublicationLinkModal(true);
  };

  const getPlaceholderImage = (cover = true) => {
    if (coverImageUrl && cover) {
      return coverImageUrl;
    }

    if (item.taskType === TaskType.Publication) {
      return publicationPlaceholder;
    }

    if (isProject()) {
      return projectPlaceholder;
    }

    if (isTask()) {
      return placeholderIcon;
    }

    return placeholderIcon;
  };

  const getCoverSkeleton = () => {
    switch (taskType) {
      case TaskType.Action:
      case TaskType.Payment:
        return <SkeletonCircle size={16} />;

      case TaskType.Publication:
      case TaskType.Content:
        return <Skeleton width={48} height={48} borderRadius={8} />;

      default:
        return <SkeletonCircle size={48} />;
    }
  };

  if (tasksLoading && dataType === tableDataType.Task) {
    return (
      <div className="tasks-table__td-info-loader">
        {getCoverSkeleton()}
        <SkeletonText width={60} height={15} marginLeft={12} />
      </div>
    );
  }

  const hasShareProjectResourcesPermission = () =>
    permissions?.project.includes("share_project_resources");

  const openShareMultipleColumnsModalWrapper = () => {
    if (hasShareProjectResourcesPermission()) {
      dispatch(openShareMultipleColumnsModal(item.taskId));
    }
  };

  const openStopSharingWithClientModalWrapper = () => {
    if (hasShareProjectResourcesPermission()) {
      dispatch(openStopSharingWithClientModal(item.taskId));
    }
  };

  return (
    <div className="ws-table__content ws-table__content--tasks-table">
      <div className="ws-table__row-info">
        {parentLoading ? (
          item.taskType !== TaskType.Creator ? (
            <Skeleton width={48} height={48} borderRadius={8} />
          ) : (
            <SkeletonCircle size={48} />
          )
        ) : item.taskType !== TaskType.Action &&
          item.taskType !== TaskType.Payment ? (
          <Link
            to={getRoute(
              ROUTES.TASK_DETAILS,
              {
                wsWorkspaceUuid: activeWorkspaceUuid,
                wsProjectUuid: params.projectId,
                wsTaskUuid: item.taskId,
              },
              { taskType },
            )}
            className={classNames("ws-table__image", {
              "ws-table__image--square": item.taskType !== TaskType.Creator,
            })}
            onClick={() => dispatch(setShowTask(true))}
          >
            <Img
              className={classNames("ws-table__image-cover", {
                "ws-table__image-cover--circle":
                  taskAvatarShape === TaskAvatarShape.Circle,
                "ws-table__image-cover--square":
                  taskAvatarShape === TaskAvatarShape.Square,
              })}
              src={getPlaceholderImage()}
              fallbackImageSrc={getPlaceholderImage(false)}
              alt=""
            />
          </Link>
        ) : (
          <Check
            isCheck={isCompleted}
            setIsCheck={(value) => {
              setIsCompleted(value);
              setTaskCompleted();
            }}
          />
        )}
        {parentLoading ? (
          <Skeleton width={80} height={15} variant="text" marginLeft={12} />
        ) : !disableLink ? (
          <Link
            key={item.title}
            to={getRoute(
              ROUTES.TASK_DETAILS,
              {
                wsWorkspaceUuid: activeWorkspaceUuid,
                wsProjectUuid: params.projectId,
                wsTaskUuid: item.taskId,
              },
              { taskType: !singleRow ? taskType : "details" },
            )}
          >
            <div
              className="ws-table__name ws-table__name--clickable"
              onClick={!singleRow ? undefined : openProjectDetailsPane}
            >
              {item.projectName}
            </div>
          </Link>
        ) : (
          <div
            className={classNames("ws-table__name", {
              "ws-table__name--with-tick":
                item.taskType === TaskType.Action ||
                item.taskType === TaskType.Payment,
            })}
          >
            <div className="ws-table__task-name-wrapper">
              <Link
                key={item.title}
                to={getRoute(
                  ROUTES.TASK_DETAILS,
                  {
                    wsWorkspaceUuid: activeWorkspaceUuid,
                    wsProjectUuid: params.projectId,
                    wsTaskUuid: item.taskId,
                  },
                  { taskType },
                )}
                className="ws-table__task-name"
                onClick={() => dispatch(setShowTask(true))}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: XssFilter(item.title) }}
                />
              </Link>
              {permissions?.project.includes(
                "share_project_resources_preview",
              ) &&
                clientMode && (
                  <Tooltip
                    center
                    content={
                      item.shared ? (
                        <IDHFormattedMessage
                          id="ws_tooltip_click_to_stop_sharing"
                          defaultMessage="Click to stop sharing with Client"
                        />
                      ) : (
                        <IDHFormattedMessage
                          id="ws_tooltip_click_to_share"
                          defaultMessage="Click to share with Client"
                        />
                      )
                    }
                    contentHidden={permissions?.project.includes(
                      "share_project_resources",
                    )}
                  >
                    {item.shared ? (
                      <ClientOrangeIcon
                        className={classNames("ws-table__task-shared", {
                          "ws-table__task-shared--disabled":
                            permissions?.project.includes(
                              "share_project_resources",
                            ),
                        })}
                        onClick={() => openStopSharingWithClientModalWrapper()}
                      />
                    ) : (
                      <div
                        className={classNames("ws-table__task-not-shared", {
                          "ws-table__task-not-shared--disabled":
                            permissions?.project.includes(
                              "share_project_resources",
                            ),
                        })}
                        onClick={() => openShareMultipleColumnsModalWrapper()}
                      />
                    )}
                  </Tooltip>
                )}
            </div>

            {item.taskType === TaskType.Creator && (
              <div>
                {item.socialProfiles.length ? (
                  <SocialProfileIcons data={item.socialProfiles} />
                ) : (
                  <Button variant="transparent" onClick={openSocialMediaModal}>
                    <PlusAltIcon />
                    <IDHFormattedMessage
                      id="ws_add_social_profiles"
                      defaultMessage="Add social profiles"
                    />
                  </Button>
                )}
              </div>
            )}

            {item.taskType === TaskType.Publication && (
              <TaskInfoPublicationControls
                publication={item}
                openUpdatePublicationLinkModal={openUpdatePublicationLinkModal}
              />
            )}
          </div>
        )}
      </div>

      {showShareMultipleColumnsModal === item.taskId && (
        <ShareMultipleColumnsModal
          onClose={() => dispatch(hideShareMultipleColumnsModal())}
          taskAvatarShape={taskAvatarShape}
          tasks={[item]}
          taskType={item.taskType}
        />
      )}

      {showStopSharingWithClientModal === item.taskId && (
        <StopSharingWithClientModal
          onClose={() => dispatch(hideStopSharingWithClientModal())}
          taskAvatarShape={taskAvatarShape}
          getPlaceholderImage={getPlaceholderImage}
          task={item}
        />
      )}

      {showAddSocialProfilesModal && (
        <AddSocialProfilesModal
          objectType="task"
          onClose={() => setShowAddSocialProfilesModal(false)}
          name={item.title}
          taskId={item.taskId}
          socialProfiles={item.socialProfiles}
        />
      )}

      {showUpdatePublicationLinkModal && (
        <UpdatePublicationLinkModal
          onClose={() => setShowUpdatePublicationLinkModal(false)}
          taskId={item.taskId}
        />
      )}
    </div>
  );
}
