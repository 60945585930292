import React from "react";

import { SOCIAL_ICONS, SocialProfileStatus } from "src/types";
import { SocialProfile } from "../../../modals/AddSocialProfilesModal/AddSocialProfilesModal";
import TooltipPortal from "../../TooltipPortal/TooltipPortal";
import {
  existAtLeastOneSocialProfileWithOneOfStatuses,
  existAtLeastOneSocialProfileWithStatus,
  getTooltipBySocialProfileStatus,
  socialProfileStatusesWithTooltip,
  socialProfileStatusesWithWarning,
} from "./utils";
import SocialProfileStatusIcon from "./SocialProfileStatusIcon";
import SocialProfileLink from "./SocialProfileLink";
import SocialProfileIconsWrapper, {
  SocialProfileIconSizes,
} from "./SocialProfileIconsWrapper";

import "./SocialProfileIcons.scss";

interface SocialProfileIconsProps {
  data: SocialProfile[];
  size?: SocialProfileIconSizes;
  addDataImgSrc?: boolean;
}

function SocialProfileIcons({
  data,
  size,
  addDataImgSrc,
}: SocialProfileIconsProps) {
  const filteredData = Array.isArray(data)
    ? data
        .filter((item) =>
          Object.keys(SOCIAL_ICONS).includes(item.socialProvider),
        )
        ?.map((el) => ({
          ...el,
          icon: SOCIAL_ICONS[el.socialProvider],
        }))
    : [];

  return (
    <SocialProfileIconsWrapper size={size}>
      {filteredData.map((item) => (
        <SocialProfileLink
          key={item.socialUrl}
          status={item.status}
          url={item.socialUrl}
        >
          {socialProfileStatusesWithTooltip.includes(item.status) ? (
            <TooltipPortal
              contentClassName="suggestion-tooltip-content"
              content={getTooltipBySocialProfileStatus(item.status)}
              autoSize
            >
              <img
                src={!addDataImgSrc ? item.icon : undefined}
                data-img-src={addDataImgSrc ? item.icon : undefined}
                alt={item.socialProvider}
              />
            </TooltipPortal>
          ) : (
            <img
              src={!addDataImgSrc ? item.icon : undefined}
              data-img-src={addDataImgSrc ? item.icon : undefined}
              alt={item.socialProvider}
            />
          )}
        </SocialProfileLink>
      ))}

      {existAtLeastOneSocialProfileWithOneOfStatuses(
        filteredData,
        socialProfileStatusesWithWarning,
      ) && (
        <SocialProfileStatusIcon
          status={SocialProfileStatus.NotFound}
          addDataImgSrc={addDataImgSrc}
        />
      )}
      {existAtLeastOneSocialProfileWithStatus(
        SocialProfileStatus.Fresh,
        filteredData,
      ) && (
        <SocialProfileStatusIcon
          status={SocialProfileStatus.Fresh}
          addDataImgSrc={addDataImgSrc}
        />
      )}
    </SocialProfileIconsWrapper>
  );
}

export default SocialProfileIcons;
