import React from "react";
import "./TitleBox.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import CustomImage from "../../../../../components/CustomImage/CustomImage";
import ProjectPlaceholder from "../../../../../../images/project-placeholder.svg";
import { getImageSource } from "../../../../../../utils/methods";

export function TitleBox(props) {
  const { data } = props;

  return (
    <div className="title-box">
      <img src={getImageSource(data?.cover, "small", ProjectPlaceholder)} />
      <div className="title-box__info">
        <span className="title-box__info-header">
          <IDHFormattedMessage
            id="ws_campaign_report"
            defaultMessage="Campaign report"
          />
        </span>
        <span className="title-box__info-value">{data.title}</span>
      </div>
    </div>
  );
}
