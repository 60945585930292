import React from "react";
import "./FormulaSwitch.scss";

import { useIntl } from "react-intl";
import CustomRadio from "src/app/components/CustomRadio/CustomRadio";
import { FormulaTab } from "../../types";

interface Props {
  data: any;
  formulaTab: FormulaTab;
  setFormulaTab: (value: FormulaTab) => void;
}

export function FormulaSwitch({ data, formulaTab, setFormulaTab }: Props) {
  const intl = useIntl();

  return (
    <div className="formula-switch">
      <div className="formula-switch__input-wrapper">
        <CustomRadio
          id="table"
          name="structure"
          label={intl.formatMessage({
            id: "ws_table",
            defaultMessage: "Table",
          })}
          checked={formulaTab === "table"}
          onChange={() => setFormulaTab("table")}
          disabled={data}
        />

        <CustomRadio
          id="overview"
          name="structure"
          label={intl.formatMessage({
            id: "ws_overview",
            defaultMessage: "Overview",
          })}
          checked={formulaTab === "overview"}
          onChange={() => setFormulaTab("overview")}
          disabled={data}
        />
      </div>
    </div>
  );
}
