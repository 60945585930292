import React, { createRef } from "react";
import "./DefaultLayout.scss";

import PageHeader from "../../components/PageHeader/PageHeader";
import { PageSubHeader } from "../../components/PageSubHeader/PageSubHeader";

export const topbarRef = createRef();

export function DefaultLayout({
  name,
  header,
  subheader,
  scrollRef,
  children,
}) {
  return (
    <div className={`ws-page-default ws-page-default--${name}`} ref={scrollRef}>
      <div className="ws-page-default__topbar" ref={topbarRef}>
        <PageHeader title={header} />
        {subheader && <PageSubHeader>{subheader}</PageSubHeader>}
      </div>
      {children}
    </div>
  );
}
