import React, { Dispatch, SetStateAction, useEffect } from "react";
import "./HowItWorks.scss";

import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { StepOne } from "./steps/StepOne";
import { StepTwo } from "./steps/StepTwo";
import { StepThree } from "./steps/StepThree";

interface Props {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  onClose: () => void;
}

export const HowItWorks: React.FC<Props> = (props) => {
  const { currentStep, setCurrentStep, onClose } = props;

  useEffect(() => {
    return () => setCurrentStep(1);
  }, []);

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <StepOne onClose={onClose} />;
      case 2:
        return <StepTwo />;
      case 3:
        return <StepThree />;
      default:
        return null;
    }
  };

  return (
    <AnimatedDiv className="how-it-works">{renderStepContent()}</AnimatedDiv>
  );
};
