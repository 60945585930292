import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import {
  DragDropContext,
  DragUpdate,
  Draggable,
  DraggingStyle,
  DropResult,
  Droppable,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";

import CustomInput from "src/app/components/CustomInput/CustomInput";
import { translateMessage } from "src/app/methods/translateMessage";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import NoResultsMessage from "src/app/components/NoResultsMessage/NoResultsMessage";
import { ReactComponent as TickLightGrey } from "src/images/tick-light-grey.svg";
import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";
import { Button } from "src/app/components/Button/Button";
import { tableDataType } from "src/app/components/Table/Table";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { ReactComponent as TickBlue } from "src/images/tick-blue.svg";
import { ReactComponent as GridIcon } from "src/images/grid.svg";
import { generateRankString } from "src/utils/rankStrings";
import {
  updateGlobalProjectFieldRank,
  updateGlobalTaskFieldRank,
} from "src/redux";
import { RootState } from "src/redux/reducers";
import {
  GlobalProjectMetaField,
  GlobalTaskMetaField,
} from "src/redux/settings/settingsReducer";
import { filterGlobalMetaFields } from "src/app/methods/getMetaFieldTypeOptions";
import {
  MetaFieldIcon,
  renderTooltipContent,
  renderTooltipIcon,
  searchObjectsByName,
} from "./utils";
import { GlobalFieldActionDropdown } from "./components/GlobalFieldActionDropdown/GlobalFieldActionDropdown";
import ManageFieldModal from "../../ManageFieldModal";

import "./GlobalFields.scss";

export type GlobalField = GlobalProjectMetaField | GlobalTaskMetaField;

interface Coordinates {
  clientHeight: number;
  clientWidth: number;
  clientY: number;
  clientX: number;
}
interface GlobalFieldsProps {
  options: Array<GlobalField>;
  dataType?: tableDataType;
  selectedGlobalMetaFields?: Array<GlobalField>;
  setSelectedGlobalMetaFields?: Dispatch<SetStateAction<Array<GlobalField>>>;
}

function GlobalFields({
  options,
  dataType,
  selectedGlobalMetaFields,
  setSelectedGlobalMetaFields,
}: GlobalFieldsProps) {
  const [value, setValue] = useState("");
  const [displayOptions, setDisplayOptions] = useState<Array<GlobalField>>([]);
  const [showAddFieldModal, setShowAddFieldModal] = useState(false);
  const [coordinates, setCoordinates] = useState<Coordinates>(
    {} as Coordinates,
  );

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const intl = useIntl();

  const filteredOptions = useMemo(
    () => filterGlobalMetaFields(options, dataType),
    [options, dataType],
  );

  const enableFieldManaging = dataType;

  const handleSelectGlobalMetaFields = (selectedField: GlobalField) => {
    if (!setSelectedGlobalMetaFields) return;
    setSelectedGlobalMetaFields((prev) => {
      const isFieldSelected = prev.find(
        (field) => field.uuid === selectedField.uuid,
      );

      if (isFieldSelected) {
        const updatedGlobalMetaFieldData = prev.filter(
          (field) => field.uuid !== selectedField.uuid,
        );
        return [...updatedGlobalMetaFieldData];
      }

      return [...prev, selectedField];
    });
  };

  const handleClick = (option: GlobalField) => {
    if (!option.existsInProject && !enableFieldManaging) {
      handleSelectGlobalMetaFields(option);
    }
  };

  useEffect(() => {
    if (!value) {
      setDisplayOptions(filteredOptions);
    } else {
      const searchResults = searchObjectsByName(value, filteredOptions);
      setDisplayOptions(searchResults);
    }
  }, [value, filteredOptions]);

  useEffect(() => {
    return () => {
      if (setSelectedGlobalMetaFields) {
        setSelectedGlobalMetaFields([]);
      }
    };
  }, []);

  const handleDragUpdate = (update: DragUpdate) => {
    if (!update.destination) {
      return;
    }

    const queryAttr = "data-rbd-drag-handle-draggable-id";

    const { draggableId } = update;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery) as {
      clientHeight: number;
      clientWidth: number;
      parentNode: any;
    };

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, destinationIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setCoordinates({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft,
      ),
    });
  };

  const updateRank = (uuid: string, newRank: string) => {
    switch (dataType) {
      case tableDataType.Task:
        dispatch(updateGlobalTaskFieldRank(activeWorkspaceUuid, uuid, newRank));
        break;

      case tableDataType.Project:
        dispatch(
          updateGlobalProjectFieldRank(activeWorkspaceUuid, uuid, newRank),
        );
        break;
    }
  };

  const handleDragEnd = async (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }
    const { destination, source } = result;

    const sourceField: any = filteredOptions[source.index];
    const targetField: any = filteredOptions[destination.index];
    const fieldIndex = filteredOptions.findIndex(
      (field) => field.uuid === targetField.uuid,
    );

    let newRank = "";
    if (destination.index < source.index) {
      newRank = generateRankString(
        filteredOptions[fieldIndex - 1]?.rank || "",
        filteredOptions[fieldIndex].rank,
      );
      // move bottom
    } else {
      newRank = generateRankString(
        filteredOptions[fieldIndex].rank,
        filteredOptions[fieldIndex + 1]?.rank || "",
      );
    }
    setCoordinates({} as Coordinates);

    updateRank(sourceField.uuid, newRank);
  };

  const getElementTop = (
    style: DraggingStyle | NotDraggingStyle | undefined,
  ) => {
    if (style && "top" in style) {
      if (window.innerWidth < 1024) {
        return style.top - 235;
      }

      if (window.innerWidth < 1400) {
        return style.top - 205;
      }

      return style.top - 135;
    }
    return "auto";
  };

  return (
    <AnimatedDiv className="global-fields">
      <div
        className={classNames("global-fields__input-wrapper", {
          "global-fields__input-wrapper--with-button": enableFieldManaging,
        })}
      >
        <CustomInput
          type="text"
          placeholder={translateMessage({
            intl,
            id: "ws_search_for_predefined_column",
            defaultMessage: "Search for a predefined column",
          })}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue(e.target.value)
          }
        />
        {enableFieldManaging && (
          <Button
            size="large"
            variant="blue"
            onClick={() => setShowAddFieldModal(true)}
          >
            <PlusIcon />
            <IDHFormattedMessage id="ws_add_new" defaultMessage="Add new" />
          </Button>
        )}
      </div>
      <div className="global-fields__header">
        <span className="global-fields__header-column-name">
          <IDHFormattedMessage
            id="ws_column_name"
            defaultMessage="Column name"
          />
        </span>
        {dataType === tableDataType.Task && (
          <span className="global-fields__header-reserved-for">
            <IDHFormattedMessage
              id="ws_applicable_for"
              defaultMessage="Applicable for"
            />
          </span>
        )}
        <span className="global-fields__header-type">
          <IDHFormattedMessage id="ws_type" defaultMessage="Type" />
        </span>
        {enableFieldManaging && (
          <span className="global-fields__header-action">
            <IDHFormattedMessage id="ws_action" defaultMessage="Action" />
          </span>
        )}
      </div>
      <DragDropContext
        onDragUpdate={handleDragUpdate}
        onDragEnd={(result) => handleDragEnd(result)}
      >
        <Droppable droppableId="droppable" direction="vertical">
          {(provided, snapshot) => (
            <ul
              ref={provided.innerRef}
              className="global-fields__list"
              {...provided.droppableProps}
            >
              {displayOptions.length > 0 ? (
                displayOptions
                  .sort((a, b) => a.rank.localeCompare(b.rank))
                  .map((option, index) => {
                    const isFieldSelected = selectedGlobalMetaFields?.find(
                      (field) => field.uuid === option.uuid,
                    );
                    return (
                      <Draggable
                        key={option.uuid}
                        draggableId={option.uuid}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            className={classNames(
                              "global-fields__list-item",
                              option.existsInProject &&
                                "global-fields__list-item--disabled",
                            )}
                            key={option.uuid}
                            onClick={() => handleClick(option)}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              top: getElementTop(provided.draggableProps.style),
                            }}
                            data-qa-field-name={option.name}
                          >
                            <span
                              className="global-fields__list-item-drag"
                              {...provided.dragHandleProps}
                            >
                              <GridIcon />
                            </span>
                            <div className="global-fields__text">
                              <div className="global-fields__name">
                                <span className="global-fields__name-text">
                                  {option.name}
                                </span>
                                &nbsp;
                                {option.existsInProject && (
                                  <TickLightGrey />
                                )}{" "}
                                {isFieldSelected && !option.existsInProject && (
                                  <TickBlue />
                                )}
                              </div>
                              <div className="global-fields__description">
                                {option.description}
                              </div>
                            </div>
                            {dataType === tableDataType.Task && (
                              <div className="global-fields__reserved-for">
                                {option.taskType || (
                                  <IDHFormattedMessage
                                    id="ws_all"
                                    defaultMessage="All"
                                  />
                                )}
                              </div>
                            )}
                            <div className="global-fields__icon">
                              <TooltipPortal
                                content={renderTooltipContent(
                                  option.type,
                                  option.valueSource,
                                )}
                              >
                                <MetaFieldIcon
                                  icon={renderTooltipIcon(
                                    option.type,
                                    option.valueSource,
                                  )}
                                />
                              </TooltipPortal>
                            </div>
                            {enableFieldManaging && (
                              <div className="global-fields__action">
                                <GlobalFieldActionDropdown
                                  data={option}
                                  dataType={dataType}
                                  projectId=""
                                />
                              </div>
                            )}
                          </li>
                        )}
                      </Draggable>
                    );
                  })
              ) : (
                <NoResultsMessage />
              )}
              {provided.placeholder}
              {coordinates && snapshot.isDraggingOver && (
                <AnimatedDiv
                  className="global-fields__list-placeholder"
                  style={{
                    top: coordinates?.clientY,
                    left: coordinates?.clientX,
                    height: coordinates?.clientHeight,
                    width: coordinates?.clientWidth,
                  }}
                />
              )}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      {showAddFieldModal && enableFieldManaging && (
        <ManageFieldModal
          onClose={() => setShowAddFieldModal(false)}
          title={
            <IDHFormattedMessage id="ws_add_field" defaultMessage="Add field" />
          }
          inputLabelText={
            <IDHFormattedMessage id="ws_name" defaultMessage="Name" />
          }
          dataType={dataType}
          projectId="projectId"
          globalFieldMode
        />
      )}
    </AnimatedDiv>
  );
}

export default GlobalFields;
