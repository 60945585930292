import React from "react";
import "./SelectedFiltersCounter.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export function SelectedFiltersCounter({ amount }) {
  return amount > 0 ? (
    <div className="selected-filters-counter">
      {amount}{" "}
      <IDHFormattedMessage id="front_selected" defaultMessage="selected" />
    </div>
  ) : (
    <></>
  );
}
