import React, { useState, useEffect } from "react";
import ImgChecker from "src/app/components/ImgChecker/ImgChecker";
import { GoToSocial } from "../../audiences-manager/components/GoToSocial";
import PostPreview from "../../campaign-details/PostPreview/PostPreview";

function PostImagesChecker({
  data,
  render,
  max = 3,
  socialProfileUrl,
  socialProvider,
}) {
  const [successImages, setSuccessImages] = useState([]);
  const [dataIndex, setDataIndex] = useState(0);

  /* Because sometimes we receive null from image's link and we don't want to
  show placeholders in recent posts column. We use this component to create array
  containing only existing images. */

  const addSuccessImage = (item) => {
    setSuccessImages([...successImages, item]);
    incrementIndex();
  };

  const incrementIndex = () => {
    if (dataIndex + 1 < data.length && successImages.length < max) {
      setDataIndex((prev) => prev + 1);
    }
  };

  return (
    <>
      {(data.length === dataIndex + 1 && successImages.length === 0) ||
      data.length === 0 ? (
        <GoToSocial
          socialProfileUrl={socialProfileUrl}
          socialProvider={socialProvider}
        />
      ) : (
        <>
          <ImgChecker
            key={data[dataIndex].thumbnailSrc}
            src={data[dataIndex].thumbnailSrc}
            onSuccess={() => addSuccessImage(data[dataIndex])}
            onError={incrementIndex}
          />
          {successImages.map((item, index) => {
            if (index > max - 1) return null;
            return <>{render(item, index)}</>;
          })}
        </>
      )}
    </>
  );
}

export default PostImagesChecker;
