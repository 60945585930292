import React, { useEffect, useRef, useState } from "react";
import "./TooltipPortal.scss";
import classNames from "classnames";
import useOnClickOutside from "src/app/methods/useOnClickOutside";
import { Portal } from "react-overlays";
import { overlaysRef } from "src/App";
import TooltipSign from "../TooltipSign/TooltipSign";

function TooltipPortal(props) {
  const {
    children,
    content,
    contentClassName,
    className,
    noBreak,
    clickable,
    up,
    center,
    alignRight,
    small,
    big,
    noHeightLimit,
    autoSize,
    contentHidden,
    wordBreak = "break-word",
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (clickable) {
      setIsOpen(false);
    }
  });

  const clickHandler = () => {
    if (clickable) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <span
      ref={ref}
      className={classNames(
        "tooltip-portal",
        {
          "tooltip-portal--no-break": noBreak,
          "tooltip-portal--clickable": clickable,
          "tooltip-portal--small": small,
          "tooltip-portal--big": big,
          "tooltip-portal--no-height-limit": noHeightLimit,
          "tooltip-portal--auto-size": autoSize,
          "tooltip-portal--align-right": alignRight,
          "tooltip-portal--open": clickable && isOpen,
          "tooltip-portal--hovered": isHover,
        },
        className,
      )}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <span onClick={clickHandler}>
        {typeof children === "function"
          ? children(isOpen)
          : children || (
              <TooltipSign
                blue={(clickable && isOpen) || (!clickable && isHover)}
              />
            )}
      </span>
      <Portal container={overlaysRef}>
        <TooltipContent
          isOpen={(clickable ? isOpen : isHover) && !contentHidden}
          center={center}
          content={content}
          wordBreak={wordBreak}
          parentRef={ref}
          contentClassName={contentClassName}
          up={up}
        />
      </Portal>
    </span>
  );
}

export default TooltipPortal;

function TooltipContent({
  contentClassName,
  isOpen,
  center,
  content,
  wordBreak,
  parentRef,
  up,
}) {
  const [showContent, setShowContent] = useState(false);
  const [position, setPosition] = useState({ left: 0, top: 0 });

  useEffect(() => {
    const setupPos = () => {
      const rect = parentRef.current.getBoundingClientRect();

      let positionObj = {};

      if (up) {
        positionObj = {
          top: rect.top - 1.5 * rect.height,
          left: rect.left + rect.width / 2,
        };
      } else {
        positionObj = {
          left: rect.left + rect.width / 2,
          top: rect.bottom + window.scrollY,
        };
      }

      setPosition(positionObj);
    };
    if (isOpen) {
      setupPos();
      setShowContent(true);
    } else {
      setTimeout(() => {
        setShowContent(false);
      }, 200);
    }
  }, [isOpen, up]);

  return isOpen ? (
    <span
      className={classNames(
        "tooltip-portal__content",
        {
          "tooltip-portal__content--open": showContent && isOpen,
          "tooltip-portal__content--center": center,
          "tooltip-portal__content--up": up,
        },
        contentClassName && contentClassName,
      )}
      style={position}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <span className="tooltip-portal__text" style={{ wordBreak }}>
        {content}
      </span>
    </span>
  ) : null;
}
