import React from "react";

import { ReactComponent as PlusWhiteIcon } from "src/images/plus-white.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import NotFoundImg from "src/images/clickbait.svg";
import { EmptyTabContainer } from "./EmptyTabContainer/EmptyTabContainer";
import { EmptyTabContent } from "./EmptyTabContent/EmptyTabContent";

function EmptyContentTab(props) {
  const { onButtonClick } = props;

  return (
    <EmptyTabContainer className="empty-content-tab">
      <EmptyTabContent
        text={
          <IDHFormattedMessage
            id="ws_empty_content_tab_text"
            defaultMessage="No contents has been assigned to this creator. \n Add new or assign already added content."
          />
        }
        buttonText={
          <IDHFormattedMessage
            id="ws_add_content"
            defaultMessage="Add content"
          />
        }
        onButtonClick={onButtonClick}
        buttonIcon={<PlusWhiteIcon />}
        imgUrl={NotFoundImg}
      />
    </EmptyTabContainer>
  );
}

export { EmptyContentTab };
