import React, { useEffect, useState } from "react";
import { uuidv7 } from "uuidv7";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { TextBox } from "src/app/components/TextBox/TextBox";
import { translateMessage } from "src/app/methods/translateMessage";
import { clearReplyToCommentData, getTaskDetails } from "src/redux";
import { wsAxiosPost } from "src/helpers/wsAxios";
import { RootState } from "src/redux/reducers";
import { API_URLS } from "src/utils/API_URLS";
import { IMember } from "src/app/project/ProjectTypes";
import { ContentProposalElement } from "src/redux/comment/types";
import scrollElementToBottom from "src/helpers/scrollElementToBottom";
import AddCommentFieldButton from "../AddCommentField/AddCommentFieldButton";
import AcceptedRejectedContentHeader, {
  ReplyingToHeader,
} from "../ContentProposalField/AcceptedRejectedContentHeader";
import { decodeComment } from "../../Comments/components/CommentText";

const getFirstElementStatus = (elements: ContentProposalElement[]) => {
  return elements[0].status;
};

interface ReplyToCommentFieldProps {
  membersList: IMember[];
}

export default function ReplyToCommentField({
  membersList,
}: ReplyToCommentFieldProps) {
  const [encodedValue, setEncodedValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const dispatch = useDispatch();

  const { replyToCommentData } = useSelector(
    (state: RootState) => state.commentReducer,
  );

  const hideReplyToCommentField = () => {
    dispatch(clearReplyToCommentData());
  };

  const handleSendReplyToComment = async () => {
    try {
      await wsAxiosPost(
        API_URLS.createTaskComment,
        {
          wsTaskCommentUuid: uuidv7(),
          wsTaskUuid: replyToCommentData.wsTaskUuid,
          comment: encodedValue,
          replyWsTaskCommentUuid: replyToCommentData.parentWsTaskCommentUuid,
        },
        undefined,
        () => {
          setIsLoading(true);
        },
        () => {
          hideReplyToCommentField();
        },
        () => {
          setIsLoading(false);
        },
      );
      await dispatch(getTaskDetails(replyToCommentData.wsTaskUuid));
      setTimeout(() => {
        scrollElementToBottom("task__container");
      }, 1500);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    return () => {
      hideReplyToCommentField();
    };
  }, []);

  return (
    <div className="add-comment-field add-comment-field--focus">
      <TextBox
        className="add-comment-field__textarea, add-comment-field__textarea--focus"
        setEncodedValue={setEncodedValue}
        placeholder={translateMessage({
          intl,
          id: "ws_write_a_comment",
          defaultMessage: "Write a comment",
        })}
        showButtons
        enableMentions
        enableEmoji
        editorHeaderChildren={
          <ReplyingToHeader>
            {replyToCommentData.replyToCommentText.length > 0 ? (
              <div className="content-proposal-media-element-wrapper">
                <span
                  className="replying-to-header__content-text"
                  dangerouslySetInnerHTML={{
                    __html: decodeComment(
                      replyToCommentData.replyToCommentText,
                      membersList,
                    ),
                  }}
                />
              </div>
            ) : null}
            {replyToCommentData.replyToCommentContent.length > 0 ? (
              <AcceptedRejectedContentHeader
                content={replyToCommentData.replyToCommentContent}
                status={getFirstElementStatus(
                  replyToCommentData.replyToCommentContent,
                )}
              />
            ) : null}
          </ReplyingToHeader>
        }
        bottomRightButton={
          <>
            <Button
              variant="white-with-black-border"
              size="medium"
              className="cancel-button"
              onClick={hideReplyToCommentField}
            >
              <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
            </Button>
            <AddCommentFieldButton
              disabled={isLoading || encodedValue.length === 0}
              onCommentClick={handleSendReplyToComment}
              buttonText={
                <IDHFormattedMessage id="ws_send" defaultMessage="Send" />
              }
            />
          </>
        }
      />
    </div>
  );
}
