import React from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";

function HtmlParser({ html, allowedTags = ["br"], allowTargetBlank = false }) {
  if (!html) {
    return null;
  }
  const htmlWithNewLines = html.split(/\r\n|\r|\n/g).join("<br/>");
  let config = { ALLOWED_TAGS: allowedTags };

  if (allowTargetBlank) {
    config = { ALLOWED_TAGS: allowedTags, ADD_ATTR: ["target"] };
  }

  const clean = DOMPurify.sanitize(htmlWithNewLines, config);

  return <span dangerouslySetInnerHTML={{ __html: clean }} />;
}

HtmlParser.propTypes = {
  html: PropTypes.string.isRequired,
};

HtmlParser.defaultProps = {
  html: "",
};

export default HtmlParser;
