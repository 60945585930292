import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { TooltipedEllipsis } from "src/app/components/TooltipedEllipsis/TooltipedEllipsis";
import { ContentProposalElementStatus } from "src/redux/comment/types";

interface DotIndicatorProps {
  status: ContentProposalElementStatus;
}

export default function DotIndicator({ status }: DotIndicatorProps) {
  return (
    <div
      className={`content-proposal-comment__indicator content-proposal-comment__indicator--${status}`}
    >
      <TooltipedEllipsis
        content={
          <IDHFormattedMessage
            id={`ws_filter_status_${status}`}
            defaultMessage={status}
          />
        }
      />
    </div>
  );
}
