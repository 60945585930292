import React, { ReactNode } from "react";

import { Status } from "src/app/components/Status/Status";
import { ReactComponent as ReplyArrow } from "src/images/reply.svg";
import thumbsUpIcon from "src/images/thumbs-up.png";
import thumbsDownIcon from "src/images/thumbs-down.png";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { getPreviewFileType } from "src/app/metafields/FileField/FilesDropdown";
import { getExtensionFromName } from "src/utils/methods";
import {
  ContentProposalElement,
  ContentProposalElementStatus,
  ContentProposalElementType,
} from "src/redux/comment/types";
import XssFilter from "../../../components/XssFilter/XssFilter";

interface ReplyingToHeaderProps {
  children: ReactNode;
}

export function ReplyingToHeader({ children }: ReplyingToHeaderProps) {
  return (
    <div className="replying-to-header">
      <div className="replying-to-header__content">
        <div className="replying-to-header__replying-to">
          <ReplyArrow />
          <IDHFormattedMessage
            id="ws_replying_to"
            defaultMessage="Replying to"
          />
        </div>
      </div>
      {children}
    </div>
  );
}

interface ContentProposalElementStatusProps {
  status: ContentProposalElementStatus;
}

export function ContentProposalElementStatusBadge({
  status,
}: ContentProposalElementStatusProps) {
  return (
    <Status
      className={
        status === ContentProposalElementStatus.Accepted
          ? "status--green-02"
          : "status--grey-02"
      }
    >
      {status === ContentProposalElementStatus.Accepted ? (
        <>
          <img src={thumbsUpIcon} alt="" />
          <IDHFormattedMessage id="ws_accepted" defaultMessage="Accepted" />
        </>
      ) : (
        <>
          <img src={thumbsDownIcon} alt="" />
          <IDHFormattedMessage id="ws_rejected" defaultMessage="Rejected" />
        </>
      )}
    </Status>
  );
}

const sortModeratedProposalElementsByCaptionFirst = (
  arr: ContentProposalElement[],
) => {
  const captionObj = arr.find(
    (obj) => obj.type === ContentProposalElementType.Caption,
  );
  if (captionObj) {
    const remainingObjects = arr.filter(
      (obj) => obj.type !== ContentProposalElementType.Caption,
    );
    return [captionObj, ...remainingObjects];
  }
  return arr;
};

interface AcceptedRejectedContentHeaderProps {
  status: ContentProposalElementStatus;
  content: ContentProposalElement[];
}

export default function AcceptedRejectedContentHeader({
  status,
  content,
}: AcceptedRejectedContentHeaderProps) {
  const sortedModeratedProposalElements =
    sortModeratedProposalElementsByCaptionFirst(content);

  return (
    <>
      {sortedModeratedProposalElements.map((el) => {
        if (el.type === ContentProposalElementType.Caption) {
          return (
            <div
              key={el.uuid}
              className="content-proposal-media-element-wrapper"
            >
              <span
                className="replying-to-header__content-text"
                dangerouslySetInnerHTML={{
                  __html: XssFilter(el.caption, ["br"]),
                }}
              />
              <ContentProposalElementStatusBadge status={status} />
            </div>
          );
        }

        const isExternalLink =
          el.type === ContentProposalElementType.ExternalUrl;

        return (
          <div key={el.uuid} className="content-proposal-media-element-wrapper">
            {getPreviewFileType(
              getExtensionFromName(isExternalLink ? "url" : el.name),
              el,
            )}
            <ContentProposalElementStatusBadge status={status} />
          </div>
        );
      })}
    </>
  );
}
