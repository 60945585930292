import React, { Dispatch, SetStateAction } from "react";
import update from "immutability-helper";

import { AttachmentMetaDataAndKey } from "src/app/Task/components/AddCommentField/AddCommentField";
import AttachmentLoader from "src/app/Task/Attachments/AttachmentLoader";
import Attachment from "src/app/Task/Attachments/Attachment";
import { AttachmentPlacement, TaskType } from "src/types";
import { AttachmentsFieldItemWrapper } from "./AttachmentsFieldWrapper";

interface AttachmentsFieldProps {
  attachments: AttachmentMetaDataAndKey[];
  setAttachments: Dispatch<SetStateAction<AttachmentMetaDataAndKey[]>>;
}

export default function AttachmentsField({
  attachments,
  setAttachments,
}: AttachmentsFieldProps) {
  const handleRemoveAttachment = (e: React.MouseEvent, uuid: string) => {
    e.stopPropagation();
    setAttachments((prev) => prev?.filter((item) => uuid !== item.localUuid));
  };

  const moveAttachment = (dragIndex: number, hoverIndex: number) => {
    const dragCard = attachments[dragIndex];
    setAttachments(
      update(attachments, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      }),
    );
  };

  return (
    <div className="text-box__attachments-wrapper">
      {attachments.map((item, index) => (
        <AttachmentsFieldItemWrapper
          key={item.localUuid}
          id={item.localUuid}
          index={index}
          moveAttachment={moveAttachment}
        >
          {item.isLoading ? (
            <AttachmentLoader fileName={item.attachment.name} />
          ) : (
            <Attachment
              data={item.attachment}
              place={AttachmentPlacement.AttachmentPreview}
              taskType={TaskType.Content}
              handleRemoveAttachment={(e) =>
                handleRemoveAttachment(e, item.localUuid)
              }
            />
          )}
        </AttachmentsFieldItemWrapper>
      ))}
    </div>
  );
}
