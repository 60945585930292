import React, { useRef } from "react";
import { useIsOverflow } from "src/app/methods/useIsOverflow";
import { Tooltip as ReactTooltip } from "react-tooltip";

function ColumnHeaderTitle(props) {
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);

  const { field } = props;
  return (
    <div>
      <div
        className="ws-overview-fields-table__header-title"
        data-tooltip-id={`ws-overview-fields-table__header-title_${field.uuid}`}
        data-tooltip-place="bottom"
        ref={ref}
      >
        {field.name}
      </div>
      {isOverflow && (
        <ReactTooltip
          id={`ws-overview-fields-table__header-title_${field.uuid}`}
          className="react-tooltip__dark-mode"
        >
          {field.name}
        </ReactTooltip>
      )}
    </div>
  );
}

export { ColumnHeaderTitle };
