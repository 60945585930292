import React, { useState, useRef } from "react";
import "./StatisticListItem.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { StatisticValue } from "../StatisticValue/StatisticValue";
import { DashedBox } from "../../../../../components/DashedBox/DashedBox";
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import {
  getStatisticHeader,
  getTooltipMetricName,
} from "../../../../../../helpers/statistic-list-item";
import Pictogram, {
  PictogramVariants,
} from "../../../../../components/Pictogram/Pictogram";

function StatisticListItem(props) {
  const {
    item: {
      statistic,
      editable,
      value,
      isCompound,
      icon: { color, name },
    },
    platform,
    platformDisplayName,
  } = props;

  const [showInput, setShowInput] = useState(false);

  return (
    <DashedBox
      key={statistic}
      className="statistic-dashed-box"
      focused={showInput}
    >
      <div className="task-statistic__header">
        {!statistic.includes("sentiment") && `${platformDisplayName} `}
        {getStatisticHeader(statistic)}
      </div>
      <Tooltip
        center
        content={
          <>
            <IDHFormattedMessage
              id="ws_enter_a_value_for_the"
              defaultMessage="Enter a value for the"
            />
            &nbsp;
            {getTooltipMetricName(statistic)}
            <IDHFormattedMessage id="ws_metric" defaultMessage="metric" />.
            &nbsp;
            <IDHFormattedMessage id="ws_the" defaultMessage="The" />
            &nbsp;
            {getStatisticHeader(statistic)}
            <IDHFormattedMessage
              id="ws_metric_value_will_be_calculated_automatically"
              defaultMessage="metric value will be calculated automatically"
            />
            .
          </>
        }
        contentHidden={!isCompound || statistic === "er"}
      >
        <div className="task-statistic">
          <div className="task-statistic__icon">
            <Pictogram identifier={name} variant={PictogramVariants[color]} />
          </div>

          <StatisticValue
            editable={editable}
            value={value}
            platform={platform}
            statistic={statistic}
            setShowInput={setShowInput}
            showInput={showInput}
            isCompound={isCompound}
          />
        </div>
      </Tooltip>
    </DashedBox>
  );
}

export { StatisticListItem };
