import React from "react";

import classNames from "classnames";

import { taskAvatarShape, tableDataType } from "../Table";
import { ReactComponent as PlusIcon } from "../../../../images/plus-transparent.svg";

interface AddCampaignRowProps {
  addTableElement?: () => void;
  taskAvatarShapeType: taskAvatarShape;
  taskType: string;
  dataType: tableDataType;
}

function AddCampaignRow({
  addTableElement,
  taskAvatarShapeType,
  taskType,
  dataType,
}: AddCampaignRowProps) {
  return (
    <div className="ws-table__row-flex">
      <div className="ws-table__content">
        <div className="ws-table__add-button" onClick={addTableElement}>
          <span
            className={classNames("ws-table__add-button-empty", {
              "ws-table__add-button-empty--circle":
                taskAvatarShapeType === taskAvatarShape.Circle,
              "ws-table__add-button-empty--square":
                taskAvatarShapeType === taskAvatarShape.Square,
            })}
          >
            <PlusIcon />
          </span>
          {`Click here to add ${
            dataType === tableDataType.Project ? "campaign" : taskType
          }`}
        </div>
      </div>
    </div>
  );
}

export default AddCampaignRow;
