import React, { useEffect, useMemo, useRef, useState } from "react";
import "./SectionHeader.scss";

import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { setReportGridElements } from "../../../../../../redux";

export function SectionHeader(props) {
  const { id, text, subHeader } = props;

  const [value, setValue] = useState("");

  const headerRef = useRef();

  const {
    reportReducer: { gridElements, numberedHeaders, applyBoxesContent },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (value !== text) {
      setValue(text);
    }
  }, [text]);

  const updateHeaderTitle = () => {
    const newGridElements = gridElements.map((item) => {
      if (item.label === id) {
        return {
          ...item,
          text: value,
        };
      }
      return item;
    });

    dispatch(setReportGridElements(newGridElements));
  };

  const Number = useMemo(() => {
    if (!numberedHeaders?.length) return null;

    const headerNumber = numberedHeaders?.find(
      (item) => item.label === id,
    )?.number;

    return headerNumber;
  }, [numberedHeaders]);

  return (
    <div
      ref={headerRef}
      id={id}
      className={classNames("section-header", {
        "section-header--small": subHeader,
      })}
    >
      <span className="section-header__number">{Number}.</span>
      <input
        className="section-header__text"
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={updateHeaderTitle}
      />
    </div>
  );
}
