import React, { Dispatch, FC, ReactNode, SetStateAction, useMemo } from "react";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import classNames from "classnames";
import SelectLabel from "../SelectLabel/SelectLabel";

interface Props {
  multiValue: any;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  emptyFieldPlaceholder: ReactNode;
  handleLabelClick: any;
  stackLabels?: boolean;
  readOnly?: boolean;
  singleLine?: boolean;
}

export const getCountedSelectValues = (value: any) => {
  let newLabels: any = [];

  value.forEach((item: any) => {
    const existingItem = newLabels.find((el: any) => el.value === item.value);
    if (!existingItem) {
      newLabels.push({
        ...item,
        count: 1,
      });
    } else {
      newLabels = newLabels.map((el: any) => {
        if (el.value === item.value) {
          return {
            ...el,
            count: el.count + 1,
          };
        }
        return el;
      });
    }
  });

  return newLabels || [];
};

export const AggregatedLabels: FC<Props> = (props) => {
  const {
    multiValue,
    setIsOpen,
    emptyFieldPlaceholder,
    handleLabelClick,
    stackLabels,
    readOnly,
    singleLine,
  } = props;

  const maxNumber = singleLine ? 3 : 5;

  const getLabelStyles = () => {
    if (multiValue.length === 1) {
      return { flexBasis: "100%" };
    }

    if (multiValue.length === 2) {
      return { flexBasis: "calc((100% - 4px) / 2)" };
    }

    return {
      flexBasis: "calc((100% - 8px) / 3)",
    };
  };

  const countedLabels = useMemo(() => {
    return getCountedSelectValues(multiValue);
  }, [multiValue]);

  const tooltipContent = useMemo(() => {
    return countedLabels
      .map(
        (item: { name: string; count: number }) =>
          `${item.name} x ${item.count}`,
      )
      .join(", ");
  }, [multiValue]);

  const Content = useMemo(() => {
    return (
      <span
        onClick={readOnly ? undefined : () => setIsOpen(true)}
        className={classNames("label-selector__value-wrapper", {
          "label-selector__value-wrapper--single-line": singleLine,
        })}
      >
        {countedLabels.slice(0, maxNumber).map((item: any) => (
          <SelectLabel
            key={item.value}
            value={item}
            onClickHandler={handleLabelClick}
            emptyFieldPlaceholder={emptyFieldPlaceholder}
            style={maxNumber ? {} : getLabelStyles()}
            readOnly={readOnly}
            showCounter
          />
        ))}
        {countedLabels.length > maxNumber && (
          <span className="select__value-wrapper-counter">
            +{countedLabels.length - maxNumber}
          </span>
        )}
      </span>
    );
  }, [stackLabels, multiValue, getLabelStyles]);

  return (
    <TooltipPortal content={tooltipContent} noHeightLimit>
      {Content}
    </TooltipPortal>
  );

  // return Content;
};
