import React from "react";
import "./CampaignList.scss";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import CustomImage from "src/app/components/CustomImage/CustomImage";
import { BreakdownValues } from "src/app/components/GlobalTaskDetails/tabs/Performance/Performance";
import { ReactComponent as LockIcon } from "src/images/lock.svg";
import ProjectPlaceholder from "src/images/project-placeholder.svg";
import { ReactComponent as ShareAltIcon } from "src/images/share-alt.svg";
import { getImageSource } from "src/utils/methods";
import { RootState } from "src/redux/reducers";
import AggregatedSelect from "src/app/components/AggregatedSelect/AggregatedSelect";
import { Metadata } from "src/app/Task/Subtask/Subtask";

interface Props {
  data: { [key: string]: BreakdownValues };
}

export const CampaignList: React.FC<Props> = (props) => {
  const { data } = props;

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  return (
    <AnimatedDiv className="campaign-list">
      {Object.entries(data).map((item) => {
        const [, itemData] = item;
        return (
          <div className="campaign-list__item">
            <span className="campaign-list__item-data">
              <CustomImage
                className="campaign-list__item-cover"
                src={getImageSource(
                  itemData.projectCover,
                  "tiny",
                  ProjectPlaceholder,
                )}
              />
              <span className="campaign-list__item-title">
                {itemData.projectTitle || <LockIcon />}
              </span>
            </span>

            {itemData.extraFieldsValues.map((metaData: Metadata) => {
              return (
                <span
                  className="campaign-list__item-data"
                  style={{ display: "block", fontSize: "12px" }}
                >
                  <AggregatedSelect
                    uuid={metaData.uuid}
                    optionsData={metaData.data.singleSelectOptions}
                    fieldValue={metaData.value}
                    singleLine
                    readOnly
                  />
                </span>
              );
            })}

            <span className="campaign-list__item-data">
              <span className="campaign-list__item-value">
                {itemData.value}
              </span>
              {itemData.projectTitle ? (
                <Link
                  to={{
                    pathname: `/workspace/${activeWorkspaceUuid}/projects/${itemData.projectUuid}/${itemData.wsTaskUuid}`,
                    search: `taskType=${itemData.wsTaskType}`,
                  }}
                  target="_blank"
                >
                  <ShareAltIcon className="campaign-list__item-link" />
                </Link>
              ) : (
                <span className="campaign-list__item-link-placeholder" />
              )}
            </span>
          </div>
        );
      })}
    </AnimatedDiv>
  );
};
