import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useReducer,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { getGlobalTasks } from "src/redux/creator-database/creatorDatabaseActions";
import { Rectangle } from "@glideapps/glide-data-grid";
import { tableDataType } from "src/app/components/Table/Table";
import { uploadFileBasingOnContext } from "src/app/metafields/FileField/taskFunctions";
import { useParams } from "react-router";
import { GlideDataGridContext, StaticCellSettings } from "../../../types";
import { getCellPosition, initialStaticCellSettings } from "../../../utils";
import { RootState } from "../../../../../../redux/reducers";
import creatorDatabaseFiltersReducer, {
  creatorDatabaseFiltersReducerInitialState,
} from "../../../../../CreatorDatabase/redux/creatorDatabaseFiltersReducer";
import { getDictionaryListElement } from "../../../../../../redux";

interface EmptyFileCellProps {
  uuid: string;
  rowUuid: string;
  closeDropdown: () => void;
  setStaticCellSettings: Dispatch<SetStateAction<StaticCellSettings>>;
  bounds: Rectangle;
  wsWorkspaceUuid: string;
  context: GlideDataGridContext;
}

function EmptyFileCell({
  uuid,
  rowUuid,
  closeDropdown,
  setStaticCellSettings,
  bounds,
  wsWorkspaceUuid,
  context,
}: EmptyFileCellProps) {
  const dispatch = useDispatch();
  const {
    creatorDatabaseReducer: { globalTaskColumns },
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);
  const params = useParams<{ dictionaryUuid: string }>();

  const [creatorDatabaseFilters] = useReducer(
    creatorDatabaseFiltersReducer,
    creatorDatabaseFiltersReducerInitialState(globalTaskColumns),
  );
  const { filters, sortBy, sort } = creatorDatabaseFilters;

  const isGlobalTask = context === tableDataType.CreatorDatabase;

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    setStaticCellSettings({
      numberOfFiles: e.target.files?.length ?? 0,
      style: getCellPosition(bounds),
    });
    uploadFileBasingOnContext({
      e,
      wsMetaFieldUuid: uuid,
      wsWorkspaceUuid,
      wsTaskUuid: rowUuid,
      thenCallback: () => {
        closeDropdown();
        if (isGlobalTask) {
          dispatch(getGlobalTasks(wsWorkspaceUuid, { filters, sortBy, sort }));
        } else if (context === tableDataType.Dictionary) {
          dispatch(getDictionaryListElement(params.dictionaryUuid, rowUuid));
        }
      },
      finallyCallback: () => {
        setStaticCellSettings(initialStaticCellSettings);
      },
      context: isGlobalTask ? tableDataType.GlobalTask : context,
    });
  };

  return (
    <input
      type="file"
      className="glide-data-grid__empty-file-cell"
      multiple
      onChange={(e) => uploadFile(e)}
    />
  );
}

export default EmptyFileCell;
