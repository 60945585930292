import React, { ChangeEvent } from "react";
import classNames from "classnames";

import { ReactComponent as AttachmentIcon } from "src/images/attachment.svg";
import "./AddAttachmentInput.scss";

interface AddAttachmentInputProps {
  inputRef: React.RefObject<HTMLInputElement>;
  handleAddAttachments: (e: ChangeEvent<HTMLInputElement>) => void;
  acceptedFileExtensions: string;
  className?: string;
}

function AddAttachmentInput({
  inputRef,
  handleAddAttachments,
  acceptedFileExtensions,
  className,
}: AddAttachmentInputProps) {
  return (
    <label
      className={classNames("add-attachment-input", className)}
      htmlFor="comment-attachments"
    >
      <AttachmentIcon />
      <input
        ref={inputRef}
        id="comment-attachments"
        multiple
        type="file"
        accept={acceptedFileExtensions}
        onChange={handleAddAttachments}
      />
    </label>
  );
}

export default AddAttachmentInput;
