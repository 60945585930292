import React, { useEffect, useState } from "react";
import "./Sidebar.scss";

import axios from "axios";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as LogoIcon } from "src/images/menu-icons/idh-logo.svg";
import { identify } from "src/redux/main/mainActions";
import { RootState } from "src/redux/reducers";
import { API_URLS } from "../../utils/API_URLS";
import { AppConfig } from "../../config/AppConfig";

import { ReactComponent as ArabyAdsIcon } from "./images/araby-ads.svg";
import { ReactComponent as LogotypeIcon } from "./images/indahash-full.svg";
import {
  SidebarElement,
  SidebarItemTypes,
  getSidebarElement,
} from "./SidebarItems";

import SidebarItem from "./SidebarItem";

const Sidebar: React.FC = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(false);

  const dispatch = useDispatch();
  const envName = AppConfig.getEnvironmentName();
  const { hide, show, boot, update, isOpen } = useIntercom();

  const {
    mainReducer: {
      identity,
      activeWorkspaceUuid,
      workspacesList,
      intercomUnreadMessages,
    },
  } = useSelector((state: RootState) => state);

  const baseUrl = `/workspace/${activeWorkspaceUuid}`;

  const workspaceData = workspacesList.find(
    (workspace) => workspace.uuid === activeWorkspaceUuid,
  );

  const sidebarElements: Array<SidebarElement> =
    identity?.sidebarItems
      ?.map((item: SidebarItemTypes) => getSidebarElement(item))
      .filter(Boolean) || [];

  useEffect(() => {
    if (identity?.id && identity?.email) {
      const allowedUsers = [
        "michal.sokolik@indahash.com",
        "maciej.trawka@indahash.com",
        "grzegorz.wende@indahash.com",
        "philip.rutecki@indahash.com",
        "denis.wojciechowski@indahash.com",
        "daniel.wroblewski@indahash.com",
        "tomasz.zembrzycki@indahash.com",
        "daniel.jedrzejczyk@indahash.com",
        "imane.saida@indahash.com",
        "tomasz.zewlakow@indahash.com",
      ];

      const allowedWorkspaces = [
        "603fe17e-89cf-4b1d-ba86-691a1f805a03", // iConnect
      ];

      if (
        !allowedUsers.includes(identity.email) &&
        !allowedWorkspaces.includes(activeWorkspaceUuid)
      ) {
        return;
      }

      boot({
        name: identity?.name,
        email: identity.email,
        alignment: "left",
        horizontalPadding: 20,
        verticalPadding: 20,
        backgroundColor: "#5d78ff",
        customAttributes: {
          user_id: identity?.intercomId ?? "",
          articles_template: "workspace",
        },
      });
    }
  }, [identity]);

  return (
    <aside
      className={classNames("sidebar", {
        "sidebar--opened": isOpen,
      })}
      onMouseEnter={() => setIsOpenSidebar(true)}
      onMouseLeave={() => setIsOpenSidebar(false)}
    >
      <nav role="navigation" aria-label="primary">
        <Link to="/workspace/">
          {workspaceData?.logo ? (
            <div
              className={classNames("sidebar__brand-logo", {
                "sidebar__brand-logo--hover": isOpenSidebar,
              })}
            >
              {isOpenSidebar ? (
                <img
                  src={workspaceData.logo}
                  className="sidebar__brand-logo-full"
                />
              ) : (
                <img
                  src={workspaceData.smallLogo ?? workspaceData.logo}
                  className="sidebar__brand-logo-small"
                />
              )}
            </div>
          ) : (
            <>
              {workspaceData ? (
                <div
                  className={classNames("sidebar__logo", {
                    "sidebar__logo--local": envName === "local" || envName === "localdev",
                    "sidebar__logo--dev": envName === "dev",
                    "sidebar__logo--beta": envName === "beta",
                    "sidebar__logo--stage1": envName === "stage1",
                  })}
                >
                  <LogoIcon className="sidebar__logo-shape" />
                  <ArabyAdsIcon className="sidebar__logo-araby-ads" />
                  <LogotypeIcon className="sidebar__logo-word" />
                </div>
              ) : (
                <div className="sidebar__brand-logo-empty" />
              )}
            </>
          )}
        </Link>
        <ul className="sidebar__list">
          {sidebarElements.map((wsSidebarElement) => {
            return (
              <SidebarItem
                key={wsSidebarElement.key}
                to={`${baseUrl}/${wsSidebarElement.path}`}
                messageId={wsSidebarElement.translateKey}
                defaultMessage={wsSidebarElement.defaultMessage}
                overrideHtml={wsSidebarElement.overrideHtml}
              >
                {wsSidebarElement.icon}
              </SidebarItem>
            );
          })}
        </ul>

        <ul className="sidebar__list sidebar__list--bottom">
          {/* }<SidebarItem
            onClick={() => {
              if (isOpen === true) {
                hide();
              } else {
                show();
              }
            }}
            messageId="ws_support"
            defaultMessage="Support"
          >
            <div className="sidebar__intercom-icon">
              <IntercomIcon />
              <span className="sidebar__intercom-icon-counter">{intercomUnreadMessages}</span>
            </div>
          </SidebarItem> */}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
