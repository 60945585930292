import React from "react";
import { useHistory } from "react-router";

import { updateViewParam } from "src/app/SettingsModal/methods";
import { AppLocation } from "src/redux/main/mainReducer";
import { ExtensionTypeEnum, RecruitmentFormDetails } from "src/types";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tabs from "src/app/components/Tabs/Tabs";
import Tab from "src/app/components/Tabs/Tab";
import { ReactComponent as QuestionMarkIcon } from "src/images/question-circle.svg";
import { ActiveTabs } from "./types";

interface RecruitmentFormTabsProps {
  extensionDetails: RecruitmentFormDetails;
  activeTab: ActiveTabs;
  setActiveTab: React.Dispatch<React.SetStateAction<ActiveTabs>>;
  extensionType: ExtensionTypeEnum;
  isLoading: boolean;
}

export default function RecruitmentFormTabs({
  extensionDetails,
  activeTab,
  setActiveTab,
  extensionType,
  isLoading,
}: RecruitmentFormTabsProps) {
  const history = useHistory();
  const handleOpenHowItWorksModal = () => {
    updateViewParam(
      history,
      "howItWorks",
      "location",
      AppLocation.SettingsModal,
    );
  };
  return (
    <Tabs>
      <div className="extensions__tabs">
        {extensionDetails?.enabled && (
          <Tab
            tabText={
              <IDHFormattedMessage id="ws_share" defaultMessage="Share" />
            }
            onClick={() => setActiveTab(ActiveTabs.Share)}
            noClickable={isLoading}
            active={activeTab === ActiveTabs.Share}
          />
        )}
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_general" defaultMessage="General" />
          }
          onClick={() => setActiveTab(ActiveTabs.General)}
          noClickable={isLoading}
          active={activeTab === ActiveTabs.General}
        />
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_fields" defaultMessage="Fields" />
          }
          onClick={() => setActiveTab(ActiveTabs.Fields)}
          noClickable={isLoading}
          active={activeTab === ActiveTabs.Fields}
        />
        {extensionType === ExtensionTypeEnum.CreatorDatabaseSubmissionForm && (
          <Tab
            tabText={
              <IDHFormattedMessage
                id="ws_notifications"
                defaultMessage="Notifications"
              />
            }
            onClick={() => setActiveTab(ActiveTabs.Notifications)}
            noClickable={isLoading}
            active={activeTab === ActiveTabs.Notifications}
          />
        )}
      </div>
      {extensionType === ExtensionTypeEnum.RecruitmentForm && (
        <button
          onClick={handleOpenHowItWorksModal}
          className="extensions__how-it-works-btn"
        >
          <QuestionMarkIcon />
          <IDHFormattedMessage
            id="ws_how_it_works"
            defaultMessage="How it works"
          />
        </button>
      )}
    </Tabs>
  );
}
