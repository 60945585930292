// odpowiednie proporcje osiągniemy poprzez manipulację szerokością strony lub gapem

export const formatOptions = [
  {
    label: "A4 (Portrait)",
    value: {
      cols: 12,
      rows: 17,
      rowHeight: 50,
      pageHeight: 1191,
      pageWidth: 842,
      pagePrintHeight: 1188,
      pagePrintWidth: 840,
    },
  },
  {
    label: "A4 (Portrait)",
    value: {
      cols: 6,
      rows: 18,
      rowHeight: 50,
      pageHeight: 1260,
      pageWidth: 891,
      pagePrintHeight: 1260,
      pagePrintWidth: 891,
    },
  },
];
