import React, { useContext, useEffect, useState } from "react";
import "./DetailsPopup.scss";

import PopUp, { PopupRow, PopupCol } from "src/app/components/PopUp";
import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import { CustomTextareaLabel } from "src/app/components/CustomTextarea/CustomTextarea";
import { TextBoxWrapper } from "src/app/components/TextBoxWrapper/TextBoxWrapper";
import { PopupSection } from "src/app/components/PopUp/components/PopupSection";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { injectIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { AccessFields } from "./AccessFields";
import { AccessLinkFieldContext } from "../AccessLinkField";

function DetailsPopup(props) {
  const { intl } = props;

  const {
    accessLinkData,
    setAccessLinkData,
    showDetailsPopup,
    setShowDetailsPopup,
    selectedVals,
    noManageModal,
    handleConfirm,
    onManageClose,
  } = useContext(AccessLinkFieldContext);

  const [caption, setCaption] = useState(accessLinkData.caption);
  const [description, setDescription] = useState(accessLinkData.description);
  const [loading, setLoading] = useState(false);

  const sendData = () => {
    setAccessLinkData({
      metaFields: selectedVals.map((t) => {
        return {
          wsMetaFieldType: t.field.metafieldType,
          wsMetaFieldUuid: t.field.value,
          wsMetaFieldAccessMode: t.mode,
          wsMetaFieldIsRequired: t.required,
          wsMetaFieldDescription: t.wsMetaFieldDescription,
        };
      }),
      caption,
      description,
    });
  };

  useEffect(() => {
    noManageModal && sendData();
  }, [caption, description, selectedVals]);

  return (
    <PopUp
      title={
        <IDHFormattedMessage
          id="ws_creating_new_form"
          defaultMessage="Creating new form"
        />
      }
      paragraph={
        <IDHFormattedMessage
          id="ws_details_popup_paragraph"
          defaultMessage="Create a form that can be shared with people outside the platform. Include a clear title and description that will help them understand the purpose of the form."
        />
      }
      show={showDetailsPopup}
      className="details-popup"
      width={600}
      close={() => {
        setShowDetailsPopup(false);
        noManageModal && onManageClose();
      }}
      confirmText={
        noManageModal ? (
          <IDHFormattedMessage id="ws_create" defaultMessage="Create" />
        ) : (
          <IDHFormattedMessage id="ws_set" defaultMessage="Set" />
        )
      }
      confirm={() => {
        setLoading(true);
        sendData();
        if (noManageModal) {
          handleConfirm(null, () => setShowDetailsPopup(false));
        } else setShowDetailsPopup(false);
      }}
      confirmDisabled={selectedVals.length === 0}
      loading={loading}
    >
      <PopupSection>
        <PopupRow>
          <PopupCol>
            <CustomInputLabel htmlFor="details-title">
              <IDHFormattedMessage id="ws_title" defaultMessage="Title" />
            </CustomInputLabel>
            <CustomInput
              id="details-title"
              type="text"
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              placeholder={translateMessage({
                intl,
                id: "ws_enter_form_title",
                defaultMessage: "Enter form title",
              })}
              fullWidth
            />
          </PopupCol>
        </PopupRow>
        <PopupRow className="details-popup__area-wrap">
          <PopupCol>
            <CustomTextareaLabel
              htmlFor="details-popup__description"
              text={
                <IDHFormattedMessage
                  id="ws_description"
                  defaultMessage="Description"
                />
              }
            />

            <TextBoxWrapper
              id="details-popup__description"
              className="details-popup__description"
              value={description || ""}
              onChange={setDescription}
              placeholder={translateMessage({
                intl,
                id: "ws_enter_form_description",
                defaultMessage: "Enter form description",
              })}
            />
          </PopupCol>
        </PopupRow>
      </PopupSection>
      <PopupSection className="details-popup__access-fields">
        <AccessFields />
      </PopupSection>
    </PopUp>
  );
}

export default injectIntl(DetailsPopup);
