import React, { useState } from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tab from "../../components/Tabs/Tab";
import Tabs from "../../components/Tabs/Tabs";
import { AudienceDataTab } from "../tabs/AudienceDataTab/AudienceDataTab";
import { ProjectSummaryTab } from "../tabs/ProjectSummaryTab/ProjectSummaryTab";
import { CreatorsInsightsTab } from "../tabs/CreatorsInsightsTab/CreatorsInsightsTab";

function TabsContainer(props) {
  const { metadata } = props;

  const [activeTab, setActiveTab] = useState("project-summary");

  return (
    <div className="project-details-tabs-container">
      <Tabs>
        <Tab
          tabText={
            <IDHFormattedMessage id="ws_overview" defaultMessage="Overview" />
          }
          active={activeTab === "project-summary"}
          onClick={() => setActiveTab("project-summary")}
          medium
        />
        <Tab
          tabText={
            <IDHFormattedMessage
              id="ws_creator_insights"
              defaultMessage="Creator insights"
            />
          }
          active={activeTab === "creators-insights"}
          onClick={() => setActiveTab("creators-insights")}
          medium
        />
        <Tab
          tabText={
            <IDHFormattedMessage
              id="ws_audience_data"
              defaultMessage="Audience data"
            />
          }
          active={activeTab === "audience-data"}
          onClick={() => setActiveTab("audience-data")}
          medium
        />
      </Tabs>

      {activeTab === "project-summary" ? (
        <ProjectSummaryTab metadata={metadata} />
      ) : activeTab === "creators-insights" ? (
        <CreatorsInsightsTab />
      ) : activeTab === "audience-data" ? (
        <AudienceDataTab />
      ) : null}
    </div>
  );
}

export { TabsContainer };
