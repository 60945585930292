import React from "react";
import classNames from "classnames";

import CreatorPlaceholder from "src/images/creator-placeholder.svg";
import CustomImage from "../../../../../components/CustomImage/CustomImage";

import { GlobalTaskSearchResult } from "../../../TopSearch";

interface Props {
  data: GlobalTaskSearchResult;
}

export function GlobalTaskCover(props: Props) {
  return (
    <CustomImage
      className={classNames("search-section__item-cover")}
      src={props?.data?.avatarUrl ?? CreatorPlaceholder}
      alt="campaign-icon"
      loading="lazy"
    />
  );
}
