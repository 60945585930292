import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Chart from "../../../../components/Chart/Chart";
import { ReactComponent as ERIcon } from "../../../../../images/profile-data/er.svg";

import ProfileBox, { PBHeader } from "../components/ProfileBox/ProfileBox";

export function EngagementRate(props) {
  const { er, erStatisticsData } = props;
  return (
    <ProfileBox block>
      <PBHeader
        icon={<ERIcon />}
        count={`${er}%`}
        title={
          <IDHFormattedMessage
            id="ws_engagement_rate"
            defaultMessage="Engagement Rate"
          />
        }
      />
      <Chart
        title=""
        color="#FD397A"
        icon=""
        data={erStatisticsData}
        height={150}
      />
    </ProfileBox>
  );
}
