import React from "react";
import classNames from "classnames";

import CreatorPlaceholder from "src/images/creator-placeholder.svg";
import ProjectPlaceholder from "src/images/project-placeholder.svg";

import TickIcon from "src/images/tick-grey.svg";
import TickCompletedIcon from "src/images/tick-completed.svg";
import { TaskType } from "src/types";
import CustomImage from "../../../../../components/CustomImage/CustomImage";
import { getImageSource } from "../../../../../../utils/methods";

export function TaskItemCover(props) {
  const { data } = props;

  const getPlaceholder = () => {
    switch (data.taskType) {
      case TaskType.Creator:
        return CreatorPlaceholder;
      case TaskType.Content:
        return ProjectPlaceholder;
      case TaskType.Publication:
        return ProjectPlaceholder;
      default:
        return null;
    }
  };

  if (data.taskType === TaskType.Action || data.taskType === TaskType.Payment) {
    return (
      <img
        className="search-section__item-tick"
        src={data.isCompleted ? TickCompletedIcon : TickIcon}
      />
    );
  }

  const square =
    data.taskType === TaskType.Content ||
    data.taskType === TaskType.Publication;

  return (
    <CustomImage
      className={classNames("search-section__item-cover", {
        "search-section__item-cover--square": square,
      })}
      src={getImageSource(
        { thumbnails: data?.wsTaskCover },
        "tiny",
        getPlaceholder(),
      )}
      alt="campaign-icon"
      loading="lazy"
    />
  );
}
