import React, { Dispatch, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { RootState } from "src/redux/reducers";
import GlobalFields, { GlobalField } from "../GlobalFields/GlobalFields";
import { MFState } from "../../types";

interface LibraryFieldsProps {
  updateState: Dispatch<React.SetStateAction<MFState>>;
}

export default function LibraryFields({ updateState }: LibraryFieldsProps) {
  const [selectedGlobalMetaFields, setSelectedGlobalMetaFields] = useState<
    Array<GlobalField>
  >([]);

  const {
    projectReducer: { globalMetaFieldsList },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    updateState((prev) => ({
      ...prev,
      globalMetaFieldData: selectedGlobalMetaFields,
    }));
  }, [selectedGlobalMetaFields, updateState]);

  return (
    <GlobalFields
      options={globalMetaFieldsList}
      selectedGlobalMetaFields={selectedGlobalMetaFields}
      setSelectedGlobalMetaFields={setSelectedGlobalMetaFields}
    />
  );
}
