import React from "react";
import { SummaryBoxList } from "../../../components/SummaryBoxList/SummaryBoxList";

function Actions(props) {
  const { foundFields } = props;

  return <SummaryBoxList foundFields={foundFields} />;
}

export { Actions };
