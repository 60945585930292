import React, { ReactNode } from "react";
import "./NoResultsScreen.scss";

interface NoResultsProps {
  title: ReactNode;
  subtitle: ReactNode;
  imgUrl?: string;
  bottomContent?: ReactNode;
}

function NoResults({ title, subtitle, imgUrl, bottomContent }: NoResultsProps) {
  return (
    <div className="no-results-screen">
      <div className="no-results-screen__content">
        <h2 className="no-results-screen__title">{title}</h2>
        {subtitle && (
          <div>
            <p className="no-results-screen__subtitle">{subtitle}</p>
          </div>
        )}
        {bottomContent && (
          <div className="no-results-screen__bottom-content">
            {bottomContent}
          </div>
        )}
      </div>
      {imgUrl && (
        <div className="no-results-screen__img-wrapper">
          <img src={imgUrl} className="no-results-screen__img" />
        </div>
      )}
    </div>
  );
}

export default NoResults;
