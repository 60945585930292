import {
  OPEN_TRIAL_EXCEEDED_MODAL,
  CLOSE_TRIAL_EXCEEDED_MODAL,
  OPEN_TRIAL_LIMITATIONS_MODAL,
  CLOSE_TRIAL_LIMITATIONS_MODAL,
} from "./globalModalsTypes";

export function openTrialExceededModal() {
  return {
    type: OPEN_TRIAL_EXCEEDED_MODAL,
  };
}

export function closeTrialExceededModal() {
  return {
    type: CLOSE_TRIAL_EXCEEDED_MODAL,
  };
}

export function openTrialLimitationsModal() {
  return {
    type: OPEN_TRIAL_LIMITATIONS_MODAL,
  };
}

export function closeTrialLimitationsModal() {
  return {
    type: CLOSE_TRIAL_LIMITATIONS_MODAL,
  };
}
