import React, { forwardRef, useEffect, useState } from "react";
import { TextBox } from "../TextBox/TextBox";

export const TextBoxWrapper = forwardRef<HTMLElement, any>((props, ref) => {
  const { value, onChange, onBlur, ...rest } = props;

  const [localValue, setLocalValue] = useState("");

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleBlur = () => {
    if (onBlur) {
      onBlur(localValue);
    }

    onChange(localValue);
  };

  return (
    <TextBox
      ref={ref}
      value={value}
      setEncodedValue={setLocalValue}
      onBlur={handleBlur}
      {...rest}
    />
  );
});
