import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { tableDataType } from "src/app/components/Table/Table";
import Tab from "src/app/components/Tabs/Tab";
import Tabs from "src/app/components/Tabs/Tabs";
import GlobalFields from "src/app/modals/ManageFieldModal/components/GlobalFields/GlobalFields";
import { getSettingsGlobalFields } from "src/redux";
import { RootState } from "src/redux/reducers";

export enum ActiveTabs {
  CampaignAttributes = "CampaignAttributes",
  DataFields = "DataFields",
}

export default function ManageGlobalFields() {
  const [activeTab, setActiveTab] = useState<ActiveTabs>(
    ActiveTabs.CampaignAttributes,
  );

  const {
    mainReducer: { activeWorkspaceUuid },
    settingsReducer: { globalProjectMetaFields, globalTaskMetaFields },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (activeWorkspaceUuid) {
      dispatch(getSettingsGlobalFields(activeWorkspaceUuid));
    }
  }, [activeWorkspaceUuid]);

  let options = [];
  let dataTypeBasedOnActiveTab;
  switch (activeTab) {
    case ActiveTabs.CampaignAttributes:
      dataTypeBasedOnActiveTab = tableDataType.Project;
      options = globalProjectMetaFields;
      break;
    case ActiveTabs.DataFields:
      dataTypeBasedOnActiveTab = tableDataType.Task;
      options = globalTaskMetaFields;
      break;
    default:
      return null;
  }

  return (
    <div>
      <Tabs>
        <Tab
          tabText={
            <IDHFormattedMessage
              id="ws_campaign_attributes"
              defaultMessage="Campaign attributes"
            />
          }
          active={activeTab === ActiveTabs.CampaignAttributes}
          onClick={() => {
            setActiveTab(ActiveTabs.CampaignAttributes);
          }}
        />
        <Tab
          tabText={
            <IDHFormattedMessage
              id="ws_data_fields"
              defaultMessage="Data fields"
            />
          }
          active={activeTab === ActiveTabs.DataFields}
          onClick={() => {
            setActiveTab(ActiveTabs.DataFields);
          }}
        />
      </Tabs>
      <GlobalFields options={options} dataType={dataTypeBasedOnActiveTab} />
    </div>
  );
}
