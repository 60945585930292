import React from "react";
import { getExtensionFromName, openUrlInNewTab } from "src/utils/methods";

export const getAttachmentClickMethod = (
  event: React.MouseEvent<HTMLDivElement>,
  fileName: string,
  publicPreviewUrl: string,
) => {
  const fileExtension = getExtensionFromName(fileName);

  if (!publicPreviewUrl) return event.stopPropagation();

  switch (fileExtension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "mp4":
      return event.stopPropagation();
    default:
      return openUrlInNewTab(publicPreviewUrl);
  }
};
