/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import classNames from "classnames";

import { ReactComponent as TrashCanIcon } from "src/images/trash-can.svg";
import { ReactComponent as TickIcon } from "src/images/tick.svg";
import CustomInput from "../../../CustomInput/CustomInput";
import {
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "../../../Dropdown/Dropdown";
import { Mode, SelectOption } from "../../types";
import { showToast } from "../../../../methods/showToast";
import useOnClickOutside from "../../../../methods/useOnClickOutside";
import IDHFormattedMessage from "../../../IDHFormattedMessage/IDHFormattedMessage";
import { COLOR_OPTIONS } from "../../utils";

import "./EditOptionPane.scss";

interface EditOptionPaneInterface {
  mode?: Mode;
  data: SelectOption | undefined | null;
  setData: Dispatch<SetStateAction<SelectOption | undefined | null>>;
  isOpen: boolean;
  options: SelectOption[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setOptions: Dispatch<SetStateAction<any>>;
  fieldName: string;
  updateSingleSelect: (newName: string, newOptions: SelectOption[]) => void;
  showRemoveOptionModal?: boolean;
  setShowRemoveOptionModal?: Dispatch<SetStateAction<boolean>>;
}

function EditOptionPane({
  mode,
  data,
  setData,
  isOpen,
  options,
  setIsOpen,
  setOptions,
  fieldName,
  updateSingleSelect,
  setShowRemoveOptionModal,
}: EditOptionPaneInterface) {
  const [optionName, setOptionName] = useState(data?.name || "");
  const [showMenu, setShowMenu] = useState(false);

  const paneRef = useRef<any>(null);

  const renameSelectOption = async () => {
    if (fieldName === data?.name) return;

    try {
      let shouldBreak = false;

      const newOptions = options.map((item: any) => {
        if (item.name === optionName) {
          shouldBreak = true;
        }

        if (item.value === data?.value) {
          return {
            ...item,
            name: optionName,
          };
        }

        return item;
      });

      if (shouldBreak) return;

      await updateSingleSelect(fieldName, newOptions);

      setOptions(newOptions);
    } catch (error) {
      showToast("error", "Error", "Could not rename option.");
    }
  };

  const handleClickOutside = async () => {
    await renameSelectOption();
    setIsOpen(false);
  };

  useOnClickOutside(paneRef, () => handleClickOutside());

  useEffect(() => {
    if (data?.name) {
      setOptionName(data.name);
    }
  }, [data]);

  useEffect(() => {
    if (isOpen) {
      setShowMenu(true);
    } else {
      setTimeout(() => {
        setShowMenu(false);
      }, 200);
    }
  }, [isOpen]);

  const removeSelectOption = async () => {
    try {
      const newOptions = options.filter(
        (item: any) => item.value !== data?.value,
      );

      await updateSingleSelect(fieldName, newOptions);

      setOptions(newOptions);
      setShowMenu(false);
      setIsOpen(false);
      setData(null);
    } catch (error) {
      showToast("error", "Error", "Could not rename option.");
    }
  };

  const changeOptionColor = async (
    e: Event,
    color: string,
    backgroundColor: string,
  ) => {
    e.stopPropagation();

    try {
      const newOptions = options.map((item: any) => {
        if (item.value === data?.value) {
          return {
            ...item,
            color: color.replace("#", ""),
            backgroundColor: backgroundColor.replace("#", ""),
            name: optionName,
          };
        }

        return item;
      });

      await updateSingleSelect(fieldName, newOptions);

      setOptions(newOptions);
    } catch (error) {
      showToast("error", "Error", "Could not change option color.");
    }
  };

  const handleRemoveClick = () => {
    if (mode === "edit-global" && setShowRemoveOptionModal) {
      setShowRemoveOptionModal(true);
    } else {
      removeSelectOption();
    }
  };

  return (isOpen || showMenu) && data ? (
    <div
      ref={paneRef}
      className={classNames("edit-option-pane", {
        "edit-option-pane--open": showMenu && isOpen,
      })}
      onClick={(e) => {
        e.stopPropagation();
        renameSelectOption();
      }}
    >
      <div className="input-wrapper">
        <CustomInput
          value={optionName}
          onChange={(e) => setOptionName(e.target.value)}
          placeholder="Enter option name"
          onKeyPress={(e) => {
            if (e.key === "Enter") renameSelectOption();
          }}
          required
        />
      </div>
      <DropdownMenuItem
        onClick={handleRemoveClick}
        className="dropdown__menu-item--danger"
      >
        <div className="option-wrapper__left">
          <TrashCanIcon />
          <IDHFormattedMessage id="ws_remove" defaultMessage="Remove" />
        </div>
      </DropdownMenuItem>

      <DropdownMenuSeparator dashed />

      <p className="search-text">
        <IDHFormattedMessage
          id="ws_select_or_create_option"
          defaultMessage="Select an option or create one"
        />
      </p>

      {COLOR_OPTIONS.map((item) => (
        <DropdownMenuItem
          key={item.backgroundColor}
          onClick={(e: Event) => {
            changeOptionColor(e, item.color, item.backgroundColor);
          }}
        >
          <div className="option-wrapper__left">
            <span
              className="color-box"
              style={{ backgroundColor: item.backgroundColor }}
            />
            {item.label}
          </div>
          {`#${data.color}` === item.color && (
            <TickIcon className="tick-icon" />
          )}
        </DropdownMenuItem>
      ))}
    </div>
  ) : null;
}

export default EditOptionPane;
