import classNames from "classnames";
import React from "react";

function TrialLimitationsListItem(props) {
  const { title, description, icon, actionsLeft, maxActions } = props;

  return (
    <li className="trial-limitations-list__item">
      <div className="trial-limitations-list__item-title">
        {icon}
        <span className="trial-limitations-list__title">{title}</span>
        <span
          className={classNames("trial-limitations-list__actions-left", {
            "trial-limitations-list__actions-left--full":
              actionsLeft === maxActions,
          })}
        >
          {actionsLeft}
          <span>&nbsp;/&nbsp;</span>
          {maxActions}
        </span>
      </div>
      <div className="trial-limitations-list__item-description">
        {description}
      </div>
    </li>
  );
}

export { TrialLimitationsListItem };
