export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT = "GET_PROJECT";
export const GET_PROJECT_FAIL = "GET_PROJECT_FAIL";
export const CLEAN_GET_PROJECT_FAILED = "CLEAN_GET_PROJECT_FAILED";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT = "UPDATE_PROJECT";

export const ADD_PROJECT_META_FIELD = "ADD_PROJECT_META_FIELD";
export const ADD_PROJECT_META_FIELD_SUCCESS = "ADD_PROJECT_META_FIELD_SUCCESS";
export const ADD_PROJECT_META_FIELD_FAIL = "ADD_PROJECT_META_FIELD_FAIL";
export const GET_ACTIVE_SORTING_COLUMN = "GET_ACTIVE_SORTING_COLUMN";
export const UPDATE_PROJECT_META_FIELD_NAME = "UPDATE_PROJECT_META_FIELD_NAME";
export const UPDATE_PROJECT_META_FIELD_NAME_SUCCESS =
  "UPDATE_PROJECT_META_FIELD_NAME_SUCCESS";
export const UPDATE_PROJECT_META_FIELD_RANK = "UPDATE_PROJECT_META_FIELD_RANK";
export const UPDATE_PROJECT_META_VALUE = "UPDATE_PROJECT_META_VALUE";
export const UPDATE_PROJECT_META_VALUE_FAIL = "UPDATE_PROJECT_META_VALUE_FAIL";
export const SET_PROJECT_FIELD_ADDED_OR_UPDATED =
  "SET_PROJECT_FIELD_ADDED_OR_UPDATED";
export const RENAME_PROJECT_ON_LIST = "RENAME_PROJECT_ON_LIST";
export const DELETE_PROJECT_META_FIELD = "DELETE_PROJECT_META_FIELD";
export const DELETE_PROJECT_META_FIELD_SUCCESS =
  "DELETE_PROJECT_META_FIELD_SUCCESS";
export const UPDATE_PROJECT_META_FIELD_VISIBILITY =
  "UPDATE_PROJECT_META_FIELD_VISIBILITY";
export const UPDATE_PROJECT_SINGLE_SELECT_FIELD =
  "UPDATE_PROJECT_SINGLE_SELECT_FIELD";
export const UPDATE_PROJECT_META_FIELD_ON_LIST =
  "UPDATE_PROJECT_META_FIELD_ON_LIST";
export const UPDATE_PROJECT_META_FIELD_ON_LIST_FAIL =
  "UPDATE_PROJECT_META_FIELD_ON_LIST_FAIL";
export const CHANGE_FIELD_VISIBILITY = "CHANGE_FIELD_VISIBILITY";
export const GET_PROJECTS_LIST = "GET_PROJECTS_LIST";
export const GET_PROJECT_TEMPLATES = "GET_PROJECT_TEMPLATES";
export const GET_FILTERED_PROJECTS_LIST = "GET_FILTERED_PROJECTS_LIST";
export const CLEAR_PROJECTS_LIST = "CLEAR_PROJECTS_LIST";
export const SET_PROJECTS_LIST_OFFSET = "SET_PROJECTS_LIST_OFFSET";
export const GET_FILTERED_PROJECTS_LIST_SUCCESS =
  "GET_FILTERED_PROJECTS_LIST_SUCCESS";
export const GET_PROJECTS_LIST_SUCCESS = "GET_PROJECTS_LIST_SUCCESS";
export const GET_PROJECTS_LIST_FAIL = "GET_PROJECTS_LIST_FAIL";
export const GET_PROJECT_TEMPLATES_SUCCESS = "GET_PROJECT_TEMPLATES_SUCCESS";
export const GET_PROJECT_TEMPLATES_FAIL = "GET_PROJECT_TEMPLATES_FAIL";
export const DELETE_FIELD = "DELETE_FIELD";
export const DELETE_FIELD_SUCCESS = "DELETE_FIELD_SUCCESS";
export const ADD_NEW_TASK = "ADD_NEW_TASK";
export const GET_TASKS_LIST = "GET_TASKS_LIST";
export const GET_TASKS_LIST_SUCCESS = "GET_TASKS_LIST_SUCCESS";
export const GET_TASKS_COUNTERS = "GET_TASKS_COUNTERS";
export const GET_TASKS_COUNTERS_SUCCESS = "GET_TASKS_COUNTERS_SUCCESS";
export const CLEAR_TASKS_COUNTERS = "CLEAR_TASKS_COUNTERS";
export const UPDATE_TASKS_LIST = "UPDATE_TASKS_LIST";
export const UPDATE_TASKS_LIST_SUCCESS = "UPDATE_TASKS_LIST_SUCCESS";
export const SET_ACTIVE_PROJECT_ID = "SET_ACTIVE_PROJECT_ID";
export const ADD_NEW_FIELD_SUCCESS = "ADD_NEW_FIELD_SUCCESS";
export const SET_SHOW_TASK = "SET_SHOW_TASK";
export const ADD_NEW_SUBTASK = "ADD_NEW_SUBTASK";
export const CREATE_EMPTY_SUBTASK = "CREATE_EMPTY_SUBTASK";
export const UPDATE_BOARD_TASK_RANK = "UPDATE_BOARD_TASK_RANK";

export const DELETE_TASK = "DELETE_TASK";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAIL = "DELETE_TASK_FAIL";
export const DUPLICATE_TASK = "DUPLICATE_TASK";
export const GET_MEMBERS_LIST = "GET_MEMBERS_LIST";
export const GET_MEMBERS_LIST_SUCCESS = "GET_MEMBERS_LIST_SUCCESS";
export const GET_MEMBERS_LIST_FAIL = "GET_MEMBERS_LIST_FAIL";
export const SET_MEMBERS_OPTIONS = "SET_MEMBERS_OPTIONS";
export const SET_TASK_TYPE = "SET_TASK_TYPE";
export const SET_PROJECT_LIST = "SET_PROJECT_LIST";
export const SET_PROJECT_TEMPLATES = "SET_PROJECT_TEMPLATES";
export const SET_FILTERED_PROJECT_LIST = "SET_FILTERED_PROJECT_LIST";
export const SET_PROJECT_COLUMNS = "SET_PROJECT_COLUMNS";
export const SET_PROJECT_INITIAL_COLUMN_UUIDS =
  "SET_PROJECT_INITIAL_COLUMN_UUIDS";
export const GET_TASKS_AUTOCOMPLETE = "GET_TASKS_AUTOCOMPLETE";
export const CLEAR_TASKS_AUTOCOMPLETE = "CLEAR_TASKS_AUTOCOMPLETE";
export const GET_TASKS_AUTOCOMPLETE_SUCCESS = "GET_TASKS_AUTOCOMPLETE_SUCCESS";
export const SET_PROJECT_LOADING = "SET_PROJECT_LOADING";
export const UPDATE_LIST_TASK_RANK = "UPDATE_LIST_TASK_RANK";
export const CLEAR_PROJECT_BASIC_DATA = "CLEAR_PROJECT_BASIC_DATA";
export const SET_EDITED_CELL_ID = "SET_EDITED_CELL_ID";
export const GET_GLOBAL_PROJECT_META_FIELD_LIST =
  "GET_GLOBAL_PROJECT_META_FIELD_LIST";
export const GET_GLOBAL_PROJECT_META_FIELD_LIST_SUCCESS =
  "GET_GLOBAL_PROJECT_META_FIELD_LIST_SUCCESS";
export const GET_GLOBAL_TASK_META_FIELD_LIST =
  "GET_GLOBAL_PROJECT_META_FIELD_LIST";
export const GET_GLOBAL_TASK_META_FIELD_LIST_SUCCESS =
  "GET_GLOBAL_PROJECT_META_FIELD_LIST_SUCCESS";
export const CREATE_GLOBAL_PROJECT_META_FIELD =
  "CREATE_GLOBAL_PROJECT_META_FIELD";
export const CREATE_GLOBAL_PROJECT_META_FIELD_SUCCESS =
  "CREATE_GLOBAL_PROJECT_META_FIELD_SUCCESS";
export const CLEAR_GLOBAL_META_FIELD_LIST = "CLEAR_GLOBAL_META_FIELD_LIST";
// Wizard fields
export const UPDATE_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS =
  "UPDATE_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS";
export const CLEAR_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS =
  "CLEAR_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS";
export const SET_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS =
  "SET_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS";
export const CLEAR_PROJECT_ERROR_MESSAGES = "CLEAR_PROJECT_ERROR_MESSAGES";

export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";
export const GET_PROJECT_DETAILS_SUCCESS = "GET_PROJECT_DETAILS_SUCCESS";
export const CLEAR_PROJECT_DETAILS = "CLEAR_PROJECT_DETAILS";
export const REMOVE_PROJECT = "REMOVE_PROJECT";
