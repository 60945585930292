import React from "react";
import "./CancelTrialModal.scss";

import { useSelector } from "react-redux";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalTitle, ModalText } from "../../components/Modal/Modal";
import { ReactComponent as CancelOval } from "../../../images/cancel-oval.svg";

function CancelTrialModal(props) {
  const { onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const {
    mainReducer: { identity },
  } = useSelector((state) => state);

  return (
    <Modal
      className="cancel-trial-modal"
      onClose={handleClose}
      displayCancelButton
      closeButtonText="Close"
      variant="medium"
    >
      <ModalTitle icon={<CancelOval />}>
        <IDHFormattedMessage
          id="id_cancel_trial"
          defaultMessage="Cancel trial"
        />
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="id_to_cancel_the_trial_period_please_contact_support"
          defaultMessage="To cancel the trial period, please contact us at"
        />
        :
        <span className="contact-support-modal__email">
          &nbsp;
          <a href={`mailto:${identity?.trialLimits?.bdWsMemberEmail}`}>
            {identity?.trialLimits?.bdWsMemberEmail}
          </a>
        </span>
      </ModalText>
    </Modal>
  );
}

export { CancelTrialModal };
