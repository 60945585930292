import React, { useState } from "react";
import "./CouponGroupSettingsDropdown.scss";

import { Button } from "src/app/components/Button/Button";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

import { ReactComponent as DotsIcon } from "src/images/dots.svg";
import { ReactComponent as SettingsIcon } from "src/images/switch.svg";
import { ReactComponent as DeleteIcon } from "src/images/trash-can.svg";

import classNames from "classnames";
import { CouponGroup } from "src/redux/coupon/couponReducer";
import { CouponGroupSettingsModal } from "../../modals/CouponGroupSettingsModal/CouponGroupSettingsModal";
import { DeleteCouponGroupModal } from "../../modals/DeleteCouponGroupModal/DeleteCouponGroupModal";

type ButtonSize = "default" | "small";

interface Props {
  couponGroupUuid: string;
  groupData: CouponGroup;
  buttonSize?: ButtonSize;
  onOpenSettings?: () => void;
}

export const CouponGroupSettingsDropdown: React.FC<Props> = (props) => {
  const { couponGroupUuid, groupData, buttonSize, onOpenSettings } = props;

  const [showGroupSettingsModal, setShowGroupSettingsModal] = useState(false);
  const [showDeleteCouponGroupModal, setShowDeleteCouponGroupModal] =
    useState(false);

  const openGroupSettingsModal = () => {
    if (onOpenSettings) onOpenSettings();
    setShowGroupSettingsModal(true);
  };

  return (
    <>
      <Dropdown>
        {(isOpen, setIsOpen) => (
          <>
            <Button
              variant="light-grey"
              size="large"
              onClick={() => setIsOpen(true)}
              className={classNames("coupon-group-settings-dropdown__button", {
                "coupon-group-settings-dropdown__button--small":
                  buttonSize === "small",
              })}
            >
              <DotsIcon />
            </Button>

            <DropdownMenu isOpen={isOpen}>
              <DropdownMenuItem onClick={openGroupSettingsModal}>
                <SettingsIcon />
                <IDHFormattedMessage
                  id="ws_settings"
                  defaultMessage="Settings"
                />
              </DropdownMenuItem>
              <DropdownMenuItem
                className="dropdown__menu-item--danger"
                onClick={() => setShowDeleteCouponGroupModal(true)}
              >
                <DeleteIcon />
                <IDHFormattedMessage id="ws_remove" defaultMessage="Remove" />
              </DropdownMenuItem>
            </DropdownMenu>
          </>
        )}
      </Dropdown>

      {showGroupSettingsModal && (
        <CouponGroupSettingsModal
          onClose={() => setShowGroupSettingsModal(false)}
          groupData={groupData}
        />
      )}

      {showDeleteCouponGroupModal && (
        <DeleteCouponGroupModal
          onClose={() => setShowDeleteCouponGroupModal(false)}
          couponGroupName={groupData?.name}
          couponGroupUuid={couponGroupUuid}
        />
      )}
    </>
  );
};
