import React, { useMemo } from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SectionHeader } from "../../../../components/Pane/SectionHeader/SectionHeader";
import { SectionRowWrapper } from "../../../../components/Pane/SectionRowWrapper/SectionRowWrapper";
import { SentimentList } from "../components/SentimentList/SentimentList";

function SentimentData(props) {
  const { platform, platformDisplayName, sentimentData } = props;

  const listData = useMemo(
    () =>
      sentimentData?.map((item) => {
        const { name, ...rest } = item;

        return {
          ...rest,
          statistic: name,
        };
      }) || [],
    [sentimentData],
  );

  return (
    <SectionRowWrapper>
      <div className="task-section-header-wrapper">
        <SectionHeader
          name={
            <IDHFormattedMessage
              id="ws_statistics"
              defaultMessage="Statistics"
            />
          }
          // tooltip={
          //   <IDHFormattedMessage
          //     id="ws_sentiment_data_tooltip"
          //     defaultMessage={`To assess the sentiment of comments on Instagram, we utilize an AI-driven process that begins with a thorough text analysis. This involves examining each comment to identify key phrases and words indicative of sentiment, such as "love" or "great" for positive sentiments, and "dislike" or "problem" for negative sentiments. Neutral sentiments typically emerge in comments that provide factual remarks or questions without emotional language. Additionally, the AI considers the use of emojis and punctuation, such as exclamation marks or smiley faces, which often enhance or clarify the emotional tone of a comment. The AI also takes into account the context in which words are used to accurately determine sentiment. This is crucial for identifying nuances like sarcasm, where the literal words might suggest a positive sentiment but the intended meaning is negative. By integrating lexical cues, contextual understanding, and emotional indicators, the AI effectively classifies the sentiment of each comment. This sophisticated analysis provides detailed insights into audience reactions, allowing for more tailored and effective engagement strategies on social platforms.`}
          //   />
          // }
        />
      </div>

      <SentimentList
        sentimentData={listData}
        platform={platform}
        platformDisplayName={platformDisplayName}
      />
    </SectionRowWrapper>
  );
}

export { SentimentData };
