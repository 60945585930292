import React, { useEffect, useMemo, useRef, useState } from "react";
import "./TeamDetails.scss";

import { useSelector } from "react-redux";

import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as TrashCanIcon } from "src/images/trash-can.svg";
import { ReactComponent as EditIcon } from "src/images/edit.svg";
import { ReactComponent as TickIcon } from "src/images/tick-light-grey.svg";
import { ReactComponent as CrossIcon } from "src/images/cross.svg";

import { showToast } from "src/app/methods/showToast";
import axios from "axios";
import { API_URLS } from "src/utils/API_URLS";
import CustomImage from "src/app/components/CustomImage/CustomImage";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import { Button } from "src/app/components/Button/Button";
import { ScalingInput } from "src/app/components/ScalingInput/ScalingInput";
import { RootState } from "src/redux/reducers";
import InviteTeamUser from "../InviteTeamUser/InviteTeamUser";
import { Team, TeamMember } from "../../utils";
import { TeamsSection } from "../../Teams";

interface Props {
  teams: Team[];
  onClose: () => void;
  getTeams: () => void;
  setSection: (newSection: TeamsSection) => void;
}

export const TeamDetails: React.FC<Props> = (props) => {
  const { teams, onClose, getTeams, setSection } = props;

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState<Partial<TeamMember>>({});
  const [localName, setLocalName] = useState("");
  const [editMode, setEditMode] = useState(false);

  const {
    mainReducer: { settingsModalData, activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const ref = useRef<HTMLInputElement>(null);

  const { objectUuid } = settingsModalData;

  const teamDetails: Partial<Team> = useMemo(
    () =>
      objectUuid ? teams.find((team) => team.uuid === objectUuid) || {} : {},
    [teams, objectUuid],
  );

  useEffect(() => {
    if (teamDetails.name) {
      setLocalName(teamDetails.name);
    }
  }, [teamDetails]);

  const goBack = () => setSection(TeamsSection.TeamList);

  const addMember = () => {
    const uuids: string[] = [];
    let breakFunction = false;

    users.forEach((member: any) => {
      if (member.value.id) {
        uuids.push(member.value.id);
      } else {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_invalid_member_email"
            defaultMessage="Invalid member e-mail!"
          />,
        );
        breakFunction = true;
      }
    });

    if (breakFunction) {
      return;
    }

    axios
      .post(API_URLS.addTeamMember, {
        wsTeamUuid: teamDetails.uuid,
        uuids,
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_something_went_wrong"
            defaultMessage="Something went wrong"
          />,
        );
      })
      .then(() => {
        getTeams();
        setUsers([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const memberRemoveClick = (member: TeamMember) => {
    setShowRemoveModal(true);
    setMemberToRemove(member);
  };

  const removeMember = () => {
    if (!memberToRemove.uuid) return;

    axios
      .post(API_URLS.removeTeamMember, {
        wsMemberUuid: memberToRemove.uuid,
        wsTeamUuid: teamDetails.uuid,
      })
      .then(() => {
        getTeams();
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id="ws_member_has_been_deleted"
            defaultMessage="Member has been deleted."
          />,
        );
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_could_not_delete_member"
            defaultMessage="Could not delete member."
          />,
        );
      });
  };

  const editModeClick = () => {
    setEditMode((v) => !v);
    setTimeout(() => {
      setLocalName(`${teamDetails.name} `);
      setLocalName(teamDetails.name || "");
      ref.current?.focus();
    }, 1);
  };

  const renameTeam = () => {
    if (localName === teamDetails.name) {
      setEditMode(false);
      return;
    }

    axios
      .put(API_URLS.renameTeam, {
        wsTeamUuid: teamDetails.uuid,
        wsWorkspaceUuid: activeWorkspaceUuid,
        name: localName,
      })
      .then(() => {
        getTeams();
        setEditMode(false);
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_could_not_rename_team"
            defaultMessage="Could not rename Team."
          />,
        );
      });
  };

  const disableEditMode = () => {
    if (teamDetails.name) {
      setLocalName(teamDetails.name);
    }
    setEditMode(false);
  };

  return (
    <>
      <AnimatedDiv className="team-details">
        <div className="team-details__link" onClick={goBack}>
          <IDHFormattedMessage id="ws_teams" defaultMessage="Teams" />
        </div>

        <div className="team-details__title">
          {editMode ? (
            <ScalingInput
              className="team-details__title-input"
              ref={ref}
              value={localName}
              onChange={(e) => setLocalName(e.target.value)}
            />
          ) : (
            localName
          )}

          {editMode ? (
            <>
              <TickIcon onClick={renameTeam} />
              <CrossIcon onClick={disableEditMode} />
            </>
          ) : (
            <EditIcon onClick={editModeClick} />
          )}
        </div>

        <InviteTeamUser
          addMember={addMember}
          members={teamDetails.wsMembers}
          users={users}
          setUsers={setUsers}
          loading={loading}
        />

        {teamDetails.wsMembers?.length ? (
          <div className="team-details__list-topbar">
            <IDHFormattedMessage
              id="ws_people_with_access"
              defaultMessage="People with access"
            />
          </div>
        ) : null}

        <div className="team-details__list-wrapper">
          {teamDetails.wsMembers?.map((member) => (
            <div className="team-details__list-member" data-qa={member.email}>
              <div className="team-details__list-member-info">
                <CustomImage
                  className="team-details__list-member-cover"
                  src={member.avatarUrl}
                  alt="avatar-url"
                />
                <div className="team-details__list-member-data">
                  <span className="team-details__list-member-name">
                    {member.name}
                  </span>
                  <span className="team-details__list-member-email">
                    {member.email}
                  </span>
                </div>
              </div>

              <TrashCanIcon
                className="team-details__list-member-delete-icon"
                onClick={() => memberRemoveClick(member)}
              />
            </div>
          ))}
        </div>
      </AnimatedDiv>

      <SettingsButtons>
        <div style={{ display: "flex", gap: "16px" }}>
          <Button
            size="large"
            variant="white-with-black-border"
            onClick={goBack}
          >
            <IDHFormattedMessage id="ws_back" defaultMessage="Back" />
          </Button>
          <Button
            size="large"
            variant="white-with-black-border"
            onClick={onClose}
          >
            <IDHFormattedMessage id="ws_close" defaultMessage="Close" />
          </Button>
        </div>
      </SettingsButtons>

      {showRemoveModal && (
        <RemoveModal
          onClose={() => setShowRemoveModal(false)}
          objectNames={[memberToRemove?.name ?? ""]}
          removeFunction={removeMember}
        />
      )}
    </>
  );
};
