import React from "react";

import { Button, ButtonVariantDefault } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as PlusIcon } from "src/images/plus-transparent.svg";

interface AddSocialProfilesModalButtonsProps {
  onClose: () => void;
  onConfirmClick: (e: React.FormEvent) => void;
  handleAddSocialLink: () => void;
}

export function AddSocialProfilesModalButtons({
  onClose,
  onConfirmClick,
  handleAddSocialLink,
}: AddSocialProfilesModalButtonsProps) {
  return (
    <div className="add-social-profiles-modal__buttons-wrapper">
      <Button
        className="add-social-link-button"
        variant="bare"
        size="small"
        type="button"
        onClick={handleAddSocialLink}
      >
        <PlusIcon />
        <IDHFormattedMessage
          defaultMessage="Add Social Link"
          id="ws_add_social_link"
        />
      </Button>
      <div className="add-social-profiles-modal__buttons">
        <Button
          type="button"
          variant="white-with-border"
          size="large"
          onClick={onClose}
        >
          <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          variant={ButtonVariantDefault}
          size="large"
          type="submit"
          onClick={onConfirmClick}
        >
          <IDHFormattedMessage id="ws_confirm" defaultMessage="Confirm" />
        </Button>
      </div>
    </div>
  );
}
