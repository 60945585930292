import React, { useEffect, useState } from "react";
import "./WsContentProposalDownloadModal.scss";

import { ReactComponent as DownloadContentIcon } from "src/images/ws-download-proposal-image.svg";
import { useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RootState } from "src/redux/reducers";
import Modal, { ModalText, ModalTitle } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";

interface Props {
  onClose: () => void;
}

export const WsContentProposalDownloadModal: React.FC<Props> = (props) => {
  const { onClose } = props;

  const [runningDone, setRunningDone] = useState(false);

  useEffect(() => {
    if (runningDone) {
      setTimeout(() => {
        setRunningDone(false);
      }, 3000);
    }
  }, [runningDone]);

  return (
    <Modal
      onClose={onClose}
      className="content-proposal-download-modal"
      disableTransition
    >
      <div className="content-proposal-download-modal__row">
        <div className="content-proposal-download-modal__content">
          <div className="content-proposal-download-modal__title">
            <IDHFormattedMessage
              id="ws_download_content_modal_title"
              defaultMessage="Check your inbox for the content package"
            />
          </div>
          <div className="content-proposal-download-modal__description">
            <IDHFormattedMessage
              id="ws_download_content_modal_description"
              defaultMessage="We've sent the content package to your email. Go to your inbox to download the files."
            />
          </div>
          <Button variant="white-with-border" size="small" onClick={onClose}>
            <IDHFormattedMessage id="ws_close" defaultMessage="Close" />
          </Button>
        </div>
        <div className="content-proposal-download-modal__image">
          <DownloadContentIcon />
        </div>
      </div>
    </Modal>
  );
};
