import React from "react";
import Pictogram, {
  PictogramVariants,
} from "../../../../../components/Pictogram/Pictogram";
import { renderGeneratorMetaValue } from "../../../../../generator-data/components/ContentBox/ContentBox";
import "./SentimentAnalysisBox.scss";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import RatingSelector from "src/app/components/RatingSelector/RatingSelector";
import { getStatisticHeader } from "src/helpers/statistic-list-item";

export function SentimentAnalysisBox(props) {
  const { data } = props;

  const getIconName = () => {
    switch (data.key) {
      case "sentimentPositiveShare":
        return "positive";
      case "sentimentNeutralShare":
        return "neutral";
      case "sentimentNegativeShare":
        return "negative";
      case "sentimentProductRelatedShare":
        return "productMentions";

      default:
        return "neutral";
    }
  };

  const getIconVariant = () => {
    switch (data.key) {
      case "sentimentPositiveShare":
        return PictogramVariants.ForestCrayola;
      case "sentimentNeutralShare":
        return PictogramVariants.PhilippineSilver;
      case "sentimentNegativeShare":
        return PictogramVariants.Vermilion;
      case "sentimentProductRelatedShare":
        return PictogramVariants.Instagram;

      default:
        return PictogramVariants.PhilippineSilver;
    }
  };

  return (
    <div className="sentiment-analysis-box">
      <span className="sentiment-analysis-box__name">
        {getStatisticHeader(data.key)}
      </span>

      <span className="sentiment-analysis-box__value">
        <span className="sentiment-analysis-box__value-icon">
          <Pictogram
            identifier={`Sentiment/${getIconName()}`}
            variant={getIconVariant()}
          />
        </span>
        {data?.value || 0}%
      </span>
    </div>
  );
}
