import React, { useEffect, useState } from "react";
import "./OverviewFieldsTable.scss";

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { RootState } from "src/redux/reducers";
import { IProject } from "src/redux/project/types";
import { SkeletonText } from "../Skeleton/Skeleton";
import { DateObject, IColumn } from "../../project/Project";
import {
  getProject,
  getTasksAutocomplete,
  setGallerySources,
  updateProjectMetaFieldRank,
  updateTaskMetaFieldRank,
  updateTasksList,
} from "../../../redux";
import { generateRankString } from "../../../utils/rankStrings";
import { HIDDEN_FIELDS, TASK_TYPES } from "../../../utils/variables";

import { ProjectOrTaskName } from "../../project/ProjectOrTaskName";
import ColumnOptionsDropdown from "../../dropdowns/ColumnOptionsDropdown";
import { TableField } from "../../project/TableField/TableField";
import { ReactComponent as PlusIcon } from "../../../images/plus-transparent.svg";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import { tableDataType } from "../Table/Table";

export enum taskAvatarShape {
  Circle,
  Square,
}

interface Props {
  className?: string;
  data: IProject[];
  fields: any[];
  disableLink?: boolean;
  filtersVisible?: boolean;
  onTableValueChange?: (
    taskId: string,
    uuid: string,
    newValue: string | DateObject | null | boolean | number,
  ) => void;
  actionButton: (projectData: IProject) => React.ReactNode | React.FC;
  updateData?: () => void;
  addTableElement?: () => void;
  addTableMetaField?: () => void;
  taskType: string;
  dataType: tableDataType;
  taskAvatarShape: taskAvatarShape;
  singleRow?: boolean;
}

function OverviewFieldsTable(props: Props) {
  const {
    className,
    data,
    fields,
    disableLink,
    filtersVisible,
    onTableValueChange,
    actionButton,
    updateData,
    addTableMetaField,
    addTableElement,
    taskType,
    dataType,
    singleRow,
  } = props;

  const [filteredFields, setFilteredFields] = useState<IColumn[]>([]);
  const [autoCompletes, setAutoCompletes] = useState<any>([]);

  const dispatch = useDispatch();

  const params = useParams<{ projectId: string }>();

  const {
    taskReducer: { tasksLoading },
    projectReducer: { projectLoading, projectBasicData },
  } = useSelector((state: RootState) => state);

  // clears data after TaskType change and sets data to display
  useEffect(() => {
    setAutoCompletes([]);
    dispatch(setGallerySources([]));
  }, [taskType]);

  useEffect(() => {
    const objectMetaData = data[0]?.metadata;
    const autoCompletesLocal: any = [];
    const newFilteredFields = fields.filter((field) => {
      if (
        TASK_TYPES.includes(field.type) &&
        !autoCompletes.includes(field.type) &&
        !autoCompletesLocal.includes(field.type)
      ) {
        autoCompletesLocal.push(field.type);
        setAutoCompletes((list: any) => [...list, field.type]);
        dispatch(getTasksAutocomplete(params.projectId, field.type));
      }
      const uuid = field.id || field.uuid;
      return (
        field.isVisible &&
        !HIDDEN_FIELDS.includes(field.name) &&
        objectMetaData?.find((metafield: any) => metafield.uuid === uuid)
      );
    });

    setFilteredFields(newFilteredFields);
  }, [fields, data]);

  const handleDragUpdate = (update: any) => {
    if (!update.destination) {
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const { destination, source } = result;

    const sourceField: any = filteredFields[source.index];
    const fieldIndex = destination.index;

    let newRank = "";

    // move left
    if (destination.index < source.index) {
      newRank = generateRankString(
        filteredFields[fieldIndex - 1]?.rank || "",
        filteredFields[fieldIndex].rank,
      );
      // move right
    } else {
      newRank = generateRankString(
        filteredFields[fieldIndex].rank,
        filteredFields[fieldIndex + 1]?.rank || "",
      );
    }
    updateMetaFieldRank(sourceField, newRank);
  };

  const updateMetaFieldRank = async (
    sourceField: { id: string; uuid: string },
    newRank: string,
  ) => {
    switch (dataType) {
      case tableDataType.Project:
        await dispatch(
          updateProjectMetaFieldRank(
            params.projectId,
            sourceField.uuid,
            newRank,
          ),
        );
        dispatch(getProject(params.projectId));
        break;

      case tableDataType.Task:
        await dispatch(updateTaskMetaFieldRank(sourceField.id, newRank));
        dispatch(updateTasksList(params.projectId, taskType));
        break;
    }
  };

  return (
    <>
      {data && (
        <div
          className={classNames(
            "ws-overview-fields-table",
            "ws-overview-fields-table--tall",
            filteredFields.length === 0 &&
              !projectBasicData?.permissions?.project.includes(
                "project_meta_fields_management",
              ) &&
              "ws-overview-fields-table--empty",
            className,
          )}
        >
          {!singleRow && (
            <div className="ws-overview-fields-table__info">
              <div className="ws-overview-fields-table__header ws-overview-fields-table__header--left">
                {dataType === tableDataType.Project ? "Name" : taskType}
              </div>
              {data.length > 0 ? (
                data.map((item: any) => (
                  <ProjectOrTaskName
                    key={item.projectId}
                    item={item}
                    disableLink={disableLink}
                    parentLoading={
                      dataType === tableDataType.Task
                        ? tasksLoading
                        : projectLoading
                    }
                    singleRow={singleRow}
                    dataType={dataType}
                    updateData={updateData}
                    taskAvatarShape={props.taskAvatarShape}
                  />
                ))
              ) : (
                <div className="ws-overview-fields-table__content">
                  <div
                    className="ws-overview-fields-table__add-button"
                    onClick={addTableElement}
                  >
                    <span
                      className={classNames(
                        "ws-overview-fields-table__add-button-empty",
                        {
                          "ws-overview-fields-table__add-button-empty--circle":
                            props.taskAvatarShape === taskAvatarShape.Circle,
                          "ws-overview-fields-table__add-button-empty--square":
                            props.taskAvatarShape === taskAvatarShape.Square,
                        },
                      )}
                    >
                      <PlusIcon />
                    </span>
                    {`Click here to add ${
                      dataType === tableDataType.Project ? "campaign" : taskType
                    }`}
                  </div>
                </div>
              )}
            </div>
          )}

          <DragDropContext
            onDragUpdate={handleDragUpdate}
            onDragEnd={(result) => handleDragEnd(result)}
          >
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  id="table-scroll"
                  className={classNames(
                    "ws-overview-fields-table__columns",
                    {
                      "ws-overview-fields-table__columns--dragging-over":
                        snapshot.isDraggingOver,
                    },
                    "scroll-fixed",
                  )}
                  {...provided.droppableProps}
                >
                  {filteredFields?.map((field: any, index) => {
                    return (
                      <Draggable
                        key={field.id || field.uuid}
                        draggableId={field.id || field.uuid}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            className={classNames(
                              "ws-overview-fields-table__column",
                              {
                                "ws-overview-fields-table__column--dragging":
                                  snapshot.isDragging,
                              },
                            )}
                            {...provided.draggableProps}
                            data-column-name={field.name}
                            data-column-key={field.key}
                          >
                            <div
                              className="ws-overview-fields-table__header"
                              key={index}
                            >
                              <ColumnOptionsDropdown
                                field={field}
                                dataType={dataType}
                                dragHandleProps={provided.dragHandleProps}
                              />
                            </div>
                            {data?.map((item: any) => {
                              const itemField: any = item.metadata.find(
                                (meta: any) =>
                                  field.id === meta.uuid ||
                                  field.uuid === meta.uuid,
                              );

                              const fieldValue = itemField?.value?.date
                                ? itemField?.value?.date
                                : itemField?.value;

                              const uuid = itemField?.uuid;

                              if (itemField) {
                                return (
                                  <div
                                    key={item.projectId}
                                    className="ws-overview-fields-table__row"
                                  >
                                    {(tasksLoading &&
                                      dataType === tableDataType.Task) ||
                                    (projectLoading &&
                                      dataType === tableDataType.Project) ? (
                                      <SkeletonText width={60} height={15} />
                                    ) : (
                                      <TableField
                                        fieldValue={
                                          fieldValue === "" ? null : fieldValue
                                        }
                                        fieldData={itemField}
                                        onValueChange={(newValue) => {
                                          if (
                                            (newValue ||
                                              newValue === false ||
                                              newValue === null) &&
                                            onTableValueChange
                                          ) {
                                            onTableValueChange(
                                              item.projectId,
                                              uuid,
                                              newValue,
                                            );
                                          }
                                        }}
                                        objectId={item.projectId}
                                        uuid={uuid}
                                        dataType={dataType}
                                      />
                                    )}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                  {/* {placeholderProps && snapshot.isDraggingOver && (
                    <AnimatedDiv
                      className="board-column__placeholder"
                      style={{
                        top: placeholderProps?.clientY,
                        left: placeholderProps?.clientX,
                        height: placeholderProps?.clientHeight,
                        width: placeholderProps?.clientWidth,
                      }}
                    />
                  )} */}

                  {dataType === tableDataType.Project &&
                    singleRow &&
                    projectBasicData?.permissions?.project.includes(
                      "project_meta_fields_management",
                    ) && (
                      <div
                        className="ws-overview-fields-table__add-button ws-overview-fields-table__add-button--project"
                        // className={classNames(
                        //   "ws-overview-fields-table__add-button ws-overview-fields-table__add-button--project",
                        //   {
                        //     "ws-overview-fields-table__add-button ws-overview-fields-table__add-button--project-small":
                        //       filteredFields.length > 0,
                        //   }
                        // )}
                        onClick={addTableMetaField}
                      >
                        <span
                          className={classNames(
                            "ws-overview-fields-table__add-button-empty ws-overview-fields-table__add-button-empty--circle",
                            {
                              "ws-overview-fields-table__add-button-empty--small":
                                filteredFields.length > 0,
                            },
                          )}
                        >
                          <PlusIcon />
                        </span>
                        <IDHFormattedMessage
                          id="ws_add_overview"
                          defaultMessage="Add overview"
                        />
                        ...
                      </div>
                    )}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {data.length && !singleRow ? (
            <div className="ws-overview-fields-table__actions">
              <div className="ws-overview-fields-table__header ws-overview-fields-table__header--flex">
                <IDHFormattedMessage id="ws_action" defaultMessage="Action" />
              </div>

              {data.map((item, index) => (
                <div
                  key={item.projectId}
                  className={classNames(
                    "ws-overview-fields-table__content",
                    "ws-overview-fields-table__content--center",
                    {
                      "ws-overview-fields-table__content--first": index === 0,
                    },
                  )}
                >
                  {actionButton(item)}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}

export default OverviewFieldsTable;
