import React from "react";
import "./CampaignInfoCell.scss";

import axios from "axios";

import { Link } from "react-router-dom";
import { ReactComponent as PublishIcon } from "src/images/publish.svg";
import { ReactComponent as UnPublishIcon } from "src/images/unpublish.svg";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import { showToast } from "src/app/methods/showToast";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { CampaignInfoCellTooltip } from "./CampaignInfoCellTooltip/CampaignInfoCellTooltip";
import { SocialProviderIcon2 } from "../../../../components/SocialProviderIcon2/SocialProviderIcon2";
import CONSTS from "../../../../helpers/consts";
import HtmlParser from "../../../../components/HtmlParser/HtmlParser";

function CampaignInfoCell({
  name,
  headerLink,
  listItem,
  published,
  setPublished,
}) {
  const { socialProvider, canManageList = false } = listItem;

  const changeListVisibility = () => {
    const newPublished = !published;
    setPublished(newPublished);
    axios
      .post(CONSTS.changeListVisibility, {
        listId: listItem.listId,
        isPublic: newPublished,
      })
      .catch((error) => {
        error?.response?.data?.content?.errors[0] &&
          showToast("error", "Error", error.response.data.content.errors[0]);
        setPublished(!newPublished);
      })
      .then((res) => {
        res &&
          showToast(
            "success",
            "Success",
            <IDHFormattedMessage id={res.data.content} />,
          );
      });
  };

  return (
    <div className="influencers-list__cell--campaign-info campaign-info">
      <div className="campaign-info__col">
        <div className="campaign-info__name-wrapper">
          {headerLink ? (
            <Link
              to={headerLink}
              className="campaign-info__name"
              data-listName={name}
            >
              <HtmlParser html={name} />
            </Link>
          ) : (
            <div className="campaign-info__name">
              <HtmlParser html={name} />
            </div>
          )}

          {canManageList && (
            <MaterialTooltip
              contentClassName="campaing-info-tooltip"
              content={<CampaignInfoCellTooltip isPublic={published} />}
            >
              {published ? (
                <PublishIcon
                  className="campaing-info-tooltip-icon"
                  onClick={changeListVisibility}
                />
              ) : (
                <UnPublishIcon
                  className="campaing-info-tooltip-icon"
                  onClick={changeListVisibility}
                />
              )}
            </MaterialTooltip>
          )}
        </div>

        <div className="campaign-info__additional">
          <SocialProviderIcon2 provider={socialProvider} grey={false} />
        </div>
      </div>
    </div>
  );
}

export default CampaignInfoCell;
