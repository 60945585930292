import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { API_URLS } from "../../../utils/API_URLS";
import { showToast } from "../../methods/showToast";
import { getProject, getProjectDetails } from "../../../redux";
import "./ProjectInfo.scss";
import { injectIntl } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";

function ProjectInfo(props) {
  const { title, projectUuid, projectNameMetaField, intl } = props;
  const [value, setValue] = useState(title);

  const dispatch = useDispatch();

  const renameProject = async () => {
    if (value === title) {
    } else if (value.length === 0) {
      setValue(title);
    } else {
      try {
        await axios.put(API_URLS.updateProjectMetaValue, {
          metaFieldUuid: projectNameMetaField.uuid,
          metaValue: value,
          uuids: [projectUuid],
        });
        dispatch(getProject(projectUuid));
        dispatch(getProjectDetails(projectUuid));
      } catch (err) {
        console.error(err);
        showToast("error", "Error", "Cannot change campaign name");
      }
    }
  };

  useEffect(() => {
    if (value !== title) {
      setValue(title);
    }
  }, [title]);

  return (
    <input
      className={classNames("project-info__input", {})}
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={renameProject}
      placeholder={`${translateMessage({
        intl,
        id: "ws_type_campaign_name",
        defaultMessage: "Type campaign name",
      })}...`}
    />
  );
}

export default injectIntl(ProjectInfo);
